import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as UpdateIcon } from 'assets/icons/editPensle.svg';
import Chip from 'common/Chip';
import Input from 'common/Input';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { createActionTaken, updateActionTaken, deleteActionTaken } from 'Api/Incidents';
import ItemForm from './ItemForm';
import { SWrapper, SHeader, SActions, SAddMore, SContentRow, SRow } from './ActionTaken.styles';

const ActionTaken = ({ loading, actionTaken, setActionTaken }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [forms, setForms] = useState([]);
  const [editItems, setEditItems] = useState([]);

  const create = async (values, id) => {
    setLoadingCreate(true);
    try {
      const body = {
        action: values.action,
        color_code: values.color_code,
      };

      const { data } = await createActionTaken(body);
      deleteFormItem(id);
      setActionTaken((prevState) => [...prevState, data]);
      showToaster({ type: 'success', message: 'Action taken has been successfully added!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingCreate(false);
    }
  };

  const update = async (values, id) => {
    setLoadingUpdate(true);

    try {
      const body = {
        action: values.action,
        color_code: values.color_code,
      };

      const { data } = await updateActionTaken(id, body);

      const updatedList = actionTaken.map((item) => (item.id === id ? data : item));
      onCancel(id);
      setActionTaken(updatedList);
      showToaster({ type: 'success', message: 'Action taken has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handleDeleteActionTaken = async (id) => {
    try {
      await deleteActionTaken(id);
      const newActions = actionTaken.filter((item) => item.id !== id);
      setActionTaken(newActions);
      showToaster({ type: 'success', message: 'Action has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const toggleEdit = (id) => {
    setEditItems([...editItems, id]);
  };

  const onCancel = (id) => {
    const newEditItems = editItems.filter((item) => item !== id);
    setEditItems(newEditItems);
  };

  return (
    <SWrapper>
      <SHeader>
        <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
          Action Taken
        </Typography>
      </SHeader>
      {!loading && (
        <>
          <SRow>
            <div className='settings-header-item'>
              <Typography variant='overLine'>ACTION TAKEN</Typography>
            </div>
            <div className='settings-header-item'>
              <Typography variant='overLine'>COLOR</Typography>
            </div>
            <div className='settings-header-item' />
            <div className='settings-header-item'>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography />
              </div>
            </div>
          </SRow>
          {actionTaken?.map((item) =>
            editItems.includes(item.id) ? (
              <ItemForm
                item={item}
                key={item.id}
                defaultItem={!!item?.default}
                onCancel={() => onCancel(item?.id)}
                onSubmit={(values) => update(values, item.id)}
                loading={loadingUpdate}
              />
            ) : (
              <SContentRow key={item.id}>
                <div className='settings-table-item'>
                  <Typography variant='s2'>{item.action}</Typography>
                </div>
                <div className='settings-table-item'>
                  <Input
                    className='color-input'
                    name='color_code'
                    width='100px'
                    type='color'
                    value={item.color_code || palette.orange500}
                    disabled
                  />
                </div>
                <div className='settings-table-item'>
                  <Chip
                    label={item.action}
                    labelColor={item.color_code || palette.orange500}
                    bgColor={`${item.color_code || palette.orange500}11`}
                  />
                </div>
                <SActions className='settings-table-item settings-table-actions-wrapper'>
                  {item.default !== 1 && (
                    <>
                      <span className='action_wrapper' onClick={() => handleDeleteActionTaken(item.id)}>
                        <DeleteIcon width={11} height={11} fill={palette.red500} />
                      </span>
                      <span className='action_wrapper' onClick={() => toggleEdit(item.id)}>
                        <UpdateIcon width={11} height={11} fill={palette.indigo500} />
                      </span>
                    </>
                  )}
                </SActions>
              </SContentRow>
            )
          )}
          {forms.map((item) => (
            <ItemForm
              key={item.id}
              onSubmit={(values) => create(values, item.id)}
              onCancel={() => deleteFormItem(item.id)}
              loading={loadingCreate}
            />
          ))}
          <SAddMore onClick={addNewForm}>
            <PlusIcon fill='#4F5AED' />
            <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
              Add Action...
            </Typography>
          </SAddMore>
        </>
      )}
    </SWrapper>
  );
};

export default ActionTaken;
