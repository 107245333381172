import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderStar from 'components/HeaderStar';
import ExportCsv from 'componentsV2/ExportCsv';
import CustomButton from 'components/CustomButton/CustomButton';
import classes from './tableShipments.module.css';

const HeaderShipments = () => {
  const target = useRef(null);
  const navigate = useNavigate();

  return (
    <div className={classes.headerWrapper}>
      <HeaderStar title='Shipments' />
      <div className='d-flex align-items-center gap-3'>
        <ExportCsv type='shipments' height={36} />
        <CustomButton
          ref={target}
          type='primary'
          title='Create Shipment'
          styleButton={{ padding: '6px 12px' }}
          onClick={() => navigate('/planner', { state: { isShipmentHeaderNavigatedStatus: 1 } })}
        />
      </div>
    </div>
  );
};

export default HeaderShipments;
