import { object, string } from 'yup';

export const validationSchema = object().shape({
  assigned_to1: string().when('replace1', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  amount1: string().when('replace1', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  previous_driver1_amount: string().when('replace1', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  assigned_to2: string().when('replace2', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  amount2: string().when('replace2', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  previous_driver2_amount: string().when('replace2', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
