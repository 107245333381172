import axios from '../services/axios';

export async function getHardwareList(params) {
  const { data } = await axios.get('user/registration/hardware-paginated', { params });
  return data;
}

export async function getHardwareCart(params) {
  const { data } = await axios.get('hardware-cart', { params });
  return data;
}

export async function addToCart(body) {
  const { data } = await axios.post('hardware-cart', body);
  return data;
}

export async function removeFromCart(body) {
  const { data } = await axios.post('remove-hardware-cart', body);
  return data;
}
