import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import HeaderStar from 'components/HeaderStar';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { getReportCategories, updateReportFavoriteStatus } from 'Api/Reports';
import ReportAccordion from './components/ReportAccordion';
import Search from '../ApplicantsAndReferrals/components/Search';
import { updateFavorite } from './helpers';
import { SHeaderWrapper, SPageWrapper } from './Reports.styles';

const Reports = () => {
  const showToaster = useShowToaster();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const getReports = async () => {
    try {
      const params = {
        query: debouncedSearch || undefined,
      };
      const { data } = await getReportCategories(params);
      const convertedData = data.map((item) => ({ ...item, expanded: true }));
      setCategories(convertedData);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const updateIsFavorite = async (item) => {
    setUpdateLoading(true);
    const newCategories = updateFavorite(item, categories);
    setCategories(newCategories);

    try {
      const body = { is_favourite: item.is_favourite ? 0 : 1 };
      await updateReportFavoriteStatus({ id: item.id, body });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
    getReports();
    setUpdateLoading(false);
  };

  const onStarCLick = (e, item) => {
    e.stopPropagation();
    if (updateLoading) {
      return;
    }
    updateIsFavorite(item);
  };

  useEffect(() => {
    setLoading(true);
    getReports();
  }, [debouncedSearch]);

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Reports' isBeta />
      </SHeaderWrapper>
      <Search search={search} onChange={setSearch} />
      <Divider margin='16px 0' />
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {categories.map((item) => (
            <ReportAccordion key={item.id} data={item} title={item.category} onStarClick={onStarCLick} />
          ))}
        </>
      )}
    </SPageWrapper>
  );
};

export default Reports;
