import uuid from 'react-uuid';

export const settingOptions = [
  { id: 1, name: 'send_vehicle_gps_positions', label: 'Send outbound data with vehicle GPS positions' },
  { id: 2, name: 'send_vehicle_arrives_at_pickup', label: 'Send outbound data when vehicle arrives at pick up' },
  { id: 3, name: 'send_vehicle_departs_pickup', label: 'Send outbound data when vehicle departs pick up' },
  { id: 4, name: 'send_vehicle_arrives_at_delivery', label: 'Send outbound data when vehicle arrives at delivery' },
  { id: 5, name: 'send_vehicle_departs_delivery', label: 'Send outbound data when vehicle departs delivery' },
  { id: 6, name: 'send_shipment_completed', label: 'Send outbound data when shipment is completed' },
  { id: 7, name: 'send_outbound_data_to_edi', label: 'Send outbound data to EDI Provider on shipment creation' },
];

export const initialValues = {
  id: uuid(),
  document_204: false,
  document_214: false,
  document_210: false,
  send_vehicle_gps_positions: false,
  send_vehicle_arrives_at_pickup: false,
  send_vehicle_departs_pickup: false,
  send_vehicle_arrives_at_delivery: false,
  send_vehicle_departs_delivery: false,
  send_shipment_completed: false,
  send_outbound_data_to_edi: false,
  auto_approve_all_incoming_shipments: false,
  secondary_api_key: null,
};

export const getInitialValues = (settings, keys) => {
  return settings?.length
    ? settings.map((data) => {
        const {
          id,
          document_204,
          document_214,
          document_210,
          secondary_api_key_id,
          send_vehicle_gps_positions,
          send_vehicle_arrives_at_pickup,
          send_vehicle_departs_pickup,
          send_vehicle_arrives_at_delivery,
          send_vehicle_departs_delivery,
          send_shipment_completed,
          send_outbound_data_to_edi,
          auto_approve_all_incoming_shipments,
        } = data || {};

        return {
          id,
          document_204: !!document_204,
          document_214: !!document_214,
          document_210: !!document_210,
          send_vehicle_gps_positions: !!send_vehicle_gps_positions,
          send_vehicle_arrives_at_pickup: !!send_vehicle_arrives_at_pickup,
          send_vehicle_departs_pickup: !!send_vehicle_departs_pickup,
          send_vehicle_arrives_at_delivery: !!send_vehicle_arrives_at_delivery,
          send_vehicle_departs_delivery: !!send_vehicle_departs_delivery,
          send_shipment_completed: !!send_shipment_completed,
          send_outbound_data_to_edi: !!send_outbound_data_to_edi,
          auto_approve_all_incoming_shipments: !!auto_approve_all_incoming_shipments,
          secondary_api_key: keys.find((i) => i.id === secondary_api_key_id) || null,
        };
      })
    : [initialValues];
};
