import moment from 'moment';
import { getQuoteStartEndDate, getQuoteTotalDistance } from 'componentsV2/Planner/Quotes/CreateQuote/CreateQuote.data';

export const quoteValuesConverter = (values, submitType, pdfUrl) => {
  const { startDate, endDate, totalDuration } = getQuoteStartEndDate(values);
  const totalDistance = getQuoteTotalDistance(values);

  return {
    status_id: submitType === 'draft' ? 0 : 1,
    start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
    end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
    expiry_date: values.expiry_date ? moment(values.expiry_date).format('YYYY-MM-DD') : null,
    total_duration: totalDuration || null,
    total_distance: totalDistance || 0,
    estimated_cost_count: values.estimated_cost_count,
    estimated_cost_rate: values.estimated_cost_rate,

    payment_term_notes: values.payment_term_notes || null,
    quote_pdf_path: pdfUrl || null,
  };
};

export const legValuesConverter = (leg, index) => {
  return {
    quantity: leg.quantity,
    leg_number: index + 1,
    deadhead_stop_point_id: leg.deadhead_stop_point?.id || null,
    deadhead_country_id: leg.deadhead_country?.id || null,
    deadhead_state_id: leg.deadhead_state?.id || null,
    deadhead_city_id: leg.deadhead_city?.id || null,
    deadhead_zipcode: leg.deadhead_zipcode || null,
    deadhead_start_date: leg.deadhead_start_date ? moment(leg.deadhead_start_date).format('YYYY-MM-DD HH:mm:ss') : null,
  };
};

export const stopValuesConverter = (stop, legIndex, stopIndex) => {
  return {
    leg: legIndex + 1,
    order_in_leg: stopIndex + 1,
    stop_point_type: stop.stop_point_type,
    stop_point_id: stop.stop_point?.id,
    country_id: stop.country?.id || null,
    state_id: stop.state?.id || null,
    city_id: stop.city?.id || null,
    zipcode: stop.zipcode || null,
    scheduled_type: stop.scheduled_type?.key,
    scheduled_tbd: Number(stop.scheduled_tbd),
    scheduled_date: stop.scheduled_date ? moment(stop.scheduled_date).format('YYYY-MM-DD') : null,
    scheduled_date_to:
      stop.scheduled_date_to || stop.scheduled_date
        ? moment(stop.scheduled_date_to || stop.scheduled_date).format('YYYY-MM-DD')
        : null,
    from: stop.from ? moment(stop.from).format('HH:mm') : null,
    to: stop.to || stop.from ? moment(stop.to || stop.from).format('HH:mm') : null,
    equipment_type: stop.equipment_type?.id || null,
    equipment_type_length: stop.equipment_type_length?.id || null,
    equipment_action: stop.equipment_action?.key || null,
    equipment_tbd: stop.equipment_tbd || null,
    notes_text: stop.notes_text || null,
    bill_type: stop.bill_type ? 1 : 2,
    dock_high: Number(stop.dock_high),
    stackable: Number(stop.stackable),
    liftgate_service: Number(stop.liftgate_service),
    labor_required: Number(stop.labor_required),
    hazardous_materials: Number(stop.hazardous_materials),

    cargos:
      stop.stop_point_type === 1
        ? stop.cargos
            .filter((i) => !!i.commodity)
            .map((cargo) => ({
              uuid: cargo.id,
              commodity_id: cargo.commodity?.id || null,
              description: cargo.description || null,
              sku_barcode: cargo.sku_barcode || null,
              expected_quantity: cargo.expected_quantity || null,
              expected_quantity_type: cargo.expected_quantity_type?.id || null,
              expected_weight: cargo.expected_weight || null,
              expected_weight_type: cargo.expected_weight_type?.id || null,
              expected_weight_unit: cargo.expected_weight_unit?.id || null,
              value: cargo.value || null,
              value_type: cargo.value_type?.id || null,
              expected_dimensions: {
                l: cargo.expected_dimensions?.l || null,
                w: cargo.expected_dimensions?.w || null,
                h: cargo.expected_dimensions?.h || null,
              },
              expected_dimension_unit: cargo.expected_dimension_unit?.id || null,
              expected_stackable: Number(cargo.expected_stackable),
            }))
        : undefined,
    connected_cargos: stop.stop_point_type === 2 ? stop.cargos.map((cargo) => cargo.id) : undefined,
  };
};

export const createQuoteConverter = (values, submitType, pdfUrl) => {
  const stops = [];

  values.legs.forEach((leg, legIndex) => {
    leg.stops.forEach((stop, stopIndex) => {
      stops.push(stopValuesConverter(stop, legIndex, stopIndex));
    });
  });

  return {
    ...quoteValuesConverter(values, submitType, pdfUrl),
    stops,
    legs: values.legs.map((leg, legIndex) => legValuesConverter(leg, legIndex)),
    billing: {
      customer_id: values.billing.customer.id,
      currency_id: values.billing.currency?.id || null,
      reference_id: values.billing.reference_id || null,
      contact_user_id: values.billing.contact?.id || null,
      payment_term_id: values.billing.payment_term?.id || null,
      charges: values.charges
        .filter((i) => !!i.charge_type)
        .map((item) => ({
          charge_type_id: item.charge_type?.id,
          quantity: item.quantity,
          rate: item.rate,
          sub_total: Number(item.quantity || 0) * Number(item.rate || 0),
          currency_id: values.billing.currency?.id || null,
        })),
      total_power_unit: values.billing.total_power_unit || null,
      total_amount: values.charges.reduce((a, b) => a + Number(b.quantity || 0) * Number(b.rate || 0), 0),
    },
    line_items: values.line_items
      .filter((i) => !!i.charge_type)
      .map((item) => ({
        charge_type_id: item.charge_type?.id,
        quantity: item.quantity,
        rate: item.rate,
        sub_total: Number(item.quantity || 0) * Number(item.rate || 0),
        currency_id: values.line_items_currency?.id || null,
      })),
  };
};
