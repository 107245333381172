import React, { useEffect, useState } from 'react';
import moment from 'moment';
import InputAdornment from '@mui/material/InputAdornment';
import useDebounce from 'hooks/useDebounce';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import Search from 'common/Search';
import Tooltip from 'common/Tooltip';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import AddTransaction from 'components/TransationTab/components/AddTransaction';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getTabs } from 'Api/Planner';
import { getAllTransactions, getTransactionCategories } from 'Api/CardManagement';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import FeatureNotAvailable from '../FeatureNotAvailable';
import TransactionFilters from './components/TransactionFilters';
import CardHolderSelect from './components/CardHolderSelect';
import { initialFilters, filterTableTop, useColumns, transactionStatuses } from './Transactions.data';
import { STableHeader, STitleWrapper, SWrapper } from '../Cards/Cards.styles';
import { SFiltersWrapper, SSearchWrapper } from './Transactions.styles';
import LinkTransaction from './components/LinkTransaction';

const Transactions = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [filter, setFilter] = useState({ filterTableTop });
  const debouncedSearch = useDebounce(search, 500);
  const [openFeatureNotAvailable, setOpenFeatureNotAvailable] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);
  const [filterType, setFilterType] = useState('All');
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [receipt, setReceipt] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const downloadCsv = async (data) => {
    try {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `transactions.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // Do nothing
    }
  };

  const getTransactions = async (isExport) => {
    if (!isExport) {
      setLoading(true);
    }
    const driverId = [];
    const staffId = [];
    selectedFilters?.cardHolders?.forEach((i) => {
      if (i.user_type === 'Driver') {
        driverId.push(i.user_id);
      }
      if (i.user_type === 'Staff') {
        staffId.push(i.user_id);
      }
    });

    try {
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.page_size,
        query: debouncedSearch || undefined,
        user_type: selectedFilters.tab || undefined,
        start_date: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        'filter[category][]': selectedFilters?.category?.id || undefined,
        'filter[type][]': selectedFilters?.status?.name || undefined,
        'filter[merchant][]': selectedFilters?.merchant || undefined,
        driver_id: driverId.length ? driverId : undefined,
        staff_id: staffId.length ? staffId : undefined,
        export: isExport ? true : undefined,
      };

      if (selectedFilters?.providers?.length) {
        selectedFilters?.providers.forEach((item, index) => {
          params[`filter[provider][${index}]`] = item;
        });
      }

      const data = await getAllTransactions(params);
      if (isExport && typeof data === 'string') {
        downloadCsv(data);
      } else {
        setTransactions(data);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getTransactionCategory = async () => {
    try {
      const { data } = await getTransactionCategories();
      setCategoryOptions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTabsData = async () => {
    try {
      const { data } = await getTabs({ type: 'card_management_transactions' });
      const newFilterTableTop = [
        { ...filter.filterTableTop[0], count: data[0].count },
        { ...filter.filterTableTop[1], count: data[2].count },
        { ...filter.filterTableTop[2], count: data[1].count },
        { ...filter.filterTableTop[3], count: data[3].count },
      ];
      setFilter((prevState) => ({ ...prevState, filterTableTop: newFilterTableTop }));
    } catch (e) {
      // Do nothing
    }
  };

  const onViewReceipt = (receipt) => {
    setReceipt(receipt);
  };

  const onChangeTab = (n) => {
    setSearch('');
    setSelectedFilters({ ...selectedFilters, page: 1, tab: n.id });
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getTransactions();
  }, [selectedFilters, dateRange, debouncedSearch]);

  useEffect(() => {
    getTabsData();
    getTransactionCategory();
  }, []);

  const PaginationComponent = () => (
    <Pagination
      rowPerPage={selectedFilters.page_size}
      data={transactions}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
      rowsPerPageOptions={[10, 50, 100, 150]}
    />
  );

  const onAssignTransaction = (provider) => {
    setSelectedProvider(provider);
    setOpenLinkModal(true);
  };

  const columns = useColumns({ setOpenFeatureNotAvailable, onAssignTransaction, onViewReceipt });

  return (
    <SWrapper>
      <STableHeader>
        <STitleWrapper>
          <Typography variant='h1'>Transactions</Typography>
          <SFiltersWrapper>
            <DateRangePicker
              top='-168px'
              dateRange={dateRange}
              initialRangeName='All Time'
              setDateRange={setDateRange}
              type='allTime'
            />
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='110px'
                  options={['All', 'Category', 'Type']}
                  value={filterType}
                  onChange={(e, val) => setFilterType(val)}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <FilterIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {filterType === 'All' && (
                <Search search={search} onChange={setSearch} className='transaction-search-input' />
              )}
              {filterType === 'Type' && (
                <Autocomplete
                  width='250px'
                  disableClearable={false}
                  placeholder='Select Type..'
                  options={transactionStatuses}
                  value={selectedFilters.status}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, status: val }))}
                  className='filter-dropdown'
                />
              )}
              {filterType === 'Category' && (
                <Autocomplete
                  width='250px'
                  disableClearable={false}
                  placeholder='Select Category..'
                  labelKey='description'
                  options={categoryOptions}
                  value={selectedFilters.category}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, category: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                />
              )}
            </SSearchWrapper>
            {['Type', 'Category'].includes(filterType) && (
              <CardHolderSelect
                value={selectedFilters.cardHolders || []}
                onChange={(val) => setSelectedFilters((prevState) => ({ ...prevState, cardHolders: val }))}
              />
            )}
          </SFiltersWrapper>
        </STitleWrapper>
        <div className='d-flex gap-3 align-items-center align-self-end me-3'>
          <CustomButton
            type='secondary'
            title='Print Report'
            leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => navigate('/reports/26')}
          />
          <Tooltip title='Export CSV'>
            <span>
              <CustomButton
                type='secondary'
                leftIcon={<ExportIcon fill={palette.gray700} />}
                styleButton={{ padding: '9px 12px', margin: 0 }}
                onClick={() => getTransactions(true)}
              />
            </span>
          </Tooltip>
        </div>
      </STableHeader>
      <TransactionFilters
        filter={selectedFilters}
        updateFilter={setSelectedFilters}
        resetSearch={() => setSearch('')}
      />
      <SwitchRadio
        name='tableTopTabMenu'
        items={filter.filterTableTop}
        value={selectedFilters.tab}
        type='tab'
        onChange={onChangeTab}
        plus={false}
      />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={transactions?.data}
          rowPerPage={selectedFilters.page_size}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {openFeatureNotAvailable && (
        <FeatureNotAvailable
          open={openFeatureNotAvailable}
          onClose={() => setOpenFeatureNotAvailable(false)}
          text='This feature is only available with the Truckin Digital Cash Card.'
        />
      )}
      {openAddTransaction && (
        <AddTransaction
          open={openAddTransaction}
          onClose={() => setOpenAddTransaction(false)}
          onSuccess={getTransactions}
        />
      )}
      {openLinkModal && (
        <LinkTransaction
          open={openLinkModal}
          onClose={() => {
            setOpenLinkModal(false);
            setSelectedProvider(null);
          }}
          assignData={selectedProvider}
          onSuccess={() => {
            getTransactions();
            getTabsData();
          }}
        />
      )}
      <ViewPdf title='Receipt' open={!!receipt} onClose={() => setReceipt(null)} pdfUrl={receipt} />
    </SWrapper>
  );
};

export default Transactions;
