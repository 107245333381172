import styled from 'styled-components';

export const SAddressWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0 12px;
  margin-bottom: 24px;
  text-align: left;
`;

export const SFlexRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: flex-end;
`;
