import * as yup from 'yup';
import { date, string, object, number, mixed, array } from 'yup';
import { validator } from 'utils/helpers';

export const validationBasicSchema = yup.object({
  name: yup.string('').required('Carrier Name is required'),
  dot: yup.string('').required('Carrier DOT is required'),
  phone_number: yup
    .string()
    .min(10, 'Enter valid number')
    .max(12, 'Enter valid number')
    .required('Phone number is required'),
  email: string().required('Email is required').email('Invalid Email Address'),
  address: string().trim().required('Required'),
  address2: string().trim().nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required'),
  can_travel_to: array().min(1, 'Can Travel To is required').of(number()),
  trailer_interchange: string().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),
  trailer_interchange_from: date().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  trailer_interchange_expires: date().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
});

export const detailsBasicValidationSchema = yup.object({
  name: yup.string('').required('Carrier Name is required'),
  dot: yup.string('').required('Carrier DOT is required'),
  phone_number: yup
    .string()
    .min(10, 'Enter valid number')
    .max(12, 'Enter valid number')
    .required('Phone number is required'),
  email: string().required('Email is required').email('Invalid Email Address'),
  address: string().trim().required('Required').nullable(),
  address2: string().trim().nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required').nullable(),
  trailer_interchange: mixed().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  trailer_interchange_from: date().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  trailer_interchange_expires: date().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
});

export const documentsValidationSchema = object().shape({
  master_agreement: mixed().required('Attachment is required'),
  w9: mixed().required('Attachment is required'),
  authority: mixed().required('Attachment is required'),
  reference_sheet: mixed().required('Attachment is required'),
  liability_insurance_exp_date: validator.futureRequiredDate(),
  liability_insurance: mixed().required('Attachment is required'),
  cargo_insurance_exp_date: validator.futureRequiredDate(),
  cargo_insurance: mixed().required('Attachment is required'),
  general_liability_exp_date: validator.futureRequiredDate(),
  general_liability: mixed().required('Attachment is required'),
  documents: array().of(
    object().shape({
      name: string().trim().required('Required').nullable(),
      expiration_date: date().when('no_expiration', {
        is: (no_expiration) => !no_expiration,
        then: validator.futureRequiredDate(),
        otherwise: validator.date(),
      }),
      file: string().trim().required('Required').nullable(),
    })
  ),
});
