import { array, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  addDocument: array().of(
    object().shape({
      expiry_date: validator.date(),
    })
  ),
});
