import React from 'react';
import OpenBills from 'pages/Accounting/Payables/TableSection/Tables/OpenBills';
import PaidBills from 'pages/Accounting/Payables/TableSection/Tables/PaidBills';

export const tabs = [
  {
    key: 'openBills',
    label: 'Open Bills',
    component: ({ vendor, refreshList, carrierBill }) => (
      <OpenBills vendor={vendor} refreshStats={refreshList} carrierBill={carrierBill} />
    ),
  },
  {
    key: 'paidBills',
    label: 'Paid Bills',
    component: ({ vendor, refreshList }) => <PaidBills vendor={vendor} refreshStats={refreshList} />,
  },
];
