import uuid from 'react-uuid';
import moment from 'moment';

export const getSettingSuggestions = (policyTypes, insuranceSettings) => {
  const rows = [];

  const {
    physical_insurance_policy_active,
    physical_insurance_policy_id,
    physical_insurance_expire_date,
    physical_insurance_policy_attach,
    liability_insurance_policy_active,
    liability_insurance_policy_id,
    liability_insurance_expire_date,
    liability_insurance_policy_attach,
    motor_cargo_insurance_policy_active,
    motor_cargo_insurance_policy_id,
    motor_cargo_insurance_expire_date,
    motor_cargo_insurance_policy_attach,
    general_liability_insurance_policy_active,
    general_liability_insurance_policy_id,
    general_liability_insurance_expire_date,
    general_liability_insurance_policy_attach,
    highway_tax_active,
    highway_tax_expire_date,
    highway_tax_date_attach,
    ifta_sticker_active,
    ifta_sticker_no,
    ifta_sticker_expire_date,
    ifta_sticker_attach,
    kyu_active,
    kyu_id,
    kyu_attach,
  } = insuranceSettings || {};

  policyTypes.forEach((type) => {
    switch (type) {
      case 'physical_insurance':
        physical_insurance_policy_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 2, name: 'Physical Insurance' },
            reference_id: physical_insurance_policy_id || '',
            document_exp_date: physical_insurance_expire_date ? moment(physical_insurance_expire_date).toDate() : null,
            no_expire: !physical_insurance_expire_date,
            document: physical_insurance_policy_attach || null,
            use_from_policy: true,
          });
        break;
      case 'liability_insurance':
        liability_insurance_policy_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 3, name: 'Auto Liability' },
            reference_id: liability_insurance_policy_id || '',
            document_exp_date: liability_insurance_expire_date
              ? moment(liability_insurance_expire_date).toDate()
              : null,
            no_expire: !liability_insurance_expire_date,
            document: liability_insurance_policy_attach || null,
            use_from_policy: true,
          });
        break;
      case 'motor_cargo_insurance':
        motor_cargo_insurance_policy_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 4, name: 'Motor Cargo' },
            reference_id: motor_cargo_insurance_policy_id || '',
            document_exp_date: motor_cargo_insurance_expire_date
              ? moment(motor_cargo_insurance_expire_date).toDate()
              : null,
            no_expire: !motor_cargo_insurance_expire_date,
            document: motor_cargo_insurance_policy_attach || null,
            use_from_policy: true,
          });
        break;
      case 'general_liability_insurance':
        general_liability_insurance_policy_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 5, name: 'General Liability' },
            reference_id: general_liability_insurance_policy_id || '',
            document_exp_date: general_liability_insurance_expire_date
              ? moment(general_liability_insurance_expire_date).toDate()
              : null,
            no_expire: !general_liability_insurance_expire_date,
            document: general_liability_insurance_policy_attach || null,
            use_from_policy: true,
          });
        break;
      case 'ifta_sticker':
        ifta_sticker_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 6, name: 'IFTA Sticker' },
            reference_id: ifta_sticker_no || '',
            document_exp_date: ifta_sticker_expire_date ? moment(ifta_sticker_expire_date).toDate() : null,
            no_expire: !ifta_sticker_expire_date,
            document: ifta_sticker_attach || null,
            use_from_policy: true,
          });
        break;
      case 'highway_tax':
        highway_tax_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 7, name: 'Highway Tax' },
            reference_id: '',
            document_exp_date: highway_tax_expire_date ? moment(highway_tax_expire_date).toDate() : null,
            no_expire: !highway_tax_expire_date,
            document: highway_tax_date_attach || null,
            use_from_policy: true,
          });
        break;
      case 'kyu':
        kyu_active &&
          rows.push({
            id: uuid(),
            isNew: true,
            equipment_document_type: { id: 8, name: 'KYU' },
            reference_id: kyu_id || '',
            document_exp_date: null,
            no_expire: false,
            document: kyu_attach || null,
            use_from_policy: true,
          });
        break;
      default:
    }
  });

  return rows;
};
