import React, { useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/drivers/indigoDownload.svg';
import Modal from 'common/Modal';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { templateLink } from '../ImportCSV.data';
import { SCountWrapper, SDownloadTemplate } from './ImportCSVModal.styles';

const ImportCSVModal = ({ open, onClose, csvToArray, importData, loading, type }) => {
  const [importFile, setImportFile] = useState(null);

  const onImport = (file) => {
    setImportFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        csvToArray(text);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Import from CSV'
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='600px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'Upload',
          type: 'primary',
          title: 'Upload',
          onClick: importData,
          disabled: !importFile || loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>1</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2'>
            <InputLabel className='mb-0'>Download the CSV Template</InputLabel>
            <Typography as='div' variant='s2' style={{ color: palette.gray500 }}>
              First download the CSV template. Then fill out asked data in that template.
            </Typography>
            <SDownloadTemplate
              href={`${process.env.REACT_APP_BASE_URL}/uploads/csv/${templateLink[type]}.csv`}
              target='_blank'
            >
              <div className='download-template-content'>
                <DownloadIcon />
                <Typography variant='s2' style={{ color: palette.indigo500, textDecoration: 'none' }}>
                  Download CSV Template
                </Typography>
              </div>
            </SDownloadTemplate>
          </div>
        </div>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>2</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2 w-100'>
            <Uploader
              type={1}
              label='Upload filled CSV file here'
              document={importFile}
              accept={['text/csv']}
              onDrop={(files) => onImport(files[0])}
              onRemove={() => setImportFile(null)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportCSVModal;
