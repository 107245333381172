import React from 'react';
import Modal from 'common/Modal';
import NoteTable from 'components/NoteTab/NoteTab';
import { palette } from 'utils/constants';

const DriverNotes = ({ open, onClose, driver }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Driver Notes'
      $bgColor={palette.gray0}
      $maxWidth='1200px'
      $minWidth='1200px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      backdrop='static'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
        },
      ]}
    >
      <NoteTable modalView driverId={driver.id} />
    </Modal>
  );
};

export default DriverNotes;
