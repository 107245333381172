import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { getDocumentSettings, updateDocumentSettings } from 'Api/CompanySettings';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { addDocumentType, deleteDocumentType, documentType, updateDocumentType } from 'Api/Document';
import { SWrapper, SContentRow, SAddMore, SActions } from './Documents.styles';
import ItemForm from './ItemForm';
import { getInitialValues } from './Documents.data';

const Documents = ({ isConfiguringStep, onCancelPrevPage, onSaveAndNext }) => {
  const { value, setAuth } = useAuth();
  const showToaster = useShowToaster();
  const hasBrokerage = useHasBrokerage();
  const [loadingSave, setLoadingSave] = useState(false);
  const [settings, setSettings] = useState(null);
  const [forms, setForms] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const getDocumentTypes = async () => {
    try {
      const { data } = await documentType();
      setDocumentTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoadingSave(true);
    try {
      const body = {
        driver_uploaded_doc_verification_required: Number(values.driver_uploaded_doc_verification_required),
        carrier_uploaded_doc_verification_required: Number(values.carrier_uploaded_doc_verification_required),
        rename_bill_of_lading_to_proof_of_delivery: Number(values.rename_bill_of_lading_to_proof_of_delivery),
        require_approval_for_driver_paper_log: Number(values.require_approval_for_driver_paper_log),
      };
      await updateDocumentSettings(body);
      getDocumentTypes();
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
      setAuth({ ...value, shouldUpdateSettings: Date.now() }); // To trigger get settings on App.jsx
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleSubmit, setValues } = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
  });

  const getSettings = async () => {
    try {
      const { data } = await getDocumentSettings();
      setSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onUpdateDocumentType = async (field, checked, documentType) => {
    if (loadingUpdate) {
      return;
    }

    try {
      setLoadingUpdate(true);
      setDocumentTypes((prevState) =>
        prevState.map((item) => (item.id === documentType.id ? { ...item, [field]: !item[field] } : item))
      );
      await updateDocumentType({
        document_type_id: documentType.id,
        driver_visible: documentType.driver_visible,
        shipment_id_required: documentType.shipment_id_required,
        [field]: checked ? 1 : 0,
      });
      showToaster({ type: 'success', message: 'Document type has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      getDocumentTypes();
    } finally {
      setLoadingUpdate(false);
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewDocumentType = async (values, id) => {
    try {
      const data = {
        name: values?.name,
        driver_visible: Number(values.driver_visible),
        shipment_id_required: Number(values.shipment_id_required),
      };
      await addDocumentType(data);
      getDocumentTypes();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Document type has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteDocumentType(item.id);
      const newDocumentTypes = documentTypes.filter((el) => el.id !== item?.id);
      setDocumentTypes(newDocumentTypes);
      showToaster({ type: 'success', message: 'Document type has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  useEffect(() => {
    getSettings();
    getDocumentTypes();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Documents'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingSave}
      />
      <Divider margin='8px 0 36px' />
      <Typography variant='h2'>General</Typography>
      <Divider margin='8px 0 24px' />
      <div className='form-wrapper'>
        <div className='form-field-row'>
          <CustomCheckbox
            name='driver_uploaded_doc_verification_required'
            type='switch'
            smail={false}
            checked={values.driver_uploaded_doc_verification_required}
            onChange={(checked) =>
              setValues((prevState) => ({
                ...prevState,
                driver_uploaded_doc_verification_required: checked,
              }))
            }
          />
          <Typography variant='s2'>
            Require Approval on Document Inbox for Drivers before attaching to Shipment Documents
          </Typography>
        </div>
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='carrier_uploaded_doc_verification_required'
              type='switch'
              smail={false}
              checked={values.carrier_uploaded_doc_verification_required}
              onChange={(checked) =>
                setValues((prevState) => ({
                  ...prevState,
                  carrier_uploaded_doc_verification_required: checked,
                }))
              }
            />
            <Typography variant='s2'>
              Require Approval on Document Inbox for Carriers before attaching to Shipment Documents and Vendor Bills
            </Typography>
          </div>
        )}
        <div className='form-field-row'>
          <CustomCheckbox
            name='require_approval_for_driver_paper_log'
            type='switch'
            smail={false}
            checked={values.require_approval_for_driver_paper_log}
            onChange={(checked) =>
              setValues((prevState) => ({
                ...prevState,
                require_approval_for_driver_paper_log: checked,
              }))
            }
          />
          <Typography variant='s2'>Require Approval on Document Inbox for Driver Paper Logbook</Typography>
        </div>
        <div className='form-field-row'>
          <CustomCheckbox
            name='rename_bill_of_lading_to_proof_of_delivery'
            type='switch'
            smail={false}
            checked={values.rename_bill_of_lading_to_proof_of_delivery}
            onChange={(checked) =>
              setValues((prevState) => ({
                ...prevState,
                rename_bill_of_lading_to_proof_of_delivery: checked,
              }))
            }
          />
          <Typography variant='s2'>Rename Bill of Lading to Proof of Delivery</Typography>
        </div>
      </div>
      <Typography variant='h2'>Document Types</Typography>
      <Divider margin='8px 0 24px' />
      <div className='doc-types-table-header'>
        <Typography variant='c1' style={{ color: palette.gray700 }} className='table-header-item'>
          DOCUMENT TYPE
        </Typography>
        <Typography variant='c1' style={{ color: palette.gray700 }} className='table-header-item'>
          VISIBLE TO DRIVER APP
        </Typography>
        <Typography variant='c1' style={{ color: palette.gray700 }} className='table-header-item'>
          REQUIRE SHIPMENT ID
        </Typography>
        <div className='table-header-item' />
      </div>
      <Divider margin='8px 0' />
      {documentTypes?.map((item) => {
        return (
          <SContentRow key={item.id}>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {item.name}
              </Typography>
            </div>
            <div className='settings-table-item'>
              <div className='flex-item'>
                <CustomCheckbox
                  type='switch'
                  name='driver_visible'
                  checked={item.driver_visible}
                  onChange={(checked) => onUpdateDocumentType('driver_visible', checked, item)}
                  smail={false}
                  style={{ paddingLeft: '50px' }}
                />
              </div>
            </div>
            <div className='settings-table-item'>
              <div className='flex-item'>
                <CustomCheckbox
                  type='switch'
                  name='shipment_id_required'
                  checked={item.shipment_id_required}
                  onChange={(checked) => onUpdateDocumentType('shipment_id_required', checked, item)}
                  smail={false}
                  style={{ paddingLeft: '50px' }}
                />
              </div>
            </div>
            <SActions className='settings-table-item settings-table-actions-wrapper'>
              {!!item.is_delete && (
                <span className='action-wrapper' onClick={() => deleteItem(item)}>
                  <DeleteIcon width={11} height={11} fill={palette.red500} />
                </span>
              )}
            </SActions>
          </SContentRow>
        );
      })}
      {forms.map((item) => (
        <ItemForm
          key={item.id}
          onSubmit={(values) => createNewDocumentType(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          Add Another...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default Documents;
