export const driverAndEquipmentExpiryAction = [
  { name: 'Alert + Out of Service', value: 'Alert + Out of Service' },
  { name: 'Alert Only', value: 'Alert Only' },
  { name: 'Ignore', value: 'Ignore' },
];

export const staffAndOwnerOperatorEquipmentExpiryAction = [
  { name: 'Alert + Disabled', value: 'Alert + Disabled' },
  { name: 'Alert Only', value: 'Alert Only' },
  { name: 'Ignore', value: 'Ignore' },
];

export const customerEquipmentExpiryAction = [
  { name: 'Alert + Restrict Over Credit', value: 'Alert + Restrict Over Credit' },
  { name: 'Alert Only', value: 'Alert Only' },
  { name: 'Ignore', value: 'Ignore' },
];

export const customerExpiryAction = [
  { name: 'Alert + Restricted Collections', value: 'Alert + Restricted Collections' },
  { name: 'Alert Only', value: 'Alert Only' },
  { name: 'Ignore', value: 'Ignore' },
];

export const carrierEquipmentExpiryAction = [
  { name: 'Alert + Restricted', value: 'Alert + Restrict Over Credit' },
  { name: 'Alert Only', value: 'Alert Only' },
  { name: 'Ignore', value: 'Ignore' },
];

export const advanceNotice = ['Same Day', '30 Days', '60 Days', '90 Days'];
