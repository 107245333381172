import moment from 'moment';
import { formatNumber, palette } from 'utils/constants';

export const openReceivablesChartOptions = (currency) => ({
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 18,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index, values) {
          if (index === 0) {
            return moment().subtract(11, 'month').format('MMM YYYY');
          }
          if (index === values.length - 1) {
            return moment().format('MMM YYYY');
          }
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label} - ${currency}${formatNumber(item[0].raw)}`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});

export const readyMissingPaperworkInvoicesOptions = (currency) => ({
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 1.5,
  tension: 0.2,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label}`;
        },
        label(context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += `${currency}${formatNumber(context.parsed.y)}`;
          }
          return label;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      boxPadding: 3,
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index, values) {
          if (index === 0) {
            return moment().subtract(11, 'month').format('MMM YYYY');
          }
          if (index === values.length - 1) {
            return moment().format('MMM YYYY');
          }
        },
      },
    },
    y: {
      suggestedMax: 3000,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
        color: palette.gray50,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1000,
        callback(value) {
          if (value === 0) {
            return value;
          }
          return `${currency}${value / 1000}k`;
        },
      },
    },
  },
});

const paidInvoicesLabels = {
  0: '31-60 Days',
  1: '0-30 Days',
  2: '61-90 Days',
  3: '90+ Days',
};

export const paidInvoicesOptions = {
  cutout: '65%',
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  labels: {
    display: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

export const paidInvoicesPlugins = (currency) => [
  {
    id: 'hoverText',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      ctx.save();

      if (_active.length) {
        ctx.font = '700 24px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${currency}${formatNumber(_active[0]?.element?.$context?.raw)}`, width / 2, height / 2 - 10);

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${paidInvoicesLabels[_active[0]?.index]}`, width / 2, height / 2 + 16);
      }
    },
  },
  {
    id: 'textCenter',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      const total = chart.data.datasets[0].data?.reduce((acc, cur) => {
        return acc + cur;
      }, 0);

      if (_active.length === 0) {
        ctx.save();
        ctx.font = '700 24px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        if (total) {
          ctx.fillText(`${currency}${formatNumber(total)}`, width / 2, height / 2 - 10);
        }

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('Total', width / 2, height / 2 + 16);
      }
    },
  },
];

export const getPaidInvoicesChartData = (invoicesData) => {
  const data = [];
  const colors = [];
  const hoverColors = [];

  if (invoicesData?.thirtyOneToSixty?.amount) {
    data.push(invoicesData?.thirtyOneToSixty?.amount);
    colors.push(palette.indigo400);
    hoverColors.push(palette.indigo500);
  }

  if (invoicesData?.zeroToThirty?.amount) {
    data.push(invoicesData?.zeroToThirty?.amount);
    colors.push(palette.indigo200);
    hoverColors.push('#9EA5FF');
  }

  if (invoicesData?.sixtyOneToNinety?.amount) {
    data.push(invoicesData?.sixtyOneToNinety?.amount);
    colors.push('#F5BA0B');
    hoverColors.push('#E4B118');
  }

  if (invoicesData?.nintyPlus?.amount) {
    data.push(invoicesData?.nintyPlus?.amount);
    colors.push(palette.red400);
    hoverColors.push(palette.red500);
  }

  return {
    labels: [],
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: colors,
        hoverBackgroundColor: hoverColors,
        hoverBorderColor: hoverColors,
        borderWidth: 1,
      },
    ],
  };
};
