import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import Input from 'common/Input';
import useForm from 'hooks/useForm';
import { blockNotNumberChars } from 'utils/helpers';
import { activateModule } from 'Api/Stripe';
import { setupExternalAccount } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { initialValues } from './AddDepositWithdrawalSource.data';

const AddDepositWithdrawalSource = ({ onClose, module }) => {
  const showToaster = useShowToaster();
  const stripe = useStripe();

  const onSubmit = async (values) => {
    try {
      const { token } = await stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: values.routing_number,
        account_number: values.account_number,
        account_holder_name: values.account_holder_name,
      });

      await setupExternalAccount({ external_account: token.id });
      await activateModule({ module_name: module });
      showToaster({ type: 'success', message: 'Deposit/withdrawal source has been successfully added!' });
      onClose();
    } catch (e) {
      showToaster({
        type: 'error',
        message: getErrorMessage(e) || 'Provided information about bank account is wrong!',
      });
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors, isValid } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className='d-flex flex-column gap-4' style={{ width: '400px' }}>
      <div className='d-flex flex-column' style={{ marginTop: '32px', gap: '2px' }}>
        <Typography variant='h2'>Add Deposit & Withdrawal Source</Typography>
      </div>
      <Input
        required
        type='number'
        label='Routing Number'
        placeholder='000 000 000'
        name='routing_number'
        value={values.routing_number}
        onChange={handleChange}
        onKeyDown={blockNotNumberChars}
        error={touchedErrors.routing_number}
      />
      <Input
        required
        type='number'
        label='Account Number'
        placeholder='00000000000'
        name='account_number'
        value={values.account_number}
        onChange={handleChange}
        onKeyDown={blockNotNumberChars}
        error={touchedErrors.account_number}
      />
      <Input
        required
        label='Account Holder Name'
        name='account_holder_name'
        value={values.account_holder_name}
        onChange={handleChange}
        error={touchedErrors.account_holder_name}
      />
      <CustomButton
        type='primary'
        onClick={handleSubmit}
        title='Add Deposit & Withdrawal Source'
        styleButton={{ padding: '6px 8px', width: '100%', justifyContent: 'center' }}
        styleTitle={{ fontSize: 14 }}
        disabled={!isValid}
      />
    </div>
  );
};

export default AddDepositWithdrawalSource;
