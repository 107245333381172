import { createSlice } from '@reduxjs/toolkit';
import { STRIPE_MODULE } from 'utils/constants';

const initialState = {
  stripeOnboarding: null,
  stripeModuleData: {
    [STRIPE_MODULE.ACCOUNTS_RECEIVABLES]: null,
    [STRIPE_MODULE.ACCOUNTS_PAYABLE]: null,
    [STRIPE_MODULE.CARD_MANAGEMENT]: null,
    [STRIPE_MODULE.PAYROLL]: null,
  },
};
export const stripeSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    updateOnboarding: (state, action) => {
      state.stripeOnboarding = action.payload;
    },
    updateModuleData: (state, action) => {
      state.stripeModuleData = { ...state.stripeModuleData, [action.payload.key]: action.payload.data };
    },
  },
});

export const { updateOnboarding, updateModuleData } = stripeSlice.actions;

export default stripeSlice.reducer;
