import React from 'react';
import moment from 'moment';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import { formatPhoneNumber } from 'utils/helpers';

import Chip from 'common/Chip';
import Info from './Info';
import { photoIdentificationTypes } from '../ViewApplication.data';
import { SColumn, SInfo, SSection } from '../ViewApplicantModal.styles';

const DriverInfo = ({ data }) => {
  const { use } = useTheme();
  const {
    full_name,
    social_security_number,
    driving_licence,
    phone_no,
    email,
    date_of_birth,
    job_positing,
    emergency_contact_first_name,
    emergency_contact_last_name,
    emergency_contact_number,
    addresses,
  } = data || {};
  const { address1, city, state, country, zipcode } = addresses?.find((i) => i.current === 1) || {};

  const { formatDate } = useDateFormat();
  const driverLicenseExpired = moment().isAfter(
    job_positing?.applicant_type?.id === 1
      ? driving_licence?.driving_licence_expired
      : driving_licence?.photo_id_expiration
  );

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}>
        Applicant Info
      </Typography>
      <SSection>
        <SColumn>
          <Info label='Applicant Name' infoText={full_name} />
          <Info label='Social security' infoText={social_security_number} />
          {job_positing?.applicant_type?.id === 1 ? (
            <Info label='Driver License Type' infoText={driving_licence?.licence_type?.name} />
          ) : (
            <Info
              label='Photo Identification Type'
              infoText={photoIdentificationTypes[driving_licence?.photo_id_type]}
            />
          )}
          <Info label='Address' infoText={address1}>
            <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
              {city?.name}
              {city ? ',' : ''} {state?.name}
              {state ? ',' : ''} {country?.sortname || country?.name} {zipcode}
            </Typography>
          </Info>
        </SColumn>
        <SColumn>
          <SInfo>
            <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
              Phone Number
            </Typography>
            <Typography
              as='a'
              href={`tel:${phone_no}`}
              variant='b2'
              className='info-link'
              style={{ color: use(palette.indigo500, palette.indigo200) }}
            >
              {formatPhoneNumber(`+${phone_no}`)}
            </Typography>
          </SInfo>
          <Info
            label={
              job_positing?.applicant_type?.id === 1
                ? 'Driver License'
                : `${
                    photoIdentificationTypes[driving_licence?.photo_id_type] === 'ID'
                      ? ''
                      : photoIdentificationTypes[driving_licence?.photo_id_type] || ''
                  } ID`
            }
            infoText={
              driving_licence && `${driving_licence?.driving_licence_number || driving_licence?.photo_id_number}`
            }
          />
          <Info
            label={`${
              job_positing?.applicant_type?.id === 1
                ? 'Driver License'
                : photoIdentificationTypes[driving_licence?.photo_id_type] || 'ID'
            } Expiration`}
            infoText={
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {job_positing?.applicant_type?.id === 1
                    ? driving_licence?.driving_licence_expired
                      ? formatDate(driving_licence?.driving_licence_expired)
                      : 'N/A'
                    : driving_licence?.photo_id_expiration
                    ? formatDate(driving_licence?.photo_id_expiration)
                    : 'N/A'}
                </Typography>
                {(driving_licence?.driving_licence_expired || driving_licence?.photo_id_expiration) && (
                  <Chip
                    size='small'
                    label={driverLicenseExpired ? 'Expired' : 'Active'}
                    labelColor={driverLicenseExpired ? palette.red500 : palette.green500}
                    bgColor={driverLicenseExpired ? palette.red0 : palette.green0}
                  />
                )}
              </div>
            }
          />
          <Info
            label='Emergency Contact Name'
            infoText={
              (emergency_contact_first_name || emergency_contact_last_name) &&
              `${emergency_contact_first_name} ${emergency_contact_last_name}`
            }
          />
        </SColumn>
        <SColumn>
          <SInfo>
            <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
              Email
            </Typography>
            <Typography
              as='a'
              href={`mailto: ${email}`}
              variant='b2'
              className='info-link'
              style={{ color: use(palette.indigo500, palette.indigo200) }}
            >
              {email}
            </Typography>
          </SInfo>
          {job_positing?.applicant_type?.id === 1 && (
            <Info label='Driver License State' infoText={driving_licence?.driving_licence_state?.name} />
          )}
          <Info label='Date of Birth' infoText={formatDate(date_of_birth)} />
          <SInfo>
            <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
              Emergency Contact Phone Number
            </Typography>
            <Typography
              as='a'
              href={emergency_contact_number ? `tel:${emergency_contact_number}` : ''}
              variant='b2'
              className='info-link'
              style={{ color: use(palette.indigo500, palette.indigo200) }}
            >
              {emergency_contact_number ? formatPhoneNumber(`+${emergency_contact_number}`) : '-'}
            </Typography>
          </SInfo>
        </SColumn>
      </SSection>
    </div>
  );
};

export default DriverInfo;
