import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SUnderlineText } from 'pages/Accounting/Receivables/Receivables.styles';
import { SWrapper } from './PaymentMethodCard.styles';

const PaymentMethodCard = ({ cardData, isSelected, onClick, isFactor, disabled }) => {
  // const showToaster = useShowToaster();
  const navigate = useNavigate();
  // const { stripeOnboarding } = useSelector((state) => state.stripe);

  // const activateStripeModule = async () => {
  //   try {
  //     await activateModule({ module_name: STRIPE_MODULE.ACCOUNTS_RECEIVABLES });
  //     showToaster({ type: 'success', message: 'Online Payments from Customers are successfully enabled!' });
  //   } catch (e) {
  //     showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
  //   }
  // };

  const disabledDescription =
    cardData.value === 'online' ? (
      isFactor ? (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          Online payment is not available for factoring company!
        </Typography>
      ) : (
        <>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            This feature is not available at this time.
          </Typography>
          {/* <SUnderlineText */}
          {/*  className='underline-text' */}
          {/*  onClick={(e) => { */}
          {/*    e.stopPropagation(); */}
          {/*    if (stripeOnboarding) { */}
          {/*      activateStripeModule(); */}
          {/*    } else { */}
          {/*      navigate('/truckin-digital-cash-card/accounts_receivables'); */}
          {/*    } */}
          {/*  }} */}
          {/* > */}
          {/*  Enable Online Payments{' '} */}
          {/* </SUnderlineText> */}
          {/* <Typography variant='s2' style={{ color: palette.gray700 }}> */}
          {/*  to activate this option! */}
          {/* </Typography> */}
        </>
      )
    ) : (
      <>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          Please setup your
        </Typography>
        <SUnderlineText
          className='underline-text'
          onClick={(e) => {
            e.stopPropagation();
            navigate('/company-settings', { state: { tabId: 2, subTabName: 'FundsTransferInstructions' } });
          }}
        >
          Funds Transfer Instructions{' '}
        </SUnderlineText>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          to activate this option.
        </Typography>
      </>
    );

  return (
    <SWrapper onClick={disabled ? undefined : onClick} className={isSelected ? 'selected' : ''} $disabled={disabled}>
      <div className='card-content'>
        <Typography variant='h5' style={{ color: disabled ? palette.gray200 : palette.gray700 }}>
          {cardData.title}
        </Typography>
      </div>
      <Tooltip
        title={disabled ? disabledDescription : cardData.description}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: 13,
              fontFamily: 'Inter',
              color: palette.gray700,
              bgcolor: palette.white,
              border: '1px solid #dadde9',
            },
          },
        }}
      >
        <InfoIcon width={16} height={16} className='payment-info-icon' />
      </Tooltip>
    </SWrapper>
  );
};

export default PaymentMethodCard;
