import React from 'react';
import moment from 'moment';
import hexRgb from 'hex-rgb';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import Chip from 'common/Chip';
import CustomTooltip from 'common/Tooltip';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import OfferExpiration from 'pages/OfferedShipments/ShipmentDetails/components/OfferExpiration';
import useDateFormat from 'hooks/useDateFormat';
import { getShipmentType } from 'utils/helpers';
import { CURRENCY, formatNumber, palette, STOP_POINT_TAB } from 'utils/constants';
import { equipmentTypeText } from 'components/TableShipments/helpers/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import RowActions from './components/RowActions';
import { SGroup } from './ShipmentsTable.styles';

export const offerStatus = {
  award_accepted: { label: 'AWARD ACCEPTED', color: palette.green500, bgColor: palette.green0 },
  awarded: { label: 'AWARDED', color: palette.green500, bgColor: palette.green0 },
  bid_received: { label: 'BID RECEIVED', color: palette.orange500, bgColor: palette.orange0 },
  offered: { label: 'OFFERED', color: palette.gray500, bgColor: palette.gray0 },
  expired: { label: 'EXPIRED', color: palette.red500, bgColor: palette.red0 },
  awarded_and_dispatched: { label: 'AWARDED & DISPATCHED', color: palette.green500, bgColor: palette.green0 },
};

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  sort: { field: 'created_at', sortBy: 'desc' },
  tab: 0,
  shipment_id: '',
  reference_id: '',
  statuses: [],
  group: [],
  groups: [],
  stop_points: [],
  customers: [],
  equipmentTypes: [],
  origin_city: '',
  origin_state: '',
  origin_miles: '',
  origin_zipcode: '',
  destination_city: '',
  destination_state: '',
  destination_miles: '',
  destination_zipcode: '',
  ediStatus: [],
  offer_to_drivers: [],
  offer_to_carriers: [],
  pickupRange: [null, null],
  deliveryRange: [null, null],
};

export const settingsDataMapper = (key) => {
  const map = {
    customer: 'Customer',
    shipment_id: 'Shipment ID',
    reference: 'Reference',
    amount: 'Amount',
    posted_amount: 'Posted Amount',
    posted_date: 'Posted Date',
    age: 'Age',
    stops: 'Stops',
    equipment_type: 'Equipment Type',
    groups: 'Tags',
    status: 'Status',
    origin_address: 'Origin',
    destination_address: 'Destination',
    bids: 'Bids',
    offered: 'Offered',
    lowest_bid: 'Lowest Bid',
    margin: 'Margin',
    rpm: 'RPM',
    expiration: 'Expiration',
    offered_by: 'Offered By',
  };
  return map[key] ? map[key] : key;
};

export const useColumns = ({
  tab,
  shipments,
  handleSelectAll,
  selectedRows,
  handleSelectRow,
  sort,
  sortingQuery,
  onViewNotes,
}) => {
  const { formatDate, formatTime, convertToCustomerTime, dateFormat, timeFormat } = useDateFormat();
  const navigate = useNavigate();

  return [
    {
      field: '',
      title: shipments.length ? (
        <CustomCheckbox onChange={handleSelectAll} checked={shipments.length === selectedRows.length} />
      ) : (
        ''
      ),
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.shipment_id === row.shipment_id)}
          />
        </span>
      ),
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (row) => {
        const type = row?.shipment_type;

        return !!row.shipment_billing?.length && row.shipment_billing.length === 1 ? (
          <Typography
            variant='s3'
            style={{ color: palette.dark800 }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/customer-profile/${row.shipment_billing?.[0]?.billing_customer?.id}`);
            }}
          >
            {row.shipment_billing?.[0]?.billing_customer?.company_name}&nbsp;
            {type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
            {!!row.shipment_notes?.length && (
              <NoteIcon
                width={14}
                height={14}
                className='pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  onViewNotes(row);
                }}
              />
            )}
          </Typography>
        ) : row.shipment_billing && row.shipment_billing.length > 1 ? (
          <CustomTooltip
            arrow={false}
            title={
              <div>
                {row.shipment_billing.map((el, i) => (
                  <Typography
                    key={el?.id || i}
                    as='p'
                    variant='s2'
                    className='customerWrapper'
                    style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/customer-profile/${el?.billing_customer?.id}`);
                    }}
                  >
                    {el?.billing_customer?.company_name}
                  </Typography>
                ))}
              </div>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 13,
                  fontFamily: 'Inter',
                  color: palette.gray700,
                  bgcolor: palette.white,
                  border: '1px solid #dadde9',
                  marginTop: '4px !important',
                },
              },
            }}
          >
            <div>
              <Typography variant='s3' style={{ color: palette.dark800 }}>
                Multi-Customer LTL
              </Typography>
            </div>
          </CustomTooltip>
        ) : null;
      },
    },
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <div>
          <div key={row.id} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span className='tablePlaner_shipment' style={{ color: palette.dark800 }}>
              {row.shipment_id}
            </span>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <Typography variant='s3'>{getShipmentType(row.shipment_type)}</Typography>
          </div>
          <div>
            {(row.shipment_billing && row.shipment_type !== 'ltl') ||
            (row.shipment_type === 'ltl' && row.shipment_billing.length === 1) ? (
              <CustomTooltip title='Reference'>
                <span>
                  <Typography variant='s3' style={{ color: palette.dark800 }}>
                    {row.shipment_billing?.[0]?.reference_id}
                  </Typography>
                </span>
              </CustomTooltip>
            ) : (
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='button-tooltip-2'>
                    {row.shipment_billing?.map((el, i) => (
                      <p key={el?.id || i} style={{ margin: 0 }}>
                        {el?.reference_id}
                      </p>
                    ))}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div ref={ref} className='ellipsis'>
                    <Typography variant='s3' {...triggerHandler} style={{ color: palette.dark800 }}>
                      Multi-Ref LTL
                    </Typography>
                  </div>
                )}
              </OverlayTrigger>
            )}
          </div>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='AMOUNT' field='amount' />,
      field: 'amount',
      render: (row) => {
        let totalAmount = 0;

        row?.shipment_billing?.forEach((el) => {
          totalAmount += Number(el?.total_amount);
        });
        const currency = Object.values(CURRENCY).find(
          (item) => item.id === Number(row?.shipment_billing[0]?.currency_id)
        );

        return (
          <Typography variant='s3' style={{ color: palette.dark800 }}>
            {currency?.symbol || '$'}
            {formatNumber(totalAmount)}
          </Typography>
        );
      },
    },
    {
      title: <ColumnHeader title='POSTED AMOUNT' field='posted_amount' />,
      field: 'posted_amount',
      render: (row) => (
        <Typography variant='b2' className='text-center'>
          {row.posted_amount}
        </Typography>
      ),
    },
    {
      title: <ColumnHeader title={tab === 5 ? 'DISPATCHED DATE' : 'POSTED DATE'} field='posted_date' />,
      field: 'posted_date',
      render: (row) => (
        <div className='d-flex flex-column align-items-start'>
          {tab === 5 ? (
            <Typography variant='b2' className='text-center'>
              {row.shipment_offer_options?.posted_dat
                ? convertToCustomerTime(row.shipment_offer_options.posted_date)
                : '-'}
            </Typography>
          ) : (
            <>
              <Typography variant='b2' className='text-center'>
                {row.shipment_offer_options?.posted_date
                  ? convertToCustomerTime(row.shipment_offer_options.posted_date, timeFormat)
                  : ''}
              </Typography>
              <Typography variant='b2' className='text-center'>
                {row.shipment_offer_options?.posted_date
                  ? convertToCustomerTime(row.shipment_offer_options.posted_date, dateFormat)
                  : ''}
              </Typography>
            </>
          )}
        </div>
      ),
    },
    {
      title: <ColumnHeader title='AGE' field='age' sort={sort} onClick={sortingQuery} />,
      field: 'age',
      render: (row) => (
        <Typography variant='b2' className='text-center'>
          {row.shipment_offer_options?.posted_date
            ? `${moment.utc().diff(moment.utc(row.shipment_offer_options.posted_date), 'hours')}h ${
                moment.utc().diff(moment.utc(row.shipment_offer_options.posted_date), 'minutes') % 60
              }m`
            : '-'}
        </Typography>
      ),
    },
    {
      title: <ColumnHeader title='STOPS' field='stops' sort={sort} onClick={sortingQuery} />,
      field: 'stops',
      render: (row) => (
        <div>
          <Typography variant='b2' className='ms-2'>
            {row.shipment_stops?.length}
          </Typography>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='EQUIPMENT TYPE' field='equipment_type' />,
      field: 'equipment_type',
      render: (row) => {
        const typeLength = row.shipment_stops[0]?.equipment_type_length_value?.length;
        const equipmentType = row.shipment_stops[0]?.equipment_type_data?.title;
        const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;
        const firstNullArrivalDate = row.shipment_stops.find((stop) => !stop.arrival_date);

        return (
          <div className='d-flex flex-column'>
            <span style={{ color: palette.dark800 }}>{type === 'Truck' ? 'Truck (Power Only)' : type}</span>
            <Typography variant='s3' style={{ color: palette.dark800 }}>
              {!firstNullArrivalDate?.stop_point_type
                ? '-'
                : equipmentTypeText({
                    type: firstNullArrivalDate.stop_point_type,
                    action: firstNullArrivalDate.equipment_action,
                    equipment: firstNullArrivalDate.equipment,
                    equipment_tbd: firstNullArrivalDate.equipment_tbd,
                  })}
            </Typography>
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='TAGS' field='groups' />,
      field: 'groups',
      render: (rowData) => {
        const shipmentBilling = rowData.shipment_billing?.filter((item) => !!item.groups);
        return (
          <div className='d-flex flex-wrap gap-1'>
            {shipmentBilling?.map((item) => {
              const colorCode = hexRgb(item.groups.color);
              const textColor =
                colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? palette.white : palette.gray900;
              return (
                <SGroup $color={textColor} $bgColor={item?.groups?.color} key={item.id}>
                  {item?.groups?.short_name}
                </SGroup>
              );
            })}
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='STATUS' field='status' />,
      field: 'status',
      render: (row) => (
        <div className='d-flex gap-1'>
          <Chip
            label={offerStatus[row.offer_status]?.label}
            labelColor={offerStatus[row.offer_status]?.color}
            bgColor={offerStatus[row.offer_status]?.bgColor}
            fontSize='11px'
            fontWeight={500}
          />
          <CustomTooltip title='Bids Count'>
            <span>
              <Chip label={row.bids_count || 0} radius='12px' />
            </span>
          </CustomTooltip>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      field: 'origin_address',
      render: (row) => {
        const originStopName = row.shipment_stops[0]?.stop_point?.location_name;
        const city = row.shipment_stops[0]?.stop_point?.city?.name;
        const id = row.shipment_stops[0]?.stop_point?.id;
        const state = row.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = row.shipment_stops[0]?.stop_point?.zipcode;
        const scheduledOrigin = row.shipment_stops[0].scheduled_date;
        const scheduledOriginTo = row.shipment_stops[0].scheduled_date_to;
        const fromData = row.shipment_stops[0]?.from;
        const toData = row.shipment_stops[0]?.to;
        const typeId = row.shipment_stops[0]?.scheduled_type;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOriginTo} ${toData || ''}`;

        return (
          <div>
            <Typography
              variant='b3'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {originStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <div>
              <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
              <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
              {!!toData && [1, 3].includes(Number(typeId)) && (
                <>
                  <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                  <Typography variant='b2'>{formatTime(to)}</Typography>
                </>
              )}
              <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
            </div>
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      field: 'destination_address',
      render: (row) => {
        const destinationStopName = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.id;
        const typeId = row.shipment_stops?.[row.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = row.shipment_stops[row.shipment_stops.length - 1].scheduled_date;
        const scheduledDestinationTo = row.shipment_stops[row.shipment_stops.length - 1].scheduled_date_to;
        const fromData = row.shipment_stops[row.shipment_stops.length - 1]?.from;
        const toData = row.shipment_stops[row.shipment_stops.length - 1]?.to;

        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestinationTo} ${toData || ''}`;

        return (
          <div>
            <Typography
              variant='b3'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {destinationStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <div>
              <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
              <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
              {!!toData && [1, 3].includes(Number(typeId)) && (
                <>
                  <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                  <Typography variant='b2'>{formatTime(to)}</Typography>
                </>
              )}
              <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
            </div>
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='BIDS' field='bids' sort={sort} onClick={sortingQuery} />,
      field: 'bids',
      render: (row) => (
        <div>
          <Typography variant='b2' className='ms-2'>
            {row.bids_count || 0}
          </Typography>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='OFFERED' field='offered' />,
      field: 'offered',
      render: (row) => (
        <div>
          <Typography variant='b2' className='ms-3'>
            {row.offers_count || 0}
          </Typography>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='LOWEST BID' field='lowest_bid' sort={sort} onClick={sortingQuery} />,
      field: 'lowest_bid',
      render: (row) => (
        <Typography variant='b2' className='text-center'>
          {row.lowest_bid?.bid_amount ? `$${row.lowest_bid.bid_amount}` : '-'}
        </Typography>
      ),
    },
    {
      title: <ColumnHeader title='HIGHEST MARGIN' field='margin' />,
      field: 'margin',
      render: (row) => {
        const shipmentAmount = row?.shipment_billing?.reduce((acc, cur) => acc + Number(cur.total_amount), 0);
        const percent = row.lowest_bid?.bid_amount
          ? ((shipmentAmount - Number(row.lowest_bid.bid_amount || 0)) * 100) / shipmentAmount
          : null;
        return (
          <Typography
            variant='b2'
            style={{
              color: !percent || percent === 0 ? palette.gray700 : percent > 0 ? palette.green500 : palette.red500,
            }}
          >
            {percent !== null ? (
              <>
                {percent < 0 ? '-' : percent > 0 ? '+' : ''}
                {formatNumber(Math.abs(percent))}%
              </>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    {
      title: <ColumnHeader title='LOWEST RPM' field='rpm' />,
      field: 'rpm',
      render: (row) => (
        <Typography variant='b2' className='text-center'>
          {row.lowest_bid?.bid_amount
            ? `$${formatNumber(
                Number(row.lowest_bid.bid_amount || 0) / (row.loaded_miles + Number(row.lowest_bid?.empty_miles || 0))
              )}`
            : '-'}
        </Typography>
      ),
    },
    {
      title: <ColumnHeader title='EXPIRATION' field='expiration' />,
      field: 'expiration',
      render: (row) =>
        row.shipment_offer_options ? <OfferExpiration offerOptions={row.shipment_offer_options} /> : '-',
    },
    {
      title: <ColumnHeader title='OFFERED BY' field='offered_by' />,
      field: 'offered_by',
      render: (row) =>
        row.shipment_offer_options?.offered_by ? (
          <UserInfo
            data={{
              ...row.shipment_offer_options.offered_by,
              name: `${row.shipment_offer_options.offered_by.first_name} ${row.shipment_offer_options.offered_by.last_name}`,
              image: row.shipment_offer_options.offered_by.profile_logo,
              user_type: 'staff',
            }}
          />
        ) : (
          '-'
        ),
    },
  ];
};

export const actionsColumn = ({
  onRevertAll,
  onCancelShipment,
  onViewNotes,
  onRevertAndResend,
  onExtendExpiration,
}) => {
  return {
    field: 'actions',
    render: (row) => (
      <RowActions
        shipment={row}
        onRevertAndResend={() => onRevertAndResend(row)}
        onRevertAll={() => onRevertAll(row)}
        onCancelShipment={() => onCancelShipment(row)}
        onViewNotes={() => onViewNotes(row)}
        onExtendExpiration={() => onExtendExpiration(row)}
      />
    ),
  };
};
