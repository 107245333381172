import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Overlay } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import styles from 'components/TablePlaner/helpers/RecurrningDetails/steps/DocumentsRecurring/documentsRecurring.module.css';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { createCarrierDocuments, updateCarrierDocument } from 'Api/Carriers';
import { updateStaticDocumentConverter } from 'components/Carriers/CarrierProfile/DocumentsTab/DocumentsTab.data';
import AddDocumentModal from './AddDocumentModal';

const DocumentsItem = ({ doc, onClickBlock, onSubmitEdit, onDelete, carrierId }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const target = useRef(null);
  const [isOpenItemEdit, setIsOpenItemEdit] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  function onEdit(e) {
    e.stopPropagation();
    setIsOpenItemEdit(false);
    setIsOpenEditModal(true);
  }

  function onDeleteItem(e) {
    e.stopPropagation();
    typeof onDelete === 'function' && onDelete(e, doc);
  }

  const onSubmitDocEditModal = (values, validateForm, resetForm, submitForm, setFieldError) => {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0 && values.doc !== '';
      if (values.doc === '') return setFieldError('doc', 'Required');

      if (isValid) {
        if (doc.isStatic) {
          const formData = updateStaticDocumentConverter(values, doc, carrierId);

          createCarrierDocuments(formData).then(() => {
            typeof onSubmitEdit === 'function' && onSubmitEdit(values);
            resetForm();
            setIsOpenEditModal(false);
            showToaster({ type: 'success', message: 'Document updated successfully!' });
          });
          return;
        }

        const formData = new FormData();
        // if (values?.doc === doc?.path) delete values?.doc;
        formData.append('name', values.name);
        formData.append(`expiration_date`, values.no_expiry ? '' : moment(values.expire_date).format('YYYY/MM/DD'));
        formData.append('file', values.doc);

        updateCarrierDocument(formData, doc?.id).then((res) => {
          if (res?.success) {
            typeof onSubmitEdit === 'function' && onSubmitEdit(values);
            resetForm();
            setIsOpenEditModal(false);
            showToaster({ type: 'success', message: 'Document updated successfully!' });
          }
        });
      }
    });
  };

  const modalInitialValue = useMemo(() => {
    return {
      doc: doc?.path ? doc.path : null,
      name: doc?.name ? doc.name : '',
      expire_date: doc?.expiration_date ? moment(doc?.expiration_date).toDate() : null,
      no_expiry: !doc?.expiration_date,
      type: doc.type || null,
    };
  }, [doc]);

  return (
    <>
      <div
        onClick={(e) => onClickBlock(doc, e)}
        className={classNames(styles.filed_item, use(styles.light, styles.dark))}
      >
        <div className={styles.filed_name_item} style={{ width: '100%' }}>
          <Typography variant='s2' style={{ color: palette.indigo500 }}>
            {doc?.name}
          </Typography>
        </div>
        <div className={styles.filed_name_item}>
          <Typography variant='s2' style={{ color: palette.indigo500 }}>
            {doc?.expiration_date ? formatDate(doc?.expiration_date) : '-'}
          </Typography>
        </div>
        <div className={styles.filed_date_item}>
          <div
            ref={target}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenItemEdit(true);
            }}
          >
            <EditIcon style={{ transform: 'rotate(90deg)' }} />
          </div>
        </div>
        <Overlay
          placement='bottom'
          target={target.current}
          show={isOpenItemEdit}
          onHide={() => setIsOpenItemEdit(false)}
          rootCloseEvent='click'
          rootClose
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => {
            return (
              <div
                {...props}
                style={{ backgroundColor: use(palette.white, palette.dark800), ...props.style }}
                className={classNames(styles.popover_documents_item_edit, use(styles.light, styles.dark))}
              >
                <div onClick={onEdit} className={styles.popover_item}>
                  <Typography variant='s2'>Edit</Typography>
                </div>
                {doc.id && (
                  <div onClick={onDeleteItem} className={styles.popover_item}>
                    <Typography variant='s2' style={{ color: use(palette.red400, palette.red500) }}>
                      Delete
                    </Typography>
                  </div>
                )}
              </div>
            );
          }}
        </Overlay>
      </div>
      {isOpenEditModal && (
        <AddDocumentModal
          initialValueOutSide={modalInitialValue}
          isOpen={isOpenEditModal}
          setIsOpen={setIsOpenEditModal}
          onSubmit={onSubmitDocEditModal}
        />
      )}
    </>
  );
};

export default DocumentsItem;
