import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getDocType } from 'Api/Planner';
import DocsLTL from './DocsLTL';
import sm from '../../Documents/Documents.module.css';
import DocumentsFooter from '../../Documents/DocumentsFooter';
import { ShipmentContext } from '../../Layout';
import CustomizedSnackbars from '../../../toast/Toast';
import { getDocumentTemplate } from '../../ShipmentStops/helpers/constants';

const DocumentsLTL = () => {
  const { use } = useTheme();
  const [planAssign, setPlanAssign] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'error',
  });
  const {
    paymentTermData,
    setStep,
    billToData,
    createShipmentData,
    onCloseModal,
    updateSelectedTotalAmount,
    onCreate,
    docType,
    updateDocType,
    selectedCharges,
    documentsUploadFiles,
    updateDocumentsUploadFiles,
    docShipmentNumberData,
    updateDocShipmentNumberData,
    shipmentCreateLoading,
  } = useContext(ShipmentContext);
  const [errorsObj, setErrorsObj] = useState({});

  const getDocumentType = () => {
    getDocType({ shipment_id_required: 1 }).then((res) => {
      if (res && res?.data) {
        const selectData = res.data
          .filter((el) => !el.hidden)
          .map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.name,
              labelSelected: null,
            };
          });
        updateDocType(selectData);
      }
    });
  };

  function validation(values, form) {
    const mapErrorNames = ['documentName', 'documentReferenceId', 'file'];
    const { shipmentsDoc } = values;
    const errors = {};
    let isValid = false;
    let redirectFirstElement = 0;
    let foundedConfirmations = null;
    let bol = false;
    shipmentsDoc.forEach((docsObj, indexPrefix) => {
      if (!bol) foundedConfirmations = null;
      const { docs } = docsObj;
      errors[indexPrefix] = {};
      docs.forEach((doc, index) => {
        const file = documentsUploadFiles?.[indexPrefix]?.[index];
        const { documentName, documentReferenceId } = doc;
        const prevDocsErrors = errors?.[indexPrefix]?.docs ? errors?.[indexPrefix]?.docs : {};
        const requireConfirmations = paymentTermData[indexPrefix]?.require_confirmations;

        /** Confirmation * */
        if (+documentName !== 4 && foundedConfirmations === null && !bol && +requireConfirmations === 1) {
          foundedConfirmations = { ...doc, index: indexPrefix };
          bol = true;
        }
        if (+documentName === 4 && +requireConfirmations === 1) {
          foundedConfirmations = false;
        }

        if (!!documentName && !!documentReferenceId && !!file) {
          const cloneErrorObj = { ...errorsObj };
          delete cloneErrorObj?.[indexPrefix]?.docs?.[index];
        }

        errors[indexPrefix].docs = {
          ...errorsObj?.[indexPrefix]?.docs,
          ...prevDocsErrors,
        };

        const rowMap = { documentName, documentReferenceId, file };

        errors[indexPrefix].docs[index] = {};
        Object.keys(rowMap).forEach((key, idx) => {
          if (idx === 0) {
            errors[indexPrefix].docs[index] = { indexPrefix, index };
          }
          const value = rowMap[key];
          if (value) {
            delete errors[indexPrefix].docs[index][key];
          } else {
            if (redirectFirstElement === 0 && (!!file || !!documentName || !!documentReferenceId)) {
              // form.setFieldValue('documentNumberShipment', indexPrefix)
              redirectFirstElement++;
            }
            errors[indexPrefix].docs[index][key] = 'Required';
          }
        });

        if (!documentName && !documentReferenceId && !file) {
          delete errors[indexPrefix].docs[index];
        }
      });

      if (Object.keys(errors[indexPrefix]?.docs).length === 0) delete errors[indexPrefix];
    });

    outer_loop: for (let i = 0; i < Object.keys({ ...errors }).length; i++) {
      const { docs } = Object.values({ ...errors })[i];
      for (let i = 0; i < Object.keys({ ...docs }).length; i++) {
        const key = Object.keys({ ...docs })[i];
        const doc = { ...docs[key] };
        const docArr = Object.keys(doc);
        const founded = docArr.find((name) => mapErrorNames.includes(name));
        isValid = !founded;
        if (founded) {
          break outer_loop;
        }
      }
    }
    if (Object.keys(errors).length === 0) isValid = true;

    /** Confirmation * */
    if (isValid) {
      if (foundedConfirmations) {
        const message = `Confirmation document upload is required per customer terms(
                ${docShipmentNumberData[foundedConfirmations.index].label})!`;
        isValid = false;
        setShowMessage({
          ...showMessage,
          message,
          visible: true,
        });
        form.setFieldValue('documentNumberShipment', foundedConfirmations.index);
      } else {
        isValid = true;
      }
    }

    setErrorsObj(errors);
    return isValid;
  }

  function onClickPlanAssign(type, submitForm) {
    setPlanAssign(!planAssign);
    setTimeout(() => submitForm(), 0);
  }

  const onNext = (values, form) => {
    const isValid = validation(values, form);
    const lastStepData = { ...values };
    !!isValid && onCreate({ lastStepData, isLTL: true, planAssign });
  };

  const onDrop = (acceptedFiles, indexPrefix, index) => {
    updateDocumentsUploadFiles({
      ...documentsUploadFiles,
      [indexPrefix]: { ...documentsUploadFiles[indexPrefix], [index]: acceptedFiles[0] },
    });
    const cloneErrorsObj = { ...errorsObj };
    if (cloneErrorsObj?.[indexPrefix]?.docs?.[index]?.file) {
      delete cloneErrorsObj?.[indexPrefix]?.docs?.[index]?.file;
      setErrorsObj(cloneErrorsObj);
    }
  };

  const onDelete = (indexPrefix, index, rowDeleted = false) => {
    const cloneDocuments = { ...documentsUploadFiles };

    if (typeof cloneDocuments?.[indexPrefix] === 'object') {
      if (Object.keys(cloneDocuments?.[indexPrefix])?.length === 1) {
        delete cloneDocuments?.[indexPrefix];
        !!rowDeleted && updateDocumentsUploadFiles(cloneDocuments);
      } else {
        delete cloneDocuments?.[indexPrefix]?.[index];
      }
    }

    if (!rowDeleted) {
      updateDocumentsUploadFiles(cloneDocuments);
    } else if (typeof cloneDocuments?.[indexPrefix] === 'object' && !!rowDeleted) {
      const data = {};
      Object.keys(cloneDocuments).forEach((keyPrev) => {
        const docs = { ...cloneDocuments[keyPrev] };
        data[keyPrev] = {};
        Object.keys(docs).forEach((key) => {
          const value = docs[key];
          if (+key > index) {
            data[keyPrev][key - 1] = value;
          } else {
            data[keyPrev][key] = value;
          }
        });
      });
      updateDocumentsUploadFiles(data);
    }
  };

  const onDownload = (indexPrefix, index) => {
    const cloneDocuments = { ...documentsUploadFiles };
    const currentFile = cloneDocuments[indexPrefix][index];
    if (currentFile) {
      const link = document.createElement('a');
      const url = URL.createObjectURL(currentFile);
      link.setAttribute('download', currentFile.name);
      link.href = url;
      link.click();
    }
  };

  const documentsStyles = useMemo(() => {
    return {
      select: {
        height: 32,
        width: 400,
        borderRadius: '6px 0 0 6px',
        borderRight: `1px solid ${use(palette.gray200, palette.dark700)}`,
      },
      selectNumber: {
        height: 32,
        width: 400,
        borderRadius: 6,
        borderRight: `1px solid ${use(palette.gray200, palette.dark700)}`,
      },
      input: {
        width: 200,
        height: 32,
        borderRadius: '0 6px 6px 0',
        paddingLeft: 12,
      },
      chooseFile: {
        whiteSpace: 'nowrap',
        marginLeft: 8,
        color: use(palette.indigo500, palette.indigo500),
      },
      addADocument: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        width: 400,
        border: `1px dashed ${use(palette.gray200, palette.gray400)}`,
        cursor: 'copy',
      },
      addADocumentTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
    };
  }, [palette, use]);

  function onChangeDocumentSelect(indexPrefix, index) {
    const clone = errorsObj;
    if (clone?.[indexPrefix]?.docs?.[index]?.documentName) {
      clone[indexPrefix].docs[index].documentName = '';
      setErrorsObj(clone);
    }
  }

  function onChangeReferenceId(indexPrefix, index) {
    const clone = errorsObj;
    if (clone?.[indexPrefix]?.docs?.[index]?.documentReferenceId) {
      clone[indexPrefix].docs[index].documentReferenceId = '';
      setErrorsObj(clone);
    }
  }

  const initialValue = useMemo(() => {
    const { shipmentsBilling } = createShipmentData || {};

    const initVal = shipmentsBilling.map(() => {
      return { docs: [{ ...getDocumentTemplate() }] };
    });

    return { shipmentsDoc: initVal };
  }, [createShipmentData]);

  useEffect(() => {
    getDocumentType();
  }, []);

  useEffect(() => {
    const dataDocShipmentNumber = createShipmentData?.shipmentsBilling.map((el, index) => {
      const { reference_id, customer_id } = createShipmentData.shipmentsBilling[index];
      const customer = billToData.find((el) => +el.id === +customer_id);

      return {
        label: `${customer?.company_name}-${reference_id}`,
        key: index,
        labelSelected: null,
      };
    });
    updateDocShipmentNumberData(dataDocShipmentNumber);
  }, [createShipmentData?.shipmentsBilling]);

  useEffect(() => {
    const subTotal = {};
    Object.keys(selectedCharges).forEach((key) => {
      const charges = selectedCharges[key];
      subTotal[key] = 0;
      charges.forEach((charge) => {
        subTotal[key] = +subTotal[key] + Number(charge.qty) * Number(charge.rate);
      });
    });

    updateSelectedTotalAmount(subTotal);
  }, [selectedCharges]);
  return (
    <div className={sm.docsLTL}>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <Formik
        onSubmit={onNext}
        initialValues={{
          ...initialValue,
        }}
      >
        {({ values, submitForm, setFieldValue }) => {
          return (
            <Form>
              <FieldArray
                name='shipmentsDoc'
                render={() => {
                  return (
                    <div className={sm.documents_container}>
                      {values?.shipmentsDoc.map((docsObj, index) => {
                        const namePrefix = `shipmentsDoc[${index}]`;
                        const title = `${docShipmentNumberData?.[index]?.label}`;

                        return (
                          <DocsLTL
                            key={namePrefix}
                            title={title}
                            setFieldValue={setFieldValue}
                            setErrorsObj={setErrorsObj}
                            onDrop={onDrop}
                            values={values}
                            docType={docType}
                            docs={docsObj.docs}
                            onDelete={onDelete}
                            indexPrefix={index}
                            errorsObj={errorsObj}
                            onDownload={onDownload}
                            namePrefix={namePrefix}
                            documentsStyles={documentsStyles}
                            onChangeReferenceId={onChangeReferenceId}
                            documentsUploadFiles={documentsUploadFiles}
                            onChangeDocumentSelect={onChangeDocumentSelect}
                          />
                        );
                      })}
                    </div>
                  );
                }}
              />

              <DocumentsFooter
                setStep={setStep}
                submitForm={submitForm}
                onCloseModal={onCloseModal}
                onClickPlanAssign={onClickPlanAssign}
                shipmentCreateLoading={shipmentCreateLoading}
                updateDocumentsUploadFiles={updateDocumentsUploadFiles}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DocumentsLTL;
