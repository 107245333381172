import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Modal } from '@mui/material';
import './maintenancecost.css';
import { Toast } from 'react-bootstrap';

import ScheduleMaintenance from './MaintenanceTable/MaintainanceComponents/ScheduleMaintenance';
import Schedule from './MaintenanceTable/Schedule';
import AddRecordModal from './MaintenanceTable/MaintainanceComponents/AddRecordModal';
import RecordTable from './MaintenanceTable/Record';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 100px)',
  maxWidth: '1500px',
  minHeight: 340,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: 0,
  padding: '0px',
  borderRadius: '10px',
};
const AddRecordstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: 0,
  padding: '0px',
  borderRadius: '6px',
};

const MaintenanceCost = ({ profileData, handleMaintenanceCostClose, trailerID, equipmentMaintanance }) => {
  const [show, setShow] = useState(false);
  const [addRecord, setAddRecord] = useState(false);
  const [recordSuccess, setRecordSuccess] = useState();
  const [toastMessage, setToastMessage] = useState(false);
  const [toastMessageData, setToastMessageData] = useState();
  const [scheduleservice, setScheduleService] = useState(false);
  const [scheduleSuccess, setScheduleSuccess] = useState();

  const ScheduleServiceModalOpen = () => {
    setScheduleService(true);
  };
  const ToastClose = () => {
    setToastMessage(false);
  };
  const openToast = (message) => {
    setToastMessageData(message);
    setToastMessage(true);
    setTimeout(() => {
      ToastClose();
    }, 1000);
  };
  const getScheduleSuccess = (data) => {
    setScheduleSuccess(data);
  };

  const AddRecordModalOpen = () => {
    setAddRecord(true);
  };
  const AddRecordModalClose = () => {
    setAddRecord(false);
  };
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const getRecordSuccess = (data) => {
    setRecordSuccess(data);
  };
  return (
    <div>
      <Box sx={style}>
        <div className='maintenence-cost-header'>Maintenance</div>
        <div className=' maintenence-cost-modal-body pt-2'>
          <div className='maintenence-cost-upload'>
            <div className='maintenence-cost-button'>
              <p
                className={!show ? 'maintenence-cost-header-main' : 'maintenence-cost-header-sub ms-2 me-2 mt-1'}
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              >
                Records
              </p>
              <p
                className={show ? 'maintenence-cost-header-main' : 'maintenence-cost-header-sub ms-2 me-2 mt-1'}
                onClick={handleOpen}
                style={{ cursor: 'pointer' }}
              >
                Scheduled
              </p>
            </div>
            <div className='maintenence-cost-button'>
              {!show ? (
                <p className='maintenence-cost-header-add'>
                  <span style={{ cursor: 'pointer' }} onClick={AddRecordModalOpen}>
                    Add Record
                  </span>
                </p>
              ) : (
                <p className='maintenence-cost-header-add'>
                  <span style={{ cursor: 'pointer' }} onClick={ScheduleServiceModalOpen}>
                    Schedule Maintenance
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='ms-3 mb-3'>
          {!show ? (
            <RecordTable
              equipmentMaintanance={equipmentMaintanance}
              profileData={profileData}
              recordSuccess={recordSuccess}
              openToast={(message) => openToast(message)}
            />
          ) : (
            <Schedule
              profileData={profileData}
              openToast={(message) => openToast(message)}
              scheduleSuccess={scheduleSuccess}
              trailerID={trailerID}
            />
          )}
        </div>
        <div>
          <div className='maintenence-cost-button' style={{ float: 'right', marginRight: '10px' }}>
            <p className='maintenence-cost-header-close me-1'>
              <span
                className='ms-1 me-1'
                style={{
                  cursor: 'pointer',
                  paddingTop: '15px',
                }}
                onClick={handleMaintenanceCostClose}
              >
                Close
              </span>
            </p>
          </div>
        </div>
      </Box>
      <Modal disableEnforceFocus open={addRecord} style={{ zIndex: 1055 }} onClose={AddRecordModalClose}>
        <Box sx={AddRecordstyle}>
          <AddRecordModal
            equipmentMaintanance={equipmentMaintanance}
            AddRecordModalClose={AddRecordModalClose}
            profileData={profileData}
            getRecordSuccess={getRecordSuccess}
          />
        </Box>
      </Modal>
      {scheduleservice && (
        <ScheduleMaintenance
          open={scheduleservice}
          onClose={() => setScheduleService(false)}
          equipment={profileData}
          equipmentType={trailerID}
          onSuccess={getScheduleSuccess}
        />
      )}
      <Toast show={toastMessage} onClose={ToastClose} className='equipment-success-msg'>
        <div className='toastMessage'>{toastMessageData}</div>
      </Toast>
    </div>
  );
};

export default MaintenanceCost;
