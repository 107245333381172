import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { formatPhoneNumber } from 'utils/helpers';
import Requirement from '../../components/Requirement';
import { SWrapper } from './Intro.styles';
import { SRequirementsWrapper } from '../Steps.styles';
import InfoBox from '../../components/InfoBox';
import { infoOptions } from './Intro.data';
import IntroCheckboxes from '../../components/IntroCheckboxes';

const Intro = ({ company, jobName, requirements, introCheckboxes, handleChange, introOptions }) => {
  const { company_name, phone_number } = company || {};

  return (
    <SWrapper>
      <Typography variant='b1' className='mb-6'>
        Thank you for your interest in {company_name}. To apply for {jobName} position, please complete our online
        application for employment. Incomplete information will delay the processing of your application or prevent it
        from being submitted.
      </Typography>
      <Typography variant='b1'>
        In compliance with Federal and State equal employment opportunity laws, qualified applicants are considered for
        all positions without regard to race, color, religion, sex, national origin, age, marital status, veteran
        status, non-job related disability, or any other protected group status.
      </Typography>
      <SRequirementsWrapper>
        <Typography variant='s1'>To fill out this form, you will need to know the following:</Typography>
        {requirements?.map((item) => (
          <Requirement text={item.title} key={item.id} />
        ))}
      </SRequirementsWrapper>
      <Typography variant='b1'>
        Required entry fields are followed by <span style={{ color: palette.red500 }}>*</span>, meaning you must provide
        the requested information to continue. If you encounter any errors during this process and cannot continue,
        please contact us at{' '}
        <span style={{ fontWeight: 500 }}>{phone_number ? formatPhoneNumber(phone_number) : ''}</span>.
      </Typography>
      {introOptions.map(({ title, content }, index) => (
        <InfoBox
          key={title}
          title={title}
          checked={!!introCheckboxes[index]}
          content={content}
          values={introCheckboxes}
          handleCheckboxChange={(checked) => handleChange(index, checked)}
        />
      ))}
      <IntroCheckboxes company={company} values={introCheckboxes} handleChange={handleChange} />
    </SWrapper>
  );
};

export default Intro;
export { infoOptions };
