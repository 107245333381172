import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Modal from 'common/Modal';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createCarrierContactBook, getCarrierContactBook, updateCarrierContactBook } from 'Api/Carriers';
import { getContactBookInitialValues } from 'pages/OnboardCarrier/OnboardCarrier.data';
import { contactBookValidationSchema } from 'pages/OnboardCarrier/validationSchemas';
import ContactBookForm from './ContactBookForm';

const ContactBook = ({ open, onClose }) => {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('carrier');
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [contactBook, setContactBook] = useState(null);

  const getContactBook = async () => {
    try {
      const { data } = await getCarrierContactBook({ id: carrierId });
      setContactBook(data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleContactBookSubmit = async (values) => {
    setLoading(true);
    try {
      const contactsToCreate = values.filter((i) => !!i.isNew);
      const contactsToEdit = values.filter((i) => !i.isNew);
      const contacts = contactsToCreate.map((item) => {
        return {
          contact_name: item.contact_name,
          phone_number: item.phone_number,
          contact_type_id: item.contact_type_id?.id,
          contact_email: item.contact_email,
          portal_access: Number(item.portal_access),
          receive_offers: Number(item.receive_offers),
          check_calls: Number(item.check_calls),
        };
      });

      const data = {
        carrier_id: carrierId,
        contact_books: contacts,
      };

      if (contactsToCreate.length) {
        await createCarrierContactBook(data);
      }
      if (contactsToEdit.length) {
        const promises = contactsToEdit.map((item) => {
          const body = {
            contact_name: item.contact_name,
            phone_number: item.phone_number,
            contact_type_id: item.contact_type_id?.id,
            contact_email: item.contact_email,
            portal_access: Number(item.portal_access),
            receive_offers: Number(item.receive_offers),
            check_calls: Number(item.check_calls),
          };
          return updateCarrierContactBook(body, item.id);
        });
        await Promise.all(promises);
      }
      onClose();
    } catch (e) {
      setLoading(false);
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  useEffect(() => {
    getContactBook();
  }, []);

  const contactBookForm = useForm({
    initialValues: getContactBookInitialValues(contactBook),
    validationSchema: contactBookValidationSchema,
    onSubmit: handleContactBookSubmit,
    enableReinitialize: true,
  });

  const onUpdate = async () => {
    await contactBookForm.handleSubmit();
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<>Contact Book</>}
      $bgColor={palette.gray0}
      $maxWidth='90vw'
      $minWidth='90vw'
      $minHeight='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
          onClick: () => onUpdate(),
          loading,
          disabled: loading,
        },
      ]}
    >
      <ContactBookForm form={contactBookForm} />
    </Modal>
  );
};

export default ContactBook;
