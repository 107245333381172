import React from 'react';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { formatNumber } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';

export const useColumns = ({ sort, sortingQuery }) => {
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'date',
      title: <ColumnHeader title='DATE' field='date' sort={sort} onClick={sortingQuery} />,
      render: (row) => <Typography variant='b2'>{convertToCustomerTime(row.date)}</Typography>,
    },
    {
      field: 'amount',
      title: <ColumnHeader title='AMOUNT' />,
      render: (row) => <Typography variant='b2'>${formatNumber(row.amount)}</Typography>,
    },
    {
      field: 'fee',
      title: <ColumnHeader title='FEE' />,
      render: (row) => <Typography variant='b2'>${formatNumber(row.fee)}</Typography>,
    },
    {
      field: 'to_account',
      title: <ColumnHeader title='TO ACCOUNT' />,
      render: (row) => <Typography variant='b2'>{row.to_account || '-'}</Typography>,
    },
    {
      field: 'from_account',
      title: <ColumnHeader title='FROM ACCOUNT' />,
      render: (row) => <Typography variant='b2'>{row.from_account || '-'}</Typography>,
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' />,
      render: (row) => <Chip label={row.status_id} />,
    },
    {
      field: 'transaction_id',
      title: <ColumnHeader title='TRANSACTION ID' />,
      render: (row) => <Typography variant='b2'>{row.transaction_id}</Typography>,
    },
  ];
};
