import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${palette.gray50};
  border-radius: 6px;
`;

export const SHeader = styled.div`
  display: flex;
  gap: 16px;

  .header-title {
    margin-right: 36px;
  }

  .search-input {
    padding-left: 30px !important;
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;
    margin-top: 12px;

    .MuiTabs-flexContainer {
      column-gap: 8px;
      overflow-x: scroll;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      padding: 3px 10px;
      text-transform: none;
      color: ${palette.gray900};
      border-bottom: 2px solid ${palette.white};

      &.Mui-selected {
        border-bottom: 2px solid ${palette.indigo500};
        color: ${palette.indigo500};

        :hover {
          border-bottom: 2px solid ${palette.indigo500};
        }
      }

      .MuiTab-iconWrapper {
        font-family: 'Inter', sans-serif;
        border-radius: 8px;
        padding: 2px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        background-color: #f0f1fa;
      }
    }

    .MuiButtonBase-root:hover {
      border-bottom: 2px solid ${palette.gray400};
    }
  }
`;

export const SUnderlineText = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  color: #4f5aed;

  :hover {
    text-decoration: underline;
  }
`;
