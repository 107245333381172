import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import Autocomplete from 'common/Autocomplete';
import { getAllVehicles } from 'Api/Map';
import { getAssignToGroupBillingCharges } from 'Api/Planner';
import { LicenseType } from 'Api/Driver';
import classes from './AdvancedFilter.module.scss';
import { APPROVED_FOR, CAN_TRAVEL_TO, STATUSES, COMPENSATION_TYPES, DUTY_STATUSES, DRIVER_TYPE } from '../constat';

const AdvancedFilter = ({ open, setSearch, onSearch, filterType, filter, setFilter, children }) => {
  const [groupsData, setGroupsData] = useState([]);
  const [badgeData, setBadgeData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [licenseTypeLoading, setLicenseTypeLoading] = useState(false);

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name:
        nameKey === 'equipment_id' ? `${item.equipment_id} ${item.equipment_type?.title || ''}` : item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(filter?.status_id
        ? [
            {
              title: 'Status',
              children: filter.status_id.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'status_id' })),
            },
          ]
        : []),
      ...(filter?.group_id
        ? [
            {
              title: 'Tag',
              children: filter.group_id.map((item) =>
                normalizeBadgeData(item, 'group_name', 'id', { key: 'group_id' })
              ),
            },
          ]
        : []),
      ...(filter?.canTravelIds
        ? [
            {
              title: 'Can Travel To',
              children: filter.canTravelIds.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'canTravelIds' })
              ),
            },
          ]
        : []),
      ...(filter?.approvedForId
        ? [
            {
              title: 'Approved For',
              children: filter.approvedForId.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'approvedForId' })
              ),
            },
          ]
        : []),
      ...(filter?.equipment_id
        ? [
            {
              title: 'Equipment',
              children: filter.equipment_id.map((item) =>
                normalizeBadgeData(item, 'equipment_id', 'id', { key: 'equipment_id' })
              ),
            },
          ]
        : []),
      ...(filter?.license_type
        ? [
            {
              title: 'License Type',
              children: filter.license_type.map((item) =>
                normalizeBadgeData(item, 'name', 'id', { key: 'license_type' })
              ),
            },
          ]
        : []),
      ...(filter?.driver_compensation
        ? [
            {
              title: 'Compensation Type',
              children: filter.driver_compensation.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'driver_compensation' })
              ),
            },
          ]
        : []),
      ...(filter?.driver_duty_status
        ? [
            {
              title: 'Duty Status',
              children: filter.driver_duty_status.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'driver_duty_status' })
              ),
            },
          ]
        : []),
      ...(filter?.driver_type
        ? [
            {
              title: 'Type',
              children: filter.driver_type.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'driver_type' })
              ),
            },
          ]
        : []),
    ];
  }, []);

  const onSearchFilter = () => {
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
      } else {
        newFilter[key] = newItemValue;
      }
    } else if (typeof itemValue === 'string') {
      delete newFilter[key];
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));

    // let options = [];
    //
    //
    // if (key === 'status_id') {
    //   options = statuses.filter((el) => el.id !== id);
    //   setStatuses(options);
    // }
    // if (key === 'group_id') {
    //   options = groups.filter((el) => el.id !== id);
    //   setGroups(options);
    // }
    // if (key === 'canTravelIds') {
    //   options = canTravels.filter((el) => el.id !== id);
    //   setCanTravels(options);
    // }
    // if (key === 'approvedForId') {
    //   options = approvedOptions.filter((el) => el.id !== id);
    //   setApprovedOptions(options);
    // }
    // onApply(key, options);
  };

  const handleClearAll = () => {
    setSearch('');
    onSearch({});
    setFilter({});
    setBadgeData([]);
  };

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }
    return setFilter(newFilter);
  };

  const getFilterByGroups = async () => {
    try {
      const { data } = await getAssignToGroupBillingCharges();
      data && setGroupsData(data);
    } catch (e) {
      // Do noting
    }
  };

  const getVehiclesList = async () => {
    try {
      setVehicleLoading(true);
      const { data } = await getAllVehicles(null, null, null, null);
      setVehicleData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setVehicleLoading(false);
    }
  };

  const getLicenseTypes = async () => {
    try {
      setLicenseTypeLoading(true);
      const { data } = await LicenseType();
      setLicenseTypes(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLicenseTypeLoading(false);
    }
  };

  useEffect(() => {
    getFilterByGroups().catch();
    getVehiclesList();
    getLicenseTypes();
  }, []);

  return (
    <>
      {children?.({ onSearchFilter })}
      <Collapse in={open}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.status_id || []}
                options={STATUSES.filter((item) =>
                  filterType === 'active' ? ![5, 6].includes(item.id) : [5, 6].includes(item.id)
                )}
                placeholder='Status'
                onChange={(e, value) => onChangeMultiSelectValues('status_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.group_id || []}
                options={groupsData}
                placeholder='Tags'
                onChange={(e, value) => onChangeMultiSelectValues('group_id', value)}
                getTagLabel={(option) => (option ? option?.group_name : '')}
                getOptionLabel={(option) => (option ? option?.group_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.canTravelIds || []}
                options={CAN_TRAVEL_TO}
                placeholder='Can Travel To'
                onChange={(e, value) => onChangeMultiSelectValues('canTravelIds', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={APPROVED_FOR}
                value={filter?.approvedForId || []}
                placeholder='Approved For'
                onChange={(e, value) => onChangeMultiSelectValues('approvedForId', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                limitTags={2}
                loading={vehicleLoading}
                placeholder={filter?.equipment_id?.length ? '' : 'Equipment'}
                options={vehicleData}
                value={filter?.equipment_id || []}
                getOptionLabel={(option) => {
                  return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                }}
                getTagLabel={(option) => {
                  return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                }}
                onChange={(e, value) => onChangeMultiSelectValues('equipment_id', value)}
                isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                limitTags={2}
                loading={licenseTypeLoading}
                placeholder={filter?.license_type?.length ? '' : 'License Type'}
                options={licenseTypes}
                value={filter?.license_type || []}
                getTagLabel={(option) => (option ? option?.name : '')}
                getOptionLabel={(option) => (option ? option?.name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                onChange={(e, value) => onChangeMultiSelectValues('license_type', value)}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={COMPENSATION_TYPES}
                value={filter?.driver_compensation || []}
                placeholder={filter?.driver_compensation?.length ? '' : 'Compensation Type'}
                onChange={(e, value) => onChangeMultiSelectValues('driver_compensation', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={DUTY_STATUSES}
                value={filter?.driver_duty_status || []}
                placeholder={filter?.driver_duty_status?.length ? '' : 'Duty Status'}
                onChange={(e, value) => onChangeMultiSelectValues('driver_duty_status', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={DRIVER_TYPE}
                value={filter?.driver_type || []}
                placeholder={filter?.driver_type?.length ? '' : 'Type'}
                onChange={(e, value) => onChangeMultiSelectValues('driver_type', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>
          </div>

          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AdvancedFilter;
