import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import dingSound from 'assets/ding.mp3';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Tooltip from 'common/Tooltip';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import { createAccountTransaction, updateAccountTransaction } from 'Api/Accounts';
import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import { getAccountToUpdate } from 'pages/Accounting/Accounts/helpers';
import PayeeAutocomplete from 'pages/Accounting/Accounts/components/PayeeAutocomplete';
import { STableData, SActions } from 'pages/Accounting/Accounts/RightSection/RightSection.styles';
import { showBalanceFor } from 'pages/Accounting/Accounts/Accounts.data';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './AccountFormItem.data';

const AccountFormItem = ({
  onDelete,
  account,
  accounts,
  payees,
  onCreateSuccess,
  transaction,
  getPayees,
  onUpdateSuccess,
  form,
  firstInFuture,
  onRowClick = () => null,
  onClickRowItem,
}) => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [disabledInput, setDisabledInput] = useState(transaction?.debit ? 'credit' : 'debit');
  const [addPayeeOpen, setAddPayeeOpen] = useState(false);
  const debitRef = useRef(null);
  const creditRef = useRef(null);
  const accountOptions = useMemo(() => accounts.filter((i) => i.id !== account.id), [accounts, account]);

  const playAudio = () => {
    try {
      const ding = new Audio(dingSound);
      ding.play();
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        account_id: account.id,
        date: moment(values.date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        reference_id: values.reference_id || null,
        payee_id: values.payee?.id || 0,
        payee_type: values.payee?.type || null,
        account: values.account?.id || 0,
        memo: values.memo,
        debit: Number(values.debit),
        credit: Number(values.credit),
      };
      await createAccountTransaction(body);
      showToaster({ type: 'success', message: 'Transaction has been successfully created!' });
      playAudio();
      onCreateSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: getInitialValues(transaction, form),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const onFieldChange = (field, value, shouldGetPayees) => {
    handleChange(field, value);

    if (field === 'payee' && value.type === 'vendor' && (value.account || value.account_id)) {
      const account = value.account || accounts.find((acc) => Number(acc.id) === value.account_id);
      handleChange('account', account);
    }

    if (!value || !transaction) {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const accountToUpdate = getAccountToUpdate(field, value, transaction);

    const body = {
      account_id: account.id,
      date: field === 'date' ? moment(value).format('YYYY-MM-DD HH:mm:ss') : transaction.date,
      reference_id: transaction.reference_id,
      payee_id: field === 'payee' ? value.id : transaction.payee?.id || 0,
      payee_type: field === 'payee' ? value.type : transaction.payee?.type || null,
      account: accountToUpdate,
      memo: field === 'memo' ? value : transaction.memo,
      debit: field === 'debit' ? Number(value) : disabledInput === 'debit' ? 0 : transaction.debit,
      credit: field === 'credit' ? Number(value) : disabledInput === 'credit' ? 0 : transaction.credit,
    };

    const id = setTimeout(async () => {
      try {
        const { data } = await updateAccountTransaction(transaction.id, body);
        onUpdateSuccess(data, shouldGetPayees);
        showToaster({ type: 'success', message: 'Successfully updated!' });
      } catch (e) {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      }
    }, 250);
    setTimeoutId(id);
  };

  const onAddNewPayeeSuccess = (payee) => {
    onFieldChange('payee', payee, true);
    if (!transaction) {
      getPayees();
    }
  };

  const onAmountInputClick = (e, type) => {
    e.stopPropagation();
    setDisabledInput(type === 'debit' ? 'credit' : 'debit');
    setTimeout(() => {
      if (type === 'debit') {
        debitRef.current.focus();
      } else {
        creditRef.current.focus();
      }
    });
  };

  const onAmountKeyDown = (e) => {
    blockNonPositiveNumbers(e);
    if (e.key === 'Enter' && !transaction) {
      handleSubmit();
    }
  };

  return (
    <tr className={firstInFuture ? 'blue-border clickable' : 'clickable'} onClick={onRowClick}>
      <td>
        <DatePicker
          size='small'
          name='date'
          value={values.date}
          dateFormat='MM/dd/yyyy'
          onBlur={handleBlur}
          onChange={(val) => onFieldChange('date', val)}
          disabled={!!transaction}
          error={touchedErrors.date}
        />
      </td>
      <td>
        {transaction ? (
          transaction.pdf_check_file_link ? (
            <Typography
              variant='b2'
              style={{ color: palette.indigo500, textDecoration: 'underline', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                onClickRowItem({ field: 'reference_id', row: transaction });
              }}
            >
              *****{transaction.reference_id?.slice(-5)}
            </Typography>
          ) : (
            <Tooltip title={transaction.reference_id}>
              <span style={{ color: palette.gray700 }}>*****{transaction.reference_id?.slice(-5)}</span>
            </Tooltip>
          )
        ) : (
          <Input
            width='200px'
            size='small'
            name='reference_id'
            placeholder='ABC'
            value={values.reference_id}
            onChange={(e) => onFieldChange('reference_id', e.target.value)}
            onClick={(e) => e.stopPropagation()}
            error={touchedErrors.reference_id}
          />
        )}
      </td>
      <STableData $width='320px'>
        <div onClick={(e) => e.stopPropagation()}>
          <PayeeAutocomplete
            options={payees}
            value={values.payee}
            onChange={(e, val) => onFieldChange('payee', val)}
            onAddNew={setAddPayeeOpen}
            error={touchedErrors.payee}
            disabled={!!transaction}
          />
        </div>
      </STableData>
      <STableData $width='320px'>
        <div>
          {!transaction?.account && transaction?.bill?.is_split ? (
            <Typography className='split-input' variant='s2' onClick={onRowClick}>
              SPLIT
            </Typography>
          ) : (
            <Autocomplete
              size='small'
              name='account'
              placeholder='Select Account'
              labelKey='account_name'
              options={accountOptions}
              value={values.account}
              onChange={(e, val) => onFieldChange('account', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.account}
              disabled={!!transaction}
            />
          )}
        </div>
      </STableData>
      <STableData>
        <Input
          width='250px'
          size='small'
          name='memo'
          placeholder='Memo'
          value={values.memo}
          onChange={(e) => onFieldChange('memo', e.target.value)}
          onClick={(e) => e.stopPropagation()}
          error={touchedErrors.memo}
        />
      </STableData>
      <STableData>
        <InputWithIcon
          width='170px'
          ref={debitRef}
          size='small'
          type='number'
          name='debit'
          placeholder='0.00'
          value={values.debit}
          onChange={(e) => onFieldChange('debit', e.target.value)}
          onKeyDown={onAmountKeyDown}
          disabled={!!transaction}
          onClick={(e) => onAmountInputClick(e, 'debit')}
          error={touchedErrors.debit}
          className={disabledInput === 'debit' ? 'amount-disabled' : ''}
          wrapperStyle={{ backgroundColor: disabledInput === 'debit' ? palette.gray0 : '' }}
          tabIndex={disabledInput === 'debit' ? -1 : undefined}
        />
      </STableData>
      <STableData>
        <InputWithIcon
          width='170px'
          ref={creditRef}
          size='small'
          type='number'
          name='credit'
          placeholder='0.00'
          value={values.credit}
          onChange={(e) => onFieldChange('credit', e.target.value)}
          onKeyDown={onAmountKeyDown}
          disabled={!!transaction}
          onClick={(e) => onAmountInputClick(e, 'credit')}
          error={touchedErrors.credit}
          className={disabledInput === 'credit' ? 'amount-disabled' : ''}
          wrapperStyle={{ backgroundColor: disabledInput === 'credit' ? palette.gray0 : '' }}
          tabIndex={disabledInput === 'credit' ? -1 : undefined}
        />
      </STableData>
      {showBalanceFor.includes(account.account_type?.id) && (
        <td>
          <Typography variant='s3' style={{ color: palette.gray700 }}>
            {transaction?.balance < 0 && '-'}
            {currency}
            {formatNumber(Math.abs(Number(transaction?.balance || 0)))}
          </Typography>
        </td>
      )}
      <STableData $width='100px'>
        <SActions>
          {loading ? (
            <span className='loading-wrapper'>
              <CircularProgress size={16} />
            </span>
          ) : (
            <>
              {!transaction && (
                <span className='action-wrapper' onClick={() => handleSubmit()}>
                  <CheckIcon width={11} height={11} />
                </span>
              )}
              <span
                className='action-wrapper'
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <DeleteIcon width={11} height={11} fill={palette.red500} />
              </span>
            </>
          )}
        </SActions>
      </STableData>
      {!!addPayeeOpen && (
        <AddPayee
          open={!!addPayeeOpen}
          name={addPayeeOpen}
          onClose={() => setAddPayeeOpen(false)}
          onSuccess={onAddNewPayeeSuccess}
        />
      )}
    </tr>
  );
};

export default AccountFormItem;
