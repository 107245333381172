import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import CustomMultiSelect from 'common/CustomMultiSelect/CustomMultiSelect';

export default function EditGroupModal({ groupData, updateGroup, groups, ...props }) {
  const { use } = useTheme();
  const formik = useFormik({
    initialValues: {
      group_id: [],
    },
  });

  const editaddress = () => {
    const payload = { index: groupData?.index, value: formik.values };
    updateGroup(payload, props.onHide);
  };

  useEffect(() => {
    if (groupData?.selected_Group?.length) {
      formik?.setFieldValue('group_id', groupData?.selected_Group || []);
    }
  }, []);

  return (
    <div className='modal-container'>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Edit Tags
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.white, palette.darkborder),
          }}
        >
          <div className='content-wrapper-date'>
            <p className='text-style' style={{ color: use(palette.gray700, palette.gray50), marginLeft: '0' }}>
              Tags
            </p>
            <CustomMultiSelect
              placeholder='Select tags'
              selectedValues={formik?.values?.group_id || []}
              formik={formik}
              patchProperty='group_id'
              propertyName='group_name'
              options={groups}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>

            <Button className='next-step' type='submit' onClick={() => editaddress()}>
              Edit Tags
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
