import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { formatNumber, palette } from 'utils/constants';
import { SFlexRow, SWrapper } from './SettlementCard.styles';

const SettlementCard = ({ settlement, onClick, className, selected, handleSelect }) => {
  const { currency } = useSelector((state) => state.root);
  const { pay_period_end, amount, gross_amount, time_tracked, settlement_seen } = settlement ?? {};

  return (
    <SWrapper onClick={onClick} className={className}>
      <SFlexRow>
        <Typography variant='s2' style={{ marginLeft: '24px' }}>
          {moment(pay_period_end).format('MMM D, YYYY')}
        </Typography>
        <div className='settlement-seen-status'>
          <Chip
            label={settlement_seen ? 'Viewed' : 'New'}
            labelColor={settlement_seen ? palette.indigo500 : palette.green400}
            bgColor={settlement_seen ? palette.indigo0 : palette.green0}
            fontSize='13px'
          />
        </div>
      </SFlexRow>
      <SFlexRow>
        <div className='d-flex gap-2'>
          <span onClick={(e) => e.stopPropagation()}>
            <CustomCheckbox onChange={(checked) => handleSelect(checked, settlement)} checked={selected} />
          </span>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2'>Take Home</Typography>
            <Typography variant='s1'>
              {currency}
              {formatNumber(amount)}
            </Typography>
          </div>
        </div>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='s2'>Hours</Typography>
          <Typography variant='s1'>
            {((time_tracked || 0) - (time_tracked % 60)) / 60}h {(time_tracked || 0) % 60}m
          </Typography>
        </div>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='s2'>Gross</Typography>
          <Typography variant='s1'>
            {currency}
            {formatNumber(gross_amount)}
          </Typography>
        </div>
      </SFlexRow>
    </SWrapper>
  );
};

export default SettlementCard;
