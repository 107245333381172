import { object, string, mixed, number } from 'yup';
import { validator } from 'utils/helpers';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = object().shape({
  company_name: string().trim().required('Required'),
  dot: string().trim().required('Required'),
  phone_number: string().trim().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
  address: string().trim().required('Required'),
  address2: string().trim().nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required'),
  receivable_email: string().trim().required('Required'),
  company_email: string().trim().required('Required'),
  website: string().when('show_website', {
    is: (show_website) => !!show_website,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),

  default_invoice_pay_term: mixed().required('Required'),
  master_agreement: string().trim().required('Required'),
  liability_minimum_coverage: string().trim().required('Required'),
  cargo_insurance_minimum_coverage: string().trim().required('Required'),
  general_liability_minimum_coverage: string().trim().required('Required'),

  quick_pay_count: string().when('offer_quick_pay', {
    is: (offer_quick_pay) => !!offer_quick_pay,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),
  quick_pay_percent: string().when('offer_quick_pay', {
    is: (offer_quick_pay) => !!offer_quick_pay,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),

  ucr_doc_expiry_date: validator.futureRequiredDate(),
  ucr_doc: mixed().required('Required'),
  bmc_doc: mixed().required('Required'),
  authority: mixed().required('Required'),
  reference_sheet: mixed().required('Required'),
  master_agreement_title: string().trim().required('Required'),
  signature_first_name: string().trim().required('Required'),
  signature_last_name: string().trim().required('Required'),
});
