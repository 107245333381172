import React from 'react';
import Divider from 'common/Divider';
import PageHeader from './components/PageHeader';
import ApplicantsTable from './components/ApplicantsTable';
import { SPageWrapper } from './ApplicantsAndReferrals.styles';

const ApplicantsAndReferrals = () => {
  return (
    <SPageWrapper>
      <PageHeader showCopyUrl hideAction title='Applicants' />
      <Divider />
      <ApplicantsTable />
    </SPageWrapper>
  );
};

export default ApplicantsAndReferrals;
