import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import Popover from '@mui/material/Popover';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import 'components/ShipmentTab/ShipmentTab.css';
import { DepartmentsEmail, getValidateDataStaff, importBasicStaffData } from 'Api/Driver';
import calendar from 'assets/icons/drivers/calendar.png';
import indigoEdit from 'assets/icons/drivers/indigoEdit.svg';
import warn from 'assets/icons/drivers/warn.svg';
import { getErrorMessage } from 'utils/error';
import More from 'assets/icons/drivers/more.svg';
import tickgray from 'assets/icons/drivers/tickgray.svg';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import EditAddressModal from 'components/AddressSelectEditModal';
import EditGroupModal from 'components/ImportGroupModal/ImportGroupModal';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomizedSnackbars from 'components/toast/Toast';
import { importStaffValidationSchema } from 'components/Drivers/AddDriver/ValidationSchema';
import DriverAlertIcon from 'components/Drivers/DriverUserModal/WarningPopover';
import useDateFormat from 'hooks/useDateFormat';

const whitelist = ['US', 'CA', 'MX'];
const driverMapping = {
  'First name': 'fname',
  'First Name': 'fname',
  'Last Name': 'lname',
  'Address 1': 'address1',
  'Address 2': 'address2',
  Zip: 'zip',
  State: 'state_id',
  'Phone Number': 'phone_number',
  'Phone Extension': 'phone_ext',
  Title: 'title',
  'Email Id': 'email',
  'Hire Date': 'hire_date',
  Country: 'country_id',
  City: 'city_id',
  'Groups\r': 'groups',
  Groups: 'Groups',
};

export default function StaffUserImportModal({ ...props }) {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [onSubmitloading, setOnSubmitloading] = useState(false);
  const [onClickEdit, setOnClickEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [department, setDepartment] = useState([]);
  const [address, setAddress] = useState({});
  const [groupData, setGroupData] = useState({});
  const [masterChecked, setMasterChecked] = useState(true);
  const [list, setList] = useState([]);
  const [alertData, setAlertData] = useState();
  const [profileAlert, setProfileAlert] = useState(null);
  const [initialValues, setInitialValues] = useState([]);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const { groups } = props;

  const formik = useFormik({
    initialValues: {
      drivers: [
        {
          fname: '',
          lname: '',
          email: '',
          phone_number: '',
          hire_date: new Date(),
          address1: '',
          address2: '',
          country_id: null,
          state_id: null,
          city_id: null,
          zip: '',
          phone_ext: '',
          title: '',
          group_id: [],
          selected: true,
          id: null,
          department_id: '',
          selectedCountry: '',
          selectedState: '',
          selectedCity: '',
          errors: {
            phoneError: '',
            emailError: '',
            countryError: '',
            stateError: '',
            cityError: '',
          },
        },
      ],
    },
    validationSchema: importStaffValidationSchema,
  });

  function csvToArray(str, delimiter = ',') {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str
      .slice(0, str.indexOf('\n'))
      .split(delimiter)
      .map((heading) => driverMapping[heading]);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const rowFilter = rows?.filter((val) => val);
    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rowFilter?.map((row) => {
      const values = row.split(delimiter);
      const el = headers.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      setLoading(false);
      return el;
    });
    const validate = {
      email: [],
    };
    if (arr?.length) {
      for (let i = 0; i < arr?.length; i++) {
        const tempGroup = arr[i]?.Groups;
        const convertGroup = tempGroup?.split(','); // ["Fourth Group", "Second Group"]  //
        const data = convertGroup?.map((item) => item?.replaceAll('"', ''));
        const getGroupData = [];
        for (const grp of data || []) {
          const getGroupDataID = groups.find((gr) => {
            return gr?.group_name === grp;
          });
          getGroupData.push(getGroupDataID ?? grp);
        }
        formik.setFieldValue(`drivers[${i}].id`, i);
        formik.setFieldValue(`drivers[${i}].fname`, arr[i]?.fname);
        formik.setFieldValue(`drivers[${i}].lname`, arr[i]?.lname);
        formik.setFieldValue(`drivers[${i}].email`, arr[i]?.email);
        formik.setFieldValue(`drivers[${i}].phone_number`, arr[i]?.phone_number); // arr[i]?.phone_number
        formik.setFieldValue(`drivers[${i}].title`, arr[i]?.title);
        formik.setFieldValue(`drivers[${i}].phone_ext`, arr[i]?.phone_ext);
        formik.setFieldValue(`drivers[${i}].address1`, arr[i]?.address1);
        formik.setFieldValue(`drivers[${i}].address2`, arr[i]?.address2);
        formik.setFieldValue(`drivers[${i}].zip`, arr[i]?.zip);
        const dateTypeDob = moment(arr[i]?.hire_date || new Date(), 'MM/DD/YYYY');
        formik.setFieldValue(`drivers[${i}].hire_date`, new Date(dateTypeDob));
        formik.setFieldValue(`drivers[${i}].selected`, true);
        formik.setFieldValue(`drivers[${i}].group_id`, getGroupData);
        formik.setFieldValue(`drivers[${i}].selectedCountry`, arr[i]?.country_id);
        formik.setFieldValue(`drivers[${i}].selectedState`, arr[i]?.state_id);
        formik.setFieldValue(`drivers[${i}].selectedCity`, arr[i]?.city_id);
        formik.setFieldValue(`drivers[${i}].isEdit`, false);

        // validate.country.push(arr[i]?.country_id)
        // validate.state.push(arr[i]?.state_id)
        // validate.city.push(arr[i]?.city_id)
        validate.email.push(arr[i]?.email);
        // validate.phone_number.push(arr[i]?.phone_number)
      }
    }
    getValidateDataStaff(validate).then((res) => {
      for (let i = 0; i < arr?.length; i++) {
        if (res?.emailCheck[i] === 1) {
          formik.setFieldValue(`drivers[${i}].errors.emailError`, 'Email Address already exist');
        }
      }
    });
    setLoading(false);
    setMasterChecked(true);
    return arr;
  }
  const validateAgain = (rowData, i) => {
    setOnClickEdit(true);
    const validate = {
      email: [rowData.email],
    };
    // for
    getValidateDataStaff(validate)
      .then((res) => {
        if (res?.emailCheck[0] === 1) {
          formik.setFieldValue(`drivers[${i}].errors.emailError`, 'Email Address already exist');
        } else if (res?.emailCheck[0] === 0) {
          formik.setFieldValue(`drivers[${i}].errors.emailError`, '');
        }
        formik.setFieldValue(`drivers[${i}].isEdit`, false);
        setOnClickEdit(false);
        setInitialValues((prevState) => prevState.filter((i) => i.id !== rowData.id));
      })
      .catch(() => {
        setOnClickEdit(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const input = props?.document[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      csvToArray(text);
    };

    reader.readAsText(input);
  }, [props?.document]);

  useEffect(() => {
    DepartmentsEmail().then((res) => {
      setDepartment(res?.data || []);
    });
  }, []);

  const updateAddress = (data) => {
    const { index, value } = data;
    formik.setFieldValue(`drivers[${index}].country_id`, value?.country);
    formik.setFieldValue(`drivers[${index}].state_id`, value?.state);
    formik.setFieldValue(`drivers[${index}].city_id`, value?.city);
  };

  const updateGroup = (data, onHide = null) => {
    const { index, value } = data;
    formik.setFieldValue(`drivers[${index}].group_id`, value?.group_id);
    onHide();
  };

  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const importUsers = () => {
    setOnSubmitloading(true);
    const importDriverData = {
      fname: [],
      lname: [],
      email: [],
      phone_number: [],
      phone_ext: [],
      title: [],
      hire_date: [],
      address1: [],
      address2: [],
      country: [],
      state: [],
      city: [],
      zip: [],
      groups: [],
      department_id: [],
    };
    for (const val of formik.values?.drivers || []) {
      const dateOfBirth = val.hire_date ? moment(val.dob)?.format('MM/DD/YYYY') : '';
      if (val?.selected) {
        importDriverData.fname.push(val?.fname);
        importDriverData.lname.push(val.lname);
        importDriverData.email.push(val.email);
        importDriverData.phone_number.push(val.phone_number);
        importDriverData.phone_ext.push(val.phone_ext);
        importDriverData.title.push(val.title);
        importDriverData.hire_date.push(dateOfBirth);
        importDriverData.address1.push(val.address1);
        importDriverData.address2.push(val.address2);
        importDriverData.country.push(val.country_id?.name ?? val?.selectedCountry);
        importDriverData.state.push(val.state_id?.name ?? val?.selectedState);
        importDriverData.city.push(val?.city_id?.name ?? val?.selectedCity);
        importDriverData.zip.push(val.zip);
        importDriverData.department_id.push(val.department_id);
        const group = val.group_id?.map((item) => item?.name);
        importDriverData.groups.push(group);
      }
    }
    importBasicStaffData(importDriverData)
      .then(() => {
        setOnSubmitloading(false);
        setShowMessage({
          message: 'Staff has been created',
          visible: true,
          type: 'success',
        });
        props?.getStaff();
        setTimeout(() => {
          props.onHide();
          props.close();
        }, 1000);
      })
      .catch((error) => {
        setOnSubmitloading(false);
        setShowMessage({
          message: getErrorMessage(error),
          visible: true,
          type: 'error',
        });
      });
  };

  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;

  const ProfileAlerthandleClick = (e, alerts, formikErrors) => {
    setProfileAlert(e.currentTarget);
    setAlertData({ ...alerts, formikErrors });
  };

  const onMasterCheck = (e) => {
    const tempList = formik?.values?.drivers;
    tempList.forEach((user) => {
      user.selected = e;
    });

    // Update State
    setList(tempList);
    setMasterChecked(e);
  };

  const onItemCheck = (e, item) => {
    const tempList = formik?.values?.drivers;
    tempList.map((user) => {
      if (user.id === item) {
        user.selected = e;
      }
      return user;
    });

    setList(tempList);
    const totalItems = list?.length;
    const totalCheckedItems = tempList.filter((e) => e.selected)?.length;

    // Update State
    setMasterChecked(totalItems === totalCheckedItems);
  };
  let validate = false;
  const isValid = useMemo(() => {
    const selected = formik?.values?.drivers?.filter((e) => e.selected);
    return selected?.every(
      (i) =>
        (!i?.errors || Object.keys(i.errors)?.every((key) => !i.errors[key])) &&
        !!i?.fname &&
        !!i?.lname &&
        !!i?.email &&
        !!i?.phone_number &&
        !!i?.department_id &&
        !!i?.hire_date &&
        !!i?.title
    );
  }, [formik]);
  const totalSelected = formik?.values?.drivers?.filter((e) => e.selected)?.length;
  return (
    <Modal
      {...props}
      size='lg'
      // aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen='lg-down'
      dialogClassName='modal-custom-width'
    >
      {!!editModal && (
        <EditAddressModal
          open={!!editModal}
          onClose={() => setEditModal(false)}
          addressData={address}
          updateAddress={updateAddress}
        />
      )}
      {!!editGroup && (
        <div className={editGroup ? 'fade modal-backdrop show' : 'modal'}>
          <EditGroupModal
            show={!!editGroup}
            onHide={() => setEditGroup(false)}
            loading={loading}
            formik={formik}
            groups={groups}
            updateGroup={updateGroup}
            groupData={groupData}
          />
        </div>
      )}
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Import Staff&#40;s&#41;
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='driver-style-wrap staff-user-modal'
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
      >
        <div className='table-wrap  import-user-data-main-wrap'>
          <div className='sub-table-container sub-table-container--import-user'>
            {loading ? (
              <TablePreLoader styleWrapper={{ marginTop: 0 }} />
            ) : (
              <div className='sub-table-container' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                <table className='custom-table-wraper import-driver-modal-table'>
                  <thead className='custom-table-header-wraper'>
                    <tr className='custom-table-row-wraper  custom-table-row-wraper-header-import-user'>
                      <td>
                        <CustomCheckbox checked={masterChecked} id='mastercheck' onChange={(e) => onMasterCheck(e)} />
                      </td>
                      <td>ACTION</td>
                      <td>FIRST NAME</td>
                      <td>LAST NAME</td>
                      <td>EMAIL</td>
                      <td>PHONE NUMBER</td>
                      <td>HIRE DATE</td>
                      <td>ADDRESS 1</td>
                      <td>ADDRESS 2</td>
                      <td>COUNTRY, STATE, CITY</td>
                      <td>ZIP</td>
                      <td>TITLE</td>
                      <td>DEPARTMENT</td>
                      <td>GROUPS</td>
                    </tr>
                  </thead>
                  {formik?.values?.drivers?.map((rowData, index) => {
                    validate = !!(
                      (formik?.errors?.drivers !== undefined && formik?.errors?.drivers[index]?.department_id) ||
                      rowData?.errors?.phoneError ||
                      rowData?.errors?.emailError ||
                      rowData?.errors?.countryError ||
                      rowData?.errors?.stateError ||
                      rowData?.errors?.cityError
                    );

                    const getGroup = rowData?.group_id?.map((item) => item?.group_name);
                    const groupsName = getGroup?.toString();
                    const getDepartment = department.find((gr) => {
                      return +gr.id === Number(rowData?.department_id);
                    });
                    return (
                      <tr
                        style={validate ? { background: 'rgb(251 195 195 / 24%)' } : { color: 'white' }}
                        className={`${
                          rowData?.selected
                            ? 'custom-table-row-wraper custom-table-row-wraper-selected '
                            : 'custom-table-row-wraper '
                        } `}
                        key={rowData?.id || index}
                      >
                        <td>
                          <CustomCheckbox
                            checked={formik?.values?.drivers[index]?.selected}
                            className='form-check-input'
                            id={`drivers[${index}].id`}
                            onChange={(e) => onItemCheck(e, index)}
                          />
                        </td>
                        <td>
                          <div className='Action-container'>
                            {!rowData.isEdit && (
                              <div className='user-name-and-actions-container'>
                                <img
                                  src={More}
                                  alt='nore'
                                  onClick={() => {
                                    formik.setFieldValue(`drivers[${index}].isEdit`, true);
                                    setInitialValues((prevState) => [...prevState, formik.values.drivers[index]]);
                                  }}
                                />
                              </div>
                            )}
                            {rowData.isEdit &&
                              (onClickEdit ? (
                                <div>
                                  <CircularProgress size={26} />
                                </div>
                              ) : (
                                <div className='d-flex align-items-center gap-2'>
                                  <img
                                    src={tickgray}
                                    alt='nore-cross'
                                    className='close-edit-mode-icon'
                                    onClick={() => validateAgain(rowData, index)}
                                  />
                                  <CancelIcon
                                    width={16}
                                    height={16}
                                    onClick={() => {
                                      const initialData = initialValues.find(
                                        (i) => i.id === formik.values.drivers[index].id
                                      );
                                      if (initialData) {
                                        formik.setFieldValue(`drivers[${index}]`, initialData);
                                        return;
                                      }
                                      formik.setFieldValue(`drivers[${index}].isEdit`, false);
                                    }}
                                  />
                                </div>
                              ))}
                            {validate && (
                              <div
                                className='ms-2 me-1'
                                onClick={(e) => e.stopPropagation()}
                                style={{ position: 'relative' }}
                              >
                                <WarningIcon
                                  width={20}
                                  height={20}
                                  fill={palette.red400}
                                  onClick={(e) =>
                                    ProfileAlerthandleClick(
                                      e,
                                      rowData?.errors,
                                      formik?.errors?.drivers ? formik?.errors?.drivers[index] : null
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.fname || '-'}
                                </h2>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  className='input-field'
                                  placeholder='Wilson'
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                  name={`drivers[${index}].fname`}
                                  id={`drivers[${index}].fname`}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.fname}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.fname ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.fname}</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.lname || '-'}
                                </h2>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  className='input-field'
                                  placeholder='Wilson'
                                  name={`drivers[${index}].lname`}
                                  id={`drivers[${index}].lname`}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.lname}
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.lname ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.lname}</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.email || '-'}
                                </h2>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  className='input-field'
                                  placeholder='example@email.com'
                                  name={`drivers[${index}].email`}
                                  id={`drivers[${index}].email`}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.email}
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.email ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.email}</span>
                              ) : null}
                              {rowData?.errors?.emailError && !formik.errors?.drivers?.[index]?.email ? (
                                <span className='error-message'>Email Address already exists</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                {formik?.values?.drivers[index]?.phone_number || '-'}
                              </p>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='phone-number'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <PhoneInput
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                  addInternationalOption={false}
                                  className='input-field-phone'
                                  value={formik?.values?.drivers[index]?.phone_number}
                                  countries={whitelist}
                                  onChange={(val) => formik.setFieldValue(`drivers[${index}].phone_number`, val)}
                                  defaultCountry='US'
                                  countryOptionsOrder={whitelist}
                                  placeholder='(555) 555-1234'
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.phone_number ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.phone_number}</span>
                              ) : null}
                              {rowData?.errors?.phoneError ? (
                                <span className='error-message'>Phone Number already exists</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                {formik?.values?.drivers[index]?.hire_date
                                  ? formatDate(formik?.values?.drivers[index]?.hire_date)
                                  : '-'}
                              </p>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='datepicker'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <img src={calendar} alt='calendar p-1' />
                                <DatePicker
                                  selected={formik.values.drivers[index]?.hire_date}
                                  showYearDropdown
                                  placeholderText='mm/dd/yyyy'
                                  className='text-style ms-0'
                                  id={`drivers[${index}].hire_date`}
                                  name={`drivers[${index}].hire_date`}
                                  onBlur={formik.handleBlur}
                                  value={formik?.values?.drivers[index]?.hire_date}
                                  onChange={(date) => {
                                    formik.setFieldValue(`drivers[${index}].hire_date`, date);
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.hire_date ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.hire_date}</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.address1 || '-'}
                                </p>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  id={`drivers[${index}].address1`}
                                  name={`drivers[${index}].address1`}
                                  className='input-field'
                                  placeholder='Wilson'
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.address1}
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.address1 ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.address1}</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.address2 || '-'}
                                </p>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  id={`drivers[${index}].address2`}
                                  name={`drivers[${index}].address2`}
                                  className='input-field'
                                  placeholder='Wilson'
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.address2}
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </td>

                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {(formik?.values?.drivers[index]?.country_id?.name || ' ') +
                                    (formik?.values?.drivers[index]?.country_id?.name ? ', ' : ' ') +
                                    (formik?.values?.drivers[index]?.state_id?.name || '') +
                                    (formik?.values?.drivers[index]?.state_id?.name ? ', ' : ' ') +
                                    (formik?.values?.drivers[index]?.city_id?.name || '')}{' '}
                                  {!(
                                    formik?.values?.drivers[index]?.city_id?.name ||
                                    formik?.values?.drivers[index]?.state_id?.name ||
                                    formik?.values?.drivers[index]?.country_id?.name
                                  )
                                    ? `${formik?.values?.drivers[index]?.selectedCountry}, ${formik?.values?.drivers[index]?.selectedState}, ${formik?.values?.drivers[index]?.selectedCity}`
                                    : ''}
                                </p>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div className='address-select-edit-mode-import'>
                                <div
                                  className='selector-name name-wrap mt-1'
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                >
                                  <input
                                    type='text'
                                    name='country_id'
                                    id='country_id'
                                    className='input-field'
                                    placeholder='Address'
                                    disabled
                                    value={
                                      formik?.values?.drivers[index]?.country_id?.name ||
                                      formik?.values?.drivers[index]?.state_id?.name ||
                                      formik?.values?.drivers[index]?.city_id?.name
                                        ? (formik?.values?.drivers[index]?.country_id?.name || ' ') +
                                          (formik?.values?.drivers[index]?.country_id?.name ? ', ' : ' ') +
                                          (formik?.values?.drivers[index]?.state_id?.name || '') +
                                          (formik?.values?.drivers[index]?.state_id?.name ? ', ' : ' ') +
                                          (formik?.values?.drivers[index]?.city_id?.name || '')
                                        : `${formik?.values?.drivers[index]?.selectedCountry}, ${formik?.values?.drivers[index]?.selectedState}, ${formik?.values?.drivers[index]?.selectedCity}`
                                    }
                                    style={{
                                      backgroundColor: use(palette.white, palette.dark600),
                                      borderColor: use(palette.gray50, palette.darkborder),
                                    }}
                                  />
                                </div>
                                <img
                                  src={indigoEdit}
                                  alt='edit'
                                  className='edit-address-icon'
                                  onClick={() => {
                                    setAddress({
                                      index,
                                      country: formik.values.drivers[index]?.country_id,
                                      city: formik.values.drivers[index]?.city_id,
                                      state: formik.values.drivers[index]?.state_id,
                                    });
                                    setEditModal(true);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.zip || '-'}
                                </p>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  name={`drivers[${index}].zip`}
                                  id={`drivers[${index}].zip`}
                                  className='input-field'
                                  placeholder='Wilson'
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.zip}
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.zip ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.zip}</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {formik?.values?.drivers[index]?.title || '-'}
                                </p>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div
                                className='selector-name name-wrap'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                              >
                                <input
                                  type='text'
                                  name={`drivers[${index}].title`}
                                  id={`drivers[${index}].title`}
                                  className='input-field'
                                  placeholder='Wilson'
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik?.values?.drivers[index]?.title}
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.title ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.title}</span>
                              ) : null}
                            </div>
                          )}
                        </td>

                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <div className='user-name-and-actions-container'>
                                <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {getDepartment?.department_name || '-'}
                                </h2>
                              </div>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div className='custom-select-wrap-contain'>
                                <CustomSelect
                                  defaultValue='Select Department'
                                  options={department?.map((item) => item)} // deaprtment will be change
                                  onChange={(value) => {
                                    formik.setFieldValue(`drivers[${index}].department_id`, value);
                                  }}
                                  styles={{
                                    borderBottomLeftRadius: '0px',
                                    borderTopLeftRadius: '0px',
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik?.values?.drivers[index]?.department_id}
                                  id='department_id'
                                  name='department_id'
                                  patchProperty='id'
                                  propertyName='department_name'
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.department_id ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.department_id}</span>
                              ) : null}
                              {/* {
                                      (formik?.errors?.drivers !== undefined && formik?.errors?.drivers[index]?.department_id) ?
                                        <span className="error-message" >*Required</span>
                                        :
                                        ""
                                    } */}
                            </div>
                          )}
                        </td>
                        <td>
                          {!rowData.isEdit && (
                            <div className='import-user-view-mode'>
                              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                {groupsName || '-'}
                              </p>
                            </div>
                          )}
                          {rowData.isEdit && (
                            <div className='import-user-edit-mode'>
                              <div className='address-select-edit-mode-import'>
                                <div
                                  className='selector-name name-wrap mt-1'
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                >
                                  <input
                                    type='text'
                                    name='group_id'
                                    id='group_id'
                                    className='input-field'
                                    placeholder='Tag'
                                    disabled
                                    value={groupsName}
                                    style={{
                                      backgroundColor: use(palette.white, palette.dark600),
                                      borderColor: use(palette.gray50, palette.darkborder),
                                    }}
                                  />
                                </div>
                                <img
                                  src={indigoEdit}
                                  alt='edit'
                                  className='edit-address-icon'
                                  onClick={() => {
                                    setEditGroup(true);
                                    setGroupData({
                                      index,
                                      selected_Group: formik.values.drivers[index]?.group_id,
                                    });
                                  }}
                                />
                              </div>
                              {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.group ? (
                                <span className='error-message'>{formik.errors?.drivers[index]?.group}</span>
                              ) : null}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className='import-modal-footer-wrapper'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-line-container mt-2'>
          <img src={warn} alt='' />
          <p className='footer-line'>Please make sure you have filled all the field including complete address.</p>
        </div>
        <div className='footer-btn-container footer-btn-container-import-modal'>
          <Button className='cancel-button' onClick={props.onHide}>
            Cancel
          </Button>
          {onSubmitloading ? (
            <div className='ms-2'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <Button disabled={!isValid} style={{ opacity: isValid ? 1 : 0.7 }} onClick={() => importUsers()}>
              Import {totalSelected} Staff
            </Button>
          )}
        </div>
      </Modal.Footer>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <Popover
        id={ProfileAlertContent}
        // anchorPosition={{ top: 299, left: 669 }}
        open={ProfileAlertOpen}
        anchorEl={profileAlert}
        onClose={ProfileAlerthandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <DriverAlertIcon alertData={alertData} driverAlert='Staff Alerts' />
      </Popover>
    </Modal>
  );
}
