import React, { useEffect, useState } from 'react';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithIcon, Textarea } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { blockNonPositiveNumbers, blockNotNumberChars, formatInputNumber, removeCommas } from 'utils/helpers';
import { getShipmentOfferSettings } from 'Api/CompanySettings';
import { createShipmentOfferOptions, getShipmentOfferOptions } from 'Api/PlannerV2';
import ConfirmOffer from 'componentsV2/Planner/BulkOffer/ConfirmOffer';
import { formatNumber, palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { validationSchema } from './validationSchema';
import { autoAwardTimeOptions, getInitialValues, timeOptions } from './BulkOffer.data';
import { SMergedInputs, SWrapper } from './BulkOffer.styles';

const BulkOffer = ({
  sendOffer,
  shipment,
  offersCount,
  onSuccess,
  readOnly,
  onPostedRateChange,
  revertAll,
  loadingRevert,
  offerTo = 'carrier',
}) => {
  const showToaster = useShowToaster();
  const hasBrokerage = useHasBrokerage();
  const [loadingSave, setLoadingSave] = useState(false);
  const [offerOptions, setOfferOptions] = useState(null);
  const [offerSettings, setOfferSettings] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { shipment_id } = shipment || {};

  const onSubmit = async (values, confirmed, watchBids) => {
    if (!confirmed) {
      setOpenConfirm(true);
      return;
    }
    setLoadingSave(true);
    try {
      const body = {
        expire_shipment_offer: values.expire_shipment_offer || null,
        expire_shipment_offer_unit: values.expire_shipment_offer_unit.value || null,
        auto_repost: values.auto_repost,
        retract_carrier_award: offerTo === 'driver' ? undefined : values.retract_carrier_award || null,
        retract_carrier_award_unit: offerTo === 'driver' ? undefined : values.retract_carrier_award_unit.value || null,
        auto_award_first_lowest_amount: values.auto_award_first_lowest_amount || null,
        auto_award_first_lowest_amount_unit: values.auto_award_first_lowest_amount_unit.value || null,
        auto_dispatch: values.auto_dispatch,
        require_minimum_margin: values.require_minimum_margin,
        require_minimum_margin_percent: values.require_minimum_margin_percent,
      };
      await createShipmentOfferOptions(shipment_id, body);
      sendOffer(values, watchBids);
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(offerOptions, offerSettings),
    onSubmit: (values) => onSubmit(values, false),
    enableReinitialize: true,
    validationSchema,
  });

  const getOptions = async () => {
    try {
      const { data } = await getShipmentOfferOptions(shipment_id);
      if (!data) {
        const { data: settingsData } = await getShipmentOfferSettings(shipment_id);
        setOfferSettings(settingsData);
      }
      setOfferOptions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const shipmentAmount = shipment?.shipment_billing?.reduce((acc, cur) => acc + Number(cur.total_amount), 0);
  const percent = values.posted_rate
    ? ((shipmentAmount - Number(removeCommas(values.posted_rate) || 0)) * 100) / shipmentAmount
    : null;

  useEffect(() => {
    getOptions();
  }, [shipment_id]);

  useEffect(() => {
    onPostedRateChange(values.posted_rate);
  }, [values.posted_rate]);

  return (
    <SWrapper>
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex gap-4'>
          <InputWithIcon
            required
            label='Offer Amount'
            name='posted_rate'
            value={values.posted_rate}
            onChange={(e) => handleChange('posted_rate', formatInputNumber(e.target.value))}
            onKeyDown={blockNotNumberChars}
            error={touchedErrors.posted_rate}
            disabled={readOnly}
          />
          <div>
            <InputLabel>Margin</InputLabel>
            <Typography
              variant='b2'
              style={{
                color: !percent || percent === 0 ? palette.gray700 : percent > 0 ? palette.green500 : palette.red500,
              }}
            >
              {percent !== null ? (
                <>
                  {percent < 0 ? '-' : percent > 0 ? '+' : ''}
                  {formatNumber(Math.abs(percent))}%
                </>
              ) : (
                '-'
              )}
            </Typography>
          </div>
        </div>
        {offerTo !== 'driver' && (
          <Textarea
            rows={3}
            label='Note'
            name='note'
            placeholder='Enter notes for carrier..'
            value={values.note}
            onChange={handleChange}
            error={touchedErrors.note}
            disabled={readOnly}
          />
        )}
        <div className='form-wrapper'>
          <div className='form-field'>
            <InputLabel>Expire Shipment Offer After</InputLabel>
            <SMergedInputs>
              <Input
                width='120px'
                type='number'
                name='expire_shipment_offer'
                value={values.expire_shipment_offer}
                onChange={handleChange}
                error={touchedErrors.expire_shipment_offer}
                onKeyDown={(e) => {
                  blockNotNumberChars(e);
                  blockNonPositiveNumbers(e);
                }}
                disabled={readOnly}
              />
              <Autocomplete
                width='100px'
                name='expire_shipment_offer_unit'
                options={timeOptions}
                value={values.expire_shipment_offer_unit}
                onChange={(e, val) => handleChange('expire_shipment_offer_unit', val)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                error={touchedErrors.expire_shipment_offer_unit}
                disabled={readOnly}
              />
            </SMergedInputs>
          </div>
          {!!hasBrokerage && offerTo !== 'driver' && (
            <div className='form-field'>
              <InputLabel>Retract Carrier Award if Not Confirmed After</InputLabel>
              <SMergedInputs>
                <Input
                  width='120px'
                  name='retract_carrier_award'
                  value={values.retract_carrier_award}
                  onChange={handleChange}
                  error={touchedErrors.retract_carrier_award}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                  disabled={readOnly}
                />
                <Autocomplete
                  width='100px'
                  name='retract_carrier_award_unit'
                  options={timeOptions}
                  value={values.retract_carrier_award_unit}
                  onChange={(e, val) => handleChange('retract_carrier_award_unit', val)}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  error={touchedErrors.retract_carrier_award_unit}
                  disabled={readOnly}
                />
              </SMergedInputs>
            </div>
          )}
          <div className='form-field'>
            <InputLabel>Auto Award First Lowest Amount After</InputLabel>
            <SMergedInputs>
              <Input
                width='120px'
                name='auto_award_first_lowest_amount'
                value={values.auto_award_first_lowest_amount}
                onChange={handleChange}
                error={touchedErrors.auto_award_first_lowest_amount}
                disabled={readOnly || values.auto_award_first_lowest_amount_unit?.value === null}
                onKeyDown={(e) => {
                  blockNotNumberChars(e);
                  blockNonPositiveNumbers(e);
                }}
              />
              <Autocomplete
                width='100px'
                name='auto_award_first_lowest_amount_unit'
                options={autoAwardTimeOptions}
                value={values.auto_award_first_lowest_amount_unit}
                onChange={(e, val) => {
                  handleChange('auto_award_first_lowest_amount_unit', val);
                  if (!val?.value) {
                    handleChange('require_minimum_margin', false);
                    handleChange('require_minimum_margin_percent', '');
                  }
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                error={touchedErrors.auto_award_first_lowest_amount_unit}
                disabled={readOnly}
              />
            </SMergedInputs>
          </div>
          {!!hasBrokerage && (
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                name='require_minimum_margin'
                type='switch'
                smail={false}
                checked={values.require_minimum_margin}
                onChange={(checked) => handleChange('require_minimum_margin', checked)}
                disabled={!values.auto_award_first_lowest_amount_unit?.value}
              />
              <InputLabel className='mb-0'>Require Minimum Margin</InputLabel>
              <InputWithIcon
                icon='%'
                left={false}
                width='120px'
                name='require_minimum_margin_percent'
                value={values.require_minimum_margin_percent}
                onChange={handleChange}
                error={touchedErrors.require_minimum_margin_percent}
                disabled={!values.require_minimum_margin}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          )}
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              name='auto_repost'
              type='switch'
              smail={false}
              checked={values.auto_repost}
              onChange={(checked) => handleChange('auto_repost', checked)}
            />
            <InputLabel className='mb-0'>Auto Repost After Offer is Expired</InputLabel>
          </div>
          {!!hasBrokerage && (
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                name='auto_dispatch'
                type='switch'
                smail={false}
                checked={values.auto_dispatch}
                onChange={(checked) => handleChange('auto_dispatch', checked)}
              />
              <InputLabel className='mb-0'>Auto Dispatch After Award is Accepted</InputLabel>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-end'>
          {readOnly ? (
            <CustomButton
              type='primary'
              title='Revert All'
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={revertAll}
              disabled={loadingRevert}
            />
          ) : (
            <CustomButton
              type='primary'
              title='Submit Offer'
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={handleSubmit}
              disabled={loadingSave || !offersCount}
            />
          )}
        </div>
      </div>
      <ConfirmOffer
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        handleSubmit={(watchBids) => {
          setOpenConfirm(false);
          onSubmit(values, true, watchBids);
        }}
        offersCount={offersCount}
        offerTo={offerTo}
      />
    </SWrapper>
  );
};

export default BulkOffer;
