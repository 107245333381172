export const getInitialValues = (invoices) => {
  return invoices.map((invoice) => {
    const openBalance = invoice.billing?.open_balance || invoice.open_balance || 0;
    const discountPercent = Number(invoice.customer?.customer_payment_term?.pay_term_types?.discount || 0);
    const discountAmount = discountPercent ? (openBalance * discountPercent) / 100 : '';

    return {
      id: invoice.id || null,
      customer: invoice.billing?.billing_customer || invoice.customer || null,
      payment_method: null,
      bill_date: invoice.customer_billed_date || null,
      aging: invoice.age || null,
      due_date: invoice.due_date || null,
      payment_date: new Date(),
      invoice_id: invoice.invoice_id || null,
      reference_id: invoice.billing?.reference_id || invoice.reference_id || '',
      discount_type: { label: '%', value: 'percent' },
      discount_percent: discountPercent,
      discount: discountAmount,
      open_balance: openBalance,
      amount_paid: '',
      credit_used: '',
      pay_reference: invoice.pay_reference || '',
      account: null,
      apply_credit: false,
      credits: invoice.customer?.credits || 0,
    };
  });
};

export const paymentMethods = [
  { name: 'Wire', value: 4 },
  { name: 'ACH', value: 2 },
  { name: 'Check', value: 8 },
  { name: 'Credit Card', value: 32 },
  { name: 'Cash', value: 16 },
];

export const getSelectedMethods = (combinedValue) => {
  const selectedMethods = [];

  const paymentMethods = {
    online: 1,
    ach: 2,
    wire: 4,
    check: 8,
    cash: 16,
  };

  for (const method in paymentMethods) {
    if ((combinedValue && paymentMethods[method]) !== 0) {
      selectedMethods.push(method);
    }
  }

  return selectedMethods;
};
