import React, { useState, useContext, useEffect, useMemo } from 'react';
import { OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import Tooltip from 'common/Tooltip';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { TableContext } from 'context/tableContext';
import { checkLicense } from 'Api/Driver';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download2.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import styles from './DriverHeader.module.css';
import CustomButton from '../CustomButton/CustomButton';
import AddDriver from '../Drivers/AddDriver/AddDriver';
import ImportCSv from '../Drivers/AddDriver/ImportCsv';
import DarkOverlay from '../DarkOverlay';
import DropDownPlaner from '../DropDownPlaner';
import ExportCsvButton from '../ExportCsvButton';
import PopoverSettings from '../PopoverSettings/PopoverSettingDriver';
import Layout from '../CreateShipment/Layout';
import HeaderStar from '../HeaderStar';
import AlertDialog from '../AlertDialogBox/AlertDialogBox';

const DriverHeader = ({ getDrivers, search, setSearch, onSearch, advancedOpen, setAdvancedOpen }) => {
  const { use } = useTheme();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [isOpenCsv, setIsOpenCsv] = useState(false);

  const [open, setOpen] = useState(false);
  const [openStep, setOpenStep] = useState(null);
  const [currentDriverId, setCurrentDriverId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));

  const handleOpen = () => {
    if (accountStatus?.account_status === 'intrial') {
      setOpen(true);
      return;
    }
    setLoading(true);
    checkLicense()
      .then((res) => {
        if (res.user_available_count > 0) {
          setOpen(true);
        } else {
          setAlertShow(true);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentDriverId(null);
    setOpenStep(null);
  };

  const newDriverAdded = () => {
    setOpen(false);
    setCurrentDriverId(null);
    setOpenStep(null);
    setShow(true);
  };

  const {
    filter,
    updateFilter,
    dragItem,
    onChangeOrder,
    getSettings,
    updateOnCreate,
    createModalCLose,
    switchRadioButtons,
    onsStTableColumn,
    onSetSwitchRadioButtons,
    tableColumn,
    multiSelectOptionsDriver,
  } = useContext(TableContext);

  const NO_BUTTON_TYPES = ['origin_location', 'destination_location', 'scheduled_origin', 'scheduled_destination'];

  const styleHeader = useMemo(() => {
    return {
      styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '97%',
        height: '97%',
        bgcolor: use(palette.white, palette.dark900),
        boxShadow: 24,
        borderRadius: '8px',
      },
    };
  }, [use, palette]);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettings
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={updateFilter}
          onChangeOrder={onChangeOrder}
          onsStTableColumn={onsStTableColumn}
          switchRadioButtons={switchRadioButtons}
          setShowPopover={(isOpen) => setShow(isOpen)}
          onSetSwitchRadioButtons={onSetSwitchRadioButtons}
          tableColumn={tableColumn}
        />
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (location?.state?.openCreate) {
      setOpen(true);
    }
    if (location.state?.openStep) {
      setOpenStep(location.state?.openStep);
    }
    if (location.state?.currentDriverId) {
      setCurrentDriverId(location.state?.currentDriverId);
    }
  }, [location?.state?.openCreate]);

  return (
    <div className={styles['DiverHeader_container driver-style-wrap']}>
      {!!alertShow && <AlertDialog show={!!alertShow} onHide={() => setAlertShow(false)} />}
      <div className={styles.driverHeader_wrapper}>
        <div className={styles.driver_title_wrap}>
          <HeaderStar title='Drivers' />
        </div>
        <div className='d-flex align-items-center gap-3'>
          <Tooltip title='Import from CSV'>
            <CustomButton
              type='secondary'
              leftIcon={<DownloadIcon fill='#464F60' />}
              styleButton={{ padding: '9px 12px', margin: 0 }}
              onClick={() => setIsOpenCsv(true)}
            />
          </Tooltip>
          <ExportCsvButton type='driver' />
          {loading ? (
            <div className='me-4'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <CustomButton
              type='primary'
              title='Add Driver'
              leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
              styleButton={{ padding: '4px 12px', margin: 0 }}
              onClick={handleOpen}
            />
          )}
        </div>
        <Modal
          backdrop='static'
          show={isOpenCsv}
          onHide={() => setIsOpenCsv(false)}
          centered
          dialogClassName='import-modal-wrap'
          enforceFocus={false}
          aria-labelledby='contained-modal-title-vcenter'
        >
          <ImportCSv open={open} close={() => setIsOpenCsv(false)} newDriver={newDriverAdded} getDrivers={getDrivers} />
        </Modal>

        <Modal
          backdrop='static'
          show={open}
          onHide={handleClose}
          onEscapeKeyDown={(e) => e.preventDefault()}
          centered
          id='add-driver-modal'
        >
          <AddDriver
            open={open}
            openStep={openStep}
            currentDriverId={currentDriverId}
            close={handleClose}
            newDriver={newDriverAdded}
            getDrivers={getDrivers}
          />
        </Modal>
      </div>
      <div className='top_popover_search-wrapper d-flex align-items-center'>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popOverContent}
          rootClose={show}
          onToggle={setShow}
          show={show}
          defaultShow={false}
          delay={0}
        >
          <div
            className='top_popover'
            style={{ backgroundColor: use(palette.white, palette.dark800), marginBottom: '1rem' }}
          >
            <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
          </div>
        </OverlayTrigger>
        <div className='filterPlaner '>
          <DropDownPlaner context='' data={multiSelectOptionsDriver} filterBy={filter.searchBy.title} />
          <input
            type='text'
            onChange={(e) => setSearch(e.target.value)}
            className='owner-filter-input-wrap'
            placeholder='Search'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
            value={search}
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              color: use(palette.gray400, palette.white),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          />
          {!NO_BUTTON_TYPES.includes(filter.searchBy.value) && (
            <CustomButton
              type='primary'
              title='Search'
              onClick={() => onSearch()}
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '5px 12px', margin: '0 0 0 8px' }}
            />
          )}
          <CustomButton
            type='primary'
            title=''
            leftIcon={
              advancedOpen ? (
                <MinusIcon fill='#fff' style={{ margin: 8 }} />
              ) : (
                <PlusIcon fill='#fff' style={{ margin: 8 }} />
              )
            }
            className='ms-3'
            styleButton={{ height: 32, margin: 0 }}
            onClick={() => setAdvancedOpen(!advancedOpen)}
          />
        </div>

        <DarkOverlay visible={show} styles={{ zIndex: 500 }} />
      </div>
      <Modal open={isOpen} enforceFocus={false} onClose={() => setIsOpen(false)}>
        <Box sx={styleHeader.styleModal}>
          <Layout
            onCloseModal={() => setIsOpen(false)}
            createModalCLose={createModalCLose}
            updateOnCreate={updateOnCreate}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default DriverHeader;
