import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Input from 'common/Input';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import { getCustomersList } from 'Api/Customers';
import { SWrapper, SSearchWrapper, SDatePicker } from './TableFilters.styles';

const TableFilters = ({ search, setSearch, selectedFilters, setSelectedFilters, locations, setLocations }) => {
  const [filterType, setFilterType] = useState('All');
  const [customers, setCustomers] = useState([]);

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ itemsPerPage: 1000 });
      setCustomers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onLocationChange = (e) => {
    setLocations((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onRangeChange = (dates) => {
    setLocations((prevState) => ({ ...prevState, scheduled: dates }));
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <SWrapper>
      <SSearchWrapper>
        <div>
          <Autocomplete
            width='100px'
            options={['All', 'Customer', 'Location']}
            value={filterType}
            onChange={(e, val) => setFilterType(val)}
          />
        </div>
        {filterType === 'All' && <Search search={search} onChange={setSearch} className='search-input' />}
        {filterType === 'Customer' && (
          <Autocomplete
            multiple
            width='410px'
            labelKey='company_name'
            disableClearable={false}
            options={customers}
            value={selectedFilters.customers}
            onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customers: val }))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            className='filter-dropdown'
            limitTags={2}
          />
        )}
        {filterType === 'Location' && (
          <>
            <Input
              width='180px'
              name='origin_city'
              placeholder='Origin'
              value={locations.origin_city}
              onChange={onLocationChange}
              className='origin-input'
            />
            <div className='location-inputs'>
              <Input
                width='120px'
                name='origin_state'
                placeholder='State'
                value={locations.origin_state}
                onChange={onLocationChange}
              />
              <ArrowRight fill={palette.indigo500} />
              <div className='d-flex align-items-center gap-1'>
                <Input
                  width='180px'
                  name='destination_city'
                  placeholder='Destination'
                  value={locations.destination_city}
                  onChange={onLocationChange}
                />
                <Input
                  width='120px'
                  name='destination_state'
                  placeholder='State'
                  value={locations.destination_state}
                  onChange={onLocationChange}
                />
              </div>
              <div>
                <SDatePicker
                  selectsRange
                  renderStart={false}
                  shouldCloseOnSelect={false}
                  startDate={locations.scheduled[0]}
                  endDate={locations.scheduled[1]}
                  placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                  onChange={(dates) => onRangeChange(dates)}
                />
              </div>
            </div>
          </>
        )}
      </SSearchWrapper>
    </SWrapper>
  );
};

export default TableFilters;
