import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import { palette } from 'utils/constants';

export const SHeader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
`;

export const SCompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${palette.white};
  align-items: center;
  padding: 20px 40px 16px;
  min-height: 116px;
  border-bottom: 1px solid #e9edf5;

  .logo-wrapper {
    min-height: 48px;

    img {
      object-fit: contain;
    }
  }

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    padding: 20px 8px 16px;
    .logo-wrapper {
      width: 50%;

      img {
        width: 100%;
      }
    }

    & > * {
      flex: unset;
    }
  }
`;

export const ScrollableContainer = styled.div`
  margin-top: 158px;
  overflow: auto;
  height: calc(100vh - 206px);
`;

export const SAddressFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    span {
      font-size: 12px !important;
    }
  }
`;

export const SLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    background-color: ${palette.gray50};
    border-radius: 4px;
    flex: 1;

    .MuiLinearProgress-bar {
      background-color: ${({ $bgColor }) => $bgColor || palette.green400};
    }
  }
`;
