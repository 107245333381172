import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #e9edf5;
  border-bottom: 1px solid #e9edf5;

  .sub-section:first-child {
    width: ${({ $hideChartSection }) => ($hideChartSection ? '50%' : '30%')};
    height: 100%;
  }

  .sub-section:last-child {
    width: 30%;
    height: 100%;
  }

  .sub-section:nth-child(2) {
    width: ${({ $hideChartSection }) => ($hideChartSection ? '50%' : '40%')};
    height: 100%;
    border-left: 1px solid #e9edf5;
    border-right: ${({ $hideChartSection }) => ($hideChartSection ? '' : `1px solid ${palette.gray50}`)};
    padding: 20px;
  }

  .sub-section-one {
    min-height: 300px;
    margin-right: 20px;
    position: relative;

    .empty-data {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .settings-icon {
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 0;
    }
  }

  .trophy-icon {
    margin-right: 6px;
  }
`;

export const SDriverInfoWrapper = styled.div`
  padding: 20px 0;

  :first-child {
    border-bottom: 1px solid #e9edf5;
  }

  .driver-image {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;

    img {
      object-fit: cover;
      border-radius: 50%;
      cursor: pointer;
    }

    .driver-name {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: #464f60;
    }
  }
`;

export const SInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SChartSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .info {
    display: flex;
    flex-direction: column;
  }

  .row-flex {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
`;

export const SSettlementInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const SChartWrapper = styled.div`
  canvas {
    height: 200px !important;
  }
`;
