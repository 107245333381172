import { getReceivablesCSV } from 'Api/AccountingReceivables';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  is_brokered: false,
  customers: [],
  stopPoints: [],
  groups: [],
  other_customers: [],
};

export const paymentMethods = {
  credit_card: 'Credit Card',
  wire: 'Wire',
  cash: 'Cash',
  ach: 'ACH',
  check: 'Check',
  stripe: 'Online Payment',
  quickbook: 'QuickBooks Online',
};

export const paymentMethodsNumeric = {
  128: 'QuickBooks Online',
  64: 'Wallet',
  32: 'Credit Card',
  4: 'Wire',
  16: 'Cash',
  2: 'ACH',
  8: 'Check',
  1: 'Online Payment',
};

export const filterOptions = [
  { id: 1, label: 'All', value: 'all' },
  { id: 2, label: 'Shipment ID', value: 'shipment_id' },
  { id: 3, label: 'Invoice ID', value: 'invoice_id' },
  { id: 4, label: 'Batch', value: 'batch_id' },
  { id: 5, label: 'Reference ID', value: 'reference_id' },
  { id: 6, label: 'Vehicle ID', value: 'vehicle_id' },
  { id: 7, label: 'Trailer ID', value: 'trailer_id' },
  { id: 8, label: 'Driver', value: 'driver' },
  { id: 9, label: 'Bill of Lading #', value: 'bill_of_lading' },
  { id: 10, label: 'Customer', value: 'customer' },
  { id: 11, label: 'Tags', value: 'group' },
  { id: 12, label: 'Stop Point', value: 'stop_point' },
  { id: 13, label: 'Carrier', value: 'carrier' },
];

export const otherRevenueFilterOptions = [
  { id: 1, label: 'All', value: 'all' },
  { id: 2, label: 'Invoice ID', value: 'invoice_id' },
  { id: 3, label: 'Customer', value: 'customer' },
  { id: 3, label: 'Tags', value: 'group' },
  { id: 4, label: 'Reference ID', value: 'reference_id' },
  { id: 5, label: 'Carrier', value: 'carrier' },
];

const downloadCsv = (csvString) => {
  const url = window.URL.createObjectURL(new Blob([csvString], { type: 'text/csv' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `shipment-billing.csv`);
  document.body.appendChild(link);
  link.click();
};

export const onExportClick = async (data, invoiceType) => {
  try {
    const params = {
      shipment_billing: data.map((i) => (invoiceType === 'ready' ? i.id : i.billing_id)),
      export: invoiceType,
    };

    const res = await getReceivablesCSV(params);
    downloadCsv(res);
  } catch (e) {
    /* empty */
  }
};

export function findNextStop(shipment) {
  let count = 0;

  if (!shipment) {
    return 0;
  }

  const { shipment_stops } = shipment || {};

  shipment_stops?.forEach((stop) => {
    if (!!stop.departure_date && !!stop.arrival_date) {
      count += 1;
    }
  });

  return count;
}
