import React from 'react';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import PhoneInput from 'common/PhoneInput';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';

const Education = ({ form }) => {
  const { values, handleChange, touchedErrors } = form;

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12} md={4}>
          <Input
            required
            name='school_name'
            label='School Name'
            onChange={handleChange}
            value={values.school_name}
            error={touchedErrors.school_name}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            width='100%'
            required
            label='Start Date'
            name='start_date'
            value={values.start_date}
            onChange={(val) => handleChange(`start_date`, val)}
            error={touchedErrors.start_date}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            width='100%'
            required
            label='End Date'
            name='end_date'
            value={values.end_date}
            onChange={(val) => handleChange(`end_date`, val)}
            error={touchedErrors.end_date}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressFields
            required
            hideAddress2
            values={values}
            handleChange={handleChange}
            touchedErrors={touchedErrors}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Input
            required
            name='subject'
            label='What did you study?'
            onChange={handleChange}
            value={values.subject}
            error={touchedErrors.subject}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PhoneInput
            required
            label='Phone Number'
            name='phone_no'
            placeholder='+1 (555) 555-1234'
            onChange={(val) => handleChange('phone_no', val)}
            value={values.phone_no}
            error={touchedErrors.phone_no}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <div className='d-flex gap-2'>
            <DatePicker
              required
              label='Graduation Date'
              name='graduation_date'
              value={values.graduation_date}
              onChange={(val) => handleChange(`graduation_date`, val)}
              error={touchedErrors.graduation_date}
              disabled={!!values.ongoing}
            />
            <div>
              <div style={{ height: 32 }} />
              <CustomCheckbox checked={!!values.ongoing} onChange={(checked) => handleChange('ongoing', checked)}>
                <Typography variant='b1' style={{ marginLeft: 8, color: palette.gray700, whiteSpace: 'nowrap' }}>
                  On-going
                </Typography>
              </CustomCheckbox>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            required
            type='number'
            name='gpa'
            label='GPA'
            onChange={(e) => handleChange(`gpa`, e.target.value)}
            value={values.gpa}
            onKeyDown={blockNonPositiveNumbers}
            error={touchedErrors.gpa}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            required
            type='number'
            name='instruction_hrs'
            label='Hours of Instruction'
            onChange={(e) => handleChange(`instruction_hrs`, e.target.value)}
            value={values.instruction_hrs}
            onKeyDown={blockNonPositiveNumbers}
            error={touchedErrors.instruction_hrs}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>Logbooks used?</InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='log_books_1'
                name='log_books'
                value={1}
                checked={values.log_books === 1}
                onChange={() => handleChange(`log_books`, 1)}
                label='Yes'
              />
              <Radio
                id='log_books_0'
                name='log_books'
                value={0}
                checked={values.log_books === 0}
                onChange={() => handleChange(`log_books`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.log_books} />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>
              Were you subject to the FMCSA or Transport Canada Safety Regulations while attending this school?
            </InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='subject_to_safety_regulations_1'
                name='subject_to_safety_regulations'
                value={1}
                checked={values.subject_to_safety_regulations === 1}
                onChange={() => handleChange(`subject_to_safety_regulations`, 1)}
                label='Yes'
              />
              <Radio
                id='subject_to_safety_regulations_0'
                name='subject_to_safety_regulations'
                value={0}
                checked={values.subject_to_safety_regulations === 0}
                onChange={() => handleChange(`subject_to_safety_regulations`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.subject_to_safety_regulations} />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>
              Did you perform any safety sensitive functions at this school, regulated by DOT, and subject to drug and
              alcohol testing?
            </InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='perform_safety_functions_1'
                name='perform_safety_functions'
                value={1}
                checked={values.perform_safety_functions === 1}
                onChange={() => handleChange(`perform_safety_functions`, 1)}
                label='Yes'
              />
              <Radio
                id='perform_safety_functions_0'
                name='perform_safety_functions'
                value={0}
                checked={values.perform_safety_functions === 0}
                onChange={() => handleChange(`perform_safety_functions`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.perform_safety_functions} />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>Border Crossing</InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='crossed_border_1'
                name='crossed_border'
                value={1}
                checked={values.crossed_border === 1}
                onChange={() => handleChange(`crossed_border`, 1)}
                label='Yes'
              />
              <Radio
                id='crossed_border_0'
                name='crossed_border'
                value={0}
                checked={values.crossed_border === 0}
                onChange={() => handleChange(`crossed_border`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.crossed_border} />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>Federal Motor Carrier Regulations</InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='federal_motor_regulations_1'
                name='federal_motor_regulations'
                value={1}
                checked={values.federal_motor_regulations === 1}
                onChange={() => handleChange(`federal_motor_regulations`, 1)}
                label='Yes'
              />
              <Radio
                id='federal_motor_regulations_0'
                name='federal_motor_regulations'
                value={0}
                checked={values.federal_motor_regulations === 0}
                onChange={() => handleChange(`federal_motor_regulations`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.federal_motor_regulations} />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>Hazardous Materials</InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='hazardous_materials_1'
                name='hazardous_materials'
                value={1}
                checked={values.hazardous_materials === 1}
                onChange={() => handleChange(`hazardous_materials`, 1)}
                label='Yes'
              />
              <Radio
                id='hazardous_materials_0'
                name='hazardous_materials'
                value={0}
                checked={values.hazardous_materials === 0}
                onChange={() => handleChange(`hazardous_materials`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.hazardous_materials} />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Education;
