import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import Radio from 'common/Radio';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import Input, { InputWithText } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { downloadDirectDeposit } from 'utils/helpers';
import { generateAccountToken, saveBankAccount, uploadDocument, uploadDocumentPublic } from 'Api/Carriers';
import { palette } from 'utils/constants';
import { Typography } from '../../Typography';

const PaymentMethod = ({ form, carrierId, onboardingData, isOnboarding, carrier }) => {
  const showToaster = useShowToaster();
  const [plaidToken, setPlaidToken] = useState(null);
  const { values, handleChange, setValues, touchedErrors } = form;

  const uploadDepositForm = async (file) => {
    try {
      const formData = new FormData();
      formData.append('carrier_id', carrierId);
      formData.append('source', 'general');
      formData.append('file_upload', file);

      const data = onboardingData
        ? await uploadDocumentPublic(formData, onboardingData)
        : await uploadDocument(formData);
      handleChange('bank_direct_deposit_form', data.document_path);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const handleConnectAccount = async (public_token, metadata) => {
    try {
      const { data, bank_account_verified, bank_account_mismatch_reason } = await saveBankAccount(
        onboardingData.dot,
        onboardingData.token,
        { public_token, metadata }
      );
      setValues((prevState) => ({
        ...prevState,
        bank_name: data.bank_name || '',
        bank_routing: data.routing_number || '',
        routing_confirmed: data.routing_number || '',
        bank_account: data.account_number || '',
        account_confirmed: data.account_number || '',
        bank_account_verified,
        bank_account_mismatch_reason,
      }));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getPlaidToken = async () => {
    try {
      const { data } = await generateAccountToken(onboardingData.dot, onboardingData.token);
      setPlaidToken(data.link_token);
    } catch (e) {
      // Do nothing
    }
  };

  const { open: openPlaid, ready } = usePlaidLink({
    onSuccess: handleConnectAccount,
    token: plaidToken,
  });

  useEffect(() => {
    if (isOnboarding && !ready) {
      getPlaidToken();
    }
  }, []);

  const onAchSelect = () => {
    handleChange('payment_method', 'ach');
  };

  const disconnectAccount = () => {
    handleChange('bank_name', '');
    handleChange('bank_routing', '');
    handleChange('bank_account', '');
    handleChange('routing_confirmed', '');
    handleChange('account_confirmed', '');
    handleChange('bank_account_verified', false);
  };

  return (
    <div className='d-flex flex-column gap-2 mt-3'>
      <div className='d-flex align-items-center gap-4'>
        <Radio
          label='Check'
          name='payment_method'
          value='check'
          checked={values.payment_method === 'check'}
          onChange={() => {
            handleChange('payment_method', 'check');
            handleChange('bank_account_verified', false);
          }}
        />
        <Radio
          label='ACH'
          name='payment_method'
          value='ach'
          checked={values.payment_method === 'ach'}
          onChange={onAchSelect}
        />
        {!isOnboarding && (
          <Radio
            label='3rd Party'
            name='payment_method'
            value='third_party'
            checked={values.payment_method === 'third_party'}
            onChange={() => handleChange('payment_method', 'third_party')}
          />
        )}
      </div>
      {values.payment_method === 'ach' && (
        <div className='d-flex gap-3 mb-2'>
          <div className='d-flex flex-column gap-3'>
            <InputWithText
              left
              className='w-100'
              name='bank_name'
              label='Bank Details'
              text='Bank Name'
              value={values.bank_name}
              onChange={handleChange}
              disabled={isOnboarding}
            />
            <div className='d-flex gap-2'>
              <InputWithText
                left
                textWidth='96px'
                width='200px'
                name='bank_routing'
                text='Routing #'
                value={values.bank_routing}
                onChange={handleChange}
                disabled={isOnboarding}
              />
              {!isOnboarding && (
                <Input
                  name='routing_confirmed'
                  placeholder='Re-enter Routing #'
                  value={values.routing_confirmed}
                  onChange={handleChange}
                  error={touchedErrors.routing_confirmed}
                />
              )}
            </div>
            <div className='d-flex gap-2'>
              <InputWithText
                left
                textWidth='96px'
                width='200px'
                type='number'
                name='bank_account'
                text='Account #'
                value={values.bank_account}
                onChange={handleChange}
                disabled={isOnboarding}
              />
              {!isOnboarding && (
                <Input
                  name='account_confirmed'
                  placeholder='Re-enter Account #'
                  value={values.account_confirmed}
                  onChange={handleChange}
                  error={touchedErrors.account_confirmed}
                />
              )}
            </div>
          </div>
          {!isOnboarding && (
            <div>
              <InputLabel>
                Upload Direct Deposit Form{' '}
                <span className='download-sample' onClick={downloadDirectDeposit}>
                  Download Sample
                </span>
              </InputLabel>
              <Uploader
                width='350px'
                document={values.bank_direct_deposit_form}
                onDrop={(files) => uploadDepositForm(files[0])}
                onRemove={() => handleChange('bank_direct_deposit_form', null)}
              />
            </div>
          )}
        </div>
      )}
      {values.payment_method === 'check' && isOnboarding && (
        <Typography variant='s2' style={{ color: palette.gray700, marginLeft: '8px' }}>
          Checks will be mailed to
          {values.payFactoring
            ? ` ${values.address} ${values.city?.name || ''} ${values.city ? ',' : ''} ${
                values.state?.short_name || ''
              } ${values.zipcode || ''}  ${values.country?.name || ''}`
            : ` ${carrier.address1} ${carrier.city?.name || ''}, ${carrier.state?.short_name || ''} ${
                carrier.zipcode || ''
              }  ${carrier.country?.name || ''}`}
        </Typography>
      )}

      {values.payment_method === 'ach' && isOnboarding && (
        <div className='mt-2'>
          <CustomButton
            type={values?.bank_name ? 'danger' : 'primary'}
            title={values?.bank_name ? 'Disconnect' : 'Connect Account'}
            onClick={values?.bank_name ? disconnectAccount : openPlaid}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
          />
        </div>
      )}
      {values.payment_method === 'third_party' && (
        <div className='d-flex flex-column gap-3'>
          <Input
            width='350px'
            label='3rd Party Name'
            name='third_party_name'
            value={values.third_party_name}
            onChange={handleChange}
          />
          <Input
            width='350px'
            label=' 3rd Party Identifier'
            name='third_party_id'
            value={values.third_party_id}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
