import axios from '../services/axios';

export async function GetSupport({ page, itemsPerPage, query, status_id, ...rest }) {
  const params = {
    page,
    itemsPerPage,
    ...rest,
  };
  if (query) params.query = query;
  if (Array.isArray(status_id) && status_id?.length !== 0 && status_id[0] !== 0) params.status_id = status_id;
  const { data } = await axios.get('/support', {
    params,
  });
  return data;
}

export async function GetSupportStatus({ query }) {
  const { data } = await axios.get('/support/status', {
    params: {
      ...(!!query && { query }),
    },
  });
  return data;
}

export async function CreateSupport(values) {
  const { data } = await axios.post('/support', values);
  return data;
}

export async function GetSupportComment(id) {
  const { data } = await axios.get(`support/comment/${id}`);
  return data;
}

export async function CreateSupportComment(jsonData) {
  const { data } = await axios.post('/support/comment', jsonData);
  return data;
}

export async function uploadSupportAttachment(body) {
  const { data } = await axios.post(`support/image`, body);
  return data;
}

export async function getSupportTicketTypes() {
  const { data } = await axios.get('/support/types');
  return data;
}

export async function getSupportTicketPriorities() {
  const { data } = await axios.get('/support/priorities');
  return data;
}
