import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .origin-input {
    input {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      padding: 5px 12px 6px;
      margin-right: 4px;
    }
  }

  .location-inputs {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .custom-date-picker-input {
    margin-top: 0;
    width: unset;

    span {
      white-space: nowrap;
    }
  }
`;

export const SDatePicker = styled(DatePicker)`
  width: 200px;
  font-size: 14px;
  box-shadow: ${palette.boxShadow2};
  border-radius: 6px;
  padding: 5px 12px 6px;
`;
