import { array, number, object, ref, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  customer: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  reference_id: string().trim().required('Required'),
  terms: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  bill_date: validator.requiredDate().max(ref('due_date'), "Billed date can't be after due date!").nullable(),
  due_date: validator.requiredDate().min(ref('bill_date'), '').nullable(),
  items: array()
    .of(
      object({
        item: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        desc: string().trim().required('Required'),
        quantity: number().required('Required').nullable(),
        price: number().required('Required').nullable(),
      })
    )
    .min(1, 'Required'),
  recurring_days: number().when('is_recurring', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Max 30', (value) => {
        return value > 0 && value <= 30;
      })
      .nullable(),
  }),
  recurring_months: object().when('is_recurring', {
    is: true,
    then: object()
      .shape({ name: string().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  recurring_duration: number().when('is_recurring', {
    is: true,
    then: number().required('Required').nullable(),
  }),
  recurring_frequency: number().when(['is_recurring', 'recurring_duration'], {
    is: (is_recurring, recurring_duration) => !!is_recurring && recurring_duration === 2,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value >= 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  payment_method: string().when('is_auto_debt_enabled', {
    is: true,
    then: string().required('Required').nullable(),
  }),
});
