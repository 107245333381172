export const stopPointMapper = {
  'Custom ID': 'stop_point_id',
  'Location Name': 'location_name',
  Address1: 'address1',
  Address2: 'address2',
  Country: 'country',
  State: 'state',
  City: 'city',
  Zip: 'zipcode',
  'Require Scale/Tickets': 'require_scale_tickets',
  'Require Lumper Receipt': 'require_lumper_receipt',
};
