import { palette } from 'utils/constants';

export const STOP_POINT_TYPES = {
  1: { label: 'PICK UP', className: 'pickUp', bgColor: palette.green500, color: palette.white },
  2: { label: 'DELIVERY', className: 'delivery', bgColor: palette.red500, color: palette.white },
  3: { label: 'WAYPOINT', className: 'wayPoint', bgColor: palette.blueText, color: palette.white },
};

export const BID_STATUS = {
  1: { label: 'BID SUBMITTED', bgColor: palette.orange0, color: palette.orange500 },
  2: { label: 'DECLINED', bgColor: palette.red0, color: palette.red500 },
  3: { label: 'AWARDED', bgColor: palette.green0, color: palette.green500 },
  4: { label: 'AWARD ACCEPTED', bgColor: palette.green0, color: palette.green500 },
  6: { label: 'BID DECLINED', bgColor: palette.red0, color: palette.red500 },
};

export const PROGRESS_COLOR = {
  1: palette.orange500,
  2: palette.red500,
  3: palette.green400,
  4: palette.green400,
  6: palette.red500,
};
