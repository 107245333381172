import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Chip from 'common/Chip';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatNumber, palette } from 'utils/constants';
import { GetPurchaseAddonList } from 'Api/Billing';
import { STabs } from 'components/Billing/PurchaseAddOnsModal/PurchaseAddOnsModal.styles';
import styles from 'components/Billing/PurchaseAddOnsModal/PurchaseAddOnsModal.module.css';
import { planType } from 'components/customerOnboardingComponents/data';
import { SAddonsWrapper, SWrapper } from './Addons.styles';

export const CARRIER_TRIAL_ADDON = {
  PAYROLL: 'lOaIsp',
  ACCOUNTING: 'AflQ6N',
  SAFETY: 'kbfYBN',
  IFTA: 'ADy9CN',
};

export const CARRIER_BROKER_TRIAL_ADDON = {
  BROKERAGE: 'qkxwy6',
  PAYROLL: 'lOaIsp',
  ACCOUNTING: 'AflQ6N',
  SAFETY: 'kbfYBN',
  IFTA: 'ADy9CN',
};

const Addons = () => {
  const navigate = useNavigate();
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('All');
  const [categories, setCategories] = useState([]);
  const [guide, setGuide] = useState(null);
  const planSelected = JSON.parse(localStorage.getItem('plan-selected'));
  const brokerInfo = JSON.parse(localStorage.getItem('brokerInfo'));
  const label = planType[planSelected?.duration]?.addonBillTitle;
  const freeTrialAddons = brokerInfo ? CARRIER_BROKER_TRIAL_ADDON : CARRIER_TRIAL_ADDON;

  const getAddons = async () => {
    setLoading(true);
    try {
      const { data } = await GetPurchaseAddonList();
      const categories = data.reduce(
        (acc, cur) => {
          if (!cur.category || acc.some((item) => item === cur.category)) {
            return acc;
          }
          return [...acc, cur.category];
        },
        ['All']
      );
      const sortedAddons = data.sort((a, b) => {
        if (Object.values(freeTrialAddons).includes(a.plan_id) && Object.values(freeTrialAddons).includes(b.plan_id)) {
          return 1;
        }

        if (Object.values(freeTrialAddons).includes(a.plan_id) || Object.values(freeTrialAddons).includes(b.plan_id)) {
          return -1;
        }

        return 0;
      });

      setCategories(categories);
      setAddons(sortedAddons);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  // const onGoBack = () => {
  //   navigate('/onboarding/company', { replace: true });
  // };

  const onContinue = () => {
    navigate('/onboarding/account-summary');
  };

  const onViewGuide = (addon) => {
    if (addon.guide_type === 'pdf') {
      setGuide(addon.guide);
      return;
    }

    window.open(addon.guide, '_blank');
  };

  useEffect(() => {
    getAddons();
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <div className='addons-header'>
        <Typography variant='h4' style={{ color: palette.gray900 }}>
          Add-Ons
        </Typography>
      </div>
      <Divider color={palette.gray200} margin='8px 0' />
      <div className='d-flex justify-content-between'>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          Addons are optional and can be enabled / disabled after you have created your account.
        </Typography>
        <div className='addon-actions'>
          {/* <CustomButton */}
          {/*  title='Go Back' */}
          {/*  type='primary' */}
          {/*  styleTitle={{ fontSize: 14, fontWeight: 500 }} */}
          {/*  styleButton={{ padding: '6px 12px', margin: 0 }} */}
          {/*  onClick={onGoBack} */}
          {/* /> */}
          <CustomButton
            title='Continue'
            type='primary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={onContinue}
          />
        </div>
      </div>
      <SAddonsWrapper>
        <STabs value={tab} onChange={(e, value) => setTab(value)} variant='scrollable' scrollButtons={false}>
          {categories.map((item) => (
            <Tab
              key={item}
              value={item}
              disableRipple
              label={
                <span style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                  {item}{' '}
                  <Chip
                    label={item === 'All' ? addons.length : addons?.filter((i) => i.category === item)?.length || 0}
                    style={{ borderRadius: '12px' }}
                  />
                </span>
              }
            />
          ))}
        </STabs>
        {(tab === 'All' ? addons : addons.filter((i) => i.category === tab))?.map((item) => (
          <div key={item?.id} className={styles.item_wrapper}>
            <div className={styles.image_wrapper}>
              <figure className={styles.figure}>
                <img className={styles.image} src={item?.image} alt='Img' />
              </figure>
            </div>
            <div className={styles.info_wrapper}>
              <div className={styles.center}>
                <div className={styles.title_subtitle}>
                  <Typography variant='h5' style={{ color: palette.gray900 }}>
                    {item?.name}
                  </Typography>
                  <Typography variant='b2'>{item?.description}</Typography>
                </div>
                <div className={styles.plans}>
                  {item?.plans?.map((plan) => (
                    <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }} key={plan.id}>
                      {plan?.plan_title},
                      <Typography
                        variant='s2'
                        style={{
                          color: palette.gray500,
                          marginLeft: 5,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {Number(plan?.monthly_amount) === 0
                          ? `Included with plan`
                          : `$${formatNumber(Number(plan?.monthly_amount))} ${label}`}{' '}
                        {!!plan?.setup_one_time_fee &&
                        plan?.setup_one_time_fee !== '0.00' &&
                        plan?.setup_one_time_fee !== '0'
                          ? `+ One Time Setup Fee $${formatNumber(plan?.setup_one_time_fee)}`
                          : ''}
                      </Typography>
                    </Typography>
                  ))}
                </div>
              </div>
              <div>
                {Object.values(freeTrialAddons).includes(item.plan_id) && (
                  <Chip label='Included in free trial' labelColor={palette.green500} bgColor={palette.green0} />
                )}
                {item.guide && (
                  <CustomButton
                    type='secondary'
                    title='View Guide'
                    styleButton={{ padding: '6px 12px', marginTop: '8px' }}
                    styleTitle={{ fontSize: 14, whiteSpace: 'nowrap' }}
                    onClick={() => onViewGuide(item)}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </SAddonsWrapper>
      <ViewPdf open={!!guide} title='Guide' pdfUrl={guide} onClose={() => setGuide(null)} />
    </SWrapper>
  );
};

export default Addons;
