import React from 'react';
import PhoneInput from 'common/PhoneInput';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import Input, { InputWithText } from 'common/Input';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import AddressFields from 'pages/CardManagement/TruckinDigitalCashCard/steps/BasicInfo/AddressFields';
import { palette } from 'utils/constants';

const Verification = ({ form, setCurrentStep }) => {
  const { values, handleChange, touchedErrors, handleSubmit } = form;

  return (
    <div className='d-flex flex-column gap-4' style={{ width: '400px' }}>
      <div className='d-flex flex-column' style={{ marginTop: '32px', gap: '2px' }}>
        <Typography variant='h2'>Verify you represent this business</Typography>
        <Typography variant='s3' style={{ color: palette.gray500 }}>
          This form must be filled out by someone with significant control and management of your business. If that’s
          not you, make sure to ask the right person to continue.
        </Typography>
      </div>
      <div>
        <Input
          name='first_name'
          placeholder='First name'
          label='Legal name'
          onChange={handleChange}
          value={values.first_name}
          error={touchedErrors.first_name}
          style={{ marginBottom: '8px' }}
          required
        />
        <Input
          name='last_name'
          placeholder='Last name'
          onChange={handleChange}
          value={values.last_name}
          error={touchedErrors.last_name}
          required
        />
        <Typography variant='c2' style={{ color: palette.gray500 }}>
          Enter your name exactly as it is recorded with the IRS.
        </Typography>
      </div>
      <Input
        label='Email address'
        placeholder='executive@example.com'
        name='email'
        value={values.email}
        onChange={handleChange}
        error={touchedErrors.email}
        required
      />
      <Input
        label='Job title'
        placeholder='CEO, Manager, Partner'
        name='job_title'
        value={values.job_title}
        onChange={handleChange}
        error={touchedErrors.job_title}
        required
      />
      <div>
        <DatePicker
          label='Date of birth'
          width='185px'
          name='date_of_birth'
          value={values.date_of_birth}
          onChange={(val) => handleChange('date_of_birth', val)}
          error={touchedErrors.date_of_birth}
          disableFuture
        />
      </div>
      <div>
        <AddressFields label='Home Address' form={form} />
      </div>
      <PhoneInput
        name='phone_number'
        placeholder='+1 (555) 555-1234'
        label='Phone number'
        onChange={(val) => handleChange('phone_number', val)}
        value={values.phone_number}
        error={touchedErrors.phone_number}
        required
      />
      <div>
        <InputWithText
          left
          style={{ width: '100px' }}
          label='Last 4 digits of Social Security number'
          className='w-100'
          text='•••—••—'
          name='last4'
          onChange={handleChange}
          value={values.last4}
          error={touchedErrors.last4}
          placeholder='8888'
          maxLength='4'
          required
        />
      </div>
      <CustomCheckbox
        name='is_25_percent_owner'
        checked={values.is_25_percent_owner}
        onChange={(checked) => handleChange('is_25_percent_owner', checked ? 1 : 0)}
      >
        <Typography
          variant='s2'
          style={{
            color: palette.gray700,
            marginLeft: 8,
            whiteSpace: 'nowrap',
          }}
        >
          I own 25% or more of the company.
        </Typography>
      </CustomCheckbox>
      {!!values.is_25_percent_owner && (
        <div className='d-flex flex-column align-items-center gap-1'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Are you the only person who owns 25% or more of the company?
          </Typography>
          <div className='d-flex gap-4 w-100'>
            <CustomRadioButton
              field={{
                name: 'is_the_only_25_percent_owner',
                value: 1,
                onChange: () => handleChange('is_the_only_25_percent_owner', 1),
                checked: values.is_the_only_25_percent_owner === 1,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                Yes
              </Typography>
            </CustomRadioButton>
            <CustomRadioButton
              field={{
                name: 'is_the_only_25_percent_owner',
                value: 2,
                onChange: () => handleChange('is_the_only_25_percent_owner', 2),
                checked: values.is_the_only_25_percent_owner === 2,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                No
              </Typography>
            </CustomRadioButton>
          </div>
        </div>
      )}
      <div className='d-flex flex-column gap-3 pb-5'>
        <CustomButton
          height={12}
          type='primary'
          title='Continue'
          styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
          onClick={() => {
            handleSubmit(values);
          }}
        />
        <CustomButton
          height={12}
          type='secondary'
          title='Back'
          styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
          onClick={() => setCurrentStep(1)}
        />
      </div>
    </div>
  );
};

export default Verification;
