import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { SFlexColumn } from '../Shipments.styles';

const ShipmentId = ({ data }) => {
  const { use } = useTheme();

  return (
    <SFlexColumn>
      <Typography variant='s2'>
        {data.shipment_id} {data.shipment_type === 'ltl' && 'LTL'}
      </Typography>
      {data.shipment_billing.length > 1 ? (
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='button-tooltip-2'>
              {data.shipment_billing.map((el) => (
                <p key={el.id} style={{ margin: 0 }}>
                  {el?.reference_id}
                </p>
              ))}
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div ref={ref}>
              <Typography {...triggerHandler} variant='b2' style={{ color: use(palette.dark800, palette.gray200) }}>
                Multi-Ref
              </Typography>
            </div>
          )}
        </OverlayTrigger>
      ) : (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          Ref: {data.shipment_billing[0].reference_id}
        </Typography>
      )}
    </SFlexColumn>
  );
};

export default ShipmentId;
