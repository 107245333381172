import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fade, Popper } from '@mui/material';
import { ReactComponent as OpenArrow } from 'assets/icons/arrowDown.svg';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { palette, statusColor } from 'utils/constants';
import UserInfo from '../UserInfo';
import TimeWorked from '../TimeWorked';
import TableActions from '../TableActions';
import { staffStatus } from '../../TimeTracking.data';
import { getTimeTracked } from '../../TimeTracking.convertors';
import RowActions from './RowActions';
import { SAccordionContainer, SNameImage, SSubTableContainer } from './TableAccordion.styles';

const TableAccordion = ({ row, toggleAccordion, onUpdateTimeTracking, company, dateRange, onDelete }) => {
  const { use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { first_name, last_name } = row || {};

  const onMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onRowClick = (rowData, index) => {
    onUpdateTimeTracking({
      ...rowData,
      start_time: convertToCustomerTime(rowData.start_time, 'MM/DD/YYYY hh:mm A'),
      end_time: rowData?.end_time ? convertToCustomerTime(rowData.end_time, 'MM/DD/YYYY hh:mm A') : null,
      tableIndex: index,
      accordianIndex: rowData.id,
    });
  };

  return (
    <div style={{ backgroundColor: row.isActive ? palette.indigo0 : '' }}>
      <SAccordionContainer>
        <div className='accordion-container-sub' onClick={() => toggleAccordion(row)}>
          <OpenArrow fill={palette.gray500} />
          <SNameImage onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Popper open={open} anchorEl={anchorEl} placement='top-start' transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <UserInfo data={row} />
                </Fade>
              )}
            </Popper>
            <div className='profile-image-container'>
              <img src={row.image || row.profile_logo || userDefault} alt='' width={32} height={32} />
              <div
                className='profile-active-indicator'
                style={{
                  backgroundColor:
                    row.user_type === 2
                      ? use(
                          statusColor[row?.status_data?.driver_status]?.color,
                          statusColor[row?.status_data?.driver_status]?.darkColor
                        )
                      : use(
                          statusColor[staffStatus[row?.login_status]]?.color,
                          statusColor[staffStatus[row?.login_status]]?.darkColor
                        ),
                }}
              />
            </div>
            <Typography variant='b2' style={{ color: use(palette.dark700, palette.gray200) }}>
              {first_name} {last_name}
            </Typography>
          </SNameImage>
        </div>
        <TimeWorked data={row} onClick={() => toggleAccordion(row)} />
        <Typography
          variant='b2'
          style={{ color: use(palette.gray700, palette.gray200) }}
          onClick={() => toggleAccordion(row)}
        >
          {currency}
          {row?.compensation_details?.pay_per_hour || 'N/A'}
        </Typography>
        <Typography
          variant='b2'
          style={{ color: use(palette.gray700, palette.gray200) }}
          onClick={() => toggleAccordion(row)}
        >
          {currency}
          {((row.totalHours + row.totalMinutes / 60) * Number(row.compensation_details?.pay_per_hour)).toFixed(2)}
        </Typography>
        <TableActions data={row} company={company} dateRange={dateRange} />
      </SAccordionContainer>
      {row.isActive && (
        <SSubTableContainer
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          onClick={(e) => e.stopPropagation()}
        >
          <table className='custom-table-wraper'>
            <thead className='custom-table-header-wraper'>
              {row?.trackingTableData?.length === 0 ? (
                <p className='on-empty-data'>No data found</p>
              ) : (
                <tr className='custom-table-row-wraper custom-table-row-wraper-header'>
                  <td>ID</td>
                  <td>HOURS TRACKED</td>
                  <td>START TIME</td>
                  <td>START LOCATION</td>
                  <td>END TIME</td>
                  <td>END LOCATION</td>
                  <td />
                </tr>
              )}
            </thead>
            <tbody>
              {row?.time_trackings?.map((rowData, index) => {
                return (
                  <tr className='custom-table-row-wraper' key={rowData.id} onClick={() => onRowClick(rowData, index)}>
                    <td>
                      <span>{rowData?.id}</span>
                    </td>
                    <td>
                      <span
                        onClick={() => onRowClick(rowData, index)}
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        {getTimeTracked(rowData)}
                      </span>
                    </td>
                    <td>
                      <p
                        onClick={() => onRowClick(rowData, index)}
                        className='table-data-text'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        {convertToCustomerTime(rowData?.start_time)}
                      </p>
                      {rowData?.break === 1 ? (
                        <Chip
                          size='small'
                          label='BREAK START'
                          labelColor={palette.orange500}
                          bgColor={palette.orangeBackground1}
                          fontSize='11px'
                          fontWeight={500}
                        />
                      ) : (
                        <Chip
                          size='small'
                          label='CLOCKED IN'
                          labelColor={palette.green500}
                          bgColor={palette.green0}
                          fontSize='11px'
                          fontWeight={500}
                        />
                      )}
                    </td>
                    <td>
                      <div onClick={() => onRowClick(rowData, index)}>
                        {rowData?.start_time_street && (
                          <p
                            className='table-data-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {rowData?.start_time_street},
                          </p>
                        )}
                        {rowData?.start_time_city && (
                          <p
                            className='table-data-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {rowData?.start_time_city}, {rowData?.start_time_state_code},
                          </p>
                        )}
                        {rowData?.start_time_country && (
                          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                            {rowData?.start_time_country}
                          </p>
                        )}
                        {!rowData?.start_time_street && !rowData?.start_time_city && !rowData?.start_time_country && (
                          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                            -
                          </p>
                        )}
                      </div>
                    </td>
                    <td>
                      <div onClick={() => onRowClick(rowData, index)}>
                        {rowData?.end_time ? (
                          <>
                            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                              {convertToCustomerTime(rowData?.end_time)}
                            </p>
                            {rowData?.break === 1 ? (
                              <Chip
                                size='small'
                                label='BREAK END'
                                labelColor={use(palette.orangeBackground1, palette.red400)}
                                bgColor={palette.orange500}
                                fontSize='11px'
                                fontWeight={500}
                              />
                            ) : (
                              <Chip
                                size='small'
                                label='CLOCKED OUT'
                                labelColor={use(palette.red400, palette.red400)}
                                bgColor={palette.red0}
                                fontSize='11px'
                                fontWeight={500}
                              />
                            )}
                          </>
                        ) : (
                          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                            -
                          </p>
                        )}
                      </div>
                    </td>
                    <td>
                      <div onClick={() => onRowClick(rowData, index)}>
                        {rowData?.end_time_street && (
                          <p
                            className='table-data-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {rowData?.end_time_street},
                          </p>
                        )}
                        {rowData?.end_time_city && (
                          <p
                            className='table-data-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {rowData?.end_time_city}, {rowData?.end_time_state_code},
                          </p>
                        )}
                        {rowData?.end_time_country && (
                          <p
                            className='table-data-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {rowData?.end_time_country}
                          </p>
                        )}
                        {!rowData?.end_time_street && !rowData?.end_time_city && !rowData?.end_time_country && (
                          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                            -
                          </p>
                        )}
                      </div>
                    </td>
                    <td>
                      <RowActions onDelete={() => onDelete(rowData.id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SSubTableContainer>
      )}
    </div>
  );
};

export default TableAccordion;
