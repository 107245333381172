import React from 'react';
import { palette } from 'utils/constants';
import { SWrapper } from './Radio.styles';

const Radio = ({ wrapperClassName, label, gap, id, value, disabled, labelColor, ...props }) => {
  return (
    <SWrapper className={`label ${wrapperClassName || ''}`} $disabled={disabled}>
      <input value={value} id={id || value} type='radio' disabled={disabled} {...props} />
      <span className='checkmark' />
      {!!label && (
        <label htmlFor={id || value} style={{ marginLeft: gap || '6px', color: labelColor || palette.gray900 }}>
          {label}
        </label>
      )}
    </SWrapper>
  );
};

export default Radio;
