import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Chip from 'common/Chip';
import Search from 'common/Search';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import useDateFormat from 'hooks/useDateFormat';
import { getChangeLog } from 'Api/CompanySettings';
import { STable, STableRow, SWrapper } from './ChangeLog.styles';

const ChangeLog = () => {
  const { convertToCustomerTime, formatDate } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [changeLog, setChangeLog] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState(null);
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });
  const debouncedSearch = useDebounce(search, 500);

  const getChangeLogData = async () => {
    try {
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'sort[][created_at]': 'desc',
        query: debouncedSearch || undefined,
        from_date: dateRange.start ? moment(dateRange.start).format('MM/DD/YYYY') : undefined,
        to_date: dateRange.end ? moment(dateRange.end).format('MM/DD/YYYY') : undefined,
      };

      const response = await getChangeLog(params);
      setChangeLog(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    setLoading(true);
    getChangeLogData();
  }, [selectedFilters, debouncedSearch, dateRange]);

  return (
    <SWrapper>
      <div>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Change Logs
        </Typography>
        <div className='d-flex align-items-center gap-3 mt-4 mb-3'>
          <DateRangePicker top='-108px' dateRange={dateRange} initialRangeName='All Time' setDateRange={setDateRange} />
          <Search search={search} onChange={setSearch} />
        </div>
      </div>
      <div />
      {loading ? (
        <TableSkeleton />
      ) : (
        <STable>
          <thead>
            <tr>
              <th>CHANGED FROM / TO</th>
              <th>CHANGED SECTION</th>
              <th>CHANGED BY</th>
            </tr>
          </thead>
          <tbody>
            {changeLog.data.map((item) => (
              <STableRow key={item.id}>
                <td>
                  <div className='d-flex align-items-center gap-3' style={{ color: palette.gray700 }}>
                    <Chip
                      disableRipple
                      onClick={
                        item.change_type === 'image' && item.change_from ? () => setPdfUrl(item.change_from) : undefined
                      }
                      label={
                        item.change_type === 'date' && item.change_from
                          ? formatDate(item.change_from)
                          : item.change_type === 'image' && item.change_from
                          ? 'VIEW OLD ATTACHMENT'
                          : item.change_from || '-'
                      }
                    />{' '}
                    <ArrowRight fill={palette.gray500} />{' '}
                    <Chip
                      disableRipple
                      onClick={
                        item.change_type === 'image' && item.change_to ? () => setPdfUrl(item.change_to) : undefined
                      }
                      label={
                        item.change_type === 'date' && item.change_to
                          ? formatDate(item.change_to)
                          : item.change_type === 'image' && item.change_to
                          ? 'VIEW NEW ATTACHMENT'
                          : item.change_to || '-'
                      }
                    />
                  </div>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.change_description || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    By {item?.change_by_user_details?.first_name} {item?.change_by_user_details?.last_name} on{' '}
                    {convertToCustomerTime(item.created_at)}
                  </Typography>
                </td>
              </STableRow>
            ))}
          </tbody>
        </STable>
      )}
      {!loading && !changeLog.data?.length && <NoRecords />}
      <Pagination
        data={changeLog}
        rowPerPage={selectedFilters.itemsPerPage}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
        rowsPerPageOptions={[25, 50, 100, 150]}
      />
      <ViewPdf open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
    </SWrapper>
  );
};

export default ChangeLog;
