import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  padding: 4px 16px;
  background: #5371cc;
}

;

h4 {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${palette.white};
}
`;

export const SContent = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 4px 8px;
  border: 2px solid ${palette.gray50};
  border-top: none;
  background: ${palette.gray0};

  .info {
    width: 65%;
    font-size: 13px !important;
  }

  .answer {
    width: 35%;
    font-size: 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
