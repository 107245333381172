import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { getGroupShortName } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { createGroup, updateGroup } from 'Api/CompanySettings';
import { getInitialValues } from './AddGroup.data';
import { validationSchema } from './validationSchema';
import { SFormWrapper } from './AddGroup.styles';

const AddGroup = ({ open, onClose, onSuccess, group }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        group_name: values.group_name,
        short_name: values.short_name || undefined,
        color: values.color,
      };

      let groupData;

      if (group) {
        const { data } = await updateGroup(group.id, body);
        groupData = data || null;
      } else {
        const { data } = await createGroup(body);
        groupData = data || null;
      }

      showToaster({ type: 'success', message: `Tag has been successfully ${group ? 'updated' : 'created'}` });
      onSuccess(groupData);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, touchedErrors, handleSubmit } = useForm({
    initialValues: getInitialValues(group),
    validationSchema,
    onSubmit,
  });

  const handleNameChange = (value) => {
    handleChange('group_name', value);
    if (!value) {
      handleChange('short_name', '');
      return;
    }

    const shortName = getGroupShortName(value);
    handleChange('short_name', shortName);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={group ? 'Update Tag' : 'Add Tag'}
      $bgColor={palette.gray0}
      $maxWidth='440px'
      $minWidth='440px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: group ? 'Update' : 'Add Tag',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <SFormWrapper>
        <div>
          <InputLabel required>Tag Name</InputLabel>
          <div className='merged-inputs'>
            <Input
              className='name-input w-100'
              name='group_name'
              value={values.group_name}
              onChange={(e) => handleNameChange(e.target.value)}
            />
            <Input
              className='color-input'
              name='color'
              width='100px'
              type='color'
              value={values.color}
              onChange={handleChange}
            />
          </div>
          <ErrorMessage error={touchedErrors.group_name} />
        </div>
        <Input
          width='200px'
          required
          maxLength={4}
          label='Short Name'
          name='short_name'
          onChange={handleChange}
          value={values.short_name}
          error={touchedErrors.short_name}
        />
      </SFormWrapper>
    </Modal>
  );
};

export default AddGroup;
