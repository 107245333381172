import moment from 'moment/moment';

export const updateStaticDocumentConverter = (values, doc, carrierId) => {
  const formData = new FormData();
  formData.append(`carrier_id`, carrierId);

  switch (doc.type) {
    case 'master_agreement':
      formData.append(`master_agreement`, values.doc);
      break;
    case 'w9':
      formData.append(`w9`, values.doc);
      break;
    case 'authority':
      formData.append(`authority`, values.doc);
      break;
    case 'reference_sheet':
      formData.append(`reference_sheet`, values.doc);
      break;
    case 'liability_insurance':
      if (typeof values.doc !== 'string') {
        formData.append(`liability_insurance`, values.doc);
      }
      formData.append(
        `liability_insurance_exp_date`,
        values.no_expiry ? '' : moment(values.expire_date).format('YYYY/MM/DD')
      );
      break;
    case 'cargo_insurance':
      if (typeof values.doc !== 'string') {
        formData.append(`cargo_insurance`, values.doc);
      }
      formData.append(
        `cargo_insurance_exp_date`,
        values.no_expiry ? '' : moment(values.expire_date).format('YYYY/MM/DD')
      );
      break;
    case 'general_liability':
      if (typeof values.doc !== 'string') {
        formData.append(`general_liability`, values.doc);
      }
      formData.append(
        `general_liability_exp_date`,
        values.no_expiry ? '' : moment(values.expire_date).format('YYYY/MM/DD')
      );
      break;

    case 'trailer_interchange_and_indemnity_agreement':
      if (typeof values.doc !== 'string') {
        formData.append(`trailer_interchange_and_indemnity_agreement`, values.doc);
      }
      formData.append(
        `trailer_interchange_expires`,
        values.no_expiry ? '' : moment(values.expire_date).format('YYYY/MM/DD')
      );
      break;
    default:
  }

  return formData;
};
