export const assignDriverFromOptions = [
  { id: 1, name: 'Current Location' },
  { id: 2, name: 'Previous Shipment' },
  { id: 3, name: 'Planned Shipment' },
  { id: 4, name: 'Custom Location' },
];

export const getInitialValues = (data) => {
  const {
    immediately,
    dispatch_next_in_line,
    dispatch_driver_hours_before_scheduled,
    send_tracking_updates,
    send_driver_reminder,
    driver_from_location,
  } = data || {};
  return {
    immediately: !!immediately || false,
    dispatch_next_in_line: !!dispatch_next_in_line || false,
    is_dispatch_driver_hours_before_scheduled: !!dispatch_driver_hours_before_scheduled || false,
    dispatch_driver_hours_before_scheduled: dispatch_driver_hours_before_scheduled || '',
    send_tracking_updates: !!send_tracking_updates || false,
    is_send_driver_reminder: !!send_driver_reminder || false,
    send_driver_reminder: send_driver_reminder || '',
    driver_from_location: driver_from_location
      ? assignDriverFromOptions.find((item) => item.id === Number(driver_from_location))
      : assignDriverFromOptions[0],
  };
};
