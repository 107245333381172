import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Gear } from 'assets/sidemanu/settings-20px.svg';
import { ReactComponent as Warning } from 'assets/icons/equipment/warning.svg';

import Tooltip from 'common/Tooltip';
import AddAccount from 'pages/Accounting/Accounts/components/AddAccount';
import ErrorMessage from 'common/ErrorMessage';
import useShowToaster from 'hooks/useShowToaster';
import Input, { InputWithIcon } from 'common/Input';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getCheckStartingNumber } from 'Api/PayrollSettings';
import { getBillData, getCheckSettings } from 'Api/Checks';
import { getAccountsList, getAccountTypes } from 'Api/Accounts';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';
import {
  formatCheckNumber,
  SEND_PAYMENT_ACTIVE_ROUTE,
  SEND_PAYMENT_KEYS,
  SEND_PAYMENT_TYPES,
} from '../../../constants';
import { SendPaymentModalContext } from '../../../index';
import PrintChecksModal from '../../PrintChecksModal/PrintChecksModal';
import ChangeOrAddModal from '../../ChangeOrAddModal/ChangeOrAddModal';
import CheckWithMemo from '../../CheckWithMemo';
import classes from './SendPaymentStep3.module.scss';

const SendPaymentStep3 = () => {
  const {
    data,
    values,
    touchedErrors,
    handleSubmit,
    onClose,
    activeSelectName,
    setStep,
    setFieldValue,
    validateField,
    validateForm,
    setTouched,
  } = useContext(SendPaymentModalContext);
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { startingCheckNumberValue } = values || {};
  const [accounts, setAccounts] = useState([]);
  const [checksData, setChecksData] = useState({});
  const [typeOptionBank, setTypeOptionBank] = useState(null);
  const [isOpenNewAccount, setIsOpenNewAccount] = useState(false);
  const [isOpenPrintCheckModal, setIsOpenPrintCheckModal] = useState(false);
  const [isOpenChangeOrAddModal, setIsOpenChangeOrAddModal] = useState(false);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSettings = async () => {
    try {
      const { data } = await getCheckSettings();
      setSettings(data);
      return data;
    } catch (e) {
      // Do nothing
    }
  };

  const setBankValue = (data) => {
    setFieldValue('paid_by_account', data);
  };

  const changeAllCheckNumbers = (startingCheckNumber, printChecks) => {
    printChecks.forEach((el, index) => {
      const checkNumberName = `printChecks[${index}].checkNumber`;
      setFieldValue(checkNumberName, formatCheckNumber(startingCheckNumber, index)).then(() => {
        validateField(checkNumberName);
      });
    });
  };

  const getAccounts = useCallback(
    async (isUpdate) => {
      try {
        const { data } = await getAccountsList();
        const filteredData = data.filter((i) => i.account_type.id === ACCOUNT_TYPE.BANK && i.account_status === 1);
        setAccounts(filteredData);
        if (isUpdate) {
          const founded = filteredData.find((el) => el?.id === values?.paid_by_account?.id);
          founded && setBankValue(founded);
        }
        return filteredData;
      } catch (e) {
        // Do nothing
      }
    },
    [values.paid_by_account]
  );

  const getCheckStarting = async (account_id, printChecks) => {
    if (account_id) {
      const { data } = await getCheckStartingNumber({ account_id });
      if (typeof data === 'string') {
        setFieldValue('startingCheckNumberValue', data);
        changeAllCheckNumbers(data, printChecks);
        setFieldValue('defaultStartingCheckNumberValue', data);
      }
    }
  };

  const getAccountTypeOptions = async () => {
    try {
      const { data } = await getAccountTypes();
      const bankOption = data.find((el) => Number(el?.id) === ACCOUNT_TYPE.BANK);
      bankOption && setTypeOptionBank(bankOption);
    } catch (e) {
      // Do nothing
    }
  };

  const setInitialFields = () => {
    setFieldValue('printChecks', []);
    setTouched(
      Object.keys(values).reduce((touchedFields, fieldName) => {
        touchedFields[fieldName] = false;
        return touchedFields;
      }, {})
    );

    const printChecks = [];
    if (Array.isArray(data) && data.length > 0) {
      for (const item of data) {
        const index = data.indexOf(item);
        if (activeSelectName === SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS) {
          const start = item.pay_period_start ? moment(new Date(item.pay_period_start)).format('MMM D, YYYY') : '';
          const end = item.pay_period_end ? moment(new Date(item.pay_period_end)).format('MMM D, YYYY') : '';
          const checkData = {
            id: item?.id,
            checkNumber: '',
            memoValue: `Settlement ${item.id}-${start} to ${end}`,
          };
          printChecks[index] = checkData;
          setFieldValue(`printChecks[${index}]`, checkData);
        } else if (activeSelectName === SEND_PAYMENT_KEYS.OPEN_BILLS) {
          const checkData = {
            id: item?.id,
            checkNumber: '',
            memoValue: `${item?.vendor?.name}-${item.reference_id}-${item?.bill_due_date}`,
          };
          printChecks[index] = checkData;
          setFieldValue(`printChecks[${index}]`, checkData);
        } else if (activeSelectName === SEND_PAYMENT_KEYS.DRIVERS && item.app.driver_type === 'owner') {
          const checkData = {
            memoValue: '',
            amount: '',
            checkNumber: '',
            id: item?.app.operator_id,
          };
          printChecks[index] = checkData;
          setFieldValue(`printChecks[${index}]`, checkData);
        } else if (activeSelectName === SEND_PAYMENT_KEYS.STAFF) {
          const checkData = {
            amount: '',
            memoValue: '',
            checkNumber: '',
            id: item?.staff?.id,
          };
          printChecks[index] = checkData;
          setFieldValue(`printChecks[${index}]`, checkData);
        } else {
          const checkData = { memoValue: '', amount: '', checkNumber: '', id: item?.id };
          printChecks[index] = checkData;
          setFieldValue(`printChecks[${index}]`, checkData);
        }
      }
    }
    return printChecks;
  };

  const onSelectAccount = (value) => {
    setBankValue(value.paid_by_account);
    setIsOpenChangeOrAddModal(false);
    return getCheckStarting(value.paid_by_account?.id, values.printChecks);
  };

  const checks = useMemo(() => {
    const activeDataIds = data.map((item) =>
      activeSelectName === SEND_PAYMENT_KEYS.STAFF ? item?.staff?.id : item?.id
    );
    switch (activeSelectName) {
      case SEND_PAYMENT_KEYS.DRIVERS:
        return { type: SEND_PAYMENT_TYPES.DRIVERS, ids: activeDataIds };
      case SEND_PAYMENT_KEYS.VENDORS:
        return { type: SEND_PAYMENT_TYPES.VENDORS, ids: activeDataIds };
      case SEND_PAYMENT_KEYS.OWNER_OPERATORS:
        return { type: SEND_PAYMENT_TYPES.OWNER_OPERATORS, ids: activeDataIds };
      case SEND_PAYMENT_KEYS.OPEN_BILLS:
        return { type: SEND_PAYMENT_TYPES.OPEN_BILLS, ids: activeDataIds };
      case SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS:
        return { type: SEND_PAYMENT_TYPES.OPEN_SETTLEMENTS, ids: activeDataIds };
      case SEND_PAYMENT_KEYS.STAFF:
        return { type: SEND_PAYMENT_TYPES.STAFF, ids: activeDataIds };
      default:
    }
  }, [activeSelectName]);

  const getBillDataList = async () => {
    try {
      const data = await getBillData(checks);
      setChecksData({ checks: data?.checks, company: data?.company });
    } catch (e) {
      // Do nothing
    }
  };

  const handlePrintChecks = () => {
    handleSubmit();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (!isValid && values.paid_by_account === null) {
        showToaster({ type: 'error', message: 'Please select the funding account' });
      }

      isValid && setIsOpenPrintCheckModal(true);
    });
  };

  const condForTooltip = useMemo(() => {
    const bank = values?.paid_by_account;
    return !bank?.account_number || !bank?.account_routing;
  }, [values.paid_by_account]);

  useEffect(() => {
    setLoading(true);
    getBillDataList();
    getAccountTypeOptions();
    const printChecks = setInitialFields();
    return getSettings().then((settings) => {
      getAccounts()
        .then((data) => {
          const paid_by_account = data.find((acc) => acc.id === settings.default_account_id);
          if (paid_by_account) {
            setBankValue(paid_by_account);
            return getCheckStarting(paid_by_account?.id, printChecks);
          }
          if (data.length === 1) {
            setBankValue(data[0]);
            return getCheckStarting(data[0]?.id, printChecks);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <span className={classes.title}>Print Checks</span>
        <div className={classes.line} />
        <div className={classes.bankHeader}>
          <span>Please select the funding account</span>
          <span onClick={() => setIsOpenChangeOrAddModal(true)}>Change or Add</span>
          <div className={classes.gearIcon} onClick={() => setIsOpenNewAccount(true)}>
            <Gear width={16} height={16} />
          </div>
        </div>

        <div className={classes.bankingInfo}>
          {values.paid_by_account && (
            <div className='d-flex align-items-center gap-2'>
              <span>{values.paid_by_account?.account_name}</span>
              {condForTooltip && (
                <Tooltip title='Please update your bank account details, missing data!'>
                  <span>
                    <Warning fill={palette.red500} width={14} height={23} />
                  </span>
                </Tooltip>
              )}
            </div>
          )}
        </div>

        <div className={classes.checkNumber}>
          <span>Starting Check Number:</span>
          <div className='d-flex flex-column'>
            <Input
              type='number'
              className='count-input'
              onKeyDown={blockNotNumberChars}
              value={startingCheckNumberValue}
              onChange={(e) => {
                changeAllCheckNumbers(e.target.value, values.printChecks);
                setFieldValue('startingCheckNumberValue', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='w-100'>
          <ErrorMessage error={touchedErrors?.startingCheckNumberValue} />
        </div>

        <div className={classes.checkList}>
          <span className={classes.checkListTitle}>Print Check(s) - Count {data.length}</span>

          {values.printChecks.map((check, index) => {
            return (
              <div key={`${check?.id || index}`} className={classes.checkListItem}>
                <CheckWithMemo
                  amount={check?.amount}
                  signatureUrl={settings?.signature}
                  memoValue={check.memoValue}
                  company={checksData.company}
                  bank={values.paid_by_account}
                  checkNumber={check?.checkNumber}
                  check={checksData?.checks?.[index]?.detail}
                  type={Number(checksData?.checks?.[index]?.type)}
                />
                <Input
                  required
                  // type='number'
                  // onKeyDown={blockNotNumberChars}
                  value={check.memoValue}
                  placeholder='Enter memo'
                  className='count-input mt-2'
                  label={`Check ${formatCheckNumber(startingCheckNumberValue, index)} - Memo`}
                  onChange={(e) => setFieldValue(`printChecks[${index}].memoValue`, e.target.value)}
                />
                <ErrorMessage error={touchedErrors?.printChecks?.[index]?.memoValue} />
                <Input
                  required
                  // type='number'
                  // onKeyDown={blockNotNumberChars}
                  label='Check Number'
                  value={check.checkNumber}
                  className='count-input mt-2'
                  placeholder='Enter Check Number'
                  onChange={(e) => setFieldValue(`printChecks[${index}].checkNumber`, e.target.value)}
                />
                <ErrorMessage error={touchedErrors?.printChecks?.[index]?.checkNumber} />

                {activeSelectName !== SEND_PAYMENT_KEYS.OPEN_BILLS &&
                  activeSelectName !== SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS && (
                    <InputWithIcon
                      required
                      type='number'
                      label='Amount'
                      name='amount'
                      className='mt-2'
                      placeholder='0.00'
                      value={values.amount}
                      onKeyDown={blockNonPositiveNumbers}
                      error={touchedErrors?.printChecks?.[index]?.amount}
                      onChange={(e) => setFieldValue(`printChecks[${index}].amount`, e.target.value)}
                    />
                  )}
              </div>
            );
          })}
        </div>

        <Button type='submit' style={{ marginRight: 0 }} className='next-step w-100 mt-2' onClick={handlePrintChecks}>
          Preview & Print {data.length} Checks
        </Button>
        <Button style={{ margin: 0 }} className='cancel-button w-100 mt-2' onClick={() => setStep((p) => p - 1)}>
          Go Back
        </Button>
        <Button style={{ margin: 0 }} className='cancel-button w-100 mt-2' onClick={onClose}>
          Cancel
        </Button>
      </div>

      {isOpenChangeOrAddModal && (
        <ChangeOrAddModal
          accounts={accounts}
          typeOptionBank={typeOptionBank}
          onSelectAccount={onSelectAccount}
          onSuccessAddedAccount={getAccounts}
          defaultPaidByAccount={values.paid_by_account}
          onClose={() => setIsOpenChangeOrAddModal(false)}
        />
      )}

      {isOpenNewAccount && (
        <AddAccount
          open={isOpenNewAccount}
          uploaderLabel='Upload logo'
          account={values.paid_by_account}
          className={classes.addAccountModal}
          onSuccess={() => getAccounts(true)}
          onClose={() => setIsOpenNewAccount(false)}
        />
      )}

      {isOpenPrintCheckModal && (
        <PrintChecksModal
          defaultSettings={settings}
          billType={checks?.type}
          checks={checksData?.checks}
          bank={values.paid_by_account}
          company={checksData.company}
          printChecks={values.printChecks}
          activeSelectName={activeSelectName}
          onClose={() => setIsOpenPrintCheckModal(false)}
          onFinish={() => {
            navigate(SEND_PAYMENT_ACTIVE_ROUTE[activeSelectName], { replace: true });
            window.dispatchEvent(
              new CustomEvent('on_print_check_complete', { detail: { activeName: activeSelectName } })
            );
            onClose();
            setIsOpenPrintCheckModal(false);
          }}
        />
      )}

      <SpinnerLoadingMultipleTexts
        isVisible={loading}
        fullScreen={false}
        texts={['Generating checks.. please stand by..', "Still working on your checks.. don't go anywhere.. "]}
      />
      {/* {loading && ( */}
      {/*  <div className={classes.loading}> */}
      {/*    <SDotLoader> */}
      {/*      <p className='spinner-text text-center'> */}
      {/*        Getting <br /> check data... */}
      {/*      </p> */}
      {/*      <div className='spinner' /> */}
      {/*    </SDotLoader> */}
      {/*  </div> */}
      {/* )} */}
    </div>
  );
};

export default SendPaymentStep3;
