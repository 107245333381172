import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Radio from 'common/Radio';
import Loader from 'common/Loader';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import OwnerAutocomplete from 'componentsV2/OwnerOperator/OwnerAutocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { DriverGeneral, updateGeneDriver } from 'Api/Driver';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './UpdateDriverType.data';

const UpdateDriverType = ({ open, onClose, driverId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [driver, setDriver] = useState(null);

  const onSubmit = async (values) => {
    try {
      setLoadingUpdate(true);

      const body = {
        driver_type: values.driver_type,
        operator_id: values.driver_type === 'owner' ? values.operator.id : null,
      };

      const { data } = await updateGeneDriver(body, driverId);
      showToaster({ type: 'success', message: 'Driver type has been updated successfully!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(driver),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getDriver = async () => {
    try {
      setLoading(true);
      const { data } = await DriverGeneral({ id: driverId });
      setDriver(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDriver();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Update Driver's Basic Details`}
      $bgColor={palette.gray0}
      $maxWidth='400px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'update',
          type: 'primary',
          title: 'Update',
          onClick: handleSubmit,
          disabled: loading || loadingUpdate,
        },
      ]}
    >
      {loading ? (
        <Loader loading minHeight='100px' />
      ) : (
        <div>
          <div className='mb-4'>
            <InputLabel>What type of driver is this?</InputLabel>
            <div>
              <Radio
                label='Company'
                name='driver_type'
                value='company'
                checked={values.driver_type === 'company'}
                onChange={() => handleChange('driver_type', 'company')}
                wrapperClassName='mb-2'
              />
              <Radio
                label='Sub-Contractor / Owner-Operator'
                name='driver_type'
                value='owner'
                checked={values.driver_type === 'owner'}
                onChange={() => handleChange('driver_type', 'owner')}
              />
              <ErrorMessage error={touchedErrors.driver_type} />
            </div>
          </div>
          {values.driver_type === 'owner' && (
            <div>
              <OwnerAutocomplete
                name='operator'
                value={values.operator}
                onChange={(e, val) => handleChange('operator', val)}
                onGetOperators={(operators) =>
                  handleChange(
                    'operator',
                    operators.find((item) => item.id === driver?.driver_details?.app?.operator_id) || null
                  )
                }
                error={touchedErrors.operator}
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default UpdateDriverType;
