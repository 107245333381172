import React from 'react';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import { SPhoneInput } from './PhoneInput.styles';
import InputLabel from '../InputLabel';
import ErrorMessage from '../ErrorMessage';

const PhoneInput = ({
  name,
  onChange = () => {},
  onTabKey,
  disabled,
  invalid,
  value,
  autoFocus = false,
  error,
  label,
  required,
  wrapperClassName,
  width,
  ...rest
}) => {
  return (
    <div className={wrapperClassName}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <SPhoneInput
        {...rest}
        $width={width}
        country='us'
        inputProps={{ name, autoFocus }}
        value={value}
        onChange={(val) => onChange(val)}
        onKeyDown={(event) => {
          if (onTabKey && event.key === 'Tab') {
            event.preventDefault();
            onTabKey();
          }
        }}
        disabled={disabled}
        invalid={invalid}
      />
      <ErrorMessage error={error} />
    </div>
  );
};

export default PhoneInput;
