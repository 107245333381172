import { palette } from 'utils/constants';

export const graphDataConverter = (data) => {
  const graphData = {};

  data.forEach((item) => {
    const { average_waiting_time } = item;
    const hours = average_waiting_time.full_seconds / 3600;

    const key =
      hours < 0.5
        ? 'Under 30m'
        : hours >= 0.5 && hours < 1
        ? '31m to 1h'
        : `${Math.floor(hours)}h to ${Math.floor(hours + 1)}h`;

    if (graphData[key]) {
      graphData[key].count++;
    } else {
      graphData[key] = { hours, count: 1 };
    }
  });

  return Object.keys(graphData)
    .map((key) => ({
      label: key,
      count: graphData[key].count,
      hours: graphData[key].hours,
      from:
        graphData[key].hours < 0.5
          ? 0
          : graphData[key].hours >= 0.5 && graphData[key].hours < 1
          ? 0.5
          : Math.floor(graphData[key].hours),
      to:
        graphData[key].hours < 0.5
          ? 0.5
          : graphData[key].hours >= 0.5 && graphData[key].hours < 1
          ? 1
          : Math.floor(graphData[key].hours + 1),
    }))
    .sort((a, b) => b.hours - a.hours);
};

export const getOptions = (allCount, barsCount, type) => ({
  responsive: true,
  maintainAspectRatio: false,
  barThickness: barsCount < 25 ? 62 : 40,
  scales: {
    x: {
      title: {
        display: true,
        text: 'Avg Wait Time',
        color: palette.gray700,
        font: { family: 'Inter', size: 14 },
      },
      grid: {
        display: false,
      },
    },
    y: {
      suggestedMax: 5,
      ticks: {
        stepSize: 1,
      },
      title: {
        display: true,
        text: `${type === 'equipment' ? 'Vehicle' : 'Driver'} Count`,
        color: palette.gray700,
        font: { family: 'Inter', size: 14 },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }

          return `Average Wait Time - ${item[0].label?.split(',')?.[0]}`;
        },
        afterTitle(item) {
          if (!item[0]) {
            return null;
          }

          return `${item[0].raw} ${type === 'equipment' ? 'Vehicle' : 'Driver'}${
            item[0].raw === 1 ? '' : 's'
          } (${Math.round((item[0].raw * 100) / allCount)}% of ${
            type === 'equipment' ? 'vehicles' : 'drivers'
          } visited this stop point)`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});
