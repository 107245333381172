import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';
import Chip from 'common/Chip';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { shouldRender } from 'pages/chatApp/components/SideBar/Channel/helpers';
import { SChannel } from './Channel.styles';

const Channel = ({ channel, setActiveChannel, watchers, chatUser, tab, activeInnerTab, setActiveInnerTab }) => {
  const { channel: activeChannel } = useChatContext();
  const navigate = useNavigate();
  const selected = channel?.id === activeChannel?.id;

  const renderChanelName = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);
        if (chatUser.id === sender?.user.id) {
          return receiver?.user?.name;
        }
        return sender?.user?.name;
      }
      return channel.data.name;
    }
  };

  const OpenChat = async (val) => {
    if (val) {
      await channel.updatePartial({
        set: {
          open_chat: val,
          chat_open_date_time: new Date(),
        },
      });
    } else {
      await channel.updatePartial({
        set: {
          open_chat: val,
          chat_closed_by: chatUser.name,
          chat_closed_date_time: new Date(),
        },
      });
    }
  };

  if (!channel?.data.open_chat && channel?.state.unreadCount > 0) {
    OpenChat(true);
  }

  const handleClick = () => {
    navigate(`/chat/${channel.id}`);
    setActiveChannel(channel, watchers);

    if (!channel?.data) {
      return;
    }

    if (activeInnerTab === 1 && !channel.data.open_chat) {
      setActiveInnerTab(2);
    }

    if (activeInnerTab === 2 && channel.data.open_chat) {
      setActiveInnerTab(1);
    }
  };

  if (!shouldRender(tab, channel)) {
    return null;
  }

  return (
    <SChannel className={selected ? 'selected' : ''}>
      <div className='channel-item-content' onClick={() => handleClick()}>
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='s2'>{renderChanelName() || 'Channel'}</Typography>
          {!!channel?.state?.unreadCount && (
            <Chip
              label={channel.state.unreadCount}
              bgColor={palette.indigo500}
              labelColor={palette.indigo0}
              fontSize='11px'
              fontWeight={500}
            />
          )}
        </div>
      </div>
    </SChannel>
  );
};

export default Channel;
