import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { SSectionHeader } from 'pages/Dashboard/Dashboard.styles';
import TopSection from 'pages/Payroll/PayrollAndSettlements/components/TopSection';
import { topCompanyConverter } from 'pages/Payroll/PayrollAndSettlements/components/TopSection/helpers';
import { getTopCompany } from 'Api/Payroll';

const Payroll = () => {
  const [topCompany, setTopCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkedDriverTypes, setCheckedDriverTypes] = useState(['company', 'owner']);

  const getTopCompanyData = async () => {
    try {
      setLoading(true);
      const { data } = await getTopCompany({ driver_type: checkedDriverTypes });
      const convertedData = topCompanyConverter(data);
      setTopCompany(convertedData);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const onDriverTypeChange = (type) => {
    let newTypes;
    if (checkedDriverTypes.includes(type)) {
      newTypes = checkedDriverTypes.filter((item) => item !== type);
    } else {
      newTypes = [...checkedDriverTypes, type];
    }

    setCheckedDriverTypes(newTypes);
  };

  useEffect(() => {
    getTopCompanyData();
  }, []);

  return (
    <div className='d-flex flex-column gap-2'>
      <SSectionHeader>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Payroll
        </Typography>
      </SSectionHeader>
      <TopSection
        hideChartSection
        topCompany={topCompany}
        loading={loading}
        checkedDriverTypes={checkedDriverTypes}
        onDriverTypeChange={onDriverTypeChange}
        getTopCompanyData={getTopCompanyData}
      />
    </div>
  );
};

export default Payroll;
