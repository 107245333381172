import React from 'react';
import { STableWrapper } from '../Hos-Logbook.styles';
import HOSTable from './Tables/HOS';

const TableSection = () => {
  return (
    <STableWrapper>
      <HOSTable />
    </STableWrapper>
  );
};

export default TableSection;
