import { ReactComponent as MenuIcon } from 'assets/sidemanu/Menu-primary.svg';
import { ReactComponent as DashboardIcon } from 'assets/sidemanu/dashboard-20px.svg';
import { ReactComponent as StaffIcon } from 'assets/sidemanu/staff.svg';
import { ReactComponent as PlannerIcon } from 'assets/sidemanu/plannerNewIcon.svg';
import { ReactComponent as CarrierIcon } from 'assets/sidemanu/carrier.svg';
import { ReactComponent as ShipmentIcon } from 'assets/sidemanu/shipment.svg';
import { ReactComponent as StopIcon } from 'assets/sidemanu/StopPointNewIcon.svg';
import { ReactComponent as Customers } from 'assets/sidemanu/customers.svg';
import { ReactComponent as AccountingIcon } from 'assets/sidemanu/accounting.svg';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as MapIcon } from 'assets/sidemanu/map-20px.svg';
import { ReactComponent as DocIcon } from 'assets/sidemanu/doc.svg';
import { ReactComponent as FuelIcon } from 'assets/sidemanu/fuel.svg';
import { ReactComponent as ReportIcon } from 'assets/sidemanu/report.svg';
import { ReactComponent as PayIcon } from 'assets/sidemanu/pay.svg';
import { ReactComponent as SettingsIcon } from 'assets/sidemanu/settings-20px.svg';
import { ReactComponent as CloudIcon } from 'assets/sidemanu/cloud-sync.svg';
import { ReactComponent as Brush } from 'assets/sidemanu/brush.svg';
import { ReactComponent as LogoIcon } from 'assets/sidemanu/logo.svg';
import { ReactComponent as TruckInDigital } from 'assets/sidemanu/truckindigital.svg';
import { ReactComponent as UserFilled } from 'assets/sidemanu/UserFilled.svg';
import { ReactComponent as ShieldIcon } from 'assets/sidemanu/shield.svg';
import { ReactComponent as RepairIcon } from 'assets/sidemanu/repair.svg';
import { ReactComponent as RecruitingIcon } from 'assets/sidemanu/recruiting.svg';

import wideLogo from 'assets/images/Mask Group.png';
import boxed from 'assets/sidemanu/boxed.png';
import fluidIcon from 'assets/sidemanu/fluid.png';
import modeDarkIcon from 'assets/sidemanu/mode-dark.png';
import modeLightIcon from 'assets/sidemanu/mode-light.png';
import modeSysIcon from 'assets/sidemanu/mode-system.png';

export const MenuIcons = {
  wideLogo,
  CloudIcon,
  DashboardIcon,
  LogoIcon,
  MenuIcon,
  PlannerIcon,
  CarrierIcon,
  SettingsIcon,
  ShipmentIcon,
  ShieldIcon,
  StaffIcon,
  AccountingIcon,
  CardIcon,
  boxed,
  Brush,
  Customers,
  DocIcon,
  fluidIcon,
  FuelIcon,
  MapIcon,
  modeSysIcon,
  modeDarkIcon,
  modeLightIcon,
  PayIcon,
  ReportIcon,
  StopIcon,
  TruckInDigital,
  UserFilled,
  RepairIcon,
  RecruitingIcon,
};
