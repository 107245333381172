import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import { SWrapper, SSearchWrapper } from './CustomerTransactionsTab.styles';

const TableFilters = ({ dateRange, setDateRange, search, setSearch, driverSearch, setDriverSearch }) => {
  const [filterType, setFilterType] = useState('All');

  return (
    <SWrapper>
      <DateRangePicker
        top='-108px'
        initialRangeName='All Time'
        dateRange={dateRange}
        setDateRange={setDateRange}
        type='allTime'
      />
      <SSearchWrapper>
        <div>
          <Autocomplete
            width='110px'
            options={['All', 'Driver']}
            value={filterType}
            onChange={(e, val) => setFilterType(val)}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FilterIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {filterType === 'All' && <Search search={search} onChange={setSearch} className='search-input' />}
        {filterType === 'Driver' && (
          <Search search={driverSearch} onChange={setDriverSearch} className='search-input' />
        )}
      </SSearchWrapper>
    </SWrapper>
  );
};

export default TableFilters;
