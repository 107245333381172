import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { ReactComponent as ImportIcon } from 'assets/icons/download2.svg';
import { Icons } from 'assets/icons';
import ImportSamsaraVehicles from 'pages/landing/Equipment/ImportSamsaraVehicles';
import { LicenseState, OwnerOperator } from 'Api/Equipment';
import ImportCSV from 'pages/landing/Equipment/ImportEquipments/ImportVehicle/ImportCSV';
import authHeader from 'services/auth-header';
import ImportSamsaraTrailers from 'pages/landing/Equipment/ImportSamsaraTrailers';
import ImportTrailersPreview from 'pages/landing/Equipment/ImportTrailersPreview';
import UploadCsv from 'pages/landing/Equipment/UploadCsv';
import useShowToaster from 'hooks/useShowToaster';
import { importSamsaraEquipment } from 'Api/EquipmentImport';
import ThreeDotActions from 'common/ThreeDotActions';
import { getErrorMessage } from 'utils/error';
import ExportCsvButton from '../../ExportCsvButton';
import TrailerTable from './TrailerTable';
import styles from './EquipmentList.module.css';
import AddEquipment from '../AddEquipment/AddEquipment';
import HeaderStar from '../../HeaderStar';
import VehicleTable from './VehicleTable/VehicleTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '1350px',
  minWidth: '1350px',

  borderRadius: '4px',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
};

const EquipmentList = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const showToaster = useShowToaster();
  const [tableData, setTableData] = useState([]);
  const [openCSVTable, setOpenCSVTable] = useState(false);
  const [connectedService, setConnectedService] = useState({ vehicle: false, trailer: false });
  const [tabN, setTabN] = useState(location?.state?.tab || 'vehicles');
  const [, setAddEquipmentSteps] = useState(false);
  const [show, setShow] = useState(false);
  const [openImportVehicle, setOpenImportVehicle] = useState(false);
  const [importCSV, setImportCSV] = useState(false);
  const [plateState, setPlateState] = useState();
  const [ownerOperator, setOwnerOperator] = useState([]);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const [page, setPage] = useState(location?.state?.tab === 'trailer' ? 2 : 1);
  const [uploadCsvOpen, setUploadCsvOpen] = useState(false);
  const [samsaraTrailersData, setSamsaraTrailersData] = useState(null);
  const [trailerPreviewOpen, setTrailerPreviewOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createdEquipmentId, setCreatedEquipmentId] = useState('');

  const handleImportVehicleOpen = () => {
    setImportCSV(false);
    setOpenImportVehicle(true);
  };

  const handleImportVehicleClose = () => {
    setOpenImportVehicle(false);
    setTableData([]);
  };

  const LicenseStateID = async () => {
    const promises = [];

    ['231', '38', '142'].map((id) => {
      promises.push(LicenseState(id));
      return id;
    });

    const response = await Promise.all(promises);
    const finalRes = [...response[0].data.data, ...response[1].data.data, ...response[2].data.data];
    const PlateStateData = finalRes.map((v) => {
      return { label: v.name, value: v.id };
    });
    setPlateState(PlateStateData);
  };

  const OwnerOperatorID = async () => {
    OwnerOperator().then((res) => {
      const OwnerOp = res.data.map((v) => {
        return {
          label: v.owner_operator_name,
          value: v.id,
        };
      });
      setOwnerOperator(OwnerOp);
    });
  };

  useEffect(() => {
    LicenseStateID();
    OwnerOperatorID();
  }, []);

  const CSVTableHandleOpen = () => {
    setImportCSV(false);
    setTimeout(() => {
      setOpenCSVTable(true);
    }, 300);
  };

  useEffect(() => {
    if (location?.state?.openCreate) {
      setShow(true);
    }
  }, [location?.state]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}/customer/all-connected-services`, {
          headers: authHeader(),
        })
        .then((res) => {
          const vehicle = res?.data?.data?.find((v) => v.id === 47);
          const trailer = res?.data?.data?.find((v) => v.id === 50);
          setConnectedService({
            vehicle: !!vehicle?.connected,
            trailer: !!trailer?.connected,
          });
        });
    } catch {
      showToaster({ type: 'error', message: 'Error!' });
    }
  }, []);

  const getValue = (columnsData, result) => {
    if (result) {
      setTableData(result);
    } else {
      setTableData([]);
    }
  };

  const importSamsaraData = async () => {
    const body = {
      connected_service_id: tabN === 'vehicles' ? 47 : 50,
    };
    try {
      const { data } = await importSamsaraEquipment(body);
      setTableData(data || []);

      if (tabN === 'trailer') {
        setTrailerPreviewOpen(true);
      } else {
        handleImportVehicleOpen();
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getSuccessValue = () => {
    handleImportVehicleClose();
    setOpenCSVTable(false);
    setTableIndex(Date.now());
  };

  const handlePreviewSubmit = (data) => {
    setSamsaraTrailersData(data);
    setUploadCsvOpen(true);
  };

  const handleTabChange = (equipmentType) => {
    setTabN(equipmentType === 'vehicle' ? 'vehicles' : 'trailer');
    setPage(equipmentType === 'vehicle' ? 1 : 2);
  };

  const onUploadSuccess = (data) => {
    setUploadCsvOpen(false);
    setTrailerPreviewOpen(false);
    setTableData(data);
    handleImportVehicleOpen();
  };

  return (
    <>
      <div className='equipment-table-header mt-3'>
        <div className='d-flex justify-content-between mb-3'>
          <div>
            <HeaderStar title='Equipment' />
          </div>
          <div className='d-flex'>
            <ThreeDotActions
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              actionButton={
                <button className={styles.ImportVehicleButton}>
                  <ImportIcon fill='#464F60' />
                </button>
              }
            >
              <li
                onClick={() => {
                  setAnchorEl(null);
                  setImportCSV(true);
                }}
              >
                Import from CSV
              </li>
              {connectedService?.trailer === true && tabN === 'trailer' && (
                <li
                  onClick={() => {
                    setAnchorEl(null);
                    importSamsaraData();
                  }}
                >
                  <div className='d-flex align-items-center'>
                    <img src={Icons.Samsara} alt='Import' style={{ width: '22px', height: '22px' }} />
                    <p className={styles.ImportButtonText}>Import from Samsara Trailers</p>
                  </div>
                </li>
              )}
              {connectedService?.vehicle === true && tabN === 'vehicles' && (
                <li
                  onClick={() => {
                    setAnchorEl(null);
                    importSamsaraData();
                  }}
                >
                  <div className='d-flex align-items-center'>
                    <img src={Icons.Samsara} alt='Import' style={{ width: '22px', height: '22px' }} />
                    <p className={styles.ImportButtonText}>Import from Samsara Vehicles</p>
                  </div>
                </li>
              )}
            </ThreeDotActions>
            <div style={{ marginRight: '16px' }}>
              {tabN === 'vehicles' && <ExportCsvButton type='vehicles' />}
              {tabN === 'trailer' && <ExportCsvButton type='trailers' />}
            </div>
            <button className='add-equipment-button' onClick={() => setShow(true)}>
              <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                  fill='#fff'
                />
              </svg>
              <p className='add-equipment-text'>Add Equipment</p>
            </button>
          </div>
        </div>
      </div>
      <Modal open={importCSV}>
        <Box sx={styleImportCSV}>
          <ImportCSV
            handleImportCSVClose={() => setImportCSV(false)}
            handleImportVehicleOpen={CSVTableHandleOpen}
            getValue={getValue}
            tab={tabN}
          />
        </Box>
      </Modal>
      {tabN === 'vehicles' ? (
        <ImportSamsaraVehicles
          open={openImportVehicle}
          onClose={() => setOpenImportVehicle(false)}
          data={tableData}
          states={plateState}
          ownerOperators={ownerOperator}
          onCreateSuccess={getSuccessValue}
          isSamsara
        />
      ) : (
        <ImportSamsaraTrailers
          open={openImportVehicle}
          onClose={() => setOpenImportVehicle(false)}
          data={tableData}
          states={plateState}
          ownerOperators={ownerOperator}
          onCreateSuccess={getSuccessValue}
          isSamsara
        />
      )}
      {tabN === 'vehicles' ? (
        <ImportSamsaraVehicles
          open={openCSVTable}
          onClose={() => setOpenCSVTable(false)}
          data={tableData}
          states={plateState}
          ownerOperators={ownerOperator}
          onCreateSuccess={getSuccessValue}
          isSamsara={false}
        />
      ) : (
        <ImportSamsaraTrailers
          open={openCSVTable}
          onClose={() => setOpenCSVTable(false)}
          data={tableData}
          states={plateState}
          ownerOperators={ownerOperator}
          onCreateSuccess={getSuccessValue}
          isSamsara={false}
        />
      )}
      <UploadCsv
        open={uploadCsvOpen}
        samsaraData={samsaraTrailersData}
        onClose={() => setUploadCsvOpen(false)}
        onUploadSuccess={onUploadSuccess}
      />
      <ImportTrailersPreview
        open={trailerPreviewOpen}
        onClose={() => setTrailerPreviewOpen(false)}
        data={tableData}
        states={plateState}
        ownerOperators={ownerOperator}
        onCreateSuccess={getSuccessValue}
        onSubmit={handlePreviewSubmit}
        isSamsara
      />
      <Modal open={show} style={{ zIndex: 1055 }}>
        <Box sx={style}>
          <AddEquipment
            onSuccess={(equipmentId) => {
              setCreatedEquipmentId(equipmentId);
            }}
            handleClose={() => setShow(false)}
            setAddEquipmentSteps={setAddEquipmentSteps}
            defaultType={tabN === 'trailer' ? 'trailer' : 'vehicle'}
            onChangeTab={handleTabChange}
          />
        </Box>
      </Modal>
      {page === 1 ? (
        <VehicleTable
          mainTab={page}
          setMainTab={setPage}
          setTabName={setTabN}
          key={tableIndex}
          createdEquipmentId={createdEquipmentId}
        />
      ) : (
        <TrailerTable
          mainTab={page}
          setMainTab={setPage}
          setTabName={setTabN}
          key={tableIndex}
          createdEquipmentId={createdEquipmentId}
        />
      )}
    </>
  );
};

export default EquipmentList;
const styleImportCSV = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '460px',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
};
