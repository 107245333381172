import axios from '../services/axios';

export async function getEquipmentCounts() {
  const { data } = await axios.get('dashboard/get-equipment-count');
  return data;
}

export async function getDriverCounts() {
  const { data } = await axios.get('dashboard/get-driver-count');
  return data;
}

export async function getStaffCounts() {
  const { data } = await axios.get('dashboard/get-staff-count');
  return data;
}

export async function getOwnerOperatorCounts() {
  const { data } = await axios.get('dashboard/get-owner-operator-count');
  return data;
}

export async function getDriverStatusCount() {
  const { data } = await axios.get('dashboard/get-driver-status-count');
  return data;
}

export async function getEquipmentStatusCount() {
  const { data } = await axios.get('dashboard/get-equipment-status-count');
  return data;
}

export async function getAccountReceivableCount() {
  const { data } = await axios.get('dashboard/get-account-receivable-count');
  return data;
}

export async function getDriverRetentionCount(params) {
  const { data } = await axios.get('dashboard/get-driver-retention-count', { params });
  return data;
}

export async function getIncidentSummary(params) {
  const { data } = await axios.get('dashboard/get-incident-summary', { params });
  return data;
}

export async function getDriverIncidentCount(params) {
  const { data } = await axios.get('dashboard/get-driver-incident-count', { params });
  return data;
}

export async function getTotalIncident(params) {
  const { data } = await axios.get('dashboard/get-total-incident', { params });
  return data;
}

export async function getFuelPrices(params) {
  const { data } = await axios.get('dashboard/get-fuel-prices', { params });
  return data;
}

export async function getReadyToInvoice(params) {
  const { data } = await axios.get('dashboard/get-ready-to-invoice', { params });
  return data;
}

export async function getShipmentStatusCount() {
  const { data } = await axios.get('dashboard/get-shipment-status-count');
  return data;
}

export async function getTotalOpenReceivable(params) {
  const { data } = await axios.get('dashboard/get-total-open-receivable', { params });
  return data;
}

export async function getApplicantsList(params) {
  const { data } = await axios.get('dashboard/get-applicants-list', { params });
  return data;
}

export async function getEquipmentMaintenanceList(params) {
  const { data } = await axios.get('dashboard/get-equipment-maintenance-list', { params });
  return data;
}

export async function getRevenue(params) {
  const { data } = await axios.get('dashboard/get-revenue', { params });
  return data;
}

export async function getAllDashboardTabs() {
  const { data } = await axios.get('dashboard/get-all-dashboard-tabs');
  return data;
}

export async function getUserTabSettings() {
  const { data } = await axios.get('dashboard/get-user-tab-setting');
  return data;
}

export async function updateUserTabSettings(body) {
  const { data } = await axios.post('dashboard/update-user-tab-setting', body);
  return data;
}

export async function getDepartmentTabs() {
  const { data } = await axios.get('dashboard/get-department-tabs');
  return data;
}

export async function getTabDepartments() {
  const { data } = await axios.get('dashboard/get-tabs-department');
  return data;
}

export async function updateDepartmentTabs(body) {
  const { data } = await axios.post('dashboard/update-department-tabs', body);
  return data;
}

export async function updateTabDepartments(body) {
  const { data } = await axios.post('dashboard/update-tabs-department', body);
  return data;
}
