import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Documents.css';
import Popover from '@mui/material/Popover';
import TableWrapper from 'components/TableWrapper';
import DocumentsHeader from 'components/DocumentsHeader';
import DocumentFilters from 'components/DocumentFilters';
import SenderInfo from 'components/SenderInfo/SenderInfo';
import OpenDocumentModal from 'components/OpenDocumentModal';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import TableDocumentFooter from 'components/TableDocumentFooter/TableFooter';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { TableContext } from 'context/tableContext';
import {
  Document,
  documentByReferenceID,
  documentStatuses,
  storage,
  updateDocument,
  updateDocumentStatus,
} from 'Api/Document';
import { useColumns } from 'components/Documents/Documents.data';

const Documents = () => {
  const showToaster = useShowToaster();
  const location = useLocation();
  const { convertToCustomerTime, dateFormat } = useDateFormat();
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const myTableRef = useRef(null).current;
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [planerData, setPlanerData] = useState({ data: [] });
  const [usedStorage, setUsedStorage] = useState({ data: {} });
  const [openDocument, setOpenDocument] = useState(false);
  const [search, setSearch] = useState('');
  const [dayFilter, setDayFilter] = useState('all');
  const [dockTypeFilter, setDockTypeFilter] = useState([]);
  const [documentStatusesList, setDocumentStatusesList] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [shipmentTypeList, setShipmentTypeList] = useState([]);
  const [tableDependency, setTableDependency] = useState(Date.now());
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingUpdate] = useState(false);
  const open = Boolean(anchorEl);
  const [senderPopupData] = useState({ data: {} });
  const Popup = open ? 'simple-popover' : undefined;
  const debouncedSearch = useDebounce(search, 500);
  const debouncedDocType = useDebounce(dockTypeFilter, 1000);
  const [filter, setFilter] = useState({
    tableColumn: [],
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    filterTableTop: [],
    selectedValues: [],
  });

  const customers = useMemo(() => {
    return [
      { title: 'Customer 1', id: 1 },
      { title: 'Customer 2', id: 2 },
      { title: 'Customer 3', id: 3 },
      { title: 'Customer 4', id: 4 },
      { title: 'Customer 5', id: 5 },
      { title: 'Customer 6', id: 6 },
    ];
  }, []);

  const [dragItem, setDragItem] = useState([]);

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    Document({ page: planerData.current_page, itemsPerPage: rowPage })
      .then((res) => setPlanerData(res))
      .finally(() => setLoading(false));
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const handleSenderClose = () => {
    setAnchorEl(null);
  };

  const PaginationComponent = (event) => {
    return (
      <TableDocumentFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={planerData.total}
        totalLength={planerData.data.length}
        lastPage={planerData.last_page}
        currentPage={planerData.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setPlanerData(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onDeleteSuccess = () => {
    showToaster({ type: 'success', message: 'Document removed successfully.' });
    setTableDependency(Date.now());
    setOpenDocument(false);
  };

  const onUpdateSuccess = (form, data, status) => {
    setLoading(true);
    updateDocument(data.id, form.values, data)
      .then()
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setTableDependency(Date.now());
        setLoading(false);
      });
    if (status) {
      const reason = form.values.reason ? form.values.reason : null;
      updateDocumentStatus(status, data.id, reason)
        .then(() => {
          setTableDependency(Date.now());
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => setLoading(false));
    }
    setOpenDocument(false);
  };

  // sort query
  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[][${field}]`;
    }

    getSortedDocuments(sortField, sortBy);
  }

  const getSortedDocuments = async (sortField, sortBy) => {
    const documents = await Document({
      page: 1,
      itemsPerPage: rowPerPage,
      query: debouncedSearch || undefined,
      document_type_id: tab,
      from: dayFilter.from_date,
      to: dayFilter.to_date,
      dockTypes: dockTypeFilter?.map((item) => item.id),
      sortField,
      sortBy,
    });
    setPlanerData(documents);
  };

  function isWhatPercentOf(numA, numB) {
    const percent = ((numA / numB) * 100).toFixed(2);
    return percent === '0.00' && numA > 0 ? '0.01' : percent;
  }

  useEffect(() => {
    if (location?.state?.newDocumentId) {
      documentByReferenceID(location.state.newDocumentId)
        .then((res) => {
          setOpenDocument(res.data);
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [location?.state?.newDocumentId]);

  useEffect(() => {
    setLoading(true);

    async function init() {
      let newFilter = {};
      try {
        const documents = await Document({
          page: 1,
          itemsPerPage: rowPerPage,
          query: debouncedSearch || undefined,
          document_type_id: tab,
          from: dayFilter.from_date,
          to: dayFilter.to_date,
          dockTypes: dockTypeFilter?.map((item) => item.id),
        });
        setPlanerData(documents);
        newFilter = { ...filter, filterTableTop: [{ key: 'all', label: 'All', value: 0, id: 0, count: 0 }] };
      } catch (e) {
        // Do nothing
      }

      try {
        const res = await storage();
        setUsedStorage({
          ...res,
          percent: isWhatPercentOf(res.storage_used, res.storage_allowed),
        });
      } catch (e) {
        // Do nothing
      }

      try {
        const getStatues = await documentStatuses({
          query: debouncedSearch || undefined,
          from: dayFilter.from_date,
          to: dayFilter.to_date,
          dockTypes: dockTypeFilter?.map((item) => item.id),
        });
        setDocumentStatusesList(getStatues.data);
        newFilter.filterTableTop[0].count = getStatues.data?.reduce((total, statue) => total + statue.status_count, 0);
        newFilter = {
          ...filter,
          filterTableTop: [
            ...newFilter.filterTableTop,
            ...getStatues.data.map((item) => {
              return {
                key: item.id,
                label: item.status,
                value: item.id,
                count: item.status_count,
                id: item.id,
              };
            }),
          ],
        };
        setFilter(newFilter);
      } catch (e) {
        // Do nothing
      }

      setLoading(false);
    }

    init();
  }, [createModalCLose, debouncedSearch, tableDependency, tab, dayFilter, debouncedDocType]);

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const onChangeTab = (n) => {
    setTab(+n.value);
  };

  function onCheckMultiselectData(checkboxes) {
    setDockTypeFilter(checkboxes);
  }

  const columns = useColumns({ sortingQuery, dateFormat, convertToCustomerTime });

  return (
    <div>
      <TableContext.Provider
        value={{
          TYPES: [],
          filter,
          dragItem,
          customers,
          updateFilter,
          onChangeOrder: (items) => setDragItem(items),
          multiSelectOptions: {},
          planerData: planerData.data,
          createModalCLose,
          updateOnCreate,
          onCheckMultiselectData,
          usedStorage,
          documentStatusesList,
        }}
      >
        <DocumentsHeader
          onListUpdate={setDocumentTypeList}
          documentTypeList={documentTypeList}
          shipmentTypeList={shipmentTypeList}
          onShipmentUpdate={setShipmentTypeList}
        />
        <DocumentFilters
          search={search}
          onChange={setSearch}
          onDayChange={setDayFilter}
          documentTypeList={documentTypeList}
        />
        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={filter.filterTableTop}
          value={tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </TableContext.Provider>

      {loading ? (
        <Preloader />
      ) : (
        <div className='tableFixHead table-fixed-header-340'>
          <TableWrapper
            data={planerData?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: palette.white }}
            components={{
              Pagination: PaginationComponent,
            }}
            onRowClick={(e, rowData) => setOpenDocument(rowData)}
            columns={columns}
          />
        </div>
      )}

      <Popover
        id={Popup}
        anchorPosition={{ top: 200, left: 400 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleSenderClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SenderInfo sendMessage senderData={senderPopupData} />
      </Popover>

      {openDocument && (
        <OpenDocumentModal
          onDeleteSuccess={onDeleteSuccess}
          onSubmit={onUpdateSuccess}
          data={openDocument}
          documentTypeList={documentTypeList}
          shipmentTypeList={shipmentTypeList}
          isOpen={!!openDocument}
          onClose={() => {
            if (loadingUpdate) {
              return;
            }
            setOpenDocument(false);
          }}
          loadingUpdate={loadingUpdate}
        />
      )}
    </div>
  );
};

export default Documents;
