export const getInitialValues = (location) => {
  const {
    location_name,
    address1,
    address2,
    country,
    state,
    city,
    zipcode,
    pin,
    time,
    nfc_enabled,
    qr_enabled,
    card_enabled,
  } = location || {};

  return {
    location_name: location_name || '',
    address: address1 || '',
    address2: address2 || '',
    country: country || null,
    state: state || null,
    city: city || null,
    zipcode: zipcode || '',
    pin: pin || '',
    timezone: time || null,
    nfc_enabled: !!nfc_enabled,
    qr_enabled: !!qr_enabled,
    card_enabled: !!card_enabled,
  };
};
