import React, { useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import { Collapse, OverlayTrigger, Popover } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { equipmentStatuses } from 'Api/Map';
import CustomButton from '../../CustomButton/CustomButton';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import { Typography } from '../../Typography';
import sm from '../../TablePlaner/helpers/TableHeaderFilterByShow.module.css';

const CanTravelToValue = [
  {
    title: 'USA',
    id: 1,
    value: 'can_travel_to',
    type: 'checkbox',
    can_travel_to: 'usa',
    key: uuid(),
  },
  {
    title: 'Canada',
    id: 2,
    value: 'can_travel_to',
    type: 'checkbox',
    can_travel_to: 'canada',
    key: uuid(),
  },
  {
    title: 'Mexico',
    id: 3,
    value: 'can_travel_to',
    type: 'checkbox',
    can_travel_to: 'mexico',
    key: uuid(),
  },
];

export const MOTIONDATA = [
  {
    title: 'Not Moving',
    id: 1,
    value: 'motions',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Idling',
    id: 2,
    value: 'motions',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Moving',
    id: 3,
    value: 'motions',
    type: 'checkbox',
    key: uuid(),
  },
];

const TableHeaderFilterByShow = (props) => {
  const { use } = useTheme();
  const {
    groups,
    setGroups,
    statuses,
    setStatuses,
    equipments,
    setEquipments,
    motions,
    setMotions,
    onApplyFilterCheckboxes,
    filterByGroupsData,
    filterByEquipmentTypeData,
    data,
    onDeleteLocations,
    onReset,
    onDeleteLocationsItems,
    localFilterCheckboxes,
    setLocalFilterCheckboxes,
    disabledFilters,
    canTravelto,
    setCanTravelTo,
    MapComponent,
  } = props;
  const [show, setShow] = useState(false);
  const [statusFilterData, setStatusFilterData] = useState([]);

  const [accordion, setAccordion] = useState({
    status: false,
    can_travel_to: false,
    groups: false,
    equipments: false,
    radius: false,
    motions: false,
  });

  const totalCount = () => {
    let value;
    if (data && data[0] && data[0]?.length) {
      value = data[0].find((x) => x.value === 'location');
    }

    const totalData = data?.map((v, index) =>
      !data[index]?.length
        ? v?.searchValue?.length
        : value && value !== undefined
        ? Number(data[index]?.length) - 1
        : data[index]?.length
    );
    const sumOfTotalData = totalData.reduce((a, b) => a + b, 0);
    return sumOfTotalData;
  };
  const styles = useMemo(() => {
    return {
      container: {
        borderColor: use(palette.gray200, palette.gray700),
      },
      title: {
        color: use(palette.indigo500, palette.indigo500),
        marginTop: 4,
        background: palette.indigo0,
        padding: '0 6px 0 6px',
      },
      reset: {
        marginLeft: 8,
        marginTop: 4,
        cursor: 'pointer',
        color: use(palette.indigo500, palette.indigo500),
      },
      searchValue: {
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: use(palette.indigo700, palette.indigo700),
      },
      additionalText: {
        marginTop: 4,
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: use(palette.indigo700, palette.indigo700),
      },
      options: {
        cursor: 'pointer',
      },
      checkboxes: {
        margin: '0 0 5px 10px',
        width: 'calc(50% - 30px)',
      },
      checkboxesText: {
        color: use(palette.gray700, palette.gray200),
        marginLeft: 5,
        whiteSpace: 'nowrap',
      },
    };
  }, [use, palette]);

  function onToggleItem(name) {
    setAccordion((prevState) => {
      return {
        status: false,
        can_travel_to: false,
        groups: false,
        equipments: false,
        radius: false,
        motions: false,
        [name]: !prevState[name],
      };
    });
  }

  useEffect(() => {
    equipmentStatuses('vehicle').then((res) => {
      setStatusFilterData(res.data);
    });
  }, []);

  function onTickCheckboxes(item, name) {
    const mapState = {
      can_travel_to: { state: canTravelto, setState: setCanTravelTo },
      status: { state: statuses, setState: setStatuses },
      groups: { state: groups, setState: setGroups },
      equipment_types: { state: equipments, setState: setEquipments },
      motions: { state: motions, setState: setMotions },
    };
    const TYPE = localFilterCheckboxes[name];
    const { state, setState } = mapState[name];
    const foundedIndex = TYPE.findIndex((el) => el.id === item.id);

    if (foundedIndex !== -1) {
      const newState = state.filter((id) => id !== item.id);
      setState([...newState]);

      const newType = [...TYPE];
      newType.splice(foundedIndex, 1);
      setLocalFilterCheckboxes((prevState) => {
        return {
          ...prevState,
          [name]: [...newType],
        };
      });
    } else {
      setState([...state, item.id]);
      setLocalFilterCheckboxes((prevState) => {
        return {
          ...prevState,
          [name]: [...prevState[name], { ...item }],
        };
      });
    }
  }

  const content = (
    <Popover
      style={{
        minWidth: 426,
        backgroundColor: use(palette.white, palette.dark800),
      }}
    >
      <div className={sm.overlay_header} style={{ borderColor: use(palette.gray200, palette.gray700) }}>
        <Typography variant='h5'>Options</Typography>
      </div>

      <div className={sm.overlay_body} style={{ backgroundColor: use(palette.gray0, palette.dark700) }}>
        {/* // status */}
        <div className={sm.overlay_status_wrapper}>
          <div
            className={`${sm.closed} ${disabledFilters.status ? sm.disable_collapse : ''}`}
            onClick={() => {
              if (!disabledFilters.status) {
                onToggleItem('status');
              }
            }}
          >
            <Typography variant='s1'>Status</Typography>
            <ArrowDown
              style={{
                transform: `rotateX(${accordion.status ? 180 : 0}deg)`,
              }}
            />
          </div>

          <Collapse in={accordion.status}>
            <div className={sm.opened}>
              {statusFilterData.map((status) => {
                const { id, equipment_status } = status;

                return equipment_status !== 'Drop Trailer' ? (
                  <CustomCheckbox
                    key={id}
                    style={styles.checkboxes}
                    checked={statuses.includes(id)}
                    onChange={() => onTickCheckboxes(status, 'status')}
                  >
                    <Typography variant='s2' style={styles.checkboxesText}>
                      {equipment_status}
                    </Typography>
                  </CustomCheckbox>
                ) : (
                  ''
                );
              })}
            </div>
          </Collapse>
        </div>
        {MapComponent ? (
          <div className={sm.overlay_status_wrapper}>
            <div
              className={`${sm.closed} ${disabledFilters.can_travel_to ? sm.disable_collapse : ''}`}
              onClick={() => {
                if (!disabledFilters.status) {
                  onToggleItem('can_travel_to');
                }
              }}
            >
              <Typography variant='s1'>Can Travel To</Typography>
              <ArrowDown
                style={{
                  transform: `rotateX(${accordion.can_travel_to ? 180 : 0}deg)`,
                }}
              />
            </div>

            <Collapse in={accordion.can_travel_to}>
              <div className={sm.opened}>
                {CanTravelToValue.map((status) => {
                  const { id, value, title } = status;

                  return title !== 'Drop Trailer' ? (
                    <CustomCheckbox
                      key={id}
                      style={styles.checkboxes}
                      checked={canTravelto.includes(id)}
                      onChange={() => onTickCheckboxes(status, value)}
                    >
                      <Typography variant='s2' style={styles.checkboxesText}>
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  ) : (
                    ''
                  );
                })}
              </div>
            </Collapse>
          </div>
        ) : (
          ''
        )}
        {/* // groups */}
        <div className={sm.overlay_status_wrapper}>
          <div
            className={`${sm.closed} ${disabledFilters.groups ? sm.disable_collapse : ''}`}
            onClick={() => {
              if (!disabledFilters.groups) {
                onToggleItem('groups');
              }
            }}
          >
            <Typography variant='s1'>Tags</Typography>
            <ArrowDown
              style={{
                transform: `rotateX(${accordion.groups ? 180 : 0}deg)`,
              }}
            />
          </div>

          <Collapse in={accordion.groups}>
            <div className={sm.opened}>
              {filterByGroupsData.map((group) => {
                const { id, value, title } = group;
                return (
                  <CustomCheckbox
                    key={id}
                    style={styles.checkboxes}
                    checked={groups.includes(id)}
                    onChange={() => onTickCheckboxes(group, value)}
                  >
                    <Typography variant='s2' style={styles.checkboxesText}>
                      {title}
                    </Typography>
                  </CustomCheckbox>
                );
              })}
            </div>
          </Collapse>
        </div>

        <div className={sm.overlay_status_wrapper}>
          <div
            className={`${sm.closed} ${disabledFilters.equipment_types ? sm.disable_collapse : ''}`}
            onClick={() => {
              if (!disabledFilters.equipment_types) {
                onToggleItem('equipments');
              }
            }}
          >
            <Typography variant='s1'>Vehicle Type</Typography>
            <ArrowDown
              style={{
                transform: `rotateX(${accordion.equipments ? 180 : 0}deg)`,
              }}
            />
          </div>

          <Collapse in={accordion.equipments}>
            <div className={sm.opened}>
              {filterByEquipmentTypeData.map((equipment) => {
                const { id, value, title } = equipment;
                return (
                  <CustomCheckbox
                    key={id}
                    style={styles.checkboxes}
                    checked={equipments.includes(id)}
                    onChange={() => onTickCheckboxes(equipment, value)}
                  >
                    <Typography variant='s2' style={styles.checkboxesText}>
                      {title}
                    </Typography>
                  </CustomCheckbox>
                );
              })}
              <CustomCheckbox
                key='Day-Cab'
                style={styles.checkboxes}
                checked={equipments.includes('Day-Cab')}
                onChange={() =>
                  onTickCheckboxes(
                    {
                      title: 'Truck (Day-Cab)',
                      id: 'Day-Cab',
                      value: 'equipment_types',
                      type: 'checkbox',
                      key: 'Truck (Day-Cab)',
                      typeData: 'vehicle',
                    },
                    'equipment_types'
                  )
                }
              >
                <Typography variant='s2' style={styles.checkboxesText}>
                  Truck (Day-Cab)
                </Typography>
              </CustomCheckbox>
              <CustomCheckbox
                key='Sleeper'
                style={styles.checkboxes}
                checked={equipments.includes('Sleeper')}
                onChange={() =>
                  onTickCheckboxes(
                    {
                      title: 'Truck (Sleeper)',
                      id: 'Sleeper',
                      value: 'equipment_types',
                      type: 'checkbox',
                      key: 'Truck (Sleeper)',
                      typeData: 'vehicle',
                    },
                    'equipment_types'
                  )
                }
              >
                <Typography variant='s2' style={styles.checkboxesText}>
                  Truck (Sleeper)
                </Typography>
              </CustomCheckbox>
            </div>
          </Collapse>
        </div>

        {!MapComponent && (
          <div className={sm.overlay_status_wrapper}>
            <div
              className={`${sm.closed} ${disabledFilters.equipment_types ? sm.disable_collapse : ''}`}
              onClick={() => {
                onToggleItem('motions');
              }}
            >
              <Typography variant='s1'>Motion</Typography>
              <ArrowDown
                style={{
                  transform: `rotateX(${accordion.equipments ? 180 : 0}deg)`,
                }}
              />
            </div>

            <Collapse in={accordion.motions}>
              <div className={sm.opened}>
                {MOTIONDATA.map((m) => {
                  const { id, value, title } = m;
                  return (
                    <CustomCheckbox
                      key={id}
                      style={styles.checkboxes}
                      checked={motions?.includes(id)}
                      onChange={() => onTickCheckboxes(m, value)}
                    >
                      <Typography variant='s2' style={styles.checkboxesText}>
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  );
                })}
              </div>
            </Collapse>
          </div>
        )}
      </div>

      <div className={sm.overlay_footer} style={{ borderColor: use(palette.gray200, palette.gray700) }}>
        <CustomButton
          title='Cancel'
          type='secondary'
          onClick={() => setShow(false)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
        />
        <CustomButton
          title='Apply'
          type='primary'
          onClick={() => {
            setShow(false);
            onApplyFilterCheckboxes({
              checkboxes: localFilterCheckboxes,
              status: statuses,
              groups,
              equipment_types: equipments,
              can_travel_to: canTravelto,
              motions,
            });
          }}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
        />
      </div>
    </Popover>
  );
  return (
    <div className={sm.TableHeaderFilterByShow_container} style={styles.container}>
      <div className={sm.title_wrapper} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <OverlayTrigger show={show} trigger='click' rootClose placement='right' overlay={content} onToggle={setShow}>
          <div className={sm.overlay_trigger_wrapper}>
            <Typography variant='overLine' style={styles.options}>
              <FilterIcon
                width={11}
                height={11}
                style={{ marginRight: 4 }}
                fill={use(palette.gray700, palette.gray200)}
              />
              Options: {data?.length ? totalCount(data) : ''}
            </Typography>
          </div>
        </OverlayTrigger>
        <Typography variant='overLine' style={styles.reset} onClick={onReset}>
          Clear All
        </Typography>
      </div>
      <div className={sm.mapped_data_container}>
        {data.map((el) => {
          const { title, searchValue } = el || {};
          const stopPoint = el?.[0]?.type && el?.[0]?.type === 'stop_point';
          return (
            <div key={uuid()} className={sm.container}>
              {stopPoint ? null : Array.isArray(el) ? (
                <Locations
                  data={el}
                  styles={styles}
                  onDeleteLocations={onDeleteLocations}
                  onDeleteLocationsItems={onDeleteLocationsItems}
                />
              ) : (
                <div className={sm.mapped_data_wrapper}>
                  <Typography variant='c1' style={styles.title}>
                    {title}:
                  </Typography>
                  {Array.isArray(searchValue) ? (
                    searchValue.map((item, index) => (
                      <div key={item.title} className={sm.many_data_show_wrapper}>
                        <Typography variant='c1' style={styles.searchValue}>
                          {item.title || item.equipment_status}
                          {index !== searchValue.length - 1 && ','}
                          &nbsp;
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <div className={sm.many_data_show_wrapper}>
                      <Typography variant='c1' style={styles.searchValue}>
                        {searchValue}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Locations = ({ data, styles }) => {
  const origin = data.filter((d) => ['city', 'state', 'postCode'].includes(d.value));
  const radius = data.filter((d) => ['radius'].includes(d.value));
  const Equipment = data.filter((d) => ['equipment_id'].includes(d.valuePrefix));
  const drivers = data.filter((d) => ['driver'].includes(d.valuePrefix));

  drivers.forEach((d, i) => {
    d.searchValue = d.headerValue[i]?.label;
  });
  drivers.forEach((d, i) => {
    d.id = d.value[i];
  });

  const render = (data, type) => {
    if (data.length === 0) return null;
    return (
      <div className='d-flex'>
        {!!type && !!origin.length && (
          <div className={sm.many_data_show_wrapper}>
            <Typography variant='c1' style={styles.searchValue}>
              {type}(&nbsp;
            </Typography>
          </div>
        )}

        {data.map((el, i) => {
          return (
            <div key={uuid()} className={sm.many_data_show_wrapper}>
              <Typography variant='c1' style={styles.searchValue}>
                {el?.searchValue}
                {i !== data.length - 1 && ','}&nbsp;
              </Typography>
              {!!type && !!origin.length && (
                <Typography variant='c1' style={styles.searchValue}>
                  )&nbsp;
                </Typography>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={sm.container}>
      <div className={sm.mapped_data_wrapper}>
        <Typography variant='c1' style={styles.title}>
          {data?.[0]?.title}:
        </Typography>
        {render(origin)}
        {render(radius, 'radius')}
        {render(Equipment)}
        {render(drivers)}
      </div>
    </div>
  );
};

export default TableHeaderFilterByShow;
