import React from 'react';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import Info from './Info';
import { SColumn, SSection } from '../ViewApplicantModal.styles';

const MilitaryHistory = ({ data }) => {
  const { formatDate } = useDateFormat();
  const { service_branch, service_from, service_to, discharge_rank, discharge_type, mos } = data || {};

  if (!data) {
    return null;
  }

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Military History
      </Typography>
      <SSection>
        <SColumn>
          <Info label='Branch of Service' infoText={service_branch} />
          <Info label='Dates of Service' infoText={`${formatDate(service_from)} to ${formatDate(service_to)}`} />
        </SColumn>
        <SColumn>
          <Info label='Rank at Discharge' infoText={discharge_rank} />
          <Info label='Type of Discharge' infoText={discharge_type} />
          <Info label='Military Occupational Specialty (MOS)' infoText={mos} />
        </SColumn>
      </SSection>
    </div>
  );
};

export default MilitaryHistory;
