export const dateFormatSwitch = [
  { key: 'MM/DD/YYYY', label: 'mm/dd/yyyy', value: 1 },
  { key: 'DD/MM/YYYY', label: 'dd/mm/yyyy', value: 2 },
];

export const timeFormatSwitch = [
  { key: 'HH:mm', label: 'Military Time', value: 1 },
  { key: 'hh:mm A', label: 'AM/PM', value: 2 },
];

export const amountSwitch = [
  { key: 'without_k', label: '10,000', value: 1 },
  { key: 'with_k', label: '10k', value: 2 },
];
