import styled from 'styled-components';

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin: 64px 0 0;
  border-bottom: 1px solid #e9edf5;
`;

export const SCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 16px;
`;

export const SFlexWrapper = styled.div`
  display: flex;
`;

export const SImageContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid rgba(213, 218, 229, 0.32);
  border-radius: 40px;
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SImage = styled.img`
  box-sizing: border-box;
  position: absolute;
  width: 48px;
  height: 48px;
  border: 1px solid rgba(213, 218, 229, 0.32);
  border-radius: 100%;
`;

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
