import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'common/Modal';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { STable } from 'pages/Accounting/Payables/Payables.styles';

const Credits = ({ open, onClose, credits, customer }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Credits'
      $minHeight='300px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done' }]}
    >
      <div>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>DATE</th>
              <th>REFERENCE</th>
              <th>PAYEE</th>
              <th>INVOICE ID</th>
              <th>MEMO</th>
              <th>DEBIT</th>
              <th>CREDIT</th>
              <th>BALANCE</th>
            </tr>
          </thead>
          <tbody>
            {credits.map((item) => (
              <tr key={item.id} className='body-row'>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatDate(item.date)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.reference_id || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {customer?.company_name || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.invoice_id || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.memo || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(item.debit)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(item.credit)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(item.balance)}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!credits?.length && <NoRecords />}
      </div>
    </Modal>
  );
};

export default Credits;
