import React, { useState } from 'react';
import './addgroup.css';
import axios from 'axios';
import { ModalBody } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Select from 'react-select';
import authHeader from 'services/auth-header';
import { Groups } from 'Api/Equipment';
import AddNewGroup from 'componentsV2/Group/AddGroup';
import { palette } from 'utils/constants';

const AddGroup = ({ handleClose, id, AddGroupSuccessModal, onSuccess = () => null }) => {
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [groupId, setGroupId] = useState();

  const { data, isError, isLoading, error, refetch } = useQuery('grp', Groups);

  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }

  const handleChange = (e) => {
    setGroupId(e.value);
  };
  const arr = data.data.map((t) => ({ label: `${t.group_name}`, value: t.id }));
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: palette.white,
      color: palette.dark800,
      height: 32,
      minHeight: 32,
      minWidth: 250,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: palette.white,
      color: palette.dark800,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: palette.white,
      color: palette.dark800,
    }),
  };
  const handleSubmitGroup = () => {
    const values = {
      group_id: [groupId],
      relation_type: 4,
    };
    axios
      .put(`${API_URL}/group/relation/${id}`, values, { headers: authHeader() })
      .then((res) => {
        if (res && res.status === 200) {
          onSuccess();
          handleClose();
          AddGroupSuccessModal(id);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };
  return (
    <div className='equipment-list-add-group' style={{ backgroundColor: palette.white }}>
      <ModalBody className='p-0 addGroupsModel'>
        <div>
          <div className='hook-trailer-modal-header'>
            <h6 className='hook-trailer-modal-heading mt-3 ms-3' style={{ color: palette.dark800 }}>
              Add Tags
            </h6>
          </div>
          <div className='hook-trailer-modal-body' style={{ backgroundColor: palette.white }}>
            <div className='d-flex justify-content-between'>
              <p className='hook-trailer-modal-text' style={{ color: palette.dark800 }}>
                Tag*
              </p>
              <p onClick={() => setOpenAddGroup(true)}>
                <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                    fill='#4f5aed'
                  />
                </svg>
                <span className='me-5 ms-1 hook-trailer-modal-text' style={{ color: palette.dark800 }}>
                  New Tag
                </span>
              </p>
            </div>

            <div className='select-trailer-list mt-0'>
              <Select
                options={arr}
                type='checkbox'
                name='arr'
                checked={arr.map((value) => value.value)}
                onChange={handleChange}
                styles={customStyles}
              />
            </div>
          </div>
          <div className='add-driver-modal-footer d-flex justify-content-end'>
            <button
              className='hook-trailer-modal-cancel-button'
              onClick={handleClose}
              style={{
                backgroundColor: palette.white,
                color: palette.dark800,
              }}
            >
              Cancel
            </button>
            <button className='hook-trailer-modal-hook-trailer-button' onClick={() => handleSubmitGroup(id)}>
              Add Tag
            </button>
          </div>
        </div>
      </ModalBody>
      {!!openAddGroup && <AddNewGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={refetch} />}
    </div>
  );
};

export default AddGroup;
