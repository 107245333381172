import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getDriverCounts } from 'Api/Dashboard';
import { SAvatarGroupWrapper, SBannerWrapper, SCountBox } from 'pages/Dashboard/Dashboard.styles';

const DriverCount = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [drivers, setDrivers] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCounts = async () => {
    setLoading(true);
    try {
      const { data } = await getDriverCounts();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  const users = drivers?.details?.map((item, i) => (
    <Avatar key={item?.id || i} src={item.image} sx={{ width: 32, height: 32 }} />
  ));

  return (
    <SCountBox onClick={() => navigate('/driver')}>
      <Typography variant='s2' style={{ color: use(palette.gray500) }}>
        Drivers
      </Typography>
      {loading ? (
        <>
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '30px', height: '32px' }} />
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px' }} />
        </>
      ) : (
        <>
          <Typography variant='h1'>{drivers?.count}</Typography>
          <SBannerWrapper>
            <div className='left-banner' />
            <SAvatarGroupWrapper>
              <AvatarGroup max={100} total={drivers?.count} style={{ justifyContent: 'flex-end' }}>
                {users}
              </AvatarGroup>
            </SAvatarGroupWrapper>
            <div className='right-banner' />
          </SBannerWrapper>
        </>
      )}
    </SCountBox>
  );
};

export default DriverCount;
