import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getShipmentStatusCount } from 'Api/Dashboard';
import { SSectionHeader, SSmallChartBox } from 'pages/Dashboard/Dashboard.styles';
import DoughnutChart from 'pages/Dashboard/components/Charts/DoughnutChart';
import { useNavigate } from 'react-router-dom';

const fillColorArray = new Array(80).fill(palette.indigo300);
const fillColors = fillColorArray.map((color, i) => {
  if (i % 2 === 0) {
    return palette.white;
  }
  return color;
});

const getCenterText = (label, count, isMapsPage) => {
  return {
    id: 'textCenter',
    afterDraw(chart) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;

      ctx.save();
      ctx.font = `${isMapsPage ? '700 20px Inter' : '700 32px Inter'}`;
      ctx.fillStyle = palette.gray700;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(count, width / 2, height / 2 - 10);

      ctx.font = `${isMapsPage ? '500 14px Inter' : '500 16px Inter'}`;
      ctx.fillStyle = palette.gray500;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(label, width / 2, height / 2 + 16);
    },
  };
};

const Shipments = ({ isMapsPage }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [counts, setCounts] = useState(true);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getShipmentStatusCount();
      setCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasetsNotPlanned = [
    {
      data: [counts?.not_planned, Number(counts?.total) - Number(counts?.not_planned)],
      backgroundColor: [palette.indigo400, palette.gray100],
      hoverBackgroundColor: [palette.indigo400, palette.gray100],
      borderWidth: 0,
      cutout: '80%',
    },
  ];

  const datasetsPlanned = [
    {
      data: [counts?.planned, Number(counts?.total) - Number(counts?.planned)],
      backgroundColor: [palette.blue400, palette.gray100],
      hoverBackgroundColor: [palette.blue400, palette.gray100],
      borderWidth: 0,
      cutout: '80%',
    },
  ];

  const datasetsOnTime = [
    {
      data: [counts?.on_time, Number(counts?.total) - Number(counts?.on_time)],
      backgroundColor: [palette.green400, palette.gray100],
      hoverBackgroundColor: [palette.green400, palette.gray100],
      borderWidth: 0,
      cutout: '80%',
    },
  ];

  const datasetsDelayed = [
    {
      data: [counts?.delay, Number(counts?.total) - Number(counts?.delay)],
      backgroundColor: [palette.red400, palette.gray100],
      hoverBackgroundColor: [palette.red400, palette.gray100],
      borderWidth: 0,
      cutout: '80%',
    },
  ];

  const datasetsArrived = [
    {
      data: [counts?.arrived, Number(counts?.total) - Number(counts?.arrived)],
      backgroundColor: [palette.orange300, palette.gray100],
      hoverBackgroundColor: [palette.orange300, palette.gray100],
      borderWidth: 0,
      cutout: '80%',
    },
  ];

  const datasetsTotal = [
    {
      data: new Array(80).fill(1),
      backgroundColor: fillColors,
      hoverBackgroundColor: fillColors,
      borderWidth: 0,
      cutout: '80%',
    },
  ];

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className='d-flex flex-column gap-2'>
      {!isMapsPage && (
        <SSectionHeader>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            Shipments
          </Typography>
        </SSectionHeader>
      )}
      <div className='d-flex'>
        {!isMapsPage && (
          <SSmallChartBox>
            <DoughnutChart
              loading={loading}
              isMapsPage={isMapsPage}
              datasets={datasetsNotPlanned}
              total={counts?.total}
              hoverText={{}}
              centerText={getCenterText('Not Planned', counts?.not_planned, isMapsPage)}
            />
          </SSmallChartBox>
        )}
        <SSmallChartBox $mapsPage={!!isMapsPage} onClick={() => navigate('/shipments', { state: { tab: 'Planned' } })}>
          <DoughnutChart
            loading={loading}
            isMapsPage={isMapsPage}
            datasets={datasetsPlanned}
            total={counts?.total}
            hoverText={{}}
            centerText={getCenterText('Planned', counts?.planned, isMapsPage)}
          />
        </SSmallChartBox>
        <SSmallChartBox $mapsPage={!!isMapsPage} onClick={() => navigate('/shipments', { state: { tab: 'On Route' } })}>
          <DoughnutChart
            loading={loading}
            isMapsPage={isMapsPage}
            datasets={datasetsOnTime}
            total={counts?.total}
            hoverText={{}}
            centerText={getCenterText('On Route', counts?.on_time, isMapsPage)}
          />
        </SSmallChartBox>
        <SSmallChartBox $mapsPage={!!isMapsPage} onClick={() => navigate('/shipments', { state: { tab: 'Delayed' } })}>
          <DoughnutChart
            loading={loading}
            isMapsPage={isMapsPage}
            datasets={datasetsDelayed}
            total={counts?.total}
            hoverText={{}}
            centerText={getCenterText('Delayed', counts?.delay, isMapsPage)}
          />
        </SSmallChartBox>
        <SSmallChartBox $mapsPage={!!isMapsPage} onClick={() => navigate('/shipments', { state: { tab: 'Arrived' } })}>
          <DoughnutChart
            loading={loading}
            isMapsPage={isMapsPage}
            datasets={datasetsArrived}
            total={counts?.total}
            hoverText={{}}
            centerText={getCenterText('Arrived', counts?.arrived, isMapsPage)}
          />
        </SSmallChartBox>
        <SSmallChartBox $mapsPage={!!isMapsPage} onClick={() => navigate('/shipments')}>
          <DoughnutChart
            loading={loading}
            isMapsPage={isMapsPage}
            datasets={datasetsTotal}
            total={isMapsPage ? Number(counts?.total) - Number(counts?.not_planned) : counts?.total}
            hoverText={{}}
            centerText={getCenterText(
              'Total',
              isMapsPage ? Number(counts?.total) - Number(counts?.not_planned) : counts?.total,
              isMapsPage
            )}
          />
        </SSmallChartBox>
      </div>
    </div>
  );
};

export default Shipments;
