import uuid from 'react-uuid';

export const getAverageTimeConverted = (averageTime) => {
  const timeInMinutes = Math.round(averageTime);
  const averageTimeDays = Math.floor(timeInMinutes / 1440);
  const averageTimeHours = Math.floor((timeInMinutes % 1440) / 60);
  const averageTimeMinutes = timeInMinutes % 60;

  return `${averageTimeDays ? `${averageTimeDays}d` : ''} ${
    averageTimeHours ? `${averageTimeHours}h` : ''
  } ${averageTimeMinutes}m`;
};

export const detailedReportConverter = (data) => {
  return Object.values(data).map((items) => ({
    id: uuid(),
    data: items.map((i) => ({ ...i, id: uuid() })),
    totalWaitTimeMinutes: items.reduce((acc, cur) => {
      const waitTimeMinutes = cur.duration_days * 1440 + cur.duration_hours * 60 + cur.duration_minutes;

      return acc + waitTimeMinutes;
    }, 0),
  }));
};

export const getFilteredReports = (reports, sort, averageRange, loading, type) => {
  const { field, sortBy } = sort;
  const counter = sortBy === 'asc' ? -1 : 1;

  let filteredReports = [...(reports || [])];

  if (loading) {
    return filteredReports;
  }

  switch (field) {
    case 'wait_time':
      if (type === 'summary') {
        filteredReports.sort((a, b) =>
          a.average_waiting_time.full_seconds < b.average_waiting_time.full_seconds
            ? counter
            : a.average_waiting_time.full_seconds === b.average_waiting_time.full_seconds
            ? 0
            : -counter
        );
      } else {
        filteredReports = filteredReports.map((item) => ({
          ...item,
          data: item.data.sort((a, b) => {
            const totalMinutesA = a.duration_days * 1440 + a.duration_hours * 60 + a.duration_minutes;
            const totalMinutesB = b.duration_days * 1440 + b.duration_hours * 60 + b.duration_minutes;

            return totalMinutesA < totalMinutesB ? counter : totalMinutesA === totalMinutesB ? 0 : -counter;
          }),
        }));
      }
      break;
    case 'arrived_date':
      if (type === 'summary') {
        filteredReports.sort((a, b) =>
          a.arrived_date < b.arrived_date ? counter : a.arrived_date === b.arrived_date ? 0 : -counter
        );
      } else {
        filteredReports = filteredReports.map((item) => ({
          ...item,
          data: item.data.sort((a, b) => {
            return a.arrived_date < b.arrived_date ? counter : a.arrived_date === b.arrived_date ? 0 : -counter;
          }),
        }));
      }
      break;
    case 'departed_date':
      if (type === 'summary') {
        filteredReports.sort((a, b) =>
          a.departed_date < b.departed_date ? counter : a.departed_date === b.departed_date ? 0 : -counter
        );
      } else {
        filteredReports = filteredReports.map((item) => ({
          ...item,
          data: item.data.sort((a, b) => {
            return a.departed_date < b.departed_date ? counter : a.departed_date === b.departed_date ? 0 : -counter;
          }),
        }));
      }
      break;
    default:
      break;
  }

  return type === 'summary' || averageRange[0] === null
    ? filteredReports
    : filteredReports.filter((item) => {
        const hours = item.totalWaitTimeMinutes / item.data.length / 60;
        return hours >= averageRange[0] && hours < averageRange[1];
      });
};
