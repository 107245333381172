import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SInfo } from '../ViewApplicantModal.styles';

const Info = ({ label, infoText, children }) => {
  const { use } = useTheme();

  return (
    <SInfo>
      <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
        {label}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
        {infoText || 'N/A'}
      </Typography>
      {children}
    </SInfo>
  );
};

export default Info;
