import React from 'react';
import { Typography } from 'components/Typography';
import { ALERT_TYPE } from 'componentsV2/AlertCenter/TakeActions/TakeActions.data';
import { palette } from 'utils/constants';
import Status from './Status';
import UserInfo from './UserInfo';

const AlertInfo = ({ alert, onUserClick = () => null, children }) => {
  const { driver_details, staff_details, owner_operator_details, alert_message, reason, alert_name, details_info } =
    alert;

  return (
    <div className='py-4 px-3 bg-white'>
      {(!!driver_details || !!staff_details || owner_operator_details) && (
        <div className='d-flex justify-content-between align-items-center gap-4 mb-2'>
          <UserInfo
            userData={{
              ...(driver_details || staff_details || owner_operator_details),
              user_type: owner_operator_details ? 3 : staff_details ? 1 : 2,
            }}
            onUserClick={onUserClick}
          />
          <Status alert={alert} />
        </div>
      )}
      <div className='d-flex justify-content-between align-items-center gap-4'>
        <Typography variant='s2'>{alert_message}</Typography>
        {!driver_details && !staff_details && !owner_operator_details && <Status alert={alert} />}
      </div>
      {!!reason && (
        <div className='mt-2'>
          <Typography variant='s2'>Reason: {reason}</Typography>
        </div>
      )}
      {alert_name?.alert_name === ALERT_TYPE.POSSIBLE_FRAUD_DETECTED && (
        <div className='mt-3'>
          <Typography variant='s2'>Here is what we have gathered</Typography>
          <div className='d-flex flex-column mt-2 gap-2'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Account Owner Name:{' '}
              <Typography variant='s2'>{details_info?.accounts?.[0]?.owners?.[0]?.names?.join(', ')}</Typography>
            </Typography>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Addresses:{' '}
              {details_info?.accounts?.[0]?.owners?.[0]?.addresses?.map((item) => (
                <Typography variant='s2' as='p' className='mb-0'>
                  {item.data?.street} {item.data?.city} {item.data?.region}, {item.data?.postal_code}{' '}
                  {item.data?.country}
                </Typography>
              ))}
            </Typography>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Emails:{' '}
              <Typography variant='s2'>
                {details_info?.accounts?.[0]?.owners?.[0]?.emails?.map((item) => (
                  <Typography variant='s2' as='p' className='mb-0'>
                    {item.data}
                  </Typography>
                ))}
              </Typography>
            </Typography>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Phone Numbers:{' '}
              <Typography variant='s2'>
                {details_info?.accounts?.[0]?.owners?.[0]?.phone_numbers?.map((item) => (
                  <Typography variant='s2' as='p' className='mb-0'>
                    {item.data}
                  </Typography>
                ))}
              </Typography>
            </Typography>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default AlertInfo;
