import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;

  .settings-header {
    margin-bottom: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    margin-top: 24px;

    .form-field-row {
      display: flex;
      align-items: center;
      column-gap: 8px;

      :not(:last-child) {
        border-bottom: 1px solid ${palette.gray50};
        padding-bottom: 18px;
      }
    }
  }
`;
