import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Divider from 'common/Divider';
import { tabs } from './Bills.data';
import { Count, STableWrapper, STabs } from './Bills.styles';

const Bills = ({ vendor, refreshList }) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!vendor) {
    return null;
  }

  return (
    <STableWrapper>
      <STabs value={Number(activeTab) || 0} onChange={(e, newValue) => setActiveTab(newValue)}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            id={`tab-${index}`}
            value={index}
            label={
              <>
                {tab.label}
                {tab.key === 'openBills' && vendor?.carrier?.open_bill_count > 0 && (
                  <Count>{vendor.carrier.open_bill_count}</Count>
                )}
              </>
            }
            iconPosition='end'
            disableRipple
          />
        ))}
      </STabs>
      <Divider margin='2px 0 16px 0' />
      {tabs[Number(activeTab) || 0]?.component({ vendor, refreshList })}
    </STableWrapper>
  );
};

export default Bills;
