import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import Divider from 'common/Divider';
import { palette } from 'utils/constants';
import { SActionsWrapper, SDotsWrapper, SPopover } from '../Cards.styles';

const RowActions = ({
  row,
  onAssignCard,
  onDeleteCard,
  onLoadCash,
  onSetLimit,
  onSetPin,
  onSetRestriction,
  onUpdateStatus,
  onCardDetails,
  setOpenFeatureNotAvailable,
  onViewCashHistory,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const onDotsClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onViewTransactions = () => {
    navigate(`/driver-profile/${row.user.id}/transactions?provider=44`);
  };

  const onClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'assignCard':
        onAssignCard();
        break;
      case 'deleteCard':
        onDeleteCard();
        break;
      case 'loadCash':
        onLoadCash();
        break;
      case 'setLimit':
        onSetLimit();
        break;
      case 'setPin':
        // setOpenFeatureNotAvailable(true);
        onSetPin();
        break;
      case 'setRestriction':
        onSetRestriction();
        break;
      case 'updateStatus':
        onUpdateStatus();
        break;
      case 'replaceCard':
        setOpenFeatureNotAvailable(true);
        break;
      case 'cardDetails':
        onCardDetails();
        break;
      case 'viewTransactions':
        onViewTransactions();
        break;
      case 'viewCashHistory':
        onViewCashHistory();
        break;
      default:
    }
  };

  return (
    <SActionsWrapper>
      <SDotsWrapper className='action-dots'>
        <span className='dots' onClick={onDotsClick}>
          <ActionDots width={15} height={15} />
        </span>
        <SPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className='actions-wrapper'>
            {row?.user ? (
              <>
                <li onClick={(event) => onActionClick(event, 'loadCash')}>Load / Remove Cash</li>
                <li onClick={(event) => onActionClick(event, 'viewCashHistory')}>View Cash History</li>
                <li onClick={(event) => onActionClick(event, 'cardDetails')}>Card Details</li>
                <li onClick={(event) => onActionClick(event, 'setLimit')}>Set Spend Limit</li>
                <li onClick={(event) => onActionClick(event, 'setPin')}>Set PIN</li>
                <li onClick={(event) => onActionClick(event, 'setRestriction')}>Set Restriction</li>
                <li onClick={(event) => onActionClick(event, 'updateStatus')}>Update Status</li>
                <li onClick={(event) => onActionClick(event, 'viewTransactions')}>View Transactions</li>
                <Divider margin='4px 0' />
                <li onClick={(event) => onActionClick(event, 'replaceCard')}>Replace Card</li>
                <li className='delete-action' onClick={(event) => onActionClick(event, 'deleteCard')}>
                  Remove User
                </li>
              </>
            ) : (
              <>
                <li style={{ color: palette.indigo500 }} onClick={(event) => onActionClick(event, 'assignCard')}>
                  Assign Card
                </li>
                <li onClick={(event) => onActionClick(event, 'replaceCard')}>Replace Card</li>
                <Divider margin='4px 0' />
                <li onClick={(event) => onActionClick(event, 'loadCash')}>Load / Remove Cash</li>
                <li onClick={(event) => onActionClick(event, 'viewCashHistory')}>View Cash History</li>
                <li onClick={(event) => onActionClick(event, 'cardDetails')}>Card Details</li>
                <li onClick={(event) => onActionClick(event, 'setLimit')}>Set Spend Limit</li>
                <li onClick={(event) => onActionClick(event, 'setPin')}>Set PIN</li>
                <li onClick={(event) => onActionClick(event, 'setRestriction')}>Set Restriction</li>
                <li onClick={(event) => onActionClick(event, 'updateStatus')}>Update Status</li>
              </>
            )}
          </div>
        </SPopover>
      </SDotsWrapper>
    </SActionsWrapper>
  );
};

export default RowActions;
