import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getEquipmentDocumentTypes } from 'Api/Equipment';
import { getHighwayTaxPolicy, getIFTAPolicy, getInsurancePolicies, getKYUPolicy } from 'Api/Insurance';
import FormRow from './FormRow';
import { initialValues } from '../UpdateDocuments/UpdateDocuments.data';
import { SAddMore, STable } from './DocumentsList.styles';

const DocumentsList = ({
  form,
  readOnly,
  hideAddNew,
  equipmentId,
  equipmentType,
  onDeleteSuccess = () => null,
  isUpdate,
  documentsToDelete,
  setDocumentsToDelete,
}) => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [insuranceSettings, setInsuranceSettings] = useState(null);
  const { values, handleChange } = form;

  const getDocumentTypes = async () => {
    try {
      const { data } = await getEquipmentDocumentTypes();
      setDocumentTypes((data || []).filter((item) => equipmentType === 'vehicle' || [1, 2, 9, 10].includes(item.id)));
    } catch (e) {
      // Do nothing
    }
  };

  const getInsuranceSettings = async () => {
    try {
      const [{ value: insurance }, { value: highwayTax }, { value: ifta }, { value: kyu }] = await Promise.allSettled([
        getInsurancePolicies(),
        getHighwayTaxPolicy(),
        getIFTAPolicy(),
        getKYUPolicy(),
      ]);
      setInsuranceSettings({
        ...(insurance?.data || {}),
        ...(highwayTax?.data || {}),
        ...(ifta?.data || {}),
        ...(kyu?.data || {}),
      });
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getInsuranceSettings();
  }, []);

  const onAddRow = () => {
    handleChange(`documents[${values.documents.length}]`, { ...initialValues, id: uuid() });
  };

  useEffect(() => {
    getDocumentTypes();
  }, [equipmentType]);

  return (
    <div>
      <STable>
        <tbody>
          {values.documents.map((item, index) => (
            <FormRow
              key={item.id}
              form={form}
              index={index}
              document={item}
              readOnly={readOnly}
              documentTypes={documentTypes}
              insuranceSettings={insuranceSettings}
              equipmentId={equipmentId}
              onDeleteSuccess={onDeleteSuccess}
              isUpdate={isUpdate}
              documentsToDelete={documentsToDelete}
              setDocumentsToDelete={setDocumentsToDelete}
            />
          ))}
        </tbody>
      </STable>
      <div className='mt-3'>
        {!readOnly && !hideAddNew && (
          <SAddMore onClick={() => onAddRow()}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another
            </Typography>
          </SAddMore>
        )}
      </div>
    </div>
  );
};

export default DocumentsList;
