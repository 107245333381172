import React, { useMemo, useState } from 'react';
import Autocomplete from 'common/Autocomplete';
import useShowToaster from 'hooks/useShowToaster';
import { addCity } from 'Api/User';

const CityAutocomplete = ({ values, selectedState, setFieldValue, cities, getCity }) => {
  const showToaster = useShowToaster();
  const [inputValue, setInputValue] = useState('');
  const updatedOptions = useMemo(() => {
    if (inputValue && cities.every((i) => i.name !== inputValue)) {
      return [{ name: `+ Add "${inputValue}"`, id: 'add-new', type: '' }, ...cities];
    }
    return cities;
  }, [inputValue, cities]);

  const onAddNew = async (newCity) => {
    if (!selectedState) {
      showToaster({ type: 'error', message: 'Please select state before adding new city!' });
      setInputValue('');
      return;
    }
    try {
      const body = {
        name: newCity,
        state_id: selectedState.id,
      };
      const res = await addCity(body);
      getCity(selectedState.id);
      setFieldValue('city', res);
    } catch (e) {
      // Do nothing
    }
  };

  const handleChange = (e, val) => {
    if (val?.id === 'add-new') {
      onAddNew(inputValue);
      return;
    }

    setFieldValue('city', val);
  };

  return (
    <Autocomplete
      name='city'
      labelKey='name'
      placeholder='City...'
      options={updatedOptions}
      value={values.city}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(e, val) => setInputValue(val)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default CityAutocomplete;
