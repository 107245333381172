import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomizedSnackbars from 'components/toast/Toast';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { AppendToFormData } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import {
  createCustomerFuelMatrix,
  deleteCustomersFuelSurchargeMatrix,
  getCommodity,
  getCustomerFuelSurchargeMatrix,
  getFullPrices,
  updateCustomersFuelSurchargeMatrix,
} from 'Api/Customers';
import ColumnHeader from 'common/ColumnHeader';
import useDateFormat from 'hooks/useDateFormat';
import sm from './FuelSurchargeMatrixTab.module.css';

const FuelSurchargeMatrixTab = () => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [toggle, setToggle] = useState(false);
  const [fullPrice, setFullPrice] = useState({});
  const [shipmentTypes, setShipmentTypes] = useState();
  const [fuelSurchargeMatrix, setFuelSurchargeMatrix] = useState();
  const [refetchIndex, setRefetchIndex] = useState(Date.now());
  const [changedIndexes, setChangedIndexes] = useState([]);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const { id } = useParams();

  const onUpdate = (el, index, values, errors, getFieldHelpers, arrayHelpers) => {
    if (changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
    const commodity_type_id = getFieldHelpers(`fuel_matrix[${index}][commodity_type_id]`);
    const pricing_date = getFieldHelpers(`fuel_matrix[${index}][pricing_date]`);
    const from = getFieldHelpers(`fuel_matrix[${index}][from]`);
    const to = getFieldHelpers(`fuel_matrix[${index}][to]`);
    const fsc = getFieldHelpers(`fuel_matrix[${index}][fsc]`);
    commodity_type_id.setTouched('Required');
    pricing_date.setTouched('Required');
    from.setTouched('Required');
    to.setTouched('Required');
    fsc.setTouched('Required');
    if (el?.id) {
      updatesFuelSurchargeMatrixUpdate(el, values);
    } else {
      createFuelSurchargeMatrixUpdate(el, values, index, arrayHelpers);
    }
  };

  const updatesFuelSurchargeMatrixUpdate = (el) => {
    const updateData = {
      customer_id: el.customer_id,
      commodity_type_id: el.commodity_type_id,
      pricing_date: moment(el.pricing_date).isValid ? moment(el.pricing_date).format('YYYY-MM-DD') : null,
      from: el.from,
      to: el.to,
      fsc: el.fsc,
    };

    updateCustomersFuelSurchargeMatrix(updateData, el.id).then((res) => {
      if (res && res.data) {
        setShowMessage({ ...showMessage, message: 'Fuel Surcharge Matrix updated successfully', visible: true });
      }
    });
  };

  const createFuelSurchargeMatrixUpdate = (el, values, index) => {
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, index);
    const map = mapper(updateData, index);
    Object.keys(map).forEach((key) => {
      if (key) {
        formData.append(key, map[key]);
      }
    });
    if (moment(el.pricing_date).isValid()) {
      formData.append(`fuel_matrix[${index}][pricing_date]`, moment(el.pricing_date).format('YYYY-MM-DD'));
    }
    createCustomerFuelMatrix(formData).then((res) => {
      if (Number(res?.status) === 200) {
        setShowMessage({ ...showMessage, message: 'Fuel Surcharge Matrix created successfully!', visible: true });
      }
    });
  };

  const mapper = (obj, index) => {
    const newData = {
      customer_id: id,
    };
    const namesMap = {
      [`fuel_matrix[${index}]commodity_type_id`]: [`fuel_matrix[${index}][commodity_type_id]`],
      [`fuel_matrix[${index}]from`]: [`fuel_matrix[${index}][from]`],
      [`fuel_matrix[${index}]to`]: [`fuel_matrix[${index}][to]`],
      [`fuel_matrix[${index}]fsc`]: [`fuel_matrix[${index}][fsc]`],
    };

    Object.keys(obj).forEach((key) => {
      const name = namesMap[key];
      newData[name] = obj[key];
    });

    return newData;
  };

  const onCancel = () => {
    setToggle(!toggle);
    setRefetchIndex(Date.now());
    setShowMessage({
      ...showMessage,
      message: '',
      visible: false,
    });
    setChangedIndexes([]);
  };

  const formRef = useRef();

  const { use } = useTheme();
  const defaultValue = {
    commodity_type_id: '',
    pricing_date: null,
    from: '',
    to: '',
    fsc: '',
  };
  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Shipment Type', width: '15%', required: true },
      { key: 2, label: 'Pricing Date', width: '10%', required: true },
      { key: 3, label: 'From', width: '10%', required: true },
      { key: 4, label: 'To', width: '10%', required: true },
      { key: 5, label: 'FSC', width: '13%', required: true },
      { key: 6, label: '', width: '5%' },
    ];
  }, []);

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const onDelete = (el, index, arrayHelpers) => {
    if (el?.id) {
      deleteCustomersFuelSurchargeMatrix(el.id).then(() => {
        arrayHelpers.remove(index);
        setShowMessage({ ...showMessage, message: 'Fuel Surcharge Matrix deleted successfully', visible: true });
      });
    } else {
      arrayHelpers.remove(index);
    }
  };

  useEffect(() => {
    getFullPrices().then((res) => {
      if (res && res?.data) {
        const price = res.data?.diesel;
        setFullPrice(price);
      }
      return res;
    });

    getCommodity().then((res) => {
      if (res && res?.data) {
        const any = [{ id: '*', title: 'Any' }];
        const options = any.concat(res.data).map((el) => {
          return {
            ...el,
            key: el.id,
            label: el.title,
            labelSelected: null,
          };
        });
        setShipmentTypes(options);
      }
    });

    getCustomerFuelSurchargeMatrix({ id }).then((res) => {
      setFuelSurchargeMatrix({
        fuel_matrix: {
          data: res.data.map((item) => ({
            ...item,
            pricing_date: moment(item.pricing_date).toDate(),
          })),
        },
      });
    });
  }, [refetchIndex]);

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
        maxWidth: '300px',
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = fuelSurchargeMatrix?.fuel_matrix?.data?.length
    ? {
        fuel_matrix: fuelSurchargeMatrix?.fuel_matrix?.data,
      }
    : { fuel_matrix: [{ ...defaultValue }] };

  const handleSelectChange = (index) => {
    if (!changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const handleFieldChange = (index, fieldName, values, setChangedIndexes, changedIndexes) => (event) => {
    const newValue = event.target.value;
    const oldValue = values.fuel_matrix[index][fieldName];
    const isChanged = newValue !== oldValue;

    if (isChanged && !changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    } else if (!isChanged && changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
  };

  return (
    <div
      className={sm.fuelSurchargeMatrixTab}
      style={{
        backgroundColor: use(palette.gray0, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className={sm['fuelSurchargeMatrixTab-header']}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='d-flex flex-column'>
          <p className={sm.heading} style={{ color: use(palette.gray900, palette.gray50) }}>
            EIA
          </p>
          <Typography variant='s2' sx={{ alignItems: 'center' }}>
            {fullPrice.type}: {fullPrice.price} {fullPrice.unit} as of {fullPrice.date}
          </Typography>
        </div>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={onCancel} className='driver-compansation-cancel-btn'>
              Close
            </button>
          </div>
        )}
      </div>
      {toggle ? (
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Formik initialValues={initialValue} innerRef={formRef}>
                {({ values, getFieldHelpers, setFieldValue, errors, touched }) => (
                  <Form>
                    <FieldArray
                      name='fuel_matrix'
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            <div className={sm.row}>
                              {titlesOption.map((el) => {
                                return (
                                  <Typography
                                    key={el?.label}
                                    variant='s2'
                                    style={{ width: el.width, whiteSpace: 'nowrap' }}
                                  >
                                    {el.label}
                                    {el.required && <span className={sm.required_system}>*</span>}
                                  </Typography>
                                );
                              })}
                            </div>

                            {values.fuel_matrix.map((el, index) => {
                              const namePrefix = `fuel_matrix[${index}]`;
                              return (
                                <div key={el?.id} className={[sm.row, sm.line].join(' ')} style={{ marginTop: 0 }}>
                                  <div style={{ width: '15%' }}>
                                    <Field
                                      name={`${namePrefix}[commodity_type_id]`}
                                      options={shipmentTypes}
                                      styles={styles.type}
                                      component={CustomSelect}
                                      menuStyles={styles.fullWidthPercent}
                                      validate={validateStopPointInput}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[commodity_type_id]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <DatePicker
                                      name={`${namePrefix}.pricing_date`}
                                      value={values.fuel_matrix[index].pricing_date}
                                      onChange={(val) => {
                                        setFieldValue(`${namePrefix}.pricing_date`, val);
                                        handleSelectChange(index);
                                      }}
                                      disablePast
                                      error={
                                        touched?.fuel_matrix?.[index]?.pricing_date &&
                                        errors?.fuel_matrix?.[index]?.pricing_date
                                      }
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[from]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 30, maxWidth: '100px' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                      onChange={handleFieldChange(
                                        index,
                                        'from',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      leftIcon={<p style={{ margin: 0, paddingBottom: 3 }}>{currency}</p>}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[from]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[to]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 24, maxWidth: '100px' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      onChange={handleFieldChange(
                                        index,
                                        'to',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      className={sm.input_basic_details}
                                      leftIcon={<p style={{ margin: 0, paddingBottom: 3 }}>{currency}</p>}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[to]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '13%' }}>
                                    <Field
                                      name={`${namePrefix}[fsc]`}
                                      component={CustomInput}
                                      style={{
                                        marginTop: 0,
                                        height: '32px',
                                        borderRadius: '8px 0 0 8px',
                                      }}
                                      labelStyle={{
                                        alignItems: 'flex-start',
                                        marginTop: 0,
                                      }}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                      onChange={handleFieldChange(
                                        index,
                                        'fsc',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      rightParentText={<p style={{ margin: 0, padding: 3 }}>/mile</p>}
                                      rightParentTextStyle={{
                                        color: use(palette.gray500, palette.gray200),
                                        backgroundColor: palette.gray0,
                                        height: '32px',
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[fsc]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>
                                  <div
                                    style={{ width: '5%', height: 32 }}
                                    className='d-flex justify-content-center align-items-center gap-2'
                                  >
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => onDelete(el, index, arrayHelpers)}
                                    />
                                    {!changedIndexes.includes(index) ? (
                                      <TickIcon fill={use(palette.green500, palette.white)} style={styles.deleteIcon} />
                                    ) : (
                                      <CustomButton
                                        type='primary'
                                        title=''
                                        styleButton={{
                                          padding: '6px 12px',
                                          margin: '6px 0 0 0',
                                        }}
                                        styleTitle={{ fontSize: 14 }}
                                        onClick={() => onUpdate(el, index, values, errors, getFieldHelpers)}
                                        leftIcon={
                                          <TickIcon
                                            fill={use(palette.white, palette.white)}
                                            style={{ width: 9, height: 9 }}
                                          />
                                        }
                                        buttonProps={{
                                          type: 'submit',
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}

                            <CustomButton
                              type='secondary'
                              title='Add Another'
                              styleTitle={styles.addAnotherTitle}
                              styleButton={styles.addAnotherButton}
                              onClick={() => arrayHelpers.push({ ...defaultValue })}
                              leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                            />
                          </div>
                        );
                      }}
                    />
                  </Form>
                )}
              </Formik>
              {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
            </div>
          </div>
        </div>
      ) : (
        <div className={sm['table-wrap']}>
          <div className='sub-table-container'>
            <MaterialTableWrapper
              columns={[
                {
                  title: <ColumnHeader title='SHIPMENT TYPE' field='commodity_type_id' />,
                  field: 'commodity_type_id',
                  render: (rowData) => {
                    const commodity_type = rowData?.commodity_type;
                    const commodity_type_id = rowData?.commodity_type_id;
                    return (
                      <p className={sm['list-text']}>
                        {commodity_type?.title ? commodity_type?.title : commodity_type_id === '*' ? 'Any' : '-'}
                      </p>
                    );
                  },
                },
                {
                  title: <ColumnHeader title='PRICING DATE' field='pricing_date' />,
                  field: 'pricing_date',
                  render: (rowData) => {
                    return (
                      <p className={sm['list-text']}>{rowData.pricing_date ? formatDate(rowData.pricing_date) : '-'}</p>
                    );
                  },
                },
                {
                  title: <ColumnHeader title='FROM' field='from' />,
                  field: 'from',
                  render: (rowData) => {
                    return (
                      <p className={sm['list-text']}>
                        {currency} {rowData.from}
                      </p>
                    );
                  },
                },
                {
                  title: <ColumnHeader title='TO' field='to' />,
                  field: 'to',
                  render: (rowData) => {
                    return (
                      <p className={sm['list-text']}>
                        {currency} {rowData.to}
                      </p>
                    );
                  },
                },
                {
                  title: <ColumnHeader title='FSC' field='fsc' />,
                  field: 'fsc',
                  render: (rowData) => {
                    return (
                      <p className={sm['list-text']}>
                        {currency}
                        {formatNumber(rowData.fsc)}/mile
                      </p>
                    );
                  },
                },
              ]}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
              data={fuelSurchargeMatrix?.fuel_matrix?.data}
              options={{
                sorting: false,
                toolbar: false,
                draggable: false,
                paging: false,
                rowStyle: {
                  borderBottom: 'none',
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FuelSurchargeMatrixTab;
