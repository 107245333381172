import React, { useMemo, useState } from 'react';
import Tabs from 'common/Tabs';
import Modal from 'common/Modal';
import Divider from 'common/Divider';
import { palette } from 'utils/constants';
import HardwareCard from './HardwareCard';
import { SHardware } from './Hardware.styles';

const Hardware = ({ open, onClose, hardware, selectedHardware, handleSubmit }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [cartItems, setCartItems] = useState(selectedHardware || []);
  const [tab, setTab] = useState('all');

  const filteredHardware = useMemo(() => {
    return hardware.filter((i) => tab === 'all' || i.category === tab);
  }, [tab, hardware]);

  const tabs = useMemo(() => {
    return hardware.reduce(
      (acc, cur) => {
        if (!cur.category) {
          return acc;
        }

        const tabIndex = acc.findIndex((i) => i.value === cur.category);

        if (tabIndex !== -1) {
          acc[tabIndex].count += 1;
        } else {
          acc.push({ label: cur.category, value: cur.category, count: 1 });
        }

        return acc;
      },
      [{ label: 'All', value: 'all', count: hardware.length }]
    );
  }, [hardware]);

  const onSubmit = () => {
    handleSubmit(cartItems);
    onClose();
  };

  const addToCart = (data, quantity) => {
    const foundItem = cartItems.find((item) => item.id === data.id);

    if (foundItem) {
      if (quantity === 0) {
        setCartItems((prevState) => prevState.filter((item) => item.id !== foundItem.id));
        return;
      }
      setCartItems((prevState) => prevState.map((item) => (item.id === foundItem.id ? { ...item, quantity } : item)));
      return;
    }

    if (quantity !== 0) {
      setCartItems((prevState) => [...prevState, { ...data, quantity }]);
    }
  };

  const handleCardClick = (idx) => {
    setActiveCard(idx);
  };

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Hardware'
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='80vw'
      $minHeight='500px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update Cart',
          onClick: onSubmit,
        },
      ]}
    >
      <SHardware>
        <Tabs tabs={tabs} activeTab={tab} handleTabChange={handleTabChange} />
        <Divider margin='0 0 12px' />
        <div className='hardware-list'>
          {filteredHardware?.map((data, index) => (
            <HardwareCard
              key={data.id}
              data={data}
              index={index}
              activeCard={activeCard}
              initialQuantity={cartItems.find((item) => item.id === data.id)?.quantity || 0}
              handleCardClick={handleCardClick}
              addToCart={addToCart}
              hardwareList={hardware}
            />
          ))}
        </div>
      </SHardware>
    </Modal>
  );
};

export default Hardware;
