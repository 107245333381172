import { number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  incident_date: validator.pastRequiredDate(),
  incident_type: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  report_details: string().trim().required('Required'),
  action_taken: object().when('canReview', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
