import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Add from 'assets/icons/drivers/add.svg';
import Copy from 'assets/icons/drivers/copy.svg';
import More from 'assets/icons/drivers/more.svg';
import { AddDocument } from 'Api/Driver';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import License from 'assets/icons/drivers/license.svg';
import { getErrorMessage } from 'utils/error';
import { operatorDocumentData, operatorDocumentUpdate, OperatorRemoveDocumentData } from 'Api/OwnerOperator';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import MaterialTableWrapper, { MaterialTableSort } from '../MaterialTableWrapper';
import DriverLicense from '../DriverLicense/DriverLicense';
import OwnerUpdateDocumentModal from '../OwnerUpdateDocumentModal/OwnerUpdateDocumentModal';
import OwnerAddDocumentModal from '../OwnerAddDocumentModal/OwnerAddDocumentModal';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';

const OwnerDocument = () => {
  const { formatDate } = useDateFormat();
  const showToaster = useShowToaster();
  const [modalShow, setModalShow] = React.useState(false);
  const [updateModalShow, setUpdateModalShow] = React.useState(false);
  const [driverIndex, setDriverIndex] = React.useState(0);
  const { id } = useParams();
  const { use } = useTheme();
  const [createLoading, setCreateLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [updateDocumentData, setUpdateDocumentData] = useState([]);

  const showError = (message) => {
    showToaster({ type: 'error', message });
  };

  const onSuccess = (message) => {
    showToaster({ type: 'success', message });
  };

  useEffect(() => {
    getDriverData();
  }, []);

  const getDriverData = async () => {
    operatorDocumentData({ id }).then((res) => {
      setDocuments(res?.data);
    });
  };
  const AddUpdateDocuments = async (documentsInfo, type, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);
    if (type === 'add') {
      AddDocument(documentsInfo)
        .then(() => {
          setCreateLoading(false);
          getDriverData();
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
        })
        .catch((error) => {
          setCreateLoading(false);
          showError(getErrorMessage(error));
        });
    } else {
      delete documentsInfo?.index;
      delete documentsInfo?.tableData;
      if (documentsInfo?.document_path.startsWith('https')) {
        delete documentsInfo?.document_path;
      }
      operatorDocumentUpdate([documentsInfo])
        .then(() => {
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          getDriverData();
          setCreateLoading(false);
        })
        .catch((error) => {
          setCreateLoading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  const deleteDocument = (item) => {
    OperatorRemoveDocumentData(item.id)
      .then(() => {
        onSuccess('Document has been deleted successfully!');
        getDriverData();
      })
      .catch((error) => {
        showError(getErrorMessage(error));
      });
  };

  async function downloadImage(file) {
    if (file.includes('.pdf')) {
      fetch(file, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'driver.pdf';
          link.click();
        });
    } else {
      fetch(file, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/png' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'driver.png';
          link.click();
        });
    }
  }

  function sortingQuery(field, sortBy) {
    const sortField = `sort[][${field}]`;

    getSortedDocuments(sortField, sortBy);
  }

  const getSortedDocuments = (sortField, sortBy) => {
    operatorDocumentData({ id, sortField, sortBy }).then((res) => {
      setDocuments(res?.data);
    });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'document_name',
        title: <MaterialTableSort title='NAME' field='document_name' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <div onClick={() => setDriverIndex(rowData?.tableData?.id)}>
              <div className='document-details-wrap'>
                <p className='document-details-name'>{rowData?.document_name}</p>
                <img src={Copy} className='hidden-icon' alt='Copy' />
              </div>
            </div>
          );
        },
      },
      {
        field: 'expiry_date',
        title: <MaterialTableSort title='EXPIRY' field='expiry_date' sortingQuery={sortingQuery} />,
        render: (rowData) => (
          <div onClick={() => setDriverIndex(rowData?.tableData?.id)}>
            <div className='document-details-wrap-right'>
              <p className='document-details-value'>
                {' '}
                {rowData?.expiry_date ? formatDate(rowData?.expiry_date || new Date()) : 'No Expiration'}
              </p>
              <div className='hidden-icon'>
                <Dropdown className='driver-document-document'>
                  <Dropdown.Toggle className='menu-drop' id='dropdown-basic'>
                    <img src={More} alt='' className='hidden icon' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-menu-contain'>
                    <Dropdown.Item
                      className='drop-text-contain'
                      href='#/action-1'
                      onClick={() => downloadImage(rowData?.document_path)}
                    >
                      <p className='edit-text'>Download</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='drop-text-contain'
                      href='#/action-2'
                      onClick={() => {
                        setUpdateDocumentData({
                          ...rowData,
                          index: rowData?.tableData?.id,
                          operator_id: id,
                          no_expiry: !rowData.expiry_date,
                        });
                        setUpdateModalShow(true);
                      }}
                    >
                      <p className='edit-text'>Edit</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='drop-text-contain'
                      href='#/action-3'
                      onClick={() => deleteDocument(rowData, rowData?.tableData?.id)}
                    >
                      <p className='delete-text'>Delete</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div
      className='Driver-document-tab  driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div
        className='driver-document'
        style={{
          borderColor: use(palette.gray50, palette.darkborder),
          backgroundColor: use(palette.white, palette.dark800),
        }}
      >
        {!!modalShow && (
          <OwnerAddDocumentModal
            show={!!modalShow}
            onHide={() => setModalShow(false)}
            modalShow={modalShow}
            onSuccess={onSuccess}
            loading={createLoading}
            showError={showError}
            AddUpdateDocuments={AddUpdateDocuments}
            id={id}
          />
        )}
        {!!updateModalShow && (
          <OwnerUpdateDocumentModal
            show={!!updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            updateDocumentData={updateDocumentData}
            setUpdateDocumentData={setUpdateDocumentData}
            onSuccess={onSuccess}
            showError={showError}
            loading={createLoading}
            AddUpdateDocuments={AddUpdateDocuments}
            id={id}
          />
        )}

        <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
          <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Documents
          </p>
          <PrimaryBtn
            icon={Add}
            style={{ color: use(palette.gray700, palette.gray200) }}
            title='Add Document'
            onClick={() => setModalShow(true)}
          />
        </div>

        <div className='document-details'>
          <MaterialTableWrapper
            data={documents}
            style={{ backgroundColor: use(palette.white, palette.dark800), maxHeight: 'maxContent' }}
            columns={columns}
          />
        </div>
      </div>
      <DriverLicense
        picture={documents[driverIndex]?.document_path ? documents[driverIndex]?.document_path : License}
      />
    </div>
  );
};

export default OwnerDocument;
