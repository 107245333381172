import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './accountSummary.css';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import cardsImg from 'assets/images/onboarding/image 18.png';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import Input from 'common/Input';
import MessagePopup from 'common/MessagePopup';
import { Typography } from 'components/Typography';
import ErrorMessage from 'common/message/errorMessage';
import SplashScreen from 'components/splashScreen/splashScreen';
import { planType } from 'components/customerOnboardingComponents/data';
import { formatNumber, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import AuthService from 'services/auth.service';
import achImg from 'assets/images/onboarding/ach.png';
import { createPaymentIntent, GetPurchaseAddonList } from 'Api/Billing';
import styles from 'components/Billing/PurchaseAddOnsModalFinal/PurchaseAddOnsModalFinal.module.css';
import CustomButton from 'components/CustomButton/CustomButton';

const API_URL = process.env.REACT_APP_API_URL;
const steps = ['Creating your account..', 'Creating database..', 'Creating storage bucket..', 'Finishing up..'];

const countryShortName = {
  231: 'US',
  38: 'CA',
  142: 'MX',
};

const numberRegex = /^[0-9]*$/;

const AccountSummary = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { onboardingSettings } = useSelector((state) => state.onboarding);
  const plan = JSON.parse(localStorage.getItem('plan-selected'));
  const acc = JSON.parse(localStorage.getItem('accountValid'));
  const accountVal = JSON.parse(localStorage.getItem('account'));
  const selectedPlan = JSON.parse(localStorage.getItem('selected-plan'));
  const goBackFromCompany = JSON.parse(localStorage.getItem('goBackFromCompany'));
  const brokerInfo = JSON.parse(localStorage.getItem('brokerInfo'));
  const coupon = JSON.parse(localStorage.getItem('coupon'));

  const [hardwares, setHardwares] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [numberOfUser, setNumberOfUser] = useState(Number(accountVal ? accountVal.workers : 1));
  const [totalOfHardware, setTotalOfHardware] = useState(0.0);
  const [totalShipping, setTotalShipping] = useState(0.0);
  const [totalOfTax, setTotalOfTax] = useState(0.0);
  const [subTotal, setSubtotal] = useState(0.0);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [sumOfTotalHardware, setSumOfTotalHardware] = useState(0.0);
  const [taxRate, setTaxRate] = useState(0);
  const [hardware, setHardware] = useState([]);
  const [isApplied, setIsApplied] = useState(false);
  const [couponCode, setCouponCode] = useState(coupon?.code || '');
  const [couponCodeErr, setCouponCodeErr] = useState('');
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches);
  const [showMobileAlert, setShowMobileAlert] = useState(false);

  const submitForPrices = {
    plan_type_membership_id: plan.membership_plan_id,
    payment_method: paymentMethod || 'credit-card',
    number_of_users: numberOfUser,
    state_id: acc.shipping_state,
    hardware: hardwares,
    applied_coupon: couponCode,
  };
  const [sumOfTotalHardwareNumber, setSumOfTotalHardwareNumber] = useState();
  const [displayError, setDisplayError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isFinance, setIsFinance] = useState(false);
  const [isCreditCard, setIsCreditCard] = useState(false);
  const [isAch, setIsAch] = useState(false);
  const [onCredit, setonCredit] = useState(false);
  const [open, setOpen] = useState(false);
  const [brokerageAddon, setBrokerageAddon] = useState(null);

  const [wait, setWait] = useState(false);
  const [summary, setSummary] = useState();
  const [cardErr, setCardErr] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [checkBox, setCheckBox] = useState({
    terms_and_conditions: false,
    privacy_policy: false,
    billing_policy: false,
    send_sms: false,
  });

  const canBypassPayment = !!onboardingSettings && !onboardingSettings.is_require_payment_enable;
  const duration = summary?.plan_prices?.plan?.duration;
  const brokerageMonthly = Number(brokerageAddon?.plans?.[0]?.monthly_amount) || 0;
  const brokerageTotal = brokerageMonthly * (planType[plan.duration].monthsCount || 1) || 0;
  const brokerageDiscountAmount = ((summary?.plan_prices?.promo_code?.value || 0) * brokerageTotal) / 100;
  const brokerageTotalWithDiscount = brokerageTotal - brokerageDiscountAmount || 0;
  const financingDisabled =
    (summary?.plan_prices?.payment_info?.monthly_payment || 0) * (planType[duration]?.monthsCount || 1) +
      brokerageTotalWithDiscount <
    5000;

  const createAccount = async (paymentData) => {
    let stripe_token;
    if (paymentMethod === 'credit-card') {
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardHolderName || undefined,
          email: acc?.email,
          phone: acc?.phone_number,
          address: {
            city: goBackFromCompany?.city?.name,
            country: countryShortName[acc?.country_id],
            line1: acc?.address1,
            line2: acc?.address2,
            state: goBackFromCompany?.state?.name,
          },
        },
      });
      stripe_token = payload.paymentMethod?.id;
    }

    if (paymentMethod === 'ach') {
      const { client_secret } = await createPaymentIntent();
      await stripe.collectBankAccountForPayment({
        clientSecret: client_secret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: acc?.company_name,
              email: acc?.email,
              phone: acc?.phone_number,
              address: {
                city: goBackFromCompany?.city?.name,
                country: countryShortName[acc?.country_id],
                line1: acc?.address1,
                line2: acc?.address2,
                state: goBackFromCompany?.state?.name,
                postal_code: acc?.zip,
              },
            },
          },
        },
      });
      const stripePayload = await stripe.confirmUsBankAccountPayment(client_secret);
      if (stripePayload?.error) {
        return;
      }
      stripe_token = stripePayload.paymentIntent.payment_method;
    }
    setLoading(true);
    axios
      .post(`${API_URL}/user/registration/carrier/checkout`, {
        ...paymentData,
        broker_profile: brokerInfo
          ? {
              company_name: brokerInfo.company_name || null,
              dot: brokerInfo.dot || null,
              company_email: brokerInfo.email || null,
              address1: brokerInfo.address || null,
              address2: brokerInfo.address2 || null,
              phone_number: brokerInfo.phone_number || null,
              country_id: brokerInfo.country?.id || null,
              state_id: brokerInfo.state?.id || null,
              city_id: brokerInfo.city?.id || null,
              zipcode: brokerInfo.zipcode || null,
            }
          : null,
        stripe_token,
        total: summary?.total || 0,
        payment_method_bypass: Number(canBypassPayment),
        payment_method: canBypassPayment ? null : paymentData.payment_method,
      })
      .then((res) => {
        if (!matches) {
          setShowMobileAlert(true);
          setLoading(false);
          return;
        }
        if (res.data.token.access_token) {
          res.data.user.accessToken = res.data.token.access_token;
          setAuth({
            user: res.data.user,
            isLoggedIn: true,
            error: null,
            loading: false,
          });
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('accountStatus', JSON.stringify(res.data.customer_status_data));
          if (res.data?.chat_token) {
            localStorage.setItem('chatToken', JSON.stringify(res.data.chat_token));
          }
          AuthService.getCurrentUser();
        }

        localStorage.removeItem('hardwareCart');
        localStorage.removeItem('goBackCartItems');
        localStorage.removeItem('goBackFromCompany');
        localStorage.removeItem('price-summary');

        if (isMobile) {
          window.open('https://www.truckindigital.com/app-manager-install', '_self');
        } else {
          navigate('/dashboard', { replace: true });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const message = getErrorMessage(err);
        if (err.response.data?.errors) {
          setErrMsg(err.response.data?.errors?.customer_data[0]);
          setDisplayError(true);
          return;
        }
        setErrMsg(message || 'Something went wrong');
        setDisplayError(true);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (paymentMethod === 'credit-card') {
      const cardElementContainer = document.querySelector('#card-element');
      const cardElementEmpty = cardElementContainer?.classList?.contains('StripeElement--empty');
      if (cardElementEmpty) {
        setErrMsg('Please fill the card details');
        setDisplayError(true);
        return;
      }
      if (cardErr !== '') {
        return;
      }
    }
    if (!paymentMethod && (!onboardingSettings || !!onboardingSettings?.is_require_payment_enable)) {
      setErrMsg('Please select payment method');
      setDisplayError(true);
      return;
    }
    if (!checkBox.terms_and_conditions) {
      setErrMsg('The agreement terms and conditions field is required.');
      setDisplayError(true);
      return;
    }
    if (!checkBox.privacy_policy) {
      setErrMsg('The agreement privacy policy field is required.');
      setDisplayError(true);
      return;
    }
    if (!checkBox.billing_policy) {
      setErrMsg('The agreement billing policy field is required.');
      setDisplayError(true);
      return;
    }

    const paymentData = {
      agreement: {
        terms_and_conditions: checkBox.terms_and_conditions ? 'on' : '',
        privacy_policy: checkBox.privacy_policy ? 'on' : '',
        billing_policy: checkBox.billing_policy ? 'on' : '',
      },
      partner_referral_link: accountVal.partner_referral_link || undefined,
      plan_type_membership_id: plan.membership_plan_id,
      payment_method: paymentMethod,
      number_of_users: numberOfUser,
      applied_coupon: couponCode,
      customer_data: {
        ...JSON.parse(localStorage.getItem('accountValid')),
        send_sms: 1,
        customer_type: brokerInfo ? 'carrier_broker' : 'carrier',
      },
      hardware,
    };

    createAccount(paymentData);
  };

  const postPrice = (data) => {
    axios
      .post(`${API_URL}/user/registration/prices`, { ...data, source: 'onboarding' })
      .then((res) => {
        localStorage.setItem('price-summary', JSON.stringify(res.data));
        setSummary(res.data);
        if (data.applied_coupon) {
          if (!res.data?.plan_prices?.payment_info?.message) {
            setIsApplied(false);
            setCouponCodeErr('Enter valid promo code');
          } else {
            setIsApplied(true);
          }
        }
        return res.data;
      })
      .catch((err) => {
        if (err.response?.data) {
          setErrMsg(err.response.data.error?.message);
          setDisplayError(true);
        } else {
          setErrMsg(err.message);
          setDisplayError(true);
        }
      });
  };

  const removeCart = (data, qty) => {
    hardwares.forEach((ele) => {
      if (ele.id === data.id) {
        ele.quantity = qty;
      }
    });
    hardware.forEach((ele) => {
      if (ele.id === data.id) {
        ele.quantity = qty;
      }
    });
    if (qty <= 0) {
      setHardwares(hardwares.filter((ele) => ele.quantity > 0));
      setHardware(hardware.filter((ele) => ele.quantity > 0));
      submitForPrices.hardware = hardwares;
      postPrice(submitForPrices);
      return;
    }
    if (paymentMethod === 'credit-card') {
      submitForPrices.stripe_token = stripe?._apiKey;
    }
    postPrice(submitForPrices);
  };

  const getAddons = async () => {
    try {
      const { data } = await GetPurchaseAddonList();
      const brokerage = data?.find((i) => i.plan_id === 'qkxwy6');

      if (brokerage) {
        setBrokerageAddon(brokerage);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setCheckBox({ ...checkBox, [name]: !checkBox[name] });
  };

  const handleCouponCode = (e) => {
    setCouponCodeErr('');
    setCouponCode(e.target.value);
  };

  const handleApplyCoupon = () => {
    if (couponCode === '') {
      setCouponCodeErr('please enter code');
      return;
    }
    submitForPrices.applied_coupon = couponCode;
    if (paymentMethod === 'credit-card') {
      submitForPrices.stripe_token = stripe?._apiKey;
    }
    postPrice(submitForPrices);
  };

  const handlePaymentMethod = (payment_method) => {
    if (isAch && payment_method === 'ach') {
      setOpen(true);
      return;
    }
    setPaymentMethod(payment_method);
    if (payment_method === 'credit-card') {
      setonCredit(true);
    }
  };

  const handleCardChange = (e) => {
    if (e.empty) {
      setCardErr('Please fill the card details');
      return;
    }
    setCardErr('');
    if (e.error !== undefined) {
      setCardErr(e.error.message);
    }
  };

  const handleNumberUserDec = () => {
    if (numberOfUser <= 0) return;
    setNumberOfUser(numberOfUser - 1);
    submitForPrices.number_of_users = numberOfUser - 1;
    if (paymentMethod === 'credit-card') {
      submitForPrices.stripe_token = stripe?._apiKey;
    }
    postPrice(submitForPrices);
  };

  const handleNumberUserInc = () => {
    setNumberOfUser(numberOfUser + 1);
    submitForPrices.number_of_users = numberOfUser + 1;
    if (paymentMethod === 'credit-card') {
      submitForPrices.stripe_token = stripe?._apiKey;
    }
    postPrice(submitForPrices);
  };

  const handleNumberUserChange = (e) => {
    const isValid = numberRegex.test(e.target.value);
    if (!isValid) {
      return;
    }

    const value = !Number.isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
    setNumberOfUser(value);
    submitForPrices.number_of_users = value;
    if (paymentMethod === 'credit-card') {
      submitForPrices.stripe_token = stripe?._apiKey;
    }
    postPrice(submitForPrices);
  };

  const handleGoback = () => {
    localStorage.setItem('hardwareCart', JSON.stringify(hardware));
    localStorage.removeItem('goBackCartItems');
    localStorage.removeItem('price-summary');
    navigate('/onboarding/company', { replace: true });
  };

  useEffect(() => {
    if (paymentMethod === 'credit-card') {
      submitForPrices.stripe_token = stripe ? stripe._apiKey : process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    }
    postPrice(submitForPrices);
    setSummary(JSON.parse(localStorage.getItem('price-summary')));
  }, [plan.duration, stripe?._apiKey, paymentMethod, hardware]);

  useEffect(() => {
    setIsFinance(plan.duration === 'monthly');

    if (summary?.total < 1) {
      if (paymentMethod === 'ach') {
        setPaymentMethod('');
      }
      setIsAch(true);
    } else {
      setIsAch(false);
    }

    setIsCreditCard(false);
    if (plan.duration === 'monthly' && summary?.total < 1) {
      setIsAch(true);
    }

    if (paymentMethod === 'financing' && (isFinance || financingDisabled)) {
      setPaymentMethod('');
    }
  });

  useEffect(() => {
    const savedHardwares = JSON.parse(localStorage.getItem('hardwareItem'));
    const cartItems = JSON.parse(localStorage.getItem('hardware'));
    if (savedHardwares !== null) {
      setHardwares(savedHardwares);
    }
    if (cartItems !== null) {
      setHardware(cartItems);
    }

    if (brokerInfo) {
      getAddons();
    }
  }, []);

  useEffect(() => {
    if (hardware.length !== 0 && hardwares.length !== 0) {
      postPrice(submitForPrices);
    }
  }, [hardware, hardwares, wait]);

  useEffect(() => {
    setWait(summary === null || summary === undefined);
  }, [summary, wait]);

  useEffect(() => {
    setTotalOfTax(formatNumber(summary?.hardware_prices?.tax));
    setTotalShipping(formatNumber(summary?.hardware_prices?.shipping));
    setTotalOfHardware(summary?.hardware_prices?.total_without_discount);
    setSubtotal(formatNumber((summary?.total || 0) + brokerageTotalWithDiscount));
    setTaxRate(summary?.hardware_prices?.tax_rate);
    setDiscountAmount(
      formatNumber((summary?.plan_prices?.payment_info?.discount_amount || 0) + (brokerageDiscountAmount || 0))
    );
    const hwPrice = Number(summary?.hardware_prices?.shipping) + Number(summary?.hardware_prices?.tax);
    setSumOfTotalHardware(formatNumber(Number(summary?.hardware_prices?.total_without_discount) - hwPrice));
  }, [summary]);

  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  const btnStyle = {
    background: canBypassPayment
      ? palette.indigo500
      : paymentMethod === 'financing'
      ? palette.indigo500
      : paymentMethod === 'credit-card' && onCredit
      ? palette.indigo500
      : paymentMethod === 'ach'
      ? palette.indigo500
      : palette.white,

    color: canBypassPayment
      ? palette.white
      : paymentMethod === 'financing'
      ? palette.white
      : paymentMethod === 'credit-card' && onCredit
      ? palette.white
      : paymentMethod === 'ach'
      ? palette.white
      : palette.gray900,

    boxShadow:
      paymentMethod === 'financing'
        ? 'none'
        : paymentMethod === 'credit-card' && onCredit
        ? 'none'
        : paymentMethod === 'ach'
        ? 'none'
        : ' 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(104, 113, 130, 0.16) ',
  };

  useEffect(() => {
    if (subTotal) {
      setSumOfTotalHardwareNumber(Number(subTotal.replaceAll(',', '')));
    }
  }, [subTotal]);

  const monthlyPlanRecurring =
    Number(summary?.plan_prices.payment_info?.monthly_payment || 0) + Number(brokerageTotalWithDiscount || 0);

  return (
    <>
      <div className='account-summary'>
        {showMobileAlert && <MobileScreenAlert setShowMobileAlert={setShowMobileAlert} />}
        {wait ? (
          <div className='wait-for-price'>
            <p>Please wait .... </p>
          </div>
        ) : (
          ''
        )}
        <div className={`account-summary-container ${canBypassPayment ? 'justify-content-center' : ''}`}>
          {!canBypassPayment && (
            <div className='account-summary-payment'>
              <div className='error-message'>
                <ErrorMessage message={errMsg} close={() => setDisplayError(false)} show={displayError} left />
              </div>
              <p className='payment-subtitle'>Payment Method After Trial</p>
              <div className='divider' style={{ margin: '10px 0' }} />
              <div
                className={isFinance || financingDisabled ? 'payment-card disabled' : 'payment-card'}
                style={{
                  marginTop: '20px',
                  boxShadow:
                    paymentMethod === 'financing'
                      ? ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1.5px #4F5AED, 0px 2px 5px rgba(79, 90, 237, 0.1)'
                      : '',
                }}
              >
                <div className='payment-card-head mb-3'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ cursor: 'pointer' }}
                      type='radio'
                      disabled={isFinance || financingDisabled}
                      name='payment'
                      checked={paymentMethod === 'financing'}
                      onClick={() => handlePaymentMethod('financing')}
                    />
                    <p className='payment-subtitle' style={{ marginBottom: 0, marginLeft: '8px' }}>
                      Finance Agreement
                    </p>
                  </div>
                </div>
                {duration === 'monthly' && planType.monthly.financingText}
                {duration === 'yearly' && (
                  <div>
                    <p className='body-b2 gap-1' style={{ fontSize: '16px', fontWeight: 700, color: palette.gray900 }}>
                      {summary?.plan_prices?.promo_code?.extended_trial_days || 14} days free, then $
                      {formatNumber(sumOfTotalHardwareNumber / planType.yearly.monthsCount)}/paid monthly for 1 year
                    </p>
                    <p className='body-b2' style={{ marginBottom: '5px' }}>
                      {planType.yearly.financingText}
                    </p>
                  </div>
                )}
                {duration === '3-Year' && (
                  <div>
                    <p className='body-b2 gap-1' style={{ fontSize: '16px', fontWeight: 700, color: palette.gray900 }}>
                      {summary?.plan_prices?.promo_code?.extended_trial_days || 14} days free, then $
                      {formatNumber(sumOfTotalHardwareNumber / planType['3-Year'].monthsCount)}/paid monthly for 3 years
                    </p>
                    <p className='body-b2' style={{ marginBottom: '5px' }}>
                      {planType['3-Year'].financingText}
                    </p>
                  </div>
                )}
              </div>
              <div
                className='payment-card'
                style={{
                  marginTop: '20px',
                  boxShadow:
                    paymentMethod === 'credit-card' && onCredit
                      ? ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1.5px #4F5AED, 0px 2px 5px rgba(79, 90, 237, 0.1)'
                      : '',
                }}
              >
                <div className='payment-card-head'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <input
                      type='radio'
                      style={{ cursor: 'pointer' }}
                      name='payment'
                      checked={paymentMethod === 'credit-card'}
                      disabled={isCreditCard}
                      onClick={() => handlePaymentMethod('credit-card')}
                    />
                    <p className='payment-subtitle' style={{ marginBottom: 0, marginLeft: '8px' }}>
                      Credit Card
                    </p>
                  </div>
                  <div>
                    <img src={cardsImg} alt='img' />
                  </div>
                </div>
                {paymentMethod === 'credit-card' && onCredit ? (
                  <>
                    <Input
                      className='w-100 mb-3'
                      type='text'
                      name='card_name'
                      placeholder='Name On Card'
                      value={cardHolderName}
                      onChange={(e) => setCardHolderName(e.target.value)}
                    />
                    <CardElement
                      className='stripeCard'
                      id='card-element'
                      onReady={() => {}}
                      onChange={handleCardChange}
                      onBlur={() => {}}
                      onFocus={() => {}}
                    />
                    <p className='requiredMessage'>{cardErr}</p>
                  </>
                ) : (
                  <div>
                    <p className='body-b2 gap-1' style={{ fontSize: '16px', fontWeight: 700, color: palette.gray900 }}>
                      {' '}
                      {summary?.plan_prices?.promo_code?.extended_trial_days || 14} days free, then $
                      {duration === 'monthly' &&
                        `${formatNumber(
                          Number(monthlyPlanRecurring) + (Number(monthlyPlanRecurring) * 0.0299 + 0.3)
                        )}/month recurring`}
                      {duration === 'yearly' &&
                        `${formatNumber(
                          Number(sumOfTotalHardwareNumber) + (Number(sumOfTotalHardwareNumber) * 0.0299 + 0.3)
                        )} up front for 1 year`}
                      {duration === '3-Year' &&
                        `${formatNumber(
                          Number(sumOfTotalHardwareNumber) + (Number(sumOfTotalHardwareNumber) * 0.0299 + 0.3)
                        )} up front for 3 years`}{' '}
                    </p>
                    <p className='body-b2' style={{ marginBottom: '5px' }}>
                      {planType[duration]?.creditCardText}
                    </p>
                  </div>
                )}
              </div>
              <div
                className={isAch ? 'payment-card disabled ' : 'payment-card '}
                style={{
                  marginTop: '20px',
                  boxShadow:
                    paymentMethod === 'ach'
                      ? ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1.5px #4F5AED, 0px 2px 5px rgba(79, 90, 237, 0.1)'
                      : '',
                }}
              >
                <div className='payment-card-head'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type='radio'
                      name='payment'
                      style={{ cursor: 'pointer' }}
                      checked={paymentMethod === 'ach'}
                      onClick={() => handlePaymentMethod('ach')}
                    />
                    <p className='payment-subtitle' style={{ marginBottom: 0, marginLeft: '8px' }}>
                      ACH
                    </p>
                  </div>
                  <img src={achImg} height={50} alt='img' />
                </div>
                <div>
                  {isAch && <p className='body-b2'>Transaction must be over $1.00 for ACH.</p>}
                  <p className='body-b2 gap-1' style={{ fontSize: '16px', fontWeight: 700, color: palette.gray900 }}>
                    {' '}
                    {summary?.plan_prices?.promo_code?.extended_trial_days || 14} days free, then $
                    {duration === 'monthly' && `${formatNumber(Number(monthlyPlanRecurring))}/month recurring`}
                    {duration === 'yearly' && `${formatNumber(Number(sumOfTotalHardwareNumber))} up front for 1 year`}
                    {duration === '3-Year' && `${formatNumber(Number(sumOfTotalHardwareNumber))} up front for 3 years`}
                  </p>
                  <p className='body-b2' style={{ marginBottom: '5px' }}>
                    {planType[duration]?.achText}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className='account-summary-order-conatiner'>
            {canBypassPayment && (
              <ErrorMessage message={errMsg} close={() => setDisplayError(false)} show={displayError} left />
            )}
            <div className='d-flex justify-content-between'>
              <p className='payment-subtitle'>
                Order Summary{' '}
                <span className='cart-items'>
                  {`${Number(hardware?.length) + 1}`} {hardware.length === 0 ? 'Item' : 'Items'}
                </span>{' '}
              </p>
              <span className='navigate-button' onClick={() => navigate('/onboarding/hardware')}>
                Need Hardware?
              </span>
            </div>
            <div className='account-summary-order'>
              <div className='account-summary-plan'>
                <div className='card-summary'>
                  <div style={{ width: '15%' }}>
                    <div className='card-summary-img user-licenses-image'>
                      <img src={truckinLogo} alt='img' width={60} height={60} />
                    </div>
                  </div>
                  <div className='card-summary-body'>
                    <p className='body-b2' style={{ color: '#171C26', marginBottom: 0 }}>
                      {selectedPlan.name}
                    </p>
                    {selectedPlan.plan_types?.map(
                      (p) =>
                        p.duration === plan.duration && (
                          <p className='caption-c1' style={{ color: '#4F5AED', marginTop: 0 }}>
                            ${p.duration === 'monthly' && `${p.amount}.00 /month /per user`}
                            {p.duration === 'yearly' &&
                              `${p.amount / planType.yearly.monthsCount} /per month /per user for 1 year license `}
                            {p.duration === '3-Year' &&
                              `${
                                p.amount / planType['3-Year'].monthsCount
                              } /per month /per user for 36 months license `}
                          </p>
                        )
                    )}
                    <div className='qty-btn'>
                      <div className='hardware-add-btn'>
                        <button className='minus-btn plus-btn btn' onClick={handleNumberUserDec}>
                          -
                        </button>
                        <input
                          className='input-num'
                          placeholder={numberOfUser}
                          value={numberOfUser}
                          onChange={handleNumberUserChange}
                        />
                        <button className='plus-btn btn' onClick={handleNumberUserInc}>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  {selectedPlan.plan_types?.map(
                    (p) =>
                      p.duration === plan.duration && (
                        <p
                          className='subtitle-s2'
                          style={{
                            color: '#171C26',
                            width: '20%',
                            textAlign: 'right',
                            paddingRight: '10px',
                          }}
                        >
                          $
                          {p.duration === 'monthly'
                            ? formatNumber(p.amount * numberOfUser)
                            : formatNumber(p.amount * numberOfUser)}
                        </p>
                      )
                  )}
                </div>
                {!!brokerageAddon && (
                  <>
                    <div className='divider' style={{ margin: 0 }} />
                    <div className='item1 justify-content-between'>
                      <div style={{ width: '15%' }}>
                        <div className='image-wrapper'>
                          <figure className='figure'>
                            <img className='image' src={brokerageAddon?.image} alt='Img' />
                          </figure>
                        </div>
                      </div>
                      <div className={styles.info1} style={{ width: 'calc(82% + 4px)' }}>
                        <div className='info1_left'>
                          <div className={styles.info1_lt}>
                            <Typography variant='b2' style={{ color: palette.gray900 }}>
                              {brokerageAddon?.plans?.[0]?.plan_title}
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            <Typography variant='c1' style={{ color: palette.indigo500 }}>
                              ${formatNumber(brokerageMonthly)} /per month /flat for{' '}
                              {planType[plan.duration].monthsCount} month(s)
                            </Typography>
                          </div>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                          <Typography variant='s2'>${formatNumber(brokerageTotal)}</Typography>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className='divider' style={{ margin: 0 }} />
                {hardware?.length !== 0
                  ? hardware?.map((data) => (
                      <div key={data.id}>
                        <CartCard data={data} removeCart={removeCart} selectedPlan={plan} summary={summary} />
                      </div>
                    ))
                  : ''}

                <div className='totals'>
                  <p className='body-b2'>Promo Code</p>
                  <div className='applyCouponbox'>
                    {!isApplied && (
                      <div className='applyCoupon'>
                        <button onClick={handleApplyCoupon}>Apply</button>
                        <input type='text' placeholder='Enter Code' value={couponCode} onChange={handleCouponCode} />
                        <p className='requiredMessage code-err'>{couponCodeErr}</p>
                      </div>
                    )}
                    {isApplied && (
                      <div className='d-flex align-items-center gap-2 mt-2'>
                        <CustomButton
                          type='secondary'
                          title='Cancel'
                          onClick={() => {
                            setCouponCode('');
                            setIsApplied(false);
                            submitForPrices.applied_coupon = '';
                            postPrice(submitForPrices);
                          }}
                          styleTitle={{ fontSize: 14 }}
                          styleButton={{
                            padding: '0 6px',
                            margin: 0,
                            justifyContent: 'center',
                          }}
                        />
                        <Typography variant='b3' style={{ color: palette.gray700 }}>{`Promo Code (${couponCode}) -${
                          summary?.plan_prices?.promo_code?.value || 0
                        }% $${discountAmount}`}</Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className='totals'>
                  <p className='body-b2'>
                    {planType[plan.duration]?.recurring} After{' '}
                    {summary?.plan_prices?.promo_code?.extended_trial_days || 14} Days Free Trial
                  </p>
                  <div className='body-b2'>
                    $
                    {formatNumber(
                      (summary?.plan_prices?.payment_info?.monthly_payment || 0) * planType[plan.duration].monthsCount +
                        brokerageTotalWithDiscount
                    )}
                  </div>
                </div>
                {paymentMethod === 'credit-card' && (
                  <div className='totals'>
                    <p className='body-b2'>Processing Fee (2.9% + $0.30)</p>
                    <div className='body-b2'>
                      $
                      {formatNumber(
                        ((summary?.plan_prices?.payment_info?.monthly_payment || 0) *
                          planType[plan.duration].monthsCount +
                          brokerageTotalWithDiscount) *
                          0.0299 +
                          0.3
                      )}
                    </div>
                  </div>
                )}
                <div className='totals mt-2' style={{ marginBottom: '8px' }}>
                  <p className='payment-subtitle' style={{ margin: '0px' }}>
                    Plan - Due Now
                  </p>
                  <p className='payment-subtitle' style={{ margin: '0px' }}>
                    $0.00
                  </p>
                </div>
                <div className='divider' style={{ margin: 0 }} />
                {hardware.length !== 0 && (
                  <div className='totals'>
                    <p className='body-b2' style={{ margin: '0px' }}>
                      Hardware Total
                    </p>
                    <div className='body-b2'>${sumOfTotalHardware}</div>
                  </div>
                )}

                {hardware.length !== 0 && (
                  <>
                    <div className='totals'>
                      <p className='body-b2'>Shipping + Handling</p>
                      <div className='body-b2'>${totalShipping}</div>
                    </div>
                    <div className='totals'>
                      <p className='body-b2'>Sales Tax ({taxRate}%)</p>
                      <div className='body-b2'>${totalOfTax}</div>
                    </div>
                  </>
                )}

                {hardware.length !== 0 && (
                  <>
                    <div className='totals'>
                      <p className='payment-subtitle' style={{ marginBottom: '5px' }}>
                        Hardware - Due Now
                      </p>
                      <p className='payment-subtitle' style={{ margin: '0px' }}>
                        ${formatNumber(totalOfHardware)}
                      </p>
                    </div>
                    <p className='totals email-agrement' style={{ paddingBottom: '10px' }}>
                      *Hardware License Order Form Agreement and Invoice will be emailed to you separately. You will not
                      be charged upon continue.
                    </p>
                  </>
                )}
              </div>
            </div>
            <p className='subtitle-s2' style={{ color: '#171C26', marginTop: '32px' }}>
              By clicking continue, you must also check and agree to the following below:
            </p>
            <div className='checkbox-terms'>
              <input type='checkbox' name='terms_and_conditions' onChange={handleChange} />
              <p className='subtitle-s2' style={{ color: '#464F60' }}>
                I agree to the{' '}
                <a
                  className='AccountSummaryTermsConditions'
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.truckindigital.com/legal/terms-and-conditions'
                >
                  Terms & Conditions
                </a>
              </p>
            </div>
            <div className='checkbox-terms'>
              <input type='checkbox' name='privacy_policy' onChange={handleChange} />
              <p className='subtitle-s2' style={{ color: '#464F60' }}>
                I agree to the{' '}
                <a
                  className='AccountSummaryTermsConditions'
                  href='https://www.truckindigital.com/legal/privacy-policy'
                  target='_blank'
                  rel='noreferrer'
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
            <div className='checkbox-terms'>
              <input type='checkbox' name='billing_policy' onChange={handleChange} />
              <p className='subtitle-s2' style={{ color: '#464F60' }}>
                I agree to the{' '}
                <a
                  className='AccountSummaryTermsConditions'
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.truckindigital.com/legal/billing-policy'
                >
                  Billing Policy.
                </a>
              </p>
            </div>
            {/* <div className='checkbox-terms'> */}
            {/*  <input type='checkbox' name='send_sms' onChange={handleChange} /> */}
            {/*  <p className='subtitle-s2' style={{ color: '#464F60' }}> */}
            {/*    I authorize Truckin Digital to send text messages to Drivers or Staff on dispatch or system alerts */}
            {/*    including when using the SMS Chat function for internal communications. Message/data rates apply. */}
            {/*    Consent is not a condition of purchase. */}
            {/*  </p> */}
            {/* </div> */}
            <div className='divider' style={{ margin: '10px 0' }} />
            <button className='button btn w-100' onClick={handleSubmit} style={btnStyle}>
              Continue
            </button>
            <button className='button btn w-100' onClick={handleGoback}>
              Go Back
            </button>
            <div className='legend'>
              <p
                className='body-b2'
                style={{
                  color: palette.gray500,
                  margiTop: 0,
                  marginBottom: '5px',
                  columnGap: '4px',
                  textAlign: 'start',
                }}
              >
                {summary?.plan_prices?.promo_code?.extended_trial_days || 14} days free, then
                {plan.duration === 'monthly' &&
                  ` $${formatNumber(
                    Number(
                      (summary?.plan_prices?.payment_info?.monthly_payment || 0) + (brokerageTotalWithDiscount || 0)
                    ) +
                      (paymentMethod === 'credit-card'
                        ? Number(
                            (summary?.plan_prices?.payment_info?.monthly_payment || 0) +
                              (brokerageTotalWithDiscount || 0)
                          ) *
                            0.0299 +
                          0.3
                        : 0)
                  )} Per Month.`}
                {plan.duration === 'yearly' &&
                  ` $${formatNumber(
                    (summary?.plan_prices.payment_info?.monthly_payment || 0) * planType.yearly.monthsCount +
                      brokerageTotalWithDiscount +
                      (paymentMethod === 'credit-card'
                        ? Number(
                            (summary?.plan_prices.payment_info?.monthly_payment || 0) * planType.yearly.monthsCount +
                              brokerageTotalWithDiscount
                          ) *
                            0.0299 +
                          0.3
                        : 0)
                  )} Per Every Year.`}
                {plan.duration === '3-Year' &&
                  ` $${formatNumber(
                    (summary?.plan_prices.payment_info?.monthly_payment || 0) * planType['3-Year'].monthsCount +
                      brokerageTotalWithDiscount +
                      (paymentMethod === 'credit-card'
                        ? Number(
                            (summary?.plan_prices.payment_info?.monthly_payment || 0) * planType['3-Year'].monthsCount +
                              brokerageTotalWithDiscount
                          ) *
                            0.0299 +
                          0.3
                        : 0)
                  )} Per Every 3 Years.`}{' '}
                No commitment free trial, cancel at anytime.
              </p>
              <div className='divider' style={{ margin: 0 }} />
              <p className='body-b2' style={{ color: '#687182', margin: '5px 0' }}>
                All prices are in US Dollars (USD).
              </p>
              <div className='divider' style={{ margin: 0 }} />
              <p className='body-b2' style={{ color: '#687182', margin: '5px 0' }}>
                {planType[plan.duration]?.infoText1}
              </p>
              <div className='divider' style={{ margin: 0 }} />
              <p className='body-b2' style={{ color: '#687182', margin: '5px 0', textAlign: 'left' }}>
                {planType[plan.duration]?.infoText2}
              </p>
              <div className='divider' style={{ margin: 0 }} />
              <p className='body-b2 d-block' style={{ color: '#687182', margin: '5px 0', textAlign: 'left' }}>
                Addons are sold separately and can be purchased after you create your account.{' '}
                <span className='navigate-button' onClick={() => navigate('/onboarding/addons')}>
                  See Addon List
                </span>
              </p>
              {paymentMethod === 'credit-card' && (
                <>
                  <div className='divider' style={{ margin: 0 }} />
                  <p className='body-b2' style={{ color: '#687182', margin: '5px 0', textAlign: 'left' }}>
                    Your credit card will be charged for $1.00 authorization, and refunded immediately. The Order
                    License Agreement and Invoice will be emailed to you separately.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {loading ? <SplashScreen steps={steps} /> : ''}
      </div>
      <MessagePopup
        title='Warning'
        open={open}
        onClose={() => setOpen(false)}
        message='ACH transaction must be above $1.00 in order to use ACH payment method.'
      />
    </>
  );
};

export default AccountSummary;

const CartCard = ({ data, removeCart, summary, selectedPlan }) => {
  const [total, setTotal] = useState(0.0);
  const currEle = summary?.hardware_prices?.hardware.find((ele) => ele.id === data.id);
  const duration = selectedPlan?.duration;

  const handleAddCart = () => {
    removeCart(data, Number(data.quantity) + 1);
  };

  const handleRemoveCart = () => {
    removeCart(data, Number(data.quantity) - 1);
  };

  const handleCartChange = (e) => {
    const isValid = numberRegex.test(e.target.value);
    if (!isValid) {
      return;
    }
    removeCart(data, Number(e.target.value) || 0);
  };

  useEffect(() => {
    setTotal(formatNumber(data.price * data.quantity));
  }, [data, handleAddCart, handleRemoveCart]);

  return (
    <>
      <div className='card-summary'>
        <div className='card-summary-img' style={{ width: '15%' }}>
          <img src={data.images[0].image} alt='img' />
        </div>
        <div className='card-summary-body'>
          <p className='body-b2 text-left' style={{ color: '#171C26', marginBottom: 0, textAlign: 'left' }}>
            {data.name}
          </p>
          <p className='caption-c1 text-left' style={{ color: '#4F5AED', marginTop: 0 }}>
            ${formatNumber(data.price / (planType[duration]?.monthsCount || 1))}{' '}
            {duration === 'yearly' && `/month /per user for 1 year license `}
            {duration === 'yearly'
              ? ''
              : currEle?.license === 1
              ? `/month /unit for 36 months license`
              : currEle?.license === 0
              ? `/one time purchase /unit`
              : ``}
          </p>

          <div className='qty-btn'>
            <div className='hardware-add-btn'>
              <button className='minus-btn plus-btn btn' onClick={handleRemoveCart}>
                -
              </button>
              <input
                className='input-num'
                placeholder={data.quantity}
                value={data.quantity}
                onChange={handleCartChange}
              />
              <button className='plus-btn btn' onClick={handleAddCart}>
                +
              </button>
            </div>
          </div>
        </div>
        <p
          className='subtitle-s2 ammount-width'
          style={{
            color: '#171C26',
            textAlign: 'right',
            paddingRight: '10px',
          }}
        >
          ${total}
        </p>
      </div>
      <div className='divider' style={{ margin: 0 }} />
    </>
  );
};

const MobileScreenAlert = ({ setShowMobileAlert }) => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    navigate('/login', { replace: true });
    setShowMobileAlert(false);
  };

  return (
    <div className='mobile-screen-msg'>
      <div>
        <p className='body-b2  mobile-screen-msg-text'>
          Please use a web browser to access Truckin Digital. Mobile devices are not supported at this time. Please
          check your email inbox for login instructions.
        </p>
        <button className='ok-btn' onClick={handleOnclick}>
          Done
        </button>
      </div>
    </div>
  );
};
