import React from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import BootstrapTooltip from 'react-bootstrap/Tooltip';

const Tooltip = ({ title, children, tooltipStyles, ...props }) => {
  return (
    <MuiTooltip
      arrow
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: 13,
            fontFamily: 'Inter',
            ...tooltipStyles,
          },
        },
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export const renderTooltip = (title) => {
  return <BootstrapTooltip>{title}</BootstrapTooltip>;
};

export default Tooltip;
