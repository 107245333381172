import React from 'react';
import { string, object, number } from 'yup';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import styles from 'components/TableShipments/detailsRow/steps/Notes/notes.module.css';

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = (onViewNoteAttachment) => {
  const { use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: '#',
      width: '5%',
      title: <Title title='#' />,
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>{Number(rowData?.tableData?.id) + 1}</span>
        );
      },
    },
    {
      field: 'title',
      title: <Title title='TITLE' />,
      render: (rowData) => {
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.title}</span>;
      },
    },
    {
      field: 'note',
      title: <Title title='NOTE' />,
      render: (rowData) => {
        return (
          <div className={styles.columns_notes_wrapper}>
            <div className={styles.columns_notes}>
              <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.notes}</span>
            </div>
            <div className={styles.notes_bottom_wrapper}>
              {!!rowData?.department?.department_email && (
                <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                  <Typography variant='c1'>{rowData?.department?.department_email}</Typography>
                </div>
              )}
              {!!rowData?.department?.department_name && (
                <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                  <Typography variant='c1'>{rowData?.department?.department_name}</Typography>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'added_by',
      title: <Title title='ADDED BY' />,
      render: (rowData) => {
        const { first_name, last_name } = rowData.added_by || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData.added_by === null ? '-' : `${first_name && first_name} ${last_name && last_name}`}
          </span>
        );
      },
    },
    {
      field: 'date',
      title: <Title title='DATE' />,
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData?.created_at && convertToCustomerTime(rowData?.created_at)}
          </span>
        );
      },
    },
    {
      field: 'attachment',
      title: <Title title='ATTACHMENT' />,
      render: (rowData) => {
        return rowData.attachment ? (
          <div
            onClick={() => {
              if (rowData.attachment) onViewNoteAttachment(rowData.attachment);
            }}
          >
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              View
            </Typography>
          </div>
        ) : (
          '-'
        );
      },
    },
  ];
};

export const validationSchema = object({
  title: string().trim().required('Required'),
  notes: string().trim().required('Required'),
});

export const cargoValidationSchema = object({
  cargo_onboard_id: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
