export function generateValue(shipmentId) {
  const alphaPart = shipmentId.match(/[a-zA-Z]+/);
  const numParts = shipmentId.match(/\d+/g);
  const prefix = alphaPart ? alphaPart[0] : String.fromCharCode(65 + Math.random() * 26);

  const num1 = numParts && numParts[0] ? parseInt(numParts[0], 10) : Math.floor(Math.random() * 10000);
  const num2 = numParts && numParts[1] ? parseInt(numParts[1], 10) : Math.floor(Math.random() * 100000);
  const paddedNum1 = String(num1).padStart(4, '0');
  const paddedNum2 = String(num2).padStart(5, '0');
  return `${prefix}${paddedNum1}${paddedNum2}`;
}

export function parseFlexibleTimeToMinutes(timeStr) {
  const regex = /(\d+)([dhm])/g;
  let totalMinutes = 0;
  let match;

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(timeStr)) !== null) {
    const value = parseInt(match[1], 10);
    const unit = match[2];

    // eslint-disable-next-line default-case
    switch (unit) {
      case 'd':
        totalMinutes += value * 1440;
        break;
      case 'h':
        totalMinutes += value * 60;
        break;
      case 'm':
        totalMinutes += value;
        break;
    }
  }

  return totalMinutes;
}
export function compareTimeDurations(givenDuration, compareToDuration) {
  const givenMinutes = parseFlexibleTimeToMinutes(givenDuration);
  const compareToMinutes = parseFlexibleTimeToMinutes(compareToDuration);
  return givenMinutes < compareToMinutes;
}

export function formatTimeDiff(diff) {
  const days = Math.floor(diff / (24 * 60));
  const hours = Math.floor((diff % (24 * 60)) / 60);
  const minutes = Math.round(diff % 60);
  return `${days}d ${hours}h ${minutes}m`;
}

export function sumDurations(durations) {
  let totalMinutes = 0;
  for (const duration of durations) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    const parts = duration?.split(' ');
    if (parts && parts?.length) {
      for (const part of parts) {
        if (part) {
          if (part.endsWith('d')) {
            days = parseInt(part, 10);
          } else if (part.endsWith('h')) {
            hours = parseInt(part, 10);
          } else if (part.endsWith('m')) {
            minutes = parseInt(part, 10);
          }
        }
      }
      totalMinutes += days * 24 * 60 + hours * 60 + minutes;
    }
  }
  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return `${days}d ${hours}h ${minutes}m`;
}
