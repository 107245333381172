import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Autocomplete from 'common/Autocomplete';
import { getAssignToGroupBillingCharges } from 'Api/Planner';
import { COMPENSATION_TYPES } from './AdvancedFilter.data';
import classes from './AdvancedFilter.module.scss';

const AdvancedFilter = ({ open, filter, setFilter, setSearch, onSearch, children }) => {
  const [groupsData, setGroupsData] = useState([]);
  const [badgeData, setBadgeData] = useState([]);

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name: item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const getFilterByGroups = async () => {
    try {
      const { data } = await getAssignToGroupBillingCharges();
      data && setGroupsData(data);
    } catch (e) {
      // Do noting
    }
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(filter.group_id
        ? [
            {
              title: 'Tag',
              children: filter.group_id.map((item) =>
                normalizeBadgeData(item, 'group_name', 'id', { key: 'group_id' })
              ),
            },
          ]
        : []),
      ...(filter?.compensation_type_id
        ? [
            {
              title: 'Compensation Type',
              children: filter.compensation_type_id.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'compensation_type_id' })
              ),
            },
          ]
        : []),
    ];
  }, []);

  const onSearchFilter = () => {
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
      } else {
        newFilter[key] = newItemValue;
      }
    } else if (typeof itemValue === 'string') {
      delete newFilter[key];
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    onSearch({});
    setSearch('');
    setFilter({});
    setBadgeData([]);
  };

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }
    return setFilter(newFilter);
  };

  useEffect(() => {
    getFilterByGroups().catch();
  }, []);

  return (
    <>
      {children?.({ onSearchFilter })}
      <Collapse in={open}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.group_id || []}
                options={groupsData}
                placeholder='Tags'
                onChange={(e, value) => onChangeMultiSelectValues('group_id', value)}
                getTagLabel={(option) => (option ? option?.group_name : '')}
                getOptionLabel={(option) => (option ? option?.group_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={COMPENSATION_TYPES}
                value={filter?.compensation_type_id || []}
                placeholder={filter?.compensation_type_id?.length ? '' : 'Compensation Type'}
                onChange={(e, value) => onChangeMultiSelectValues('compensation_type_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>
          </div>
          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AdvancedFilter;
