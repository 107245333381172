import get from 'lodash/get';
import moment from 'moment/moment';

export const getCompanyName = (data, companyName) => {
  const driverType = get(data, 'user.app.driver_type');

  switch (driverType) {
    case 'company':
      return typeof companyName === 'string' ? companyName || '' : '';
    case 'owner':
      return get(data, 'user.app.operator.owner_operator_name', '');
    default:
      return null;
  }
};

export const statusCountConverter = (data) => {
  const statuses = {};

  data?.forEach((item) => {
    switch (item.status_id) {
      case 1:
        statuses.ready = item;
        break;
      case 2:
        statuses.upcoming = item;
        break;
      case 3:
        statuses.processed = item;
        break;
      case 4:
        statuses.disputed = item;
        break;
      default:
    }
  });

  return statuses;
};

export const calcPercentage = ({ this_month, prev_month }) => {
  const thisMonthAmount = this_month?.amount || 0;
  const prevMonthAmount = prev_month?.amount || 0;

  const diff = Math.abs(thisMonthAmount - prevMonthAmount);

  const diffInPercentage = ((diff / prevMonthAmount) * 100).toFixed(2);

  return `${thisMonthAmount >= prevMonthAmount ? '+' : '-'}${diffInPercentage}%`;
};

export const topCompanyConverter = (data) => {
  const { amounts_by_months } = data || {};

  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;
  const prevMonth = moment().subtract(1, 'months').month() + 1;

  const thisMonthData = amounts_by_months?.find((item) => item.month === currentMonth && currentYear === item.year);
  const prevMonthData = amounts_by_months?.find((item) => {
    return currentMonth !== 1 ? item.month === prevMonth : item.year === currentYear - 1 && item.month === 12;
  });

  const sortedAmounts = amounts_by_months?.slice().sort((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    }
    return a.year - b.year;
  });

  return {
    ...data,
    this_month: thisMonthData || null,
    prev_month: prevMonthData || null,
    amounts_by_months: sortedAmounts,
  };
};
