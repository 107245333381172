import Chip from 'common/Chip';
import React from 'react';
import { palette } from 'utils/constants';

export const planMapper = {
  monthly: {
    title: 'Month to Month',
    commitmentLabel: <Chip label='NO COMMITMENT' />,
    divideBy: 1,
    billTitle: 'Billed monthly',
  },
  yearly: {
    title: '12 Month Agreement',
    commitmentLabel: <Chip bgColor={palette.green0} labelColor={palette.green500} label='SAVE 10% PER MONTH' />,
    divideBy: 12,
    billTitle: 'Billed every 1 year',
  },
  '3-Year': {
    title: '36 Month Agreement',
    commitmentLabel: <Chip bgColor={palette.green0} labelColor={palette.green500} label='SAVE 25% PER MONTH' />,
    divideBy: 36,
    billTitle: 'Billed every 3 years',
  },
};
