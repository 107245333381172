export const getInitialValues = (settings) => {
  const { real_time_locations, checked_in_locations } = settings || {};

  return {
    real_time_locations: !!real_time_locations,
    checked_in_locations: !!checked_in_locations,
  };
};

export const updateInventoryConverter = (values) => {
  const { real_time_locations, checked_in_locations } = values || {};

  return {
    real_time_locations: Number(real_time_locations),
    checked_in_locations: Number(checked_in_locations),
  };
};
