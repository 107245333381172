import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  company_name: string().trim().required('Required'),
  dot: string().trim().required('Required'),
  email: string().required('Required').email('Invalid Email Address'),
  phone_number: string().trim().required('Required'),
  address: string().trim().required('Required'),
  address2: string().trim().nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required'),
});
