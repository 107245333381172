import React, { useState } from 'react';
import moment from 'moment/moment';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import Input from 'common/Input';
import ErrorMessage from 'common/ErrorMessage';
import { addIdNumber } from 'Api/CardManagement';

const ssnRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;

const SsnVerification = ({ idNumber, setIdNumber, setCurrentStep, verification, personId }) => {
  const [idNumberError, setIdNumberError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!idNumber) {
        setIdNumberError('Social Security Number is required');
        return;
      }
      if (idNumber.match(ssnRegex) === null) {
        setIdNumberError('Social security number is not valid (valid format: 12-345-6789).');
        return;
      }
      if (idNumber.slice(-4) !== verification.last4) {
        setIdNumberError(`Social security number doesn't match with the last 4 digits provided earlier.`);
        return;
      }
      const body = {
        person_id: personId,
        id_number: idNumber.replaceAll('-', ''),
      };

      await addIdNumber(body);
      setCurrentStep(3);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='d-flex flex-column gap-4' style={{ width: '400px' }}>
      <div className='d-flex flex-column' style={{ marginTop: '32px', gap: '2px' }}>
        <Typography variant='h2'>Additional information</Typography>
        <Typography variant='s3' style={{ color: palette.gray500 }}>
          Please provide a few more details to verify your account.
        </Typography>
      </div>
      <div
        style={{
          width: '400px',
          backgroundColor: palette.gray100,
          border: `1px solid ${palette.gray200}`,
          borderRadius: '8px',
          padding: '20px 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          marginBottom: '24px',
        }}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex flex-column'>
            <Typography variant='c1'>
              {verification.first_name} {verification.last_name}
            </Typography>
            <Typography variant='c2'>Account Representative, Owner and Executive</Typography>
          </div>
        </div>
        <Typography variant='c2' style={{ color: palette.gray700 }}>
          {verification.email}
        </Typography>
        <Typography variant='c2' style={{ color: palette.gray700 }}>
          Born on {moment(verification.date_of_birth).format('MMMM D, YYYY')}
        </Typography>
        <div className='d-flex flex-column' style={{ marginBottom: '16px' }}>
          <Typography variant='c2' style={{ color: palette.gray700 }}>
            {verification.address1}
          </Typography>
          <Typography variant='c2' style={{ color: palette.gray700 }}>
            {verification.city.name}, {verification.state.short_name} {verification.address2}{' '}
            {verification.country.sortname}
          </Typography>
        </div>
        <Typography variant='c1'>Other information provided</Typography>
        <Typography variant='c2' style={{ color: palette.gray700 }}>
          SSN, Job title, Phone
        </Typography>
      </div>
      <div>
        <Input
          name='id_number'
          placeholder='123-45-6789'
          label='Social Security number'
          onChange={(e) => {
            setIdNumberError('');
            setIdNumber(e.target.value);
          }}
          value={idNumber}
          required
        />
        {idNumberError && <ErrorMessage error={idNumberError} />}
        <Typography variant='c2' style={{ color: palette.gray500 }}>
          To verify your identity, we’ll need to know your full Social Security number.
        </Typography>
      </div>
      <div className='d-flex flex-column gap-3 pb-5'>
        <CustomButton
          height={12}
          type='primary'
          title='Submit'
          styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
          onClick={handleSubmit}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default SsnVerification;
