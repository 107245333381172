import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { PostStopPointStepTwo } from 'Api/Planner';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { Typography } from 'components/Typography';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import Tooltip from 'common/Tooltip';
import sm from '../addStopPointModal.module.css';

import CustomInput from '../../../helpers/CustomInput';
import CustomSelect from '../../../ShipmentStops/helpers/CustomSelect';
import AddStopPointFooter from '../addStopPointFooter';
import { StopPointModalContext } from '../index';
import { getContactBookTemplate } from '../../../ShipmentStops/helpers/constants';

const StopPointModalStep2 = () => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const { use } = useTheme();
  const {
    step,
    setStep,
    onChangeShow,
    stopPointId,
    validateEmail,
    validateStopPointInput,
    typeData,
    getTypeData,
    getCustomerStopPoint,
  } = useContext(StopPointModalContext);

  const onNext = (values) => {
    setLoading(true);
    const formData = new FormData();
    const postData = { stop_point_id: stopPointId };
    function appendToFormData(data, namePrefix = '') {
      if (Array.isArray(data)) {
        if (data[0] && typeof data[0] === 'object') {
          data.forEach((object, objectIndex) => {
            const name = `${namePrefix}[${objectIndex}]`;
            appendToFormData(object, name);
          });
        }
      } else if (typeof data === 'object') {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          const value = data[key];
          const name = nameMapper(namePrefix, key);
          const v = value === true ? 1 : value === false ? 0 : value;
          appendToFormData(v, name);
        });
      } else {
        postData[namePrefix] = data;
      }
    }
    appendToFormData(values);

    Object.keys(postData).forEach((key) => {
      const value = postData[key];
      formData.append(key, value);
    });

    PostStopPointStepTwo(formData)
      .then(() => {
        setStep((p) => p + 1);
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      })
      .finally(() => setLoading(false));
  };

  function nameMapper(namePrefix, key) {
    const nameInServer = {
      name: `${namePrefix}[name]`,
      email: `${namePrefix}[email]`,
      phone_number: `${namePrefix}[phone_number]`,
      contact_type_id: `${namePrefix}[contact_type_id]`,
      portal_access: `${namePrefix}[portal_access]`,
      email_updates: `${namePrefix}[email_updates]`,
      sms_updates: `${namePrefix}[sms_updates]`,
    };

    const name = nameInServer[key] ? nameInServer[key] : namePrefix + key;
    return name;
  }

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '10px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = useMemo(() => {
    return { contact_books: [getContactBookTemplate()] };
  }, []);

  useEffect(() => {
    getTypeData();
  }, []);

  return (
    <div className={sm.step2}>
      <Formik onSubmit={onNext} initialValues={initialValue}>
        {({ values, submitForm }) => (
          <Form>
            <FieldArray
              name='contact_books'
              render={(arrayHelpers) => {
                return (
                  <div>
                    <div className={sm.row}>
                      <Typography variant='s2' style={{ width: '26%' }}>
                        Name
                        <span className={sm.required_system}>*</span>
                      </Typography>
                      <Typography variant='s2' style={{ width: '26%' }}>
                        Email
                        {/* <span className={sm.required_system}>*</span> */}
                      </Typography>
                      <Typography variant='s2' style={{ width: '24%' }}>
                        Phone Number
                        <span className={sm.required_system}>*</span>
                      </Typography>
                      <Typography variant='s2' style={{ width: '25%' }}>
                        Type
                        <span className={sm.required_system}>*</span>
                      </Typography>
                      <Typography variant='s2' style={{ width: '8%' }}>
                        Portal Access
                      </Typography>
                      <Typography variant='s2' style={{ width: '8%' }}>
                        Email Updates
                      </Typography>
                      <Typography variant='s2' style={{ width: '8%' }}>
                        SMS Updates
                      </Typography>
                    </div>
                    {values?.contact_books?.map((el, index) => {
                      const namePrefix = `contact_books[${index}]`;
                      return (
                        <div key={namePrefix} className={[sm.row2, sm.line].join(' ')} style={{ marginTop: 0 }}>
                          <div className={sm.field_wrapper1}>
                            <Field
                              name={`${namePrefix}[name]`}
                              component={CustomInput}
                              style={{ paddingLeft: 10 }}
                              labelStyle={styles.labelStyle}
                              validate={validateStopPointInput}
                              className={sm.input_basic_details}
                            />
                            <ErrorMessage
                              name={`${namePrefix}[name]`}
                              render={(error) => (
                                <Typography variant='c2' style={styles.error}>
                                  {error}
                                </Typography>
                              )}
                            />
                          </div>

                          <div className={sm.field_wrapper2}>
                            <Field
                              name={`${namePrefix}[email]`}
                              component={CustomInput}
                              style={{ paddingLeft: 10 }}
                              labelStyle={styles.labelStyle}
                              validate={validateEmail}
                              className={sm.input_basic_details}
                            />
                            <ErrorMessage
                              name={`${namePrefix}[email]`}
                              render={(error) => (
                                <Typography variant='c2' style={styles.error}>
                                  {error}
                                </Typography>
                              )}
                            />
                          </div>

                          <div className={sm.field_wrapper3}>
                            <Field
                              name={`${namePrefix}[phone_number]`}
                              inputStyle={{ width: '100%' }}
                              labelStyle={{ marginTop: 0 }}
                              countryCodeEditable={false}
                              component={CustomPhoneInput}
                              validate={validateStopPointInput}
                            />
                            <ErrorMessage
                              name={`${namePrefix}[phone_number]`}
                              render={(error) => (
                                <Typography variant='c2' style={styles.error}>
                                  {error}
                                </Typography>
                              )}
                            />
                          </div>

                          <div className={sm.field_wrapper4}>
                            <div>
                              <Field
                                name={`${namePrefix}[contact_type_id]`}
                                options={typeData}
                                styles={styles.type}
                                component={CustomSelect}
                                menuStyles={styles.fullWidthPercent}
                                validate={validateStopPointInput}
                              />
                              <ErrorMessage
                                name={`${namePrefix}[contact_type_id]`}
                                render={(error) => (
                                  <Typography variant='c2' style={styles.error}>
                                    {error}
                                  </Typography>
                                )}
                              />
                            </div>
                          </div>

                          <div className={sm.field_wrapper5}>
                            <Tooltip title='This feature is not available'>
                              <span>
                                <Field name={`${namePrefix}[portal_access]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[portal_access]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                      disabled
                                    />
                                  )}
                                </Field>
                              </span>
                            </Tooltip>
                          </div>

                          <div className={sm.field_wrapper6}>
                            <Field name={`${namePrefix}[email_updates]`}>
                              {({ field }) => (
                                <CustomCheckbox
                                  name={`${namePrefix}[email_updates]`}
                                  field={field}
                                  type='switch'
                                  withinForm
                                  checked={!!field.value}
                                />
                              )}
                            </Field>
                          </div>

                          <div className={sm.field_wrapper7}>
                            <Field name={`${namePrefix}[sms_updates]`}>
                              {({ field }) => (
                                <CustomCheckbox
                                  name={`${namePrefix}[sms_updates]`}
                                  field={field}
                                  type='switch'
                                  withinForm
                                  checked={!!field.value}
                                />
                              )}
                            </Field>
                          </div>

                          {values?.contact_books.length > 1 && (
                            <DeleteIcon
                              width={12}
                              height={12}
                              fill={palette.indigo500}
                              style={styles.deleteIcon}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          )}
                        </div>
                      );
                    })}
                    <CustomButton
                      type='secondary'
                      title='Add Another'
                      leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                      styleButton={styles.addAnotherButton}
                      styleTitle={styles.addAnotherTitle}
                      onClick={() => {
                        arrayHelpers.push(getContactBookTemplate());
                      }}
                    />
                  </div>
                );
              }}
            />

            <AddStopPointFooter
              step={step}
              setStep={setStep}
              submitForm={submitForm}
              getCustomerStopPoint={getCustomerStopPoint}
              onChangeShow={onChangeShow}
              loading={loading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StopPointModalStep2;
