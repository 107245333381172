import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { getVehicleList } from 'Api/Ifta';
import Autocomplete from 'common/Autocomplete';

const VehicleSelect = ({ selectedData, setSelectedData, startDate, endDate }) => {
  const [vehiclesListData, setVehiclesListData] = useState([]);

  const getVehiclesList = async () => {
    try {
      const params = {
        from_date: moment(startDate).format('DD-MM-YYYY'),
        to_date: moment(endDate).format('DD-MM-YYYY'),
      };
      const { data } = await getVehicleList(params);
      setVehiclesListData(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getVehiclesList();
  }, [startDate]);

  return (
    <Autocomplete
      width='200px'
      name='vehicle_list'
      options={vehiclesListData}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedData}
      onChange={(e, value) => setSelectedData(value)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.equipment_id} {option.equipment_type.title}
        </li>
      )}
      getOptionLabel={(option) => `${option.equipment_id} ${option.equipment_type.title}`}
    />
  );
};

export default VehicleSelect;
