import React, { useState } from 'react';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import DatePicker from 'common/DatePicker';
import { SimpleLoader } from 'common/Loader';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomTimeInput from 'components/CustomTimeInput';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getAlphabet, palette } from 'utils/constants';
import { updateShipmentStop } from 'Api/PlannerV2';
import { SCHEDULED_DATE_TYPE } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getStopName } from 'componentsV2/Shipment/SplitShipment/SplitShipment.data';
import Tooltip from 'common/Tooltip';
import { validationSchema } from './validationSchema';

const StopPoint = ({ stop, index, onStopUpdate, stops }) => {
  const showToaster = useShowToaster();
  const { formatDate, formatTime } = useDateFormat();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    id,
    from,
    to,
    scheduled_date,
    scheduled_type,
    stop_point,
    stop_point_type,
    equipment_type_length,
    number,
    bill_type,
  } = stop || {};
  const { location_name, address1, address2, city, state, zipcode } = stop_point || {};
  const scheduledType = SCHEDULED_DATE_TYPE.find((type) => +type.key === +stop.scheduled_type);
  const scheduledBefore =
    !stops[0].isEmpty && stops[0]?.scheduled_date
      ? moment(`${scheduled_date} ${from}`).isBefore(`${stops[0]?.scheduled_date} ${stops[0].to}`)
      : false;

  const onSubmit = async (values) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const body = {
        stop_point_type,
        equipment_type_length,
        number,
        stop_point_id: stop_point?.id,
        bill_type,
        scheduled_date: moment(values.scheduled_date).format('YYYY-MM-DD'),
        from: values.from,
        to: Number(values.scheduled_type) === 3 ? values.to : values.from,
      };

      const { data } = await updateShipmentStop(id, body);
      onStopUpdate(data);
      setEditMode(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setFieldValue, setFieldTouched } = useForm({
    initialValues: {
      scheduled_date: scheduled_date ? moment(scheduled_date).toDate() : null,
      from,
      to,
      scheduled_type,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Draggable draggableId={id.toString()} index={index} isDragDisabled={editMode} disableInteractiveElementBlocking>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div className='optimize-stop-wrapper light'>
            <div className='order-wrapper'>
              {Number(stop_point_type) === 2 && Number(stops[0]?.stop_point_type) === 1 && scheduledBefore && (
                <Tooltip
                  placement='left'
                  title={`The scheduled time ${formatDate(scheduled_date) || ''} ${
                    from && formatTime(`${scheduled_date} ${from}`)
                  } ${to && ` to ${formatTime(`${scheduled_date} ${to}`)}`} is before Stop A: Pick Up!`}
                >
                  <WarningIcon fill={palette.red500} />
                </Tooltip>
              )}
              <Typography variant='c1' style={{ color: getStopName(Number(stop?.stop_point_type)).color }}>
                STOP {getAlphabet(index)} {getStopName(Number(stop?.stop_point_type)).type}
              </Typography>
            </div>
            <div />
            <div className='optimize-drag-item-wrapper'>
              <div className='drag-item-left'>
                <Typography variant='s1'>{location_name}</Typography>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {address1}
                </Typography>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {address2}
                </Typography>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {city?.name} {state?.name} {zipcode}
                </Typography>
              </div>
              <div className='drag-item-right'>
                <DragIcon fill={palette.indigo500} width={14} height={14} />
              </div>
            </div>

            <div className='d-flex align-items-center gap-2'>
              {editMode ? (
                <div className='d-flex align-items-center gap-2'>
                  <div>
                    <DatePicker
                      size='small'
                      width='135px'
                      name='scheduled_date'
                      value={values.scheduled_date}
                      dateFormat='MM/dd/yyyy'
                      onChange={(val) => handleChange(`scheduled_date`, val)}
                      disabled={values.use_default_worker_compensation}
                    />
                    <ErrorMessage error={touchedErrors.scheduled_date} />
                  </div>
                  <div>
                    <CustomTimeInput
                      field={{ name: `from`, value: values.from }}
                      form={{ setFieldValue, setFieldTouched }}
                      style={{ justifyContent: 'center', width: '74px', height: '26px' }}
                      className='small-time-input'
                    />
                    <ErrorMessage error={touchedErrors.from} />
                  </div>
                  {Number(values.scheduled_type) === 3 && (
                    <div className='d-flex align-items-center gap-2'>
                      <Typography variant='b2'>to</Typography>
                      <CustomTimeInput
                        field={{ name: `to`, value: values.to }}
                        form={{ setFieldValue, setFieldTouched }}
                        style={{ justifyContent: 'center', width: '74px', height: '26px' }}
                        className='small-time-input'
                      />
                      <ErrorMessage error={touchedErrors.to} />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Typography variant='c1'>{formatDate(scheduled_date) || ''}</Typography>
                  <Typography variant='c1' style={{ marginRight: 5 }}>
                    <>
                      &nbsp;{from && formatTime(`${scheduled_date} ${from}`)}&nbsp;
                      {[1, 3].includes(Number(scheduledType?.key)) &&
                        !!to &&
                        ` to ${formatTime(`${scheduled_date} ${to}`)}`}
                    </>
                  </Typography>
                  <Typography variant='c1'>
                    {Number(scheduledType?.key) === 1 ? '(FCFS)' : scheduledType?.label}
                  </Typography>
                </div>
              )}
              {editMode ? (
                <span className='d-flex gap-2'>
                  <span
                    className='pointer d-flex'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSubmit();
                    }}
                  >
                    {loading ? <SimpleLoader loading size={16} /> : <CheckIcon width={16} height={16} />}
                  </span>
                  {!loading && (
                    <span
                      className='pointer d-flex'
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(false);
                      }}
                    >
                      <CloseIcon fill={palette.red300} width={14} height={14} />
                    </span>
                  )}
                </span>
              ) : (
                <span
                  className='pointer d-flex'
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditMode(true);
                  }}
                >
                  <EditIcon width={13} height={13} />
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default StopPoint;
