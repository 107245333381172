import React, { useState } from 'react';
import moment from 'moment';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import DetailsChart from '../DetailsChart';
import SettlementGross from '../DetailsComponents/SettlemntGross';
import SettlementTaxes from '../DetailsComponents/SettlementTaxes';
import SettlementBenefits from '../DetailsComponents/SettlementBenefits';
import SettlementTakeHome from '../DetailsComponents/SettlementTakeHome';
import SettlementDeductions from '../DetailsComponents/SettlementDeductions';

const Details = ({ settlement }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const { pay_period_end, pdf_file_link } = settlement ?? {};

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <Typography variant='h5'>{moment(pay_period_end).format('MMM D, YYYY')}</Typography>
        <CustomButton
          type='primary'
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '6px 12px', margin: 0 }}
          title='View Statement'
          onClick={() => setPdfUrl(pdf_file_link)}
        />
      </div>
      <div className='mb-4'>
        <DetailsChart settlement={settlement} />
      </div>
      <div className='d-flex flex-column gap-2' key={settlement.id}>
        <SettlementGross settlement={settlement} />
        <SettlementTaxes settlement={settlement} />
        <SettlementBenefits settlement={settlement} />
        <SettlementDeductions settlement={settlement} />
        <SettlementTakeHome settlement={settlement} />
      </div>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </div>
  );
};

export default Details;
