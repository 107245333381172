import { convertToCustomerTime } from 'utils/helpers';

export const getInitialValues = () => {
  return {
    isNew: true,
    date: convertToCustomerTime(Date.now()),
    time: convertToCustomerTime(Date.now(), 'HH:mm:ss'),
    percent: '0',
    tax_type: null,
    auto_deduct: 0,
    day: 'Monday',
    month_week: 'Week',
    apply: true,
  };
};
