import axios from 'axios';
import axiosInstance from './axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function getIFTAConnectedService() {
  const { data } = await axiosInstance.get('ifta/connected-service');
  return data;
}

export async function getSamsaraJurisdiction(params) {
  const { data } = await axiosInstance.get('ifta/samsara/jurisdiction', {
    params,
  });
  return data;
}

export async function getSamsaraVehicle(params) {
  const { data } = await axiosInstance.get('ifta/samsara/vehicle', {
    params,
  });
  return data;
}

class PositionApplicationsServices {
  getFuelHistory(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    const url = `/fuel-price/fuel-price-history?fuel_type=${data.fuel_type}&from_date=${data.from_date}&sort[][${data.sort_by}]=${data.sort_order}&page=${data.page}&to_date=${data.to_date}&itemsPerPage=${data.itemsPerPage}`;

    return axios
      .get(API_URL + url, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getJurisdiction(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    const url = `/ifta/jurisdiction?from_date=${data.from_date}&to_date=${data.to_date}&fuel_type=${data.fuel_type}&sort[][jurisdiction]=${data.sortingOrder}&itemsPerPage=1000&page=1`;

    return axios
      .get(API_URL + url, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getVehicleData(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    const url = `/ifta/vehicle?from_date=${data?.from_date}&to_date=${data?.to_date}&fuel_type=${data.fuel_type}&equipment_id=${data.equipment_id}&sort[][${data.sortBy}]=${data.sortingOrder}&itemsPerPage=1000&page=1`;

    return axios
      .get(API_URL + url, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getFuelTypes() {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    const url = `/fuel-types`;

    return axios
      .get(API_URL + url, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }
}

export default new PositionApplicationsServices();

export function formatAmount(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    /* empty */
  }
}
