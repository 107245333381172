import styled from 'styled-components';

export const SOrderSummary = styled.div`
  flex: 1;
  padding: 0 20px;
  margin-top: 12px;

  .account-summary-order {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
    border-radius: 8px;
  }

  .card-summary {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0;
  }

  .totals {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
  }

  .add-addons-button {
    margin: 8px 0;
    display: flex;
    justify-content: center;
  }

  .summary-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 16px 10px 10px;

    &:not(.summary-item-license) {
      padding: 20px 16px 10px 10px;
    }

    .summary-item-image {
      display: flex;
      width: 14%;
      justify-content: center;

      img {
        width: 100%;
        height: 64px;
        object-fit: contain;
      }
    }

    .summary-item-body {
      width: 52%;
      height: 100%;
    }

    .summary-item-amount {
      width: 32%;
      text-align: right;
      display: flex;
      justify-content: space-between;
    }

    .remove-cart-item-icon {
      position: absolute;
      right: 8px;
      top: 6px;
      cursor: pointer;
    }
  }
`;
