import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import Policies from 'components/Policies';
import StaffLogTab from 'components/StaffLogTab/index';
import StaffLicense from 'components/StaffLicense/StaffLicense';
import PreviousEmployment from 'componentsV2/PreviousEmployment';
import ShipmentTabTable from 'components/ShipmentTab/ShipmentTab';
import IncidentTabTable from 'components/IncidentTab/IncidentTab';
import StaffDocument from 'components/StaffDocument/StaffDocument';
import StaffRequestTable from 'components/StaffRequest/StaffRequest';
import StaffNoteTable from 'components/StaffNoteTable/StaffNoteTable';
import TransactionTabTable from 'components/TransationTab/TransactionTab';
import StaffCompansation from 'components/StaffCompensation/StaffCompensation';
import StaffAbsenceTable from 'components/StaffAbsenceTable/StaffAbsenceTable';
import RestrictionSchedule from 'components/RestrictionSchedule/RestrictionSchedule';
import StaffShipmentActivity from 'components/StaffShipmentActivity/StaffShipmentActivity';
import StaffConnectedServices from 'components/StaffConnectedServices/StaffConnectedServices';
import StaffGeneralInformation from 'components/StaffGeneralInformation/StaffGeneralInformation';
import DriverAbsenceAndRequest from 'components/DriverAbsenceAndRequest/DriverAbsenceAndRequest';
import './StaffProfileTab.css';
import StaffTimeTracking from '../StaffTimeTracking/StaffTimeTracking';

export default function StaffProfileTab({
  absenceDetails,
  driverInfo,
  staffLicence,
  updateCompensation,
  showError,
  driverRequest = { driverRequest },
  updateProfile,
  driverOtherData,
  onSuccess,
  loading,
  driverCompansation,
  staff_name,
  driverAbsence,
  updateAbsenceAllowanceData,
  staff_id,
  setIsChange,
  isChange,
  isChangeRequest,
  setIsChangeRequest,
  setRefreshIndex,
  commissionsIndex,
}) {
  const { id, slug } = useParams();
  const [key, setKey] = useState(slug || 'general');
  const navigate = useNavigate();
  const { use } = useTheme();
  const [toggle, setToggle] = React.useState(false);
  const isDarkMode = use(palette.white, palette.dark800) !== '#FFFFFF';

  useEffect(() => {
    navigate(`/staff-profile/${id}/${key}`, { replace: true });
  }, [id, key]);

  return (
    <div className='modal-container-tab driver-style-wrap staff-style-wrap'>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className={`mb-3 profile-tab ${isDarkMode ? 'dark-mode-tab' : ''}`}
        style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
      >
        <Tab eventKey='general' className='profile-tab-content' unmountOnExit title='General'>
          <div className='Driver-general' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <div className='staff-general-info'>
              <div className={!toggle ? `staff-general-left` : `staff-general-left-edit-mode`}>
                <StaffGeneralInformation
                  driverOtherData={driverOtherData}
                  staffInfo={driverInfo}
                  updateGeneralProfile={updateProfile}
                  onSuccess={onSuccess}
                  loading={loading}
                  toggle={toggle}
                  setToggle={setToggle}
                />
              </div>
              <div className={!toggle ? `staff-general-right` : `staff-general-right-edit-mode`}>
                <StaffLicense driverOtherData={staffLicence} staffInfo={driverInfo} setRefreshIndex={setRefreshIndex} />
              </div>
            </div>
            <div className='activity-container'>
              <StaffShipmentActivity staff_id={staff_id} />
            </div>
          </div>
        </Tab>
        <Tab
          eventKey='compensation'
          unmountOnExit
          className='profile-tab-content'
          title='Compensation'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <StaffCompansation
              showError={showError}
              updateCompensation={updateCompensation}
              staffCompansation={driverCompansation}
              onSuccess={onSuccess}
              loading={loading}
              commissionsIndex={commissionsIndex}
            />
          </div>
        </Tab>
        <Tab eventKey='documents' unmountOnExit className='profile-tab-content' title='Documents'>
          <StaffDocument />
        </Tab>
        <Tab
          eventKey='time-tracking'
          unmountOnExit
          className='profile-tab-content'
          title='Time Tracking'
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div>
            <StaffTimeTracking />
          </div>
        </Tab>
        <Tab
          eventKey='booked-shipments'
          unmountOnExit
          className='profile-tab-content'
          title='Booked Shipments'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <ShipmentTabTable isStaff />
          </div>
        </Tab>
        <Tab
          eventKey='absences'
          unmountOnExit
          className='profile-tab-content'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          title='Attendance'
        >
          <div>
            <DriverAbsenceAndRequest data={driverAbsence} />
            <StaffAbsenceTable
              absenceDetails={absenceDetails}
              absLoading={loading}
              absence_allowance={driverAbsence?.absence_allowance}
              updateAbsenceAllowanceData={updateAbsenceAllowanceData}
              setIsChange={setIsChange}
              isChange={isChange}
            />
          </div>
        </Tab>
        <Tab
          eventKey='requests'
          unmountOnExit
          className='profile-tab-content'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          title='Requests'
        >
          <div>
            <DriverAbsenceAndRequest data={driverRequest} isRequest />
            <StaffRequestTable
              driverInfo={driverInfo}
              isChangeRequest={isChangeRequest}
              setIsChangeRequest={setIsChangeRequest}
            />
          </div>
        </Tab>
        <Tab
          eventKey='incidents'
          unmountOnExit
          className='profile-tab-content'
          title='Incidents'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <IncidentTabTable firstName={driverInfo?.first_name} lastName={driverInfo?.last_name} isStaff />
          </div>
        </Tab>
        <Tab
          eventKey='restriction-schedule'
          unmountOnExit
          className='profile-tab-content'
          title='Restriction & Schedule'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <RestrictionSchedule onSuccess={onSuccess} showError={showError} />
          </div>
        </Tab>
        <Tab eventKey='previous-employment' unmountOnExit className='profile-tab-content' title='Previous Employment'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <PreviousEmployment userType='staff' userId={id} />
          </div>
        </Tab>
        <Tab eventKey='transactions' unmountOnExit className='profile-tab-content' title='Transactions'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <TransactionTabTable isStaff />
          </div>
        </Tab>
        <Tab eventKey='connected-services' unmountOnExit className='profile-tab-content' title='Connected Services'>
          <StaffConnectedServices />
        </Tab>
        <Tab eventKey='policies' unmountOnExit className='profile-tab-content' title='Policies'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <Policies userType='staff' userId={id} />
          </div>
        </Tab>
        <Tab eventKey='notes' unmountOnExit className='profile-tab-content' title='Notes'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <StaffNoteTable staff_name={staff_name} />
          </div>
        </Tab>
        <Tab eventKey='log' unmountOnExit className='profile-tab-content' title='Log'>
          <StaffLogTab />
        </Tab>
      </Tabs>
    </div>
  );
}
