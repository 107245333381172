import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import { Box } from '@mui/material';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { TableContext } from 'context/tableContext';
import Search from 'pages/Payroll/PayrollAndSettlements/components/Search';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import styles from '../DriverHeader/DriverHeader.module.css';
import CustomButton from '../CustomButton/CustomButton';
import DarkOverlay from '../DarkOverlay';
import PopoverSettings from '../PopoverSettings/PopoverSettingRequest';
import Layout from '../CreateShipment/Layout';
import HeaderStar from '../HeaderStar';

const RequestHeader = ({ setModalShow, search, setSearch, advancedOpen, setAdvancedOpen, onSearch }) => {
  const { use } = useTheme();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    filter,
    updateFilter,
    dragItem,
    onChangeOrder,
    getSettings,
    updateOnCreate,
    createModalCLose,
    switchRadioButtons,
    onsStTableColumn,
    onSetSwitchRadioButtons,
    tableColumn,
  } = useContext(TableContext);

  const styleHeader = useMemo(() => {
    return {
      styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '97%',
        height: '97%',
        bgcolor: use(palette.white, palette.dark900),
        boxShadow: 24,
        borderRadius: '8px',
      },
    };
  }, [use, palette]);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettings
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={updateFilter}
          onChangeOrder={onChangeOrder}
          onsStTableColumn={onsStTableColumn}
          switchRadioButtons={switchRadioButtons}
          setShowPopover={(isOpen) => setShow(isOpen)}
          onSetSwitchRadioButtons={onSetSwitchRadioButtons}
          tableColumn={tableColumn}
        />
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (location?.state?.openCreate) {
      setModalShow(true);
    }
  }, [location?.state]);

  return (
    <div className={styles['DiverHeader_container driver-style-wrap']}>
      <div className={styles.driverHeader_wrapper}>
        <div className={styles.driver_title_wrap}>
          <HeaderStar title='Requests' />
        </div>
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title='Add Request'
          leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
          styleButton={{ padding: '6px 12px' }}
          onClick={() => setModalShow(true)}
        />
      </div>
      <div className='top_popover_search-wrapper d-flex align-items-center'>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popOverContent}
          rootClose={show}
          onToggle={setShow}
          show={show}
          defaultShow={false}
          delay={0}
        >
          <div
            className='top_popover'
            style={{ backgroundColor: use(palette.white, palette.dark800), marginBottom: '1rem' }}
          >
            <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
          </div>
        </OverlayTrigger>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8, marginLeft: '16px', marginBottom: '1rem' }}>
          <Search
            search={search}
            onChange={setSearch}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
          />
          <CustomButton
            type='primary'
            title='Search'
            onClick={() => onSearch()}
            styleTitle={{ fontSize: 14 }}
            styleButton={{ padding: '5px 12px', margin: '0 8px' }}
          />
          <CustomButton
            type='primary'
            title=''
            leftIcon={
              advancedOpen ? (
                <MinusIcon fill='#fff' style={{ margin: 8 }} />
              ) : (
                <PlusIcon fill='#fff' style={{ margin: 8 }} />
              )
            }
            className='ms-3'
            styleButton={{ height: 32, margin: 0 }}
            onClick={() => setAdvancedOpen(!advancedOpen)}
          />
        </div>
        <DarkOverlay visible={show} styles={{ zIndex: 11 }} />
      </div>
      <Modal open={isOpen} enforceFocus={false} onClose={() => setIsOpen(false)}>
        <Box sx={styleHeader.styleModal}>
          <Layout
            onCloseModal={() => setIsOpen(false)}
            createModalCLose={createModalCLose}
            updateOnCreate={updateOnCreate}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default RequestHeader;
