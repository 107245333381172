import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;

  .body-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 18px;
  }
`;
