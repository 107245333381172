import React, { useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import { Collapse, OverlayTrigger, Popover } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import sm from './TableHeaderFilterByShow.module.css';
import { MOTIONDATA } from '../../Equipment/EquipmentHeader/EquipmentHeaderFilterByShow';

const TableHeaderFilterByShow = ({
  groups,
  setGroups,
  motions,
  setMotions,
  statuses,
  setStatuses,
  equipments,
  setEquipments,
  onApplyFilterCheckboxes,
  filterByGroupsData,
  data,
  onReset,
  onDeleteLocationsItems,
  localFilterCheckboxes,
  setLocalFilterCheckboxes,
  disabledFilters,
  onDeleteStopPointItem,
  statusData,
  tableType,
  onDeleteDatePickerCreatedBy,
  audit,
  type,
}) => {
  const initialAccordion = { status: false, groups: false, equipments: false, motions: false };

  const [show, setShow] = useState(false);

  const [accordion, setAccordion] = useState({ ...initialAccordion });

  const styles = useMemo(() => {
    return {
      container: {
        borderColor: palette.gray200,
      },
      title: {
        color: palette.indigo500,
        marginTop: 4,
        background: palette.indigo0,
        padding: '0 6px 0 6px',
      },
      reset: {
        marginLeft: 8,
        marginTop: 4,
        cursor: 'pointer',
        color: palette.indigo500,
      },
      searchValue: {
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: palette.indigo700,
      },
      additionalText: {
        marginTop: 4,
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: palette.indigo700,
      },
      options: {
        cursor: 'pointer',
      },
      checkboxes: {
        margin: '0 0 5px 10px',
        width: 'calc(50% - 30px)',
      },
      checkboxesText: {
        color: palette.gray700,
        marginLeft: 5,
        whiteSpace: 'nowrap',
      },
    };
  }, [palette]);

  function onToggleItem(name) {
    setAccordion((prevState) => {
      return {
        status: false,
        groups: false,
        equipments: false,
        motions: false,
        [name]: !prevState[name],
      };
    });
  }

  function onTickCheckboxes(item, name) {
    const mapState = {
      status: { state: statuses, setState: setStatuses },
      groups: { state: groups, setState: setGroups },
      equipment_types: { state: equipments, setState: setEquipments },
      motions: { state: motions, setState: setMotions },
    };
    const TYPE = localFilterCheckboxes[name];
    const { state, setState } = mapState[name];
    const foundedIndex = TYPE.findIndex((el) => +el.id === +item.id);

    if (foundedIndex !== -1) {
      const newState = state.filter((id) => +id !== +item.id);
      setState([...newState]);

      const newType = [...TYPE];
      newType.splice(foundedIndex, 1);
      setLocalFilterCheckboxes((prevState) => {
        return {
          ...prevState,
          [name]: [...newType],
        };
      });
    } else {
      setState([...state, item.id]);
      setLocalFilterCheckboxes((prevState) => {
        return {
          ...prevState,
          [name]: [...prevState[name], { ...item }],
        };
      });
    }
  }

  useEffect(() => {
    !show && setAccordion({ ...initialAccordion });
  }, [show]);

  const content = (
    <Popover
      style={{
        minWidth: 426,
        backgroundColor: palette.white,
      }}
    >
      <div className={sm.overlay_header} style={{ borderColor: palette.gray200 }}>
        <Typography variant='h5'>Options</Typography>
      </div>

      <div className={sm.overlay_body} style={{ backgroundColor: palette.gray0 }}>
        {!audit && tableType !== 'shipment' && (
          <div className={sm.overlay_status_wrapper}>
            <div
              className={`${sm.closed} ${disabledFilters?.status ? sm.disable_collapse : ''}`}
              onClick={() => {
                if (!disabledFilters?.status) {
                  onToggleItem('status');
                }
              }}
            >
              <Typography variant='s1'>Status</Typography>
              <ArrowDown style={{ transform: `rotateX(${accordion.status ? 180 : 0}deg)` }} />
            </div>

            <Collapse in={accordion.status}>
              <div className={sm.opened}>
                {statusData?.map((status) => {
                  const { id, value, title } = status;
                  return (
                    <CustomCheckbox
                      key={id}
                      style={styles.checkboxes}
                      checked={statuses.includes(id)}
                      onChange={() => onTickCheckboxes(status, value)}
                    >
                      <Typography variant='s2' style={styles.checkboxesText}>
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  );
                })}
              </div>
            </Collapse>
          </div>
        )}

        {audit && (
          <div className={sm.overlay_status_wrapper}>
            <div
              className={`${sm.closed} ${disabledFilters?.status ? sm.disable_collapse : ''}`}
              onClick={() => {
                if (!disabledFilters?.status) {
                  onToggleItem('status');
                }
              }}
            >
              <Typography variant='s1'>Status</Typography>
              <ArrowDown style={{ transform: `rotateX(${accordion.status ? 180 : 0}deg)` }} />
            </div>

            <Collapse in={accordion.status}>
              <div className={sm.opened}>
                {statusData?.map((status) => {
                  const { id, value, title } = status;
                  return (
                    <CustomCheckbox
                      key={id}
                      style={styles.checkboxes}
                      checked={statuses.includes(id)}
                      onChange={() => onTickCheckboxes(status, value)}
                    >
                      <Typography variant='s2' style={styles.checkboxesText}>
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  );
                })}
              </div>
            </Collapse>
          </div>
        )}

        {/* // groups */}
        {tableType !== 'shipment' && (
          <div className={sm.overlay_status_wrapper}>
            <div
              className={`${sm.closed} ${disabledFilters?.groups ? sm.disable_collapse : ''}`}
              onClick={() => {
                if (!disabledFilters?.groups) {
                  onToggleItem('groups');
                }
              }}
            >
              <Typography variant='s1'>Tags</Typography>
              <ArrowDown style={{ transform: `rotateX(${accordion.groups ? 180 : 0}deg)` }} />
            </div>

            <Collapse in={accordion.groups}>
              <div className={sm.opened}>
                {filterByGroupsData.map((group) => {
                  const { id, value, title } = group;
                  return (
                    <CustomCheckbox
                      key={id}
                      style={styles.checkboxes}
                      checked={groups.includes(id)}
                      onChange={() => onTickCheckboxes(group, value)}
                    >
                      <Typography variant='s2' style={styles.checkboxesText}>
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  );
                })}
              </div>
            </Collapse>
          </div>
        )}

        {/* // equipment_types */}
        {/* <div className={sm.overlay_status_wrapper}> */}
        {/*  <div */}
        {/*    className={`${sm.closed} ${disabledFilters?.equipment_types ? sm.disable_collapse : ''}`} */}
        {/*    onClick={() => { */}
        {/*      if (!disabledFilters?.equipment_types) { */}
        {/*        onToggleItem('equipments'); */}
        {/*      } */}
        {/*    }} */}
        {/*  > */}
        {/*    <Typography variant='s1'>Equipment Type</Typography> */}
        {/*    <ArrowDown style={{ transform: `rotateX(${accordion.equipments ? 180 : 0}deg)` }} /> */}
        {/*  </div> */}

        {/*  <Collapse in={accordion.equipments}> */}
        {/*    <div className={sm.opened}> */}
        {/*      {filterByEquipmentTypeData.map((equipment) => { */}
        {/*        const { id, value, title } = equipment; */}
        {/*        return ( */}
        {/*          <CustomCheckbox */}
        {/*            key={id} */}
        {/*            style={styles.checkboxes} */}
        {/*            checked={equipments.includes(id)} */}
        {/*            onChange={() => onTickCheckboxes(equipment, value)} */}
        {/*          > */}
        {/*            <Typography variant='s2' style={styles.checkboxesText}> */}
        {/*              {title} */}
        {/*            </Typography> */}
        {/*          </CustomCheckbox> */}
        {/*        ); */}
        {/*      })} */}
        {/*    </div> */}
        {/*  </Collapse> */}
        {/* </div> */}

        {tableType === 'shipment' && (
          <div className={sm.overlay_status_wrapper}>
            <div
              className={`${sm.closed} ${disabledFilters?.groups ? sm.disable_collapse : ''}`}
              onClick={() => {
                if (!disabledFilters?.motions) {
                  onToggleItem('motions');
                }
              }}
            >
              <Typography variant='s1'>Motion</Typography>
              <ArrowDown style={{ transform: `rotateX(${accordion.motions ? 180 : 0}deg)` }} />
            </div>

            <Collapse in={accordion.motions}>
              <div className={sm.opened}>
                {MOTIONDATA.map((motion) => {
                  const { id, value, title } = motion;
                  return (
                    <CustomCheckbox
                      key={id}
                      style={styles.checkboxes}
                      checked={motions?.includes(id)}
                      onChange={() => onTickCheckboxes(motion, value)}
                    >
                      <Typography variant='s2' style={styles.checkboxesText}>
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  );
                })}
              </div>
            </Collapse>
          </div>
        )}
      </div>

      <div className={sm.overlay_footer} style={{ borderColor: palette.gray200 }}>
        <CustomButton
          title='Cancel'
          type='secondary'
          onClick={() => setShow(false)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
        />
        <CustomButton
          title='Apply'
          type='primary'
          onClick={() => {
            setShow(false);
            onApplyFilterCheckboxes({
              checkboxes: localFilterCheckboxes,
              status: statuses,
              groups,
              motions,
              equipment_types: equipments,
            });
          }}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
        />
      </div>
    </Popover>
  );

  return (
    <div className={sm.TableHeaderFilterByShow_container} style={styles.container}>
      <div className={sm.title_wrapper}>
        {tableType === 'noPopup' ? (
          <div className={sm.overlay_trigger_wrapper}>
            <Typography variant='overLine' style={styles.options}>
              <FilterIcon width={11} height={11} style={{ marginRight: 4 }} fill={palette.gray700} />
              Options: {data.length}
            </Typography>
          </div>
        ) : (
          <div className={sm.title_wrapper_left}>
            {type !== 'history' && (
              <OverlayTrigger
                show={show}
                trigger='click'
                rootClose
                placement='right'
                overlay={content}
                onToggle={setShow}
              >
                <div className={sm.overlay_trigger_wrapper}>
                  <Typography variant='overLine' style={styles.options}>
                    <FilterIcon width={11} height={11} style={{ marginRight: 4 }} fill={palette.gray700} />
                    Options: {data.length}
                  </Typography>
                </div>
              </OverlayTrigger>
            )}
            <Typography variant='overLine' style={styles.reset} onClick={onReset}>
              Clear All
            </Typography>
          </div>
        )}
      </div>
      <div className={sm.mapped_data_container}>
        {data.map((el) => {
          const { title, searchValue } = el || {};
          const stopPoint = el?.[0]?.type && el?.[0]?.type === 'stop_point';
          return (
            <div key={uuid()} className={sm.container}>
              {stopPoint ? (
                <StopPoint data={el} styles={styles} onDeleteStopPointItem={onDeleteStopPointItem} />
              ) : Array.isArray(el) ? (
                <Locations
                  data={el}
                  styles={styles}
                  onDeleteLocationsItems={onDeleteLocationsItems}
                  onDeleteDatePickerCreatedBy={onDeleteDatePickerCreatedBy}
                />
              ) : (
                <div className={sm.mapped_data_wrapper}>
                  <Typography variant='c1' style={styles.title}>
                    {title}:
                  </Typography>
                  {/* // groups - status - equipment */}
                  {Array.isArray(searchValue) ? (
                    searchValue.map((item, index) => (
                      <div key={item?.id || index} className={sm.many_data_show_wrapper}>
                        <Typography variant='c1' style={styles.searchValue}>
                          {item.title || item.label || item.equipment_id || `${item.fname} ${item.lname}`}
                          {index !== searchValue.length - 1 && ','}
                          &nbsp;
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <div className={sm.many_data_show_wrapper}>
                      <Typography variant='c1' style={{ ...styles.searchValue, paddingRight: 4 }}>
                        {searchValue}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Locations = ({ data, styles, onDeleteLocationsItems, onDeleteDatePickerCreatedBy }) => {
  const origin = data.filter((d) => ['origin_city', 'origin_state'].includes(d.value));
  const destination = data.filter((d) => ['destination_city', 'destination_state'].includes(d.value));
  const locations = data.filter((d) => ['locations'].includes(d.value));
  const created_date = data.filter((d) => ['created_date'].includes(d.value));

  const commaOrigin = destination.length > 0 || locations.length > 0 || created_date.length > 0;
  const commaDest = locations.length > 0 || created_date.length > 0;

  const render = (data, type, comma = true) => {
    if (data.length === 0) return null;
    const index = data.findIndex((el) => el.value === 'origin_city' || el.value === 'destination_city');
    const sortedData = index === 0 ? data : data.reverse();

    return (
      <div className='d-flex'>
        {sortedData.map((el) => {
          if (!!type && (el.value === 'origin_city' || el.value === 'destination_city')) {
            return (
              <Typography key={el.key} variant='c1' style={styles.additionalText}>
                {type}(&nbsp;
              </Typography>
            );
          }
          return null;
        })}
        {sortedData.map((el, i) => {
          return (
            <div key={uuid()} className={sm.many_data_show_wrapper}>
              <Typography variant='c1' style={styles.searchValue}>
                {el?.searchValue}
                {!!type && (el.value === 'origin_city' || el.value === 'destination_city') && (
                  <>
                    &nbsp;
                    <Typography variant='c1' style={{ ...styles.additionalText, marginTop: 0 }}>
                      )&nbsp;
                    </Typography>
                  </>
                )}
                {(comma || (!comma && !!type && i === 0 && sortedData.length > 1)) && ','}&nbsp;
              </Typography>

              <div
                className={sm.delete_item_wrapper}
                onClick={() => {
                  if (el.value === 'created_date') {
                    onDeleteDatePickerCreatedBy(el);
                  } else {
                    onDeleteLocationsItems(el);
                  }
                }}
              >
                <DeleteIcon width={8} height={8} fill={palette.red500} />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={sm.container}>
      <div className={sm.mapped_data_wrapper}>
        <Typography variant='c1' style={styles.title}>
          {data?.[0]?.title}:
        </Typography>
        {render(origin, 'Origin', commaOrigin)}
        {render(destination, 'Destination', commaDest)}
        {render(locations, null, false)}
        {render(created_date, null, false)}
      </div>
    </div>
  );
};

const StopPoint = ({ data, styles }) => {
  const [dataMap, setDataMap] = useState([]);

  useEffect(() => {
    const newDataMap = [];
    const dates = [];
    data.forEach((el) => (el?.value === 'stop_point' ? newDataMap.push(el) : dates.push(el)));
    if (dates[0]?.searchValue && dates[1]?.searchValue) {
      newDataMap.push({ ...dates[0], ...dates[1], searchValue: `${dates[0]?.searchValue} - ${dates[1]?.searchValue}` });
    } else if (dates[0]?.searchValue && !dates[1]?.searchValue) {
      newDataMap.push({ ...dates[0], searchValue: dates[0]?.searchValue });
    }

    setDataMap(newDataMap);
  }, [data]);

  return (
    <div className={sm.mapped_data_wrapper}>
      <Typography variant='c1' style={styles.title}>
        Stop Point:
      </Typography>
      {dataMap.map((item, index) => {
        return (
          <div key={item?.id || index} className={sm.many_data_show_wrapper}>
            <Typography variant='c1' style={styles.searchValue}>
              {item.searchValue}
              {index !== dataMap.length - 1 && dataMap?.[index]?.value !== 'stop_point_scheduled_from' && ','}
              &nbsp;
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TableHeaderFilterByShow;
