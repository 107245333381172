import React from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { palette } from 'utils/constants';
import { useSelector } from 'react-redux';
import { getOptions } from './ChartSection.data';

const months = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ChartOption = ({ monthsData }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const { currency } = useSelector((state) => state.root);

  const options = getOptions(currency);

  const data = {
    labels: (monthsData || []).map((item) => `${months[item.month]} ${item.year}`),
    datasets: [
      {
        data: (monthsData || []).map((item) => item.amount),
        backgroundColor: palette.indigo400,
        borderRadius: 4,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};
