import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import button from 'assets/icons/drivers/button.svg';
import close from 'assets/icons/drivers/close.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useFlag from 'assets/icons/drivers/usaFlag.svg';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import maxico from 'assets/icons/drivers/mexico.png';
import useForm from 'hooks/useForm';
import AddressFields from 'common/AddressFields';

const whitelist = ['US', 'CA', 'MX'];

const OwnerGeneralInformation = ({ updateGeneralProfile, staffInfo, onSuccess, loading }) => {
  const [toggle, setToggle] = React.useState(false);

  const { use } = useTheme();

  const formik = useForm({
    initialValues: {
      owner_operator_name: '',
      phone_no: '',
      address1: '',
      email_id: '',
      address2: '',
      state_id: null,
      country_id: null,
      city_id: null,
      zip: '',
      operator_type_id: 1,
      vehicles_can_travel: [],
    },
  });

  const { values, handleChange, touchedErrors } = formik;

  useEffect(() => {
    formik.setFieldValue('owner_operator_name', staffInfo?.owner_operator_name);
    formik.setFieldValue('email_id', staffInfo?.email_id);
    formik.setFieldValue('address1', staffInfo?.address1);
    formik.setFieldValue('address2', staffInfo?.address2);
    formik.setFieldValue('phone_no', staffInfo?.phone_no);
    formik.setFieldValue('country_id', staffInfo?.country);
    formik.setFieldValue('state_id', staffInfo?.state);
    formik.setFieldValue('city_id', staffInfo?.city);
    formik.setFieldValue('zip', staffInfo?.zip);
    const ids = [];
    staffInfo?.vehicles_can_travel?.forEach((item) => {
      ids.push(String(item.id));
    });
    formik.setFieldValue('vehicles_can_travel', ids || []);
  }, [staffInfo]);

  const saveGeneral = () => {
    const { country_id, state_id, city_id } = formik.values;
    const payload = {
      ...staffInfo,
      ...formik.values,
      country_id: country_id?.id,
      state_id: state_id?.id,
      city_id: city_id?.id,
    };
    updateGeneralProfile(payload, null, successResponse, 'Owner Operator has been updated successfully');
  };

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const onChangeVehicles = (value) => {
    const temp = formik.values.vehicles_can_travel;
    const hasValueIndex = temp.indexOf(value);
    if (hasValueIndex >= 0) {
      temp.splice(hasValueIndex, 1);
    } else {
      temp.push(value);
    }
    formik.setFieldValue('vehicles_can_travel', temp);
  };

  const handleAddressChange = (event, value) => {
    const addressFieldsMapper = {
      address: 'address1',
      address2: 'address2',
      city: 'city_id',
      state: 'state_id',
      country: 'country_id',
      zipcode: 'zip',
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  return (
    <div
      className='generalInformation driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
        height: '100%',
      }}
    >
      <div className='header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          General Information
        </p>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <img src={close} alt='' onClick={() => setToggle(!toggle)} className='tick-close' />
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <img
                src={button}
                alt=''
                onClick={() => {
                  saveGeneral();
                }}
                className='tick-close'
              />
            )}
          </div>
        )}
      </div>
      {toggle ? (
        <div className='information-edit-mode' style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <div className='col-6'>
              <p className='table-data-text col-6 m-0' style={{ color: use(palette.gray400, palette.gray200) }}>
                Name
              </p>
            </div>
            <div className='col-6'>
              <input
                type='text'
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: use('black', palette.gray600),
                }}
                className='input-type'
                name='owner_operator_name'
                id='owner_operator_name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.owner_operator_name}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: use(palette.gray400, palette.gray200) }}>
                Phone Number
              </p>
            </div>
            <div className='col-6'>
              <PhoneInput
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
                addInternationalOption={false}
                className='input-type'
                value={formik.values.phone_no}
                countries={whitelist}
                onChange={(val) => formik.setFieldValue('phone_no', val)}
                defaultCountry='US'
                placeholder='(555) 555-1234'
              />
            </div>
          </div>

          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: use(palette.gray400, palette.gray200) }}>
                Email
              </p>
            </div>
            <div className='col-6'>
              <input
                className='input-type'
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: use('black', palette.gray600),
                }}
                type='text'
                name='email_id'
                id='email_id'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email_id}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex information-edit-mode-sections'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <div className='col-6'>
              <p className='table-data-text property' style={{ color: use(palette.gray400, palette.gray200) }}>
                Address
              </p>
            </div>
            <div className='col-6'>
              <AddressFields
                required
                size='small'
                isOneColumn
                values={{
                  address: values.address1,
                  address2: values.address2,
                  city: values.city_id,
                  state: values.state_id,
                  country: values.country_id,
                  zipcode: values.zip,
                }}
                handleChange={handleAddressChange}
                touchedErrors={touchedErrors}
              />
            </div>
          </div>
          <div
            className='col-12  information-edit-mode-sections'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <div className='col-12'>
              <p className='table-data-text m-0' style={{ color: use(palette.gray400, palette.gray200) }}>
                Vechicle Can Travel To
              </p>
            </div>
            <div className='col-12 d-flex can-travel-wrap mt-2'>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  type='checkbox'
                  name='vehicles_can_travel'
                  id='vehicles_can_travel'
                  checked={formik.values.vehicles_can_travel.indexOf('1') >= 0}
                  onChange={() => onChangeVehicles('1')}
                />
                <span className='information-edit-checkbox-title' style={{ color: use('black', palette.gray600) }}>
                  USA
                </span>
              </div>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  type='checkbox'
                  name='vehicles_can_travel'
                  id='vehicles_can_travel'
                  checked={formik.values.vehicles_can_travel.indexOf('2') >= 0}
                  onChange={() => onChangeVehicles('2')}
                />
                <span className='information-edit-checkbox-title' style={{ color: use('black', palette.gray600) }}>
                  Canada
                </span>
              </div>
              <div className='d-flex align-items-center  can-travel-wrap-sub'>
                <input
                  type='checkbox'
                  name='vehicles_can_travel'
                  id='vehicles_can_travel'
                  checked={formik.values.vehicles_can_travel.indexOf('3') >= 0}
                  onChange={() => onChangeVehicles('3')}
                />
                <span className='information-edit-checkbox-title' style={{ color: use('black', palette.gray600) }}>
                  Mexico
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='information'>
          <div
            className='info-container border-block'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <p className='property'>Name</p>
            <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
              {staffInfo?.owner_operator_name}
            </p>
          </div>

          <div
            className='info-container border-block'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <p className='property'>Phone Number</p>
            <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
              {staffInfo?.phone_no}
            </p>
          </div>
          <div
            className='info-container border-block'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <p className='property'>Email</p>
            <p className='value contact'>{staffInfo?.email_id}</p>
          </div>
          <div
            className='info-container address border-block'
            style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
          >
            <p className='property'>Address</p>
            <div className='address-details'>
              <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                {staffInfo?.address1}
              </p>
              <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                {staffInfo?.address2}
              </p>
              <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>{`${
                staffInfo?.city?.name || ''
              }${staffInfo?.state?.name ? ',' : ''} ${staffInfo?.state?.name || ''}  ${staffInfo?.zip || ''}`}</p>
              <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                {staffInfo?.country?.name}
              </p>
            </div>
          </div>
          <div className='info-container'>
            <p className='property'>Can Travel To</p>
            <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
              {staffInfo?.vehicles_can_travel?.map((item) => {
                return item?.id === 1 ? (
                  <img src={useFlag} alt='USA-Flag' />
                ) : item?.id === 2 ? (
                  <img src={turkeyFlag} alt='canada-Flag' />
                ) : item?.id === 3 ? (
                  <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
                ) : null;
              })}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnerGeneralInformation;
