import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SFlexColumnGap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  .edit-icon-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg {
      visibility: hidden;
    }
  }

  :hover {
    .edit-icon-wrapper {
      svg {
        visibility: visible;
      }
    }
  }
`;

export const SFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .warning-icon path {
    fill: ${palette.red500};
  }
`;
