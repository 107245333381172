import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getInsurancePolicies, updateInsurancePolicies } from 'Api/Insurance';
import { validationSchema } from 'pages/CompanySettings/pagesComponents/Equipment/Insurance/validationSchema';
import InsuranceFields from '../components/InsuranceFields';
import { getInitialValues, insuranceFields, updateInsuranceConverter } from './Insurance.data';
import { SWrapper } from '../Equipment.styles';

const Insurance = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      const formData = updateInsuranceConverter(values);
      await updateInsurancePolicies(formData);

      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const getSettings = async () => {
    try {
      const { data } = await getInsurancePolicies();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Insurance'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={form.handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          {insuranceFields.map((field) => (
            <InsuranceFields key={field.id} field={field} form={form} />
          ))}
        </div>
      )}
    </SWrapper>
  );
};

export default Insurance;
