import styled from 'styled-components';

export const SBoxWrapper = styled.div`
  padding: 18px 26px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 2%), 0 0 0 1px rgb(70 79 96 / 2%), 0 2px 5px rgb(89 96 120 / 30%);
  border-radius: 8px;

  & > div {
    height: 130px;
  }
`;
