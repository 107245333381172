import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { useParams } from 'react-router-dom';
import SafetyRecords from '../../../../DotMonitoring/TableSection/SafetyRecords/SafetyRecords';

const DotRecords = ({ open, onClose, onSuccess, hideView }) => {
  const { id } = useParams();
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<div>DOT Records</div>}
      $bgColor={palette.gray0}
      $maxWidth='80vw'
      $minWidth='1000px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        ...(!hideView
          ? [
              {
                key: 'close',
                type: 'secondary',
                title: 'View Selected',
                onClick: () => onSuccess(),
              },
            ]
          : []),
        {
          key: 'done',
          type: 'primary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <SafetyRecords vehicleId={id} />
    </Modal>
  );
};

export default DotRecords;
