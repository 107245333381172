import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../maintenancecost.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import axios from 'axios';
import { EquipmentMaintenanceSchedule } from 'Api/Equipment';
import { Icons } from 'assets/icons';
import authHeader from 'services/auth-header';
import { EquipmentTableSort } from 'components/Equipment/EquipmentTableWrapper/EquipmentTableWrapper';
import ScheduleMaintenance from './MaintainanceComponents/ScheduleMaintenance';

const API_URL = process.env.REACT_APP_API_URL;

const Record = ({ profileData, openToast, scheduleSuccess, trailerID }) => {
  const { currency } = useSelector((state) => state.root);
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewPopup, setViewPopup] = useState(null);
  const [page, setPage] = useState(2);
  const [recordID, setRecordID] = useState();
  const [scheduleservice, setScheduleService] = useState(false);
  const [scheduUpdate, setScheduleUpdate] = useState();

  const viewPopupOpen = Boolean(viewPopup);

  const viewPopupModal = viewPopupOpen ? 'simple-popover' : undefined;
  const viewPopupModalOpen = (e, id) => {
    setViewPopup(e.currentTarget);
    setRecordID(id);
  };
  const viewPopupClose = () => {
    setViewPopup(null);
  };
  const getscheduleUpdate = (data) => {
    setScheduleUpdate(data);
  };
  const ScheduleServiceModalOpen = (id) => {
    setRecordID(id);
    viewPopupClose();
    setScheduleService(true);
  };
  async function MaintenanceSchedule(query = '') {
    if (query === '') {
      query = `sort[][id] = desc`;
    }
    setLoading(true);
    EquipmentMaintenanceSchedule(profileData?.id, {
      query,
    }).then((res) => {
      setSchedule(res?.data);
      setLoading(false);
      setPage(2);
    });
  }
  useEffect(() => {
    MaintenanceSchedule();
  }, [scheduleSuccess, scheduUpdate]);

  const handleRemoveRecord = () => {
    axios.delete(`${API_URL}/equipment/maintenance-schedule/${recordID}`, { headers: authHeader() }).then((res) => {
      if (res) {
        openToast('Schedule remove successfully');
        viewPopupClose();
        MaintenanceSchedule();
        setPage(2);
      }
    });
  };
  const onScroll = () => {
    const tableEl = document.getElementById('Schedule-reacords');
    if (tableEl.scrollHeight - tableEl.offsetHeight - tableEl.scrollTop <= 1) {
      moreData();
    }
  };
  async function moreData() {
    setLoading(true);
    const url = `${API_URL}/equipment/maintenance-schedule?page=${page}&equipment_id=${profileData?.id}`;
    axios.get(url, { headers: authHeader() }).then((res) => {
      setSchedule([...schedule, ...(res?.data?.data || [])]);
      setPage(page + 1);
      setLoading(false);
    });
  }

  function sortingQuery(field, sortBy, nested_field) {
    let urlQuery;
    if (nested_field) {
      urlQuery = `sort[${nested_field}]${field}=${sortBy}`;
    } else {
      urlQuery = `sort[][${field}]=${sortBy}`;
    }

    MaintenanceSchedule(urlQuery);
  }

  return (
    <div className='Schedule-table' id='Schedule-reacords' onScroll={onScroll}>
      <table className='table'>
        {loading ? (
          <div style={{ position: 'absolute', left: '50%', top: '60%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </div>
        ) : (
          ''
        )}
        <thead>
          <tr style={{ padding: '10px 0px' }}>
            <th scope='col p-1' className='maintenance-cost-table-heading' data-field='TYPE'>
              <span>
                <EquipmentTableSort title='TYPE' sortingQuery={sortingQuery} field='[id]' nested_field='record_type' />
              </span>
            </th>
            <th scope='col p-1' className='maintenance-cost-table-heading'>
              DESCRIPTION
            </th>
            <th scope='col p-1' className='maintenance-cost-table-heading'>
              <span>
                <EquipmentTableSort title='SHOP' sortingQuery={sortingQuery} field='[id]' nested_field='service_at' />
              </span>
            </th>
            <th scope='col p-1' className='maintenance-cost-table-heading'>
              <span>
                <EquipmentTableSort title='ESTIMATED COST' sortingQuery={sortingQuery} field='cost' />
              </span>
            </th>
            <th scope='col p-1' className='maintenance-cost-table-heading'>
              <span>
                <EquipmentTableSort title='INTERVAL' sortingQuery={sortingQuery} field='date_interval' />
              </span>
            </th>
            <th scope='col p-1' className='maintenance-cost-table-heading'>
              <span>
                <EquipmentTableSort title='STATUS' sortingQuery={sortingQuery} field='status' />
              </span>
            </th>
          </tr>
        </thead>
        <tbody className='maintenance-cost-table-content align-middle'>
          {schedule &&
            schedule?.map((record) => (
              <tr>
                <td className='align-middle'>{record?.record_type?.type}</td>
                <td className='align-middle' style={{ maxWidth: '500px' }}>
                  {record?.description || '-'}
                </td>
                <td>
                  <span style={{ fontSize: '13px' }} className='me-1'>
                    {record?.service_at?.name || '-'}
                  </span>

                  {record?.service_at?.address1 ? (
                    <span style={{ fontSize: '13px' }} className='me-1'>
                      {record?.service_at?.address1},
                    </span>
                  ) : (
                    ''
                  )}
                  {record?.service_at?.address2 ? (
                    <span style={{ fontSize: '13px' }} className='me-1'>
                      {record?.service_at?.address2},
                    </span>
                  ) : (
                    ''
                  )}

                  {record?.service_at?.city?.name ? (
                    <span style={{ fontSize: '13px' }} className='me-1'>
                      {record?.service_at?.city?.name},
                    </span>
                  ) : (
                    ''
                  )}
                  {record?.service_at?.state?.name ? (
                    <span className='me-1'>{record?.service_at?.state?.name}</span>
                  ) : (
                    ''
                  )}

                  {record?.service_at?.zipcode ? (
                    <span style={{ fontSize: '13px' }}>{record?.service_at?.zipcode}</span>
                  ) : (
                    ''
                  )}
                </td>
                <td>{record?.cost ? `${currency} ${record?.cost}` : '-'}</td>
                <td className='align-middle'>
                  {record?.date_interval_mode === 1 ? (
                    <span>
                      {record?.date_interval}{' '}
                      {record?.date_interval_type_id?.type === 'Month' ? 'Months' : record?.date_interval_type_id?.type}
                    </span>
                  ) : record?.one_time === 1 ? (
                    <span>One Time</span>
                  ) : (
                    <span>
                      {record?.odometer_interval} {record?.odometer_type}
                    </span>
                  )}
                </td>
                <td>
                  <div className='d-flex justify-content-between me-3'>
                    <span
                      style={{
                        backgroundColor: '#E1FCEF',
                        color: '#14804A',
                        fontSize: '12px',
                        padding: '2px 2px',
                        borderRadius: '4px',
                      }}
                    >
                      {record?.status}
                    </span>
                    <span className='dropdown more-icon dropstart'>
                      <span data-bs-toggle='dropdown'>
                        <img src={Icons.More} onClick={(e) => viewPopupModalOpen(e, record?.id)} alt='' />
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          {loading === false && schedule?.length === 0 && (
            <div style={{ position: 'absolute', left: '40%', top: '60%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <span>No record found</span>
              </Box>{' '}
            </div>
          )}
        </tbody>
      </table>
      <Popover
        id={viewPopupModal}
        // anchorPosition={{ top: 299, left: 669 }}
        open={viewPopupOpen}
        anchorEl={viewPopup}
        onClose={viewPopupClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        style={{ marginRight: '40px' }}
      >
        <div className='maintenance-scheduled-action-pop-up'>
          <div className='maintenance-action-pop-up-content'>
            <span className='mt-1 popover-link' onClick={() => ScheduleServiceModalOpen(recordID)}>
              Edit
            </span>
            <span className='mt-1 popover-link ' style={{ color: '#D12953' }} onClick={handleRemoveRecord}>
              Remove
            </span>
          </div>
        </div>
      </Popover>
      {scheduleservice && (
        <ScheduleMaintenance
          open={scheduleservice}
          onClose={() => setScheduleService(false)}
          equipment={profileData}
          equipmentType={trailerID}
          onSuccess={getscheduleUpdate}
          maintenance={schedule?.find((v) => v?.id === recordID)}
        />
      )}
    </div>
  );
};

export default Record;
