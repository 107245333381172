import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, FastField, Field, useField } from 'formik';

import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getCustomerPaymentTerm, getRecurringLane } from 'Api/Planner';

import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import {
  getCharges,
  UpdateShipmentBillingHeader,
  updateRecurringLaneBilling,
  createRecurringLaneBillingCharge,
  CreateShipmentBillingCharge,
  updateRecurringLaneBillingCharge,
  UpdateShipmentBillingCharge,
} from 'Api/Shipment';
import { getCustomerPayTerms } from 'Api/Customers';
import { handleToaster } from 'store/reducers/root.reducer';
import { Typography } from 'components/Typography';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { BillingChargesContext } from './BillingChargeChild';
import CustomSelect from '../../../../CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomButton from '../../../../CustomButton/CustomButton';
import CustomInput from '../../../../CreateShipment/helpers/CustomInput';
import styles from './billingCharges.module.css';

const BillingChargesHeader = ({ isRecurring, setChargeErrors }) => {
  const { use } = useTheme();
  const { billToData, assignToGroupsData } = useSelector((state) => state?.shipments);

  const {
    isValid,
    touched,
    setTouched,
    values,
    getContacts,
    selectedBillTo,
    updateBillToSelected,
    selectedContact,
    updateContactSelected,
    contactDataObj,
    updatePaymentTermData,
    editMood,
    setEditMood,
    validateForm,
    setFieldValue,
    selectedCharges,
    updateSelectedCharges,
    onChangeContactEditModal,
    selectedGrandTotalForm,
    allChargesData,
    setUpdateBillData,
    indexPrefix,
  } = useContext(BillingChargesContext);
  const { id } = useParams();
  const contactData = contactDataObj?.[0];
  const selectedBillToObj = selectedBillTo?.[0];
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const helpersContact = useField(`contact_user_id`)[2];
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState([]);

  const helpersGroup = useField('group')[2];

  const activeItemGroups = useMemo(() => {
    if (values?.group) {
      return assignToGroupsData.find((el) => +el.id === +values.group);
    }
  }, [assignToGroupsData, values]);

  const validateRequired = (value) => {
    let error;
    if (value !== undefined && value === '') {
      error = 'Required';
    }
    return error;
  };

  function onChangeBillTo(selectedItem) {
    const customer_id = selectedItem?.id;
    getCustomerPaymentTerm({ customer_id }).then((res) => updatePaymentTermData({ ...res?.data }));
  }

  const billingStyles = useMemo(() => {
    return {
      headerLeft: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      global: {
        width: 'calc(20% - 40px)',
      },
      text: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
      },
      group: {
        maxWidth: '100%',
        wordBreak: 'break-word',
        margin: 10,
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
      cancel: {
        padding: '0 6px',
        margin: 0,
        fontSize: 14,
      },
      save: {
        padding: '0 6px',
        margin: 0,
        marginLeft: 12,
        fontSize: 14,
      },
    };
  }, [use, palette]);

  useEffect(() => {
    const customer_id = values?.customer_id;
    const contact_user_id = values?.contact_user_id;
    if (customer_id) {
      setDisabled(false);
      const billTo = billToData.find((item) => +item.id === +customer_id);
      !!billTo && updateBillToSelected(billTo);
      if (billTo?.id) {
        getContacts(billTo.id).then((data) => {
          const founded = data?.find((item) => +item.id === +contact_user_id);
          !founded && helpersContact.setValue('');
        });
      }
      getCustomerPayTerms().then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: getPaymentTermTitle(el),
              labelSelected: null,
            };
          });
          setInvoicePayTermsOptions(selectData);
        }
      });
    } else {
      setDisabled(true);
    }
  }, [values?.customer_id, billToData]);

  useEffect(() => {
    const contact = contactData?.find((item) => +item.id === +values.contact_user_id);
    if (contactData?.length > 0) {
      updateContactSelected({ ...contact });
    } else {
      updateContactSelected({});
    }
  }, [values?.contact_user_id, contactData]);

  const mapChargesData = (charge) => {
    return {
      charge_type: charge?.charge_type,
      quantity: charge?.quantity,
      rate: charge?.rate,
      id: charge?.id,
      detention: charge?.detention,
      ref_id: charge?.ref_id,
      ref_shipment_id: charge?.ref_shipment_id,
    };
  };

  const getBillingCharges = () => {
    if (!isRecurring) {
      getCharges(id).then((res) => {
        if (res?.data?.[indexPrefix]) {
          updateSelectedCharges(res.data[indexPrefix].billing_charges?.map((charge) => mapChargesData(charge)));
        }
      });
    } else {
      getRecurringLane(id).then((res) => {
        if (res?.data?.shipment_billing?.[indexPrefix]) {
          updateSelectedCharges(
            res.data.shipment_billing[indexPrefix].billing_charges?.map((charge) => mapChargesData(charge))
          );
        }
      });
    }
  };

  const shouldUpdateStops = (nextProps, props, name) => {
    switch (name) {
      case 'group':
        return nextProps?.options !== props?.options;
      case 'contact_user_id':
        return nextProps?.options !== props?.options;
      default:
    }
  };

  const createCharge = (params, charge, index) => {
    return (isRecurring ? createRecurringLaneBillingCharge(params) : CreateShipmentBillingCharge(params)).then(
      (res) => {
        if (res?.data?.id) {
          dispatch(handleToaster({ message: 'Billing charge created successfully!', visible: true, type: 'success' }));
          const chargeNewId = { ...charge, id: res?.data?.id };
          setFieldValue(`billing_charges[${index}]`, chargeNewId);
          // const chargeSave = [...selectedCharges];
          // const foundedIndex = chargeSave.findIndex((el) => el.id === chargeNewId.id);
          // if (foundedIndex !== -1) {
          //   chargeSave.splice(foundedIndex, 1, chargeNewId);
          // } else {
          //   chargeSave.push(chargeNewId);
          // }
          // updateSelectedCharges(chargeSave);
        }
      }
    );
  };

  const updateCharge = (params, charge, id) => {
    return (
      isRecurring ? updateRecurringLaneBillingCharge(id, params) : UpdateShipmentBillingCharge({ params, id })
    ).then((res) => {
      res?.message && dispatch(handleToaster({ message: res?.message, visible: true, type: 'success' }));
      // const chargeSave = [...selectedCharges];
      // const foundedIndex = chargeSave.findIndex((el) => el.id === charge.id);
      // if (foundedIndex !== -1) {
      //   chargeSave.splice(foundedIndex, 1, charge);
      // } else {
      //   chargeSave.push(charge);
      // }
      // updateSelectedCharges(chargeSave);
    });
  };

  const onSaveCharge = (charge, index) => {
    if (!charge.charge_type) {
      setChargeErrors((prevState) => ({ ...prevState, [index]: true }));
      return Promise.resolve();
    }
    return validateForm().then((errors) => {
      if (!!errors?.billing_charges && !!errors?.billing_charges?.[index]) {
        const array = touched?.billing_charges ? [...touched.billing_charges] : [];
        array[index] = {
          quantity: true,
          rate: true,
          charge_type: true,
        };
        setTouched({
          billing_charges: array,
        });
        return Promise.resolve();
      }
      const update = selectedCharges.find((el) => Number(el?.id) === Number(charge?.id));

      const { charge_type, quantity, rate, id } = charge || {};
      const params = new URLSearchParams();
      params.append('charge_type', typeof charge_type === 'object' ? charge_type?.id : charge_type);
      params.append('quantity', quantity);
      params.append('rate', rate);
      params.append('sub_total', +quantity * +rate);

      if (update) {
        return updateCharge(
          params,
          { ...charge, charge_type: typeof charge_type === 'object' ? charge_type?.id : charge_type },
          id
        );
      }
      params.append('billing_id', allChargesData.id);
      selectedGrandTotalForm?.id && params.append('currency_id', selectedGrandTotalForm.id);
      return createCharge(
        params,
        { ...charge, charge_type: typeof charge_type === 'object' ? charge_type?.id : charge_type },
        index
      );
    });
  };

  const onUpdateHeaderData = async () => {
    validateForm().then(() => {
      setLoading(true);
      const { customer_id, reference_id, group, contact_user_id, payment_term_id } = values || {};
      const params = new URLSearchParams();
      params.append('customer_id', customer_id);
      params.append('reference_id', reference_id || '');
      selectedGrandTotalForm?.id && params.append('currency_id', selectedGrandTotalForm.id);
      !!group && params.append('group_id', group);
      !!contact_user_id && params.append('contact_user_id', contact_user_id);
      !!payment_term_id && params.append('payment_term_id', payment_term_id);

      (isRecurring
        ? updateRecurringLaneBilling(allChargesData?.id, params)
        : UpdateShipmentBillingHeader({ params, id: allChargesData?.id })
      )
        .then((res) => {
          const promises = values?.billing_charges?.map((el, i) => onSaveCharge(el, i));
          Promise.all(promises)
            .then(() => {
              getBillingCharges();
              res?.message && dispatch(handleToaster({ message: res?.message, visible: true, type: 'success' }));
              setEditMood(true);
              setUpdateBillData(new Date());
            })
            .catch((e) => {
              showToaster({ type: 'error', message: getErrorMessage(e) });
            })
            .finally(() => setLoading(false));
        })
        .catch((e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) });
        });
    });
  };

  return (
    <>
      <div className={styles.global_header_wrapper} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <div>
          <Typography variant='h2' style={{ marginBottom: 16 }}>
            Billing Charges
          </Typography>
          <div className={styles.header_buttons_wrapper}>
            <CustomButton
              type='secondary'
              title='Close'
              styleButton={billingStyles.cancel}
              onClick={() => {
                setEditMood(true);
                setUpdateBillData(new Date());
              }}
            />
            <CustomButton
              type='primary'
              title='Save'
              styleButton={billingStyles.save}
              onClick={onUpdateHeaderData}
              disabled={loading || !isValid || values.billing_charges?.some((charge) => !charge.charge_type)}
            />
          </div>
        </div>
        <div className={styles.line} />
      </div>

      <div className={styles.billing_charges_header} style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className={styles.header_titles_wrapper}>
          <div className={styles.titles}>
            <div className={styles.header_title_left}>
              <Typography variant='overLine' style={billingStyles.global}>
                BILL TO
              </Typography>
              <Typography variant='overLine' style={{ width: '180px' }}>
                REFERENCE ID
              </Typography>
              <Typography variant='overLine' style={{ width: '200px' }}>
                {!editMood ? 'ASSIGN TAG' : 'TAG'}
              </Typography>
              <div className={[styles.edit_wrapper].join(' ')} style={billingStyles.global}>
                <Typography variant='overLine'>CONTACT</Typography>
                <div
                  onClick={() => {
                    Object.keys(selectedBillToObj).length > 0 && onChangeContactEditModal(true);
                  }}
                >
                  <EditIcon style={{ cursor: 'pointer' }} fill={use(palette.gray500, palette.gray200)} />
                </div>
              </div>
              <Typography style={{ width: '20%' }} variant='overLine' className={styles.paymentTerm}>
                PAYMENT TERM
              </Typography>
            </div>
          </div>
          <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
        </div>
        <div className={styles.header_wrapper}>
          <div style={billingStyles.headerLeft}>
            <div style={billingStyles.global}>
              <Field
                name='customer_id'
                isSearchable
                options={
                  selectedBillToObj && billToData.every((i) => i.id !== selectedBillToObj?.id)
                    ? [...billToData, selectedBillToObj]
                    : billToData
                }
                styles={{ height: 32 }}
                component={CustomSelect}
                validate={validateRequired}
                onChange={onChangeBillTo}
              />
              <ErrorMessage
                name='customer_id'
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
              {!!values?.customer_id && (
                <div className={styles.header_titles_wrapper}>
                  <Typography variant='s2' style={billingStyles.text}>
                    {selectedBillToObj?.address1},&nbsp;
                    {selectedBillToObj?.address2}
                  </Typography>
                  <Typography variant='s2' style={billingStyles.text}>
                    {selectedBillToObj?.city?.name},&nbsp;
                    {selectedBillToObj?.state?.name}&nbsp;
                    {selectedBillToObj?.zipcode}&nbsp;
                  </Typography>
                  <Typography variant='s2' style={billingStyles.text}>
                    {selectedBillToObj?.country?.name}
                  </Typography>
                </div>
              )}
            </div>
            <div style={{ width: '180px' }}>
              <FastField
                name='reference_id'
                component={CustomInput}
                labelStyle={{ margin: 0 }}
                style={{ width: '100%', borderRadius: 6, height: 32, padding: '0 6px' }}
              />
              <ErrorMessage
                name='reference_id'
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
            </div>

            <div style={{ width: '200px' }}>
              <FastField
                name='group'
                deleted
                isSearchable
                styles={{ height: 32 }}
                component={CustomSelect}
                dropDownStyles={{ flexGrow: 0 }}
                options={assignToGroupsData}
                onDelete={() => helpersGroup.setValue('')}
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'group')}
              />

              {activeItemGroups?.short_name && (
                <div className={styles.group_wrapper} style={{ backgroundColor: activeItemGroups?.color }}>
                  <Typography variant='c2' style={billingStyles.group}>
                    {activeItemGroups.short_name}
                  </Typography>
                </div>
              )}
            </div>
            <div style={billingStyles.global}>
              <FastField
                name='contact_user_id'
                deleted
                isSearchable
                disabled={disabled}
                options={contactData}
                styles={{ height: 32 }}
                component={CustomSelect}
                onDelete={() => helpersContact.setValue('')}
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'contact_user_id')}
              />

              {!!values?.contact_user_id &&
                typeof selectedContact === 'object' &&
                Object.keys(selectedContact).length > 0 && (
                  <div className={styles.header_titles_wrapper}>
                    {!!selectedContact?.phone_number && (
                      <Typography variant='s2' style={billingStyles.text}>
                        <ContactIcon style={{ marginRight: 8 }} />
                        {selectedContact?.phone_number}
                      </Typography>
                    )}
                    {!!selectedContact?.contact_email && (
                      <Typography variant='s2' style={billingStyles.text}>
                        <MailIcon style={{ marginRight: 8 }} />
                        {selectedContact?.contact_email}
                      </Typography>
                    )}
                  </div>
                )}
            </div>
            <Typography style={{ width: '20%' }} variant='s2' className={styles.paymentTerm}>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <FastField
                    name='payment_term_id'
                    disabled={disabled}
                    options={invoicePayTermsOptions}
                    dropDownStyles={{ width: '300px' }}
                    styles={{ height: 32 }}
                    component={CustomSelect}
                    shouldUpdate={() => true}
                  />
                </div>
              </div>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingChargesHeader;
