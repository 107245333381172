import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { acceptAward } from 'Api/OfferedShipments';
import { SAcceptAward } from './AcceptAward.styles';

const AcceptAward = ({ shipment, onSuccess }) => {
  const showToaster = useShowToaster();
  const { dot, token, type } = useParams();
  const [loadingAwardSubmit, setLoadingAwardSubmit] = useState(false);

  const { bid } = shipment || {};

  const handleAwardSubmit = async (acceptance) => {
    setLoadingAwardSubmit(true);
    try {
      const body = {
        bid_id: bid.id,
        acceptance,
      };
      await acceptAward(body, { dot, token, type });
      showToaster({
        type: 'success',
        message: `Award has been successfully ${acceptance === 4 ? 'accepted' : 'declined'}!`,
      });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAwardSubmit(false);
    }
  };

  return (
    <SAcceptAward>
      <div className='d-flex flex-column align-items-center'>
        <Typography variant='button2' style={{ color: palette.green500 }}>
          Shipment Awarded! Your shipment rate has been accepted. Please accept the award to receive shipment
          confirmation.
        </Typography>
        <div className='w-100 d-flex justify-content-end gap-2 mt-5'>
          <CustomButton
            type='secondary'
            title='Decline Award'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={() => handleAwardSubmit(5)}
            disabled={loadingAwardSubmit}
          />
          <CustomButton
            type='primary'
            title='Accept Award'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={() => handleAwardSubmit(4)}
            disabled={loadingAwardSubmit}
          />
        </div>
      </div>
    </SAcceptAward>
  );
};

export default AcceptAward;
