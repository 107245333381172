import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';

export const SWrapper = styled.div`
  padding: 8px 16px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 2%), 0 0 0 1px rgb(70 79 96 / 2%), 0 2px 5px rgb(89 96 120 / 30%);
  border-radius: 8px;
`;

export const SAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    .MuiAccordionSummary-root {
      min-height: auto;
      width: fit-content;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 0 12px 0 0;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
`;
