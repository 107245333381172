import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import uuid from 'react-uuid';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import classNames from 'classnames';
import sm from './CustomSelct.module.css';

const Input = React.forwardRef(({ value, onChange, styles }, ref) => {
  const { use } = useTheme();
  return (
    <input
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.keyCode === 13) e.preventDefault();
      }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        ...styles,
      }}
      className={sm.search_input_custom_input}
    />
  );
});

const CustomSelect = ({
  field,
  form,
  valueMultiArr,
  styles,
  menuStyles,
  deleteBtnStyle,
  deleteWidthCondition,
  onDelete,
  deleted,
  options,
  searchOptions,
  onChange,
  defaultActiveValue,
  isSearchable,
  isSearchInValue = '',
  onAddStopPoint,
  footer,
  dropDownStyles,
  isSearchableStyles,
  disabled = false,
  multi,
  defaultText = '',
  selectFirst = false,
  required = false,
  allControlOutSideForm = false,
  label,
  labelStyle,
  keyName,
  labelName,
  img = false,
  imgName,
  imgStyle,
  imgWrapperStyle,
  imgDefault,
  onDeleteItem,
  classNamesSelectedLabel,
  ...props
}) => {
  const { use } = useTheme();
  const KEY_NAME = keyName || 'key';
  const LABEL_NAME = labelName || 'label';
  const IMG_NAME = imgName || 'img';

  const [active, setActive] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [search, setSearch] = useState('');

  const onToggle = (show) => {
    setTimeout(() => {
      setIsVisible(show);
      !isVisible && setSearch('');
    }, 0);
  };

  const selectStyles = useMemo(() => {
    return {
      onAddStopPoint: {
        textAlign: 'start',
        display: 'block',
        marginTop: 4,
        marginLeft: 2,
        cursor: 'pointer',
        color: use(palette.indigo500, palette.indigo500),
      },
    };
  }, [use, palette]);

  const data = useMemo(() => {
    const actualOptions = searchOptions && search ? searchOptions : options;
    return (
      actualOptions &&
      Array.isArray(actualOptions) &&
      actualOptions?.filter((el) => {
        if (isSearchInValue) {
          return !search ? true : el[isSearchInValue]?.toLowerCase().includes(search?.toLowerCase());
        }
        return !search ? true : el?.[LABEL_NAME]?.toLowerCase().includes(search?.toLowerCase());
      })
    );
  }, [options, searchOptions, search, isSearchInValue]);

  const toggleValue = (value) => {
    const newValue = Array.isArray(field?.value) ? [...field.value] : [field?.value];
    if (newValue.includes(value)) {
      const index = newValue.findIndex((val) => val === value);
      newValue.splice(index, 1);
    } else {
      newValue.push(value);
    }
    form && form.setFieldValue(field.name, newValue);
  };

  const onSelectHandler = (value) => {
    const el = data.find((d) => String(d?.[KEY_NAME]) === String(value));
    if (multi) {
      !allControlOutSideForm && toggleValue(value);
      if (typeof onChange === 'function') {
        const cond = onChange(el);
        setTimeout(() => {
          !!cond && setActive({});
          setIsVisible(cond);
        }, 1);
      }
    } else {
      if (typeof onChange === 'function') {
        const cond = onChange(el);
        setTimeout(() => {
          !!cond && setActive({});
          setIsVisible(cond);
        }, 1);
      }
      form && !allControlOutSideForm && form.setFieldValue(field.name, value);
    }
  };

  const onDeleteHandler = (e) => {
    e.stopPropagation();
    typeof onDelete === 'function' && onDelete(e, active);
    setActive({});
  };

  useEffect(() => {
    if (selectFirst) {
      const firstOption = options?.[0];
      if (firstOption && firstOption?.[KEY_NAME] !== active?.[KEY_NAME]) {
        form && form.setFieldValue(field.name, firstOption?.[KEY_NAME]);
        setActive(firstOption);
        onChange(firstOption);
      }
    } else if (Array.isArray(options) && options?.length > 0) {
      const activeOption = [...options, ...(searchOptions || [])].find((option) => {
        if (option && option?.[KEY_NAME] !== undefined) {
          return String(option?.[KEY_NAME]) === String(field?.value);
        }
        return false;
      });
      field !== undefined && setActive(activeOption || '');
    } else {
      setActive('');
    }
  }, [options, field?.value, selectFirst]);

  useEffect(() => {
    if (multi) {
      const fieldInitialValue = Array.isArray(field?.value)
        ? [...(field?.value || [])]
        : field?.value
        ? [field?.value]
        : [];
      form && form.setFieldValue(field.name, fieldInitialValue);
    }
  }, []);

  useEffect(() => {
    if (deleteWidthCondition) setActive({});
  }, [deleteWidthCondition]);

  useEffect(() => {
    !form && setActive(defaultActiveValue);
  }, [defaultActiveValue]);

  return (
    <Dropdown
      show={isVisible}
      onToggle={onToggle}
      onClick={props?.onClick}
      style={{ ...dropDownStyles }}
      className={sm.dropdownS}
      onSelect={onSelectHandler}
      autoClose={multi ? 'outside' : true}
    >
      <div name={field?.name} style={{ display: 'flex', alignItems: 'flex-end' }}>
        {!!label && (
          <Typography
            variant='s2'
            style={{
              ...labelStyle,
              marginLeft: 0,
              marginBottom: 3,
              color: use(palette.gray700, palette.gray200),
            }}
          >
            {label}
          </Typography>
        )}
        {!!required && <span className={sm.select_required}>*</span>}
      </div>
      <Dropdown.Toggle
        as={isVisible && isSearchable ? Input : Button}
        value={search}
        onChange={(value) => setSearch(value)}
        className={sm.dropdown_toggle}
        styles={isSearchableStyles}
        disabled={disabled}
        style={{
          color: use(palette.gray700, palette.gray200),
          backgroundColor: use(palette.white, palette.dark800),
          ...styles,
        }}
      >
        {active?.labelSelected ? (
          <Typography
            variant='s2'
            className={classNames([sm.address_length, classNamesSelectedLabel])}
            style={{ maxWidth: styles?.maxWidth && Number(styles?.maxWidth) - 30 }}
          >
            {multi ? defaultText || 'Select...' : active.labelSelected}
          </Typography>
        ) : active?.[LABEL_NAME] ? (
          <Typography variant='s2' className={classNames([sm.address_length, classNamesSelectedLabel])}>
            {multi ? defaultText || 'Select...' : active?.[LABEL_NAME]}
          </Typography>
        ) : (
          <Typography
            variant='s2'
            style={{
              color: use(palette.gray700, palette.gray200),
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {defaultText || 'Select...'}
          </Typography>
        )}

        <div className={sm.arrows_wrapper}>
          {!!deleted && (
            <div className={sm.delete_container} style={{ ...deleteBtnStyle }} onClick={onDeleteHandler}>
              <DeleteIcon />
            </div>
          )}
          <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        align={{ placement: 'end' }}
        className={`${sm.customSelect_menu} ${isSearchable ? sm.isSearchable : ''}
                ${data?.length === 0 ? sm.noShadow : ''}
                `}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          ...menuStyles,
        }}
      >
        <div className={sm.dropdown_menu_scroll_wrapper}>
          {!!onAddStopPoint && typeof onAddStopPoint === 'function' && (
            <Typography
              variant='s2'
              className={sm.add_stop_point_wrapper}
              style={selectStyles.onAddStopPoint}
              onClick={() => {
                setIsVisible(false);
                onAddStopPoint();
              }}
            >
              <PlusIcon /> Add Stop Point
            </Typography>
          )}

          {data && data.length > 0 ? (
            data?.map((el) => {
              const key = el?.[KEY_NAME];
              const label = el?.[LABEL_NAME];
              return (
                <Dropdown.Item
                  key={uuid()}
                  eventKey={key}
                  className={`${sm.menu_items}
                                            ${use(sm.light, sm.dark)}
                                            ${
                                              multi
                                                ? field?.value?.includes(String(key))
                                                  ? sm.active
                                                  : ''
                                                : key === active?.[KEY_NAME]
                                                ? sm.active
                                                : ''
                                            }
                                            ${el?.highlighted ? sm.highlighted : ''}`}
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    backgroundColor: !!multi && use(palette.white, palette.dark800),
                  }}
                  active={multi ? field?.value?.includes(String(key)) : key === active?.[KEY_NAME] ? true : undefined}
                  onClick={() => {
                    !!multi &&
                      setTimeout(() => {
                        setIsVisible(true);
                      }, 5);
                    setActive(el);
                  }}
                >
                  {multi ? (
                    <CustomCheckbox
                      checked={
                        field?.value
                          ? field?.value?.includes(String(key))
                          : Array.isArray(valueMultiArr)
                          ? valueMultiArr.includes(el?.[KEY_NAME])
                          : false
                      }
                    >
                      <div className={sm.checkbox_wrapper_multi}>
                        {img && (
                          <div className={sm.img_wrapper} style={{ marginLeft: 8, ...imgWrapperStyle }}>
                            <img src={el[IMG_NAME] ? el[IMG_NAME] : imgDefault} alt='img' style={{ ...imgStyle }} />
                          </div>
                        )}
                        <Typography
                          variant='s2'
                          style={{
                            color: key === active?.[KEY_NAME] && use(palette.gray700, palette.gray200),
                            marginLeft: 8,
                          }}
                        >
                          {label}
                        </Typography>
                      </div>
                    </CustomCheckbox>
                  ) : (
                    <div className={sm.checkbox_wrapper}>
                      {img && (
                        <div
                          onClick={(e) => onDeleteItem(e, el)}
                          className={sm.img_wrapper}
                          style={{ marginRight: 8, ...imgWrapperStyle }}
                        >
                          <img alt='img' src={el[IMG_NAME] ? el[IMG_NAME] : imgDefault} style={{ ...imgStyle }} />
                        </div>
                      )}
                      <Typography
                        variant='s2'
                        style={{
                          color: key === active?.[KEY_NAME] ? palette.white : use(palette.gray700, palette.gray200),
                        }}
                      >
                        {label}
                      </Typography>
                    </div>
                  )}
                </Dropdown.Item>
              );
            })
          ) : (
            <Dropdown.Item
              className={`${sm.menu_items} ${use(sm.light, sm.dark)} ${sm.highlighted}`}
              style={{
                color: use(palette.gray700, palette.gray200),
                backgroundColor: !!multi && use(palette.white, palette.dark800),
              }}
            >
              <Typography
                variant='s2'
                style={{
                  textAlign: 'center',
                  color: use(palette.gray700, palette.gray200),
                }}
              >
                No Data available
              </Typography>
            </Dropdown.Item>
          )}
        </div>
        {!!footer && (
          <Dropdown.Header
            key='input'
            className={[sm.menu_items, sm.menu_items_footer].join(' ')}
            style={{ color: use(palette.gray700, palette.gray200) }}
          >
            {footer}
          </Dropdown.Header>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomSelect.defaultProps = {
  onChange: () => {},
  keyName: 'key',
  labelName: 'label',
};

export default React.memo(CustomSelect);
