import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';

import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import CustomButton from 'components/CustomButton/CustomButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import classes from './documents.module.css';
import { validationSchema } from './validationSchema';

const AddDocumentModal = ({ isOpen, setIsOpen, onSubmit, initialValueOutSide }) => {
  const { use } = useTheme();

  function onSubmitHandler(validateForm, resetForm, submitForm, values) {
    onSubmit(values, validateForm, resetForm, submitForm);
  }

  function onDrop(acceptedFiles, name, setFiledValue) {
    setFiledValue(name, acceptedFiles[0]);
  }

  function onDelete(e, setFieldValue, name) {
    e.stopPropagation();
    setFieldValue(name, '');
  }

  function cancel(resetForm) {
    setIsOpen(false);
    resetForm();
  }

  const styles = useMemo(() => {
    return {
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      labelInputs: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      add: {
        padding: '2px 8px',
        marginRight: 0,
        marginTop: 0,
      },
      cancel: {
        padding: '2px 8px',
        marginTop: 0,
      },
      modalBody: {
        maxHeight: 500,
        overflowY: 'auto',
        padding: '20px',
        width: 500,
      },
      modalFooter: {
        backgroundColor: use(palette.white, palette.dark900),
      },
      dropZone: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  }, [use, palette]);

  const defaultValues = {
    doc: '',
    name: '',
    expire_date: null,
    no_expiry: false,
  };

  const initialValues = useMemo(() => {
    return initialValueOutSide ? { ...initialValueOutSide } : { ...defaultValues };
  }, [initialValueOutSide]);

  return (
    <div>
      <Formik onSubmit={() => {}} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, resetForm, submitForm, validateForm, setFieldValue, touched, errors, handleBlur }) => {
          return (
            <Form>
              <ModalWrapper
                width={500}
                isOpen={isOpen}
                title={initialValueOutSide ? 'Update Document' : 'Add Document'}
                styleBody={styles.modalBody}
                onHide={() => setIsOpen(false)}
                footer={
                  <div style={styles.modalFooter} className={classes.modalFooterLane}>
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => cancel(resetForm)}
                      styleButton={styles.cancel}
                    />
                    <CustomButton
                      type='primary'
                      styleButton={styles.add}
                      title={initialValueOutSide ? 'Update Document' : 'Add Document'}
                      onClick={() => onSubmitHandler(validateForm, resetForm, submitForm, values)}
                    />
                  </div>
                }
              >
                <div className={classes.errorWrapper}>
                  <Field
                    required
                    label='Document Name'
                    name='name'
                    component={CustomInput}
                    className={classes.addDocInputs}
                    labelStyle={styles.labelInputs}
                    disabled={!!values.type && !!values.name}
                  />
                  <ErrorMessage error={touched.name && errors.name} />
                </div>

                <div className='d-flex flex-column mb-1'>
                  <Typography variant='s2'>
                    Expiry Date
                    {!values.no_expiry && <span className={classes.required}>*</span>}
                  </Typography>
                  <Field name='expire_date'>
                    {() => (
                      <DatePicker
                        name='expire_date'
                        value={values?.expire_date}
                        onBlur={handleBlur}
                        onChange={(val) => setFieldValue(`expire_date`, val)}
                        disabled={
                          !!values?.no_expiry ||
                          ['master_agreement', 'w9', 'authority', 'reference_sheet'].includes(values.type)
                        }
                        disablePast
                        error={touched.expire_date && errors.expire_date}
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <CustomCheckbox
                    name='no_expiry'
                    checked={!!values.no_expiry}
                    disabled={['master_agreement', 'w9', 'authority', 'reference_sheet'].includes(values.type)}
                    onChange={(checked) => {
                      setFieldValue('no_expiry', checked);
                      if (checked) {
                        setFieldValue(`expire_date`, null);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      No Expiration
                    </Typography>
                  </CustomCheckbox>
                </div>

                <div className={classes.fileWrapper}>
                  <Typography variant='s2'>
                    Upload Document
                    <span className={classes.required}>*</span>
                  </Typography>
                  <div
                    className={classes.modalAddDocumentUpload}
                    style={{ backgroundColor: use(palette.white, palette.dark800) }}
                  >
                    <Field name='doc'>
                      {() => (
                        <ImageDropZone
                          width='100%'
                          height='100%'
                          maxSize={1000000}
                          deletable={false}
                          name='doc'
                          deletedComponent
                          accept={['application/pdf']}
                          customStyle={styles.dropZone}
                          onDrop={(a, n) => onDrop(a, n, setFieldValue)}
                        >
                          <div className={classes.dropZoneContent}>
                            <UploadIcon />

                            {values?.doc ? (
                              <div className={classes.uploadedFiles}>
                                <TickIcon fill={palette.green500} />
                                <Typography variant='s2' style={{ color: palette.green500 }}>
                                  File Uploaded
                                </Typography>
                                <div onClick={(e) => onDelete(e, setFieldValue, 'doc')}>
                                  <DeleteIcon fill={palette.indigo500} />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <Typography variant='s2'>
                                  <Typography style={{ color: palette.indigo500 }}>Upload document&nbsp;</Typography>
                                  or drag and drop
                                </Typography>
                              </div>
                            )}
                          </div>
                        </ImageDropZone>
                      )}
                    </Field>
                  </div>
                  <ErrorMessage error={touched.doc && errors.doc} />
                </div>
              </ModalWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddDocumentModal;
