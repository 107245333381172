import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 0 0 1px rgb(70 79 96 / 16%), 0 2px 5px rgb(89 96 120 / 10%);
  border-radius: 6px;
  margin: ${({ $margin }) => $margin || ''};

  .search-icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;
