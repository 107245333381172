import React from 'react';
import Popover from '@mui/material/Popover';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import style from '../../DialogueModals/ProfileAlert/AlertIcon.module.css';

const AlertPopover = ({ open, anchorEl, onClose, data, title = 'Equipment Alerts' }) => {
  const { use } = useTheme();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={style.AlertHeader} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <p className={style.ExpiringItems} style={{ color: use(palette.dark800, palette.gray200) }}>
          {title}
          <span className={style.OrangeAlertCounting}>{data?.alerts?.length}</span>
        </p>
      </div>
      <div className={style.AlertBody} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        {data?.alerts?.map((item) => (
          <p className={style.AlertMsg} key={item} style={{ color: use(palette.dark800, palette.gray200) }}>
            {item}
          </p>
        ))}
      </div>
    </Popover>
  );
};

export default AlertPopover;
