import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { formatNumber, palette } from 'utils/constants';

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray500);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
};

const createBoxes = (doc) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setDrawColor(23, 28, 38);

  // Left Box 1

  doc.line(10, 26, pageWidth / 2 - 4, 26);
  doc.line(10, 34, pageWidth / 2 - 4, 34);
  doc.line(10, 42, pageWidth / 2 - 4, 42);
  doc.line(10, 50, pageWidth / 2 - 4, 50);
  doc.line(10, 58, pageWidth / 2 - 4, 58);
  doc.line(10, 66, pageWidth / 2 - 4, 66);
  doc.line(10, 26, 10, 66);
  doc.line(pageWidth / 2 - 4, 26, pageWidth / 2 - 4, 66);
  doc.line(56, 26, 56, 34);
  doc.line(66, 50, 66, 66);
  doc.line(80, 50, 80, 66);

  // Right box 1

  doc.line(pageWidth / 2 + 4, 26, pageWidth - 10, 26);
  doc.line(pageWidth / 2 + 4, 34, pageWidth - 10, 34);
  doc.line(pageWidth / 2 + 4, 42, pageWidth - 10, 42);
  doc.line(pageWidth / 2 + 4, 50, pageWidth - 10, 50);
  doc.line(pageWidth / 2 + 4, 58, pageWidth - 10, 58);
  doc.line(pageWidth / 2 + 4, 66, pageWidth - 10, 66);
  doc.line(pageWidth / 2 + 4, 26, pageWidth / 2 + 4, 66);
  doc.line(pageWidth - 10, 26, pageWidth - 10, 66);
  doc.line(166, 50, 166, 58);
  doc.line(180, 50, 180, 58);

  // Left Box 2
  doc.line(10, 68, pageWidth / 2 - 4, 68);
  doc.line(10, 76, pageWidth / 2 - 4, 76);
  doc.line(10, 84, pageWidth / 2 - 4, 84);
  doc.line(10, 92, pageWidth / 2 - 4, 92);
  doc.line(10, 100, pageWidth / 2 - 4, 100);
  doc.line(10, 68, 10, 100);
  doc.line(pageWidth / 2 - 4, 68, pageWidth / 2 - 4, 100);
  doc.line(66, 92, 66, 100);
  doc.line(80, 92, 80, 100);

  // Right box 2
  doc.line(pageWidth / 2 + 4, 68, pageWidth - 10, 68);
  doc.line(pageWidth / 2 + 4, 76, pageWidth - 10, 76);
  doc.line(pageWidth / 2 + 4, 84, pageWidth - 10, 84);
  doc.line(pageWidth / 2 + 4, 92, pageWidth - 10, 92);
  doc.line(pageWidth / 2 + 4, 100, pageWidth - 10, 100);

  doc.line(pageWidth / 2 + 4, 68, pageWidth / 2 + 4, 100);
  doc.line(pageWidth - 10, 68, pageWidth - 10, 100);
  doc.line(144, 68, 144, 76);
  doc.line(174, 68, 174, 76);

  // Contact Box
  doc.setLineWidth(0.4);
  doc.line(10, 102, pageWidth - 10, 102);
  doc.line(10, 134, pageWidth - 10, 134);
  doc.line(10, 102, 10, 134);
  doc.line(pageWidth - 10, 102, pageWidth - 10, 134);
  doc.setFillColor(134, 143, 160);
  doc.rect(10.2, 107, pageWidth - 20.4, 3, 'F');
  doc.setLineWidth(0.3);
  doc.line(pageWidth / 2, 107, pageWidth / 2, 134);
  doc.setLineWidth(0.2);
  doc.line(10, 126, pageWidth / 2, 126);

  // COD Fee
  doc.setLineWidth(0.15);
  doc.line(10, 136, pageWidth - 10, 136);
  doc.line(10, 143, pageWidth - 10, 143);
  doc.line(10, 152, pageWidth - 10, 152);
};

const addCheckmarks = (doc, data, notesTop, emergencyTop) => {
  const {
    guaranteedStandard,
    guaranteedMultiday,
    CODFee,
    collect,
    noDeliveryDate,
    deliverOn,
    deliveryBy,
    driverByTime,
    CODCheck,
    trailerLoadedBy,
    freightCountedBy,
    pickUpDetails,
  } = data;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setPage(1);
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 700);

  if (guaranteedStandard) {
    doc.text(`✓`, 13, 113);
  }
  if (guaranteedMultiday) {
    doc.text(`✓`, 13, 118);
  }
  if (deliveryBy) {
    doc.text(`✓`, pageWidth / 2 + 3, 113);
  }
  if (driverByTime === 1 && deliveryBy) {
    doc.text(`✓`, pageWidth / 2 + 7, 116);
  }
  if (driverByTime === 2 && deliveryBy) {
    doc.text(`✓`, pageWidth / 2 + 24, 116);
  }
  if (deliverOn) {
    doc.text(`✓`, pageWidth / 2 + 3, 121);
  }
  if (noDeliveryDate) {
    doc.text(`✓`, pageWidth / 2 + 3, 129);
  }
  if (collect) {
    doc.text(`✓`, 152, emergencyTop + 7);
  }
  if (CODFee === 1) {
    doc.text(`✓`, 30, 140);
  }
  if (CODFee === 2) {
    doc.text(`✓`, 47, 140);
  }
  if (CODCheck === 1) {
    doc.text(`✓`, 185, 140);
  }
  if (CODCheck === 2) {
    doc.text(`✓`, 197, 140);
  }

  if (pickUpDetails.length > 2) {
    doc.setPage(2);
  }

  if (trailerLoadedBy === 1) {
    doc.text(`✓`, 96, notesTop + 73);
  }
  if (trailerLoadedBy === 2) {
    doc.text(`✓`, 110, notesTop + 73);
  }
  if (freightCountedBy === 1) {
    doc.text(`✓`, 156, notesTop + 69);
  }
  if (freightCountedBy === 2) {
    doc.text(`✓`, 172, notesTop + 69);
  }
  if (freightCountedBy === 3) {
    doc.text(`✓`, 130, notesTop + 73);
  }
  if (freightCountedBy === 4) {
    doc.text(`✓`, 172, notesTop + 73);
  }
};

const createHeader = (doc, data, company) => {
  const { blNumber, ebolDate } = data;
  const { company_name, phone_number } = company || {};

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(14);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`SCAC: ${company_name}`, pageWidth / 2, 8, { align: 'center' });

  doc.setFontSize(10);
  doc.text('Web straight bill of lading—original—not negotiable', pageWidth / 2, 13, { align: 'center' });

  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray700);
  doc.text(`For shipping information please call ${phone_number}`, pageWidth / 2, 17, { align: 'center' });

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(blNumber || `474-246590-8`, pageWidth - 29, 6, { align: 'center' });
  doc.addImage(getBarcode(blNumber || '474-246590-8', false), 'JPEG', pageWidth - 50, 6, 40, 12);

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);

  doc.text(`Date: `, 12, 23);
  doc.text(`B/L number:`, pageWidth / 2 + 6, 23);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`${ebolDate}`, 20, 23.2);
  doc.text(`${blNumber}`, pageWidth / 2 + 22, 23.2);
};

export const generateBillOfLading = async ({ data, shipment_id }) => {
  const company = await GetCompanyProfile();

  const {
    shipperNumber,
    trailerNumber,
    pickupLocationName,
    pickupAddress,
    pickupCity,
    pickupState,
    pickupZip,
    deliveryNumber,
    deliveryLocationName,
    deliveryAddress,
    deliveryCity,
    deliveryState,
    deliveryZip,
    invoice,
    customerAddress1,
    customerAddress2,
    customerCity,
    customerState,
    customerZipCode,
    customerNumber,
    storeNumber,
    department,
    poNumber,
    specialInstruction,
    timeFrom,
    timeTo,
    deliveryByDate,
    deliverOnDate,
    betweenFrom,
    betweenTo,
    CODAmount,
    pickUpDetails,
    note1,
    note2,
    trailerOrVehicleNumber,
    signDate,
    huReceived,
    carrier,
    phone,
    name,
    contract,
    totalCharges,
    contactName,
    contactPhone,
    guaranteedMultiday,
    deliveryBy,
    deliverOn,
  } = data;

  const { company_name: customer } = invoice || {};
  const { contact_name } = contactName || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  createHeader(doc, data, company);
  createBoxes(doc);

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);

  // Left Box 1
  doc.text(`Shipper Number:`, 12, 28.5);
  doc.text(`Trailer Number:`, 58, 28.5);
  doc.text(`Shipper Name:`, 12, 36.5);
  doc.text(`Address:`, 12, 44.5);
  doc.text(`City:`, 12, 52.5);
  doc.text(`State:`, 68, 52.5);
  doc.text(`ZIP Code:`, 82, 52.5);
  doc.text(`Origin City (if different than before):`, 12, 60.5);
  doc.text(`State:`, 68, 60.5);
  doc.text(`ZIP Code:`, 82, 60.5);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(shipperNumber || '', 12, 32);
  doc.text(trailerNumber || '', 58, 32);
  doc.text(pickupLocationName || '', 12, 40);
  doc.text(pickupAddress || '', 12, 48);
  doc.text(pickupCity || '', 12, 56);
  doc.text(pickupState || '', 68, 56);
  doc.text(pickupZip || '', 82, 56);

  // Right Box 1
  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.text(`Receiver Number:`, pageWidth / 2 + 6, 28.5);
  doc.text(`Receiver Name:`, pageWidth / 2 + 6, 36.5);
  doc.text(`Address:`, pageWidth / 2 + 6, 44.5);
  doc.text(`City:`, pageWidth / 2 + 6, 52.5);
  doc.text(`State:`, 168, 52.5);
  doc.text(`ZIP Code:`, 182, 52.5);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(deliveryNumber || '', pageWidth / 2 + 6, 32);
  doc.text(deliveryLocationName || '', pageWidth / 2 + 6, 40);
  doc.text(deliveryAddress || '', pageWidth / 2 + 6, 48);
  doc.text(deliveryCity || '', pageWidth / 2 + 6, 56);
  doc.text(deliveryState || '', 168, 56);
  doc.text(deliveryZip || '', 182, 56);

  // Left Box 2
  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.text(`Customer Invoice:`, 12, 70.5);
  doc.text(`Address:`, 12, 78.5);
  doc.text(`Address 2:`, 12, 86.5);
  doc.text(`City:`, 12, 94.5);
  doc.text(`State:`, 68, 94.5);
  doc.text(`ZIP Code:`, 82, 94.5);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(customer || '', 12, 74);
  doc.text(customerAddress1 || '', 12, 82);
  doc.text(customerAddress2 || '', 12, 90);
  doc.text(customerCity || '', 12, 98);
  doc.text(customerState || '', 68, 98);
  doc.text(customerZipCode || '', 82, 98);

  // Right Box 2
  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.text(`Customer Number:`, pageWidth / 2 + 6, 70.5);
  doc.text(`Store Number:`, 146, 70.5);
  doc.text(`Department:`, 176, 70.5);
  doc.text(`P.O. Number:`, pageWidth / 2 + 6, 78.5);
  doc.text(`Special Instructions:`, pageWidth / 2 + 6, 86.5);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(customerNumber || '', pageWidth / 2 + 6, 74);
  doc.text(storeNumber || '', 146, 74);
  doc.text(department || '', 176, 74);
  doc.text(poNumber || '', pageWidth / 2 + 6, 82);
  doc.text(specialInstruction || '', pageWidth / 2 + 6, 90);

  // Contact Box
  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.text(`Contact Name:`, 12, 105);
  doc.text(`Contact Phone:`, pageWidth / 2 + 6, 105);

  doc.setFontSize(7.5);
  doc.setFont('Inter', 'normal', 700);
  doc.text(contact_name || '', 31, 105);
  doc.text(contactPhone || '', pageWidth / 2 + 26, 105);

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.white);
  doc.text(`Standard Guaranteed`, 14, 109.3);
  doc.text(`Expedited Guaranteed`, pageWidth / 2 + 4, 109.3);

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);

  doc.setFillColor(23, 28, 38);
  doc.setLineWidth(0.2);
  doc.rect(13, 111, 2.2, 2.2);
  doc.text(`Guaranteed Standard Service by 5 p.m. or end of business day`, 17, 113);

  doc.rect(13, 116, 2.2, 2.2);
  if (guaranteedMultiday) {
    doc.text(
      `Guaranteed Multiday Window Between: ${timeFrom || '____ /____ /______'} & ${timeTo || '____ /____ /______'}`,
      17,
      118
    );
  } else {
    doc.text(`Guaranteed Multiday Window Between: ____ /____ /______ & ____ /____ /______`, 17, 118);
  }

  doc.rect(pageWidth / 2 + 3, 111, 2.2, 2.2);
  doc.text(
    `Time-Critical,  Delivery By ${deliveryBy && deliveryByDate ? deliveryByDate : '_____ /_____ /________'}`,
    pageWidth / 2 + 7,
    113
  );
  doc.rect(pageWidth / 2 + 7, 114, 2.2, 2.2);
  doc.text(`By Noon`, pageWidth / 2 + 11, 116);
  doc.rect(pageWidth / 2 + 24, 114, 2.2, 2.2);
  doc.text(`By 5 PM or end of business day`, pageWidth / 2 + 28, 116);

  doc.rect(pageWidth / 2 + 3, 119, 2.2, 2.2);
  doc.text(
    `Time-Critical Hour Window,    Deliver on ${deliverOn && deliverOnDate ? deliverOnDate : '_____ /_____ /________'}`,
    pageWidth / 2 + 7,
    121
  );
  if (deliverOn) {
    doc.text(`Between: ${betweenFrom || '_________'} & ${betweenTo || '_________'}`, pageWidth / 2 + 8, 124);
  } else {
    doc.text(` _________ & _________`, pageWidth / 2 + 8, 124);
  }

  doc.rect(pageWidth / 2 + 3, 127, 2.2, 2.2);
  doc.text(`Time-Critical (fastest ground delivery – no delivery date required)`, pageWidth / 2 + 7, 129);

  doc.setFontSize(11);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Quote ID:  ${shipment_id}`, 13, 131);

  // COD Fee
  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`COD Fee:`, 13, 140.5);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.rect(30, 138, 2.2, 2.2);
  doc.text(`Prepaid`, 33, 140.3);
  doc.rect(47, 138, 2.2, 2.2);
  doc.text(`Collect`, 50, 140.3);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`COD Amount: $${formatNumber(CODAmount)}`, 70, 140.5);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Customer check OK for COD amount?`, 123, 140.5);
  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.rect(185, 138, 2.2, 2.2);
  doc.text(`Yes`, 188, 140.3);
  doc.rect(197, 138, 2.2, 2.2);
  doc.text(`No`, 200, 140.3);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor(palette.gray900);
  doc.text(`Shipping units #`, 18, 146, { maxWidth: 16, align: 'center' });
  doc.text(`Kind of package`, 33, 146, { maxWidth: 16, align: 'center' });
  doc.text(`Qty #`, 46, 147, { maxWidth: 16, align: 'center' });
  doc.text(`Unit of measure`, 58, 146, { maxWidth: 16, align: 'center' });
  doc.text(`HM`, 71, 147, { maxWidth: 16, align: 'center' });
  doc.text(`Description of articles, special marks and exceptions`, 107, 146, { maxWidth: 50, align: 'center' });
  doc.text(`NMFC item #`, 144, 146, { maxWidth: 12, align: 'center' });
  doc.text(`Weight (lb)`, 160, 146, { align: 'center' });
  doc.text(`Subject to`, 160, 148.5, { align: 'center' });
  doc.text(`correction`, 160, 151, { align: 'center' });
  doc.text(`Shipment dimensions`, 188, 146, { align: 'center' });
  doc.text(`Length`, 176, 150, { align: 'center' });
  doc.text(`Width`, 188, 150, { align: 'center' });
  doc.text(`Height`, 200, 150, { align: 'center' });

  let totalUnits = 0;
  let totalQty = 0;
  let totalWeight = 0;

  pickUpDetails.forEach((item, index) => {
    const y = 156 + index * 6;
    doc.text(`${item.pickUpUnity || ''}`, 18, y, { maxWidth: 16, align: 'center' });
    doc.text(`${item.pickUpKind || ''}`, 33, y, { maxWidth: 16, align: 'center' });
    doc.text(`${item.pickUpQty || ''}`, 46, y, { maxWidth: 16, align: 'center' });
    doc.text(`${item.pickUpMeasure || ''}`, 58, y, { maxWidth: 16, align: 'center' });
    doc.text(`${item.pickUpHm ? 'YES' : 'NO'}`, 71, y, { maxWidth: 16, align: 'center' });
    doc.setFontSize(7);
    doc.text(`${item.pickUpDescription || ''}`, 107, y - 1.5, {
      maxWidth: 62,
      align: 'center',
    });
    doc.setFontSize(8);
    doc.text(`${item.pickUpNMFCItem || ''}`, 144, y, { align: 'center' });
    doc.text(`${item.pickUpWeight || ''}`, 160, y, { align: 'center' });
    doc.text(`${item.pickUpLength || ''}`, 176, y, { align: 'center' });
    doc.text(`${item.pickUpWidth || ''}`, 188, y, { align: 'center' });
    doc.text(`${item.pickUpHeight || ''}`, 200, y, { align: 'center' });
    doc.line(10, 158 + index * 6, pageWidth - 10, 158 + index * 6);

    totalUnits += Number(item.pickUpUnity) || 0;
    totalQty += Number(item.pickUpQty) || 0;
    totalWeight += Number(item.pickUpWeight) || 0;
  });

  const totalTop = 158 + (pickUpDetails.length - 1) * 6;
  doc.line(10, totalTop + 8, pageWidth - 10, totalTop + 8);
  doc.setFontSize(9);
  doc.text(`${totalUnits}`, 18, totalTop + 5, { maxWidth: 16, align: 'center' });
  doc.text(`${totalQty}`, 46, totalTop + 5, { maxWidth: 16, align: 'center' });
  doc.text(`GRAND TOTAL`, 77, totalTop + 5);
  doc.text(`${totalWeight}`, 160, totalTop + 5, { align: 'center' });

  doc.line(170, 147, pageWidth - 10, 147);
  doc.line(10, 136, 10, totalTop + 8);
  doc.line(26, 143, 26, totalTop + 8);
  doc.line(41, 143, 41, totalTop + 8);
  doc.line(51, 143, 51, totalTop + 8);
  doc.line(66, 143, 66, totalTop + 8);
  doc.line(76, 143, 76, totalTop + 8);
  doc.line(139, 143, 139, totalTop + 8);
  doc.line(150, 143, 150, totalTop + 8);
  doc.line(170, 143, 170, totalTop + 8);
  doc.line(182, 147, 182, totalTop + 8);
  doc.line(194, 147, 194, totalTop + 8);
  doc.line(pageWidth - 10, 143, pageWidth - 10, totalTop + 8);

  // Emergency Contact
  const emergencyTop = totalTop + 9;

  doc.line(10, emergencyTop, pageWidth - 10, emergencyTop);
  doc.line(10, emergencyTop + 10, pageWidth - 10, emergencyTop + 10);
  doc.line(10, emergencyTop, 10, emergencyTop + 10);
  doc.line(120, emergencyTop, 120, emergencyTop + 10);
  doc.line(170, emergencyTop, 170, emergencyTop + 10);
  doc.line(pageWidth - 10, emergencyTop, pageWidth - 10, emergencyTop + 10);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`EMERGENCY CONTACT`, 12, emergencyTop + 3);
  doc.setFontSize(7);
  doc.text(`Phone:`, 12, emergencyTop + 8);
  doc.text(`Name:`, 47, emergencyTop + 8);
  doc.text(`Contact #:`, 86, emergencyTop + 8);

  doc.text(phone || '', 21, emergencyTop + 8);
  doc.text(name || '', 56, emergencyTop + 8);
  doc.text(contract || '', 100, emergencyTop + 8);

  doc.text(`Shipment charges are prepaid,`, 122, emergencyTop + 3);
  doc.text(`unless marked collect:`, 122, emergencyTop + 7);
  doc.rect(152, emergencyTop + 5, 2.2, 2.2);
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`Collect`, 155, emergencyTop + 7);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Total Charges:`, 172, emergencyTop + 3);
  doc.text(`Total Charges:`, 172, emergencyTop + 3);
  doc.text('$', 172, emergencyTop + 8);

  doc.text(`${totalCharges}` || '', 175, emergencyTop + 8);

  if (pickUpDetails.length > 2) {
    doc.addPage();
    createHeader(doc, data, company);
  }

  // Notes
  const notesTop = pickUpDetails.length > 2 ? 27 : emergencyTop + 11;
  doc.line(10, notesTop, pageWidth - 10, notesTop);
  doc.line(10, notesTop + 9, pageWidth - 10, notesTop + 9);
  doc.line(10, notesTop + 15, pageWidth - 10, notesTop + 15);
  doc.line(10, notesTop + 23, pageWidth - 10, notesTop + 23);
  doc.line(10, notesTop + 32, pageWidth - 10, notesTop + 32);
  doc.line(10, notesTop + 38, pageWidth - 10, notesTop + 38);
  doc.line(10, notesTop + 55, pageWidth - 10, notesTop + 55);
  doc.line(10, notesTop + 61, pageWidth - 10, notesTop + 61);
  doc.line(10, notesTop + 75, pageWidth - 10, notesTop + 75);
  doc.line(10, notesTop + 82, pageWidth - 10, notesTop + 82);
  doc.line(10, notesTop + 89, pageWidth - 10, notesTop + 89);

  doc.line(94, notesTop + 66, pageWidth - 10, notesTop + 66);

  doc.line(162, notesTop + 23, 162, notesTop + 32);
  doc.line(pageWidth / 2, notesTop + 38, pageWidth / 2, notesTop + 55);

  doc.line(56, notesTop + 61, 56, notesTop + 75);
  doc.line(94, notesTop + 61, 94, notesTop + 75);
  doc.line(128, notesTop + 61, 128, notesTop + 75);
  doc.line(158, notesTop + 61, 158, notesTop + 66);
  doc.line(pageWidth / 2, notesTop + 75, pageWidth / 2, notesTop + 89);

  doc.line(10, notesTop, 10, notesTop + 89);
  doc.line(pageWidth - 10, notesTop, pageWidth - 10, notesTop + 89);

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Note 1:`, 12, notesTop + 3);
  doc.text(`Note 2:`, 12, notesTop + 12.6);
  doc.text(`Note 3:`, 12, notesTop + 18);

  doc.setFontSize(6.5);
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    `Where the rate depends on value, shippers must state specifically in writing the agreed or declared value of the property as follows: The agreed or declared value of`,
    22,
    notesTop + 3
  );
  doc.text(
    `the property is hereby specifically stated by the shipper to be not exceeding ${
      note1 || '___________________________'
    } per ${note2 || '___________________________'}`,
    22,
    notesTop + 7
  );
  doc.text(
    `Liability limitation for loss or damage on this shipment may be applicable. See 49 U.S.C. §14706 (c)(1)(A) and (B).`,
    22,
    notesTop + 12.6
  );
  doc.text(
    `Products requiring special or additional care or attention in handling or stowing must be so marked and packaged as to ensure safe transportation with ordinary care.
See Sec. 2(e) of NMFC item 360.`,
    22,
    notesTop + 18
  );

  doc.text(
    `If this shipment is to be delivered to the consignee without recourse on the consignor, the consignor shall sign the following statement:`,
    12,
    notesTop + 25.3
  );
  doc.text(
    `The carrier may decline to make delivery of this shipment without payment of freight and all other lawful charges.`,
    12,
    notesTop + 29
  );
  doc.text(`Signature of consignor`, 164, notesTop + 25.3);

  doc.text(
    `Received subject to individually determined rates or written contracts that have been agreed on in writing between the carrier and shipper, if applicable, otherwise to rates, classifications and rules that have been established by the carrier and are available to the shipper on request.`,
    12,
    notesTop + 34.3,
    { maxWidth: pageWidth - 20 }
  );
  doc.text(
    `The property described above is in apparent good order, except as noted (contents and condition of contents of packages unknown), marked, consigned, and destined, as indicated above which said carrier (the word carrier being understood throughout this contract as meaning any person or corporation in possession of the property under the contract) agrees to carry its usual place of delivery of said destination, if on its route, otherwise to deliver to another carrier on the route to said destination.`,
    12,
    notesTop + 40.3,
    { maxWidth: pageWidth / 2 - 14 }
  );
  doc.text(
    `It is mutually agreed as to each carrier of all or any of said property over all or any portion of said route to destination and as to each party at any time interested in all or any of said property, that every service to be performed hereunder shall be subject to all the conditions not prohibited by law, whether printed or written, herein contained, including the conditions on the back hereof, which are hereby agreed to by the shipper and accepted for himself and his assigns.`,
    pageWidth / 2 + 2,
    notesTop + 40.3,
    { maxWidth: pageWidth / 2 - 14 }
  );
  doc.text(
    `I hereby declare that the contents of this consignment are fully and accurately described above by the proper shipping name and are classified, packaged, marked and
labeled/placarded and are in all respects in proper condition for transport according to applicable international and national governmental regulations.`,
    12,
    notesTop + 57.3
  );

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Shipper Company Name`, 12, notesTop + 63.5);
  doc.text(`Carrier`, 58, notesTop + 63.5);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`Trailer #:`, 96, notesTop + 64);
  doc.text(`Date:`, 130, notesTop + 64);
  doc.text(`H/U received:`, 160, notesTop + 64);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`${pickupLocationName || ''}`, 12, notesTop + 68, { maxWidth: 42 });
  doc.text(`${carrier || ''}`, 58, notesTop + 68, { maxWidth: 38 });

  doc.setFontSize(7);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`${trailerOrVehicleNumber}`, 108, notesTop + 64);
  doc.text(`${signDate}`, 138, notesTop + 64);
  doc.text(`${huReceived}`, 178, notesTop + 64);

  doc.setFont('Inter', 'normal', 700);
  doc.text(`Trailer Loaded By:`, 96, notesTop + 69);
  doc.setFont('Inter', 'normal', 400);
  doc.rect(96, notesTop + 71, 2.2, 2.2);
  doc.text(`Shipper`, 99, notesTop + 73);
  doc.rect(110, notesTop + 71, 2.2, 2.2);
  doc.text(`Driver`, 113, notesTop + 73);

  doc.setFont('Inter', 'normal', 700);
  doc.text(`Freight Counted By:`, 130, notesTop + 69);
  doc.setFont('Inter', 'normal', 400);
  doc.rect(156, notesTop + 67, 2.2, 2.2);
  doc.text(`Shipper`, 159, notesTop + 69);
  doc.rect(172, notesTop + 67, 2.2, 2.2);
  doc.text(`Driver: Loose pieces`, 175, notesTop + 69);
  doc.rect(130, notesTop + 71, 2.2, 2.2);
  doc.text(`Driver: pallets said to contain`, 133, notesTop + 73);
  doc.rect(172, notesTop + 71, 2.2, 2.2);
  doc.text(`Driver: pallets containing`, 175, notesTop + 73);

  doc.setFont('Inter', 'normal', 700);
  doc.text(`Shipper First, Last Name`, 12, notesTop + 77.5);
  doc.text(`Receiver First, Last Name`, pageWidth / 2 + 2, notesTop + 77.5);

  doc.setFont('Inter', 'normal', 700);
  doc.text(`Shipper Signature`, 12, notesTop + 84.5);
  doc.text(`Receiver Signature`, pageWidth / 2 + 2, notesTop + 84.5);

  addCheckmarks(doc, data, notesTop, emergencyTop);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
  }

  const url = doc.output('datauristring');
  const blob = doc.output('blob');

  return { blob, url };
};
