import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getEquipmentCounts } from 'Api/Dashboard';
import { SCountBox } from '../../../Dashboard.styles';

const EquipmentCount = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [equipmentCount, setEquipmentCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCounts = async () => {
    setLoading(true);
    try {
      const { data } = await getEquipmentCounts();
      setEquipmentCount(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <SCountBox onClick={() => navigate('/equipment')}>
      <Typography variant='s2' style={{ color: use(palette.gray500) }}>
        Equipment
      </Typography>
      <div className='d-flex gap-5'>
        <div className='d-flex flex-column'>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '54px', height: '32px' }} />
          ) : (
            <Typography variant='h1'>{equipmentCount?.vehicle_count}</Typography>
          )}
          <Typography variant='c1' style={{ color: use(palette.gray500) }}>
            Vehicles
          </Typography>
        </div>
        <div className='d-flex flex-column'>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '54px', height: '32px' }} />
          ) : (
            <Typography variant='h1'>{equipmentCount?.trailer_count}</Typography>
          )}
          <Typography variant='c1' style={{ color: use(palette.gray500) }}>
            Trailers
          </Typography>
        </div>
      </div>
    </SCountBox>
  );
};

export default EquipmentCount;
