import { array, number, object, string } from 'yup';

const youtubeRegex = /^https:\/\/www\.youtube\.com\/watch\?v=.+/;

export const validationSchema = object().shape({
  title: string().trim().required('Required').nullable(),
  category_id: number().required('Required').nullable(),
  video: string().trim().matches(youtubeRegex, 'Enter a valid URL'),
  description: string().trim().required('Required').nullable(),
  visible_to_id: array()
    .of(
      object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable()
    )
    .min(1, 'Required'),
});
