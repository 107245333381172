import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'common/Modal';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { STable } from 'pages/Accounting/Receivables/Receivables.styles';

const BillingCharges = ({ open, onClose, items }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Billing Charges'
      $bgColor={palette.gray0}
      $maxWidth='1000px'
      $minWidth='1000px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
        },
      ]}
    >
      <div>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>DESCRIPTION</th>
              <th>QUANTITY</th>
              <th>RATE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {(items || []).map((item) => (
              <tr key={item.id}>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    {item.desc || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    {item.quantity}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    {item.price}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(item.total_amount)}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!items?.length && <NoRecords />}
      </div>
    </Modal>
  );
};

export default BillingCharges;
