import get from 'lodash/get';

export const filterVendors = (vendors, { search, sort, hideInactive }) => {
  return vendors
    .filter((item) => item.name?.toLowerCase()?.includes(search.toLowerCase()) && (item.active === 1 || !hideInactive))
    .sort((a, b) => {
      if (get(a, sort.field) > get(b, sort.field)) {
        return sort.sortBy === 'asc' ? 1 : -1;
      }
      if (get(a, sort.field) < get(b, sort.field)) {
        return sort.sortBy === 'asc' ? -1 : 1;
      }
      return 0;
    });
};
