import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { validationSchema } from 'components/TablePlaner/helpers/EdiApiAccept/validationSchema';
import { tableCloudPopover } from 'Api/Planner';
import { getCustomerRejectReasons } from 'Api/Customers';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';

const EDIRejectReason = ({ open, onClose, shipment, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        shipment_ids: [shipment.shipment_id],
        acceptOrDecline: 2,
        rejection_reason_id: values.rejection_reason?.id,
      };

      const res = await tableCloudPopover(body);
      if (res?.status) {
        showToaster({ type: 'success', message: 'Shipment Declined Successfully' });
        onSuccess();
      } else {
        showToaster({ type: 'error', message: 'Something went wrong!' });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { rejection_reason: null },
    onSubmit,
    validationSchema,
  });

  const getCustomerRejectionReasons = async () => {
    try {
      const { data } = await getCustomerRejectReasons(shipment?.shipment_billing[0]?.billing_customer?.id);
      setRejectionReasons(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomerRejectionReasons();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Rejection Reason'
      $bgColor={palette.gray0}
      $maxWidth='400px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Submit',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='mt-2 mb-4'>
        <div>
          <Autocomplete
            required
            width='100%'
            label='Rejection Reason'
            name='rejection_reason'
            placeholder='Select Reason'
            options={rejectionReasons}
            value={values.rejection_reason}
            onChange={(e, val) => handleChange('rejection_reason', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.reason?.title} (${option.reason?.code})`}
            error={touchedErrors.rejection_reason}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EDIRejectReason;
