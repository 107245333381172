import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import styles from './equipmentaddtabmodal.module.css';

const CustomModal = ({ children, onHide, showModal, styleBody, headerTitle }) => {
  const { use } = useTheme();

  return (
    <Modal size='lg' centered show={showModal} onHide={onHide} enforceFocus={false} className='customModal'>
      <Modal.Body style={styleBody}>
        {headerTitle !== undefined && (
          <div className='customHeader' style={{ background: use(palette.white, palette.dark800) }}>
            <span style={{ color: use(palette.gray900, palette.white) }}>{headerTitle}</span>
          </div>
        )}

        <div className={styles.EquipmentcustomBody} style={{ background: use(palette.white, palette.gray900) }}>
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
