import React from 'react';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import { getAlphabet, palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';
import classes from '../copyShipment.module.scss';

const CopyStop = ({ data, index, values, setFieldValue, touched, errors }) => {
  const getStopName = (type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: palette.green500 },
      2: { type: ' : DELIVERY', color: palette.red500 },
      3: { type: ' : WAYPOINT', color: palette.blueText },
    };
    return TYPE_STOPS[type];
  };

  return (
    <div>
      <div className={classes.switchWrapper}>
        <div className={classes.switchTextWrapper}>
          <Typography variant='s2'>
            <Typography variant='s2' style={{ color: getStopName(data.stop_point_type).color }}>
              STOP {getAlphabet(index)}
              {getStopName(data.stop_point_type).type}
            </Typography>
          </Typography>
          <Typography variant='b2'>
            {data?.stop_point?.location_name} ({data?.stop_point?.address1}, {data?.stop_point?.city?.name},{' '}
            {data?.stop_point?.state?.short_name} {data?.stop_point?.zipcode})
          </Typography>
        </div>
      </div>

      <div>
        <div className={classes.line} />
        <div className='ms-2'>
          <InputLabel required>Scheduled Date</InputLabel>
          <div className='d-flex gap-2 mb-4'>
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <div>
                <DateTimePicker
                  name='scheduled_date'
                  value={values.stops[index]?.scheduled_date}
                  onChange={(date) => setFieldValue(`stops[${index}].scheduled_date`, date)}
                  error={touched?.stops?.[index]?.scheduled_date && errors?.stops?.[index]?.scheduled_date}
                />
              </div>
            </div>
            {[1, 3].includes(Number(data.scheduled_type)) && (
              <div className='d-flex justify-content-start align-items-center gap-2'>
                <Typography variant='s2'>to</Typography>
                <div>
                  <DateTimePicker
                    name='scheduled_date_to'
                    value={values.stops[index]?.scheduled_date_to}
                    onChange={(date) => setFieldValue(`stops[${index}].scheduled_date_to`, date)}
                    error={touched?.stops?.[index]?.scheduled_date_to && errors?.stops?.[index]?.scheduled_date_to}
                    minDate={values.stops[index]?.scheduled_date}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyStop;
