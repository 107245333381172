import React from 'react';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';

const Title = ({ title }) => {
  return (
    <Typography variant='overLine' style={{ color: palette.gray900 }}>
      {title}
    </Typography>
  );
};

const statusMapper = {
  SCHEDULED: <Chip size='small' label='SCHEDULED' labelColor={palette.goldDark} bgColor={palette.goldLight} />,
  COMPLETED: <Chip size='small' label='COMPLETED' labelColor={palette.green500} bgColor={palette.green0} />,
  OVERDUE: <Chip size='small' label='OVERDUE' labelColor={palette.red500} bgColor={palette.red0} />,
};

export const useColumns = ({ currency }) => {
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'equipment_id',
      title: <Title title='EQUIPMENT ID' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.equipment.equipment_id}
        </Typography>
      ),
    },
    {
      field: 'type',
      title: <Title title='TYPE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.record_type.type}
        </Typography>
      ),
    },
    {
      field: 'description',
      title: <Title title='DESCRIPTION' />,
      render: (row) => (
        <Typography
          variant='b2'
          style={{
            color: palette.gray700,
            display: 'inline-block',
            width: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row.description || '-'}
        </Typography>
      ),
    },
    {
      field: 'due_by',
      title: <Title title='DUE BY' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.service_date_time) || '-'}
        </Typography>
      ),
    },
    {
      field: 'status',
      title: <Title title='STATUS' />,
      render: (row) => statusMapper[row.status.status],
    },
    {
      field: 'service_at',
      title: <Title title='SERVICED AT' />,
      render: (row) => (
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.service_at?.name || '-'}
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.service_at?.address || '-'}
          </Typography>
        </div>
      ),
    },
    {
      field: 'cost',
      title: <Title title='COST' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          ${row.cost} {row.cost ? `${currency} ${row.cost}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'updated_on',
      title: <Title title='UPDATED ON' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.updated_at)}
        </Typography>
      ),
    },
  ];
};
