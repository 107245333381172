import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import Add from 'assets/icons/drivers/add.svg';
import right from 'assets/icons/drivers/right.svg';
import { Icons } from 'assets/icons';
import { deleteCustomHoliday, getCustomerHolidays } from 'Api/Customers';
import ColumnHeader from 'common/ColumnHeader';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import Pagination from 'common/Pagination';
import RowActions from './RowActions';
import sm from './HolidaysTab.module.css';
import MaterialTableWrapper from '../MaterialTableWrapper';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import AddHolidayModal from '../AddHolidayModal/AddHolidayModal';
import Preloader from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import ManageHolidayModal from '../ManageHolidayModal/ManageHolidayModal';

const HolidaysTab = () => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [open, setOpen] = useState(false);
  const [openManageHoliday, setOpenManageHoliday] = useState(false);
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [holidayList, setHolidayList] = useState({ data: [] });
  const [holidayToEdit, setHolidayToEdit] = useState(null);
  const { id } = useParams();
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });

  const handleCloseManageHoliday = () => {
    setOpenManageHoliday(false);
  };

  const getHolidayLogData = async () => {
    setLoading(true);
    try {
      const customerHolidays = await getCustomerHolidays(id, selectedFilters);
      setHolidayList(customerHolidays);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const removeHoliday = async (id) => {
    try {
      await deleteCustomHoliday(id);
      showToaster({ type: 'success', message: 'Customer holiday has been successfully removed!' });
      getHolidayLogData();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const columns = [
    {
      title: <ColumnHeader title='ID' field='id' />,
      field: 'id',
    },
    {
      title: <ColumnHeader title='HOLIDAY NAME' field='holiday_name' />,
      field: 'holiday_name',
    },
    {
      title: <ColumnHeader title='DATE' field='date' />,
      field: 'date',
      render: (rowData) => {
        const holidayDate = moment({
          year: moment().year(),
          month: rowData.month_of_year - 1,
          day: rowData.day_of_year,
        });

        return (
          <div className='flex-row d-flex'>
            <span
              style={{
                color: use(palette.gray600, palette.gray200),
              }}
            >
              {holidayDate.format('dddd').substring(0, 3)}
              {', '}
              {formatDate(holidayDate)}
            </span>
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='COUNTRY' field='country_id' />,
      field: 'country_id',
      render: (rowData) => {
        const country = rowData.country.name;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{country}</span>;
      },
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='APPLY TO STOP POINTS' field='apply_to_stop_points' />
        </div>
      ),
      field: 'apply_to_stop_points',
      align: 'center',
      render: (rowData) => {
        return rowData.holiday_customers[0]?.holiday_stop_points ? (
          <img src={right} alt='' />
        ) : (
          <img src={Icons.CrossRedIcon} alt='' />
        );
      },
    },
    {
      field: '',
      render: (rowData) => (
        <RowActions
          onEdit={() => {
            setHolidayToEdit(rowData);
            setOpen(true);
          }}
          onDelete={() => removeHoliday(rowData.holiday_customers?.[0]?.id)}
        />
      ),
    },
  ];

  useEffect(() => {
    getHolidayLogData();
  }, [selectedFilters]);

  return (
    <div
      className={sm.holidaysTab}
      style={{
        backgroundColor: use(palette.gray0, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className={sm['holidaysTab-header']}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <p className={sm.heading} style={{ color: use(palette.gray900, palette.gray50) }}>
          Holidays
        </p>
        <div className='d-flex'>
          <PrimaryBtn
            icon={Add}
            style={{ color: use(palette.gray700, palette.gray200) }}
            title='Add Holiday'
            onClick={() => setOpen(true)}
          />
          <img
            style={{ transform: 'rotate(90deg)' }}
            onClick={() => setOpenManageHoliday(true)}
            src={moreInfo}
            alt=''
          />
        </div>
      </div>
      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          columns={columns}
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            color: use(palette.gray700, palette.gray200),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
          rowPerPage={selectedFilters.itemsPerPage}
          data={holidayList.data}
          components={{
            Pagination: () =>
              Pagination({
                data: holidayList,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [25, 50, 100, 150],
              }),
          }}
        />
      )}
      {!!open && (
        <Modal
          id='add-holiday-modal'
          size='sm'
          centered
          show={open}
          onHide={() => {
            setOpen(false);
            setHolidayToEdit(null);
          }}
          dialogClassName='modal-50w'
          enforceFocus={false}
        >
          <AddHolidayModal
            customerHoliday={holidayToEdit}
            onClose={() => {
              setHolidayToEdit(null);
              setOpen(false);
            }}
            onSuccess={getHolidayLogData}
          />
        </Modal>
      )}
      <ManageHolidayModal open={openManageHoliday} onClose={handleCloseManageHoliday} onSuccess={getHolidayLogData} />
    </div>
  );
};

export default HolidaysTab;
