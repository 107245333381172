import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import Input from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { getStopPointType } from 'Api/Planner';
import { createCarrierContactBook, getCarrierContactBook, updateCarrierContactBook } from 'Api/Carriers';
import { contactBookInitialValues, getContactBookInitialValues } from 'pages/OnboardCarrier/OnboardCarrier.data';
import { contactBookValidationSchema } from 'pages/OnboardCarrier/validationSchemas';
import { STable } from 'pages/OnboardCarrier/steps/ContactBook/ContactBook.styles';
import sm from './ContactBook.module.css';

const ContactBook = ({ Cancel, nextStep, customer }) => {
  const { use } = useTheme();
  const [contactBook, setContactBook] = useState(null);
  const [onNextLoading, setOnNextLoading] = useState(false);
  const showToaster = useShowToaster();

  const onSubmit = async () => {
    setOnNextLoading(true);
    try {
      const contactsToCreate = values.filter((i) => !!i.isNew);
      const contactsToEdit = values.filter((i) => !i.isNew);
      const f = new FormData();
      contactsToCreate.forEach((item, index) => {
        f.append(`contact_books[${index}][contact_name]`, item.contact_name);
        f.append(`contact_books[${index}][contact_email]`, item.contact_email);
        f.append(`contact_books[${index}][phone_number]`, item.phone_number);
        f.append(`contact_books[${index}][contact_type_id]`, item.contact_type_id?.id);
        f.append(`contact_books[${index}][check_calls]`, item.check_calls ? 1 : 0);
        f.append(`contact_books[${index}][portal_access]`, item.portal_access ? 1 : 0);
        f.append(`contact_books[${index}][receive_offers]`, item.receive_offers ? 1 : 0);
      });
      f.append(`carrier_id`, customer.basicDetails.carrier);

      if (contactsToCreate.length) {
        await createCarrierContactBook(f);
      }
      if (contactsToEdit.length) {
        const promises = contactsToEdit.map((item) => {
          const body = {
            contact_name: item.contact_name,
            phone_number: item.phone_number,
            contact_type_id: item.contact_type_id?.id,
            contact_email: item.contact_email,
            receive_offers: Number(item.receive_offers),
            check_calls: Number(item.check_calls),
          };
          return updateCarrierContactBook(body, item.id);
        });
        await Promise.all(promises);
      }
      onNextStep(values.contact_books);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      setOnNextLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: getContactBookInitialValues(contactBook),
    validationSchema: contactBookValidationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const [typeData, setTypeData] = useState([]);

  const getTypeData = () => {
    getStopPointType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          setTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onNextStep = (values) => {
    setOnNextLoading(false);
    showToaster({ type: 'success', message: 'Contact added successfully!' });
    nextStep(values);
  };

  const deleteContact = async (row, index) => {
    if (row.isNew) {
      setValues((prevState) => prevState.filter((el, i) => i !== index));
    }
  };

  useEffect(() => {
    getCarrierContactBook({ id: customer.basicDetails.carrier })
      .then((res) => {
        setContactBook(res.data);
      })
      .catch(() => {
        // Do nothing
      });
    getTypeData();
  }, []);

  return (
    <>
      <Modal.Body className='add-customer-modal-body'>
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Typography variant='h4' style={{ color: palette.gray900 }}>
                Contact Book
              </Typography>
              <STable>
                <thead>
                  <tr className='header-row'>
                    <th>
                      <InputLabel required>Name</InputLabel>
                    </th>
                    <th>
                      <InputLabel required>Email</InputLabel>
                    </th>
                    <th>
                      <InputLabel required>Phone Number</InputLabel>
                    </th>
                    <th>
                      <InputLabel required>Type</InputLabel>
                    </th>
                    <th>
                      <InputLabel>Receive Offers</InputLabel>
                    </th>
                    <th>
                      <InputLabel>Allow Check Calls</InputLabel>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {values.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <Input
                          name={`${index}.contact_name`}
                          onChange={handleChange}
                          value={values[index].contact_name}
                          error={touchedErrors[index]?.contact_name}
                        />
                      </td>
                      <td>
                        <Input
                          name={`${index}.contact_email`}
                          onChange={handleChange}
                          value={values[index].contact_email}
                          error={touchedErrors[index]?.contact_email}
                        />
                      </td>
                      <td>
                        <PhoneInput
                          name={`${index}.phone_number`}
                          placeholder='+1 (555) 555-1234'
                          onChange={(val) => handleChange(`${index}.phone_number`, val)}
                          value={values[index].phone_number}
                          error={touchedErrors[index]?.phone_number}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          width='150px'
                          labelKey='title'
                          name={`${index}.contact_type_id`}
                          value={values[index].contact_type_id}
                          options={typeData}
                          onChange={(e, val) => handleChange(`${index}.contact_type_id`, val)}
                          error={touchedErrors[index]?.contact_type_id}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                      </td>
                      <td>
                        <CustomCheckbox
                          name={`${index}.receive_offers`}
                          type='switch'
                          smail={false}
                          checked={values[index].receive_offers}
                          onChange={(checked) => handleChange(`${index}.receive_offers`, checked)}
                        />
                      </td>
                      <td>
                        <CustomCheckbox
                          name={`${index}.check_calls`}
                          type='switch'
                          smail={false}
                          checked={values[index].check_calls}
                          onChange={(checked) => handleChange(`${index}.check_calls`, checked)}
                        />
                      </td>
                      <td>
                        {index > 0 && item.isNew && (
                          <DeleteIcon
                            width={12}
                            height={12}
                            fill={palette.indigo500}
                            onClick={() => deleteContact(item, index)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </STable>
              <CustomButton
                type='secondary'
                title='Add Another'
                styleTitle={{ fontSize: 14, color: palette.indigo500, marginLeft: 8 }}
                styleButton={{ padding: '7px 12px', boxShadow: 'none', backgroundColor: 'transparent', paddingLeft: 0 }}
                onClick={() => handleChange(values.length, { ...contactBookInitialValues, id: Date.now() })}
                leftIcon={<PlusIcon fill={palette.indigo500} />}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            {onNextLoading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='primary'
                title='Next Step'
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ContactBook;
