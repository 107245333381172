export const initialValues = {
  isNew: true,
  reason: null,
};

export const getInitialValues = (rejectReasons) => {
  if (!rejectReasons?.length) {
    return [];
  }

  return rejectReasons.map((item) => ({ id: item.id, isNew: false, reason: item.reason }));
};
