import axios from '../services/axios';

export async function GetBillingDetail() {
  const { data } = await axios.get(`customer/billing-details`);
  return data;
}

export async function GetPurchaseAddonList() {
  const { data } = await axios.get(`addon/list`);
  return data;
}

export async function GetAdditionalServices(params) {
  const { data } = await axios.get(`customer/addon`, {
    params,
  });
  return data;
}

export async function GetBillingCardsPrice() {
  const { data } = await axios.get(`user/registration/plan/carrier`);
  return data;
}

export async function GetBillingCardsFeatures() {
  const { data } = await axios.get(`user/registration/features`);
  return data;
}

export async function PostBillingCheckPricesCoupon(jsonData) {
  const { data } = await axios.post('user/registration/prices', jsonData);
  return data;
}

export async function PostBillingCarrierCheckout(jsonData) {
  const { data } = await axios.post('user/registration/carrier/checkout', jsonData);
  return data;
}

export async function UpdateCustomPlan(jsonData) {
  const { data } = await axios.post('customer/update-customer-plan', jsonData);
  return data;
}

export async function UpdateExpiredCustomPlan(jsonData) {
  const { data } = await axios.post('customer/purchase-multi-item-prices', jsonData);
  return data;
}

export async function UpdatePaymentMethod(dataJson) {
  const { data } = await axios.put('/billing-change-card', dataJson);
  return data;
}

export async function GetBillingTransactions(params) {
  const { data } = await axios.get(`billing-customer/transactions`, {
    params,
  });
  return data;
}

export async function GetBillingManageList(params) {
  const { data } = await axios.get(`manage-user-license`, {
    params,
  });
  return data;
}

export async function RemoveManageDriver(id, dataJson) {
  const { data } = await axios.put(`/driver/status/${id}`, dataJson);
  return data;
}

export async function RemoveManageStaff(id, dataJson) {
  const { data } = await axios.put(`/user/staff/basic/${id}`, dataJson);
  return data;
}

export async function AddonsCancel(id) {
  const { data } = await axios.post(`/customer/addon-cancel/${id}`);
  return data;
}

export async function getBillingInvoice() {
  const { data } = await axios.get(`customer/billing/get-invoice-pdf-link`);
  return data;
}

export async function getHardwareList(params) {
  const { data } = await axios.get('customer/hardware', { params });
  return data;
}

export async function getTransactions(params) {
  const { data } = await axios.get('customer/billing/customer-transaction', { params });
  return data;
}

export async function purchaseAdditionalService(body) {
  const { data } = await axios.post(`customer/addon-purchase`, body);
  return data;
}

export async function assignAddon(body) {
  const { data } = await axios.post(`customer/addon-assignedto`, body);
  return data;
}

export async function removeAssignedAddon(body) {
  const { data } = await axios.post(`customer/addon-assigned/remove`, body);
  return data;
}

export async function checkAddonPrices(body) {
  const { data } = await axios.post(`customer/addon/prices`, body);
  return data;
}

export async function getAssignedLicenses() {
  const { data } = await axios.get(`customer/addon-assignedto`);
  return data;
}

export async function getAddonLicenses(params) {
  const { data } = await axios.get(`manage-user-license`, { params });
  return data;
}

export async function getPaymentDetails() {
  const { data } = await axios.get(`customer/billing/get-payment-details`);
  return data;
}

export async function updatePaymentMethod(body) {
  const { data } = await axios.put(`customer/billing/update-payment-details`, body);
  return data;
}

export async function getMyHardware(params) {
  const { data } = await axios.get(`my-hardware`, { params });
  return data;
}

export async function updateDeviceName(body) {
  const { data } = await axios.put(`driver-device`, body);
  return data;
}

export async function cancelDowngrade() {
  const { data } = await axios.post(`customer/cancel-plan-downgrade`);
  return data;
}

export async function getAgreementNumbers() {
  const { data } = await axios.get(`customer/hardware-agreement-number`);
  return data;
}

export async function createAchIntent() {
  const { data } = await axios.post(`customer/billing/create-ach-intent`);
  return data;
}

export async function createPaymentIntent() {
  const { data } = await axios.get(`/customer/billing/create-ach-intent-onboarding`);
  return data;
}

export async function cancelAddonDowngrade(body) {
  const { data } = await axios.post(`customer/cancel-add-on-plan-upgrade-downgrade`, body);
  return data;
}

export async function getPrices(body) {
  const { data } = await axios.post(`customer/get-multi-item-prices`, body);
  return data;
}
