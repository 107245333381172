import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startOfDay, endOfDay, addDays } from 'date-fns';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import Header from 'pages/Reports/ReportDetails/shared/Header';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getAssetPerformanceDetails } from 'Api/Reports';
import { getGroups } from 'Api/StaffV2';
import PageWrapper from '../shared/PageWrapper';
import { useColumns } from './assetPerformance.data';
import { generatePDF } from './generatePdf';
import { TYPE_OPTIONS } from './constats';
import { STableFilters, STableWrapper } from '../ReportDetails.styles';

const AssetPerformance = () => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  const [typeOption, setTypeOption] = useState(TYPE_OPTIONS[0]);
  const [group, setGroup] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');

  const [loading, setLoading] = useState(false);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [isPreparingCsvOrPdf, setIsPreparingCsvOrPdf] = useState(false);
  const [groupsData, setGroupData] = useState([]);
  const [reportItems, setReportItems] = useState([]);
  const [sort, setSort] = useState({ field: 'name', sortBy: 'desc' });
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });

  const getAssetPerformanceList = async () => {
    setLoading(true);
    try {
      let sortParam = { 'sort[][gross_rpm]': 'asc' };
      if (sort) {
        sortParam = { [`sort[][${sort.field}]`]: sort.sortBy };
      }

      const params = {
        ...sortParam,
        type: typeOption?.type,
        start: moment(dateRange.start ? dateRange.start.toUTCString() : new Date(0).toUTCString()).format('YYYY-MM-DD'),
        end: moment(dateRange.end ? dateRange.end.toUTCString() : new Date().toUTCString()).format('YYYY-MM-DD'),
      };
      if (group?.id) {
        params.group = group?.id;
      }

      const { data } = await getAssetPerformanceDetails(params);
      setReportItems(data || []);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    } finally {
      setLoading(false);
    }
  };

  const getGroupsList = async () => {
    try {
      setIsGroupsLoading(true);
      const { data } = await getGroups();
      setGroupData(data || []);
      setIsGroupsLoading(false);
    } catch (e) {
      setIsGroupsLoading(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      setIsPreparingCsvOrPdf(true);
      const data = { reportItems, title: 'Asset Performance' };
      const { blob } = await generatePDF(
        data,
        download,
        dateRange,
        dateFormat,
        typeOption?.type === 4,
        typeOption?.type === 3,
        currency
      );
      setIsPreparingCsvOrPdf(false);
      if (!download) {
        setPdfUrl(URL.createObjectURL(blob));
      }
    } catch (e) {
      setIsPreparingCsvOrPdf(false);
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }
    let titles = {};
    let reports = {};
    /** STAFF * */
    if (typeOption?.type === 4) {
      titles = {
        STAFF: 'STAFF',
        'HOURLY RATE': 'HOURLY RATE',
        'TIME TRACKED': 'TIME TRACKED',
        'PAY PER SHIPMENT': 'PAY PER SHIPMENT',
        'BOOKED EMPTY (MI)': 'BOOKED EMPTY (MI)',
        'BOOKED LOADED (MI)': 'BOOKED LOADED (MI)',
        'BOOKED TOTAL (MI)': 'BOOKED TOTAL (MI)',
        'BOOKED SHIPMENTS': 'BOOKED SHIPMENTS',
        'GROSS RPM': 'GROSS RPM',
        'GROSS REVENUE': 'GROSS REVENUE',
        'GROSS PAY': 'GROSS PAY',
        'NET PAY': 'NET PAY',
        'MARGIN %': 'MARGIN %',
        'GROSS MARGIN': 'GROSS MARGIN',
      };
      reports = reportItems.map((data) => {
        return {
          STAFF: data?.name || '-',
          'HOURLY RATE': data?.hourly_rate && !!parseFloat(data.hourly_rate) ? `$${data?.hourly_rate}` : '-',
          'TIME TRACKED': data?.time_tracked || '-',
          'PAY PER SHIPMENT':
            data?.pay_per_shipment && !!parseFloat(data.pay_per_shipment)
              ? `${data?.flat_or_per === 1 ? '$' : ''}${data?.pay_per_shipment}${data?.flat_or_per === 2 ? '%' : ''}`
              : '-',
          'BOOKED EMPTY (MI)': parseInt(data?.booked_empty?.toString(), 10)?.toString() || '-',
          'BOOKED LOADED (MI)': parseInt(data?.booked_loaded?.toString(), 10)?.toString() || '-',
          'BOOKED TOTAL (MI)': parseInt(data?.booked_total?.toString(), 10)?.toString() || '-',
          'BOOKED SHIPMENTS': data?.booked_shipments || '-',
          'GROSS RPM': data?.gross_rpm ? `$${data?.gross_rpm}` : '-',
          'GROSS REVENUE': data?.gross_revenue ? `$${data?.gross_revenue}` : '-',
          'GROSS PAY': data?.gross_pay ? `$${data?.gross_pay}` : '-',
          'NET PAY': data?.net_pay ? `$${data?.net_pay}` : '-',
          'MARGIN %': data?.margin ? `${data?.margin}%` : '-',
          'GROSS MARGIN': data?.gross_margin ? `$${data?.gross_margin}` : '-',
        };
      });
    } else {
      titles = {
        DRIVER: typeOption?.type === 3 ? 'VEHICLE' : 'DRIVER',
        'FUEL CPM': 'FUEL CPM',
        'FUEL COSTS': 'FUEL COSTS',
        'EMPTY (MI)': 'EMPTY (MI)',
        'LOADED (MI)': 'LOADED (MI)',
        'TOTAL (MI)': 'TOTAL (MI)',
        SHIPMENTS: 'SHIPMENTS',
        'GROSS RPM': 'GROSS RPM',
        'GROSS REVENUE': 'GROSS REVENUE',
        'GROSS PAY': 'GROSS PAY',
        'NET PAY': 'NET PAY',
        'MARGIN %': 'MARGIN %',
        'GROSS MARGIN': 'GROSS MARGIN',
      };
      if (typeOption.type === 3) {
        delete titles['NET PAY'];
      }

      reports = reportItems.map((data) => {
        const obj = {
          DRIVER: data?.name || '-',
          'FUEL CPM': data?.fuel_cpm ? `$${data?.fuel_cpm}` : '-',
          'FUEL COSTS': data?.fuel_costs ? `$${data?.fuel_costs}` : '-',
          'EMPTY (MI)': parseInt(data?.empty?.toString(), 10)?.toString() || '-',
          'LOADED (MI)': parseInt(data?.loaded?.toString(), 10)?.toString() || '-',
          'TOTAL (MI)': parseInt(data?.total?.toString(), 10)?.toString() || '-',
          SHIPMENTS: data?.shipments || '-',
          'GROSS RPM': data?.gross_rpm ? `$${data?.gross_rpm}` : '-',
          'GROSS REVENUE': data?.gross_revenue ? `$${data?.gross_revenue}` : '-',
          'GROSS PAY': data?.gross_pay ? `$${data?.gross_pay}` : '-',
          'NET PAY': data?.net_pay ? `$${data?.net_pay}` : '-',
          'MARGIN %': data?.margin ? `${data?.margin}%` : '-',
          'GROSS MARGIN': data?.gross_margin ? `$${data?.gross_margin}` : '-',
        };
        if (typeOption.type === 3) {
          delete obj['NET PAY'];
        }
        return obj;
      });
    }

    const arrayToConvert = [titles, ...reports];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';
        line += `"${arrayToConvert[i][index]}"`;
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('download', 'Asset Performance');
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    if (typeOption && dateRange) {
      getAssetPerformanceList();
    }
  }, [sort, group, typeOption, dateRange]);

  useEffect(() => {
    getGroupsList();
  }, []);

  const columns = useColumns({
    sortingQuery,
    sort,
    isStaff: typeOption?.type === 4,
    isVehicle: typeOption?.type === 3,
  });

  return (
    <PageWrapper title='Asset Performance'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              type='allTime'
              dateRange={dateRange}
              initialRangeName='Last 30 days'
              setDateRange={setDateRange}
            />

            <Autocomplete
              name='type'
              value={typeOption}
              width='200px'
              options={TYPE_OPTIONS}
              placeholder='Select Type'
              onChange={(e, value) => setTypeOption(value)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <Autocomplete
              name='group'
              width='200px'
              value={group}
              options={groupsData}
              labelKey='group_name'
              loading={isGroupsLoading}
              disableClearable={false}
              placeholder='Select Tag'
              onChange={(e, value) => setGroup(value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
          <CustomButton
            disabled={loading || isPreparingCsvOrPdf}
            title='Generate Report'
            className='action-button'
            onClick={() => onPdfClick(false)}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          disabled={loading || isPreparingCsvOrPdf}
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
        />
        <Divider margin='16px 0 16px' color='transparent' />
        <MaterialTableWrapper columns={columns} data={reportItems} isLoading={loading} />
      </STableWrapper>
      {pdfUrl && <ViewPdf pdfUrl={pdfUrl} open={pdfUrl} title='Asset Performance' onClose={() => setPdfUrl(null)} />}
    </PageWrapper>
  );
};

export default AssetPerformance;
