import React, { useState } from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import ViewAttachment from 'components/ViewAttachment';
import { palette } from 'utils/constants';
import { uploadDriverDocument } from 'Api/Driver';
import { uploadStaffDocument } from 'Api/StaffV2';
import { SAddMore, SFlexWrapper, SAttachmentWrapper } from './IncidentPhotos.styles';

const mapper = {
  incident_record_images: {
    accept: ['image/jpeg', 'image/png'],
    label: 'Incident Photos',
    placeholder: 'Image',
    viewLabel: 'Photo',
  },
  incident_record_video: {
    accept: ['video/mp4', 'video/webm', 'video/ogg'],
    label: 'Incident Videos',
    placeholder: 'Video',
    viewLabel: 'Video',
  },
  document_path: {
    accept: ['application/pdf', 'image/jpeg', 'image/png'],
    label: 'Upload Documentation',
    placeholder: 'Document',
    viewLabel: 'Document',
  },
};

const IncidentPhotos = ({ values, handleChange, userId, name, userType }) => {
  const [imageLink, setImageLink] = useState('');

  const uploadIncidentPhoto = (file) => {
    if (userType === 'driver') {
      uploadDriverDocument(file[0], userId).then((res) => {
        if (name === 'document_path') {
          handleChange(`${name}`, [...values[`${name}`], { path: res.data.full_path, name: file[0]?.name }]);
        } else {
          handleChange(`${name}`, [...values[`${name}`], res.data.full_path]);
        }
      });
    } else {
      uploadStaffDocument(file[0], userId).then((data) => {
        if (name === 'document_path') {
          handleChange(`${name}`, [...values[`${name}`], { path: data.full_path, name: file[0]?.name }]);
        } else {
          handleChange(`${name}`, [...values[`${name}`], data.full_path]);
        }
      });
    }
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newImages = values[`${name}`].filter((item, i) => i !== index);
    handleChange(`${name}`, newImages);
  };

  return (
    <SAttachmentWrapper>
      <InputLabel>{mapper[name].label}</InputLabel>
      <div>
        <SFlexWrapper>
          {values?.[`${name}`]?.map((image, index) => (
            <div key={image?.id || index}>
              <p
                className='attachment-wrapper'
                onClick={() => {
                  if (name === 'document_path') {
                    if (image.path?.startsWith('https://')) {
                      setImageLink(image.path);
                    }
                  } else {
                    if (image.startsWith('https://')) {
                      setImageLink(image);
                    }
                  }
                }}
                style={{ backgroundColor: palette.indigo0 }}
              >
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: palette.green400 }}>
                  {name === 'document_path' ? image.name : `${mapper[name].placeholder} ${index + 1}`}
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={(e) => onAttachmentDelete(e, index)}
                />
              </p>
            </div>
          ))}
          {!!values?.[`${name}`]?.length && (
            <SAddMore>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
                Add Another...
              </Typography>
              <ImageDropZone
                multiple
                onDrop={(files) => uploadIncidentPhoto(files)}
                width='100%'
                height='100%'
                name='small'
                deletable={false}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
                accept={mapper[name].accept}
              />
            </SAddMore>
          )}
        </SFlexWrapper>
        {!values?.[`${name}`]?.length && (
          <div className='upload-document-deposit'>
            <div className='text-style upload-label'>
              <p className='upload-document-icon'>
                <img src={uploadLicense} alt='' />
              </p>
              <p className='upload-document-text'>
                <span style={{ color: palette.indigo500 }}> Upload {mapper[name].placeholder}</span>
                <span style={{ color: palette.gray700 }}> or drag and drop</span>
              </p>
            </div>
            <ImageDropZone
              multiple
              onDrop={(files) => uploadIncidentPhoto(files)}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              accept={mapper[name].accept}
            />
          </div>
        )}
      </div>
      {!!imageLink && (
        <ViewAttachment
          title={`Incident ${mapper[name].viewLabel}`}
          open={!!imageLink}
          onClose={() => setImageLink('')}
          attachment={imageLink}
          isVideo={name === 'incident_record_video'}
        />
      )}
    </SAttachmentWrapper>
  );
};

export default IncidentPhotos;
