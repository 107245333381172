import React, { useState } from 'react';
import { ReactComponent as ImportIcon } from 'assets/icons/download2.svg';
import Tooltip from 'common/Tooltip';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getHeaders } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { importStopPoints } from 'Api/ImportCSV';
import ImportCSVModal from './ImportCSVModal';
import { stopPointsConverter } from './converters';
import { typeMapper, CSV_TYPE } from './ImportCSV.data';

const ImportCSV = ({ type, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [data, setData] = useState(null);

  const csvToArray = (str) => {
    const mapper = typeMapper[type];
    const columns = getHeaders(str, mapper);

    if (columns?.length) {
      setData(stopPointsConverter(columns));
    }
  };

  const importData = async () => {
    try {
      setLoading(true);
      const response = await importCSV(data);
      if (response.warning) {
        showToaster({ type: 'warning', message: response.message });
      } else {
        showToaster({ type: 'success', message: 'Data has been successfully imported!' });
      }
      setOpenImport(false);
      onSuccess();
    } catch (e) {
      let singleErrorMessage = '';
      if (e.response?.data?.response?.length) {
        singleErrorMessage = `${e.response.data.response.join('. ')}.`;
      }
      showToaster({ type: 'error', message: singleErrorMessage || getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const importCSV = async (data) => {
    switch (type) {
      case CSV_TYPE.STOP_POINTS:
        return importStopPoints(data);
      default:
        return () => null;
    }
  };

  return (
    <>
      <Tooltip title='Import CSV'>
        <CustomButton
          type='secondary'
          leftIcon={<ImportIcon fill={palette.gray700} />}
          styleButton={{ padding: '6px 12px', margin: 0, height: 36 }}
          onClick={() => setOpenImport(true)}
          disabled={loading}
        />
      </Tooltip>
      {openImport && (
        <ImportCSVModal
          open={openImport}
          onClose={() => setOpenImport(false)}
          csvToArray={csvToArray}
          importData={importData}
          loading={loading}
          type={type}
        />
      )}
    </>
  );
};

export default ImportCSV;
export { CSV_TYPE };
