import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

export const NavLinkWrapper = ({ deleteDefaultStyles = false, to, onClick, children, style, ...rest }) => {
  const defaultStyles = useMemo(() => {
    return {
      top: 0,
      left: 0,
      inset: 0,
      color: 'initial',
      position: 'absolute',
      background: 'initial',
      textDecoration: 'none',
      padding: '8px 8px 30px 8px',
    };
  }, []);
  return (
    <NavLink
      style={deleteDefaultStyles ? style : { ...defaultStyles, ...style }}
      to={to}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </NavLink>
  );
};
