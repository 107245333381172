import { number, object, string } from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = object().shape({
  company_logo: string().trim().required('Required').typeError('Required'),
  company_name: string().trim().required('Required'),
  dot: string().trim().required('Required'),
  phone_number: string().trim().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
  address1: string().trim().required('Required'),
  country_id: string().trim().required('Required'),
  state_id: string().trim().required('Required'),
  city_id: string().trim().required('Required'),
  zip: string().trim().required('Required'),
  time_zone_id: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
