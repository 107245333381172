import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import wexIcon from 'assets/images/wex.png';
import { ReactComponent as CardIcon } from 'assets/icons/cashCard.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/drivers/table-logo/right-arrow.svg';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { GetAllConnectedSeries } from 'Api/Driver';
import { SUnderlineText, SSection } from './SetupCard.styles';

const SetupCard = ({ open, onClose, module }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const customer = user.customer.company_name;
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  const getConnectedServices = async () => {
    setLoading(true);
    try {
      const { data } = await GetAllConnectedSeries();
      const wex = data?.find((i) => i.id === 44);

      if (wex?.connected) {
        setIsConnected(true);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConnectedServices();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <Typography variant='h4' style={{ color: palette.gray900, display: 'flex', justifyContent: 'center' }}>
          Choose how you'd like to setup card management
        </Typography>
      }
      customBody={{ borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
      $bgColor={palette.white}
      $maxWidth='1120px'
      $minWidth='1120px'
      className='modified-scrollbar'
      footer={false}
    >
      <div className='d-flex'>
        <SSection>
          <div>
            <Typography variant='button2'>Truckin Digital Cash Card</Typography>
            <Typography
              variant='overLine'
              style={{
                color: palette.indigo500,
                padding: '2px 6px',
                backgroundColor: palette.indigo0,
                borderRadius: '4px',
                marginLeft: '8px',
              }}
            >
              PREPAID
            </Typography>
          </div>
          <Typography variant='overLine2'>
            A prepaid card for your company - distribute cards to drivers or staff.
          </Typography>
          <Typography variant='overLine2' style={{ marginTop: '16px' }}>
            With the Truckin Digital Cash Card, you can easily and securely manage your company expenses with just a few
            clicks. The Truckin Digital Cash Card is accepted at thousands of locations across the world. Use the card
            anywhere where VISA is accepted: including gas stations, truck stops, and more.
          </Typography>
          <div className='position-relative' style={{ marginTop: '24px' }}>
            <CardIcon style={{ marginLeft: '-13px' }} />
            <Typography
              variant='s1'
              style={{ color: palette.white, position: 'absolute', bottom: '44px', right: '24px' }}
            >
              {customer}
            </Typography>
          </div>
          <CustomButton
            height={12}
            type='primary'
            // title='Get Started'
            title='Not Available'
            styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
            onClick={() => navigate(`/truckin-digital-cash-card/${module}`)}
            disabled
          />
          {/* <Typography variant='c1' style={{ color: palette.gray700 }} className='mt-2 terms'> */}
          {/*  By clicking the Get Started button, you agree to the{' '} */}
          {/*  <a href='https://stripe.com/legal/celtic-spend-card' target='_blank' rel='noreferrer'> */}
          {/*    Issuing Bank Terms */}
          {/*  </a> */}
          {/*  ,{' '} */}
          {/*  <a href='https://stripe.com/legal/connect-account' target='_blank' rel='noreferrer'> */}
          {/*    Stripe Connected Account Agreement */}
          {/*  </a> */}
          {/*  ,{' '} */}
          {/*  <a href='https://stripe.com/legal/ssa#services-terms' target='_blank' rel='noreferrer'> */}
          {/*    Stripe Issuing Accountholder Terms */}
          {/*  </a> */}
          {/*  , and you consent to electronic signatures as set forth in the{' '} */}
          {/*  <a href='https://stripe.com/legal/celtic-spend-card' target='_blank' rel='noreferrer'> */}
          {/*    Issuing Bank Terms */}
          {/*  </a> */}
          {/*  . */}
          {/* </Typography> */}
          <Typography variant='overLine2' className='mt-3'>
            Our card comes with a range of features to help you stay on top of your expenses, including real-time
            transaction tracking and reporting, customizable spending limits, and instant card lock and unlock.
            Consolidate transactions giving you one place to manage purchases, cash advances, and much more!
          </Typography>
          <div className='d-flex  align-items-baseline' style={{ marginTop: '16px' }}>
            <div>
              <CheckIcon />
            </div>
            <Typography variant='overLine2' style={{ marginLeft: '8px' }}>
              Make purchases anywhere Visa is accepted.
            </Typography>
          </div>
          <div className='d-flex  align-items-baseline'>
            <div>
              <CheckIcon />
            </div>
            <Typography variant='overLine2' style={{ marginLeft: '8px' }}>
              Set security features such as ATM, PIN Code, Vehicle ID, Deduct on payroll and fuel fraud prevention.
            </Typography>
          </div>
          <div className='d-flex  align-items-baseline'>
            <div>
              <CheckIcon />
            </div>
            <Typography variant='overLine2' style={{ marginLeft: '8px' }}>
              Assign cards to staff or drivers and set usage limits.
            </Typography>
          </div>
          <div className='d-flex  align-items-baseline'>
            <div>
              <CheckIcon />
            </div>
            <Typography variant='overLine2' style={{ marginLeft: '8px' }}>
              Set card mode type: Use for company expenses or for payroll. Pay your drivers on their card instantly.
            </Typography>
          </div>
          <div className='d-flex align-items-baseline'>
            <div>
              <CheckIcon />
            </div>
            <Typography variant='overLine2' style={{ marginLeft: '8px' }}>
              Fund your cards same-day when you need to distribute cash on the road.
            </Typography>
          </div>
          <div
            style={{
              width: '400px',
              backgroundColor: palette.indigo0,
              border: `1px solid ${palette.indigo100}`,
              borderRadius: '8px',
              padding: '20px 24px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '24px',
            }}
          >
            <Typography variant='button2' style={{ color: palette.indigo500 }}>
              $0.00{' '}
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                monthly fee
              </Typography>
            </Typography>
            <Typography variant='button2' style={{ color: palette.indigo500 }}>
              $3.00{' '}
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                per active card, one time + shipping
              </Typography>
            </Typography>
            <Typography variant='button2' style={{ color: palette.indigo500 }}>
              $0.00{' '}
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                account set-up fee
              </Typography>
            </Typography>
          </div>
        </SSection>
        <SSection style={{ padding: '8px 60px 8px 80px', border: 'none' }}>
          <Typography variant='button2'>Use Current Card Provider</Typography>
          <Typography variant='overLine2'>
            If you already have a fleet card, you can connect your provider to Truckin Digital! Manage, set limits and
            view transactions in one platform.
          </Typography>
          <Typography variant='s2' style={{ color: palette.gray700, marginTop: '24px' }}>
            Supported providers:
          </Typography>
          <div
            style={{
              border: `1px solid ${palette.gray50}`,
              borderRadius: '8px',
              display: 'flex',
              padding: '19px 16px',
              gap: '16px',
            }}
          >
            <div>
              <img src={wexIcon} alt='wex' />
            </div>
            <div>
              <div>
                <Typography variant='button2'>WEX EFS Fleet Card</Typography>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <Typography variant='overLine2'>
                  Connect your existing WEX EFS account with Truckin Digital! Set purchase limits in real-time, down to
                  the individual card level all from your Truckin Digital Card Management module. Consolidate
                  transactions giving you one place to manage purchases, cash advances, and more.
                </Typography>
              </div>
              {!loading &&
                (isConnected ? (
                  <Typography variant='button1' style={{ color: palette.green500 }}>
                    Connected
                  </Typography>
                ) : (
                  <SUnderlineText onClick={() => navigate('/connectedservicesediapiintegrations')}>
                    <Typography variant='button1' style={{ color: palette.indigo500 }}>
                      Get Started
                    </Typography>
                    <ArrowIcon fill={palette.indigo500} />
                  </SUnderlineText>
                ))}
            </div>
          </div>
        </SSection>
      </div>
    </Modal>
  );
};

export default SetupCard;
