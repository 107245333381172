import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';

import Search from 'common/Search';
import DateRangePicker from 'common/DateRangePicker';
import PopoverSettings from 'components/PopoverSettings/PopoverSettingAbsence';
import { palette } from 'utils/constants';
import Layout from 'components/CreateShipment/Layout';

const TableFilters = ({
  search,
  setSearch,
  dateRange,
  setDateRange,

  filter,
  setFilter,
  dragItem,
  getSettings,
  onChangeOrder,
  onSetTableColumn,
  switchRadioButtons,
  onSetSwitchRadioButtons,
  tableColumn,
  settingsOpen,
  setSettingsOpen,
}) => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: palette.white }}>
      <Popover.Body>
        <PopoverSettings
          filter={filter}
          updateFilter={setFilter}
          dragItem={dragItem}
          getSettings={getSettings}
          onChangeOrder={onChangeOrder}
          onsStTableColumn={onSetTableColumn}
          switchRadioButtons={switchRadioButtons}
          setShowPopover={(isOpen) => setShow(isOpen)}
          onSetSwitchRadioButtons={onSetSwitchRadioButtons}
          tableColumn={tableColumn}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <div className='d-flex align-items-center gap-3 mb-4'>
      <OverlayTrigger
        trigger='click'
        placement='bottom-start'
        overlay={popOverContent}
        rootClose={show}
        onToggle={setShow}
        show={show}
        defaultShow={false}
        delay={0}
      >
        <div className='top_popover' style={{ backgroundColor: palette.white, marginBottom: 0 }}>
          <DataTableIcon widht={14} height={14} fill={palette.gray700} />
        </div>
      </OverlayTrigger>
      <Search search={search} onChange={setSearch} width='content-width' />
      <DateRangePicker initialRangeName='This Year' dateRange={dateRange} setDateRange={setDateRange} />
      <Modal open={isOpen} enforceFocus={false} onClose={() => setIsOpen(false)}>
        <Box>
          <Layout
            onCloseModal={() => setIsOpen(false)}
            createModalCLose={settingsOpen}
            updateOnCreate={setSettingsOpen}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default TableFilters;
