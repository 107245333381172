import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-2.svg';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import ActionButtons from 'common/ActionButtons';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import ThreeDotActions from 'common/ThreeDotActions';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { transformYupErrorsIntoObject } from 'utils/helpers';
import { CURRENCY, formatNumber, palette } from 'utils/constants';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { BILLING_GRAND_TOTAL_CURRENCY } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getShipmentChargeType } from 'Api/Planner';
import { getCustomerContactBook, getCustomerPayTerms, getCustomersList } from 'Api/Customers';

import { validationSchema } from 'componentsV2/Planner/Quotes/CreateQuote/validationSchema';
import {
  createQuoteBillingCharge,
  deleteQuoteBillingCharge,
  updateQuoteBilling,
  updateQuoteBillingCharge,
} from 'Api/Shipment';
import InfoItem from '../InfoItem';
import { initialCharge } from '../../CreateQuote.data';
import { SBilling, STable, SAddMore } from '../../CreateQuote.styles';

const Billing = ({ form, quote, shouldSave }) => {
  const showToaster = useShowToaster();
  // const { formatDate } = useDateFormat();
  const [customers, setCustomers] = useState([]);
  const [customerPayTerms, setCustomerPayTerms] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [chargeTypes, setChargeTypes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateBillingLoading, setUpdateBillingLoading] = useState(false);
  const [updateChargeLoading, setUpdateChargeLoading] = useState(null);
  const [deleteChargeLoading, setDeleteChargeLoading] = useState(null);
  const editMode = !!quote;

  const { values, handleChange, touchedErrors, setErrors, setTouched, setValues } = form;

  const currencySymbol = CURRENCY[values.billing.currency?.title?.toLowerCase()]?.symbol;

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ page: 1, page_size: 10000 });
      const { data: payTerms } = await getCustomerPayTerms();
      setCustomers(data);
      setCustomerPayTerms(payTerms);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerContacts = async (customerId) => {
    try {
      const { data } = await getCustomerContactBook({ id: customerId });
      setCustomerContacts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getChargeType = async () => {
    try {
      const { data } = await getShipmentChargeType();
      setChargeTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onSaveBilling = async () => {
    try {
      await validationSchema.validateAt(`billing`, values, {
        strict: true,
        abortEarly: false,
      });

      setUpdateBillingLoading(true);

      const body = {
        customer_id: values.billing.customer.id,
        currency_id: values.billing.currency?.id || null,
        reference_id: values.billing.reference_id || null,
        contact_user_id: values.billing.contact?.id || null,
        payment_term_id: values.billing.payment_term?.id || null,
        total_power_unit: values.billing.total_power_unit || null,
      };

      await updateQuoteBilling(quote.billing.id, body);

      showToaster({ type: 'success', message: `Billing has been updated successfully!` });

      handleChange(`billing`, {
        ...values.billing,
        isEdit: false,
        isNew: false,
      });
    } catch (e) {
      const { validationErrors, validationTouched } = transformYupErrorsIntoObject(e);

      if (Object.keys(validationErrors)?.length) {
        setErrors(validationErrors, { shouldValidate: false });
        setTouched(validationTouched, { shouldValidate: false });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setUpdateBillingLoading(false);
    }
  };

  const onSaveCharge = async (charge, index) => {
    try {
      if (isCurrentItemLoading(index)) {
        return;
      }

      await validationSchema.validateAt(`charges[${index}]`, values, {
        strict: true,
        abortEarly: false,
      });

      setUpdateChargeLoading(index);

      let newItem;

      if (charge.isNew) {
        const body = {
          billing_id: quote.billing.id,
          charge_type_id: charge.charge_type.id,
          quantity: charge.quantity,
          rate: charge.rate,
          sub_total: Number(charge.quantity || 0) * Number(charge.rate || 0),
          currency_id: values.billing.currency?.id || null,
        };

        const { data } = await createQuoteBillingCharge(body);
        newItem = data;
      } else {
        const body = {
          charge_type_id: charge.charge_type.id,
          quantity: charge.quantity,
          rate: charge.rate,
          sub_total: Number(charge.quantity || 0) * Number(charge.rate || 0),
          currency_id: values.billing.currency?.id || null,
        };

        await updateQuoteBillingCharge(charge.id, body);
      }

      showToaster({
        type: 'success',
        message: `Charge has been ${charge.isNew ? 'added' : 'updated'} successfully!`,
      });

      handleChange(`charges[${index}]`, {
        ...charge,
        id: newItem?.id || charge.id,
        isEdit: false,
        isNew: false,
      });
    } catch (e) {
      const { validationErrors, validationTouched } = transformYupErrorsIntoObject(e);

      if (Object.keys(validationErrors)?.length) {
        setErrors(validationErrors, { shouldValidate: false });
        setTouched(validationTouched, { shouldValidate: false });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setUpdateChargeLoading(null);
    }
  };

  const onDeleteCharge = async (charge, index) => {
    try {
      if (isCurrentItemLoading(index)) {
        return;
      }

      if (!charge.isNew) {
        setDeleteChargeLoading(index);
        await deleteQuoteBillingCharge(charge.id);
        showToaster({ type: 'success', message: `Charge has been deleted successfully!` });
      }

      handleChange(
        `charges`,
        values.charges.filter((item, i) => i !== index)
      );
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setDeleteChargeLoading(null);
    }
  };

  const isCurrentItemLoading = (index) => {
    return updateChargeLoading === index || deleteChargeLoading === index;
  };

  const onCustomerChange = (customer) => {
    setValues((prevState) => ({
      ...prevState,
      billing: {
        ...prevState.billing,
        customer,
        contact: null,
        payment_term: customer.customer_payment_term?.pay_term_types || null,
      },
    }));
  };

  const onChargeTypeChange = (value, index) => {
    handleChange(`charges[${index}].charge_type`, value);
    if (value.type.account_type === 'Expense' && Number(values.charges[index].rate) > 0) {
      handleChange(`charges[${index}].rate`, `-${values.charges[index].rate}`);
    }
    if (value.type.account_type !== 'Expense' && Number(values.charges[index].rate) < 0) {
      handleChange(`charges[${index}].rate`, `${Math.abs(values.charges[index].rate)}`);
    }
  };

  const onAddCharge = () => {
    handleChange('charges', [...values.charges, { ...initialCharge, id: uuid() }]);
  };

  const onEditBilling = () => {
    handleChange('billing.isEdit', true);
  };

  const onEditCharge = (index) => {
    handleChange(`charges[${index}].isEdit`, true);
  };

  useEffect(() => {
    getChargeType();
    getCustomers();
  }, []);

  useEffect(() => {
    if (values.billing.customer) {
      getCustomerContacts(values.billing.customer.id);
    }
  }, [values.billing.customer]);

  return (
    <SBilling>
      <div className='mb-3'>
        <Typography variant='s1'>Charges</Typography>
      </div>
      <div
        className={classNames('create-quote-section', {
          'unsaved-animation': !!quote && !!shouldSave && !!values.billing.isEdit,
        })}
      >
        {editMode && (
          <div className='d-flex justify-content-end mb-2'>
            <ActionButtons
              data={values.billing}
              onEdit={() => onEditBilling()}
              onSave={() => onSaveBilling()}
              loadingSave={updateBillingLoading}
            />
          </div>
        )}
        <div className='billing-info-wrapper'>
          <div>
            {editMode && !values.billing.isEdit ? (
              <InfoItem title='QUOTE TO' content={values.billing.customer?.company_name || '-'} />
            ) : (
              <Autocomplete
                width='300px'
                label='QUOTE TO'
                labelProps={{ variant: 'c3' }}
                labelKey='company_name'
                options={customers}
                value={values.billing.customer}
                onChange={(e, val) => onCustomerChange(val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            )}
            <ErrorMessage error={touchedErrors.billing?.customer} />
          </div>
          <div>
            {editMode && !values.billing.isEdit ? (
              <InfoItem title='REFERENCE ID' content={values.billing.reference_id || '-'} />
            ) : (
              <Input
                label='REFERENCE ID'
                labelProps={{ variant: 'c3' }}
                name='billing.reference_id'
                onChange={(e) => handleChange(`billing.reference_id`, e.target.value)}
                value={values.billing.reference_id}
              />
            )}
            <ErrorMessage error={touchedErrors.billing?.reference_id} />
          </div>
          <div>
            {/* {editMode && !values.billing.isEdit ? ( */}
            {/*  <InfoItem title='QUOTE EXPIRY' content={values.expiry_date ? formatDate(values.expiry_date) : '-'} /> */}
            {/* ) : ( */}
            <DatePicker
              label='QUOTE EXPIRY'
              labelProps={{ variant: 'c3' }}
              name='expiry_date'
              value={values.expiry_date}
              onChange={(val) => handleChange(`expiry_date`, val)}
              error={touchedErrors?.expiry_date}
              disablePast
            />
            {/* )} */}
          </div>
          <div>
            {editMode && !values.billing.isEdit ? (
              <InfoItem title='CONTACT' content={values.billing.contact ? values.billing.contact.contact_name : '-'} />
            ) : (
              <Autocomplete
                width='200px'
                label='CONTACT'
                labelProps={{ variant: 'c3' }}
                name='billing.contact'
                value={values.billing.contact}
                onChange={(e, value) => handleChange('billing.contact', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option
                    ? `${option.contact_type?.title ? `${option.contact_type?.title} - ` : ''}${option.contact_name}`
                    : ''
                }
                options={customerContacts}
              />
            )}
            <ErrorMessage error={touchedErrors.billing?.contact} />
          </div>
          <div>
            {editMode && !values.billing.isEdit ? (
              <InfoItem
                title='PAYMENT TERM'
                content={values.billing.payment_term ? getPaymentTermTitle(values.billing.payment_term) : '-'}
              />
            ) : (
              <Autocomplete
                width='200px'
                label='PAYMENT TERM'
                labelProps={{ variant: 'c3' }}
                name='billing.payment_term'
                value={values.billing.payment_term}
                onChange={(e, value) => handleChange('billing.payment_term', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option ? getPaymentTermTitle(option) : '')}
                options={customerPayTerms}
              />
            )}
            <ErrorMessage error={touchedErrors.billing?.payment_term} />
          </div>
        </div>
        <div>
          <STable>
            <thead>
              <tr className='header-row'>
                <th>CHARGES PER UNIT</th>
                <th>QTY</th>
                <th>RATE</th>
                <th>SUB-TOTAL</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {values.charges.map((charge, index) => (
                <tr
                  className={classNames('body-row', {
                    'unsaved-animation': !!quote && !!shouldSave && (!!charge.isEdit || !!charge.isNew),
                  })}
                  key={charge.id}
                >
                  <td>
                    <div>
                      {editMode && !charge.isEdit && !charge.isNew ? (
                        <InfoItem content={charge.charge_type?.title || '-'} />
                      ) : (
                        <Autocomplete
                          width='400px'
                          name={`charges[${index}].charge_type`}
                          labelKey='title'
                          options={chargeTypes}
                          value={charge.charge_type}
                          onChange={(e, val) => onChargeTypeChange(val, index)}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                      )}
                      <ErrorMessage error={touchedErrors?.charges?.[index]?.charge_type} />
                    </div>
                  </td>
                  <td>
                    <div>
                      {editMode && !charge.isEdit && !charge.isNew ? (
                        <InfoItem content={charge.quantity || '-'} />
                      ) : (
                        <Input
                          width='100px'
                          type='number'
                          placeholder='1'
                          name={`charges[${index}].quantity`}
                          value={charge.quantity}
                          onChange={handleChange}
                        />
                      )}
                      <ErrorMessage error={touchedErrors?.charges?.[index]?.quantity} />
                    </div>
                  </td>
                  <td>
                    <div>
                      {editMode && !charge.isEdit && !charge.isNew ? (
                        <InfoItem content={charge.rate || '-'} />
                      ) : (
                        <InputWithIcon
                          icon={currencySymbol}
                          width='150px'
                          type='number'
                          placeholder='0.00'
                          name={`charges[${index}].rate`}
                          value={charge.rate}
                          onChange={handleChange}
                        />
                      )}
                      <ErrorMessage error={touchedErrors?.charges?.[index]?.rate} />
                    </div>
                  </td>
                  <td>
                    <div>
                      <Typography variant='s2'>
                        {currencySymbol}
                        {formatNumber(Number(charge.quantity || 0) * Number(charge.rate || 0))}
                      </Typography>
                    </div>
                  </td>
                  {editMode && (
                    <td>
                      <ActionButtons
                        data={charge}
                        onEdit={() => onEditCharge(index)}
                        onSave={() => onSaveCharge(charge, index)}
                        onDelete={() => onDeleteCharge(charge, index)}
                        loadingSave={updateChargeLoading === index}
                        loadingDelete={deleteChargeLoading === index}
                      />
                    </td>
                  )}
                </tr>
              ))}
              <tr className='total-row'>
                <td>
                  <SAddMore onClick={onAddCharge}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Add Another...
                    </Typography>
                  </SAddMore>
                </td>
                <td>
                  <div>
                    <InputLabel variant='c3'>TOTAL PER UNIT</InputLabel>
                    <Typography variant='s2'>
                      {Number(values.billing?.total_power_unit) ? currencySymbol : ''}
                      {Number(values.billing?.total_power_unit)
                        ? formatNumber(
                            values.charges.reduce(
                              (acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0),
                              0
                            ) / Number(values.billing?.total_power_unit)
                          )
                        : '-'}
                    </Typography>
                  </div>
                </td>
                <td>
                  <div>
                    {editMode && !values.billing.isEdit ? (
                      <InfoItem title='TOTAL POWER UNITS' content={values.billing?.total_power_unit || '-'} />
                    ) : (
                      <Input
                        width='100px'
                        type='number'
                        label='TOTAL POWER UNITS'
                        labelProps={{ variant: 'c3' }}
                        name='billing.total_power_unit'
                        value={values.billing.total_power_unit}
                        onChange={handleChange}
                      />
                    )}
                    <ErrorMessage error={touchedErrors.billing?.total_power_unit} />
                  </div>
                </td>
                <td>
                  <div>
                    <InputLabel variant='c3'>TOTAL</InputLabel>
                    <Typography variant='s2'>
                      {currencySymbol}
                      {formatNumber(
                        values.charges.reduce((acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0), 0)
                      )}
                    </Typography>
                  </div>
                </td>
                <td>
                  <ThreeDotActions
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    actionButton={
                      <div className='d-flex justify-content-between align-items-center gap-2'>
                        <Typography variant='s2' style={{ color: palette.gray700 }}>
                          {values.billing.currency?.title}
                        </Typography>
                        <ArrowIcon fill={palette.gray700} style={{ transform: `rotate(90deg)` }} />
                      </div>
                    }
                  >
                    {BILLING_GRAND_TOTAL_CURRENCY.map((item) => (
                      <li
                        key={item.id}
                        style={{
                          backgroundColor: item.id === values.billing.currency?.id ? palette.indigo500 : '',
                          color: item.id === values.billing.currency?.id ? palette.white : palette.gray900,
                        }}
                        onClick={() => {
                          handleChange('billing.currency', item);
                          setAnchorEl(null);
                        }}
                      >
                        {item.title}
                      </li>
                    ))}
                  </ThreeDotActions>
                </td>
              </tr>
            </tbody>
          </STable>
        </div>
      </div>
    </SBilling>
  );
};

export default Billing;
