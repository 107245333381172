import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import classes from './SpinnerLoadingMultipleTexts.module.scss';

const SpinnerLoadingMultipleTexts = ({
  isVisible,
  texts,
  animationDuration = 4,
  fullScreen = true,
  zIndex = 10000,
  textStyle,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const duration = animationDuration + (animationDuration * 10) / 100;

  useEffect(() => {
    if (isVisible && texts && texts.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, duration * 1000);

      return () => clearInterval(intervalId);
    }
  }, [isVisible, texts]);

  if (!isVisible) return null;

  return (
    <div
      style={{ zIndex }}
      onClick={(e) => e.stopPropagation()}
      className={classNames([classes.container, { [classes.fullScreen]: fullScreen }])}
    >
      <div className={classes.wrapper}>
        <div className={classes.dotLoader}>
          <div className='spinner' />
        </div>
        {!!texts && (
          <span
            key={texts[currentIndex]}
            className={classes.text}
            style={{
              animationDuration: `${duration}s`,
              ...textStyle,
            }}
          >
            {texts[currentIndex]}
          </span>
        )}
      </div>
    </div>
  );
};

export default SpinnerLoadingMultipleTexts;
