import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-2.svg';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import { getAlphabet, palette } from 'utils/constants';
import { getStopName } from 'componentsV2/Shipment/SplitShipment/SplitShipment.data';
import { stopPointTypes } from '../../CreateQuote.data';

const ShipmentStopType = ({ stop, stopIndex, onTypeSelect, hideAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Typography
      variant='c3'
      className='d-flex gap-2'
      style={{ color: getStopName(Number(stop?.stop_point_type)).color }}
    >
      STOP {getAlphabet(stopIndex)} {getStopName(Number(stop?.stop_point_type)).type}
      {!hideAction && (
        <ThreeDotActions
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          actionButton={<ArrowIcon fill={palette.gray700} style={{ transform: `rotate(90deg)` }} />}
        >
          {stopPointTypes.map((item) => (
            <li
              key={item.id}
              style={{
                backgroundColor: item.id === stop.stop_point_type ? palette.indigo500 : '',
                color: item.id === stop.stop_point_type ? palette.white : palette.gray900,
              }}
              onClick={() => {
                onTypeSelect(item);
                setAnchorEl(null);
              }}
            >
              {item.title}
            </li>
          ))}
        </ThreeDotActions>
      )}
    </Typography>
  );
};

export default ShipmentStopType;
