import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class OnboardingService {
  checkDot(dot_number) {
    return axios
      .get(`${API_URL}/user/registration/dot-check/${dot_number}`)
      .then((res) => {
        return res;
      })
      .catch((err) => err.response);
  }

  getFeatures() {
    return axios.get(`${API_URL}/user/registration/features`).then((res) => {
      return res;
    });
  }

  getPlanCarrier() {
    return axios.get(`${API_URL}/user/registration/plan/carrier`).then((res) => {
      return res;
    });
  }

  getHardware() {
    return axios
      .get(`${API_URL}/user/registration/hardware-paginated`, { params: { itemsPerPage: 1000 } })
      .then((res) => {
        return res;
      });
  }

  validateAccount(data) {
    return axios
      .post(`${API_URL}/user/registration/carrier/validate`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => err.response);
  }

  getAddons() {
    return axios.get(`${API_URL}/addon/list`).then((res) => {
      return res;
    });
  }
}

export default new OnboardingService();
