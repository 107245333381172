import React from 'react';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import styles from '../SecurityLog.module.css';

const driverPermissions = {
  camera_access: 'CAMERA ACCESS',
  location_access: 'LOCATION ACCESS',
  map_installed: 'MAP NOT INSTALLED',
  notification_access: 'NOTIFICATION ACCESS',
  storage_access: 'STORAGE ACCESS',
};

const getRowId = () => {
  const randomDecimal = Math.random();
  return Math.floor(randomDecimal * 1000000) + 1;
};

export const useColumns = ({ clearLock, securityLog, page }) => {
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'type',
      title: <ColumnHeader title='TYPE' field='login_attempt_status_id' />,
      render: (row) => {
        const { status, color } = row.login_attempt_status;
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='mb-1 d-flex align-items-center gap-2'>
              {status && (
                <span
                  style={{
                    fontSize: 11,
                    fontWeight: 500,
                    borderRadius: 4,
                    padding: '2px 6px',
                    color: `${color}`,
                    backgroundColor: color === '#14804A' ? '#E1FCEF' : '#FAF0F3',
                  }}
                >
                  {status}
                </span>
              )}
              {row.login_attempt_status?.id === 5 &&
                securityLog?.id === row.id &&
                row.tableData?.id === 0 &&
                page === 1 && (
                  <Typography
                    variant='s2'
                    style={{ color: palette.indigo500, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => clearLock(row.user_id)}
                  >
                    Clear Lock
                  </Typography>
                )}
            </div>
            <Typography variant='overLine2'>{convertToCustomerTime(row.created_at)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'device_name',
      title: <ColumnHeader title='DEVICE' field='device_type' />,
      render: (row) => (
        <Typography variant='overLine2'>
          {row.device_type} ({row.device_name})
        </Typography>
      ),
    },
    {
      field: 'version_id',
      title: <ColumnHeader title='VERSION ID' field='current_version' />,
      render: (row) => <Typography variant='overLine2'>{row.current_version}</Typography>,
    },
    {
      field: 'location',
      title: <ColumnHeader title='LOCATION' field='location' />,
      render: (row) => <Typography variant='overLine2'>{row.location || '-'}</Typography>,
    },
    {
      field: 'ip_address',
      title: <ColumnHeader title='IP ADDRESS' field='ip_address' />,
      render: (row) => <Typography variant='overLine2'>{row?.ip_address || '-'}</Typography>,
    },
    {
      field: 'version_status',
      title: <ColumnHeader title='DEVICE STATUS' field='version_status_id' />,
      render: (row) => {
        const device_name = row.version_status?.status?.toUpperCase();
        const { id, device_type, user_id, ...requiredPermissionsKeys } = row.driver_app_permissions || {};
        const permissionsRequired = Object.keys(requiredPermissionsKeys)
          .map((el) => ({
            id: getRowId(),
            name: driverPermissions[el],
            value: el,
          }))
          .filter((key) => requiredPermissionsKeys[key.value] === 0);

        return (
          <div className='d-flex flex-column gap-1'>
            <span
              style={{
                fontSize: 11,
                fontWeight: 500,
                borderRadius: 4,
                padding: '2px 6px',
                width: 'fit-content',
                color: row.version_status?.color,
                backgroundColor:
                  row.version_status?.color === '#38A06C'
                    ? '#E1FCEF'
                    : row.version_status?.color === '#2264E5'
                    ? '#D7E3FB'
                    : row.version_status?.color === '#F5BA0B'
                    ? '#FCF2E6'
                    : row.version_status?.color === '#DC4067'
                    ? '#FAF0F3'
                    : null,
              }}
            >
              {device_name}
            </span>
            <div className='d-flex w-100 flex-wrap gap-1'>
              {permissionsRequired?.map((item) => {
                return (
                  <span key={item?.id} className={styles.permission_required}>
                    {item?.name}
                  </span>
                );
              })}
            </div>
          </div>
        );
      },
    },
  ];
};
