import React from 'react';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { Icons } from 'assets/icons';
import authHeader from 'services/auth-header';
import { blockNotNumberChars, formatAmount } from 'utils/helpers';

const CartCard = ({
  summary,
  removeCart,
  cartItems,
  DeleteFromCart,
  hardware,
  handleClose,
  API_URL,
  setCartItemsData,
  setDeleteLoading,
  deleteLoading,
}) => {
  const currEle = summary?.hardware_prices?.hardware.find((ele) => ele.id === hardware.id);

  const handleAddCart = (i) => {
    removeCart(cartItems[i], cartItems[i].quantity + 1);
  };

  const handleAddQuantity = (i, value) => {
    if (value !== '' && value !== '0') {
      cartItems[i].quantity = value;
      removeCart(cartItems[i], parseInt(cartItems[i].quantity, 10));
    } else {
      cartItems[i].quantity = '1';
      removeCart(cartItems[i], parseInt(cartItems[i].quantity, 10));
    }
  };

  const handleRemoveCart = (i) => {
    removeCart(cartItems[i], cartItems[i].quantity - 1);
  };

  const handleDelete = (i) => {
    if (cartItems?.length > 1) {
      const Cart = [...cartItems];
      Cart.splice(i, 1);
      DeleteFromCart(Cart);
      const submitForPrices = {
        id: cartItems[i]?.objectId,
      };
      try {
        setDeleteLoading(true);
        axios
          .post(`${API_URL}/remove-hardware-cart`, submitForPrices, {
            headers: authHeader(),
          })
          .then(() => {
            setCartItemsData(Math.random());
            setDeleteLoading(false);
          });
      } catch (error) {
        setDeleteLoading(false);
      }
    } else {
      const submitForPrices = {
        id: cartItems[i]?.objectId,
      };
      try {
        setDeleteLoading(true);
        axios
          .post(`${API_URL}/remove-hardware-cart`, submitForPrices, {
            headers: authHeader(),
          })
          .then(() => {
            setCartItemsData(Math.random());
            localStorage.removeItem('price-summary');
            localStorage.removeItem('hardware');
            localStorage.removeItem('hardwareItem');
            handleClose();
            setDeleteLoading(false);
          });
      } catch (error) {
        setDeleteLoading(false);
      }
    }
  };

  return deleteLoading ? (
    <CircularProgress style={{ width: '20px', height: '20px', marginLeft: '220px' }} />
  ) : cartItems ? (
    cartItems?.map((value, index) => {
      const sameInPrices = summary?.hardware_prices?.hardware.find((ele) => ele.id === value.id);
      return (
        <>
          <div className='card-summary' key={value?.name}>
            <div className='card-summary-img'>
              <img src={value?.images[0]?.image} alt='img' />
            </div>
            <div className='card-summary-body'>
              <p className='body-b2 text-left' style={{ color: '#171C26', marginBottom: 0 }}>
                {value?.name}
              </p>
              <p className='caption-c1 text-left' style={{ color: '#4F5AED', marginTop: 0 }}>
                ${formatAmount(Number(sameInPrices?.price_per_item).toFixed(2))}
                {currEle?.license === 1
                  ? `/month /unit for 36 months license`
                  : currEle?.license === 0
                  ? `/one time purchase /unit`
                  : ``}
              </p>

              <div className='qty-btn'>
                <div className='hardware-add-btn'>
                  <button className='minus-btn plus-btn btn' onClick={() => handleRemoveCart(index)}>
                    -
                  </button>
                  <input
                    className='input-num'
                    value={value?.quantity}
                    onKeyDown={blockNotNumberChars}
                    onChange={(e) => handleAddQuantity(index, e.target.value)}
                  />
                  <button className='plus-btn btn' onClick={() => handleAddCart(index)}>
                    +
                  </button>
                </div>
              </div>
            </div>
            <div>
              <p
                className='subtitle-s2 ammount-width'
                style={{
                  color: '#171C26',
                  textAlign: 'right',
                  paddingRight: '10px',
                }}
              >
                ${formatAmount(sameInPrices?.price_per_quantity || 0)}
              </p>
              <div
                style={{
                  color: 'red',
                  marginTop: '30px',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '20px',
                  float: 'right',
                  marginRight: '20px',
                }}
                onClick={() => handleDelete(index)}
              >
                <img src={Icons?.CrossRedIcon} alt='' />
              </div>
            </div>
          </div>
          <div className='divider' style={{ margin: 0 }} />
        </>
      );
    })
  ) : (
    <div>No Cart items to display</div>
  );
};

export default CartCard;
