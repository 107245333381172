import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { Typography } from 'components/Typography';
import Autocomplete from 'common/Autocomplete';
import ThreeDotActions from 'common/ThreeDotActions';
import { getIncidentYears } from 'Api/DotMonitoring';
import { palette } from 'utils/constants';
import CustomButton from 'components/CustomButton/CustomButton';
import { ChartWrapper, SWrapper } from './StatsSection.styles';
import TotalIncidents from '../../Dashboard/components/Overview/TotalIncidents';
import NewIncidents from '../../Dashboard/components/Overview/NewIncidents';
import IncidentSummary from '../../DotMonitoring/StatsSection/components/IncidentSummary';

const StatsSection = ({ refreshData }) => {
  const navigate = useNavigate();
  const [year, setYear] = useState('All');
  const [anchorEl, setAnchorEl] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(async () => {
    const { data } = await getIncidentYears();
    setYearOptions(data);
  }, []);

  return (
    <SWrapper>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex gap-3'>
          <Typography variant='title1'>Incidents for </Typography>
          <Autocomplete
            options={['All', ...yearOptions]}
            style={{ width: '90px' }}
            value={year}
            onChange={(e, val) => {
              setYear(val);
            }}
            isOptionEqualToValue={(option, value) => option === value}
          />
        </div>
        <div style={{ width: 'fit-content' }}>
          <ThreeDotActions
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            actionButton={
              <CustomButton
                type='secondary'
                title=''
                leftIcon={<GearIcon fill={palette.gray700} />}
                styleButton={{ padding: '8px 12px', margin: 0 }}
                onClick={() => null}
              />
            }
            popoverStyles={{ margin: '10px 50px 0 0' }}
          >
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate('/company-settings', { state: { tabId: 9, subTabName: 'Incidents' } });
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              Incidents
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate('/company-settings', { state: { tabId: 9, subTabName: 'RulesAndConduct' } });
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              Rules & Conduct (Handbook)
            </li>
          </ThreeDotActions>
        </div>
      </div>
      <ChartWrapper>
        <NewIncidents year={year} refreshDat={refreshData} />
        <TotalIncidents year={year} refreshDat={refreshData} />
        <IncidentSummary year={year} refreshData={refreshData} />
      </ChartWrapper>
    </SWrapper>
  );
};

export default StatsSection;
