import React from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import style from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon.module.css';

const DriverAlertIcon = ({ alertData, driverAlert, shipmentAlert }) => {
  const { use } = useTheme();
  return (
    <>
      <div className={style.AlertHeader} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <p className={style.ExpiringItems} style={{ color: use(palette.dark800, palette.gray200) }}>
          {shipmentAlert || driverAlert || 'Equipment Alerts'}{' '}
        </p>
      </div>
      <div className={style.AlertBody} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        {alertData?.phoneError && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.phoneError}
          </p>
        )}
        {alertData?.emailError && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.emailError}
          </p>
        )}

        {alertData?.countryError && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.countryError}
          </p>
        )}
        {alertData?.stateError && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.stateError}
          </p>
        )}
        {alertData?.cityError && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.cityError}
          </p>
        )}
        {alertData?.formikErrors?.license_type && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.formikErrors?.license_type}
          </p>
        )}

        {alertData?.formikErrors?.department_id && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            {alertData?.formikErrors?.department_id}
          </p>
        )}
        {alertData?.canTravelError && (
          <p className={style.AlertMsg} style={{ color: use(palette.dark800, palette.gray200) }}>
            Can travel field is required
          </p>
        )}
      </div>
    </>
  );
};

export default DriverAlertIcon;
