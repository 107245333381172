import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  height: 100%;
  font-family: 'Inter', sans-serif;
  margin-top: 16px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const STableWrapper = styled.div`
  margin-top: 66px;
  margin-bottom: 100px;
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;
    margin-top: 12px;

    .MuiTabs-flexContainer {
      column-gap: 8px;
      overflow-x: scroll;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      padding: 3px 10px;
      text-transform: none;
      color: ${palette.gray900};
      border-bottom: 2px solid ${palette.white};

      &.Mui-selected {
        border-bottom: 2px solid ${palette.indigo500};
        color: ${palette.indigo500};

        :hover {
          border-bottom: 2px solid ${palette.indigo500};
        }
      }

      .MuiTab-iconWrapper {
        font-family: 'Inter', sans-serif;
        border-radius: 8px;
        padding: 2px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        background-color: #f0f1fa;
      }
    }

    .MuiButtonBase-root:hover {
      border-bottom: 2px solid ${palette.gray400};
    }
  }
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const STableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;
  min-width: 1030px;

  .filters-wrapper {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const SAmountWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 0 24px 5px 0;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .amount-input {
    input {
      height: 26px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
`;

export const SUnderlineText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${palette.indigo500};
  padding-left: 8px;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const STable = styled.table`
  width: 100%;
  margin-top: 12px;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
    white-space: nowrap;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border-bottom: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell {
    visibility: hidden;
    transition: visibility 0.2s;
  }

  :hover {
    background-color: ${palette.gray0};

    .actions-cell {
      visibility: visible;
    }
  }
`;

export const SModalWrapper = styled.div`
  .add-accident-modal-body {
    padding: 0 20px 16px 20px;
    height: calc(80vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
    background: ${palette.gray0};
  }
`;
