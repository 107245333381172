import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SHeaderWrapper = styled.div`
  position: relative;

  .onboarding-header {
    background: ${palette.indigo500};
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
  }

  @media (max-width: 768px) {
    .main-logo {
      width: 120px;
    }

    .onboarding-header {
      padding: 12px 8px;
    }
  }
`;

export const SCallButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  padding: 4px 12px;
  background: ${palette.white};
  box-shadow: ${palette.boxShadow2};
  border-radius: 6px;
  border: none;
  transition: all 0.2s;
  text-decoration: none;

  :hover {
    transform: scale(1.03);
  }

  @media (max-width: 768px) {
    padding: 2px 6px;
    column-gap: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    span {
      font-size: 12px !important;
    }
  }
`;

export const SStepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  background-color: ${({ $bgColor }) => $bgColor || palette.white};
  overflow: auto;

  .steps-horizontal-divider {
    width: 20px;
    height: 0;
    border-radius: 4px;
    background-color: ${palette.gray100};
    border: 2px solid ${palette.gray100};
    margin: auto 8px;
  }

  .step-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background: ${palette.gray50};
    color: ${palette.gray600};
    border-radius: 12px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    white-space: nowrap;

    &.active {
      color: ${palette.indigo500};
      background: ${palette.gray50};
    }
  }

  @media (max-width: 768px) {
    .step-button {
      font-size: 12px;
    }

    padding: 12px;
  }
`;
