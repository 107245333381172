import moment from 'moment';
import { initialRecurs } from 'components/CreateShipment/Schedule/Schedule.data';

export const MINUTES_IN_DAY = 1440;
export const MINUTES_IN_WEEK = 10080;

export const recurringDataConverter = (data) => {
  const { shipment_lane } = data;

  const recurringDays = shipment_lane.days;
  const shipmentsCount = shipment_lane.recurring_shipments_per_day;
  const pickupTimes = shipment_lane.day_wise_pickup_time;
  const pickupTimesTo = shipment_lane.day_wise_pickup_time_to;
  const deliveryTimesAfter = shipment_lane.day_wise_delivery_time_after;
  const deliveryTimesAfterTo = shipment_lane.day_wise_delivery_time_after_to;

  const recurs = initialRecurs.map((item) => {
    const countIndex = recurringDays.findIndex((i) => i === item.title);
    if (countIndex === -1) {
      return item;
    }

    const count = shipmentsCount[countIndex];
    const day_wise_pickup_time = pickupTimes?.[countIndex];
    const day_wise_pickup_time_to = pickupTimesTo?.[countIndex];
    const day_wise_delivery_time_after = deliveryTimesAfter?.[countIndex];
    const day_wise_delivery_time_after_to = deliveryTimesAfterTo?.[countIndex];

    return {
      ...item,
      amount: count || '',
      checked: countIndex !== -1,
      dateSettings: day_wise_pickup_time.map((i, index) => {
        const day_wise_delivery_time_after_array = day_wise_delivery_time_after[index].map((deliveryTime) => {
          const delivery_time = day_wise_pickup_time[index]
            ? moment(day_wise_pickup_time[index], 'HH:mm').add(deliveryTime, 'minutes').format('HH:mm')
            : null;

          const isTimeBefore = moment(delivery_time, 'HH:mm').isBefore(moment(day_wise_pickup_time[index], 'HH:mm'));

          return (
            isTimeBefore ? Math.ceil(deliveryTime / MINUTES_IN_DAY) : Math.floor(deliveryTime / MINUTES_IN_DAY)
          ).toString();
        });

        let counter = 0;

        const delivery_time_array = day_wise_delivery_time_after[index].map((deliveryTime) => {
          counter += Number(deliveryTime || 0);
          return day_wise_pickup_time[index]
            ? moment(day_wise_pickup_time[index], 'HH:mm').add(counter, 'minutes').format('HH:mm')
            : null;
        });

        const delivery_time_to_array = day_wise_delivery_time_after_to[index].map((deliveryTime, deliveryIndex) => {
          return delivery_time_array[deliveryIndex]
            ? moment(delivery_time_array[deliveryIndex], 'HH:mm').add(deliveryTime, 'minutes').format('HH:mm')
            : null;
        });

        return {
          day_wise_pickup_time: day_wise_pickup_time[index] || '',
          day_wise_pickup_time_to: day_wise_pickup_time_to[index] || '',
          day_wise_delivery_time_after: ['', ...day_wise_delivery_time_after_array],
          delivery_time: ['', ...delivery_time_array],
          delivery_time_to: ['', ...delivery_time_to_array],
        };
      }),
    };
  });

  return {
    ...data,
    shipment_lane: { ...data.shipment_lane, recurs },
  };
};
