import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import ItemModal from 'pages/CompanySettings/pagesComponents/ShipmentTypes/ItemModal';
import PopoverBillingCharges from 'components/CreateShipment/BillingCharges/PopoverBillingCharges';
import {
  BILLING_GRAND_TOTAL_CURRENCY,
  getChargesTemplateShipment,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { useTheme } from 'context/themeContext';
import { handleToaster } from 'store/reducers/root.reducer';
import { CURRENCY, palette } from 'utils/constants';
import {
  deleteShipmentBillingCharge,
  UpdateShipmentBillingHeader,
  updateRecurringLaneBilling,
  deleteRecurringLaneBillingCharge,
} from 'Api/Shipment';
import BillingCharge from 'components/TableShipments/detailsRow/steps/BillingCharges/BillingCharge';
import { getFullPrices } from 'Api/Planner';
import { BillingChargesContext } from './BillingChargeChild';
import styles from './billingCharges.module.css';

const BillingChargesBody = ({ shipment, isRecurring, chargeErrors, setChargeErrors }) => {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const [disabledDelete, setDisabledDelete] = useState(false);
  const [loadingDeleteChargeItem, setLoadingDeleteChargeItem] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);

  const {
    values,
    selectedCharges,
    updateSelectedCharges,
    selectedGrandTotalForm,
    updateSelectedGrandTotal,
    count,
    allChargesData,
    getChargeType,
    indexPrefix,
  } = useContext(BillingChargesContext);
  const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(selectedGrandTotalForm?.id));
  const [fuelPrice, setFuelPrice] = useState(null);

  const getFuelPrice = async () => {
    try {
      const { data } = await getFullPrices();
      setFuelPrice(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onDeleteCharge = (arrayHelpers, index, charge) => {
    const nextValues = {
      ...values,
      billing_charges: values.billing_charges.filter((iterationCharge, idx) => idx !== index),
    };
    nextValues.billing_charges.length === 1 && setDisabledDelete(true);

    const foundedSelectedChargesIndex = selectedCharges.findIndex((el) => el?.id === charge?.id);

    if (foundedSelectedChargesIndex !== -1) {
      setLoadingDeleteChargeItem(index);
      (isRecurring ? deleteRecurringLaneBillingCharge(charge?.id) : deleteShipmentBillingCharge(charge?.id)).then(
        (res) => {
          res?.message && dispatch(handleToaster({ message: res?.message, visible: true, type: 'success' }));
          const cloneSelectedCharges = [...selectedCharges];
          cloneSelectedCharges.splice(foundedSelectedChargesIndex, 1);
          updateSelectedCharges(cloneSelectedCharges);
          setLoadingDeleteChargeItem(false);
          arrayHelpers.remove(index);
        }
      );
    } else {
      arrayHelpers.remove(index);
    }
  };

  useEffect(() => {
    if (selectedCharges.length >= 2) {
      setDisabledDelete(false);
    } else {
      setDisabledDelete(true);
    }
  }, [selectedCharges]);

  useEffect(() => {
    getFuelPrice();
  }, []);

  return (
    <div className={styles.body_chief_container} style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
      <div className={styles.header_titles_wrapper}>
        <div className={styles.titles}>
          <div className='d-flex justify-content-between'>
            <Typography variant='overLine' style={{ minWidth: 386 }}>
              CHARGES
              <GearIcon className='pointer ms-3' onClick={() => setOpenItemModal(true)} />
            </Typography>
          </div>
          <Typography variant='overLine' style={{ minWidth: 80 }}>
            QTY
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 160 }}>
            RATE
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 160 }}>
            SUB-TOTAL
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 120 }} />
        </div>
        <div className={styles.line} style={{ margin: '4px 0 12px -20px' }} />
      </div>

      <FieldArray
        name='billing_charges'
        render={(arrayHelpers) => {
          return (
            <div style={{ marginBottom: 30 }}>
              {values.billing_charges?.map((charge, index) => (
                <BillingCharge
                  key={charge.id || index}
                  charge={charge}
                  index={index}
                  currencyData={currencyData}
                  chargeErrors={chargeErrors}
                  setChargeErrors={setChargeErrors}
                  miles={Number(shipment?.empty_miles || 0) + Number(shipment?.loaded_miles || 0)}
                  fuelPrice={fuelPrice}
                  disabledDelete={disabledDelete}
                  loadingDeleteChargeItem={loadingDeleteChargeItem}
                  onDeleteCharge={() => onDeleteCharge(arrayHelpers, index, charge)}
                />
              ))}

              <GrandTotal
                count={count}
                values={values}
                arrayHelpers={arrayHelpers}
                currencyData={currencyData}
                allChargesData={allChargesData}
                selectedGrandTotalForm={selectedGrandTotalForm}
                updateSelectedGrandTotal={(data) => updateSelectedGrandTotal(data, indexPrefix)}
                isRecurring={isRecurring}
              />
            </div>
          );
        }}
      />
      {openItemModal && (
        <ItemModal open={openItemModal} onClose={() => setOpenItemModal(false)} onSuccess={getChargeType} />
      )}
    </div>
  );
};

export default BillingChargesBody;

const GrandTotal = ({
  arrayHelpers,
  selectedGrandTotalForm,
  updateSelectedGrandTotal,
  count,
  allChargesData,
  values,
  currencyData,
  isRecurring,
}) => {
  const { use } = useTheme();
  const dispatch = useDispatch();

  const onChangeItem = (item) => {
    updateSelectedGrandTotal({ ...item });
    const { customer_id, reference_id, group, contact_user_id } = values || {};
    const params = new URLSearchParams();
    params.append('customer_id', customer_id);
    params.append('reference_id', reference_id || '');
    params.append('currency_id', item.id);
    !!group && params.append('group_id', group);
    !!contact_user_id && params.append('contact_user_id', contact_user_id);

    (isRecurring
      ? updateRecurringLaneBilling(allChargesData?.id, params)
      : UpdateShipmentBillingHeader({ params, id: allChargesData?.id })
    ).then((res) => {
      res?.message && dispatch(handleToaster({ message: res?.message, visible: true, type: 'success' }));
    });
  };

  const style = useMemo(() => {
    return {
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
    };
  }, []);

  return (
    <div className={styles.another_wrapper} style={{ justifyContent: 'space-between' }}>
      <CustomButton
        type='secondary'
        title='Add Another'
        leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
        styleButton={style.addAnotherButton}
        styleTitle={style.addAnotherTitle}
        onClick={() => arrayHelpers.push(getChargesTemplateShipment())}
      />
      <div className={styles.grand_total}>
        <Typography variant='s2' style={{ marginBottom: 8 }}>
          Grand Total
        </Typography>
        <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
        <div className={styles.grand_total_bottom_wrapper}>
          <Typography variant='s2'>
            {currencyData?.symbol || '$'}
            {count}
          </Typography>
          <PopoverBillingCharges
            data={BILLING_GRAND_TOTAL_CURRENCY}
            onClick={(item) => onChangeItem(item)}
            selectedItem={selectedGrandTotalForm}
          />
        </div>
      </div>
    </div>
  );
};
