import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import { CURRENCY, formatNumber, palette } from 'utils/constants';
import { getQuoteTotalDistance } from 'componentsV2/Planner/Quotes/CreateQuote/CreateQuote.data';

const NetProfit = ({ form, fuelPrice }) => {
  const { values } = form;
  const { currency } = useSelector((state) => state.root);
  const totalDistance = getQuoteTotalDistance(values);

  const currencySymbol = CURRENCY[values.billing.currency?.title?.toLowerCase()]?.symbol;
  const lineItemsCurrencySymbol = CURRENCY[values.line_items_currency?.title?.toLowerCase()]?.symbol;

  const charges = values.charges.reduce((acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0), 0);
  const additionalCharges = values.line_items.reduce(
    (acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0),
    0
  );
  const estimatedCost =
    Number(values.estimated_cost_count || 0) * Number(values.estimated_cost_rate || 0) +
    (Math.round(totalDistance) / 6) * Number(fuelPrice || 0);

  return (
    <div className='w-100'>
      <div className='mb-2'>
        <Typography variant='s1'>Net Profit</Typography>
      </div>
      <div className='create-quote-section'>
        <div>
          <div className='d-flex justify-content-between align-items-center gap-3 mb-1'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              Charges
            </Typography>
            <Typography variant='s2'>
              {currencySymbol}
              {formatNumber(charges)}
            </Typography>
          </div>
          <div className='d-flex justify-content-between align-items-center gap-3 mb-1'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              Additional Charges
            </Typography>
            <Typography variant='s2'>
              {lineItemsCurrencySymbol}
              {formatNumber(additionalCharges)}
            </Typography>
          </div>
          <div className='d-flex justify-content-between align-items-center gap-3 mb-1'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              Estimated Costs
            </Typography>
            <Typography variant='s2'>
              {currency}
              {formatNumber(estimatedCost)}
            </Typography>
          </div>
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <Typography variant='s1' style={{ color: palette.green600 }}>
              Estimated Net Profit
            </Typography>
            <Typography variant='h5' style={{ color: palette.green600 }}>
              {currency}
              {formatNumber((charges || 0) + (additionalCharges || 0) + (estimatedCost || 0))}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetProfit;
