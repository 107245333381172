import { formatNumber, palette } from 'utils/constants';
import { oneTimeAdditionType } from '../../../Settlement/converters';
import { calcAppliedAmount, calcAppliedTaxAmount, getTaxAmount, twoDecimals } from '../../../Settlement/helpers';

export const createBalancesTable = (doc, data) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let end;
  const { balances, userType, currency, settlementId } = data;

  doc.setFontSize(11);
  doc.setTextColor('#4F5AED');
  doc.setFont('Inter', 'normal', 400);
  doc.text('Balances', 16, 16);
  doc.setDrawColor(188, 194, 206);
  doc.line(16, 18, pageWidth - 16, 18);
  doc.line(16, 18.1, pageWidth - 16, 18.1);

  // Header
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Name', 16, 22);
  doc.text('Payments', 65, 22, { align: 'center' });
  doc.text('Payment Amount', 100, 22, { align: 'center' });
  doc.text('Original Balance', 145, 22, { align: 'center' });
  doc.text('Balance Remain', pageWidth - 32, 22, { align: 'center' });

  // Content
  doc.setFont('Inter', 'normal', 400);
  [...(balances || [])]?.forEach((item, index, array) => {
    const processedSettlements = item.deductions?.[0]?.settlement?.filter(
      (settlement) =>
        settlement[`${userType}_settlement`].status_id === 3 || settlement.settlement_id === Number(settlementId)
    );
    const paidAmount = (processedSettlements || []).reduce((acc, cur) => acc + Number(cur.amount), 0);

    doc.text(`${item.name}`, 16, 28 + index * 5);
    doc.text(`${(processedSettlements || []).length} out of ${item.amortized_months}`, 65, 28 + index * 5, {
      align: 'center',
    });
    doc.text(`${currency}${twoDecimals(item.payment_amount)}`, 100, 28 + index * 5, {
      align: 'center',
    });
    doc.text(`${currency}${twoDecimals(item.original_balance)}`, 145, 28 + index * 5, { align: 'center' });
    doc.text(`${currency}${twoDecimals(item.original_balance - paidAmount)}`, pageWidth - 32, 28 + index * 5, {
      align: 'center',
    });

    if (index === array.length - 1) {
      end = 28 + index * 5;
    }
  });

  return end || 28;
};

export const createDeductionsTable = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let end;
  const { deductions, currency, transactions } = data;

  doc.setFontSize(11);
  doc.setTextColor('#4F5AED');
  doc.setFont('Inter', 'normal', 400);
  doc.text('Deductions', 16, top);
  doc.setDrawColor(188, 194, 206);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
  doc.line(16, top + 2.1, pageWidth - 16, top + 2.1);

  // Header
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date / Time', 16, top + 6);
  doc.text('Deduction Type', 50, top + 6);
  doc.text('Quantity', pageWidth - 85, top + 6, { align: 'center' });
  doc.text('Amount Per Qty', pageWidth - 55, top + 6, { align: 'center' });
  doc.text('Total', pageWidth - 32, top + 6);

  // Content
  doc.setFont('Inter', 'normal', 400);
  [...(deductions || []), ...(transactions || [])]
    ?.filter((item) => !!item.apply)
    ?.forEach((item, index, array) => {
      if (item.deduction_type === 'transaction') {
        doc.text(`${item.transaction.date}`, 16, top + 12 + index * 5);
        doc.text(
          `${item.transaction.purchase_description} - REF# ${item.transaction.invoice} - ${item.transaction.merchant}`,
          50,
          top + 12 + index * 5
        );
      } else {
        doc.text(`${item.date}`, 16, top + 12 + index * 5);
        doc.text(`${item.deduction_type.deduction_type}`, 50, top + 12 + index * 5);
        doc.text(`${item.quantity}`, pageWidth - 85, top + 12 + index * 5, { align: 'center' });
        doc.text(`${currency}${twoDecimals(item.per_quantity_amount)}`, pageWidth - 55, top + 12 + index * 5, {
          align: 'center',
        });
      }

      doc.setFont('Inter', 'normal', 700);
      doc.text(`- ${currency}${twoDecimals(item.amount)}`, pageWidth - 32, top + 12 + index * 5);
      doc.setFont('Inter', 'normal', 400);

      if (index === array.length - 1) {
        end = top + 12 + index * 5;
      }
    });

  return end || top + 12;
};

export const createAdditionsTable = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let end;
  const { additions, absences, detention, overtime, oneTimeAdditions, currency, dateFormat } = data;

  doc.setFontSize(11);
  doc.setTextColor('#4F5AED');
  doc.setFont('Inter', 'normal', 400);
  doc.text('Other Additions', 16, top);
  doc.setDrawColor(188, 194, 206);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
  doc.line(16, top + 2.1, pageWidth - 16, top + 2.1);

  // Header
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date / Time', 16, top + 6);
  doc.text('Addition Type', 50, top + 6);
  doc.text('Quantity', pageWidth - 85, top + 6, { align: 'center' });
  doc.text('Amount Per Qty', pageWidth - 55, top + 6, { align: 'center' });
  doc.text('Total', pageWidth - 32, top + 6);

  // Content
  doc.setFont('Inter', 'normal', 400);
  [...(additions || []), ...(absences || []), ...(detention || []), ...(overtime || []), ...(oneTimeAdditions || [])]
    ?.filter((item) => !!item.apply)
    ?.forEach((item, index, array) => {
      doc.text(`${dateFormat.formatDate(item.date || item.created_at)}`, 16, top + 12 + index * 5);
      if (item.type === 'Detention') {
        const stopName = item.stop_name.length <= 30 ? item.stop_name : `${item.stop_name.substring(0, 30)}...`;
        doc.text(
          `Detention - ID: ${item?.shipment_id} - ${stopName} (${Math.floor(item.time / 60)}h ${item.time % 60}m)`,
          50,
          top + 12 + index * 5
        );
      } else if (item.type === 'Overtime Pay') {
        doc.text(
          `Overtime Pay - ID ${item.time_tracker} - ${Math.floor(item.time / 60)}h ${item.time % 60}m`,
          50,
          top + 12 + index * 5
        );
      } else if (item.type === 'Paid Time Off (PTO)') {
        doc.text(
          `${item.type}   ${dateFormat.formatDate(item.start_date)} - ${dateFormat.formatDate(item.end_date)}`,
          50,
          top + 12 + index * 5
        );
      } else if (item.type === 'one_time_addition') {
        doc.text(
          `Shipment ${item?.shipment_id} - ${oneTimeAdditionType[item.addition_type_id]} ${
            item.addition_type_id === 2 && !!item.shipment_stop?.stop_point?.location_name
              ? ` - ${item.shipment_stop.stop_point.location_name}`
              : ''
          }`,
          50,
          top + 12 + index * 5
        );
      } else {
        doc.text(
          `${item?.addition_type?.addition_type || item.type} ${
            item?.shipment_driver_pay ? ` - Shipment ${item.shipment_driver_pay.shipment_id}` : ''
          }`,
          50,
          top + 12 + index * 5
        );
        doc.text(`${item.quantity}`, pageWidth - 85, top + 12 + index * 5, { align: 'center' });
        doc.text(`${currency}${twoDecimals(item.per_quantity_amount)}`, pageWidth - 55, top + 12 + index * 5, {
          align: 'center',
        });
      }

      doc.setFont('Inter', 'normal', 700);
      doc.text(`${currency}${twoDecimals(item.amount)}`, pageWidth - 32, top + 12 + index * 5);
      doc.setFont('Inter', 'normal', 400);

      if (index === array.length - 1) {
        end = top + 12 + index * 5;
      }
    });

  return end || top + 12;
};

export const createTaxesTable = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let end;
  const { taxes, currency, gross_revenue, deductions, additions } = data;

  doc.setFontSize(11);
  doc.setTextColor('#4F5AED');
  doc.setFont('Inter', 'normal', 400);
  doc.text('Taxes', 16, top);
  doc.setDrawColor(188, 194, 206);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
  doc.line(16, top + 2.1, pageWidth - 16, top + 2.1);

  // Header
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date / Time', 16, top + 6);
  doc.text('Tax Type', 50, top + 6);
  doc.text('Percent', pageWidth - 85, top + 6, { align: 'center' });
  doc.text('Total', pageWidth - 32, top + 6);

  // Content
  doc.setFont('Inter', 'normal', 400);
  taxes
    ?.filter((item) => !!item.apply)
    ?.forEach((item, index, arr) => {
      doc.text(`${item.date}`, 16, top + 12 + index * 5);
      doc.text(`${item.tax_type.tax_type}`, 50, top + 12 + index * 5);
      doc.text(`${item.percent}%`, pageWidth - 85, top + 12 + index * 5, { align: 'center' });
      doc.setFont('Inter', 'normal', 700);
      doc.text(
        `- ${currency}${
          item.percent ? formatNumber(getTaxAmount({ tax: item, deductions, additions, gross: gross_revenue })) : '0.00'
        }`,
        pageWidth - 32,
        top + 12 + index * 5
      );
      doc.setFont('Inter', 'normal', 400);
      if (index === arr.length - 1) {
        end = top + 12 + index * 5;
      }
    });

  return end || top + 12;
};

export const createBenefitsTable = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let end;
  const { benefits, currency } = data;

  doc.setFontSize(11);
  doc.setTextColor('#4F5AED');
  doc.setFont('Inter', 'normal', 400);
  doc.text('Benefits', 16, top);
  doc.setDrawColor(188, 194, 206);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
  doc.line(16, top + 2.1, pageWidth - 16, top + 2.1);

  // Header
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date / Time', 16, top + 6);
  doc.text('Benefit Type', 50, top + 6);
  doc.text('Quantity', pageWidth - 85, top + 6, { align: 'center' });
  doc.text('Amount Per Qty', pageWidth - 55, top + 6, { align: 'center' });
  doc.text('Total', pageWidth - 32, top + 6);

  // Content
  doc.setFont('Inter', 'normal', 400);
  benefits
    ?.filter((item) => !!item.apply)
    ?.forEach((item, index, arr) => {
      doc.text(`${item.date}`, 16, top + 12 + index * 5);
      doc.text(`${item.benefit_type.benefit_type}`, 50, top + 12 + index * 5);
      doc.text(`${item.quantity}`, pageWidth - 85, top + 12 + index * 5, { align: 'center' });
      doc.text(`${currency}${twoDecimals(item.per_quantity_amount)}`, pageWidth - 55, top + 12 + index * 5, {
        align: 'center',
      });
      doc.setFont('Inter', 'normal', 700);
      doc.text(`- ${currency}${twoDecimals(item.amount)}`, pageWidth - 32, top + 12 + index * 5);
      doc.setFont('Inter', 'normal', 400);

      if (index === arr.length - 1) {
        end = top + 12 + index * 5;
      }
    });

  return end || top + 12;
};

export const createTotalSettlementSection = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const left = pageWidth / 2;

  const { settlementDetails, deductions, additions, taxes, benefits, transactions, currency, yearToDate } = data;
  const { taxes: ytdTaxes, benefits: ytdBenefits, take_home, other, gross, total_miles, shipments_count } = yearToDate;
  const { user_type, shipments, loaded_miles, empty_miles } = settlementDetails || {};

  const deductionsSum = (calcAppliedAmount(deductions || []) || 0) + calcAppliedAmount(transactions || 0);
  const taxesSum = calcAppliedTaxAmount(taxes || [], deductions || [], additions || [], settlementDetails?.gross) || 0;
  const benefitsSum = calcAppliedAmount(benefits || []) || 0;

  const ytdGross = Number(settlementDetails?.gross) + Number(gross);
  const ytdDeductionsSum = Number(other?.deductions_sum) + Number(deductionsSum);
  const ytdTaxesSum = Number(ytdTaxes.total_amount) + Number(taxesSum);
  const ytdBenefitsSum = Number(ytdBenefits?.total_amount) + Number(benefitsSum);
  const ytdTakeHome = Number(settlementDetails?.take_home) + Number(take_home);

  const deductionTotalPercent = (Number(ytdDeductionsSum) * 100) / Number(ytdGross);
  const taxesTotalPercent = (Number(ytdTaxesSum) * 100) / Number(ytdGross);
  const benefitsTotalPercent = (Number(ytdBenefitsSum) * 100) / Number(ytdGross);
  const takeHomePercent = (Number(ytdTakeHome) * 100) / Number(ytdGross);

  doc.setFillColor(20, 128, 74);
  doc.rect(16, top, pageWidth / 2 - 20, 7, 'F');
  doc.rect(left, top, pageWidth / 2 - 16, 7, 'F');
  doc.setFontSize(10);
  doc.setTextColor(palette.white);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Current Summary', left + 4, top + 5);
  doc.text('Year to Date', 20, top + 5);

  doc.setFillColor(209, 41, 83);
  doc.circle(18, top + 11, 1.5, 'F');
  doc.setFillColor(59, 68, 190);
  doc.circle(18, top + 17, 1.5, 'F');
  doc.setFillColor(245, 186, 11);
  doc.circle(18, top + 23, 1.5, 'F');
  doc.setFillColor(118, 127, 251);
  doc.circle(18, top + 29, 1.5, 'F');

  doc.setDrawColor(188, 194, 206);
  doc.line(16, top + 14, pageWidth / 2 - 4, top + 14);
  doc.line(16, top + 20, pageWidth / 2 - 4, top + 20);
  doc.line(16, top + 26, pageWidth / 2 - 4, top + 26);
  doc.line(16, top + 32, pageWidth / 2 - 4, top + 32);
  if (user_type === 2) {
    doc.line(16, top + 42, pageWidth / 2 - 4, top + 42);
  }

  doc.setTextColor('#171C26');
  doc.text(`Taxes - ${formatNumber(taxesTotalPercent)}%`, 22, top + 12);
  doc.text(`Benefits - ${formatNumber(benefitsTotalPercent)}%`, 22, top + 18);
  doc.text(`Deductions - ${formatNumber(deductionTotalPercent)}%`, 22, top + 24);
  doc.text(`Take Home - ${formatNumber(takeHomePercent)}%`, 22, top + 30);
  if (user_type === 2) {
    doc.text(`Miles Driven`, 22, top + 40);
    doc.text(`Shipments Completed`, 22, top + 46);
  }

  doc.text('Gross Pay:', left + 4, top + 12);
  doc.text('Taxes:', left + 4, top + 18);
  doc.text('Benefits:', left + 4, top + 24);
  doc.text('Deductions:', left + 4, top + 30);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${currency}${formatNumber(ytdTaxesSum)}`, pageWidth / 2 - 7, top + 12, { align: 'right' });
  doc.text(`${currency}${formatNumber(ytdBenefitsSum)}`, pageWidth / 2 - 7, top + 18, { align: 'right' });
  doc.text(`${currency}${formatNumber(ytdDeductionsSum)}`, pageWidth / 2 - 7, top + 24, { align: 'right' });
  doc.text(`${currency}${formatNumber(ytdTakeHome)}`, pageWidth / 2 - 7, top + 30, { align: 'right' });
  if (user_type === 2) {
    doc.text(
      `${Number(total_miles) + Number(loaded_miles || 0) + Number(empty_miles || 0)}`,
      pageWidth / 2 - 7,
      top + 40,
      { align: 'right' }
    );
    doc.text(`${Number(shipments_count) + (shipments?.length || 0)}`, pageWidth / 2 - 7, top + 46, { align: 'right' });
  }

  doc.text(
    settlementDetails?.gross ? `${currency}${formatNumber(settlementDetails.gross.toFixed(2))}` : 'N/A',
    pageWidth - 16,
    top + 12,
    { align: 'right' }
  );
  doc.text(`- ${currency}${formatNumber(taxesSum)}`, pageWidth - 16, top + 18, { align: 'right' });
  doc.text(`- ${currency}${formatNumber(benefitsSum)}`, pageWidth - 16, top + 24, { align: 'right' });
  doc.text(`- ${currency}${formatNumber(deductionsSum)}`, pageWidth - 16, top + 30, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.line(left, top + 32, pageWidth - 16, top + 32);

  doc.setFont('Inter', 'normal', 400);
  doc.text('Total Net Pay:', left + 4, top + 38);

  doc.setFont('Inter', 'normal', 700);
  doc.setFontSize(15);
  doc.text(`${currency}${formatNumber(settlementDetails?.take_home)}`, pageWidth - 16, top + 38, { align: 'right' });
};
