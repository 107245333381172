import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { palette, STOP_POINT_TAB } from 'utils/constants';
import { onChangeInventoryTab, onChangeEquipmentHistoryTab } from 'store/reducers/stopPoint.reducer';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as EditPensilIcon } from 'assets/icons/editPensle.svg';
import { SimpleLoader } from 'common/Loader';
import CustomButton from 'components/CustomButton/CustomButton';
import { Typography } from 'components/Typography';
import OverLayWrapper from 'components/TablePlaner/helpers/RecurrningDetails/helpers/OverLayWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Walmart.module.css';
import { HEADER_STATUSES, WALMART_TABS } from './constants/constants';
import { getAverageTimeConverted } from './helpers';

const WalmartHeader = ({
  onBackPlanner,
  overLayDelete,
  showStatuses,
  setShowStatuses,
  overLayChange,
  rowData,
  setShowIdModal,
  equipmentAtStop,
  loading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, tab: currentTab } = useParams();

  const onChangeTab = (tab) => {
    if (currentTab !== tab.tab) {
      navigate(`/stop-point/${id}/${tab.tab}`);
    }
  };

  const founded = HEADER_STATUSES.find((st) => st.id === rowData?.stop_point?.status_id);

  return (
    <div className={classNames(styles.header, styles.light)}>
      <div className={styles.title_wrapper}>
        <div className={styles.title_block}>
          <Typography variant='h1' style={{ color: palette.gray900 }}>
            {rowData?.stop_point?.location_name}
          </Typography>
        </div>
        <div onClick={onBackPlanner} className={styles.back_planner_wrapper}>
          <ArrowLeft width={10} height={10} />
          <Typography variant='c1' className={styles.back_planner}>
            Go Back
          </Typography>
        </div>
      </div>

      {founded && (
        <OverLayWrapper
          deleted={false}
          show={showStatuses}
          data={HEADER_STATUSES}
          onChange={overLayChange}
          onDelete={overLayDelete}
          onChaneShow={setShowStatuses}
          defaultSelected={founded}
        />
      )}
      <div className={styles.customer_contact_wrapper}>
        <div className={styles.customer_wrapper}>
          <div>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              System ID
            </Typography>
          </div>
          <div className={styles.customer}>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {rowData?.stop_point?.id}
            </Typography>
          </div>
        </div>
        <div className={styles.contact_wrapper}>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div>
              <div className={styles.contact_header}>
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  ID
                </Typography>
                <div
                  onClick={() => {
                    setShowIdModal(true);
                  }}
                  className={styles.edit_pensle}
                >
                  <EditPensilIcon />
                </div>
              </div>
              <div className={styles.contact}>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  {rowData?.stop_point?.stop_point_id}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contact_wrapper}>
          <div className={styles.contact_header}>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Inventory
            </Typography>
          </div>
          <div className={styles.contact}>
            <div className={styles.inventorVehiclesWrapper}>
              <Typography
                variant='b2'
                style={{ color: palette.gray900 }}
                className={styles.inventorVehicles}
                onClick={() => {
                  dispatch(onChangeInventoryTab(1));
                  navigate(`/stop-point/${id}/${STOP_POINT_TAB.INVENTORY}`);
                }}
              >
                Vehicles
              </Typography>
              {equipmentAtStop?.vehicles_count ? (
                <span style={{ backgroundColor: '#E9EDF5', padding: '0 8px', borderRadius: 8, fontSize: 11 }}>
                  {equipmentAtStop?.vehicles_count}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
            <div
              className={styles.inventorTrailersWrapper}
              onClick={() => {
                dispatch(onChangeInventoryTab(2));
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.INVENTORY}`);
              }}
            >
              <Typography variant='b2' style={{ color: palette.gray900 }} className={styles.inventorTrailers}>
                Trailers
              </Typography>
              {equipmentAtStop?.trailers_count ? (
                <span style={{ backgroundColor: '#E9EDF5', padding: '0 8px', borderRadius: 8, fontSize: 11 }}>
                  {equipmentAtStop?.trailers_count}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.contact_wrapper}>
          <div className={styles.contact_header}>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Average Wait Time
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center', height: 16, marginTop: '4px' }}>
            {loading ? (
              <div>
                <SimpleLoader loading={loading} size={16} />
              </div>
            ) : (
                [STOP_POINT_TAB.INVENTORY, STOP_POINT_TAB.EQUIPMENT_HISTORY].includes(currentTab)
                  ? equipmentAtStop?.average_time
                  : rowData?.stop_point?.average_waiting_time
              ) ? (
              <span style={{ backgroundColor: '#E9EDF5', padding: '0 8px', borderRadius: 8, fontSize: 11 }}>
                {getAverageTimeConverted(
                  [STOP_POINT_TAB.INVENTORY, STOP_POINT_TAB.EQUIPMENT_HISTORY].includes(currentTab)
                    ? equipmentAtStop?.average_time
                    : rowData?.stop_point?.average_waiting_time
                )}
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
        </div>
        <div className={styles.contact_wrapper}>
          <div className={styles.contact_header}>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Visits
            </Typography>
          </div>
          <div className='d-flex gap-3'>
            <div className={styles.inventorVehiclesWrapper}>
              <Typography
                variant='b2'
                style={{ color: palette.gray900 }}
                className={styles.inventorVehicles}
                onClick={() => {
                  dispatch(onChangeEquipmentHistoryTab(1));
                  navigate(`/stop-point/${id}/${STOP_POINT_TAB.EQUIPMENT_HISTORY}`);
                }}
              >
                Vehicles
              </Typography>
              {equipmentAtStop?.vehicle_visits ? (
                <span style={{ backgroundColor: '#E9EDF5', padding: '0 8px', borderRadius: 8, fontSize: 11 }}>
                  {equipmentAtStop?.vehicle_visits}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
            <div className={styles.inventorVehiclesWrapper}>
              <Typography
                variant='b2'
                style={{ color: palette.gray900 }}
                className={styles.inventorVehicles}
                onClick={() => {
                  dispatch(onChangeEquipmentHistoryTab(2));
                  navigate(`/stop-point/${id}/${STOP_POINT_TAB.EQUIPMENT_HISTORY}`);
                }}
              >
                Trailers
              </Typography>
              {equipmentAtStop?.trailer_visits ? (
                <span style={{ backgroundColor: '#E9EDF5', padding: '0 8px', borderRadius: 8, fontSize: 11 }}>
                  {equipmentAtStop?.trailer_visits}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line} />

      <div className={styles.tabs_container}>
        {WALMART_TABS.map((tab) => {
          const active = currentTab === tab.tab;
          return (
            <div key={tab?.id} className={classNames(styles.tabs_wrapper, { [styles.has_a_hover]: !active })}>
              <CustomButton
                type={active ? 'primary' : 'secondary'}
                title={tab?.title}
                onClick={() => onChangeTab(tab)}
                className={styles.tabs}
                styleTitle={{ padding: '2px 10px' }}
                hover={false}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WalmartHeader;
