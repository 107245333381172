import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import Radio from 'common/Radio';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './FormItem.data';
import { SActions, STableRow } from '../Payroll.styles';

const FormItem = ({
  data,
  onSubmit,
  onDelete,
  showPercent,
  flatOrPercent,
  showTaxExempt,
  taxOptions,
  accounts,
  loading,
}) => {
  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues: getInitialValues(data, accounts),
    validationSchema,
    onSubmit,
  });

  return (
    <STableRow>
      <td>
        <Input
          name='type'
          onChange={handleChange}
          value={values.type}
          error={touchedErrors.type}
          style={{ maxWidth: '400px' }}
        />
      </td>
      <td>
        {flatOrPercent ? (
          <div className='d-flex align-items-center gap-3'>
            <div className='d-flex flex-column gap-2'>
              <Radio
                label='Flat'
                value={0}
                checked={values.is_percent === 0}
                onChange={() => handleChange('is_percent', 0)}
              />
              <Radio
                label='Percent'
                value={1}
                checked={values.is_percent === 1}
                onChange={() => handleChange('is_percent', 1)}
              />
            </div>
            {values.is_percent ? (
              <InputWithIcon
                icon='%'
                width='120px'
                type='number'
                name='amount'
                onChange={handleChange}
                placeholder='0.00'
                value={values.amount}
                error={touchedErrors.amount}
              />
            ) : (
              <InputWithIcon
                width='120px'
                type='number'
                name='amount'
                onChange={handleChange}
                placeholder='0.00'
                value={values.amount}
                error={touchedErrors.amount}
              />
            )}
          </div>
        ) : (
          <InputWithIcon
            icon={showPercent ? '%' : ''}
            width='120px'
            type='number'
            name='amount'
            onChange={handleChange}
            placeholder={showPercent ? '0' : '0.00'}
            value={values.amount}
            error={touchedErrors.amount}
          />
        )}
      </td>
      {accounts && (
        <td>
          <Autocomplete
            width='240px'
            name='account'
            labelKey='account_name'
            options={accounts}
            value={values.account}
            onChange={(e, val) => handleChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </td>
      )}
      {taxOptions && (
        <td>
          <Autocomplete
            multiple
            width='100%'
            name='taxes'
            labelKey='tax_type'
            options={taxOptions}
            limitTags={1}
            value={values.taxes}
            onChange={(e, values) => handleChange('taxes', values)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </td>
      )}
      {showTaxExempt && (
        <td>
          <CustomCheckbox checked={values.tax_exempt} onChange={(checked) => handleChange('tax_exempt', checked)}>
            <Typography variant='s2' style={{ color: palette.gray700, marginLeft: '8px' }}>
              Tax Exempt
            </Typography>
          </CustomCheckbox>
        </td>
      )}
      <td>
        <SActions className='flex-item'>
          {loading ? (
            <span className='loading-wrapper'>
              <CircularProgress size={16} />
            </span>
          ) : (
            <span className='action-wrapper' onClick={() => handleSubmit()}>
              <CheckIcon width={11} height={11} />
            </span>
          )}
          <span className='action-wrapper' onClick={onDelete}>
            <DeleteIcon width={11} height={11} fill={palette.red500} />
          </span>
        </SActions>
      </td>
    </STableRow>
  );
};

export default FormItem;
