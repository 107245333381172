import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import ErrorMessage from 'common/ErrorMessage';
import useShowToaster from 'hooks/useShowToaster';
import PlanCard from 'components/Billing/PlanCard';
import cardsImg from 'assets/images/onboarding/image 18.png';
import { ReactComponent as Check } from 'assets/icons/checkGreen.svg';

import {
  getBillingCardsFeatures,
  getBillingDetail,
  postBillingCheckPricesCoupon,
  updateCustomPlan,
} from 'store/actions/billing.actions';
import { setBillingDetail, toggleUpDownGradeModalIsVisible } from 'store/reducers/billing.reducer';
import { planType } from 'components/customerOnboardingComponents/data';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';
import useDateFormat from 'hooks/useDateFormat';
import { useAuth } from 'context/auth.context';
import { GetBillingDetail } from 'Api/Billing';
import { getErrorMessage } from 'utils/error';
import { getPremiumPlans } from 'Api/Onboarding';
import { updatePlans } from 'store/reducers/onboarding.reducer';
import CustomInput from '../../CreateShipment/helpers/CustomInput';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import CustomButton from '../../CustomButton/CustomButton';
import { Typography } from '../../Typography';
import styles from './UpgradeModal.module.css';

const UpgradeModal = ({ onSuccess, onCancelAccount }) => {
  const dispatch = useDispatch();
  const { value } = useAuth();
  const showToaster = useShowToaster();
  const {
    paymentRequired,
    paymentMethod,
    billingDetail,
    billingCardsPrice,
    billingCardsFeatures,
    upDownGradeModalIsVisible,
    billingCheckPricesCoupon,
  } = useSelector((state) => state.billing);
  const userData = useMemo(() => {
    return JSON.parse(localStorage.getItem('user'));
  }, []);
  const { plans } = useSelector((state) => state.onboarding);
  const [purchasePaymentMethod, setPurchasePaymentMethod] = useState('');
  const [activeCard, setActiveCard] = useState(billingDetail?.customer_plan?.plan?.plan?.id);
  const [numberOfUsers, setNumberOfUsers] = useState(billingDetail?.customer_plan?.user_licence_count);
  const [showRequired, setShowRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const [selectedPlanType, setSelectedPlanType] = useState(billingDetail?.customer_plan?.plan);

  const getPlans = async () => {
    try {
      const response = await getPremiumPlans({ customer_type: value?.user?.customer?.customer_type || 'carrier' });
      const data = response.map((plan) => {
        const sortedPlanTypes = plan.plan_types.sort((a, b) => a.amount - b.amount);
        return {
          ...plan,
          plan_types: sortedPlanTypes.filter(
            (item) =>
              !(
                (!!paymentRequired?.hide_yearly_plan && item.duration === 'yearly') ||
                (!!paymentRequired?.hide_3_year_plan && item.duration === '3-Year')
              )
          ),
        };
      });
      if (data[0].plan_types?.length === 1) {
        setSelectedPlanType(data[0].plan_types[0]);
      }
      dispatch(updatePlans(data));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    dispatch(getBillingDetail());
    dispatch(getBillingCardsFeatures());
    getPlans();
  }, []);

  const couponDiscountType = billingCheckPricesCoupon?.plan_prices?.promo_code?.type;
  const couponDiscountValue = billingCheckPricesCoupon?.plan_prices?.promo_code?.value;
  const to_be_paid =
    typeof billingCheckPricesCoupon?.plan_prices?.payment_info?.to_be_paid === 'string'
      ? billingCheckPricesCoupon?.plan_prices?.payment_info?.to_be_paid?.split(',')?.join('')
      : billingCheckPricesCoupon?.plan_prices?.payment_info?.to_be_paid;

  const discountAmount =
    typeof billingCheckPricesCoupon?.plan_prices?.payment_info?.discount_amount === 'string'
      ? billingCheckPricesCoupon?.plan_prices?.payment_info?.discount_amount?.split(',')?.join('')
      : billingCheckPricesCoupon?.plan_prices?.payment_info?.discount_amount;

  const amount_total = couponDiscountValue
    ? couponDiscountType === 'percentage'
      ? Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.amount_total) -
        (Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.amount_total) * couponDiscountValue) / 100
      : Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.amount_total) - couponDiscountValue
    : billingCheckPricesCoupon?.plan_prices?.payment_info?.amount_total;

  const isPaymentMethodChanged =
    billingDetail?.customer_plan?.customer_status === 'intrial' &&
    purchasePaymentMethod !== paymentMethod?.payment_method;

  const handleSubmit = (values) => {
    if (!values.allow) {
      setShowRequired(true);
      return;
    }
    setLoading(true);
    const selectedPlan = selectedPlanType.plan;
    dispatch(
      updateCustomPlan(
        {
          duration: selectedPlanType.duration,
          amount: selectedPlanType.amount * Number(values?.number_of_users || 1),
          user_licence_count: values?.number_of_users ? values?.number_of_users : null,
          new_plan: selectedPlan ? selectedPlan.name : null,
          coupon_code: values?.coupon_code ? values?.coupon_code : '',
          next_renewal_date: billingCheckPricesCoupon?.plan_prices?.payment_info?.next_renewal_date,
          to_be_paid: Number(to_be_paid),
          payment_method: purchasePaymentMethod,
          qty_diff: billingCheckPricesCoupon?.plan_prices?.payment_info?.user_diff || 0,
          discount_amount: discountAmount || 0,
          is_upgrade: billingDetail?.customer_plan?.user_licence_count <= values?.number_of_users,
          is_payment_initiated: 1,
        },
        () => {
          if (accountStatus?.account_status === 'trialexpired') {
            setTimeout(() => {
              GetBillingDetail()
                .then((res) => {
                  dispatch(setBillingDetail({ ...res.data?.billing_details, promo_code: res.data.promo_code }));
                  if (res.data?.customer_status_data) {
                    localStorage.setItem('accountStatus', JSON.stringify(res.data.customer_status_data));
                  }
                  dispatch(toggleUpDownGradeModalIsVisible(false));
                  showToaster({ type: 'success', message: 'Plan has been successfully updated!' });
                  onSuccess();
                })
                .catch(() => {
                  /* empty */
                });
              setLoading(false);
            }, 10000);
          } else {
            dispatch(getBillingDetail());
            dispatch(toggleUpDownGradeModalIsVisible(false));
            showToaster({ type: 'success', message: 'Plan has been successfully updated!' });
            onSuccess();
            setLoading(false);
          }
        },
        (e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
          setLoading(false);
        }
      )
    );
  };
  const initialValue = useMemo(() => {
    const { customer_plan, promo_code } = billingDetail || {};
    return {
      number_of_users: customer_plan?.user_licence_count ? customer_plan?.user_licence_count : 0,
      coupon_code: promo_code?.code || '',
    };
  }, [billingDetail]);

  return (
    <>
      <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{ ...initialValue }}>
        {(props) => {
          return (
            <UpgradeModalForm
              {...props}
              plans={plans}
              userData={userData}
              stripePaymentMethod={paymentMethod}
              billingCheckPricesCoupon={billingCheckPricesCoupon}
              dispatch={dispatch}
              setPaymentMethod={setPurchasePaymentMethod}
              paymentMethod={purchasePaymentMethod}
              numberOfUsers={numberOfUsers}
              setActiveCard={setActiveCard}
              selectedPlanType={selectedPlanType}
              setSelectedPlanType={setSelectedPlanType}
              activeCard={activeCard}
              setNumberOfUsers={setNumberOfUsers}
              billingDetail={billingDetail}
              billingCardsPrice={billingCardsPrice}
              billingCardsFeatures={billingCardsFeatures}
              upDownGradeModalIsVisible={upDownGradeModalIsVisible}
              to_be_paid={to_be_paid}
              amount_total={amount_total}
              showRequired={showRequired}
              setShowRequired={setShowRequired}
              isPaymentMethodChanged={isPaymentMethodChanged}
              onCancelAccount={onCancelAccount}
            />
          );
        }}
      </Formik>
      <SpinnerLoadingMultipleTexts
        isVisible={loading}
        fullScreen
        animationDuration={8}
        textStyle={{ fontSize: '28px', fontFamily: 'Inter, sans-serif', fontWeight: 700, color: palette.gray700 }}
        texts={[
          'Updating account license(s)..',
          'Please do not close or refresh this page..',
          'Please check your email for payment invoice, upon payment, your license(s) will be activated..',
        ]}
      />
    </>
  );
};

function UpgradeModalForm({
  plans,
  values,
  dispatch,
  userData,
  submitForm,
  activeCard,
  setFieldValue,
  numberOfUsers,
  billingDetail,
  paymentMethod,
  setPaymentMethod,
  setNumberOfUsers,
  // stripePaymentMethod,
  selectedPlanType,
  setSelectedPlanType,
  billingCheckPricesCoupon,
  upDownGradeModalIsVisible,
  to_be_paid,
  amount_total,
  showRequired,
  setShowRequired,
  isPaymentMethodChanged,
  onCancelAccount,
}) {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  // const [onCredit, setonCredit] = useState(true);
  const [showCouponErr, setShowCouponErr] = useState(false);
  const [decreaseError, setDecreaseError] = useState(false);
  const { paymentRequired } = useSelector((state) => state.billing);
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const processingFee = !Number(to_be_paid) ? 0 : Number(to_be_paid || 0) * 0.0299 + 0.3;

  // const onUpdateMethodSuccess = () => {
  //   dispatch(getPaymentMethod());
  //   dispatch(toggleUpdatePaymentMethodModalIsVisible(false));
  // };

  const handlePaymentMethod = (payment_method) => {
    // if (payment_method === 'credit-card') {
    //   if (stripePaymentMethod?.payment_method !== 'credit-card') {
    //     showToaster({
    //       type: 'success',
    //       message: 'You need to change your default payment method to credit card to proceed!',
    //     });
    //     dispatch(toggleUpdatePaymentMethodModalIsVisible(true));
    //     return;
    //   }
    //   setonCredit(true);
    // }
    //
    // if (payment_method === 'ach') {
    //   if (stripePaymentMethod?.payment_method !== 'ach') {
    //     showToaster({
    //       type: 'success',
    //       message: 'You need to change your default payment method to ACH to proceed!',
    //     });
    //     dispatch(toggleUpdatePaymentMethodModalIsVisible(true));
    //     return;
    //   }
    //   setonCredit(false);
    // }

    setPaymentMethod(payment_method);
  };

  const onLicenseDecrease = () => {
    if (Number(values?.number_of_users) - 1 < billingDetail?.customer_plan?.user_licensed) {
      setDecreaseError(true);
      return;
    }

    setFieldValue('number_of_users', Number(values?.number_of_users) - 1);
    addUsers(-1);
  };

  const selectPlan = (plan) => {
    const activePlan = billingDetail?.customer_plan?.plan;
    const goBackToMonthly =
      billingDetail?.customer_plan?.customer_status === 'active' && plan.amount < activePlan?.amount;

    if (goBackToMonthly) {
      showToaster({
        type: 'error',
        message: `You have pre-paid for ${activePlan?.duration} recurring and cannot revert to ${plan?.duration} until your agreement has expired.`,
      });
      return;
    }

    setSelectedPlanType(plan);
  };

  const onApply = (isCancel) => {
    if (isCancel) {
      setFieldValue('coupon_code', '');
      setShowCouponErr(false);
    }
    dispatch(
      postBillingCheckPricesCoupon({
        plan_type_membership_id: selectedPlanType.membership_plan_id,
        payment_method: paymentMethod || 'credit-card',
        number_of_users: numberOfUsers,
        applied_coupon: isCancel ? '' : values?.coupon_code || '',
        state_id: userData?.state?.id,
        source: 'upgrade',
        hardware: [],
      })
    );
  };

  const addUsers = (count) => {
    setNumberOfUsers((prev) => prev + count);
  };

  useEffect(() => {
    dispatch(
      postBillingCheckPricesCoupon({
        plan_type_membership_id: selectedPlanType.membership_plan_id,
        payment_method: paymentMethod || 'credit-card',
        number_of_users: numberOfUsers,
        applied_coupon: values?.coupon_code || '',
        state_id: userData?.state?.id,
        source: 'upgrade',
        hardware: [],
      })
    );
  }, [activeCard, numberOfUsers, paymentMethod, selectedPlanType.membership_plan_id]);

  useEffect(() => {
    if (!billingCheckPricesCoupon?.plan_prices?.payment_info?.message && values.coupon_code) {
      setShowCouponErr(true);
      return;
    }
    setShowCouponErr(false);
  }, [billingCheckPricesCoupon?.plan_prices?.payment_info]);

  const financingDisabled =
    selectedPlanType?.duration === 'monthly' ||
    (['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
      ? Number(amount_total) < 5000
      : Number(to_be_paid) < 5000);

  useEffect(() => {
    if (paymentMethod === 'financing' && financingDisabled) {
      setPaymentMethod('');
    }
  }, [paymentMethod, financingDisabled]);

  const { customer_plan } = billingDetail || {};
  return (
    <>
      <Form>
        <div className={styles.modal_body_wrapper}>
          {/* <div className={styles.top1}> */}
          {/*   <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}> */}
          {/*     Monthly */}
          {/*   </Typography> */}
          {/*   <CustomCheckbox */}
          {/*     name='duration' */}
          {/*     type='switch' */}
          {/*     smail={false} */}
          {/*     value={values.duration} */}
          {/*     checked={!!values.duration} */}
          {/*     onChange={onDurationChange} */}
          {/*   /> */}
          {/*   <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}> */}
          {/*     3-Year */}
          {/*   </Typography> */}
          {/*   <span className={styles.save}>SAVE 25% +</span> */}
          {/* </div> */}

          <div className={`${styles.top2} mt-4`}>
            <div className={styles.top2_item}>
              <div className={styles.mini_row}>
                <Check className={styles.check} />
              </div>
              <div className={styles.mini_row}>
                <Typography className={styles.text} variant='c1' style={{ color: use(palette.indigo500) }}>
                  Support Included
                </Typography>
              </div>
            </div>
            <div className={styles.top2_item}>
              <div className={styles.mini_row}>
                <Check className={styles.check} />
              </div>
              <div className={styles.mini_row}>
                <Typography className={styles.text} variant='c1' style={{ color: use(palette.indigo500) }}>
                  Billed Per User (Driver & Staff)
                </Typography>
              </div>
            </div>
            <div className={styles.top2_item}>
              <div className={styles.mini_row}>
                <Check className={styles.check} />
              </div>
              <div className={styles.mini_row}>
                <Typography className={styles.text} variant='c1' style={{ color: use(palette.indigo500) }}>
                  Cancel Anytime
                </Typography>
              </div>
            </div>
          </div>
          {plans?.[0]?.plan_types?.length > 1 && (
            <div className={styles.card_block}>
              {plans?.[0]?.plan_types?.map((item) => (
                <PlanCard plan={item} isActive={selectedPlanType?.id === item.id} selectPlan={selectPlan} />
              ))}
              {/* {plans?.map((itemCard, idx) => { */}
              {/*   return ( */}
              {/*     <div */}
              {/*       key={itemCard.id} */}
              {/*       className={classNames([ */}
              {/*         styles.card_wrapper, */}
              {/*         activeCard === itemCard.id ? styles.active_card : '', */}
              {/*         { [styles.expanded]: viewMore[itemCard.id] }, */}
              {/*       ])} */}
              {/*     > */}
              {/*       <div className={styles.card_top}> */}
              {/*         <div className={styles.title_block}> */}
              {/*           <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}> */}
              {/*             {itemCard.name} */}
              {/*           </Typography> */}
              {/*           {idx === 0 ? ( */}
              {/*             <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*               For 3 to 15 fleet size with starter pack features. */}
              {/*             </Typography> */}
              {/*           ) : idx === 1 ? ( */}
              {/*             <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*               For 16 to 70 fleet size with advanced features. */}
              {/*             </Typography> */}
              {/*           ) : idx === 2 ? ( */}
              {/*             <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*               For 70+ fleet size with all features included. */}
              {/*             </Typography> */}
              {/*           ) : ( */}
              {/*             <span /> */}
              {/*           )} */}
              {/*         </div> */}
              {/*         <div className={styles.price_block}> */}
              {/*           <div className={styles.price}> */}
              {/*             {!values?.duration ? ( */}
              {/*               <> */}
              {/*                 <Typography variant='big'>${itemCard.plan_types[0].amount}</Typography> */}
              {/*                 <Typography variant='s1' style={{ color: use(palette.gray900, palette.gray200) }}> */}
              {/*                   USD */}
              {/*                 </Typography> */}
              {/*               </> */}
              {/*             ) : ( */}
              {/*               <div className={styles.changed_price_wrapper}> */}
              {/*                 <span className={styles.old_price}>${itemCard.plan_types[0].amount}</span> */}
              {/*                 <Typography variant='big'>${itemCard.plan_types[1].amount / 36}</Typography> */}
              {/*                 <Typography variant='s1' style={{ color: use(palette.gray900, palette.gray200) }}> */}
              {/*                   USD */}
              {/*                 </Typography> */}
              {/*               </div> */}
              {/*             )} */}
              {/*           </div> */}
              {/*           <div className={styles.price_subtitle}> */}
              {/*             <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*               Per User / Month */}
              {/*             </Typography> */}
              {/*           </div> */}
              {/*         </div> */}
              {/*         <div className={styles.button_block}> */}
              {/*           {billingDetail?.customer_plan?.plan?.plan?.id === itemCard.id && */}
              {/*           ((billingDetail?.customer_plan?.plan?.duration === 'monthly' && !values?.duration) || */}
              {/*             (billingDetail?.customer_plan?.plan?.duration === '3-Years' && !!values?.duration)) ? ( */}
              {/*             <CustomButton */}
              {/*               height={45} */}
              {/*               type='primary' */}
              {/*               title='Current Plan' */}
              {/*               styleButton={{ */}
              {/*                 height: 39, */}
              {/*                 padding: '6px 12px', */}
              {/*                 margin: 0, */}
              {/*                 alignItems: 'center', */}
              {/*                 justifyContent: 'center', */}
              {/*                 backgroundColor: '#9EA5FF', */}
              {/*               }} */}
              {/*               styleTitle={{ fontSize: 14 }} */}
              {/*               onClick={() => toggleActiveCard(itemCard.id)} */}
              {/*             /> */}
              {/*           ) : activeCard !== itemCard.id ? ( */}
              {/*             <CustomButton */}
              {/*               className={styles.select_button} */}
              {/*               type='secondary' */}
              {/*               title='Select Plan' */}
              {/*               styleTitle={{ */}
              {/*                 color: use(palette.indigo500), */}
              {/*                 fontSize: '14px', */}
              {/*                 fontWeight: 500, */}
              {/*               }} */}
              {/*               styleButton={{ */}
              {/*                 padding: '8px 59px', */}
              {/*                 backgroundColor: '#F0F1FA', */}
              {/*                 margin: 0, */}
              {/*                 border: '1px solid #F0F1FA', */}
              {/*               }} */}
              {/*               onClick={() => toggleActiveCard(itemCard.id)} */}
              {/*             /> */}
              {/*           ) : ( */}
              {/*             <CustomButton */}
              {/*               type='primary' */}
              {/*               title='Selected' */}
              {/*               styleTitle={{ */}
              {/*                 fontSize: '14px', */}
              {/*                 fontWeight: 500, */}
              {/*               }} */}
              {/*               styleButton={{ */}
              {/*                 padding: '8px 59px', */}
              {/*                 backgroundColor: '#F0F1FA', */}
              {/*                 margin: 0, */}
              {/*                 border: '1px solid #F0F1FA', */}
              {/*               }} */}
              {/*               onClick={() => toggleActiveCard(itemCard.id)} */}
              {/*             /> */}
              {/*           )} */}
              {/*           {!values?.duration ? ( */}
              {/*             <Typography variant='c1' style={{ color: use(palette.gray500, palette.gray200) }}> */}
              {/*               Billed monthly */}
              {/*             </Typography> */}
              {/*           ) : ( */}
              {/*             <Typography variant='c1' style={{ color: use(palette.gray500, palette.gray200) }}> */}
              {/*               Billed every 3 years */}
              {/*             </Typography> */}
              {/*           )} */}
              {/*         </div> */}
              {/*       </div> */}
              {/*       <div className={styles.card_bottom}> */}
              {/*         {billingCardsFeatures */}
              {/*           ?.slice(0, viewMore[itemCard.id] ? billingCardsFeatures.length - 1 : 0) */}
              {/*           .map((itemFeature) => { */}
              {/*             return ( */}
              {/*               <div key={itemFeature.id} className={styles.feature_row}> */}
              {/*                 <div style={{ display: 'flex', flexDirection: 'column' }}> */}
              {/*                   <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}> */}
              {/*                     {itemFeature?.name?.toUpperCase()} */}
              {/*                   </Typography> */}
              {/*                   {itemFeature?.children?.map((el) => { */}
              {/*                     const foundedFeature = el?.plans?.find((f) => f.name === itemCard.name); */}
              {/*                     return ( */}
              {/*                       <div */}
              {/*                         style={{ */}
              {/*                           display: 'flex', */}
              {/*                           gap: 8, */}
              {/*                           marginBottom: 6, */}
              {/*                           alignItems: 'start', */}
              {/*                         }} */}
              {/*                       > */}
              {/*                         {foundedFeature ? ( */}
              {/*                           <img */}
              {/*                             style={{ */}
              {/*                               height: '12px', */}
              {/*                               width: '12px', */}
              {/*                               marginTop: 3, */}
              {/*                             }} */}
              {/*                             src={checkIcon} */}
              {/*                             alt='righttick' */}
              {/*                           /> */}
              {/*                         ) : ( */}
              {/*                           <img */}
              {/*                             style={{ */}
              {/*                               height: '10px', */}
              {/*                               width: '10px', */}
              {/*                               marginTop: 5, */}
              {/*                             }} */}
              {/*                             src={xIcon} */}
              {/*                             alt='crosstick' */}
              {/*                           /> */}
              {/*                         )} */}
              {/*                         <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*                           {el.name} */}
              {/*                         </Typography> */}
              {/*                       </div> */}
              {/*                     ); */}
              {/*                   })} */}
              {/*                 </div> */}
              {/*               </div> */}
              {/*             ); */}
              {/*           })} */}
              {/*         <div> */}
              {/*           <span className={styles.view_more} onClick={() => toggleViewMore(itemCard.id)}> */}
              {/*             {viewMore[itemCard.id] ? 'View Less...' : 'View More...'} */}
              {/*           </span> */}
              {/*         </div> */}
              {/*       </div> */}
              {/*     </div> */}
              {/*   ); */}
              {/* })} */}
            </div>
          )}

          <div className={styles.paymentType_title}>
            <div className={styles.line_wrapper}>
              <Typography variant='h5'>Payment Method</Typography>
            </div>
          </div>

          <div
            style={{ display: 'flex', width: '100%', padding: '0 20px', marginLeft: 0 }}
            className='account-summary-container'
          >
            <div style={{ display: 'flex', width: '100%', gap: 5 }} className='account-summary-payment'>
              <div
                className={financingDisabled ? 'payment-card disabled' : 'payment-card'}
                style={{
                  marginTop: '20px',
                  boxShadow:
                    paymentMethod === 'financing'
                      ? ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1.5px #4F5AED, 0px 2px 5px rgba(79, 90, 237, 0.1)'
                      : '',
                }}
              >
                <div className='payment-card-head'>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: financingDisabled ? 0 : '14px' }}>
                    <input
                      style={{ cursor: 'pointer' }}
                      type='radio'
                      name='payment'
                      checked={paymentMethod === 'financing'}
                      onClick={() => handlePaymentMethod('financing')}
                      disabled={financingDisabled}
                    />
                    <p className='payment-subtitle' style={{ marginBottom: 0, marginLeft: '8px' }}>
                      Finance Agreement
                    </p>
                  </div>
                </div>
                {selectedPlanType?.duration === 'monthly' ? (
                  planType.monthly.financingText
                ) : (
                  <div>
                    {!!financingDisabled && (
                      <p className='body-b2 mb-2'>Transaction must be over $5,000.00 for financing.</p>
                    )}
                    <p
                      className='body-b2'
                      style={{
                        display:
                          ['intrial'].includes(billingDetail?.customer_plan?.customer_status) ||
                          Number(to_be_paid) === 0 ||
                          !!financingDisabled
                            ? 'none'
                            : '',
                      }}
                    >
                      <span className='payment-subtitle' style={{ marginRight: '5px' }}>
                        $
                        {`${formatNumber(
                          Number(
                            ['intrial'].includes(billingDetail?.customer_plan?.customer_status)
                              ? amount_total
                              : to_be_paid
                          ) / planType[selectedPlanType?.duration].monthsCount
                        )}`}
                      </span>{' '}
                      <span>/month for {planType[selectedPlanType?.duration].monthsCount} months</span>
                    </p>
                    <p className='body-b2' style={{ marginBottom: '5px' }}>
                      If you choose to pay with the financing option, you will be making monthly payments for{' '}
                      {planType[selectedPlanType?.duration].monthsCount} months + finance charges. Varies on credit
                      approval.
                    </p>
                  </div>
                )}
              </div>
              {/* <div */}
              {/*  className='payment-card' */}
              {/*  style={{ */}
              {/*    marginTop: '20px', */}
              {/*    boxShadow: */}
              {/*      paymentMethod === 'credit-card' && onCredit */}
              {/*        ? ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1.5px #4F5AED, 0px 2px 5px rgba(79, 90, 237, 0.1)' */}
              {/*        : '', */}
              {/*  }} */}
              {/* > */}
              {/*  <div className='payment-card-head'> */}
              {/*    <div */}
              {/*      style={{ */}
              {/*        display: 'flex', */}
              {/*        alignItems: 'center', */}
              {/*        marginBottom: '20px', */}
              {/*      }} */}
              {/*    > */}
              {/*      <input */}
              {/*        type='radio' */}
              {/*        style={{ cursor: 'pointer' }} */}
              {/*        name='payment' */}
              {/*        disabled={false} */}
              {/*        checked={paymentMethod === 'credit-card'} */}
              {/*        onClick={() => handlePaymentMethod('credit-card')} */}
              {/*      /> */}
              {/*      <p className='payment-subtitle' style={{ marginBottom: 0, marginLeft: '8px' }}> */}
              {/*        Credit Card */}
              {/*      </p> */}
              {/*    </div> */}
              {/*    <div> */}
              {/*      <img src={cardsImg} alt='img' /> */}
              {/*    </div> */}
              {/*  </div> */}
              {/*  {paymentMethod === 'credit-card' && */}
              {/*  onCredit && */}
              {/*  stripePaymentMethod?.payment_method === 'credit-card' ? ( */}
              {/*    <div style={{ display: 'flex', columnGap: '16px', marginTop: '12px' }}> */}
              {/*      <div> */}
              {/*        {creditCard[stripePaymentMethod?.payment_method_details?.card?.brand] || ( */}
              {/*          <Card width={40} height={40} /> */}
              {/*        )} */}
              {/*      </div> */}
              {/*      <div> */}
              {/*        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*          {stripePaymentMethod?.payment_method_details?.billing_details?.name} */}
              {/*        </Typography> */}
              {/*        <div style={{ display: 'flex', columnGap: 18, marginTop: 8 }}> */}
              {/*          <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}> */}
              {/*            **** **** **** {stripePaymentMethod?.payment_method_details?.card?.last4} */}
              {/*          </Typography> */}
              {/*          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
              {/*            {stripePaymentMethod?.payment_method_details?.card?.exp_month}/ */}
              {/*            {Number(stripePaymentMethod?.payment_method_details?.card?.exp_year) % 100} */}
              {/*          </Typography> */}
              {/*        </div> */}
              {/*      </div> */}
              {/*    </div> */}
              {/*  ) : billingDetail?.customer_plan?.customer_status !== 'intrial' ? ( */}
              {/*    <div> */}
              {/*      <p className='body-b2' style={{ display: Number(to_be_paid + processingFee) === 0 ? 'none' : '' }}> */}
              {/*        {' '} */}
              {/*        <span className='payment-subtitle' style={{ marginRight: '5px' }}> */}
              {/*          ${formatNumber(Number(to_be_paid + processingFee))} */}
              {/*        </span>{' '} */}
              {/*        {selectedPlanType?.duration === 'monthly' */}
              {/*          ? 'Per Month Recurring' */}
              {/*          : `up front for ${planType[selectedPlanType?.duration].monthsCount} months`} */}
              {/*      </p> */}
              {/*      <p className='body-b2' style={{ marginBottom: '5px' }}> */}
              {/*        {planType[selectedPlanType?.duration].creditCardText} */}
              {/*      </p> */}
              {/*    </div> */}
              {/*  ) : ( */}
              {/*    <div> */}
              {/*      /!* <p className='body-b2' style={{ display: Number(amount_total) === 0 ? 'none' : '' }}> *!/ */}
              {/*      /!*  {' '} *!/ */}
              {/*      /!*  <span className='payment-subtitle' style={{ marginRight: '5px' }}> *!/ */}
              {/*      /!*    ${formatNumber(Number(amount_total)?.toFixed(2))} *!/ */}
              {/*      /!*  </span>{' '} *!/ */}
              {/*      /!*  {selectedPlanType?.duration === 'monthly' *!/ */}
              {/*      /!*    ? 'Per Month Recurring after trial' *!/ */}
              {/*      /!*    : `up front for ${planType[selectedPlanType?.duration].monthsCount} months after trial`} *!/ */}
              {/*      /!* </p> *!/ */}
              {/*      <p className='body-b2' style={{ marginBottom: '5px' }}> */}
              {/*        {planType[selectedPlanType?.duration].creditCardText} */}
              {/*      </p> */}
              {/*    </div> */}
              {/*  )} */}
              {/* </div> */}
              <div
                className='payment-card '
                style={{
                  marginTop: '20px',
                  boxShadow:
                    paymentMethod === 'ach'
                      ? ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1.5px #4F5AED, 0px 2px 5px rgba(79, 90, 237, 0.1)'
                      : '',
                }}
              >
                <div className='payment-card-head'>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '14px' }}>
                    <input
                      type='radio'
                      name='payment'
                      style={{ cursor: 'pointer' }}
                      checked={paymentMethod === 'ach'}
                      onClick={() => handlePaymentMethod('ach')}
                    />
                    <p className='payment-subtitle' style={{ marginBottom: 0, marginLeft: '8px' }}>
                      Credit Card or ACH
                    </p>
                  </div>
                  <img src={cardsImg} alt='img' />
                </div>
                {/* {paymentMethod === 'ach' && stripePaymentMethod?.payment_method === 'ach' && ( */}
                {/*  <div> */}
                {/*    <div> */}
                {/*      <Typography variant='button2'> */}
                {/*        {stripePaymentMethod?.payment_method_details?.us_bank_account?.bank_name} */}
                {/*      </Typography> */}
                {/*    </div> */}
                {/*    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
                {/*      {stripePaymentMethod?.payment_method_details?.billing_details?.name} */}
                {/*    </Typography> */}
                {/*    <div style={{ display: 'flex', columnGap: 18, marginTop: 8 }}> */}
                {/*      <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}> */}
                {/*        **** **** **** {stripePaymentMethod?.payment_method_details?.us_bank_account?.last4} */}
                {/*      </Typography> */}
                {/*      <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}> */}
                {/*        {`| ${stripePaymentMethod?.payment_method_details?.us_bank_account?.routing_number}`} */}
                {/*      </Typography> */}
                {/*    </div> */}
                {/*  </div> */}
                {/* )} */}
                {/* {paymentMethod !== 'ach' && billingDetail?.customer_plan?.customer_status !== 'intrial' && ( */}
                <div>
                  <p className='body-b2' style={{ display: Number(to_be_paid) === 0 ? 'none' : '' }}>
                    <span className='payment-subtitle' style={{ marginRight: '5px' }}>
                      ${formatNumber(Number(to_be_paid))}
                    </span>{' '}
                    {selectedPlanType?.duration === 'monthly'
                      ? 'Per Month Recurring'
                      : `up front for ${planType[selectedPlanType?.duration].monthsCount} months`}
                  </p>
                  <p className='body-b2' style={{ marginBottom: '5px' }}>
                    {planType[selectedPlanType?.duration].achText} For upgrades, an invoice will be emailed to you
                    separately, your subscription changes takes effect when you make payment.
                  </p>
                </div>
                {/* )} */}
                {/* {paymentMethod !== 'ach' && billingDetail?.customer_plan?.customer_status === 'intrial' && ( */}
                {/*  <div> */}
                {/*    /!* <p className='body-b2' style={{ display: Number(amount_total) === 0 ? 'none' : '' }}> *!/ */}
                {/*    /!*  <span className='payment-subtitle' style={{ marginRight: '5px' }}> *!/ */}
                {/*    /!*    ${formatNumber(Number(amount_total)?.toFixed(2))} *!/ */}
                {/*    /!*  </span>{' '} *!/ */}
                {/*    /!*  {selectedPlanType?.duration === 'monthly' *!/ */}
                {/*    /!*    ? 'Per Month Recurring' *!/ */}
                {/*    /!*    : `up front for ${planType[selectedPlanType?.duration].monthsCount} months`} *!/ */}
                {/*    /!* </p> *!/ */}
                {/*    <p className='body-b2' style={{ marginBottom: '5px' }}> */}
                {/*      {planType[selectedPlanType?.duration].achText} */}
                {/*    </p> */}
                {/*  </div> */}
                {/* )} */}
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <div className={styles.license_wrapper}>
              <Typography variant='s2'>User License(s)</Typography>
              <div className={styles.license_changes}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', width: '160px' }}>
                    <CustomButton
                      type='secondary'
                      title='-'
                      styleButton={{
                        height: 32,
                        padding: '2px 10px',
                        margin: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      styleTitle={{ marginTop: '-6px', fontSize: '30px' }}
                      onClick={onLicenseDecrease}
                    />
                    <Field
                      name='number_of_users'
                      type='number'
                      component={CustomInput}
                      labelStyle={{ margin: 0 }}
                      onChange={(e) => {
                        if (+e.target.value >= billingDetail?.customer_plan?.user_licensed) {
                          setDecreaseError(false);
                        }
                        setNumberOfUsers(+e.target.value);
                      }}
                      style={{
                        width: 94,
                        borderRadius: 0,
                        height: 32,
                        textAlign: 'center',
                      }}
                    />
                    <CustomButton
                      type='secondary'
                      title='+'
                      styleButton={{
                        height: 32,
                        padding: '2px 10px',
                        margin: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      styleTitle={{ marginTop: '-4px', fontSize: '20px' }}
                      onClick={() => {
                        setDecreaseError(false);
                        setFieldValue('number_of_users', Number(values?.number_of_users) + 1);
                        addUsers(1);
                      }}
                    />
                  </div>
                  {decreaseError && (
                    <ErrorMessage
                      error={`You have ${billingDetail?.customer_plan?.user_licensed} licensed users, please remove a license to decrease.`}
                    />
                  )}
                </div>
                <div>
                  <div className={styles.coupon_code}>
                    {billingCheckPricesCoupon?.plan_prices?.payment_info?.promo_code_applied ? (
                      <>
                        <div>{billingCheckPricesCoupon?.plan_prices?.payment_info?.message}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant='button1'
                            style={{
                              color: use(palette.red500),
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              onApply(true);
                            }}
                          >
                            Cancel
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Field
                            name='coupon_code'
                            type='text'
                            component={CustomInput}
                            placeholder='Promo Code'
                            labelStyle={{ margin: 0 }}
                            style={{ width: 246, height: 32, boxShadow: 'none' }}
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant='button1'
                            style={{
                              color: use(palette.indigo500),
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              onApply(false);
                            }}
                          >
                            Apply
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                  {showCouponErr && (
                    <Typography variant='c2' style={{ color: use(palette.red500) }}>
                      Please enter valid promo code!
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.info_block}>
              <div className={styles.info_item}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Per User Cost
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  {billingCheckPricesCoupon && selectedPlanType?.duration === 'monthly' ? (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      ${billingCheckPricesCoupon?.plan_prices?.payment_info?.plan_type_amount?.toFixed(2)}
                    </Typography>
                  ) : billingCheckPricesCoupon && selectedPlanType?.duration ? (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      $
                      {(
                        Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.plan_type_amount) /
                        planType[selectedPlanType?.duration].monthsCount
                      )?.toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      $0.00
                    </Typography>
                  )}
                  <span className={styles.duration}>MONTHLY</span>
                </div>
              </div>
              {!!billingCheckPricesCoupon?.plan_prices?.payment_info?.promo_code_applied && (
                <div className={styles.item_line}>
                  <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                    Per User Cost (After Promo)
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      $
                      {formatNumber(
                        (Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.plan_type_amount) /
                          planType[selectedPlanType?.duration].monthsCount) *
                          (1 - (Number(billingCheckPricesCoupon?.plan_prices?.promo_code?.value) || 0) / 100)
                      )}
                    </Typography>
                    <span className={styles.duration}>MONTHLY</span>
                  </div>
                </div>
              )}
              <div className={styles.item_line}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Recurring Total
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  {billingCheckPricesCoupon && selectedPlanType?.duration === 'monthly' ? (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      $
                      {formatNumber(
                        Number(
                          ['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
                            ? amount_total
                            : Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.user_diff) < 0
                            ? Number(amount_total)
                            : Number(to_be_paid) + Number(customer_plan.amount)
                        ) + (paymentMethod === 'credit-card' ? processingFee : 0)
                      )}
                    </Typography>
                  ) : billingCheckPricesCoupon && !!selectedPlanType?.duration ? (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      $
                      {formatNumber(
                        ['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
                          ? Number(amount_total) + (paymentMethod === 'credit-card' ? processingFee : 0)
                          : Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.user_diff) < 0
                          ? Number(amount_total)
                          : Number(amount_total) +
                            Number(customer_plan.amount) +
                            (paymentMethod === 'credit-card' ? processingFee : 0)
                      )}
                    </Typography>
                  ) : (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      $0.00
                    </Typography>
                  )}
                  <span className={styles.duration}>{selectedPlanType?.duration?.toUpperCase()}</span>
                </div>
              </div>
              <div className={styles.item_line}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Billed Today
                </Typography>
                <div>
                  {accountStatus?.account_status === 'trialexpired' ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                      {billingCheckPricesCoupon && selectedPlanType?.duration === 'monthly' ? (
                        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          $
                          {formatNumber(
                            Number(
                              ['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
                                ? amount_total
                                : Number(to_be_paid) + Number(customer_plan.amount)
                            ) + (paymentMethod === 'credit-card' ? processingFee : 0)
                          )}
                        </Typography>
                      ) : billingCheckPricesCoupon && !!selectedPlanType?.duration ? (
                        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          $
                          {formatNumber(
                            ['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
                              ? Number(amount_total) + (paymentMethod === 'credit-card' ? processingFee : 0)
                              : Number(amount_total) +
                                  Number(customer_plan.amount) +
                                  (paymentMethod === 'credit-card' ? processingFee : 0)
                          )}
                        </Typography>
                      ) : (
                        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          $0.00
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                      ${formatNumber(Number(to_be_paid || 0))}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Field name='allow'>
                {({ field }) => (
                  <div>
                    <Typography variant='s2' />
                    <CustomCheckbox
                      field={field}
                      withinForm
                      checked={!!field.value}
                      style={{ marginTop: 22 }}
                      name='allow'
                      onChange={() => setShowRequired(false)}
                    >
                      {accountStatus?.account_status === 'trialexpired' ? (
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                          <Typography
                            variant='s2'
                            style={{
                              whiteSpace: 'nowrap',
                              marginLeft: 8,
                              color: use(palette.orange500),
                            }}
                          >
                            I allow Truckin Digital to bill
                          </Typography>{' '}
                          {billingCheckPricesCoupon && selectedPlanType?.duration === 'monthly' ? (
                            <Typography variant='s2' style={{ color: use(palette.orange500) }}>
                              $
                              {formatNumber(
                                // Number(billingCheckPricesCoupon?.plan_prices?.payment_info?.monthly_payment) +
                                Number(
                                  ['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
                                    ? amount_total
                                    : Number(to_be_paid) + Number(customer_plan.amount)
                                )
                              )}
                            </Typography>
                          ) : billingCheckPricesCoupon && !!selectedPlanType?.duration ? (
                            <Typography variant='s2' style={{ color: use(palette.orange500) }}>
                              $
                              {formatNumber(
                                ['intrial', 'trialexpired'].includes(billingDetail?.customer_plan?.customer_status)
                                  ? Number(amount_total)
                                  : Number(amount_total) + Number(customer_plan.amount)
                              )}
                            </Typography>
                          ) : (
                            <Typography variant='s2' style={{ color: use(palette.orange500) }}>
                              $0.00
                            </Typography>
                          )}{' '}
                          <Typography
                            variant='s2'
                            style={{
                              whiteSpace: 'nowrap',
                              color: use(palette.orange500),
                            }}
                          >
                            to payment method above with new plan changes on {formatDate(moment())}.
                          </Typography>
                        </div>
                      ) : (
                        <Typography
                          variant='s2'
                          style={{
                            whiteSpace: 'nowrap',
                            marginLeft: 8,
                            color: use(palette.orange500),
                          }}
                        >
                          I allow Truckin Digital to bill $
                          {formatNumber(
                            Number((to_be_paid || 0) + (paymentMethod === 'credit-card' ? processingFee : 0))
                          )}{' '}
                          to payment method above with new plan changes on {formatDate(moment())}.
                        </Typography>
                      )}
                    </CustomCheckbox>
                    {showRequired && <ErrorMessage error='Required' />}
                  </div>
                )}
              </Field>
            </div>
          </div>
        </div>
        <div className={styles.footer_buttons}>
          {paymentRequired?.is_require_payment_enable === 0 && accountStatus?.account_status === 'trialexpired' ? (
            <CustomButton
              type='secondary'
              onClick={onCancelAccount}
              title='Cancel account'
              styleButton={{ margin: 10, padding: '6px 12px' }}
              styleTitle={{
                fontSize: 12,
                marginTop: 1,
              }}
            />
          ) : (
            <CustomButton
              type='secondary'
              onClick={() => dispatch(toggleUpDownGradeModalIsVisible(!upDownGradeModalIsVisible))}
              title='Cancel'
              styleButton={{ margin: 10, padding: '6px 12px' }}
              styleTitle={{
                fontSize: 12,
                marginTop: 1,
              }}
            />
          )}
          {(paymentRequired?.is_require_payment_enable === 0 && billingDetail?.payment_method_bypass) ||
          (paymentRequired?.is_require_payment_enable === 0 && accountStatus?.account_status === 'trialexpired') ? (
            <CustomButton
              type='primary'
              onClick={() => {
                if (!numberOfUsers) {
                  setDecreaseError(true);
                  return;
                }
                submitForm();
              }}
              title='Continue'
              disabled={!paymentMethod}
              styleButton={{ margin: '10px 4px 10px 0', padding: '6px 12px' }}
              styleTitle={{
                fontSize: 12,
                marginTop: 1,
              }}
            />
          ) : (
            <CustomButton
              type='primary'
              onClick={() => {
                if (!numberOfUsers || numberOfUsers < billingDetail?.customer_plan?.user_licensed) {
                  setDecreaseError(true);
                  return;
                }
                submitForm();
              }}
              title='Change Plan & Authorize'
              disabled={
                (billingDetail?.customer_plan?.plan?.plan?.id === activeCard &&
                  numberOfUsers === billingDetail?.customer_plan?.user_licence_count &&
                  billingDetail?.customer_plan?.plan?.duration === selectedPlanType?.duration &&
                  !isPaymentMethodChanged) ||
                !paymentMethod
              }
              styleButton={{ margin: '10px 4px 10px 0', padding: '6px 12px' }}
              styleTitle={{
                fontSize: 12,
                marginTop: 1,
              }}
            />
          )}
        </div>
      </Form>
      {/* <Backdrop open={updatePaymentMethodModalVisible}> */}
      {/*  <UpdatePaymentMethod */}
      {/*    open={updatePaymentMethodModalVisible} */}
      {/*    onClose={() => dispatch(toggleUpdatePaymentMethodModalIsVisible(false))} */}
      {/*    onSuccess={onUpdateMethodSuccess} */}
      {/*    onCreditCardSuccess={() => { */}
      {/*      setPaymentMethod('credit-card'); */}
      {/*      setonCredit(true); */}
      {/*    }} */}
      {/*    onAchSuccess={() => { */}
      {/*      setPaymentMethod('ach'); */}
      {/*      setonCredit(false); */}
      {/*    }} */}
      {/*  /> */}
      {/* </Backdrop> */}
    </>
  );
}

export default UpgradeModal;
