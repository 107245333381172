export const calendarStyles = {
  3: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkColor: '#AA5B00',
  },
  2: {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkColor: '#D12953',
  },
  1: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkColor: '#14804A',
  },
};

export const eventStyleGetter = (event) => {
  return {
    style: {
      backgroundColor: calendarStyles[event.type]?.bgColor,
      color: calendarStyles[event.type]?.color,
      outlineColor: calendarStyles[event.type]?.darkColor,
      fontSize: '14px',
    },
  };
};
