import { array, date, number, object, ref, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationBasicSchema = object({
  fname: string('').required('First name is required'),
  lname: string().required('Last name is required'),
  phone_number: string()
    .min(10, 'Enter valid number')
    .max(12, 'Enter valid number')
    .required('Phone number is required'),
  hire_date: validator.pastRequiredDate(),
  license_exp_dt: validator.date(),
  dob: validator.pastRequiredDate(),
  driver_type: string().trim().required('Required').nullable(),
  operator: object().when('driver_type', {
    is: 'owner',
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});

export const CompensationValidationSchema = object({
  tax_id: string().required('Tax id is required'),
  tax_type: string().required('Tax Type is required'),
  bank_routing: string()
    .oneOf([ref('confirm_bank_routing'), null], 'Bank Routing must match')
    .nullable(),
  confirm_bank_routing: string()
    .oneOf([ref('bank_routing'), null], 'Bank Routing must match')
    .nullable(),
  bank_account: number()
    .oneOf([ref('confirm_bank_account'), null], 'Bank Account must match')
    .nullable(),
  confirm_bank_account: number()
    .oneOf([ref('bank_account'), null], 'Bank Account must match')
    .nullable(),
  paid_time_off_amount: number().when('paid_time_off_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  detention_amt: number().when('detention_pay_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  detention_hrs: number().when('detention_pay_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  layover_amt: number().when('layover_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  per_stop_amt: number().when('stopoff_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  no_of_stops: number().when('stopoff_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  each_truck_order_unused_amt: number().when('truck_order_unused_option', {
    is: true,
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  pay_raise: array().when(['is_pay_raise'], {
    is: (is_pay_raise) => !!is_pay_raise,
    then: array().when(['pay_option'], {
      is: (pay_option) => pay_option === 'mile',
      then: array().of(
        object().shape({
          empty: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value > 0),
          loaded: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value > 0),
          pay_raise_schedule_days: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value >= 0),
          pay_raise_schedule_months: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value >= 0),
          pay_raise_schedule_years: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value >= 0),
        })
      ),
      otherwise: array().of(
        object().shape({
          pay_raise_schedule_amt: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value > 0),
          pay_raise_schedule_days: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value >= 0),
          pay_raise_schedule_months: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value >= 0),
          pay_raise_schedule_years: number()
            .required('Required')
            .nullable()
            .test('Required', 'Required', (value) => value >= 0),
        })
      ),
    }),
  }),
  shipment_addition: array().when(['pay_on_each_shipment'], {
    is: (pay_on_each_shipment) => !!pay_on_each_shipment,
    then: array().of(
      object().shape({
        payroll_addition: object().shape({ id: number().required('Required') }),
        amount: number()
          .required('Required')
          .nullable()
          .test('Required', 'Required', (value) => value > 0),
      })
    ),
  }),
});

export const CompensationOwnerValidationSchema = object({
  tax_id: string().required('Tax id is required'),
  tax_type_id: string().required('Tax Type is required'),
  routing_confirmed: string()
    .oneOf([ref('routing'), null], 'Bank Routing must match')
    .nullable(),
  account_confirmed: string()
    .oneOf([ref('account'), null], 'Bank Account must match')
    .nullable(),
});

export const PolicyValidationSchema = object({
  excused_absences: string().required('Excused Attendance is required'),
  unexcused_absences: string().required('Unexcused Attendance is required'),
  vacations_allowed: string().required('Vacations is required'),
  absence_reset_based_on: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  compensation_policy_expiry_date: validator.date(),
});

export const addGroupValidation = object({
  group_name: string().required('Tag name is required'),
  color: string().required('Color is required'),
});

export const resetPinValidation = object({
  login_pin: string()
    .min(4, 'Pin is not valid, enter 4 digits')
    .max(4, 'Pin is not valid, enter 4 digits')
    .required('Pin is required'),
});

export const validationBasicOwnerSchema = object({
  owner_operator_name: string().when(['operator_type_id'], {
    is: (operator_type_id) => operator_type_id === '2' || operator_type_id === 2,
    then: string().required('Company name is required'),
    otherwise: string(),
  }),
  lname: string().when(['operator_type_id'], {
    is: (operator_type_id) => operator_type_id === '1' || operator_type_id === 1,
    then: string().required('Last name is required'),
    otherwise: string(),
  }),
  fname: string().when(['operator_type_id'], {
    is: (operator_type_id) => operator_type_id === '1' || operator_type_id === 1,
    then: string().required('first name is required'),
    otherwise: string(),
  }),
  email_id: string().email('Enter a valid email').required('Email is required'),
  phone_no: string().min(10, 'Enter valid number').max(12, 'Enter valid number').required('Phone number is required'),
  address: string().trim().required('Required').nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required').nullable(),
  operator_type_id: number().required('Operator type id is required'),
});

export const notesValidationSchema = object({
  title: string('').required('Title is required'),
  notes: string('').required('Notes description is required'),
  note_to_email: string('').required('Receiver email is required'),
  department_id: string('').required('CC Department Email is required'),
});

export const requestValidationSchema = object({
  req_start_datetime: date().when('request_type', {
    is: (request_type) => request_type === 'Time Off' || request_type === 'Maintenance',
    then: validator.requiredDate('Start date is required'),
    otherwise: validator.date(),
  }),
  req_end_datetime: date().when('request_type', {
    is: (request_type) => request_type === 'Time Off' || request_type === 'Maintenance',
    then: validator.requiredDate('End date is required'),
    otherwise: validator.date(),
  }),
  request_type: string().required('Request type is required'),
  shipment_id: string().when('request_type', {
    is: (request_type) => request_type === 'Delivery Number' || request_type === 'Pickup Number',
    then: string()
      .trim()
      .required('Required')
      .test('Required', 'Required', (value) => value && value !== 'Select Shipment Id...')
      .nullable(),
    otherwise: string().nullable(),
  }),
  rej_reason: string().when('req_status', {
    is: (req_status) => req_status === 'Rejected',
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  request_amount: number().when('request_type', {
    is: (request_type) => request_type === 'Cash Advance' || request_type === 'Fuel Advance',
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => Number(value) > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
});

export const requestStaffValidationSchema = object({
  request_type: string('').required('Request type is required'),
  request_amount: number().when('request_type', {
    is: (request_type) => request_type === 'Cash Advance' || request_type === 'Fuel Advance',
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => Number(value) > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  start_date: date().when('request_type', {
    is: (request_type) => request_type === 'Time Off',
    then: validator.requiredDate('Start date is required'),
    otherwise: validator.date(),
  }),
  end_date: date().when('request_type', {
    is: (request_type) => request_type === 'Time Off',
    then: validator.requiredDate('End date is required'),
    otherwise: validator.date(),
  }),
});

export const absenceValidationSchema = object({
  reason_title: string('').required('Reason title is required'),
  start_date: validator.requiredDate('Start date is required'),
  end_date: validator.requiredDate('End date is required'),
  absence_details: string('').required('Attendance details is required'),
  internal_notes: string('').required('Internal notes is required'),
});

export const staffAbsenceValidationSchema = object({
  reason_title: string('').required('Reason title is required'),
  absence_start_date: validator.requiredDate('Start date is required'),
  absence_end_date: validator.requiredDate('End date is required'),
  absence_details: string('').required('Attendance details is required'),
  internal_notes: string('').required('Internal notes is required'),
  type: number().required('Required'),
});

export const PrevEmployVerification = object({
  start_date: string('').required('Required!').nullable(),
  end_date: string('').required('Required!').nullable(),
  hold_position: string('').required('Required!').nullable(),
  reason_for_leaving: string('').required('Required!').nullable(),
  driver_class: string('').required('Required!').nullable(),
  driver_type: string('').required('Required!').nullable(),
  employer_truck: array()
    .nullable()
    .test('at-least-one-not-empty', 'Required!', function () {
      const { employer_truck, otherTruck } = this.parent;
      return employer_truck.length > 0 || otherTruck.length > 0;
    }),
  subject_to_fmcsRs: string('').required('Required!').nullable(),
  subject_to_dot_dna: string('').required('Required!').nullable(),
  eligible_for_rehire: string('').required('Required!').nullable(),
  employer_experience: array()
    .nullable()
    .test('at-least-one-not-empty', 'Required!', function () {
      const { employer_experience, otherExperience } = this.parent;
      return employer_experience.length > 0 || otherExperience.length > 0;
    }),
  responsible_for_maintaining_logs: string('').required('Required!').nullable(),
  employer_area_driven: array()
    .nullable()
    .test('at-least-one-not-empty', 'Required!', function () {
      const { employer_area_driven, otherAreaDriven } = this.parent;
      return employer_area_driven.length > 0 || otherAreaDriven.length > 0;
    }),
  states_driven: string('').required('Required!').nullable(),
  driver_terminated: string('').required('Required!').nullable(),
  trailer_length: string('').required('Required!').nullable(),
  accidents_preventable: string('').required('Required!').nullable(),
  accidents_non_preventable: string('').required('Required!').nullable(),
  accidents_dot_reportable: string('').required('Required!').nullable(),
  employee_alcohol_test_result: string('').required('Required!').nullable(),
  employee_verified_positive_drug_test: string('').required('Required!').nullable(),
  employee_refuse_test: string('').required('Required!').nullable(),
  employee_drug_alcohol_regulations_violations: string('').required('Required!').nullable(),
  previous_employee_drug_alcohol_regulations_violations: string('').required('Required!').nullable(),
  employee_complete_return_to_duty_process: string('').required('Required!').nullable(),
  title: string('').required('Required!').nullable(),
  date: string('').required('Required!').nullable(),
  phone_no: string('').required('Required!').nullable(),
  company_dot: string('').required('Required!').nullable(),
  email: string('').required('Required!').nullable(),
  signature_name: string('').required('Required!').nullable(),
  accident: array().when(['accidents_preventable', 'accidents_non_preventable', 'accidents_dot_reportable'], {
    is: (accidents_preventable, accidents_non_preventable, accidents_dot_reportable) =>
      accidents_preventable !== '0' || accidents_non_preventable !== '0' || accidents_dot_reportable !== '0',
    then: array().of(
      object().shape({
        acc_date: date().required('Required').nullable(),
        acc02_country_state_city: string('').required('Required!').nullable(),
        acc_description: string('').required('Required!').nullable(),
        acc_fatalities: string('').required('Required!').nullable(),
        acc_injuries: string('').required('Required!').nullable(),
      })
    ),
    otherwise: array().nullable(),
  }),
});

export const addDocValidationSchema = object({
  addDocument: array().of(
    object().shape({
      certification_exp_date: validator.date(),
    })
  ),
});

export const requestListValidationSchema = object({
  request_type: string('').required('Request type is required'),
  request_amount: number().when('request_type', {
    is: (request_type) => request_type === 'Cash Advance' || request_type === 'Fuel Advance',
    then: number()
      .required('Required')
      .test('Required', 'Required', (value) => Number(value) > 0)
      .nullable(),
    otherwise: number().nullable(),
  }),
  start_date: date().when('request_type', {
    is: (request_type) => request_type === 'Time Off' || request_type === 'Maintenance',
    then: validator.requiredDate('Start date is required'),
    otherwise: validator.date(),
  }),
  end_date: date().when('request_type', {
    is: (request_type) => request_type === 'Time Off' || request_type === 'Maintenance',
    then: validator.requiredDate('End date is required'),
    otherwise: validator.date(),
  }),
});

export const importDriverValidationSchema = object({
  drivers: array().of(
    object().shape({
      fname: string().required('First name is required').nullable(),
      lname: string().required('Last name is required').nullable(),
      phone_number: string()
        .min(11, 'Invalid phone number')
        .max(12, 'Invalid phone number')
        .required('Phone number is required')
        .nullable(),
    })
  ),
});

export const importStaffValidationSchema = object({
  drivers: array().of(
    object().shape({
      fname: string('').required('First name is required'),
      lname: string().required('Last name is required'),
      email: string().email('Enter a valid email').required('Email is required'),
      hire_date: string().required('Hire Date is required').nullable(),
      phone_number: string().required('Phone number is required'),
      title: string().required('Title is required'),
      department_id: string().required('Department name is required'),
    })
  ),
});

export const documentsValidationSchema = object({
  certifications: array().of(
    object().shape({
      certification_exp_date: validator.date(),
    })
  ),
});
