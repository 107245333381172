import { date, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  name: string().trim().required('Required').nullable(),
  doc: string().trim().required('Required').nullable(),
  expire_date: date().when('no_expiry', {
    is: (no_expiry) => !no_expiry,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
});
