import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper } from './ServiceCard.styles';

const ServiceCard = ({ service }) => {
  return (
    <SWrapper>
      <div className='card-body'>
        <div className='service-img'>
          <img src={service.logo} alt='addon' />
        </div>
        <div className='addons-info'>
          <div className='d-flex flex-column'>
            <Typography variant='s2'>{service.provider_name}</Typography>
            <Typography variant='c1' style={{ color: palette.gray500 }}>
              {service.type}
            </Typography>
          </div>
          <Typography variant='c2' style={{ color: palette.gray500 }}>
            {service.description}
          </Typography>
        </div>
      </div>
    </SWrapper>
  );
};

export default ServiceCard;
