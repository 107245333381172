import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './EquipmentMap.css';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import axios from 'axios';
import { Icons } from 'assets/icons';
import { getAllTrailers, mapPageFlag } from 'Api/Map';
import mapImage from 'assets/map.png';
import { PusherJs } from 'common/pusher';
import { getAlphabet, STOP_POINT_TAB } from 'utils/constants';
import { onChangeInventoryTab } from 'store/reducers/stopPoint.reducer';
import SplashScreen from '../../splashScreen/splashScreen';

const TrailerContainerMap = ({
  setGetCoords,
  setMyCustomMap,
  visibleTrafic,
  visibleWeather,
  truckRistriction,
  POI,
  status,
  routeCoords,
  updatePoint,
  mapLayersValues,
  routeTypeMap,
  routeChange,
  routeOptions,
  radius,
  setZoomDataTrailer,
  zoomDataTrailer,
  routeMarkers,
  setRouteMarkers,
  routeStopContent,
  setRouteStopContent,
  stopPointAll,
  stopPointVisible,
  stopPointCoords,
  stopPointsData,
  firstCoords,
  firstCoordsFound,
  locationFilterData,
  optionFilterData,
  lastCenter,
  lastZoom,
  setLastCenter,
  setLastZoom,
}) => {
  const dispatch = useDispatch();
  const { customer } = JSON.parse(localStorage.getItem('user')) || {};
  const { dot } = customer || {};
  const [coords, setCoords] = useState([]);
  const [latlng, setlatlng] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [stopPointMarker, setStopPointMarker] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [vehicleData, setVehicleData] = useState({ data: [] });
  const [, setLoadingMap] = useState(true);
  const [reloadConfig] = useState(true);
  const steps = ['Gathering live coordinates..', 'Preparing map..', 'Map ready..'];

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function init() {
      try {
        setLoadingMap(true);
        const res = await getAllTrailers(filters, source.token, locationFilterData, optionFilterData);
        setVehicleData(res);
        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          // Do nothing
        }
      }
    }

    if (reloadConfig) {
      init();
    }

    return () => {
      source.cancel('Canceled due to stale request');
    };
  }, [reloadConfig, filters]);

  useEffect(() => {
    if (latlng?.length === 0 || (!loading && vehicleData)) {
      setlatlng(vehicleData?.data);
    }
  }, [vehicleData, loading]);

  function deepEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  const getLiveMarker = (isHookedToVehicle, isMoving) => {
    let vehicleType;
    if (isHookedToVehicle) {
      if (isMoving) {
        vehicleType = 'vehiclePlusTrailer';
      } else {
        vehicleType = 'greyVehiclePlusTrailer';
      }
    } else {
      vehicleType = 'greyTrailer';
    }
    return vehicleType;
  };

  useEffect(() => {
    if (!latlng) return;
    const copyEquipmentGPS = [...latlng];
    const copyVehicleGPS = [...latlng];
    const equipmentGPS = copyEquipmentGPS?.filter((v) => v?.location_target === 'Equipment');
    const equipmentCoords = equipmentGPS?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
      },
      latitude: c?.equipment_gps?.latitude,
      longitude: c?.equipment_gps?.longitude,
      hooked_to: !!c?.hook_to,
      heading: c?.equipment_gps?.heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.fname : '',
      drivers_lname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.lname : '',
      image: c?.drivers ? c?.drivers[0]?.image : '',
      phone_number: c?.drivers ? c?.drivers[0]?.phone_number : '',
      driver_id: c?.drivers ? c?.drivers[0]?.id : '',
      current_location: c?.equipment_gps?.location,
      motion: c?.equipment_gps?.motion,
      last_update: c?.equipment_gps?.updated_at,
      status: c?.equipment_status?.equipment_status,
      speed: c?.equipment_connected_services_device?.speed_mph,
    }));
    const EquipmentGPSwithLastKnown = equipmentGPS?.filter((g) => !g.equipment_gps);

    const EquipmentGPSwithLastKnownCoords = EquipmentGPSwithLastKnown?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
      },
      latitude: c?.last_known_latitude,
      longitude: c?.last_known_longitude,
      hooked_to: !!c?.hook_to,
      heading: c?.last_known_heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      current_location: c?.last_known_location,
      last_update: c?.last_known_update,
      status: c?.equipment_status?.equipment_status,
      speed: c?.equipment_connected_services_device?.speed_mph,
    }));

    const hookToVehicleGps = copyVehicleGPS.filter((v) => v?.location_target === 'Vehicle');
    const HookToDriverGPS = hookToVehicleGps.filter((v) => v?.hook_to?.location_target === 'Driver');

    const HookToCopydriverGPS = HookToDriverGPS?.filter((v) => v?.hook_to?.drivers);

    const driverCoords = HookToCopydriverGPS?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
      },
      latitude: c?.hook_to?.drivers[0]?.driver_gps?.latitude,
      longitude: c?.hook_to?.drivers[0]?.driver_gps?.longitude,
      hooked_to: !!c?.hook_to,
      heading: c?.hook_to?.drivers[0]?.driver_gps?.heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.fname : '',
      drivers_lname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.lname : '',
      phone_number: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.phone_number : '',
      image: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.image : '',
      driver_id: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.id : '',
      current_location:
        c?.hook_to?.drivers && c?.hook_to?.drivers[0]?.driver_gps ? c?.hook_to?.drivers[0]?.driver_gps?.location : '',
      last_update:
        c?.hook_to?.drivers && c?.hook_to?.drivers[0]?.driver_gps ? c?.hook_to?.drivers[0]?.driver_gps?.updated_at : '',
      status: c?.equipment_status?.equipment_status,
      speed: c?.equipment_connected_services_device?.speed_mph,
      motion:
        c?.hook_to?.drivers && c?.hook_to?.drivers[0]?.driver_gps ? c?.hook_to?.drivers[0]?.driver_gps?.motion : '',
    }));
    const DriverGPSwithLastKnown = HookToDriverGPS?.filter((g) => !g?.hook_to?.drivers);
    const DriverGPSwithLastKnownCopy = HookToDriverGPS?.filter((g) => !g?.hooked_to?.drivers?.driver_gps);
    const DriverGpslastknown = [...DriverGPSwithLastKnown, DriverGPSwithLastKnownCopy];
    const DriverGPSwithLastKnownCoords = DriverGpslastknown?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
      },
      latitude: c?.hook_to?.last_known_latitude,
      longitude: c?.hook_to?.last_known_longitude,
      hooked_to: !!c?.hook_to,
      heading: c?.hook_to?.last_known_heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.fname : '',
      drivers_lname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.lname : '',
      phone_number: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.phone_number : '',
      image: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.image : '',
      driver_id: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.id : '',
      current_location: c?.hook_to?.last_known_location,
      last_update: c?.hook_to?.last_known_update,
      status: c?.equipment_status?.equipment_status,
      speed: c?.hook_to?.drivers?.driver_gps?.speed_mph,
      motion:
        c?.hook_to?.drivers && c?.hook_to?.drivers[0]?.driver_gps ? c?.hook_to?.drivers[0]?.driver_gps?.motion : '',
    }));
    const hookToequipmentGPS = hookToVehicleGps?.filter((v) => v?.hook_to?.location_target === 'Equipment');

    const CopyhookToequipmentGPS = hookToequipmentGPS?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
      },
      latitude: c?.hook_to?.equipment_gps?.latitude,
      longitude: c?.hook_to?.equipment_gps?.longitude,
      hooked_to: !!c?.hook_to,
      heading: c?.hook_to?.equipment_gps?.heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.fname : '',
      drivers_lname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.lname : '',
      phone_number: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.phone_number : '',
      image: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.image : '',
      driver_id: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.id : '',
      current_location: c?.hook_to?.equipment_gps?.location,
      last_update: c?.hook_to?.equipment_gps?.updated_at,
      status: c?.equipment_status?.equipment_status,
      speed: c?.hook_to?.equipment_connected_services_device?.speed_mph,
      engine_state: c?.hook_to?.equipment_connected_services_device?.engine_state,
      motion: c?.hook_to?.equipment_gps?.motion,
    }));
    const hookToEquipmentGPSwithLastKnown = hookToequipmentGPS?.filter((g) => !g?.hook_to?.equipment_gps);

    const hookToEquipmentGPSwithLastKnownCoords = hookToEquipmentGPSwithLastKnown?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
        driver: c?.hook_to?.drivers?.length
          ? `${c?.hook_to?.drivers?.[0]?.fname} ${c?.hook_to?.drivers?.[0]?.lname}`
          : null,
      },
      latitude: c?.hook_to?.last_known_latitude,
      longitude: c?.hook_to?.last_known_longitude,
      hooked_to: !!c?.hook_to,
      heading: c?.hook_to?.last_known_heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.fname : '',
      drivers_lname: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.lname : '',
      phone_number: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.phone_number : '',
      image: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.image : '',
      driver_id: c?.hook_to?.drivers ? c?.hook_to?.drivers[0]?.id : '',
      current_location: c?.hook_to?.last_known_location,
      last_update: c?.hook_to?.last_known_update,
      status: c?.hook_to?.equipment_status,
      speed: c?.equipment_connected_services_device?.speed_mph,
      motion: c?.hook_to?.equipment_gps?.motion,
    }));

    const finalCoords = [
      ...equipmentCoords,
      ...driverCoords,
      ...EquipmentGPSwithLastKnownCoords,
      ...DriverGPSwithLastKnownCoords,
      ...CopyhookToequipmentGPS,
      ...hookToEquipmentGPSwithLastKnownCoords,
    ];
    const data = finalCoords?.filter((c) => c?.latitude);
    setCoords(data);
    if (!zoomDataTrailer.length) {
      setZoomDataTrailer(data);
    }
  }, [latlng, loading]);

  const navigate = useNavigate();
  const garlaxPopup = useRef([]);

  useEffect(() => {
    if (!coords.length && loading === true) {
      return;
    }
    TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
    const myMap = new TrimbleMaps.Map({
      container: 'myMap',
      style: TrimbleMaps.Common.Style.BASIC,
      center: new TrimbleMaps.LngLat(-96.7026, 40.8136),
      zoom: 4.6,
    });
    if (myMap) {
      setMyCustomMap(myMap);
      setMapInstance(myMap);
      setGetCoords(coords);
    }

    function getGeoJsonFeatures() {
      const features = [];

      coords?.forEach((v, i) => {
        const lng = v?.longitude;
        const lat = v?.latitude;
        let type;

        if (v?.hooked_to) {
          if (v?.speed?.toString().split('.')[0] && v?.speed?.toString().split('.')[0] !== '0') {
            type = 'vehiclePlusTrailer';
          } else {
            type = 'greyVehiclePlusTrailer';
          }
        } else {
          if (v.equipment_status?.id === 6) {
            type = 'purpleSquare';
          } else {
            type = 'greyTrailer';
          }
        }

        features.push({
          type: 'Feature',
          properties: {
            i,
            Type: type,
            bearing: parseInt(v.heading, 10),
            equipment_id: v?.equipment_id,
            id: v?.id,
          },
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
        });
      });

      return features;
    }
    let GPS;

    const trailer = new Image(); // Image constructor
    trailer.src = Icons.Trailers;
    trailer.alt = 'alt';

    const VehiclePlusTrailer = new Image(); // Image constructor
    VehiclePlusTrailer.src = Icons.VehiclePlusTrailer;
    VehiclePlusTrailer.alt = 'alt';

    const GreyTrailer = new Image(); // Image constructor
    GreyTrailer.src = Icons.GreyColorTrailer;
    GreyTrailer.alt = 'alt';

    const GreyVehiclePlusTrailer = new Image(); // Image constructor
    GreyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;
    GreyVehiclePlusTrailer.alt = 'alt';

    const PurpleSquare = new Image();
    PurpleSquare.src = Icons.PurpleSquare;
    PurpleSquare.alt = 'alt';

    const GreenTrailer = new Image();
    GreenTrailer.src = Icons.GreenSquare;
    GreenTrailer.alt = 'alt';

    // Wait for the map to load before adding layers and data sources
    myMap.on('load', () => {
      myMap.addControl(new TrimbleMaps.FullscreenControl());
      myMap.addControl(new TrimbleMaps.NavigationControl());

      // Use cluster properties on datasource to group data
      myMap.addSource('randomPoints', {
        type: 'geojson',
        cluster: true,
        clusterRadius: 40,
        clusterMaxZoom: 14,
        data: {
          type: 'FeatureCollection',
          features: getGeoJsonFeatures(),
        },
      });

      // Use filter to shot clustered points
      myMap.addLayer({
        id: 'clusteredPoints',
        type: 'circle',
        source: 'randomPoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 50, 25],
          'circle-color': '#4f5aed',
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 3,
        },
      });

      // Show count for clustered points
      myMap.addLayer({
        id: 'clusterCount',
        type: 'symbol',
        source: 'randomPoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count}',
          'text-font': ['Roboto Regular'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#FFF',
        },
      });

      // let popup;
      // let closeTimeout;

      if (updatePoint) {
        myMap.getSource('randomPoints').setData(updatePoint);
      }

      myMap.on('move', () => {
        removeGarlaxPopups();
      });

      myMap.on('render', 'hqPoints', (evt) => {
        removeGarlaxPopups();

        for (let i = 0; i < evt?.features?.length; i++) {
          const index = evt.features[i].properties?.i;
          const popupLocation = evt.features[i].geometry.coordinates.slice();
          const driverAssigned = !!coords[index]?.drivers_fname;
          const driverName = driverAssigned ? `(${coords[index]?.drivers_fname} ${coords[index]?.drivers_lname})` : '';
          const equipmentId = coords[index]?.equipment_id;
          const vehicleId = coords[index]?.hook_to?.equipment_id;
          const trailerTableId = coords[index]?.id;
          const vehicleTableId = coords[index]?.hook_to?.id;

          const popupContent = `
            <div class='d-flex flex-column align-items-start gap-1'>
              <span id='trailer-box-${trailerTableId}' class='trailer-info info-box pointer'>
                 ${equipmentId} 
              </span>
              <span
               id='vehicle-box-${vehicleTableId}'
               class='vehicle-info info-box pointer' 
               style='display: ${vehicleId ? 'inline' : 'none'}'
              >
                ${vehicleId} ${driverName}
              </span>
            </div>
          `;

          const pop = new TrimbleMaps.Popup({ closeButton: false })
            .setLngLat(popupLocation)
            .setHTML(popupContent)
            .addTo(myMap);
          garlaxPopup.current.push(pop);
          pop.addClassName(`garlax-popup`);
          if (vehicleId) {
            pop.addClassName(`with-trailer`);
          }

          const trailer = document.getElementById(`trailer-box-${trailerTableId}`);
          trailer?.addEventListener('click', () => {
            navigate(`/equipment-profile/trailer/${trailerTableId}`);
          });

          if (vehicleTableId) {
            const vehicle = document.getElementById(`vehicle-box-${vehicleTableId}`);
            vehicle?.addEventListener('click', () => {
              navigate(`/equipment-profile/vehicle/${vehicleTableId}`);
            });
          }
        }
      });

      myMap.on('click', 'hqPoints', (evt) => {
        if (!evt?.features?.length) {
          return;
        }

        const index = evt.features[0].properties?.i;
        navigate(`/equipment-profile/trailer/${coords[index]?.id}`);
        // const popupLocation = evt.features[0].geometry.coordinates.slice();
        // const Eqstatus = coords[index]?.equipment_status?.equipment_status;
        // const EqColorStatus = coords[index]?.equipment_status?.color;
        // const EqBgColorStatus = coords[index]?.equipment_status?.background_color;
        // const driverAssigned = !!coords[index]?.drivers_fname;
        // const image = coords[index]?.image;
        // const href = `tel:${coords[index]?.phone_number}`;
        // const motionText = coords[index]?.motion?.motion;
        // const motionDuration = coords[index]?.motion?.duration;
        //
        // const showImage = !!coords[index]?.hook_to?.equipment_id;
        // const popupContent = `
        //   <div class="row">
        //     <div class='d-flex gap-2 mt-2 mb-2'>
        //       <div class=' d-flex'>
        //         <div class='driver-on-popup' style='color:#4F5AED'>
        //           ${coords[evt.features[0].properties?.i]?.equipment_id}
        //         </div>
        //         <div class='driver-on-popup ms-1'>
        //           ${coords[evt.features[0].properties?.i]?.equipment_type}
        //         </div>
        //       </div>
        //       <div class=${showImage ? 'd-block' : 'd-none'}>
        //         <img src=${Icons.EquipmentProfileIcon} alt='ig'/>
        //       </div>
        //       <div class='driver-on-popup '>
        //         <span style='color:#4F5AED'>
        //           ${
        //             coords[evt.features[0].properties?.i]?.hook_to?.equipment_id
        //               ? coords[evt.features[0].properties?.i]?.hook_to?.equipment_id
        //               : ''
        //           }
        //         </span>
        //             <span class='ms-1'> ${
        //               coords[evt.features[0].properties?.i]?.hook_to?.length
        //                 ? coords[evt.features[0].properties?.i]?.hook_to?.length
        //                 : ''
        //             }</span>
        //             <span>${
        //               coords[evt.features[0].properties?.i]?.hook_to?.unit
        //                 ? coords[evt.features[0].properties?.i]?.hook_to?.unit
        //                 : ''
        //             }</span>
        //             <span>${
        //               coords[evt.features[0].properties?.i]?.hook_to?.title
        //                 ? coords[evt.features[0].properties?.i]?.hook_to?.title
        //                 : ''
        //             }</span>
        //
        //         </div>
        //     </div>
        //     </div>
        //     <div class='popup-style'>
        //         <div class="col driver-on-popup">
        //             <div class='d-flex col-8 w-50 justify-content-start'>
        //                 <div class='equipment-status-on-popup' style=background-color:${
        //                   motionColors[motionText]?.bgColor
        //                 }>
        //                                           <span style=color:${motionColors[motionText]?.color}>${
        //   motionText || '-'
        // } ${formatSeconds(motionDuration)}</span>
        //                 </div>
        //             </div>
        //         </div>
        //         <div class='row mt-2 mb-2'>
        //             <div class=${driverAssigned ? 'd-none' : 'd-flex col-12 justify-content-start'}>
        //             <div class='equipment-status-on-popup' style=background-color:${EqBgColorStatus}><span
        //                     style=color:${EqColorStatus}>${Eqstatus}</span>
        //             </div>
        //         </div>
        //         <div class='col-6'>
        //             <div class='driver-on-popup'>
        //                 <div class='image-wrapper pointer-event' id='viewDriver'>
        //                     <div class=${
        //                       image ? 'd-block' : 'd-none'
        //                     }><img style="width: 25px; height: 25px; border-radius: 50%;" src=${image}/></div>
        //                 <div>
        //                             <span>${
        //                               coords[evt.features[0].properties?.i]?.drivers_fname
        //                                 ? coords[evt.features[0].properties?.i]?.drivers_fname
        //                                 : ''
        //                             }
        //                               </span>
        //                     <span>
        //                                 ${
        //                                   coords[evt.features[0].properties?.i]?.drivers_lname
        //                                     ? coords[evt.features[0].properties?.i]?.drivers_lname
        //                                     : ''
        //                                 }
        //                             </span>
        //                 </div>
        //             </div>
        //             <a class='mt-2 text-decoration-none pointer' id='callToDriver'>${
        //               coords[evt.features[0].properties?.i]?.phone_number
        //                 ? formatPhoneNumberIntl(coords[evt.features[0].properties?.i]?.phone_number)
        //                 : ''
        //             }
        //             </a>
        //         </div>
        //     </div>
        //     <div class='driver-on-popup mt-2'>
        //         <img src=${Icons.Location} class='me-2 '/>${
        //   coords[evt.features[0].properties?.i]?.current_location
        //     ? coords[evt.features[0].properties?.i]?.current_location
        //     : ''
        // }
        //     </div>
        //     <div class='driver-on-popup mt-2'>${
        //       coords[evt.features[0].properties?.i]?.last_update
        //         ? convertToCustomerTime(coords[evt.features[0].properties?.i]?.last_update, 'll hh:mm A')
        //         : ''
        //     }
        //     </div>
        //     <div class='d-flex'>
        //         <!--           <button class='select-vehicle mt-2 me-1' id='selectVehicle'>Select Trailer </button>-->
        //         <button class='select-vehicle mt-2' id='viewProfile1'>Select Trailer</button>
        //     </div>
        //   `;

        // const popupContent1 = `
        //   <div class="popup-style">
        //     <div class="row">
        //       <div class='mt-2 mb-2'>
        //         <div class='w-100 d-flex align-items-center justify-content-between gap-2'>
        //           <div class='d-flex gap-1'>
        //             <div class='driver-on-popup' style='color:#4F5AED'>
        //               ${coords[evt.features[0].properties?.i]?.equipment_id}
        //             </div>
        //             <div class='driver-on-popup ms-1'>${coords[evt.features[0].properties?.i]?.equipment_type}</div>
        //             <div class=${showImage ? 'd-block' : 'd-none'}>
        //               <img src=${Icons.EquipmentProfileIcon} alt='ig' />
        //             </div>
        //             <div>
        //               <div class='driver-on-popup nowrap'>
        //                 <span style='color:#4F5AED'>
        //                   ${
        //                     coords[evt.features[0].properties?.i]?.hook_to?.equipment_id
        //                       ? coords[evt.features[0].properties?.i]?.hook_to?.equipment_id
        //                       : ''
        //                   }
        //                 </span>
        //                 <span class='ms-1'>
        //                   ${
        //                     coords[evt.features[0].properties?.i]?.hook_to?.length
        //                       ? coords[evt.features[0].properties?.i]?.hook_to?.length
        //                       : ''
        //                   }
        //                 </span>
        //                 <span>
        //                   ${
        //                     coords[evt.features[0].properties?.i]?.hook_to?.unit
        //                       ? coords[evt.features[0].properties?.i]?.hook_to?.unit
        //                       : ''
        //                   }
        //                 </span>
        //                 <span>
        //                   ${
        //                     coords[evt.features[0].properties?.i]?.hook_to?.title
        //                       ? coords[evt.features[0].properties?.i]?.hook_to?.title
        //                       : ''
        //                   }
        //                 </span>
        //               </div>
        //             </div>
        //           </div>
        //           <div class='d-flex align-items-center gap-2'>
        //             <div class=${driverAssigned ? 'd-none' : 'd-flex col-6 w-50 justify-content-start'}>
        //               <div class='equipment-status-on-popup' style=background-color:${EqBgColorStatus}>
        //                 <span style=color:${EqColorStatus}>
        //                   ${Eqstatus}
        //                 </span>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <div class='popup-style'>
        //     <div class="row mb-1">
        //       <div class="col-12 d-flex justify-content-end gap-3">
        //         <div class='d-flex'>
        //           <div class='equipment-status-on-popup' style=background-color:${motionColors[motionText]?.bgColor}>
        //             <span style=color:${motionColors[motionText]?.color}>
        //               ${motionText || '-'} ${formatSeconds(motionDuration)}
        //             </span>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     <div class="row">
        //       <div class='col-6'>
        //         <div class='driver-on-popup'>
        //           <div class='image-wrapper pointer' id='viewDriver'>
        //             <div class=${image ? 'd-block' : 'd-none'}>
        //               <img style="width: 25px; height: 25px; border-radius: 50%;" src=${image} alt='' />
        //             </div>
        //             <div>
        //               <span>
        //                 ${
        //                   coords[evt.features[0].properties?.i]?.drivers_fname
        //                     ? coords[evt.features[0].properties?.i]?.drivers_fname
        //                     : ''
        //                 }
        //               </span>
        //               <span>
        //                 ${
        //                   coords[evt.features[0].properties?.i]?.drivers_lname
        //                     ? coords[evt.features[0].properties?.i]?.drivers_lname
        //                     : ''
        //                 }
        //               </span>
        //             </div>
        //           </div>
        //           <a class='mt-2 text-decoration-none pointer' id='callToDriver'>
        //             ${
        //               coords[evt.features[0].properties?.i]?.phone_number
        //                 ? formatPhoneNumberIntl(coords[evt.features[0].properties?.i]?.phone_number)
        //                 : ''
        //             }
        //           </a>
        //       </div>
        //   </div>
        //   <div class='driver-on-popup'>
        //     <img src=${Icons.Location} alt='location' class='me-2'/>
        //       ${
        //         coords[evt.features[0].properties?.i]?.current_location
        //           ? coords[evt.features[0].properties?.i]?.current_location
        //           : ''
        //       }
        //   </div>
        //   <div class='driver-on-popup mt-2'>
        //     ${
        //       coords[evt.features[0].properties?.i]?.last_update
        //         ? convertToCustomerTime(coords[evt.features[0].properties?.i]?.last_update, `ll ${timeFormat}`)
        //         : ''
        //     }
        //   </div>
        //   <div class='d-flex justify-content-end'>
        //     <button class='select-vehicle mt-2 ' id='viewProfile'>
        //       <img src='${navigateIcon}' alt='navigate' />
        //     </button>
        //   </div>
        // `;
        //
        // if (popup) {
        //   popup?.remove();
        //   popup = null;
        // }
        //
        // popup = new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent1).addTo(myMap);
        // popup.addClassName('popup-wrapper');
        //
        // const popupElement = popup.getElement();
        //
        // popupElement.addEventListener('mouseenter', () => {
        //   clearTimeout(closeTimeout);
        // });
        //
        // popupElement.addEventListener('mouseleave', () => {
        //   closeTimeout = setTimeout(() => {
        //     popup.remove();
        //     popup = null;
        //   }, 300); // Feel free to adjust the delay as needed.
        // });
        //
        // const viewButton = document.getElementById('viewProfile');
        // viewButton.addEventListener('click', () => {
        //   navigate(`/equipment-profile/trailer/${coords[index]?.id}`);
        // });
        //
        // const viewDriver = document.getElementById('viewDriver');
        // viewDriver.addEventListener('click', () => {
        //   navigate(`/driver-profile/${coords[evt.features[0].properties?.i]?.driver_id}/general`);
        // });
        //
        // const callHref = document.getElementById('callToDriver');
        // callHref.href = href;
      });

      // Change cursor when hovering over a feature on the hqPoints layer
      myMap.on('mouseenter', 'hqPoints', () => {
        myMap.getCanvas().style.cursor = 'pointer';
      });

      // // Change cursor back
      // myMap.on('mouseout', 'hqPoints', () => {
      //   closeTimeout = setTimeout(() => {
      //     if (popup) {
      //       popup.remove();
      //       popup = null;
      //     }
      //   }, 300);
      // });

      let popupIk;
      // Listen for clicks on the truck stops layer
      myMap.on('click', 'truck-stops', (evt) => {
        if (!evt?.features?.length) {
          return;
        }

        const popupLocation = evt.features[0].geometry.coordinates.slice();
        const popupContent = `Name: ${evt.features[0].properties.name}<br />
                    ID: ${evt.features[0].properties.poi}<br />
                    Set: ${evt.features[0].properties.set}`;

        popupIk = new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent).addTo(myMap);
      });

      myMap.on('zoomend', () => {
        const zoom = myMap.getZoom();
        if (zoom <= 9 && popupIk && popupIk.isOpen()) {
          popupIk.remove(); // Remove popup from the map.
        }
      });

      myMap.on('move', () => {
        // Update stored center and zoom whenever the map is moved
        setLastCenter(myMap.getCenter());
        setLastZoom(myMap.getZoom());
      });

      myMap.addImage('trailer', trailer);
      myMap.addImage('VehiclePlusTrailer', VehiclePlusTrailer);
      myMap.addImage('GreyTrailer', GreyTrailer);
      myMap.addImage('GreyVehiclePlusTrailer', GreyVehiclePlusTrailer);
      myMap.addImage('PurpleSquare', PurpleSquare);
      myMap.addImage('GreenTrailer', GreenTrailer);

      myMap.addLayer({
        id: 'hqPoints',
        type: 'symbol',
        source: 'randomPoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'match', // type of expression
            ['get', 'Type'], // property to match
            'trailers',
            'trailer',
            'greyTrailer',
            'GreyTrailer',
            'vehiclePlusTrailer',
            'VehiclePlusTrailer',
            'greyVehiclePlusTrailer',
            'GreyVehiclePlusTrailer',
            'purpleSquare',
            'PurpleSquare',
            'greenTrailer',
            'GreenTrailer',
            '',
          ],
          'icon-rotate': ['get', 'bearing'],
          'icon-rotation-alignment': 'auto',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          // "text-field": ["get", "equipment_id"],
          'text-allow-overlap': true,
          'text-font': ['Roboto Regular'],
          'text-anchor': 'bottom',
          'text-offset': [0, -3],
          'text-padding': 20,
          'text-size': 10,
        },
      });

      myMap.addLayer({
        id: 'truck-stops',
        type: 'symbol',
        source: 'Base',
        'source-layer': 'poi',
        paint: {
          'icon-halo-color': 'rgba(255, 255, 255, 1)',
          'icon-halo-width': 5,
          'icon-translate': [0, -1],
          'text-halo-color': 'rgba(255, 255, 255, 0.8)',
          'text-halo-width': 1.5,
          'text-color': 'rgba(5, 57, 150, 1)',
        },
        layout: {
          'icon-image': 'poi_truck_stop',
          'icon-size': ['interpolate', ['linear'], ['zoom'], 9, 0.7, 12, 1],
          'text-field': ['step', ['zoom'], '', 12, ['get', 'name']],
          'text-font': ['Roboto Regular'],
          'text-anchor': 'top',
          'text-size': 11,
          'text-offset': [0, 1],
        },
        filter: ['match', ['get', 'type'], ['truck_stop'], true, false],
      });
      myMap.setLayoutProperty('truck-stops', 'visibility', 'none');

      let uniqueEquipmentIds = [];

      setLoadingMap(false);

      myMap.on('zoomend', () => {
        const zoom = myMap.getZoom();
        let zoomedFeatures;
        let timer = null;
        let animations = {};
        let equipmentLocations = {};
        const lastUpdates = {};

        if (zoom >= 9) {
          zoomedFeatures = myMap.querySourceFeatures('randomPoints', {
            sourceLayer: 'hqPoints',
          });
          uniqueEquipmentIds = [...new Set(zoomedFeatures.map((obj) => obj.properties.id))].filter(
            (equipmentId) => equipmentId !== undefined
          );
          mapPageFlag({ equipment_id: uniqueEquipmentIds })
            .then(() => {
              GPS = PusherJs.subscribe(`private-equipmentLocation-MapPage.${dot}`);

              GPS.bind('equipment-location', (updateData) => {
                const equipmentId = updateData.message.equipment_id;
                const data = updateData.message.data;
                const speed_mph = updateData.message.data.speed_mph || 0;
                if (!lastUpdates[equipmentId] || !deepEqual(lastUpdates[equipmentId], data)) {
                  lastUpdates[equipmentId] = data; // Save new update

                  equipmentLocations[equipmentId] = {
                    coordinates: [data.longitude, data.latitude],
                    bearing: data.heading,
                    duration: +speed_mph * 150,
                    is_hooked: data.is_hooked,
                    engine_state: data.engine_state,
                  };
                }

                if (myMap && myMap.getSource('randomPoints')) {
                  window.cancelAnimationFrame(timer);
                  timer = window.requestAnimationFrame(updateEquipmentLocations.bind(this, speed_mph));
                }
              });

              function updateEquipmentLocations(speed_mph, timestamp) {
                const sourceData = myMap.getSource('randomPoints')._data;
                const newData = sourceData?.features?.map((item) => {
                  if (equipmentLocations.hasOwnProperty(item.properties.id)) {
                    const { coordinates, bearing, duration, is_hooked, engine_state } =
                      equipmentLocations[item.properties.id];
                    let oldCoordinates;
                    const feature = sourceData?.features?.find(
                      (feature) => feature.properties.id === item.properties.id
                    );
                    if (feature) {
                      oldCoordinates = feature.geometry.coordinates;
                    }

                    if (!animations[item.properties.id] && oldCoordinates) {
                      animations[item.properties.id] = {
                        startTime: timestamp,
                        startCoordinates: oldCoordinates,
                        endCoordinates: coordinates,
                        duration,
                      };
                    }

                    if (animations[item.properties.id]) {
                      let progress =
                        duration === 0
                          ? 1
                          : (timestamp - animations[item.properties.id].startTime) /
                            animations[item.properties.id].duration;

                      if (progress > 1) {
                        progress = 1;
                        delete animations[item.properties.id];
                      }

                      if (animations[item.properties.id]) {
                        const newCoordinates = [
                          animations[item.properties.id].startCoordinates[0] * (1 - progress) +
                            coordinates[0] * progress,
                          animations[item.properties.id].startCoordinates[1] * (1 - progress) +
                            coordinates[1] * progress,
                        ];

                        const isMoving = duration > 150;
                        const type = getLiveMarker(is_hooked, isMoving, !!engine_state, engine_state);

                        return {
                          type: 'Feature',
                          properties: {
                            ...item.properties,
                            Type: type,
                            bearing,
                          },
                          geometry: {
                            type: 'Point',
                            coordinates: newCoordinates,
                          },
                        };
                      }
                    }
                  }

                  return item;
                });

                const updatedPoints = {
                  ...sourceData,
                  features: newData,
                };

                myMap.getSource('randomPoints').setData(updatedPoints);

                if (Object.keys(equipmentLocations).length > 0 || Object.keys(animations).length > 0) {
                  timer = window.requestAnimationFrame(updateEquipmentLocations.bind(this, speed_mph));
                }
              }
            })
            .catch(() => {
              // Do nothing
            });
        } else {
          timer = null;
          animations = null;
          equipmentLocations = null;

          if (uniqueEquipmentIds.length) {
            mapPageFlag({
              equipment_id: uniqueEquipmentIds,
              delete_flag: true,
            }).catch(() => {
              // Do nothing
            });
          }
          GPS && GPS.unsubscribe(`private-equipmentLocation-MapPage.${dot}`);
        }
      });
    });

    return () => {
      GPS && GPS.unsubscribe(`private-equipmentLocation-MapPage.${dot}`);
    };
  }, [coords, loading, mapLayersValues]);

  const routemarker = [];
  const routeContent = [];
  useEffect(() => {
    if (firstCoords.length === 1) {
      if (radius) return;
      if (!mapInstance) return;
      if (routeCoords.length >= 2) {
        setTimeout(() => {
          // if (!stopPointsData || !stopPointsData.length) return;
          routeCoords?.map((tracking, i) => {
            const city = tracking?.city;
            const state = tracking?.state;
            const zipcode = tracking?.zipcode;
            const streetAddress = tracking?.streetAddress;

            const el = document.createElement('div');
            el?.classList?.add(`black-marker${i}`);
            // el.title = "Marker with a label and an icon";
            if (i !== 0) {
              el.innerHTML = `<div style='text-transform: uppercase; color: white; background:#2196F3; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 15px 35px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);border-radius: 4px;margin-bottom:10px;width: max-content;height: 20px;left:30px;position:absolute;top:-20px;padding:10px;display:flex;'
            '><span style="font-family: Inter;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
            ${i !== 0 ? `Stop ${getAlphabet(i - 1)}` : ''}: </span>
              <span style="font-family: Inter;margin-left:3px ;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
                ${streetAddress ? `${streetAddress},` : ''}
                ${city ? `${city}` : ''}
                ${state ? `,${state}` : ''}
                ${zipcode ? `,${zipcode}` : ''}</span></div>`;
            } else {
              el.innerHTML = `<div style='text-transform: uppercase; color: white;background: #AA5B00;box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 15px 35px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);border-radius: 4px;margin-bottom:10px;width: 160px;height: 20px;left:30px;position:absolute;top:-20px;padding:10px;display:flex;'>
                                      <span style="font-family: Inter;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">Dead Head - ${Math.round(
                                        stopPointsData[0]?.emptyMiles
                                      )} Miles</span>
                                </div>`;
            }
            const marker = new TrimbleMaps.Marker({
              color: 'blue',
              element: el,
            })
              .setLngLat([tracking?.longitude, tracking?.latitude])

              .addTo(mapInstance);

            if (i !== 0) {
              const ele = document.createElement('div');
              ele?.classList?.add(`cust-marker${i}`);
              const htmlContent1 = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;
              ele.innerHTML = htmlContent1;
              const marker2 = new TrimbleMaps.Marker({
                element: ele,
                color: 'red',
              })
                .setLngLat([tracking?.longitude, tracking?.latitude])
                .addTo(mapInstance);
              routemarker.push(marker2);
            }
            routeContent.push(marker);
            setRouteMarkers(routemarker);
            setRouteStopContent(routeContent);
            return true;
          });
        }, 3000);
      }
    }
  }, [
    routeCoords,
    routeTypeMap,
    routeChange,
    routeOptions,
    mapInstance,
    firstCoords,
    // stopPointsData,
  ]);

  const removeGarlaxPopups = () => {
    if (garlaxPopup.current?.length) {
      garlaxPopup.current.forEach((item) => item.remove());
      garlaxPopup.current = [];
    }
  };

  useEffect(() => {
    if (firstCoordsFound) return;
    if (firstCoords.length === 1) return;
    if (radius) return;
    if (!mapInstance) return;
    if (routeCoords.length >= 1) {
      setTimeout(() => {
        routeCoords?.forEach((tracking, i) => {
          const city = tracking?.city;
          const state = tracking?.state;
          const zipcode = tracking?.zipcode;
          const el = document.createElement('div');
          el?.classList?.add(`black-marker${i}`);
          const htmlContent = `<div style='text-transform: uppercase; color: white; background:#2196F3; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 15px 35px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);border-radius: 4px;margin-bottom:10px;width: max-content;height: 20px;left:30px;position:absolute;top:-20px;padding:10px;display:flex;'
          '><span style="font-family: Inter;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
          ${`Stop ${getAlphabet(i)}`}: </span>
            <span style="font-family: Inter;margin-left:3px ;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">${
              city ? `${city},` : ''
            } ${state ? `${state},` : ''} ${zipcode ? `${zipcode}` : ''}</span></div>`;
          el.innerHTML = htmlContent;
          const marker = new TrimbleMaps.Marker({
            color: 'blue',
            element: el,
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])

            .addTo(mapInstance);
          const ele = document.createElement('div');
          ele?.classList?.add(`cust-marker${i}`);
          const htmlContent1 = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;
          ele.innerHTML = htmlContent1;
          const marker2 = new TrimbleMaps.Marker({
            element: ele,
            color: 'red',
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .addTo(mapInstance);
          routemarker.push(marker2);
          routeContent.push(marker);
          setRouteMarkers(routemarker);
          setRouteStopContent(routeContent);
        });
      }, 3000);
    } else {
      if (routeMarkers.length) {
        routeMarkers.map((M) => M.remove());
      }
      if (routeStopContent.length) {
        routeStopContent.map((m) => m?.remove());
      }
    }
  }, [routeCoords, routeTypeMap, routeChange, routeOptions, mapInstance, firstCoords]);

  function handleSpanClick(stopId, count, equipmentType) {
    dispatch(onChangeInventoryTab(equipmentType));
    navigate(`/stop-point/${stopId}/${STOP_POINT_TAB.INVENTORY}`);
  }

  const StopPointArr = [];
  useEffect(() => {
    if (!mapInstance) return;
    if (stopPointVisible) {
      if (stopPointAll.length > 1) {
        stopPointAll?.forEach((StopPointsData, i) => {
          const address1 = StopPointsData?.value?.address1;
          const address2 = StopPointsData?.value?.address2;
          const city = StopPointsData?.value?.city;
          const state = StopPointsData?.value?.state;
          const zipcode = StopPointsData?.value?.zipcode;
          const country = StopPointsData?.value?.country;
          const StopPointName = StopPointsData?.value?.current_location;
          const stop_point_vehicles_count = StopPointsData?.value?.stop_point_vehicles_count;
          const stop_point_trailers_count = StopPointsData?.value?.stop_point_trailers_count;
          const stopId = StopPointsData?.id;

          const el = document.createElement('div');
          el?.classList?.add(`black-marker${i}`);
          // Create a popup
          const popup = new TrimbleMaps.Popup({
            offset: 0,
          }).setHTML(
            `<span style="font-family: 'Inter', sans-serif; font-size: 18px; font-weight: bold; color: #000000; display: block; margin-bottom: 10px;">${StopPointName}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${address1}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${address2}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${city}, ${state} ${zipcode}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${country}</span>
            <div style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">Current Vehicles - <span style="font-weight: bold; color: #4F5AED; cursor: pointer" id='equipment-map-vehicle-in-stop'>${stop_point_vehicles_count}</span></div>
            <div style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">Current Trailers - <span style="font-weight: bold; color: #4F5AED; cursor: pointer" id='equipment-map-trailer-in-stop'>${stop_point_trailers_count}</span></div>
           `
          );

          popup.on('open', () => {
            const vehicleSpan = document.getElementById('equipment-map-vehicle-in-stop');
            vehicleSpan?.addEventListener('click', () => handleSpanClick(stopId, stop_point_vehicles_count, 1));

            const trailerSpan = document.getElementById('equipment-map-trailer-in-stop');
            trailerSpan?.addEventListener('click', () => handleSpanClick(stopId, stop_point_trailers_count, 2));
          });

          const ele = document.createElement('div');
          ele?.classList?.add(`cust-marker${i}`);
          const htmlContent1 = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;
          ele.innerHTML = htmlContent1;
          const marker2 = new TrimbleMaps.Marker({
            element: ele,
            color: 'red',
          })
            .setLngLat([StopPointsData?.value?.longitude, StopPointsData?.value?.latitude])
            .setPopup(popup)
            .addTo(mapInstance);
          StopPointArr.push(marker2);
          setStopPointMarker(StopPointArr);
        });
      }
    } else {
      if (stopPointMarker.length) {
        stopPointMarker.map((M) => M.remove());
      }
    }
  }, [mapInstance, stopPointAll, stopPointVisible]);

  useEffect(() => {
    if (radius) {
      if (!mapInstance) return;
      if (stopPointCoords.length >= 1) {
        setTimeout(() => {
          stopPointCoords?.forEach((stopPointValue, i) => {
            const ele = document.createElement('div');
            ele?.classList?.add(`cust-marker${i}`);
            const htmlContent1 = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;
            ele.innerHTML = htmlContent1;
            new TrimbleMaps.Marker({
              element: ele,
              color: 'red',
            })
              .setLngLat([stopPointValue?.longitude, stopPointValue?.latitude])
              .addTo(mapInstance);
          });
        }, 3000);
      }
    }
  }, [coords, mapInstance, stopPointCoords]);

  // useEffect(() => {
  //   if(radius) {
  //   if (!mapInstance) return;
  //   if (coords.length < 5) {
  //       coords?.map((v)=>{

  //         setTimeout(() => {

  //           const myRoute = new TrimbleMaps.Route({
  //             routeId: `myRoute_${Math.random()}`,
  //             isDraggable: false,
  //             stops: [
  //               new TrimbleMaps.LngLat(v?.longitude, v?.latitude),
  //               ...routeCoords.map(
  //                 (tracking) =>
  //                   new TrimbleMaps.LngLat(tracking?.longitude, tracking?.latitude)
  //               ),
  //             ],
  //             frameRoute: true,
  //             // showStops:false,
  //             routeType:
  //               `${routeTypeMap}` === "0"
  //                 ? TrimbleMaps.Common.RouteType.PRACTICAL
  //                 : `${routeTypeMap}` === "1"
  //                 ? TrimbleMaps.Common.RouteType.SHORTEST
  //                 : TrimbleMaps.Common.RouteType.SHORTEST,
  //             routeOption: {
  //               highwayOnly: routeOptions?.highway_only,
  //               bordersOpen: routeOptions?.open_border,
  //             },
  //             routeColor: "#4F5AED",
  //                       routeWidth:5,
  //             tollRoads: `{routeOptions?.avoid_tolls}` === true ? TrimbleMaps.Common.TollRoadsType.AVOID_IF_POSSIBLE : TrimbleMaps.Common.TollRoadsType.NONE,
  //             hazMatType: `{routeOptions?.hazmat}` === true ? TrimbleMaps.Common.HazMatType.GENERAL : TrimbleMaps.Common.HazMatType.NONE,
  //           });

  //           //   myRoute.update({
  //           //     routeId: 'myRoute',
  //           // });
  //           setRouteInstance(myRoute);
  //           myRoute.addTo(mapInstance);
  //         }, 3000);
  //       })

  //   }
  // }
  // }, [routeCoords, routeTypeMap, routeChange, routeOptions,coords,mapInstance]);

  useEffect(() => {
    if (!mapInstance) return;
    mapLayersValues && mapLayersValues === 'SATELLITE'
      ? mapInstance.setStyle(TrimbleMaps.Common.Style.SATELLITE)
      : mapLayersValues === 'TRANSPORTATION_DARK'
      ? mapInstance.setStyle(TrimbleMaps.Common.Style.TRANSPORTATION_DARK)
      : mapInstance.setStyle(TrimbleMaps.Common.Style.BASIC);
    mapInstance.setCenter(lastCenter);
    mapInstance.setZoom(lastZoom);
  }, [mapLayersValues, mapInstance]);

  useEffect(() => {
    if (!mapInstance) return;

    if (mapInstance && mapInstance.isStyleLoaded()) {
      const trafficVisible = mapInstance.isTrafficVisible();
      const radarVisible = mapInstance.isWeatherRadarVisible();
      const poiVisible = mapInstance?.getLayer('truck-stops');

      if (visibleTrafic) {
        mapInstance.setTrafficVisibility(true);
      } else if (trafficVisible) {
        mapInstance.setTrafficVisibility(false);
      }
      if (visibleWeather) {
        mapInstance.setWeatherRadarVisibility(true);
      } else if (radarVisible) {
        mapInstance.setWeatherRadarVisibility(false);
      }
      if (POI) {
        mapInstance.setLayoutProperty('truck-stops', 'visibility', 'visible');
      } else if (poiVisible) {
        mapInstance.setLayoutProperty('truck-stops', 'visibility', 'none');
      }

      if (truckRistriction) {
        const truckRestriction = new TrimbleMaps.TruckRestriction();
        truckRestriction.addTo(mapInstance);
      }
    }
  }, [visibleTrafic, mapInstance, visibleWeather, truckRistriction, POI]);

  useEffect(() => {
    const mapFilters = [];
    if (status) {
      mapFilters.push(status);
    }
    setLoading(true);
    setFilters(mapFilters);
  }, [status, locationFilterData, optionFilterData]);

  return (
    <>
      {loading && (
        <div style={{ height: '100%', width: '100%', backgroundImage: `url(${mapImage})` }}>
          <SplashScreen steps={steps} />
        </div>
      )}
      <div id='myMap' style={{ height: '100%', width: '100%' }} />
    </>
  );
};

export default TrailerContainerMap;
