import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Divider from 'common/Divider';
import Tooltip from 'common/Tooltip';
import AddBalance from 'componentsV2/AddBalance';
import EditBalance from 'componentsV2/EditBalance';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { deleteStaffBalance } from 'Api/Staff';
import { deleteDriverBalance } from 'Api/Driver';
import { STable, SWrapper, SAddMore } from '../Tables.styles';

const Balances = ({
  userId,
  balances,
  userType,
  onSuccess,
  balanceToEdit,
  onEditBalanceClose,
  onViewBalanceDetails,
  onEditBalanceSuccess,
}) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [balanceToDeleteId, setBalanceToDeleteId] = useState(null);
  const [loadingDeleteBalance, setLoadingDeleteBalance] = useState(false);
  const [openAddBalance, setOpenAddBalance] = useState(false);

  const deleteBalance = async () => {
    try {
      setLoadingDeleteBalance(true);
      if (userType === 'driver') {
        await deleteDriverBalance(balanceToDeleteId);
      } else {
        await deleteStaffBalance(balanceToDeleteId);
      }
      onSuccess();
      setBalanceToDeleteId(null);
      showToaster({ type: 'success', message: 'Balance has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingDeleteBalance(false);
    }
  };

  return (
    <SWrapper>
      <div className='d-flex align-items-center gap-3'>
        <Typography variant='h3'>Balances</Typography>
        <Tooltip
          placement='right'
          title={
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Truckin Digital Balances is a feature that enables companies to manage and track financial balances for
              their drivers or staff members. Users can add specific balances for each driver or staff member, allowing
              the company to maintain a clear overview of any deductions or outstanding balances.
            </Typography>
          }
          tooltipStyles={{ bgcolor: palette.white, border: '1px solid #dadde9', maxWidth: '600px' }}
        >
          <InfoIcon width={16} height={16} />
        </Tooltip>
      </div>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>DATE CREATED</th>
            <th>NAME</th>
            <th>TYPE</th>
            <th>PAYMENTS</th>
            <th>PAYMENT AMOUNT</th>
            <th>TERM</th>
            <th>ORIGINAL BALANCE</th>
            <th>BALANCE REMAIN</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {balances?.map((item) => {
            const processedSettlements = item.deductions?.[0]?.settlement?.filter(
              (settlement) => settlement[`${userType}_settlement`].status_id === 3
            );
            const paidAmount = (processedSettlements || []).reduce((acc, cur) => acc + Number(cur.amount), 0);

            return (
              <tr key={item.id} className='body-row' onClick={() => onViewBalanceDetails(item)}>
                <td style={{ width: '15%' }}>
                  <Typography variant='s2'>{convertToCustomerTime(item.created_at)}</Typography>
                </td>
                <td style={{ width: '15%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {item.name}
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {item.type}
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {(processedSettlements || []).length} out of {item.amortized_months}
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatAmount(item.payment_amount)}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {item.month_week_settlement
                      ? `Every ${item.day} of the ${item.month_week}`
                      : `Over ${item.amortized_months} settlements`}
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatAmount(item.original_balance)}
                  </Typography>
                </td>
                <td style={{ width: '5%' }}>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatAmount(item.original_balance - paidAmount)}
                  </Typography>
                </td>
                <td style={{ width: '5%' }}>
                  <span
                    className='action-wrapper'
                    onClick={(e) => {
                      e.stopPropagation();
                      setBalanceToDeleteId(item.id);
                    }}
                  >
                    <DeleteIcon width={9} height={9} fill={palette.red500} />
                  </span>
                </td>
              </tr>
            );
          })}
          <tr className='total-row'>
            <td>
              <SAddMore onClick={() => setOpenAddBalance(true)}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Add Another...
                </Typography>
              </SAddMore>
            </td>
          </tr>
        </tbody>
      </STable>
      {!!balanceToDeleteId && (
        <ConfirmationModal
          width='510px'
          open={!!balanceToDeleteId}
          onClose={() => setBalanceToDeleteId(null)}
          headerTitle='Delete Balance'
          text='Are you sure you want to delete this balance?'
          onConfirm={deleteBalance}
          disabled={loadingDeleteBalance}
        />
      )}
      {!!openAddBalance && (
        <AddBalance
          open={openAddBalance}
          onClose={() => setOpenAddBalance(false)}
          onSuccess={onSuccess}
          userId={userId}
          isStaff={userType === 'staff'}
        />
      )}
      {!!balanceToEdit && (
        <EditBalance
          open={!!balanceToEdit}
          balance={balanceToEdit}
          onClose={onEditBalanceClose}
          userType={userType}
          onSuccess={onEditBalanceSuccess}
        />
      )}
    </SWrapper>
  );
};

export default Balances;
