import React from 'react';
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';

import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadBig from 'assets/icons/documents/uploadBig.png';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from '../Typography';
import ImageDropZone from '../ImgageDropZone';
import CustomTextArea from '../CustomTextArea';
import CustomPhoneInput from '../CustomPhoneInput';
import styles from './SupportTicketModal.module.css';
import CustomInput from '../CreateShipment/helpers/CustomInput';

const SupportTicketModal = ({ open, onClose, loading, onSubmit, userData, ticketTypes, priorities }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();

  const uploadScreenshot = (file, propertyName, setFieldValue) => {
    if (file[0].size > 20971520) {
      showToaster({ type: 'error', message: 'Please upload file under 20MB in size.' });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setFieldValue(propertyName, file[0]);
  };
  const resetUploadedScreenshot = (e, setFieldValue) => {
    e.stopPropagation();
    setFieldValue('uploadScreenshot', null);
  };

  const validateRequired = (value) => {
    let error;
    if (value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const handleSubmit = async (values) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        title: '',
        user_name: userData ? `${userData.first_name} ${userData.last_name}` : '',
        company_name: userData?.customer?.company_name ? userData?.customer.company_name : '',
        company_dot: userData?.customer?.dot ? userData?.customer?.dot : '',
        phone_no: userData?.phone_number ? userData?.phone_number : '',
        image: '',
        description: '',
        type: ticketTypes.find((item) => !!item.default) || null,
        priority: priorities.find((item) => !!item.default) || null,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm, setFieldValue }) => {
        return (
          <Modal
            showModal={open}
            onHide={onClose}
            headerTitle='Create Ticket'
            $bgColor={palette.gray0}
            $maxWidth='448px'
            $minWidth='448px'
            $bodyMaxHeight='70vh'
            className='modified-scrollbar'
            backdrop='static'
            backdropClassName='double-modal-backdrop'
            styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
            buttons={[
              {
                key: 'close',
                type: 'secondary',
                title: 'Cancel',
                onClick: onClose,
              },
              {
                key: 'submit',
                type: 'primary',
                title: 'Create Ticket',
                disabled: loading,
                onClick: submitForm,
              },
            ]}
          >
            <Form>
              <div className={styles.container}>
                <div style={{ marginBottom: 32 }}>
                  <label className={styles.label_wrapper}>
                    <span className={styles.label_required}>Ticket Title</span>
                    <Field
                      type='text'
                      name='title'
                      validate={validateRequired}
                      component={CustomInput}
                      style={{ width: '100%', padding: '6px 12px' }}
                      labelStyle={{ margin: 0 }}
                      value={values.title}
                    />
                    <ErrorMessage
                      name='title'
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </label>
                </div>
                <div>
                  <label className={styles.label_wrapper}>
                    <span>User Name</span>
                    <Field
                      name='user_name'
                      type='text'
                      disabled
                      component={CustomInput}
                      style={{ width: '100%', padding: '6px 12px' }}
                      labelStyle={{ margin: 0 }}
                      value={values.user_name}
                      className='input-field'
                    />
                  </label>
                </div>
                <div>
                  <label className={styles.label_wrapper}>
                    <span>Company Name</span>
                    <Field
                      name='company_name'
                      type='text'
                      disabled
                      component={CustomInput}
                      style={{ width: '100%', padding: '6px 12px' }}
                      labelStyle={{ margin: 0 }}
                      value={values.company_name}
                      className='input-field'
                    />
                  </label>
                </div>
                <div>
                  <label className={styles.label_wrapper}>
                    <span>Company Dot</span>
                    <Field
                      name='company_dot'
                      type='text'
                      disabled
                      component={CustomInput}
                      style={{ width: '100%', padding: '6px 12px' }}
                      labelStyle={{ margin: 0 }}
                      value={values.company_dot}
                      className='input-field'
                    />
                  </label>
                </div>
                <div className={styles.label_wrapper}>
                  <span>Phone Number</span>
                  <Field
                    name='phone_no'
                    disabled
                    inputStyle={{ width: '100%' }}
                    labelStyle={{ marginTop: 8 }}
                    countryCodeEditable={false}
                    component={CustomPhoneInput}
                  />
                </div>

                <div className={styles.description_wrapper}>
                  <div style={{ marginBottom: 8 }}>
                    <span className={styles.label_required}>Description</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FastField
                      name='description'
                      component={CustomTextArea}
                      value={values.description}
                      validate={validateRequired}
                      styles={{ height: 92 }}
                    />
                    <ErrorMessage
                      name='description'
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <Autocomplete
                    required
                    label='Type'
                    name='type'
                    options={ticketTypes}
                    value={values.type}
                    onChange={(e, val) => setFieldValue('type', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>

                <div className='mt-4'>
                  <Autocomplete
                    required
                    label='Priority'
                    name='priority'
                    options={priorities}
                    value={values.priority}
                    onChange={(e, val) => setFieldValue('priority', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>

                <div className='mt-4 mb-4'>
                  <div style={{ marginBottom: 8 }}>
                    <span className={styles.upload_title}>Upload Screenshot</span>
                  </div>
                  <div
                    className='upload-document'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                      height: 150,
                      width: '100%',
                      margin: '0',
                    }}
                  >
                    <label htmlFor='myFile' className={styles['drop-zone-style']}>
                      <span className='upload-icon me-1'>
                        <img src={uploadBig} alt='upload' />
                      </span>
                      <br />
                      {values?.image ? (
                        <p>
                          <img src={right} alt='attachment' className='attachment-icon' />
                          <span className='text-style' style={{ color: '#38A06C' }}>
                            File Uploaded
                          </span>
                          <img
                            src={cross}
                            alt='cross'
                            className='upload-cancel-icon'
                            onClick={(e) => resetUploadedScreenshot(e, setFieldValue)}
                          />
                        </p>
                      ) : (
                        <p>
                          <span style={{ color: '#4F5AED' }}>Upload Screenshot</span> or drag and drop
                        </p>
                      )}
                    </label>
                    <ImageDropZone
                      onDrop={(img) => uploadScreenshot(img, 'image', setFieldValue)}
                      width='100%'
                      height='100%'
                      name='small'
                      deletable={false}
                      accept={['image/jpeg', 'image/png', 'image/svg+xml']}
                      customStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        border: '1px dashed #BCC2CE',
                        borderRadius: '8px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default SupportTicketModal;
