import React from 'react';
import { SChip } from './Chip.styles';

const Chip = ({ bgColor, labelColor, fontSize, fontWeight, radius, size = 'small', ...props }) => {
  return (
    <SChip
      $bgColor={bgColor}
      $labelColor={labelColor}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $radius={radius}
      size={size}
      {...props}
    />
  );
};

export default Chip;
