import React, { useState, useMemo, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { useSelector } from 'react-redux';
import { Formik, Form, FastField, Field } from 'formik';

import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { getBrokerageSetting, updateBrokerageSetting } from 'Api/CompanySettings';
import { ADDON_PLAN_ID, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import { useAuth } from 'context/auth.context';
import moment from 'moment/moment';
import { GetAdditionalServices } from 'Api/Billing';
import { useNavigate } from 'react-router-dom';
import uploadBig from 'assets/icons/documents/uploadBig.png';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import ImageDropZone from 'components/ImgageDropZone';
import SignatureCanvas from 'react-signature-canvas';
import { blockNonPositiveNumbers, getIframeSource, removeCommas } from 'utils/helpers';
import ViewPdf from 'components/ViewPdf';
import { getErrorMessage } from 'utils/error';
import Chip from 'common/Chip';
import InputLabel from 'common/InputLabel';
import { customerPayTerms } from 'Api/Customers';
import AddressFields from 'common/AddressFields';
import Autocomplete from 'common/Autocomplete';
import OnboardingService from 'services/onboardingService';
import { generateMasterPdf } from 'pages/OnboardCarrier/steps/MasterAgreement/helper';
import { generateIndemityPdf } from 'pages/OnboardCarrier/steps/IndemityAgreement/helper';
import { generatePDF } from 'pages/CarrierConfirmation/GenerateCarrierConfirmation/helpers';
import classes from 'components/TableRowEditModalTypes/CheckInOutModal/checkInOut.module.scss';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { confirmationShipment, pickupStops } from 'pages/CompanySettings/pagesComponents/Brokerage/Brokerage.data';
import { LoadingIndicator } from 'stream-chat-react';
import { validationSchema } from './validationSchema';
import styles from './Brokerage.module.css';

const editorConfiguration = {
  plugins: [...ClassicEditor.builtinPlugins],
  toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],
};

const Brokerage = ({ isConfiguringStep, onSaveAndNext }) => {
  const navigate = useNavigate();
  const { value, setAuth } = useAuth();
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState();
  const signPad = useRef({});
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addons, setAddons] = useState([]);
  const showToaster = useShowToaster();
  const showBrokerage =
    value?.user?.customer?.customer_type === 'carrier_broker' ||
    isConfiguringStep ||
    addons?.some((i) => i.addon_plan?.addon?.plan_id === ADDON_PLAN_ID.BROKERAGE && i.status === 'Active');
  const [imageData, setImageData] = useState(null);

  const getAddons = async () => {
    setLoadingPlans(true);
    try {
      const { data } = await GetAdditionalServices({ itemsPerPage: 1000 });
      setAddons(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingPlans(false);
    }
  };

  const getCompanyProfile = async () => {
    return getBrokerageSetting()
      .then((res) => {
        if (res?.data) {
          setCompanyProfile(res?.data);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAddons();
    getCompanyProfile().then();
  }, []);

  const scrollToFirstError = () => {
    setTimeout(() => {
      const firstErrorElement = document.querySelector('.brokerage-error-message');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const handleButtonClick = async (validateForm, handleSubmit) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    } else {
      handleSubmit();
      scrollToFirstError();
    }
  };

  const handleSubmit = (values) => {
    if (values.signature) {
      const formData = new FormData();
      formData.append('company_name', values.company_name);
      formData.append('dot', values.dot);
      formData.append('phone_number', values.phone_number);
      formData.append('fax_number', values.fax_number);
      formData.append('address1', values.address);
      formData.append('address2', values.address2);
      formData.append('country_id', values.country?.id);
      formData.append('state_id', values.state?.id);
      formData.append('city_id', values.city?.id);
      formData.append('zipcode', values.zipcode);
      formData.append('show_website', Number(values.show_website));
      formData.append('website', values.website);
      formData.append('receivable_email', values.receivable_email);
      formData.append('company_email', values.company_email);
      formData.append('ucr_doc_expiry_date', moment(values.ucr_doc_expiry_date).format('YYYY/MM/DD'));
      formData.append('ucr_doc', values.ucr_doc);
      formData.append('bmc_doc', values.bmc_doc);
      formData.append('authority', values.authority);
      formData.append('reference_sheet', values.reference_sheet);
      formData.append('terms_and_conditions', values.terms_and_conditions);
      formData.append('master_agreement', values.master_agreement);
      formData.append('logo', values.logo);
      formData.append('signature', values.signature);
      formData.append('signature_first_name', values.signature_first_name);
      formData.append('signature_last_name', values.signature_last_name);
      formData.append('master_agreement_title', values.master_agreement_title);
      formData.append('liability_minimum_coverage', removeCommas(values.liability_minimum_coverage));
      formData.append('cargo_insurance_minimum_coverage', removeCommas(values.cargo_insurance_minimum_coverage));
      formData.append('general_liability_minimum_coverage', removeCommas(values.general_liability_minimum_coverage));
      formData.append('offer_quick_pay', Number(values.offer_quick_pay));
      formData.append('attach_confirmation_to_shipment_doc', Number(values.attach_confirmation_to_shipment_doc));
      formData.append('quick_pay_count', values.quick_pay_count);
      formData.append('quick_pay_percent', values.quick_pay_percent);
      formData.append('quick_pay_type', 'days');
      formData.append('trailer_interchange_agreement', values.trailer_interchange_agreement);
      formData.append('use_own_trailer_interchange', Number(values.use_own_trailer_interchange));
      formData.append('default_invoice_pay_term', values?.default_invoice_pay_term?.id);
      updateBrokerageSetting(formData)
        .then(() => {
          showToaster({ type: 'success', message: `Changes have been successfully updated!` });
          setAuth({ ...value, shouldUpdateBrokerage: Date.now() });
          if (isConfiguringStep) {
            onSaveAndNext('saveAndNext');
          }
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else {
      showToaster({ type: 'warning', message: 'Save signature to continue' });
    }
  };

  const formatInitialValue = (value) => {
    if (!value) {
      return '';
    }

    const numericValue = Number(value);

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue);
  };

  const initialValue = useMemo(() => {
    const {
      logo,
      company_name,
      dot,
      scac,
      phone_number,
      fax_number,
      address1,
      address2,
      country,
      state,
      city,
      zipcode,
      website,
      show_website,
      receivable_email,
      company_email,
      ucr_doc_expiry_date,
      ucr_doc,
      bmc_doc,
      authority,
      reference_sheet,
      terms_and_conditions,
      master_agreement,
      signature,
      signature_first_name,
      signature_last_name,
      master_agreement_title,
      liability_minimum_coverage,
      cargo_insurance_minimum_coverage,
      general_liability_minimum_coverage,
      offer_quick_pay,
      attach_confirmation_to_shipment_doc,
      quick_pay_count,
      quick_pay_percent,
      quick_pay_type,
      trailer_interchange_agreement,
      use_own_trailer_interchange,
      default_invoice_pay_term,
    } = companyProfile || {};
    return {
      logo: logo || '',
      company_name: company_name || '',
      dot: dot || '',
      scac: scac || '',
      phone_number: phone_number || '',
      fax_number: fax_number || '',
      address: address1 || '',
      address2: address2 || '',
      country: country || null,
      state: state || null,
      city: city || null,
      zipcode: zipcode || '',
      website: website || '',
      show_website: show_website ? !!show_website : false,
      receivable_email: receivable_email || '',
      company_email: company_email || '',
      ucr_doc_expiry_date: ucr_doc_expiry_date ? moment(ucr_doc_expiry_date).toDate() : null,
      ucr_doc: ucr_doc || '',
      bmc_doc: bmc_doc || '',
      authority: authority || '',
      reference_sheet: reference_sheet || '',
      terms_and_conditions: terms_and_conditions || '',
      master_agreement: master_agreement || '',
      signature: signature || '',
      signature_last_name: signature_last_name || '',
      signature_first_name: signature_first_name || '',
      master_agreement_title: master_agreement_title || '',
      liability_minimum_coverage: formatInitialValue(liability_minimum_coverage) || '',
      cargo_insurance_minimum_coverage: formatInitialValue(cargo_insurance_minimum_coverage) || '',
      general_liability_minimum_coverage: formatInitialValue(general_liability_minimum_coverage) || '',
      use_own_trailer_interchange: !!use_own_trailer_interchange,
      offer_quick_pay: !!offer_quick_pay || '',
      attach_confirmation_to_shipment_doc: !!attach_confirmation_to_shipment_doc,
      quick_pay_count: quick_pay_count || '',
      quick_pay_percent: quick_pay_percent || '',
      quick_pay_type: quick_pay_type || '',
      trailer_interchange_agreement: trailer_interchange_agreement || '',
      default_invoice_pay_term: default_invoice_pay_term || null,
    };
  }, [companyProfile, invoicePayTermsOptions]);

  if (!loadingPlans && !showBrokerage) {
    return (
      <div className={styles.addon_restriction}>
        <Typography variant='s1' style={{ color: palette.gray700 }}>
          License required for Brokerage, please{' '}
          <Typography
            variant='s1'
            style={{ color: palette.indigo500 }}
            onClick={() => navigate(`/billing?openAddon=true&addonId=26&planId=62`)}
          >
            purchase here
          </Typography>
        </Typography>
      </div>
    );
  }

  if (loadingPlans) {
    return null;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{ ...initialValue }}
      validationSchema={validationSchema}
    >
      {(props) => {
        return (
          <CompanyProfileForm
            {...props}
            handleButtonClick={handleButtonClick}
            invoicePayTermsOptions={invoicePayTermsOptions}
            setInvoicePayTermsOptions={setInvoicePayTermsOptions}
            companyProfile={companyProfile}
            isConfiguringStep={isConfiguringStep}
            onSaveAndNext={onSaveAndNext}
            signPad={signPad}
            imageData={imageData}
            setImageData={setImageData}
            loading={loading}
          />
        );
      }}
    </Formik>
  );
};

function CompanyProfileForm({
  handleSubmit,
  handleBlur,
  validateForm,
  handleButtonClick,
  values,
  setFieldValue,
  setValues,
  companyProfile,
  isConfiguringStep,
  errors,
  signPad,
  touched,
  setImageData,
  invoicePayTermsOptions,
  setInvoicePayTermsOptions,
  loading,
}) {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [openViewPDF, setOpenViewPDF] = useState(false);
  const addressRef = useRef(null);
  const [file, setFile] = useState(null);
  const [barcodeImage, setBarcodeImage] = useState(null);
  const [showFile, setShowFile] = useState({
    blob: '',
  });
  const [cropModalShow, setCropModalShow] = useState(false);
  const [cropper, setCropper] = useState();
  const [addressInfo, setAddressInfo] = useState(null);
  const [dropzoneIndex, setDropzoneIndex] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingIndemityPdf, setLoadingIndemityPdf] = useState(false);
  const [loadingTerms, setLoadingTerms] = useState(false);

  const shippersAndConsignees = confirmationShipment.shipment_stops
    ?.filter((stop) => Number(stop.stop_point_type) === 1 || Number(stop.stop_point_type) === 2)
    .map((el) => {
      if (Number(el.stop_point_type) === 1) {
        return { ...el, type: 'Shipper' };
      }
      return { ...el, type: 'Consignee' };
    });

  const getBarcode = (id, displayValue = true) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, id, { displayValue });
    return canvas.toDataURL('image/jpeg');
  };

  const handleChange = (event, value) => {
    if (event?.target) {
      const { name, value } = event.target;
      setFieldValue(name, value);
    } else {
      setFieldValue(event, value);
    }
  };

  useEffect(() => {
    if (companyProfile?.logo) {
      setShowFile({
        blob: `${companyProfile.logo}?cache=${Date.now().toString()}`,
      });
    }

    if (signPad?.current && companyProfile?.signature) {
      fetch(companyProfile.signature)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (signPad.current) {
              signPad.current.fromDataURL(reader.result);
              // setFieldValue('signature', reader.result);
            }
          };
          reader.readAsDataURL(blob);
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [companyProfile]);

  useEffect(() => {
    customerPayTerms().then((res) => {
      if (res && res?.data) {
        setInvoicePayTermsOptions(res.data);
      }
    });

    const image = getBarcode(1234567890);
    setBarcodeImage(image);
  }, []);

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setShowFile({ blob: reader.result });
    };
    reader.readAsDataURL(file);
  };
  const uploadDocument = async (file) => {
    readFile(file[0]);
    setFile(file[0]);
    setValues({ ...values, logo: file[0] });
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadDocument([blob]);
        setCropModalShow(false);
      });
    }
  };

  const onImageUpload = (file) => {
    if (file?.[0]) {
      setCropModalShow(true);
      readFile(file[0]);
    }
  };

  const onCancel = () => {
    setFile(null);
    setCropModalShow(false);
    if (companyProfile?.logo) {
      setShowFile({
        blob: `${companyProfile.logo}?cache=${Date.now().toString()}`,
      });
    } else {
      setShowFile({ blob: '' });
    }
    setValues({ ...values, logo: companyProfile?.logo });
    setDropzoneIndex(Date.now());
  };

  const onClear = () => {
    setFieldValue('signature', '');
    signPad?.current.clear();
  };

  const save = () => {
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      setImageData(file);
      setFieldValue('signature', file);
      showToaster({ type: 'success', message: 'Signature has been saved' });
    }, 'image/png');
  };

  const companyProfileStyle = useMemo(() => {
    return {
      company_name: {
        width: 320,
        borderRadius: 6,
        height: 32,
        margin: '0 !important',
        padding: '0 12px',
      },
      company_website: {
        width: 320,
        borderRadius: 6,
        height: 32,
        margin: '0 !important',
        padding: '0 12px',
      },
      dot_number: {
        width: 320,
        borderRadius: 6,
        height: 32,
        padding: '0 12px',
      },
      signature_name: {
        width: 240,
        borderRadius: 6,
        height: 32,
        padding: '0 12px',
      },
      title: {
        width: '100%',
        borderRadius: 6,
        height: 32,
        padding: '0 12px',
      },
      fax: {
        margin: '10px 0 0 0',
        color: use(palette.gray700, palette.gray200),
      },
      address: {
        width: 400,
        borderRadius: 6,
        height: 32,
        padding: '0 12px',
      },
      zip: {
        width: 194,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      globalLabel: {
        width: 400,
        margin: '10px 0 0 0',
        color: use(palette.gray700, palette.gray200),
      },
    };
  }, [palette, use]);

  const onDotChange = async (val) => {
    const value = (val || '').trim();
    setFieldValue('dot', value);
    if (value.length !== 7) {
      return;
    }
    try {
      const { data } = await OnboardingService.checkDot(value);

      if (!data?.error?.message && !data?.data?.message) {
        const { phone, legalName, phyStreet, phyCountry, phyState, phyCity, phyZipcode } =
          data?.data?.content?.carrier || {};

        if (phyCountry || phyState || phyCity) {
          setAddressInfo({ country: phyCountry, state: phyState, city: phyCity });
        }

        setValues((prevState) => ({
          ...prevState,
          company_name: legalName || values.company_name,
          phone_number: phone ? `+1${phone.replaceAll(/[- +()]/g, '')}` : value.phone,
          address: phyStreet || '',
          zipcode: phyZipcode || values.zipcode,
        }));
      }
    } catch (e) {
      // do nothing
    }
  };

  const onNumberInputChange = (name, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');

    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue / 100);

    setFieldValue(name, formattedValue);
  };

  return (
    <>
      <Form>
        <div className={`${styles.companyProfile_wrapper} mb-5`}>
          <div className={styles.companyProfile_header}>
            <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
              Brokerage
            </Typography>
            <CustomButton
              onClick={() => handleButtonClick(validateForm, handleSubmit)}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ margin: 0, height: 24 }}
            />
          </div>

          <div className={styles.logo_wrapper}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), width: 400 }}>
              Logo
            </Typography>
            <div className={styles['upload-input']}>
              <div className={`${styles['upload-document']} ${file !== null ? styles['no-border'] : ''}`}>
                <label htmlFor='logo'>
                  <div className={styles.backDropImg}>
                    {!companyProfile?.logo && file === null && (
                      <span className={styles['upload-icon']}>
                        <img src={uploadBig} alt='upload' style={{ width: '25px', height: '25px' }} />
                      </span>
                    )}
                    {showFile?.blob && (
                      <img
                        src={showFile.blob}
                        alt='something'
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          borderRadius: '8px',
                        }}
                      />
                    )}
                    {file === null && !companyProfile?.logo && (
                      <div>
                        <p className={styles['upload-document-drag']} style={{ fontSize: '10px' }}>
                          Upload &nbsp; <span> or drag and drop</span>
                        </p>
                      </div>
                    )}
                  </div>
                </label>
                {file !== null && (
                  <div onClick={(e) => e.stopPropagation()} className={styles['uploaded-icon-box']}>
                    <p className={styles['uploaded-icon']} style={{ fontSize: '10px' }}>
                      <img src={right} alt='icon' />
                      Uploaded
                    </p>
                    <p
                      className={styles['uploaded-icon']}
                      style={{
                        fontSize: '10px',
                        zIndex: '10',
                        marginLeft: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCancel();
                      }}
                    >
                      <img src={cross} alt='icon' />
                      Cancel
                    </p>
                  </div>
                )}
                <ImageDropZone
                  onDrop={onImageUpload}
                  width='100%'
                  height='100%'
                  id='logo'
                  deletable={false}
                  maxSize={5242880}
                  accept={['image/jpeg', 'image/png', 'image/svg+xml', 'image/bmp']}
                  key={dropzoneIndex}
                />
                <ErrorMessage className='brokerage-error-message' error={touched.logo && errors.logo} />
              </div>
            </div>
            {companyProfile?.logo && (
              <label htmlFor='logo' className={styles.replace_wrapper}>
                <Typography variant='s2' style={{ color: use(palette.indigo500) }}>
                  Replace Logo
                </Typography>
              </label>
            )}
          </div>

          <div className={styles.basic_wrapper}>
            <div style={{ display: 'flex', width: 400 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                DOT Number
              </Typography>
            </div>
            <div>
              <FastField
                name='dot'
                type='text'
                disabled={companyProfile?.dot}
                onChange={(e) => onDotChange(e.target.value)}
                component={CustomInput}
                style={companyProfileStyle.dot_number}
              />
              <ErrorMessage className='brokerage-error-message' error={touched.dot && errors.dot} />
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <div style={{ display: 'flex', width: 400 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                Company Name
              </Typography>
            </div>
            <div>
              <FastField
                name='company_name'
                type='text'
                component={CustomInput}
                style={companyProfileStyle.company_name}
              />
              <ErrorMessage className='brokerage-error-message' error={touched.company_name && errors.company_name} />
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Telephone Number
            </Typography>
            <div>
              <Field
                name='phone_number'
                inputStyle={{ width: 320 }}
                labelStyle={{ marginTop: 10 }}
                countryCodeEditable={false}
                component={CustomPhoneInput}
              />
              <ErrorMessage className='brokerage-error-message' error={touched.phone_number && errors.phone_number} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <div style={{ width: 400, display: 'flex', alignItems: 'baseline', gap: '5px' }}>
              <Typography variant='s2' style={companyProfileStyle.fax}>
                Fax Number
              </Typography>
              <Chip
                size='small'
                label='Optional'
                labelColor={palette.gray600}
                bgColor='#F0F2F7'
                fontSize='11px'
                fontWeight={500}
              />
            </div>
            <div>
              <Field
                name='fax_number'
                inputStyle={{ width: 320 }}
                labelStyle={{ marginTop: 10 }}
                countryCodeEditable={false}
                component={CustomPhoneInput}
              />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Company Email
            </Typography>
            <div>
              <FastField
                name='company_email'
                type='text'
                component={CustomInput}
                onChange={(e) => {
                  setFieldValue('company_email', e.target.value);
                }}
                style={{
                  margin: '0 !important',
                  width: 320,
                  height: 32,
                  padding: '6px 12px',
                  borderRadius: 6,
                }}
              />
              <ErrorMessage className='brokerage-error-message' error={touched.company_email && errors.company_email} />
            </div>
          </div>
          <div className={styles.address_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Address
            </Typography>
            <div style={{ width: '400px' }}>
              <AddressFields
                values={values}
                handleChange={handleChange}
                hideLabel
                defaultAddress={addressInfo}
                ref={addressRef}
                limitCountries
              />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <div style={companyProfileStyle.globalLabel}>
              <Field name='show_website'>
                {({ field }) => {
                  return (
                    <label className={styles.actions}>
                      <CustomCheckbox
                        name='show_website'
                        field={field}
                        type='switch'
                        withinForm
                        smail={false}
                        checked={!!field.value}
                      />
                      <Typography
                        variant='s2'
                        style={{
                          color: use(palette.gray700, palette.gray200),
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Show website on documents
                      </Typography>
                    </label>
                  );
                }}
              </Field>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <div>
                <Field
                  name='website'
                  type='text'
                  disabled={!values?.show_website}
                  component={CustomInput}
                  placeholder='www.website.com'
                  leftIcon='https://'
                  leftIconStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    color: '#687182',
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                    justifyContent: 'center',
                    backgroundColor: '#F7F9FC',
                    maxWidth: '74px',
                    width: '100%',
                    height: 32,
                    left: 0,
                  }}
                  style={{
                    margin: '0 !important',
                    width: 440,
                    height: 32,
                    padding: '6px 8px 6px 86px',
                    borderRadius: 6,
                  }}
                />
                <ErrorMessage className='brokerage-error-message' error={touched.website && errors.website} />
              </div>
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Accounts Receivable Email
            </Typography>
            <div>
              <FastField
                name='receivable_email'
                type='text'
                component={CustomInput}
                onChange={(e) => {
                  setFieldValue('receivable_email', e.target.value);
                }}
                style={{
                  margin: '0 !important',
                  width: 320,
                  height: 32,
                  padding: '6px 12px',
                  borderRadius: 6,
                }}
              />
              <ErrorMessage
                className='brokerage-error-message'
                error={touched.receivable_email && errors.receivable_email}
              />
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Default Invoice Payment Terms
            </Typography>
            <div>
              <Autocomplete
                getOptionLabel={(option) => `${option ? getPaymentTermTitle(option) : ''}`}
                options={invoicePayTermsOptions || []}
                value={values?.default_invoice_pay_term}
                onChange={(e, val) => handleChange('default_invoice_pay_term', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                style={{ width: '320px', marginTop: 5 }}
                error={touched?.default_invoice_pay_term && errors.default_invoice_pay_term}
              />
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Carrier Auto Liability Minimum Coverage
            </Typography>
            <div>
              <FastField
                name='liability_minimum_coverage'
                onKeyDown={blockNonPositiveNumbers}
                component={CustomInput}
                onChange={(e) => onNumberInputChange('liability_minimum_coverage', e.target.value)}
                leftIcon={<DollarIcon height={32} />}
                style={{
                  margin: '0 !important',
                  width: 320,
                  height: 32,
                  padding: '6px 12px',
                  borderRadius: 6,
                  paddingLeft: 25,
                }}
              />
              <ErrorMessage
                className='brokerage-error-message'
                error={touched.liability_minimum_coverage && errors.liability_minimum_coverage}
              />
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Carrier Cargo Insurance Minimum Coverage
            </Typography>
            <div>
              <FastField
                name='cargo_insurance_minimum_coverage'
                onKeyDown={blockNonPositiveNumbers}
                component={CustomInput}
                onChange={(e) => onNumberInputChange('cargo_insurance_minimum_coverage', e.target.value)}
                leftIcon={<DollarIcon height={32} />}
                style={{
                  margin: '0 !important',
                  width: 320,
                  height: 32,
                  padding: '6px 12px',
                  borderRadius: 6,
                  paddingLeft: 25,
                }}
              />
              <ErrorMessage
                className='brokerage-error-message'
                error={touched.cargo_insurance_minimum_coverage && errors.cargo_insurance_minimum_coverage}
              />
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              General Liability Minimum Coverage
            </Typography>
            <div>
              <FastField
                name='general_liability_minimum_coverage'
                onKeyDown={blockNonPositiveNumbers}
                component={CustomInput}
                onChange={(e) => onNumberInputChange('general_liability_minimum_coverage', e.target.value)}
                leftIcon={<DollarIcon height={32} />}
                style={{
                  margin: '0 !important',
                  width: 320,
                  height: 32,
                  padding: '6px 12px',
                  borderRadius: 6,
                  paddingLeft: 25,
                }}
              />
              <ErrorMessage
                className='brokerage-error-message'
                error={touched.general_liability_minimum_coverage && errors.general_liability_minimum_coverage}
              />
            </div>
          </div>

          <div className={styles.editor_wrapper}>
            <div style={companyProfileStyle.globalLabel}>
              <Field name='use_own_trailer_interchange'>
                {({ field }) => {
                  return (
                    <label className={styles.actions}>
                      <CustomCheckbox
                        name='use_own_trailer_interchange'
                        field={field}
                        type='switch'
                        withinForm
                        smail={false}
                        checked={!!field.value}
                      />

                      <Typography
                        variant='s2'
                        style={{
                          color: use(palette.gray700, palette.gray200),
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Custom Trailer Interchange Agreement
                      </Typography>
                    </label>
                  );
                }}
              </Field>
            </div>
            <div style={{ margin: '10px 0 0 0', display: 'flex', gap: 10 }}>
              {!loading && (
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    placeholder='Discribe in detail'
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue('trailer_interchange_agreement', data);
                    }}
                    config={editorConfiguration}
                    data={values.trailer_interchange_agreement || ''}
                  />
                  <ErrorMessage
                    className='brokerage-error-message'
                    error={touched.trailer_interchange_agreement && errors.trailer_interchange_agreement}
                  />
                </div>
              )}
              {values.company_name && values.logo && values.use_own_trailer_interchange && values.address && (
                <span
                  className={styles.viewButton}
                  onClick={async () => {
                    try {
                      setLoadingIndemityPdf(true);
                      const { url } = await generateIndemityPdf({
                        company: {
                          ...values,
                          use_own_trailer_interchange: values.use_own_trailer_interchange,
                          logo: values.logo,
                          date_time_format_settings: companyProfile?.date_time_format_settings,
                        },
                        carrier: {
                          name: 'CARRIER',
                          address1: '123 Test St.',
                          city: { name: 'Detroit' },
                          state: { short_name: 'MI' },
                          zipcode: '48248',
                          email: 'carrier@mail.com',
                        },
                        values: { phone: '+15555551234', printedName: 'CARRIER', title: 'CARRIER' },
                      });
                      setOpenViewPDF(url);
                      setLoadingIndemityPdf(false);
                    } catch (err) {
                      setLoadingIndemityPdf(false);
                    }
                  }}
                >
                  View Sample
                  {loadingIndemityPdf && <LoadingIndicator />}
                </span>
              )}
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <div style={companyProfileStyle.globalLabel}>
              <Field name='offer_quick_pay'>
                {({ field }) => {
                  return (
                    <label className={styles.actions}>
                      <CustomCheckbox
                        name='offer_quick_pay'
                        field={field}
                        type='switch'
                        withinForm
                        smail={false}
                        checked={!!field.value}
                      />
                      <Typography
                        variant='s2'
                        style={{
                          color: use(palette.gray700, palette.gray200),
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Offer Quick Pay to Carriers
                      </Typography>
                    </label>
                  );
                }}
              </Field>
            </div>
            <div className='d-flex flex-column gap-2'>
              <div className='d-flex align-items-center gap-2' style={{ marginTop: 10 }}>
                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Payment will be made within
                </span>
                <FastField
                  name='quick_pay_count'
                  inputType='number'
                  component={CustomInput}
                  disabled={!values?.offer_quick_pay}
                  onChange={(e) => {
                    setFieldValue('quick_pay_count', e.target.value);
                  }}
                  labelStyle={{ margin: 0 }}
                  style={{
                    margin: 0,
                    width: 100,
                    height: 32,
                    padding: '6px 12px',
                    borderRadius: 6,
                    paddingLeft: 25,
                  }}
                />
                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Days
                </span>
              </div>
              <div className='d-flex align-items-center gap-2' style={{ marginTop: 10 }}>
                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Deduct
                </span>
                <FastField
                  name='quick_pay_percent'
                  inputType='number'
                  onKeyDown={blockNonPositiveNumbers}
                  component={CustomInput}
                  onChange={(e) => {
                    setFieldValue('quick_pay_percent', e.target.value);
                  }}
                  disabled={!values?.offer_quick_pay}
                  labelStyle={{ margin: 0 }}
                  style={{
                    margin: 0,
                    width: 100,
                    height: 32,
                    padding: '6px 12px',
                    borderRadius: 6,
                    paddingLeft: 25,
                  }}
                />
                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  % from payment
                </span>
              </div>
              {touched.quick_pay_count && errors.quick_pay_count && (
                <ErrorMessage className='brokerage-error-message' error={errors.quick_pay_count} />
              )}
              {!errors.quick_pay_count && touched.quick_pay_type && errors.quick_pay_type && (
                <ErrorMessage className='brokerage-error-message' error={errors.quick_pay_type} />
              )}
              {!errors.quick_pay_count &&
                !errors.quick_pay_type &&
                touched.quick_pay_percent &&
                errors.quick_pay_percent && (
                  <ErrorMessage className='brokerage-error-message' error={errors.quick_pay_percent} />
                )}
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Upload UCR (Expiry Date)
            </Typography>
            <div style={{ margin: '10px 0 0 0' }}>
              <div className='d-flex flex-column'>
                <div className='d-flex gap-3 align-items-center'>
                  <DatePicker
                    name='ucr_doc_expiry_date'
                    value={values.ucr_doc_expiry_date}
                    onChange={(val) => setFieldValue('ucr_doc_expiry_date', val)}
                    onBlur={handleBlur}
                    error={touched.ucr_doc_expiry_date && errors.ucr_doc_expiry_date}
                    disablePast
                  />
                  <div className='w-100'>
                    <Uploader
                      type={3}
                      document={values.ucr_doc}
                      onDrop={(files) => setFieldValue('ucr_doc', files[0])}
                      onRemove={() => setFieldValue('ucr_doc', null)}
                      accept={['application/pdf']}
                    />
                  </div>
                  {values.ucr_doc && (
                    <span
                      className={styles.viewButton}
                      onClick={async () => {
                        const url = await getIframeSource(values.ucr_doc);
                        setOpenViewPDF(url);
                      }}
                    >
                      View
                    </span>
                  )}
                </div>
                {touched.ucr_doc && errors.ucr_doc ? (
                  <ErrorMessage className='brokerage-error-message' error={errors.ucr_doc} />
                ) : null}
              </div>
            </div>
          </div>

          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Upload BMC-84/85
            </Typography>
            <div className='d-flex flex-column'>
              <div className='d-flex gap-3 align-items-baseline'>
                <div style={{ margin: '10px 0 0 0' }}>
                  <Uploader
                    type={3}
                    name='bmc_doc'
                    uploaderLabel='Upload BMC'
                    document={values.bmc_doc}
                    onDrop={(files) => setFieldValue('bmc_doc', files[0])}
                    onRemove={() => setFieldValue('bmc_doc', null)}
                    accept={['application/pdf']}
                  />
                </div>
                {values.bmc_doc && (
                  <span
                    className={styles.viewButton}
                    onClick={async () => {
                      const url = await getIframeSource(values.bmc_doc);
                      setOpenViewPDF(url);
                    }}
                  >
                    View
                  </span>
                )}
              </div>
              <ErrorMessage className='brokerage-error-message' error={touched.bmc_doc && errors.bmc_doc} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Upload Authority
            </Typography>
            <div className='d-flex flex-column'>
              <div className='d-flex gap-3 align-items-baseline'>
                <div style={{ margin: '10px 0 0 0' }}>
                  <Uploader
                    type={3}
                    name='authority'
                    uploaderLabel='Upload Authority'
                    document={values.authority}
                    onDrop={(files) => setFieldValue('authority', files[0])}
                    onRemove={() => setFieldValue('authority', null)}
                    accept={['application/pdf']}
                  />
                </div>
                {values.authority && (
                  <span
                    className={styles.viewButton}
                    onClick={async () => {
                      const url = await getIframeSource(values.authority);
                      setOpenViewPDF(url);
                    }}
                  >
                    View
                  </span>
                )}
              </div>
              <ErrorMessage className='brokerage-error-message' error={touched.authority && errors.authority} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Upload Reference Sheet
            </Typography>
            <div className='d-flex flex-column'>
              <div className='d-flex gap-3 align-items-baseline'>
                <div style={{ margin: '10px 0 0 0' }}>
                  <Uploader
                    type={3}
                    name='reference_sheet'
                    uploaderLabel='Upload Reference Sheet'
                    document={values.reference_sheet}
                    onDrop={(files) => setFieldValue('reference_sheet', files[0])}
                    onRemove={() => setFieldValue('reference_sheet', null)}
                    accept={['application/pdf']}
                  />
                </div>
                {values.reference_sheet && (
                  <span
                    className={styles.viewButton}
                    onClick={async () => {
                      const url = await getIframeSource(values.reference_sheet);
                      setOpenViewPDF(url);
                    }}
                  >
                    View
                  </span>
                )}
              </div>
              <ErrorMessage
                className='brokerage-error-message'
                error={touched.reference_sheet && errors.reference_sheet}
              />
            </div>
          </div>
          <div className={styles.basic_checkbox_wrapper}>
            <Field name='attach_confirmation_to_shipment_doc'>
              {({ field }) => {
                return (
                  <div className='d-flex align-items-center gap-2'>
                    <CustomCheckbox
                      name='attach_confirmation_to_shipment_doc'
                      field={field}
                      type='switch'
                      withinForm
                      smail={false}
                      checked={!!field.value}
                    />

                    <Typography
                      variant='s2'
                      style={{
                        color: use(palette.gray700, palette.gray200),
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Attach confirmation to shipment documents upon carrier signature
                    </Typography>
                  </div>
                );
              }}
            </Field>
          </div>
          <div className={styles.editor_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Confirmation Terms and Conditions
            </Typography>
            <div style={{ margin: '10px 0 0 0', display: 'flex', gap: 10 }}>
              {!loading && (
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue('terms_and_conditions', data);
                  }}
                  data={values.terms_and_conditions || ''}
                />
              )}
              {values.company_name && values.address && values.logo && (
                <span
                  className={styles.viewButton}
                  onClick={async () => {
                    try {
                      const localData = JSON.parse(localStorage.getItem('user'));
                      const timeZone = JSON.parse(localData?.customer?.timezone);
                      const dateFormatted = timeZone.zone_name.replace(/ - /g, '/');
                      setLoadingTerms(true);
                      const { url } = await generatePDF({
                        shipment: confirmationShipment,
                        company: {
                          ...values,
                          address1: values.address,
                          customer_timezone: dateFormatted,
                          logo: values.logo,
                        },
                        shippersAndConsignees,
                        currency,
                        barcodeImage,
                        pickupStops,
                        total_weight: 1000,
                        total_qty: 10,
                        values: {
                          driver_phone_number: '12342234232',
                          driver_name: 'Samuel Johnson',
                          signature_first_name: 'Bob Doe',
                          signature_last_name: '',
                          signature_date: '11/30/2023',
                          truck_id: '000',
                          trailer_id: '1111',
                          reference_id: '251-S3-113020232311',
                        },
                      });
                      setOpenViewPDF(url);
                      setLoadingTerms(false);
                    } catch (err) {
                      setLoadingTerms(false);
                    }
                  }}
                >
                  View Sample
                  {loadingTerms && <LoadingIndicator />}
                </span>
              )}
            </div>
          </div>
          <div className={styles.editor_wrapper}>
            <div style={{ minWidth: 400 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                Carrier Master Agreement
              </Typography>
            </div>
            <div className='d-flex flex-column gap-3'>
              <div style={{ margin: '10px 0 0 0', display: 'flex', gap: 10 }}>
                {!loading && (
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      placeholder='Discribe in detail'
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('master_agreement', data);
                      }}
                      config={editorConfiguration}
                      data={values.master_agreement || ''}
                    />
                    <ErrorMessage
                      className='brokerage-error-message'
                      error={touched.master_agreement && errors.master_agreement}
                    />
                  </div>
                )}
                {values.master_agreement &&
                  values.company_name &&
                  values.signature_last_name &&
                  values.signature_first_name &&
                  values.signature &&
                  values.logo &&
                  values.address && (
                    <span
                      className={styles.viewButton}
                      onClick={async () => {
                        try {
                          setLoadingDocument(true);
                          const { url } = await generateMasterPdf({
                            company: {
                              ...values,
                              logo: values.logo,
                              date_time_format_settings: companyProfile?.date_time_format_settings,
                            },
                            carrier: {
                              name: 'CARRIER',
                              address1: '123 Test St.',
                              city: { name: 'Detroit' },
                              state: { short_name: 'MI' },
                              zipcode: '48248',
                              email: 'carrier@mail.com',
                            },
                            values: { phone: '+15555551234', printedName: 'CARRIER', title: 'CARRIER' },
                          });
                          setOpenViewPDF(url);
                          setLoadingDocument(false);
                        } catch (err) {
                          setLoadingDocument(false);
                        }
                      }}
                    >
                      View Sample
                      {loadingDocument && <LoadingIndicator />}
                    </span>
                  )}
              </div>
              <div className={styles.editor_wrapper}>
                <div style={{ margin: '10px 0 0 0' }}>
                  <div className='d-flex justify-content-between gap-4 mb-2'>
                    <div>
                      <InputLabel required>First Name</InputLabel>
                      <FastField
                        name='signature_first_name'
                        type='text'
                        placeholder='First Name'
                        component={CustomInput}
                        style={companyProfileStyle.signature_name}
                      />
                      <ErrorMessage
                        className='brokerage-error-message'
                        error={touched.signature_first_name && errors.signature_first_name}
                      />
                    </div>
                    <div>
                      <InputLabel required>Last Name</InputLabel>
                      <FastField
                        name='signature_last_name'
                        type='text'
                        placeholder='Last Name'
                        component={CustomInput}
                        style={companyProfileStyle.signature_name}
                      />
                      <ErrorMessage
                        className='brokerage-error-message'
                        error={touched.signature_last_name && errors.signature_last_name}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: '10px' }}>
                    <InputLabel required>Title</InputLabel>
                    <FastField
                      name='master_agreement_title'
                      type='text'
                      placeholder='Title'
                      component={CustomInput}
                      style={companyProfileStyle.title}
                    />
                    <ErrorMessage
                      className='brokerage-error-message'
                      error={touched.master_agreement_title && errors.master_agreement_title}
                    />
                  </div>

                  <div className='d-flex justify-content-between'>
                    <InputLabel required>Signature</InputLabel>
                    <div>
                      <span onClick={onClear} className={styles.clear} style={{ marginRight: 10 }}>
                        Clear
                      </span>
                      <span onClick={save} className={classes.clear}>
                        Save
                      </span>
                    </div>
                  </div>
                  <div>
                    <InfoIcon fill='#AA5B00FF' />
                    <Typography
                      variant='s2'
                      style={{
                        color: '#AA5B00FF',
                        marginLeft: 6,
                      }}
                    >
                      This signature will appear on the Carrier Master Agreement for the Broker Acceptance.
                    </Typography>
                  </div>
                  <div className={styles.signature_area}>
                    <SignatureCanvas canvasProps={{ className: styles.sigCanvas }} penColor='black' ref={signPad} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      {!!cropModalShow && (
        <ImageCropModal
          backdrop='static'
          show={cropModalShow}
          onHide={onCancel}
          image={showFile}
          getCropData={getCropData}
          setCropper={setCropper}
        />
      )}
      {openViewPDF && (
        <ViewPdf title='' open={openViewPDF} pdfUrl={openViewPDF} onClose={() => setOpenViewPDF(false)} />
      )}
    </>
  );
}

export default Brokerage;
