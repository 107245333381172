import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import AddressFields from 'common/AddressFields';
import ActionButtons from 'common/ActionButtons';
import { Typography } from 'components/Typography';
import { DatePicker, TimePicker } from 'common/Pickers';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ShipmentCommodity from 'componentsV2/Commodity/ShipmentCommodity';
import Input, { InputWithIcon, InputWithText, Textarea } from 'common/Input';
import StopPointAutocomplete from 'componentsV2/StopPoint/StopPointAutocomplete';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, getAlphabet, palette, VALUE_TYPES } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars, transformYupErrorsIntoObject } from 'utils/helpers';
import {
  calculateStopsDistance,
  createQuoteStop,
  deleteQuoteStop,
  updateQuoteCargo,
  updateQuoteStop,
} from 'Api/Shipment';
import { getMeasurementTypes, getQuantityTypes, getWeightTypes } from 'Api/Planner';
import { getStopName } from 'componentsV2/Shipment/SplitShipment/SplitShipment.data';
import {
  createCargoConverter,
  getCargoTotals,
  getInitialValue,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
  SCHEDULED_DATE_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';

import InfoItem from '../InfoItem';
import ShipmentStopType from './ShipmentStopType';
import { stopValuesConverter } from '../../converters';
import { validationSchema } from '../../validationSchema';
import { getDeliveryCargos } from '../../CreateQuote.data';
import { SMergedInputs } from '../../CreateQuote.styles';

const ShipmentStop = ({
  form,
  stop,
  legIndex,
  stopIndex,
  stopPoints,
  commodity,
  equipmentTypes,
  getStopPoints,
  quote,
}) => {
  const showToaster = useShowToaster();
  const { formatDate, formatTime } = useDateFormat();
  const { weightUnit, qtyUnit } = useSelector((state) => state.root);
  const [openAddCommodity, setOpenAddCommodity] = useState(false);
  const [cargoSaved, setCargoSaved] = useState(false);
  const [weightTypes, setWeightTypes] = useState([]);
  const [, setMeasurementTypes] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);
  const [cargosToView, setCargosToView] = useState(null);
  const [updateStopLoading, setUpdateStopLoading] = useState(false);
  const [deleteStopLoading, setDeleteStopLoading] = useState(false);
  const [loadingDistance, setLoadingDistance] = useState(false);
  const controller = useRef(new AbortController());
  const editMode = !!quote;

  const { values, handleChange, touchedErrors, setErrors, setTouched } = form;

  const prefix = `legs[${legIndex}].stops[${stopIndex}]`;
  const errors = touchedErrors.legs?.[legIndex]?.stops?.[stopIndex];

  const equipmentActions =
    stop.stop_point_type === 1
      ? stop.equipment_type?.vehicle_type_id === 2 || stop.equipment_type?.id === 1
        ? EQUIPMENT_ACTION
        : [EQUIPMENT_ACTION[0]]
      : stop.stop_point_type === 2
      ? EQUIPMENT_ACTION_DELIVERY
      : EQUIPMENT_ACTION_WAYPOINT;
  const cargos = stop.stop_point_type === 2 ? getDeliveryCargos(values.legs[legIndex].stops, stop) : [];
  const totalValues = stop.stop_point_type !== 3 ? getCargoTotals(stop.cargos || []) : null;

  const handleAddressChange = (event, value) => {
    const addressFieldsMapper = {
      country: `${prefix}.country`,
      state: `${prefix}.state`,
      city: `${prefix}.city`,
      zipcode: `${prefix}.zipcode`,
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  const onCargoFieldChange = (field, value) => {
    handleChange(`${prefix}.cargos[0].${field}`, value);
  };

  const onEquipmentTypeChange = (value) => {
    handleChange(`${prefix}`, { ...stop, equipment_type: value, equipment_action: null });
  };

  const getAdditionalData = async () => {
    try {
      const [{ data: measurement }, { data: quantity }, { data: weight }] = await Promise.all([
        getMeasurementTypes(),
        getQuantityTypes(),
        getWeightTypes(),
      ]);
      setWeightTypes(weight || []);
      setMeasurementTypes(measurement || []);
      setQuantityTypes(quantity || []);
      if (stop.stop_point_type === 1 && stop.cargos?.[0]?.isNew) {
        onCargoFieldChange('expected_quantity_type', quantity?.find((el) => el.name.toLowerCase() === qtyUnit) || null);
        onCargoFieldChange('expected_weight_type', weight?.find((el) => el.unit.toLowerCase() === weightUnit) || null);
        onCargoFieldChange('expected_dimension_unit', measurement?.[1] || null);
      }

      if (stop.stop_point_type === 2 && stop.cargos?.[0]?.isNew) {
        onCargoFieldChange('quantity_type', quantity?.find((el) => el.name.toLowerCase() === qtyUnit) || null);
        onCargoFieldChange('weight_type', weight?.find((el) => el.unit.toLowerCase() === weightUnit) || null);
        onCargoFieldChange('dimension_unit', measurement?.[1] || null);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onSaveStop = async (stop) => {
    try {
      if (updateStopLoading || deleteStopLoading) {
        return;
      }

      await validationSchema.validateAt(`legs[${legIndex}].stops[${stopIndex}]`, values, {
        strict: true,
        abortEarly: false,
      });

      setUpdateStopLoading(true);

      let newItem;

      if (stop.isNew) {
        const body = { quote_id: quote.id, ...stopValuesConverter(stop, legIndex, stopIndex) };

        const { data } = await createQuoteStop(body);
        newItem = data;
      } else {
        const body = stopValuesConverter(stop, legIndex, stopIndex);

        await updateQuoteStop(stop.id, body);
        if (Number(stop.stop_point_type) === 1 && stop.cargos?.length === 1 && stop.cargos?.[0]?.commodity) {
          const body = createCargoConverter(stop.cargos[0]);
          updateQuoteCargo(stop.cargos[0].id, body);
        }
      }

      showToaster({
        type: 'success',
        message: `Stop has been ${stop.isNew ? 'added' : 'updated'} successfully!`,
      });

      handleChange(`${prefix}`, { ...stop, id: newItem?.id || stop.id, isEdit: false, isNew: false });
    } catch (e) {
      const { validationErrors, validationTouched } = transformYupErrorsIntoObject(e);

      if (Object.keys(validationErrors)?.length) {
        setErrors(validationErrors, { shouldValidate: false });
        setTouched(validationTouched, { shouldValidate: false });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setUpdateStopLoading(false);
    }
  };

  const onDeleteStop = async (stop) => {
    try {
      if (updateStopLoading || deleteStopLoading) {
        return;
      }

      if (!stop.isNew) {
        setDeleteStopLoading(true);
        await deleteQuoteStop(stop.id);
        showToaster({ type: 'success', message: `Stop point has been deleted successfully!` });
      }

      handleChange(
        `legs[${legIndex}].stops`,
        values.legs[legIndex].stops.filter((i) => i.id !== stop.id)
      );
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setDeleteStopLoading(null);
    }
  };

  const onEditStop = () => {
    handleChange(`${prefix}.isEdit`, true);
  };

  const calculateCurrentAndNextStopDistance = async () => {
    try {
      const currentStop = stop;
      const nextStop = values.legs[legIndex].stops[stopIndex + 1];

      if (
        !nextStop ||
        (!stop.stop_point && (!stop.country || !stop.state || !stop.city || !stop.zipcode)) ||
        (!nextStop?.stop_point && (!nextStop?.country || !nextStop?.state || !nextStop?.city || !nextStop?.zipcode))
      ) {
        return;
      }

      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      setLoadingDistance(true);

      const body = {
        stops: [
          {
            stop_point_id: currentStop.stop_point ? currentStop.stop_point.id : null,
            country: !currentStop.stop_point && currentStop.country ? currentStop.country.name : null,
            state: !currentStop.stop_point && currentStop.state ? currentStop.state.short_name : null,
            city: !currentStop.stop_point && currentStop.city ? currentStop.city.name : null,
            zipcode: !currentStop.stop_point && currentStop.zipcode ? currentStop.zipcode : null,
          },
          {
            stop_point_id: nextStop.stop_point ? nextStop.stop_point.id : null,
            country: !nextStop.stop_point && nextStop.country ? nextStop.country.name : null,
            state: !nextStop.stop_point && nextStop.state ? nextStop.state.short_name : null,
            city: !nextStop.stop_point && nextStop.city ? nextStop.city.name : null,
            zipcode: !nextStop.stop_point && nextStop.zipcode ? nextStop.zipcode : null,
          },
        ],
      };

      const data = await calculateStopsDistance(body);
      handleChange(`${prefix}.distance_to_next_stop`, data?.loaded_miles);
    } catch (e) {
      handleChange(`${prefix}.distance_to_next_stop`, null);
      // Do nothing
    } finally {
      setLoadingDistance(false);
    }
  };

  useEffect(() => {
    getAdditionalData();
  }, []);

  useEffect(() => {
    calculateCurrentAndNextStopDistance();
  }, [
    stop.stop_point,
    stop.country,
    stop.state,
    stop.city,
    stop.zipcode,
    values.legs[legIndex].stops[stopIndex + 1]?.stop_point,
    values.legs[legIndex].stops[stopIndex + 1]?.country,
    values.legs[legIndex].stops[stopIndex + 1]?.state,
    values.legs[legIndex].stops[stopIndex + 1]?.city,
    values.legs[legIndex].stops[stopIndex + 1]?.zipcode,
  ]);

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between gap-4 mb-3'>
        <ShipmentStopType
          hideAction={stopIndex === 0}
          stop={stop}
          stopIndex={stopIndex}
          onTypeSelect={(type) => handleChange(`${prefix}.stop_point_type`, type.id)}
        />
        <div>
          {editMode && (
            <ActionButtons
              data={stop}
              onEdit={() => onEditStop()}
              onSave={() => onSaveStop(stop)}
              onDelete={
                stopIndex === 0 || stopIndex === values.legs[legIndex].stops.length - 1
                  ? null
                  : () => onDeleteStop(stop)
              }
              loadingSave={updateStopLoading}
              loadingDelete={deleteStopLoading}
            />
          )}
        </div>
      </div>
      <div className='shipment-stop'>
        <div className='shipment-stop-column'>
          {editMode && !stop.isEdit && !stop.isNew ? (
            <div className='mb-3'>
              <InputLabel className='shipment-stop-input-label shipment-stop-left-input-label mb-1'>
                Location
              </InputLabel>
              <InfoItem
                content={
                  stop.stop_point
                    ? `${stop.stop_point.location_name} - ${stop.stop_point.address1 || ''}, ${
                        stop.stop_point.city?.name || ''
                      }, ${stop.stop_point.state?.short_name || ''} ${stop.stop_point.zipcode || ''}`
                    : stop.country || stop.state || stop.city || stop.zipcode
                    ? `${stop.country?.name ? `${stop.country?.name},` : ''}${
                        stop.city?.name ? ` ${stop.city?.name},` : ''
                      } ${stop.state?.short_name || ''} ${stop.zipcode || ''}`
                    : '-'
                }
              />
            </div>
          ) : (
            <>
              <div>
                <StopPointAutocomplete
                  size='small'
                  labelProps={{ variant: 'c3', style: { color: getStopName(Number(stop?.stop_point_type)).color } }}
                  options={stopPoints}
                  onAddStopPoint={() => getStopPoints(true)}
                  name={`${prefix}.stop_point`}
                  value={stop.stop_point}
                  onChange={(e, val) => handleChange(`${prefix}.stop_point`, val)}
                />
              </div>
              <Divider className='mt-2 mb-2'>
                <Typography variant='s2' style={{ color: palette.gray500 }}>
                  or
                </Typography>
              </Divider>
              <div className='mb-3'>
                <AddressFields
                  gap='8px'
                  hideLabel
                  hideAddress
                  limitCountries
                  size='small'
                  values={{
                    country: stop.country,
                    state: stop.state,
                    city: stop.city,
                    zipcode: stop.zipcode,
                  }}
                  handleChange={handleAddressChange}
                />
                <ErrorMessage
                  error={
                    errors?.stop_point || errors?.country || errors?.state || errors?.city || errors?.zipcode
                      ? 'One of stop point or country/state/city/zipcode is required'
                      : null
                  }
                />
              </div>
            </>
          )}
          <div>
            <div className='mb-1'>
              <CustomCheckbox
                name={`${prefix}.bill_type`}
                checked={stop.bill_type}
                onChange={(checked) => handleChange(`${prefix}.bill_type`, checked)}
                disabled={editMode && !stop.isEdit && !stop.isNew}
              >
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Billable Move
                </Typography>
              </CustomCheckbox>
            </div>
            <div className='mb-1'>
              <CustomCheckbox
                name={`${prefix}.dock_high`}
                checked={stop.dock_high}
                onChange={(checked) => handleChange(`${prefix}.dock_high`, checked)}
                disabled={editMode && !stop.isEdit && !stop.isNew}
              >
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Dock High
                </Typography>
              </CustomCheckbox>
            </div>
            <div className='mb-1'>
              <CustomCheckbox
                name={`${prefix}.labor_required`}
                checked={stop.labor_required}
                onChange={(checked) => handleChange(`${prefix}.labor_required`, checked)}
                disabled={editMode && !stop.isEdit && !stop.isNew}
              >
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Labor Required (Loading)
                </Typography>
              </CustomCheckbox>
            </div>
            {stop.stop_point_type === 1 && (
              <>
                <div className='mb-1'>
                  <CustomCheckbox
                    name={`${prefix}.liftgate_service`}
                    checked={stop.liftgate_service}
                    onChange={(checked) => handleChange(`${prefix}.liftgate_service`, checked)}
                    disabled={editMode && !stop.isEdit && !stop.isNew}
                  >
                    <Typography variant='s2' style={{ marginLeft: '8px' }}>
                      Liftgate Service
                    </Typography>
                  </CustomCheckbox>
                </div>
                <div className='mb-1'>
                  <CustomCheckbox
                    name={`${prefix}.hazardous_materials`}
                    checked={stop.hazardous_materials}
                    onChange={(checked) => handleChange(`${prefix}.hazardous_materials`, checked)}
                    disabled={editMode && !stop.isEdit && !stop.isNew}
                  >
                    <Typography variant='s2' style={{ marginLeft: '8px' }}>
                      Hazardous Materials
                    </Typography>
                  </CustomCheckbox>
                </div>
              </>
            )}
          </div>
        </div>

        {stop.stop_point_type !== 3 && (
          <div className='shipment-stop-column'>
            <div className='d-flex gap-3 mb-3'>
              <InputLabel className='shipment-stop-input-label shipment-stop-left-input-label'>Commodity</InputLabel>
              <div className='d-flex align-items-center gap-3 flex-grow-1'>
                <div className='flex-1'>
                  {stop.stop_point_type === 1 &&
                    (editMode && !stop.isEdit && !stop.isNew ? (
                      !!stop.cargos?.length && (
                        <Typography
                          variant='c1'
                          style={{
                            color: stop.cargos?.length > 1 ? palette.indigo500 : '',
                            cursor: stop.cargos?.length > 1 ? 'pointer' : '',
                          }}
                          onClick={() =>
                            stop.cargos?.length > 1 &&
                            setCargosToView(
                              stop.cargos.map((item) => ({
                                ...item,
                                shipment_stop: { stop_point: stop.stop_point },
                                expected_quantity_type: item.expected_quantity_type_data,
                                expected_weight_type: item.expected_weight_type_data,
                                expected_dimension_unit: item.expected_dimension_unit_data,
                                expected_dimensions:
                                  typeof item.expected_dimensions === 'string'
                                    ? JSON.parse(item.expected_dimensions)
                                    : null,
                              }))
                            )
                          }
                        >
                          {stop.cargos?.length > 1 ? (
                            <span>
                              Multiple Commodities - <span style={{ textDecoration: 'underline' }}>See Cargo</span>
                            </span>
                          ) : (
                            `${stop.cargos?.[0]?.commodity?.title || ''}`
                          )}
                        </Typography>
                      )
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        <div className='w-100'>
                          <Autocomplete
                            size='small'
                            labelKey='title'
                            options={commodity}
                            value={
                              stop.cargos?.length > 1
                                ? { id: 'multi-commodity', title: 'Multi-Commodities' }
                                : stop.cargos[0]?.commodity || null
                            }
                            disabled={stop.cargos?.length > 1}
                            onChange={(e, value) => onCargoFieldChange('commodity', value)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            error={errors?.cargos?.[0]?.commodity}
                          />
                        </div>
                        <Typography
                          variant='s2'
                          style={{ color: palette.indigo500 }}
                          className='pointer nowrap'
                          onClick={() => setOpenAddCommodity(true)}
                        >
                          {cargoSaved || stop.cargos?.length > 1 ? 'View/Add Multiple' : 'Add Multiple'}
                        </Typography>
                      </div>
                    ))}
                  {stop.stop_point_type === 2 &&
                    (editMode && !stop.isEdit && !stop.isNew ? (
                      stop.cargos?.length ? (
                        <Typography
                          variant='c1'
                          style={{
                            color: stop.cargos?.length > 1 ? palette.indigo500 : '',
                            cursor: stop.cargos?.length > 1 ? 'pointer' : '',
                          }}
                          onClick={() =>
                            stop.cargos?.length > 1 &&
                            setCargosToView(
                              stop.cargos.map((item) => ({
                                ...item,
                                shipment_stop: { stop_point: stop.stop_point },
                                expected_quantity_type: item.expected_quantity_type_data,
                                expected_weight_type: item.expected_weight_type_data,
                                expected_dimension_unit: item.expected_dimension_unit_data,
                                expected_dimensions:
                                  typeof item.expected_dimensions === 'string'
                                    ? JSON.parse(item.expected_dimensions)
                                    : null,
                              }))
                            )
                          }
                        >
                          {stop.cargos?.length > 1 ? (
                            <span>
                              Multiple Commodities - <span style={{ textDecoration: 'underline' }}>See Cargo</span>
                            </span>
                          ) : (
                            `${stop.cargos?.[0]?.commodity?.title || ''}`
                          )}
                        </Typography>
                      ) : (
                        '-'
                      )
                    ) : (
                      <Autocomplete
                        width='100%'
                        multiple
                        size='small'
                        placeholder={stop?.cargos?.length ? '' : 'Select Cargo for Delivery'}
                        labelKey='title'
                        options={cargos}
                        getOptionLabel={(option) =>
                          !!option.stopIndex || option.stopIndex === 0
                            ? `Stop ${option.stopIndex} - ${option.commodity?.title}${
                                option.sku_barcode ? ` (${option.sku_barcode})` : ''
                              }`
                            : `${option.commodity?.title}${option.sku_barcode ? ` (${option.sku_barcode})` : ''}`
                        }
                        getTagLabel={(option) =>
                          !!option.stopIndex || option.stopIndex === 0
                            ? `Stop ${option.stopIndex} - ${option.commodity?.title}${
                                option.sku_barcode ? ` (${option.sku_barcode})` : ''
                              }`
                            : `${option.commodity?.title}${option.sku_barcode ? ` (${option.sku_barcode})` : ''}`
                        }
                        value={stop.cargos || []}
                        onChange={(e, value) => handleChange(`${prefix}.cargos`, value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className='d-flex gap-3 mb-3'>
              <InputLabel className='shipment-stop-input-label shipment-stop-left-input-label'>Qty</InputLabel>
              {stop.stop_point_type === 1 &&
                (editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem
                    content={
                      totalValues.expected_quantity
                        ? `${totalValues.expected_quantity || '-'} ${
                            stop.cargos[0]?.expected_quantity_type?.name || ''
                          }`
                        : '-'
                    }
                  />
                ) : (
                  <SMergedInputs>
                    <Input
                      type='number'
                      size='small'
                      name='cargos[0].expected_quantity'
                      value={totalValues.expected_quantity}
                      onChange={(e) => onCargoFieldChange('expected_quantity', e.target.value)}
                      onKeyDown={(e) => {
                        blockNotNumberChars(e);
                        blockNonPositiveNumbers(e);
                      }}
                      disabled={stop.cargos?.length > 1}
                      error={errors?.cargos?.[0]?.expected_quantity}
                    />
                    <div>
                      <Autocomplete
                        size='small'
                        name='cargos[0].expected_quantity_type'
                        options={quantityTypes}
                        value={stop.cargos[0]?.expected_quantity_type || null}
                        onChange={(e, value) => onCargoFieldChange('expected_quantity_type', value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disabled={stop.cargos?.length > 1}
                        error={errors?.cargos?.[0]?.expected_quantity_type}
                      />
                    </div>
                  </SMergedInputs>
                ))}
              {stop.stop_point_type === 2 &&
                (editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem
                    content={`${(stop.cargos.length ? totalValues.expected_quantity : stop.quantity) || '-'} ${
                      (stop.cargos.length
                        ? stop.cargos?.[0]?.expected_quantity_type?.name || null
                        : stop.quantity_type?.name || null) || ''
                    }`}
                  />
                ) : (
                  <SMergedInputs>
                    <Input
                      name={`${prefix}.quantity`}
                      type='number'
                      size='small'
                      value={stop.cargos.length ? totalValues.expected_quantity : stop.quantity}
                      onKeyDown={(e) => {
                        blockNotNumberChars(e);
                        blockNonPositiveNumbers(e);
                      }}
                      onChange={handleChange}
                      disabled={!!stop.cargos.length}
                    />
                    <div>
                      <Autocomplete
                        size='small'
                        name='cargos[0].expected_quantity_type'
                        options={quantityTypes}
                        value={
                          stop.cargos.length
                            ? stop.cargos?.[0]?.expected_quantity_type || null
                            : stop.quantity_type || null
                        }
                        onChange={(e, value) => handleChange(`${prefix}.quantity_type`, value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disabled={!!stop.cargos.length}
                      />
                    </div>
                  </SMergedInputs>
                ))}
            </div>
            <div className='d-flex gap-3 mb-3'>
              <InputLabel className='shipment-stop-input-label shipment-stop-left-input-label'>Dimensions</InputLabel>
              {stop.stop_point_type === 1 && (
                <div className='d-flex gap-2'>
                  {stop.cargos?.length === 1 && (
                    <>
                      <div>
                        {editMode && !stop.isEdit && !stop.isNew ? (
                          <InfoItem content={totalValues.length || '-'} />
                        ) : (
                          <InputWithText
                            type='number'
                            size='small'
                            text='L'
                            name='cargos[0].expected_dimensions.l'
                            value={totalValues.length}
                            onChange={(e) => onCargoFieldChange('expected_dimensions.l', e.target.value)}
                            disabled={stop.cargos?.length > 1}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        )}
                        <ErrorMessage error={errors?.cargos?.[0]?.expected_dimensions?.l} />
                      </div>
                      <Typography variant='s2' style={{ color: palette.gray500 }}>
                        x
                      </Typography>
                      <div>
                        {editMode && !stop.isEdit && !stop.isNew ? (
                          <InfoItem content={totalValues.width || '-'} />
                        ) : (
                          <InputWithText
                            type='number'
                            size='small'
                            text='W'
                            name='cargos[0].expected_dimensions.w'
                            value={totalValues.width}
                            onChange={(e) => onCargoFieldChange('expected_dimensions.w', e.target.value)}
                            disabled={stop.cargos?.length > 1}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        )}
                        <ErrorMessage error={errors?.cargos?.[0]?.expected_dimensions?.w} />
                      </div>
                      <Typography variant='s2' style={{ color: palette.gray500 }}>
                        x
                      </Typography>
                      <div>
                        {editMode && !stop.isEdit && !stop.isNew ? (
                          <InfoItem content={totalValues.height || '-'} />
                        ) : (
                          <InputWithText
                            type='number'
                            size='small'
                            text='H'
                            name='cargos[0].expected_dimensions.h'
                            value={totalValues.height}
                            onChange={(e) => onCargoFieldChange('expected_dimensions.h', e.target.value)}
                            disabled={stop.cargos?.length > 1}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        )}
                        <ErrorMessage error={errors?.cargos?.[0]?.expected_dimensions?.h} />
                      </div>
                    </>
                  )}
                  {stop.cargos?.length > 1 && (
                    <div>
                      <Typography variant='b2'>
                        {formatNumber(totalValues.volumeInCubicFeet)} <Typography variant='c3'>CU. FT.</Typography>
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              {stop.stop_point_type === 2 && (
                <div className='d-flex gap-2'>
                  {(!stop.cargos?.length || stop.cargos?.length === 1) && (
                    <>
                      <div>
                        {editMode && !stop.isEdit && !stop.isNew ? (
                          <InfoItem
                            content={
                              stop.cargos.length
                                ? Number(totalValues.length || 0).toFixed(2)
                                : stop.dimensions?.l || '-'
                            }
                          />
                        ) : (
                          <InputWithText
                            type='number'
                            size='small'
                            text='L'
                            name={`${prefix}.dimensions.l`}
                            value={
                              stop.cargos.length ? Number(totalValues.length || 0).toFixed(2) : stop.dimensions?.l || ''
                            }
                            onChange={handleChange}
                            onKeyDown={blockNonPositiveNumbers}
                            disabled={!!stop.cargos.length}
                          />
                        )}
                      </div>
                      <Typography variant='s2' style={{ color: palette.gray500 }}>
                        x
                      </Typography>
                      <div>
                        {editMode && !stop.isEdit && !stop.isNew ? (
                          <InfoItem
                            content={
                              stop.cargos.length ? Number(totalValues.width || 0).toFixed(2) : stop.dimensions?.w || '-'
                            }
                          />
                        ) : (
                          <InputWithText
                            type='number'
                            size='small'
                            text='W'
                            name={`${prefix}.dimensions.w`}
                            value={
                              stop.cargos.length ? Number(totalValues.width || 0).toFixed(2) : stop.dimensions?.w || ''
                            }
                            onChange={handleChange}
                            onKeyDown={blockNonPositiveNumbers}
                            disabled={!!stop.cargos.length}
                          />
                        )}
                      </div>
                      <Typography variant='s2' style={{ color: palette.gray500 }}>
                        x
                      </Typography>
                      <div>
                        {editMode && !stop.isEdit && !stop.isNew ? (
                          <InfoItem
                            content={
                              stop.cargos.length
                                ? Number(totalValues.height || 0).toFixed(2)
                                : stop.dimensions?.h || '-'
                            }
                          />
                        ) : (
                          <InputWithText
                            type='number'
                            size='small'
                            text='H'
                            name={`${prefix}.dimensions.h`}
                            value={
                              stop.cargos.length ? Number(totalValues.height || 0).toFixed(2) : stop.dimensions?.h || ''
                            }
                            onChange={handleChange}
                            onKeyDown={blockNonPositiveNumbers}
                            disabled={!!stop.cargos.length}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {stop.cargos?.length > 1 && (
                    <div>
                      <Typography variant='b2'>
                        {formatNumber(totalValues.volumeInCubicFeet)} <Typography variant='c3'>CU. FT.</Typography>
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='d-flex gap-3 mb-3'>
              <InputLabel className='shipment-stop-input-label shipment-stop-left-input-label'>Weight</InputLabel>
              {stop.stop_point_type === 1 &&
                (editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem
                    content={
                      stop.cargos?.length > 1
                        ? formatNumber(totalValues.expected_weight)
                        : totalValues.expected_weight
                        ? `${
                            stop.cargos?.length > 1
                              ? formatNumber(totalValues.expected_weight)
                              : totalValues.expected_weight || '-'
                          } ${stop.cargos[0]?.expected_weight_type?.unit || ''}`
                        : '-'
                    }
                  />
                ) : (
                  <SMergedInputs>
                    <Input
                      type={stop.cargos?.length > 1 ? 'text' : 'number'}
                      size='small'
                      name='cargos[0].expected_weight'
                      value={
                        stop.cargos?.length > 1
                          ? formatNumber(totalValues.expected_weight)
                          : totalValues.expected_weight
                      }
                      onChange={(e) => onCargoFieldChange('expected_weight', e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                      disabled={stop.cargos?.length > 1}
                      error={errors?.cargos?.[0]?.expected_weight}
                    />
                    <div>
                      <Autocomplete
                        size='small'
                        labelKey='unit'
                        name='cargos[0].expected_weight_type'
                        options={weightTypes}
                        value={stop.cargos[0]?.expected_weight_type || null}
                        onChange={(e, value) => onCargoFieldChange('expected_weight_type', value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disabled={stop.cargos?.length > 1}
                        error={errors?.cargos?.[0]?.expected_weight_type}
                      />
                    </div>
                  </SMergedInputs>
                ))}
              {stop.stop_point_type === 2 &&
                (editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem
                    content={`${stop.cargos.length ? formatNumber(totalValues.expected_weight) : stop.weight || '-'} ${
                      stop.cargos.length
                        ? stop.cargos?.[0]?.expected_weight_type?.unit || ''
                        : stop.weight_type?.unit || ''
                    }`}
                  />
                ) : (
                  <SMergedInputs>
                    <Input
                      type='text'
                      size='small'
                      name={`${prefix}.weight`}
                      value={stop.cargos.length ? formatNumber(totalValues.expected_weight) : stop.weight || ''}
                      onChange={handleChange}
                      onKeyDown={blockNonPositiveNumbers}
                      disabled={!!stop.cargos.length}
                    />
                    <div>
                      <Autocomplete
                        size='small'
                        labelKey='unit'
                        name={`${prefix}.weight_type`}
                        options={weightTypes}
                        value={
                          stop.cargos.length ? stop.cargos?.[0]?.expected_weight_type || null : stop.weight_type || null
                        }
                        onChange={(e, value) => handleChange(`${prefix}.weight_type`, value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disabled={!!stop.cargos.length}
                      />
                    </div>
                  </SMergedInputs>
                ))}
            </div>
            {stop.stop_point_type === 1 && (
              <div className='d-flex gap-3 mb-3'>
                <InputLabel className='shipment-stop-input-label shipment-stop-left-input-label'>Value</InputLabel>
                {editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem
                    content={
                      (stop.cargos?.length > 1 ? formatNumber(totalValues.value) : stop.cargos[0]?.value)
                        ? `${
                            stop.cargos?.length > 1 ? formatNumber(totalValues.value) : stop.cargos[0]?.value || '-'
                          } ${stop.cargos?.length > 1 ? VALUE_TYPES[1].name : stop.cargos[0]?.value_type?.name || ''}`
                        : '-'
                    }
                  />
                ) : (
                  <SMergedInputs>
                    <InputWithIcon
                      wrapperClassName='merged-input'
                      type={stop.cargos?.length > 1 ? 'text' : 'number'}
                      size='small'
                      name='cargos[0].value'
                      value={stop.cargos?.length > 1 ? formatNumber(totalValues.value) : stop.cargos[0]?.value}
                      onChange={(e) => onCargoFieldChange('value', e.target.value)}
                      disabled={stop.cargos?.length > 1}
                      onKeyDown={blockNonPositiveNumbers}
                      error={errors?.cargos?.[0]?.value}
                    />
                    <div>
                      <Autocomplete
                        size='small'
                        options={VALUE_TYPES}
                        name='cargos[0].value_type'
                        value={stop.cargos?.length > 1 ? VALUE_TYPES[1] : stop.cargos[0]?.value_type || null}
                        onChange={(e, value) => onCargoFieldChange('value_type', value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disabled={stop.cargos?.length > 1}
                        error={errors?.cargos?.[0]?.value_type}
                      />
                    </div>
                  </SMergedInputs>
                )}
              </div>
            )}
          </div>
        )}

        <div className='shipment-stop-column'>
          <div className='d-flex gap-3 mb-3'>
            <InputLabel className='shipment-stop-input-label'>Scheduled Date Type</InputLabel>
            <div className='w-100'>
              {editMode && !stop.isEdit && !stop.isNew ? (
                <InfoItem content={stop.scheduled_type?.label || '-'} />
              ) : (
                <Autocomplete
                  size='small'
                  labelKey='label'
                  options={SCHEDULED_DATE_TYPE}
                  value={stop.scheduled_type}
                  onChange={(e, value) => handleChange(`${prefix}.scheduled_type`, value)}
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                />
              )}
              <ErrorMessage error={errors?.scheduled_type} />
            </div>
          </div>
          <div className='d-flex gap-3 mb-3'>
            <InputLabel className='shipment-stop-input-label'>Scheduled Date/Time</InputLabel>
            <div className='d-flex flex-column gap-2'>
              {editMode && !stop.isEdit && !stop.isNew ? (
                <InfoItem
                  content={
                    stop.scheduled_tbd
                      ? 'TBD'
                      : stop.scheduled_date && stop.from
                      ? `${formatDate(stop.scheduled_date)} ${formatTime(stop.from)}`
                      : '-'
                  }
                />
              ) : (
                <div className='d-flex justify-content-start align-items-start gap-2'>
                  <div>
                    <DatePicker
                      width='130px'
                      size='small'
                      name={`${prefix}.scheduled_date`}
                      value={stop.scheduled_tbd ? null : stop.scheduled_date}
                      onChange={(date) => handleChange(`${prefix}.scheduled_date`, date)}
                      error={errors?.scheduled_date}
                      disabled={!!stop.scheduled_tbd}
                    />
                  </div>
                  <div>
                    <TimePicker
                      size='small'
                      width='100px'
                      value={stop.scheduled_tbd ? null : stop.from}
                      onChange={(time) => handleChange(`${prefix}.from`, time)}
                      error={errors?.from}
                      disabled={!!stop.scheduled_tbd}
                    />
                  </div>
                  <CustomCheckbox
                    name={`${prefix}.scheduled_tbd`}
                    checked={!!stop.scheduled_tbd}
                    onChange={(checked) => handleChange(`${prefix}.scheduled_tbd`, checked)}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      TBD
                    </Typography>
                  </CustomCheckbox>
                </div>
              )}
              {[1, 3].includes(stop.scheduled_type?.key) &&
                (editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem
                    content={
                      stop.scheduled_date_to && stop.to
                        ? `to ${formatDate(stop.scheduled_date)} ${formatTime(stop.to)}`
                        : '-'
                    }
                  />
                ) : (
                  <div className='d-flex justify-content-start align-items-start gap-2'>
                    <Typography variant='s2' className='mt-1'>
                      to
                    </Typography>
                    <div>
                      <DatePicker
                        width='130px'
                        size='small'
                        name={`${prefix}.scheduled_date_to`}
                        value={stop.scheduled_tbd ? null : stop.scheduled_date_to}
                        onChange={(date) => handleChange(`${prefix}.scheduled_date_to`, date)}
                        minDate={moment(stop.scheduled_date).toDate()}
                        error={errors?.scheduled_date_to}
                        disabled={!!stop.scheduled_tbd}
                      />
                    </div>
                    <div>
                      <TimePicker
                        size='small'
                        width='100px'
                        value={stop.scheduled_tbd ? null : stop.to}
                        onChange={(time) => handleChange(`${prefix}.to`, time)}
                        error={errors?.to}
                        disabled={!!stop.scheduled_tbd}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {stop.stop_point_type === 1 && (
            <div className='d-flex gap-3 mb-3'>
              <InputLabel className='shipment-stop-input-label'>Equipment Required</InputLabel>
              <div className='w-100'>
                {editMode && !stop.isEdit && !stop.isNew ? (
                  <InfoItem content={stop.equipment_type?.title || '-'} />
                ) : (
                  <Autocomplete
                    size='small'
                    labelKey='title'
                    options={equipmentTypes}
                    value={stop.equipment_type}
                    onChange={(e, value) => onEquipmentTypeChange(value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                )}
                <ErrorMessage error={errors?.equipment_type} />
              </div>
              {stop.equipment_type?.vehicle_type_id === 2 && stop.equipment_type?.dimensions?.length > 0 && (
                <div>
                  {editMode && !stop.isEdit && !stop.isNew ? (
                    <InfoItem
                      content={
                        stop.equipment_type_length?.length_info
                          ? `${stop.equipment_type_length.length_info.length} ${stop.equipment_type_length.length_info.unit}`
                          : '-'
                      }
                    />
                  ) : (
                    <Autocomplete
                      width='100px'
                      size='small'
                      options={stop.equipment_type.dimensions}
                      value={stop.equipment_type_length}
                      onChange={(e, value) => handleChange(`${prefix}.equipment_type_length`, value)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => `${option.length_info.length} ${option.length_info.unit}`}
                    />
                  )}
                  <ErrorMessage error={errors?.equipment_type_length} />
                </div>
              )}
            </div>
          )}

          <div className='d-flex gap-3 mb-3'>
            <InputLabel className='shipment-stop-input-label'>Equipment Action</InputLabel>
            <div className='w-100'>
              {editMode && !stop.isEdit && !stop.isNew ? (
                <InfoItem content={stop.equipment_action?.label || '-'} />
              ) : (
                <Autocomplete
                  size='small'
                  labelKey='label'
                  options={equipmentActions}
                  value={stop.equipment_action}
                  onChange={(e, value) => handleChange(`${prefix}.equipment_action`, value)}
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                />
              )}
              <ErrorMessage error={errors?.equipment_action} />
            </div>
          </div>
          <div className='d-flex gap-3 mb-3'>
            <InputLabel className='shipment-stop-input-label'>Stop Notes</InputLabel>
            {editMode && !stop.isEdit && !stop.isNew ? (
              <InfoItem content={stop.notes_text || '-'} />
            ) : (
              <Textarea
                placeholder='Notes..'
                name={`${prefix}.notes_text`}
                value={stop.notes_text}
                onChange={handleChange}
                className='flex-1'
              />
            )}
          </div>
          {stopIndex !== values.legs[legIndex].stops.length - 1 && (
            <div className='d-flex justify-content-end align-items-center gap-3 me-3'>
              <InputLabel className='mb-0' variant='c2'>
                DISTANCE TO STOP {getAlphabet(stopIndex + 1)}
              </InputLabel>
              {loadingDistance ? (
                <Skeleton width={80} height={18} />
              ) : (
                <Typography variant='c2'>
                  {stop.distance_to_next_stop || stop.distance_to_next_stop === 0
                    ? Math.round(stop.distance_to_next_stop)
                    : '???'}{' '}
                  MILES
                </Typography>
              )}
            </div>
          )}
        </div>
        {stop.stop_point_type === 3 && <div className='shipment-stop-column' />}

        {openAddCommodity && (
          <ShipmentCommodity
            createMode={!editMode}
            stopId={editMode && !stop.isNew ? stop.id : undefined}
            quoteId={editMode ? quote.id : undefined}
            open={openAddCommodity}
            onClose={() => setOpenAddCommodity(false)}
            cargo={stop.cargos}
            onUpdateSuccess={(cargo) =>
              handleChange(`${prefix}.cargos`, [...cargo.map((item) => getInitialValue(item))])
            }
            onDeleteSuccess={(cargo) => {
              handleChange(
                `${prefix}.cargos`,
                stop.cargos.filter((i) => i.id !== cargo.id)
              );
            }}
            equipment={
              stop.equipment_type
                ? {
                    ...(stop.equipment_type || {}),
                    length: stop.equipment_type_length || stop.equipment_type?.dimensions?.[0],
                  }
                : null
            }
            onSave={(val) => {
              if (editMode) {
                handleChange(
                  `${prefix}.cargos`,
                  val.map((item) => ({ ...item, isNew: false }))
                );
                return;
              }

              handleChange(
                `${prefix}.cargos`,
                val.map((item) => ({ ...item, isNew: false }))
              );
              setCargoSaved(true);

              values.legs.forEach((item, upperIndex) => {
                item.stops.forEach((i, innerIndex) => {
                  if (Number(i.stop_point_type) === 2 && upperIndex >= legIndex && innerIndex >= stopIndex) {
                    handleChange(`legs[${upperIndex}].stops[${innerIndex}].cargos`, []);
                  }
                });
              });
            }}
          />
        )}
        {!!cargosToView && (
          <ShipmentCommodity
            open={!!cargosToView}
            onClose={() => setCargosToView(null)}
            cargo={cargosToView}
            readOnly
          />
        )}
      </div>
    </div>
  );
};

export default ShipmentStop;
