import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: [],
  upDownGradeModalIsVisible: false,
  updatePaymentMethodModalVisible: false,
  manageModalIsVisible: false,
  paymentMethod: null,
  billingDetail: null,
  billingCardsPrice: null,
  billingCardsFeatures: null,
  billingCheckPricesCoupon: null,
  billingCarrierCheckout: null,
  billingUpdatedData: null,
  billingPaymentMethodUpdateData: null,
  additionalServicesList: null,
  paymentRequired: null,
  additionalServicesListStatus: 'DEFAULT',
};
export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setBillingDetail: (state, action) => {
      state.billingDetail = action.payload;
    },
    setBillingCardsPrice: (state, action) => {
      state.billingCardsPrice = action.payload;
    },
    setBillingCardsFeatures: (state, action) => {
      state.billingCardsFeatures = action.payload;
    },
    setBillingCheckPricesCoupon: (state, action) => {
      state.billingCheckPricesCoupon = action.payload;
    },
    setBillingCarrierCheckout: (state, action) => {
      state.billingCarrierCheckout = action.payload;
    },
    setBillingUpdatedData: (state, action) => {
      state.billingUpdatedData = action.payload;
    },
    setBillingPaymentMethodUpdatedData: (state, action) => {
      state.billingPaymentMethodUpdateData = action.payload;
    },
    setBillingAdditionalServices: (state, action) => {
      state.additionalServicesList = action.payload;
      state.additionalServicesListStatus = 'SUCCESS';
    },
    toggleUpDownGradeModalIsVisible: (state, action) => {
      state.upDownGradeModalIsVisible = action.payload;
    },
    toggleUpdatePaymentMethodModalIsVisible: (state, action) => {
      state.updatePaymentMethodModalVisible = action.payload;
    },
    toggleManageModalIsVisible: (state, action) => {
      state.manageModalIsVisible = action.payload;
    },
    setBillingState(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updatePaymentRequired: (state, action) => {
      state.paymentRequired = action.payload;
    },
  },
});

export const {
  setPlans,
  setBillingDetail,
  toggleUpdatePaymentMethodModalIsVisible,
  toggleUpDownGradeModalIsVisible,
  toggleManageModalIsVisible,
  setBillingCardsPrice,
  setBillingCardsFeatures,
  setBillingCheckPricesCoupon,
  setBillingPaymentMethodUpdatedData,
  setBillingAdditionalServices,
  setBillingState,
  setPaymentMethod,
  setBillingUpdatedData,
  setBillingCarrierCheckout,
  updatePaymentRequired,
} = billingSlice.actions;

export default billingSlice.reducer;
