import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import { getAppUrl } from 'utils/helpers';
import { getOnboardingSettings, getPremiumPlans } from 'Api/Onboarding';
import Header from 'components/customerOnboardingComponents/Header';
import { updatePlans, updateSettings } from 'store/reducers/onboarding.reducer';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CustomerOnboarding = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const appUrl = getAppUrl();

    if (appUrl !== 'https://app.truckindigital.com') {
      return;
    }

    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = `https://www.clarity.ms/tag/${i}`;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'jc1x2qaon1');
  }, []);

  const getSettings = async () => {
    try {
      const response = await getOnboardingSettings();
      dispatch(updateSettings(response));
      return response;
    } catch (e) {
      // Do nothing
    }
  };

  const getPlans = async () => {
    try {
      const settings = await getSettings();
      const response = await getPremiumPlans({ customer_type: 'carrier' });
      const data = response.map((plan) => {
        const sortedPlanTypes = plan.plan_types.sort((a, b) => a.amount - b.amount);
        return {
          ...plan,
          plan_types: sortedPlanTypes.filter(
            (item) =>
              !(
                (!!settings?.hide_yearly_plan && item.duration === 'yearly') ||
                (!!settings?.hide_3_year_plan && item.duration === '3-Year')
              )
          ),
        };
      });
      dispatch(updatePlans(data));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (location?.pathname === '/onboarding/account') {
      getSettings();
    } else {
      getPlans();
    }
  }, []);

  return (
    // <Elements stripe={stripePromise}>
    <>
      <Header />
      {/* <OnboardingHeader /> */}
      <Outlet />
    </>
    // </Elements>
  );
};

export default CustomerOnboarding;
