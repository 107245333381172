import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';

const getSpecificInfo = (transactions) => {
  const data = { title: '', description: '' };
  const mySet = new Set([]);

  for (let i = 0; i < transactions.length; i++) {
    const obj = transactions[i];
    const provider = obj.Provider;
    const cardNumber = obj['Card Number'].match(/\d+/g)?.[0] || '';
    if (provider === '' && cardNumber === '') {
      continue;
    }
    mySet.add(`${provider} ****${cardNumber}`);
  }

  const firstItem = transactions?.[0];
  const assignedTo = firstItem?.['Assigned To'] ? firstItem?.['Assigned To'].toString() : '-';
  const userType = firstItem?.['User Type'] ? firstItem?.['User Type'].toString() : '';
  data.title = `${assignedTo} ${userType ? `(${userType})` : ''}`;
  data.description = Array.from(mySet).join(', ');
  return data;
};

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc, top, isSpecific = false) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.setFontSize(2.5);

  if (isSpecific) {
    doc.text('DATE', 8, top);
    doc.text('MERCHANT', 18, top);
    doc.text('CATEGORY', 29, top);
    doc.text('REFERENCE', 47, top);
    doc.text('ADDRESS', 60, top);
    doc.text('CITY', 73, top);
    doc.text('STATE', 86, top);
    doc.text('COUNTRY', 99, top);
    doc.text('AMOUNT PER GALLON', 110, top);
    doc.text('AMOUNT', 128, top);
    doc.text('Fees', 141, top);
    doc.text('EQUIPMENT ID', 152, top);
    doc.text('DESCRIPTION', 167, top);
    doc.text('GALLONS', 180, top);
    doc.text('TYPE', pageWidth - 8, top, { align: 'right' });
  } else {
    doc.text('ASSIGNED TO', 8, top);
    doc.text('USER TYPE', 20, top);
    doc.text('PROVIDER', 29, top);
    doc.text('CARD NUMBER', 42, top);
    doc.text('DATE', 53, top);
    doc.text('MERCHANT', 60, top);
    doc.text('CATEGORY', 71, top);
    doc.text('REFERENCE', 88, top);
    doc.text('ADDRESS', 96, top);
    doc.text('CITY', 109, top);
    doc.text('STATE', 120, top);
    doc.text('COUNTRY', 128, top);
    doc.text('AMOUNT PER GALLON', 139, top);
    doc.text('AMOUNT', 154, top);
    doc.text('Fees', 164, top);
    doc.text('EQUIPMENT ID', 173, top);
    doc.text('DESCRIPTION', 182, top);
    doc.text('GALLONS', 194, top);
    doc.text('TYPE', pageWidth - 8, top, { align: 'right' });
  }
  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);

  const lineHeight = doc.internal.getLineHeight();
  return top + lineHeight;
};

const createTableRow = (doc, data, top, isSpecific = false) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(2.5);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);

  const totalGallons = Number(data.Gallons || 1);
  const totalAmount = Number(data.Amount || 0);

  const costPerGallon = totalAmount / totalGallons;

  if (isSpecific) {
    doc.text(data?.Date ? data?.Date.toString() : '-', 8, top);
    doc.text(data?.Merchant ? data?.Merchant.toString() : '-', 18, top);
    doc.text(data?.Category ? data?.Category.toString() : '-', 29, top);
    doc.text(data?.Reference ? data?.Reference.toString() : '-', 47, top);
    doc.text(data?.Location ? data?.Location.toString() : '-', 60, top);
    doc.text(data?.City ? data?.City.toString() : '-', 73, top);
    doc.text(data?.State ? data?.State.toString() : '-', 86, top);
    doc.text(data?.Country ? data?.Country.toString() : '-', 99, top);
    doc.text(`$${formatAmount(costPerGallon)}`, 110, top);
    doc.text(data?.Amount ? `$${data?.Amount}` : '-', 128, top);
    doc.text(data?.Fees ? `$${data?.Fees.toString()}` : '-', 141, top);
    doc.text(data?.['Equipment ID'] ? data?.['Equipment ID'].toString() : '-', 152, top);
    doc.text(data?.Description ? data?.Description.toString() : '-', 167, top);
    doc.text(data?.Gallons ? data?.Gallons.toString() : '-', 180, top);
    doc.text(data?.Type ? data?.Type.toString() : '-', pageWidth - 8, top, { align: 'right' });
  } else {
    doc.text(data?.['Assigned To'] ? data?.['Assigned To'].toString() : '-', 8, top);
    doc.text(data?.['User Type'] ? data?.['User Type'].toString() : '-', 20, top);
    doc.text(data?.Provider ? data?.Provider.toString() : '-', 29, top);
    doc.text(data?.['Card Number'] ? data?.['Card Number'].toString() : '-', 42, top);
    doc.text(data?.Date ? data?.Date.toString() : '-', 53, top);
    doc.text(data?.Merchant ? data?.Merchant.toString() : '-', 60, top);
    doc.text(data?.Category ? data?.Category.toString() : '-', 71, top);
    doc.text(data?.Reference ? data?.Reference.toString() : '-', 88, top);
    doc.text(data?.Location ? data?.Location.toString() : '-', 96, top);
    doc.text(data?.City ? data?.City.toString() : '-', 109, top);
    doc.text(data?.State ? data?.State.toString() : '-', 120, top);
    doc.text(data?.Country ? data?.Country.toString() : '-', 128, top);
    doc.text(`$${formatAmount(costPerGallon)}`, 139, top);
    doc.text(data?.Amount ? `$${data?.Amount}` : '-', 154, top);
    doc.text(data?.Fees ? `$${data?.Fees.toString()}` : '-', 164, top);
    doc.text(data?.['Equipment ID'] ? data?.['Equipment ID'].toString() : '-', 173, top);
    doc.text(data?.Description ? data?.Description.toString() : '-', 182, top);
    doc.text(data?.Gallons ? data?.Gallons.toString() : '-', 194, top);
    doc.text(data?.Type ? data?.Type.toString() : '-', pageWidth - 8, top, { align: 'right' });
  }

  const lineHeight = doc.internal.getLineHeight();
  const newY = top + lineHeight;

  return newY;
};

export const generatePDF = async (data, download, dateRange, dateFormat, isSpecific = false) => {
  const { transactions, title } = data || {};
  if (!transactions?.length) {
    return;
  }

  const doc = new jsPDF({ format: 'letter' });
  const { start, end } = dateRange;
  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.height;
  const yHeader = isSpecific ? 32 : 28;

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(title || '', pageWidth / 2, 22, { align: 'center' });

  if (isSpecific) {
    const { title, description } = getSpecificInfo(transactions);
    doc.setFontSize(6.4);
    doc.setTextColor(palette.gray700);
    doc.text(title, pageWidth / 2, 25, { align: 'center' });
    doc.text(description, pageWidth / 2, 28.5, { align: 'center' });
  }

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      yHeader,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, yHeader, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(dateFormat.formatDateTime(), pageWidth - 16, yHeader, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, yHeader + 2, pageWidth - 8, yHeader + 2);

  let index = 0;
  let page = 1;
  let top = isSpecific ? 44 : 40;

  for (const item of transactions) {
    doc.setFont('Inter', 'normal', 700);

    index === 0 && createTableHeader(doc, top, isSpecific);
    const lastRowHeight = createTableRow(doc, item, top + 6 + index * 5, isSpecific);
    if (lastRowHeight > pageHeight - 15) {
      index = 0;
      page += 1;
      top = 0;
      doc.addPage(page);
      createTableHeader(doc, top + 6, isSpecific);
    }

    index += 1;
  }

  /** FOOTER * */
  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber;
    doc.setFontSize(12);
    createFooter(doc, page, totalPages);
  }

  if (download) {
    doc.save(`Card-Transactions.pdf`);
  }

  const url = doc.output('datauristring', { filename: 'Card Transactions' });
  const blob = doc.output('blob', { filename: 'Card Transactions' });
  // window.open(URL.createObjectURL(blob));

  return { blob, url };
};
