import React, { useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/drivers/indigoDownload.svg';
import { ReactComponent as UploadIcon } from 'assets/icon.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { importCarrierCsv } from 'Api/Carriers';
import { getHeaders } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { carriersMapper } from './ImportCsv.data';
import { SAttachedDoc, SCountWrapper, SDownload, SDownloadWrapper } from './ImportCsv.styles';

const ImportCsv = ({ open, onClose, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [data, setData] = useState(null);

  function csvToArray(str) {
    const arr = getHeaders(str, carriersMapper);

    const importCustomersData = [];

    if (arr?.length) {
      for (let i = 0; i < arr.length; i++) {
        const customerData = {
          name: arr[i]?.name,
          dot: arr[i]?.dot,
          email: arr[i]?.email,
          phone_number: arr[i]?.phone_number,
          can_travel_to_usa: arr[i]?.can_travel_to_usa,
          can_travel_to_mexico: arr[i]?.can_travel_to_mexico,
          can_travel_to_canada: arr[i]?.can_travel_to_canada,
          primary_contact: {
            name: arr[i]?.contact_name,
            email: arr[i]?.contact_email,
            phone_number: arr[i]?.contact_phone,
          },
        };

        importCustomersData.push(customerData);
      }
    }

    setData(importCustomersData);
  }

  const importCarriers = async () => {
    try {
      setLoading(true);
      const res = await importCarrierCsv(data);
      if (res.warning) {
        showToaster({ type: 'warning', message: res.message });
      } else {
        showToaster({ type: 'success', message: 'Carriers have been successfully imported!' });
      }
      onClose();
      onSuccess();
    } catch (e) {
      let singleErrorMessage = '';
      if (e.response?.data?.response?.length) {
        singleErrorMessage = `${e.response.data.response.join('. ')}.`;
      }
      showToaster({ type: 'error', message: singleErrorMessage || getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const onImport = (file) => {
    setImportFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        csvToArray(text);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Import from CSV'
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='600px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'Upload',
          type: 'primary',
          title: 'Upload',
          onClick: importCarriers,
          disabled: !importFile || loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>1</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2'>
            <Typography as='div' variant='s2'>
              Download the CSV Template
            </Typography>
            <Typography as='div' variant='s2' style={{ color: palette.gray500 }}>
              First download the CSV template. Then fill out asked data in that template.
            </Typography>
            <SDownloadWrapper href={`${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportCarrier.csv`} target='_blank'>
              <SDownload>
                <DownloadIcon />
                <Typography variant='s2' style={{ color: palette.indigo500, textDecoration: 'none' }}>
                  Download CSV Template
                </Typography>
              </SDownload>
            </SDownloadWrapper>
          </div>
        </div>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>2</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2 w-100'>
            <Typography as='div' variant='s2'>
              Upload filled CSV file here:
            </Typography>
            {importFile ? (
              <SAttachedDoc>
                <AttachmentIcon fill={palette.indigo500} />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  {importFile.name}
                </Typography>
                <CancelIcon fill={palette.red500} style={{ cursor: 'pointer' }} onClick={() => setImportFile(null)} />
              </SAttachedDoc>
            ) : (
              <div
                className='upload-selector csv-upload'
                style={{ position: 'relative', backgroundColor: palette.white, borderColor: palette.gray50 }}
              >
                <label htmlFor='myFile' className='text-style csv-text-style'>
                  <UploadIcon width={30} height={30} fill={palette.gray300} />
                  <Typography variant='s2' style={{ color: palette.indigo500, marginTop: '10px' }}>
                    Upload File
                    <Typography variant='s2'> or drag and drop</Typography>
                  </Typography>
                </label>
                <ImageDropZone
                  onDrop={(files) => onImport(files[0])}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['text/csv']}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportCsv;
