import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SSection = styled.div`
  display: flex;
  margin-top: 8px;

  & > div {
    flex: 1;
    border-top: 1px solid ${palette.gray50};
    border-bottom: 1px solid ${palette.gray50};
    border-right: 1px solid ${palette.gray50};
    padding: 16px 20px;

    :first-child {
      padding: 16px 16px 16px 0;
    }

    :last-child {
      border-right: none;
      padding: 16px 0 16px 16px;
    }
  }
`;

export const SInfo = styled.div`
  display: flex;
  flex-direction: column;

  .info-link {
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const SColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

export const SQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 16px;
`;

export const SFlexRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 10px 16px;
  border-bottom: 1px solid ${palette.gray50};

  & > * {
    flex: 1;
  }
`;

export const SOtherQuestion = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SFormRow = styled.div`
  display: flex;
  column-gap: 20px;
  margin-bottom: ${({ $margin }) => $margin || '32px'};

  .select-field {
    width: 350px;
  }

  .date-field {
    width: 150px;
  }
`;

export const SActionsFormWrapper = styled.div`
  padding: 8px 4px;
`;

export const SUploadWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 32px;
  height: fit-content;

  .file-uploaded {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f1fa;
    border-radius: 4px;
    height: 24px;
    padding: 0 8px;
    width: max-content;

    .delete-icon {
      cursor: pointer;
    }
  }

  .uploader-content {
    display: flex;
    align-items: center;
    column-gap: 6px;

    svg path {
      fill: ${palette.indigo500};
    }
  }

  .attachment-error-message {
    margin-top: 8px;
  }

  &:hover .uploader-content span {
    text-decoration: underline;
  }
`;
