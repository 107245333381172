import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';
import moment from 'moment';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

import { PusherJs } from 'common/pusher';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import TableBulkActions from 'components/TableBulkActions';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomModal from 'components/CustomModal/CustomModal';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import {
  SHIPMENT_BULK_ACTIONS_DATA,
  ALL_TAB_FILTER_ID,
  DEFAULT_DISABLED_TABS,
  LOCATION_INITIAL_VALUE,
  STATUS_TAB_ADD_MODAL_SHIPMENT,
  LOCATION_INITIAL_VALUE_STOP_POINT,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { TableShipmentsContext } from 'context/tableContext';
import { updateRecentActivity } from 'store/reducers/shipments.reducer';
import { getErrorMessage } from 'utils/error';
import { debounce, formatNumber, palette } from 'utils/constants';
import { updateRowEditItem } from 'store/reducers/root.reducer';
import {
  Shipment,
  getShipmentPopoverSettings,
  getRecentActivity,
  getShipmentInfo,
  deleteRevertShipment,
  getTimeFrame,
  getColumnsWidth,
  updateColumnsWidth,
  updateCellularTracking,
  getShipmentsTotal,
} from 'Api/Shipment';
import {
  getEquipmentRequiredVehicles,
  getTabs,
  customerStopPoint,
  getAssignToGroupBillingCharges,
  getBillToBillingCharges,
  getEquipmentRequiredTrailers,
  getVehicleData,
  revertLastAction,
  UpdateShare,
  deleteDispatch,
} from 'Api/Planner';
import RestrictedCheckIn from 'components/TableShipments/components/RestrictedCheckIn';
import RequestCellularTracking from 'components/TableShipments/components/RequestCellularTracking';
import Pagination from 'common/Pagination';
import ConfirmModal from 'common/ConfirmModal';
import { getUserProfile } from 'Api/User';
import { getDrivers } from 'Api/EquipmentProfile';
import { getCarriers } from 'Api/Carriers';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import PlannedLoads from './components/PlannedLoads';
import TableShipmentRowEditChildes from './TableShipmentRowEditChildes';
import { DEFAULTWIDTHOFCOLUMNS } from './helpers/constants';
import AddTab from './helpers/ShipmentModalContent';
import classes from './tableShipments.module.css';
import TableDrawer from './TableDrawer';
import ShipmentsFilter from './ShipmentsFilter';
import HeaderShipments from './HeaderShipments';
import { useColumns } from './TableShipments.data';

let controller = null;

const TableShipments = () => {
  const { use } = useTheme();
  const hasBrokerage = useHasBrokerage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dot_num } = useContext(LayoutContext);
  const { currency } = useSelector((state) => state.root);
  const { recentActivity } = useSelector((state) => state?.shipments);
  const [refs, setRefs] = useState({});
  const [tableShipmentModalObj, setTableShipmentModalObj] = useState({});
  const [refsEditIcon, setRefEditIcon] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
  const [rowCheckBoxes, setRowCheckBoxes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [motions, setMotions] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [localFilterCheckboxes, setLocalFilterCheckboxes] = useState({
    status: [],
    groups: [],
    equipment_types: [],
    motions: [],
  });
  const [getShipmentFilterData, setGetShipmentFilterData] = useState({});
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [stopPointCondition, setStopPointCondition] = useState(false);
  const myTableRef = useRef(null).current;
  const resizingColumn = useRef();
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [shipmentSettingsProgressBar, setShipmentSettingsProgressBar] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [carrierData, setCarrierData] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [requestTrackingData, setRequestTrackingData] = useState(null);
  const [stopTrackingData, setStopTrackingData] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [shipmentsData, setShipmentsData] = useState({ data: [] });
  const [filterByGroupsData, setFilterByGroupsData] = useState([]);
  const [filterByEquipmentTypeData, setFilterByEquipmentTypeData] = useState([]);
  const [animationList, setAnimationList] = useState({});
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [page, setPage] = useState(1);
  const [hasRun, setHasRun] = useState(false);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState();
  const [columnWidths, setColumnWidths] = useState({ ...DEFAULTWIDTHOFCOLUMNS });
  const [openConfirmCarrierCheckin, setOpenConfirmCarrierCheckin] = useState(null);
  const [activeTabData, setActiveTabData] = useState({
    key: 'all',
    label: 'All',
    value: 1,
    id: ALL_TAB_FILTER_ID,
    count: 0,
  });
  const [disabledFilters, setDisabledFilters] = useState({ ...DEFAULT_DISABLED_TABS });
  const [triggerAdvancedClear, setTriggerAdvancedClear] = useState(null);
  const [dateRangeLocation, setDateRangeLocation] = useState([null, null]);
  const [dateRangeStopPoint, setDateRangeStopPoint] = useState([null, null]);
  const [openRestrictedCheckIn, setOpenRestrictedCheckIn] = useState(false);
  const [rowToCheckin, setRowToCheckin] = useState(false);
  const [shipmentsTotal, setShipmentsTotal] = useState(null);
  const [sort, setSort] = useState({ field: '', sortBy: 'desc' });
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Shipment ID', value: 'shipment_id', key: 9999 },
    allValue: '',
    cloudSwitch: false,
    selectedValues: [],
    locations: LOCATION_INITIAL_VALUE,
    stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
    customerSelectData: [],
    equipmentSelectedData: [],
    driverSelectedData: [],
    carrierSelectedData: [],
    tableColumn,
    switchRadioButtons: {
      amount: '1',
      date: '1',
      time: '1',
    },
    forAll: false,
    shipmentProgressBar: shipmentSettingsProgressBar,
    filterTableTop: [],
    planerHeaderSwitch: '0',
    applyAllUsersModal: { value: '0' },
  });
  const shipmentTotalsController = useRef(new AbortController());

  const multiSelectOptions = useMemo(() => {
    const submenuOptions = [
      {
        title: 'Shipment ID',
        id: 999,
        value: 'shipment_id',
        key: 9999,
      },
      {
        title: 'Reference ID',
        id: 888,
        value: 'reference_id',
        key: 8888,
      },
      {
        title: 'Customer',
        id: 333,
        value: 'customers',
        key: uuid(),
        disabled: disabledFilters.customers,
      },
      {
        title: 'Location',
        id: 444,
        value: 'locations',
        key: uuid(),
      },
      {
        title: 'Stop Point',
        id: 555,
        value: 'stop_point',
        key: uuid(),
        disabled: disabledFilters.stop_point,
      },
      {
        title: 'Vehicle ID',
        id: 666,
        value: 'equipment',
        key: uuid(),
      },
      {
        title: 'Driver',
        id: 777,
        value: 'driver',
        key: uuid(),
      },
    ];

    if (hasBrokerage) {
      submenuOptions.push({
        title: 'Carrier',
        id: 888,
        value: 'carrier',
        key: uuid(),
      });
    }

    return {
      title: 'All',
      id: 999,
      value: 'all',
      key: 9999,
      submenu: submenuOptions,
    };
  }, [filterByGroupsData, filterByEquipmentTypeData, disabledFilters, hasBrokerage]);

  function findNextStop(rowData) {
    const { status_id, shipment_stops } = rowData;

    if (['1', '2', '8', '9'].includes(status_id)) {
      return 0;
    }

    if (shipment_stops.every((stop) => !!stop.departure_date && !!stop.arrival_date)) {
      return shipment_stops.length;
    }

    const index = shipment_stops.findIndex((stop) => !stop.departure_date && stop.arrival_date);

    if (index !== -1) {
      return index + 1;
    }

    const firstMissingArrivalIndex = shipment_stops.findIndex((stop) => !stop.arrival_date);

    if (firstMissingArrivalIndex !== -1) {
      return firstMissingArrivalIndex + 1;
    }

    return 0;
  }

  const handleMouseDown = (columnField) => (e) => {
    resizingColumn.current = {
      columnField,
      startingWidth: columnWidths[columnField],
      startingX: e.clientX,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!resizingColumn.current) return;
    const delta = e.clientX - resizingColumn.current.startingX;

    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [resizingColumn.current.columnField]: Math.max(resizingColumn.current.startingWidth + delta, 50),
    }));

    const param = {
      table_type: 'shipment',
      column_type: resizingColumn.current.columnField,
      width: Math.max(resizingColumn.current.startingWidth + delta, 50),
    };

    updateColumnsWidth(param);
  };

  const getUser = async () => {
    try {
      const { data } = await getUserProfile();
      setUser(data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleMouseUp = () => {
    resizingColumn.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  function checkNullOrUndefined(val) {
    return val === null || val === undefined ? '-' : val;
  }

  function onClickRowIcon(e, rowData, type) {
    e.stopPropagation();
    switch (type) {
      case 'notes':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 6 } });
        break;
      case 'detention':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 7 } });
        break;
      default:
    }
  }

  function onClickPlanned(e, rowData, count) {
    e.stopPropagation();
    if (count) {
      setSelectedShipment(rowData);
      setAddActionOpen(true);
    }
  }

  // function pressStarIcon(id) {
  //   const data = shipmentsData.data.map((item) => (+id === +item.id ? { ...item, star: !item.star } : item));
  //   setShipmentsData({ ...shipmentsData, data });
  // }

  /** routes Bulk action and rowsEditIcons-OverLay* */
  function onClickBulkAction(item, index, arrIds) {
    const ids = arrIds.filter((key) => key !== 'all');
    switch (item.id) {
      case 19:
        deleteRevertShipment({ shipment_ids: ids })
          .then((res) => {
            if (res?.data) {
              showToaster({
                type: 'success',
                message: 'Dispatch has been reverted and shipment is returned to planner page.',
              });
              navigate('/planner');
            }
          })
          .catch(() => {
            // Do nothing
          });
        break;
      case 97:
        revertLastAction([ids])
          .then(() => {
            showToaster({ type: 'success', message: 'Last action has been reverted.' });
          })
          .catch(() => {
            // Do nothing
          });
        break;
      default:
    }
  }

  const revertDispatch = async (shipment) => {
    try {
      await deleteDispatch({ shipment_id: shipment?.shipment_id });
      if (shipment?.brokerage_dispatch) {
        navigate(
          `/planner/carrier-dispatch?id=${shipment.shipment_id}&carrier=${shipment.brokerage_dispatch.carrier_id}`
        );
      } else {
        navigate(`/planner/dispatch?id=${shipment.shipment_id}&equipment=${shipment.equipment?.id}`);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  function onClickTableRowsEditOption(item, index, rowData) {
    if (item?.route) {
      +item.id === 3 && navigate(`/chat/${dot_num}_driver_group_${rowData.assigned_to_1.id}`);
      +item.id === 18 && navigate(`/${item.route}/${rowData.shipment_id}/dispatch`);
      +item.id === 16 && navigate(`/${item.route}/${rowData.shipment_id}/new`);
    } else {
      switch (item?.id) {
        case 9:
          navigate(`/shipment/${rowData?.shipment_id}`, { state: rowData });
          break;
        case 1:
          dispatch(updateRowEditItem({ ...rowData, label: 'Shipment' }));
          break;
        case 2:
          const dropTrailerRequired =
            rowData?.shipment_stops &&
            !rowData?.shipment_stops?.[0]?.arrival_date &&
            Number(rowData?.shipment_stops?.[0]?.equipment_action) === 2 &&
            rowData.equipment?.hooked_to;

          const shouldConfirm =
            rowData.status?.id === 1 && rowData.dispatch_type === 'external_carrier' && !rowData.checkInConfirmed;
          if (shouldConfirm) {
            setOpenConfirmCarrierCheckin({ item, rowData });
            return;
          }

          if (dropTrailerRequired && !rowData.brokerage_dispatch?.carrier) {
            setRowToCheckin(rowData);
            setOpenRestrictedCheckIn(true);
            return;
          }

          setTableShipmentModalObj({ item, rowData });
          break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 11:
        case 12:
        case 13:
        case 14:
        case 97:
        case 43:
        case 61:
          setTableShipmentModalObj({ item, rowData });
          break;
        case 19:
          // onRevertDots(rowData.shipment_id);
          setTableShipmentModalObj({ item, rowData: [rowData] });
          break;
        case 125:
        case 17:
          setTableShipmentModalObj({ item, rowData: [rowData] });
          break;
        case 44:
          setRequestTrackingData(rowData);
          break;
        case 45:
          setStopTrackingData(rowData);
          break;
        case 23:
          revertDispatch(rowData);
          break;
        default:
      }
    }
  }

  function tableRowCheckboxChange(value, arr) {
    if (value === 'all') {
      const cond = rowCheckBoxes.includes('all');
      const all = cond ? [] : ['all'];

      !cond &&
        arr.data.forEach((row) => {
          all.push(row?.shipment_id);
        });
      setRowCheckBoxes(all);
    } else {
      let newRowData;
      const activeTr = document.querySelector(`td[value='${value}']`)?.parentElement;
      if (!rowCheckBoxes.includes(value)) {
        if (activeTr) activeTr.style.background = palette.indigo0;
        newRowData = [...rowCheckBoxes, value];
        const allChecked = arr.data.every((el) => newRowData.includes(el?.id));
        !!allChecked && newRowData.push('all');
        setRowCheckBoxes(newRowData);
      } else {
        if (activeTr) activeTr.style.background = null;
        newRowData = rowCheckBoxes.filter((id) => id !== value && id !== 'all');
        setRowCheckBoxes(newRowData);
      }
    }
  }

  const fetchRecentActivity = () => {
    getRecentActivity({ page, itemsPerPage: 25 })
      .then((res) => {
        if (res && res?.data) {
          dispatch(updateRecentActivity([...recentActivity, ...(res?.data || [])]));
        }
        setPage((prevState) => ++prevState);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getShipmentTabs = useCallback(({ type }) => {
    return getTabs({ type })
      .then((res) => {
        if (res && res?.data) {
          const newData = res.data.map((el) => {
            return {
              ...el,
              label: el?.data?.name,
              key: uuid(),
              value: uuid(),
            };
          });
          const filterTopTable = [
            {
              key: 'all',
              label: 'All',
              value: 1,
              id: ALL_TAB_FILTER_ID,
              count: 0,
            },
            ...newData,
          ];
          return filterTopTable;
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterByGroups = useCallback(() => {
    getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterStopPointData = useCallback(() => {
    customerStopPoint()
      .then((res) => {
        if (res && res?.data) {
          const stopPoints = res.data.map((el) => ({
            id: el.id,
            key: el.id,
            label: el?.location_name,
          }));
          setStopPoints([...stopPoints]);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterCustomerData = useCallback(() => {
    getBillToBillingCharges()
      .then((res) => {
        const newCustomers = res.data.map((el) => ({
          id: el.id,
          title: el.company_name,
          label: el.company_name,
          key: el.id,
        }));
        setCustomers(newCustomers);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterTimeLine = useCallback(() => {
    getTimeFrame()
      .then((res) => {
        const newTimeLine = [];
        for (const [key, value] of Object.entries(res?.data)) {
          const newObj = { [key]: value, id: key, key, label: value, labelSelected: null };
          newTimeLine.push(newObj);
        }
        setTimeLine(newTimeLine);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterEquipmentData = useCallback(() => {
    getVehicleData()
      .then((res) => {
        if (res && res?.data) {
          const newEquipments = res.data.map((el) => {
            const label = `${el.equipment_type?.title} ${el.equipment_id} ${
              el.drivers ? `(${el.drivers?.map((item) => `${item?.fname} ${item?.lname} `) || ''})` : ''
            }`;
            return {
              id: el.id,
              title: label,
              label,
              searchValue: label,
              key: el.id,
            };
          });
          setEquipmentData(newEquipments);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterDriverData = useCallback(() => {
    getDrivers()
      .then((res) => {
        if (res && res?.data) {
          const newEquipments = res.data.map((el) => {
            const label = `${el.fname} ${el.lname}`;
            return {
              id: el.id,
              title: label,
              label,
              searchValue: label,
              key: el.id,
            };
          });
          setDriverData(newEquipments);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterCarrierData = useCallback(() => {
    getCarriers()
      .then((res) => {
        if (res && res?.data) {
          const newEquipments = res.data.map((el) => {
            const label = `${el.name}`;
            return {
              id: el.id,
              title: label,
              label,
              searchValue: label,
              key: el.id,
            };
          });
          setCarrierData(newEquipments);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];

    await Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicle, trailers]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 1) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
        if (trailers && trailers?.data) {
          trailers.data.forEach((el) => {
            const filterByData = {
              title: el.title,
              id: el.id,
              value: 'equipment_types',
              type: 'checkbox',
              key: uuid(),
              typeData: 'trailers',
            };
            filterByEquipmentData.push(filterByData);
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);

  function extractDates(inputStr) {
    const regex = /(\d{2}\/\d{2}\/\d{4}) - (\d{2}\/\d{2}\/\d{4})/;
    const regexStartDate = /(\d{2}\/\d{2}\/\d{4})/;
    const match = inputStr.match(regex);
    const matchStartDate = inputStr.match(regexStartDate);
    if (match) {
      return {
        startDate: match[1],
        endDate: match[2],
      };
    }
    if (matchStartDate) {
      return {
        startDate: matchStartDate[1],
        endDate: matchStartDate[1],
      };
    }
    return null;
  }

  const onApplyFilterCheckboxes = useCallback(
    ({
      checkboxes,
      status,
      groups,
      motions,
      customers,
      vehicles,
      trailers,
      drivers,
      createdBy,
      stop_points,
      equipment_types,
      shipment_id,
      reference_id,
      carrier_ids,
      bill_of_lading_id,
      valuePickupDate,
      valueDeliveryDate,
      origin,
      destination,
    }) => {
      const TYPES = ['status', 'groups', 'equipment_types', 'motions'];
      const {
        status: statusArr,
        groups: groupsArr,
        motions: motionsArr,
        equipment_types: equipment_types_Arr,
        customer,
        vehicle,
        trailer,
        driver,
        created_by,
        stop_point,
        shipment_id: shipmentIds,
        reference_id: referenceIds,
        bill_of_lading_id: billOfLadingId,
        is_edi,
      } = checkboxes;

      const { origin_city, origin_state, origin_zipcode, origin_miles } = origin || {};
      const { destination_city, destination_state, destination_zipcode, destination_miles } = destination || {};

      const statusParent = { id: statusArr?.[0]?.id, value: TYPES[0], searchValue: statusArr, title: 'Status' };
      const groupsParent = { id: groupsArr?.[0]?.id, value: TYPES[1], searchValue: groupsArr, title: 'Tags' };
      const motionsParent = { id: motionsArr?.[0]?.id, value: TYPES[3], searchValue: motionsArr, title: 'Motions' };
      const customerParent = { id: customer?.[0]?.id, value: TYPES[1], searchValue: customer, title: 'Customer' };
      const vehicleParent = { id: vehicle?.[0]?.id, value: TYPES[1], searchValue: vehicle, title: 'Vehicle' };
      const trailerParent = { id: trailer?.[0]?.id, value: TYPES[1], searchValue: trailer, title: 'Trailer' };
      const driverParent = { id: driver?.[0]?.id, value: TYPES[1], searchValue: driver, title: 'Driver' };
      const createdByParent = {
        id: created_by?.[0]?.id,
        value: TYPES[1],
        searchValue: created_by,
        title: 'Created By',
      };
      const shipmentIdsParent = {
        id: shipmentIds?.[0],
        value: TYPES[1],
        searchValue: shipmentIds,
        title: 'Shipment ID',
      };
      const billOfLadingIdParent = {
        id: billOfLadingId?.[0],
        value: TYPES[1],
        searchValue: billOfLadingId,
        title: 'Bill of Lading ID',
      };
      const referenceIdsParent = {
        id: referenceIds?.[0],
        value: TYPES[1],
        searchValue: referenceIds,
        title: 'Reference ID',
      };
      const stop_pointParent = {
        id: stop_point?.[0]?.id,
        value: TYPES[1],
        searchValue: stop_point,
        title: 'Stop Point',
      };
      const equipmentParent = {
        id: equipment_types_Arr?.[0]?.id,
        value: TYPES[2],
        searchValue: equipment_types_Arr,
        title: 'Equipment types',
      };

      const selectedValues = [...statusArr, ...groupsArr, ...equipment_types_Arr, ...motionsArr]; // filter.selectedValues[state]

      // data in backEnd
      const allFilterData = {
        ...getShipmentFilterData,
        status,
        groups,
        motion: motions,
        equipment_types,
        customers,
        stop_point: stop_points,
        shipment_id: shipment_id?.[0],
        reference_id: reference_id?.[0],
        bill_of_lading_id: bill_of_lading_id?.[0],
        status_ids: status,
        equipment: vehicles,
        trailer_id: trailers,
        driver_id: drivers,
        created_by: createdBy,
        carrier_ids,
        is_edi: is_edi ? 1 : undefined,
        ...activeTabData?.data?.filters,
      };

      let originParent;
      let searchValue = '';
      if (origin_city && origin_state) {
        searchValue = `${origin_city}, ${origin_state}`;
        allFilterData.origin_city = origin_city;
        allFilterData.origin_state = origin_state;
      } else if (origin_zipcode) {
        searchValue = `${origin_zipcode}`;
        allFilterData.origin_zipcode = origin_zipcode;
      }
      if (origin_zipcode && origin_city && origin_state) {
        searchValue += `, ${origin_zipcode}`;
      }
      if (origin_miles && ((origin_state && origin_city) || origin_zipcode)) {
        allFilterData.origin_miles = origin_miles;
        searchValue += ` (${origin_miles} miles)`;
      }
      if (searchValue) {
        originParent = {
          id: 'origin ',
          value: TYPES[1],
          searchValue,
          title: 'Origin',
        };
      }

      let destinationParent;
      let destinationSearchValue = '';
      if (destination_city && destination_state) {
        destinationSearchValue = `${destination_city}, ${destination_state}`;
        allFilterData.destination_city = destination_city;
        allFilterData.destination_state = destination_state;
      } else if (destination_zipcode) {
        destinationSearchValue = `${destination_zipcode}`;
        allFilterData.destination_zipcode = destination_zipcode;
      }
      if (destination_zipcode && destination_city && destination_state) {
        destinationSearchValue += `, ${destination_zipcode}`;
      }
      if (destination_miles && ((destination_state && destination_city) || destination_zipcode)) {
        allFilterData.destination_miles = destination_miles;
        destinationSearchValue += ` (${destination_miles} miles)`;
      }
      if (destinationSearchValue) {
        destinationParent = {
          id: 'destination ',
          value: TYPES[1],
          searchValue: destinationSearchValue,
          title: 'Destination',
        };
      }

      let pickupDateRangeParent;
      if (valuePickupDate) {
        pickupDateRangeParent = {
          id: 'pickup',
          value: TYPES[1],
          searchValue: valuePickupDate,
          title: 'Pickup Date Range',
        };
        const dates = extractDates(valuePickupDate);
        allFilterData.origin_scheduled_from = dates.startDate;
        allFilterData.origin_scheduled_to = dates.endDate;
      }

      let deliveryDateParent;
      if (valueDeliveryDate) {
        deliveryDateParent = {
          id: 'pickup',
          value: TYPES[1],
          searchValue: valueDeliveryDate,
          title: 'Delivery Date Range',
        };
        const dates = extractDates(valueDeliveryDate);
        allFilterData.destination_scheduled_from = dates.startDate;
        allFilterData.destination_scheduled_to = dates.endDate;
      }

      // show data in user
      const showData = [...showHeaderFilters];
      const filtered = showData.filter((option) => !TYPES.includes(option?.value));

      const newShowData = [...filtered];
      statusParent?.id && newShowData.push(statusParent);
      groupsParent?.id && newShowData.push(groupsParent);
      motionsParent?.id && newShowData.push(motionsParent);
      equipmentParent?.id && newShowData.push(equipmentParent);
      customerParent?.id && newShowData.push(customerParent);
      vehicleParent?.id && newShowData.push(vehicleParent);
      trailerParent?.id && newShowData.push(trailerParent);
      driverParent?.id && newShowData.push(driverParent);
      createdByParent?.id && newShowData.push(createdByParent);
      stop_pointParent?.id && newShowData.push(stop_pointParent);
      shipmentIdsParent?.id && newShowData.push(shipmentIdsParent);
      referenceIdsParent?.id && newShowData.push(referenceIdsParent);
      billOfLadingIdParent?.id && newShowData.push(billOfLadingIdParent);
      pickupDateRangeParent && newShowData.push(pickupDateRangeParent);
      deliveryDateParent && newShowData.push(deliveryDateParent);
      originParent?.id && newShowData.push(originParent);
      destinationParent?.id && newShowData.push(destinationParent);

      setShowHeaderFilters(newShowData);
      updateFilter({ ...filter, selectedValues });
      setGetShipmentFilterData({ ...getShipmentFilterData, status, groups, motions, equipment_types });

      getShipment({ allFilterData });
    },
    [activeTabData, updateFilter, getShipment, showHeaderFilters, getShipmentFilterData, filter]
  );

  const onChangeMultiselectSearchBy = useCallback(
    (data) => {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'shipment_id' && !values.includes(data.value)) updateData.allValue = '';
      if (data.value === 'reference_id' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
    },
    [showHeaderFilters, filter, updateFilter]
  );

  const stopCellularTracking = async () => {
    const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
    const allFilterData = { ...getShipmentFilterData, ...tabData };
    try {
      const body = {
        customer_dot: user?.customer?.dot,
        carrier_id: stopTrackingData?.brokerage_dispatch?.carrier_id,
        shipment_id: stopTrackingData?.shipment_id,
        status: 'stopped',
      };
      await updateCellularTracking(body);
      setStopTrackingData(null);
      showToaster({ type: 'success', message: 'Tracking has been stopped!' });
      getShipment({ allFilterData });
    } catch (e) {
      showToaster({ type: 'success', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    getShipment({ page: 1, itemsPerPage: rowPage, allFilterData });
  };

  const onPageChange = (page) => {
    const lastPage = shipmentsData.last_page;
    const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
    const allFilterData = { ...getShipmentFilterData, ...tabData };

    if (lastPage !== 1) {
      getShipment({ page, itemsPerPage: rowPerPage, allFilterData });
    }
  };

  function updateFilter(updatedFilter) {
    setFilter(updatedFilter);
  }

  const onClickModalButtons = (isVisible, item, typeClose) => {
    item !== null && onChangeTab(item, typeClose);
    setShowModal(isVisible);
  };

  const implementCountTotal = useCallback(
    (total, activeData, reset, modalTypeData) => {
      const type = modalTypeData?.type;
      const modalUpdatedData = modalTypeData?.data;
      const selectedData = activeData || activeTabData;

      if (selectedData) {
        const filterTableTop = filter.filterTableTop.map((el) => {
          if (+el.id === Number(selectedData?.id) || (selectedData.key === 'all' && +el.id === +selectedData.id)) {
            return {
              ...el,
              count: total,
            };
          }
          return el;
        });

        // reset
        if (reset) {
          setFilter((prevState) => {
            return { ...prevState, ...reset, filterTableTop: [...filterTableTop] };
          });
          setShowHeaderFilters([]);
        } else {
          // modal add and delete
          if (type === 'add' || type === 'delete' || type === 'update') {
            if (type === 'add') {
              const addedData = {
                ...modalUpdatedData,
                count: total,
                label: modalUpdatedData.data.name,
                key: uuid(),
                value: uuid(),
              };
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...prevState.filterTableTop, { ...addedData }],
                };
              });
            } else if (type === 'delete') {
              const id = modalUpdatedData;
              const newFilteredData = filter.filterTableTop.filter((el) => el.id !== id);
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newFilteredData],
                };
              });
            } else if (type === 'update') {
              const id = modalUpdatedData?.id;
              const newUpdatedData = filter.filterTableTop.map((el) => {
                if (el.id === id) {
                  const updateData = {
                    ...modalUpdatedData,
                    count: total,
                    label: modalUpdatedData.data.name,
                    key: uuid(),
                    value: uuid(),
                  };
                  return updateData;
                }
                return el;
              });
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newUpdatedData],
                };
              });
            }
          } else if (filterTableTop?.length > 0) {
            setFilter((prevState) => {
              return { ...prevState, filterTableTop: [...filterTableTop] };
            });
          }
        }
      }
    },
    [activeTabData, filter, updateFilter]
  );

  useEffect(() => {
    if (hasRun) return;
    if (location?.state?.tab && filter.filterTableTop?.length) {
      const stateTab = filter.filterTableTop.find((item) => item.label === location.state.tab);
      if (stateTab) {
        onChangeTab(stateTab, {});
        setHasRun(true);
      }
    }
  }, [location?.state?.tab, filter.filterTableTop, hasRun]);

  const getShipmentsTotalData = async (params) => {
    try {
      if (shipmentTotalsController.current) {
        shipmentTotalsController.current.abort();
        shipmentTotalsController.current = new AbortController();
      }

      const { data } = await getShipmentsTotal({ ...params, signal: shipmentTotalsController.current.signal });
      setShipmentsTotal(data);
    } catch (e) {
      // Do nothing
    }
  };

  function getShipment(data) {
    controller && controller.abort();
    controller = new AbortController();

    const {
      page = 1,
      reset,
      filtersType,
      sort,
      filtersValue,
      itemsPerPage,
      remainingObj,
      allFilterData,
      getData = false,
      implementActiveData,
      modalTypeData,
      hasALoading = true,
    } = data || {};

    hasALoading && setLoading(true);

    const fetchParams = {
      signal: controller.signal,
      page,
      itemsPerPage: itemsPerPage || rowPerPage,
      sort,
      remainingData: remainingObj
        ? getData
          ? { ...remainingObj, ...getShipmentFilterData }
          : remainingObj
        : getShipmentFilterData,
    };

    if (allFilterData) {
      fetchParams.allFilterData = allFilterData;
    } else {
      fetchParams.filtersType = filtersType;
      fetchParams.filtersValue = filtersValue;
    }

    getShipmentsTotalData(fetchParams);
    return Shipment(fetchParams)
      .then((res) => {
        setShipmentsData(res);
        bindPusher(res);
        implementCountTotal(res.total, implementActiveData, reset, modalTypeData);
        return res;
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => setLoading(false));
  }

  const mapperSettingsData = useCallback((key) => {
    const map = {
      shipment_id: 'Shipment ID',
      customer: 'Customer',
      assigned_to: 'Driver',
      equipment: 'Equipment',
      origin_stop_name: 'Origin',
      status: 'Status',
      groups: 'Tags',
      current_location: 'Current Location',
      next_stop: 'Next Stop',
      total_eta: 'Total ETA',
      rpm: 'RPM',
      amount: 'Amount',
      hours_remaining: 'Hours of Service',
      billing_of_lading: 'Bill of Lading',
      previous_stop: 'Previous Stop',
      commodity: 'Commodity',
      next_equipment_action: 'Type',
      empty_miles: 'Empty Miles',
      loaded_miles: 'Loaded Miles',
      deliver_earliest: 'Deliver Earliest',
      deliver_latest: 'Deliver Latest',
      destination_stop_name: 'Destination',
      speed: 'Telematics',
      planned: 'Planned',
      motion: 'Motion',
      weight: 'Weight',
    };
    return map[key] ? map[key] : key;
  }, []);

  const getSettings = useCallback(() => {
    getShipmentPopoverSettings({ type: 'shipment' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
          setShipmentSettingsProgressBar(!!Number(allData?.shipment_progress_bar));
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [mapperSettingsData]);

  useEffect(async () => {
    try {
      getSettings();
      setLoading(true);
      const widths = await getColumnsWidth('shipment');
      if (widths.data.length) {
        const updatedWidthsFromBackend = widths.data.reduce((acc, curr) => {
          acc[curr.column_type] = curr.width;
          return acc;
        }, {});
        setColumnWidths({
          ...DEFAULTWIDTHOFCOLUMNS,
          ...updatedWidthsFromBackend,
        });
      }

      const shipmentIds = searchParams.getAll('id');
      const defaultTab = searchParams.get('defaultTab');
      const params = {
        page: 1,
        itemsPerPage: rowPerPage,
      };

      if (shipmentIds?.length) {
        params.allFilterData = { shipment_ids: shipmentIds };
        searchParams.delete('id');
        setSearchParams(searchParams);
      }

      if (defaultTab) {
        const tabsData = await getShipmentTabs({ type: 'shipment' });
        const tabData = tabsData.find((i) => i.data?.name === defaultTab);

        if (tabData && tabData.data?.filters) {
          setActiveTabData(tabData);
          params.allFilterData = { ...(params.allFilterData || {}), ...(tabData.data?.filters || {}) };
        }
        searchParams.delete('defaultTab');
        setSearchParams(searchParams);
      }

      getShipmentsTotalData(params);
      Shipment(params)
        .then((res) => {
          setShipmentsData(res);
          bindPusher(res);
          if (filter.filterTableTop.length === 0) {
            getShipmentTabs({ type: 'shipment' }).then((tabs) => {
              const newFilterTabsData = tabs?.map((el) => {
                if (el.key === 'all') {
                  return {
                    ...el,
                    count: res.total,
                  };
                }
                return el;
              });
              setFilter({ ...filter, filterTableTop: newFilterTabsData });
            });
          }
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => setLoading(false));
    } catch (e) {
      // Do nothing
    }
  }, []);

  const bindPusher = (shipmentsData) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const channelShipmentProgress = `private-shipmentData-update.${dot}`;

    PusherJs.unsubscribe(channelShipmentProgress);
    const channelShipmentProgress_table = PusherJs.subscribe(channelShipmentProgress);

    channelShipmentProgress_table?.bind('shipment-update', (e) => {
      if (e?.message?.data?.id) {
        const id = e?.message?.data?.id;
        const founded = shipmentsData.data.find((el) => el?.shipment_id === id);
        if (founded) {
          getShipmentInfo(id)
            .then((res) => {
              setShipmentsData((prev) => {
                const newData = res?.data;
                const updatedData = prev?.data?.map((data) => {
                  if (data.shipment_id === id) {
                    return newData;
                  }
                  return data;
                });
                return {
                  ...prev,
                  data: updatedData,
                };
              });
              animationRowData(id);
            })
            .catch(() => {
              // Do nothing
            });
        }
      }
    });
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [columns[0]];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    cols.push(columns[columns.length - 1]);
    return cols;
  };

  function sortingQuery(field, nested_field) {
    controller && controller.abort();
    controller = new AbortController();
    let sortField;
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]:${direction}`;
    } else {
      sortField = `sort[][${field}]:${direction}`;
    }

    setSort({ field, sortBy: direction });
    getShipment({ sort: [sortField], hasALoading: false });
  }

  const animationRowData = useCallback(
    (id) => {
      // For animation
      setAnimationList({ ...animationList, [id]: true });
      setTimeout(() => {
        setAnimationList({ ...animationList, [id]: false });
      }, 1500);
      // For animation
    },
    [animationList]
  );

  const onEditTableTab = useCallback((item) => {
    const { data, user_id, id, system } = item;
    const { name, filters } = data;
    const { status, groups, equipment_types, customers, stop_point, is_edi, is_brokered, time_frame, zipcode } =
      filters;
    const initialValue = {
      name,
      status,
      groups,
      equipment_types,
      customers,
      stop_point,
      user_id,
      id,
      system,
      is_edi,
      is_brokered,
      time_frame,
      zipcode,
    };
    setTabModalEditData(initialValue);
    setShowModal(true);
  }, []);

  const onChangeTabHelper = useCallback(
    (disabledFiltersData) => {
      const { customers, groups, status, equipment_types, motions, stop_point } = disabledFiltersData;
      const showedFiltersArrData = showHeaderFilters.filter((el) => {
        const value = el?.value;
        if (value) {
          return disabledFilters.hasOwnProperty(value);
        }
        if (Array.isArray(el) && el?.[0].type === 'stop_point') {
          return true;
        }
        return false;
      });
      const newShowHeaderFilters = showHeaderFilters.filter(
        (el) =>
          (!Array.isArray(el) && !disabledFiltersData[el.value]) ||
          (Array.isArray(el) && el?.[0].type === 'stop_point' && !stop_point)
      );

      setShowHeaderFilters(newShowHeaderFilters);

      const newSelectedValues = filter.selectedValues.filter((el) => !disabledFiltersData[el?.value]);
      const clone = { ...getShipmentFilterData };

      Object.keys(disabledFiltersData).forEach((key) => {
        const value = disabledFiltersData[key];
        if (value) {
          if (key === 'stop_point') {
            delete clone[key];
            delete clone.stop_point_scheduled_from;
            delete clone.stop_point_scheduled_to;
            setDateRangeStopPoint([null, null]);
          } else {
            delete clone[key];
          }
        }
      });

      setGetShipmentFilterData(clone);
      setFilter((prevState) => {
        return {
          ...prevState,
          selectedValues: newSelectedValues,
          customerSelectData: !customers ? prevState.customerSelectData : [],
          stopPointAll: !stop_point ? prevState.stopPointAll : LOCATION_INITIAL_VALUE_STOP_POINT,
        };
      });

      for (let i = 0; i < Object.keys(disabledFiltersData).length; i++) {
        const key = Object.keys(disabledFiltersData)[i];
        const value = Object.values(disabledFiltersData)[i];
        const foundedSelectData = showedFiltersArrData.find(
          (el) => (!Array.isArray(el) && el.value === key) || (Array.isArray(el) && el?.[0].type === 'stop_point')
        );
        if (value && foundedSelectData) {
          setFilter((prevState) => {
            return {
              ...prevState,
              searchBy: { id: 1, title: 'Shipment ID', value: 'shipment_id', key: 9999 },
            };
          });
        }
      }
      const newLocalData = {
        groups: groups ? [] : localFilterCheckboxes.groups,
        status: status ? [] : localFilterCheckboxes.status,
        equipment_types: equipment_types ? [] : localFilterCheckboxes.equipment_types,
        motions: motions ? [] : localFilterCheckboxes.motions,
      };
      setLocalFilterCheckboxes(newLocalData);
      !!groups && setGroups([]);
      !!status && setStatuses([]);
      !!equipment_types && setEquipments([]);
      !!motions && setEquipments([]);
    },
    [showHeaderFilters, disabledFilters, filter]
  );

  const onChangeTab = useCallback(
    (item, modalTypeData) => {
      const allTabData = item?.data?.filters;
      const disabledFiltersData = {};
      const remainingObj = {};
      let allFilterData;

      const activeTabData = { ...item, data: { ...item.data, filters: {} } };
      if (item.key !== 'all') {
        Object.keys(allTabData).forEach((key) => {
          const value = allTabData[key];
          const conditionCloudSwitch =
            (key === 'is_edi' && !!value) || (key === 'is_brokered' && !!value) || (key === 'time_frame' && !!value);
          if ((value.length && value.length > 0) || conditionCloudSwitch) {
            activeTabData.data.filters[key] = value;
          }
        });
      }

      if (allTabData) {
        const clonegetShipmentFilterData = { ...getShipmentFilterData };
        Object.keys(allTabData).forEach((key) => {
          const value = allTabData[key];
          if (Array.isArray(value) && value.length > 0) {
            disabledFiltersData[key] = true;
            remainingObj[key] = value;
          } else if (typeof value === 'object' && Object.keys(value).length > 0) {
            disabledFiltersData[key] = true;
            remainingObj[key] = value?.key;
            delete clonegetShipmentFilterData.stop_point_scheduled_from;
            delete clonegetShipmentFilterData.stop_point_scheduled_to;
          } else if (typeof value === 'boolean' && !!value) {
            remainingObj[key] = value;
          }
        });
        allFilterData = { ...clonegetShipmentFilterData, ...remainingObj };
      } else {
        if (item.key === 'all') {
          allFilterData = { ...getShipmentFilterData };
        } else if (item) {
          allFilterData = { ...getShipmentFilterData };
        }
      }
      setActiveTabData(activeTabData);
      setDisabledFilters({ ...DEFAULT_DISABLED_TABS, ...disabledFiltersData });
      onChangeTabHelper({ ...DEFAULT_DISABLED_TABS, ...disabledFiltersData });
      getShipment({ allFilterData, implementActiveData: item, modalTypeData });
    },
    [getShipmentFilterData, onChangeTabHelper, getShipment, showHeaderFilters]
  );

  const onRowClick = (e, value) => {
    if (!rowCheckBoxes?.length) {
      const shipmentId = value?.shipment_id;
      if (Number(value.status_id) === 8) {
        navigate(`/planner/pending-dispatch?id=${shipmentId}`);
        return;
      }

      navigate(`/shipment/${shipmentId}`, { state: value });
    }
  };

  useEffect(() => {
    if (highlightedRow) {
      setTimeout(() => {
        setHighlightedRow(null);
      }, 3000);
    }
  }, [highlightedRow]);

  function onSubmitShipmentRowEditChildes(data) {
    switch (data.lastData.item.id) {
      case 7:
        UpdateShare(data.lastData.rowData.shipment_id, data.res.customer || undefined)
          .then((result) => {
            showToaster({ type: 'success', message: result.data?.message || 'Success!' });
            getShipment();
          })
          .catch((err) => {
            if (err.response) {
              showToaster({ type: 'error', message: err.response.data.error });
            }
          });
        break;
      case 19:
        navigate('/planner', { state: { allValue: data.lastData.rowData[0].shipment_id } });
        break;
      case 43:
        if (data?.lastData?.rowData?.shipment_id) {
          getShipment({ allFilterData: { shipment_ids: [data.lastData.rowData.shipment_id] } });
        }
        break;
      case 61:
        getShipment({ allFilterData: { ...getShipmentFilterData, ...activeTabData?.data?.filters } });
        break;
      case 17:
      case 125:
        setActiveTabData({
          key: 'all',
          label: 'All',
          value: 1,
          id: ALL_TAB_FILTER_ID,
          count: 0,
        });
        getShipment();
        break;
      case 97:
        getShipment();
        break;
      case 11:
        const queryParams = new URLSearchParams();
        data?.res?.forEach((shipmentId) => {
          queryParams.append('id', shipmentId);
        });
        navigate(`/planner?${queryParams.toString()}`);
        break;
      case 13:
        navigate(`/shipment/${data.lastData?.rowData?.shipment_id}`, { state: { defaultTab: 5 } });
        break;
      case 8:
        navigate('/planner');
        break;
      case 14:
      case 6:
      case 5:
      case 4:
        getShipment();
        break;
      case 2:
        // if (shipmentsData && shipmentsData.data && shipmentsData.data.length && data?.res?.data?.shipment_id) {
        //   const updatedVehicleData = shipmentsData.data.map((item) => {
        //     if (item.shipment_id === data?.res?.data?.shipment_id) {
        //       return {
        //         ...data?.res?.data,
        //       };
        //     }
        //     return item;
        //   });
        //   setShipmentsData((prevState) => ({
        //     ...prevState,
        //     data: updatedVehicleData,
        //   }));
        // }
        if (data?.res?.data?.shipment_id || data.lastData?.rowData?.shipment_id) {
          getShipment({
            allFilterData: { shipment_ids: [data?.res?.data?.shipment_id || data.lastData?.rowData?.shipment_id] },
          });
        }
        // setHighlightedRow(data?.res?.data?.shipment_id);
        break;
      default:
    }
  }

  /** Filter * */
  function onDeleteManyDataItem(item, parent_value) {
    if (item === 'all') {
      const deletedData = showHeaderFilters.filter((el) => el.value !== item);
      const filterType = showHeaderFilters?.find((el) => el.value === item)?.value;
      const remainingObj = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      delete remainingObj[filterType];
      updateFilter({ ...filter, allValue: '' });
      setGetShipmentFilterData({ ...remainingObj });
      setShowHeaderFilters(deletedData);
      getShipment({ filtersType: '', filtersValue: '', reset: false, remainingObj });
      return;
    }

    if (parent_value === 'equipment') {
      const cloneShowData = [...showHeaderFilters];
      const parentIndex = cloneShowData.findIndex((el) => el?.value === parent_value);
      const newShowData = cloneShowData[parentIndex].searchValue.filter((el) => Number(el?.id) !== +item.id);
      cloneShowData[parentIndex].searchValue = newShowData;
      if (newShowData.length === 0) {
        delete cloneShowData[parentIndex];
      }
      setShowHeaderFilters([...cloneShowData.filter((el) => el !== undefined)]);

      const filteredEquipments = filter.equipmentSelectedData.filter((el) => +el.id !== +item.id);

      const arrIds = filteredEquipments.map((el) => el?.id);
      const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      allFilterData.equipment = arrIds;

      setFilter({ ...filter, equipmentSelectedData: filteredEquipments });
      setGetShipmentFilterData({ ...getShipmentFilterData, equipment: arrIds });
      getShipment({ allFilterData });
      return;
    }

    const mapStates = {
      status: { state: statuses, setState: setStatuses },
      groups: { state: groups, setState: setGroups },
      equipment_types: { state: equipments, setState: setEquipments },
      motions: { state: motions, setState: setMotions },
    };

    const { id, value } = item || {};
    const parentIndex = showHeaderFilters.findIndex((el) => el.value === parent_value);
    const parent = showHeaderFilters[parentIndex];

    if (parent) {
      const newFilterData = parent.searchValue.filter((el) => +el.id !== +id);
      const empty = newFilterData.length === 0;
      let allFilterNewData;

      if (empty) {
        allFilterNewData = showHeaderFilters.filter((el) => el.value !== parent_value);
      } else {
        const showHeaderFiltersData = [...showHeaderFilters];
        showHeaderFiltersData[parentIndex] = {
          ...showHeaderFiltersData[parentIndex],
          searchValue: newFilterData,
        };
        allFilterNewData = showHeaderFiltersData;
      }
      let activeOption;
      const clonePlannerFilterData = { ...getShipmentFilterData };

      if (parent?.value !== 'customers') {
        activeOption = filter.selectedValues.filter((el) => +el.id !== +id);

        const foundedOptionIndex = filter.selectedValues.findIndex((el) => +el.id === +id);
        const foundedOption = filter.selectedValues[foundedOptionIndex];

        const { value: type, id: foundedId } = foundedOption || {};
        const { state, setState } = mapStates[type];
        const newState = state.filter((elId) => +elId !== +foundedId);
        setState(newState);
        setLocalFilterCheckboxes((prevState) => {
          return {
            ...prevState,
            [type]: activeOption,
          };
        });
        setFilter((prevState) => ({ ...prevState, selectedValues: activeOption }));
      } else {
        activeOption = filter.customerSelectData.filter((el) => +el.id !== +id);
        setFilter((prevState) => ({ ...prevState, customerSelectData: activeOption }));
      }

      const selectedValues = activeOption?.filter((el) => el.value === value);
      const arrTypeIds = selectedValues.map((el) => el.id);
      clonePlannerFilterData[parent.value] = arrTypeIds;

      const allFilterData = { ...clonePlannerFilterData, ...activeTabData?.data?.filters };
      getShipment({ allFilterData })
        .then(() => {
          setGetShipmentFilterData(clonePlannerFilterData);
          setShowHeaderFilters(allFilterNewData);
        })
        .catch(() => {
          // Do nothing
        });
    }
  }

  /** All * */
  const debounceAll = useMemo(() => {
    return debounce((value, type) => {
      const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      const showData = [...showHeaderFilters];
      const indexAll = showData.findIndex((el) => Number(el?.key) === 9999 || Number(el?.key) === 8888);

      const showAllObj =
        type === 'reference_id'
          ? { id: 2, key: 8888, searchValue: value, title: 'Reference ID', value: 'reference_id' }
          : { id: 1, key: 9999, searchValue: value, title: 'Shipment ID', value: 'shipment_id' };
      indexAll !== -1 ? (showData[indexAll] = showAllObj) : showData.push(showAllObj);

      if (!value) {
        delete allFilterData.shipment_id;
        delete allFilterData.reference_id;
        setGetShipmentFilterData({ ...allFilterData });
        showData.splice(indexAll, 1);
      } else {
        if (type === 'reference_id') {
          allFilterData.reference_id = value;
          setGetShipmentFilterData({ ...getShipmentFilterData, reference_id: value, shipment_id: undefined });
        } else {
          allFilterData.shipment_id = value;
          setGetShipmentFilterData({ ...getShipmentFilterData, shipment_id: value, reference_id: undefined });
        }
      }

      setShowHeaderFilters([...showData]);

      if (controller) controller.abort();
      controller = new AbortController();
      getShipment({ allFilterData, signal: controller.signal });
    }, 350);
  }, [activeTabData, getShipmentFilterData, showHeaderFilters]);

  function onChangeAll(value) {
    const updateData = { ...filter };

    if (filter.searchBy.value === 'name') updateData.allValue = value;
    if (filter.searchBy.value === 'all') updateData.allValue = value;
    if (filter.searchBy.value === 'reference_id') updateData.allValue = value;
    if (filter.searchBy.value === 'shipment_id') updateData.allValue = value;
    setFilter(updateData);
    debounceAll(value, filter.searchBy.value);
  }

  /** Customers * */
  function onChangeCustomers(value) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    const customerSelectData = [...filter.customerSelectData];
    const foundedIndex = customerSelectData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? customerSelectData.splice(foundedIndex, 1) : customerSelectData.push(value);

    const idsCustomers = customerSelectData.map((el) => el?.id);
    allFilterData.customers = idsCustomers;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === +filter.searchBy.id);
    const showCustomerObj = { id: 333, key: uuid(), title: 'Customer', value: 'customers' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...customerSelectData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (customerSelectData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetShipmentFilterData({ ...getShipmentFilterData, customers: idsCustomers });
    setFilter({ ...filter, customerSelectData });
    getShipment({ allFilterData });
  }

  /** Stop-point-select-onChange * */
  function onChangeStopPointSelect(value) {
    const TYPE = 'stop_point';
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const foundIndexShow = showHeaderFilters.findIndex((el) => Array.isArray(el) && el[0].type === TYPE);

    if (foundIndexShow !== -1) {
      const stop_point_index = showHeaderFilters[foundIndexShow].findIndex((point) => point.value === TYPE);
      const cloneShowData = [...showHeaderFilters];
      stop_point_index === -1
        ? cloneShowData[foundIndexShow]?.push({ ...value, value: TYPE, type: TYPE, searchValue: value.label })
        : (cloneShowData[foundIndexShow][stop_point_index] = {
            ...value,
            value: TYPE,
            type: TYPE,
            searchValue: value.label,
          });
      setShowHeaderFilters(cloneShowData);
    } else {
      setShowHeaderFilters([
        ...showHeaderFilters,
        [
          {
            ...value,
            value: TYPE,
            type: TYPE,
            searchValue: value.label,
          },
        ],
      ]);
    }

    setGetShipmentFilterData({ ...getShipmentFilterData, stop_point: value?.id });
    allFilterData.stop_point = value?.id;
    getShipment({ allFilterData });
    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: {
          ...prev.stopPointAll,
          stop_point: { ...prev.stopPointAll.stop_point, ...value, searchValue: value.label },
        },
      };
    });
  }

  /** Stop-point-select-Delete * */
  function onDeleteStopPointSelect() {
    const TYPE = 'stop_point';
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const cloneShowData = [...showHeaderFilters];
    const foundIndexShow = cloneShowData.findIndex((el) => Array.isArray(el) && el[0].type === TYPE);
    if (foundIndexShow !== -1) {
      const stop_point_index = cloneShowData[foundIndexShow].findIndex((point) => point.value === TYPE);
      if (cloneShowData[foundIndexShow]?.length === 1) {
        cloneShowData.splice(foundIndexShow, 1);
      } else {
        cloneShowData[foundIndexShow].splice(stop_point_index, 1);
      }

      setShowHeaderFilters(cloneShowData);
    }

    const cloneGetPlanerFilterData = { ...getShipmentFilterData };
    delete cloneGetPlanerFilterData.stop_point;
    setGetShipmentFilterData({ ...cloneGetPlanerFilterData });

    delete allFilterData.stop_point;
    getShipment({ allFilterData });

    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: { ...prev.stopPointAll, stop_point: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point },
      };
    });
  }

  /** Stop-point-Dates * */
  function onChangeDatePickerStopPoint(dates) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const TYPE = 'stop_point';
    const first = dates[0];
    const last = dates[1];
    const STOP_POINT_FROM = 'stop_point_scheduled_from';
    const STOP_POINT_TO = 'stop_point_scheduled_to';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidLast = !!moment(last).isValid();

    const cloneGetPlanerData = { ...getShipmentFilterData };
    const showData = [...showHeaderFilters];

    const foundIndexShow = showData.findIndex((el) => Array.isArray(el) && el?.[0].type === TYPE);
    const from = {
      key: uuid(),
      searchValue: formatDateFirst,
      type: 'stop_point',
      value: 'stop_point_scheduled_from',
    };
    const to = { key: uuid(), searchValue: formatDateLast, type: 'stop_point', value: 'stop_point_scheduled_to' };

    const arrDates = [from];

    if (isValidLast) {
      arrDates.push(to);
      cloneGetPlanerData.stop_point_scheduled_to = formatDateLast;
      allFilterData.stop_point_scheduled_to = formatDateLast;
    } else {
      delete cloneGetPlanerData.stop_point_scheduled_to;
      delete allFilterData?.stop_point_scheduled_to;
    }

    if (foundIndexShow === -1) {
      showData.push(arrDates);
    } else {
      const foundSelectStopPoint = showData?.[foundIndexShow]?.find((el) => el?.value === TYPE);
      !!foundSelectStopPoint && arrDates.push(foundSelectStopPoint);
      showData[foundIndexShow] = arrDates;
    }

    cloneGetPlanerData.stop_point_scheduled_from = formatDateFirst;
    allFilterData.stop_point_scheduled_from = formatDateFirst;

    setGetShipmentFilterData(cloneGetPlanerData);
    setShowHeaderFilters(showData);
    setDateRangeStopPoint(dates);
    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: {
          ...prev.stopPointAll,
          [STOP_POINT_FROM]: { ...prev.stopPointAll[STOP_POINT_FROM], searchValue: formatDateFirst },
          [STOP_POINT_TO]: {
            ...prev.stopPointAll[STOP_POINT_TO],
            searchValue: isValidLast ? formatDateLast : '',
          },
        },
      };
    });
    getShipment({ allFilterData });
  }

  /** Stop-point-show-data-Delete * */
  function onDeleteStopPointItem(item) {
    const newShowData = [];
    const cloneGetShipmentData = { ...getShipmentFilterData };
    showHeaderFilters.forEach((el) => {
      if (Array.isArray(el) && el[0]?.type === 'stop_point') {
        let newStopPointArr;
        if (item?.value === 'stop_point') {
          newStopPointArr = el.filter((point) => point.value !== item.value);
          delete cloneGetShipmentData[item.value];
          setFilter((prevState) => {
            return {
              ...prevState,
              stopPointAll: {
                ...filter.stopPointAll,
                stop_point: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point,
              },
            };
          });
        } else {
          newStopPointArr = el.filter((point) => point?.value === 'stop_point');
          delete cloneGetShipmentData.stop_point_scheduled_from;
          delete cloneGetShipmentData.stop_point_scheduled_to;
          setDateRangeStopPoint([null, null]);
          setFilter((prevState) => {
            return {
              ...prevState,
              stopPointAll: {
                ...filter.stopPointAll,
                stop_point_scheduled_from: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point_scheduled_from,
                stop_point_scheduled_to: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point_scheduled_to,
              },
            };
          });
        }
        return newStopPointArr.length > 0 && newShowData.push(newStopPointArr);
      }
      newShowData.push(el);
    });
    setShowHeaderFilters(newShowData);
    setGetShipmentFilterData(cloneGetShipmentData);

    const allFilterData = { ...cloneGetShipmentData, ...activeTabData?.data?.filters };
    getShipment({ allFilterData });
  }

  /** Location-inputs * */
  const debounceLocation = useMemo(() => {
    return debounce((value, name) => {
      const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      const showData = [...showHeaderFilters];
      const index_show_location = showData.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === 'locations');
      const showLocationObj = {
        key: uuid(),
        searchValue: value,
        title: 'Location',
        valuePrefix: 'locations',
        value: name,
      };

      if (index_show_location === -1) {
        showData.push([showLocationObj]);
      } else {
        const index_loc_item = showData[index_show_location].findIndex((loc) => loc.value === name);
        if (!value) {
          showData[index_show_location].length === 1
            ? showData.splice(index_show_location, 1)
            : showData[index_show_location].splice(index_loc_item, 1);
        } else {
          index_loc_item === -1
            ? showData[index_show_location].push(showLocationObj)
            : (showData[index_show_location][index_loc_item] = showLocationObj);
        }
      }

      if (!value) {
        delete allFilterData[name];
        setGetShipmentFilterData({ ...allFilterData });
      } else {
        allFilterData[name] = value;
        setGetShipmentFilterData({ ...getShipmentFilterData, [name]: value });
      }

      setShowHeaderFilters([...showData]);

      if (controller) controller.abort();
      controller = new AbortController();
      getShipment({ allFilterData, signal: controller.signal });
    }, 350);
  }, [activeTabData, getShipmentFilterData, showHeaderFilters]);

  function onChangeLocationValues(name, e) {
    const value = { ...filter?.locations, [name]: { ...filter?.locations[name], searchValue: e.target.value } };
    setFilter({ ...filter, [filter.searchBy.value]: value });
    debounceLocation(e.target.value, name);
  }

  /** Location-inputs and date delete * */
  function onDeleteLocationsItems(item) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    if (item.value === 'locations') {
      delete allFilterData?.scheduled_from;
      delete allFilterData?.scheduled_to;
      setDateRangeLocation([null, null]);
    } else {
      delete allFilterData?.[item.value];
    }

    const locations = filter.locations;
    const newLocation = {};

    Object.keys(locations).forEach((key) => {
      const obj = locations[key];

      if (key === item.value) {
        if (item.value === 'locations') {
          newLocation.scheduled_from = { ...obj, value: 'scheduled_from', searchValue: '' };
          newLocation.scheduled_to = { ...obj, value: 'scheduled_to', searchValue: '' };
        } else {
          newLocation[key] = { ...obj, searchValue: '' };
        }
      } else {
        newLocation[key] = { ...obj };
      }
    });
    const newShowFilterData = [];
    showHeaderFilters.forEach((el) => {
      if (Array.isArray(el)) {
        const locData = el.filter((loc) => loc.value !== item.value);
        locData.length > 0 && newShowFilterData.push(locData);
      } else {
        newShowFilterData.push(el);
      }
    });

    getShipment({ allFilterData })
      .then(() => {
        if (item.value === 'locations') setDateRangeLocation([null, null]);
        setFilter((prev) => ({ ...prev, locations: newLocation }));
        setShowHeaderFilters(newShowFilterData);
        setGetShipmentFilterData(allFilterData);
      })
      .catch(() => {
        // Do nothing
      });
  }

  /** Location-date-picker * */
  function onChangeLocationDatePicker(dates) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const TYPE = 'locations';
    const first = dates[0];
    const last = dates[1];
    const SCHEDULED_FROM = 'scheduled_from';
    const SCHEDULED_TO = 'scheduled_to';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidLast = !!moment(last).isValid();

    const cloneGetPlanerData = { ...getShipmentFilterData };
    const showData = [...showHeaderFilters];

    const index_show_location = showData.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === TYPE);
    const showLocationObj = {
      key: uuid(),
      title: 'Location',
      value: TYPE,
      from: formatDateFirst,
      valuePrefix: TYPE,
      to: isValidLast ? formatDateLast : '',
      searchValue: `${formatDateFirst} ${isValidLast ? `- ${formatDateLast}` : ''}`,
    };

    if (index_show_location === -1) {
      showData.push([showLocationObj]);
    } else {
      const index_loc_item = showData[index_show_location].findIndex((loc) => loc.value === TYPE);
      index_loc_item === -1
        ? showData[index_show_location].push(showLocationObj)
        : (showData[index_show_location][index_loc_item] = showLocationObj);
    }

    if (isValidLast) {
      cloneGetPlanerData[SCHEDULED_TO] = formatDateLast;
      allFilterData[SCHEDULED_TO] = formatDateLast;
    } else {
      delete cloneGetPlanerData[SCHEDULED_TO];
      delete allFilterData?.[SCHEDULED_TO];
    }

    cloneGetPlanerData[SCHEDULED_FROM] = formatDateFirst;
    allFilterData[SCHEDULED_FROM] = formatDateFirst;

    setGetShipmentFilterData(cloneGetPlanerData);
    setShowHeaderFilters(showData);
    setDateRangeLocation(dates);
    setFilter((prev) => {
      return {
        ...prev,
        locations: {
          ...prev.locations,
          [SCHEDULED_FROM]: { ...filter?.locations[SCHEDULED_FROM], searchValue: formatDateFirst },
          [SCHEDULED_TO]: {
            ...filter?.locations[SCHEDULED_TO],
            searchValue: isValidLast ? formatDateLast : '',
          },
        },
      };
    });
    getShipment({ allFilterData });
  }

  function onChangeEquipment(value) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    const equipmentSelectedData = [...filter.equipmentSelectedData];
    const foundedIndex = equipmentSelectedData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? equipmentSelectedData.splice(foundedIndex, 1) : equipmentSelectedData.push(value);

    const idsEquipment = equipmentSelectedData.map((el) => el?.id);
    allFilterData.equipment = idsEquipment;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === Number(filter.searchBy.id));
    const showCustomerObj = { id: 666, key: uuid(), title: 'Vehicle ID', value: 'equipment' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...equipmentSelectedData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (equipmentSelectedData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetShipmentFilterData({ ...getShipmentFilterData, equipment: idsEquipment });
    setFilter({ ...filter, equipmentSelectedData });
    getShipment({ allFilterData });
  }

  function onChangeDriver(value) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    const driverSelectedData = [...filter.driverSelectedData];
    const foundedIndex = driverSelectedData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? driverSelectedData.splice(foundedIndex, 1) : driverSelectedData.push(value);

    const idsDriver = driverSelectedData.map((el) => el?.id);
    allFilterData.driver_id = idsDriver;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === Number(filter.searchBy.id));
    const showCustomerObj = { id: 777, key: uuid(), title: 'Driver', value: 'driver' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...driverSelectedData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (driverSelectedData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetShipmentFilterData({ ...getShipmentFilterData, driver_id: idsDriver });
    setFilter({ ...filter, driverSelectedData });
    getShipment({ allFilterData });
  }

  function onChangeCarrier(value) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    const carrierSelectedData = [...filter.carrierSelectedData];
    const foundedIndex = carrierSelectedData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? carrierSelectedData.splice(foundedIndex, 1) : carrierSelectedData.push(value);

    const idsCarrier = carrierSelectedData.map((el) => el?.id);
    allFilterData.carrier_ids = idsCarrier;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === Number(filter.searchBy.id));
    const showCustomerObj = { id: 888, key: uuid(), title: 'Carrier', value: 'carrier' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...carrierSelectedData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (carrierSelectedData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetShipmentFilterData({ ...getShipmentFilterData, carrier_ids: idsCarrier });
    setFilter({ ...filter, carrierSelectedData });
    getShipment({ allFilterData });
  }

  /** Clear All * */
  function onReset() {
    const resetDaya = {
      ...filter,
      allValue: '',
      selectedValues: [],
      locations: LOCATION_INITIAL_VALUE,
      customerSelectData: [],
      equipmentSelectedData: [],
      driverSelectedData: [],
      carrierSelectedData: [],
      stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
    };
    setTriggerAdvancedClear(new Date());
    setShowHeaderFilters([]);

    getShipment({ allFilterData: { ...activeTabData?.data?.filters }, reset: resetDaya });
    setDateRangeLocation([null, null]);
    setDateRangeStopPoint([null, null]);

    setGroups([]);
    setMotions([]);
    setStatuses([]);
    setEquipments([]);
    setLocalFilterCheckboxes({ status: [], groups: [], equipment_types: [], motions: [] });
    setGetShipmentFilterData({});

    setStopPointCondition(true);
    setTimeout(() => {
      setStopPointCondition(false);
    }, 0);
  }

  /** Filter * */

  useEffect(() => {
    getFilterByGroups();
    getFilterByEquipmentType().then();
    getFilterCustomerData();
    getFilterTimeLine();
    getFilterStopPointData();
    getFilterEquipmentData();
    getFilterDriverData();
    getFilterCarrierData();
    fetchRecentActivity();
    getUser();
  }, []);

  useEffect(() => {
    !showModal && setTabModalEditData({});
  }, [showModal]);

  useEffect(() => {
    const refs = {};
    shipmentsData?.data.forEach((d) => {
      refs[d.id] = React.createRef();
    });
    setRefs(refs);
    setRefEditIcon(refs);
  }, [shipmentsData?.data]);

  useEffect(() => {
    Object.keys(refs).forEach((id) => {
      const ref = refs[id];
      if (ref && ref.current) {
        if (rowCheckBoxes.includes(+id)) {
          const row = ref.current.closest('tr');
          if (row) row.style.backgroundColor = use(palette.indigo50, palette.dark600);
        } else {
          const row = ref.current.closest('tr');
          if (row) row.style.removeProperty('background-color');
        }
      }
    });
  }, [refs, rowCheckBoxes, use, palette]);

  const columns = useColumns({
    user,
    columnWidths,
    rowCheckBoxes,
    refs,
    tableRowCheckboxChange,
    shipmentsData,
    shipmentSettingsProgressBar,
    animationList,
    onClickRowIcon,
    sortingQuery,
    findNextStop,
    setTableShipmentModalObj,
    filter,
    refsEditIcon,
    checkNullOrUndefined,
    onClickPlanned,
    onClickTableRowsEditOption,
    sort,
  });

  return (
    <TableShipmentsContext.Provider
      value={{
        loading,
        triggerAdvancedClear,
        filter,
        dragItem,
        customers,
        tableColumn,
        getSettings,
        updateFilter,
        stopPoints,
        stopPointCondition,
        equipmentData,
        carrierData,
        driverDataFilter: driverData,
        switchRadioButtons,
        multiSelectOptions,
        onChangeMultiselectSearchBy,
        dateRangeLocation,
        setDateRangeLocation,
        dateRangeStopPoint,
        setDateRangeStopPoint,
        onsStTableColumn: (v) => setTableColumn(v),
        onChangeOrder: (items) => setDragItem(items),
        onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
        shipmentSettingsProgressBar,
        setShipmentSettingsProgressBar,
        /** Filter-helpers* */
        onChangeAll,
        onChangeCustomers,
        onChangeEquipment,
        onChangeDriver,
        onChangeCarrier,
        onChangeLocationValues,
        onChangeLocationDatePicker,
        onChangeDatePickerStopPoint,
        onChangeStopPointSelect,
        onDeleteStopPointSelect,
        /** table show data* */
        filterByGroupsData,
        onDeleteManyDataItem,
        localFilterCheckboxes,
        onDeleteStopPointItem,
        onDeleteLocationsItems,
        setEquipments,
        disabledFilters,
        onApplyFilterCheckboxes,
        setLocalFilterCheckboxes,
        filterByEquipmentTypeData,
        groups,
        motions,
        onReset,
        statuses,
        setGroups,
        setMotions,
        equipments,
        showHeaderFilters,
        setStatuses,
      }}
    >
      <div className={classes.shipmentsContainer}>
        <HeaderShipments />

        <ShipmentsFilter />

        <TableBulkActions
          title='Bulk Actions'
          typeBtn='primary'
          overlayStyle={{ width: 140 }}
          placement='bottom-start'
          styleContainer={{ marginTop: 8 }}
          options={SHIPMENT_BULK_ACTIONS_DATA}
          showBulkActions={showBulkActions}
          setShowBulkActions={setShowBulkActions}
          conditionShow={rowCheckBoxes?.length > 0}
          onClickOption={(e, i) => onClickBulkAction(e, i, rowCheckBoxes)}
        />

        <div className={classes.switchRadioWrapper}>
          <div>
            <SwitchRadio
              plus
              type='tab'
              items={filter.filterTableTop}
              onChange={onChangeTab}
              onEdit={onEditTableTab}
              name='tableTopTabMenu'
              value={activeTabData.id}
              onClickPlus={() => setShowModal(true)}
            />
          </div>
          <div>
            <ClockIcon />
            <Typography
              variant='button1'
              style={{ color: palette.indigo500, whiteSpace: 'nowrap' }}
              onClick={() => setIsOpen(true)}
            >
              Recent Activity
            </Typography>
          </div>
        </div>
        <CustomModal
          footer={false}
          filter={filter}
          showModal={showModal}
          headerTitle='Add Tab'
          styleBody={{ width: 446 }}
          updateFilter={updateFilter}
        >
          <AddTab
            filter={filter}
            customers={customers}
            timeLines={timeLine}
            typeofDataJson='shipment'
            stopPointsData={stopPoints}
            updateFilter={updateFilter}
            initialValue={tabModalEditData}
            onClickButtons={onClickModalButtons}
            filterByGroupsData={filterByGroupsData}
            statusData={STATUS_TAB_ADD_MODAL_SHIPMENT}
            filterByEquipmentTypeData={filterByEquipmentTypeData}
          />
        </CustomModal>

        <div className={classes.tableDrawerWrapper}>
          {loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <>
              <div
                className={`tableFixHead table-fixed-header-360 ${isOpen ? 'expanded-activity' : 'condensed-activity'}`}
                style={{ width: isOpen ? 'calc(100% - 329px)' : '100%' }}
              >
                <MaterialTable
                  columns={filterTableColumn(columns)}
                  data={shipmentsData.data}
                  onRowClick={onRowClick}
                  style={{ backgroundColor: use(palette.white, palette.dark800) }}
                  options={{
                    tableLayout: 'fixed',
                    toolbar: false,
                    sorting: false,
                    pageSize: shipmentsData.data.length,
                    pageSizeOptions: [25, 50, 100, 250, 500],
                    rowStyle: (rowData) => {
                      if (rowData.shipment_id === highlightedRow) {
                        return {
                          backgroundColor: 'transparent',
                          backgroundImage: 'linear-gradient(45deg, #a8e063, #56ab2f)',
                          color: 'white',
                          transition: 'background-color 0.5s ease, background-image 0.5s ease, color 0.5s ease',
                        };
                      }
                      return {
                        backgroundColor: '',
                        backgroundImage: 'none',
                        color: 'initial',
                        transition: 'background-color 0.5s ease, background-image 0.5s ease, color 0.5s ease',
                      };
                    },
                  }}
                  components={{
                    Header: ({ columns }) => {
                      return (
                        <thead>
                          <tr style={{ borderTop: 'none' }}>
                            {columns.map((column) => (
                              <th
                                key={column.field}
                                style={{
                                  width: column.width,
                                  borderRight: column.resizable ? '1px solid #ddd' : '0px',
                                  boxSizing: 'border-box',
                                  padding: '4px 0 4px 12px',
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 2,
                                  background: '#fff',
                                }}
                              >
                                <div className='position-relative'>
                                  <div
                                    className='resizable-column-header'
                                    style={{
                                      display: column.center ? 'flex' : 'block',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {column.title}
                                  </div>
                                  {!!column.resizable && (
                                    <div
                                      style={{
                                        cursor: 'col-resize',
                                        position: 'absolute',
                                        right: '-2px',
                                        top: 0,
                                        bottom: 0,
                                        width: 5,
                                        zIndex: 10,
                                      }}
                                      onMouseDown={handleMouseDown(column.field)}
                                    />
                                  )}
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                      );
                    },
                    Pagination: () => null,
                  }}
                />
                <div className='d-flex justify-content-end gap-5 pt-2 pb-2 pe-5'>
                  <div className='d-flex align-items-center gap-1'>
                    <Typography variant='h6'>Total Miles:</Typography>{' '}
                    <Typography variant='b2'>
                      {formatNumber(Math.round(shipmentsTotal?.total_miles || 0), 0)}
                    </Typography>
                  </div>
                  <div className='d-flex align-items-center gap-1'>
                    <Typography variant='h6'>Total Revenue:</Typography>{' '}
                    <Typography variant='b2'>
                      {currency}
                      {formatNumber(shipmentsTotal?.total_revenue)}
                    </Typography>
                  </div>
                </div>
                <Pagination
                  data={shipmentsData}
                  rowPerPage={rowPerPage}
                  onPageChange={onPageChange}
                  onChangeRowPerPage={onChangeRowPerPage}
                  rowsPerPageOptions={[20, 50, 100, 250, 500]}
                />
              </div>
              <TableDrawer
                width={350}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                data={recentActivity}
                page={page}
                moreData={fetchRecentActivity}
                loading={loading}
                filterTableTop={filter.filterTableTop}
              />
            </>
          )}
        </div>
        <TableShipmentRowEditChildes data={tableShipmentModalObj} onSubmit={onSubmitShipmentRowEditChildes} />
        {addActionOpen && (
          <PlannedLoads
            open={addActionOpen}
            shipmentData={selectedShipment}
            onClose={() => setAddActionOpen(false)}
            onSuccess={(selectedRows) => {
              const ids = selectedRows.map((item) => item.shipment_id);
              if (ids.length) {
                getShipment({ allFilterData: { shipment_ids: ids } });
              }
              setAddActionOpen(null);
            }}
          />
        )}
        {openRestrictedCheckIn && (
          <RestrictedCheckIn
            open={openRestrictedCheckIn}
            onClose={() => setOpenRestrictedCheckIn(false)}
            shipment={rowToCheckin}
            onSuccess={() => {
              getShipment({ allFilterData: { shipment_ids: [rowToCheckin?.shipment_id] } });
              setRowToCheckin(null);
            }}
          />
        )}
        {!!requestTrackingData && (
          <RequestCellularTracking
            open={!!requestTrackingData}
            onClose={() => setRequestTrackingData(null)}
            shipment={requestTrackingData}
            onSuccess={() => {
              const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
              const allFilterData = { ...getShipmentFilterData, ...tabData };
              getShipment({ allFilterData });
            }}
          />
        )}
        {!!stopTrackingData && (
          <ConfirmModal
            open={!!stopTrackingData}
            title='Stop Cellular Tracking'
            text={`Are you sure you want to stop cellular tracking for shipment ${stopTrackingData?.shipment_id}?`}
            btnText='Stop Tracking'
            onClose={() => setStopTrackingData(null)}
            onConfirm={stopCellularTracking}
          />
        )}
        {!!openConfirmCarrierCheckin && (
          <ConfirmModal
            open={!!openConfirmCarrierCheckin}
            title='Warning!'
            width='560px'
            text={
              <div>
                <Typography variant='s1' as='p' style={{ color: palette.gray700 }}>
                  The shipment has not yet been confirmed by{' '}
                  {openConfirmCarrierCheckin.rowData?.brokerage_dispatch?.carrier?.name}.
                </Typography>
                <Typography variant='s1' as='p' style={{ color: palette.gray700 }}>
                  Are you sure you want to proceed without a signed confirmation?
                </Typography>
              </div>
            }
            btnType='primary'
            btnText='Proceed'
            onClose={() => setOpenConfirmCarrierCheckin(null)}
            onConfirm={() => {
              onClickTableRowsEditOption(openConfirmCarrierCheckin.item, null, {
                ...openConfirmCarrierCheckin.rowData,
                checkInConfirmed: true,
              });
              setOpenConfirmCarrierCheckin(null);
            }}
          />
        )}
      </div>
    </TableShipmentsContext.Provider>
  );
};

export default TableShipments;
