import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './AddCarrierStyle.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as CheckedIcon } from 'assets/icons/createChecked.svg';
import { ReactComponent as RadioIcon } from 'assets/icons/radioChecked.svg';
import { getBrokerageSetting } from 'Api/CompanySettings';
import BasicDetails from './BasicDetails';
import ContactBook from './ContactBook';
import PaymentTerms from './PaymentTerms';
import Documents from './Documents';
import { Typography } from '../../Typography';
import Operations from './Operations';

const AddCarrier = ({ close, getCarriers }) => {
  const { use } = useTheme();
  const [status, setStatus] = useState(1);
  const [company, setCompany] = useState(null);
  const [stepsDone, setStepsDone] = useState({
    isBasicDetailsSaved: false,
    isOperation: false,
    isContactBook: false,
    isPaymentTerms: false,
    isFuelSurchargeMatrix: false,
  });
  const [customer, setCustomer] = useState({
    basicDetails: {},
    basicDetailsStates: {},
    contactBook: {},
    paymentTerms: {},
  });

  useEffect(() => {
    getCompanyProfile();
  }, []);

  const getCompanyProfile = async () => {
    return getBrokerageSetting().then((res) => {
      if (res?.data) {
        setCompany(res?.data);
      }
    });
  };

  const nextStep = (formValue) => {
    if (status === 1) {
      const { formikData, stateData } = formValue || {};
      setCustomer({
        ...customer,
        basicDetails: formikData,
        basicDetailsStates: stateData,
      });
    }
    if (status === 2) {
      setCustomer({
        ...customer,
        operations: formValue,
      });
    }
    if (status === 3) {
      setCustomer({
        ...customer,
        contactBook: formValue,
      });
    }
    if (status === 4) {
      setCustomer({
        ...customer,
        paymentTerms: formValue,
      });
    }
    setStatus(status + 1);
  };

  const prevStep = () => {
    if (status <= 5) {
      document.getElementById('driver-container').scrollLeft -= 400;
    }
    setStatus(status - 1);
  };

  const Cancel = () => {
    close();
  };

  return (
    <>
      {status !== 8 && (
        <div
          className='main-modal-wrapper'
          style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative' }}
        >
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            Add Carrier
          </Typography>

          <Modal.Header className='add-customer-modal-header driver-style-wrap'>
            <div className='carrier-steps-wrapper'>
              <div className={status >= 1 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}>
                {status > 1 ? (
                  <div className='d-flex gap-2'>
                    <CheckedIcon />
                    <Typography variant='button2' style={{ color: palette.indigo500 }}>
                      Basic Details
                    </Typography>
                  </div>
                ) : (
                  <div className='d-flex gap-2'>
                    <RadioIcon />
                    <Typography variant='button1'>Basic Details</Typography>
                  </div>
                )}

                <div className='carrier-progress'>
                  <div
                    className={status === 1 ? 'incomplete-progressbar' : status > 1 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 2 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}>
                {status > 2 ? (
                  <div className='d-flex gap-2'>
                    <CheckedIcon />
                    <Typography variant='button2' style={{ color: palette.indigo500 }}>
                      Operations
                    </Typography>
                  </div>
                ) : (
                  <div className='d-flex gap-2'>
                    <RadioIcon />
                    <Typography variant='button1'>Operations</Typography>
                  </div>
                )}

                <div className='carrier-progress'>
                  <div
                    className={status === 2 ? 'incomplete-progressbar' : status > 2 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 3 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}>
                {status > 3 ? (
                  <div className='d-flex gap-2'>
                    <CheckedIcon />
                    <Typography variant='button2' style={{ color: palette.indigo500 }}>
                      Contact Book
                    </Typography>
                  </div>
                ) : (
                  <div className='d-flex gap-2'>
                    <RadioIcon />
                    <Typography variant='button1'>Contact Book</Typography>
                  </div>
                )}

                <div className='carrier-progress'>
                  <div
                    className={status === 3 ? 'incomplete-progressbar' : status > 3 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 3 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}>
                {status > 3 ? (
                  <div className='d-flex gap-2'>
                    <CheckedIcon />
                    <Typography variant='button2' style={{ color: palette.indigo500 }}>
                      Payment Terms
                    </Typography>
                  </div>
                ) : (
                  <div className='d-flex gap-2'>
                    <RadioIcon />
                    <Typography variant='button1'>Payment Terms</Typography>
                  </div>
                )}

                <div className='carrier-progress'>
                  <div
                    className={status === 4 ? 'incomplete-progressbar' : status > 4 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 4 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}>
                {status > 4 ? (
                  <div className='d-flex gap-2'>
                    <CheckedIcon />
                    <Typography variant='button2' style={{ color: palette.indigo500 }}>
                      Documents
                    </Typography>
                  </div>
                ) : (
                  <div className='d-flex gap-2'>
                    <RadioIcon />
                    <Typography variant='button1'>Documents</Typography>
                  </div>
                )}

                <div className='carrier-progress'>
                  <div
                    className={status === 5 ? 'incomplete-progressbar' : status > 5 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
            </div>
          </Modal.Header>
        </div>
      )}
      {status === 1 && (
        <div>
          <BasicDetails
            nextStep={nextStep}
            Cancel={Cancel}
            basicDetails={customer.basicDetails}
            stepsDone={stepsDone.isBasicDetailsSaved}
            basicDetailsStates={customer.basicDetailsStates}
            customer={customer}
            getCarriers={getCarriers}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          <Operations
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isOperation}
            setStepsDone={setStepsDone}
            carrier={customer}
          />
        </div>
      )}

      {status === 3 && (
        <div>
          <ContactBook
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isContactBook}
            setStepsDone={setStepsDone}
            customer={customer}
          />
        </div>
      )}
      {status === 4 && (
        <div>
          <PaymentTerms
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPreviousEmployement}
            paymentTerms={customer.paymentTerms}
            setStepsDone={setStepsDone}
            carrier={customer}
            company={company}
          />
        </div>
      )}
      {status === 5 && (
        <div>
          <Documents
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            carrier={customer}
            getCarriers={getCarriers}
          />
        </div>
      )}
    </>
  );
};

export default AddCarrier;
