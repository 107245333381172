import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/images/truckinLogo.png';
import { formatNumber, palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 8, pageHeight - 2, { align: 'right' });
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Invoice ID', 8, top);
  doc.text('Reference', 40, top);
  doc.text('Aging', 85, top);
  doc.text('Sent Date', 105, top);
  doc.text('Paid Date', 135, top);
  doc.text('Amount', 162, top);
  doc.text('Balance', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, top, dateFormat, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  if (data.isCustomer || data.isTotal) {
    doc.setFont('Inter', 'normal', 700);
  } else {
    doc.setFont('Inter', 'normal', 400);
  }
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);

  if (!data.isBlank) {
    if (data.isCustomer) {
      doc.text(`${data.customerName || '-'}`, 8, top);
    } else if (data.isTotal) {
      doc.text(`Total ${data.customerName || '-'}`, 8, top + 2);
      doc.line(8, top - 2, pageWidth - 8, top - 2);
      doc.text(`${currency}${formatNumber(data.total_amount)}`, 162, top + 2);
      doc.text(`${currency}${formatNumber(data.total_balance)}`, pageWidth - 8, top + 2, { align: 'right' });
    } else {
      doc.text(`${data.invoice_id || '-'}`, 8, top);
      doc.text(`${data.reference_id || '-'}`, 40, top);
      doc.text(`${data.age || '-'}`, 85, top);
      doc.text(`${data.customer_billed_date ? dateFormat.formatDate(data.customer_billed_date) : '-'}`, 105, top);
      doc.text(`${data.customer_payment_date ? dateFormat.formatDate(data.customer_payment_date) : '-'}`, 135, top);
      doc.text(`${currency}${formatNumber(data.amount)}`, 162, top);
      doc.text(`${currency}${formatNumber(data.open_balance)}`, pageWidth - 8, top, { align: 'right' });
    }
  }
};

const createTableRows = (doc, data, dateFormat, currency) => {
  if (!data?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  data.forEach((item, index) => {
    createTableRow(doc, item, top + index * 5, dateFormat, currency);
  });

  return top + data.length * 5;
};

export const generatePDF = async (data, download, dateRange, dateFormat, currency) => {
  if (!data?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Sales by Customer Detail', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(dateFormat.convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageRecords = data.slice(0, 40);
  const restRecords = data.slice(40);

  createTableRows(doc, firstPageRecords, dateFormat, currency);

  for (let i = 0; i < (restRecords?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageData = restRecords.slice(i * 45, (i + 1) * 45);
    createTableRows(doc, currentPageData, dateFormat, currency);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({
    title: `Sales by Customer Detail - ${
      start && end ? `${dateFormat.formatDate(start)} - ${dateFormat.formatDate(end)}` : 'All Time'
    }`,
  });

  const url = doc.output('datauristring', { filename: 'Sales by Customer Detail' });
  const blob = doc.output('blob', { filename: 'Sales by Customer Detail' });

  if (download) {
    doc.save(
      `Sales by Customer Detail - ${
        start && end ? `${dateFormat.formatDate(start)} - ${dateFormat.formatDate(end)}` : 'All Time'
      }.pdf`
    );
  }
  return { blob, url };
};
