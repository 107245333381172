import React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { RemoveManageDriver, RemoveManageStaff } from 'Api/Billing';
import styles from './ManageRemoveModal.module.css';

const ManageRemoveModal = ({ data, show, onClose, getManageList }) => {
  const onRemove = () => {
    if (data?.type === 'Driver') {
      RemoveManageDriver(data?.id, {
        status: 5,
        status_title: 'Terminated',
        terminated_note: 'Testing',
        terminated_date_time: '2022-12-12 14:17:19',
      }).then((res) => {
        if (res) {
          getManageList();
          onClose();
        }
      });
    } else if (data?.type === 'Staff') {
      RemoveManageStaff(data?.id, {
        login_status: 5,
        status: {
          id: 5,
          staff_status: 'Disabled',
        },
      }).then((res) => {
        if (res) {
          getManageList();
          onClose();
        }
      });
    } else onClose();
  };

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      backdropClassName={styles.backDropModal}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Remove User License</Typography>
        </div>
        <div className={styles.body_wrapper}>
          <div className={styles.bottom_line}>
            <Typography variant='s2'>
              Are you sure you want to remove the user license from {data.name}? Removing the license will set{' '}
              {data.name} to Terminated status. {data.name} will no longer have access to the Apps and will be excluded
              from payroll, reports, along with all system functions.
            </Typography>
          </div>
        </div>
        <div className={styles.footer_wrapper}>
          <CustomButton
            type='secondary'
            title='Nevermind'
            leftIcon={false}
            styleButton={{ padding: '2px 6px' }}
            onClick={onClose}
          />
          <CustomButton
            type='danger'
            title='Yes, Remove User Licenses'
            leftIcon={false}
            styleButton={{ padding: '2px 6px' }}
            onClick={onRemove}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ManageRemoveModal;
