import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Icons } from 'assets/icons';
import styles from './PaymentAgreement.module.css';

const Type = ({ handleClose }) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/billing');
    handleClose();
  };

  return (
    <>
      <Modal.Body className={styles.PaymentAgreementModalBody}>
        <div className='mt-3'>
          <div className={styles.PaymentAgreementMainHeading}>
            <p>Check Email & Sign Agreement</p>
          </div>
          <div className={styles.PaymentAgreementParaHeading}>
            <p style={{ width: '720px' }}>
              We have emailed you the Hardware Order Form and Agreement. Once you sign and accept the agreement, we will
              proceed with the order. You will be notified within 24 hours on status of your order by email.
            </p>
          </div>
        </div>
        <div className={styles.PaymentAgreementsubMainBoxBody}>
          <div className={styles.PaymentAgreementsubMainBody}>
            <div className={styles.PaymentAgreementSubHeading}>
              <p>Did not receive an email?</p>
            </div>
            <div className={styles.PaymentAgreementParaHeading}>
              <p>
                Please check your Spam / Junk mail in case the email went there. If you still cannot find the agreement
                in your mailbox, please contact sales.
              </p>
            </div>
            <div className='d-flex justify-content-center'>
              <a href='tel:+1(866)300-7701' style={{ textDecoration: 'none' }}>
                <div className={styles.PaymentAgreementPhoneButton}>
                  <img className={styles.PaymentAgreementButtonImg} src={Icons.Phone} alt='' />
                  +1 (866) 300-7701
                </div>
              </a>
              <a href='mailto:{info.email}' style={{ textDecoration: 'none' }}>
                <div className={styles.PaymentAgreementEmailButton}>
                  <img className={styles.PaymentAgreementButtonImg} src={Icons.Email} alt='' />
                  Email Sales
                </div>
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={styles['type-cancel-button']} onClick={handleSubmit}>
          Complete
        </button>
      </Modal.Footer>
    </>
  );
};

export default Type;
