import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 16px;

  .add-action {
    display: inline-flex;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
    margin-bottom: 8px;
  }

  .first-line {
    display: flex;
    column-gap: 32px;
  }
`;

export const SFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-left: 16px;
  min-width: 1000px;
`;

export const SFormRow = styled.div`
  display: flex;
  column-gap: 32px;

  .icon-wrapper {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;

    .delete-icon {
      display: inline-block;
      padding: 3px;
      cursor: pointer;
    }

    svg {
      display: block;
    }

    .empty-icon {
      width: 16px;
    }
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 10px 0 0 16px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const STotalSection = styled.div`
  margin-top: ${({ $marginTop }) => $marginTop || '16px'};
  margin-right: 110px;

  .total-row {
    display: flex;
    flex-direction: column;
    align-items: end;
    column-gap: 46px;
    padding: 12px 0;
    :first-child {
      padding-top: 0;
    }
  }
`;

export const SAttachmentWrapper = styled.div`
  .upload-document-deposit {
    width: 100%;
    position: relative;
    border: 1px dashed ${palette.gray200};
    background: ${palette.white};
    color: ${palette.gray900};
  }
`;

export const SOption = styled.li`
  &&&.MuiAutocomplete-option {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    padding: 4px 8px;
  }
`;

export const SAppliedPaymentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${palette.white};
  border: 1px solid ${palette.gray50};

  .payments-header {
    padding: 16px 20px 0;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-top: 1px solid ${palette.gray50};
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
    white-space: nowrap;
  }

  td {
    padding: 12px 16px 4px;
  }

  .address-row {
    td {
      padding: 0 16px;
    }
  }
`;

export const SWarningPopup = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: ${palette.white};
  border-radius: 6px;
  box-shadow: ${palette.boxShadow2};

  .underline-text {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${palette.indigo500};
    padding-left: 8px;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
