import React from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { useSelector } from 'react-redux';
import { ReactComponent as UsaFlag } from 'assets/icons/drivers/usaFlag.svg';
import { ReactComponent as Approved } from 'assets/icons/icons-approval.svg';
import { ReactComponent as Up } from 'assets/icons/arrow-up.svg';
import { ReactComponent as Favourite } from 'assets/icons/favourite.svg';
import { ReactComponent as StarFilled } from 'assets/icons/star-filled.svg';
import { ReactComponent as Tsa } from 'assets/icons/drivers/tsa.svg';
import { ReactComponent as Fast } from 'assets/icons/drivers/fast.svg';
import { ReactComponent as Twic } from 'assets/icons/drivers/twic.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import ColumnHeader from 'common/ColumnHeader';
import CanadaFlag from 'assets/icons/drivers/turkeyFlag.svg';
import Mexico from 'assets/icons/drivers/mexico.png';
import UserInfo from 'components/StopPoint/UserInfo';

import Chip from 'common/Chip';
import { Typography } from '../Typography';

export const initialFilters = {
  tab: 0,
  page: 1,
  itemsPerPage: 50,
  types: [],
  payTerm: [],
  canTravelTo: [],
  approvedFor: [],
  equipment_types: [],
  sort: { field: null },
};

export const allTab = {
  key: 'all',
  label: 'All',
  value: 0,
  id: 0,
  count: 0,
};

export const severityies = [
  { name: 'Low', value: 1 },
  { name: 'Medium', value: 2 },
  { name: 'High', value: 3 },
  { name: 'Passed', value: 4 },
];

export const canTravelToSelectList = [
  { name: 'USA', value: 1 },
  { name: 'Canada', value: 2 },
  { name: 'Mexico', value: 3 },
];

export const mapperSettingsData = (key) => {
  const map = {
    name: 'Carrier name',
    dot: 'DOT/MC',
    address: 'Address',
    power_units: 'Power Units',
    shipments_brokered: 'Shipments Brokered',
    shipment_gross: 'Shipment Gross',
    status: 'Status',
    carrier_operation: 'Operations',
    preferred: 'Preferred',
    us_driver_inspections: 'Driver Inspections (US)',
    ca_driver_inspections: 'Driver Inspections (CA)',
    us_vehicle_inspections: 'Vehicle Inspections (US)',
    ca_vehicle_inspections: 'Vehicle Inspections (CA)',
    crashes: 'Crashes',
    equipment_type: 'Equipment Type',
    type: 'Type',
  };
  return map[key] ? map[key] : key;
};

const truncateToTwoDecimals = (num) => {
  const multiplier = 100;
  return Math.floor(num * multiplier) / multiplier;
};

export const useColumns = ({ sortingQuery, changePreferred, openNotes, showMore, sort }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'name',
      title: <ColumnHeader title='CARRIER NAME' field='name' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const {
          name,
          can_travel_to,
          approved_for,
          token,
          carrier_notes_count,
          preferred,
          email,
          phone_number,
          approved_for_custom,
        } = rowData;
        const contactInfo = {
          name,
          email,
          phone_number,
        };
        return (
          <div>
            <div className='d-flex align-items-center gap-2'>
              {rowData?.logo_path ? (
                <img
                  src={rowData.logo_path}
                  alt='img'
                  width={30}
                  height={30}
                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                />
              ) : (
                <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
              )}
              <UserInfo data={contactInfo} />
              {!!token && !!token?.used && <Approved width={18} height={18} className='ms-2' />}
              {!!carrier_notes_count && (
                <NotesIcon
                  onClick={(e) => openNotes(e, rowData)}
                  width={14}
                  height={14}
                  fill={palette.goldDark}
                  style={{ marginLeft: 4, flexShrink: 0 }}
                />
              )}
              {preferred ? (
                <StarFilled fill={palette.amber500} onClick={(e) => changePreferred(e, rowData)} />
              ) : (
                <Favourite onClick={(e) => changePreferred(e, rowData)} />
              )}
            </div>
            <div>
              {!!can_travel_to?.includes('1') && <UsaFlag />}
              {!!can_travel_to?.includes('2') && <img src={CanadaFlag} alt='mexico-Flag' />}
              {!!can_travel_to?.includes('3') && <img src={Mexico} alt='mexico-Flag' className='mexico-flag-icon' />}
              {!!approved_for?.includes('1') && <Tsa width={30} height={30} />}
              {!!approved_for?.includes('2') && <Fast width={40} height={30} />}
              {!!approved_for?.includes('3') && <Twic width={40} height={30} />}
              {!!approved_for?.includes('4') && approved_for_custom && (
                <Typography variant='b2'> {approved_for_custom}</Typography>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'dot',
      title: <ColumnHeader title='CARRIER NAME' field='dot' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { dot, mc } = rowData;
        return (
          <div className='d-flex flex-column'>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>DOT {dot || '-'}</span>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>MC {mc || '-'}</span>
          </div>
        );
      },
    },
    {
      field: 'carrier_operation',
      title: <ColumnHeader title='OPERATIONS' field='carrier_operation' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { carrier_operation } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{carrier_operation || '-'}</span>;
      },
    },
    {
      field: 'type',
      title: <ColumnHeader title='TYPE' field='type' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { type } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {' '}
            {type === 0 ? 'External' : 'In-Network'}
          </span>
        );
      },
    },

    {
      field: 'shipment_gross',
      title: <ColumnHeader title='CARRIER PAY' field='gross_brokered' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { gross_brokered } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {currency} {gross_brokered || '-'}
          </span>
        );
      },
    },
    {
      field: 'shipments_brokered',
      title: <ColumnHeader title='SHIPMENT COMPLETED' field='shipment_brokered' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { shipment_brokered } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{shipment_brokered || '-'}</span>;
      },
    },
    {
      field: 'power_units',
      title: <ColumnHeader title='POWER UNITS' field='power_unit' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { power_unit } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{power_unit}</span>;
      },
    },
    {
      field: 'us_driver_inspections',
      title: (
        <ColumnHeader
          title='DRIVER INSPECTIONS (US)'
          field='us_driver_inspections'
          onClick={sortingQuery}
          sort={sort}
        />
      ),

      render: (rowData) => {
        const { us_driver_national_average, us_driver_out_of_service_percent } = rowData;

        const medium = us_driver_out_of_service_percent - us_driver_national_average;
        return (
          <div className='d-flex flex-column'>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {' '}
              {medium < 0 ? (
                <Up fill={palette.green400} style={{ transform: 'rotate(180deg)' }} />
              ) : (
                <Up fill={palette.red400} />
              )}
              <Chip
                bgColor={medium < 0 ? palette.green0 : palette.red0}
                labelColor={medium < 0 ? palette.green400 : palette.red400}
                label={`${truncateToTwoDecimals(
                  Math.abs(
                    truncateToTwoDecimals(us_driver_out_of_service_percent) -
                      truncateToTwoDecimals(us_driver_national_average)
                  )?.toFixed(2)
                )}%`}
              />{' '}
              {truncateToTwoDecimals(us_driver_out_of_service_percent)}% O.O.S /{' '}
              {truncateToTwoDecimals(us_driver_national_average)}% NAT. AVG.
            </span>
          </div>
        );
      },
    },
    {
      field: 'us_vehicle_inspections',
      title: (
        <ColumnHeader
          title='VEHICLE INSPECTIONS (US)'
          field='us_vehicle_inspections'
          onClick={sortingQuery}
          sort={sort}
        />
      ),
      render: (rowData) => {
        const { us_vehicle_out_of_service_percent, us_vehicle_national_average } = rowData;
        const medium = us_vehicle_out_of_service_percent - us_vehicle_national_average;
        return (
          <div className='d-flex flex-column'>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {' '}
              {medium < 0 ? (
                <Up fill={palette.green400} style={{ transform: 'rotate(180deg)' }} />
              ) : (
                <Up fill={palette.red400} />
              )}
              <Chip
                bgColor={medium < 0 ? palette.green0 : palette.red0}
                labelColor={medium < 0 ? palette.green400 : palette.red400}
                label={`${truncateToTwoDecimals(
                  Math.abs(
                    truncateToTwoDecimals(us_vehicle_out_of_service_percent) -
                      truncateToTwoDecimals(us_vehicle_national_average)
                  )?.toFixed(2)
                )}%`}
              />{' '}
              {truncateToTwoDecimals(us_vehicle_out_of_service_percent)}% O.O.S /{' '}
              {truncateToTwoDecimals(us_vehicle_national_average)}% NAT. AVG.
            </span>
          </div>
        );
      },
    },
    {
      field: 'crashes',
      title: <ColumnHeader title='CRASHES' field='crashes' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { crashes } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{crashes || '-'}</span>;
      },
    },
    {
      field: 'equipment_type',
      title: <ColumnHeader title='EQUIPMENT TYPE' />,
      render: (rowData) => {
        return (
          <div style={{ maxWidth: 220, display: 'flex', gap: 5, flexWrap: 'wrap' }}>
            {!rowData?.equipment_types?.length && '-'}
            {(rowData?.equipment_types || [])?.slice(0, 2).map((item) => (
              <Chip
                key={item.id}
                size='medium'
                label={item?.title}
                labelColor={palette.indigo500}
                fontWeight={500}
                bgColor={palette.indigo0}
                height='20px'
                style={{ marginRight: '5px' }}
              />
            ))}
            {rowData?.equipment_types?.length > 2 && (
              <Typography variant='c1' style={{ color: palette.indigo500 }} onClick={(e) => showMore(e, rowData)}>
                Show More
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'address',
      title: <ColumnHeader title='ADDRESS' field='address1' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { address1 } = rowData;
        const { address2 } = rowData;
        const city = rowData.city?.name;
        const state = rowData.state?.short_name;
        const { zipcode } = rowData;
        return (
          <div>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {address1 ? `${address1}, ` : '-'}
              {address2 && `${address2}, `}
            </span>
            <div style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city}, `}
              {state && `${state}, `}
              {state && `${zipcode}`}
            </div>
          </div>
        );
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status_id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { status } = rowData;

        return (
          <Chip
            fontWeight={500}
            fontSize='11px'
            style={{ textTransform: 'uppercase' }}
            bgColor={status.bg_color}
            labelColor={status.color}
            label={status.title}
          />
        );
      },
    },
  ];
};
