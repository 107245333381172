import React, { useEffect, useState } from 'react';
import { getCustomerContactBook, getCustomerPayTerms, getCustomersList } from 'Api/Customers';
import { palette } from 'utils/constants';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { STable } from '../CreateInvoice.styles';

const BillTo = ({ values, handleChange, touchedErrors }) => {
  const [customers, setCustomers] = useState([]);
  const [customerPayTerms, setCustomerPayTerms] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ page: 1, page_size: 10000 });
      const { data: payTerms } = await getCustomerPayTerms();
      setCustomers(data);
      setCustomerPayTerms(payTerms);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerContacts = async () => {
    try {
      const { data } = await getCustomerContactBook({ id: values.customer.id });
      setCustomerContacts(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (values.customer) {
      getCustomerContacts();
    }
  }, [values.customer]);

  return (
    <div>
      <div className='mb-2'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Bill To
        </Typography>
      </div>
      <STable>
        <thead>
          <tr>
            <th>BILL TO</th>
            <th>REFERENCE ID</th>
            <th>TERMS</th>
            <th>CONTACT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Autocomplete
                name='customer'
                width='250px'
                labelKey='company_name'
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.company_name}
                  </li>
                )}
                value={values.customer}
                onChange={(e, value) => {
                  handleChange('customer', value);
                  handleChange('email_to', null);
                  if (value.customer_payment_term?.pay_term_types) {
                    handleChange('terms', value.customer_payment_term.pay_term_types);
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={customers}
                error={touchedErrors.customer}
              />
            </td>
            <td>
              <Input
                name='reference_id'
                width='150px'
                value={values.reference_id}
                onChange={handleChange}
                error={touchedErrors.reference_id}
              />
            </td>
            <td>
              <Autocomplete
                name='terms'
                width='300px'
                value={values.terms}
                onChange={(e, value) => handleChange('terms', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                  return option ? getPaymentTermTitle(option) : '';
                }}
                options={customerPayTerms}
                error={touchedErrors.terms}
              />
            </td>
            <td>
              <Autocomplete
                name='email_to'
                width='200px'
                value={values.email_to}
                onChange={(e, value) => handleChange('email_to', value)}
                isOptionEqualToValue={(option, value) => option.contact_email === value.contact_email}
                getOptionLabel={(option) => {
                  return option ? `${option.contact_type?.title} - ${option.contact_name}` : '';
                }}
                options={customerContacts}
                error={touchedErrors.email_to}
              />
            </td>
          </tr>
          <tr className='address-row'>
            <td>
              {values.customer && (
                <div className='d-flex flex-column'>
                  <Typography variant='c1' style={{ color: palette.gray500 }}>
                    {values.customer.address1 || ''}
                  </Typography>
                  <Typography variant='c1' style={{ color: palette.gray500 }}>
                    {values.customer.city?.name ? `${values.customer.city?.name}` : ''}
                    {values.customer.state?.short_name ? `, ${values.customer.state?.short_name}` : ''}
                    {values.customer.zipcode ? ` - ${values.customer.zipcode}` : ''}
                  </Typography>
                </div>
              )}
            </td>
            <td colSpan={2} />
            <td>
              {values.email_to && (
                <div className='d-flex flex-column'>
                  <Typography variant='c1' style={{ color: palette.gray500 }}>
                    {values.email_to.phone_number}
                  </Typography>
                  <Typography variant='c1' style={{ color: palette.gray500 }}>
                    {values.email_to.contact_email}
                  </Typography>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </STable>
    </div>
  );
};

export default BillTo;
