export const getInitialValues = (data) => {
  const {
    bank_name,
    bank_address_1,
    bank_address_2,
    city,
    state,
    bank_zip_code,
    country,
    ach_enabled,
    ach_account_number,
    ach_routing_number,
    wire_enabled,
    wire_account_number,
    wire_routing_number,
  } = data || {};

  return {
    bank_name: bank_name || '',
    address: bank_address_1 || '',
    address2: bank_address_2 || '',
    city: city || null,
    state: state || null,
    zipcode: bank_zip_code || '',
    country: country || null,
    ach_enabled: !!ach_enabled || false,
    ach_account_number: ach_account_number || '',
    confirm_ach_account_number: ach_account_number || '',
    ach_routing_number: ach_routing_number || '',
    confirm_ach_routing_number: ach_routing_number || '',
    wire_enabled: !!wire_enabled || false,
    wire_account_number: wire_account_number || '',
    confirm_wire_account_number: wire_account_number || '',
    wire_routing_number: wire_routing_number || '',
    confirm_wire_routing_number: wire_routing_number || '',
  };
};
