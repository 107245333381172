import React from 'react';
import styles from './SecurityLogHeader.module.css';
import HeaderStar from '../../HeaderStar';

const SecurityLogHeader = () => {
  return (
    <div className={styles.securityLogHeader_wrapper}>
      <HeaderStar title='Security' />
    </div>
  );
};
export default SecurityLogHeader;
