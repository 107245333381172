import { array, number, object, string } from 'yup';

export const validationSchema = array()
  .of(
    object().shape({
      commodity: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      expected_quantity: number().required('Required').nullable(),
      expected_quantity_type: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      expected_weight: number().required('Required').nullable(),
      expected_weight_type: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      expected_weight_unit: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      expected_dimensions: object()
        .shape({
          l: number().required('Required').nullable(),
          w: number().required('Required').nullable(),
          h: number().required('Required').nullable(),
        })
        .required('Required')
        .nullable(),
      expected_dimension_unit: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      value: number().required('Required').nullable(),
      value_type: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      sku_barcode: string().trim().required('Required').nullable(),
    })
  )
  .min(1, 'Each pickup should have at least 1 cargo');

export const singleCargoValidationSchema = array()
  .of(
    object().shape({
      commodity: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
      expected_weight: number().required('Required').nullable(),
      expected_weight_type: object()
        .shape({ id: number().required('Required').nullable() })
        .required('Required')
        .nullable(),
    })
  )
  .min(1, 'Each pickup should have at least 1 cargo');
