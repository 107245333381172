import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './header.css';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ReactComponent as ClockIcon } from 'assets/icons/header/clock-filled.svg';
import { ReactComponent as DownIcon } from 'assets/icons/header/down.svg';
import { ReactComponent as CarrierIcon } from 'assets/sidemanu/carrier.svg';
import cartIcon from 'assets/icons/equipment/cart.png';
import loadBoardIcon from 'assets/icons/loadBoard.svg';
import staffSmall from 'assets/icons/header/staff-small.svg';
import documentSmall from 'assets/icons/header/document-small.svg';
import safetySmall from 'assets/icons/header/safety-small.svg';
import repairShopSmall from 'assets/icons/header/repair-shop-small.svg';
import customersSmall from 'assets/icons/header/customers-small.svg';
import stopPointSmall from 'assets/icons/header/stop-point-small.svg';
import accountingIcon from 'assets/sidemanu/accounting.svg';
import ChatIcon from 'assets/icons/header/chat-20px.svg';
import profile from 'assets/icons/drivers/user-profile.svg';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { getAppUrl } from 'utils/helpers';
import { palette, PERMISSIONS, CREATE_PERMISSIONS } from 'utils/constants';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import { getMultiAccountList } from 'Api/Headerv2';
import { getTimeZone, updateUserTimeZone } from 'Api/Header';
import { hardwareCart, getAlertCenter } from 'Api/Equipment';
import AuthService from 'services/auth.service';
import ScanQR from 'componentsV2/ManagerApp/ScanQR';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { onChangeIsOpenSendPayment } from 'store/reducers/header.reducer';
import ScheduleMaintenance from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/MaintenanceCost/MaintenanceTable/MaintainanceComponents/ScheduleMaintenance';
import AddRecordModal from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/MaintenanceCost/MaintenanceTable/MaintainanceComponents/AddRecordModal';
import CartComponent from '../Cart/CartComponent';
import Bell from './bell';
import DropDownAvatar from '../DropDownAvatar';
import CustomModal from '../CustomModal/CustomModal';
import styles from '../PlanerModalContent/PlanerModalContent.module.css';
import packageJson from '../../../package.json';

import { LayoutContext } from '../layout';
import NewDocuments from './newDocuments';
import InstallDesktopApp from './InstallDesktopApp';
import Notification from './Notification';
import Notepad from './Notepad/Notepad';

const AddRecordstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: 0,
  padding: '0px',
  borderRadius: '6px',
};

const LiveTime = memo(({ hoverClock, selectedTimeZone, changedTimeZone }) => {
  const { use } = useTheme();
  const [liveTime, setLiveTime] = useState(moment.tz(selectedTimeZone?.zone_name?.replace(/ - /g, '/')));

  useEffect(() => {
    const timer = setInterval(() => {
      setLiveTime(moment.tz(selectedTimeZone?.zone_name?.replace(/ - /g, '/')));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [selectedTimeZone?.zone_name]);

  useEffect(() => {
    if (changedTimeZone) {
      setLiveTime(changedTimeZone);
    }
  }, [changedTimeZone]);

  return (
    <p
      className='header-caption-c1'
      style={{
        color: hoverClock === '' ? palette.gray400 : use(palette.gray900, palette.gray50),
      }}
    >
      {liveTime.format('L, h:mm:ss A')}
    </p>
  );
});

const Header = () => {
  const { theme, use } = useTheme();
  const {
    cartItemsData,
    userStatus,
    userData,
    messageNotification,
    unreadSettlementsCount,
    offeredShipmentsCount,
    setTimeZone,
    menuButtons,
    favoritesOnHeader,
  } = useContext(LayoutContext);
  const url = getAppUrl();
  const location = useLocation();
  const dispatch = useDispatch();
  const storageData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
  const { permissions } = useSelector((state) => state?.root);
  const [toggleNotif, setToggleNotif] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showMultiAccount, setShowMultiAccount] = useState(false);
  const [hoverClock, setHoverClock] = useState('');
  const [CartShow, setCartShow] = useState(false);
  const [cartItemTotal, setCartItemTotal] = useState();
  const [CartItem, setCartItem] = useState([]);
  const [onCancel, setOnCancel] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [alertData, setAlertData] = useState({ data: [] });
  const avatarRef = useRef(null);
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [multiAccountList, setMultiAccountList] = useState([]);
  const [resetIndex, setResetIndex] = useState(Date.now());
  const [openScheduleMaintenance, setOpenScheduleMaintenance] = useState(false);
  const [openAddRecord, setOpenAddRecord] = useState(false);
  const [showFavorite, setShowFavorite] = useState(true);
  const hasBrokerage = useHasBrokerage();

  const defaultValue = () => {
    try {
      const data = JSON.parse(localStorage.getItem('user'))?.customer?.timezone || '';
      let parseData = JSON.parse(data);
      parseData = parseData !== '' && {
        ...parseData,
        zone_name: parseData.zone_name.replace(/\//g, ' - '),
      };
      return parseData;
    } catch (e) {
      return {};
    }
  };
  const CartHandleClose = () => setCartShow(false);
  const CartHandleShow = () => setCartShow(true);

  const [selectedTimeZone, setSelectedTimeZone] = useState({
    ...defaultValue(),
  });
  const [liveTime, setLiveTime] = useState(moment.tz(selectedTimeZone?.zone_name?.replace(/ - /g, '/')));

  useEffect(async () => {
    try {
      setTimeZone(selectedTimeZone);
      const { data } = await getMultiAccountList();
      setMultiAccountList(data);
    } catch (e) {
      // Do nothing
    }
  }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setLiveTime(moment.tz(selectedTimeZone?.zone_name?.replace(/ - /g, '/')));
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [selectedTimeZone?.zone_name]);

  useEffect(() => {
    window.addEventListener('click', onToggleAvatar);
    return () => {
      window.removeEventListener('click', onToggleAvatar);
    };
  }, [toggleNotif]);

  const onToggleAvatar = (e) => {
    if (toggleNotif && !avatarRef.current.contains(e.target)) {
      setToggleNotif((p) => !p);
    }
  };

  const handleCloseRecordModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenAddRecord(false);
  };

  const onOpenTimeZoneModal = () => {
    let departmentName;
    try {
      departmentName = JSON.parse(localStorage.getItem('user')).department.department_name;
    } catch (e) {
      // Do nothing
    }
    if (departmentName && departmentName === 'Management') {
      setIsOpen(true);
      getTimeZone().then((res) => {
        if (res?.data) {
          const replaceData = res.data.map((option) => {
            return {
              ...option,
              zone_name: option.zone_name.replace(/\//g, ' - '),
            };
          });
          setTimeZoneData(replaceData);
        }
      });
    }
  };

  const UpdateCart = (value) => {
    setOnCancel(value);
  };

  useEffect(() => {
    hardwareCart()
      .then((res) => {
        setCartItem((res || []).filter((i) => i.quantity > 0));
      })
      .catch(() => {
        // Do nothing
      });
  }, [cartItemsData, onCancel]);

  useEffect(() => {
    const controller = new AbortController();

    getAlertCenter({
      page: 1,
      itemsPerPage: 300,
      alert_status: ['N'],
      sortField: 'sort[][created_at]',
      sortBy: 'desc',
      signal: controller.signal,
    })
      .then((res) => {
        if (res) {
          setAlertData(res);
        }
      })
      .catch(() => {
        // Do nothing
      });

    return () => {
      controller.abort();
    };
  }, [resetIndex]);

  const onUpdateTimeZone = () => {
    try {
      updateUserTimeZone(selectedTimeZone.id).then((res) => res);
      const localData = JSON.parse(localStorage.getItem('user'));
      const newLocalData = JSON.stringify({
        ...localData,
        customer: {
          ...localData.customer,
          timezone: JSON.stringify({
            ...selectedTimeZone,
            zone_name: selectedTimeZone.zone_name.replace(/ - /g, '/'),
          }),
        },
      });
      localStorage.setItem('user', newLocalData);
      setLiveTime(moment.tz(selectedTimeZone.zone_name.replace(/ - /g, '/')));
      setIsOpen(false);
    } catch (e) {
      // Do nothing
    }
  };

  const loginTo = (customer_link) => {
    setShowMultiAccount(false);
    AuthService.logout().then(() => {
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
      navigate(`/login?redirect=${customer_link}`);
    });
  };

  const goTo = (route, type) => {
    setShow(false);
    navigate(`/${route}`, { state: { openCreate: true, type } });
  };

  const cache = useMemo(() => Date.now().toString(), [userData]);

  const popover = (
    <Popover id='popover-create'>
      <Popover.Body>
        {CREATE_PERMISSIONS.FLEET_STAFF?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={staffSmall} alt='staffSmall' className='me-3' />
              <span className='text'>Fleet + Staff</span>
            </div>
            <ul className='popover-create-list mt-3'>
              {CREATE_PERMISSIONS.DRIVER?.some((permission) => permissions.includes(permission)) && (
                <li onClick={() => goTo('driver')}>Driver</li>
              )}
              {CREATE_PERMISSIONS.EQUIPMENT?.some((permission) => permissions.includes(permission)) && (
                <li onClick={() => goTo('equipment')}>Equipment</li>
              )}
              {CREATE_PERMISSIONS.STAFF?.some((permission) => permissions.includes(permission)) && (
                <li onClick={() => goTo('staff')}>Staff User</li>
              )}
              {CREATE_PERMISSIONS.OWNER_OPERATOR?.some((permission) => permissions.includes(permission)) && (
                <li onClick={() => goTo('owner-operators')}>Owner Operator</li>
              )}
              {CREATE_PERMISSIONS.ATTENDANCE?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    navigate(`/absence-list?openCreate=true`);
                  }}
                >
                  Attendance
                </li>
              )}
              {CREATE_PERMISSIONS.REQUEST?.some((permission) => permissions.includes(permission)) && (
                <li onClick={() => goTo('request-list')}>Request</li>
              )}
            </ul>
          </>
        )}
        {CREATE_PERMISSIONS.DOCUMENT?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={documentSmall} alt='staffSmall' className='me-3' />
              <span className='text'>Documents</span>
            </div>
            <ul className='popover-create-list mt-3'>
              <li onClick={() => goTo('documents')}>Document</li>
            </ul>
          </>
        )}
        {CREATE_PERMISSIONS.SAFETY?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={safetySmall} alt='staffSmall' className='me-3' />
              <span className='text'>Safety</span>
            </div>
            <ul className='popover-create-list mt-3'>
              <li onClick={() => goTo('incidents', 'driver')}>Driver Incident</li>
              <li onClick={() => goTo('incidents', 'staff')}>Staff Incident</li>
              <li
                onClick={() => {
                  setShow(false);
                  navigate(`/accidents-crashes?openCreate=true`);
                }}
              >
                Accident Report
              </li>
            </ul>
          </>
        )}
        {CREATE_PERMISSIONS.REPAIR_SHOP?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={repairShopSmall} alt='staffSmall' className='me-3' />
              <span className='text'>Repair Shop</span>
            </div>
            <ul className='popover-create-list mt-3'>
              <li
                onClick={() => {
                  setShow(false);
                  navigate(`/repair-shop?openCreate=true`);
                }}
              >
                Work Order
              </li>
              <li
                onClick={() => {
                  setShow(false);
                  setOpenScheduleMaintenance(true);
                }}
              >
                Schedule Maintenance
              </li>
              <li
                onClick={() => {
                  setShow(false);
                  setOpenAddRecord(true);
                }}
              >
                Maintenance Record
              </li>
            </ul>
          </>
        )}
        {/* {CREATE_PERMISSIONS.SHIPMENT?.some((permission) => permissions.includes(permission)) && ( */}
        {/*  <> */}
        {/*    <div className='popover-create-title'> */}
        {/*      <img src={shipmentSmall} alt='staffSmall' className='me-3' /> */}
        {/*      <span className='text'>Shipments</span> */}
        {/*    </div> */}
        {/*    <ul className='popover-create-list mt-3'> */}
        {/*      <li onClick={() => goTo('planner')}>Shipment</li> */}
        {/*      <li */}
        {/*        onClick={() => { */}
        {/*          setShow(false); */}
        {/*          navigate(`/quotes?openCreate=true`); */}
        {/*        }} */}
        {/*      > */}
        {/*        Quote */}
        {/*      </li> */}
        {/*    </ul> */}
        {/*  </> */}
        {/* )} */}
        {CREATE_PERMISSIONS.CARRIER?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <CarrierIcon fill='#868FA0' className='me-3' />
              <span className='text'>Carriers</span>
            </div>
            <ul className='popover-create-list mt-3'>
              <li onClick={() => goTo('carriers')}>Carrier</li>
            </ul>
          </>
        )}
        {CREATE_PERMISSIONS.CUSTOMER?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={customersSmall} alt='staffSmall' className='me-3' />
              <span className='text'>Customers</span>
            </div>
            <ul className='popover-create-list mt-3'>
              <li onClick={() => goTo('customers')}>Customer</li>
            </ul>
          </>
        )}
        {CREATE_PERMISSIONS.STOP_POINT?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={stopPointSmall} alt='staffSmall' className='me-3' />
              <span className='text'>Stop Points</span>
            </div>
            <ul className='popover-create-list mt-3'>
              <li onClick={() => goTo('stop-point')}>Stop Point</li>
            </ul>
          </>
        )}
        {CREATE_PERMISSIONS.ACCOUNTING?.some((permission) => permissions.includes(permission)) && (
          <>
            <div className='popover-create-title'>
              <img src={accountingIcon} alt='acc' className='me-3' />
              <span className='text'>Accounting</span>
            </div>
            <ul className='popover-create-list mt-3'>
              {CREATE_PERMISSIONS.ACCOUNT?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    navigate(`/accounting/accounts?openCreate=true`);
                  }}
                >
                  Account
                </li>
              )}
              {CREATE_PERMISSIONS.INVOICE?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    navigate(`/accounting/receivables?openCreate=true`);
                  }}
                >
                  Invoice
                </li>
              )}
              {CREATE_PERMISSIONS.VENDOR?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    navigate(`/accounting/vendors?openCreate=true`);
                  }}
                >
                  Vendor
                </li>
              )}
              {CREATE_PERMISSIONS.ITEM_SERVICE?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    navigate('/company-settings?openCreate=true', { state: { tabId: 2, subTabName: 'ShipmentTypes' } });
                  }}
                >
                  Item & Service
                </li>
              )}
              {CREATE_PERMISSIONS.BILL?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    navigate(`/accounting/payables?openCreate=true`);
                  }}
                >
                  Bill
                </li>
              )}
              {CREATE_PERMISSIONS.SEND_PAYMENT?.some((permission) => permissions.includes(permission)) && (
                <li
                  onClick={() => {
                    setShow(false);
                    dispatch(onChangeIsOpenSendPayment({ open: true }));
                  }}
                >
                  Send Payment
                </li>
              )}
            </ul>
          </>
        )}
      </Popover.Body>
    </Popover>
  );

  const handleFavoriteTab = () => {
    setShowFavorite(!showFavorite);
  };

  const onClickFavoriteItems = (path, state) => {
    if (state) {
      navigate(path, { state });
      return;
    }
    path && path !== '' && navigate(path);
  };

  const popoverMultiAccount = (
    <Popover id='popover-multi-account' style={{ maxWidth: '350px' }}>
      <Popover.Body>
        <ul className='popover-create-list mt-3' style={{ paddingLeft: '0' }}>
          {multiAccountList?.map((account) => {
            return (
              <li
                key={account?.company_name}
                onClick={() => loginTo(account.customer_link)}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {account?.company_name}
              </li>
            );
          })}
        </ul>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    let cartTotal = 0;
    if (CartItem) {
      CartItem.forEach((value) => {
        cartTotal += Number(value?.quantity);
      });
      setCartItemTotal(cartTotal);
    }
  }, [CartItem]);

  return (
    <div>
      <div
        className='header-dashboard'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='header-container' style={{ marginLeft: !theme.condensed ? 260 : 79 }}>
          <div className='d-flex align-items-center gap-4'>
            <div
              className={`header-time ${hoverClock}`}
              style={{
                backgroundColor:
                  hoverClock === '' ? use(palette.white, palette.dark800) : use(palette.indigo50, palette.dark700),
              }}
              onMouseOver={() => setHoverClock('hovered')}
              onMouseLeave={() => setHoverClock('')}
              onClick={onOpenTimeZoneModal}
            >
              <ClockIcon fill={hoverClock === '' ? palette.gray400 : use(palette.gray900, palette.gray50)} />
              <LiveTime hoverClock={hoverClock} selectedTimeZone={selectedTimeZone} changedTimeZone={liveTime} />
              {/* <p */}
              {/*  className='header-caption-c1' */}
              {/*  style={{ */}
              {/*    color: hoverClock === '' ? palette.gray400 : use(palette.gray900, palette.gray50), */}
              {/*  }} */}
              {/* > */}
              {/*  {liveTime.format('L, h:mm:ss A')} */}
              {/* </p> */}
            </div>
            {url === 'https://app.beta.truckindigital.com' && (
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='h5' style={{ color: '#e04f44', whiteSpace: 'nowrap' }}>
                  EARLY RELEASE (BETA)
                </Typography>
                <Typography variant='h5' style={{ color: '#e04f44' }}>
                  v{packageJson.version}
                </Typography>
              </div>
            )}
          </div>
          <ScanQR />
          <div className='header-notification'>
            <InstallDesktopApp />
            {!!multiAccountList?.length && (
              <OverlayTrigger
                trigger='click'
                placement='bottom'
                rootClose={showMultiAccount}
                onToggle={setShowMultiAccount}
                show={showMultiAccount}
                overlay={popoverMultiAccount}
              >
                <button className='header-create'>
                  <span>{storageData?.customer?.company_name}</span>
                  <DownIcon />
                </button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              rootClose={show}
              onToggle={setShow}
              show={show}
              overlay={popover}
            >
              <button className='header-create'>
                <span>Create</span>
                <DownIcon />
              </button>
            </OverlayTrigger>
            {!!hasBrokerage && permissions.includes(PERMISSIONS.PLANNER.name) && (
              <div className='chat-notification'>
                <Link to='/offered-shipments'>
                  <Notification icon={loadBoardIcon} Notification={offeredShipmentsCount} />
                </Link>
              </div>
            )}
            <Notepad />
            <div className='chat-notification'>
              <Link to='/my-pay'>
                <Notification icon={accountingIcon} Notification={unreadSettlementsCount} />
              </Link>
            </div>
            {permissions.includes(PERMISSIONS.DOCUMENTS.name) && <NewDocuments />}
            {permissions.includes(PERMISSIONS.CHAT.name) && (
              <div className='chat-notification'>
                <Link to='/chat'>
                  <Notification icon={ChatIcon} Notification={messageNotification} />
                </Link>
              </div>
            )}

            {CartItem?.length > 0 ? (
              <div className='chat-notification' onClick={CartHandleShow}>
                <Notification icon={cartIcon} Notification={cartItemTotal} />
              </div>
            ) : (
              ''
            )}
            {/* <img scr={cartIcon}/> */}
            <Bell alertData={alertData} getAlertData={() => setResetIndex(Date.now())} />
            <div>
              <div
                className='avatar_wrapper'
                onClick={(e) => {
                  e.stopPropagation();
                  setToggleNotif(true);
                }}
              >
                <img src={`${userData?.profileLogo ? userData?.profileLogo : profile}?cache=${cache}`} alt='avatar' />
                <span
                  className={`avatar_available ${userStatus?.title.toLowerCase().split(' ').join('')}`}
                  style={{ borderColor: use(palette.white, palette.dark800) }}
                />
              </div>
              {!!toggleNotif && (
                <DropDownAvatar className='notif-dropdown' setToggleNotif={setToggleNotif} ref={avatarRef} />
              )}
              <CustomModal
                showModal={isOpen}
                styleBody={{ width: 446 }}
                onHide={() => setIsOpen(false)}
                headerTitle='Update time zone'
                styleButtons={{ padding: '6px 12px' }}
                onExited={() => setSelectedTimeZone(defaultValue())}
                buttons={[
                  {
                    key: 'close',
                    type: 'secondary',
                    title: 'Cancel',
                    onClick: () => {
                      setIsOpen(false);
                      setSelectedTimeZone(defaultValue());
                    },
                  },
                  {
                    key: 'submit',
                    type: 'primary',
                    title: 'Update',
                    onClick: onUpdateTimeZone,
                  },
                ]}
              >
                <div className='clock_modal_wrapper'>
                  <span style={{ color: use(palette.gray200, palette.gray700) }} className='clock_modal_select_title'>
                    Time zone
                  </span>
                  <Autocomplete
                    isOptionEqualToValue={(option) => option.id === selectedTimeZone.id}
                    defaultValue={defaultValue}
                    className={styles.autocomplete_material}
                    popupIcon={<Arrows width={8} height={12} />}
                    clearIcon={null}
                    sx={{
                      height: 35,
                      '&.MuiAutocomplete-root': {
                        marginTop: '10px',
                      },
                      '.MuiAutocomplete-input': {
                        color: use(palette.dark900, palette.white),
                      },
                    }}
                    ListboxProps={{
                      sx: {
                        '&.MuiAutocomplete-listbox': {
                          backgroundColor: use(palette.white, palette.gray900),
                          color: use(palette.gray900, palette.white),
                        },
                      },
                    }}
                    id='combo-box-demo'
                    options={timeZoneData}
                    getOptionLabel={(option) => option.zone_name}
                    onChange={(e, value) => setSelectedTimeZone(value)}
                    renderInput={(params) => <TextField {...params} placeholder='Select...' hiddenLabel />}
                  />
                  <div className='clock_modal_description'>
                    Changing the time zone will apply system wide to all users. This will impact all aspects of the
                    system, including shipment ETA, login data, logs etc.
                  </div>
                </div>
              </CustomModal>
              <Modal open={CartShow}>
                <Box sx={style}>
                  <CartComponent handleClose={CartHandleClose} CartItemQty={CartItem} UpdateCart={UpdateCart} />
                </Box>
              </Modal>
            </div>
          </div>
        </div>
        {openScheduleMaintenance && (
          <ScheduleMaintenance
            open={openScheduleMaintenance}
            onClose={() => setOpenScheduleMaintenance(false)}
            onSuccess={() => null}
          />
        )}
        <Modal disableEnforceFocus open={openAddRecord} style={{ zIndex: 1055 }} onClose={handleCloseRecordModal}>
          <Box sx={AddRecordstyle}>
            <AddRecordModal
              equipmentMaintanance={() => null}
              AddRecordModalClose={() => setOpenAddRecord(false)}
              getRecordSuccess={() => null}
            />
          </Box>
        </Modal>
      </div>
      <div>
        {favoritesOnHeader === 1 && (
          <div
            className='header-favorite d-flex align-items-center'
            style={{ marginLeft: !theme.condensed ? '208px' : '22px' }}
          >
            <div onClick={handleFavoriteTab} className='d-flex align-items-center'>
              Favorites
              <span className='ms-1'>{showFavorite ? <ArrowRight /> : <DownIcon />}</span>
            </div>
            <div className={!showFavorite ? 'd-none' : 'd-flex align-items-center gap-1 mt-1 ms-1'}>
              {menuButtons
                ?.filter((el) => {
                  return el.title === 'Favorites';
                })
                ?.map((item) => {
                  return item.collapse?.subItems?.map((fav) => {
                    const { subTitle, path, state } = fav;
                    return (
                      <div
                        className='header-caption-c1 mx-0 header-favorite-options'
                        style={{
                          color: path === location.pathname ? palette.indigo500 : palette.gray400,
                          backgroundColor: path === location.pathname ? palette.indigo0 : palette.gray0,
                        }}
                        onClick={() => onClickFavoriteItems(path, state)}
                      >
                        {subTitle}
                      </div>
                    );
                  });
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '1350px',
  minWidth: '1350px',

  borderRadius: '4px',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
};
