import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import moment from 'moment';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { createHoliday, updateHoliday } from 'Api/Customers';
import validationSchema from './ValidationSchema';
import sm from './CreateHolidayModal.module.css';

const CreateHolidayModal = ({ holiday, onClose, onSuccess, getCustomerHolidays }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();

  const onSubmit = (values) => {
    const month = 1 + moment(values.date).month();
    const day = moment(values.date).date();
    const postData = {
      holiday_name: values.holiday,
      month_of_year: month,
      day_of_year: day,
      country_id: values.country.id,
    };
    if (!holiday) {
      createHoliday(postData)
        .then(() => {
          showToaster({ type: 'success', message: 'Holiday has been successfully created!' });
          onClose();
          onSuccess();
        })
        .catch((e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
        });
    } else {
      updateHoliday(holiday.id, postData)
        .then(() => {
          showToaster({ type: 'success', message: 'Holiday has been successfully updated!' });
          onClose();
          onSuccess();
          getCustomerHolidays();
        })
        .catch((e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      holiday: holiday?.holiday_name || '',
      date: holiday
        ? moment({ year: moment().year(), month: holiday.month_of_year - 1, day: holiday.day_of_year }).toDate()
        : null,
      country: holiday?.country || '',
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div style={{ border: '1px solid rgba(0,0,0,0.4)', borderRadius: '0.5rem' }}>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            {holiday ? 'Update Holiday' : 'Add Holiday'}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div>
          <div className='d-flex flex-column'>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Holiday Name <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column' style={{ width: '100%' }}>
                <input
                  type='text'
                  name='holiday'
                  id='holiday'
                  className={sm.field}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.holiday}
                />
                {formik.touched.holiday && formik.errors.holiday ? (
                  <span className={sm['tab-error-message']}>{formik.errors.holiday}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <DatePicker
                required
                label='Date'
                name='date'
                onBlur={formik.handleBlur}
                value={formik.values.date}
                onChange={(value) => {
                  formik.setFieldValue('date', value);
                }}
              />
              {formik.touched.date && formik.errors.date ? (
                <span className={sm['tab-error-message']}>{formik.errors.date}</span>
              ) : null}
            </div>

            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Country <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column' style={{ width: '100%' }}>
                <Autocomplete
                  required
                  name='country'
                  options={[
                    { id: 231, name: 'United States' },
                    { id: 38, name: 'Canada' },
                    { id: 142, name: 'Mexico' },
                  ]}
                  value={formik.values.country}
                  onChange={(e, val) => {
                    formik.setFieldValue('country', val);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                {formik.touched.country && formik.errors.country ? (
                  <span className={sm['tab-error-message']}>{formik.errors.country}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.white, palette.darkborder),
        }}
      >
        <div className='footer-btn-container'>
          <Button className='cancel-button' onClick={onClose}>
            Cancel
          </Button>
          <Button className='next-step' type='submit' onClick={formik.handleSubmit}>
            {holiday ? 'Update Holiday' : 'Create Holiday'}
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default CreateHolidayModal;
