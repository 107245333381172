import React, { useContext, useEffect, useState } from 'react';
import './DriverProfile.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import hexRgb from 'hex-rgb';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import inbox from 'assets/icons/drivers/inbox.svg';
import comment from 'assets/icons/drivers/comment.svg';
import call from 'assets/icons/drivers/call.svg';
import useFlag from 'assets/icons/drivers/usaFlag.svg';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import maxico from 'assets/icons/drivers/mexico.png';
import down from 'assets/icons/drivers/down.svg';
import reset from 'assets/icons/drivers/reset.svg';
import download from 'assets/icons/drivers/download.svg';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import edit from 'assets/icons/drivers/edit.svg';
import {
  AddCompensation,
  driverAbsenceData,
  driverCompansationData,
  DriverGeneral,
  updateAbsenceAllowence,
  driverRequestData,
  getDriverShipmentStats,
  getStatus,
  hourOfService,
  updateGeneDriver,
  UpdateStatus,
} from 'Api/Driver';
import {
  getDriverCriminalRecords,
  getDriverDrugTestRecords,
  getDriverMvrRecords,
  getDriverPsp,
  getFmcsaResults,
  getMedicalExaminers,
} from 'Api/DriverV2';
import { getPrevEmployment } from 'Api/PreviousEmployment';
import { palette, statusColor } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { Modal } from 'react-bootstrap';
import Chip from 'common/Chip';
import ViewPdf from 'components/ViewPdf';
import { BackdropLoader } from 'common/Loader';
import UpdateDriverType from 'componentsV2/Driver/UpdateDriverType';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import UpdateModal from '../StatusUpdateModal/StatusUpdateModal';
import DriverEmploymentStatus from './components/DriverEmploymentStatus';
import { generatePDF } from './components/DriverQualificationFile/generatePdf';
import UpdateDateModal from '../UpdateHireDateModal/UpdateHireDateModal';
import UpdateDriverIdModal from '../UpdateDriverIdModal/UpdateDriverIdModal';
import GenerateId from '../GenerateIdCardModal/GenerateIdCardModal';
import DriverProfileTab from '../DriverProfileTab/DriverProfileTab';
import ResetPinModal from '../ReserPinModal/ResetPinModal';
import CustomizedSnackbars from '../toast/Toast';
import AddGroupsDrivers from '../AddGroupModal/AddGroupModalDriver';
import { LayoutContext } from '../layout';
import AddDriver from '../Drivers/AddDriver/AddDriver';
import {
  medicalBackgroundConverter,
  transformPrevEmploymentPdf,
  transformReportItems,
} from './components/DriverProfile.data';

const DriverProfile = () => {
  const location = useLocation();
  const showToaster = useShowToaster();
  const { dot_num } = useContext(LayoutContext);
  const { formatDate, formatDateTime, convertToCustomerTime } = useDateFormat();
  const [statusUpdateModalShow, setStatusUpdateModalShow] = useState(false);
  const [dateUpdateModalShow, setDateUpdateModalShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateDriverIdModalShow, setUpdateDriverIdModalShow] = useState(false);
  const [addGroupModalShow, setAddGroupModalShow] = useState(false);
  const [generateIdModalShow, setGenerateIdModalShow] = useState(false);
  const [resetPinModalShoew, setResetPinModalShoew] = useState(false);
  const [driverStatus, setDriverStatus] = useState({});
  const [driverGeneral, setDriverGeneral] = useState();
  const [driverCompansation, setDriverCompansation] = useState();
  const [driverRequest, setDriverRequest] = useState([]);
  const [driverAbsence, setDriverAbsence] = useState();
  const [hourService, setHourService] = useState();
  const [driverShipmentData, setDriverShipmentData] = useState({ loading: true, data: [] });
  const [driverGroupsData, setDriverGroupsData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDQF, setLoadingDQF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [fromDriver, setFromDriver] = useState(false);
  const [openUpdateType, setOpenUpdateType] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const [openUpdateEmploymentStatus, setOpenUpdateEmploymentStatus] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { use } = useTheme();

  useEffect(() => {
    GetCompensation(id);

    getDriverShipmentStats({ id })
      .then((res) => {
        setDriverShipmentData({
          loading: false,
          data: res.data,
        });
      })
      .catch(() => {
        setDriverShipmentData({
          loading: false,
          data: [],
        });
      });

    getStatus({ id })
      .then((res) => {
        setDriverStatus(res.data);
      })
      .catch(() => {
        // Do nothing
      });

    hourOfService({ id })
      .then((res) => {
        setHourService(res.data);
      })
      .catch(() => {
        // Do nothing
      });

    driverRequestData({ page: '1', sort: 'asc', driver_id: id })
      .then((res) => {
        setDriverRequest(res);
      })
      .catch(() => {
        // Do nothing
      });

    GetGeneral(id);
  }, []);

  const absenceDetails = (year) => {
    driverAbsenceData({
      page: '1',
      sort: 'asc',
      driver_id: id,
      year: year ?? Number(moment(new Date()).format('yyyy')),
    })
      .then((res) => {
        setDriverAbsence(res);
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    absenceDetails();
  }, []);

  const GetGeneral = (id) => {
    DriverGeneral({ id })
      .then((res) => {
        setCompanyDetails(res.data.company_details);
        setDriverGeneral({
          ...res.data.driver_details,
          allowed_vehicle_types: JSON.parse(res.data.driver_details.allowed_vehicle_types || '[]'),
          allowed_trailer_types: JSON.parse(res.data.driver_details.allowed_trailer_types || '[]'),
        });
        setDriverGroupsData(res.data.groups);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const GetCompensation = (id) => {
    driverCompansationData({ id })
      .then((res) => {
        setDriverCompansation(res?.data);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const updateAbsenceAllowanceData = (updatedAllowance, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    const payload = { ...updatedAllowance };
    updateAbsenceAllowence(payload, id)
      .then((res) => {
        const updatedData = { ...driverAbsence, ...updatedAllowance, ...res?.data };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverAbsence(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const handleClose = () => {
    GetGeneral(id);
    setOpen(false);
  };

  const updateProfile = (updatedDriverInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    const payload = { ...updatedDriverInfo, operating_states: undefined };
    if (payload?.driver_licence_upload && payload?.driver_licence_upload.includes('s3.amazonaws.com')) {
      delete payload.driver_licence_upload;
    }
    updateGeneDriver(payload, id)
      .then((res) => {
        const updatedData = {
          ...driverGeneral,
          ...updatedDriverInfo,
          ...res?.data,
          certifications: updatedDriverInfo?.certifications,
          allowed_vehicle_types: res?.data?.allowed_vehicle_types
            ? Array.isArray(res?.data?.allowed_vehicle_types)
              ? res?.data?.allowed_vehicle_types
              : JSON.parse(res.data.allowed_vehicle_types || '[]')
            : [],
          allowed_trailer_types: res?.data?.allowed_trailer_types
            ? Array.isArray(res?.data?.allowed_trailer_types)
              ? res?.data?.allowed_trailer_types
              : JSON.parse(res.data.allowed_trailer_types || '[]')
            : [],
        };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverGeneral(updatedData);
        GetGeneral(id);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const updateCompensation = (updatedCompensationInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    const payload = { ...updatedCompensationInfo };
    AddCompensation(payload)
      .then((res) => {
        const updatedData = { ...driverCompansation, ...updatedCompensationInfo, ...res?.data };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverCompansation(updatedData);
        GetCompensation(id);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const updateGroupProfile = (updatedDriverGroupsInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    updateGeneDriver({ group_id: updatedDriverGroupsInfo?.id, driver_id: driverGeneral?.driver_id }, id)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverGroupsData(updatedDriverGroupsInfo);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const testColor = driverGroupsData?.length ? driverGroupsData[0]?.color : driverGroupsData?.color;

  const colorCode = hexRgb(testColor || '#f4f6f7');
  const TextColor = colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';

  const updateDriverStatus = (values, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    const { status } = values;
    const payload = { ...values };
    if (status === 1 || status === 2 || status === 4 || status === 5 || status === 6) {
      if (status !== 4) {
        delete payload.out_of_service_reason;
        delete payload.out_of_service_date_time;
      }
      if (status !== 5) {
        delete payload.terminated_note;
        delete payload.terminated_date_time;
      }
      if (status !== 6) {
        delete payload.quit_note;
        delete payload.quite_date_time;
      }
      if (status !== 2) {
        delete payload.country_data;
        delete payload.note_not_available;
        delete payload.city_data;
        delete payload.driver_next_available_location;
        delete payload.driver_not_available_until_date;
        delete payload.driver_not_available_until_time;
        delete payload.state_data;
      }
    }
    UpdateStatus(payload, id)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverStatus(payload);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const continueAdding = () => {
    setOpen(true);
    // navigate(`/driver`, {
    //   state: { openCreate: true, openStep: driverGeneral.draft_step_number, currentDriverId: id },
    // });
  };

  const onGenerateDQF = async () => {
    try {
      setLoadingDQF(true);
      const p1 = getMedicalExaminers(id);
      const p2 = getDriverCriminalRecords(id);
      const p3 = getDriverMvrRecords(id);
      const p4 = getDriverDrugTestRecords(id);
      const p5 = getDriverPsp(id);
      const p6 = getFmcsaResults(id);
      const p7 = getPrevEmployment({ id, userType: 'driver', params: { page: 1, itemsPerPage: 10000 } });
      const [
        { value: medical },
        { value: criminal },
        { value: mvr },
        { value: drug },
        { value: psp },
        { value: fmcsa },
        { value: previousEmployment },
      ] = await Promise.allSettled([p1, p2, p3, p4, p5, p6, p7]);
      const medicalData = {
        medicalExaminersResults: medical?.data || [],
        mvrCheckResults: mvr?.data || [],
        drugTestResults: drug?.data || [],
        criminalCheckResults: criminal?.data || [],
        pspResults: psp?.data || [],
        fmcsaResults: fmcsa?.data || [],
      };

      if (!previousEmployment?.data) {
        showToaster({ type: 'error', message: 'No data available to download.' });
        return;
      }

      const prevEmployment = transformPrevEmploymentPdf(previousEmployment?.data || []);
      const convertedMedicalData = medicalBackgroundConverter(medicalData);
      const pdfMedicalData = transformReportItems(convertedMedicalData);

      const { url } = await generatePDF(
        driverGeneral,
        pdfMedicalData,
        prevEmployment,
        formatDate,
        convertToCustomerTime
      );
      setPdfUrl(url);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingDQF(false);
    }
  };

  useEffect(() => {
    if (location?.state?.from) {
      setFromDriver(true);
    }
  }, [location?.state?.from]);

  const goBack = () => {
    if (fromDriver) {
      navigate('/driver', { state: { openCreate: false } });
    } else {
      navigate(-1);
    }
  };

  return (
    <div className='profile-container driver-style-wrap'>
      <div className='back-navigate-container' onClick={() => goBack()}>
        <img src={BackArrow} alt='Back-Arrow' />
        <p className='navigater-page-name'>Go Back</p>
      </div>
      <div className='user-profile-header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div>
          <div className='user-details-container'>
            <div className='user-image-container'>
              <img src={driverGeneral?.image ? driverGeneral?.image : userDefault} alt='user' className='userImage' />
              <div
                className='active-indicator'
                style={{
                  backgroundColor: use(
                    statusColor[driverStatus?.status_title]?.color,
                    statusColor[driverStatus?.status_title]?.darkColor
                  ),
                }}
              />
            </div>
            <div className='user-details '>
              <div className='user-name-and-actions-container'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  {driverGeneral?.fname} {driverGeneral?.lname}
                </h2>
                <div className='call-to-action-icons'>
                  <a href={`mailto:${driverGeneral?.email}`}>
                    <img src={inbox} alt='inbox-icon' />
                  </a>
                  <span onClick={() => navigate(`/chat/${dot_num}_driver_group_${driverGeneral.id}`)}>
                    <img src={comment} alt='comment-icon' />
                  </span>
                  <a href={`tel:${driverGeneral?.phone_number}`}>
                    <img src={call} alt='call-icon' />
                  </a>
                </div>
              </div>
              <div className='user-country-and-status-container'>
                <div className='country-flag-icons'>
                  {driverGeneral?.certifications?.can_travel_usa === 1 ? <img src={useFlag} alt='USA-Flag' /> : null}
                  {driverGeneral?.certifications?.can_travel_mexico === 1 ? (
                    <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
                  ) : null}
                  {driverGeneral?.certifications?.can_travel_canada === 1 ? (
                    <img src={turkeyFlag} alt='canada-Flag' />
                  ) : null}
                </div>
                <div
                  className='user-status'
                  style={{
                    backgroundColor: use(
                      statusColor[driverStatus?.status_title]?.bgColor,
                      statusColor[driverStatus?.status_title]?.darkBgColor
                    ),
                  }}
                >
                  <p
                    className='user-status-tag'
                    style={{
                      color: use(
                        statusColor[driverStatus?.status_title]?.color,
                        statusColor[driverStatus?.status_title]?.darkColor
                      ),
                    }}
                  >
                    {driverStatus?.status_title}
                  </p>
                </div>
                <div className='user-dropdown-icon'>
                  <img src={down} alt='Down-icon' onClick={() => setStatusUpdateModalShow(true)} />
                  {statusUpdateModalShow && (
                    <UpdateModal
                      show={statusUpdateModalShow}
                      onHide={() => setStatusUpdateModalShow(false)}
                      name={`${driverGeneral?.fname} ${driverGeneral?.lname}`}
                      id={id}
                      driverStatus={driverStatus}
                      updateDriverStatus={updateDriverStatus}
                      loading={loading}
                      onSuccess={onSuccess}
                    />
                  )}
                </div>
              </div>
            </div>
            {driverGeneral?.draft_step_number && (
              <div className='draft ms-5' onClick={continueAdding}>
                <span className='draft-link'>Continue Adding Driver </span>
              </div>
            )}
          </div>
          {driverStatus?.status_title === 'Terminated' ? (
            <div className='tagline-container-wrap'>
              <p>
                {`Driver has been terminated ${
                  driverStatus?.terminated_date_time
                    ? `on ${formatDateTime(driverStatus?.terminated_date_time, true)}`
                    : ''
                } because ${driverStatus?.terminated_note || ''}`}
                . Eligible for rehire - {driverStatus?.rehire_status || 'Yes'}
              </p>
            </div>
          ) : driverStatus?.status_title === 'Not Available' ? (
            <div className='tagline-container-wrap'>
              <p>
                Driver not available until{' '}
                {driverStatus?.driver_not_available_until_date
                  ? `on ${formatDate(driverStatus?.driver_not_available_until_date)}`
                  : ''}{' '}
                {driverStatus?.driver_not_available_until_time || ''}. Notes: {driverStatus?.note_not_available || ''}
              </p>
            </div>
          ) : driverStatus?.status_title === 'Out of Service' ? (
            <div className='tagline-container-wrap'>
              <p>
                Driver is Out of Service{' '}
                {driverStatus?.out_of_service_date_time
                  ? `on ${formatDateTime(driverStatus?.out_of_service_date_time, true)}`
                  : ''}{' '}
                Notes: {driverStatus?.out_of_service_reason || ''}
              </p>
            </div>
          ) : driverStatus?.status_title === 'Quit' ? (
            <div className='tagline-container-wrap'>
              <p>
                Driver has been Quit{' '}
                {driverStatus?.quite_date_time ? `on ${formatDateTime(driverStatus?.quite_date_time, true)}` : ''}{' '}
                because Notes: {driverStatus?.quit_note || ''}. Eligible for rehire -{' '}
                {driverStatus?.rehire_status || 'Yes'}
              </p>
            </div>
          ) : null}
        </div>
        <div className='btn-container'>
          <PrimaryBtn icon={download} title='Download DQF' onClick={onGenerateDQF} />
          <PrimaryBtn icon={reset} title='Reset PIN' onClick={() => setResetPinModalShoew(true)} />
          {resetPinModalShoew && (
            <ResetPinModal
              show={resetPinModalShoew}
              onHide={() => setResetPinModalShoew(false)}
              name={`${driverGeneral?.fname} ${driverGeneral?.lname}`}
              driverId={id}
              showError={showError}
              onSuccess={onSuccess}
            />
          )}
          <PrimaryBtn icon={download} title='Generate ID' onClick={() => setGenerateIdModalShow(true)} />
          <GenerateId
            show={generateIdModalShow}
            onHide={() => setGenerateIdModalShow(false)}
            driverInfo={driverGeneral}
            companyInfo={companyDetails}
          />
        </div>
      </div>
      <div className='user-profile-sub-header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Tags</p>
            <img src={edit} alt='edit' onClick={() => setAddGroupModalShow(true)} />
            {addGroupModalShow && (
              <AddGroupsDrivers
                show={addGroupModalShow}
                onHide={() => setAddGroupModalShow(false)}
                showError={showError}
                onSuccess={onSuccess}
                driverGroupInfo={driverGroupsData}
                updateGroupProfile={updateGroupProfile}
                loading={loading}
              />
            )}
          </div>
          <div className='data-group-wraper'>
            {driverGroupsData?.length ? (
              <div
                className='user-data-group'
                style={{ backgroundColor: driverGroupsData[0]?.color ? driverGroupsData[0]?.color : 'white' }}
              >
                <p className='group-text' style={{ color: TextColor }}>
                  {driverGroupsData[0]?.group_name || '-'}
                </p>
              </div>
            ) : (
              <div
                className='user-data-group'
                style={{ backgroundColor: driverGroupsData?.color ? driverGroupsData?.color : 'white' }}
              >
                <p className='group-text' style={{ color: TextColor }}>
                  {driverGroupsData?.group_name || '-'}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Type</p>
            <img src={edit} alt='edit' onClick={() => setOpenUpdateType(true)} />
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {driverGeneral?.app?.driver_type === 'company'
                ? 'Company'
                : driverGeneral?.app?.driver_type === 'owner'
                ? `Owner Operator${
                    driverGeneral?.app?.operator?.owner_operator_name
                      ? ` - ${driverGeneral?.app?.operator?.owner_operator_name}`
                      : ''
                  }`
                : '-'}
            </p>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Hire Date</p>
            <img src={edit} alt='edit' onClick={() => setDateUpdateModalShow(true)} />
            {dateUpdateModalShow && (
              <UpdateDateModal
                show={dateUpdateModalShow}
                onHide={() => setDateUpdateModalShow(false)}
                showError={showError}
                onSuccess={onSuccess}
                updateProfile={updateProfile}
                driverInfo={driverGeneral || { certifications: {} }}
                loading={loading}
              />
            )}
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {driverGeneral?.certifications?.hire_date
                ? format(new Date(driverGeneral?.certifications?.hire_date), 'MM/dd/yyyy')
                : '-'}
            </p>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Driver ID</p>
            <img src={edit} alt='edit' onClick={() => setUpdateDriverIdModalShow(true)} />
            {updateDriverIdModalShow && (
              <UpdateDriverIdModal
                show={updateDriverIdModalShow}
                onHide={() => setUpdateDriverIdModalShow(false)}
                showError={showError}
                onSuccess={onSuccess}
                driverInfo={driverGeneral}
                updateProfile={updateProfile}
                loading={loading}
              />
            )}
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {driverGeneral?.driver_id ?? '-'}
            </p>
          </div>
        </div>
        <div className='user-data-container user-data-container-last'>
          <div className='user-data'>
            <p className='user-data-head'>Employment Status</p>
            <img src={edit} alt='edit' onClick={() => setOpenUpdateEmploymentStatus(true)} />
          </div>
          <div className='mb-2'>
            <Chip
              label={
                driverStatus?.status_title === 'Terminated' || driverStatus?.status_title === 'Quit'
                  ? 'INACTIVE'
                  : 'ACTIVE'
              }
              bgColor={
                driverStatus?.status_title === 'Terminated' || driverStatus?.status_title === 'Quit'
                  ? palette.red0
                  : palette.green0
              }
              labelColor={
                driverStatus?.status_title === 'Terminated' || driverStatus?.status_title === 'Quit'
                  ? palette.red500
                  : palette.green500
              }
              size='small'
            />
          </div>
        </div>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <DriverProfileTab
        loading={loading}
        driverInfo={driverGeneral}
        setDriverInfo={setDriverGeneral}
        onSuccess={onSuccess}
        updateProfile={updateProfile}
        driverShipmentData={driverShipmentData}
        hourService={hourService}
        updateCompensation={updateCompensation}
        driverCompansation={driverCompansation}
        driverRequest={driverRequest}
        showError={showError}
        driverAbsence={driverAbsence}
        updateAbsenceAllowanceData={updateAbsenceAllowanceData}
        driver_name={`${driverGeneral?.fname} ${driverGeneral?.lname}`}
        absenceDetails={absenceDetails}
        setGenerateIdModalShow={setGenerateIdModalShow}
      />
      <Modal
        backdrop='static'
        show={open}
        onHide={handleClose}
        onEscapeKeyDown={(e) => e.preventDefault()}
        centered
        id='add-driver-modal'
      >
        <AddDriver
          open={open}
          openStep={driverGeneral?.draft_step_number}
          getDrivers={handleClose}
          currentDriverId={id}
          close={handleClose}
        />
      </Modal>
      {!!openUpdateEmploymentStatus && (
        <DriverEmploymentStatus
          driverId={id}
          driverStatus={driverStatus}
          open={openUpdateEmploymentStatus}
          setDriverStatus={setDriverStatus}
          onClose={() => setOpenUpdateEmploymentStatus(false)}
        />
      )}
      {openUpdateType && (
        <UpdateDriverType
          open={openUpdateType}
          onClose={() => setOpenUpdateType(false)}
          driverId={id}
          onSuccess={() => GetGeneral(id)}
        />
      )}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Driver Qualification File' />
      )}
      <BackdropLoader loading={loadingDQF} />
    </div>
  );
};
export default DriverProfile;
