import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatNumber, getDaysNumber, palette, VALUE_TYPES } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as SettingsIcon } from 'assets/icons/createShipment/settingsFiled.svg';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomTextArea from 'components/CustomTextArea';
import { DatePicker, TimePicker } from 'common/Pickers';
import CustomButton from 'components/CustomButton/CustomButton';
import Input, { InputWithIcon, InputWithText } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ShipmentCommodity from 'componentsV2/Commodity/ShipmentCommodity';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import useDateFormat from 'hooks/useDateFormat';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getAllTrailers } from 'Api/Map';
import { createQuickEquipment } from 'Api/Shipment';
import { getCommodity, getGrandTotal, getMeasurementTypes, getQuantityTypes, getWeightTypes } from 'Api/Planner';
import { ShipmentContext } from 'components/CreateShipment/Layout';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { SMergedInputs } from 'components/CreateShipment/CreateShipment.styles';
import ErrorMessage from 'common/ErrorMessage';
import { getCargoTotals } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { useSelector } from 'react-redux';
import { PopoverCreatShipmentItem } from '../helpers/PopoverCreatShipment';
import CustomSelect from '../helpers/CustomSelect';
import { EQUIPMENT_ACTION, QUANTITY, SCHEDULED_DATE_TYPE, WEIGHT_TYPE } from '../helpers/constants';
import sm from '../AddStopPoint/AddStopPoint.module.css';

const PickUp = ({
  stop,
  values,
  namePrefix,
  index,
  arrayHelpers,
  stopPoints,
  errors,
  touched,
  indexPrefix,
  lastChild,
  equipmentRequired,
  setFieldValue,
  setFieldTouched,
}) => {
  const { formatTime } = useDateFormat();
  const scrollRef = useRef(null);
  const endDateScrollRef = useRef(null);
  const { weightUnit, qtyUnit } = useSelector((state) => state.root);

  const {
    updateEquipmentId,
    commodity,
    updateCommodityAll,
    onChangeAddStopPointModal,
    setCurrentStopAddIndex,
    currentStopAddIndex,
    currentStopAddValue,
    onChangeCommodityModal,
    shipmentSettings,
    createShipmentData,
    equipmentLengthData,
  } = useContext(ShipmentContext);
  const showToaster = useShowToaster();

  const { use } = useTheme();
  const ref = useRef(null);
  const PLACE = `${namePrefix}[${index}]`;
  const PLACE_ERRORS = errors?.legs && errors?.legs?.[indexPrefix]?.stops?.[index];
  const PLACE_TOUCHED = touched?.legs && touched?.legs?.[indexPrefix]?.stops?.[index];
  const prefix = `legs[${indexPrefix}].stops[${index}]`;
  const currentStop = values.legs[indexPrefix].stops[index];
  const [show, setShow] = useState(false);
  const [condInfoIcon, setCondInfoIcon] = useState({});
  const [selectedEquipment, setSelectedEquipment] = useState({});
  const [selectedStopPointId, setSelectedStopPointId] = useState('');
  const [localEquipmentIdData, setLocalEquipmentIdData] = useState([]);
  const [weightTypes, setWeightTypes] = useState([]);
  const [, setMeasurementTypes] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);
  const [thirdPartyLoading, setThirdPartyLoading] = useState(false);
  const flagEquipmentRequired = equipmentRequired.find((e) => +e.key === Number(stop?.equipment_type))?.flag;

  const [customTrailerId, setCustomTrailerId] = useState(null);
  const [customTrailerType, setCustomTrailerType] = useState(null);
  const [customTrailerLength, setCustomTrailerLength] = useState(null);
  const [openAddCommodity, setOpenAddCommodity] = useState(false);
  const [cargoSaved, setCargoSaved] = useState(false);

  const [thirdParty, setThirdParty] = useState(false);
  const [toBeDetermined, setToBeDetermined] = useState(stop?.equipment_tbd);

  const equipment = equipmentRequired?.find((i) => Number(i.key) === Number(currentStop?.equipment_type));
  const length = currentStop?.equipment_type_length
    ? equipmentLengthData?.find((i) => Number(i.key) === Number(currentStop?.equipment_type_length))
    : equipmentLengthData?.[0] || null;
  const equipmentHeightFeet = !!length?.height && length.height / 12;
  const totalValues = getCargoTotals(currentStop.cargos || [], equipmentHeightFeet);

  const getCommodityAll = () => {
    getCommodity().then((res) => {
      if (res && res?.data) {
        updateCommodityAll(res.data);
      }
    });
  };

  const getGrandTotalDefault = () => {
    getGrandTotal()
      .then((res) => {
        if (res && res?.data) {
          const qtyObj = {
            pieces: QUANTITY[0].key,
            units: QUANTITY[1].key,
            qty: QUANTITY[2].key,
          };
          const weightObj = {
            lbs: WEIGHT_TYPE[0].key,
            kgs: WEIGHT_TYPE[1].key,
          };

          setFieldValue(`${prefix}.quantity_type`, String(qtyObj[res.data?.default_qty_type]));
          setFieldValue(`${prefix}.weight_type`, String(weightObj[res.data?.default_weight]));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    getEquipmentIdInType();
  }, []);

  const getEquipmentIdInType = (length_id) => {
    getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8', length_id } }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setLocalEquipmentIdData([...equipmentId]);
        updateEquipmentId([...equipmentId]);
      }
    });
  };

  const onChangeAction = (obj) => {
    const keyAction = +obj.key;
    if (keyAction === 2 || keyAction === 3) {
      const selectedDataLength = equipmentLengthData.find((item) => +item.key === +stop.equipment_type_length);
      getEquipmentIdInType(selectedDataLength?.key);
    }
  };

  const onChangeTypeLength = (obj) => {
    const key = obj.key;
    getEquipmentIdInType(key);
  };

  const onChangeTimes = (value, name, pointId) => {
    setFieldValue(`${prefix}.${name}`, value);

    if (value) {
      const completedChangeTimeInput = moment(value).isValid();
      const stopPointIdData = pointId || selectedStopPointId;

      if (completedChangeTimeInput && stopPointIdData) {
        const date = moment(stop.scheduled_date).toDate();
        const dayMoment = moment(date).day();
        const day = getDaysNumber(dayMoment);
        const workingHour = stopPointIdData?.stop_point_working_hour || [];
        const thisDay = workingHour.find((el) => +el.week_day === +day);

        const recommendedStart = thisDay?.start
          ? moment(`${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${thisDay?.start}`)
          : null;
        const recommendedEnd = thisDay?.end
          ? moment(`${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${thisDay?.end}`)
          : null;

        if ([1, 3].includes(Number(stop?.scheduled_type))) {
          const statTimeValue = name === 'startTime' ? value : stop.startTime;
          const endTimeValue = name === 'endTime' ? value : stop.endTime;
          const timeDateStart = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(statTimeValue).format('HH:mm')}`
          );
          const timeDateEnd = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(endTimeValue).format('HH:mm')}`
          );

          if (
            timeDateStart.isBefore(recommendedStart) ||
            timeDateStart.isAfter(recommendedEnd) ||
            timeDateEnd.isBefore(recommendedStart) ||
            timeDateEnd.isAfter(recommendedEnd)
          ) {
            setCondInfoIcon({ start: recommendedStart, end: recommendedEnd });
          } else {
            setCondInfoIcon({});
          }
        } else if (Number(stop?.scheduled_type) === 2 || Number(stop?.scheduled_type) === 1 || !stop?.scheduled_type) {
          const timeDateInput = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`
          );
          if (timeDateInput.isBefore(recommendedStart) || timeDateInput.isAfter(recommendedEnd)) {
            setCondInfoIcon({ start: recommendedStart, end: recommendedEnd });
          } else {
            setCondInfoIcon({});
          }
        }
      }
    } else {
      setCondInfoIcon({});
    }
  };

  const onChangeTimeValidate = (date, scheduled_type, field, selectedStop) => {
    if (Number(createShipmentData?.frequency) === 2) {
      return;
    }

    setFieldValue(`${prefix}.${field}`, date);

    if (field === 'scheduled_date') {
      const startDate = moment(date);
      const endDate = moment(currentStop.scheduled_date_to);
      if (endDate.isBefore(startDate)) {
        setFieldValue(`${prefix}.scheduled_date_to`, startDate.toDate());
      }
    }

    const dayMoment = moment(date).day();
    const day = getDaysNumber(dayMoment);
    const type = scheduled_type || stop.scheduled_type;
    const workingHour = (selectedStop || selectedStopPointId)?.stop_point_working_hour || [];
    const thisDay = workingHour.find((el) => +el.week_day === +day);
    if (!!thisDay && [1, 3].includes(+type) && !!(selectedStop || selectedStopPointId)) {
      const end = thisDay?.end || '';
      const start = thisDay?.start || '';
      if (field === 'scheduled_date_to') {
        setFieldValue(`${prefix}.endTime`, moment(end, 'HH:mm').toDate());
      } else {
        setFieldValue(`${prefix}.startTime`, moment(start, 'HH:mm').toDate());
      }
    } else {
      if (field === 'scheduled_date_to') {
        setFieldValue(`${prefix}.endTime`, null);
        setFieldTouched(`${prefix}.endTime`, false);
      } else {
        setFieldValue(`${prefix}.startTime`, null);
        setFieldTouched(`${prefix}.startTime`, false);
      }
    }
  };

  const onChangeStopPointId = (key, updateScheduledType) => {
    const selectedStopPointId = stopPoints.find((el) => +el.key === +key);
    setSelectedStopPointId(selectedStopPointId);
    if (selectedStopPointId?.working_hour_by && updateScheduledType) {
      setFieldValue(`${prefix}.scheduled_type`, selectedStopPointId?.working_hour_by?.toString());
      onChangeScheduledType(
        SCHEDULED_DATE_TYPE.find((i) => i.key === selectedStopPointId?.working_hour_by),
        selectedStopPointId
      );
    }
  };

  const onChangeScheduledType = (e, selectedStopPointId) => {
    if (Number(createShipmentData?.frequency) !== 2) {
      setFieldValue(`${prefix}.endTime`, null);
    }
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, e.key, 'scheduled_date', selectedStopPointId);
    }
    if (stop.scheduled_date_to) {
      const date = moment(stop.scheduled_date_to, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, e.key, 'scheduled_date_to', selectedStopPointId);
    }
  };

  const onChangeEquipmentType = (value) => {
    value?.flag !== 'vehicles' && setFieldValue(`${prefix}.equipment_action`, '');
    return false;
  };

  const shouldUpdateStops = (nextProps, props, name) => {
    const next = nextProps.formik.values?.legs?.[indexPrefix]?.stops?.[index];
    const current = props.formik.values?.legs?.[indexPrefix]?.stops?.[index];
    const nextError = nextProps.formik.errors?.legs?.[indexPrefix]?.stops?.[index];

    switch (name) {
      case 'stop_point_id':
        return true;
      case 'equipment_type':
        return nextProps?.menuStyles.width !== props?.menuStyles.width || nextProps?.options !== props?.options;
      case 'equipment_action':
        return nextError?.options !== props?.options || next?.equipment_type !== current?.equipment_type;
      case 'commodity':
        return nextError?.options !== props?.options;
      case 'equipment_type_length':
        return nextError?.options !== props?.options;
      case 'equipment_id':
        return true;
      default:
    }
  };

  // const onCargoFieldChange = (field, value) => {
  //   setValues((prevState) => {
  //     set(prevState.legs[indexPrefix].stops[index].cargos[0], field, value);
  //
  //     return prevState;
  //   });
  // };

  const onCargoFieldChange = (field, value) => {
    setFieldValue(`${prefix}.cargos[0].${field}`, value);
  };

  const getAdditionalData = async () => {
    try {
      const [{ data: measurement }, { data: quantity }, { data: weight }] = await Promise.all([
        getMeasurementTypes(),
        getQuantityTypes(),
        getWeightTypes(),
      ]);
      setWeightTypes(weight || []);
      setMeasurementTypes(measurement || []);
      setQuantityTypes(quantity || []);
      if (currentStop.cargos?.[0]?.isNew) {
        onCargoFieldChange('expected_quantity_type', quantity?.find((el) => el.name.toLowerCase() === qtyUnit) || null);
        onCargoFieldChange('expected_weight_type', weight?.find((el) => el.unit.toLowerCase() === weightUnit) || null);
        onCargoFieldChange('expected_dimension_unit', measurement?.[1] || null);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    const selectedData = equipmentRequired.find((item) => +item.key === +stop.equipment_type);
    !!selectedData && setSelectedEquipment(selectedData);
    selectedData?.flag === 'vehicles' && setFieldValue(`${prefix}.equipment_type_length`, '');
  }, [equipmentRequired, stop?.equipment_type]);

  useEffect(() => {
    (!stop.weight_type || !stop.quantity_type) && getGrandTotalDefault();
    getCommodityAll();

    setToBeDetermined(!!stop?.equipment_tbd);

    const pointId = stopPoints?.find((el) => +el.key === +stop.stop_point_id);
    onChangeTimes(stop.startTime, 'startTime', pointId);
  }, []);

  useEffect(() => {
    if (!stop?.stop_point_id) {
      return;
    }

    onChangeStopPointId(stop.stop_point_id, false);
  }, [stop.stop_point_id]);

  useEffect(() => {
    if (Array.isArray(stopPoints) && stopPoints.length > 0) {
      const pointId = stopPoints?.find((el) => +el.key === +stop.stop_point_id);
      !!pointId && setSelectedStopPointId(pointId);
    }
  }, [stopPoints, stop]);

  useEffect(() => {
    getAdditionalData();
  }, []);

  const onAddCustomTrailer = () => {
    if (customTrailerType && customTrailerId && customTrailerLength) {
      const body = {
        equipment_type_id: customTrailerType.key,
        equipment_id: customTrailerId,
        length_id: customTrailerLength.key,
      };

      setThirdPartyLoading(true);
      createQuickEquipment(body)
        .then((res) => {
          const label = `${res?.data?.equipment_id || ''}  (${res?.data?.make || ''}  ${res?.data?.model_id || ''})`;
          const newValue = {
            ...res.data,
            key: res.data.id,
            label,
            labelSelected: null,
          };
          setLocalEquipmentIdData([...localEquipmentIdData, newValue]);
          showToaster({ type: 'success', message: 'Success' });
          setCustomTrailerId('');
          setCustomTrailerLength(null);
          setCustomTrailerType(null);
          ref.current.click();
          setFieldValue(`${prefix}.equipment_id`, res.data.id);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        })
        .finally(() => {
          setThirdPartyLoading(false);
        });
    }
  };

  useEffect(() => {
    if (currentStopAddValue && currentStopAddIndex === PLACE) {
      setFieldValue(`${prefix}.stop_point_id`, currentStopAddValue);
    }
  }, [currentStopAddValue]);

  return (
    <div
      ref={ref}
      className={`
            ${sm.pickUp_container}
            ${index === 0 ? sm.first : lastChild === index ? sm.last : ''}
        `}
    >
      {/* Scheduled Date Id */}
      <div className={sm.field_error_wrapper}>
        <CustomSelect
          field={{
            name: `${prefix}.stop_point_id`,
            value: currentStop.stop_point_id,
          }}
          form={{ setFieldValue }}
          isSearchable
          options={stopPoints}
          menuStyles={{ maxWidth: 480 }}
          defaultText='Select Stop Point'
          onChange={(value) => onChangeStopPointId(value?.key, true)}
          onAddStopPoint={() => {
            onChangeAddStopPointModal(true);
            setCurrentStopAddIndex(PLACE);
          }}
          styles={{ maxWidth: 480, height: 32, margin: '8px 0 4px' }}
          shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'stop_point_id')}
          isSearchableStyles={{ maxWidth: 480, width: '100%', margin: '8px 0 4px', height: 32 }}
        />
        <ErrorMessage error={PLACE_TOUCHED?.stop_point_id && PLACE_ERRORS?.stop_point_id} />
      </div>

      {/* Scheduled Date Type */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Scheduled Date Type
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{
              name: `${prefix}.scheduled_type`,
              value: currentStop.scheduled_type,
            }}
            form={{ setFieldValue }}
            menuStyles={{ width: 400 }}
            options={SCHEDULED_DATE_TYPE}
            styles={{ maxWidth: 400, height: 22 }}
            onChange={(e) => onChangeScheduledType(e)}
            shouldUpdate={() => true}
          />
          <ErrorMessage error={PLACE_TOUCHED?.scheduled_type && PLACE_ERRORS?.scheduled_type} />
        </div>
      </div>

      {/* Scheduled Date/Time */}
      {Number(createShipmentData?.frequency) !== 2 && (
        <div className='d-flex justify-content-start align-items-start mt-2'>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start' }}>
            Scheduled Date/Time
          </Typography>
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <div ref={scrollRef}>
                <DatePicker
                  width='130px'
                  size='small'
                  name={`${prefix}.scheduled_date`}
                  value={currentStop.scheduled_date}
                  onChange={(date) => onChangeTimeValidate(date, null, 'scheduled_date')}
                  disablePast={!shipmentSettings?.allow_past_date}
                  error={PLACE_TOUCHED?.scheduled_date && PLACE_ERRORS?.scheduled_date}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={currentStop.startTime}
                  onChange={(time) => onChangeTimes(time, 'startTime')}
                  error={PLACE_TOUCHED?.startTime && PLACE_ERRORS?.startTime}
                />
              </div>
            </div>
            {[1, 3].includes(+stop.scheduled_type) && (
              <div className='d-flex justify-content-start align-items-start gap-2'>
                <Typography variant='s2' className='mt-1'>
                  to
                </Typography>
                <div ref={endDateScrollRef}>
                  <DatePicker
                    width='130px'
                    size='small'
                    name={`${prefix}.scheduled_date_to`}
                    value={currentStop.scheduled_date_to}
                    onChange={(date) => onChangeTimeValidate(date, null, 'scheduled_date_to')}
                    minDate={moment(currentStop.scheduled_date).toDate()}
                    error={PLACE_TOUCHED?.scheduled_date_to && PLACE_ERRORS?.scheduled_date_to}
                  />
                </div>
                <div>
                  <TimePicker
                    size='small'
                    width='100px'
                    value={currentStop.endTime}
                    onChange={(time) => onChangeTimes(time, 'endTime')}
                    error={PLACE_TOUCHED?.endTime && PLACE_ERRORS?.endTime}
                  />
                </div>
              </div>
            )}
          </div>
          {Object.keys(condInfoIcon).length > 0 && (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => (
                <Tooltip id={`${indexPrefix}-${index}-recommended`} {...props}>
                  <span>
                    Stop point may be closed on arrival. Working hours are&nbsp;
                    {moment(condInfoIcon?.start).isValid() && formatTime(condInfoIcon?.start)}&nbsp; to&nbsp;
                    {moment(condInfoIcon?.end).isValid() && formatTime(condInfoIcon?.end)}
                  </span>
                </Tooltip>
              )}
            >
              <div style={{ marginLeft: '12px' }}>
                <WarningIcon width={15} height={15} style={{ cursor: 'pointer' }} />
              </div>
            </OverlayTrigger>
          )}
        </div>
      )}

      {/* Equipment Required and Equipment Required length */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Required
        </Typography>

        <div>
          <div className={sm.field_error_wrapper}>
            <CustomSelect
              field={{
                name: `${prefix}.equipment_type`,
                value: currentStop.equipment_type,
              }}
              form={{ setFieldValue }}
              isSearchable
              styles={{
                width: selectedEquipment?.flag === 'trailers' ? 283 : 400,
                maxWidth: selectedEquipment?.flag === 'trailers' ? 283 : 400,
                height: 22,
                marginRight: 8,
              }}
              isSearchableStyles={{
                width: selectedEquipment?.flag === 'trailers' ? 283 : 400,
                maxWidth: selectedEquipment?.flag === 'trailers' ? 283 : 400,
                height: 22,
                marginRight: 8,
              }}
              shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_type')}
              options={equipmentRequired}
              menuStyles={{ width: selectedEquipment?.flag === 'trailers' ? 283 : 400 }}
              onChange={onChangeEquipmentType}
            />
            <ErrorMessage error={PLACE_TOUCHED?.equipment_type && PLACE_ERRORS?.equipment_type} />
          </div>
        </div>
        <div style={{ display: selectedEquipment?.flag === 'trailers' ? 'block' : 'none' }}>
          <CustomSelect
            field={{
              name: `${prefix}.equipment_type_length`,
              value: currentStop.equipment_type_length,
            }}
            form={{ setFieldValue }}
            name={`${prefix}.equipment_type_length`}
            isSearchable
            value={currentStop.equipment_type_length}
            menuStyles={{ width: 109 }}
            onChange={onChangeTypeLength}
            options={equipmentLengthData}
            shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_type_length')}
            styles={{ width: 109, maxWidth: 109, height: 22 }}
            isSearchableStyles={{ width: 109, maxWidth: 109, height: 22 }}
          />
          <ErrorMessage error={PLACE_TOUCHED?.equipment_type_length && PLACE_ERRORS?.equipment_type_length} />
        </div>
      </div>

      {/* Equipment Action and Equipment */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Action
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{
              name: `${prefix}.equipment_action`,
              value: currentStop.equipment_action,
            }}
            form={{ setFieldValue }}
            onChange={onChangeAction}
            menuStyles={{ width: 400 }}
            disabled={!stop?.equipment_type}
            styles={{ maxWidth: 400, height: 22 }}
            selectFirst={flagEquipmentRequired === 'vehicles' && Number(currentStop?.equipment_type) !== 1}
            shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_action')}
            options={
              flagEquipmentRequired === 'trailers' || Number(currentStop?.equipment_type) === 1
                ? EQUIPMENT_ACTION
                : flagEquipmentRequired === 'vehicles'
                ? [EQUIPMENT_ACTION[0]]
                : []
            }
          />
          <ErrorMessage error={PLACE_TOUCHED?.equipment_action && PLACE_ERRORS?.equipment_action} />
        </div>
      </div>

      {(+stop.equipment_action === 2 || +stop.equipment_action === 3) && (
        <div className={sm.field_wrapper}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
            Equipment
          </Typography>
          <SubRight style={{ width: 20 }} />
          <div style={{ flexGrow: 1 }}>
            <CustomSelect
              field={{
                name: `${prefix}.equipment_id`,
                value: currentStop.equipment_id,
              }}
              form={{ setFieldValue }}
              isSearchable
              disabled={toBeDetermined}
              styles={{ maxWidth: 380, height: 22 }}
              menuStyles={{ width: 380 }}
              isSearchableStyles={{ width: 380, height: 22 }}
              options={localEquipmentIdData}
              shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
            />
            <ErrorMessage error={PLACE_TOUCHED?.equipment_id && PLACE_ERRORS?.equipment_id} />

            {(+stop.equipment_action === 2 || +stop.equipment_action === 3) && (
              <div>
                <div style={{ maxWidth: '380px', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  <CustomCheckbox
                    checked={thirdParty}
                    onChange={(checked) => {
                      setFieldValue(`${prefix}.thirdParty`, checked);
                      if (checked) {
                        setThirdParty(true);
                        setToBeDetermined(false);
                        setFieldValue(`${prefix}.equipment_id`, '');
                      } else {
                        setThirdParty(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      3rd party
                    </Typography>
                  </CustomCheckbox>
                  <CustomCheckbox
                    checked={toBeDetermined}
                    onChange={(checked) => {
                      if (checked) {
                        setFieldValue(`${prefix}.equipment_tbd`, 1);
                        setToBeDetermined(true);
                        setThirdParty(false);
                        setFieldValue(`${prefix}.thirdParty`, false);
                        setFieldValue(`${prefix}.equipment_id`, '');
                      } else {
                        setFieldValue(`${prefix}.equipment_tbd`, 0);
                        setToBeDetermined(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      To be determined (TBD)
                    </Typography>
                  </CustomCheckbox>
                </div>
                {thirdParty && (
                  <div className='d-flex gap-3 mb-2' style={{ width: 400, marginTop: 10 }}>
                    <CustomInput
                      value={customTrailerId}
                      labelStyle={{ margin: 0 }}
                      style={{
                        borderRadius: 6,
                        paddingLeft: 8,
                        width: 90,
                      }}
                      placeholder='ID'
                      onChange={(e) => {
                        setCustomTrailerId(e.target.value);
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Type'
                      options={equipmentRequired.filter((item) => item.flag === 'trailers')}
                      value={customTrailerType}
                      onChange={(e, value) => {
                        setCustomTrailerType(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Length'
                      options={equipmentLengthData}
                      value={customTrailerLength}
                      onChange={(e, value) => {
                        setCustomTrailerLength(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <div className='d-flex gap-2'>
                      <CustomButton
                        type='secondary'
                        leftIcon={<DeleteIcon fill={palette.red500} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', margin: 0 }}
                        onClick={() => {
                          setCustomTrailerId('');
                          setCustomTrailerLength(null);
                          setCustomTrailerType(null);
                          setThirdParty(false);
                          setFieldValue(`${prefix}.thirdParty`, false);
                          setFieldValue(`${prefix}.equipment_id`, '');
                        }}
                        disabled={thirdPartyLoading}
                      />
                      <CustomButton
                        type='primary'
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddCustomTrailer();
                        }}
                        leftIcon={<CheckIcon fill={palette.white} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', marginTop: 0 }}
                        disabled={thirdPartyLoading}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Pick Up Number */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Pick Up Number
        </Typography>
        <div className={sm.field_error_wrapper}>
          <CustomInput
            name={`${prefix}.number`}
            className={sm.customInputField}
            value={currentStop.number}
            onChange={(e) => setFieldValue(`${prefix}.number`, e.target.value)}
          />
        </div>
      </div>

      {/* Commodity */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Commodity
          <SettingsIcon style={{ marginLeft: 4, cursor: 'pointer' }} onClick={() => onChangeCommodityModal(true)} />
        </Typography>
        <div className='d-flex align-items-center gap-3 flex-grow-1'>
          <div>
            <Autocomplete
              width='298px'
              size='small'
              labelKey='title'
              options={commodity}
              value={
                stop.cargos?.length > 1
                  ? { id: 'multi-commodity', title: 'Multi-Commodities' }
                  : stop.cargos[0]?.commodity || null
              }
              disabled={stop.cargos?.length > 1}
              onChange={(e, value) => onCargoFieldChange('commodity', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={
                (typeof PLACE_ERRORS?.cargos === 'string' && PLACE_ERRORS.cargos) ||
                (PLACE_TOUCHED?.cargos?.[0]?.commodity && PLACE_ERRORS?.cargos?.[0]?.commodity)
              }
            />
          </div>
          <Typography
            variant='s2'
            style={{ color: palette.indigo500 }}
            className='pointer'
            onClick={() => setOpenAddCommodity(true)}
          >
            {cargoSaved || currentStop.cargos?.length > 1 ? 'View/Add Multiple' : 'Add Multiple'}
          </Typography>
        </div>
      </div>

      {/* Qty */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Qty
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ marginTop: '10px' }}>
              <SMergedInputs>
                <Input
                  width='250px'
                  type='number'
                  size='small'
                  name='cargos[0].expected_quantity'
                  value={totalValues.expected_quantity}
                  onChange={(e) => onCargoFieldChange('expected_quantity', e.target.value)}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                  disabled={stop.cargos?.length > 1}
                  error={PLACE_TOUCHED?.cargos?.[0]?.expected_quantity && PLACE_ERRORS?.cargos?.[0]?.expected_quantity}
                />
                <div>
                  <Autocomplete
                    width='150px'
                    size='small'
                    name='cargos[0].expected_quantity_type'
                    options={quantityTypes}
                    value={stop.cargos[0]?.expected_quantity_type || null}
                    onChange={(e, value) => onCargoFieldChange('expected_quantity_type', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={stop.cargos?.length > 1}
                    error={
                      PLACE_TOUCHED?.cargos?.[0]?.expected_quantity_type &&
                      PLACE_ERRORS?.cargos?.[0]?.expected_quantity_type
                    }
                  />
                </div>
              </SMergedInputs>
            </div>
          </div>
        </div>
      </div>

      {/* Dimensions */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Dimensions
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {currentStop.cargos?.length === 1 && (
            <>
              <div style={{ marginTop: '10px' }}>
                <InputWithText
                  width='90px'
                  type='number'
                  size='small'
                  text='L'
                  name='cargos[0].expected_dimensions.l'
                  value={totalValues.length}
                  onChange={(e) => onCargoFieldChange('expected_dimensions.l', e.target.value)}
                  disabled={stop.cargos?.length > 1}
                  onKeyDown={blockNonPositiveNumbers}
                  error={
                    PLACE_TOUCHED?.cargos?.[0]?.expected_dimensions?.l &&
                    PLACE_ERRORS?.cargos?.[0]?.expected_dimensions?.l
                  }
                />
              </div>

              <div className={sm.dimensions_x}>x</div>
              <div style={{ marginTop: '10px' }}>
                <InputWithText
                  width='90px'
                  type='number'
                  size='small'
                  text='W'
                  name='cargos[0].expected_dimensions.w'
                  value={totalValues.width}
                  onChange={(e) => onCargoFieldChange('expected_dimensions.w', e.target.value)}
                  disabled={stop.cargos?.length > 1}
                  onKeyDown={blockNonPositiveNumbers}
                  error={
                    PLACE_TOUCHED?.cargos?.[0]?.expected_dimensions?.w &&
                    PLACE_ERRORS?.cargos?.[0]?.expected_dimensions?.w
                  }
                />
              </div>

              <div className={sm.dimensions_x}>x</div>
              <div style={{ marginTop: '10px' }}>
                <InputWithText
                  width='90px'
                  type='number'
                  size='small'
                  text='H'
                  name='cargos[0].expected_dimensions.h'
                  value={totalValues.height}
                  onChange={(e) => onCargoFieldChange('expected_dimensions.h', e.target.value)}
                  disabled={stop.cargos?.length > 1}
                  onKeyDown={blockNonPositiveNumbers}
                  error={
                    PLACE_TOUCHED?.cargos?.[0]?.expected_dimensions?.h &&
                    PLACE_ERRORS?.cargos?.[0]?.expected_dimensions?.h
                  }
                />
              </div>
            </>
          )}

          {currentStop.cargos?.length > 1 && (
            <div style={{ marginTop: '10px' }} className='ms-2'>
              <Typography variant='b2'>
                {formatNumber(totalValues.volumeInCubicFeet)} <Typography variant='c3'>CU. FT.</Typography>
              </Typography>
            </div>
          )}
        </div>
      </div>

      {/* Weight */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Weight
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ marginTop: '10px' }}>
              <SMergedInputs>
                <Input
                  width='250px'
                  type={stop.cargos?.length > 1 ? 'text' : 'number'}
                  size='small'
                  name='cargos[0].expected_weight'
                  value={
                    stop.cargos?.length > 1 ? formatNumber(totalValues.expected_weight) : totalValues.expected_weight
                  }
                  onChange={(e) => onCargoFieldChange('expected_weight', e.target.value)}
                  onKeyDown={blockNonPositiveNumbers}
                  disabled={stop.cargos?.length > 1}
                  error={PLACE_TOUCHED?.cargos?.[0]?.expected_weight && PLACE_ERRORS?.cargos?.[0]?.expected_weight}
                />
                <div>
                  <Autocomplete
                    width='150px'
                    size='small'
                    labelKey='unit'
                    name='cargos[0].expected_weight_type'
                    options={weightTypes}
                    value={stop.cargos[0]?.expected_weight_type || null}
                    onChange={(e, value) => onCargoFieldChange('expected_weight_type', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={stop.cargos?.length > 1}
                    error={
                      PLACE_TOUCHED?.cargos?.[0]?.expected_weight_type &&
                      PLACE_ERRORS?.cargos?.[0]?.expected_weight_type
                    }
                  />
                </div>
              </SMergedInputs>
            </div>
          </div>
        </div>
      </div>

      {/* Value */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Value
        </Typography>
        <div className={sm.field_error_wrapper}>
          <div style={{ marginTop: '10px' }}>
            <SMergedInputs>
              <InputWithIcon
                wrapperClassName='merged-input'
                width='250px'
                type={stop.cargos?.length > 1 ? 'text' : 'number'}
                size='small'
                name='cargos[0].value'
                value={stop.cargos?.length > 1 ? formatNumber(totalValues.value) : currentStop.cargos[0]?.value}
                onChange={(e) => onCargoFieldChange('value', e.target.value)}
                disabled={stop.cargos?.length > 1}
                onKeyDown={blockNonPositiveNumbers}
                error={PLACE_TOUCHED?.cargos?.[0]?.value && PLACE_ERRORS?.cargos?.[0]?.value}
              />
              <div>
                <Autocomplete
                  width='150px'
                  size='small'
                  options={VALUE_TYPES}
                  name='cargos[0].value_type'
                  value={stop.cargos?.length > 1 ? VALUE_TYPES[1] : stop.cargos[0]?.value_type || null}
                  onChange={(e, value) => onCargoFieldChange('value_type', value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={stop.cargos?.length > 1}
                  error={PLACE_TOUCHED?.cargos?.[0]?.value_type && PLACE_ERRORS?.cargos?.[0]?.value_type}
                />
              </div>
            </SMergedInputs>
          </div>
        </div>
      </div>

      {/* Stop Notes */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Stop Notes
        </Typography>
        <CustomTextArea
          field={{ value: currentStop.stop_notes }}
          styleLabel={{ marginLeft: 36 }}
          name={`${prefix}.stop_notes`}
          styles={{ maxWidth: 400, height: 22 }}
          value={currentStop.stop_notes}
          onChange={(value) => setFieldValue(`${prefix}.stop_notes`, value)}
        />
      </div>

      {/* {bill_type} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.bill_type}
          name={`${prefix}.bill_type`}
          onChange={(checked) => setFieldValue(`${prefix}.bill_type`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Billable Move
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {dock_high} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.dock_high}
          name={`${prefix}.dock_high`}
          onChange={(checked) => setFieldValue(`${prefix}.dock_high`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Dock High
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {stackable} */}
      {/* <div className={sm.field_wrapper_textArea}> */}
      {/*  <Typography variant='s2' style={{ width: 157 }} /> */}
      {/*  <CustomCheckbox */}
      {/*    checked={currentStop.stackable} */}
      {/*    name={`${prefix}.stackable`} */}
      {/*    onChange={(checked) => setFieldValue(`${prefix}.stackable`, checked)} */}
      {/*  > */}
      {/*    <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}> */}
      {/*      Stackable */}
      {/*    </Typography> */}
      {/*  </CustomCheckbox> */}
      {/* </div> */}

      {/* {liftgate_service} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.liftgate_service}
          name={`${prefix}.liftgate_service`}
          onChange={(checked) => setFieldValue(`${prefix}.liftgate_service`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Liftgate Service
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {labor_required} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.labor_required}
          name={`${prefix}.labor_required`}
          onChange={(checked) => setFieldValue(`${prefix}.labor_required`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Labor Required (Loading)
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {hazardous_materials} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.hazardous_materials}
          name={`${prefix}.hazardous_materials`}
          onChange={(checked) => setFieldValue(`${prefix}.hazardous_materials`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Hazardous Materials
          </Typography>
        </CustomCheckbox>
      </div>

      <PopoverCreatShipmentItem
        show={show}
        id={stop.id}
        index={index}
        setShow={(v) => setShow(v)}
        arrayHelpers={arrayHelpers}
        shipmentSettings={shipmentSettings}
      />
      <div className={sm.line_fixed} style={{ backgroundColor: use(palette.gray50, palette.gray400) }} />
      {openAddCommodity && (
        <ShipmentCommodity
          createMode
          open={openAddCommodity}
          onClose={() => setOpenAddCommodity(false)}
          cargo={stop.cargos}
          equipment={equipment ? { ...(equipment || {}), length } : null}
          onSave={(val) => {
            setFieldValue(
              `${prefix}.cargos`,
              val.map((item) => ({ ...item, isNew: false }))
            );
            setCargoSaved(true);

            values.legs.forEach((item, upperIndex) => {
              item.stops.forEach((i, innerIndex) => {
                if (Number(i.stop_point_type) === 2 && upperIndex >= indexPrefix && innerIndex >= index) {
                  setFieldValue(`legs[${upperIndex}].stops[${innerIndex}].connected_cargos`, []);
                }
              });
            });
          }}
        />
      )}
    </div>
  );
};

export default PickUp;
