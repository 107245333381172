import { array, number, object, string } from 'yup';

export const validationSchema = object({
  tabName: string().required('Required').nullable(),
  alert_categories: array()
    .of(
      object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable()
    )
    .min(1, 'Required'),
});
