import React, { useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getNextAvailabilitySettings, updateNextAvailabilitySettings } from 'Api/CompanySettings';
import { getInitialValues } from './NextAvailability.data';
import { SWrapper } from '../StaffDriver.styles';

const NextAvailability = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoadingUpdate(true);
      const body = {
        current_location_update: Number(values.current_location_update),
        pto_update: Number(values.pto_update),
        shipments_update: Number(values.shipments_update),
        consider_operating_states: Number(values.consider_operating_states),
        consider_allowed_equipment: Number(values.consider_allowed_equipment),
      };
      await updateNextAvailabilitySettings(body);

      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleSubmit, handleChange } = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
  });

  const getSettings = async () => {
    try {
      const { data } = await getNextAvailabilitySettings();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Driver Next Availability'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      {loading ? (
        <Loader loading />
      ) : (
        <div className='d-flex flex-column'>
          <div className='w-75 d-flex align-items-start gap-3'>
            <CustomCheckbox
              type='switch'
              name='current_location_update'
              checked={!!values.current_location_update}
              onChange={(checked) => handleChange('current_location_update', checked)}
              smail={false}
              labelStyle={{ marginTop: '4px' }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              If no shipments are planned, and driver is available status, update the next available date and location
              to the current date.{' '}
              <Tooltip title='Enabling this feature, will overwrite any manually set next available date and time from Availability page if Driver has no planned shipments and is available currently. '>
                <InfoIcon width={14} height={14} fill={palette.indigo500} />
              </Tooltip>
            </Typography>
          </div>
          <Divider margin='16px 0' />
          <div className='w-75 d-flex align-items-start gap-3'>
            <CustomCheckbox
              type='switch'
              name='pto_update'
              checked={!!values.pto_update}
              onChange={(checked) => handleChange('pto_update', checked)}
              smail={false}
              labelStyle={{ marginTop: '4px' }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              If the driver has scheduled PTO or absence in the future, consider it and update next available date and
              location after he returns from PTO or absence.
            </Typography>
          </div>
          <Divider margin='16px 0' />
          <div className='w-75 d-flex align-items-start gap-3'>
            <CustomCheckbox
              type='switch'
              name='shipments_update'
              checked={!!values.shipments_update}
              onChange={(checked) => handleChange('shipments_update', checked)}
              smail={false}
              labelStyle={{ marginTop: '4px' }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              If the driver is currently on a shipment, the next available date and time will be the estimated delivery
              time of his latest shipment plus average wait time.
            </Typography>
          </div>
          <Divider margin='16px 0' />
          <div className='w-75 d-flex align-items-start gap-3'>
            <CustomCheckbox
              type='switch'
              name='consider_operating_states'
              checked={!!values.consider_operating_states}
              onChange={(checked) => handleChange('consider_operating_states', checked)}
              smail={false}
              labelStyle={{ marginTop: '4px' }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Consider for Planner Match allowed travel area
            </Typography>
          </div>
          <Divider margin='16px 0' />
          <div className='w-75 d-flex align-items-start gap-3'>
            <CustomCheckbox
              type='switch'
              name='consider_allowed_equipment'
              checked={!!values.consider_allowed_equipment}
              onChange={(checked) => handleChange('consider_allowed_equipment', checked)}
              smail={false}
              labelStyle={{ marginTop: '4px' }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Consider for Planner Match allowed equipment type
            </Typography>
          </div>
        </div>
      )}
    </SWrapper>
  );
};

export default NextAvailability;
