import React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download2.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/exportIcon.svg';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { palette } from 'utils/constants';
import { onExportClick } from '../../../TableSection.data';
import { SWrapper } from '../../../ReadyToInvoice/components/BulkActions/BulkActions.styles';

const BulkActions = ({ data, setOpenMarkPaid, setOpenRevertInvoice, onDownloadClick }) => {
  return (
    <SWrapper>
      <CustomButton
        type='primary'
        title='Mark Paid'
        onClick={() => setOpenMarkPaid(true)}
        leftIcon={<CheckIcon fill={palette.white} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
      />
      <CustomButton
        type='secondary'
        title='Credit Card Payment'
        onClick={() => null}
        leftIcon={<CardIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
      />
      <CustomButton
        type='secondary'
        title='Download'
        onClick={onDownloadClick}
        leftIcon={<DownloadIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
      />
      <CustomButton
        type='secondary'
        title='Export CSV'
        leftIcon={<ExportIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
        onClick={() => onExportClick(data, 'open')}
      />
      <CustomButton
        type='secondary'
        title='Revert'
        leftIcon={<RevertIcon fill={palette.gray700} style={{ marginRight: 10, transform: 'rotate(90deg)' }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
        onClick={() => setOpenRevertInvoice(true)}
      />
    </SWrapper>
  );
};

export default BulkActions;
