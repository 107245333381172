import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getKYUPolicy, updateKYUPolicy } from 'Api/Insurance';
import InsuranceFields from '../components/InsuranceFields';
import { validationSchema } from './validationSchema';
import { getInitialValues, kyuFields } from './KYU.data';
import { SWrapper } from '../Equipment.styles';

const KYU = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoadingUpdate(true);
      const formData = new FormData();
      formData.append('kyu_active', Number(values.kyu_active));
      formData.append('kyu_id', values.kyu_id || '');
      if (typeof values.kyu_attach !== 'string') {
        formData.append('kyu_attach', values.kyu_attach);
      }

      await updateKYUPolicy(formData);
      showToaster({ type: 'success', message: 'KYU settings have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const getSettings = async () => {
    try {
      const { data } = await getKYUPolicy();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='KYU'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={form.handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      <div>{loading ? <Loader loading={loading} /> : <InsuranceFields field={kyuFields} form={form} />}</div>
    </SWrapper>
  );
};

export default KYU;
