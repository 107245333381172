import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/two-way.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as OneTime } from 'assets/icons/createShipment/oneTime.svg';
import { ReactComponent as RecurringLane } from 'assets/icons/createShipment/recurringLane.svg';
import Chip from 'common/Chip';
import Loader from 'common/Loader';
import NoRecords from 'common/NoRecords';
import CustomTooltip from 'common/Tooltip';
import UserInfo from 'components/UserInfo';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ValidationlModal from 'components/PlanToVehicle/ValidationlModal';
import { equipmentTypeText } from 'components/TableShipments/helpers/constants';
import RecommendedBackhaul from 'pages/Planner/Availability/components/RecommendedBackhaul';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { checkDateInterval, getDatesInRange, validateWeight } from 'pages/Planner/Availability/helpers';
import { getAbsenceData } from 'Api/Planner';
import useDateFormat from 'hooks/useDateFormat';
import { getShipmentType } from 'utils/helpers';
import { getAvailableDrivers } from 'Api/PlannerV2';
import { formatNumber, palette, TRUCK_TYPES } from 'utils/constants';
import { availabilityDataConverter } from 'pages/Planner/Availability/converters';
import { STable, STableRow } from 'pages/Planner/Availability/Availability.styles';
import { SPlannerMatch } from './PlannerMatch.styles';

const PlannerMatch = ({ filters, search, updateTabCount }) => {
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);
  const { convertToCustomerTime, formatDate, formatDateTime } = useDateFormat();
  const [driverData, setDriverData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [selectedBackhaul, setSelectedBackhaul] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 10 });
  const [validationModalData, setValidationModalData] = useState({
    title: '',
    header: '',
    shipmentId: null,
    open: false,
  });
  const controller = useRef(new AbortController());

  const selectedShipmentsGrossRevenue = selectedShipments.reduce((acc, cur) => acc + cur.grossRevenue, 0);
  const selectedShipmentsTotalMiles = selectedShipments.reduce((acc, cur) => acc + cur.totalMiles, 0);

  const selectedBackhaulGrossRevenue = selectedBackhaul?.shipment_billing?.reduce(
    (acc, cur) => acc + cur.total_amount,
    0
  );
  const selectedBackhaulTotalMiles =
    Number(selectedBackhaul?.empty_miles || 0) + Number(selectedBackhaul?.loaded_miles || 0);

  const getDrivers = async () => {
    let aborted = false;

    try {
      if (controller?.current) {
        controller.current.abort();
        controller.current = new AbortController();
      }

      setLoading(true);

      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        start_date: filters.dateRange[0] ? moment(filters.dateRange[0]).format('YYYY-MM-DD') : undefined,
        end_date: filters.dateRange[1] ? moment(filters.dateRange[1]).format('YYYY-MM-DD') : undefined,
        query: search || undefined,
        driver_ids: filters.drivers.map((i) => i.id),
        equipment_ids: filters.equipment.map((i) => i.id),
        group_ids: filters.groups.map((i) => i.id),
        city: filters.city && filters.state ? filters.city : undefined,
        state: filters.city && filters.state ? filters.state : undefined,
        zipcode: filters.zipcode ? filters.zipcode : undefined,
        miles: ((filters.city && filters.state) || filters.zipcode) && filters.miles ? filters.miles : undefined,
        radius: filters.radius ? filters.radius : undefined,
        date_span: filters.date_span ? filters.date_span : 0,
      };

      const response = await getAvailableDrivers(params, controller?.current?.signal);
      const convertedData = availabilityDataConverter(
        response.data || [],
        convertToCustomerTime,
        filters.dateRange,
        'planner-match'
      );

      setDriverData({ ...response, data: convertedData });
      setSelectedDriver(convertedData?.[0] || null);
      updateTabCount(response.total);
    } catch (e) {
      if (e.message === 'canceled') {
        aborted = true;
      }
    } finally {
      if (!aborted) {
        setLoading(false);
      }
    }
  };

  const onRowClick = (driver) => {
    if (selectedDriver?.id !== driver.id) {
      setSelectedDriver(driver);
      setSelectedShipments([]);
      setSelectedShipment(null);
    }
  };

  const onShipmentClick = (shipment) => {
    setSelectedShipment(shipment);

    if (selectedShipments.some((i) => i.id === shipment.id)) {
      return;
    }

    handleSelectShipment(true, shipment);
  };

  const navigateToDispatch = async (shipments) => {
    const vehicleData = selectedDriver.equipment[0];
    const uniqShipments = uniqBy(shipments, (item) => item.shipment_id);

    if (uniqShipments?.length > 1) {
      const queryParams = new URLSearchParams();
      queryParams.set('equipment', vehicleData?.id);
      uniqShipments.forEach((item) => {
        queryParams.append('id', item.shipment_id);
      });

      navigate(`/planner/dispatch-multiple?${queryParams.toString()}`);
      return;
    }

    const shipment = uniqShipments[0];

    const messages = [];
    const arr = [];
    if (selectedDriver.certifications.can_travel_usa === 1) {
      arr.push(231);
    }
    if (selectedDriver.certifications.can_travel_canada === 1) {
      arr.push(38);
    }
    if (selectedDriver.certifications.can_travel_mexico === 1) {
      arr.push(142);
    }

    if (selectedDriver.status_data?.id === 4) {
      messages.push('Driver is Out of Service');
    }

    const pickupDate = shipment.shipment_stops.find((item) => +item.stop_point_type === 1)?.scheduled_date;
    const deliveryDate = shipment.shipment_stops.find((item) => +item.stop_point_type === 2)?.scheduled_date;

    const absenceData = await getAbsenceData({ driver_id: selectedDriver.id });

    let isDateInRange = false;
    if (absenceData && absenceData.data && absenceData.data.dates?.length) {
      isDateInRange = checkDateInterval(pickupDate, deliveryDate, absenceData.data.dates);
    }
    if (isDateInRange) {
      messages.push(
        `Driver has an absence scheduled on ${getDatesInRange(
          absenceData.data.dates,
          pickupDate,
          deliveryDate,
          formatDate
        )}`
      );
    }

    if (
      !!shipment.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age_flag &&
      vehicleData.year &&
      moment().year() - Number(vehicleData.year) >
        shipment.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age
    ) {
      messages.push(
        `This customer does not allow to use vehicle older than ${shipment.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age} years`
      );
    }

    if (
      !!shipment.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age_flag &&
      vehicleData?.hooked_to?.year &&
      moment(vehicleData.hooked_to.year).isValid() &&
      moment()
        .subtract(
          shipment.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age,
          'years'
        )
        .isAfter(vehicleData.hooked_to.year)
    ) {
      messages.push(
        `This customer does not allow to use trailer older than ${shipment.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age} years`
      );
    }

    let shouldNavigate = true;
    const restrictedCountryIds = [];

    shipment.shipment_stops.forEach((stop) => {
      if (!arr.includes(stop.stop_point.country.id)) {
        shouldNavigate = false;
        restrictedCountryIds.push(stop.stop_point.country.id);
      }
    });

    if (!shouldNavigate) {
      const restrictedCountries = [...new Set(restrictedCountryIds)].map((item) =>
        item === 231 ? 'USA' : item === 38 ? 'Canada' : item === 142 ? 'Mexico' : ''
      );
      messages.push(`Driver cannot travel to ${restrictedCountries.join(', ')}`);
    }

    const weight = vehicleData?.vehicleData || vehicleData?.hooked_to?.cargo_maximum_capacity_weight;
    const weightType = vehicleData?.cargo_capacity_measuring_unit
      ? vehicleData?.cargo_capacity_measuring_unit
      : vehicleData?.hooked_to?.cargo_capacity_measuring_unit;
    if (weight) {
      const validWeight = validateWeight(weight, shipment.shipment_stops);
      if (!validWeight.isValid) {
        const shipmentWeightType = shipment.shipment_stops[0]?.reported_weight_types?.unit;
        messages.push(
          `The shipment weight is ${validWeight?.weight} ${shipmentWeightType} however the equipment you selected can only haul up to ${weight} ${weightType}`
        );
      }
    }

    if (
      shipment.shipment_stops?.some((item) => item.hazardous_materials) &&
      vehicleData?.drivers?.every((i) => !i.certifications?.has_hazmat)
    ) {
      messages.push(`This driver does not have an HAZMAT endorsement`);
    }

    if (messages.length) {
      setValidationModalData({
        open: true,
        header: 'Select Vehicle',
        shipmentId: shipment?.shipment_id,
        title: `Heads up! ${messages.join(', ')}, do you wish to proceed?`,
      });
    } else {
      navigate(`/planner/dispatch?id=${shipment?.shipment_id}&equipment=${vehicleData?.id}`);
    }
  };

  const navigateToOffer = (shipments) => {
    const vehicleData = selectedDriver.equipment[0];
    const uniqShipments = uniqBy(shipments, (item) => item.shipment_id);

    if (uniqShipments.length > 1) {
      const queryParams = new URLSearchParams();
      queryParams.append('equipment', vehicleData.id);
      uniqShipments.forEach((item) => {
        queryParams.append('id', item.shipment_id);
      });

      navigate(`/planner/offer-multiple?${queryParams.toString()}`);
      return;
    }

    navigate(`/planner/offer?id=${uniqShipments[0].shipment_id}&equipment=${vehicleData.id}`);
  };

  const continueSelect = () => {
    const vehicleData = selectedDriver.equipment?.[0];
    if (vehicleData) {
      navigate(`/planner/dispatch?id=${validationModalData?.shipmentId}&equipment=${vehicleData?.id}`);
    }
  };

  const handleSelectShipment = (checked, shipment) => {
    if (checked) {
      setSelectedShipments((prevState) => [...prevState, shipment]);
    } else {
      setSelectedShipments((prevState) => prevState.filter((item) => item.shipment_id !== shipment.shipment_id));
    }

    setSelectedShipment(shipment);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getDrivers();
  }, [filters, search, selectedFilters]);

  return (
    <SPlannerMatch>
      <div className='table-wrapper'>
        {loading ? (
          <TableSkeleton />
        ) : (
          <STable>
            <thead>
              <tr className='nowrap'>
                <th>NAME</th>
                <th>VEHICLE</th>
                <th>NEXT AVAILABLE DATE</th>
                <th>NEXT AVAILABLE LOCATION</th>
                <th>CURRENT RPM</th>
                <th>PROJECTED RPM</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {driverData.data?.map((driver) => (
                <STableRow
                  key={driver.id}
                  className={selectedDriver?.id === driver.id ? 'selected' : ''}
                  onClick={() => onRowClick(driver)}
                >
                  <td>
                    <UserInfo
                      size='32px'
                      statusSize='7px'
                      data={{
                        ...driver,
                        name: `${driver.fname} ${driver.lname}`,
                        image: driver.image,
                        status: driver.status_data?.driver_status,
                        user_type: 'driver',
                      }}
                    />
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver.equipment[0].equipment_id || 'TBD'} {driver.equipment[0].equipment_type?.title}
                      {!!driver.equipment[0].truck_type && ` (${TRUCK_TYPES[driver.equipment[0].truck_type]})`}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver.equipment?.[0]?.next_available_date
                        ? convertToCustomerTime(driver.equipment?.[0]?.next_available_date)
                        : '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver.equipment?.[0]?.next_available_location || '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }} className='nowrap'>
                      {currency}
                      {formatNumber(
                        (Number(driver.driver_gross_revenue) || 0) / (Number(driver.total_miles) || 0)
                      )}{' '}
                      /mile
                    </Typography>
                  </td>
                  <td>
                    {!!selectedShipments?.length && selectedDriver?.id === driver.id ? (
                      <Typography variant='b2' style={{ color: palette.gray700 }} className='nowrap'>
                        {currency}
                        {formatNumber(
                          (Number(
                            driver.driver_gross_revenue +
                              (selectedDriver?.id === driver.id
                                ? (selectedShipmentsGrossRevenue || 0) + (selectedBackhaulGrossRevenue || 0)
                                : 0)
                          ) || 0) /
                            (Number(
                              driver.total_miles +
                                (selectedDriver?.id === driver.id
                                  ? (selectedShipmentsTotalMiles || 0) + (selectedBackhaulTotalMiles || 0)
                                  : 0)
                            ) || 0)
                        )}{' '}
                        /mile
                      </Typography>
                    ) : (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        -
                      </Typography>
                    )}
                  </td>
                  <td>
                    <div className='d-flex align-items-center gap-2'>
                      <TwoWaysIcon />
                      <Chip
                        label={driver.planners?.length || 0}
                        labelColor={palette.indigo500}
                        bgColor={palette.indigo0}
                        radius='20px'
                      />
                    </div>
                  </td>
                </STableRow>
              ))}
            </tbody>
          </STable>
        )}
        {!loading && !driverData?.data?.length && (
          <NoRecords text='The system was unable to match any planner shipments that correspond with your fleet' />
        )}
        {!loading && !!driverData?.data?.length && (
          <Pagination
            data={driverData}
            rowPerPage={selectedFilters.itemsPerPage}
            onPageChange={onPageChange}
            onChangeRowPerPage={onChangeRowPerPage}
            rowsPerPageOptions={[10, 25, 50, 100, 150]}
          />
        )}
      </div>
      <div className='matched-shipments-wrapper'>
        {selectedDriver ? (
          <Typography variant='c2' style={{ color: palette.gray500 }}>
            {selectedDriver.planners?.length
              ? `Our system has matched the following shipments from planner to ${selectedDriver.fname} ${selectedDriver.lname}.`
              : `No shipments matched for this driver.`}
          </Typography>
        ) : (
          <Typography variant='c2' style={{ color: palette.gray500 }}>
            Please select driver to view matched shipments.
          </Typography>
        )}
        <div className='matched-shipments'>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <STable>
              <thead>
                <tr className='nowrap no-border'>
                  <th />
                  <th>SHIPMENT</th>
                  <th>MILES</th>
                  <th>EQUIPMENT TYPE</th>
                  <th>ORIGIN</th>
                  <th />
                  <th>DESTINATION</th>
                  <th>DRIVER PAY</th>
                </tr>
              </thead>
              <tbody>
                {selectedDriver?.planners?.map((shipment) => {
                  const { shipment_id, shipment_type, shipment_billing, reverted, shipment_stops } = shipment;

                  const typeLength = shipment_stops[0]?.equipment_type_length_value?.length;
                  const equipmentType = shipment_stops[0]?.equipment_type_data?.title;
                  const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;
                  const firstNullArrivalDate = shipment_stops.find((stop) => !stop.arrival_date);

                  const { stop_point, scheduled_type, scheduled_date, scheduled_date_to, from, to } =
                    shipment_stops[0] || {};
                  const {
                    stop_point: destination_stop_point,
                    scheduled_type: destination_scheduled_type,
                    scheduled_date: destination_scheduled_date,
                    scheduled_date_to: destination_scheduled_date_to,
                    from: destination_from,
                    to: destination_to,
                  } = shipment_stops[shipment_stops.length - 1] || {};

                  const originStopName = stop_point?.location_name;
                  const originCity = stop_point?.city?.name;
                  const originState = stop_point?.state?.short_name;
                  const originZipcode = stop_point?.zipcode;
                  const originScheduledType = scheduled_type;
                  const scheduledOrigin = scheduled_date;
                  const scheduledOriginTo = scheduled_date_to || scheduled_date;
                  const originFromDate = from;
                  const originToDate = to;
                  const originFrom = `${scheduledOrigin} ${originFromDate || ''}`;
                  const originTo = `${scheduledOriginTo} ${originToDate || ''}`;

                  const destinationStopName = destination_stop_point?.location_name;
                  const destinationCity = destination_stop_point?.city?.name;
                  const destinationState = destination_stop_point?.state?.short_name;
                  const destinationZipcode = destination_stop_point?.zipcode;
                  const destinationScheduledType = destination_scheduled_type;
                  const scheduledDestination = destination_scheduled_date;
                  const scheduledDestinationTo = destination_scheduled_date_to || destination_scheduled_date;
                  const destinationFromDate = destination_from;
                  const destinationToDate = destination_to;
                  const destinationFrom = `${scheduledDestination} ${destinationFromDate || ''}`;
                  const destinationTo = `${scheduledDestinationTo} ${destinationToDate || ''}`;

                  return (
                    <Fragment key={shipment.id}>
                      <tr
                        className={classNames(`matched-shipment no-border`, {
                          selected: selectedShipments.some((i) => i.id === shipment.id),
                        })}
                        onClick={() => onShipmentClick(shipment)}
                      >
                        <td>
                          <span onClick={(e) => e.stopPropagation()}>
                            <CustomCheckbox
                              onChange={(checked) => handleSelectShipment(checked, shipment)}
                              checked={selectedShipments?.some((i) => i.id === shipment.id)}
                            />
                          </span>
                        </td>
                        <td>
                          <div className='d-flex flex-column justify-content-center'>
                            <div>
                              <Typography variant='s2' style={{ color: palette.dark800 }}>
                                {shipment_id}
                              </Typography>
                            </div>
                            {(shipment_billing && shipment_type !== 'ltl') ||
                            (shipment_type === 'ltl' && shipment_billing.length === 1) ? (
                              <Typography variant='s3' style={{ color: palette.dark800 }}>
                                {shipment_billing?.[0]?.reference_id}
                              </Typography>
                            ) : (
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id='button-tooltip-2'>
                                    {shipment_billing?.map((el, i) => (
                                      <p key={el?.id || i} className='m-0'>
                                        {el?.reference_id}
                                      </p>
                                    ))}
                                  </Tooltip>
                                }
                              >
                                {({ ref, ...triggerHandler }) => (
                                  <div ref={ref} className='ellipsis'>
                                    <Typography variant='s3' {...triggerHandler} style={{ color: palette.dark800 }}>
                                      Multi-Ref LTL
                                    </Typography>
                                  </div>
                                )}
                              </OverlayTrigger>
                            )}
                            <div className='d-flex align-items-center gap-1'>
                              <Typography variant='s3'>{getShipmentType(shipment_type)}</Typography>
                              {(!shipment_type || shipment_type === 'ltl') &&
                                (shipment.lane_id ? (
                                  <RecurringLane fill={palette.indigo500} />
                                ) : (
                                  <OneTime fill={palette.indigo500} />
                                ))}
                              {!!reverted && (
                                <CustomTooltip title='Reverted'>
                                  <ReloadIcon fill={palette.red500} />
                                </CustomTooltip>
                              )}
                              {shipment_stops?.some((i) => i.hazardous_materials) && (
                                <Typography variant='c1' style={{ color: palette.orange400 }}>
                                  HAZMAT
                                </Typography>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column align-items-start'>
                            <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                              {Math.round(shipment.empty_miles || 0)}{' '}
                              <Typography variant='c3' style={{ fontSize: '10px' }}>
                                EMPTY
                              </Typography>
                            </Typography>
                            <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                              {Math.round(shipment.loaded_miles || 0)}{' '}
                              <Typography variant='c3' style={{ fontSize: '10px' }}>
                                LOADED
                              </Typography>
                            </Typography>
                            <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                              {Math.round(shipment.loaded_miles || 0) + Math.round(shipment.empty_miles || 0)}{' '}
                              <Typography variant='c3' style={{ fontSize: '10px' }}>
                                TOTAL MILES
                              </Typography>
                            </Typography>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center flex-column'>
                            <Typography variant='s3' style={{ color: palette.dark800 }}>
                              {type}
                            </Typography>
                            <Typography variant='s3' style={{ color: palette.dark800 }}>
                              {!firstNullArrivalDate?.stop_point_type
                                ? '-'
                                : equipmentTypeText({
                                    type: firstNullArrivalDate.stop_point_type,
                                    action: firstNullArrivalDate.equipment_action,
                                    equipment: firstNullArrivalDate.equipment,
                                    equipment_tbd: firstNullArrivalDate.equipment_tbd,
                                  })}
                            </Typography>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column justify-content-center'>
                            <Typography variant='b3'>{originStopName}</Typography>
                            <Typography variant='s3' style={{ color: palette.dark800 }}>
                              {originCity && `${originCity},`} {originState}
                              {originZipcode && `, ${originZipcode}`}
                            </Typography>
                            <Typography variant='b3'>
                              {formatDateTime(`${originFrom}`)}
                              {!!originToDate && [1, 3].includes(Number(originScheduledType))
                                ? ` to ${formatDateTime(originTo)}`
                                : ''}
                              {originScheduledType
                                ? ` (${SCHEDULED_DATE_TYPE_OBJ[originScheduledType].shortName})`
                                : ''}
                            </Typography>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <ArrowRight width={18} height={18} fill={palette.gray700} />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column justify-content-center'>
                            <Typography variant='b3'>{destinationStopName}</Typography>
                            <Typography variant='s3' style={{ color: palette.dark800 }}>
                              {destinationCity && `${destinationCity},`} {destinationState}
                              {destinationZipcode && `, ${destinationZipcode}`}
                            </Typography>
                            <Typography variant='b3'>
                              {formatDateTime(`${destinationFrom}`)}
                              {!!destinationToDate && [1, 3].includes(Number(destinationScheduledType))
                                ? ` to ${formatDateTime(destinationTo)}`
                                : ''}
                              {destinationScheduledType
                                ? ` (${SCHEDULED_DATE_TYPE_OBJ[destinationScheduledType].shortName})`
                                : ''}
                            </Typography>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column align-items-start'>
                              <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                                {shipment.cost === null ? 'Flat' : `${currency}${formatNumber(shipment.cost)}`}
                              </Typography>
                              <Typography
                                className='nowrap'
                                variant='s3'
                                style={{
                                  color:
                                    shipment.grossRevenue - (shipment.cost || 0) < 0
                                      ? palette.red500
                                      : palette.green500,
                                }}
                              >
                                {currency}
                                {shipment.grossRevenue} ({shipment.grossRevenue - (shipment.cost || 0) < 0 ? '' : '+'}
                                {formatNumber(
                                  ((shipment.grossRevenue - (shipment.cost || 0)) / shipment.grossRevenue) * 100
                                )}
                                %)
                              </Typography>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {selectedShipment?.id === shipment.id && (
                        <tr
                          className={classNames(`matched-shipment no-border`)}
                          style={{ backgroundColor: palette.white }}
                        >
                          <td colSpan={8}>
                            <RecommendedBackhaul
                              driver={selectedDriver}
                              shipment={shipment}
                              selectedBackhaul={selectedBackhaul}
                              setSelectedBackhaul={setSelectedBackhaul}
                              filters={filters}
                              search={search}
                            />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </STable>
          )}
          {!![...selectedShipments, ...(selectedBackhaul ? [selectedBackhaul] : [])].length && (
            <div className='matched-shipments-footer'>
              <div>
                <Typography variant='s2' style={{ color: palette.gray500 }}>
                  You have selected {[...selectedShipments, ...(selectedBackhaul ? [selectedBackhaul] : [])].length}{' '}
                  shipment
                  {[...selectedShipments, ...(selectedBackhaul ? [selectedBackhaul] : [])].length === 1
                    ? ''
                    : 's'} for {selectedDriver.fname} {selectedDriver.lname}
                </Typography>
              </div>
              <div className='d-flex align-items-center gap-3'>
                {selectedDriver?.app?.driver_type === 'company' &&
                  !!selectedDriver?.compensation_details?.allow_shipment_offers && (
                    <CustomButton
                      onClick={() =>
                        navigateToOffer([...selectedShipments, ...(selectedBackhaul ? [selectedBackhaul] : [])])
                      }
                      type='primary'
                      title='Offer'
                      styleButton={{ padding: '6px 12px', margin: 0 }}
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                    />
                  )}
                <CustomButton
                  onClick={() =>
                    navigateToDispatch([...selectedShipments, ...(selectedBackhaul ? [selectedBackhaul] : [])])
                  }
                  type='primary'
                  title='Dispatch'
                  styleButton={{ padding: '6px 12px', margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ValidationlModal
        isOpen={!!validationModalData.open}
        setIsOpen={() => setValidationModalData((prevState) => ({ ...prevState, open: false }))}
        onAddBtn={continueSelect}
        data={validationModalData}
      />
    </SPlannerMatch>
  );
};

export default PlannerMatch;
