import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;

  .header-row {
    border-top: 1px solid ${palette.gray50};
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 12px 10px 12px;
      white-space: nowrap;
    }

    .settings-gear {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    &.bg-gray {
      background: ${palette.gray0};
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 12px 10px;
    }

    :hover {
      background: ${palette.gray0};
    }
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SMergedInputs = styled.div`
  display: flex;

  input,
  .merged-input {
    vertical-align: top;
    border-radius: 6px 0 0 6px;
  }

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 25px;
  }

  .middle-input {
    .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: 0;
    }
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;
