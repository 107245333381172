import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as SystemIcon } from 'assets/icons/arrows-circular.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrows.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { deleteAddition } from 'Api/Payroll';
import { getAdditionsList } from 'Api/PayrollSettings';
import DisputedActions from 'pages/Payroll/shared/DisputedActions';
import Recurring from './components/Recurring';
import AdditionsSettings from './components/AdditionsSettings';
import DeclineReason from '../../components/DeclineReason';
import { userTypes } from '../Tables.data';
import { getInitialValues } from './Additions.data';
import { calcAppliedAmount } from '../../helpers';
import { oneTimeAdditionType } from '../../converters';
import { STable, SWrapper, SAddMore, SCheckboxWrapper, SPickerWrapper, SAutocomplete, SInput } from '../Tables.styles';

const Additions = ({
  additions,
  user_type,
  updateAdditions,
  absences,
  detention,
  overtime,
  oneTimeAdditions,
  updateAbsences,
  updateDetention,
  updateOvertime,
  updateOneTimeAdditions,
  settlement,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loadingDeleteId, setLoadingDeleteId] = useState(null);
  const [additionTypes, setAdditionTypes] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(null);
  const [additionToDecline, setAdditionToDecline] = useState(null);

  const totalAppliedAdditions = useMemo(() => {
    return (
      calcAppliedAmount(additions) +
      calcAppliedAmount(absences || []) +
      calcAppliedAmount(detention || []) +
      calcAppliedAmount(overtime || []) +
      calcAppliedAmount(oneTimeAdditions || [])
    );
  }, [additions, absences, detention, overtime, oneTimeAdditions]);

  const getAdditionTypes = async () => {
    try {
      const { data } = await getAdditionsList();
      setAdditionTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onMouseEnter = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(row.id);
    const newAdditions = additions.map((item) =>
      item.id === row.id ? { ...item, edit: true } : { ...item, edit: false }
    );
    updateAdditions(newAdditions);
  };

  const onMouseLeave = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(null);
    const newAdditions = additions.map((item) => (item.id === row.id ? { ...item, edit: false } : item));
    updateAdditions(newAdditions);
  };

  const onDateChange = (id, value) => {
    const date = moment(value).format('MM/DD/YYYY');
    const time = moment(value).format('HH:mm:ss');

    const newDeductions = additions.map((item) => (item.id === id ? { ...item, date, time } : item));
    updateAdditions(newDeductions);
  };

  const onTypeChange = (id, value) => {
    const newAdditions = additions.map((item) =>
      item.id === id
        ? {
            ...item,
            addition_type: value,
            quantity: 1,
            per_quantity_amount: value.amount,
            amount: value.amount,
          }
        : item
    );
    updateAdditions(newAdditions);
  };

  const onQuantityChange = (id, value) => {
    const newAdditions = additions.map((item) =>
      item.id === id ? { ...item, quantity: value, amount: Number(item.per_quantity_amount) * Number(value) } : item
    );
    updateAdditions(newAdditions);
  };

  const onAmountChange = (id, value) => {
    const newAdditions = additions.map((item) =>
      item.id === id ? { ...item, per_quantity_amount: value, amount: Number(item.quantity) * Number(value) } : item
    );
    updateAdditions(newAdditions);
  };

  const onDayChange = (id, value) => {
    const newAdditions = additions.map((item) => (item.id === id ? { ...item, day: value } : item));
    updateAdditions(newAdditions);
  };

  const onMonthWeekChange = (id, value) => {
    const newAdditions = additions.map((item) => {
      return item.id === id ? { ...item, month_week: value, day: value === 'Month' ? '1st' : 'Monday' } : item;
    });
    updateAdditions(newAdditions);
  };

  const onApplyChange = (id, checked) => {
    const newAdditions = additions.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateAdditions(newAdditions);
  };

  const onAbsenceApplyChange = (id, checked) => {
    const newAbsences = absences.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateAbsences(newAbsences);
  };

  const onDetentionApplyChange = (id, checked) => {
    const newDetention = detention.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateDetention(newDetention);
  };

  const onOvertimeApplyChange = (id, checked) => {
    const newOvertime = overtime.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateOvertime(newOvertime);
  };

  const onOneTimeAdditionApplyChange = (id, checked) => {
    const newOneTimeAdditions = oneTimeAdditions.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateOneTimeAdditions(newOneTimeAdditions);
  };

  const handleSubmit = (id, newData) => {
    const newAdditions = additions.map((item) => {
      return item.id === id ? newData : item;
    });
    updateAdditions(newAdditions);
  };

  const removeAddition = async (row) => {
    if (row.isNew) {
      const newDeductions = additions.filter((item) => item.id !== row.id);
      updateAdditions(newDeductions);
      return;
    }
    setLoadingDeleteId(row.id);
    try {
      await deleteAddition({ user_type: userTypes[user_type], id: row.id });
      updateAdditions((prevState) => prevState.filter((item) => item.id !== row.id));
      showToaster({ type: 'success', message: 'Addition has been deleted successfully' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingDeleteId(null);
  };

  const onAddMore = () => {
    updateAdditions((prevState) => [...prevState, { ...getInitialValues(), id: Date.now() }]);
  };

  const onSettingsClose = () => {
    getAdditionTypes();
    setSettingsOpen(false);
  };

  const onDecline = async (reason) => {
    if (additionToDecline.type === 'Overtime Pay') {
      const newOvertime = overtime.map((item) =>
        item.id === additionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
      );
      updateOvertime(newOvertime);
      setAdditionToDecline(null);
      return;
    }

    if (additionToDecline.type === 'Paid Time Off (PTO)') {
      const newAbsences = absences.map((item) =>
        item.id === additionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
      );
      updateAbsences(newAbsences);
      setAdditionToDecline(null);
      return;
    }

    if (additionToDecline.type === 'Detention') {
      const newDetention = detention.map((item) =>
        item.id === additionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
      );
      updateDetention(newDetention);
      setAdditionToDecline(null);
      return;
    }

    if (additionToDecline.type === 'one_time_addition') {
      const newOneTimeAdditions = oneTimeAdditions.map((item) =>
        item.id === additionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
      );
      updateOneTimeAdditions(newOneTimeAdditions);
      setAdditionToDecline(null);
      return;
    }

    const newAdditions = additions.map((item) =>
      item.id === additionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
    );
    updateAdditions(newAdditions);
    setAdditionToDecline(null);
  };

  const onApprove = (addition) => {
    if (addition.type === 'Overtime Pay') {
      const newOvertime = overtime.map((item) =>
        item.id === addition.id ? { ...item, dispute_approved: true } : item
      );
      updateOvertime(newOvertime);
      return;
    }

    if (addition.type === 'Paid Time Off (PTO)') {
      const newAbsences = absences.map((item) =>
        item.id === addition.id ? { ...item, dispute_approved: true } : item
      );
      updateAbsences(newAbsences);
      return;
    }

    if (addition.type === 'Detention') {
      const newDetention = detention.map((item) =>
        item.id === addition.id ? { ...item, dispute_approved: true } : item
      );
      updateDetention(newDetention);
      return;
    }

    if (addition.type === 'one_time_addition') {
      const newOneTimeAdditions = oneTimeAdditions.map((item) =>
        item.id === addition.id ? { ...item, dispute_approved: true } : item
      );
      updateOneTimeAdditions(newOneTimeAdditions);
      return;
    }

    const newAdditions = additions.map((item) =>
      item.id === addition.id ? { ...item, dispute_approved: true } : item
    );
    updateAdditions(newAdditions);
  };

  useEffect(() => {
    getAdditionTypes();
  }, []);

  return (
    <SWrapper>
      <Typography variant='h3'>Additions</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>DATE / TIME</th>
            <th>
              ADDITION TYPE
              <GearIcon onClick={() => setSettingsOpen(true)} className='settings-gear' />
            </th>
            <th>QUANTITY</th>
            <th>AMOUNT PER QUANTITY</th>
            <th>TOTAL</th>
            <th>SET INTERVAL</th>
            <th>APPLY TO SETTLEMENT</th>
          </tr>
        </thead>
        <tbody>
          {additions.map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.isNew ? 'bg-gray' : ''} ${
                  item.disputed && settlement.status_id === 4 ? 'disputed' : ''
                } ${item.dispute_approved ? 'dispute-approved' : ''}`}
                onMouseEnter={() => onMouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                <td style={{ width: '16%' }}>
                  {item.edit || item.isNew ? (
                    <SPickerWrapper>
                      <DatePicker
                        showTimeInput
                        showYearDropdown
                        selected={item.date && item.time ? new Date(`${item.date} ${item.time}`) : ''}
                        placeholderText='mm/dd/yyyy hh:mm'
                        dateFormat='MM/dd/yyyy h:mm aa'
                        onChange={(val) => onDateChange(item.id, val)}
                      />
                      <ArrowIcon className='arrow-icon' width={11} height={11} />
                    </SPickerWrapper>
                  ) : (
                    <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                      {formatDateTime(`${item.date} ${item.time}`)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '25%' }}>
                  {item.edit || item.isNew ? (
                    <SAutocomplete
                      labelKey='addition_type'
                      value={item.addition_type || null}
                      onChange={(e, value) => onTypeChange(item.id, value)}
                      isOptionEqualToValue={(option, value) => option.addition_type === value.addition_type}
                      options={additionTypes}
                    />
                  ) : (
                    <Typography variant='b2'>
                      {item.addition_type?.addition_type || 'N/A'}
                      {item.shipment_driver_pay ? ` - Shipment ${item.shipment_driver_pay.shipment_id}` : ''}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  {item.edit || item.isNew ? (
                    <SInput
                      type='number'
                      value={item.quantity || ''}
                      onChange={(e) => onQuantityChange(item.id, e.target.value)}
                      onKeyDown={(e) => {
                        blockNonPositiveNumbers(e);
                        blockNotNumberChars(e);
                      }}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {item.quantity || 0}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  {item.edit || item.isNew ? (
                    <SInput
                      type='number'
                      value={item.per_quantity_amount || ''}
                      onChange={(e) => onAmountChange(item.id, e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {currency}
                      {formatNumber(item.per_quantity_amount || 0)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  <Typography variant='button2'>
                    {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Recurring
                    data={item}
                    onDayChange={onDayChange}
                    onMonthWeekChange={onMonthWeekChange}
                    handleSubmit={handleSubmit}
                  />
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox checked={!!item.apply} onChange={(value) => onApplyChange(item.id, value)} />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Add
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }}>
                  {loadingDeleteId === item.id ? (
                    <span className='loading-wrapper'>
                      <CircularProgress size={16} />
                    </span>
                  ) : (
                    <span className='action-wrapper' onClick={() => removeAddition(item)}>
                      <DeleteIcon width={9} height={9} fill={palette.red500} />
                    </span>
                  )}
                </td>
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''}
                ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setAdditionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {(absences || []).map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
                key={item.id}
              >
                <td style={{ width: '16%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                    <Chip label={formatDate(item.start_date)} />
                    -
                    <Chip label={formatDate(item.end_date)} />
                  </div>
                  <Typography variant='c2'>{item.days === 1 ? '1 day' : `${item.days} days`}</Typography>
                </td>
                <td style={{ width: '25%' }}>
                  <SystemIcon width={20} height={20} fill={palette.green400} />
                  <Typography variant='b2' style={{ marginLeft: '4px' }}>
                    Paid Time Off (PTO)
                  </Typography>
                </td>
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }}>
                  <Typography variant='button2'>
                    {currency}
                    {item.amount}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    One Time Addition
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox checked={!!item.apply} onChange={(value) => onAbsenceApplyChange(item.id, value)} />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Add
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }} />
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''}
                ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}
                  key={item.id}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setAdditionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {(detention || []).map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
                key={item.id}
              >
                <td style={{ width: '16%' }}>
                  <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                    {formatDateTime(item?.date)}
                  </Typography>
                </td>
                <td style={{ width: '25%' }}>
                  <SystemIcon width={20} height={20} fill={palette.green400} />
                  <Typography variant='b2' style={{ marginLeft: '4px' }}>
                    Detention - ID:{item?.shipment_id} - {item.stop_name} ({Math.floor(item.time / 60)}h{' '}
                    {item.time % 60}m)
                  </Typography>
                </td>
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }}>
                  <Typography variant='button2'>
                    {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    One Time Addition
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox
                      checked={!!item.apply}
                      onChange={(value) => onDetentionApplyChange(item.id, value)}
                    />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Add
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }} />
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''}
                ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}
                  key={item.id}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setAdditionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {(overtime || []).map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
                key={item.id}
              >
                <td style={{ width: '16%' }}>
                  <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                    {formatDateTime(item?.date)}
                  </Typography>
                </td>
                <td style={{ width: '25%' }}>
                  <SystemIcon width={20} height={20} fill={palette.green400} />
                  <Typography variant='b2' style={{ marginLeft: '4px' }}>
                    Overtime Pay - ID {item?.time_tracker} - {Math.floor(item.time / 60)}h {item.time % 60}m
                  </Typography>
                </td>
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }}>
                  <Typography variant='button2'>
                    {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    One Time Addition
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox
                      checked={!!item.apply}
                      onChange={(value) => onOvertimeApplyChange(item.id, value)}
                    />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Add
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }} />
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''}
                ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}
                  key={item.id}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setAdditionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {(oneTimeAdditions || []).map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
                key={item.id}
              >
                <td style={{ width: '16%' }}>
                  <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                    {formatDateTime(item?.date)}
                  </Typography>
                </td>
                <td style={{ width: '25%' }}>
                  <Typography variant='b2' style={{ marginLeft: '4px' }}>
                    Shipment {item?.shipment_id} - {oneTimeAdditionType[item.addition_type_id]}
                    {item.addition_type_id === 2 &&
                      !!item.shipment_stop?.stop_point?.location_name &&
                      ` - ${item.shipment_stop.stop_point.location_name}`}
                  </Typography>
                </td>
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }}>
                  <Typography variant='button2'>
                    {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    One Time Addition
                  </Typography>
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox
                      checked={!!item.apply}
                      onChange={(value) => onOneTimeAdditionApplyChange(item.id, value)}
                    />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Add
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }} />
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''}
                ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}
                  key={item.id}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setAdditionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          <tr className='total-row'>
            <td>
              <SAddMore onClick={onAddMore}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                  Add Another...
                </Typography>
              </SAddMore>
            </td>
            <td />
            <td colSpan='2' className='align-end'>
              <Typography variant='button2'>Total applied amount:</Typography>
            </td>
            <td>
              <Typography variant='button2'>
                {currency}
                {formatNumber(totalAppliedAdditions || 0)}
              </Typography>
            </td>
            <td colSpan='3' />
          </tr>
        </tbody>
      </STable>
      <AdditionsSettings open={settingsOpen} onClose={onSettingsClose} />
      <DeclineReason open={!!additionToDecline} onClose={() => setAdditionToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default Additions;
