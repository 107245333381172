import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { updateSalesCommission } from 'Api/Staff';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './UpdateAgent.data';

const UpdateAgent = ({ open, onClose, onSuccess, agent, customerId }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        customer_id: customerId,
        type: Number(values.type),
        flat_per_shipment: values.type === 1 ? Number(values.flat_per_shipment) : 0,
        percentage_per_shipment: values.type === 2 ? Number(values.percentage_per_shipment) : 0,
        percentage_after_driver_pay: values.type === 3 ? Number(values.percentage_after_driver_pay) : 0,
        end_date: moment(values.end_date || new Date()).format('YYYY-MM-DD'),
        is_active: values.is_active,
      };

      await updateSalesCommission({ staffId: agent.user.staff.id, id: agent.id }, body);
      showToaster({ type: 'success', message: 'Sales agent has been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, handleBlur, touchedErrors } = useForm({
    initialValues: getInitialValues(agent),
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Update Sales Agent'
      $bgColor={palette.gray0}
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex gap-4'>
          <div>
            <InputLabel>Flat Per Shipment</InputLabel>
            <div className='d-flex align-items-center gap-2'>
              <Radio
                name='type'
                id='type-1'
                value={1}
                checked={values.type === 1}
                onChange={() => handleChange(`type`, 1)}
              />
              <InputWithIcon
                width='160px'
                type='number'
                name='flat_per_shipment'
                placeholder='0'
                value={values.flat_per_shipment}
                onChange={handleChange}
                onKeyDown={blockNonPositiveNumbers}
                disabled={values.type !== 1}
                error={touchedErrors.flat_per_shipment}
              />
            </div>
          </div>
          <div>
            <InputLabel>% of Shipment Gross</InputLabel>
            <div className='d-flex align-items-center gap-2'>
              <Radio
                name='type'
                id='type-2'
                value={2}
                checked={values.type === 2}
                onChange={() => handleChange(`type`, 2)}
              />
              <InputWithIcon
                width='160px'
                type='number'
                icon='%'
                name='percentage_per_shipment'
                placeholder='0'
                value={values.percentage_per_shipment}
                onChange={handleChange}
                onKeyDown={blockNonPositiveNumbers}
                disabled={values.type !== 2}
                error={touchedErrors.percentage_per_shipment}
              />
            </div>
          </div>
          <div>
            <InputLabel>% of Shipment After Driver Pay</InputLabel>
            <div className='d-flex align-items-center gap-2'>
              <Radio
                name='type'
                id='type-3'
                value={3}
                checked={values.type === 3}
                onChange={() => handleChange(`type`, 3)}
              />
              <InputWithIcon
                width='160px'
                type='number'
                icon='%'
                name='percentage_after_driver_pay'
                placeholder='0'
                value={values.percentage_after_driver_pay}
                onChange={handleChange}
                onKeyDown={blockNonPositiveNumbers}
                disabled={values.type !== 3}
                error={touchedErrors.percentage_after_driver_pay}
              />
            </div>
          </div>
        </div>
        <div className='d-flex gap-4'>
          <div className='date-picker-wrapper'>
            <DatePicker
              required
              name='end_date'
              label='End Date'
              value={values.end_date}
              onBlur={handleBlur}
              onChange={(val) => handleChange(`end_date`, val)}
              disabled={!!values.is_active}
              error={touchedErrors.end_date}
            />
          </div>
          <div style={{ marginTop: '32px' }}>
            <CustomCheckbox checked={values.is_active} onChange={(checked) => handleChange(`is_active`, checked)}>
              <Typography variant='s2' className='ms-2 nowrap' style={{ color: palette.gray700 }}>
                While Active
              </Typography>
            </CustomCheckbox>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateAgent;
