import styled from 'styled-components';
import Input from 'common/Input';

export const SMergedInputs = styled.div`
  display: flex;

  input {
    border-radius: 6px 0 0 6px;
  }
`;

export const SInput = styled(Input)`
  input {
    width: ${({ width }) => width || '120px'} !important;
    padding: 2px 12px;
    height: 32px; !important;
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;
