import React, { useState, useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { getDaysNumber, palette } from 'utils/constants';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomTextArea from 'components/CustomTextArea';
import { DatePicker, TimePicker } from 'common/Pickers';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getAllTrailers } from 'Api/Map';
import { createQuickEquipment } from 'Api/Shipment';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import useDateFormat from 'hooks/useDateFormat';
import ErrorMessage from 'common/ErrorMessage';
import { ShipmentContext } from '../../Layout';
import { PopoverCreatShipmentItem } from '../helpers/PopoverCreatShipment';
import { EQUIPMENT_ACTION_WAYPOINT, SCHEDULED_DATE_TYPE } from '../helpers/constants';
import CustomSelect from '../helpers/CustomSelect';
import sm from '../AddStopPoint/AddStopPoint.module.css';
import CustomInput from '../../helpers/CustomInput';

const Waypoint = ({
  stop,
  values,
  namePrefix,
  index,
  arrayHelpers,
  stopPoints,
  touched,
  errors,
  indexPrefix,
  lastChild,
  setFieldValue,
  setFieldTouched,
}) => {
  const { formatTime } = useDateFormat();
  const showToaster = useShowToaster();
  const scrollRef = useRef(null);
  const endDateScrollRef = useRef(null);
  const {
    updateEquipmentId,
    onChangeAddStopPointModal,
    setCurrentStopAddIndex,
    currentStopAddIndex,
    equipmentRequired,
    currentStopAddValue,
    shipmentSettings,
    createShipmentData,
    equipmentLengthData,
  } = useContext(ShipmentContext);

  const { use } = useTheme();
  const ref = useRef(null);
  const PLACE = `${namePrefix}[${index}]`;
  const PLACE_ERRORS = errors?.legs && errors?.legs?.[indexPrefix]?.stops?.[index];
  const PLACE_TOUCHED = touched?.legs && touched?.legs?.[indexPrefix]?.stops?.[index];
  const prefix = `legs[${indexPrefix}].stops[${index}]`;
  const currentStop = values.legs[indexPrefix].stops[index];
  const [show, setShow] = useState(false);
  const [condInfoIcon, setCondInfoIcon] = useState({});
  const [selectedStopPointId, setSelectedStopPointId] = useState('');
  const [localEquipmentIdData, setLocalEquipmentIdData] = useState([]);
  const [thirdPartyLoading, setThirdPartyLoading] = useState(false);

  const [customTrailerId, setCustomTrailerId] = useState(null);
  const [customTrailerType, setCustomTrailerType] = useState(null);
  const [customTrailerLength, setCustomTrailerLength] = useState(null);

  const [thirdParty, setThirdParty] = useState(false);
  const [toBeDetermined, setToBeDetermined] = useState(stop?.equipment_tbd);

  const getEquipmentIdInType = () => {
    getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8' } }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `
                                     ${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setLocalEquipmentIdData([...equipmentId]);
        updateEquipmentId([...equipmentId]);
      }
    });
  };

  const onChangeAction = (obj) => {
    const key = +obj.key;
    key === 3 && getEquipmentIdInType();
  };

  const onChangeTimes = (value, name, pointId) => {
    setFieldValue(`${prefix}.${name}`, value);

    if (value) {
      const completedChangeTimeInput = moment(value).isValid();
      const stopPointIdData = pointId || selectedStopPointId;

      if (completedChangeTimeInput && stopPointIdData) {
        const date = moment(stop.scheduled_date).toDate();
        const dayMoment = moment(date).day();
        const day = getDaysNumber(dayMoment);
        const workingHour = stopPointIdData?.stop_point_working_hour || [];
        const thisDay = workingHour.find((el) => +el.week_day === +day);

        const recommendedStart = thisDay?.start
          ? moment(`${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${thisDay?.start}`)
          : null;
        const recommendedEnd = thisDay?.end
          ? moment(`${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${thisDay?.end}`)
          : null;

        if ([1, 3].includes(Number(stop?.scheduled_type))) {
          const statTimeValue = name === 'startTime' ? value : stop.startTime;
          const endTimeValue = name === 'endTime' ? value : stop.endTime;
          const timeDateStart = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(statTimeValue).format('HH:mm')}`
          );
          const timeDateEnd = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(endTimeValue).format('HH:mm')}`
          );

          if (
            timeDateStart.isBefore(recommendedStart) ||
            timeDateStart.isAfter(recommendedEnd) ||
            timeDateEnd.isBefore(recommendedStart) ||
            timeDateEnd.isAfter(recommendedEnd)
          ) {
            setCondInfoIcon({ start: recommendedStart, end: recommendedEnd });
          } else {
            setCondInfoIcon({});
          }
        } else if (Number(stop?.scheduled_type) === 2 || Number(stop?.scheduled_type) === 1 || !stop?.scheduled_type) {
          const timeDateInput = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`
          );
          if (timeDateInput.isBefore(recommendedStart) || timeDateInput.isAfter(recommendedEnd)) {
            setCondInfoIcon({ start: recommendedStart, end: recommendedEnd });
          } else {
            setCondInfoIcon({});
          }
        }
      }
    } else {
      setCondInfoIcon({});
    }
  };

  useEffect(() => {
    getEquipmentIdInType();
  }, []);

  const onAddCustomTrailer = () => {
    if (customTrailerType && customTrailerId && customTrailerLength) {
      const body = {
        equipment_type_id: customTrailerType.key,
        equipment_id: customTrailerId,
        length_id: customTrailerLength.key,
      };
      setThirdPartyLoading(true);
      createQuickEquipment(body)
        .then((res) => {
          const label = `${res?.data?.equipment_id || ''}  (${res?.data?.make || ''}  ${res?.data?.model_id || ''})`;
          const newValue = {
            ...res.data,
            key: res.data.id,
            label,
            labelSelected: null,
          };
          setLocalEquipmentIdData([...localEquipmentIdData, newValue]);
          showToaster({ type: 'success', message: 'Success' });
          setCustomTrailerId('');
          setCustomTrailerLength(null);
          setCustomTrailerType(null);
          ref.current.click();
          setFieldValue(`${prefix}.equipment_id`, res.data.id);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        })
        .finally(() => {
          setThirdPartyLoading(false);
        });
    }
  };

  const onChangeTimeValidate = (date, scheduled_type, field, selectedStop) => {
    if (Number(createShipmentData?.frequency) === 2) {
      return;
    }

    setFieldValue(`${prefix}.${field}`, date);

    if (field === 'scheduled_date') {
      const startDate = moment(date);
      const endDate = moment(currentStop.scheduled_date_to);
      if (endDate.isBefore(startDate)) {
        setFieldValue(`${prefix}.scheduled_date_to`, startDate.toDate());
      }
    }

    const dayMoment = moment(date).day();
    const day = getDaysNumber(dayMoment);
    const type = scheduled_type || stop.scheduled_type;
    const workingHour = (selectedStop || selectedStopPointId)?.stop_point_working_hour || [];
    const thisDay = workingHour.find((el) => +el.week_day === +day);
    if (!!thisDay && [1, 3].includes(+type) && !!(selectedStop || selectedStopPointId)) {
      const end = thisDay?.end || '';
      const start = thisDay?.start || '';
      if (field === 'scheduled_date_to') {
        setFieldValue(`${prefix}.endTime`, moment(end, 'HH:mm').toDate());
      } else {
        setFieldValue(`${prefix}.startTime`, moment(start, 'HH:mm').toDate());
      }
    } else {
      if (field === 'scheduled_date_to') {
        setFieldValue(`${prefix}.endTime`, null);
        setFieldTouched(`${prefix}.endTime`, false);
      } else {
        setFieldValue(`${prefix}.startTime`, null);
        setFieldTouched(`${prefix}.startTime`, false);
      }
    }
  };

  const onChangeStopPointId = (key, updateScheduledType) => {
    const selectedStopPointId = stopPoints.find((el) => +el.key === +key);
    setSelectedStopPointId(selectedStopPointId);
    if (selectedStopPointId?.working_hour_by && updateScheduledType) {
      setFieldValue(`${PLACE}.scheduled_type`, selectedStopPointId?.working_hour_by?.toString());
      onChangeScheduledType(
        SCHEDULED_DATE_TYPE.find((i) => i.key === selectedStopPointId?.working_hour_by),
        selectedStopPointId
      );
    }
  };

  const onChangeScheduledType = (e, selectedStopPointId) => {
    if (Number(createShipmentData?.frequency) !== 2) {
      setFieldValue(`${prefix}.endTime`, null);
    }
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, e.key, 'scheduled_date', selectedStopPointId);
    }
    if (stop.scheduled_date_to) {
      const date = moment(stop.scheduled_date_to, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, e.key, 'scheduled_date_to', selectedStopPointId);
    }
  };

  const shouldUpdateStops = (nextProps, props, name) => {
    switch (name) {
      case 'stop_point_id':
        return true;
      case 'equipment_id':
        return true;
      case 'equipment_type':
        return nextProps?.menuStyles.width !== props?.menuStyles.width;
      default:
    }
  };

  useEffect(() => {
    const pointId = stopPoints?.find((el) => +el.key === +stop.stop_point_id);
    onChangeTimes(stop.startTime, 'startTime', pointId);
  }, []);

  useEffect(() => {
    if (!stop?.stop_point_id) {
      return;
    }

    onChangeStopPointId(stop?.stop_point_id, false);
  }, [stop?.stop_point_id]);

  useEffect(() => {
    if (Array.isArray(stopPoints) && stopPoints.length > 0) {
      const pointId = stopPoints?.find((el) => +el.key === Number(stop?.stop_point_id));
      !!pointId && setSelectedStopPointId(pointId);
    }
  }, [stopPoints, stop]);

  useEffect(() => {
    if (currentStopAddValue && currentStopAddIndex === PLACE) {
      setFieldValue(`${prefix}.stop_point_id`, currentStopAddValue);
    }
  }, [currentStopAddValue]);

  return (
    <div
      ref={ref}
      className={`
            ${sm.pickUp_container}
            ${index === 0 ? sm.first : lastChild === index ? sm.last : ''}`}
      style={{ padding: '5px 5px 85px 5px' }}
    >
      {/* Scheduled Date Id */}
      <div className={sm.field_error_wrapper}>
        <CustomSelect
          field={{
            name: `${prefix}.stop_point_id`,
            value: currentStop.stop_point_id,
          }}
          form={{ setFieldValue }}
          isSearchable
          options={stopPoints}
          menuStyles={{ maxWidth: 480 }}
          defaultText='Select Stop Point'
          onChange={(value) => onChangeStopPointId(value?.key)}
          onAddStopPoint={() => {
            onChangeAddStopPointModal(true);
            setCurrentStopAddIndex(PLACE);
          }}
          styles={{ maxWidth: 480, height: 32, margin: '8px 0 4px' }}
          shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'stop_point_id')}
          isSearchableStyles={{ maxWidth: 480, width: '100%', margin: '8px 0 4px', height: 32 }}
        />
        <ErrorMessage error={PLACE_TOUCHED?.stop_point_id && PLACE_ERRORS?.stop_point_id} />
      </div>

      {/* Scheduled Date Type */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Scheduled Date Type
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{
              name: `${prefix}.scheduled_type`,
              value: currentStop.scheduled_type,
            }}
            form={{ setFieldValue }}
            menuStyles={{ width: 400 }}
            options={SCHEDULED_DATE_TYPE}
            styles={{ maxWidth: 400, height: 22 }}
            onChange={(e) => onChangeScheduledType(e)}
            shouldUpdate={() => true}
          />
          <ErrorMessage error={PLACE_TOUCHED?.scheduled_type && PLACE_ERRORS?.scheduled_type} />
        </div>
      </div>
      {/* Scheduled Date/Time */}
      {Number(createShipmentData?.frequency) !== 2 && (
        <div className='d-flex justify-content-start align-items-start mt-2'>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start' }}>
            Scheduled Date/Time
          </Typography>
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <div ref={scrollRef}>
                <DatePicker
                  width='130px'
                  size='small'
                  name={`${prefix}.scheduled_date`}
                  value={currentStop.scheduled_date}
                  onChange={(date) => onChangeTimeValidate(date, null, 'scheduled_date')}
                  disablePast={!shipmentSettings?.allow_past_date}
                  error={PLACE_TOUCHED?.scheduled_date && PLACE_ERRORS?.scheduled_date}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={currentStop.startTime}
                  onChange={(time) => onChangeTimes(time, 'startTime')}
                  error={PLACE_TOUCHED?.startTime && PLACE_ERRORS?.startTime}
                />
              </div>
            </div>
            {[1, 3].includes(+stop.scheduled_type) && (
              <div className='d-flex justify-content-start align-items-start gap-2'>
                <Typography variant='s2' className='mt-1'>
                  to
                </Typography>
                <div ref={endDateScrollRef}>
                  <DatePicker
                    width='130px'
                    size='small'
                    name={`${prefix}.scheduled_date_to`}
                    value={currentStop.scheduled_date_to}
                    onChange={(date) => onChangeTimeValidate(date, null, 'scheduled_date_to')}
                    minDate={moment(currentStop.scheduled_date).toDate()}
                    error={PLACE_TOUCHED?.scheduled_date_to && PLACE_ERRORS?.scheduled_date_to}
                  />
                </div>
                <div>
                  <TimePicker
                    size='small'
                    width='100px'
                    value={currentStop.endTime}
                    onChange={(time) => onChangeTimes(time, 'endTime')}
                    error={PLACE_TOUCHED?.endTime && PLACE_ERRORS?.endTime}
                  />
                </div>
              </div>
            )}
          </div>
          {Object.keys(condInfoIcon).length > 0 && (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => (
                <Tooltip id={`${indexPrefix}-${index}-recommended`} {...props}>
                  <span>
                    Stop point may be closed on arrival. Working hours are&nbsp;
                    {moment(condInfoIcon?.start).isValid() && formatTime(condInfoIcon?.start)}&nbsp; to&nbsp;
                    {moment(condInfoIcon?.end).isValid() && formatTime(condInfoIcon?.end)}
                  </span>
                </Tooltip>
              )}
            >
              <div style={{ marginLeft: '12px' }}>
                <WarningIcon width={15} height={15} style={{ cursor: 'pointer' }} />
              </div>
            </OverlayTrigger>
          )}
        </div>
      )}

      {/* Equipment Action */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Action
        </Typography>

        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{
              name: `${prefix}.equipment_action`,
              value: currentStop.equipment_action,
            }}
            form={{ setFieldValue }}
            onChange={onChangeAction}
            menuStyles={{ width: 400 }}
            styles={{ maxWidth: 400, height: 22 }}
            options={EQUIPMENT_ACTION_WAYPOINT}
          />
          <ErrorMessage error={PLACE_TOUCHED?.equipment_action && PLACE_ERRORS?.equipment_action} />
        </div>
      </div>

      {+stop.equipment_action === 3 && (
        <div className={sm.field_wrapper}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
            Equipment
          </Typography>
          <SubRight style={{ width: 20 }} />
          <div style={{ flexGrow: 1 }}>
            <CustomSelect
              field={{
                name: `${prefix}.equipment_id`,
                value: currentStop.equipment_id,
              }}
              form={{ setFieldValue }}
              isSearchable
              disabled={toBeDetermined}
              styles={{ maxWidth: 380, height: 22 }}
              menuStyles={{ width: 380 }}
              isSearchableStyles={{ width: 380, height: 22 }}
              options={localEquipmentIdData}
              shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
            />
            <ErrorMessage error={PLACE_TOUCHED?.equipment_id && PLACE_ERRORS?.equipment_id} />

            {+stop.equipment_action === 3 && (
              <div>
                <div style={{ maxWidth: '380px', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  <CustomCheckbox
                    checked={thirdParty}
                    onChange={(checked) => {
                      setFieldValue(`${prefix}.thirdParty`, checked);
                      if (checked) {
                        setThirdParty(true);
                        setToBeDetermined(false);
                        setFieldValue(`${prefix}.equipment_id`, '');
                      } else {
                        setThirdParty(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      3rd party
                    </Typography>
                  </CustomCheckbox>
                  <CustomCheckbox
                    checked={toBeDetermined}
                    onChange={(checked) => {
                      if (checked) {
                        setFieldValue(`${prefix}.equipment_tbd`, 1);
                        setToBeDetermined(true);
                        setThirdParty(false);
                        setFieldValue(`${prefix}.equipment_id`, '');
                        setFieldValue(`${prefix}.thirdParty`, false);
                      } else {
                        setFieldValue(`${prefix}.equipment_tbd`, 0);
                        setToBeDetermined(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      To be determined (TBD)
                    </Typography>
                  </CustomCheckbox>
                </div>
                {thirdParty && (
                  <div className='d-flex gap-3 mb-2' style={{ width: 400, marginTop: 10 }}>
                    <CustomInput
                      value={customTrailerId}
                      labelStyle={{ margin: 0 }}
                      style={{
                        borderRadius: 6,
                        paddingLeft: 8,
                        width: 90,
                      }}
                      placeholder='ID'
                      onChange={(e) => {
                        setCustomTrailerId(e.target.value);
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Type'
                      options={equipmentRequired.filter((item) => item.flag === 'trailers')}
                      value={customTrailerType}
                      onChange={(e, value) => {
                        setCustomTrailerType(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Length'
                      options={equipmentLengthData}
                      value={customTrailerLength}
                      onChange={(e, value) => {
                        setCustomTrailerLength(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <div className='d-flex gap-2'>
                      <CustomButton
                        type='secondary'
                        leftIcon={<DeleteIcon fill={palette.red500} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', margin: 0 }}
                        onClick={() => {
                          setCustomTrailerId('');
                          setCustomTrailerLength(null);
                          setCustomTrailerType(null);
                          setThirdParty(false);
                          setFieldValue(`${prefix}.thirdParty`, false);
                          setFieldValue(`${prefix}.equipment_id`, '');
                        }}
                        disabled={thirdPartyLoading}
                      />
                      <CustomButton
                        type='primary'
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddCustomTrailer();
                        }}
                        leftIcon={<CheckIcon fill={palette.white} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', marginTop: 0 }}
                        disabled={thirdPartyLoading}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Stop Notes */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Stop Notes
        </Typography>
        <CustomTextArea
          field={{ value: currentStop.stop_notes }}
          styleLabel={{ marginLeft: 36 }}
          name={`${prefix}.stop_notes`}
          styles={{ maxWidth: 400, height: 22 }}
          value={currentStop.stop_notes}
          onChange={(value) => setFieldValue(`${prefix}.stop_notes`, value)}
        />
      </div>

      {/* {bill_type} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.bill_type}
          name={`${prefix}.bill_type`}
          onChange={(checked) => setFieldValue(`${prefix}.bill_type`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Billable Move
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {dock_high} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.dock_high}
          name={`${prefix}.dock_high`}
          onChange={(checked) => setFieldValue(`${prefix}.dock_high`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Dock High
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {labor_required} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.labor_required}
          name={`${prefix}.labor_required`}
          onChange={(checked) => setFieldValue(`${prefix}.labor_required`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Labor Required (Loading)
          </Typography>
        </CustomCheckbox>
      </div>

      <PopoverCreatShipmentItem
        show={show}
        id={stop.id}
        index={index}
        setShow={(v) => setShow(v)}
        arrayHelpers={arrayHelpers}
        shipmentSettings={shipmentSettings}
      />

      <div className={sm.line_fixed} style={{ backgroundColor: use(palette.gray50, palette.gray400) }} />
    </div>
  );
};

export default React.memo(Waypoint);
