import React from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import CountInput from 'common/CountInput';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';

const Addon = ({ addon, prices, selectedPlanType, onQuantityChange, onDelete, readOnly }) => {
  const { duration } = selectedPlanType || {};
  const addonPrice = prices?.addon_prices?.[addon?.activePlan?.id];
  const amount = (addonPrice?.amount || 0) + (addonPrice?.discount_value || 0);

  return (
    <div className='summary-item'>
      {!readOnly && (
        <DeleteIcon className='remove-cart-item-icon' fill={palette.red500} onClick={() => onDelete(addon)} />
      )}
      <div className='summary-item-image'>
        <img src={addon.image} alt='img' width={60} height={60} />
      </div>
      <div className='summary-item-body'>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {addon?.name}
          </Typography>
          <Typography variant='c1' style={{ color: palette.indigo500 }}>
            ${formatAmount((amount || 0) / addon.quantity)} /{planMapper[duration]?.per}
            {addonPrice?.setup_fees ? ` + One Time Setup Fee $${formatAmount(addonPrice?.setup_fees)}` : ''}
          </Typography>
        </div>
      </div>
      <div className='summary-item-amount'>
        <CountInput
          value={addon.quantity}
          handleChange={(val) => onQuantityChange(addon, val)}
          disabled={addon?.assigned_type?.id === 4 || readOnly}
          minValue={1}
        />
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          ${formatAmount((amount || 0) + (addonPrice?.setup_fees ? Number(addonPrice?.setup_fees) : 0))}
        </Typography>
      </div>
    </div>
  );
};

export default Addon;
