import axios from '../services/axios';

export async function getVendorsList(params) {
  const { data } = await axios.get('account/vendors', { params });
  return data;
}

export async function createVendor(body) {
  const { data } = await axios.post('account/vendors', body);
  return data;
}

export async function updateVendor(id, body) {
  const { data } = await axios.put(`account/vendors/${id}`, body);
  return data;
}

export async function deleteVendor(id) {
  const { data } = await axios.delete(`account/vendors/${id}`);
  return data;
}

export async function updateVendorStatus(id, body) {
  const { data } = await axios.post(`account/vendors/update-status/${id}`, body);
  return data;
}

export async function getPaymentTerms(params) {
  const { data } = await axios.get('customer/payment-terms', { params });
  return data;
}

export async function getNextVendorId() {
  const { data } = await axios.get('account/vendors/nextId');
  return data;
}
