import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { BackdropLoader } from 'common/Loader';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { connectService, getCardNumbers } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';

const ConnectWex = ({ open, onClose, driverId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [cardNumbers, setCardNumbers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCardNumbersList = async () => {
    try {
      const { data } = await getCardNumbers();
      setCardNumbers(data.map((item) => item.card_number));
    } catch (e) {
      // Do nothing
    }
  };

  const connectWexService = async (driverId, cardNumber) => {
    const body = {
      driver_id: driverId,
      connected_service_id: 44,
      fields: [{ id: 219, custom_field_value: cardNumber }],
    };
    try {
      await connectService(body);
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await connectWexService(driverId, values.card_number);
      showToaster({ type: 'success', message: 'User has been successfully assigned' });
      onSuccess(values.card_number);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      card_number: null,
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    getCardNumbersList();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='WEX EFS Fleet Card'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Connect',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <Autocomplete
        required
        label='Card Number'
        placeholder='0000 0000 0000 0000'
        options={cardNumbers}
        value={values.card_number}
        onChange={(e, val) => handleChange('card_number', val)}
        error={touchedErrors.card_number}
        inputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <CardIcon />
            </InputAdornment>
          ),
        }}
      />
      <BackdropLoader loading={loading} />
    </Modal>
  );
};

export default ConnectWex;
