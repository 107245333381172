export const CAN_TRAVEL_TO = [
  {
    id: 1,
    title: 'USA',
    value: 'can_travel_usa',
  },
  {
    id: 2,
    title: 'Canada',
    value: 'can_travel_canada',
  },
  {
    id: 3,
    title: 'Mexico',
    value: 'can_travel_mexico',
  },
];
export const APPROVED_FOR = [
  {
    id: 1,
    title: 'FAST',
  },
  {
    id: 2,
    title: 'TSA',
  },
  {
    id: 3,
    title: 'TWIC',
  },
];
