import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { carrierConfirmShipment, getBrokerageSettings, getCarrierShipment } from 'Api/Carriers';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import { useSelector } from 'react-redux';
import Final from './steps/Final';
import CarrierConfirmationHeader from './components/CarrierConfirmationHeader';
import CarrierConfirmationFooter from './components/CarrierConfirmationFooter';
import { SWrapper, SStepperContent } from './CarrierConfirmation.styles';
import Confirmation from './steps/Confirmation';
import {
  confirmationValidationSchema,
  confirmationValidationSchemaVehicle,
  getConfirmationInitialValues,
  initialValues,
} from './CarrierConfirmation.data';
import { generatePDF } from './GenerateCarrierConfirmation/helpers';

const CarrierConfirmation = () => {
  const { currency } = useSelector((state) => state.root);
  const showToaster = useShowToaster();
  const [company, setCompany] = useState(null);
  const [shipment, setShipment] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { dot, token, shipment_id } = useParams();
  const [barcodeImage, setBarcodeImage] = useState();
  const signPad = useRef({});

  const onNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const onBack = () => {
    if (currentStep === 0) {
      return;
    }

    setCurrentStep((prevState) => prevState - 1);
  };

  const handleConfirmationSubmit = async (values) => {
    setLoading(true);
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const shippersAndConsignees = shipment.shipment_stops
            ?.filter((stop) => Number(stop.stop_point_type) === 1 || Number(stop.stop_point_type) === 2)
            .map((el) => {
              if (Number(el.stop_point_type) === 1) {
                return { ...el, type: 'Shipper' };
              }
              return { ...el, type: 'Consignee' };
            });

          const pickupStops = shippersAndConsignees?.filter((stop) => Number(stop.stop_point_type) === 1);

          const total_weight = pickupStops?.reduce((acc, cur) => acc + Number(cur.weight), 0);
          const total_qty = pickupStops?.reduce((acc, cur) => acc + cur.quantity, 0);

          const { blob } = await generatePDF({
            shipment,
            company,
            shippersAndConsignees,
            currency,
            barcodeImage,
            pickupStops,
            total_weight,
            total_qty,
            values: { ...values, signature: reader.result },
          });

          const formData = new FormData();
          formData.append('doc', blob);
          formData.append('shipment_id', shipment_id);
          formData.append('vehicle_id', values.truck_id);
          formData.append('trailer_id', values.trailer_id);
          formData.append('driver_name', values.driver_name);
          formData.append('driver_phone_number', values.driver_phone_number);
          formData.append('reference_id', values.reference_id);

          await carrierConfirmShipment(dot, token, formData);
          setLoading(false);
          onNext();
        } catch (e) {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
          setLoading(false);
        }
      };
    }, 'image/png');
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getBrokerageSettings(dot, token);
      setCompany(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const confirmationForm = useForm({
    initialValues,
    onSubmit: handleConfirmationSubmit,
    validationSchema: shipment?.brokerage_dispatch?.trailer_type
      ? confirmationValidationSchema
      : confirmationValidationSchemaVehicle,
  });

  const getShipmentInfo = async () => {
    try {
      const params = {
        shipment_id,
      };
      const { data } = await getCarrierShipment(dot, token, params);
      setShipment(data);
      confirmationForm.setValues(getConfirmationInitialValues(data));
      if (data?.brokerage_dispatch?.confirmation_signed) {
        setCurrentStep(1);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCompanyInfo();
    getShipmentInfo();
  }, []);

  const steps = [
    {
      key: 'confirmation',
      onNext: confirmationForm.handleSubmit,
      Component: (
        <Confirmation
          company={company}
          form={confirmationForm}
          shipment={shipment}
          barcodeImage={barcodeImage}
          setBarcodeImage={setBarcodeImage}
          signPad={signPad}
        />
      ),
    },

    { key: 'finalPage', Component: <Final shipment={shipment} /> },
  ];

  return (
    <SWrapper $bgColor={currentStep !== steps.length - 1 ? palette.gray0 : null}>
      <CarrierConfirmationHeader company={company} />
      <SStepperContent>{steps[currentStep]?.Component}</SStepperContent>
      {currentStep !== steps.length - 1 && (
        <CarrierConfirmationFooter
          step={currentStep}
          stepsCount={steps.length}
          onBack={onBack}
          onNext={steps[currentStep]?.onNext || onNext}
          disabled={loading}
        />
      )}
    </SWrapper>
  );
};

export default CarrierConfirmation;
