import styled from 'styled-components';
import { palette } from '../../../../../../utils/constants';

export const SInputLabel = styled.div`
  color: ${palette.gray500};
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 0 0 1px rgb(104 113 130 / 16%);
  border-left: none;
  border-radius: 0 6px 6px 0;
  padding: 5px 10px;
  height: 100%;
`;

export const SWrapper = styled.div`
  display: flex;
  height: 33px;
`;
