import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { formatNumber } from 'utils/constants';
import { numberToWords, SEND_PAYMENT_TYPES_LABELS } from '../../constants';
import classes from './CheckWithMemo.module.scss';

const CheckWithMemo = ({ memoValue, amount, checkNumber, check, company, bank, type, signatureUrl }) => {
  const [scaled, setScaled] = useState(false);
  const cardRef = useRef(null);

  const portalPdfBackDropProps = {
    className: classes.scaledPdfBackDrop,
    onClick: () => setScaled(false),
    style: {
      '--width': `${cardRef?.current?.getBoundingClientRect()?.width}px`,
      '--top': `${cardRef?.current?.getBoundingClientRect().top}px`,
      '--left': `${cardRef?.current?.getBoundingClientRect().left}px`,
    },
  };

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <span>{check?.name}</span>
          <span>{check?.amount && `$${formatNumber(check?.amount)} USD`}</span>
        </div>
        <div className={classes.headerBottom}>
          <span>{SEND_PAYMENT_TYPES_LABELS?.[type]}</span>
          <span>Amount</span>
        </div>
      </div>

      <div ref={cardRef} className={classes.pdfCard} onClick={() => setScaled(true)}>
        <div className={classes.pdfHeader}>
          <div className={classes.pdfHeaderInfo}>
            {company?.logo_path && (
              <img crossOrigin='anonymous' src={`${company?.logo_path}?cache=${Date.now().toString()}`} alt='logo' />
            )}
            <div className={classes.pdfHeaderInfoTexts}>
              <span>{company?.company_name}</span>
              <span>{company?.address1}</span>
              <span>{company?.address2}</span>
              <span>
                {company?.city?.name && `${company?.city?.name},`}{' '}
                {company?.state?.short_name && `${company?.state?.short_name}`} {company?.zip && `${company?.zip}`}
              </span>
            </div>
          </div>
          <div className={classes.pdfHeaderInfo}>
            {!!bank?.account_logo && (
              <img crossOrigin='anonymous' src={`${bank?.account_logo}?cache=${Date.now().toString()}`} alt='logo' />
            )}
            <div className={classes.pdfHeaderInfoTexts}>
              <span>{bank?.account_bank_name}</span>
              <span>{bank?.address1}</span>
              <span>{bank?.address2}</span>
              <span>
                {bank?.city?.name && `${bank?.city?.name},`} {bank?.state?.short_name && `${bank?.state?.short_name}`}{' '}
                {bank?.zipcode && `${bank?.zipcode}`}
              </span>
            </div>
          </div>
          <div className={classes.pdfHeaderDate}>
            <span className={classes.pdfCheckNumber}>{checkNumber}</span>
            <span>
              DATE <b>{moment().format('MM/DD/YYYY')}</b>
            </span>
          </div>
        </div>

        <div className={classes.pdfSection}>
          <div className={classes.pdfOrderOfContainer}>
            <span className={classes.pdfOrderOfTitle}>
              PATH TO THE <br /> ORDER OF
            </span>
            <span className={classes.pdfOrderOfName}>{check?.name}</span>
            <span className={classes.pdfOrderOfDollar}>$</span>
            {amount ? (
              <span className={classes.pdfOrderOfPrice}>{amount && formatNumber(amount)}</span>
            ) : (
              <span className={classes.pdfOrderOfPrice}>{check?.amount && formatNumber(check.amount)}</span>
            )}
          </div>
          <div className={classes.pdfDollars}>
            {amount ? (
              <span>{amount && numberToWords(Number(amount).toFixed(2))}</span>
            ) : (
              <span>{check?.amount && numberToWords(Number(check.amount).toFixed(2))}</span>
            )}
            <span>{'*'.repeat(200)}</span>
            <span>DOLLARS</span>
          </div>
          <div className={classes.pdfUserAddress}>
            <span>{check?.name}</span>
            <span>{check?.address}</span>
            <span>{check?.address2}</span>
            <span>
              {check?.city?.name && `${check?.city?.name},`} {check?.state?.short_name && `${check?.state?.short_name}`}{' '}
              {check?.zipcode && `${check?.zipcode}`}
            </span>
          </div>
        </div>

        <div className={classes.pdfFooter}>
          <div className={classes.pdfFooterTitleWrapper}>
            <div>
              <span>MEMO</span>
              <span style={{ marginTop: memoValue ? 0 : '12px' }}>{memoValue}</span>
            </div>
            <div className={classes.signature}>
              {!!signatureUrl && (
                <img crossOrigin='anonymous' src={`${signatureUrl}?cache=${Date.now().toString()}`} alt='signature' />
              )}
            </div>
          </div>

          <div className={classes.pdfFooterCode}>
            <div className={classes.pdfFooterCodeLeft}>
              <span>a{bank?.account_routing}a</span>
            </div>
            <div className={classes.pdfFooterCodeRight}>
              <span>{bank?.account_number}c</span>
              <span>{checkNumber}</span>
            </div>
          </div>
        </div>
      </div>

      {scaled &&
        cardRef?.current &&
        createPortal(
          <div {...portalPdfBackDropProps}>
            <div
              className={classes.scaledPdf}
              onClick={(e) => e.stopPropagation()}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: cardRef?.current?.outerHTML }}
            />
          </div>,
          document.body
        )}
    </div>
  );
};

export default CheckWithMemo;
