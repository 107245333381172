import { string, object, ref } from 'yup';
import { regExp } from 'utils/helpers';

export const validationSchema = object().shape({
  old_password: string().trim().required('Required').nullable(),
  new_password: string()
    .trim()
    .required('Required')
    .matches(
      regExp.PASSWORD,
      'Password must be at least 8 characters long and must contain 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character'
    )
    .nullable(),
  confirm_password: string()
    .oneOf([ref('new_password')], 'Passwords must match')
    .required('Required')
    .nullable(),
});
