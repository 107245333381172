import React, { useState, useRef } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import calendar from 'assets/icons/drivers/calendar.svg';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
} from 'date-fns';

import './DateRangePicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -31)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
};

const local = {
  format: 'DD-MM-YYYY',
  sundayFirst: false,
};

function RangeCalender({
  dateSelect,
  dateTimeRange,
  setRangeName,
  setIsShowDatePicker,
  timeLineOpen,
  resetMyRoute,
  setIsCenter,
  isCenter,
}) {
  const datePickerRef = useRef(null);
  const [state, setState] = useState([
    {
      startDate: dateTimeRange.start,
      endDate: dateTimeRange.end,
      key: 'selection',
    },
  ]);

  const sideBar = [
    {
      label: 'Live',
      range: () => {
        return {
          startDate: defineds.startOfToday,
          endDate: defineds.startOfToday,
          label: 'Live',
        };
      },
    },
    {
      label: 'Last 7 Days',
      range: () => {
        return {
          startDate: defineds.startOfLastSevenDay,
          endDate: defineds.endOfToday,
          label: 'Last 7 Days',
        };
      },
    },
    {
      label: 'Last 30 Days',
      range: () => {
        return {
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
          label: 'Last 30 Days',
        };
      },
    },

    {
      label: 'Custom Range',
      range: () => {
        return {
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday,
          label: 'Custom Range',
        };
      },
    },
  ];

  const staticRanges = [...createStaticRanges(sideBar)];

  const apply = () => {
    setRangeName(state[0].label);
    dateSelect({
      start: state[0]?.startDate,
      end: state[0]?.endDate,
    });
    if (timeLineOpen) {
      timeLineOpen();
    }

    if (resetMyRoute && typeof resetMyRoute === 'object') {
      resetMyRoute.remove();
      setIsCenter(!isCenter);
    }
    setIsShowDatePicker(false);
  };

  return (
    <div className='datePicker'>
      <div>
        <DateRangePicker
          showSelectionPreview
          ranges={state}
          onChange={(item) => setState([item.selection])}
          months={1}
          // maxDate={addDays(new Date(), 1)}
          direction='vertical'
          scroll={{ enabled: true }}
          showMonthAndYearPickers
          staticRanges={staticRanges}
          inputRanges={[]}
          // fixedHeight={true}
          moveRangeOnFirstSelection={false}
          local={local}
          ref={datePickerRef}
        />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '10px',
            background: '#fff',
          }}
        >
          <div onClick={() => setIsShowDatePicker(false)} style={{ marginRight: '10px' }}>
            <PrimaryBtn title='Close' />
          </div>
          <div onClick={apply}>
            <PrimaryBtn icon={calendar} title='Apply' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RangeCalender;
