const finalTabs = [];

const tabService = {
  getTabs: async () => {
    if (finalTabs.length) {
      return finalTabs;
    }
    return finalTabs;
  },
};

export default tabService;
