import { array, date, object, string } from 'yup';
import moment from 'moment';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  copy_count: string().trim().required('Required').nullable(),
  reference_id: string().trim().required('Required').nullable(),
  stops: array().of(
    object().shape({
      scheduled_date: validator.requiredDate(),
      scheduled_date_to: date().when('scheduled_type', {
        is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
        then: validator.requiredDate().test('Invalid', 'Start date cannot be after end date', (value, context) => {
          const { scheduled_date } = context.parent || {};
          if (!scheduled_date || !moment(scheduled_date).isValid()) {
            return true;
          }

          return moment(scheduled_date).isSameOrBefore(value);
        }),
        otherwise: validator.date(),
      }),
    })
  ),
});
