import React, { useMemo } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { SFlexColumn } from '../Shipments.styles';

const Destination = ({ data }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const destination = useMemo(() => data?.shipment_stops?.findLast((item) => item.stop_point_type === '2'), [data]);

  if (!destination) {
    return (
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        N/A
      </Typography>
    );
  }

  return (
    <SFlexColumn>
      <Typography variant='button2'>
        {formatDate(destination.scheduled_date)} {destination.from}
        {destination?.to && destination?.to !== destination.from && ` - ${destination.to}`}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        {destination?.stop_point?.location_name}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        {destination?.stop_point?.address1}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        {destination?.stop_point?.city?.name}, {destination?.stop_point?.state?.short_name}{' '}
        {destination?.stop_point?.zipcode}
      </Typography>
    </SFlexColumn>
  );
};

export default Destination;
