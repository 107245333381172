import React, { useEffect, useRef, useState } from 'react';
import './Map.css';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import classNames from 'classnames';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import carrierIcon from 'assets/icons/carrier.svg';
import mapMarkerRedIcon from 'assets/icons/createShipment/mapMarkerRed.svg';
import mapMarkerBlueIcon from 'assets/icons/createShipment/mapMarkerBlue.svg';
import { getAlphabet, palette } from 'utils/constants';
import person from 'assets/images/person.svg';
import { Icons } from 'assets/icons';
import { mapFlag } from 'Api/Equipment';
import {
  animateMarker,
  getLiveMarker,
} from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/Map/helpers';
import { PusherJs } from 'common/pusher';
import { usePrevious } from 'hooks/usePrevious';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

const Map = ({
  legs,
  isDraggable = false,
  stopPoints,
  stopPointArr,
  orderStops,
  literalJsx,
  hoverPopover = true,
  styleContainer,
  children,
  routeOptionsOutSide,
  markerToolTip = true,
  stopIconColor = false,
  driversArr = [],
  popoverClass,
  infoHeader,
  isShipmentTable,
}) => {
  const [mapCoords, setMapCoords] = useState(null);
  const [markerAdded, setMarkerAdded] = useState(false);
  const [coords, setCoords] = useState([]);
  const [stops, setStops] = useState([]);
  const [indexForAlphabet, setIndexForAlphabet] = useState([]);
  const [myCustomMap, setMyCustomMap] = useState(null);
  const [routeIsDone, setRouteIsDone] = useState(false);
  const [liveCoords, setLiveCoords] = useState([]);
  const [providerDeviceID, setProviderDeviceID] = useState();
  const [speedForDriver, setSpeedForDriver] = useState();
  const [speedForDriverData, setSpeedForDriverData] = useState(false);
  const [myMarker, setMyMarker] = useState(null);
  const [myDriverMarker, setMyDriverMarker] = useState(null);
  const [liveMarker, setLiveMarker] = useState(null);
  const isInitialMount = useRef(true);
  const [, setDriverSpeed] = useState();
  const dataRaw = localStorage.getItem('user');
  const data = JSON.parse(dataRaw);
  const { brokerage_dispatch } = infoHeader || {};
  const { carrier } = brokerage_dispatch || {};
  const { logo_path } = carrier || {};

  const GPS = PusherJs.subscribe(
    `private-equipmentLocation-Update.${data?.customer?.dot}.${infoHeader?.equipment?.id}`
  );
  const prevStopPointArr = usePrevious(stopPointArr);
  const DRIVERGPS = PusherJs.subscribe(
    `private-driverLocation-Update.${data?.customer?.dot}.${infoHeader?.driver1?.id}`
  );
  const CARRIERGPS = PusherJs.subscribe(`private-update-tracking-location.${data?.customer?.dot}`);
  let mapCoordinates = [];

  const heading =
    infoHeader.equipment?.location_target === 'Equipment'
      ? infoHeader.equipment?.equipment_gps
        ? infoHeader.equipment?.equipment_gps?.heading
        : infoHeader.equipment?.last_known_heading
      : '' || infoHeader.equipment?.location_target === 'Vehicle'
      ? infoHeader.equipment?.hooked_to?.location_target === 'Equipment'
        ? infoHeader.equipment?.hooked_to?.equipment_gps
          ? infoHeader.equipment?.hooked_to?.equipment_gps?.heading
          : infoHeader.equipment?.hooked_to?.last_known_heading
        : infoHeader.equipment?.hooked_to?.location_target === 'Driver'
        ? infoHeader.equipment?.hooked_to?.drivers
          ? infoHeader.equipment?.hooked_to?.drivers.map((v) =>
              v?.driver_gps?.heading
                ? v?.driver_gps?.heading.toString()
                : infoHeader.equipment?.hooked_to?.last_known_heading
            )
          : infoHeader.equipment?.hooked_to?.last_known_heading
        : ''
      : '' || infoHeader.equipment?.location_target === 'carrier'
      ? infoHeader.carrier_gps_latest?.heading
      : '';

  const latitude =
    infoHeader.equipment?.location_target === 'Equipment'
      ? infoHeader.equipment?.equipment_gps
        ? infoHeader.equipment?.equipment_gps?.latitude
        : infoHeader.equipment?.last_known_latitude
      : '' || infoHeader.equipment?.location_target === 'Driver'
      ? infoHeader.equipment?.drivers
        ? infoHeader.equipment?.drivers[0]?.driver_gps?.latitude
        : infoHeader.equipment?.last_known_latitude
      : '' || infoHeader.equipment?.location_target === 'carrier'
      ? infoHeader.carrier_gps_latest?.latitude
      : '';
  const longitude =
    infoHeader.equipment?.location_target === 'Equipment'
      ? infoHeader.equipment?.equipment_gps
        ? infoHeader.equipment?.equipment_gps?.longitude
        : infoHeader.equipment?.last_known_longitude
      : '' || infoHeader.equipment?.location_target === 'Driver'
      ? infoHeader.equipment?.drivers
        ? infoHeader.equipment?.drivers[0]?.driver_gps?.longitude
        : infoHeader.equipment?.last_known_longitude
      : '' || infoHeader.equipment?.location_target === 'carrier'
      ? infoHeader.carrier_gps_latest?.longitude
      : '';

  const vehicle = new Image(); // Image constructor
  vehicle.src = Icons.Vehicle;
  vehicle.alt = 'alt';

  const GreyColorVehicle = new Image(); // Image constructor
  GreyColorVehicle.src = Icons.GreyVehicle;
  GreyColorVehicle.alt = 'alt';
  GreyColorVehicle.setAttribute('style', 'z-index:2;');

  const GreyVehiclePlusTrailer = new Image(); // Image constructor
  GreyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;
  GreyVehiclePlusTrailer.alt = 'alt';
  GreyVehiclePlusTrailer.setAttribute('style', 'z-index:2;');

  const trailer = new Image(); // Image constructor
  trailer.src = Icons.Trailers;
  trailer.alt = 'alt';

  const GreyTrailer = new Image(); // Image constructor
  GreyTrailer.src = Icons.GreyColorTrailer;
  GreyTrailer.alt = 'alt';
  GreyTrailer.setAttribute('style', 'z-index:2;');

  const VehiclePlusTrailer = new Image(); // Image constructor
  VehiclePlusTrailer.src = Icons.VehiclePlusTrailer;
  VehiclePlusTrailer.alt = 'alt';
  VehiclePlusTrailer.setAttribute('style', 'z-index:2;');

  const markers = [];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (infoHeader?.start_date_time) {
        myDriverMarker?.remove();

        /** drivers * */
        const driverMarkers = [];
        const driverIcon = infoHeader?.start_date_time ? mapMarkerIcon : driversArr?.[0]?.img;
        const carrierDriverIcon = infoHeader?.start_date_time ? mapMarkerIcon : logo_path || carrierIcon;

        driversArr?.forEach((driver) => {
          const svgContent = document.createElement('div');
          const driverIconContent = `
                              <div class='myMap_driver_marker_container' id="">
                              
                                   <img src="${carrier ? carrierDriverIcon : driverIcon || person}" alt=''>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                   <span>DEAD HEAD ${driver?.miles} Miles</span>
                                 </div>
                             </div>`;
          const { lat, lng, ...restDriver } = driver?.marker || {};
          const newLng = infoHeader?.driver_from_gps_long;
          const newLat = infoHeader?.driver_from_gps_lat;

          if (!!lat && !!lng) {
            svgContent.innerHTML = driverIconContent;
            const driverMarker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
              ...restDriver,
            }).setLngLat([newLng, newLat]);

            setMyDriverMarker(driverMarker);

            driverMarkers.push(driverMarker);
            markers.push(driverMarker);
            if (myCustomMap) {
              driverMarkers[0] && driverMarkers[0].addTo(myCustomMap);
            }
          }

          mapCoords?.forEach((cord) => {
            if (cord.longitude && cord.latitude && myMarker) {
              myMarker.setLngLat([cord.longitude, cord.latitude]).addTo(myCustomMap);
              markers.push(myMarker);
            }
          });

          let TriggerInterval;

          if (infoHeader?.equipment?.location_target === 'Driver') {
            DRIVERGPS.bind('client-equipment-location', (data) => {
              if (typeof data === 'string') {
                data = JSON.parse(data);
              }

              if (Number(infoHeader?.equipment?.id) === Number(data?.message?.data?.equipment_id) && myMarker) {
                if (infoHeader?.equipment?.location_target === 'Driver') {
                  clearInterval(TriggerInterval);
                  setSpeedForDriver(data?.message?.data?.speed_mph);
                  if (speedForDriverData !== true) {
                    setSpeedForDriverData(true);
                  }
                  setDriverSpeed(data?.message?.data?.speed_mph);
                  const oldLat = myMarker.getLngLat().lat;
                  const oldLng = myMarker.getLngLat().lng;
                  const lat = data?.message?.data?.latitude;
                  const lng = data?.message?.data?.longitude;
                  const is_hooked = data?.message?.data?.is_hooked;
                  const speed = Number(data?.message?.data?.speed_mph) || 0;
                  const mapMarker = myMarker.getElement();
                  const liveMarker = getLiveMarker(infoHeader.equipment, null, speed, is_hooked);
                  const liveSrc = liveMarker.getAttribute('src');
                  mapMarker.setAttribute('src', liveSrc);
                  setLiveMarker(mapMarker);

                  animateMarker(myMarker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (data?.message?.data?.heading) {
                    myMarker.setRotation(data?.message?.data?.heading);
                  }
                  setLiveCoords({
                    latitude: data?.message?.data?.latitude,
                    longitude: data?.message?.data?.longitude,
                  });
                }
              }
            });
          } else if (infoHeader?.equipment?.location_target === 'carrier') {
            CARRIERGPS.bind('update-tracking-location', (e) => {
              if (e?.message?.data?.shipment_id === infoHeader?.shipment_id && carrier) {
                if (myMarker) {
                  clearInterval(TriggerInterval);
                  setSpeedForDriver(65);
                  if (speedForDriverData !== true) {
                    setSpeedForDriverData(true);
                  }
                  setDriverSpeed(65);
                  const oldLat = myMarker.getLngLat().lat;
                  const oldLng = myMarker.getLngLat().lng;
                  const lat = e?.message?.data?.latitude;
                  const lng = e?.message?.data?.longitude;
                  const is_hooked = !!infoHeader?.brokerage_dispatch?.trailer_id;
                  const speed = 65;
                  const mapMarker = myMarker.getElement();
                  const liveMarker = getLiveMarker(infoHeader.equipment, null, speed, is_hooked);
                  const liveSrc = liveMarker.getAttribute('src');
                  mapMarker.setAttribute('src', liveSrc);
                  setLiveMarker(mapMarker);

                  animateMarker(myMarker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (e?.message?.data?.heading) {
                    myMarker.setRotation(e?.message?.data?.heading);
                  }
                  setLiveCoords({
                    latitude: e?.message?.data?.latitude,
                    longitude: e?.message?.data?.longitude,
                  });
                }
              }
            });
          } else {
            GPS.bind('equipment-location', (data) => {
              if (typeof data === 'string') {
                data = JSON.parse(data);
              }
              if (providerDeviceID === data?.message?.data?.provider_device_id && myMarker) {
                if (infoHeader?.equipment?.location_target !== 'Driver') {
                  setDriverSpeed(data?.message?.data?.speed_mph);
                  const oldLat = myMarker.getLngLat().lat;
                  const oldLng = myMarker.getLngLat().lng;
                  const lat = data?.message?.data?.latitude;
                  const lng = data?.message?.data?.longitude;
                  const is_hooked = data?.message?.data?.is_hooked;
                  const speed = Number(data?.message?.data?.speed_mph) || 0;
                  const mapMarker = myMarker.getElement();
                  const liveMarker = getLiveMarker(infoHeader.equipment, null, speed, is_hooked);
                  const liveSrc = liveMarker.getAttribute('src');
                  mapMarker.setAttribute('src', liveSrc);
                  setLiveMarker(mapMarker);

                  animateMarker(myMarker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (data?.message?.data?.heading) {
                    myMarker.setRotation(data?.message?.data?.heading);
                  }
                  const arrayMarker = [...mapCoordinates];
                  if (
                    arrayMarker &&
                    arrayMarker.length &&
                    arrayMarker.some(
                      (a) =>
                        parseFloat(a.longitude) !== parseFloat(data?.message?.data?.longitude) ||
                        parseFloat(a.latitude) !== parseFloat(data?.message?.data?.latitude)
                    )
                  ) {
                    arrayMarker.push({
                      longitude: data?.message?.data?.longitude,
                      latitude: data?.message?.data?.latitude,
                      heading: data?.message?.data?.heading,
                    });
                    mapCoordinates = arrayMarker;
                  } else {
                    arrayMarker.push({
                      longitude: data?.message?.data?.longitude,
                      latitude: data?.message?.data?.latitude,
                      heading: data?.message?.data?.heading,
                    });
                    mapCoordinates = arrayMarker;
                    if (data?.message?.data?.heading) {
                      myMarker.setRotation(data?.message?.data?.heading);
                    }
                  }
                }
              }
            });
          }
        });
      }
    }
  }, [infoHeader?.start_date_time, stopPointArr]);

  useEffect(() => {
    const allCoords = [];
    const allStops = [];
    const countAll = [];
    const legsStopsCount = [];
    if (!Array.isArray(stopPointArr)) {
      if (!orderStops) {
        legs?.forEach((leg, indexPrefix) => {
          legsStopsCount.push(leg.stops.length);
          leg.stops.forEach((stop, index) => {
            const prevStopsCountArr = legsStopsCount.slice(0, indexPrefix);
            const count = prevStopsCountArr.length ? prevStopsCountArr.reduce((a, b) => a + b, 0) + index : 0 + index;
            allStops.push(stop);
            countAll.push(count);
            const stopData = stopPoints?.find((point) => +point.key === +stop.stop_point_id);
            if (stopData) {
              const cord = {
                latitude: stopData.latitude,
                longitude: stopData.longitude,
                stopPointId: stopData.key,
              };
              allCoords.push(cord);
            }
          });
        });
        setStops([...stops, ...allStops]);
        setCoords([...coords, ...allCoords]);
      } else {
        orderStops?.forEach((stop, index) => {
          const stopData = stopPoints?.find((point) => +point.key === +stop.stop_point_id);
          if (stopData) {
            const cord = {
              latitude: stopData.latitude,
              longitude: stopData.longitude,
              stopPointId: stopData.key,
            };
            countAll.push(index);
            allStops.push(stop);
            allCoords.push(cord);
          }
        });
        setStops([...allStops]);
        setCoords([...allCoords]);
      }
      setIndexForAlphabet([...countAll]);
    } else {
      stopPointArr.forEach((stop, index) => {
        const latitude = stop?.latitude ? stop?.latitude : stop?.lat;
        const longitude = stop?.longitude ? stop?.longitude : stop?.lng;
        const cord = { latitude, longitude };
        countAll.push(index);
        allStops.push(stop);
        if (latitude && longitude) {
          allCoords.push(cord);
        }
      });
      setStops([...allStops]);
      setCoords([...allCoords]);
      setIndexForAlphabet([...countAll]);
    }
  }, [stopPoints, legs, orderStops, stopPointArr]);

  useEffect(() => {
    if (!mapCoords && longitude && latitude) {
      setMapCoords([{ longitude, latitude }]);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (infoHeader.equipment?.equipment_connected_services_device?.provider_device_id) {
      setProviderDeviceID(infoHeader.equipment?.equipment_connected_services_device?.provider_device_id);
      localStorage.setItem('equipment_id', infoHeader?.equipment?.id);
      localStorage.setItem(
        'provider_device_id',
        infoHeader.equipment?.equipment_connected_services_device?.provider_device_id
      );
      const body = {
        equipment_id: infoHeader?.equipment?.id,
        provider_device_id: infoHeader.equipment?.equipment_connected_services_device?.provider_device_id,
      };
      mapFlag({ body })
        .then()
        .catch(() => {
          // Do nothing
        });
    }

    return () => {
      if (!infoHeader.equipment?.equipment_connected_services_device?.provider_device_id) {
        return;
      }

      const body = {
        equipment_id: infoHeader?.equipment?.id,
        provider_device_id: infoHeader.equipment?.equipment_connected_services_device?.provider_device_id,
        delete_flag: 1,
      };
      mapFlag({ body })
        .then()
        .catch(() => {
          // Do nothing
        });
      localStorage.removeItem('equipment_id');
      localStorage.removeItem('provider_device_id');
      PusherJs.unsubscribe(`private-equipmentLocation-Update.${data?.customer?.dot}.${infoHeader?.equipment?.id}`);
      PusherJs.unsubscribe(`private-driverLocation-Update.${data?.customer?.dot}.${infoHeader?.driver1?.id}`);
      myMarker && myMarker.remove();
      myDriverMarker && myDriverMarker.remove();
      liveMarker && liveMarker.remove();
      setMyMarker(null);
      setMyDriverMarker(null);
      setLiveMarker(null);
    };
  }, []);

  useEffect(() => {
    if (!Object.keys(infoHeader).length) return;
    init([], false, liveCoords, providerDeviceID, speedForDriver, setSpeedForDriver, setSpeedForDriverData);

    async function init(
      mapHistory,
      isCenter,
      liveCoords,
      providerId,
      speedForDriver,
      setSpeedForDriver,
      setSpeedForDriverData
    ) {
      TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
      const myMap = new TrimbleMaps.Map({
        container: 'myMap',
        style: TrimbleMaps.Common.Style.BASIC,
        center: longitude ? new TrimbleMaps.LngLat(longitude, latitude) : new TrimbleMaps.LngLat(-97, 40),
        zoom: 3,
        trackResize: true,
      });
      if (myMap) {
        setMyCustomMap(myMap);
      }

      if (
        longitude &&
        latitude &&
        mapCoords &&
        infoHeader.status_id !== '7' &&
        infoHeader.status_id !== '6' &&
        infoHeader.start_date_time &&
        isShipmentTable
      ) {
        const marker = new TrimbleMaps.Marker({
          draggable: false,
          anchor: 'center',
          rotation: heading || '',
          element: infoHeader?.equipment?.hook_to
            ? (infoHeader?.equipment?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] &&
                infoHeader?.equipment?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] !==
                  '0') ||
              speedForDriver
              ? VehiclePlusTrailer
              : GreyVehiclePlusTrailer
            : VehiclePlusTrailer,
        });
        setMyMarker(marker);
        mapCoords?.forEach((cord) => {
          marker.setLngLat([cord.longitude, cord.latitude]).addTo(myMap);
          markers.push(marker);
        });

        myMap.on('load', async () => {
          myMap.zoomTo(14, {
            animate: true,
            duration: 5000,
          });
          myMap.setMaxZoom(15);

          myMap.addControl(new TrimbleMaps.FullscreenControl());
          myMap.addControl(new TrimbleMaps.NavigationControl());

          const formattedHistory = mapHistory.map((m) => {
            return {
              longitude: parseFloat(m.longitude),
              latitude: parseFloat(m.latitude),
            };
          });
          formattedHistory.reverse();
          let TriggerInterval;

          if (infoHeader?.equipment?.location_target === 'Driver') {
            DRIVERGPS.bind('client-equipment-location', (data) => {
              if (typeof data === 'string') {
                data = JSON.parse(data);
              }
              if (Number(infoHeader?.equipment?.id) === Number(data?.message?.data?.equipment_id) && marker) {
                if (infoHeader?.equipment?.location_target === 'Driver') {
                  clearInterval(TriggerInterval);
                  setSpeedForDriver(data?.message?.data?.speed_mph);
                  if (speedForDriverData !== true) {
                    setSpeedForDriverData(true);
                  }
                  setDriverSpeed(data?.message?.data?.speed_mph);
                  const oldLat = marker.getLngLat().lat;
                  const oldLng = marker.getLngLat().lng;
                  const lat = data?.message?.data?.latitude;
                  const lng = data?.message?.data?.longitude;
                  const is_hooked = data?.message?.data?.is_hooked;
                  const speed = Number(data?.message?.data?.speed_mph) || 0;
                  const mapMarker = marker.getElement();
                  const liveMarker = getLiveMarker(infoHeader.equipment, null, speed, is_hooked);
                  const liveSrc = liveMarker.getAttribute('src');
                  mapMarker.setAttribute('src', liveSrc);

                  animateMarker(marker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (data?.message?.data?.heading) {
                    marker.setRotation(data?.message?.data?.heading);
                  }
                  setLiveCoords({
                    latitude: data?.message?.data?.latitude,
                    longitude: data?.message?.data?.longitude,
                  });
                }
              }
            });
          } else if (infoHeader?.equipment?.location_target === 'carrier') {
            CARRIERGPS.bind('update-tracking-location', (e) => {
              if (e?.message?.data?.shipment_id === infoHeader?.shipment_id && carrier) {
                if (myMarker) {
                  clearInterval(TriggerInterval);
                  setSpeedForDriver(65);
                  if (speedForDriverData !== true) {
                    setSpeedForDriverData(true);
                  }
                  setDriverSpeed(65);
                  const oldLat = myMarker.getLngLat().lat;
                  const oldLng = myMarker.getLngLat().lng;
                  const lat = e?.message?.data?.latitude;
                  const lng = e?.message?.data?.longitude;
                  const is_hooked = !!infoHeader?.brokerage_dispatch?.trailer_id;
                  const speed = 65;
                  const mapMarker = myMarker.getElement();
                  const liveMarker = getLiveMarker(infoHeader.equipment, null, speed, is_hooked);
                  const liveSrc = liveMarker.getAttribute('src');
                  mapMarker.setAttribute('src', liveSrc);
                  setLiveMarker(mapMarker);

                  animateMarker(myMarker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (e?.message?.data?.heading) {
                    myMarker.setRotation(e?.message?.data?.heading);
                  }
                  setLiveCoords({
                    latitude: e?.message?.data?.latitude,
                    longitude: e?.message?.data?.longitude,
                  });
                }
              }
            });
          } else {
            GPS.bind('equipment-location', (data) => {
              if (typeof data === 'string') {
                data = JSON.parse(data);
              }
              if (providerId === data?.message?.data?.provider_device_id && marker) {
                if (infoHeader?.equipment?.location_target !== 'Driver') {
                  setDriverSpeed(data?.message?.data?.speed_mph);
                  const oldLat = marker.getLngLat().lat;
                  const oldLng = marker.getLngLat().lng;
                  const lat = data?.message?.data?.latitude;
                  const lng = data?.message?.data?.longitude;
                  const is_hooked = data?.message?.data?.is_hooked;
                  const speed = Number(data?.message?.data?.speed_mph) || 0;
                  const mapMarker = marker.getElement();
                  const liveMarker = getLiveMarker(infoHeader.equipment, null, speed, is_hooked);
                  const liveSrc = liveMarker.getAttribute('src');
                  mapMarker.setAttribute('src', liveSrc);

                  animateMarker(marker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (data?.message?.data?.heading) {
                    marker.setRotation(data?.message?.data?.heading);
                  }
                  const arrayMarker = [...mapCoordinates];
                  if (
                    arrayMarker &&
                    arrayMarker.length &&
                    arrayMarker.some(
                      (a) =>
                        parseFloat(a.longitude) !== parseFloat(data?.message?.data?.longitude) ||
                        parseFloat(a.latitude) !== parseFloat(data?.message?.data?.latitude)
                    )
                  ) {
                    arrayMarker.push({
                      longitude: data?.message?.data?.longitude,
                      latitude: data?.message?.data?.latitude,
                      heading: data?.message?.data?.heading,
                    });
                    mapCoordinates = arrayMarker;
                  } else {
                    arrayMarker.push({
                      longitude: data?.message?.data?.longitude,
                      latitude: data?.message?.data?.latitude,
                      heading: data?.message?.data?.heading,
                    });
                    mapCoordinates = arrayMarker;
                    if (data?.message?.data?.heading) {
                      marker.setRotation(data?.message?.data?.heading);
                    }
                  }
                }
              }
            });
          }
        });
      }
    }
  }, [mapCoords]);

  useEffect(() => {
    async function init() {
      if (myCustomMap && !routeIsDone && driversArr.length && stops.length && stopPointArr.length) {
        const routeId = `myRoute-${Math.random()}`;
        /// ///// Marker //////////
        const markers = [];
        // Create a marker with SVG content

        // Draw Markers

        if (!markerAdded) {
          coords.forEach((cord, i) => {
            const alphabet = getAlphabet(indexForAlphabet[i]);
            const id = stops[i]?.stop_point_type;
            const svgContent = document.createElement('div');
            let content;
            if (id === null || id === undefined) {
              content = `<div style="display: none"/>`;
            } else if (literalJsx === undefined) {
              if (markerToolTip) {
                content = `<div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                 <img src="${mapMarkerIcon}" alt=''>
                                 <div class='myMap_marker_text_wrapper'>
                                   <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                 </div> 
                             </div>`;
              } else {
                if (stopIconColor) {
                  id === 1
                    ? (content = `<div class='myMap_marker_container ${
                        CLASS_TYPES[+id]
                      }'><img src="${mapMarkerIcon}" alt=''></div>`)
                    : id === 2
                    ? (content = `<div class='myMap_marker_container ${
                        CLASS_TYPES[+id]
                      }'><img src="${mapMarkerRedIcon}" alt=''></div>`)
                    : (content = `<div class='myMap_marker_container ${
                        CLASS_TYPES[+id]
                      }'><img src="${mapMarkerBlueIcon}" alt=''></div>`);
                } else {
                  content = `<div class='myMap_marker_container ${
                    CLASS_TYPES[+id]
                  }'><img src="${mapMarkerIcon}" alt=''></div>`;
                }
              }
            } else {
              let label;
              if (literalJsx) {
                label = literalJsx;
              }
              content = ` <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                 <img src="${mapMarkerIcon}" alt=''>
                                 <div class='myMap_marker_text_wrapper'>
                                   <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                 </div> 
                                 <div 
                                  class="map_popover ${popoverClass} ${!label ? 'noneLAbel' : ''}"
                                  style="opacity: ${hoverPopover ? 0 : 1}"
                                  >
                                  <div class="popover_dispatch">
                                     <span>${stops?.[i]?.location_name}</span>
                                 </div>
                                 </div>
                             </div>
                             `;
            }
            svgContent.innerHTML = content;
            const marker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
            })
              .setLngLat([cord.longitude, cord.latitude])
              .addTo(myCustomMap);
            markers.push(marker);
          });
        }

        /** drivers * */
        const routes = [];
        const driverMarkers = [];
        const driverIcon = infoHeader?.start_date_time ? mapMarkerIcon : driversArr?.[0]?.img;
        const carrierDriverIcon = infoHeader?.start_date_time ? mapMarkerIcon : logo_path || carrierIcon;

        driversArr?.forEach((driver) => {
          const svgContent = document.createElement('div');
          const driverIconContent = `
                              <div class='myMap_driver_marker_container' id="">
                                   <img src="${carrier ? carrierDriverIcon : driverIcon || person}" alt=''>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                   <span>DEAD HEAD ${driver?.miles} Miles</span>
                                 </div>
                             </div>
      `;
          const { routeProps } = driver || {};
          const { lat, lng, ...restDriver } = driver?.marker || {};

          if (!!lat && !!lng) {
            svgContent.innerHTML = driverIconContent;
            const driverMarker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
              ...restDriver,
            }).setLngLat([lng, lat]);

            driverMarkers.push(driverMarker);
            markers.push(driverMarker);

            const startPoint = stops.find((item) => +item.stop_point_type === 1);

            if (startPoint) {
              const route = new TrimbleMaps.Route({
                routeId: `${driver.id}-${Math.random()}`,
                stops: [
                  { ...new TrimbleMaps.LngLat(+lng, +lat), stopPoint: true },
                  {
                    ...new TrimbleMaps.LngLat(Number(startPoint?.longitude), Number(startPoint?.latitude)),
                    stopPoint: true,
                  },
                ],
                routeColor: palette.blue300,
                showStops: false,
                stopPoint: true,
                vehicleType: infoHeader.shipment_route?.vehicle_type,
                routeType:
                  infoHeader.shipment_route?.route_type === 'practical'
                    ? 0
                    : infoHeader.shipment_route?.route_type === 'shortest'
                    ? 1
                    : 2,
                tollRoads: infoHeader.shipment_route?.avoid_tolls ? 2 : 3,
                highwayOnly: !!infoHeader.shipment_route?.highway_only,
                hazMatType: infoHeader.shipment_route?.hazmat_id,
                bordersOpen: !!infoHeader.shipment_route?.open_borders,
                ...routeProps,
              });
              routes.push(route);
            }
          }
        });
        /** drivers * */

        const stopsMap = coords.map((cord) => {
          const latLngObj = new TrimbleMaps.LngLat(cord.longitude, cord.latitude);
          return { ...latLngObj, stopPoint: true };
        });
        /// ///// Marker //////x////
        const routeOptions = {
          routeId,
          stops: stopsMap,
          showStops: false,
          isDraggable,
          routeColor: palette.indigo500, // optional routeColor
          vehicleType: infoHeader.shipment_route?.vehicle_type,
          routeType:
            infoHeader.shipment_route?.route_type === 'practical'
              ? 0
              : infoHeader.shipment_route?.route_type === 'shortest'
              ? 1
              : 2,
          tollRoads: infoHeader.shipment_route?.avoid_tolls ? 2 : 3,
          highwayOnly: !!infoHeader.shipment_route?.highway_only,
          hazMatType: infoHeader.shipment_route?.hazmat_id,
          bordersOpen: !!infoHeader.shipment_route?.open_borders,
          ...routeOptionsOutSide,
        };
        const myRoute = new TrimbleMaps.Route(routeOptions);

        myCustomMap.once('load', async () => {
          setMarkerAdded(true);
          if (!routeIsDone) {
            myRoute.addTo(myCustomMap);
            routes?.[0]?.addTo(myCustomMap);
            driverMarkers[0] && driverMarkers[0].addTo(myCustomMap);
            setMyDriverMarker(driverMarkers[0]);
          }
          if (myRoute && routes && driverMarkers[0]) {
            setRouteIsDone(true);
          }
          if (prevStopPointArr !== stopPointArr) {
            setRouteIsDone(false);
          }
        });
      }
    }

    if (!myCustomMap || routeIsDone) {
      return;
    }
    init();
  }, [myCustomMap, routeIsDone, isShipmentTable, stops, stopPointArr]);

  return (
    <div className={classNames('map_container')} style={{ ...styleContainer }}>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100vh', width: '100%', borderRadius: 10 }} />
        {children}
      </div>
    </div>
  );
};

export default Map;
