import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function RegistrationFeatures() {
  const { data } = await axios.get(`${API_URL}/user/registration/features`, {
    headers: authHeader(),
  });
  return data;
}

export async function Favorites() {
  const { data } = await axios.get(`${API_URL}/user/favorites`, {
    headers: authHeader(),
  });
  return data;
}

export async function AddFavorites(id) {
  const { data } = await axios.post(
    `${API_URL}/user/favorites`,
    {
      feature_id: id,
    },
    { headers: authHeader() }
  );
  return data;
}

export async function RemoveFavorites(id) {
  const { data } = await axios.delete(`${API_URL}/user/favorites`, {
    headers: authHeader(),
    data: {
      feature_id: id,
    },
  });
  return data;
}
