import React, { useContext, useEffect, useRef, useState } from 'react';
import './leftMenuSettings.css';

import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import xIcon from 'assets/icons/x.png';
import { ThemeSettingsChange, ThemeSettingsChangeFiles, ThemeSettingsChangeFilesDelete } from 'Api/LeftMenu';

import { MenuIcons } from './menuIcons';
import { Typography } from '../Typography';
import ImageDropZone from '../ImgageDropZone';
import CustomRadioButton from '../CustomRadioButton';
import { LayoutContext } from '../layout';

const LeftMenuSettings = ({ customstyle, close }) => {
  const { value } = useAuth();
  const { theme, use, setTheme } = useTheme();
  const leftMenuRef = useRef(null);
  const { userData, onSetUserData } = useContext(LayoutContext);
  const [departmentName, setDepartmentName] = useState('');

  useEffect(() => {
    window.addEventListener('click', onClickOutLeftMenu);
    return () => {
      window.removeEventListener('click', onClickOutLeftMenu);
    };
  }, [leftMenuRef]);

  const onClickOutLeftMenu = (e) => {
    if (leftMenuRef && !leftMenuRef?.current?.contains(e.target)) {
      close();
    }
  };

  const onThemeChange = (e) => {
    e.persist();
    const user = JSON.parse(localStorage.getItem('user'));
    ThemeSettingsChange({
      theme: +e.target.value,
      menuStyle: user.theme_settings?.menu_style || user.customer?.theme_settings?.menu_style,
    }).then((res) => {
      if (res && res.data) {
        try {
          const cloneUser = {
            ...user,
            theme_settings: { menu_style: res.data.menu_style, theme: res.data.theme },
          };
          localStorage.setItem('user', JSON.stringify(cloneUser));
          setTheme({ ...theme, appTheme: +e.target.value });
        } catch (e) {
          // Do nothing
        }
      }
    });
  };

  const onCondensedChange = (e) => {
    e.persist();
    const user = JSON.parse(localStorage.getItem('user'));
    const value = e.target.value;
    ThemeSettingsChange({
      menuStyle: value === 'condensed' ? 1 : 2,
      theme: user.theme_settings?.theme || user.customer?.theme_settings?.theme,
    }).then((res) => {
      if (res && res.data) {
        try {
          const closeUser = {
            ...user,
            theme_settings: { menu_style: res.data.menu_style, theme: res.data.theme },
          };
          localStorage.setItem('user', JSON.stringify(closeUser));
          switch (value) {
            case 'expanded':
              setTheme({ ...theme, condensed: false });
              break;
            case 'condensed':
              setTheme({ ...theme, condensed: true });
              break;
            default:
          }
        } catch (e) {
          // Do nothing
        }
      }
    });
  };

  const reset = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    ThemeSettingsChange({
      theme: 1,
      menuStyle: 1,
    }).then((res) => {
      if (res && res.data) {
        try {
          const cloneUser = {
            ...user,
            theme_settings: { menu_style: res.data.menu_style, theme: res.data.theme },
          };
          localStorage.setItem('user', JSON.stringify(cloneUser));
          setTheme({ ...theme, condensed: true, appTheme: 1 });
        } catch (e) {
          // Do nothing
        }
      }
    });
  };

  const updateBigOrSmallLogo = (acceptedFiles, name) => {
    const formData = new FormData();
    formData.append(name, acceptedFiles[0]);

    return ThemeSettingsChangeFiles(formData).then((res) => {
      if (res && res.status === 200 && res.statusText === 'OK') {
        try {
          const getStorage = JSON.parse(localStorage.getItem('user'));
          const cloneUser = {
            ...getStorage,
            customer: {
              ...getStorage.customer,
              theme_settings: { ...getStorage.customer.theme_settings, [name]: res.data.data[name] },
            },
          };
          localStorage.setItem('user', JSON.stringify(cloneUser));
          return res?.data?.data;
        } catch (e) {
          // Do nothing
        }
      }
    });
  };

  const onDrop = (acceptedFiles, name) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (name === 'small') {
        updateBigOrSmallLogo(acceptedFiles, 'small_logo').then(() => {
          onSetUserData({
            ...userData,
            small: reader.result,
          });
        });
      } else {
        updateBigOrSmallLogo(acceptedFiles, 'big_logo').then(() => {
          onSetUserData({
            ...userData,
            big: reader.result,
          });
        });
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const onDelete = (nameFile) => {
    const name = `${nameFile}_logo`;
    const user = JSON.parse(localStorage.getItem('user'));
    const cloneUser = {
      ...user,
      customer: {
        ...user.customer,
        theme_settings: { ...user.customer.theme_settings, [name]: null },
      },
    };
    localStorage.setItem('user', JSON.stringify(cloneUser));
    ThemeSettingsChangeFilesDelete(name);
    if (nameFile === 'small') {
      onSetUserData({ ...userData, small: '' });
    } else {
      onSetUserData({ ...userData, big: '' });
    }
  };

  useEffect(() => {
    setDepartmentName(value.user?.department?.department_name);
  }, [value?.user?.department?.id]);

  return (
    <div className={`left-menu-settings ${customstyle}`} ref={leftMenuRef}>
      <div className='left-menu-settings-head' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <Typography variant='h5'>Theme Settings</Typography>
        <img src={xIcon} onClick={close} alt='close' />
      </div>

      <div className='left-menu-settings-middle' style={{ backgroundColor: use(palette.gray0, palette.dark900) }}>
        {departmentName === 'Management' && (
          <>
            <Typography variant='s1'>Company logo</Typography>
            <Typography variant='c1' style={{ margin: '2px 0 14px 0', color: use(palette.gray500, palette.gray400) }}>
              Supports PNG, JPEG, SVG up to 1MB
            </Typography>
            <div className='left-menu-settings_line' />
            {/* <div className='logo-icon'> */}

            <div className='logo-icon' style={{ backgroundColor: use(palette.indigo600, palette.dark700) }}>
              <div
                className={`smallDropZone ${userData?.small ? '' : 'empty'}`}
                style={{ backgroundColor: use(palette.indigo600, palette.dark700) }}
              >
                <ImageDropZone
                  onDrop={onDrop}
                  width={32}
                  height={32}
                  img={userData?.small}
                  name='small'
                  deletable
                  onDelete={onDelete}
                  maxSize={1000000}
                />
              </div>
              <div
                className={`bigDropZone ${userData?.big ? '' : 'empty'}`}
                style={{ backgroundColor: use(palette.indigo600, palette.dark700) }}
              >
                <ImageDropZone
                  onDrop={onDrop}
                  width={115}
                  height={32}
                  img={userData.big}
                  name='big'
                  deletable
                  onDelete={onDelete}
                  maxSize={1000000}
                />
              </div>
            </div>
          </>
        )}

        <Typography variant='s1' style={{ margin: '32px 0 8px 0' }}>
          App Theme
        </Typography>
        <div className='left-menu-settings_line' />

        <form>
          <div className='settings-check'>
            <div className='settings-check-text'>
              <CustomRadioButton
                field={{
                  name: 'apptheme',
                  value: '1',
                  onChange: onThemeChange,
                  checked: +theme.appTheme === 1,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  System
                </Typography>
              </CustomRadioButton>
            </div>
            <div>
              <img src={MenuIcons.modeSysIcon} alt='box' />
            </div>
          </div>

          <div className='settings-check'>
            <div className='settings-check-text'>
              <CustomRadioButton
                field={{
                  name: 'apptheme',
                  value: '2',
                  onChange: onThemeChange,
                  checked: +theme.appTheme === 2,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Light
                </Typography>
              </CustomRadioButton>
            </div>
            <div>
              <img src={MenuIcons.modeLightIcon} alt='box' />
            </div>
          </div>

          {/* <div className="settings-check"> */}
          {/*    <div className="settings-check-text"> */}
          {/*        <CustomRadioButton field={{ */}
          {/*            name: 'apptheme', */}
          {/*            value: '3', */}
          {/*            onChange: onThemeChange, */}
          {/*            defaultChecked: +theme.appTheme === 3 */}
          {/*        }} */}
          {/*        > */}
          {/*            <Typography variant={'s2'} */}
          {/*                        style={{marginLeft: 8}}> */}
          {/*                Dark */}
          {/*            </Typography> */}
          {/*        </CustomRadioButton> */}
          {/*    </div> */}
          {/*    <div> */}
          {/*        <img src={MenuIcons.modeDarkIcon}/> */}
          {/*    </div> */}
          {/* </div> */}
        </form>

        <Typography variant='s1' style={{ margin: '32px 0 8px 0' }}>
          Menu Style
        </Typography>
        <div className='left-menu-settings_line' />

        <form>
          <div className='settings-check'>
            <div className='settings-check-text'>
              <CustomRadioButton
                field={{
                  name: 'menuStyle',
                  value: 'condensed',
                  onChange: onCondensedChange,
                  checked: !!theme.condensed,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Condensed
                </Typography>
              </CustomRadioButton>
            </div>
            <div>
              <img src={MenuIcons.fluidIcon} alt='box' />
            </div>
          </div>
          <div className='settings-check'>
            <div className='settings-check-text'>
              <CustomRadioButton
                field={{
                  name: 'menuStyle',
                  value: 'expanded',
                  onChange: onCondensedChange,
                  checked: !theme.condensed,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Expanded
                </Typography>
              </CustomRadioButton>
            </div>
            <div>
              <img src={MenuIcons.boxed} alt='box' />
            </div>
          </div>
        </form>
      </div>
      <div className='left-menu-settings-bottom' style={{ backgroundColor: palette.gray0 }}>
        <button
          className='btn button bottom-button'
          onClick={reset}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <span style={{ color: use(palette.gray700, palette.gray200) }}>Reset to default</span>
        </button>
      </div>
    </div>
  );
};

export default LeftMenuSettings;
