import { date, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  workers_compensation_and_employers_liability_policy_id: string().when(
    'workers_compensation_and_employers_liability_policy_active',
    {
      is: true,
      then: string().trim().required('Required'),
    }
  ),
  workers_compensation_and_employers_liability_expire_date: date().when(
    [
      'workers_compensation_and_employers_liability_policy_active',
      'workers_compensation_and_employers_liability_no_expire',
    ],
    {
      is: (
        workers_compensation_and_employers_liability_policy_active,
        workers_compensation_and_employers_liability_no_expire
      ) =>
        !!workers_compensation_and_employers_liability_policy_active &&
        !workers_compensation_and_employers_liability_no_expire,
      then: validator.futureRequiredDate(),
      otherwise: validator.date(),
    }
  ),
  workers_compensation_and_employers_liability_policy_attach: string().when(
    'workers_compensation_and_employers_liability_policy_active',
    {
      is: true,
      then: string().trim().required('Required'),
    }
  ),
});
