import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getAssignedLicenses, assignAddon, getAddonLicenses, removeAssignedAddon } from 'Api/Billing';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import Autocomplete from 'common/Autocomplete';
import Chip from 'common/Chip';
import { palette } from 'utils/constants';
import styles from './AddonsManageModal.module.css';

const addonTypeMapper = {
  1: 'driver',
  2: 'staff',
  3: 'vehicle',
};

const addonTypeLabelMapper = {
  1: 'Driver(s)',
  2: 'Staff user(s)',
  3: 'Equipment',
};

function AddonsManageModal({ show, onClose, data, quantity }) {
  const showToaster = useShowToaster();
  const [addonLicenses, setAddonLicenses] = useState([]);
  const [initialAssigned, setInitialAssigned] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removedLicenses, setRemovedLicenses] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getAssignedAddonLicenses = async (licences) => {
    try {
      const { data } = await getAssignedLicenses();
      const assignedItems = data.map((item) => Number(item?.assigned_to));
      setInitialAssigned(assignedItems);
      setAssignTo(licences.filter((item) => assignedItems.includes(item.id)));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getAddonLicense = async () => {
    try {
      const params = { itemsPerPage: 100000, tab: addonTypeMapper[data?.addon_plan?.addon?.bill_per] };
      const response = await getAddonLicenses(params);
      setAddonLicenses(response.data);
      getAssignedAddonLicenses(response.data);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const handleSubmit = async () => {
    if (assignTo.length > quantity) {
      showToaster({ type: 'error', message: `You can assign maximum of ${quantity} licences!` });
      return;
    }

    setLoading(true);
    try {
      const body = {
        assigned_to_type: data?.addon_plan?.addon?.assigned_type?.id,
        add_on_plan_id: data?.addon_plan_id,
        assigned_to: assignTo.map((item) => item.id),
        assigned_date: moment().format('YYYY-MM-DD'),
      };

      const removeLicenseBody = {
        assigned_to_type: data?.addon_plan?.addon?.assigned_type?.id,
        add_on_plan_id: data?.addon_plan_id,
        assigned_to: removedLicenses,
      };

      if (removedLicenses.length) {
        await removeAssignedAddon(removeLicenseBody);
      }

      if (assignTo.length) {
        await assignAddon(body);
      }
      showToaster({ type: 'success', message: 'Licenses have been successfully updated!' });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const handleSelectChange = (e, value, reason, details) => {
    if (details?.option?.name === 'Select All') {
      setAssignTo(assignTo.length === addonLicenses.length ? [] : addonLicenses);
      setRemovedLicenses(reason === 'removeOption' ? addonLicenses : []);
      return;
    }

    if (reason === 'clear') {
      setRemovedLicenses(addonLicenses);
    }

    if (reason === 'removeOption') {
      setRemovedLicenses((prevState) => [...prevState, details?.option?.id]);
    }

    setAssignTo(value);
  };

  useEffect(() => {
    getAddonLicense();
  }, []);

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Manage Assign</Typography>
        </div>
        <div className={styles.body_wrapper}>
          <Autocomplete
            multiple
            required
            name='assign_to'
            label={`Assign to ${addonTypeLabelMapper[data?.addon_plan?.addon?.bill_per] || 'Driver(s)'} (${
              assignTo.length
            } / ${quantity})`}
            options={[{ id: 'all', name: 'Select All' }, ...addonLicenses]}
            value={assignTo}
            onChange={handleSelectChange}
            isOptionEqualToValue={(option, value) =>
              option.id === value.id || (option.name === 'Select All' && addonLicenses?.length === assignTo.length)
            }
            disableClearable={false}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
              if (reason === 'reset') {
                return;
              }
              setInputValue(value);
            }}
            renderTags={() => null}
          />
          <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginTop: '8px' }}>
            {assignTo.map((item) => (
              <Chip
                padding='4px 8px 4px 12px'
                label={item.name}
                labelColor={palette.indigo500}
                bgColor={palette.indigo0}
                deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                onDelete={() => {
                  setAssignTo((prevState) => prevState.filter((i) => i.id !== item.id));
                  if (initialAssigned.includes(item.id)) {
                    setRemovedLicenses((prevState) => [...prevState, item.id]);
                  }
                }}
              />
            ))}
          </div>
        </div>

        <div className={styles.footer_wrapper}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '6px 12px', margin: 0 }}
            styleTitle={{ fontSize: 14 }}
            onClick={onClose}
          />
          <CustomButton
            type='primary'
            title='Update'
            styleButton={{ padding: '6px 12px', margin: 0 }}
            styleTitle={{ fontSize: 14 }}
            onClick={handleSubmit}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddonsManageModal;
