import get from 'lodash/get';
import moment from 'moment';

export const filterInvoices = (invoices, { search, sort, dateRange }) => {
  return invoices
    .filter(
      (item) =>
        (item.reference?.toLowerCase()?.includes(search.toLowerCase()) ||
          item.payee_name?.toLowerCase()?.includes(search.toLowerCase()) ||
          item.total_amount?.toString()?.toLowerCase()?.includes(search.toLowerCase())) &&
        moment(item.billed_date).isBetween(moment(dateRange.start), moment(dateRange.end))
    )
    .sort((a, b) => {
      if (get(a, sort.field) > get(b, sort.field)) {
        return sort.sortBy === 'asc' ? 1 : -1;
      }
      if (get(a, sort.field) < get(b, sort.field)) {
        return sort.sortBy === 'asc' ? -1 : 1;
      }
      return 0;
    });
};
