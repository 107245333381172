import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';
import { palette, statusColorInStatusId } from 'utils/constants';
import person from 'assets/images/person.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import { useTheme } from 'context/themeContext';
import { Typography } from '../../Typography';
import classes from './details.module.css';
import UserInfo from '../../StopPoint/UserInfo';

const HeaderUserInfo = ({ data }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const {
    assigned_to_1,
    assigned_to1,
    driver1_amount,
    assigned_to2,
    assigned_to_2,
    driver2_amount,
    brokerage_dispatch,
  } = data || {};
  const { carrier, contact_user, driver_phone_number } = brokerage_dispatch || {};
  const { logo_path } = carrier || {};
  const { contact_email, phone_number, contact_name } = contact_user || {};

  const driverInfo = {
    name: assigned_to_1?.fname,
    phone_number: driver_phone_number,
  };

  const contactInfo = {
    name: carrier?.name,
    email: contact_email,
    phone_number,
    contact_name,
  };

  return assigned_to_2 ? (
    <div className='d-flex align-items-center'>
      <ToolTipImg
        data={{
          assigned: assigned_to_1,
          assignedTo: assigned_to1,
          amount: driver1_amount,
          styleContainer: { marginRight: 6 },
        }}
      />
      <ToolTipImg
        data={{
          assigned: assigned_to_2,
          assignedTo: assigned_to2,
          amount: driver2_amount,
        }}
      />
    </div>
  ) : (
    <div className={classes.flexAlign}>
      <div
        className={classes.flexAlign}
        style={{ cursor: 'pointer' }}
        onClick={() => !!assigned_to1 && navigate(`/driver-profile/${assigned_to1}/general`)}
      >
        {(assigned_to_1?.fname || carrier) && (
          <div className={classes.avatar}>
            {carrier ? (
              logo_path ? (
                <img src={logo_path} width='100%' height='100%' alt='img' />
              ) : (
                <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
              )
            ) : (
              <img src={assigned_to_1?.image || person} alt='person' />
            )}
            {!carrier && (
              <div
                className={classes.avatarStatus}
                style={{
                  backgroundColor: use(
                    statusColorInStatusId[assigned_to_1?.status]?.color,
                    statusColorInStatusId[assigned_to_1?.status]?.darkColor
                  ),
                }}
              />
            )}
          </div>
        )}

        <div className='d-flex flex-column gap-1 '>
          {!carrier && assigned_to_1?.fname && (
            <Typography variant='overLine2' className={classes.nameUser}>
              {assigned_to_1?.fname}&nbsp;
              {assigned_to_1?.lname}
            </Typography>
          )}
          {!!carrier && (
            <div className='d-flex flex-column gap-2 ms-2 align-items-start'>
              <UserInfo data={driverInfo} />
              <UserInfo data={contactInfo} />
            </div>
          )}
        </div>
      </div>

      {!carrier && (
        <>
          <a href={`mailto:${assigned_to1?.email}`} className={classes.icons}>
            <MailIcon />
          </a>

          <a href={`tel:${assigned_to1?.phone_number}`} className={classes.icons}>
            <ContactIcon />
          </a>

          <div onClick={() => navigate('/chat')} className={classes.icons}>
            <ChatIcon />
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderUserInfo;

const ToolTipImg = ({ data }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { assigned, amount, assignedTo, styleContainer } = data || {};
  const toolTip = ({ placement, arrowProps, show: _show, popper, ...props }) => {
    return (
      <div
        {...props}
        className={classes.imgPopoverContainer}
        style={{ backgroundColor: use(palette.white, palette.dark800), ...props.style }}
      >
        <div
          className={classes.flexAlign}
          style={{ cursor: 'pointer' }}
          onClick={() => !!assignedTo && navigate(`/driver-profile/${assignedTo}/general`)}
        >
          <Typography variant='s2' className={classes.nameUser}>
            {assigned?.fname}&nbsp;
            {assigned?.lname}
          </Typography>
        </div>
        <div className={classes.flexAlign}>
          <a href={`mailto:${assigned?.email}`} className={classes.icons}>
            <MailIcon />
          </a>

          <a href={`tel:${assigned?.phone_number}`} className={classes.icons}>
            <ContactIcon />
          </a>

          <div onClick={() => navigate('/chat')} className={classes.icons}>
            <ChatIcon />
          </div>
        </div>

        {!!amount && <Typography variant='overLine'>Amount ${Number(amount)?.toFixed(2)}</Typography>}
      </div>
    );
  };

  return (
    <div onMouseLeave={() => setShow(false)}>
      <OverlayTrigger
        placement='bottom'
        trigger={['hover', 'focus']}
        delay={{ show: 100, hide: 150 }}
        overlay={toolTip}
        show={show}
        onToggle={(visible) => {
          !!visible && setShow(true);
        }}
      >
        <div
          onClick={() => !!assignedTo && navigate(`/driver-profile/${assignedTo}/general`)}
          style={{ cursor: 'pointer', ...styleContainer }}
        >
          <div className={classes.avatar}>
            <img src={assigned?.image || person} alt='person' />
            <div
              className={classes.avatarStatus}
              style={{
                backgroundColor: use(
                  statusColorInStatusId[assigned?.status]?.color,
                  statusColorInStatusId[assigned?.status]?.darkColor
                ),
              }}
            />
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};
