import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBrokerageSettings, getCarrierShipment, uploadDocumentsCarrierShipment } from 'Api/Carriers';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import Final from './steps/Final';
import { SWrapper, SStepperContent, ScrollableContainer } from './CarrierDocumentUpload.styles';
import CarrierDocumentUploadFooter from './components/CarrierDocumentUploadFooter';
import CarrierDocumentUploadHeader from './components/CarrierDocumentUploadHeader';
import Documents from './steps/Documents';
import { documentsValidationSchema, getDocumentsInitialValues } from './CarrierDocumentUpload.data';

const CarrierDocumentUpload = () => {
  const showToaster = useShowToaster();
  const [company, setCompany] = useState(null);
  const [shipmentInfo, setShipmentInfo] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { dot, token, shipment_id } = useParams();

  const onNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const onBack = () => {
    if (currentStep === 0) {
      return;
    }

    setCurrentStep((prevState) => prevState - 1);
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getBrokerageSettings(dot, token);
      setCompany(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipmentInfo = async () => {
    try {
      const params = {
        shipment_id,
      };

      const { data } = await getCarrierShipment(dot, token, params);
      setShipmentInfo(data);
      if (data?.brokerage_dispatch?.document_uploaded) {
        setCurrentStep(1);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const handleDocumentsSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(`shipment_id`, shipment_id);
      formData.append(`doc[0][type]`, 'invoice');
      formData.append(`doc[0][reference_id]`, values.invoiceReference);
      formData.append(`doc[0][file]`, values.invoice);

      let currentIndex = 1;
      if (values.bill_of_lading_array?.length) {
        values.bill_of_lading_array.forEach((item) => {
          formData.append(`doc[${currentIndex}][type]`, 'bill_of_lading');
          formData.append(`doc[${currentIndex}][reference_id]`, item.billOfLadingReference);
          formData.append(`doc[${currentIndex}][file]`, item.bill_of_lading);
          formData.append(`doc[${currentIndex}][shipment_stop_id]`, item.shipment_stop_id);
          currentIndex++;
        });
      }

      if (values.documents?.length) {
        values.documents.forEach((item) => {
          formData.append(`doc[${currentIndex}][type]`, item.type?.id);
          formData.append(`doc[${currentIndex}][reference_id]`, item.reference_id);
          formData.append(`doc[${currentIndex}][file]`, item.file);
          currentIndex++;
        });
      }

      await uploadDocumentsCarrierShipment(dot, token, formData);

      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const documentsForm = useForm({
    initialValues: getDocumentsInitialValues(),
    validationSchema: documentsValidationSchema,
    onSubmit: handleDocumentsSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    getShipmentInfo();
    getCompanyInfo();
  }, []);

  const steps = [
    {
      key: 'documents',
      title: 'Documents',
      Component: <Documents form={documentsForm} loading={loading} company={company} shipmentInfo={shipmentInfo} />,
      onNext: documentsForm.handleSubmit,
      width: '80%',
    },

    { key: 'finalPage', Component: <Final /> },
  ];

  return (
    <SWrapper $bgColor={currentStep !== steps.length - 1 ? palette.gray0 : null}>
      <CarrierDocumentUploadHeader company={company} />
      <ScrollableContainer>
        <SStepperContent>{steps[currentStep]?.Component}</SStepperContent>
        {currentStep !== steps.length - 1 && (
          <CarrierDocumentUploadFooter
            step={currentStep}
            stepsCount={steps.length}
            onBack={onBack}
            onNext={steps[currentStep]?.onNext || onNext}
            disabled={loading}
          />
        )}
      </ScrollableContainer>
    </SWrapper>
  );
};

export default CarrierDocumentUpload;
