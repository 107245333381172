import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cartIcon from 'assets/images/onboarding/icon.png';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import HardwareDescription from 'components/MyHardware/HardwareDetails';
import { palette } from 'utils/constants';
import OnboardingService from 'services/onboardingService';
import './hardware.css';

const HardwareCard = ({ data, index, ActiveCard, handleCardClick, addToCart, dataId, hardwareList }) => {
  const [quantity, setQuantity] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClick = () => {
    handleCardClick(index, data);
    setSelectedCard(data);
  };

  const handleCountChange = (value) => {
    setQuantity(value);
  };

  const handleAddToCart = () => {
    addToCart({ ...data, dataId }, quantity, dataId);
  };

  const onViewPaired = (hardware) => {
    const hardwareToView = hardwareList.find((i) => i.id === hardware.id);

    if (hardwareToView) {
      setSelectedCard(hardwareToView);
    }
  };

  useEffect(() => {
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null) {
      const ele = backCartItems?.find((ele) => ele.dataId === dataId);
      if (ele !== undefined) setQuantity(ele?.quantity);
    }
  }, []);

  useEffect(() => {
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      const ele = goBackCartItems?.find((ele) => ele.dataId === dataId);
      if (ele !== undefined) setQuantity(ele?.quantity);
    }
  }, []);

  return (
    <>
      <div className={ActiveCard === index ? 'hardware-card active-card' : 'hardware-card'} onClick={handleClick}>
        <div className='hardware-card-img-container'>
          <img
            className='hardware-img'
            alt='hardware'
            src={data?.images[0].image}
            onClick={() => setSelectedCard(data)}
          />
        </div>
        <p onClick={() => setSelectedCard(data)} className='hardware-name'>
          {data?.name}
        </p>
        {data.financed ? (
          <p className='card-price'>
            ${(Number(data?.price) / 36).toFixed(2)} <span>Per Month </span>{' '}
          </p>
        ) : (
          <p className='card-price'>
            ${data.price} <span>Per Unit </span>{' '}
          </p>
        )}
        <Typography variant='c2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          SEE DETAILS
        </Typography>
      </div>
      {!!selectedCard && (
        <HardwareDescription
          open={!!selectedCard}
          onClose={() => setSelectedCard(null)}
          count={quantity}
          hardware={selectedCard}
          handleCountChange={handleCountChange}
          onViewPaired={onViewPaired}
          handleAddToCart={handleAddToCart}
        />
      )}
    </>
  );
};

const Hardware = () => {
  const navigate = useNavigate();
  const [hardware, setHardware] = useState([]);
  const [ActiveCard, setactiveCard] = useState();
  const [addBtn, setaddBtn] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const getHardware = () => {
    OnboardingService.getHardware().then((res) => {
      setHardware(res.data.data);
    });
  };

  const addToCart = (data, qty, dataId) => {
    if (cartItems.length !== 0) {
      const isFound = cartItems.some((element) => {
        return element.dataId === data.dataId;
      });
      if (isFound) {
        cartItems.forEach((ele) => {
          if (ele.dataId === data.dataId) {
            ele.quantity = qty;
          }
        });
      } else {
        let items = [];
        items.push({ ...data, quantity: qty, dataId });
        items = [...cartItems, ...items];
        setCartItems(items);
      }
    } else {
      let items = [];
      items.push({ ...data, quantity: qty, dataId });
      items = [...cartItems, ...items];
      setCartItems(items);
    }
    if (qty <= 0) {
      const arr = cartItems.filter((ele) => ele.quantity !== 0);
      setCartItems(arr);
    }
  };

  const onContinue = () => {
    const hardwareItems = [];
    cartItems?.forEach((data) => {
      hardwareItems.push({ id: data.id, quantity: data.quantity });
    });
    localStorage.setItem('hardwareItem', JSON.stringify(hardwareItems));
    localStorage.setItem('hardware', JSON.stringify(cartItems));
    navigate('/onboarding/account-summary');
  };

  // const onSkip = () => {
  //   navigate('/onboarding/account-summary');
  // };

  const handleCardClick = (idx) => {
    setactiveCard(idx);
    setaddBtn(true);
  };
  //
  // const handleGoBack = () => {
  //   localStorage.setItem('goBackCartItems', JSON.stringify(cartItems));
  //   localStorage.removeItem('hardwareCart');
  //   navigate('/onboarding/addons', { replace: true });
  // };

  useEffect(() => {
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null && backCartItems?.length !== 0) {
      setCartItems(backCartItems);
    }
  }, []);

  useEffect(() => {
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      setCartItems(goBackCartItems);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getHardware();
    }, 500);

    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <div className='hardware mt-4'>
      <div className='addons-header'>
        <Typography variant='h4' style={{ color: palette.gray900 }}>
          Purchase Hardware
        </Typography>
      </div>
      <Divider color={palette.gray200} margin='8px 0' />
      <div className='d-flex justify-content-between'>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          Hardware is optional. Hardware can be purchased now or after you have created your account.
        </Typography>
        <div className='d-flex align-items-center gap-3'>
          {/* <CustomButton */}
          {/*  title='Go Back' */}
          {/*  type='primary' */}
          {/*  styleTitle={{ fontSize: 14, fontWeight: 500 }} */}
          {/*  styleButton={{ padding: '6px 12px', margin: 0, width: '160px', justifyContent: 'center' }} */}
          {/*  onClick={handleGoBack} */}
          {/* /> */}
          {/* {cartItems?.length ? ( */}
          <CustomButton
            title='Continue'
            type='primary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ padding: '6px 12px', margin: 0, width: '160px', justifyContent: 'center' }}
            leftIcon={<img src={cartIcon} alt='cart' style={{ marginRight: 10 }} />}
            onClick={onContinue}
          />
          {/* ) : ( */}
          {/*  <CustomButton */}
          {/*    title='Skip for now' */}
          {/*    type='primary' */}
          {/*    styleTitle={{ fontSize: 14, fontWeight: 500 }} */}
          {/*    styleButton={{ padding: '6px 12px', margin: 0, width: '160px', justifyContent: 'center' }} */}
          {/*    onClick={onSkip} */}
          {/*  /> */}
          {/* )} */}
        </div>
      </div>
      <div className='divider' style={{ marginTop: '56px' }} />
      <div className='hardware-bod'>
        <div className='hardware-container'>
          {hardware?.map((data, index) => (
            <HardwareCard
              key={data.id}
              dataId={index + 1}
              data={data}
              index={index}
              ActiveCard={ActiveCard}
              handleCardClick={handleCardClick}
              addBtn={addBtn}
              addToCart={addToCart}
              hardwareList={hardware}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hardware;
