import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import CustomMultiSelect from 'common/CustomMultiSelect/CustomMultiSelect';
import plus from 'assets/icons/drivers/plus.svg';
import { addTabs, DriverGroups, updateTabs } from 'Api/Driver';
import { getErrorMessage } from 'utils/error';
import AddGroup from 'componentsV2/Group/AddGroup';

export default function TabUpdateModal({ ...props }) {
  const { use } = useTheme();
  const { value } = useAuth();
  const isManagement = value?.user?.department?.department_name === 'Management';
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddGroup, setOpenAddGroup] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      group_id: [],
      status: [],
      forAll: false,
    },
  });

  useEffect(() => {
    if (props?.initialValue && groups?.length) {
      const { initialValue } = props;

      const patchGroupValue = initialValue?.filters?.group_id?.map((groupId) =>
        groups?.find((group) => Number(group?.id) === +groupId)
      );
      formik.setFieldValue('name', initialValue?.label);
      formik.setFieldValue('group_id', patchGroupValue);
    }
  }, [props?.initialValue, groups]);

  const addTabData = () => {
    const { group_id, name, forAll } = formik.values;
    const payload = {
      type: 'owner_operator',
      forAll,
      data: {
        name,
        filters: {
          group_id: group_id.map((group) => group.id),
          can_delete: true,
        },
      },
    };
    setLoading(true);
    if (props?.initialValue) {
      updateTabs(payload, props?.initialValue?.tabId)
        .then(() => {
          props.save();
          props.onSuccess('Tab has been updated successfully');
          setLoading(false);
          props.onHide();
        })
        .catch((error) => {
          setLoading(false);
          props.showError(getErrorMessage(error));
        });
    } else {
      addTabs(payload)
        .then(() => {
          props.save();
          props.onSuccess('Tab has been added successfully');
          setLoading(false);
          props.onHide();
        })
        .catch((error) => {
          setLoading(false);
          props.showError(getErrorMessage(error));
        });
    }
  };

  useEffect(() => {
    DriverGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const saveGroup = (val) => {
    setGroups([...groups, val]);
    formik.setValues((prevState) => ({
      ...prevState,
      group_id: [...prevState.group_id, val],
    }));
  };

  return (
    <div className='modal-container driver-style-wrap'>
      <Modal
        {...props}
        enforceFocus={false}
        dialogClassName='modal-dialog-box'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            {props?.tabName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box'
          style={{ backgroundColor: use(palette.whiteBackground, palette.dark900) }}
        >
          <div className='content-wrapper-date'>
            <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
              Tab name <span className='required'>*</span>
            </p>
            <div className='tab-name-container' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
              <input
                type='text'
                name='name'
                id='name'
                placeholder='Name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
            </div>
          </div>
          <div className='content-wrapper-group'>
            <div className='head-container'>
              <p className='text-style' style={{ color: use(palette.gray700, palette.gray50), marginLeft: '0' }}>
                Tag&#40;s&#41;
              </p>

              <div className='add-container' onClick={() => setOpenAddGroup(true)}>
                <img src={plus} alt='plus' />
                <p className='add-new'>New Tag</p>
              </div>
            </div>
            <CustomMultiSelect
              placeholder='Select tags'
              selectedValues={formik.values.group_id}
              formik={formik}
              patchProperty='group_id'
              propertyName='group_name'
              options={groups}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          className='Add-Tab-Footer Add-Tab-Footer-staff-tab'
          style={{
            background: use(palette.white, palette.dark800),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          {!props?.initialValue && (
            <div className='status-container'>
              {isManagement && (
                <>
                  <input
                    type='checkbox'
                    className='type'
                    name='forAll'
                    id='forAll'
                    checked={formik.values?.forAll === true}
                    onChange={() => formik.setFieldValue('forAll', !formik.values?.forAll)}
                  />
                  <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Apply to all users
                  </p>
                </>
              )}
            </div>
          )}

          {props?.initialValue && (
            <div>
              <Button
                className='cancel-button '
                style={{
                  backgroundColor: use(palette.red500, palette.red500),
                  color: use(palette.white, palette.white),
                  borderColor: use(palette.white, palette.boxShadow2),
                }}
                onClick={(event) => props.onClickCross(event, props?.initialValue)}
              >
                Remove
              </Button>
            </div>
          )}
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.white, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' disabled={!formik.values.name} type='submit' onClick={addTabData}>
                {props?.tabName}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={saveGroup} />}
    </div>
  );
}
