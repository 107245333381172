import React, { useEffect, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getServices } from 'Api/Onboarding';
import OnboardingService from 'services/onboardingService';
import FeatureCard from './FeatureCard';
import ServiceCard from './ServiceCard';
import { SFeaturesWrapper, SServicesWrapper, SAccordion } from './IncludedFeatures.styles';

const IncludedFeatures = () => {
  const [tableData, setTableData] = useState([]);
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [expandedFeatures, setExpandedFeatures] = useState(false);
  const [expandedServices, setExpandedServices] = useState(false);
  const brokerInfo = JSON.parse(localStorage.getItem('brokerInfo'));

  const getFeatures = () => {
    OnboardingService.getFeatures()
      .then((res) => {
        setTableData(
          res.data.filter(
            (item) =>
              item.visible_on_boarding &&
              (brokerInfo
                ? item.customer_type?.some((i) => i.customer_type === 'Carrier & Broker')
                : item.customer_type?.some((i) => i.customer_type === 'Carrier'))
          )
        );
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getServicesList = async () => {
    setLoadingServices(true);
    try {
      const { data } = await getServices();
      setServices(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingServices(false);
    }
  };

  useEffect(() => {
    getServicesList();
    setTimeout(() => {
      getFeatures();
    }, 500);

    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <div className='plan-table'>
      {!!tableData?.length && (
        <div className='mt-4 position-relative'>
          <div className='text-center'>
            <Typography variant='h4' style={{ color: palette.gray900 }}>
              What's Included
            </Typography>
          </div>
          <SFeaturesWrapper>
            {tableData.slice(0, 5)?.map((data) => (
              <FeatureCard feature={data} key={data.id} />
            ))}
          </SFeaturesWrapper>
          {!!tableData.slice(5)?.length && (
            <SAccordion
              expanded={expandedFeatures}
              onChange={(e, isExpanded) => setExpandedFeatures(isExpanded)}
              className='outer-accordion'
            >
              <AccordionSummary>
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Show {expandedFeatures ? 'Less' : 'More'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SFeaturesWrapper>
                  {tableData.slice(5)?.map((data) => (
                    <FeatureCard feature={data} key={data.id} />
                  ))}
                </SFeaturesWrapper>
              </AccordionDetails>
            </SAccordion>
          )}
        </div>
      )}
      <Divider color={palette.gray50} margin='32px auto 0' width='600px' />
      <div className='mt-5 position-relative'>
        <div className='text-center'>
          <Typography variant='h4' style={{ color: palette.gray900 }}>
            Connected Services
          </Typography>
        </div>
        <Loader loading={loadingServices} />
        <SServicesWrapper>
          {services.slice(0, 2)?.map((item) => (
            <ServiceCard key={item.id} service={item} />
          ))}
        </SServicesWrapper>
        <SAccordion
          expanded={expandedServices}
          onChange={(e, isExpanded) => setExpandedServices(isExpanded)}
          className='outer-accordion'
        >
          <AccordionSummary>
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Show {expandedServices ? 'Less' : 'More'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SServicesWrapper>
              {services.slice(2)?.map((item) => (
                <ServiceCard key={item.id} service={item} />
              ))}
            </SServicesWrapper>
          </AccordionDetails>
        </SAccordion>
      </div>
    </div>
  );
};

export default IncludedFeatures;
