import React from 'react';
import hexRgb from 'hex-rgb';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Typography } from 'components/Typography';
import { MaterialTableSort } from 'components/MaterialTableWrapper';
import { useTheme } from 'context/themeContext';
import { palette, statusColor } from 'utils/constants';

export const useColumns = ({ sortingQuery }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'owner_operator_name',
      title: <MaterialTableSort title='NAME' field='owner_operator_name' sortingQuery={sortingQuery} />,
      render: (rowData) => (
        <div>
          <Dropdown className='driver-name-basic-wrapper'>
            <Dropdown.Toggle className='menu-drop' id='driver-name-basic'>
              <div className='details-container'>
                <div className='username--details'>
                  <div className='user-name-and-actions-container'>
                    <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {rowData?.owner_operator_name}
                    </h2>
                  </div>
                </div>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      ),
    },
    {
      field: 'phone_no',
      title: <Typography variant='c3'>PHONE NUMBER</Typography>,
      render: (rowData) => (
        <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
          {rowData?.phone_no}
        </p>
      ),
    },
    {
      field: 'status',
      title: <Typography variant='c3'>STATUS</Typography>,
      render: (rowData) => {
        return (
          <div className='status-container-wrap'>
            <div
              className='warning-status-container'
              style={{
                backgroundColor: use(
                  statusColor[rowData?.status?.status]?.bgColor,
                  statusColor[rowData?.status?.status]?.darkBgColor
                ),
              }}
            >
              <p
                className='warning-status-text'
                style={{
                  color: use(
                    statusColor[rowData?.status?.status]?.color,
                    statusColor[rowData?.status?.status]?.darkColor
                  ),
                }}
              >
                {rowData?.status?.status}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      field: 'drivers_count',
      title: <MaterialTableSort title='Driver Count' field='drivers_count' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <div
            className='date-container driver-count'
            style={{
              backgroundColor: use('#F0F2F7', palette.dark600),
              color: use(palette.gray600, palette.gray400),
            }}
          >
            <p className='sub-text'>{rowData?.drivers_count}</p>
          </div>
        );
      },
    },
    {
      field: 'equipment_count',
      title: <MaterialTableSort title='Equipment Count' field='equipment_count' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <div
            className='date-container driver-count'
            style={{
              backgroundColor: use('#F0F2F7', palette.dark600),
              color: use(palette.gray600, palette.gray400),
            }}
          >
            <p className='sub-text'>{rowData?.equipment_count}</p>
          </div>
        );
      },
    },
    {
      field: 'operator_group',
      title: <MaterialTableSort title='Tags' field='operator_group' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <div className={`flex-row d-flex ${rowData?.id ? 'rowData_wrapper' : ''}`}>
            {rowData?.operator_group?.length > 0 ? (
              rowData?.operator_group?.map((el) => {
                const colorCode = hexRgb(el.color);
                const TextColor =
                  colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
                return (
                  <div
                    key={el?.id}
                    className='tablePlaner-shortName'
                    style={{
                      backgroundColor: el.color,
                      color: TextColor,
                    }}
                  >
                    {el?.short_name}
                  </div>
                );
              })
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      field: 'compensation',
      title: <MaterialTableSort title='COMPENSATION' field='compensation' sortingQuery={sortingQuery} />,
      render: (rowData) => (
        <div className='compensation-container-owner-operator'>
          {rowData?.compensation ? (
            <>
              <div className='compensation-box1'>
                {rowData?.compensation?.pay_option?.id === 1 ? (
                  <p
                    className='phone'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >{`${currency}${rowData?.compensation?.pay_per_mile_empty} - ${currency}${rowData?.compensation?.pay_per_mile_loaded}`}</p>
                ) : rowData?.compensation?.pay_option?.id === 2 ? (
                  <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {rowData?.compensation?.pay_by_percentage}%
                  </p>
                ) : rowData?.compensation?.pay_option?.id === 3 ? (
                  <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {currency}
                    {rowData?.compensation?.per_hour_worked}
                  </p>
                ) : (
                  <span />
                )}
              </div>
              <div
                className='date-container'
                style={{
                  backgroundColor: use('#F0F2F7', palette.dark600),
                  color: use(palette.gray600, palette.gray400),
                }}
              >
                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                  {rowData?.compensation?.pay_option?.name}
                </p>
              </div>
            </>
          ) : null}
        </div>
      ),
    },
  ];
};
