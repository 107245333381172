export const appliesToOptions = [
  { id: 1, name: 'Driver', value: 'driver' },
  { id: 2, name: 'Staff', value: 'staff' },
];

export const getInitialValues = (rule) => {
  const { title, description, policy_attachment, applies_to, incident_types } = rule || {};

  return {
    title: title || '',
    description: description || '',
    incident_types: incident_types || [],
    policy_attachment: policy_attachment || null,
    applies_to: applies_to ? appliesToOptions.filter((el) => applies_to.includes(el.value)) : [],
  };
};
