import axios from '../services/axios';

export async function exportStopPointsCsv() {
  const { data } = await axios.get('/customer/export-stop-point-data');
  return data;
}

export async function exportStopPointsInventoryCsv(params) {
  const { data } = await axios.get('/equipment/export-data-equipment-stoppoint', { params });
  return data;
}

export async function exportShipmentsCsv() {
  const { data } = await axios.get('/customer/export-shipments');
  return data;
}

export async function exportShipmentAuditCsv() {
  const { data } = await axios.get('/customer/export-audit');
  return data;
}

export async function exportShipmentAuditClearedCsv() {
  const { data } = await axios.get('/customer/export-audit-cleared');
  return data;
}

export async function exportShipmentHistoryCsv() {
  const { data } = await axios.get('/customer/export-shipment-history');
  return data;
}

export async function exportSettlementsCsv(params) {
  const { data } = await axios.get('/customer/payroll/export-settlements-data', { params });
  return data;
}

export async function exportTimeTrackingCsv(params) {
  const { data } = await axios.get('/customer/payroll/export-time-tracking-data', { params });
  return data;
}

export async function exportApplicantsCsv() {
  const { data } = await axios.get('/job-application-appliciants-export');
  return data;
}

export async function exportReferralsCsv() {
  const { data } = await axios.get('/job/referrals-export-data');
  return data;
}

export async function exportReferrersCsv() {
  const { data } = await axios.get('/job/referrers-data-export');
  return data;
}

export async function exportJobPositionsCsv(dot) {
  const { data } = await axios.get('/customer/job-position-export-data', {
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function exportPlannerCsv() {
  const { data } = await axios.get('/customer/export-data-shipments');
  return data;
}

export async function exportRecurringLanesCsv(params) {
  const { data } = await axios.get('/customer/shipments/export-data-recurring-lanes', { params });
  return data;
}
