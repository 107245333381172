import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.span`
  .column-title {
    font-size: 11px;
    font-weight: 500;
    color: ${palette.gray900};
  }

  .paper-log-image {
    object-fit: contain;
    width: 100vw;
    max-height: 600px;
  }
`;

export const SUnderlineText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${palette.indigo500};
  font-style: normal;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const STable = styled.table`
  width: 100%;

  .MuiAutocomplete-root {
    min-width: 130px;
  }

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 16px 12px;
      white-space: nowrap;

      svg {
        cursor: pointer;
      }
    }

    td {
      padding: 10px;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    td {
      padding: 10px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    &.invalid-row {
      background-color: ${palette.red0};
    }

    .disabled-save-icon path {
      fill: ${palette.gray200};
    }

    :hover {
      background: ${palette.gray0};

      .action-buttons {
        visibility: visible;
      }

      .action-wrapper {
        visibility: visible;
      }
    }
  }

  .action-dots {
    cursor: pointer;
  }
`;
