import React, { useMemo, useState } from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { refundAndRevertPaidInvoices } from 'Api/AccountingReceivables';

const Refund = ({ open, onClose, transaction, onRefundSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const invoicePayments = transaction?.related_payments?.filter((payment) => !payment.is_fees);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState(invoicePayments?.map((payment) => payment.invoice_id));

  const onSelectedInvoicesChange = (checked, invoiceId) => {
    if (checked) {
      setSelectedInvoiceIds((prevState) => [...prevState, invoiceId]);
    } else {
      setSelectedInvoiceIds((prevState) => prevState.filter((id) => id !== invoiceId));
    }
  };

  const totalRefund = useMemo(() => {
    return invoicePayments.reduce((acc, cur) => {
      if (selectedInvoiceIds?.some((id) => id === cur.invoice_id)) {
        acc += Number(cur.deposit);
      }

      return acc;
    }, 0);
  }, [selectedInvoiceIds]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        invoice_id: selectedInvoiceIds,
      };
      await refundAndRevertPaidInvoices(body);
      showToaster({ type: 'success', message: 'Transaction has been successfully refunded!' });
      onClose();
      onRefundSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Refund'
      $maxWidth='510px'
      $minWidth='510px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Refund',
          onClick: handleSubmit,
          disabled: loading || !selectedInvoiceIds.length,
        },
      ]}
    >
      <Typography variant='s1' as='p' className='mt-3 mb-3' style={{ color: palette.gray700 }}>
        Are you sure you want to refund this transaction?
      </Typography>
      <Typography variant='button2'>Invoice(s):</Typography>
      <table>
        <tbody>
          {invoicePayments?.map((payment) => {
            return (
              <tr key={payment.id}>
                <td style={{ minWidth: '200px' }}>
                  <CustomCheckbox
                    name='invoice'
                    checked={selectedInvoiceIds?.some((invoiceId) => invoiceId === payment.invoice_id)}
                    onChange={(checked) => onSelectedInvoicesChange(checked, payment.invoice_id)}
                  >
                    <Typography variant='s2' style={{ marginLeft: '8px' }}>
                      {payment.invoice_id}
                    </Typography>
                  </CustomCheckbox>
                </td>
                <td>
                  <Typography variant='s2'>${formatNumber(payment.deposit)}</Typography>
                </td>
              </tr>
            );
          })}
          <tr style={{}}>
            <td style={{ minWidth: '200px' }}>
              <Typography variant='button2'>Total Refund:</Typography>
            </td>
            <td>
              <Typography variant='button2'>${formatNumber(totalRefund)}</Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography variant='s2'>Refunds take 5-10 days to appear on a customer's statement.</Typography>
    </Modal>
  );
};

export default Refund;
