import { formatNumber } from 'utils/constants';

const currencies = {
  $: 'USD',
  C$: 'CAD',
  '₱': 'MXN',
};

export const generateInvoiceCharges = ({
  doc,
  pageWidth,
  invoiceCharges,
  customer_note,
  note,
  invoice_notes,
  accountingSettings,
  currency,
}) => {
  const chargesStart = 70;

  let chargesEnd;
  let termsEnd;
  let notesEnd;
  let noticeEnd;

  const shouldApplyTax = invoiceCharges.some((charge) => !!charge.is_taxable);
  const total_amount = invoiceCharges.reduce((acc, cur) => acc + cur.total_amount, 0);
  const total_tax = invoiceCharges.reduce((acc, cur) => acc + (cur.tax_amount || 0), 0);

  doc.setFontSize(8);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);

  doc.text('Description', 8, 70);
  doc.text('Quantity', shouldApplyTax ? 102 : 132, 70);
  doc.text('Rate', shouldApplyTax ? 132 : 162, 70);
  if (shouldApplyTax) {
    doc.text('Tax', 162, 70);
  }
  doc.text(`Amount ${currencies[currency]}`, 188, 70);
  doc.line(8, 71.5, pageWidth - 8, 71.5);

  doc.setFont('Inter', 'normal', 400);

  [...(invoiceCharges || [])]?.forEach((item, index, array) => {
    // title of articles
    if (item.item?.title === 'Detention' && item.desc.startsWith('Detention')) {
      doc.setFontSize(shouldApplyTax ? 6 : 7);
      doc.text(item.desc.length > 91 ? `${item.desc.slice(0, 88)}...` : item.desc, 8, chargesStart + (index + 1) * 5);
      doc.setFontSize(8);
    } else {
      doc.text(item.item?.title, 8, chargesStart + (index + 1) * 5);
    }

    // Quantity
    doc.text(`${formatNumber(item.quantity)}`, shouldApplyTax ? 102 : 132, chargesStart + (index + 1) * 5);

    // Rate
    doc.text(`${formatNumber(item.price)}`, shouldApplyTax ? 132 : 162, chargesStart + (index + 1) * 5);

    if (shouldApplyTax) {
      // Tax
      doc.text(
        item.item.tax_percentage ? `${formatNumber(item.item.tax_percentage)}%` : '-',
        162,
        chargesStart + (index + 1) * 5
      );
    }

    // Amount
    doc.text(`${formatNumber(item.total_amount)}`, 188, chargesStart + (index + 1) * 5);

    if (index !== array.length - 1) {
      doc.setDrawColor(188, 194, 206);
      doc.line(8, chargesStart + 1.5 + (index + 1) * 5, pageWidth - 8, chargesStart + 1.5 + (index + 1) * 5);
    }

    doc.setDrawColor(0, 0, 0);
    if (index === array.length - 1) {
      chargesEnd = chargesStart + (index + 1) * 5 + 5;
      doc.line(shouldApplyTax ? 102 : 132, chargesEnd - 2, pageWidth - 8, chargesEnd - 2);
      if (shouldApplyTax) {
        doc.text('Total Tax', 102, chargesEnd + 2);
      }

      const totalRow = shouldApplyTax ? chargesEnd + 7 : chargesEnd + 2;

      doc.setFont('Inter', 'normal', 700);
      doc.text(`TOTAL ${currencies[currency]}`, shouldApplyTax ? 102 : 132, totalRow);
      doc.setFont('Inter', 'normal', 400);
      if (shouldApplyTax) {
        doc.setDrawColor(188, 194, 206);
        doc.line(102, chargesEnd + 3, pageWidth - 8, chargesEnd + 3);
        doc.text(`${formatNumber(total_tax)}`, 188, chargesEnd + 2);
      }
      doc.text(`${formatNumber(total_amount)}`, 188, totalRow);

      // Invoice Terms
      if (accountingSettings?.invoice_terms) {
        doc.setFont('Inter', 'normal', 700);
        doc.text('Invoice Terms', 8, totalRow + 18);

        doc.setFont('Inter', 'normal', 400);
        doc.text(`${accountingSettings.invoice_terms}`, 8, totalRow + 22, {
          maxWidth: pageWidth - 16,
        });
        termsEnd = totalRow + 22;
      }

      const notesStart = accountingSettings.invoice_terms ? termsEnd + 12 : totalRow + 18;

      // Invoice notes
      if (note || customer_note || invoice_notes?.length) {
        doc.setFont('Inter', 'normal', 700);
        doc.text('Invoice Notes', 8, notesStart);

        doc.setFont('Inter', 'normal', 400);

        let notesToShow;
        if (customer_note && note) {
          notesToShow = [customer_note, note];
        } else if (customer_note) {
          notesToShow = [customer_note];
        } else {
          notesToShow = [note];
        }

        const invoiceNotesToShow = invoice_notes.filter((note) => !!note.show_on_invoice).map((note) => note.notes);

        notesToShow.push(...invoiceNotesToShow);

        notesToShow.forEach((note, index) => {
          doc.text(`${note}`, 8, notesStart + (index + 1) * 4, { maxWidth: pageWidth - 16 });
          if (index === notesToShow.length - 1) {
            notesEnd = notesStart + (index + 3) * 4;
          }
        });
      }

      let noticeStart = totalRow + 18;

      if (accountingSettings?.invoice_terms) {
        noticeStart = termsEnd + 12;
      }

      if (note || customer_note) {
        noticeStart = notesEnd;
      }

      // Notice of Assignment
      if (accountingSettings.is_factoring === 1 && accountingSettings?.notice) {
        doc.setFontSize(8);
        doc.setFont('Inter', 'normal', 700);
        doc.text('Notice of Assignment', 8, noticeStart);

        doc.setFontSize(8);
        doc.setFont('Inter', 'normal', 400);
        doc.text(`${accountingSettings?.notice}`, 8, noticeStart + 4, {
          maxWidth: pageWidth - 16,
        });
        noticeEnd = noticeStart + 36;
      }
    }
  });

  chargesEnd = shouldApplyTax ? chargesEnd + 10 : chargesEnd;

  return { total_amount, chargesEnd: noticeEnd || notesEnd || termsEnd || chargesEnd };
};
