import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { formatPhoneNumber } from 'utils/helpers';
import { SAddressFields, SCompanyInfo, SHeader, SProgressWrapper } from '../ApplyJob.styles';

const ApplyJobHeader = ({ company, progress }) => {
  const { use } = useTheme();
  const matches = useMediaQuery('(max-width:600px)');

  const { company_name, address1, city, state, zip, phone_number, logo_path } = company || {};

  return (
    <SHeader>
      <SCompanyInfo>
        <div className='logo-wrapper'>{logo_path && <img src={logo_path} alt='Company logo' height={48} />}</div>
        {!matches && (
          <Typography variant='h2' style={{ textAlign: 'center' }}>
            Job Application
          </Typography>
        )}
        <SAddressFields>
          <Typography variant='s2'>{company_name}</Typography>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {address1}
          </Typography>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {city?.name}, {state?.short_name || state?.name} {zip}
          </Typography>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {phone_number ? formatPhoneNumber(phone_number) : ''}
          </Typography>
        </SAddressFields>
      </SCompanyInfo>
      <SProgressWrapper last={progress === 100}>
        <LinearProgress variant='determinate' value={progress} />
      </SProgressWrapper>
    </SHeader>
  );
};

export default ApplyJobHeader;
