import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import authHeader from 'services/auth-header';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import './driverinfo.css';
import useShowToaster from 'hooks/useShowToaster';

const API_URL = process.env.REACT_APP_API_URL;

const RemoveDriver = ({
  RemoveDriverData,
  deleteDriverModalClose,
  RemoveDriverModal,
  RemoveDriverModalClose,
  onSuccess = () => null,
}) => {
  const [id, rowId, fname, lname, , Equipment_title, status, equipmentId] = RemoveDriverData;
  const { use } = useTheme();
  const showToaster = useShowToaster();

  const removeData = (id) => {
    axios
      .delete(`${API_URL}/equipment-driver/${id}/${rowId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.success) {
          deleteDriverModalClose();
          nextAvailable();
          onSuccess();
          RemoveDriverModal(rowId, res.data.data);
          setTimeout(() => {
            RemoveDriverModalClose();
          }, 3000);
        } else {
          showToaster({ type: 'error', message: res.data.message });
        }
      });
  };

  async function nextAvailable() {
    const values = { equipment_id: rowId };
    axios.post(`${API_URL}/equipment/next-available-location`, values, {
      headers: authHeader(),
    });
  }

  return (
    <div className='equipment-list-remove-driver' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <Modal.Body className='remove-driver-dailogue-body'>
        <div className='remove-driver-dailogue-wrapper'>
          <h6 className='remove-modal-header' style={{ color: use(palette.dark800, palette.gray200) }}>
            Remove Driver
          </h6>
          <p className='remove-driver-paragraph' style={{ color: use(palette.dark800, palette.gray200) }}>
            {status === 'On Shipment' ? (
              'Driver is on active shipment. Please complete or revert the shipment then remove the driver.'
            ) : (
              <>
                {' '}
                Are you sure you want to remove driver{' '}
                <span>
                  {' '}
                  {fname} {lname}{' '}
                </span>
                from {equipmentId} {Equipment_title} ?{' '}
              </>
            )}
          </p>
          <div className='d-flex justify-content-end remove-driver-footer mt-3'>
            <button
              className='cancel-button'
              onClick={deleteDriverModalClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            <button
              className={status !== 'On Shipment' ? 'vehicle-list-remove-button' : 'remove-disabled'}
              type='submit'
              disabled={status === 'On Shipment'}
              onClick={() => removeData(id)}
            >
              Yes, Remove
            </button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default RemoveDriver;
