import { numberWithCommas } from 'utils/constants';

export const getOptions = (currency) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 18,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback(val) {
            const label = this.getLabelForValue(val);
            const monthYear = label.split(' ');
            return `${monthYear[0].slice(0, 3)} ${monthYear[1]}`;
          },
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      labels: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(item) {
            if (!item[0]) {
              return null;
            }

            return `${item[0].label} - ${currency}${numberWithCommas(Number(item[0].raw).toFixed(2))}`;
          },
        },
        backgroundColor: '#FFF',
        titleColor: '#000000',
        bodyColor: '#000000',
        titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
        bodyFont: { size: 0 },
        displayColors: false,
        yAlign: 'bottom',
        borderWidth: 1,
        borderColor: '#e0e0e0',
      },
    },
  };
};
