import moment from 'moment';

export const getInitialValues = (settings) => {
  const { ifta_sticker_active, ifta_sticker_expire_date, ifta_sticker_attach, ifta_sticker_no } = settings || {};

  return {
    ifta_sticker_active: !!ifta_sticker_active,
    ifta_sticker_no: ifta_sticker_no || '',
    ifta_sticker_expire_date: ifta_sticker_expire_date ? moment(ifta_sticker_expire_date).toDate() : null,
    ifta_sticker_no_expire: !ifta_sticker_expire_date,
    ifta_sticker_attach: ifta_sticker_attach || null,
  };
};

export const iftaFields = {
  id: 1,
  title: 'IFTA',
  policy_type: 'ifta_sticker',
  active: 'ifta_sticker_active',
  policy_id: 'ifta_sticker_no',
  expire_date: 'ifta_sticker_expire_date',
  no_expire: 'ifta_sticker_no_expire',
  attachment: 'ifta_sticker_attach',
};
