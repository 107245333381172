import React from 'react';
import HeaderStar from 'components/HeaderStar';
import TableSection from './TableSection';
import { SHeaderWrapper, SPageWrapper } from './Hos-Logbook.styles';

const HosLogbook = () => {
  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Hours Of Service' />
      </SHeaderWrapper>
      <TableSection />
    </SPageWrapper>
  );
};

export default HosLogbook;
