import React from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

import Info from './Info';
import { SColumn, SSection } from '../ViewApplicantModal.styles';

const applicantStatus = {
  individual: 'Individual',
  company: 'Owner Operator',
};

const ApplicantInfo = ({ data }) => {
  const { job_positing, job_applicant_information } = data || {};

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Type of Applicant
      </Typography>
      <Divider />
      <Typography variant='s1' style={{ color: palette.gray900 }}>
        {job_positing?.applicant_type?.name} ({applicantStatus[job_applicant_information?.applicant_status]}
        {job_applicant_information?.applicant_status === 'company'
          ? ` - ${job_applicant_information.company_name}`
          : ''}
        )
      </Typography>
      <SSection>
        <SColumn>
          <Info label='EI/SS Number' infoText={job_applicant_information?.ein} />
        </SColumn>
        <SColumn>
          <Info label='Country' infoText={job_applicant_information?.country?.name} />
        </SColumn>
        <SColumn>
          <Info
            label='Address'
            infoText={
              job_applicant_information &&
              `${job_applicant_information?.address1} ${job_applicant_information?.address2 || ''}`
            }
          >
            {job_applicant_information && (
              <Typography variant='b2' style={{ color: palette.gray900 }}>
                {job_applicant_information?.city.name}, {job_applicant_information?.state.name}{' '}
                {job_applicant_information?.zipcode}
              </Typography>
            )}
          </Info>
        </SColumn>
      </SSection>
      <Divider />
    </div>
  );
};

export default ApplicantInfo;
