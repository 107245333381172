import get from 'lodash/get';

export const getCompanyName = (data) => {
  const driverType = get(data, 'user.app.driver_type');

  switch (driverType) {
    case 'company':
      return `Company Driver, ${data.company_name}` || '';
    case 'owner':
      return `Owner Operator, ${get(data, 'user.app.operator.owner_operator_name', '')}`;
    default:
      return null;
  }
};
