import { formatNumber, palette } from 'utils/constants';

const labels = ['Take Home', 'Taxes', 'Benefits', 'Deductions'];

export const options = {
  cutout: '60%',
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  labels: {
    display: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

export const plugins = [
  {
    id: 'hoverText',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      ctx.save();

      if (_active.length) {
        ctx.font = '700 24px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`$${formatNumber(_active[0]?.element?.$context?.raw)}`, width / 2, height / 2 - 10);

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${labels[_active[0]?.index]}`, width / 2, height / 2 + 16);
      }
    },
  },
  {
    id: 'textCenter',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      const total = chart.data.datasets[0].data?.reduce((acc, cur) => {
        return acc + cur;
      }, 0);

      if (_active.length === 0) {
        ctx.save();
        ctx.font = '700 24px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        if (total) {
          ctx.fillText(`$${formatNumber(total)}`, width / 2, height / 2 - 10);
        }

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('Total', width / 2, height / 2 + 16);
      }
    },
  },
];

export const defaultPlugins = [
  {
    id: 'textCenter',
    afterDraw(chart) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;

      ctx.save();
      ctx.font = '700 32px Inter';
      ctx.fillStyle = palette.gray700;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('0', width / 2, height / 2 - 10);

      ctx.font = '500 16px Inter';
      ctx.fillStyle = palette.gray500;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Total', width / 2, height / 2 + 16);
    },
  },
];
