import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SErrorMessage = styled.p`
  color: ${palette.red500};
  margin: 0;
  padding: 0;
  font-size: 11px;
  line-height: 18px;
  text-align: left;
`;
