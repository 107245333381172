import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { operatorEquipmentTrailer, operatorEquipmentVehicle } from 'Api/OwnerOperator';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import { SWrapper, SListWrapper } from './OwnerEquipment.styles';

const RequestTable = () => {
  const [tab, setTab] = useState(1);
  const [driverRequest, setDriverRequest] = React.useState({});
  const [tabs, setTabs] = useState([]);
  const [allRequest, setAllRequest] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getRequestData = useCallback(() => {
    Promise.allSettled([operatorEquipmentVehicle({ id }), operatorEquipmentTrailer({ id })]).then((responses) => {
      if (responses[0].status && responses[1].status === 'fulfilled') {
        const tempArray = { data: [] };
        responses[0].value.forEach((item) => {
          item?.equipment?.forEach((equip) => {
            tempArray.data.push(equip);
          });
        });
        responses[1].value.forEach((item) => {
          item?.equipment?.forEach((equip) => {
            tempArray.data.push(equip);
          });
        });

        const tempTabs = [];
        let value = 1;

        for (const req of tempArray?.data || {}) {
          const hasRequestIndex = tempTabs.findIndex((tab) => tab.label === req?.equipment_type?.vehicle_type);
          if (hasRequestIndex === -1) {
            value += 1;
            tempTabs.push({
              label: req?.equipment_type?.vehicle_type,
              count: 1,
              key: req?.equipment_type?.vehicle_type,
              value,
            });
          } else {
            tempTabs[hasRequestIndex].count += 1;
          }
        }
        tempTabs.unshift({ label: 'All', count: tempArray?.data?.length, key: 'all', value: 1 });
        setTabs(tempTabs);
        setDriverRequest(tempArray);
        setAllRequest(tempArray);
      }
    });
  }, [id]);

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  const onChangeTab = (tabValue) => {
    setTab(tabValue);
    if (tabValue.value === 1) {
      setDriverRequest(allRequest);
    } else {
      const getSelectedRequest = tabs.find((req) => req.value === tabValue?.value);
      const reqFilterData = allRequest?.data.filter(
        (req) => req?.equipment_type?.vehicle_type === getSelectedRequest.label
      );
      setDriverRequest({ ...driverRequest, data: reqFilterData });
    }
  };

  return (
    <SWrapper>
      <div
        className='request-table-container owner-equip-container driver-style-wrap'
        style={{
          backgroundColor: palette.white,
          borderColor: palette.gray50,
        }}
      >
        <div className='header-container' style={{ borderColor: palette.gray50 }}>
          <div className='sub-header'>
            <p className='heading' style={{ color: palette.gray700 }}>
              Equipment
            </p>
          </div>
        </div>
        <div className='table-wrap'>
          <SwitchRadio
            name='tableTopTabMenu'
            items={tabs}
            value={tab}
            type='tab'
            onChange={(v) => onChangeTab(v)}
            plus={false}
          />
          <SListWrapper>
            {driverRequest?.data?.map((equipment) => {
              const truckType =
                equipment?.truck_type === 2 ? '(Sleeper)' : equipment?.truck_type === 1 ? '(Day-Cab)' : '';
              const equipmentLength =
                equipment.length?.length && equipment.length?.unit
                  ? `${equipment.length.length}${equipment.length.unit}`
                  : '';

              return (
                <div
                  key={equipment.id}
                  className='list-item'
                  onClick={() =>
                    navigate(
                      `/equipment-profile/${equipment.equipment_type?.vehicle_type.toLowerCase()}/${equipment.id}`
                    )
                  }
                >
                  <Typography variant='s2'>
                    {equipment?.equipment_id} {equipmentLength} {equipment?.equipment_type?.title} {truckType}
                  </Typography>
                </div>
              );
            })}
          </SListWrapper>
        </div>
      </div>
    </SWrapper>
  );
};

export default RequestTable;
