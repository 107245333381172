import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { CircularProgress } from '@mui/material';
import { GetSettingsAccounting } from 'Api/CompanySettings';
import {
  createCustomerPayTerms,
  createCustomPayTerm,
  CustomerPayTerms,
  getCustomerPayTerms,
  UpdateCustomersPayTerm,
} from 'Api/Customers';
import { blockNotNumberChars } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { Typography } from 'components/Typography';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import CustomizedSnackbars from '../../toast/Toast';
import { paymentValidationSchema } from './ValidationSchema';
import CustomSelect from '../../CreateShipment/ShipmentStops/helpers/CustomSelect';
import sm from '../../PaymentTermsTab/PaymentTermsTab.module.css';

const dropTrailerStartsAfterOptions = [
  {
    name: 'DAYS',
    value: 'days',
    key: 'days',
    label: 'days',
    labelSelected: null,
  },
  {
    name: 'HOURS',
    value: 'hours',
    key: 'hours',
    label: 'hours',
    labelSelected: null,
  },
];

const PaymentTerms = ({ Cancel, nextStep, customer, paymentTerms = {}, showError }) => {
  const hasBrokerage = useHasBrokerage();
  const { currency, billOfLadingTitle } = useSelector((state) => state.root);
  const [onNextloading, setOnNextloading] = useState(false);
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState([]);
  const [accountingSettings, setAccountingSettings] = useState(null);
  const [existingTermId, setExistingTermId] = useState(null);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const {
    invoice_pay_term,
    invoice_note,
    require_signature_on_stop,
    require_cargo_scan_on_pickup,
    is_brokerage_customer,
    require_bill_of_lading,
    require_confirmations,
    send_invoice_to_factoring,
    detention,
    detentionTrailer,
    detention_starts_after_hour,
    billed_at,
    detention_trailer_dropped_starts_after,
    detention_trailer_dropped_start_after_per,
    detention_trailer_dropped_billed_at,
    credit_line_maximum,
  } = paymentTerms || {};

  const { use } = useTheme();
  const formik = useFormik({
    initialValues: {
      invoice_pay_term: invoice_pay_term || '',
      invoice_note: invoice_note || '',
      require_signature_on_stop: require_signature_on_stop || false,
      require_cargo_scan_on_pickup: require_cargo_scan_on_pickup || false,
      is_brokerage_customer: !!is_brokerage_customer,
      require_bill_of_lading: require_bill_of_lading || false,
      require_confirmations: require_confirmations || false,
      send_invoice_to_factoring: send_invoice_to_factoring || false,
      detention: detention || false,
      detentionTrailer: detentionTrailer || false,
      detention_starts_after_hour: detention_starts_after_hour || '',
      billed_at: billed_at || '',
      detention_trailer_dropped_starts_after: detention_trailer_dropped_starts_after || '',
      detention_trailer_dropped_start_after_per: detention_trailer_dropped_start_after_per || '',
      detention_trailer_dropped_billed_at: detention_trailer_dropped_billed_at || '',
      credit_line_maximum: credit_line_maximum || '',
      customTerm: '',
      discount: '',
      net_days: '',
    },
    validationSchema: paymentValidationSchema,
  });

  const onSkip = () => {
    nextStep();
  };

  const createOrUpdate = (payTermInfo) => {
    if (existingTermId) {
      const payload = {
        customer_id: customer.basicDetails.customer,
        invoice_note: payTermInfo.invoice_note,
        require_signature_on_stop: payTermInfo.require_signature_on_stop ? 1 : 0,
        require_bill_of_lading: payTermInfo.require_bill_of_lading ? 1 : 0,
        require_confirmations: payTermInfo.require_confirmations ? 1 : 0,
        is_brokerage_customer: payTermInfo.is_brokerage_customer ? 1 : 0,
        send_invoice_to_factoring: payTermInfo.send_invoice_to_factoring ? 1 : 0,
        detention_starts_after_hour: payTermInfo.detention ? payTermInfo.detention_starts_after_hour : null,
        detention_trailer_dropped_starts_after: payTermInfo.detentionTrailer
          ? payTermInfo.detention_trailer_dropped_starts_after
          : null,
        detention_trailer_dropped_start_after_per: payTermInfo.detentionTrailer
          ? payTermInfo.detention_trailer_dropped_start_after_per
          : null,
        detention_trailer_dropped_billed_at: payTermInfo.detentionTrailer
          ? payTermInfo.detention_trailer_dropped_billed_at
          : null,
        billed_at: payTermInfo.billed_at,
        credit_line_maximum: payTermInfo.credit_line_maximum,
        invoice_pay_term: payTermInfo.invoice_pay_term,
        required_document_type_id: payTermInfo.required_document_type?.id || null,
        require_cargo_scan_on_pickup: payTermInfo.require_cargo_scan_on_pickup ? 1 : 0,
      };
      UpdateCustomersPayTerm(payload, existingTermId)
        .then(() => {
          nextStep(payTermInfo, `Pay term has been created successfully`);
          setOnNextloading(false);
        })
        .catch(() => {
          setOnNextloading(false);
        });
    } else {
      createCustomerPayTerms(payTermInfo, customer.basicDetails.customer)
        .then(() => {
          nextStep(payTermInfo, `Pay term has been created successfully`);
          setOnNextloading(false);
        })
        .catch(() => {
          setOnNextloading(false);
        });
    }
  };

  const onNextStep = async () => {
    setOnNextloading(true);
    const payTermInfo = { ...formik.values };
    if (
      formik.values.invoice_pay_term === 'custom' &&
      formik.values.customTerm &&
      formik.values.discount &&
      formik.values.net_days
    ) {
      createCustomPayTerm(formik.values.customTerm, formik.values.discount, formik.values.net_days)
        .then((res) => {
          payTermInfo.invoice_pay_term = res.data.id;
          createOrUpdate(payTermInfo);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else {
      createOrUpdate(payTermInfo);
    }
  };

  const getAccountingSettings = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountingSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerPaymentTerms = async () => {
    try {
      const { data } = await CustomerPayTerms({ id: customer.basicDetails.customer });
      if (!data) {
        return;
      }
      setExistingTermId(data.id);
      formik.setValues((prevState) => ({
        ...prevState,
        invoice_pay_term: data.invoice_pay_term ? Number(data.invoice_pay_term) : '',
        invoice_note: data.invoice_note || '',
        require_signature_on_stop: !!data.require_signature_on_stop,
        require_cargo_scan_on_pickup: !!data.require_cargo_scan_on_pickup,
        is_brokerage_customer: !!data.is_brokerage_customer,
        require_bill_of_lading: !!data.require_bill_of_lading,
        require_confirmations: !!data.require_confirmations,
        send_invoice_to_factoring: !!Number(data.send_invoice_to_factoring),
        detention: !!data.detention_starts_after_hour && !!data.billed_at,
        detentionTrailer:
          !!data.detention_trailer_dropped_billed_at &&
          !!data.detention_trailer_dropped_start_after_per &&
          !!data.detention_trailer_dropped_starts_after,
        detention_starts_after_hour: data.detention_starts_after_hour || '',
        billed_at: data.billed_at || '',
        detention_trailer_dropped_starts_after: data.detention_trailer_dropped_starts_after || '',
        detention_trailer_dropped_start_after_per: data.detention_trailer_dropped_start_after_per || '',
        detention_trailer_dropped_billed_at: data.detention_trailer_dropped_billed_at || '',
        credit_line_maximum: data.credit_line_maximum || '',
      }));
    } catch (e) {
      formik.setValues((prevState) => ({
        ...prevState,
        invoice_pay_term: 2,
      }));
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomerPaymentTerms();
    getAccountingSettings();
    getCustomerPayTerms().then((res) => {
      if (res && res?.data) {
        const selectData = res.data.map((el) => {
          return {
            ...el,
            key: el.id,
            label: el.default && el.title === 'Days' ? `${el.net_day} ${el.title}` : `${el.title}`,
            labelSelected: null,
          };
        });
        setInvoicePayTermsOptions([
          { id: 'custom', key: 'custom', label: 'Add custom', labelSelected: null },
          ...selectData,
        ]);
      }
    });
  }, []);

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className='basic-container driver-style-wrap'>
          <div className='d-flex'>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Invoice Pay Terms <span className='required'>*</span>
              </p>
              <div className='d-flex' style={{ alignItems: 'center', marginTop: '10px' }}>
                <div>
                  <div className='custom-select-wrap-contain'>
                    <CustomSelect
                      defaultActiveValue={{
                        id: 1,
                        title: 'Days',
                        qb_sync: 1,
                        qb_id: 6,
                        discount: '0',
                        default: 1,
                        net_day: '30',
                        created_at: '2024-06-21T16:01:58.000000Z',
                        updated_at: '2024-06-24T15:00:04.000000Z',
                        key: 1,
                        label: '30 Days',
                        labelSelected: null,
                      }}
                      options={invoicePayTermsOptions}
                      onChange={(value) => {
                        formik.setFieldValue('invoice_pay_term', value.id);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.invoice_pay_term}
                    />
                  </div>
                  {formik.touched.invoice_pay_terms && formik.errors.invoice_pay_terms ? (
                    <span className='error-message'>{formik.errors.invoice_pay_terms}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {formik.values.invoice_pay_term && formik.values.invoice_pay_term === 'custom' ? (
            <div style={{ marginTop: '20px', display: 'flex' }}>
              <div style={{ marginRight: '20px' }}>
                <p
                  className='text-style'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    marginLeft: '0',
                  }}
                >
                  Term Name <span className='required'>*</span>
                </p>
                <div className='per-hour-work' style={{ marginTop: 10 }}>
                  <input
                    type='text'
                    name='customTerm'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.customTerm}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                      borderRadius: 6,
                      width: '150px',
                    }}
                  />
                </div>
                {formik.touched.customTerm && formik.errors.customTerm ? (
                  <span className={sm['tab-error-message']}>{formik.errors.customTerm}</span>
                ) : null}
              </div>

              <div style={{ marginRight: '20px' }}>
                <p
                  className='text-style'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    marginLeft: '0',
                  }}
                >
                  Discount <span className='required'>*</span>
                </p>
                <div className='per-hour-work' style={{ marginTop: 10 }}>
                  <input
                    type='number'
                    placeholder='0.00'
                    name='discount'
                    id='discount'
                    onBlur={formik.handleBlur}
                    onKeyDown={blockNotNumberChars}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        return;
                      }
                      formik.setFieldValue('discount', event.target.value);
                    }}
                    value={formik.values.discount}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                      borderRadius: 6,
                    }}
                  />
                  <span
                    className='doller-icon'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                      borderRadius: 6,
                      paddingRight: 8,
                    }}
                  >
                    %
                  </span>
                </div>
                {formik.touched.discount && formik.errors.discount ? (
                  <span className={sm['tab-error-message']}>{formik.errors.discount}</span>
                ) : null}
              </div>
              <div style={{ marginRight: '20px' }}>
                <p
                  className='text-style'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    marginLeft: '0',
                  }}
                >
                  Net Days <span className='required'>*</span>
                </p>
                <div className='custom-select-wrap-contain'>
                  <input
                    type='number'
                    onBlur={formik.handleBlur}
                    style={{
                      maxWidth: 100,
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                      borderRadius: '6px',
                      marginTop: '10px',
                      height: '32px',
                      boxShadow: '0px 1px 2px rgb(0 0 0 / 6%), 0px 0px 0px 1px rgb(104 113 130 / 16%)',
                    }}
                    placeholder='00'
                    value={formik.values.net_days}
                    onKeyDown={blockNotNumberChars}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        return;
                      }
                      formik.setFieldValue('net_days', event.target.value);
                    }}
                  />
                </div>
                {formik.touched.net_days && formik.errors.net_days ? (
                  <span className={sm['tab-error-message']}>{formik.errors.net_days}</span>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className='d-flex' style={{ marginTop: '20px' }}>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Invoice Notes
              </p>
              <div className='d-flex flex-column'>
                <div>
                  <textarea
                    className='text-area'
                    name='invoice_note'
                    id='invoice_note'
                    placeholder='Write note...'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.invoice_note}
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                      minWidth: 400,
                    }}
                  />
                </div>
                {formik.touched.invoice_note && formik.errors.invoice_note ? (
                  <span className='error-message'>{formik.errors.invoice_note}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_signature_on_stop}
              onChange={() => {
                formik.setFieldValue('require_signature_on_stop', !formik.values.require_signature_on_stop);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require Signature on Stops</span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_bill_of_lading}
              onChange={() => {
                formik.setFieldValue('require_bill_of_lading', !formik.values.require_bill_of_lading);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require {billOfLadingTitle}</span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_confirmations}
              onChange={() => {
                formik.setFieldValue('require_confirmations', !formik.values.require_confirmations);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require Confirmations</span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_cargo_scan_on_pickup}
              onChange={() => {
                formik.setFieldValue('require_cargo_scan_on_pickup', !formik.values.require_cargo_scan_on_pickup);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require Cargo Scan on Pickups (Driver App)</span>
            </CustomCheckbox>
          </div>
          <div className='d-flex'>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.detention}
              onChange={() => {
                formik.setFieldValue('detention', !formik.values.detention);
              }}
            />
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <span style={{ fontSize: 16, marginRight: 10 }}>Detention starts after</span>
              <div className='payment-option-second-input '>
                <input
                  type='number'
                  placeholder='0'
                  disabled={!formik.values.detention}
                  onBlur={formik.handleBlur}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  value={formik.values.detention_starts_after_hour}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('detention_starts_after_hour', event.target.value);
                  }}
                />
              </div>
              <span style={{ fontSize: 16, margin: '0 10px' }}>hour(s), billed at</span>
              <div className='per-hour-work m-0'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0'
                  name='detention_pay'
                  id='detention_pay'
                  onBlur={formik.handleBlur}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('billed_at', event.target.value);
                  }}
                  disabled={!formik.values.detention}
                  value={formik.values.billed_at}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    borderRadius: '0px 6px 6px 0px',
                  }}
                />
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.detentionTrailer}
              onChange={() => {
                formik.setFieldValue('detentionTrailer', !formik.values.detentionTrailer);
              }}
            />
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <span style={{ fontSize: 16, marginRight: 10 }}>Detention - Trailer Dropped starts after</span>
              <div className='payment-option-second-input '>
                <input
                  type='number'
                  placeholder='0'
                  disabled={!formik.values.detentionTrailer}
                  onBlur={formik.handleBlur}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  value={formik.values.detention_trailer_dropped_starts_after}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('detention_trailer_dropped_starts_after', event.target.value);
                  }}
                />
              </div>
              <div className='payment-option-second-input '>
                <CustomSelect
                  options={dropTrailerStartsAfterOptions}
                  onChange={(value) => {
                    formik.setFieldValue('detention_trailer_dropped_start_after_per', value.name);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.detention_trailer_dropped_start_after_per}
                  disabled={!formik.values.detentionTrailer}
                  styles={{ height: 30, width: 90, marginTop: '0' }}
                />
              </div>
              <span style={{ fontSize: 16, margin: '0px 10px 0 24px' }}>, billed at</span>
              <div className='per-hour-work m-0'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0'
                  name='detention_pay'
                  id='detention_pay'
                  onBlur={formik.handleBlur}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('detention_trailer_dropped_billed_at', event.target.value);
                  }}
                  disabled={!formik.values.detentionTrailer}
                  value={formik.values.detention_trailer_dropped_billed_at}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    borderRadius: '0px 6px 6px 0px',
                  }}
                />
              </div>
            </div>
          </div>
          {!!accountingSettings?.is_factoring && (
            <div>
              <CustomCheckbox
                style={{ margin: '10px' }}
                checked={formik.values.send_invoice_to_factoring}
                onChange={() => {
                  formik.setFieldValue('send_invoice_to_factoring', !formik.values.send_invoice_to_factoring);
                }}
              >
                <div className='d-flex flex-column' style={{ marginLeft: '10px' }}>
                  <span style={{ fontSize: 16 }}>Require Factoring</span>
                  <div className='d-flex align-items-center justify-content-start gap-1'>
                    <WarningIcon />
                    <Typography variant='b2' style={{ color: palette.orange500 }}>
                      Letter of assignment will be sent to this customer to factor all invoices
                    </Typography>
                  </div>
                </div>
              </CustomCheckbox>
            </div>
          )}
          {!!hasBrokerage && (
            <div>
              <CustomCheckbox
                style={{ margin: '10px' }}
                checked={formik.values.is_brokerage_customer}
                onChange={(checked) => formik.setFieldValue('is_brokerage_customer', checked)}
              >
                <span style={{ fontSize: 16, marginLeft: 10 }}>Brokerage Customer</span>
              </CustomCheckbox>
            </div>
          )}
          <div style={{ marginRight: '20px', marginTop: 30 }}>
            <p
              className='text-style'
              style={{
                color: use(palette.gray700, palette.gray200),
                marginLeft: '0',
              }}
            >
              Credit Line Maximum
            </p>
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <div className='per-hour-work' style={{ minWidth: 200 }}>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0.00'
                  name='credit_line_maximum'
                  id='credit_line_maximum'
                  onBlur={formik.handleBlur}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('credit_line_maximum', event.target.value);
                  }}
                  value={formik.values.credit_line_maximum}
                  style={{
                    minWidth: 200,
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    borderRadius: '0px 6px 6px 0px',
                  }}
                />
              </div>
              {formik.touched.credit_line_maximum && formik.errors.credit_line_maximum ? (
                <span className='error-message'>{formik.errors.credit_line_maximum}</span>
              ) : null}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button className='cancel-button' onClick={onSkip}>
              Skip
            </Button>
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                onClick={onNextStep}
                className='next-step-btn'
                disabled={!formik.isValid || !formik.values.invoice_pay_term}
                style={{ opacity: formik.isValid && formik.values.invoice_pay_term ? 1 : 0.7 }}
              >
                Next Step
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
      {showMessage && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </Form>
  );
};

export default PaymentTerms;
