import { Box, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import './equipmentdetectedhardware.css';
import { Icons } from 'assets/icons';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import DeviceNickname from 'components/MyHardware/CustomerHardware/DeviceNickname';
import Phone_Hardware from 'assets/icons/equipment/Phone_Hardware.svg';
import { EquipmentDetectedHardware } from 'Api/Equipment';
import { useNavigate } from 'react-router-dom';
import { statues } from '../../../../../Hos-Logbook/CustomCollapsibleRow/CustomCollapsibleRow.data';
import Chip from '../../../../../../common/Chip';
import CustomButton from '../../../../../../components/CustomButton/CustomButton';

const EquipmentDetectedHardwares = ({ profileData }) => {
  const navigate = useNavigate();
  const { use } = useTheme();
  const flag = profileData?.drivers?.filter((ele) => ele.hasOwnProperty('driver_connected_services_hos'))?.length > 0;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 670,
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 0,
    padding: 0,
  };
  const [open, setOpen] = React.useState(false);
  const [detectedHardware, setDetectedHardware] = useState();

  const handleClose = () => setOpen(false);

  async function init() {
    EquipmentDetectedHardware(profileData?.id).then((res) => {
      setDetectedHardware(res?.drivers);
    });
  }

  const onEditSuccess = (data) => {
    const newData = detectedHardware.map((item) => {
      if (item?.driver_connected_device_latest?.identifier === data.identifier) {
        return {
          ...item,
          driver_connected_device_latest: { ...item.driver_connected_device_latest, nickname: data.nickname },
        };
      }
      return item;
    });

    setDetectedHardware(newData);
  };

  useEffect(() => {
    if (profileData?.id) {
      init();
    }
  }, [profileData?.id]);

  return (
    <>
      <div className='d-flex justify-content-between equipment-profile-group-box'>
        <span className='details mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
          DRIVER DEVICE
        </span>
      </div>
      {detectedHardware ? (
        detectedHardware?.map((v) => (
          <div key={v.id}>
            <div className='mt-3 equipment-profile-group-box'>
              <div className='d-flex justify-content-between align-item-center' style={{ alignItems: 'center' }}>
                <div>
                  {v?.driver_connected_device_latest?.device_name ? (
                    <span className='equipment-profile-content'>
                      <img src={Phone_Hardware} alt='Aws Logo' style={{ width: '30px' }} />
                      {v?.driver_connected_device_latest?.device_name}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                {v?.driver_connected_device_latest?.nickname && (
                  <DeviceNickname
                    rowData={v?.driver_connected_device_latest}
                    onSuccess={onEditSuccess}
                    showParentheses
                  />
                )}
                <div
                  className='mt-1'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                  }}
                >
                  <span className='d-flex '>
                    {v?.driver_gps?.signal === 'poor' ? (
                      <div
                        className='equipment-signalstrenth  me-1'
                        key={v.id}
                        style={{
                          backgroundColor: use(palette.white, palette.dark800),
                        }}
                      >
                        <img src={Icons.PoorSignal} alt='' style={{ marginTop: '-8px' }} />
                      </div>
                    ) : v?.driver_gps?.signal === 'good' ? (
                      <div
                        className='equipment-profile-signalstrenth '
                        style={{
                          backgroundColor: use(palette.white, palette.dark800),
                        }}
                      >
                        <img src={Icons.GoodSignal} alt='' style={{ marginTop: '-8px' }} />
                      </div>
                    ) : v?.driver_gps?.signal === 'great' ? (
                      <div
                        className='equipment-signalstrenth  '
                        style={{
                          backgroundColor: use(palette.white, palette.dark800),
                        }}
                      >
                        <img src={Icons.StrongSignal} alt='' style={{ marginTop: '-8px' }} />
                      </div>
                    ) : (
                      ''
                    )}

                    {v?.driver_gps?.battery ? (
                      <div
                        className='Battery'
                        style={{
                          backgroundColor: use(palette.white, palette.dark800),
                        }}
                      >
                        <img
                          src={
                            Number(v?.driver_gps?.battery) > 0 && Number(v?.driver_gps?.battery) <= 25
                              ? Icons.LowBattery
                              : Number(v?.driver_gps?.battery) > 25 && Number(v?.driver_gps?.battery) <= 79
                              ? Icons.MediumBattery
                              : Icons.Battery
                          }
                          alt=''
                        />
                        <span className='equipment-profile-connected-hardware'>{v?.driver_gps?.battery} %</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                {/* <button
            className="equipment-profile-content"
            style={{ border: "none", color: "red", backgroundColor: "white" }}
          >
            {" "}
            Remove{" "}
          </button> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <span className='equipment-profile-content equipment-profile-group-box mt-2'>No driver device detected</span>
      )}

      <div>
        {flag ? (
          <div className='d-flex justify-content-between equipment-profile-group-box'>
            {profileData?.drivers?.map((v) =>
              v?.driver_connected_services_hos?.drive_remaining === '00::00::00' &&
              v?.driver_connected_services_hos?.shift_remaining === '00::00::00' &&
              v?.driver_connected_services_hos?.cycle_remaining_duration === '00::00::00' &&
              v?.driver_connected_services_hos?.time_until_break === '00::00::00' &&
              !v?.driver_connected_services_hos?.duty_status ? (
                ''
              ) : v?.driver_connected_services_hos ? (
                <span key={v.id} className='details mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
                  HOURS OF SERVICE
                </span>
              ) : (
                ''
              )
            )}
          </div>
        ) : (
          ''
        )}

        {flag && (
          <div>
            {profileData?.drivers?.map(
              (v) =>
                v?.driver_connected_services_hos && (
                  <div key={v.id}>
                    <div className='equipment-profile-group-box'>
                      <div
                        className='d-flex justify-content-between  mt-3 mb-1'
                        style={{ color: use(palette.dark800, palette.gray200) }}
                      >
                        <span className='equipment-profile-content'>Drive Remaining</span>
                        <span className='equipment-profile-content-value'>
                          {v?.driver_connected_services_hos?.drive_remaining || '-'}
                        </span>
                      </div>
                      <div
                        className='d-flex justify-content-between  mt-3 mb-1'
                        style={{ color: use(palette.dark800, palette.gray200) }}
                      >
                        <span className='equipment-profile-content'>Shift Remaining</span>
                        <span className='equipment-profile-content-value'>
                          {v?.driver_connected_services_hos?.shift_remaining || '-'}
                        </span>
                      </div>
                      <div
                        className='d-flex justify-content-between  mt-3 mb-1'
                        style={{ color: use(palette.dark800, palette.gray200) }}
                      >
                        <span className='equipment-profile-content'>Cycle Remaining</span>
                        <span className='equipment-profile-content-value'>
                          {v?.driver_connected_services_hos?.cycle_remaining_duration || '-'}
                        </span>
                      </div>
                      <div
                        className='d-flex justify-content-between  mt-3 mb-1'
                        style={{ color: use(palette.dark800, palette.gray200) }}
                      >
                        <span className='equipment-profile-content'>Time Until Break</span>
                        <span className='equipment-profile-content-value'>
                          {v?.driver_connected_services_hos?.time_until_break || ''}
                        </span>
                      </div>
                      {v?.driver_connected_services_hos?.duty_status ? (
                        <div
                          className='d-flex justify-content-between  mt-3 mb-1'
                          style={{ color: use(palette.dark800, palette.gray200) }}
                        >
                          <span className='equipment-profile-content'>Duty Status</span>
                          <Chip
                            bgColor={statues[v?.driver_connected_services_hos?.duty_status]?.bgColor}
                            labelColor={statues[v?.driver_connected_services_hos?.duty_status]?.color}
                            label={statues[v?.driver_connected_services_hos?.duty_status]?.name}
                            fontWeight={500}
                            fontSize='11px'
                            style={{ textTransform: 'uppercase' }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='d-flex justify-content-center  mt-3 mb-1'>
                        <CustomButton
                          type='primary'
                          title='View Logs'
                          className='w-100 mt-2'
                          styleButton={{ height: 28, justifyContent: 'center' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/hos-logbook/${v.id}`);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        )}
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Text in a modal
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2 }}
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default EquipmentDetectedHardwares;
