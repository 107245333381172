import React, { useState } from 'react';
import License from 'assets/icons/drivers/license.svg';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import ViewAttachment from 'components/ViewAttachment';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';

export const ViewIncidentBody = ({ incident }) => {
  const { formatDateTime } = useDateFormat();
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const bucketPrefix = `https://${user?.customer?.dot}.s3.amazonaws.com/`;
  const [openAttachment, setOpenAttachment] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isVideo, setIsVideo] = useState(false);

  return (
    <>
      <div className='incident-modal-body-box'>
        <div className='incident-wrapper'>
          <div className='incident-details-container' style={{ borderRightColor: palette.gray50 }}>
            <div className='incident-details'>
              <p className='property'>Incident ID</p>
              <p className='property-detail' style={{ color: palette.gray900 }}>
                {incident?.incident_id}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Report Number</p>
              <p className='property-detail' style={{ color: palette.gray900 }}>
                {incident?.report_number || '-'}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Date + Time</p>
              <p className='property-detail' style={{ color: palette.gray900 }}>
                {formatDateTime(`${incident?.incident_date} ${incident?.incident_time}`)}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Type of Incident</p>
              <p className='property-detail' style={{ color: palette.gray900 }}>
                {incident?.custom_incident_type ? incident?.custom_incident_type : incident?.incident_type?.type}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Location of Incident</p>
              <p className='property-detail' style={{ color: palette.gray900 }}>
                {incident?.city?.name || incident?.state?.name || incident?.country?.name
                  ? `${incident?.city?.name || ''}${incident?.city?.name ? ',' : ''} ${incident?.state?.name || ''}${
                      incident?.state?.name ? ',' : ''
                    } ${incident?.country?.name || ''} `
                  : ''}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Report Details</p>
              <p className='property-detail-sub' style={{ color: palette.gray900 }}>
                {incident?.report_details || '-'}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Reason(s) given by Person Implicated</p>
              <p className='property-detail-sub' style={{ color: palette.gray900 }}>
                {incident?.reason || ''}
              </p>
              {incident?.statement && (
                <p className='property-detail-sub' style={{ color: palette.gray900 }}>
                  {incident?.statement || ''}
                </p>
              )}
              {!!incident?.statement_document && (
                <Typography
                  variant='s2'
                  style={{ textDecoration: 'underline', color: palette.indigo500, cursor: 'pointer' }}
                  onClick={() => setPdfUrl(incident?.statement_document)}
                >
                  View Statement
                </Typography>
              )}
              {!incident?.reason && !incident?.statement && !incident?.statement_document && (
                <p className='property-detail-sub' style={{ color: palette.gray900 }}>
                  -
                </p>
              )}
            </div>
            <div className='incident-details'>
              <p className='property'>Driver Signature</p>
              {incident?.signature ? (
                <img
                  className='object-fit-contain'
                  onClick={() => setOpenAttachment(incident?.signature)}
                  src={incident?.signature}
                  width={100}
                  height={100}
                  alt='Incident'
                />
              ) : (
                '-'
              )}
            </div>
            <div className='incident-details'>
              <p className='property'>Notes</p>
              <p className='property-detail-sub' style={{ color: palette.gray900 }}>
                {incident?.notes || '-'}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Action By</p>
              <p className='property-detail' style={{ color: palette.gray900 }}>
                {incident?.added_by?.first_name
                  ? `${incident?.added_by?.first_name} ${incident?.added_by?.last_name || ' '}`
                  : '-'}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Action Taken</p>
              <p className='property-detail-sub' style={{ color: palette.gray900 }}>
                {incident?.incident_action_taken?.action || '-'}
              </p>
            </div>
            <div className='incident-details'>
              <p className='property'>Incident Photo(s)</p>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(calc(25% - 8px), 1fr))',
                  gap: '8px',
                  marginTop: '8px',
                }}
              >
                {incident?.incident_record_images?.length &&
                  incident?.incident_record_images.map((item) => (
                    <img
                      onClick={() =>
                        setOpenAttachment(
                          item?.image_path?.startsWith('https://')
                            ? item.image_path
                            : `${bucketPrefix}${item.image_path}`
                        )
                      }
                      src={
                        item?.image_path?.startsWith('https://') ? item.image_path : `${bucketPrefix}${item.image_path}`
                      }
                      width={100}
                      height={100}
                      alt='Incident'
                    />
                  ))}
              </div>
            </div>
            <div className='incident-details'>
              <p className='property'>Incident Video(s)</p>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(calc(25% - 8px), 1fr))',
                  gap: '8px',
                  marginTop: '8px',
                }}
              >
                {incident?.incident_record_video?.length &&
                  incident?.incident_record_video.map((item) => (
                    <video
                      onClick={() => {
                        setIsVideo(true);
                        setOpenAttachment(
                          item?.video_path?.startsWith('https://')
                            ? item.video_path
                            : `${bucketPrefix}${item.video_path}`
                        );
                      }}
                      src={
                        item?.video_path?.startsWith('https://') ? item.video_path : `${bucketPrefix}${item.video_path}`
                      }
                      width={100}
                      height={100}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div
            className='w-50 d-flex flex-column gap-4 ms-3'
            style={{
              borderBottomColor: palette.gray50,
            }}
          >
            {incident?.incident_record_documents?.length ? (
              <>
                <Typography variant='s1'>Incident Documents</Typography>
                <div className='d-flex flex-column gap-2'>
                  {incident?.incident_record_documents.map((doc) => (
                    <Typography
                      key={doc.path}
                      variant='s2'
                      style={{ textDecoration: 'underline', color: palette.indigo500, cursor: 'pointer' }}
                      onClick={() => (doc.path?.endsWith('.pdf') ? setPdfUrl(doc.path) : setOpenAttachment(doc.path))}
                    >
                      {doc.name}
                    </Typography>
                  ))}
                </div>
              </>
            ) : (
              <img src={License} alt='' className='image-show' />
            )}
          </div>
        </div>
      </div>
      {!!openAttachment && (
        <ViewAttachment
          title={`Incident ${isVideo ? 'Video' : 'Photo'}`}
          open={openAttachment}
          onClose={() => {
            setOpenAttachment(null);
            setIsVideo(false);
          }}
          attachment={openAttachment}
          isVideo={isVideo}
        />
      )}
      <ViewPdf title='View Document' open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
    </>
  );
};
