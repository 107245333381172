import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import Popover from '@mui/material/Popover';
import { useNavigate, useParams } from 'react-router-dom';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import calendar from 'assets/icons/drivers/calendar.svg';
import { driverShipmentData } from 'Api/Driver';
import { getStaffBookedShipments } from 'Api/StaffV2';
import useDebounce from 'hooks/useDebounce';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import DateRange from '../DateRangePicker/DateRangePicker';
import './ShipmentTab.css';
import MaterialTableWrapper from '../MaterialTableWrapper';
import TableFooter from '../TableFooter/TableFooter';
import AbsenceModal from '../AddAbsenceModal/AddAbsenceModal';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import { useShipmentColumns } from './ShipmentTab.data';

const ShipmentTabTable = ({ isStaff }) => {
  const { id } = useParams();
  const { use } = useTheme();
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [shipmentTab, setShipmentTab] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [updateAbsenceModalShow, setUpdateAbsenceModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [search, setSearch] = useState('');
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });
  const [profileAlert, setProfileAlert] = useState(null);
  const [alertData, setAlertData] = useState();
  const [sort, setSort] = useState({ field: '', sortBy: 'desc' });
  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;
  const debouncedSearch = useDebounce(search, 500);

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const getDriverShipmentData = () => {
    setLoading(true);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    const payload = {
      page: '1',
      itemsPerPage: rowPerPage,
      from_date: startFormat,
      to_date: endFormat,
      driver_id: !isStaff ? id : undefined,
      query: debouncedSearch || undefined,
      sort,
    };
    if (!isStaff) {
      driverShipmentData(payload)
        .then((res) => {
          for (const val of res?.data || []) {
            let count = 0;
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.findLast((stop) => stop.stop_point_type === '2');
            val.isPW = !(val.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
            for (const data of val?.shipment_stops || []) {
              if (data?.departure_date) {
                count += 1;
              }
            }
            val.stopDepartureCount = count;
          }
          setShipmentTab(res);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      getStaffBookedShipments(id, payload)
        .then((res) => {
          for (const val of res?.data || []) {
            let count = 0;
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.findLast((stop) => stop.stop_point_type === '2');
            val.isPW = !(val.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
            for (const data of val?.shipment_stops || []) {
              if (data?.departure_date) {
                count += 1;
              }
            }
            val.stopDepartureCount = count;
          }
          setShipmentTab(res);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const onPageChange = (event, page) => {
    setLoading(true);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filter = {
      page,
      itemsPerPage: rowPerPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      driver_id: !isStaff ? id : undefined,
    };
    if (!isStaff) {
      driverShipmentData(filter)
        .then((res) => {
          for (const val of res?.data || []) {
            let count = 0;
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.find((stop) => stop.stop_point_type === '2');
            val.isPW = !(val.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
            for (const data of val?.shipment_stops || []) {
              if (data?.departure_date) {
                count += 1;
              }
            }
            val.stopDepartureCount = count;
          }
          setShipmentTab(res);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    } else {
      getStaffBookedShipments(id, filter)
        .then((res) => {
          for (const val of res?.data || []) {
            let count = 0;
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.find((stop) => stop.stop_point_type === '2');
            val.isPW = !(val.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
            for (const data of val?.shipment_stops || []) {
              if (data?.departure_date) {
                count += 1;
              }
            }
            val.stopDepartureCount = count;
          }
          setShipmentTab(res);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const ProfileAlerthandleClick = (e, alerts) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
  };

  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    const payload = {
      page: 1,
      itemsPerPage: rowPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      driver_id: !isStaff ? id : undefined,
    };
    if (search) {
      payload.query = search;
    }
    setRowPerPage(rowPage);
    if (!isStaff) {
      driverShipmentData(payload)
        .then((res) => {
          for (const val of res?.data || []) {
            let count = 0;
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.find((stop) => stop.stop_point_type === '2');
            val.isPW = !(val.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
            for (const data of val?.shipment_stops || []) {
              if (data?.departure_date) {
                count += 1;
              }
            }
            val.stopDepartureCount = count;
          }
          setShipmentTab({ ...res });
        })
        .finally(() => setLoading(false));
    } else {
      getStaffBookedShipments(id, payload)
        .then((res) => {
          for (const val of res?.data || []) {
            let count = 0;
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.find((stop) => stop.stop_point_type === '2');
            val.isPW = !(val.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
            for (const data of val?.shipment_stops || []) {
              if (data?.departure_date) {
                count += 1;
              }
            }
            val.stopDepartureCount = count;
          }
          setShipmentTab({ ...res });
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getDriverShipmentData(debouncedSearch);
  }, [debouncedSearch, dateRange, sort]);

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={shipmentTab?.total}
        totalLength={shipmentTab?.data?.length}
        lastPage={shipmentTab?.last_page}
        currentPage={shipmentTab?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setShipmentTab(data)}
        onPageChange={onPageChange.bind(null, event)}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
      />
    );
  };

  const columns = useShipmentColumns({ ProfileAlerthandleClick, sortingQuery, sort });

  return (
    <div
      className='shipment-table-container driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading' style={{ color: use(palette.gray700, palette.gray200) }}>
            {!isStaff ? 'Shipments' : 'Booked Shipments'}
          </p>
        </div>
        <div className='serach-wrapper'>
          <input
            type='text'
            onChange={(e) => setSearch(e.target.value)}
            className='serach-feild'
            placeholder='Search'
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              color: use(palette.gray400, palette.white),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          />
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '117px', bottom: '197px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
          </div>
        </div>
      </div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0, marginLeft: '20px' }} />
      ) : (
        <div className='table-wrap'>
          <div className='sub-table-container'>
            <MaterialTableWrapper
              data={shipmentTab?.data}
              rowPerPage={rowPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: PaginationComponent,
              }}
              columns={columns}
              onRowClick={(e, rowData) =>
                navigate({
                  pathname: `/shipment/${rowData?.shipment_id}`,
                })
              }
            />
          </div>
          <AbsenceModal show={modalShow} onHide={() => setModalShow(false)} title='Add Attendance' isupdate={false} />
          <AbsenceModal
            show={updateAbsenceModalShow}
            onHide={() => setUpdateAbsenceModalShow(false)}
            title='Update Attendance'
            isupdate
          />
          <Popover
            id={ProfileAlertContent}
            open={ProfileAlertOpen}
            anchorEl={profileAlert}
            onClose={ProfileAlerthandleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            style={{ marginLeft: '20px' }}
          >
            <AlertMsg alertData={alertData} shipmentAlert='Customers' />
          </Popover>
        </div>
      )}
    </div>
  );
};

export default ShipmentTabTable;
