import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Overlay } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { palette } from 'utils/constants';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { useTheme } from 'context/themeContext';
import { putShipmentDocument } from 'Api/Shipment';
import { handleToaster } from 'store/reducers/root.reducer';
import AddDocumentModalShipment from 'components/TableShipments/detailsRow/steps/Documents/AddDocumentModalShipment';
import { Typography } from 'components/Typography';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import useShipmentLocked from 'hooks/useShipmentLocked';
import styles from '../steps/DocumentsRecurring/documentsRecurring.module.css';

const OverLayDocumentsItem = ({
  doc,
  onClickBlock,
  secondParamKey,
  onSubmitEdit,
  setAttachmentUrl,
  onDelete,
  infoHeader,
}) => {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const { isShipmentLocked } = useShipmentLocked();
  const target = useRef(null);
  const [isOpenItemEdit, setIsOpenItemEdit] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  function onEdit(e) {
    e.stopPropagation();
    setIsOpenItemEdit(false);
    setIsOpenEditModal(true);
  }

  function onView(e) {
    e.stopPropagation();
    setIsOpenItemEdit(false);
    setAttachmentUrl(doc.path);
  }

  function onDeleteItem(e) {
    e.stopPropagation();
    typeof onDelete === 'function' && onDelete(e, doc);
  }

  const onSubmitDocEditModal = (values, validateForm, resetForm, submitForm) => {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0 && values.doc !== '';
      if (values.doc === '') return;

      if (isValid) {
        const formData = new FormData();
        if (values?.doc === doc?.path) delete values?.doc;
        Object.keys(values).forEach((key) => {
          if (key !== 'shipment_id' && key !== 'shipment_stop') {
            formData.append(key, values[key]);
          }
        });
        if (values.shipment_stop) {
          formData.append('shipment_stop_id', values.shipment_stop.id);
        }

        putShipmentDocument({ formData, id: doc?.id }).then((res) => {
          if (res?.success) {
            dispatch(handleToaster({ message: 'Document updated successfully!', visible: true, type: 'success' }));
            setIsOpenEditModal(false);
            resetForm();
            typeof onSubmitEdit === 'function' && onSubmitEdit(values);
          }
        });
      }
    });
  };

  const modalInitialValue = useMemo(() => {
    return {
      type: doc?.type !== null ? doc?.type : '',
      reference_id: doc?.reference_id !== null ? doc?.reference_id : '',
      doc: doc?.path !== null ? doc?.path : '',
      shipment_id: doc?.slave_shipment_id ? doc?.slave_shipment_id : doc?.shipment_id ? doc?.shipment_id : null,
      shipment_stop: doc?.shipment_stop ? doc.shipment_stop : null,
    };
  }, [doc]);

  return (
    <>
      <div
        onClick={(e) => onClickBlock(doc, e)}
        className={classNames(styles.filed_item, use(styles.light, styles.dark))}
      >
        <div className={styles.filed_name_item}>
          <Typography variant='s2' style={{ color: palette.indigo500 }}>
            {doc?.reference_id}
          </Typography>
        </div>
        {doc?.customer && (
          <div className={styles.filed_name_item}>
            <Typography variant='s2'>
              {doc?.customer}
              {!!doc?.slave_doc && <small> ({doc?.slave_doc?.shipment_id})</small>}
            </Typography>
          </div>
        )}
        <div className={styles.filed_name_item}>
          <Typography variant='s2'>{doc?.shipment_stop?.stop_point?.location_name || '-'}</Typography>
        </div>
        <div className={styles.filed_name_item}>
          {secondParamKey === 'doc_type' ? (
            <Typography variant='s2'>{doc?.[secondParamKey]?.name}</Typography>
          ) : secondParamKey === 'date' ? (
            <Typography variant='s2'>{doc?.date}</Typography>
          ) : null}
        </div>
        <div style={{ width: '40px', textAlign: 'right' }}>
          {isShipmentLocked(infoHeader) ? (
            <ActionLocked shipment={infoHeader} size={16} />
          ) : (
            <>
              <div
                ref={target}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenItemEdit(true);
                }}
              >
                <EditIcon style={{ transform: 'rotate(90deg)' }} />
              </div>
              <Overlay
                placement='bottom'
                target={target.current}
                show={isOpenItemEdit}
                onHide={() => setIsOpenItemEdit(false)}
                rootCloseEvent='click'
                rootClose
              >
                {({ placement, arrowProps, show: _show, popper, ...props }) => {
                  return (
                    <div
                      {...props}
                      style={{ backgroundColor: use(palette.white, palette.dark800), ...props.style }}
                      className={classNames(styles.popover_documents_item_edit, use(styles.light, styles.dark))}
                    >
                      <div onClick={onView} className={styles.popover_item}>
                        <Typography variant='s2'>View</Typography>
                      </div>
                      {!doc.doc_type.hidden && (
                        <div onClick={onEdit} className={styles.popover_item}>
                          <Typography variant='s2'>Edit</Typography>
                        </div>
                      )}
                      <div onClick={onDeleteItem} className={styles.popover_item}>
                        <Typography variant='s2' style={{ color: use(palette.red400, palette.red500) }}>
                          Delete
                        </Typography>
                      </div>
                    </div>
                  );
                }}
              </Overlay>
            </>
          )}
        </div>
      </div>
      <AddDocumentModalShipment
        initialValueOutSide={modalInitialValue}
        isOpen={isOpenEditModal}
        infoHeader={infoHeader}
        setIsOpen={setIsOpenEditModal}
        onSubmit={onSubmitDocEditModal}
      />
      {/* <DocEditModal */}
      {/*    initialValueOutSide={modalInitialValue} */}
      {/*    isOpen={isOpenEditModal} */}
      {/*    setIsOpen={setIsOpenEditModal} */}
      {/*    onSubmit={onSubmitDocEditModal} */}
      {/* /> */}
    </>
  );
};

export default OverLayDocumentsItem;
