import React, { useMemo, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import classes from '../components/ApplicantsModal.module.scss';

import Position from '../components/Position';
import Education from '../components/Education';
import DriverInfo from '../components/DriverInfo';
import MedicalCard from '../components/MedicalCard';
import ApplicantInfo from '../components/ApplicantInfo';
import DriverLicense from '../components/DriverLicense';
import TrafficHistory from '../components/TrafficHistory';
import OtherQuestions from '../components/OtherQuestions';
import MilitaryHistory from '../components/MilitaryHistory';
import PreviousEmployments from '../components/PreviousEmployments';
import { navigationTabs } from '../ViewApplication.data';
import { SNavigation, SStatusTabs } from '../../../ApplicantsAndReferrals.styles';

const ApplicantInfoTab = ({ applicant }) => {
  const contentRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('driver-info');
  const driverInfoRef = useRef(null);
  const positionRef = useRef(null);
  const applicantRef = useRef(null);
  const medicalRef = useRef(null);
  const previousEmploymentRef = useRef(null);
  const militaryRef = useRef(null);
  const educationRef = useRef(null);
  const trafficRef = useRef(null);
  const otherRef = useRef(null);

  const {
    driving_licence,
    medical_examiner_card,
    previous_employment,
    military,
    education,
    traffic_accident_history,
    other,
  } = applicant || {};

  const availableTabs = useMemo(() => {
    return navigationTabs.filter(
      (tab) =>
        ![6, 7, 8].includes(tab.id) ||
        (tab.id === 6 && !!military) ||
        (tab.id === 7 && !!education) ||
        (tab.id === 8 && !!traffic_accident_history)
    );
  }, [applicant]);

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 'driver-info':
        driverInfoRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'position':
        positionRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'applicant-type':
        applicantRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'medical':
        medicalRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'previous-employment':
        previousEmploymentRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'military':
        militaryRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'education':
        educationRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'trafficHistory':
        trafficRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case 'other':
        otherRef?.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      default:
        driverInfoRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
    removeAfterPrint: true,
    onAfterPrint: () => {},
  });

  return (
    <div>
      <SNavigation>
        <SStatusTabs value={selectedTab} onChange={handleTabChange}>
          {availableTabs.map((item) => (
            <Tab label={item.label} value={item.key} key={item.key} />
          ))}
        </SStatusTabs>
      </SNavigation>
      <Button className={classNames([classes.printToPrint, 'next-step'])} onClick={handlePrint}>
        Print
      </Button>
      <div ref={contentRef} className={classes.printWrapper}>
        <div ref={driverInfoRef} />
        {!!driving_licence && <DriverLicense data={driving_licence} />}
        <DriverInfo data={applicant} />
        <div ref={positionRef} />
        <Position data={applicant} />
        <div ref={applicantRef} />
        <div style={{ pageBreakBefore: 'always' }}>
          <ApplicantInfo data={applicant} />
          <div ref={medicalRef} />
          <MedicalCard data={medical_examiner_card} />
          <div ref={previousEmploymentRef} />
          <PreviousEmployments data={previous_employment} />
          <div ref={militaryRef} />
          {!!military && <MilitaryHistory data={military} />}
          <div ref={educationRef} />
          {!!education && <Education data={education} />}
          <div ref={trafficRef} />
          {!!traffic_accident_history && <TrafficHistory data={traffic_accident_history} />}
          <div ref={otherRef} />
          <OtherQuestions data={other} />
        </div>
      </div>
    </div>
  );
};

export default ApplicantInfoTab;
