import React, { useState, useContext, useEffect } from 'react';
import { OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { TableContext } from 'context/tableContext';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import CustomButton from '../CustomButton/CustomButton';
import DarkOverlay from '../DarkOverlay';
import DropDownPlaner from '../DropDownPlaner';
import PopoverSettings from '../PopoverSettings/PopoverSettingOwnerOperator';
import HeaderStar from '../HeaderStar';
import AddOwnerOperator from '../Drivers/AddOwnerOperator/AddOwnerOperator';
import styles from '../DriverHeader/DriverHeader.module.css';
import './OwnerOperatorHeader.css';

const OwnerHeader = ({ getOwnerOperator, search, setSearch, advancedOpen, setAdvancedOpen, onSearch }) => {
  const { use } = useTheme();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStep, setOpenStep] = useState(null);
  const [currentDriverId, setCurrentDriverId] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenStep(null);
    setCurrentDriverId(null);
  };

  const newDriverAdded = () => {
    setOpen(false);
    setOpenStep(null);
    setCurrentDriverId(null);
    setShow(true);
  };

  const {
    filter,
    updateFilter,
    dragItem,
    onChangeOrder,
    getSettings,
    onSearchMultiDropDown,
    switchRadioButtons,
    onsStTableColumn,
    onSetSwitchRadioButtons,
    tableColumn,
    multiSelectOptions,
  } = useContext(TableContext);

  const NO_BUTTON_TYPES = ['origin_location', 'destination_location', 'scheduled_origin', 'scheduled_destination'];

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettings
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={updateFilter}
          onChangeOrder={onChangeOrder}
          onsStTableColumn={onsStTableColumn}
          switchRadioButtons={switchRadioButtons}
          setShowPopover={(isOpen) => setShow(isOpen)}
          onSetSwitchRadioButtons={onSetSwitchRadioButtons}
          tableColumn={tableColumn}
        />
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (location?.state?.openCreate) {
      setOpen(true);
    }
    if (location.state?.openStep) {
      setOpenStep(location.state?.openStep);
    }
    if (location.state?.currentDriverId) {
      setCurrentDriverId(location.state?.currentDriverId);
    }
  }, [location?.state?.openCreate]);

  return (
    <div className={styles['DiverHeader_container driver-style-wrap']}>
      <div className={styles.driverHeader_wrapper}>
        <div className={styles.driver_title_wrap}>
          <HeaderStar title='Owner Operators' />
        </div>
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title='Add Owner Operator'
          leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
          styleButton={{ padding: '6px 12px' }}
          onClick={handleOpen}
        />
        <Modal backdrop='static' show={open} onHide={handleClose} centered id='add-driver-modal'>
          <AddOwnerOperator
            open={open}
            openStep={openStep}
            currentDriverId={currentDriverId}
            close={handleClose}
            newDriver={newDriverAdded}
            save={getOwnerOperator}
            module
          />
        </Modal>
      </div>
      <div className='top_popover_search-wrapper d-flex align-items-center'>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popOverContent}
          rootClose={show}
          onToggle={setShow}
          show={show}
          defaultShow={false}
          delay={0}
        >
          <div
            className='top_popover'
            style={{ backgroundColor: use(palette.white, palette.dark800), marginBottom: '1rem' }}
          >
            <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
          </div>
        </OverlayTrigger>
        <div className='filterPlaner driver-style-wrap'>
          <DropDownPlaner context='' data={multiSelectOptions} filterBy={filter.searchBy.title} />
          <input
            value={search}
            type='text'
            onChange={(e) => setSearch(e.target.value)}
            className='owner-filter-input-wrap'
            placeholder='Search'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearchMultiDropDown(search);
              }
            }}
            style={{
              backgroundColor: use(palette.white, palette.dark800),

              color: use(palette.gray400, palette.white),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          />
          {/* </div> */}
          {!NO_BUTTON_TYPES.includes(filter.searchBy.value) && (
            <CustomButton
              type='primary'
              title='Search'
              onClick={() => onSearch()}
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '5px 12px', margin: '0 0 0 8px' }}
            />
          )}
          <CustomButton
            type='primary'
            title=''
            leftIcon={
              advancedOpen ? (
                <MinusIcon fill='#fff' style={{ margin: 8 }} />
              ) : (
                <PlusIcon fill='#fff' style={{ margin: 8 }} />
              )
            }
            className='ms-3'
            styleButton={{ height: 32, margin: 0 }}
            onClick={() => setAdvancedOpen(!advancedOpen)}
          />
        </div>

        <DarkOverlay visible={show} styles={{ zIndex: 11 }} />
      </div>
    </div>
  );
};
export default OwnerHeader;
