import moment from 'moment/moment';

export const cardHolderTypes = [
  { name: 'Company', value: 'company' },
  { name: 'Individual', value: 'individual' },
];

export const shippingMethods = [
  { name: 'Standard', value: 'standard' },
  { name: 'Express', value: 'express' },
  { name: 'Priority', value: 'priority' },
];

export const initialValues = {
  card_holder_type: null,
  card_holder_name: '',
  card_holder: null,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  quantity: '',
  front: null,
  back: null,
  address: '',
  address2: '',
  country: null,
  state: null,
  city: null,
  zipcode: '',
  shipping_method: shippingMethods[0],
};

export const requestBodyConverter = (values, company) => {
  const formData = new FormData();

  formData.append('type', values.card_holder_type.value);
  formData.append('card_holder_name', values.card_holder_name);
  formData.append('address1', company.address1);
  formData.append('address2', company.address2 || '');
  if (company.country_id) {
    formData.append('country', company.country_id.sortname);
  }
  if (company.state_id) {
    formData.append('state', company.state_id.name);
  }
  if (company.city_id) {
    formData.append('city', company.city_id.name);
  }
  if (company.zip) {
    formData.append('zip', company.zip);
  }
  formData.append('email', values.email);
  formData.append('phone', values.phone_number);
  formData.append('first_name', values.first_name);
  formData.append('last_name', values.last_name);
  formData.append(
    'dob[year]',
    moment(values.dob || '1998-08-19')
      .year()
      .toString()
  );
  formData.append('dob[month]', (moment(values.dob || '1998-08-19').month() + 1).toString());
  formData.append(
    'dob[day]',
    moment(values.dob || '1998-08-19')
      .date()
      .toString()
  );

  switch (values.card_holder_type.value) {
    case 'company':
      formData.append('user_type', 'company');
      formData.append('user_id', company.id);
      break;
    case 'individual':
      formData.append('user_type', values.card_holder.user_type);
      formData.append('user_id', values.card_holder.id);
      if (values.front) {
        formData.append('front', values.front);
      }
      if (values.back) {
        formData.append('back', values.back);
      }
      break;
    default:
  }

  return formData;
};
