import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SSplitShipment = styled.div`
  display: flex;

  .add-stops-section {
    width: calc(68% + 22px);
  }

  .split-section {
    flex-grow: 1;
    padding-left: 10px;
    border-left: 1px solid #e9edf5;
  }

  .droppable-container {
    padding: 8px 0;
    min-height: 270px;
  }

  .optimize-route-container {
    width: 100%;
    height: 100%;
  }

  .split-line-wrapper {
    width: 100%;
    height: 4px;
    position: relative;
    transform: translateY(-12px);
  }

  .minus-icon {
    display: flex;
    align-items: center;
    height: 12px;
    cursor: pointer;
    position: absolute;
    left: -28px;
    top: -5px;
  }

  .add-split-section-icon {
    cursor: pointer;
    left: calc(50% - 12px);
    top: 50%;
    width: 24px;
    height: 24px;
    padding: 4px;
    display: flex;
    border-radius: 4px;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: ${palette.white};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
  }

  .cut-icon {
    left: -24px;
    top: 50%;
    width: 24px;
    height: 24px;
    padding: 4px;
    display: flex;
    border-radius: 4px;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: ${palette.white};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
    animation: heartbeat 1.5s;
    animation-iteration-count: infinite;
    cursor: pointer;
  }

  .add-stops-form {
    background: ${palette.gray0};
  }

  .small-time-input {
    .react-time-input-picker-wrapper {
      height: 26px;
      margin: 0;
      border-bottom: none;
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(0.9);
    }
    20% {
      transform: scale(1);
    }
    40% {
      transform: scale(0.9);
    }
    60% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(0.9);
    }
  }
`;
