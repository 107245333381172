import styled from 'styled-components';

export const SAddons = styled.div`
  .hardware-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
    min-width: 110px;
  }
`;
