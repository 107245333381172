export const convertBalanceDetails = (balance) => {
  const settlements = balance?.deductions?.[0]?.settlement || [];
  let remainingBalance = balance.original_balance;

  const convertedSettlements = settlements.map((settlement) => {
    remainingBalance -= Number(settlement.amount);

    return {
      ...settlement,
      remainingBalance,
    };
  });

  return {
    ...balance,
    deductions: [{ settlement: convertedSettlements }],
  };
};
