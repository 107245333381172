import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import { Typography } from 'components/Typography';
import StatBox from 'pages/Accounting/Payables/StatsSection/components/StatBox';
import { palette } from 'utils/constants';
import { getPayableStats } from 'Api/Payables';
import { statsDataConverter } from './converters';
import { initialStats, initialYearFilters, statTypeYearMapper } from './AccountsPayable.data';
import { SSectionHeader } from '../../Dashboard.styles';

const AccountsPayable = () => {
  const [stats, setStats] = useState(initialStats);
  const [loading, setLoading] = useState(false);
  const [yearFilters, setYearFilters] = useState(initialYearFilters);
  const didMountRef = useRef(false);

  const getStats = async () => {
    try {
      const params = {
        variable_cost_year: moment(yearFilters.variable_cost_year).format('YYYY-MM-DD'),
        fixed_cost_year: moment(yearFilters.fixed_cost_year).format('YYYY-MM-DD'),
        admin_cost_year: moment(yearFilters.admin_cost_year).format('YYYY-MM-DD'),
        maintenance_cost_year: moment(yearFilters.maintenance_cost_year).format('YYYY-MM-DD'),
      };
      const { data } = await getPayableStats(params);
      const convertedData = statsDataConverter(data);
      setStats(convertedData);
    } finally {
      setLoading(false);
    }
  };

  const onYearChange = (key, date) => {
    setYearFilters((prevState) => ({ ...prevState, [key]: date }));
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
      didMountRef.current = true;
    }
    getStats();
  }, [yearFilters]);

  return (
    <div className='d-flex flex-column gap-2'>
      <SSectionHeader>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Accounts Payable
        </Typography>
      </SSectionHeader>
      <div className='scrollable-container'>
        <div className='stat-boxes-wrapper'>
          {Object.keys(stats || {})?.map((key) => (
            <StatBox
              key={key}
              title={key}
              data={stats[key]}
              loading={loading}
              year={yearFilters[statTypeYearMapper[key]]}
              onYearChange={(date) => onYearChange(statTypeYearMapper[key], date)}
              onTitleClick={() => null}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountsPayable;
