import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SListWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  overflow: auto;
  height: calc(100% - 60px);

  .list-item {
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;

    :hover {
      background-color: ${palette.indigo0};
      & > div > span,
      & > span {
        color: ${palette.indigo500} !important;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SAddAccount = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;
