import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import AccountCard from 'pages/Accounting/Accounts/components/AccountCard';
import { SAccordion, STableWrapper, SWrapper } from './AccountsAccordion.styles';

const AccountsAccordion = ({ title, accounts, activeAccount, hideBalances }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const onAccountClick = (account) => {
    navigate(`/accounting/accounts?id=${account.id}`);
  };

  return (
    <SWrapper>
      <SAccordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ArrowUp className='accordion-arrow' />} id='panel1a-header'>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <STableWrapper>
            {accounts.map((item) => (
              <AccountCard
                key={item.id}
                data={item}
                onClick={() => onAccountClick(item)}
                hideBalances={hideBalances}
                className={activeAccount?.id === item.id ? 'selected' : ''}
              />
            ))}
          </STableWrapper>
        </AccordionDetails>
      </SAccordion>
    </SWrapper>
  );
};

export default AccountsAccordion;
