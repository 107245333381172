import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { extendExpiration } from 'Api/OfferedShipments';
import { timeOptions } from './ExtendExpiration.data';
import { validationSchema } from './validationSchema';
import { SMergedInputs } from './ExtendExpiration.styles';

const ExtendExpiration = ({ open, onClose, shipmentId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = { minutes: Number(values.extend_amount) * values.extend_unit.multiplier };
      await extendExpiration(shipmentId, body);
      showToaster({ type: 'success', message: 'Expiration has been successfully extended!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      extend_amount: '',
      extend_unit: timeOptions[0],
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Extend Expiration'
      $maxWidth='400px'
      $minWidth='400px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Submit',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mt-2 mb-3'>
        <div>
          <InputLabel required>Extend By</InputLabel>
          <SMergedInputs>
            <Input
              width='120px'
              type='number'
              name='extend_amount'
              value={values.extend_amount}
              onChange={handleChange}
              error={touchedErrors.extend_amount}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
            />
            <Autocomplete
              width='100px'
              name='extend_unit'
              options={timeOptions}
              value={values.extend_unit}
              onChange={(e, val) => handleChange('extend_unit', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.extend_unit}
            />
          </SMergedInputs>
        </div>
      </div>
    </Modal>
  );
};

export default ExtendExpiration;
