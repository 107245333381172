import React from 'react';
import Box from '@mui/material/Box';
import styles from './TermsAndCondition.module.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 741,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 2,
};

const TermsAndCondition = ({ handleTermsAndConditionClose }) => {
  return (
    <div>
      <Box sx={style}>
        <div>
          <div>
            <span className={style.TermsAndConditionHeader}>Terms & Conditions</span>
          </div>
          <p className={styles.TermsAndConditionHeaderLine} />
          <div className={styles.TermsAndConditionMainBody}>
            <div className={styles.TermsAndConditionSubHeader}>
              <span>Shipping, Hardware Warranty and Returns</span>
            </div>
            <p className={styles.TermsAndConditionHeaderLine} />
            <div className={styles.TermsAndConditionSubp}>
              <p>
                Upon an paid or finance approved signed order form and agreement. It may take 7 to 14 business days to
                receive your hardware. There are no refunds for licenses or custom work orders purchased. All hardware
                products come with a warranty, based upon license term unless otherwise specified on this order form.
                All hardware with internal defects can be exchanged free of charge. Upon receiving hardware Customer or
                Customer’s agent has inspected the Equipment and acknowledges that the Equipment is in good and
                acceptable condition, if hardware is not in acceptable condition, customer has 14 days for a refund or
                exchange.
              </p>
            </div>
            <div className={styles.TermsAndConditionSubp}>
              <p>
                All hardware can be returned within 14 days from purchase date for a refund or exchange. If for any
                reason you are not satisfied with your item after the 14-day return period, there are no refunds for
                purchased hardware, but it is allowed for a manufacturer replacement/repair while under warranty.
                Customer must pay all shipping costs. It is of the Customer or Customer’s agent to inspect the Equipment
                and acknowledge that the Equipment is in good and acceptable condition.
              </p>
            </div>
            <div className={styles.TermsAndConditionSubp}>
              <p>
                We do not allow returns for water damage, we do not provide replacements for lost or stolen devices. The
                following conditions must be met to allow a 14-day money back guarantee:
              </p>
            </div>
            <div className={styles.TermsAndConditionSubp}>
              <ul>
                <li>Items do not have water damage and must not be tampered with any internal components.</li>
                <li>Items must have all associated box and supplies attached to it, as when first received.</li>
              </ul>
            </div>
            <p className={styles.TermsAndConditionHeaderLine} />
            <div className={styles.TermsAndConditionSubHeader}>
              <span>Use of Hardware</span>
            </div>
            <p className={styles.TermsAndConditionHeaderLine} />
            <div className={styles.TermsAndConditionSubp}>
              <p>
                Customer shall only use the Equipment while under License with Truckin Digital along in a careful and
                proper manner. Customer must comply with all laws, rules, ordinances, statutes, maintenance and storage
                of the sold hardware while under an active Truckin Digital License.
              </p>
            </div>
            <div className={styles.TermsAndConditionSubp}>
              <p>
                Customer shall train employee personnel on how to login (if applicable) and use hardware. Customer and
                Customer’s personnel can use hardware while under Truckin Digital license term only in the United
                States, Canada and Mexico. Customer may not use the hardware while under Truckin Digital License for
                non-commercial or personal use. Drivers are not allowed to tamper with the tablet hardware while under
                an active Truckin Digital License term.
              </p>
            </div>
            <p className={styles.TermsAndConditionHeaderLine} />
            <div className={styles.TermsAndConditionSubHeader}>
              <span>Financing</span>
            </div>
            <p className={styles.TermsAndConditionHeaderLine} />
            <div className={styles.TermsAndConditionSubp}>
              <p>
                We are pleased to present a financing option for your Truckin Digital license software and hardware
                purchases. If you choose to finance your purchase, you will make payments to the lender and per the
                terms of your financing agreement. However, all product support, including technical support, warranty
                coverage, and sales support will be provided directly by Truckin Digital. To finance you are required to
                fill out an application which will be provided by your Truckin Digital Sales Agent. You may be contacted
                by the financial institution agent if further information is required. Financing is based upon approval.
              </p>
            </div>
          </div>
          <p className={styles.TermsAndConditionFooterLine} />
          <div className={styles.TermsAndConditionFooter}>
            <button className={styles.TermsAndConditionCancelButton} onClick={handleTermsAndConditionClose}>
              Done
            </button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default TermsAndCondition;
