import React from 'react';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import classes from 'components/TableRowEditModalTypes/NotesEditModal/notesEditModal.module.css';
import useDateFormat from 'hooks/useDateFormat';

export const useColumns = ({ sortingQuery, onDeleteRowItem, onOpenNoteDocument }) => {
  const { use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

  return [
    {
      field: '#',
      title: <ColumnHeader title='#' field='#' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>{Number(rowData?.tableData?.id) + 1}</span>
        );
      },
    },
    {
      field: 'title',
      title: <ColumnHeader title='TITLE' field='title' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.title}</span>;
      },
    },
    {
      field: 'note',
      title: <ColumnHeader title='NOTE' field='note' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <div className={classes.columns_notes_wrapper}>
            <div className={classes.columns_notes}>
              <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.notes}</span>
            </div>
            <div className={classes.notes_bottom_wrapper}>
              {!!rowData?.department?.department_email && (
                <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                  <Typography variant='c1'>{rowData?.department?.department_email}</Typography>
                </div>
              )}
              {!!rowData?.department?.department_name && (
                <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                  <Typography variant='c1'>{rowData?.department?.department_name}</Typography>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'added_by',
      title: <ColumnHeader title='ADDED BY' field='added_by' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const { first_name, last_name } = rowData.added_by || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData.added_by === null ? 'System' : `${first_name && first_name} ${last_name && last_name}`}
          </span>
        );
      },
    },
    {
      field: 'date',
      title: <ColumnHeader title='DATE' field='date' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData?.created_at && convertToCustomerTime(rowData?.created_at)}
          </span>
        );
      },
    },
    {
      field: 'attachment',
      title: <ColumnHeader title='ATTACHMENT' field='attachment' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return rowData.attachment ? (
          <div onClick={() => onOpenNoteDocument(rowData.attachment?.document)}>
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              View
            </Typography>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      field: '',
      title: '',
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData.show_on_invoice === 1 ? 'INVOICE' : ''}
          </span>
        );
      },
    },
    {
      field: '',
      title: '',
      render: (rowData) => {
        const userId = user?.id ? user?.id : null;
        return (
          <div className={classes.deleteRowWrapper}>
            {userId !== null && userId === rowData?.added_by?.id && (
              <div onClick={(e) => onDeleteRowItem(e, rowData)} className={classes.deleteRow}>
                <DeleteIcon fill={palette.indigo500} />
              </div>
            )}
          </div>
        );
      },
    },
  ];
};
