import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #ffffff02;
  backdrop-filter: blur(4px);
  min-height: 100vh;
`;

export const SHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid ${palette.gray50};
  min-height: 72px;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffdd;
  backdrop-filter: blur(4px);
`;

export const SFooter = styled.div`
  border-top: 1px solid ${palette.gray50};
  min-height: 50px;
  padding: 10px 124px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffffdd;
  backdrop-filter: blur(4px);

  .gray-text {
    color: ${palette.gray700};
  }

  .blue-link {
    color: ${palette.indigo500};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    padding: 10px 24px;
  }
`;

export const SGradientBox = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 500px;
  background: linear-gradient(
    122deg,
    #ffffff 16%,
    #f9f7ff,
    #ebe6ff,
    #f9f7ff 42%,
    #ffffff 47%,
    #fff3f6,
    #ffdde2,
    #fff3f6 68%,
    #ffffff
  );
  background-blend-mode: screen;

  :after {
    content: '';
    height: 100px;
    width: 100%;
    display: inline-block;
    background: linear-gradient(to bottom, transparent, white);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  :before {
    content: '';
    height: 100px;
    width: 100%;
    display: inline-block;
    background: linear-gradient(to top, transparent, white);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const SPositionsListWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 88px;
`;

export const SPositionsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const SContent = styled.div`
  width: 60%;
  min-height: calc(100vh - 206px);
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 24px;
  }
`;

export const SFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 8px;
`;
