import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as BellIcon } from 'assets/icons/header/notification-20px.svg';
import { PusherJs } from 'common/pusher';
import { Typography } from 'components/Typography';
import TakeActions from 'componentsV2/AlertCenter/TakeActions';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getAlertDetails } from 'Api/AlertCenter';
import { getAlertCenter } from 'Api/Equipment';
import styles from './Bell.module.css';

const Bell = ({ alertData, getAlertData }) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const [toggleNotif, setToggleNotif] = useState(false);
  const [alertNewData, setAlertNewData] = useState({});
  const [alertToUpdate, setAlertToUpdate] = useState(null);
  const alertDropdownRef = useRef(null);
  const navigate = useNavigate();
  const controller = useRef(null);

  useEffect(() => {
    const dataRaw = localStorage.getItem('user');
    const data = JSON.parse(dataRaw);
    const channel = PusherJs.subscribe(`private-get-recent-alert.${data?.customer?.dot}`);

    channel.bind('get-recent-alert', () => {
      if (controller?.current) {
        controller.current.abort();
      }

      controller.current = new AbortController();

      getAlertCenter({
        page: 1,
        itemsPerPage: 300,
        alert_status: ['N'],
        sortField: 'sort[][created_at]',
        sortBy: 'desc',
        signal: controller.current.signal,
      })
        .then((res) => {
          if (res) {
            setAlertNewData(res);
          }
        })
        .catch(() => {
          // Do nothing
        });
    });

    return () => {
      channel.unbind('get-recent-alert');
      channel.unsubscribe();
      if (controller?.current) {
        controller.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    setAlertNewData({ ...alertData });
  }, [alertData]);

  useEffect(() => {
    window.addEventListener('click', onClickOutDocumentDropdown);
    return () => {
      window.removeEventListener('click', onClickOutDocumentDropdown);
    };
  }, [alertDropdownRef]);

  const onClickOutDocumentDropdown = (e) => {
    if (alertDropdownRef && !alertDropdownRef?.current?.contains(e.target)) {
      setToggleNotif(false);
    }
  };

  const onAlertClick = async (alert) => {
    const { status } = alert || {};

    if (status === 'Resolved') {
      return;
    }

    try {
      const { data } = await getAlertDetails(alert?.id);
      setAlertToUpdate({ ...alert, ...data });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  return (
    <div ref={alertDropdownRef} style={{ position: 'relative' }} onClick={() => setToggleNotif(!toggleNotif)}>
      <div
        className='badge'
        style={{
          display: alertNewData.total !== 0 ? 'flex' : 'none',
          width: 'max-content',
        }}
      >
        {alertNewData.total <= 99 ? alertNewData.total : '99+'}
      </div>
      <BellIcon />
      <div className={styles.BellMainContentDiv} style={{ display: toggleNotif ? 'flex' : 'none' }}>
        <div className={styles.NotificationMainHeadingDiv}>
          <div>
            <span className={styles.NotificationMainHeading}>Alerts</span>
          </div>
        </div>
        <div className={styles.NotificationMainContaint}>
          {alertNewData &&
            alertNewData?.data?.map((v) => (
              <div key={v?.id} className={styles.NotificationSubContent} onClick={() => onAlertClick(v)}>
                <div className={styles.source_wrapper}>
                  {v?.driver_details ? (
                    <span>
                      <img className={styles.image} src={v?.driver_details?.image || userDefault} alt='img' />
                    </span>
                  ) : (
                    <div className={styles.image} />
                  )}
                </div>
                <div style={{ width: '-webkit-fill-available' }}>
                  <div className='d-flex justify-content-between'>
                    <div className='ms-1'>
                      <span className={styles.NotificationDriverName}>
                        {v?.alert_type} {v?.alert_type === 'Shipment' ? v?.shipment_id : ''}
                      </span>
                    </div>
                    <div>
                      <span className={styles.NotificationDriverTime}>{convertToCustomerTime(v.created_at)}</span>
                    </div>
                  </div>
                  <p className={styles.NotificationDriverContaint}>{v?.alert_message}</p>
                </div>
                <div />
              </div>
            ))}
          {!!alertNewData && !alertNewData.data?.length && (
            <div className='d-flex justify-content-center align-items-center h-100'>
              <Typography> You have no new alerts!</Typography>
            </div>
          )}
        </div>
        <div className={styles.NotificationBottomWrapper}>
          <div />
          <div className={styles.NotificationBottom}>
            <span className={styles.NotificationBottomButton} onClick={() => navigate('/alert-center')}>
              View All
            </span>
          </div>
          <div className={styles.NotificationBottom}>
            <span className={styles.NotificationBottomButton} onClick={() => setToggleNotif(!toggleNotif)}>
              Close
            </span>
          </div>
        </div>
      </div>
      {!!alertToUpdate && (
        <TakeActions
          open={!!alertToUpdate}
          onClose={() => setAlertToUpdate(null)}
          alert={alertToUpdate}
          onSuccess={getAlertData}
        />
      )}
    </div>
  );
};
export default Bell;
