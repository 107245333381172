import uuid from 'react-uuid';

export const COMPENSATION_TYPES = [
  {
    title: 'Per Mile',
    id: 1,
    value: 'mile',
    key: uuid(),
  },
  {
    title: 'By Percentage',
    id: 2,
    value: 'percentage',
    key: uuid(),
  },
  {
    title: 'Per Hour',
    id: 3,
    value: 'per_hour',
    key: uuid(),
  },
  {
    title: 'Flat Rate',
    id: 4,
    value: 'flat_rate',
    key: uuid(),
  },
];
