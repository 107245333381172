import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { ReactComponent as ClockIcon } from 'assets/icons/icons-clock.svg';
import { palette } from 'utils/constants';
import AddressFields from 'common/AddressFields';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { getPaymentTerms } from 'Api/Carriers';
import { useParams } from 'react-router-dom';
import classes from 'components/Carriers/AddCarrier/PaymentTerms.module.scss';
import Autocomplete from 'common/Autocomplete';
import PaymentMethod from 'components/Carriers/AddCarrier/PaymentMethod';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';

const PayTerms = ({ form, company, carrierId, carrier }) => {
  const { values, handleChange, touchedErrors, setValues } = form;

  const durationConvertor = {
    days: 'business days',
    hours: 'hours',
    weeks: 'weeks',
  };

  const { offer_quick_pay, quick_pay_count, quick_pay_type, quick_pay_percent, phone_number } = company || {};
  const { dot, token } = useParams();
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState();

  useEffect(() => {
    getPaymentTerms(dot, token).then((res) => {
      if (res && res?.data) {
        setInvoicePayTermsOptions(res.data);
      }
    });
  }, []);

  return (
    <div>
      <div className='d-flex gap-5'>
        <div className='mt-2'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Invoice Pay Terms <span className='required'>*</span>
          </Typography>
          <Autocomplete
            getOptionLabel={(option) => `${getPaymentTermTitle(option)}`}
            placeholder='Select ...'
            options={invoicePayTermsOptions || []}
            value={values.invoicePayTerm}
            onChange={(e, val) => handleChange('invoicePayTerm', val)}
            isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
            style={{ width: '340px', marginTop: 5 }}
            error={touchedErrors?.invoicePayTerm}
            disabled
          />
        </div>
        <div>
          {!!offer_quick_pay && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <ClockIcon width={30} />
                <Typography variant='h5' style={{ color: palette.gray700 }}>
                  Get paid in just {quick_pay_count} {durationConvertor[quick_pay_type]} with Quick Pay!
                </Typography>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='s2' style={{ color: palette.gray700, marginLeft: '8px' }}>
                  Terms: Get Paid in {quick_pay_count} {durationConvertor[quick_pay_type]} — instead of the standard{' '}
                  {values?.invoicePayTerm?.net_day} business days. {quick_pay_percent}% Quick Pay Fee Applies Per
                  Invoice. To disable Quick Pay you must give us a call at {formatPhoneNumberIntl(phone_number)}
                </Typography>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <CustomCheckbox
                  type='switch'
                  checked={values.quick_pay}
                  onChange={(e) => handleChange('quick_pay', e)}
                  disabled={values.payFactoring}
                />

                <Typography variant='button1' style={{ color: palette.gray700, marginLeft: '8px' }}>
                  Enable Quick Pay
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={classes.payFactoringWrapper}>
        <CustomCheckbox
          name='pay_driver1'
          type='switch'
          smail={false}
          checked={values.payFactoring}
          onChange={(checked) => {
            if (checked) {
              setValues((prevState) => ({ ...prevState, payFactoring: checked, quick_pay: false }));
            } else {
              handleChange(`payFactoring`, checked);
            }
          }}
        />
        <Typography variant='s2'>Pay Factoring</Typography>
      </div>

      {values.payFactoring && (
        <div className={classes.payFactoringFieldsWrapper}>
          <div className={classes.contactWrapper}>
            <div>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Factoring Company <span className='required'>*</span>
              </Typography>
              <Input
                name='origin_city'
                style={{ width: '340px', marginTop: 5 }}
                placeholder='Factoring Company'
                onChange={(e) => handleChange(`factoringCompany`, e.target.value)}
                value={values.factoringCompany}
                error={touchedErrors.factoringCompany}
              />
            </div>
            <div>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Factor Email <span className='required'>*</span>
              </Typography>
              <Input
                name='email'
                style={{ width: '340px', marginTop: 5 }}
                placeholder='example@email.com'
                onChange={(e) => handleChange(`email`, e.target.value)}
                value={values.email}
                error={touchedErrors.email}
              />
            </div>
          </div>
          <div className='w-75 mt-4'>
            <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
          </div>
        </div>
      )}

      <div className='mt-5'>
        <Typography variant='s1' style={{ color: palette.gray900 }}>
          Payout Method
        </Typography>
        <PaymentMethod
          form={form}
          isOnboarding
          carrierId={carrierId}
          onboardingData={{ dot, token }}
          carrier={carrier}
        />
      </div>
    </div>
  );
};

export default PayTerms;
