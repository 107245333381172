import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import ViewPdf from 'components/ViewPdf';
import PhoneInput from 'common/PhoneInput';
import Input, { Textarea } from 'common/Input';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { CreateAccountingSettings, GetSettingsAccounting } from 'Api/CompanySettings';
import AddressFields from './AddressFields';
import { getInitialValues } from './Factoring.data';
import { validationSchema } from './validationSchema';
import { SWrapper } from './Factoring.styles';

const Factoring = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [accountData, setAccountData] = useState(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [pdfLink, setPdfLink] = useState('');

  const onSubmit = async (values) => {
    setLoadingUpdate(false);
    try {
      const formData = new FormData();
      formData.append('is_factoring', values?.is_factoring ? 1 : 0);
      if (values?.is_factoring) {
        formData.append('factoring_company', values?.factoring_company);
        formData.append('factor_email', values?.factor_email);
        formData.append('factor_fax', values?.factor_fax);
        formData.append('address_line1', values?.address_line1);
        formData.append('notice', values?.notice);
        formData.append('country_id', values?.country.id);
        formData.append('state_id', values?.state.id);
        formData.append('city_id', values?.city.id);
        formData.append('zip', values?.zip);
      }

      if (values?.address_line2) {
        formData.append('address_line2', values?.address_line2);
      }

      if (values?.notice_letter) {
        formData.append('notice_letter', values?.notice_letter);
      }

      await CreateAccountingSettings(formData);
      showToaster({ type: 'success', message: 'Factoring settings have been successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(accountData, isConfiguringStep),
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    handleChange(`notice_letter`, file);
  };

  const getAccountData = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountData(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAttachmentClick = (file) => {
    if (typeof file === 'string' && !file?.type) {
      setPdfLink(file);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPdfLink(reader.result);
    };
  };

  useEffect(() => {
    getAccountData();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title={
          <div className='d-flex align-items-center gap-4'>
            Factoring
            <div style={{ marginTop: 10 }}>
              <CustomCheckbox
                type='switch'
                name='is_factoring'
                smail={false}
                checked={!!values.is_factoring}
                onChange={(checked) => handleChange('is_factoring', checked)}
                labelStyle={{ marginTop: '2px' }}
              />
            </div>
          </div>
        }
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      {values?.is_factoring && (
        <div className='mt-4'>
          <div className='flex-row top-border'>
            <div className='left'>
              <Typography variant='s2'>Factoring Company</Typography>
            </div>
            <div className='right'>
              <Input
                name='factoring_company'
                value={values.factoring_company}
                onChange={handleChange}
                error={touchedErrors.factoring_company}
              />
            </div>
          </div>
          <div className='flex-row'>
            <div className='left'>
              <Typography variant='s2'>Address</Typography>
            </div>
            <div className='right'>
              <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
            </div>
          </div>
          <div className='flex-row'>
            <div className='left'>
              <Typography variant='s2'>Factor Email</Typography>
            </div>
            <div className='right'>
              <Input
                type='text'
                name='factor_email'
                value={values.factor_email}
                onChange={handleChange}
                error={touchedErrors.factor_email}
              />
            </div>
          </div>
          <div className='flex-row'>
            <div className='left'>
              <Typography variant='s2'>Send Invoices to Fax</Typography>
            </div>
            <div className='right'>
              <PhoneInput
                name='factor_fax'
                placeholder='+1 (555) 555-1234'
                onChange={(val) => handleChange('factor_fax', val)}
                value={values.factor_fax}
                error={touchedErrors.factor_fax}
              />
            </div>
          </div>
          <div className='flex-row'>
            <div className='left'>
              <Typography variant='s2'>Notice of Assignment</Typography>
            </div>
            <div className='noa-wrapper'>
              <Textarea
                rows={8}
                name='notice'
                value={values.notice}
                onChange={handleChange}
                error={touchedErrors.notice}
              />
            </div>
          </div>
          <div className='flex-row'>
            <div className='left'>
              <Typography variant='s2'>Notice of Assignment Letter</Typography>
            </div>
            <div className='right'>
              <div className='file-upload-wrapper'>
                {values?.notice_letter && (
                  <div className='choose-file-wrapper' onClick={() => onAttachmentClick(values?.notice_letter)}>
                    <AttachmentIcon fill={palette.indigo500} />
                    <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8, color: palette.indigo500 }}>
                      {values?.notice_letter?.name ? values?.notice_letter?.name : 'NOA Letter'}
                    </Typography>
                  </div>
                )}
                <ImageDropZone
                  accept={['application/pdf']}
                  onDrop={(acceptedFiles) => onDrop(acceptedFiles, 1)}
                  height={32}
                  name='notice_letter'
                  deletable
                  maxSize={1000000}
                  deletedComponent={<div className='upload-delete-wrapper' />}
                >
                  {values?.notice_letter ? (
                    <div className='underline-text'>Replace Document</div>
                  ) : (
                    <div className='choose-file-wrapper'>
                      <PlusIcon />
                      <Typography
                        variant='s2'
                        style={{ whiteSpace: 'nowrap', marginLeft: 8, color: palette.indigo500 }}
                      >
                        Attach
                      </Typography>
                    </div>
                  )}
                </ImageDropZone>
              </div>
            </div>
          </div>
        </div>
      )}
      <ViewPdf open={!!pdfLink} onClose={() => setPdfLink(null)} pdfUrl={pdfLink} title='Notice of Assignment Letter' />
    </SWrapper>
  );
};

export default Factoring;
