import React from 'react';
import HeaderStar from '../HeaderStar';
import classes from './completedShipments.module.css';

const HeaderCompletedShipments = () => {
  return (
    <div className={classes.headerWrapper}>
      <HeaderStar title='Shipment Audit' />
    </div>
  );
};

export default HeaderCompletedShipments;
