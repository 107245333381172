import React from 'react';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';

export const useColumns = ({ sort, sortingQuery }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'company_name',
      title: <ColumnHeader title='CUSTOMER' field='company_name' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.company_name ? row.company_name : '-'}
        </Typography>
      ),
    },
    {
      field: 'reference_id',
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.reference_id || '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'customer_billed_date',
      title: <ColumnHeader title='CREATE DATE' field='customer_billed_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.customer_billed_date ? formatDate(row.customer_billed_date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'occurrence',
      title: <ColumnHeader title='RECURRENCE' field='occurrence' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.occurrence || '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'total_amount',
      title: <ColumnHeader title='AMOUNT' field='total_amount' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.total_amount ? `${currency} ${formatNumber(row.total_amount)}` : '-'}
        </Typography>
      ),
    },
  ];
};
