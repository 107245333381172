import React from 'react';
import uuid from 'react-uuid';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import Divider from '@mui/material/Divider';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';

import Deadhead from './Deadhead';
import ShipmentStop from './ShipmentStop';
import AddShipmentStop from './AddShipmentStop';
import { initialDelivery, initialPickup, initialWaypoint } from '../../CreateQuote.data';

const ShipmentLeg = ({
  form,
  leg,
  legIndex,
  quote,
  shouldSave,
  stopPoints,
  getStopPoints,
  commodity,
  equipmentTypes,
}) => {
  const { values, handleChange, touchedErrors } = form;
  const totalLegDistance =
    Number(leg.distance_to_next_stop) +
    Number(
      values.legs[legIndex]?.stops?.reduce((acc, cur) => acc + Math.round(Number(cur.distance_to_next_stop || 0)), 0)
    );

  const onAddStop = (type, legIndex, stopIndex) => {
    const newStops = cloneDeep(values.legs[legIndex].stops);

    newStops.splice(stopIndex + 1, 0, {
      ...(type.id === 1 ? initialPickup : type.id === 2 ? initialDelivery : initialWaypoint),
      id: uuid(),
    });

    handleChange(`legs[${legIndex}].stops`, newStops);
  };

  return (
    <div className='shipment-leg'>
      <div className='leg-bar'>
        <Typography variant='s2' style={{ color: palette.indigo500 }}>
          {legIndex + 1}
        </Typography>
      </div>
      <div className='create-quote-section mb-4'>
        <Deadhead
          form={form}
          leg={leg}
          legIndex={legIndex}
          quote={quote}
          stopPoints={stopPoints}
          getStopPoints={getStopPoints}
        />
      </div>

      <div className='stops-wrapper create-quote-section mb-3'>
        {leg.stops.map((stop, stopIndex) => (
          <div
            className={classNames({
              'unsaved-animation': !!quote && !!shouldSave && (!!stop.isEdit || !!stop.isNew),
            })}
            key={stop.id}
          >
            <ShipmentStop
              form={form}
              stop={stop}
              quote={quote}
              legIndex={legIndex}
              stopIndex={stopIndex}
              stopPoints={stopPoints}
              commodity={commodity}
              equipmentTypes={equipmentTypes}
              getStopPoints={getStopPoints}
            />
            <Divider className='mt-2 mb-2'>
              <AddShipmentStop onTypeSelect={(type) => onAddStop(type, legIndex, stopIndex)} />
            </Divider>
          </div>
        ))}
      </div>

      <div className='d-flex justify-content-end align-items-center gap-5'>
        <div className='d-flex align-items-center gap-2'>
          <InputLabel className='mb-0'>Qty</InputLabel>
          <Input
            type='number'
            width='80px'
            placeholder='1'
            name={`legs[${legIndex}].quantity]`}
            value={values.legs[legIndex].quantity}
            onChange={handleChange}
            onKeyDown={(e) => {
              blockNonPositiveNumbers(e);
              blockNotNumberChars(e);
            }}
            error={touchedErrors.legs?.[legIndex]?.quantity}
          />
        </div>

        <div className='d-flex align-items-center gap-4'>
          <InputLabel className='mb-0' variant='c2'>
            TOTAL LANE {legIndex + 1} MOVE DISTANCE
          </InputLabel>
          <Typography variant='c2'>{Math.round(totalLegDistance)} MILES</Typography>
        </div>
      </div>
    </div>
  );
};

export default ShipmentLeg;
