import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { driverTimeTrackingData } from 'Api/Staff';
import { palette, TIME_APP_VIDEO_LINK } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import OpenArrow from 'assets/icons/arrowDown.svg';
import { getErrorMessage } from 'utils/error';
import calendar from 'assets/icons/drivers/calendar.svg';
import playVideoIcon from 'assets/images/icons-video.png';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Typography } from 'components/Typography';
import TrackingDetailsModal from 'components/TrackinDetailModal/TrackingDetailModal';
import { customerTimeToUtc } from 'utils/helpers';
import { createTimeTracking, deleteTimeTrackingRecord, updateTimeTracking } from 'Api/TimeTracking';
import useShowToaster from 'hooks/useShowToaster';
import ViewVideo from 'components/ViewVideo';
import useDateFormat from 'hooks/useDateFormat';
import { getPayPeriod } from 'Api/PayrollSettings';
import { getCurrentPayPeriod } from 'pages/Payroll/PayrollAndSettlements/helpers';
import ConfirmationModal from 'common/ConfirmationModal';
import RowActions from 'pages/Payroll/TimeTracking/components/TableAccordion/RowActions';
import CustomButton from 'components/CustomButton/CustomButton';
import DateRange from '../DateRangePicker/DateRangePicker';
import MaterialTableWrapper from '../MaterialTableWrapper';
import TableFooter from '../TableFooter/TableFooter';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import { SHeaderWrapper } from './StaffTimeTracking.styles';

const StaffTimeTracking = () => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime, dateFormat } = useDateFormat();
  const [rangeName, setRangeName] = useState('Custom Range');
  const { id } = useParams();
  const { use } = useTheme();
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTrucking, setSelectedTrucking] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openAddUpdateRecord, setOpenAddUpdateRecord] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const myTableRef = useRef(null).current;
  const didMountRef = useRef(false);

  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -30)),
    end: endOfDay(new Date()),
  });
  const [driverTimeTracking, setDriverTimeTracking] = useState({ data: [] });

  useEffect(async () => {
    initialCall();
  }, [id, dateRange]);

  const initialCall = async () => {
    setLoading(true);

    if (!didMountRef?.current) {
      const { data } = await getPayPeriod();
      const range = getCurrentPayPeriod(data);
      didMountRef.current = true;
      setDateRange({ start: moment(range.start).toDate(), end: moment(range.end).toDate() });
      return;
    }

    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');

    driverTimeTrackingData({
      staff_id: id,
      start_time: startFormat,
      end_time: endFormat,
      page: 1,
      itemsPerPage: rowPerPage,
    })
      .then((res) => {
        makeTrackingDate(res, dateRange.start, dateRange.end, 6);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const makeTrackingDate = (trackingData, startDateRange, endDateRange, period) => {
    const startDate = customerTime(startDateRange);
    const endDate = customerTime(endDateRange);

    const finalDateDay = endDate.diff(startDate, 'days');

    const slots = Math.ceil(finalDateDay / period);
    let timeTracking = [];
    for (let i = 0; i < slots; i++) {
      const startDateTT =
        i === 0
          ? moment(startDate, 'DD-MM-YYYY HH:mm').startOf('day')
          : moment(timeTracking[i - 1].endDate, 'DD-MM-YYYY HH:mm')
              .add(1, 'day')
              .startOf('day');

      const endDateTT = moment(startDateTT, 'DD-MM-YYYY HH:mm').add(period, 'days').endOf('day');
      timeTracking.push({
        startDate: startDateTT,
        endDate: endDateTT,
        totalHours: 0,
        totalMinutes: 0,
        trackingTableData: [],
        id: i + 1,
      });
    }
    for (const timeTrack of timeTracking) {
      for (const trackData of trackingData?.data || []) {
        if (moment(trackData.start_time).isBetween(moment(timeTrack.startDate), moment(timeTrack.endDate))) {
          const startDate = customerTime(trackData.start_time);
          const endDate = customerTime(new Date());
          const duration = moment.duration(endDate?.diff(startDate));
          trackData.finalDateHour = parseInt(duration?.asHours(), 10);
          trackData.finalDateMinutes = parseInt(duration?.asMinutes(), 10) % 60;
          trackData.showTime = trackData?.end_time
            ? `${trackData?.hours_tracked}h ${trackData?.minutes_tracked}m`
            : `${trackData.finalDateHour}h ${trackData.finalDateMinutes}m`;
          timeTrack.trackingTableData.push(trackData);
        }
      }
      timeTrack.totalHours = timeTrack.trackingTableData
        .map((data) => (data.end_time ? Number(data?.hours_tracked) : +data.finalDateHour))
        .reduce((a, b) => a + b, 0);
      timeTrack.totalMinutes = timeTrack.trackingTableData
        .map((data) => (data.end_time ? Number(data?.minutes_tracked) : +data.finalDateMinutes))
        .reduce((a, b) => a + b, 0);
      timeTrack.totalHours += +(timeTrack.totalMinutes / 60).toString().split('.')[0];
      timeTrack.totalMinutes %= 60;
      timeTrack.totalMinutes =
        timeTrack.totalMinutes.toString().length === 1
          ? `0${(timeTrack.totalMinutes % 60).toString()}`
          : timeTrack.totalMinutes % 60;
      timeTrack.hourPer = ((timeTrack?.totalHours || 0) / 40) * 100;
    }
    timeTracking = timeTracking.filter((timeTrack) => timeTrack.trackingTableData.length);
    timeTracking = timeTracking.map((timeTrack, index) => ({ ...timeTrack, id: index + 1 }));
    setDriverTimeTracking({
      total: trackingData.total,
      last_page: trackingData.last_page,
      current_page: trackingData.current_page,
      data: [...timeTracking],
    });
  };

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const customerTime = (date) => {
    const localData = JSON.parse(localStorage.getItem('user'));
    const timeZone = JSON.parse(localData?.customer?.timezone);
    const dateFormatted = timeZone.zone_name.replace(/ - /g, '/');

    return moment.tz(moment.utc(date), dateFormatted);
  };

  const onRowClick = (rowData, index) => {
    setSelectedTrucking({
      ...rowData,
      start_time: convertToCustomerTime(rowData.start_time, 'MM/DD/YYYY hh:mm A'),
      end_time: rowData?.end_time ? convertToCustomerTime(rowData.end_time, 'MM/DD/YYYY hh:mm A') : null,
      tableIndex: index,
      accordianIndex: rowData.id,
    });
    setOpenAddUpdateRecord(true);
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={driverTimeTracking.data?.length}
        totalLength={driverTimeTracking.data?.length}
        lastPage={driverTimeTracking.last_page}
        currentPage={driverTimeTracking.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setDriverTimeTracking(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };
  const onChangeRowPerPage = (rowPage) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    driverTimeTrackingData({
      page: driverTimeTracking.current_page,
      itemsPerPage: rowPage,
      staff_id: id,
      start_time: startFormat,
      end_time: endFormat,
      'sort[][id]': 'asc',
    })
      .then((res) => {
        makeTrackingDate(res, dateRange.start, dateRange.end, 7);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const columns = useMemo(() => {
    const toggleAccordian = (rowData) => {
      const tempData = [...driverTimeTracking.data].map((timeTrack, index) => ({
        ...timeTrack,
        isActive: rowData.id - 1 !== index ? false : timeTrack.isActive,
      }));
      tempData[rowData.id - 1].isActive = !tempData[rowData.id - 1].isActive;
      setDriverTimeTracking({ ...driverTimeTracking, data: [...tempData] });
    };
    return [
      {
        field: 'startDate',
        title: (
          <div className='time-tracked-header'>
            <span className='bold-text'>Week</span> <span className='bold-text'>Time Tracked</span>
          </div>
        ),
        render: (parentRowData) => (
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <div
              className='accordion-container-main driver-style-wrap'
              onClick={() => toggleAccordian(parentRowData)}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
            >
              <div className='accordion-container-sub'>
                <img src={OpenArrow} alt='Open' />
                <div className='sub-wraper'>
                  <div className='date-container'>
                    <p className='sub-text'>
                      {parentRowData?.startDate ? convertToCustomerTime(parentRowData?.startDate, dateFormat) : '-'}
                    </p>
                  </div>
                  <span>-</span>
                  <div className='date-container'>
                    <p className='sub-text'>
                      {parentRowData?.endDate ? convertToCustomerTime(parentRowData?.endDate, dateFormat) : '-'}{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className='time-worked-container'>
                <p className='time-worked'>
                  {parentRowData?.totalHours}h {parentRowData?.totalMinutes}m
                </p>
                <div className='time-worked-wrap'>
                  <div className='time-worked-wrap-sub' style={{ width: `${parentRowData?.hourPer}%` }} />
                </div>
              </div>
            </div>
            {parentRowData.isActive && (
              <div className='sub-table-container' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                <table className='custom-table-wraper'>
                  <thead className='custom-table-header-wraper'>
                    {parentRowData?.trackingTableData?.length === 0 ? (
                      <p className='on-empty-data'>No data found</p>
                    ) : (
                      <tr className='custom-table-row-wraper custom-table-row-wraper-header'>
                        <td>ID</td>
                        <td>HOURS TRACKED</td>
                        <td>START TIME</td>
                        <td>START LOCATION</td>
                        <td>END TIME</td>
                        <td>END LOCATION</td>
                        <td />
                      </tr>
                    )}
                  </thead>
                  {parentRowData?.trackingTableData.map((rowData, index) => {
                    return (
                      <tr
                        className='custom-table-row-wraper'
                        key={rowData.id}
                        onClick={() => onRowClick(rowData, index)}
                      >
                        <td>
                          <span>{rowData?.id}</span>
                        </td>
                        <td>
                          <span
                            onClick={() => onRowClick(rowData, index)}
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            {rowData.showTime}
                          </span>
                        </td>
                        <td>
                          <p
                            onClick={() => onRowClick(rowData, index)}
                            className='table-data-text'
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            {rowData?.start_time ? convertToCustomerTime(rowData?.start_time) : '-'}
                          </p>
                          {rowData?.break === 1 ? (
                            <div className='success-container' style={{ backgroundColor: use('#FCF2E6', '#FCF2E6') }}>
                              <p className='success-text' style={{ color: use('#AA5B00', '#AA5B00') }}>
                                BREAK START
                              </p>
                            </div>
                          ) : (
                            <div
                              className='success-container'
                              style={{ backgroundColor: use('#E1FCEF', palette.darkgreen) }}
                            >
                              <p className='success-text'>Clocked in</p>
                            </div>
                          )}
                        </td>
                        <td>
                          <div onClick={() => onRowClick(rowData, index)}>
                            {!(
                              rowData?.start_time_street &&
                              rowData?.start_time_city &&
                              rowData?.start_time_state_code &&
                              rowData?.start_time_country
                            ) ? (
                              <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                -
                              </p>
                            ) : (
                              <>
                                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                  {rowData?.start_time_street ? `${rowData?.start_time_street}` : ''}
                                </p>
                                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                  {rowData?.start_time_city && rowData?.start_time_state_code && rowData?.start_time_zip
                                    ? `${rowData?.start_time_city}, ${rowData?.start_time_state_code} ${rowData?.start_time_zip}`
                                    : !rowData?.start_time_city &&
                                      rowData?.start_time_state_code &&
                                      rowData?.start_time_zip
                                    ? `${rowData?.start_time_state_code} ${rowData?.start_time_zip}`
                                    : rowData?.start_time_city &&
                                      !rowData?.start_time_state_code &&
                                      rowData?.start_time_zip
                                    ? `${rowData?.start_time_city}, ${rowData?.start_time_zip}`
                                    : rowData?.start_time_city &&
                                      rowData?.start_time_state_code &&
                                      !rowData?.start_time_zip
                                    ? `${rowData?.start_time_city}, ${rowData?.start_time_state_code}`
                                    : ' '}{' '}
                                </p>
                                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                  {rowData?.start_time_country ? `${rowData?.start_time_country}` : ''}
                                </p>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div onClick={() => onRowClick(rowData, index)}>
                            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                              {rowData?.end_time ? convertToCustomerTime(rowData?.end_time) : '-'}
                            </p>
                            {rowData?.break === 1 && !!rowData?.end_time ? (
                              <div className='warning-container' style={{ backgroundColor: '#AA5B00' }}>
                                <p className='warning-text' style={{ color: use('#FCF2E6', palette.red400) }}>
                                  BREAK END
                                </p>
                              </div>
                            ) : !rowData?.end_time ? (
                              ' '
                            ) : (
                              <div className='warning-container' style={{ backgroundColor: '#faf0f3' }}>
                                <p className='warning-text' style={{ color: use('rgb(220, 64, 103)', palette.red400) }}>
                                  Clocked out
                                </p>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div onClick={() => onRowClick(rowData, index)}>
                            {!(
                              rowData?.end_time_street &&
                              rowData?.end_time_city &&
                              rowData?.end_time_state_code &&
                              rowData?.end_time_country
                            ) ? (
                              <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                -
                              </p>
                            ) : (
                              <>
                                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                  {rowData?.end_time_street ? `${rowData?.end_time_street}` : '-'}
                                </p>
                                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                  {rowData?.end_time_city && rowData?.end_time_state_code && rowData?.end_time_zip
                                    ? `${rowData?.end_time_city}, ${rowData?.end_time_state_code} ${rowData?.end_time_zip}`
                                    : !rowData?.end_time_city && rowData?.end_time_state_code && rowData?.end_time_zip
                                    ? `${rowData?.end_time_state_code} ${rowData?.end_time_zip}`
                                    : rowData?.end_time_city && !rowData?.end_time_state_code && rowData?.end_time_zip
                                    ? `${rowData?.end_time_city}, ${rowData?.end_time_zip}`
                                    : rowData?.end_time_city && rowData?.end_time_state_code && !rowData?.end_time_zip
                                    ? `${rowData?.end_time_city}, ${rowData?.end_time_state_code}`
                                    : ' '}{' '}
                                </p>
                                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                                  {rowData?.end_time_country ? `${rowData?.end_time_country}` : '-'}
                                </p>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <RowActions onDelete={() => onDelete(rowData.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )}
          </div>
        ),
      },
    ];
  }, [use, driverTimeTracking]);

  if (loading) {
    return <TablePreLoader />;
  }

  const onDelete = (id) => {
    setRecordToDelete(id);
  };

  const deleteTimeTracking = async () => {
    try {
      setLoadingDelete(true);
      await deleteTimeTrackingRecord(recordToDelete);
      showToaster({
        type: 'success',
        message: `Record has been successfully deleted!`,
      });
      initialCall();
      setRecordToDelete(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const addUpdateTracking = async (updatedTrackingInfo) => {
    setUpdateLoading(true);
    const payload = { ...updatedTrackingInfo };
    const diffInMinutes = Math.abs(moment(payload.end_time || new Date()).diff(payload.start_time, 'minutes'));
    const hours_tracked = Math.floor(diffInMinutes / 60);
    const minutes_tracked = diffInMinutes % 60;

    const start_time_customer = moment(payload.start_time).format('YYYY-MM-DD HH:mm:ss');
    const end_time_customer = payload.end_time ? moment(payload.end_time).format('YYYY-MM-DD HH:mm:ss') : null;

    try {
      if (selectedTrucking) {
        await updateTimeTracking(selectedTrucking.id, {
          ...payload,
          user_id: selectedTrucking.user_id,
          user_type: selectedTrucking.user_type,
          start_time: customerTimeToUtc(start_time_customer),
          end_time: end_time_customer && customerTimeToUtc(end_time_customer),
          hours_tracked,
          minutes_tracked,
          user: undefined,
          start_location_lat: undefined,
          start_location_long: undefined,
          end_location_lat: undefined,
          end_location_long: undefined,
        });
      } else {
        const formData = new FormData();

        const {
          user,
          start_time_street,
          start_time_city,
          start_time_state_code,
          start_time_country,
          start_time_zip,
          end_time_street,
          end_time_city,
          end_time_state_code,
          end_time_country,
          end_time_zip,
          start_location_lat,
          start_location_long,
          end_location_lat,
          end_location_long,
        } = payload;

        formData.append('user_id', user?.id || id);
        formData.append('user_type', 1);

        if (start_time_street) {
          formData.append('start_time_street', start_time_street);
        }
        if (start_time_city) {
          formData.append('start_time_city', start_time_city);
        }
        if (start_time_state_code) {
          formData.append('start_time_state_code', start_time_state_code);
        }
        if (start_time_country) {
          formData.append('start_time_country', start_time_country);
        }
        if (start_time_zip) {
          formData.append('start_time_zip', start_time_zip);
        }
        if (start_location_lat) {
          formData.append('start_location_lat', start_location_lat);
        }
        if (start_location_long) {
          formData.append('start_location_long', start_location_long);
        }

        if (end_time_street) {
          formData.append('end_time_street', end_time_street);
        }
        if (end_time_city) {
          formData.append('end_time_city', end_time_city);
        }
        if (end_time_state_code) {
          formData.append('end_time_state_code', end_time_state_code);
        }
        if (end_time_country) {
          formData.append('end_time_country', end_time_country);
        }
        if (end_time_zip) {
          formData.append('end_time_zip', end_time_zip);
        }
        if (end_location_lat) {
          formData.append('end_location_lat', end_location_lat);
        }
        if (end_location_long) {
          formData.append('end_location_long', end_location_long);
        }

        formData.append('start_time', customerTimeToUtc(start_time_customer));
        if (end_time_customer) {
          formData.append('end_time', customerTimeToUtc(end_time_customer));
        }
        formData.append('hours_tracked', hours_tracked);
        formData.append('minutes_tracked', minutes_tracked);

        await createTimeTracking(formData);
      }

      showToaster({
        type: 'success',
        message: `Time tracking record has been successfully ${selectedTrucking ? 'updated' : 'created'}!`,
      });
      initialCall();
      setOpenAddUpdateRecord(false);
      setSelectedTrucking(null);
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setUpdateLoading(false);
  };

  return (
    <div
      className='time-tacking-container driver-style-wrap time-tacking-container-profile-tab'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <SHeaderWrapper>
          <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Time Tracking
          </p>
          <div className='d-flex align-items-center gap-3'>
            <div className='video-link-wrapper' onClick={() => setOpenVideo(true)}>
              <img src={playVideoIcon} alt='play-video' height='36px' />
              <Typography className='underline-text' variant='s2' style={{ color: palette.indigo500 }}>
                Watch Video
              </Typography>
            </div>
            <CustomButton
              type='primary'
              title='Add Time'
              leftIcon={<PlusIcon fill={palette.white} style={{ marginRight: 10 }} />}
              styleButton={{ padding: '5px 12px', marginTop: 0 }}
              onClick={() => setOpenAddUpdateRecord(true)}
            />
          </div>
        </SHeaderWrapper>
        <div className='serach-wrapper'>
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '143px', bottom: '57px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(!isShowDatePicker)} />
          </div>
        </div>
      </div>
      <div className='tracking-head' style={{ borderColor: use(palette.gray50, palette.dark800) }}>
        <MaterialTableWrapper
          columns={columns}
          data={driverTimeTracking?.data}
          rowPerPage={rowPerPage}
          components={{
            Pagination: PaginationComponent,
          }}
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        />
        <div />
        {!!openAddUpdateRecord && (
          <TrackingDetailsModal
            show={!!openAddUpdateRecord}
            onHide={() => {
              setOpenAddUpdateRecord(false);
              setSelectedTrucking(null);
            }}
            trackingDetails={selectedTrucking}
            updateTimeTracking={addUpdateTracking}
            loading={updateLoading}
            userType='staff'
            hideUserSelect
          />
        )}
        {!!recordToDelete && (
          <ConfirmationModal
            width='510px'
            open={!!recordToDelete}
            onClose={() => setRecordToDelete(null)}
            headerTitle='Delete Record'
            text='Are you sure you want to delete this time tracking record?'
            onConfirm={deleteTimeTracking}
            disabled={loadingDelete}
          />
        )}
        {openVideo && <ViewVideo open={openVideo} onClose={() => setOpenVideo(false)} videoUrl={TIME_APP_VIDEO_LINK} />}
      </div>
    </div>
  );
};

export default StaffTimeTracking;
