import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PhoneInput from 'common/PhoneInput';
import { Typography } from 'components/Typography';
import Input, { InputWithText } from 'common/Input';
import ErrorMessage from 'common/message/errorMessage';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { blockNotNumberChars } from 'utils/helpers';
import { createLoginAttempt } from 'Api/Onboarding';
import OnboardingService from 'services/onboardingService';
import { getInitialValues } from './CarrierInfo.data';
import { validationSchema } from './validationSchema';
import { SDotLoader, SInputWrapper, SWrapper } from '../Account.styles';

const CarrierInfo = ({ accountType, onContinue, onGoBack }) => {
  const navigate = useNavigate();
  const { plans } = useSelector((state) => state.onboarding);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [dotError, setDotError] = useState('');
  const skipSelectPlan = !!plans?.[0]?.plan_types?.length && plans?.[0]?.plan_types?.length <= 1;

  const onSubmit = (values) => {
    try {
      const body = {
        first_name: values.name,
        last_name: values.last_name,
        dot: values.dot,
        company_name: values.company_name,
        telephone_number: values.phone,
        email: values.email,
        product: accountType === 3 ? 'Carrier & Broker' : 'Carrier',
        license_count: values.workers,
        date_time: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      createLoginAttempt(body);
    } catch (e) {
      // Do nothing
    }

    const partner_referral_link = searchParams.get('referralLink');

    if (partner_referral_link) {
      values.partner_referral_link = partner_referral_link;
    }
    localStorage.setItem(
      'account',
      JSON.stringify({ ...values, phone: values.phone?.startsWith('+') ? values.phone : `+${values.phone}` })
    );
    localStorage.setItem('dot-num', JSON.stringify(values.dot));
    if (accountType === 3) {
      onContinue();
      return;
    }

    if (skipSelectPlan) {
      localStorage.setItem('selected-plan', JSON.stringify(plans[0]));
      localStorage.setItem('plan-selected', JSON.stringify(plans[0].plan_types[0]));
      navigate('/onboarding/company');
    } else {
      navigate('/onboarding/select-plan');
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit,
  });

  const onDotChange = async (val) => {
    const value = (val || '').trim();
    setDotError('');
    localStorage.removeItem('ein');
    await setValues((prevState) => ({
      ...prevState,
      dot: value,
      address1: '',
      country: null,
      state: null,
      city: null,
      zip: '',
    }));
    if (value.length !== 7) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await OnboardingService.checkDot(value);

      if (data?.error?.message || data?.data?.message) {
        setDotError(data?.error?.message || data?.data?.message);
      } else {
        const { phone, totalDrivers, legalName, phyCountry, phyState, phyCity, phyStreet, phyZipcode, ein } =
          data?.data?.content?.carrier || {};

        if (ein) {
          localStorage.setItem('ein', ein);
        }

        setValues((prevState) => ({
          ...prevState,
          company_name: legalName || values.company_name,
          phone: phone ? `+1${phone.replaceAll(/[- +()]/g, '')}` : values.phone,
          workers: totalDrivers || values.workers,
          address1: phyStreet,
          zip: phyZipcode,
          country: phyCountry || null,
          state: phyState || null,
          city: phyCity || null,
        }));
      }
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <SWrapper>
      <div className='content-wrapper'>
        <div className='title-wrapper'>
          {loading && (
            <SDotLoader>
              <p className='spinner-text'>
                Validating <br /> DOT...
              </p>
              <div className='spinner' />
            </SDotLoader>
          )}
          <ErrorMessage message={dotError} close={() => setDotError('')} show={!!dotError} />
          <Typography variant='h1' className='text-center'>
            {accountType === 3 ? `Carrier Profile` : `Let's Get To Know You Better`}
          </Typography>
          <Typography variant='s3' style={{ color: palette.gray700 }} className='text-center'>
            {accountType === 3
              ? `Please enter your carrier company information below.`
              : `Incase you have any questions about our pricing plans.`}
          </Typography>
        </div>
        <div className='form-wrapper'>
          <InputWithText
            left
            className='w-100'
            text='DOT #'
            name='dot'
            placeholder='DOT Number'
            value={values.dot}
            onChange={(e) => onDotChange(e.target.value)}
            error={touchedErrors.dot}
            bgColor={palette.gray50}
            onKeyDown={blockNotNumberChars}
          />
          <SInputWrapper>
            <Input
              className='w-100'
              name='name'
              placeholder='First Name'
              value={values.name}
              onChange={handleChange}
              error={touchedErrors.name}
            />
            <Input
              className='w-100'
              name='last_name'
              placeholder='Last Name'
              value={values.last_name}
              onChange={handleChange}
              error={touchedErrors.last_name}
            />
          </SInputWrapper>
          <Input
            name='company_name'
            placeholder='Company Name'
            value={values.company_name}
            onChange={handleChange}
            error={touchedErrors.company_name}
          />
          <PhoneInput
            name='phone'
            placeholder='+1 (555) 555-1234'
            onChange={(val) => handleChange('phone', val)}
            value={values.phone}
            error={touchedErrors.phone}
            onlyCountries={['us', 'ca', 'mx']}
          />
          <Input
            name='email'
            placeholder='Email'
            value={values.email}
            onChange={handleChange}
            error={touchedErrors.email}
          />
          <InputWithText
            className='w-100'
            text='# of staff & drivers'
            name='workers'
            placeholder='1+'
            value={values.workers}
            onChange={handleChange}
            error={touchedErrors.workers}
            bgColor={palette.gray50}
          />
          <div className='d-flex flex-column gap-3 mt-3'>
            <CustomButton
              type='primary'
              title='Continue'
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px', margin: 0, width: '100%', justifyContent: 'center' }}
              onClick={handleSubmit}
            />
            <CustomButton
              type='secondar'
              title='Go Back'
              styleTitle={{ fontSize: 14, color: palette.gray900 }}
              styleButton={{ padding: '6px 12px', margin: 0, width: '100%', justifyContent: 'center' }}
              onClick={onGoBack}
            />
          </div>
        </div>
      </div>
    </SWrapper>
  );
};

export default CarrierInfo;
