import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as BillIcon } from 'assets/icons/billing.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as PrintCheck } from 'assets/icons/printCheck.svg';
import Tooltip from 'common/Tooltip';
import Pagination from 'common/Pagination';
import TableWrapper from 'components/TableWrapper';
import CustomButton from 'components/CustomButton/CustomButton';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { SEND_PAYMENT_KEYS } from 'components/SendPaymentModal/constants';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { getSettlements, updateSettlement } from 'Api/Payroll';
import { onChangeIsOpenSendPayment } from 'store/reducers/header.reducer';
import MarkPaid from '../../components/MarkPaid';
import MarkPaidOne from '../../components/MarkPaidOne';
import PaystubModal from '../../components/PaystubModal';
import { useSettlementsColumns } from '../Tables.data';

const initialFilters = {
  page: 1,
  page_size: 100,
};

const ProcessedSettlements = ({ search, dateRange }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { openSettlement } = location?.state || {};
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [settlements, setSettlements] = useState({ data: [] });
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [selectedSettlement, setSelectedSettlement] = useState(null);
  const [settlementToMarkPaid, setSettlementToMarkPaid] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  const getSettlementsList = async () => {
    setLoading(true);
    try {
      const { page, page_size } = selectedFilters || {};
      const params = {
        page,
        page_size,
        query: search || undefined,
        status_id: 3,
        from: dateRange?.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        to: dateRange?.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        'sort[pay_period_start]': 'desc',
      };
      const response = await getSettlements(params);
      setSettlements(response);
      setSelectedRows(response?.data || []);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const onRowClick = async (e, row) => {
    if (row.pdf_file_link) {
      setSelectedSettlement(row);
      setPdfUrl(row.pdf_file_link);
    }
  };

  const onModalClose = () => {
    setPdfUrl(null);
    setSelectedSettlement(null);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onRevertSuccess = (settlement) => {
    navigate(
      `/settlement/${settlement.user_type?.includes('Driver') ? 2 : 1}/${settlement.user_id}/${
        settlement.id
      }?pay_period_start=${settlement.pay_period_start}&pay_period_end=${settlement.pay_period_end}`
    );
  };

  const updateSettlementStatus = async (settlement) => {
    setLoadingRevert(true);
    try {
      const isReadySettlement = moment().isAfter(settlement.pay_period_end);
      const body = { status_id: isReadySettlement ? 1 : 2 };
      await updateSettlement({ id: settlement.id, body });
      showToaster({ type: 'success', message: 'Settlement has been reverted successfully!' });
      onRevertSuccess(settlement);
      onModalClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingRevert(false);
  };

  const onMarkPaid = (settlement) => {
    setSettlementToMarkPaid(settlement);
  };

  const onMarkPaidSuccess = () => {
    setSelectedRows([]);
    getSettlementsList();
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== settlements.data.length ? settlements.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const infoTemplates = useCallback(() => {
    return (
      <div className='d-flex align-items-start gap-1'>
        <InfoIcon width={12} height={12} fill={palette.white} style={{ marginTop: 4 }} />
        <span>This feature is not available for your company.</span>
      </div>
    );
  }, []);

  const listenEmitter = ({ detail }) => {
    if (detail.activeName === SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS) {
      getSettlementsList();
    }
  };

  useEffect(() => {
    getSettlementsList();
  }, [selectedFilters, sort, search, dateRange]);

  useEffect(() => {
    if (openSettlement && settlements?.data?.length) {
      const row = settlements.data.find((item) => item.id === Number(openSettlement));
      onRowClick(null, row);
      navigate('.', { state: {} });
    }
  }, [settlements]);

  useEffect(() => {
    window.addEventListener('on_print_check_complete', listenEmitter);
    return () => window.removeEventListener('on_print_check_complete', listenEmitter);
  }, []);

  const columns = useSettlementsColumns({
    sort,
    sortingQuery,
    onRevert: updateSettlementStatus,
    onMarkPaid,
    isProcessed: true,
    handleSelectAll,
    handleSelectRow,
    selectedRows,
    allSelected: settlements.data.length === selectedRows.length,
    hideSelectAll: !settlements.data.length,
  });

  return (
    <div>
      {!!selectedRows.length && (
        <div className='d-flex mb-3 mt-3 gap-2'>
          <CustomButton
            type='primary'
            title='Mark Paid'
            onClick={() => setSettlementToMarkPaid(true)}
            leftIcon={<CheckIcon fill={palette.white} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '4px 12px', marginTop: 0 }}
          />
          <div className='d-flex align-items-center'>
            <Tooltip title={infoTemplates()}>
              <span>
                <CustomButton
                  type='secondary'
                  title='Send ACH'
                  onClick={() => null}
                  styleButton={{ padding: '4px 12px', marginTop: 0 }}
                  leftIcon={<BillIcon width={16} height={16} fill={palette.gray700} style={{ marginRight: 4 }} />}
                />
              </span>
            </Tooltip>
          </div>
          <div className='d-flex align-items-center'>
            <CustomButton
              type='secondary'
              title='Print Checks'
              onClick={() =>
                dispatch(
                  onChangeIsOpenSendPayment({
                    open: true,
                    isAch: false,
                    defaultStep: 3,
                    data: _.cloneDeep(selectedRows),
                    fromTabName: SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS,
                  })
                )
              }
              styleButton={{ padding: '4px 12px', marginTop: 0, marginLeft: 4 }}
              leftIcon={<PrintCheck width={16} height={16} fill={palette.gray700} style={{ marginRight: 4 }} />}
            />
          </div>
        </div>
      )}

      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <div className='table-has-checkbox-mui'>
          <div className='tableFixHead table-fixed-header-300'>
            <TableWrapper
              data={settlements?.data}
              rowPerPage={selectedFilters.page_size}
              onRowClick={onRowClick}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: settlements,
                    rowPerPage: selectedFilters.page_size,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={columns}
            />
          </div>
        </div>
      )}
      <PaystubModal
        pdfUrl={pdfUrl}
        settlement={selectedSettlement}
        updateSettlementStatus={() => updateSettlementStatus(selectedSettlement)}
        onClose={onModalClose}
        loading={loadingRevert}
      />
      {!!settlementToMarkPaid && selectedRows.length === 1 && (
        <MarkPaidOne
          open={!!settlementToMarkPaid}
          onClose={() => setSettlementToMarkPaid(null)}
          settlement={selectedRows[0]}
          onSuccess={onMarkPaidSuccess}
        />
      )}
      {!!settlementToMarkPaid && selectedRows.length > 1 && (
        <MarkPaid
          open={!!settlementToMarkPaid}
          settlements={selectedRows}
          onSuccess={onMarkPaidSuccess}
          onClose={() => setSettlementToMarkPaid(null)}
        />
      )}
    </div>
  );
};

export default ProcessedSettlements;
