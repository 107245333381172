import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import { UpdateStaffRestrictionSchedule, RestrictionAndShift, staffRestrictionSchedule } from 'Api/Staff';
import { RestrictionContent } from '../Staff/AddStaff/RestrictionShift';
import './RestrictionSchedule.css';

const StaffRestrictionSchedule = ({ showError, onSuccess }) => {
  const [toggle, setToggle] = React.useState(false);
  const { id } = useParams();
  const { use } = useTheme();

  const [loading, setLoading] = useState(false);
  const [onNextloading, setOnNextloading] = useState(false);
  const [getRestruction, setGetRestruction] = useState({});

  const saveRestrictionSchedule = (values) => {
    setOnNextloading(true);
    if (values.restrict && !values.restrict_login_ip?.filter((el) => !!el.trim())?.length) {
      showError('At least one IP is required!');
      setOnNextloading(false);
      return;
    }
    if (
      values.employee_schedule &&
      !(
        values.mon_shift ||
        values.tue_shift ||
        values.wed_shift ||
        values.thur_shift ||
        values.fri_shift ||
        values.sat_shift ||
        values.sun_shift
      )
    ) {
      showError('Enter at least one day schedule');
      setOnNextloading(false);
      return;
    }
    let tempData = {
      ...values,
      restrict: values.restrict ? 1 : 0,
      employee_schedule: values.employee_schedule ? 1 : 0,
      mon_shift: values.mon_shift ? 1 : 0,
      tue_shift: values.tue_shift ? 1 : 0,
      wed_shift: values.wed_shift ? 1 : 0,
      thur_shift: values.thur_shift ? 1 : 0,
      fri_shift: values.fri_shift ? 1 : 0,
      sat_shift: values.sat_shift ? 1 : 0,
      sun_shift: values.sun_shift ? 1 : 0,
      restrict_login_ip: values.restrict ? values.restrict_login_ip?.filter((el) => !!el.trim())?.join(',') : '',
    };
    if (tempData?.employee_schedule === 0) {
      tempData = {
        ...tempData,
        mon_shift: 0,
        mon_start: '00:00',
        mon_end: '00:00',
        tue_shift: 0,
        tue_start: '00:00',
        tue_end: '00:00',
        wed_shift: 0,
        wed_start: '00:00',
        wed_end: '00:00',
        thur_shift: 0,
        thur_start: '00:00',
        thur_end: '00:00',
        fri_shift: 0,
        fri_start: '00:00',
        fri_end: '00:00',
        sat_shift: 0,
        sat_start: '00:00',
        sat_end: '00:00',
        sun_shift: 0,
        sun_start: '00:00',
        sun_end: '00:00',
      };
    }
    if (!getRestruction?.length) {
      staffRestrictionSchedule(tempData)
        .then(() => {
          setOnNextloading(false);
          successResponse('Restriction and Shift has been updated');
          Restruction();
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      UpdateStaffRestrictionSchedule(tempData, id)
        .then(() => {
          setOnNextloading(false);
          successResponse('Restriction and Shift has been updated');
          Restruction();
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      staff_id: id,
      restrict: 0,
      employee_schedule: 0,
      restrict_login_ip: [],
      mon_shift: 1,
      mon_start: '00:00',
      mon_end: '00:00',
      tue_shift: 0,
      tue_start: '00:00',
      tue_end: '00:00',
      wed_shift: 0,
      wed_start: '00:00',
      wed_end: '00:00',
      thur_shift: 0,
      thur_start: '00:00',
      thur_end: '00:00',
      fri_shift: 0,
      fri_start: '00:00',
      fri_end: '00:00',
      sat_shift: 0,
      sat_start: '00:00',
      sat_end: '00:00',
      sun_shift: 0,
      sun_start: '00:00',
      sun_end: '00:00',
    },
    onSubmit: saveRestrictionSchedule,
  });

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const copyToAll = () => {
    formik.setFieldValue('tue_start', formik.values.mon_start);
    formik.setFieldValue('tue_end', formik.values.mon_end);
    formik.setFieldValue('wed_start', formik.values.mon_start);
    formik.setFieldValue('wed_end', formik.values.mon_end);
    formik.setFieldValue('thur_start', formik.values.mon_start);
    formik.setFieldValue('thur_end', formik.values.mon_end);
    formik.setFieldValue('fri_start', formik.values.mon_start);
    formik.setFieldValue('fri_end', formik.values.mon_end);
    formik.setFieldValue('sat_start', formik.values.mon_start);
    formik.setFieldValue('sat_end', formik.values.mon_end);
    formik.setFieldValue('sun_start', formik.values.mon_start);
    formik.setFieldValue('sun_end', formik.values.mon_end);
  };

  useEffect(() => {
    if (!getRestruction) {
      return;
    }
    const { staff_restriction_shift } = getRestruction;
    formik.setFieldValue('employee_schedule', staff_restriction_shift?.employee_schedule);
    formik.setFieldValue('restrict', staff_restriction_shift?.restrict);
    formik.setFieldValue('restrict_login_ip', staff_restriction_shift?.restrict_login_ip?.split(',') || ['']);
    formik.setFieldValue('mon_start', staff_restriction_shift?.mon_start || '00:00');
    formik.setFieldValue('mon_end', staff_restriction_shift?.mon_end || '00:00');
    formik.setFieldValue('tue_start', staff_restriction_shift?.tue_start || '00:00');
    formik.setFieldValue('tue_end', staff_restriction_shift?.tue_end || '00:00');
    formik.setFieldValue('wed_start', staff_restriction_shift?.wed_start || '00:00');
    formik.setFieldValue('wed_end', staff_restriction_shift?.wed_end || '00:00');
    formik.setFieldValue('thur_start', staff_restriction_shift?.thur_start || '00:00');
    formik.setFieldValue('thur_end', staff_restriction_shift?.thur_end || '00:00');
    formik.setFieldValue('fri_start', staff_restriction_shift?.fri_start || '00:00');
    formik.setFieldValue('fri_end', staff_restriction_shift?.fri_end || '00:00');
    formik.setFieldValue('sat_start', staff_restriction_shift?.sat_start || '00:00');
    formik.setFieldValue('sat_end', staff_restriction_shift?.sat_end || '00:00');
    formik.setFieldValue('sun_start', staff_restriction_shift?.sun_start || '00:00');
    formik.setFieldValue('sun_end', staff_restriction_shift?.sun_end || '00:00');
    formik.setFieldValue('mon_shift', staff_restriction_shift?.mon_shift);
    formik.setFieldValue('tue_shift', staff_restriction_shift?.tue_shift);
    formik.setFieldValue('wed_shift', staff_restriction_shift?.wed_shift);
    formik.setFieldValue('thur_shift', staff_restriction_shift?.thur_shift);
    formik.setFieldValue('fri_shift', staff_restriction_shift?.fri_shift);
    formik.setFieldValue('sat_shift', staff_restriction_shift?.sat_shift);
    formik.setFieldValue('sun_end', staff_restriction_shift?.sun_end);
  }, [getRestruction]);

  const Restruction = () => {
    setLoading(true);
    RestrictionAndShift(id)
      .then((res) => {
        setGetRestruction(res?.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    Restruction();
  }, []);

  return (
    <div
      className='Driver-compansation-wrapper staff-restriction driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Restriction & Schedule
        </p>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={() => setToggle(!toggle)} className='driver-compansation-cancel-btn'>
              Cancel
            </button>

            {onNextloading ? (
              <CircularProgress size={30} />
            ) : (
              <button onClick={formik.handleSubmit} className='driver-compansation-save-btn'>
                Save
              </button>
            )}
          </div>
        )}
      </div>
      {toggle ? (
        <div className='driver-compansation-edit-mode'>
          <RestrictionContent formik={formik} copyToAll={copyToAll} />
        </div>
      ) : (
        <div className='Driver-compansation'>
          {(getRestruction?.staff_restriction_shift?.employee_schedule === 0 &&
            getRestruction?.staff_restriction_shift?.restrict === 0) ||
          !getRestruction?.staff_restriction_shift ? (
            <div className='d-flex justify-content-center align-items-center'>
              {!loading && <p style={{ color: use(palette.gray400, palette.gray50) }}>No records to display</p>}
            </div>
          ) : (
            <div>
              {getRestruction?.staff_restriction_shift?.restrict === 1 && (
                <div>
                  <div
                    className='compansation-name-container'
                    style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                  >
                    <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Restrict Login only to IP(s) below:
                    </p>
                  </div>
                  <div className='details-container'>
                    {getRestruction?.staff_restriction_shift?.restrict_login_ip?.split(',').map((ip) => {
                      return (
                        <div className='d-flex bank-routing'>
                          <div
                            className='bank-name-conatiner ip-container'
                            style={{ backgroundColor: palette.gray0, borderColor: palette.gray50, borderRadius: '6px' }}
                          >
                            <p className='bank-name-style text-center' style={{ color: palette.gray700 }}>
                              IP
                            </p>
                          </div>
                          <input
                            type='text'
                            style={{ backgroundColor: palette.gray0, borderColor: palette.gray50 }}
                            value={ip}
                            disabled
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {getRestruction?.staff_restriction_shift?.employee_schedule === 1 && (
                <div>
                  <div
                    className='compansation-name-container compansation-name-style-custom'
                    style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                  >
                    <p className='compansation-name ' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Working Schedule
                    </p>
                  </div>
                  <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                    <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Day</p>
                    <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>Start Time</p>
                    <p className='compensation-feilds-header-head compensation-amount-main-wrap'>End Time</p>
                  </div>
                  {getRestruction?.staff_restriction_shift?.mon_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Monday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.mon_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.mon_end}
                        </p>
                      </div>
                    </div>
                  )}
                  {getRestruction?.staff_restriction_shift?.tue_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Tuesday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.tue_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.tue_end}
                        </p>
                      </div>
                    </div>
                  )}

                  {getRestruction?.staff_restriction_shift?.wed_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Wednesday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.wed_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.wed_end}
                        </p>
                      </div>
                    </div>
                  )}

                  {getRestruction?.staff_restriction_shift?.thur_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Thursday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.thur_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.thur_end}
                        </p>
                      </div>
                    </div>
                  )}

                  {getRestruction?.staff_restriction_shift?.fri_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Friday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.fri_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.fri_end}
                        </p>
                      </div>
                    </div>
                  )}

                  {getRestruction?.staff_restriction_shift?.sat_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Saturday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.sat_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.sat_end}
                        </p>
                      </div>
                    </div>
                  )}

                  {getRestruction?.staff_restriction_shift?.sun_shift !== 0 && (
                    <div className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Sunday
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {getRestruction?.staff_restriction_shift?.sun_start}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          {getRestruction?.staff_restriction_shift?.sun_end}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StaffRestrictionSchedule;
