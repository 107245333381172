import { object, string, number, mixed, array, ref } from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const basicInfoValidationSchema = object().shape({
  name: string().trim().required('This field is required'),
  dot: string().trim().required('This field is required'),
  phoneNumber: string().trim().matches(phoneRegExp, 'Enter a valid phone number').required('Required'),
  email: string().required('Email is required').email('Invalid Email Address'),
  country: object()
    .shape({ id: number().required('Country is required') })
    .required('Country is required')
    .nullable(),
  state: object()
    .shape({ id: number().required('State is required') })
    .required('State is required')
    .nullable(),
  city: object()
    .shape({ id: number().required('City is required') })
    .required('City is required')
    .nullable(),
  address1: string().trim().required('Address 1 is required'),
  address2: string().trim().nullable(),
  zipCode: string().trim().required('Zip code is required'),
  can_travel_to: array().min(1, 'Can Travel To is required').of(number()),
  trailer_interchange: string().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),
  trailer_interchange_from: string().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),
  trailer_interchange_expires: string().when('require_trailer_interchange', {
    is: (require_trailer_interchange) => !!require_trailer_interchange,
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),
});

export const operationsValidationSchema = object().shape({
  equipment_types: array().min(1, 'Equipment Type is requires').of(object()),
  operating_states: array().min(1, 'State is required').of(object()),
});

export const contactBookValidationSchema = array().of(
  object().shape({
    contact_name: string().trim().required('Required'),
    contact_email: string().trim().required('Required').email('Enter a valid email'),
    phone_number: string().trim().required('Required'),
    contact_type_id: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
  })
);

export const payTermValidationSchema = object().shape({
  invoicePayTerm: object()
    .shape({ id: number().required('Invoice Pay Terms is required') })
    .required('Invoice Pay Terms is required')
    .nullable(),
  country: object().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: object()
      .shape({ id: number().required('Country is required') })
      .required('Country is required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  state: object().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: object()
      .shape({ id: number().required('State is required') })
      .required('State is required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  city: object().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: object()
      .shape({ id: number().required('City is required') })
      .required('City is required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  address: string().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: string().trim().required('Address 1 is required'),
    otherwise: string().nullable(),
  }),
  address2: string().trim().nullable(),
  factoringCompany: string().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: string().trim().required('Factoring Company is required'),
    otherwise: string().nullable(),
  }),
  zipcode: string().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: string().trim().required('Zip Code is required'),
    otherwise: string().nullable(),
  }),
  email: string().when('payFactoring', {
    is: (payFactoring) => !!payFactoring,
    then: string().required('Factor Email is required').email('Invalid Email Address'),
    otherwise: string().nullable(),
  }),
  routing_confirmed: string()
    .oneOf([ref('bank_routing'), null], 'Routing Number Must Match')
    .nullable(),
  account_confirmed: string()
    .oneOf([ref('bank_account'), null], 'Bank Account Must Match')
    .nullable(),
});

export const masterAgreementValidationSchema = object().shape({
  signature: mixed().required('Signature is required'),
  phone: string().trim().matches(phoneRegExp, 'Enter a valid phone number').required('Required'),
});

export const indemnityValidationSchema = object().shape({
  signature: mixed().required('Signature is required'),
  phone: string().trim().matches(phoneRegExp, 'Enter a valid phone number').required('Required'),
});

export const documentsValidationSchema = object().shape({
  w9: mixed().required('Attachment is required'),
  authority: mixed().required('Attachment is required'),
  reference_sheet: mixed().required('Attachment is required'),
  liability_insurance_exp_date: mixed().required('Required'),
  liability_insurance: mixed().required('Attachment is required'),
  cargo_insurance_exp_date: mixed().required('Required'),
  cargo_insurance: mixed().required('Attachment is required'),
  general_liability_exp_date: mixed().required('Required'),
  general_liability: mixed().required('Attachment is required'),
  documents: array().of(
    object().shape({
      name: string().trim().required('Required').nullable(),
      expiration_date: string().when('no_expiration', {
        is: (no_expiration) => !no_expiration,
        then: string().trim().required('Required').nullable(),
        otherwise: string().nullable(),
      }),
      file: string().trim().required('Required').nullable(),
    })
  ),
});
