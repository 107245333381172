import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import QRCode from 'react-qr-code';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { convertImage } from 'Api/Driver';
import profile from '../../assets/icons/drivers/user-profile.svg';
import './DriverIDCard.css';

const DriverHorizontalCard = ({ ...props }) => {
  const isVertical = props?.isVertical;
  const [path, setPath] = useState();
  const [pathLogo, setPathlogo] = useState();
  const [CompanyLogoPath, setCompanyLogoPath] = useState();

  const { use } = useTheme();
  const formik = useFormik({
    initialValues: {
      qrCode: false,
      color: '#4F5AED',
      companyLogo: 'no',
      companyLogoImage: '',
      profileImage: '',
    },
  });

  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const companyLogo = props?.companyInfo?.logo_path;

  useEffect(() => {
    if (props?.driverInfo?.image) {
      props?.setIsPathLoading(true);
      convertImage(props?.driverInfo?.image, user?.customer?.dot)
        .then((res) => {
          setPath(`data:image/jpg;base64,${res?.image}`);
          props?.setIsPathLoading(false);
        })
        .catch(() => {
          props?.setIsPathLoading(false);
        });
    } else if (props?.driverInfo?.profile_logo) {
      props?.setIsPathLoading(true);

      convertImage(props?.driverInfo?.profile_logo, user?.customer?.dot)
        .then((res) => {
          setPathlogo(`data:image/jpg;base64,${res?.image}`);
          props?.setIsPathLoading(false);
        })
        .catch(() => {
          props?.setIsPathLoading(false);
        });
    }

    if (props?.companyInfo?.logo_path) {
      props?.setIsPathLoading(true);

      convertImage(companyLogo, user?.customer?.dot)
        .then((res) => {
          setCompanyLogoPath(`data:image/jpg;base64,${res?.image}`);
          props?.setIsPathLoading(false);
        })
        .catch(() => {
          props?.setIsPathLoading(false);
        });
    }
  }, [props?.driverInfo]);

  return (
    <div>
      <div className='covering-card'>
        {isVertical ? (
          <div id='vertical' className='vertical' ref={props?.reference}>
            <div
              className='vertical-card-container driver-style-wrap'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              {CompanyLogoPath && (
                <div className='vertical-header' style={{ backgroundColor: formik.values.color }}>
                  <div className='vertical-header-logoCover'>
                    {formik.values.companyLogo === 'yes' ? (
                      !!CompanyLogoPath && <img src={CompanyLogoPath} className='logo' alt='company' />
                    ) : (
                      <p className='company-name'>{props?.companyInfo?.company_name || ''}</p>
                    )}
                  </div>
                </div>
              )}

              <div className='sub-container'>
                <div>
                  {!props.isPathLoading ? (
                    <img src={path || pathLogo || profile} alt='driver' className='driver-image' />
                  ) : (
                    <Box sx={{ width: 100 }}>
                      <Skeleton height={150} />
                    </Box>
                  )}
                </div>
                <div className='driver-details'>
                  <div className='driver-details-basic'>
                    <p className='driver-name' style={{ color: use(palette.dark900, palette.gray50) }}>{`${
                      props?.driverInfo?.first_name || props?.driverInfo?.fname || ''
                    } ${props?.driverInfo?.last_name || props?.driverInfo?.lname || ''}`}</p>
                    <p className='driver-company' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {props?.driverInfo?.app?.driver_type === 'company'
                        ? 'Company Driver'
                        : props?.driverInfo?.app?.driver_type === 'owner'
                        ? 'Sub-Contract Driver'
                        : props?.driverInfo?.title
                        ? props?.driverInfo?.title
                        : ''}{' '}
                    </p>

                    {props?.driverInfo?.app?.driver_type === 'owner' ? (
                      <p className='driver-company' style={{ color: use(palette.dark900, palette.gray50) }}>
                        {props?.driverInfo?.app?.operator?.owner_operator_name}
                      </p>
                    ) : null}
                  </div>
                  <div className='driver-details-advance driver-details-advance-vertical'>
                    <p className='driver-number' style={{ color: use(palette.dark900, palette.gray50) }}>
                      {props?.driverInfo?.driver_id
                        ? props?.driverInfo?.driver_id
                        : props?.driverInfo?.certifications?.driver_id
                        ? props?.driverInfo?.certifications?.driver_id
                        : props?.driverInfo?.id}
                    </p>
                  </div>
                </div>
                {formik.values.qrCode ? (
                  <div className='barcode-container'>
                    <QRCode
                      value={String(
                        props?.driverInfo?.driver_id
                          ? props?.driverInfo?.driver_id
                          : props?.driverInfo?.certifications?.driver_id
                          ? props?.driverInfo?.certifications?.driver_id
                          : props?.driverInfo?.id
                      )}
                      size={50}
                    />
                  </div>
                ) : (
                  <span />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div id='horizontal' className='horizontal' ref={props?.horizontalref}>
            <div
              className='horizontal-card-container driver-style-wrap'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <div className='id-header' style={{ backgroundColor: use(formik.values.color, '#4F5AED') }}>
                {CompanyLogoPath && (
                  <div className='header-logoCover'>
                    {formik.values.companyLogo === 'yes' ? (
                      CompanyLogoPath ? (
                        <img src={CompanyLogoPath} className='logo' alt='company' />
                      ) : null
                    ) : (
                      <p className='company-name'>{props?.companyInfo?.company_name || ''}</p>
                    )}
                  </div>
                )}
              </div>
              <div className='sub-container'>
                <div>
                  {!props.isPathLoading ? (
                    <img src={path || pathLogo || profile} alt='driver' className='driver-image' />
                  ) : (
                    <Box sx={{ width: 100 }}>
                      <Skeleton height={200} />
                    </Box>
                  )}
                </div>
                <div className='driver-details'>
                  <div className='driver-details-basic'>
                    <p className='driver-name' style={{ color: use(palette.dark900, palette.gray50) }}>{`${
                      props?.driverInfo?.first_name || props?.driverInfo?.fname || ''
                    } ${props?.driverInfo?.last_name || props?.driverInfo?.lname || ''}`}</p>
                    <p className='driver-company' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {props?.driverInfo?.app?.driver_type === 'company'
                        ? 'Company Driver'
                        : props?.driverInfo?.app?.driver_type === 'owner'
                        ? 'Sub-Contract Driver'
                        : props?.driverInfo?.title
                        ? props?.driverInfo?.title
                        : ''}{' '}
                    </p>
                    {props?.driverInfo?.app?.driver_type === 'owner' ? (
                      <p className='owner-type' style={{ color: use(palette.dark900, palette.gray50) }}>
                        {props?.driverInfo?.app?.operator?.owner_operator_name || ''}
                      </p>
                    ) : null}
                  </div>
                  <div className='driver-details-advance'>
                    <p className='driver-number' style={{ color: use(palette.dark900, palette.gray50) }}>
                      {props?.driverInfo?.driver_id
                        ? props?.driverInfo?.driver_id
                        : props?.driverInfo?.certifications?.driver_id
                        ? props?.driverInfo?.certifications?.driver_id
                        : props?.driverInfo?.id}
                    </p>
                    {formik.values.qrCode && (
                      <QRCode
                        value={String(
                          props?.driverInfo?.driver_id
                            ? props?.driverInfo?.driver_id
                            : props?.driverInfo?.certifications?.driver_id
                            ? props?.driverInfo?.certifications?.driver_id
                            : props?.driverInfo?.id
                        )}
                        size={50}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='card-type'>
        <p className='card-type-text' style={{ color: use(palette.dark900, palette.gray50) }}>
          Type:
        </p>
        {/* <div className="card-type-container">
          <input type="radio" checked={props?.idCardDetail.type === 'pdf'} onClick={(e) => props?.setIdCardDetail({ ...props?.idCardDetail, type: 'pdf' })} />
          <p className="card-type-name" style={{ color: use(palette.gray700, palette.gray50) }}>PDF</p>
        </div> */}
        <div className='card-type-container'>
          <input
            type='radio'
            checked={props?.idCardDetail?.type === 'jpg'}
            onClick={() => props?.setIdCardDetail({ ...props?.idCardDetail, type: 'jpg' })}
          />
          <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray50) }}>
            JPG
          </p>
        </div>
        <div className='card-type-container'>
          <input
            type='radio'
            checked={props?.idCardDetail?.type === 'png'}
            onClick={() => props?.setIdCardDetail({ ...props?.idCardDetail, type: 'png' })}
          />
          <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray50) }}>
            PNG
          </p>
        </div>
      </div>
      <div className='card-type'>
        <p className='card-type-text' style={{ color: use(palette.dark900, palette.gray50) }}>
          Background Color:
        </p>
        <div className='card-type-container'>
          <div className='driverGroupId-container-sub-wrap'>
            <input
              type='color'
              className='color-picker-input'
              name='color'
              id='color'
              onChange={formik.handleChange}
              value={formik.values.color}
            />
          </div>
        </div>
      </div>
      <div className='card-type'>
        <p className='card-type-text' style={{ color: use(palette.dark900, palette.gray50) }}>
          Use Company Logo:
        </p>
        <div className='card-type-container'>
          <div className='card-type-container'>
            <input
              type='radio'
              // name="companyLogo"
              defaultChecked
              onChange={() => formik.setFieldValue('companyLogo', 'yes')}
              value='yes'
              checked={formik.values.companyLogo === 'yes'}
            />
            <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray50) }}>
              Yes
            </p>
          </div>
          <div className='card-type-container'>
            <input
              type='radio'
              // name="companyLogo"
              onChange={() => formik.setFieldValue('companyLogo', 'no')}
              checked={formik.values.companyLogo === 'no'}
              value='no'
            />
            <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray50) }}>
              No
            </p>
          </div>
        </div>
      </div>
      <div className='card-type'>
        <div className='card-type-container'>
          <input
            type='checkbox'
            name='qrCode'
            id='qrCode'
            onChange={formik.handleChange}
            value={formik.values.qrCode}
            checked={formik.values.qrCode}
          />
          <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray50) }}>
            Add QR Code
          </p>
        </div>
      </div>
    </div>
  );
};

export default DriverHorizontalCard;
