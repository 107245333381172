import React from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Input from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import { DATE_FORMAT, formatNumber, palette } from 'utils/constants';
import classes from '../MasterAgreement/masterAgreement.module.scss';

const IndemityAgreement = ({ company, carrier, form, signPad }) => {
  const {
    company_name,
    address1,
    city,
    state,
    zipcode,
    general_liability_minimum_coverage,
    use_own_trailer_interchange,
    trailer_interchange_agreement,
    liability_minimum_coverage,
    cargo_insurance_minimum_coverage,
    signature: companySignature,
    signature_first_name,
    signature_last_name,
    phone_number,
    master_agreement_title,
    date_time_format_settings,
  } = company || {};

  const {
    name,
    trailer_interchange,
    address1: carrierAddress1,
    city: carrierCity,
    state: carrierState,
    zipcode: carrierZipcode,
    trailer_interchange_from,
    trailer_interchange_expires,
  } = carrier || {};
  const { values, handleChange, touchedErrors } = form;

  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];

  const onClear = () => {
    signPad?.current.clear();
    handleChange('signature', null);
  };

  return (
    <div>
      <Typography variant='title2'>
        This Trailer Interchange and Indemnity Agreement is entered into between {company_name} located at {address1},{' '}
        {city?.name}, {state?.short_name} {zipcode} and {name} located at {carrierAddress1}, {carrierCity?.name},{' '}
        {carrierState?.short_name} {carrierZipcode} on {moment().format('Do')} day of {moment().format('MMMM YYYY')} and
        shall govern the terms and conditions of the use of trailer equipment belonging to one party by another.{' '}
      </Typography>

      <div className='mt-3'>
        <Typography variant='title2'>
          WHEREAS, each party to this Agreement in the ordinary course of its business owns, operates and maintains
          tractor-trailer equipment suitable for transportation of cargo in intra- and interstate commerce; and{' '}
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          WHEREAS, the parties are engaged in transportation agreements which provide for the through movement of
          freight including pickup and delivery and spotting of trailer equipment under circumstances which require or
          permit one party to use the trailer of another to facilitate service; and{' '}
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          <p>
            WHEREAS, this agreement is valid from {moment(trailer_interchange_from || new Date()).format(dateFormat)}{' '}
            and will expire on midnight {moment(trailer_interchange_expires || new Date()).format(dateFormat)}; and{' '}
          </p>
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          WHEREAS, the parties desire to permit such interchange and use of trailer by the other subject to the terms
          and conditions of this uniform Agreement;{' '}
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>NOW, THEREFORE, the Parties agree as follows: </Typography>
      </div>

      {!use_own_trailer_interchange ? (
        <>
          <div className='mt-3'>
            <Typography variant='title2'>
              As otherwise agreed in writing from time to time, one party may make permissive use of trailer equipment
              owned by the other with or without compensation under the following conditions.{' '}
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              1. The party owning the equipment warrants that equipment is regularly maintained and is free from any
              known defects by it.{' '}
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              2. Upon acceptance of any trailer, the receiving party shall prepare an equipment receipt/inspection
              report noting all damage, absence of damage and conditions of safety related items including, but not
              limited to, tires, brakes, air systems, sliding tandem hook pin. Such reports shall be prepared upon the
              acceptance of the trailer. Should the inspection reveal any safety related defects, the receiving party
              will notify the owner before use. Use of any trailer without notification or the preparation of an
              inspection report shall be deemed as acceptance without recourse. No warranty, express or implied, is made
              by the owner of the quality, design, or manufacture of the equipment. The owning party agrees to extend to
              the receiving party all warranties, if any, offered by the manufacturers of the equipment. The receiving
              party shall be responsible for all loss or damage to trailer while the trailer is in its possession and
              control,except to the extent such loss is caused by the act or omission of owner or its employees.
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              Receipt by any party of equipment belonging to the other without written notice shall constitute the
              receiving party’s acknowledgment that the shipment is in good working order and free of reasonable defect.
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              4. While equipment is in its care, custody and control, any party receiving the equipment of another under
              this Agreement warrants as follows:
            </Typography>
          </div>

          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(a)</Typography>
            <Typography variant='title2'>
              It will indemnify and hold the owner of the trailer equipment harmless from all risk of loss, claims,
              damage or cause of action arising out of its use including, but not limited to, any legal fee, the cost of
              defense or a judgment resulting from any claim of negligent entrustment or vicarious liability arising out
              of the receiving party's use, including the loading and unloading of the trailer while in the receiving
              party’s possession.
            </Typography>
          </div>

          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(b)</Typography>
            <Typography variant='title2'>
              The receiving party shall use the trailer only for the use contemplated by the authorizing agreement
              between the parties and shall return same at the completion of use to the location agreed to by the
              parties, free from defect, ordinary wear and tear excepted.
            </Typography>
          </div>

          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(c)</Typography>
            <Typography variant='title2'>
              The receiving party shall bear all costs associated with any physical damage resulting from its use, and
              assume liability for any loss and/or damage to equipment (including shipments contained therein) while
              said equipment is stored on its premises or is being used by it or its designee to load, unload, transport
              cargo, or reposition equipment, unless such loss or damage is caused by the negligent acts or omissions of
              the owner.
            </Typography>
          </div>

          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(d)</Typography>
            <Typography variant='title2'>
              The receiving party warrants that its auto liability coverage is properly endorsed to extend coverage to
              the owner of trailer equipment under a lease interchange endorsement.
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              5. Insurance. As financial surety for the indemnities and undertakings in 1 through 4 above, each warrants
              that it and/or its designee shall maintain the following insurance coverage which it warrants will inure
              to the owner of the trailer equipment’s benefit:
            </Typography>
          </div>

          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(a)</Typography>
            <Typography variant='title2'>
              Commercial General Liability Coverage in the amount of not less than $
              {formatNumber(general_liability_minimum_coverage)}.
            </Typography>
          </div>
          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(b)</Typography>
            <Typography variant='title2'>
              Auto Liability Coverage in the amount of not less than ${formatNumber(liability_minimum_coverage)} per
              occurrence.
            </Typography>
          </div>
          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(c)</Typography>
            <Typography variant='title2'>
              Motor Truck Cargo Insurance in the amount of not less than the market value of shipment but not to exceed
              ${formatNumber(cargo_insurance_minimum_coverage)} per truck load.
            </Typography>
          </div>
          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(d)</Typography>
            <Typography variant='title2'>
              Worker’s Compensation Insurance in the amount required by applicable state law.
            </Typography>
          </div>
          <div className='mt-3 ms-4 d-flex gap-2'>
            <Typography>(e)</Typography>
            <Typography variant='title2'>
              Physical Damage Insurance in an amount equal to ${formatNumber(trailer_interchange)} below or exceeding
              the value of the interchanged equipment or evidence of an appropriate trailer interchange endorsement.
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              Each will be named a certificate holder as well as an additional insured on (a), supra and as a loss payee
              on (c) and (e) supra, on the other parties’ policies.
            </Typography>
          </div>

          <div className='mt-3'>
            <Typography variant='title2'>
              6. Miscellaneous. This Agreement will become effective on the date written below and will continue from
              month-to-month thereafter, subject to termination by either party on 10 days prior written notice to the
              other. This Agreement shall not be modified except by written instrument signed by authorized
              representatives for affected parties. This Agreement shall be governed by and interpreted under the laws
              of the State of Tennessee. In the absence of written trailer interchange executed at time of the transfer
              of possession or other contemporaneous written notification, the receiving party of equipment accepts the
              equipment free from observable defect or damage.
            </Typography>
          </div>
        </>
      ) : (
        <div className='mt-3'>
          <Typography variant='title2' dangerouslySetInnerHTML={{ __html: trailer_interchange_agreement }} />
        </div>
      )}

      <div className='d-flex justify-content-between w-100 mt-4'>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s1'>{company_name}</Typography>
            <div className={classes.broker_signature_area}>
              <img src={companySignature} alt='' />
            </div>
            <div style={{ height: '1px', background: palette.gray100, width: 360 }} />
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Broker, Authorized Signature
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Printed Name
            </Typography>
            <Typography variant='s1'>
              {' '}
              {signature_first_name} {signature_last_name}
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Title
            </Typography>
            <Typography variant='s1'>{master_agreement_title}</Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Phone
            </Typography>
            <Typography variant='s1'>{formatPhoneNumberIntl(phone_number)}</Typography>
          </div>
        </div>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex flex-column gap-1'>
            <div className='d-flex justify-content-between'>
              <Typography variant='s1'>{name}</Typography>
              <div className='d-flex align-items-center gap-3 me-2'>
                <span onClick={onClear} className={classes.clear}>
                  Clear
                </span>
              </div>
            </div>
            <div className={classes.signature_area}>
              <SignatureCanvas
                onBegin={() => {
                  handleChange('signature', 'Klir');
                }}
                clearOnResize={false}
                canvasProps={{ className: classes.sigCanvas }}
                penColor='black'
                ref={signPad}
              />
              <ErrorMessage error={touchedErrors.signature} />
              <div style={{ height: '1px', background: palette.gray100, width: 360 }} />
            </div>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Carrier, Authorized Signature
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Printed Name
            </Typography>
            <Input value={values.printedName} onChange={(e) => handleChange('printedName', e.target.value)} />
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Title
            </Typography>
            <Input value={values.title} onChange={(e) => handleChange('title', e.target.value)} />
          </div>

          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Phone
            </Typography>
            <PhoneInput
              name='phone'
              placeholder='+1 (555) 555-1234'
              onChange={(val) => handleChange('phone', val)}
              value={values.phone}
              error={touchedErrors.phone}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndemityAgreement;
