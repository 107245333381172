import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPlanAhead = styled.div`
  padding: 18px 24px;

  .plan-ahead-footer {
    width: 100%;
    height: 64px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 8px 8px;
    background-color: ${palette.white};
  }
`;
