import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Tooltip from 'common/Tooltip';
import HeaderStar from 'components/HeaderStar';
import CustomButton from 'components/CustomButton/CustomButton';
import useShowToaster from 'hooks/useShowToaster';

import { getExportCSV } from 'Api/EquipmentImport';
import { getErrorMessage } from 'utils/error';
import { palette, PERMISSIONS } from 'utils/constants';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';

import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/download2.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import { useTheme } from 'context/themeContext';
import PopoverSettingsCustomers from '../../PopoverSettings/PopoverSettingsCustomer';
import CarrierSearch from '../CarrierSearch/CarrierSearch';
import DarkOverlay from '../../DarkOverlay';
import AddCarrier from '../AddCarrier/AddCarrier';
import styles from './CarriersHeader.module.css';

const CarriersHeader = ({
  onSearch,
  advancedOpen,
  setAdvancedOpen,
  getCarriers,
  permissions,
  setOpenImportCsv,
  setLoading,
  search,
  setSearch,
  filter,
  dragItem,
  getSettings,
  updateFilter,
  tableColumn,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);

  const exportCsv = async () => {
    setLoading(true);
    try {
      const params = { type: 'carriers' };
      const data = await getExportCSV(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `carriers.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location?.state?.openCreate) {
      if (permissions.includes(PERMISSIONS.CREATE_CARRIER.name)) {
        setOpenAddCustomer(true);
      }
    }
  }, [location?.state]);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettingsCustomers
          type='carrier'
          filter={filter}
          dragItem={dragItem}
          tableColumn={tableColumn}
          getSettings={getSettings}
          updateFilter={updateFilter}
          setShowPopover={(isOpen) => setShow(isOpen)}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className={styles.plannerHeader_wrapper}>
        <HeaderStar title='Carriers' />
        <div className='d-flex align-items-center gap-3'>
          <Tooltip title='Import from CSV'>
            <CustomButton
              type='secondary'
              leftIcon={<ImportIcon fill={palette.gray700} />}
              styleButton={{ padding: '9px 12px', margin: 0, height: 36 }}
              onClick={() => setOpenImportCsv(true)}
            />
          </Tooltip>
          <Tooltip title='Export CSV'>
            <CustomButton
              type='secondary'
              leftIcon={<ExportIcon fill={palette.gray700} />}
              styleButton={{ padding: '9px 12px', margin: 0, height: 36 }}
              onClick={exportCsv}
            />
          </Tooltip>
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Add Carrier'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px' }}
            onClick={() => setOpenAddCustomer(true)}
            disabled={!permissions.includes(PERMISSIONS.CREATE_CARRIER.name)}
          />
        </div>
        <Modal
          show={openAddCustomer}
          onHide={() => setOpenAddCustomer(false)}
          backdrop='static'
          centered
          id='add-customer-modal'
        >
          <AddCarrier close={() => setOpenAddCustomer(false)} getCarriers={getCarriers} />
        </Modal>
      </div>
      <div className='d-flex align-items-start'>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popOverContent}
          rootClose={show}
          onToggle={setShow}
          show={show}
          defaultShow={false}
          delay={0}
        >
          <div className='top_popover' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
          </div>
        </OverlayTrigger>
        <CarrierSearch
          search={search}
          onChange={setSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearch();
            }
          }}
        />
        <CustomButton
          type='primary'
          title='Search'
          onClick={() => onSearch()}
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '5px 12px', margin: '0 0 0 8px' }}
        />
        <CustomButton
          type='primary'
          title=''
          leftIcon={
            advancedOpen ? (
              <MinusIcon fill='#fff' style={{ margin: 8 }} />
            ) : (
              <PlusIcon fill='#fff' style={{ margin: 8 }} />
            )
          }
          className='ms-3'
          styleButton={{ height: 32, margin: 0 }}
          onClick={() => setAdvancedOpen(!advancedOpen)}
        />

        <DarkOverlay visible={show} styles={{ zIndex: 500 }} />
      </div>
    </>
  );
};
export default CarriersHeader;
