import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Add from 'assets/icons/drivers/add.svg';
import Search from 'common/Search';
import { getErrorMessage } from 'utils/error';
import { months, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { deleteHoliday, getHolidays } from 'Api/Customers';
import Pagination from 'common/Pagination';
import CustomModal from 'common/Modal';
import TableSkeleton from 'common/TableSkeleton';
import ColumnHeader from 'common/ColumnHeader';
import MaterialTableWrapper from '../MaterialTableWrapper';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import CreateHolidayModal from '../CreateHolidayModal/CreateHolidayModal';
import RowActions from './RowActions';
import { SWrapper } from './ManageHolidays.styles';

const ManageHolidayModal = ({ open, onClose, onSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [holidayList, setHolidayList] = useState({ data: [] });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [holidayToEdit, setHolidayToEdit] = useState(null);
  const [openAddHoliday, setOpenAddHoliday] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });

  const getHolidaysList = async () => {
    setLoading(true);
    try {
      const params = {
        query: debouncedSearch || undefined,
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
      };
      const customerHolidays = await getHolidays(params);
      setHolidayList(customerHolidays);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const removeHoliday = async (id) => {
    try {
      await deleteHoliday(id);
      showToaster({ type: 'success', message: 'Holiday has been successfully removed!' });
      getHolidaysList();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    getHolidaysList();
  }, [debouncedSearch]);

  const columns = [
    {
      title: <ColumnHeader title='Holiday Name' />,
      field: 'holiday_name',
      sorting: false,
      headerStyle: {
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      },
      render: (rowData) => {
        return (
          <div className='flex-row d-flex'>
            <span
              style={{
                color: use(palette.gray600, palette.gray200),
                fontWeight: 500,
              }}
            >
              {rowData.holiday_name}
            </span>
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='Holiday Date' />,
      field: 'date',
      sorting: false,
      headerStyle: {
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      },
      render: (rowData) => {
        return (
          <div className='flex-row d-flex'>
            <span
              style={{
                color: use(palette.gray600, palette.gray200),
              }}
            >
              {rowData.day_of_year} of {months[rowData.month_of_year]}
            </span>
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title='Country' />,
      field: 'country_id',
      sorting: false,
      headerStyle: {
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      },
      render: (rowData) => {
        const country = rowData.country.name;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{country}</span>;
      },
    },
    {
      field: '',
      render: (rowData) => (
        <RowActions
          onEdit={() => {
            setHolidayToEdit(rowData);
            setOpenAddHoliday(true);
          }}
          onDelete={() => removeHoliday(rowData.id)}
        />
      ),
    },
  ];

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  return (
    <CustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Manage Holidays'
      $minWidth='800px'
      $maxWidth='800px'
      $minHeight='560px'
      $bgColor={palette.white}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done' }]}
    >
      <SWrapper>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div>
            <Search search={search} onChange={setSearch} />
          </div>

          <PrimaryBtn
            icon={Add}
            style={{ color: use(palette.gray700, palette.gray200) }}
            title='Create Holiday'
            onClick={() => setOpenAddHoliday(true)}
          />
        </div>
        {loading ? (
          <TableSkeleton />
        ) : (
          <MaterialTableWrapper
            columns={columns}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              color: use(palette.gray700, palette.gray200),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
            data={holidayList.data}
            components={{
              Pagination: () =>
                Pagination({
                  data: holidayList,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                  rowsPerPageOptions: [25, 50, 100, 150],
                }),
            }}
          />
        )}
      </SWrapper>
      {!!openAddHoliday && (
        <Modal
          size='sm'
          show={openAddHoliday}
          onHide={() => {
            setHolidayToEdit(null);
            setOpenAddHoliday(false);
          }}
          centered
          id='add-day-modal'
          dialogClassName='modal-50w'
          enforceFocus={false}
          backdropClassName='double-modal-backdrop'
        >
          <CreateHolidayModal
            holiday={holidayToEdit}
            onClose={() => {
              setHolidayToEdit(null);
              setOpenAddHoliday(false);
            }}
            onSuccess={getHolidaysList}
            getCustomerHolidays={onSuccess}
          />
        </Modal>
      )}
    </CustomModal>
  );
};

export default ManageHolidayModal;
