import moment from 'moment';

export const getInitialValues = (settings) => {
  const {
    workers_compensation_and_employers_liability_policy_active,
    workers_compensation_and_employers_liability_policy_id,
    workers_compensation_and_employers_liability_expire_date,
    workers_compensation_and_employers_liability_policy_attach,
  } = settings || {};

  return {
    workers_compensation_and_employers_liability_policy_active:
      !!workers_compensation_and_employers_liability_policy_active,
    workers_compensation_and_employers_liability_policy_id:
      workers_compensation_and_employers_liability_policy_id || '',
    workers_compensation_and_employers_liability_expire_date: workers_compensation_and_employers_liability_expire_date
      ? moment(workers_compensation_and_employers_liability_expire_date).toDate()
      : null,
    workers_compensation_and_employers_liability_no_expire: !workers_compensation_and_employers_liability_expire_date,
    workers_compensation_and_employers_liability_policy_attach:
      workers_compensation_and_employers_liability_policy_attach || null,
  };
};

export const insuranceFields = {
  id: 1,
  title: 'Workers Compensation and Employers Liability',
  policy_type: 'workers_compensation_and_employers_liability',
  active: 'workers_compensation_and_employers_liability_policy_active',
  policy_id: 'workers_compensation_and_employers_liability_policy_id',
  expire_date: 'workers_compensation_and_employers_liability_expire_date',
  no_expire: 'workers_compensation_and_employers_liability_no_expire',
  attachment: 'workers_compensation_and_employers_liability_policy_attach',
};

export const updateInsuranceConverter = (values) => {
  const formData = new FormData();
  const {
    workers_compensation_and_employers_liability_policy_active,
    workers_compensation_and_employers_liability_policy_id,
    workers_compensation_and_employers_liability_expire_date,
    workers_compensation_and_employers_liability_no_expire,
    workers_compensation_and_employers_liability_policy_attach,
  } = values || {};

  formData.append(
    'workers_compensation_and_employers_liability_policy_active',
    Number(workers_compensation_and_employers_liability_policy_active)
  );

  if (workers_compensation_and_employers_liability_policy_active) {
    formData.append(
      'workers_compensation_and_employers_liability_policy_id',
      workers_compensation_and_employers_liability_policy_id || ''
    );
    formData.append(
      'workers_compensation_and_employers_liability_expire_date',
      workers_compensation_and_employers_liability_no_expire ||
        !workers_compensation_and_employers_liability_expire_date
        ? ''
        : moment(workers_compensation_and_employers_liability_expire_date).format('YYYY-MM-DD')
    );
    formData.append(
      'workers_compensation_and_employers_liability_policy_attach',
      workers_compensation_and_employers_liability_policy_attach
    );
  }

  return formData;
};
