import React, { useState, useEffect } from 'react';
import { palette } from 'utils/constants';
import { getDriverStatusCount } from 'Api/Dashboard';
import DoughnutChart from '../../Charts/DoughnutChart';

const labels = {
  0: 'On Shipment',
  1: 'Available',
  2: 'Out of Service',
};

const DriverStatus = () => {
  const [loading, setLoading] = useState(false);
  const [driverStatusCounts, setDriverStatusCounts] = useState(null);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverStatusCount();
      setDriverStatusCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [driverStatusCounts?.on_shipment, driverStatusCounts?.available, driverStatusCounts?.out_of_service],
      backgroundColor: [palette.indigo400, palette.green400, palette.red400],
      borderColor: [palette.indigo400, palette.green400, palette.red400],
      hoverBackgroundColor: [palette.indigo500, palette.green500, palette.red500],
      hoverBorderColor: [palette.indigo500, palette.green500, palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={driverStatusCounts?.total}
        title='Driver Status'
      />
    </div>
  );
};

export default DriverStatus;
