import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import blowSound from 'assets/confetti.mp3';
import logo from 'assets/icons/logo/truckinLogo.png';
import { PusherJs } from 'common/pusher';
import { Typography } from 'components/Typography';
import PublicFooter from 'componentsV2/PublicFooter';
import { palette } from 'utils/constants';
import { getShipmentOffer } from 'Api/PlannerV2';
import { getBrokerageSettings } from 'Api/Carriers';
import Map from './components/Map';
import Header from './components/Header';
import { ScrollableContainer } from './ShipmentBid.styles';

const ShipmentBid = () => {
  const { dot, token, type, shipment_id } = useParams();
  const [company, setCompany] = useState(null);
  const [shipment, setShipment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAnimation, setShowAnimation] = useState(null);
  const ref = useRef(new Audio(blowSound));

  const { id, bid, planner } = shipment || {};

  const getCompanyInfo = async () => {
    try {
      const response = await getBrokerageSettings(dot, token, type === 'contact' ? 'contact' : undefined);
      setCompany(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const playAudio = () => {
    try {
      ref.current.volume = 0.3;
      ref.current.currentTime = 0.2;
      ref.current.play();
      setTimeout(() => {
        ref.current.pause();
        ref.current.currentTime = 0.2;
        ref.current.volume = 0.1;
        ref.current.play();
      }, 250);
    } catch (e) {
      // Do nothing
    }
  };

  const playAnimation = () => {
    playAudio();
    setShowAnimation(Math.random());
    setTimeout(() => {
      setShowAnimation(null);
    }, [4000]);
  };

  const getShipment = async () => {
    try {
      const { data } = await getShipmentOffer(
        { shipment_id },
        { dot, token, type: type === 'contact' ? 'contact' : undefined }
      );
      setShipment(data);

      if (data.bid?.offer_accepted === 3) {
        setTimeout(() => {
          playAnimation();
        }, 1500);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getCompanyInfo();
    getShipment();
  }, []);

  useEffect(() => {
    const channelName = `private-shipment-bid-update.${dot}-${bid?.id}`;
    const channel2Name = `private-public-shipment-offers-update.${dot}-${id}`;

    if (id) {
      const channel = PusherJs.subscribe(channel2Name);
      channel.bind('public-shipment-offers-update', () => {
        getShipment();
      });
    }

    if (bid) {
      const channel = PusherJs.subscribe(channelName);
      channel.bind('shipment-bid-update', () => {
        getShipment();
      });
    }

    return () => {
      if (!shipment) {
        return;
      }

      PusherJs.unsubscribe(channelName);
      PusherJs.unsubscribe(channel2Name);
    };
  }, [shipment]);

  if (!planner) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
        {!loading && (
          <div className='d-flex align-items-center flex-column gap-2'>
            <img src={logo} alt='Truckin Digital' width={100} height={100} />
            <Typography variant='h1' style={{ color: palette.gray700 }}>
              This shipment offer has expired! Please check your email for new offers.
            </Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Header company={company} shipment={shipment} />
      <ScrollableContainer>
        <Map shipment={shipment} getShipment={getShipment} showAnimation={showAnimation} />
      </ScrollableContainer>
      <PublicFooter />
    </div>
  );
};

export default ShipmentBid;
