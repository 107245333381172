import React from 'react';
import { ReactComponent as Jcb } from 'assets/icons/jcb.svg';
import { ReactComponent as Visa } from 'assets/icons/visa.svg';
import { ReactComponent as Amex } from 'assets/icons/amex.svg';
import { ReactComponent as Diners } from 'assets/icons/diners.svg';
import { ReactComponent as Discover } from 'assets/icons/discover.svg';
import { ReactComponent as Unionpay } from 'assets/icons/unionpay.svg';
import { ReactComponent as Mastercard } from 'assets/icons/mastercard.svg';

export const paymentMethodLabel = {
  'credit-card': 'CREDIT CARD',
  ach: 'ACH',
};

export const creditCard = {
  amex: <Amex />,
  diners: <Diners />,
  discover: <Discover />,
  jcb: <Jcb />,
  mastercard: <Mastercard />,
  visa: <Visa />,
  unionpay: <Unionpay />,
};
