export const getPaymentTermTitle = (paymentTerm) => {
  if (!paymentTerm) {
    return '-';
  }

  const discount = Number(paymentTerm?.discount) ? `- Discount ${paymentTerm?.discount}%` : '';

  const title =
    paymentTerm?.default && paymentTerm?.title === 'Days' ? `${paymentTerm?.net_day} Days` : paymentTerm?.title;

  return `${title} - Net ${paymentTerm?.net_day}${discount ? ` ${discount}` : ''}`;
};
