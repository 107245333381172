import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import { palette } from 'utils/constants';

export const SLoadingWrapper = styled.div`
  min-height: 300px;
  position: relative;

  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

export const SPageWrapper = styled.div`
  padding-top: 14px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .menu-left-section {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .menu-left-section__pay-period {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .MuiChip-root {
        margin-top: 2px;
      }
    }
  }
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell > div {
    visibility: hidden;
    transition: visibility 0.1s;
  }

  :hover {
    background-color: ${palette.gray0};

    .actions-cell > div {
      visibility: visible;
    }
  }
`;
