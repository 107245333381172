import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './footer.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import packageJson from '../../../package.json';

const Footer = () => {
  const { theme, use } = useTheme();
  const [color, setColor] = useState(use(palette.gray400, palette.gray500));

  useEffect(() => {
    setColor(use(palette.gray400, palette.gray500));
  }, [theme]);

  const onVersionClick = () => {
    window.open('https://support.truckindigital.com/support/discussions/forums/154000331204', '_blank');
  };

  return (
    <div className='footer_custom' style={{ backgroundColor: use('rgba(255, 255, 255, 0.75)', palette.dark800) }}>
      <div
        className='footer_container'
        style={{
          marginLeft: !theme.condensed ? 210 : 13,
          width: !theme.condensed ? 'calc(100% - 220px)' : 'calc(100% - 23px)',
        }}
      >
        <div className='footer-right'>
          <p className='header-caption-c1' style={{ color }}>
            Truckin Systems LLC.
          </p>
          <div className='vertical-divide-footer' />
          <p className='header-caption-c1' style={{ color }}>
            © 2020 - {moment().year()}
          </p>
          <div className='vertical-divide-footer' />
          <p className='header-caption-c1' style={{ color }}>
            All rights reserved
          </p>
          <div className='vertical-divide-footer' />
          <p className='header-caption-c1 pointer' style={{ color }} onClick={onVersionClick}>
            v{packageJson.version}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
