import React from 'react';

const SMSModal = ({ setOpen }) => {
  return (
    <div className='modalContainer add-new-channel'>
      <div className='addon-modal-area' style={{ width: '700px' }}>
        <div
          className='modal-footer-custom'
          style={{
            justifyContent: 'space-between',
            borderRadius: '8px 8px 0px 0px ',
          }}
        >
          <p>New Message</p>
        </div>
        <div className='modal-body-custom add-new-channel-body '>
          <p>To</p>
          <div className='sendto-input'>
            <div className='select-box' onClick={() => null}>
              {[] && (
                <ul>
                  {[].map((l) => (
                    <li key={l} onClick={() => null}>
                      {l}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <input type='text' placeholder={`Search ${123}`} name='search' id='search' value='' onChange={() => null} />
          </div>
          <p>Write Message</p>
          <textarea
            name=''
            id=''
            placeholder='Type Message'
            cols='30'
            rows='5'
            className='type-message'
            value=''
            onChange={() => null}
          />
        </div>
        <div className='modal-footer-custom' style={{ justifyContent: 'space-between' }}>
          <div className='addon-footer-btn'>
            <button className='addon-btn ' style={{ marginRight: '30px' }} onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button
              className='addon-btn '
              style={{
                background: '#4F5AED',
                color: '#ffff',
                border: 'none',
              }}
              onClick={() => null}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSModal;
