import React from 'react';
import Tab from '@mui/material/Tab';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { palette } from 'utils/constants';
import { SColorLabel, STabs } from './Tabs.styles';

const Tabs = ({ tabs, activeTab, handleTabChange, onEdit }) => {
  return (
    <STabs value={activeTab} onChange={(e, newValue) => handleTabChange(newValue)}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.key || tab.value}
          id={`tab-${index}`}
          value={tab.value}
          label={
            tab.color ? (
              <>
                <SColorLabel $color={tab.color} />
                {tab.label}
              </>
            ) : (
              tab.label
            )
          }
          iconPosition='end'
          disableRipple
          icon={
            !!tab.count || tab.count === 0 ? (
              <span>
                {tab.count}
                {!tab.system && (
                  <div
                    className='tab-edit-wrapper'
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onEdit(tab);
                    }}
                  >
                    <EditIcon width={9} height={9} fill={palette.white} />
                  </div>
                )}
              </span>
            ) : undefined
          }
        />
      ))}
    </STabs>
  );
};

export default Tabs;
