import { addDays, endOfDay, startOfDay } from 'date-fns';
import { palette } from 'utils/constants';

export const TYPE_OPTIONS = [
  { type: 1, name: 'Company Driver(s)', value: 'company_drivers' },
  { type: 2, name: 'Contractor Driver(s)', value: 'contractor_drivers' },
  { type: 4, name: 'Staff', value: 'staff' },
  { type: 3, name: 'Vehicle', value: 'vehicle' },
];

export const TYPE_MAPPER = {
  Capture: { color: palette.green500, bgColor: palette.green0 },
  Refund: { color: palette.red500, bgColor: palette.red0 },
  Declined: { color: palette.red500, bgColor: palette.red0 },
  'Cash Advance': { color: palette.orange500, bgColor: palette.orange0 },
  SUCCEEDED: { color: palette.orange500, bgColor: palette.orange0 },
};

export const INITIAL_DATE_RANGE = {
  start: startOfDay(addDays(new Date(), -31)),
  end: endOfDay(new Date()),
};

export const initialFilters = {
  tab: '',
  page: 1,
  page_size: 1000,
  category: [],
  selected_user: null,
  selected_vehicle: null,
  selected_state: null,
};

export const getPdfData = (standardizedStr, mapper) => {
  const str = standardizedStr.replace(/;/g, ',');
  const lines = str.split('\n');

  const headers = lines[0].split(',').map((heading) => mapper[heading.trim()] || heading.trim());

  const rows = lines.slice(1).filter((val) => val);

  return rows.map((row) => {
    const values = row.split(',');
    const rowObject = {};
    let columnIndex = 0;
    let tempValue = '';

    for (const value of values) {
      if (tempValue) {
        tempValue += `,${value}`;
      } else {
        rowObject[headers[columnIndex]] = value;
        columnIndex++;
      }
    }

    return rowObject;
  });
};
