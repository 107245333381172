export const initialValues = {
  card_provider: 'Truckin Digital',
  card_holders: [{ row_id: Date.now() }],
};

export const cardInfo = [
  'You can use this card to make purchases virtually anywhere! Our VISA card is accepted anywhere.',
  'Set security features such as ATM, PIN Code, Vehicle ID, Deduct on payroll and fuel fraud prevention.',
  'Assign cards to staff or drivers and set usage limits.',
  'Set card mode type: Use for company expenses or for payroll. Pay your drivers on their card instantly.',
  'Fund your cards same-day when you need to distribute cash on the road.',
];

export const requestBodyConverter = (values) => {
  return {
    provider_id: 'stripe',
    cards: values.card_holders.map((item) => {
      return {
        cardholder: item.id,
        name: item.card_holder_name,
        address: {
          line1: item.address || null,
          line2: item.address2 || null,
          country: item.country?.sortname || null,
          state: item.state?.name || null,
          city: item.city?.name || null,
          postal_code: item.zipcode || null,
        },
        service: item.shipping_method.value,
        type: 'bulk',
        quantity: Number(item.quantity),
      };
    }),
  };
};
