import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import Search from 'common/Search';
import Loader from 'common/Loader';
import DateRangePicker from 'common/DateRangePicker';
import NoRecords from 'common/NoRecords';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getVendorRecurringBills } from 'Api/Payables';
import AddBill from 'pages/Accounting/Payables/components/AddBill';
import { STableSection } from 'pages/Accounting/Payables/Payables.styles';

import TableFilters from '../../shared/TableFilters';
import { initialFilters } from '../../TableSection.data';
import AccountsList from './components/AccountsList';
import Transactions from './components/Transactions';

const RecurringBills = ({ vendor, refreshStats = () => null }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get('vendorId');
  const [openAddBill, setOpenAddBill] = useState(false);
  const [recurringBills, setRecurringBills] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const debouncedSearch = useDebounce(search, 500);

  const getRecurringBillsData = async (noChangeTab, navigateToVendorId) => {
    try {
      const params = {
        from: dateRange.strat ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        all: debouncedSearch || undefined,
        vendor_id: vendor?.id || selectedFilters.vendor?.id || undefined,
        account_name: selectedFilters.account_name || undefined,
        account_number: selectedFilters.account_number || undefined,
        itemsPerPage: 1000,
      };
      const { data } = await getVendorRecurringBills(params);
      setRecurringBills(!vendor ? data : data.find((el) => el.id === vendor.id));

      if (vendor) {
        setSelectedAccount(data.find((i) => i.id === vendor?.id) || null);
      } else {
        if (vendorId || navigateToVendorId) {
          setSelectedAccount(data.find((i) => i.id === Number(vendorId || navigateToVendorId)));
          searchParams.delete('vendorId');
          setSearchParams(searchParams);
        } else {
          setSelectedAccount(noChangeTab ? data.find((i) => i.id === selectedAccount?.id) || null : data[0] || null);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onAddEditSuccess = (isRecurring, vendorId) => {
    getRecurringBillsData(true, isRecurring ? vendorId : null);
    refreshStats();
  };

  const onDeleteSuccess = () => {
    getRecurringBillsData(selectedAccount?.recurring_bills?.length > 1);
    refreshStats;
  };

  useEffect(() => {
    setLoading(true);
    getRecurringBillsData();
  }, [dateRange, selectedFilters, debouncedSearch]);

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-3 gap-2'>
        {vendor ? (
          <div className='d-flex align-items-center gap-3'>
            <DateRangePicker
              top='-108px'
              initialRangeName='All Time'
              dateRange={dateRange}
              setDateRange={setDateRange}
              type='allTime'
            />
            <Search search={search} onChange={setSearch} className='search-input' />
          </div>
        ) : (
          <TableFilters
            dateRange={dateRange}
            setDateRange={setDateRange}
            search={search}
            setSearch={setSearch}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        )}
        <div className='d-flex gap-3'>
          <CustomButton
            type='secondary'
            title='Print Report'
            leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => null}
          />
          <CustomButton
            type='primary'
            title='Add Bill'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
            onClick={() => setOpenAddBill(true)}
          />
        </div>
      </div>
      <STableSection>
        {!vendor && (
          <div className='left-section'>
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <>
                <AccountsList data={recurringBills} selected={selectedAccount} updateSelected={setSelectedAccount} />
                {!recurringBills?.length && <NoRecords />}
              </>
            )}
          </div>
        )}
        <div className={!vendor ? 'right-section' : 'w-100'}>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <>
              <Transactions
                vendor={vendor}
                data={selectedAccount}
                onEditSuccess={onAddEditSuccess}
                onDeleteSuccess={onDeleteSuccess}
              />
              {!selectedAccount?.recurring_bills?.length && <NoRecords />}
            </>
          )}
        </div>
      </STableSection>
      {openAddBill && (
        <AddBill
          open={openAddBill}
          onClose={() => setOpenAddBill(false)}
          onSuccess={onAddEditSuccess}
          vendor={vendor}
        />
      )}
    </div>
  );
};

export default RecurringBills;
