import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../AddDriver/AddDriverStyle.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import BasicDetails from './BasicDetailsOwnerOperator';
import Compensation from './CompensationOwnerOperator';
import Document from './DocumentOwnerOperator';
import NewDriverAdded from '../AddDriver/DriverEditedModal';
import './AddOwnerOperator.css';
import CustomizedSnackbars from '../../toast/Toast';

const AddOwnerOperator = ({ save, close, module, openStep, currentDriverId }) => {
  const { use } = useTheme();
  const [status, setStatus] = useState(1);
  const [formData, setFormData] = useState({});
  const [modalShow, setModalShow] = React.useState(true);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [stepsDone, setStepsDone] = useState({
    isBasicDetailsSaved: false,
    isCompensation: false,
    isDocument: false,
  });
  const [drvier, setDrvier] = useState({
    basicDetails: {},
    compensation: {},
    document: {},
    basicDetailsStates: {},
  });
  const statusHandler = (status) => {
    setStatus(status);
  };
  const nextStep = (formValue, message) => {
    if (status === 1) {
      const { formikData, stateData, operator } = formValue || {};
      setDrvier({
        ...drvier,
        basicDetails: formikData,
        basicDetailsStates: stateData,
        operatorId: operator?.id || null,
        operatorName: operator?.owner_operator_name || null,
        email_id: operator?.email_id || null,
      });
    }
    if (status === 2) {
      const { formikData, CompId } = formValue || {};

      setDrvier({
        ...drvier,
        compensation: formikData,
        CompId,
      });
    }
    if (status === 3) {
      setDrvier({
        ...drvier,
        document: formValue,
      });
    }
    if (status >= 3) {
      document.getElementById('driver-container').scrollLeft += 400;
    }
    setStatus(status + 1);
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };
  const prevStep = () => {
    if (status <= 3) {
      document.getElementById('driver-container').scrollLeft -= 400;
    }
    setStatus(status - 1);
  };
  const Cancel = () => {
    close();
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  useEffect(() => {
    if (openStep) {
      setStatus(openStep);
    }
  }, []);

  return (
    <>
      {status !== 4 && (
        <div
          className='main-modal-wrapper'
          style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative' }}
        >
          <div style={{ position: 'absolute', top: '0.7rem', margin: '0 0 20px' }}>
            <p className='add-driver-modal-text' style={{ color: use('#171c26', palette.gray200) }}>
              Add Owner Operator
            </p>
          </div>
          <Modal.Header className='add-driver-modal-header driver-style-wrap'>
            <div className='steps-wrapper step-wrapper-owner-operator' id='driver-container'>
              <div
                className={
                  status >= 1 ? 'step-heading-active step-heading-active-owner-operator' : 'step-heading-owner-operator'
                }
              >
                {status > 1 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Basic Details</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={!(status >= 1)}
                      checked={status >= 1}
                      onClick={() => statusHandler(1)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Basic Details</span>
                  </div>
                )}

                <div className='progress progress-owner-opeartor'>
                  <div
                    className={status === 1 ? 'incomplete-progressbar' : status > 1 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    // style={{}}
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div
                className={
                  status >= 2 ? 'step-heading-active step-heading-active-owner-operator' : 'step-heading-owner-operator'
                }
              >
                {status > 2 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Compensation</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 2}
                      checked={status === 2}
                      onClick={() => statusHandler(2)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Compensation</span>
                  </div>
                )}

                <div className='progress progress-owner-opeartor'>
                  <div
                    className={status === 2 ? 'incomplete-progressbar' : status > 2 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div
                className={
                  status >= 3 ? 'step-heading-active step-heading-active-owner-operator' : 'step-heading-owner-operator'
                }
              >
                {status > 3 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Document</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 3}
                      checked={status === 3}
                      onClick={() => statusHandler(3)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Document</span>
                  </div>
                )}

                <div className='progress progress-owner-opeartor'>
                  <div
                    className={status === 3 ? 'incomplete-progressbar' : status > 3 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
            </div>
          </Modal.Header>
        </div>
      )}
      {status === 1 && (
        <div>
          <BasicDetails
            nextStep={nextStep}
            prevStep={prevStep}
            Cancel={Cancel}
            handleFormData={handleInputData}
            values={formData}
            basicDetails={drvier.basicDetails}
            stepsDone={stepsDone.isBasicDetailsSaved}
            showError={showError}
            operatorId={drvier.operatorId}
            save={save}
            basicDetailsStates={drvier.basicDetailsStates}
            module={module}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          <Compensation
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            compensation={drvier.compensation}
            stepsDone={stepsDone.isCompensation}
            setStepsDone={setStepsDone}
            operatorId={drvier.operatorId || currentDriverId}
            email_id={drvier.email_id}
            showError={showError}
            CompId={drvier.CompId}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 3 && (
        <div>
          <Document
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isDocument}
            setStepsDone={setStepsDone}
            operatorId={drvier.operatorId || currentDriverId}
            operatorName={drvier.operatorName}
            document={drvier.document}
            save={save}
            showError={showError}
            module={module}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 4 && (
        <div className={modalShow ? 'fade modal-backdrop show' : 'modal'}>
          {module ? (
            <NewDriverAdded
              show={modalShow}
              onHide={() => {
                setModalShow(false);
                close();
                save();
              }}
              name='Profile Created'
              desc='Owner Operator has been added successfully!'
            />
          ) : (
            <NewDriverAdded
              show={modalShow}
              onHide={() => {
                setModalShow(false);
                close();
              }}
              name='Profile Created'
              desc='Owner Operator has been added successfully!'
            />
          )}
        </div>
      )}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default AddOwnerOperator;
