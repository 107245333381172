import styled from 'styled-components';

export const SInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 33px;
  }
`;
