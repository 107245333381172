export const initialValues = {
  payperiod_type: null,
  week_day: null,
  set_payment_for: null,
};

export const weekDays = [
  { value: 1, name: 'Monday' },
  { value: 2, name: 'Tuesday' },
  { value: 3, name: 'Wednesday' },
  { value: 4, name: 'Thursday' },
  { value: 5, name: 'Friday' },
  { value: 6, name: 'Saturday' },
  { value: 0, name: 'Sunday' },
];
export const paymentForOptions = [
  { value: 1, name: '1 day after settlement is generated' },
  { value: 2, name: '2 days after settlement is generated' },
  { value: 3, name: '3 days after settlement is generated' },
  { value: 4, name: '4 days after settlement is generated' },
  { value: 5, name: '5 days after settlement is generated' },
  { value: 6, name: '6 days after settlement is generated' },
  { value: 7, name: '1 week after settlement is generated' },
  { value: 14, name: '2 weeks after settlement is generated' },
  { value: 21, name: '3 weeks after settlement is generated' },
  { value: 28, name: '4 weeks after settlement is generated' },
];
