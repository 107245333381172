import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { CreateCustomerContactBook, getStopPointType, UpdateCustomerContactBook } from 'Api/Planner';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { deleteCustomersContactBook } from 'Api/Customers';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomizedSnackbars from 'components/toast/Toast';

import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import Input from 'common/Input';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import Tooltip from 'common/Tooltip';
import CustomInput from '../../helpers/CustomInput';
import CustomSelect from '../../ShipmentStops/helpers/CustomSelect';
import { AppendToFormData } from '../../ShipmentStops/helpers/constants';
import sm from './ContactEditModal.module.css';

const ContactEditModal = ({
  show,
  onChangeShow,
  contactData,
  updateContactData,
  selectedBillTo,
  activeBillChargeIndex,
  getContacts,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const defaultValue = {
    name: '',
    email: '',
    phone_number: '',
    extension: '',
    contact_type_id: '',
    invoicing: false,
    portal_access: false,
    sms_updates: false,
    email_updates: false,
  };
  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Name', width: '15%', required: true },
      { key: 2, label: 'Email', width: '15%', required: true },
      { key: 3, label: 'Phone Number', width: '15%', required: true },
      { key: 4, label: 'EXT', width: '8%', required: false },
      { key: 5, label: 'Type', width: '15%', required: true },
      { key: 6, label: 'Invoicing', width: '8%', required: true },
      { key: 7, label: 'Portal Access', width: '8%', required: true },
      { key: 8, label: 'Email Updates', width: '8%', required: true },
      { key: 9, label: 'SMS Updates', width: '8%', required: true },
      { key: 10, label: '', width: '33px', required: false },
    ];
  }, []);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [typeData, setTypeData] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [changedIndexes, setChangedIndexes] = useState([]);

  const onDeleteRow = (el, index, arrayHelpers) => {
    if (el?.id) {
      deleteCustomersContactBook(el.id).then(() => {
        arrayHelpers.remove(index);
        setShowMessage({ ...showMessage, message: 'Contact deleted successfully', visible: true });
      });
    } else {
      arrayHelpers.remove(index);
      if (changedIndexes.includes(index)) {
        setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
      }
    }
  };

  const getTypeData = () => {
    getStopPointType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          setTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const updateContactUpdate = (el, index) => {
    const updateData = {
      customer_id: el.customer_id,
      contact_name: el.name,
      phone_number: el.phone_number,
      extension: el.extension,
      contact_type_id: el.contact_type_id,
      invoicing: el.invoicing ? 1 : 0,
      portal_access: el.portal_access ? 1 : 0,
      email_updates: el.email_updates ? 1 : 0,
      sms_updates: el.sms_updates ? 1 : 0,
      contact_email: el.email,
    };

    UpdateCustomerContactBook({ id: el.id, data: updateData })
      .then((res) => {
        if (res && res.data && +res.status === 200) {
          setShowMessage({ ...showMessage, message: 'Contact updated successfully', visible: true });
          const newData = contactData?.[activeBillChargeIndex]?.filter((item) => item.id !== el.id);
          updateContactData({ ...contactData, [activeBillChargeIndex]: [...newData, res.data.data[0]] });
          if (changedIndexes.includes(index)) {
            setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
          }
        }
      })
      .catch((e) => {
        const message = getErrorMessage(e);

        if (message?.includes('must be a valid email address')) {
          showToaster({ type: 'error', message: 'Please enter a valid email address!' });
          return;
        }

        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  };

  const createContactUpdate = (el, values, index, arrayHelpers) => {
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, index);
    const map = mapper(updateData, index);
    Object.keys(map).map((key) => formData.append(key, map[key]));
    CreateCustomerContactBook(formData)
      .then((res) => {
        if (res?.status === 200) {
          const data = mapperResponseCreate(res.data.data[0]);
          updateContactData({ ...contactData, [activeBillChargeIndex]: [...contactData[activeBillChargeIndex], data] });
          arrayHelpers.replace(index, { ...data, id: data.id });
          setShowMessage({ ...showMessage, message: 'Contact added successfully!', visible: true });
          if (changedIndexes.includes(index)) {
            setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
          }
        }
      })
      .catch((e) => {
        const message = getErrorMessage(e);

        if (message?.includes('must be a valid email address')) {
          showToaster({ type: 'error', message: 'Please enter a valid email address!' });
          return;
        }

        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  };

  const mapperResponseCreate = (data) => {
    const lastData = {};
    const map = {
      contact_email: 'email',
      contact_name: 'name',
      contact_type_id: 'contact_type_id',
      created_at: 'created_at',
      customer_id: 'customer_id',
      email_updates: 'email_updates',
      phone_number: 'phone_number',
      portal_access: 'portal_access',
      invoicing: 'invoicing',
      extension: 'extension',
      sms_updates: 'sms_updates',
      updated_at: 'updated_at',
    };

    Object.keys(data).forEach((key) => {
      const value = data[key];
      const name = map[key];
      if (name) {
        if (key === 'portal_access' || key === 'sms_updates' || key === 'email_updates' || key === 'invoicing') {
          lastData[name] = +value === 1;
        } else {
          lastData[name] = value;
        }
      } else {
        if (key === 'portal_access' || key === 'sms_updates' || key === 'email_updates' || key === 'invoicing') {
          lastData[key] = +value === 1;
        } else {
          lastData[key] = value;
        }
      }
    });
    return lastData;
  };

  const mapper = (obj, index) => {
    const newData = {
      customer_id: selectedBillTo[activeBillChargeIndex].id,
    };
    const map = {
      [`contact_books[${index}]email_updates`]: obj[`contact_books[${index}]email_updates`] ? '1' : '0',
      [`contact_books[${index}]portal_access`]: obj[`contact_books[${index}]portal_access`] ? '1' : '0',
      [`contact_books[${index}]sms_updates`]: obj[`contact_books[${index}]sms_updates`] ? '1' : '0',
      [`contact_books[${index}]invoicing`]: obj[`contact_books[${index}]invoicing`] ? '1' : '0',
    };
    const namesMap = {
      [`contact_books[${index}]sms_updates`]: [`contact_books[${index}][sms_updates]`],
      [`contact_books[${index}]email_updates`]: [`contact_books[${index}][email_updates]`],
      [`contact_books[${index}]portal_access`]: [`contact_books[${index}][portal_access]`],
      [`contact_books[${index}]name`]: [`contact_books[${index}][contact_name]`],
      [`contact_books[${index}]email`]: [`contact_books[${index}][contact_email]`],
      [`contact_books[${index}]phone_number`]: [`contact_books[${index}][phone_number]`],
      [`contact_books[${index}]extension`]: [`contact_books[${index}][extension]`],
      [`contact_books[${index}]contact_type_id`]: [`contact_books[${index}][contact_type_id]`],
    };

    Object.keys(obj).forEach((key) => {
      const name = namesMap[key];
      if (map[key]) {
        newData[name] = map[key];
      } else {
        newData[name] = obj[key];
      }
    });

    return newData;
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
    if (!isValidEmail.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '90%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = useMemo(() => {
    const contactFormData = contactData?.[activeBillChargeIndex]?.map((el) => {
      return {
        name: el?.contact_name,
        email: el?.contact_email,
        phone_number: el?.phone_number,
        contact_type_id: el?.contact_type_id,
        extension: el?.extension,
        invoicing: el?.invoicing === 1,
        portal_access: el?.portal_access === 1,
        email_updates: el?.email_updates === 1,
        sms_updates: el?.sms_updates === 1,
        customer_id: el?.customer_id,
        id: el.id,
      };
    });
    if (!contactFormData) {
      return { contact_books: [{ ...defaultValue }] };
    }
    return { contact_books: [...contactFormData, { ...defaultValue }] };
  }, [contactData, activeBillChargeIndex]);

  const onUpdate = (el, index, values, errors, getFieldHelpers, arrayHelpers) => {
    const name = getFieldHelpers(`contact_books[${index}][name]`);
    const email = getFieldHelpers(`contact_books[${index}][email]`);
    const phone_number = getFieldHelpers(`contact_books[${index}][phone_number]`);
    const contact_type_id = getFieldHelpers(`contact_books[${index}][contact_type_id]`);

    name.setTouched('Required');
    email.setTouched('Required');
    phone_number.setTouched('Required');
    contact_type_id.setTouched('Required');
    if (
      values.contact_books?.[index].name?.trim(' ') !== '' &&
      values.contact_books?.[index].email?.trim(' ') !== '' &&
      values.contact_books?.[index].phone_number !== '' &&
      values.contact_books?.[index].contact_type_id !== ''
    ) {
      if (el?.id) {
        updateContactUpdate(el, index);
      } else {
        createContactUpdate(el, values, index, arrayHelpers);
      }
    }
  };

  const onCloseModal = () => {
    onChangeShow(false);
    setShowMessage({ message: '', visible: false, type: 'success' });
    getContacts(selectedBillTo[activeBillChargeIndex].id, activeBillChargeIndex);
    setChangedIndexes([]);
  };

  const handleSelectChange = (index) => {
    if (!changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const handleFieldChange = (index, fieldName, values, setChangedIndexes, changedIndexes) => (event) => {
    const newValue = event.target.value;
    const oldValue = values.contact_books[index][fieldName];
    const isChanged = newValue !== oldValue;

    if (isChanged && !changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    } else if (!isChanged && changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
  };

  useEffect(() => {
    setHasChanges(!!changedIndexes.length);
  }, [changedIndexes]);

  useEffect(() => {
    getTypeData();
  }, []);

  return (
    <div>
      {show && (
        <Modal
          showModal={show}
          onHide={onCloseModal}
          headerTitle='Update Contact Book'
          $width='94vw'
          $minWidth='1000px'
          $height='auto'
          $bodyMaxHeight='calc(100vh - 184px)'
          backdropClassName='double-modal-backdrop'
          backdrop='static'
          styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
          buttons={[
            {
              key: 'close',
              type: 'secondary',
              title: 'Cancel',
            },
            ...(!hasChanges
              ? [
                  {
                    key: 'close',
                    type: 'primary',
                    title: 'Done',
                  },
                ]
              : []),
          ]}
        >
          <div>
            <div style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
              {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}

              <div className={sm.body}>
                <Formik initialValues={initialValue}>
                  {({ values, getFieldHelpers, errors, setFieldValue }) => (
                    <Form>
                      <FieldArray
                        name='contact_books'
                        render={(arrayHelpers) => {
                          return (
                            <div>
                              <div className={sm.row}>
                                {titlesOption.map((el) => {
                                  return (
                                    <Typography
                                      key={el?.label}
                                      variant='s2'
                                      style={{ width: el.width, whiteSpace: 'nowrap' }}
                                    >
                                      {el.label}
                                      {el.required && <span className={sm.required_system}>*</span>}
                                    </Typography>
                                  );
                                })}
                              </div>

                              {values.contact_books.map((el, index) => {
                                const namePrefix = `contact_books[${index}]`;
                                return (
                                  <div
                                    key={namePrefix}
                                    className={[sm.row, sm.line].join(' ')}
                                    style={{ marginTop: 0 }}
                                  >
                                    <div className={sm.field_wrapper1}>
                                      <Field
                                        name={`${namePrefix}[name]`}
                                        component={CustomInput}
                                        style={{ paddingLeft: 10, width: '90%' }}
                                        labelStyle={styles.labelStyle}
                                        validate={validateStopPointInput}
                                        className={sm.input_basic_details}
                                        onChange={handleFieldChange(
                                          index,
                                          'name',
                                          values,
                                          setChangedIndexes,
                                          changedIndexes
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`${namePrefix}[name]`}
                                        render={(error) => (
                                          <Typography variant='c2' style={styles.error}>
                                            {error}
                                          </Typography>
                                        )}
                                      />
                                    </div>

                                    <div className={sm.field_wrapper2}>
                                      <Field
                                        name={`${namePrefix}[email]`}
                                        component={CustomInput}
                                        style={{ paddingLeft: 10, width: '90%' }}
                                        labelStyle={styles.labelStyle}
                                        validate={validateEmail}
                                        className={sm.input_basic_details}
                                        onChange={handleFieldChange(
                                          index,
                                          'email',
                                          values,
                                          setChangedIndexes,
                                          changedIndexes
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`${namePrefix}[email]`}
                                        render={(error) => (
                                          <Typography variant='c2' style={styles.error}>
                                            {error}
                                          </Typography>
                                        )}
                                      />
                                    </div>

                                    <div className={sm.field_wrapper3}>
                                      <Field
                                        name={`${namePrefix}[phone_number]`}
                                        inputStyle={{ width: '90%' }}
                                        labelStyle={{ marginTop: 0 }}
                                        countryCodeEditable={false}
                                        component={CustomPhoneInput}
                                        validate={validateStopPointInput}
                                      />
                                      <ErrorMessage
                                        name={`${namePrefix}[phone_number]`}
                                        render={(error) => (
                                          <Typography variant='c2' style={styles.error}>
                                            {error}
                                          </Typography>
                                        )}
                                      />
                                    </div>

                                    <div className={sm.field_wrapper4}>
                                      <Input
                                        width='90%'
                                        type='number'
                                        name={`${namePrefix}[extension]`}
                                        value={values.contact_books?.[index]?.extension}
                                        onChange={(e) => {
                                          if (Number(e.target.value) > 999) {
                                            return;
                                          }
                                          setFieldValue(`${namePrefix}[extension]`, e.target.value);
                                          handleFieldChange(
                                            index,
                                            'extension',
                                            values,
                                            setChangedIndexes,
                                            changedIndexes
                                          )(e);
                                        }}
                                        onKeyDown={(e) => {
                                          blockNotNumberChars(e);
                                          blockNonPositiveNumbers(e);
                                        }}
                                      />
                                    </div>

                                    <div className={sm.field_wrapper5}>
                                      <div>
                                        <Field
                                          name={`${namePrefix}[contact_type_id]`}
                                          options={typeData}
                                          styles={styles.type}
                                          component={CustomSelect}
                                          menuStyles={styles.fullWidthPercent}
                                          validate={validateStopPointInput}
                                          onChange={() => {
                                            handleSelectChange(index);
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`${namePrefix}[contact_type_id]`}
                                          render={(error) => (
                                            <Typography variant='c2' style={styles.error}>
                                              {error}
                                            </Typography>
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div className={sm.field_wrapper6}>
                                      <Field name={`${namePrefix}[invoicing]`}>
                                        {({ field }) => (
                                          <CustomCheckbox
                                            name={`${namePrefix}[invoicing]`}
                                            field={field}
                                            type='switch'
                                            withinForm
                                            checked={!!field.value}
                                            onChange={() => {
                                              handleSelectChange(index);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>

                                    <div className={sm.field_wrapper6}>
                                      <Tooltip title='This feature is not available'>
                                        <span>
                                          <Field name={`${namePrefix}[portal_access]`}>
                                            {({ field }) => (
                                              <CustomCheckbox
                                                name={`${namePrefix}[portal_access]`}
                                                field={field}
                                                type='switch'
                                                withinForm
                                                checked={!!field.value}
                                                onChange={() => {
                                                  handleSelectChange(index);
                                                }}
                                                disabled
                                              />
                                            )}
                                          </Field>
                                        </span>
                                      </Tooltip>
                                    </div>

                                    <div className={sm.field_wrapper6}>
                                      <Field name={`${namePrefix}[email_updates]`}>
                                        {({ field }) => (
                                          <CustomCheckbox
                                            name={`${namePrefix}[email_updates]`}
                                            field={field}
                                            type='switch'
                                            withinForm
                                            checked={!!field.value}
                                            onChange={() => {
                                              handleSelectChange(index);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>

                                    <div className={sm.field_wrapper6}>
                                      <Field name={`${namePrefix}[sms_updates]`}>
                                        {({ field }) => (
                                          <CustomCheckbox
                                            name={`${namePrefix}[sms_updates]`}
                                            field={field}
                                            type='switch'
                                            withinForm
                                            checked={!!field.value}
                                            onChange={() => {
                                              handleSelectChange(index);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>

                                    {values.contact_books.length > 1 ? (
                                      <CloseIcon
                                        width={12}
                                        height={12}
                                        fill={palette.indigo500}
                                        style={styles.deleteIcon}
                                        onClick={() => onDeleteRow(el, index, arrayHelpers)}
                                      />
                                    ) : (
                                      <span style={{ width: 28 }} />
                                    )}
                                    {!changedIndexes.includes(index) ? (
                                      <TickIcon
                                        fill={use(palette.green500, palette.white)}
                                        style={{ width: 33, margin: '12px 0 0 0' }}
                                      />
                                    ) : (
                                      <CustomButton
                                        type='primary'
                                        title=''
                                        styleButton={{
                                          padding: '6px 12px',
                                          margin: '6px 0 0 0',
                                        }}
                                        styleTitle={{ fontSize: 14 }}
                                        onClick={() =>
                                          onUpdate(el, index, values, errors, getFieldHelpers, arrayHelpers)
                                        }
                                        leftIcon={
                                          <TickIcon
                                            fill={use(palette.white, palette.white)}
                                            style={{ width: 9, height: 9 }}
                                          />
                                        }
                                        buttonProps={{
                                          type: 'submit',
                                        }}
                                      />
                                    )}
                                  </div>
                                );
                              })}

                              <CustomButton
                                type='secondary'
                                title='Add Another'
                                styleTitle={styles.addAnotherTitle}
                                styleButton={styles.addAnotherButton}
                                onClick={() => arrayHelpers.push({ ...defaultValue })}
                                leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                              />
                            </div>
                          );
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ContactEditModal;
