import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { Typography } from 'components/Typography';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { palette } from 'utils/constants';
import { getVehicles } from 'Api/CompanySettings';
import UpdateSpeed from './UpdateSpeed';
import { SWrapper, STable, STableRow, SActions } from './SpeedDistanceTime.styles';

const SpeedDistanceTime = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const [vehicles, setVehicles] = useState([]);
  const [dataToUpdate, setDataToUpdate] = useState(null);

  const getVehicleList = async () => {
    try {
      const { data } = await getVehicles();
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        hideSave
        title='Speed / Distance / Time'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={() => onSaveAndNext('saveAndNext')}
      />
      <div />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>EQUIPMENT TYPE</th>
            <th>MPH</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {vehicles.map((item) => (
            <STableRow key={item.id}>
              <td>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.title || '-'}
                </Typography>
              </td>
              <td>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.mph || '-'}
                </Typography>
              </td>
              <td className='actions-cell'>
                <SActions>
                  <span className='action-wrapper' onClick={() => setDataToUpdate(item)}>
                    <EditIcon width={13} height={13} fill={palette.indigo500} />
                  </span>
                </SActions>
              </td>
            </STableRow>
          ))}
        </tbody>
      </STable>
      {!!dataToUpdate && (
        <UpdateSpeed
          open={!!dataToUpdate}
          onClose={() => setDataToUpdate(false)}
          data={dataToUpdate}
          onSuccess={getVehicleList}
        />
      )}
    </SWrapper>
  );
};
export default SpeedDistanceTime;
