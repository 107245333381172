import React from 'react';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import CustomTooltip from 'common/Tooltip';

const ActionLocked = ({ shipment, size = 14, message }) => {
  return (
    <CustomTooltip
      title={
        message || `You cannot make changes after you have ${shipment?.audited ? 'audited' : 'invoiced'} this shipment.`
      }
    >
      <LockIcon width={size} height={size} />
    </CustomTooltip>
  );
};

export default ActionLocked;
