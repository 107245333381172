import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/drivers/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/drivers/secondClose.svg';
import Input from 'common/Input';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getDriverLicenseTypes, getStates, uploadDocument } from 'Api/JobPositions';
import { COUNTRIES } from '../../ApplyJob.data';
import { SUploadWrapper, SFileUploaded } from '../Steps.styles';

const DriverLicense = ({ form, companyId }) => {
  const { use } = useTheme();
  const [states, setStates] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const showToaster = useShowToaster();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState();
  const uploaderRef = useRef(null);
  const uploaderRefBack = useRef(null);

  const { values, handleChange, handleBlur, touchedErrors } = form;

  const uploadLicense = async (files) => {
    try {
      const response = await uploadDocument(files?.[0], companyId);
      if (response?.document_path) {
        if (image?.isBack) {
          handleChange('licenseUrlBack', response.document_path);
        } else {
          handleChange('licenseUrl', response.document_path);
        }
      }
    } catch (e) {
      showToaster({ type: 'error', message: 'Unable to upload file' });
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getLicenseTypes = async () => {
    try {
      const response = await getDriverLicenseTypes(companyId);
      setLicenseTypes(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const onImageUpload = (file, isBack = false) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result, isBack });
      setCropModalOpen(true);
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadLicense([blob]);
        setCropModalOpen(false);
      });
    }
  };

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    getLicenseTypes();
  }, []);

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <Input
            name='licenseNumber'
            label='Driver License Number'
            onChange={handleChange}
            value={values.licenseNumber}
            error={touchedErrors.licenseNumber}
            required
          />
          <div className='d-flex gap-5'>
            <div className='d-flex flex-column'>
              <SUploadWrapper>
                {values.licenseUrl ? (
                  <SFileUploaded style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                    <CheckIcon />
                    <span className='text-style' style={{ color: '#38A06C' }}>
                      File Uploaded
                    </span>
                    <DeleteIcon className='delete-icon' onClick={() => handleChange('licenseUrl', null)} />
                  </SFileUploaded>
                ) : (
                  <div
                    className='uploader-content'
                    onClick={() => {
                      uploaderRef.current.click();
                      uploaderRef.current.value = null;
                    }}
                  >
                    <UploadIcon width={14} height={13} />
                    <Typography variant='s2' style={{ color: use(palette.indigo500, palette.white) }}>
                      Attach license front...
                    </Typography>
                  </div>
                )}
                {!values.licenseUrl && (
                  <input
                    ref={uploaderRef}
                    accept='image/jpeg,image/png'
                    type='file'
                    onChange={(e) => onImageUpload(e.target.files)}
                    className='d-none'
                  />
                )}
              </SUploadWrapper>
              <ErrorMessage error={touchedErrors.licenseUrl} />
            </div>
            <div className='d-flex flex-column'>
              <SUploadWrapper>
                {values.licenseUrlBack ? (
                  <SFileUploaded style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                    <CheckIcon />
                    <span className='text-style' style={{ color: '#38A06C' }}>
                      File Uploaded
                    </span>
                    <DeleteIcon className='delete-icon' onClick={() => handleChange('licenseUrlBack', null)} />
                  </SFileUploaded>
                ) : (
                  <div
                    className='uploader-content'
                    onClick={() => {
                      uploaderRefBack.current.click();
                      uploaderRefBack.current.value = null;
                    }}
                  >
                    <UploadIcon width={14} height={13} />
                    <Typography variant='s2' style={{ color: use(palette.indigo500, palette.white) }}>
                      Attach license back...
                    </Typography>
                  </div>
                )}
                {!values.licenseUrlBack && (
                  <input
                    ref={uploaderRefBack}
                    accept='image/jpeg,image/png'
                    type='file'
                    onChange={(e) => onImageUpload(e.target.files, true)}
                    className='d-none'
                  />
                )}
              </SUploadWrapper>
              <ErrorMessage error={touchedErrors.licenseUrlBack} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name='country'
            label='Driver License Country'
            placeholder='Select Country..'
            value={values.country}
            onChange={(e, value) => {
              handleChange('country', value);
              handleChange('state', null);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={COUNTRIES}
            error={touchedErrors.country}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name='state'
            label='Driver License State'
            placeholder='Select State..'
            value={values.state}
            onChange={(e, value) => handleChange('state', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={states}
            error={touchedErrors.state}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name='licenseType'
            label='Driver License Type'
            placeholder='Select license..'
            value={values.licenseType}
            onChange={(e, value) => handleChange('licenseType', value)}
            options={licenseTypes}
            error={touchedErrors.licenseType}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            required
            width='100%'
            name='expirationDate'
            label='Driver License Expiration'
            disablePast
            onBlur={handleBlur}
            value={values.expirationDate}
            onChange={(val) => handleChange('expirationDate', val)}
            error={touchedErrors.expirationDate}
          />
        </Grid>
      </Grid>
      {!!cropModalOpen && (
        <ImageCropModal
          show={cropModalOpen}
          onHide={() => setCropModalOpen(false)}
          image={image}
          getCropData={getCropData}
          setCropper={setCropper}
          backdrop='static'
        />
      )}
    </form>
  );
};

export default DriverLicense;
