import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid ${palette.gray50};
  background: #f7f9fc;

  .flex-item:nth-child(1) {
    width: 35%;
  }

  .flex-item:nth-child(2) {
    width: 20%;
  }

  .flex-item:nth-child(3) {
    width: 35%;
  }

  .flex-item:nth-child(4) {
    width: 10%;
  }
`;
