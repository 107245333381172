import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { useFormik } from 'formik';
import { uploadDriverDocument } from 'Api/Staff';
import { CircularProgress } from '@mui/material';
import { getAbsenceTypes } from 'Api/Absence';
import { DatePicker } from 'common/Pickers';
import upload from 'assets/icons/drivers/upload.png';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import ImageDropZone from '../ImgageDropZone/index';
import { staffAbsenceValidationSchema } from '../Drivers/AddDriver/ValidationSchema';

export default function StaffAbsenceModal({ ...props }) {
  const [absenceTypes, setAbsenceTypes] = useState([]);
  const isupdate = props.isupdate;
  const driverId = props?.driverId;
  const { use } = useTheme();

  const formik = useFormik({
    initialValues: {
      driver_staff_id: Number(driverId) || null,
      user_type: 'staff',
      request_id: null,
      absence_doc_id: null,
      reason_title: null,
      absence_start_date: new Date(),
      absence_end_date: new Date(),
      absence_details: '',
      internal_notes: null,
      amount: null,
      absence_document: '',
      type: '1',
    },
    validationSchema: staffAbsenceValidationSchema,
  });

  const getAbsenceType = async () => {
    try {
      const res = await getAbsenceTypes();
      setAbsenceTypes(res || []);
    } catch (e) {
      // Do nothing
    }
  };

  const uploadLicenseDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], driverId).then((res) => {
      formik.setFieldValue(propertyName, res.data.document_path);
    });
  };

  const submit = async () => {
    const startDate = moment(new Date(formik?.values?.absence_start_date)).format('MM/DD/YYYY');
    const endDate = moment(new Date(formik?.values?.absence_end_date)).format('MM/DD/YYYY');
    if (isupdate) {
      const payload = {
        ...formik.values,
        index: props?.updateData?.tableData?.id,
        id: props?.updateData?.id,
        absence_start_date: startDate,
        absence_end_date: endDate,
      };
      props.createAbsence(payload, 'update', props.onHide, successResponse, 'Attendance has been successfully updated');
    } else {
      const payload = { ...formik.values, absence_start_date: startDate, absence_end_date: endDate };
      props.createAbsence(payload, 'add', props.onHide, successResponse, 'Attendance has been successfully created');
    }
  };

  const successResponse = (msg) => {
    props.onSuccess(msg);
  };
  useEffect(() => {
    if (props?.updateData && props.isupdate) {
      formik.setFieldValue('driver_id', props?.updateData?.driver_id);
      formik.setFieldValue('request_id', props?.updateData?.request_id);
      formik.setFieldValue('absence_doc_id', props?.updateData?.absence_doc_id);
      formik.setFieldValue('reason_title', props?.updateData?.reason_title);
      formik.setFieldValue(
        'absence_start_date',
        props?.updateData?.start_date ? moment().toDate(props?.updateData?.start_date) : null
      );
      formik.setFieldValue(
        'absence_end_date',
        props?.updateData?.end_date ? moment().toDate(props?.updateData?.end_date) : null
      );
      formik.setFieldValue('absence_details', props?.updateData?.absence_details);
      formik.setFieldValue('internal_notes', props?.updateData?.internal_notes);
      // formik.setFieldValue('amount', props?.updateData?.amount);
      formik.setFieldValue('absence_document', props?.updateData?.absence_document);
      formik.setFieldValue('type', props?.updateData?.type);
    } else {
      formik.setFieldValue('reason_title', null);
    }
  }, [props.updateData]);

  useEffect(() => {
    getAbsenceType();
  }, []);

  return (
    <div>
      <Modal
        {...props}
        dialogClassName={isupdate ? 'modal-180w' : 'modal-80w'}
        enforceFocus={false}
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={
            isupdate ? 'absence-modal-body-box-updated driver-style-wrap' : 'absence-modal-body-box driver-style-wrap'
          }
          style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
        >
          <div
            className={isupdate ? 'absence-modal-wrap-update' : 'absence-modal-wrap'}
            style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
          >
            <div className='absence-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray900, palette.gray50) }}>
                Reason Title{' '}
                <span className='required' style={{ color: use(palette.gray900, palette.gray50) }}>
                  *
                </span>
              </p>
              <div>
                <input
                  type='text'
                  className='reason-input'
                  placeholder='Reason Title'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                  }}
                  name='reason_title'
                  id='reason_title'
                  onChange={formik.handleChange}
                  value={formik.values.reason_title}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className='absence-container-wrapper'>
              <DatePicker
                required
                label='Attendance Start Date'
                name='absence_start_date'
                onBlur={formik.handleBlur}
                value={formik.values.absence_start_date}
                onChange={(date) => formik.setFieldValue('absence_start_date', date)}
                error={formik.touched?.absence_start_date && formik.errors?.absence_start_date}
              />
            </div>
            <div className='absence-container-wrapper'>
              <DatePicker
                required
                label='Attendance End Date'
                name='absence_end_date'
                onBlur={formik.handleBlur}
                value={formik.values.absence_end_date}
                onChange={(date) => formik.setFieldValue('absence_end_date', date)}
                error={formik.touched?.absence_end_date && formik.errors?.absence_end_date}
              />
            </div>
            <div className='absence-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray900, palette.gray50) }}>
                Staff Notes{' '}
                <span className='required' style={{ color: use(palette.gray900, palette.gray50) }}>
                  *
                </span>
              </p>
              <div>
                <textarea
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                  }}
                  className='absence-text-area'
                  placeholder='Write staff notes...'
                  name='absence_details'
                  id='absence_details'
                  onChange={formik.handleChange}
                  value={formik.values.absence_details}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className='absence-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray900, palette.gray50) }}>
                Internal Notes{' '}
                <span className='required' style={{ color: use(palette.gray900, palette.gray50) }}>
                  *
                </span>
              </p>
              <div>
                <textarea
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                  }}
                  className='absence-text-area'
                  placeholder='Write internal notes...'
                  name='internal_notes'
                  id='internal_notes'
                  onChange={formik.handleChange}
                  value={formik.values.internal_notes}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className='absence-container-wrapper'>
              <p className='text-type' style={{ color: use(palette.gray900, palette.gray50) }}>
                Type
              </p>
              <div className='absence-card-type card-type'>
                {absenceTypes.map((item) => (
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='type'
                      onChange={formik.handleChange}
                      value={item.id}
                      checked={Number(formik.values.type) === item.id}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style text-focus'
                style={{
                  color: use('black', palette.gray200),
                  marginLeft: '0',
                }}
              >
                Upload Supporting Document
              </p>
              {formik?.values?.absence_document ? (
                <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={() => formik.setFieldValue('absence_document', null)}
                  />
                </p>
              ) : (
                <div
                  className='upload-selector'
                  style={{
                    position: 'relative',
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <label htmlFor='myFile' className='text-style'>
                    <span className='upload-icon'>
                      <img src={upload} alt='upload' />
                    </span>
                    <span
                      style={{
                        color: '#4F5AED',
                      }}
                    >
                      Upload Document
                    </span>
                  </label>
                  <ImageDropZone
                    onDrop={(file) => uploadLicenseDocument(file, 'absence_document')}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    maxSize={1000000}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['application/pdf', 'image/jpeg', 'image/png']}
                  />
                </div>
              )}
            </div>
          </div>
          {isupdate && (
            <div className='uploaded-document-update-absence'>
              {props?.updateData?.absence_document ? (
                <iframe
                  src={props?.updateData?.absence_document}
                  style={{ height: '100%', width: '100%' }}
                  title='Doc'
                />
              ) : (
                <p>No Document Uploaded</p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.white, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {props.loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button disabled={!formik.isValid} className='next-step' type='submit' onClick={submit}>
                {isupdate ? 'Update Attendance' : 'Add Attendance'}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
