import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatAmount } from 'utils/helpers';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getAccountsList, getAccountTransactions, getBankAccountTransactions, getPayeeList } from 'Api/Accounts';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { STable, STableFilters, STableWrapper } from '../ReportDetails.styles';

const AccountTransactions = () => {
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = searchParams.get('account_id');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAccountsList, setLoadingAccountsList] = useState(false);
  const [loadingPayees, setLoadingPayees] = useState(false);
  const [transactions, setTransactions] = useState({ data: [] });
  const [payees, setPayees] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [filters, setFilters] = useState({ payees: [] });
  const [dateRange, setDateRange] = useState({
    start: startDate ? moment(startDate).toDate() : null,
    end: endDate ? moment(endDate).toDate() : null,
  });

  const isBankAccount = useMemo(() => {
    return selectedAccount?.account_type?.id === ACCOUNT_TYPE.BANK;
  }, [selectedAccount]);

  const getTransactions = async () => {
    try {
      setLoading(true);

      const payeeTypes = filters.payees.map((el) => el.type);
      const payeeIds = filters.payees.map((el) => el.id);

      const params = {
        page: 1,
        itemsPerPage: 100000,
        account_id: selectedAccount.id,
        start_date: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        'sort[][date]': 'asc',
        payee_id: payeeIds.length ? payeeIds : undefined,
        payee_type: payeeTypes.length ? payeeTypes : undefined,
      };

      let response;
      if (isBankAccount) {
        response = await getBankAccountTransactions(params);
      } else {
        response = await getAccountTransactions(params);
      }

      let totalPayment = 0;
      let totalDeposit = 0;
      let balance = 0;

      const convertedData = response.data.map((item) => {
        totalPayment += Number(isBankAccount ? item.payment || 0 : item.debit || 0);
        totalDeposit += Number(isBankAccount ? item.deposit || 0 : item.credit || 0);
        const changedAmount = isBankAccount
          ? item.payment
            ? -item.payment
            : item.deposit
          : item.debit
          ? -item.debit
          : item.credit;

        balance += changedAmount;
        return {
          ...item,
          payment: isBankAccount ? Math.abs(item.payment) : undefined,
          payee:
            (payees || []).find((i) => Number(i.id) === Number(item.payee_id) && i.type === item.payee_type) || null,
          account: (accountsList || []).find((acc) => acc.id === Number(item.account)) || null,
          balance,
        };
      });

      setTransactions({
        ...response,
        totalPayment,
        totalDeposit,
        data: convertedData,
      });
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getAccounts = async () => {
    try {
      setLoadingAccountsList(true);
      const { data } = await getAccountsList();
      setAccountsList(data);
      if (accountId) {
        setSelectedAccount(data.find((el) => Number(el.id) === Number(accountId)));
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingAccountsList(false);
    }
  };

  const getPayees = async () => {
    try {
      setLoadingPayees(true);
      const { data } = await getPayeeList();
      setPayees(data.map((el) => ({ ...el, key: `${el.id}-${el.type}` })));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingPayees(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const { url } = await generatePDF({
        transactions,
        accountName: selectedAccount?.account_name,
        isBankAccount,
        download,
        dateRange,
        formatDate,
        convertToCustomerTime,
        currency,
      });
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onExport = async () => {
    try {
      if (!transactions.data?.length) {
        return;
      }

      const titles = {
        date: 'Date',
        referenceId: 'Reference ID',
        payee: 'Payee',
        account: 'Account',
        memo: 'Memo',
        payment: isBankAccount ? 'Payment' : 'Debit',
        deposit: isBankAccount ? 'Deposit' : 'Credit',
        balance: 'Balance',
      };

      const transactionsToExport = transactions.data?.map((tr) => ({
        date: moment(tr.date).format('MM/DD/YYYY'),
        referenceId: `*****${tr.reference_id?.slice(-5) || ''}`,
        payee: tr.payee?.name || '',
        account: isBankAccount
          ? tr.account?.account_name || '-'
          : !tr?.account && tr?.bill?.is_split
          ? 'SPLIT'
          : `${tr.account?.account_name || '-'}`,
        memo: tr.memo || '-',
        payment: isBankAccount ? tr.payment || 0 : tr.debit || 0,
        deposit: isBankAccount ? tr.deposit || 0 : tr.credit || 0,
        balance: tr.balance || 0,
      }));

      const arrayToConvert = [titles, ...transactionsToExport];

      let str = '';
      for (let i = 0; i < arrayToConvert.length; i++) {
        let line = '';
        for (const index in arrayToConvert[i]) {
          if (line !== '') line += ',';

          line += arrayToConvert[i][index];
        }
        str += `${line}\r\n`;
      }

      const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute(
        'download',
        `Account Transactions - ${
          dateRange.start && dateRange.end
            ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`
            : 'All Time'
        }`
      );
      link.href = url;
      link.click();
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      getTransactions();
    }
  }, [dateRange, selectedAccount, filters]);

  useEffect(() => {
    searchParams.delete('account_id');
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams, { replace: true });
    getAccounts();
    getPayees();
  }, []);

  return (
    <PageWrapper loading={loadingAccountsList} title='Account Transactions'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              dateRange={dateRange}
              initialRangeName={dateRange.start ? 'Custom Range' : 'All Time'}
              setDateRange={setDateRange}
              type='allTime'
            />
            <Autocomplete
              width='320px'
              options={accountsList}
              value={selectedAccount}
              labelKey='account_name'
              placeholder='Select Account..'
              loading={loadingAccountsList}
              onChange={(e, val) => setSelectedAccount(val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={3}
              optionKey='key'
              options={payees}
              value={filters.payees}
              loading={loadingPayees}
              placeholder={!filters.payees.length ? 'Select Payee..' : ''}
              onChange={(e, val) => setFilters((prevState) => ({ ...prevState, payees: val }))}
              getTagLabel={(option) => (option ? option?.name : '')}
              getOptionLabel={(option) => (option ? option?.name : '')}
              isOptionEqualToValue={(option, value) => option?.id === value?.id && option?.type === value?.type}
              disableClearable={false}
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={transactions.data.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={transactions.data.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        {!loading && (
          <STable>
            <thead>
              <tr className='header-row'>
                <th>DATE</th>
                <th>REFERENCE ID</th>
                <th>PAYEE</th>
                <th>ACCOUNT</th>
                <th>MEMO</th>
                <th>{isBankAccount ? 'PAYMENT' : 'DEBIT'}</th>
                <th>{isBankAccount ? 'DEPOSIT' : 'CREDIT'}</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {transactions.data.map((item) => (
                <tr key={item.id} className='body-row'>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {formatDate(item.date)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      *****{item.reference_id?.slice(-5)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.payee?.name || '-'}
                    </Typography>
                  </td>
                  <td>
                    {isBankAccount ? (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.account?.account_name || '-'}
                      </Typography>
                    ) : (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {!item?.account && item?.bill?.is_split ? 'SPLIT' : `${item.account?.account_name || '-'}`}
                      </Typography>
                    )}
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.memo || '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {currency}
                      {formatAmount(isBankAccount ? item.payment : item.debit)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {currency}
                      {formatAmount(isBankAccount ? item.deposit : item.credit)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item?.balance < 0 && '-'}
                      {currency}
                      {formatAmount(Math.abs(Number(item?.balance || 0)))}
                    </Typography>
                  </td>
                </tr>
              ))}
              {!!transactions.data.length && (
                <tr className='body-row'>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      TOTAL
                    </Typography>
                  </td>
                  <td colSpan='4' />
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {Number(transactions.totalPayment) > 0 && '-'}
                      {currency}
                      {formatAmount(transactions.totalPayment)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {currency}
                      {formatAmount(transactions.totalDeposit)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {Number(transactions.totalDeposit - transactions.totalPayment) < 0 && '-'}
                      {currency}
                      {formatAmount(Math.abs(Number(transactions.totalDeposit - transactions.totalPayment)))}
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </STable>
        )}
      </STableWrapper>
      {!loading && !transactions.data?.length && <NoRecords />}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Account Transactions' />
      )}
    </PageWrapper>
  );
};

export default AccountTransactions;
