import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import refresh from 'assets/icons/drivers/refresh.png';
import subRight from 'assets/icons/drivers/sub-right.png';
import upload from 'assets/icons/drivers/upload.png';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import add from 'assets/icons/drivers/add.png';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import Input, { InputWithText } from 'common/Input';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { blockNonPositiveNumbers } from 'utils/helpers';
import {
  DriverGroups,
  LicenseType,
  PositionAndApplication,
  uploadDriverDocument,
  AddAndUpdateBasicDetail,
} from 'Api/Driver';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import AddressFields from 'common/AddressFields';
import AddGroup from 'componentsV2/Group/AddGroup';
import CustomizedSnackbars from 'components/toast/Toast';
import ImageDropZone from 'components/ImgageDropZone/index';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import OwnerAutocomplete from 'componentsV2/OwnerOperator/OwnerAutocomplete';
import { getApplicant } from 'Api/Applicants';
import { getCountries } from 'Api/JobPositions';
import ErrorMessage from 'common/ErrorMessage';
import { getPrevEmployment } from 'Api/PreviousEmployment';
import { validationBasicSchema } from './ValidationSchema';

const whitelist = ['US', 'CA', 'MX'];

const BasicDetails = ({
  nextStep,
  showError,
  Cancel,
  driverId,
  basicDetails = {},
  stepsDone,
  basicDetailsStates = {},
  setApplicantMedicalAndBackground,
  getDrivers,
}) => {
  const showToaster = useShowToaster();
  const [uploadApplication, setUploadApplication] = useState('selectApplication');
  const [selectApplication, setSelectApplication] = useState([]);
  const [driverLicense, setDriverLicense] = useState([]);
  const [groups, setGroups] = useState([]);
  const [citizenships, setCitizenships] = useState([]);
  const [customName, setCustomName] = useState('');
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onNextloading, setOnNextloading] = useState(false);
  const [birthdate, setBirthdate] = useState(new Date('2001/01/01'));
  const [hireDate, setHireDate] = useState(new Date());
  const [image, setImage] = useState();
  const [imageBack, setImageBack] = useState();
  const [cropper, setCropper] = useState();
  const [cropModalShow, setCropModalShow] = useState();
  const [cropModalBackShow, setCropModalBackShow] = useState();

  const [validateTravel, setValidateTravel] = useState({
    errorMsg: '',
    touched: false,
  });

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const {
    driver_id,
    driver_type,
    hire_date,
    operator,
    custom_id,
    fname,
    lname,
    email,
    phone_number,
    license_no,
    license_exp_dt,
    license_type,
    driver_licence_upload,
    driver_licence_back_upload,
    dob,
    driver_address,
    driver_address2,
    driver_country,
    driver_state,
    driver_city,
    driver_zip,
    license_address1,
    license_address2,
    license_country,
    license_state,
    license_city,
    license_zip,
    citizenship,
    can_travel_canada,
    can_travel_mexico,
    can_travel_usa,
    group_id,
    approved_for,
    custom_approval,
    has_twic,
    has_fast,
    has_tsa,
    has_hazmat,
    has_custom,
    custom_name,
    passport,
    application_upload,
    applicant,
    emergency_contact_number,
    emergency_contact_last_name,
    emergency_contact_first_name,
    transmission_type,
    transmission_speed,
  } = basicDetails || {};

  const formik = useFormik({
    initialValues: {
      driver_id: driver_id || driverId,
      driver_type: driver_type || 'company',
      operator: operator || null,
      hire_date: hire_date ? moment(hire_date).toDate() : new Date(),
      custom_id: custom_id || '',
      fname: fname || '',
      lname: lname || '',
      emergency_contact_first_name: emergency_contact_first_name || '',
      emergency_contact_last_name: emergency_contact_last_name || '',
      email: email || '',
      phone_number: phone_number || '',
      emergency_contact_number: emergency_contact_number || '',
      license_no: license_no || '',
      license_exp_dt: license_exp_dt ? moment(license_exp_dt).toDate() : new Date(),
      license_type: license_type || '',
      application_upload: application_upload || '',
      driver_licence_upload: driver_licence_upload || '',
      driver_licence_back_upload: driver_licence_back_upload || '',
      dob: dob ? moment(dob).toDate() : new Date('2001/01/01'),
      driver_address: driver_address || '',
      driver_address2: driver_address2 || '',
      driver_country: driver_country || null,
      driver_state: driver_state || null,
      driver_city: driver_city || null,
      driver_zip: driver_zip || '',
      license_address1: license_address1 || '',
      license_address2: license_address2 || '',
      license_country: license_country || null,
      license_state: license_state || null,
      license_city: license_city || null,
      license_zip: license_zip || '',
      citizenship: citizenship || null,
      passport: passport || null,
      can_travel_canada: can_travel_canada || false,
      can_travel_mexico: can_travel_mexico || false,
      can_travel_usa: can_travel_usa || true,
      group_id: group_id || '',
      approved_for: approved_for || '',
      custom_approval: custom_approval || '',
      has_twic: has_twic || false,
      has_fast: has_fast || false,
      has_tsa: has_tsa || false,
      has_hazmat: has_hazmat || false,
      has_custom: has_custom || false,
      custom_name: custom_name || [],
      applicant: applicant || '',
      transmission_type: transmission_type || 3,
      transmission_speed: transmission_speed || null,
    },
    validationSchema: validationBasicSchema,
  });

  const onApplicantSelect = async (id) => {
    try {
      const { data } = await getApplicant(id);
      setApplicantMedicalAndBackground({
        ...data.job_action,
        criminal_checked_attachment: data.job_action?.criminal_checked_attachment || '',
        mvr_check_attachment: data.job_action?.mvr_check_attachment || '',
        drug_test_attachment: data.job_action?.drug_test_attachment || '',
        fmcsa_attachment: data.job_action?.fmcsa_attachment || '',
        attach_medical_verification: data.job_action?.attach_medical_verification || '',
        attach_national_registry: data.job_action?.attach_national_registry || '',
        examiner_card: data.medical_examiner_card?.upload_examiner_card || '',
        medical_examiners_expire_date: data.medical_examiner_card?.expiry_date,
      });
      const fullName = data.full_name.split(' ');
      const address = data.addresses?.[0];

      formik.setValues((prevState) => ({
        ...prevState,
        applicant: id,
        phone_number: `+${data?.phone_no || ''}`,
        driver_address: address?.address1 || '',
        driver_address2: address?.address2 || '',
        driver_zip: address?.zipcode || '',
        driver_country: address?.country || null,
        driver_state: address?.state || null,
        driver_city: address?.city || null,
        dob: new Date(data.date_of_birth),
        email: data.email,
        fname: fullName[0],
        lname: fullName.length >= 2 ? fullName[1] : '',
        emergency_contact_first_name: data.emergency_contact_first_name,
        emergency_contact_last_name: data.emergency_contact_last_name,
        emergency_contact_number: `+${data?.emergency_contact_number || ''}`,
        citizenship: address?.country || null,
        can_travel_usa: !!data.job_applicant_information?.can_travel_us,
        can_travel_canada: !!data.job_applicant_information?.can_travel_canada,
        can_travel_mexico: !!data.job_applicant_information?.can_travel_mexico,
        license_type: data.driving_licence?.licence_type?.name || '',
        license_no: data.driving_licence?.driving_licence_number || '',
        driver_licence_upload: data.driving_licence?.driving_licence_attachment || '',
        license_exp_dt: data.driving_licence?.driving_licence_expired
          ? new Date(data.driving_licence?.driving_licence_expired)
          : null,
      }));
      setBirthdate(new Date(data.date_of_birth));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onNextStep = () => {
    setOnNextloading(true);
    const {
      citizenship,
      driver_country,
      driver_state,
      driver_city,
      license_country,
      license_state,
      license_city,
      operator,
      hire_date,
      license_exp_dt,
      dob,
      applicant,
    } = formik.values;
    const driverInfo = {
      ...formik.values,
      hire_date: hire_date ? moment(hire_date).format('YYYY-MM-DD') : '',
      license_exp_dt: license_exp_dt ? moment(license_exp_dt).format('YYYY-MM-DD') : '',
      dob: dob ? moment(dob).format('YYYY-MM-DD') : '',
      driver_country: driver_country?.id || null,
      driver_state: driver_state?.id || null,
      driver_city: driver_city?.id || null,
      license_country: license_country?.id || null,
      license_state: license_state?.id || null,
      license_city: license_city?.id || null,
      citizenship: citizenship?.id || null,
      operator_id: operator?.id || null,
      application_id: applicant || null,
    };

    if (driver_id) {
      driverInfo.driver_id = driver_id;
    }
    AddAndUpdateBasicDetail(driverInfo)
      .then(async (res) => {
        let previousEmployment;

        if (applicant) {
          const { data } = await getPrevEmployment({
            id: res?.data?.id,
            userType: 'driver',
            params: {
              page: 1,
              itemsPerPage: 10000,
            },
          });
          previousEmployment = data;
        }

        setOnNextloading(false);
        nextStep(
          {
            formikData: formik.values,
            stateData: {
              birthdate,
              hireDate,
              uploadApplication,
              selectApplication,
              driverLicense,
              groups,
              citizenships,
              customName,
            },
            driverId: res?.data?.id,
            previousEmployment,
          },
          `Driver has been ${stepsDone ? 'updated' : 'created'}`
        );
      })
      .catch((error) => {
        setOnNextloading(false);
        showError(getErrorMessage(error));
      });
  };

  const onSaveAndClose = () => {
    const {
      citizenship,
      driver_country,
      driver_state,
      driver_city,
      license_country,
      license_state,
      license_city,
      operator,
      hire_date,
      license_exp_dt,
      dob,
      applicant,
    } = formik.values;
    const driverInfo = {
      ...formik.values,
      hire_date: hire_date ? moment(hire_date).format('YYYY-MM-DD') : '',
      license_exp_dt: license_exp_dt ? moment(license_exp_dt).format('YYYY-MM-DD') : '',
      dob: dob ? moment(dob).format('YYYY-MM-DD') : '',
      driver_country: driver_country?.id || null,
      driver_state: driver_state?.id || null,
      driver_city: driver_city?.id || null,
      license_country: license_country?.id || null,
      license_state: license_state?.id || null,
      license_city: license_city?.id || null,
      citizenship: citizenship?.id || null,
      operator_id: operator?.id || null,
      application_id: applicant || null,
    };

    if (driver_id) {
      driverInfo.driver_id = driver_id;
    }
    AddAndUpdateBasicDetail(driverInfo)
      .then(() => {
        getDrivers();
        Cancel();
      })
      .catch((error) => {
        showError(getErrorMessage(error));
      });
  };

  const onQuickAdd = () => {
    setLoading(true);
    const {
      citizenship,
      driver_country,
      driver_state,
      driver_city,
      license_country,
      license_state,
      license_city,
      operator,
      hire_date,
      license_exp_dt,
      dob,
      applicant,
    } = formik.values;
    AddAndUpdateBasicDetail({
      ...formik.values,
      hire_date: hire_date ? moment(hire_date).format('YYYY-MM-DD') : '',
      license_exp_dt: license_exp_dt ? moment(license_exp_dt).format('YYYY-MM-DD') : '',
      dob: dob ? moment(dob).format('YYYY-MM-DD') : '',
      driver_country: driver_country?.id || null,
      driver_state: driver_state?.id || null,
      driver_city: driver_city?.id || null,
      license_country: license_country?.id || null,
      license_state: license_state?.id || null,
      license_city: license_city?.id || null,
      citizenship: citizenship?.id || null,
      operator_id: operator?.id || null,
      application_id: applicant || null,
    })
      .then(() => {
        setLoading(false);
        setShowMessage({
          message: 'Driver has been created',
          visible: true,
          type: 'success',
        });
        getDrivers();
        Cancel();
      })
      .catch((error) => {
        setLoading(false);
        setShowMessage({
          message: getErrorMessage(error),
          visible: true,
          type: 'error',
        });
      });
  };

  const { use } = useTheme();

  const getCountriesList = async () => {
    try {
      const { data } = await getCountries();
      setCitizenships(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onSelectCitizen = (e, value) => {
    formik.setFieldValue('citizenship', value);
    if (value?.id === 231) {
      formik.setFieldValue('can_travel_usa', true);
    }
  };

  useEffect(() => {
    if (stepsDone) {
      const {
        uploadApplication,
        selectApplication,
        driverLicense,
        groups,
        citizenships,
        customName,
        hireDate,
        birthdate,
      } = basicDetailsStates;
      setSelectApplication(selectApplication);
      setDriverLicense(driverLicense);
      setGroups(groups);
      setUploadApplication(uploadApplication);
      setCitizenships(citizenships);
      setCustomName(customName);
      setBirthdate(birthdate);
      setHireDate(hireDate);
      return;
    }
    formik.setFieldValue('fname', '');

    const body = {
      itemsPerPage: 1000,
      status_id: 3,
      'filters[removed_applied]': 1,
      'filters[applicant_type_id][]': 1,
    };

    PositionAndApplication(body).then((res) => {
      setSelectApplication(res?.data || []);
    });

    LicenseType().then((res) => {
      setDriverLicense(res.data);
    });

    DriverGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], driverId)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const saveGroup = (val) => {
    setGroups([...groups, val]);
    formik.setFieldValue('group_id', val.id);
  };

  const checkTrevalErro = ({ can_travel_canada, can_travel_usa, can_travel_mexico }) => {
    if (!can_travel_canada && !can_travel_usa && !can_travel_mexico) {
      setValidateTravel({
        errorMsg: 'At least one should be selected',
        touched: true,
      });
    } else {
      setValidateTravel({
        errorMsg: '',
        touched: true,
      });
    }
  };

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file[0]);
  };

  const imageSelectedBack = (file) => {
    setCropModalBackShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImageBack({ blob: reader?.result });
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadDocument([blob], 'driver_licence_upload');
        setCropModalShow(false);
      });
    }
  };

  const getCropDataBack = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadDocument([blob], 'driver_licence_back_upload');
        setCropModalBackShow(false);
      });
    }
  };

  const handleDriverAddressChange = (event, value) => {
    const addressFieldsMapper = {
      address: 'driver_address',
      address2: 'driver_address2',
      city: 'driver_city',
      state: 'driver_state',
      country: 'driver_country',
      zipcode: 'driver_zip',
    };

    if (event?.target) {
      const { name, value } = event.target;
      formik.setFieldValue(addressFieldsMapper[name], value);
    } else {
      formik.setFieldValue(addressFieldsMapper[event], value);
    }
  };

  const handleDriverLicenseAddressChange = (event, value) => {
    const addressFieldsMapper = {
      address: 'license_address',
      address2: 'license_address2',
      city: 'license_city',
      state: 'license_state',
      country: 'license_country',
      zipcode: 'license_zip',
    };

    if (event?.target) {
      const { name, value } = event.target;
      formik.setFieldValue(addressFieldsMapper[name], value);
    } else {
      formik.setFieldValue(addressFieldsMapper[event], value);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        {!!cropModalShow && (
          <ImageCropModal
            show={cropModalShow}
            onHide={() => setCropModalShow(false)}
            image={image}
            getCropData={getCropData}
            setCropper={setCropper}
          />
        )}
        {!!cropModalBackShow && (
          <ImageCropModal
            show={cropModalBackShow}
            onHide={() => setCropModalBackShow(false)}
            image={imageBack}
            getCropData={getCropDataBack}
            setCropper={setCropper}
          />
        )}
        <div className='basic-container driver-style-wrap'>
          <div className='d-flex gap-3 align-items-end' role='group' aria-labelledby='my-radio-group'>
            <div>
              <InputLabel>What type of driver is this?</InputLabel>
              <div>
                <Radio
                  label='Company'
                  name='driver_type'
                  value='company'
                  checked={formik.values.driver_type === 'company'}
                  onChange={() => formik.setFieldValue('driver_type', 'company')}
                  wrapperClassName='mb-2'
                />
                <Radio
                  label='Sub-Contractor / Owner-Operator'
                  name='driver_type'
                  value='owner'
                  checked={formik.values.driver_type === 'owner'}
                  onChange={() => formik.setFieldValue('driver_type', 'owner')}
                />
                <ErrorMessage error={formik.touched?.driver_type && formik.errors?.driver_type} />
              </div>
            </div>
            {formik.values.driver_type === 'owner' ? (
              <OwnerAutocomplete
                name='operator'
                width='300px'
                value={formik.values.operator}
                onChange={(e, val) => formik.setFieldValue('operator', val)}
                error={formik.touched?.operator && formik.errors?.operator}
              />
            ) : (
              ''
            )}
          </div>
          <div className='d-flex mt-3'>
            <div style={{ marginRight: '20px' }}>
              <DatePicker
                width='200px'
                label='Hire Date'
                name='hire_date'
                value={formik.values.hire_date}
                onChange={(val) => {
                  formik.setFieldValue('hire_date', val);
                  setHireDate(val);
                }}
                disableFuture
                onBlur={formik.handleBlur}
              />
              {formik.touched?.hire_date && formik.errors?.hire_date ? (
                <span className='error-message'>{formik.errors.hire_date}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style text-focus'
                style={{
                  color: use('black', palette.gray200),
                  marginLeft: '0',
                }}
              >
                Application
              </p>
              <div className='flexbox mt-2' role='group' aria-labelledby='my-radio-group'>
                <div style={{ marginRight: '20px' }}>
                  <input
                    type='radio'
                    name='ApplicationList'
                    defaultChecked
                    onClick={() => setUploadApplication('selectApplication')}
                  />
                  <span className='text-style' style={{ color: use('black', palette.gray200) }}>
                    From Application Inbox
                  </span>
                  <img src={refresh} alt='refresh' />
                </div>
                <div>
                  <input
                    type='radio'
                    name='ApplicationList'
                    onClick={() => setUploadApplication('uploadApplication')}
                  />
                  <span className='text-style' style={{ color: use('black', palette.gray200) }}>
                    Upload Application
                  </span>
                </div>
              </div>
              {uploadApplication === 'selectApplication' ? (
                <div className='flexbox'>
                  <img src={subRight} className='sub-right-icon' alt={subRight} />
                  <CustomSelect
                    options={selectApplication}
                    defaultValue='Select Applicant...'
                    value={formik.values.applicant}
                    onChange={onApplicantSelect}
                    propertyName='full_name'
                  />
                </div>
              ) : formik?.values?.application_upload ? (
                <p className='attachment-wrapper ms-5' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={() => formik.setFieldValue('application_upload', null)}
                  />
                </p>
              ) : (
                <div className='d-flex justify-content-end'>
                  <div
                    className='upload-document ms-5'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <label htmlFor='myFile' className='text-style'>
                      <span className='upload-icon me-1'>
                        <img src={upload} alt='upload' />
                      </span>
                      <span
                        style={{
                          color: '#4F5AED',
                        }}
                      >
                        Upload document
                      </span>{' '}
                      or drag and drop
                    </label>
                    <ImageDropZone
                      onDrop={(file) => uploadDocument(file, 'application_upload')}
                      width='100%'
                      height='100%'
                      name='small'
                      deletable={false}
                      customStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                      accept={['application/pdf']}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <p className='driver-type-text text-focus' style={{ color: use('black', palette.gray200) }}>
              Driver Details
            </p>
          </div>
          <div className='d-flex'>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                First Name <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='fname'
                    id='fname'
                    className='input-field'
                    placeholder='Wilson'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.fname}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.fname && formik.errors.fname ? (
                  <span className='error-message'>{formik.errors.fname}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Last Name <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='lname'
                    id='lname'
                    className='input-field'
                    placeholder='Lubin'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.lname}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.lname && formik.errors.lname ? (
                  <span className='error-message'>{formik.errors.lname}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Phone Number <span className='required'>*</span>
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: driverId ? palette.gray0 : palette.white,
                    borderColor: palette.gray50,
                  }}
                  addInternationalOption={false}
                  className={`input-field-phone ${driverId ? 'disabled-phone-input' : ''}`}
                  value={formik.values.phone_number}
                  countries={whitelist}
                  onChange={(val) => formik.setFieldValue('phone_number', val)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                  disabled={!!driverId}
                />
              </div>
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <span className='error-message'>{formik.errors.phone_number}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Email
              </p>
              <div
                className='selector-name'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  className='input-field'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder='example@gmail.com'
                  style={{
                    backgroundColor: driverId ? palette.gray0 : palette.white,
                    borderColor: palette.gray50,
                  }}
                  disabled={!!driverId}
                />
              </div>
            </div>
          </div>
          <div className='d-flex' style={{ marginTop: '20px' }}>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Driver License #
              </p>
              <div
                className='driver-license'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  className='input-field'
                  name='license_no'
                  value={formik.values.license_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
              {formik.touched.license_no && formik.errors.license_no ? (
                <span className='error-message'>{formik.errors.license_no}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <DatePicker
                width='190px'
                label='Driver License Expiration'
                name='license_exp_dt'
                value={formik.values.license_exp_dt}
                onBlur={formik.handleBlur}
                onChange={(val) => formik.setFieldValue('license_exp_dt', val)}
              />
              {formik.touched?.license_exp_dt && formik.errors?.license_exp_dt ? (
                <span className='error-message'>{formik.errors.license_exp_dt}</span>
              ) : null}
            </div>

            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Driver License Type
              </p>
              <div className='custom-select-wrap-contain'>
                <CustomSelect
                  defaultValue='Select License Type..'
                  options={driverLicense}
                  onChange={(value) => formik.setFieldValue('license_type', value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.license_type}
                  id='license_type'
                  name='name'
                  patchProperty='name'
                />
              </div>
              {formik.touched.license_type && formik.errors.license_type ? (
                <span className='error-message'>{formik.errors.license_type}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style text-focus'
                style={{
                  color: use('black', palette.gray200),
                  marginLeft: '0',
                }}
              >
                Upload License Front Side
              </p>
              {formik?.values?.driver_licence_upload ? (
                <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={() => formik.setFieldValue('driver_licence_upload', null)}
                  />
                </p>
              ) : (
                <div
                  className='upload-selector'
                  style={{
                    position: 'relative',
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <label htmlFor='myFile' className='text-style'>
                    <span className='upload-icon'>
                      <img src={upload} alt='upload' />
                    </span>
                    <span
                      style={{
                        color: '#4F5AED',
                      }}
                    >
                      Upload license
                    </span>
                  </label>
                  <ImageDropZone
                    onDrop={(file) => imageSelected(file)}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['image/jpeg', 'image/png']}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <p
              className='driver-type-text'
              style={{
                color: use(palette.gray700, palette.gray200),
                marginTop: '20px',
                marginBottom: '0',
              }}
            >
              Driver License Address
            </p>
          </div>
          <div className='d-flex'>
            <div style={{ marginTop: '8px', marginRight: '20px', width: '732px' }}>
              <AddressFields
                values={{
                  address: formik.values.license_address,
                  address2: formik.values.license_address2,
                  city: formik.values.license_city,
                  state: formik.values.license_state,
                  country: formik.values.license_country,
                  zipcode: formik.values.license_zip,
                }}
                handleChange={handleDriverLicenseAddressChange}
                isOneRow
                hideLabel
                required={false}
                limitCountries
              />
            </div>
            <div>
              <p
                className='text-style text-focus'
                style={{
                  color: use('black', palette.gray200),
                  marginLeft: '0',
                  marginTop: '-20px',
                }}
              >
                Upload License Back Side
              </p>
              {formik?.values?.driver_licence_back_upload ? (
                <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={() => formik.setFieldValue('driver_licence_back_upload', null)}
                  />
                </p>
              ) : (
                <div
                  className='upload-selector'
                  style={{
                    position: 'relative',
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <label htmlFor='myFile' className='text-style'>
                    <span className='upload-icon'>
                      <img src={upload} alt='upload' />
                    </span>
                    <span
                      style={{
                        color: '#4F5AED',
                      }}
                    >
                      Upload license
                    </span>
                  </label>
                  <ImageDropZone
                    onDrop={(file) => imageSelectedBack(file)}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['image/jpeg', 'image/png']}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='d-flex' style={{ marginTop: '20px' }}>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Driver ID
              </p>
              <div
                className='selector-name emergeny-name-wrap'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  className='input-field'
                  name='custom_id'
                  id='custom_id'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.custom_id}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                  }}
                />
              </div>
            </div>

            <div style={{ marginRight: '20px' }}>
              <DatePicker
                width='220px'
                label='Date of Birth'
                name='dob'
                value={formik.values.dob}
                onChange={(val) => {
                  formik.setFieldValue('dob', val);
                  setBirthdate(val);
                }}
                onBlur={formik.handleBlur}
                disableFuture
              />
              {formik.touched?.dob && formik.errors?.dob ? (
                <span className='error-message'>{formik.errors.dob}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px', width: '252px' }}>
              <InputWithText
                label='Driver Experience'
                type='number'
                text='years'
                name='years_experience'
                bgColor={palette.gray50}
                value={formik.values.years_experience}
                onChange={(e) => formik.setFieldValue('years_experience', e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
                style={{ width: '100%' }}
                className='w-100'
              />
            </div>
          </div>
          <div className='d-flex' style={{ marginTop: '20px' }}>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Emergency contact first name
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name emergeny-name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='emergency_contact_first_name'
                    id='emergency_contact_first_name'
                    className='input-field'
                    placeholder='Wilson'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.emergency_contact_first_name}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.emergency_contact_first_name && formik.errors.emergency_contact_first_name ? (
                  <span className='error-message'>{formik.errors.emergency_contact_first_name}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Emergency contact last name
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name emergeny-name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='emergency_contact_last_name'
                    id='emergency_contact_last_name'
                    className='input-field'
                    placeholder='Lubin'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.emergency_contact_last_name}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.emergency_contact_last_name && formik.errors.emergency_contact_last_name ? (
                  <span className='error-message'>{formik.errors.emergency_contact_last_name}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Emergency contact phone number
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  addInternationalOption={false}
                  className='input-field-phone'
                  value={formik.values.emergency_contact_number}
                  countries={whitelist}
                  onChange={(val) => formik.setFieldValue('emergency_contact_number', val)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                />
              </div>
              {formik.touched.emergency_contact_number && formik.errors.emergency_contact_number ? (
                <span className='error-message'>{formik.errors.emergency_contact_number}</span>
              ) : null}
            </div>
          </div>
          <div style={{ marginTop: '20px', width: '732px' }}>
            <AddressFields
              values={{
                address: formik.values.driver_address,
                address2: formik.values.driver_address2,
                city: formik.values.driver_city,
                state: formik.values.driver_state,
                country: formik.values.driver_country,
                zipcode: formik.values.driver_zip,
              }}
              handleChange={handleDriverAddressChange}
              isOneRow
              required={false}
              limitCountries
            />
          </div>
          <div>
            <p
              className='driver-type-text'
              style={{
                color: use(palette.gray700, palette.gray200),
                marginTop: '20px',
                marginbottom: '0',
              }}
            >
              Citizenship
            </p>
            <div className='d-flex align-items-center' style={{ marginTop: '4px' }}>
              <div style={{ marginRight: '12px' }}>
                <div className='custom-select-wrap-contain-sub'>
                  <Autocomplete
                    options={citizenships}
                    placeholder='Citizenship..'
                    componentsProps={{ paper: { style: { height: '200px' } } }}
                    onChange={onSelectCitizen}
                    disableClearable={false}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={formik?.values?.citizenship}
                  />
                </div>
                {formik.touched.citizenship && formik.errors.citizenship ? (
                  <span className='error-message'>{formik.errors.citizenship}</span>
                ) : null}
              </div>
              <div className='flex-col'>
                <div style={{ marginRight: '12px' }} className='d-flex justify-content-center align-items-center'>
                  <p className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Can Travel To: <span className='required'>*</span>
                  </p>
                  <input
                    className='check-input'
                    type='checkbox'
                    name='can_travel_usa'
                    id='can_travel_usa'
                    value={formik.values.can_travel_usa}
                    checked={formik.values.can_travel_usa}
                    onChange={() => {
                      formik.setFieldValue('can_travel_usa', !formik.values.can_travel_usa);
                      checkTrevalErro({
                        can_travel_canada: formik.values.can_travel_canada,
                        can_travel_usa: !formik.values.can_travel_usa,
                        can_travel_mexico: formik.values.can_travel_mexico,
                      });
                    }}
                  />
                  <label
                    className='text-style'
                    htmlFor='flexCheckDefault'
                    style={{ color: use(palette.gray700, palette.gray200) }}
                  >
                    USA
                  </label>
                  <input
                    className='check-input'
                    type='checkbox'
                    name='can_travel_canada'
                    id='can_travel_canada'
                    value={formik.values.can_travel_canada}
                    checked={formik.values.can_travel_canada}
                    onChange={() => {
                      formik.setFieldValue('can_travel_canada', !formik.values.can_travel_canada);
                      checkTrevalErro({
                        can_travel_canada: !formik.values.can_travel_canada,
                        can_travel_usa: formik.values.can_travel_usa,
                        can_travel_mexico: formik.values.can_travel_mexico,
                      });
                    }}
                  />
                  <label
                    className=' text-style'
                    htmlFor='flexCheckDefault'
                    style={{ color: use(palette.gray700, palette.gray200) }}
                  >
                    Canada
                  </label>
                  <input
                    className='check-input'
                    type='checkbox'
                    name='can_travel_mexico'
                    id='can_travel_mexico'
                    value={formik.values.can_travel_mexico}
                    checked={formik.values.can_travel_mexico}
                    onChange={() => {
                      formik.setFieldValue('can_travel_mexico', !formik.values.can_travel_mexico);
                      checkTrevalErro({
                        can_travel_canada: formik.values.can_travel_canada,
                        can_travel_usa: formik.values.can_travel_usa,
                        can_travel_mexico: !formik.values.can_travel_mexico,
                      });
                    }}
                  />
                  <label
                    className='text-style'
                    htmlFor='flexCheckDefault'
                    style={{ color: use(palette.gray700, palette.gray200) }}
                  >
                    Mexico
                  </label>
                </div>
                {validateTravel.touched && validateTravel.errorMsg ? (
                  <span className='error-message ms-2'>{validateTravel.errorMsg}</span>
                ) : null}
              </div>
            </div>
            {formik.values.citizenship ? (
              <div className='flexbox'>
                <img src={subRight} className='sub-right-icon' alt={subRight} />
                <div>
                  <div
                    className='driver-license'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <input
                      type='text'
                      className='input-field'
                      placeholder='Passport ID'
                      name='passport'
                      id='passport'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.passport}
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              className='d-flex  justify-content-between align-items-center'
              style={{ marginTop: '4px', width: '300px' }}
            >
              <p
                className='driver-type-text'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginTop: '20px',
                }}
              >
                Add Tag(s)
              </p>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginTop: '10px',
                  marginbottom: '0px',
                }}
                onClick={() => setOpenAddGroup(true)}
              >
                <img src={add} className='' alt='add' /> New Tags
              </p>
            </div>
            <div style={{ marginRight: '12px' }}>
              <div className='custom-select-wrap-contain--basic-detail'>
                <CustomSelect
                  defaultValue='Select Tags..'
                  options={groups}
                  onChange={(value) => formik.setFieldValue('group_id', value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.group_id}
                  id='group_id'
                  name='group_id'
                  patchProperty='id'
                  propertyName='group_name'
                />
              </div>
              <div style={{ marginRight: '12px' }} className='d-flex justify-content-center align-items-center' />
            </div>
          </div>
          <div>
            <p
              className='driver-type-text text-focus'
              style={{
                color: use('black', palette.gray200),
                marginTop: '20px',
                marginbottom: '0',
              }}
            >
              Approved for:
            </p>
            <div className='d-flex' style={{ marginTop: '4px' }}>
              <div className='d-flex justify-content-center align-items-center'>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_fast'
                  id='has_fast'
                  value={formik.values.has_fast}
                  checked={formik.values.has_fast}
                  onChange={formik.handleChange}
                />
                <label
                  className='text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  FAST
                </label>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_twic'
                  id='has_twic'
                  value={formik.values.has_twic}
                  checked={formik.values.has_twic}
                  onChange={formik.handleChange}
                />
                <label
                  className=' text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  TWIC
                </label>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_tsa'
                  id='has_tsa'
                  onChange={formik.handleChange}
                  value={formik.values.has_tsa}
                  checked={formik.values.has_tsa}
                />
                <label
                  className='text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  TSA
                </label>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_hazmat'
                  id='has_hazmat'
                  onChange={formik.handleChange}
                  value={formik.values.has_hazmat}
                  checked={formik.values.has_hazmat}
                />
                <label
                  className='text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  HAZMAT
                </label>
                <div className='d-flex justify-content-center align-items-center'>
                  {formik.values.custom_name.map((val, index) => (
                    <span className='d-flex justify-content-center align-items-center' key={`${val + index}`}>
                      <input
                        className='check-input'
                        type='checkbox'
                        name={val}
                        id={val}
                        checked
                        onChange={() => {
                          const temp = formik?.values?.custom_name || [];
                          temp?.splice(index, 1);
                          formik.setFieldValue('custom_name', [...(temp || [])]);
                        }}
                      />
                      <label
                        className='text-style'
                        htmlFor='flexCheckDefault'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        {val}
                      </label>
                    </span>
                  ))}
                </div>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_custom'
                  id='has_custom'
                  value={formik.values.has_custom}
                  checked={formik.values.has_custom}
                  onChange={formik.handleChange}
                />
                <label
                  className='text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  Add Custom:
                </label>
                {formik.values.has_custom && (
                  <div className='d-flex align-items-center gap-2'>
                    <div
                      className='custume'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <input
                        type='text'
                        maxLength='4'
                        className='input-field'
                        placeholder='4 characters max'
                        name='custom_name'
                        id='custom_name'
                        onChange={(event) => setCustomName(event.target.value)}
                        value={customName}
                        style={{ backgroundColor: use(palette.white, palette.dark600) }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            formik.setFieldValue('custom_name', [...formik.values.custom_name, customName]);
                            setCustomName('');
                          }
                        }}
                      />
                    </div>
                    {!!customName.length && (
                      <CheckIcon
                        className='pointer'
                        width={18}
                        height={18}
                        onClick={() => {
                          formik.setFieldValue('custom_name', [...formik.values.custom_name, customName]);
                          setCustomName('');
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mt-3'>
            <InputLabel>Vehicle Transmission Type</InputLabel>
            <div>
              <div className='d-flex gap-3 align-items-center nowrap'>
                <Radio
                  id='automatic'
                  name='transmission_type'
                  label='Automatic'
                  value={1}
                  checked={formik.values.transmission_type === 1}
                  onChange={() => formik.setFieldValue('transmission_type', 1)}
                />
                <Radio
                  id='manual'
                  name='transmission_type'
                  label='Manual'
                  value={2}
                  checked={formik.values.transmission_type === 2}
                  onChange={() => formik.setFieldValue('transmission_type', 2)}
                />
                {formik.values.transmission_type === 2 && (
                  <Input
                    style={{ width: '80px' }}
                    type='number'
                    name='transmission_speed'
                    placeholder='Speed'
                    value={formik.values.transmission_speed}
                    onChange={(e) => formik.setFieldValue('transmission_speed', e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                )}
                <Radio
                  id='any'
                  name='transmission_type'
                  label='Any'
                  value={3}
                  checked={formik.values.transmission_type === 3}
                  onChange={() => formik.setFieldValue('transmission_type', 3)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        {!stepsDone ? (
          <Button type='button' className='cancel-button' onClick={Cancel}>
            Cancel
          </Button>
        ) : (
          <Button
            type='button'
            className='cancel-button'
            onClick={onSaveAndClose}
            disabled={!formik.isValid || !!validateTravel.errorMsg}
            style={{ opacity: formik.isValid && !validateTravel.errorMsg ? 1 : 0.7 }}
          >
            Save & Close
          </Button>
        )}
        <div className='Basic-detail-footer-btn'>
          {!stepsDone &&
            (loading ? (
              <div className='me-4'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                type='button'
                onClick={onQuickAdd}
                className='quick-add'
                disabled={!formik.isValid || !!validateTravel.errorMsg}
              >
                Quick Add
              </Button>
            ))}
          {onNextloading ? (
            <div className='ms-2'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <button
              type='button'
              onClick={onNextStep}
              className='next-step-btn'
              disabled={!formik.isValid || !!validateTravel.errorMsg}
              style={{ opacity: formik.isValid && !validateTravel.errorMsg ? 1 : 0.7 }}
            >
              Next Step
            </button>
          )}
        </div>
      </Modal.Footer>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={saveGroup} />}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </Form>
  );
};

export default BasicDetails;
