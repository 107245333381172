import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import Radio from 'common/Radio';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import { InputWithIcon, Textarea } from 'common/Input';
import useDateFormat from 'hooks/useDateFormat';
import { getEquipmentRequiredTitle } from 'utils/helpers';
import { formatNumber, getAlphabet, palette } from 'utils/constants';

import { equipmentAction } from 'pages/Shipment/ShipmentBid/components/SubmitBid/SubmitBid.data';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { BID_STATUS, PROGRESS_COLOR, STOP_POINT_TYPES } from 'pages/Shipment/ShipmentBid/ShipmentBid.data';
import { getAwardExpirationData, getBidExpirationData } from '../OfferExpiration/helpers';
import { SBidPreview, SLinearProgress, SSubmitBid, STimeline, STimelineDot } from './BidPreview.styles';

const BidPreview = ({ shipment, bid, offerOptions, onClose }) => {
  const { convertToCustomerTime } = useDateFormat();
  const [progress, setProgress] = useState(0);
  const [expireDate, setExpireDate] = useState('');
  const [expiration, setExpiration] = useState([0, 0, 0]);

  const isExpired = !bid?.offer_accepted && !!expireDate && moment().isAfter(expireDate);
  const { loaded_miles } = shipment || {};
  const {
    award_date,
    offer_accepted,
    shipment_offer,
    posted_amount,
    bid_amount,
    eta,
    selected_contact_user,
    state,
    city,
    shipment_stops_schedule_dates,
    note,
    driver_available_from,
  } = bid || {};
  const emptyMiles = Math.round(eta?.empty_miles || 0);
  const loadedMiles = Math.round(loaded_miles || 0);
  const scheduleDates = Object.values(shipment_stops_schedule_dates || {});

  const getExpirationData = (offerOptions) => {
    const { expire_date, totalDiff, minutesPassed } = award_date
      ? getAwardExpirationData(offerOptions, award_date)
      : getBidExpirationData(offerOptions);

    const days = moment(expire_date).diff(moment().utc(), 'days');
    const hours = moment(expire_date).diff(moment().utc(), 'hours') % 24;
    const minutes = moment(expire_date).diff(moment().utc(), 'minutes') % 60;

    setExpiration([days, hours < 0 ? '00' : hours.toString(), minutes < 0 ? '00' : minutes.toString()]);
    setProgress((minutesPassed * 100) / totalDiff);
    setExpireDate(expire_date);
  };

  useEffect(() => {
    if (!offerOptions) {
      return;
    }

    getExpirationData(offerOptions);

    const intervalId = setInterval(() => {
      getExpirationData(offerOptions);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [offerOptions]);

  return (
    <SBidPreview>
      <CloseIcon width={12} height={12} fill={palette.red500} className='close-icon' onClick={onClose} />
      <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
        {bid?.offer_accepted === 1 && (
          <Typography variant='c2'>Submitted on {convertToCustomerTime(bid.created_at)}</Typography>
        )}
        {bid?.offer_accepted === 2 && (
          <Typography variant='c2'>
            Declined on {convertToCustomerTime(bid.updated_at)} ({bid.decline_reason?.name})
          </Typography>
        )}
        {bid?.offer_accepted === 6 && (
          <Typography variant='c2'>
            Bid Declined by {convertToCustomerTime(bid.updated_at)} ({bid.decline_reason_text})
          </Typography>
        )}
        {bid?.offer_accepted === 4 && <div />}
        {!isExpired && (!bid?.offer_accepted || bid?.offer_accepted === 3) && (
          <div className='d-flex align-items-end gap-2'>
            <Typography variant='h1'>
              {expiration[0] ? `${expiration[0]}d` : ''} {expiration[1]}h{' '}
              {expiration[2]?.length === 1 ? `0${expiration[2]}` : expiration[2]}m
            </Typography>
            <Typography variant='s2' style={{ color: palette.gray500 }}>
              remaining until bidding is over
            </Typography>
          </div>
        )}
        {!!isExpired && (
          <Typography variant='c2'>Offer has expired automatically on {convertToCustomerTime(expireDate)}</Typography>
        )}
        <Chip
          label={isExpired ? 'OFFER EXPIRED' : BID_STATUS[offer_accepted]?.label || 'OFFERED'}
          labelColor={isExpired ? palette.red500 : BID_STATUS[offer_accepted]?.color || palette.gray700}
          bgColor={isExpired ? palette.red0 : BID_STATUS[offer_accepted]?.bgColor || palette.gray50}
          fontSize='11px'
          fontWeight={500}
        />
      </div>
      <div className='mb-3'>
        <SLinearProgress
          variant='determinate'
          value={[1, 2, 4, 6].includes(bid?.offer_accepted) || isExpired ? 100 : progress}
          $bgColor={isExpired ? palette.red500 : PROGRESS_COLOR[bid?.offer_accepted] || palette.green400}
        />
      </div>

      <div className='d-flex justify-content-between align-items-center'>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {shipment.shipment_stops?.length} Stops
        </Typography>
        {!!Number(posted_amount) && (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Posted Rate ${formatNumber(posted_amount)}
          </Typography>
        )}
      </div>
      <Divider margin='16px 0' />
      <SSubmitBid>
        <div className='form-wrapper'>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                SELECT CONTACT
              </Typography>
            </div>
            <div className='d-flex gap-3'>
              <div>
                <Autocomplete
                  width='180px'
                  size='small'
                  labelKey='contact_name'
                  name='contact'
                  options={[]}
                  value={selected_contact_user || null}
                  onChange={() => null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                STARTING LOCATION
              </Typography>
            </div>
            <div className='d-flex gap-2'>
              <div>
                <Autocomplete
                  width='90px'
                  size='small'
                  labelKey='short_name'
                  placeholder='State'
                  name='state'
                  options={[]}
                  value={state || null}
                  onChange={() => null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled
                />
              </div>
              <div>
                <Autocomplete
                  width='180px'
                  size='small'
                  name='city'
                  placeholder='City'
                  options={[]}
                  value={city || null}
                  onChange={() => null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                BID RATE AMOUNT
              </Typography>
            </div>
            <div>
              <InputWithIcon
                type='number'
                width='140px'
                size='small'
                name='bid_amount'
                placeholder='0.00'
                value={bid_amount || ''}
                onChange={() => null}
                disabled
              />
            </div>
          </div>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                RATE PER MILE
              </Typography>
            </div>
            <div>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                $
                {formatNumber(
                  (bid_amount ? Number(bid_amount) : shipment_offer.offer_amount) /
                    ((eta?.empty_miles || 0) + loaded_miles)
                )}
              </Typography>{' '}
            </div>
          </div>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                EMPTY MILES
              </Typography>
            </div>
            <div>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {emptyMiles || 0}
              </Typography>
            </div>
          </div>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                LOADED MILES
              </Typography>
            </div>
            <div>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {loadedMiles || 0}
              </Typography>
            </div>
          </div>
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                SHIPMENT NOTES
              </Typography>
            </div>
            <div className='flex-grow-1'>
              <Typography variant='c2' style={{ color: palette.gray500 }}>
                {shipment_offer?.note || '-'}
              </Typography>
            </div>
          </div>
          <Divider margin='16px 0' />
          <div>
            <div>
              <STimeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <STimelineDot $bgColor={palette.goldDark}>
                      <div>D</div>
                    </STimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className='d-flex gap-3'>
                      <div className='d-flex flex-column'>
                        <Typography variant='b2' className='nowrap'>
                          DETROIT, MI
                        </Typography>
                        <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                          {convertToCustomerTime(driver_available_from)} (Truck Available)
                        </Typography>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                {shipment.shipment_stops?.map((stop, index) => (
                  <TimelineItem key={stop.id}>
                    <TimelineSeparator>
                      <STimelineDot $bgColor={STOP_POINT_TYPES[stop.stop_point_type].bgColor}>
                        <div>{getAlphabet(index)}</div>
                      </STimelineDot>
                      {index !== shipment.shipment_stops.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className='d-flex justify-content-between gap-2'>
                        <div className='d-flex flex-column'>
                          <Typography variant='b2' className='nowrap'>
                            {stop.stop_point?.city?.name}, {stop.stop_point?.state?.short_name}
                          </Typography>
                          <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                            {moment(stop.scheduled_date).format('MM/DD/YYYY')} {stop.from}
                            {stop?.to && stop?.to !== stop.from && ` - ${stop.to}`} (
                            {SCHEDULED_DATE_TYPE_OBJ[Number(stop.scheduled_type)]?.shortName})
                          </Typography>
                          {stop.equipment_type_data && (
                            <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                              {stop.equipment_type_length_value?.length}{' '}
                              {getEquipmentRequiredTitle(stop.equipment_type_data)?.title} ({equipmentAction(stop)})
                            </Typography>
                          )}
                        </div>
                        <div className='d-flex flex-column gap-2 can-handle-box'>
                          <Typography variant='b2' style={{ color: palette.gray700 }}>
                            Can You Handle This ETA?
                          </Typography>
                          <div className='d-flex gap-3'>
                            <Radio
                              value={1}
                              checked={scheduleDates?.[index] === null}
                              onChange={() => null}
                              label='Yes'
                              labelColor={palette.gray700}
                              disabled
                            />
                            <Radio
                              value={0}
                              checked={scheduleDates?.[index] !== null}
                              onChange={() => null}
                              label='No'
                              labelColor={palette.gray700}
                              disabled
                            />
                          </div>
                          {scheduleDates?.[index] !== null && (
                            <div className='d-flex gap-2'>
                              <DateTimePicker
                                size='small'
                                value={moment(scheduleDates[index]).toDate()}
                                minDate={moment().toDate()}
                                disabled
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </STimeline>
            </div>
          </div>
          <Divider margin='16px 0' />
          <div className='form-row'>
            <div>
              <Typography variant='overLine' style={{ color: palette.gray700 }}>
                CARRIER NOTES
              </Typography>
            </div>
            <Textarea className='w-100' rows={3} placeholder='Write your notes here...' value={note} disabled />
          </div>
        </div>
      </SSubmitBid>
    </SBidPreview>
  );
};

export default BidPreview;
