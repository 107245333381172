import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { capitalize, range } from 'lodash';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import Loader from 'common/Loader';
import DatePicker from 'common/DatePicker';
import { getAbsenceList } from 'Api/Absence';
import { eventStyleGetter } from './AbsenceCalendar.data';
import { SCustomHeaderWrapper, STitleWrapper, SWrapper } from './AbsencesCalendar.styles';

function CustomYearDropdownPicker({ date, changeYear, decreaseYear, increaseYear }) {
  return (
    <SCustomHeaderWrapper>
      <button className='navigation-button' onClick={decreaseYear}>
        {'<'}
      </button>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
        <select
          value={date.getFullYear().toString()}
          onChange={(e) => {
            changeYear(e.target.value);
          }}
        >
          {range(2010, 2051).map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
      <button className='navigation-button' onClick={increaseYear}>
        {'>'}
      </button>
    </SCustomHeaderWrapper>
  );
}

function RBCToolbar(props) {
  const { view, date, views, onView, onNavigate } = props;

  const onDateChange = (date) => {
    onNavigate('DATE', moment(date).toDate());
  };

  const goToView = (view) => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate('PREV');
  };
  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  return (
    <div className='rbc-toolbar'>
      <div className='rbc-btn-group'>
        <button onClick={goToToday}>Today</button>
        <button onClick={goToBack}>Back</button>
        <button onClick={goToNext}>Next</button>
      </div>
      <div className='rbc-toolbar-label'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {view === 'month' && (
            <div style={{ width: 'fit-content' }}>
              <DatePicker
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                value={date}
                onChange={onDateChange}
                dateFormat='MM/yyyy'
                showMonthYearPicker
                renderCustomHeader={(props) => CustomYearDropdownPicker(props)}
                customInput={
                  <STitleWrapper>
                    {moment(date).format('MMMM YYYY')}
                    <ArrowDown />
                  </STitleWrapper>
                }
              />
            </div>
          )}
          {view === 'week' && (
            <div style={{ width: 'fit-content' }}>
              <DatePicker
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                value={date}
                onChange={onDateChange}
                customInput={
                  <STitleWrapper>
                    {moment(date).startOf('week').format('MMMM DD')} - {moment(date).endOf('week').format('MMMM DD')}
                    <ArrowDown />
                  </STitleWrapper>
                }
              />
            </div>
          )}
          {view === 'day' && (
            <div style={{ width: 'fit-content' }}>
              <DatePicker
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                value={date}
                onChange={onDateChange}
                customInput={
                  <STitleWrapper>
                    {moment(date).format('dddd MMMM DD')}
                    <ArrowDown />
                  </STitleWrapper>
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className='rbc-btn-group'>
        {views?.map((item) => (
          <button onClick={() => goToView(item)} key={item} className={view === item ? 'rbc-active' : ''}>
            {capitalize(item)}
          </button>
        ))}
      </div>
    </div>
  );
}

const AbsencesCalendar = ({ updateIndex, onItemClick }) => {
  const [absences, setAbsences] = useState([]);
  const [absencesData, setAbsencesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('month');
  const localizer = momentLocalizer(moment);

  const getAbsences = async () => {
    setLoading(true);
    try {
      const { data } = await getAbsenceList({ page: '1', itemsPerPage: '10000' });

      setAbsencesData(data);
      setAbsences(
        data.map((i) => ({
          id: i.id,
          title: `${i.name} (${i.absence_type?.name || i.type})`,
          start: moment(i.absence_start_date).startOf('day').toDate(),
          end: moment(i.absence_end_date).endOf('day').toDate(),
          type: i.type,
          user_type: i.user_type,
        }))
      );
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  useEffect(() => {
    getAbsences();
  }, [updateIndex]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <Calendar
        view={view}
        className='custom-calendar'
        events={absences}
        startAccessor='start'
        endAccessor='end'
        defaultDate={moment().toDate()}
        localizer={localizer}
        views={['day', 'week', 'month']}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={(event) =>
          onItemClick(absencesData.find((i) => i.id === event.id && i.user_type === event.user_type))
        }
        onView={(newView) => {
          setView(newView);
        }}
        showAllEvents
        components={{ toolbar: RBCToolbar }}
      />
    </SWrapper>
  );
};

export default AbsencesCalendar;
