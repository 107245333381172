import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderStar from 'components/HeaderStar';
import ExportCsv from 'componentsV2/ExportCsv';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomButton from 'components/CustomButton/CustomButton';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { palette } from 'utils/constants';
import styles from './PlanerHeader.module.css';
import { Typography } from '../Typography';

const assignmentSwitch = [
  { key: 'assignment', label: 'Pending Assignment', value: 0 },
  { key: 'recurring', label: 'Recurring (Lanes)', value: 1 },
];

const PlannerHeader = ({
  onChange,
  setIsOpen,
  setContinueDraft,
  headerSwitchValue,
  exportLanesParams,
  setInitialValueOutSide,
}) => {
  const location = useLocation();
  const target = useRef(null);

  useEffect(() => {
    if (location?.state?.openCreate) {
      setIsOpen(true);
    }
  }, [location?.state?.openCreate]);

  return (
    <div className={styles.plannerHeader_wrapper}>
      <HeaderStar title='Plan Shipments' />
      <SwitchRadio
        name='planerSwitch'
        onChange={(_, value) => onChange(+value)}
        items={assignmentSwitch}
        value={headerSwitchValue}
      />
      <div className='d-flex gap-4'>
        {!!localStorage.getItem('createShipmentData') && (
          <div
            className='d-flex gap-2 align-items-center pointer'
            onClick={() => {
              const draft = JSON.parse(localStorage.getItem('createShipmentData'));
              setInitialValueOutSide(draft);
              setContinueDraft(true);
              setIsOpen(true);
            }}
          >
            <DeleteIcon
              fill={palette.red500}
              onClick={(e) => {
                e.stopPropagation();
                setInitialValueOutSide({});
                localStorage.removeItem('createShipmentData');
              }}
            />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Continue Draft
            </Typography>
          </div>
        )}

        <div className='d-flex align-items-center gap-3'>
          <ExportCsv
            type={headerSwitchValue === 1 ? 'recurring-lanes' : 'planner'}
            exportParams={exportLanesParams}
            height={36}
          />
          <CustomButton
            ref={target}
            type='primary'
            title={headerSwitchValue === 1 ? 'Create Recurring' : 'Create Shipment'}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};
export default PlannerHeader;
