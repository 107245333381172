import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ExportCsv from 'componentsV2/ExportCsv';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';

import { GetEquipmentTrailer, GetEquipmentVehicle, getInventoryTab } from 'Api/StopPoint';
import { onChangeEquipmentHistoryTab, onDateRangeChange } from 'store/reducers/stopPoint.reducer';
import EquipmentLogs from 'pages/landing/Equipment/EquipmentProfile/EquipmentLogs';
import TableFilters from '../../TableFilters';
import { useColumns } from './EquipmentHistory.data';
import styles from './EquipmentHistory.module.css';

const EquipmentHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dateRange, historyEquipmentType } = useSelector((state) => state?.stopPoints);
  const { convertToCustomerTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [sort, setSort] = useState({ field: 'equipment_id', sortBy: 'desc' });
  const [equipmentLogsData, setEquipmentLogsData] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    query: '',
    page: 1,
    history: 1,
    page_size: 10,
    drivers: [],
    equipment_types: [],
    groups: [],
  });
  const [filter, setFilter] = useState({
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    filterTableTop: [],
    selectedValues: [],
    applyAllUsersModal: { value: '0' },
  });
  const [vehicles, setVehicles] = useState(null);

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  async function getVehiclesData() {
    setLoading(true);
    const {
      page,
      page_size,
      tab,
      query,
      drivers,
      history,
      equipment_types,
      groups,
      customer_id,
      first_stop,
      second_stop,
      ...rest
    } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    try {
      const params = {
        page,
        history: 1,
        page_size,
        stop_point_id: id,
        customer_id,
        first_stop,
        second_stop,
        drivers: drivers?.length && historyEquipmentType !== 2 ? drivers.map((i) => i.id) : undefined,
        equipment_types: equipment_types?.length ? equipment_types.map((i) => i.id) : undefined,
        groups: groups?.length ? groups.map((i) => i.id) : undefined,
        from: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        [sortField]: sort.sortBy,
        ...(!!query && { query }),
        ...rest,
      };

      const vehicles =
        historyEquipmentType === 2 ? await GetEquipmentTrailer(params) : await GetEquipmentVehicle(params);
      setVehicles(vehicles);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const onChangeTab = (n) => {
    dispatch(onChangeEquipmentHistoryTab(Number(n.value)));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.page_size}
      totalCount={vehicles?.total}
      totalLength={vehicles?.data?.length}
      lastPage={vehicles?.last_page}
      currentPage={vehicles?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  useEffect(() => {
    if (selectedFilters.query !== debouncedSearch) {
      setSelectedFilters({ ...selectedFilters, query: debouncedSearch, page: 1 });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    getVehiclesData();
  }, [selectedFilters, dateRange, sort, historyEquipmentType]);

  useEffect(() => {
    getInventoryTab({ stop_point_id: id, type: 'history' }).then((res) => {
      const newFilterItems = res.data.map((item, index) => ({
        ...item,
        key: item.name,
        vehicleOrTrailer: item.equipment_type,
        label: item.name,
        value: index + 1,
        id: index + 1,
        tabId: item.id,
        count: undefined,
      }));
      setFilter((prevState) => {
        return {
          ...prevState,
          filterTableTop: newFilterItems,
        };
      });
    });

    return () => {
      dispatch(
        onDateRangeChange({
          start: null,
          end: null,
        })
      );
    };
  }, []);

  const columns = useColumns({ sort, sortingQuery, setEquipmentLogsData });

  return (
    <div className={styles.workingHours_wrapper}>
      <div className={styles.workingHours_information}>
        <div className='d-flex justify-content-between align-items-start ps-4 pe-4 mb-3'>
          <div className='d-flex align-items-center gap-3'>
            <TableFilters
              dateRange={dateRange}
              setDateRange={(val) => dispatch(onDateRangeChange(val))}
              search={search}
              setSearch={setSearch}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <ExportCsv
              type='stop-point-history'
              exportParams={{ stop_point_id: id, equipment_type: historyEquipmentType === 1 ? 'vehicle' : 'trailer' }}
            />
          </div>
          <CustomButton
            type='primary'
            title='Run Report'
            styleButton={{ padding: '4px 12px', margin: 0, whiteSpace: 'nowrap' }}
            onClick={() => navigate(`/reports/37?stopPointId=${id}`)}
          />
        </div>
        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={filter.filterTableTop}
          value={historyEquipmentType}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
        {loading ? (
          <Preloader />
        ) : (
          <MaterialTableWrapper
            data={vehicles?.data}
            rowPerPage={selectedFilters.page_size}
            components={{
              Pagination: PaginationComponent,
            }}
            style={{ backgroundColor: palette.white }}
            columns={columns}
            onRowClick={(e, row) => {
              const equipmentType = row.equipment?.equipment_type_id >= 12 ? 'trailer' : 'vehicle';
              const startDate = convertToCustomerTime(row.arrived_date || new Date(), 'YYYY-MM-DD');
              const endDate = convertToCustomerTime(row.departed_date || new Date(), 'YYYY-MM-DD');
              navigate(
                `/equipment-profile/${equipmentType}/${row.equipment_id}?startDate=${startDate}&endDate=${endDate}`
              );
            }}
          />
        )}
      </div>
      {!!equipmentLogsData && (
        <EquipmentLogs
          open={!!equipmentLogsData}
          profileData={equipmentLogsData}
          onClose={() => setEquipmentLogsData(null)}
        />
      )}
    </div>
  );
};
export default EquipmentHistory;
