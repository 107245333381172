import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { STATUSES } from 'components/StaffHeader/constat';
import { SDatePicker } from 'pages/OfferedShipments/ShipmentsTable/components/AdvancedFilter/AdvancedFilter.styles';
import { getAssignToGroupBillingCharges } from 'Api/Planner';
import { GetSettingsDepartments } from 'Api/CompanySettings';
import classes from './AdvancedFilter.module.scss';

const AdvancedFilter = ({ open, filter, setFilter, setSearch, onSearch, children }) => {
  const [groupsData, setGroupsData] = useState([]);
  const [badgeData, setBadgeData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [joinDateRange, setJoinDateRange] = useState(filter.joinDateRange || [null, null]);

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name: item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const getFilterByGroups = async () => {
    try {
      const { data } = await getAssignToGroupBillingCharges();
      data && setGroupsData(data);
    } catch (e) {
      // Do noting
    }
  };

  const getDepartmentsList = async () => {
    try {
      setLoadingDepartments(true);
      const { data } = await GetSettingsDepartments();
      setDepartments(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingDepartments(false);
    }
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(filter.login_status
        ? [
            {
              title: 'Status',
              children: filter.login_status.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'login_status' })
              ),
            },
          ]
        : []),
      ...(filter.group
        ? [
            {
              title: 'Tag',
              children: filter.group.map((item) => normalizeBadgeData(item, 'group_name', 'id', { key: 'group' })),
            },
          ]
        : []),
      ...(filter.department_id
        ? [
            {
              title: 'Department',
              children: filter.department_id.map((item) =>
                normalizeBadgeData(item, 'department_name', 'id', { key: 'department_id' })
              ),
            },
          ]
        : []),
    ];
  }, []);

  const onSearchFilter = () => {
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
      } else {
        newFilter[key] = newItemValue;
      }
    } else if (typeof itemValue === 'string') {
      delete newFilter[key];
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    setJoinDateRange([null, null]);
    setFilter({});
    onSearch({});
    setSearch('');
    setBadgeData([]);
  };

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }
    return setFilter(newFilter);
  };

  useEffect(() => {
    getDepartmentsList();
    getFilterByGroups().catch();
  }, []);

  return (
    <>
      {children?.({ onSearchFilter })}
      <Collapse in={open}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <InputLabel className='mb-0'>Status</InputLabel>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.login_status || []}
                options={STATUSES}
                onChange={(e, value) => onChangeMultiSelectValues('login_status', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <InputLabel className='mb-0'>Tags</InputLabel>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.group || []}
                options={groupsData}
                onChange={(e, value) => onChangeMultiSelectValues('group', value)}
                getTagLabel={(option) => (option ? option?.group_name : '')}
                getOptionLabel={(option) => (option ? option?.group_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <InputLabel className='mb-0'>Department</InputLabel>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.department_id || []}
                options={departments}
                loading={loadingDepartments}
                onChange={(e, value) => onChangeMultiSelectValues('department_id', value)}
                getTagLabel={(option) => (option ? option?.department_name : '')}
                getOptionLabel={(option) => (option ? option?.department_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 200 }}>
              <InputLabel className='mb-0'>Join Date</InputLabel>
              <div className='d-flex align-items-center gap-1 position-relative'>
                <SDatePicker
                  selectsRange
                  renderStart={false}
                  shouldCloseOnSelect={false}
                  startDate={joinDateRange[0]}
                  endDate={joinDateRange[1]}
                  placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                  onChange={(dates) => {
                    setJoinDateRange(dates);
                    setFilter((prevState) => ({ ...prevState, joinDateRange: dates }));
                  }}
                />
                <DeleteIcon
                  className='delete-icon'
                  onClick={() => {
                    setJoinDateRange([null, null]);
                    setFilter((prevState) => ({ ...prevState, joinDateRange: [null, null] }));
                  }}
                />
              </div>
            </div>
          </div>
          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AdvancedFilter;
