import React from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { SPayTypeWrapper } from '../UserInfo.styles';

const PayRate = ({ data }) => {
  const { currency } = useSelector((state) => state.root);

  if (!data) {
    return null;
  }

  return (
    <SPayTypeWrapper>
      {data.pay_option === 'mile' && (
        <>
          <Chip label={`$${data.mile_loaded_amt} Loaded`} />
          -
          <Chip label={`$${data.mile_empty_amt} Empty`} />
        </>
      )}
      {data.pay_option === 'percentage' && (
        <Typography variant='s2'>{data?.shipment_percentage}% of Shipment</Typography>
      )}
      {data.pay_option === 'per_hour' && (
        <Typography variant='s2'>
          {currency}
          {Number(data?.per_hour_worked)?.toFixed(2)}
        </Typography>
      )}
      {data.pay_option === 'flat_rate' && <Typography variant='s2'>Flat Per Shipment</Typography>}
    </SPayTypeWrapper>
  );
};

export default PayRate;
