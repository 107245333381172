import React, { useMemo } from 'react';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';

const Paperwork = ({ data }) => {
  const { use } = useTheme();
  const received = useMemo(() => data.shipment_billing.every((item) => !!item.paperwork_received), [data]);

  return (
    <Typography
      variant='b2'
      style={{ color: received ? use(palette.green500, palette.green400) : use(palette.red500, palette.red800) }}
    >
      {received ? 'Received' : 'Not Received'}
    </Typography>
  );
};

export default Paperwork;
