import React, { useEffect, useState } from 'react';
import { UpdatePlannerPopoverSettings } from 'Api/Planner';
import styles from './PopoverSettings.module.css';
import TableColumn from './TableColumn';
import ColumnOrder from './ColumnOrder';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomButton from '../CustomButton/CustomButton';
import { amountSwitch } from './constants';

const PopoverSettings = ({
  onChangeOrder,
  filter,
  dragItem,
  updateFilter,
  setShowPopover,
  switchRadioButtons,
  onsStTableColumn,
  onSetSwitchRadioButtons,
  tableColumn,
}) => {
  const [localState, setLocalState] = useState([]);
  const [localDragItem, setLocalDragItem] = useState([]);
  const [switchRadioButtonsLocal, setSwitchRadioButtonsLocal] = useState({});

  function UpdateTableSettings(dataJson) {
    return UpdatePlannerPopoverSettings(dataJson).then((res) => res);
  }

  const onChangeHandler = (name, value) => {
    setSwitchRadioButtonsLocal({ ...switchRadioButtonsLocal, [name]: value });
  };

  const onChangeTick = (value) => {
    updateFilter({ ...filter, forAll: value });
  };

  const onClickApply = () => {
    const { forAll } = filter;
    const { amount, date, time } = switchRadioButtonsLocal;

    updateFilter({ ...filter, tableColumn: localState, switchRadioButtons: switchRadioButtonsLocal });
    onsStTableColumn([...localState]);
    onSetSwitchRadioButtons({ ...switchRadioButtonsLocal });
    onChangeOrder(localDragItem);
    const updateData = {
      type: 'owner_operator',
      forAll,
      data: {
        amount: +amount,
        date: +date,
        time: +time,
        columns: {},
        columns_order: {},
      },
    };
    localState.forEach((el) => {
      const { value, checked } = el;
      updateData.data.columns[value] = checked;
    });

    updateData.data.columns_order = localDragItem.map((el) => el.value);

    UpdateTableSettings(updateData).then((res) => res && res?.data && setShowPopover(false));
  };

  useEffect(() => {
    setSwitchRadioButtonsLocal(switchRadioButtons);
  }, [switchRadioButtons]);

  useEffect(() => {
    setLocalDragItem(dragItem);
  }, [dragItem]);
  return (
    <div>
      <div className={styles.popoverSettings_title_wrapper}>
        <span>Table Settings</span>
      </div>

      <TableColumn
        localState={localState}
        tableColumn={tableColumn}
        setLocalState={(param) => setLocalState(param)}
        localDragItem={localDragItem}
        onChangeOrderLocal={(items) => setLocalDragItem(items)}
      />

      <ColumnOrder localDragItem={localDragItem} onChangeOrderLocal={(items) => setLocalDragItem(items)} />
      <div />
      <div>
        <SwitchRadio
          name='amount'
          style={{ marginLeft: 10, position: 'static' }}
          items={amountSwitch}
          title='Amount  '
          value={+switchRadioButtonsLocal.amount}
          onChange={onChangeHandler}
        />
      </div>
      <div style={{ borderBottom: '1px solid #D5DBE5', margin: '16px 0 8px', height: '1px' }} />
      <div className={styles.popoverSettings_footer}>
        <CustomCheckbox style={{ marginLeft: 10 }} checked={filter.forAll} onChange={onChangeTick}>
          <span className={styles.footer_title_checkbox}>Apply to all users</span>
        </CustomCheckbox>

        <div className={styles.footer_right}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '2px 8px', marginRight: 16 }}
            onClick={() => setShowPopover(false)}
          />

          <CustomButton type='primary' title='Apply' styleButton={{ padding: '2px 8px' }} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default PopoverSettings;
