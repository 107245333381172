import React, { useEffect, useState } from 'react';
import styles from './TableSettingPopover.module.css';

const TableColumn = ({ types, localState, filter, setLocalState, updateFilter, setLocalDragItem }) => {
  const [cols, setCols] = useState([]);

  useEffect(() => {
    setLocalState(types);
    setCols(filter?.ColumnOrder);
  }, []);

  const onClickHandler = (item, value) => {
    for (const i in localState) {
      if (i === item) {
        setLocalState({ ...localState, [item]: value });
      }
      if (value) {
        if (!cols.includes(item)) {
          cols.push(item);
        }
      } else {
        cols.forEach((c) => {
          if (c === item) {
            const index = cols.indexOf(c);
            cols.splice(index, 1);
          }
        });
      }
      updateFilter({ ...filter, ColumnOrder: cols });
      setLocalDragItem([...cols]);
    }
  };

  return (
    <>
      <span className={styles.table_column_title}>Table Column</span>
      <div className={`${styles.tableColumn}`}>
        {types &&
          Object.keys(types).map((item, i) => {
            return (
              <TableColumnItem key={item?.id || i} item={item} value={types[item]} onClickHandler={onClickHandler} />
            );
          })}
      </div>
    </>
  );
};

export default TableColumn;

const TableColumnItem = ({ item, value, onClickHandler }) => {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    value === true ? setChecked(true) : setChecked(false);
  }, []);

  return (
    <div
      className={`${styles.tableColumn_button} ${checked ? '' : styles.active}`}
      onClick={() => {
        setChecked(!checked);
        onClickHandler(item, !checked);
      }}
    >
      <span className={`${styles.tableColumn_text} ${checked ? '' : styles.active}`}>
        {item === 'next_pm'
          ? 'Next PM'
          : item === 'vin'
          ? 'VIN'
          : item === 'equipment_id'
          ? 'Equipment ID'
          : item === 'group'
          ? 'Tags'
          : item.replaceAll('_', ' ')}
      </span>
    </div>
  );
};
