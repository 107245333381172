import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { palette } from 'utils/constants';
import { getOptions } from './Charts.data';

ChartJS.register(ArcElement, Tooltip, Legend);

const ControlDeviceChart = ({ chartData }) => {
  const data = {
    labels: (chartData || []).map((item) => `${item.control_device}`),
    datasets: [
      {
        data: (chartData || []).map((item) => item.total_count),
        backgroundColor: [palette.green400, palette.indigo400, palette.red400, palette.orange400, palette.gray400],
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getOptions(chartData)} data={data} />;
};

export default ControlDeviceChart;
