import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BackdropLoader } from 'common/Loader';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateShouldUpdateConnectedServices } from 'store/reducers/root.reducer';
import { authorizeQuickbooksService } from 'Api/AccountingReceivables';

const QuickbooksAuthorize = () => {
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authorization_code = searchParams.get('code');
  const realmId = searchParams.get('realmId');
  const [loading, setLoading] = useState(false);

  const authorizeQuickbook = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('authorization_code', authorization_code);
      formData.append('realmId', realmId);

      await authorizeQuickbooksService(formData);
      dispatch(updateShouldUpdateConnectedServices(Date.now()));
      showToaster({ type: 'success', message: 'Connection to service established!' });
    } catch (e) {
      showToaster({ type: 'erroe', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
      navigate('/connectedservicesediapiintegrations', { replace: true });
    }
  };

  useEffect(() => {
    if (authorization_code && realmId) {
      authorizeQuickbook();
    } else {
      navigate('/connectedservicesediapiintegrations', { replace: true });
    }
  }, []);

  return <BackdropLoader loading={loading} />;
};

export default QuickbooksAuthorize;
