import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa2.svg';
import { ReactComponent as CardIcon } from 'assets/icons/cashCard.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { getDriverStaffList, getStripeFees, purchaseCard } from 'Api/CardManagement';
import CardHolder from './components/CardHolder';
import { validationSchema } from './validationSchema';
import { cardInfo, initialValues, requestBodyConverter } from './PurchaseCard.data';
import { SAddMore, SBodyWrapper, SCardHolders, SFeesBox } from './PurchaseCard.styles';

const PurchaseCard = ({ open, onClose, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [driverStaff, setDriverStaff] = useState([]);
  const [company, setCompany] = useState(null);
  const [fees, setFees] = useState(null);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = requestBodyConverter(values);
      await purchaseCard(body);
      showToaster({ type: 'success', message: 'Cards have been successfully purchased!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getCompanyData = async () => {
    try {
      const res = await GetCompanyProfile();
      setCompany(res);
    } catch (e) {
      // Do nothing
    }
  };

  const getDriverStaff = async () => {
    try {
      const { data } = await getDriverStaffList();
      setDriverStaff(data);
    } catch (e) {
      // Do nothing
    }
  };

  const addNewCardholder = () => {
    handleChange(`card_holders[${values.card_holders.length}]`, { row_id: Date.now() });
  };

  const getFees = async () => {
    try {
      const { data } = await getStripeFees();
      setFees(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onCardHolderSuccess = (data, index) => {
    handleChange(`card_holders[${index}]`, { row_id: values.card_holders[index].row_id, ...data });
  };

  const onCardHolderRemove = (index) => {
    handleChange(
      `card_holders`,
      values.card_holders.filter((el, i) => i !== index)
    );
  };

  useEffect(() => {
    getDriverStaff();
    getCompanyData();
    getFees();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Purchase Card'
      $bgColor={palette.gray0}
      $width='90vw'
      $maxWidth='1100px'
      $minWidth='1100px'
      padding='0'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Purchase',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <SBodyWrapper>
        <div className='form-section'>
          <div className='d-flex flex-column gap-4'>
            <div>
              <Autocomplete
                required
                label='Card Provider'
                name='card_provider'
                value={values.card_provider}
                options={['Truckin Digital']}
                onChange={(e, val) => handleChange('card_provider', val)}
                error={touchedErrors.card_provider}
              />
            </div>
            <SCardHolders>
              {values.card_holders.map((item, index) => (
                <CardHolder
                  key={item.row_id}
                  index={index}
                  driverStaff={driverStaff}
                  company={company}
                  onSuccess={(data) => onCardHolderSuccess(data, index)}
                  onRemove={() => onCardHolderRemove(index)}
                  isCreated={!!values.card_holders[index]?.id}
                  fees={fees}
                />
              ))}
              <SAddMore onClick={addNewCardholder}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Add Another
                </Typography>
              </SAddMore>
            </SCardHolders>
          </div>
        </div>
        <div className='right-section'>
          <div className='d-flex align-items-center gap-2 mb-2'>
            <Typography variant='button2'>Truckin Digital Cash Card</Typography>
            <Chip label='PREPAID' bgColor={palette.indigo50} labelColor={palette.indigo500} />
          </div>
          <div className='card-icon-container'>
            <CardIcon />
            <VisaIcon className='visa-icon' />
          </div>
          <div className='d-flex flex-column gap-2'>
            {cardInfo.map((item) => (
              <div key={item} className='d-flex gap-2'>
                <CheckIcon style={{ marginTop: 3 }} />
                <Typography variant='s2'>{item}</Typography>
              </div>
            ))}
          </div>
          <SFeesBox>
            <div className='d-flex flex-column gap-2'>
              <div>
                <Typography variant='button2' style={{ color: palette.indigo500 }}>
                  $0.00
                </Typography>{' '}
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  monthly fee
                </Typography>
              </div>
              <div>
                <Typography variant='button2' style={{ color: palette.indigo500 }}>
                  ${formatNumber(fees?.issuing_card_cost_per_card)}
                </Typography>{' '}
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  per active card, one time + shipping
                </Typography>
              </div>
              <div>
                <Typography variant='button2' style={{ color: palette.indigo500 }}>
                  $0.00
                </Typography>{' '}
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  account set-up fee
                </Typography>
              </div>
            </div>
          </SFeesBox>
          <div className='terms'>
            <Typography variant='c1' style={{ color: palette.gray700 }}>
              By clicking the Purchase button, you agree to the{' '}
              <a href='https://stripe.com/legal/connect-account' target='_blank' rel='noreferrer'>
                Stripe Connected Account Agreement
              </a>
              ,{' '}
              <a href='https://stripe.com/legal/ssa#services-terms' target='_blank' rel='noreferrer'>
                Stripe Issuing Accountholder Terms
              </a>
              , and you consent to electronic signatures as set forth in the Issuing Bank Terms located{' '}
              <a href='https://stripe.com/legal/issuing/celtic-authorized-user-terms' target='_blank' rel='noreferrer'>
                here
              </a>
              .
            </Typography>
          </div>
        </div>
      </SBodyWrapper>
    </Modal>
  );
};

export default PurchaseCard;
