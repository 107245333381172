import React, { useEffect, useState } from 'react';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { getIncidentSummary } from 'Api/DotMonitoring';
import { exportInCSV, useColumns } from './IncidentSummary.data';
import { SBoxContent } from './IncidentSummary.styles';
import { SummaryWrapper } from '../../StatsSection.styles';

const IncidentSummary = ({ year, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const [countsData, setCountsData] = useState({});
  const [sort, setSort] = useState({ field: '', sortBy: '' });
  const [anchorTag, setAnchorTag] = useState('');

  const getCounts = async () => {
    try {
      let params = {};

      if (!year || year === 'All') {
        params = {};
      } else {
        params = {
          from_date: `01-01-${year}`,
          to_date: `31-12-${year}`,
        };
      }
      setLoading(!sort.field);
      if (sort.field) {
        params.sort_desc = sort.sortBy === 'asc' ? 1 : 0;
      }
      const { data } = await getIncidentSummary(params);
      setCountsData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, [year, sort, refreshData]);

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    if (countsData?.incident_type?.length) {
      exportInCSV(countsData.incident_type, setAnchorTag);
    }
  }, [countsData?.incident_type]);

  const columns = useColumns(countsData?.total_incident, sortingQuery);

  return (
    <SBoxContent style={{ rowGap: '8px' }}>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Incident Summary
          </Typography>
          <span className='receivable-chart-section-tag-indigo' style={{ margin: 0 }}>
            {countsData?.total_incident}
          </span>
        </div>
        <a
          href={anchorTag}
          download='incident_summary.csv'
          className={`export-csv-btn ${!anchorTag ? 'non-clickable' : ''}`}
        >
          <ExportIcon fill={palette.indigo500} />
          <span>Download CSV</span>
        </a>
      </div>
      {!loading && !!countsData && (
        <SummaryWrapper>
          <MaterialTableWrapper
            data={countsData?.incident_type}
            style={{ backgroundColor: palette.white, height: '400px', overflow: 'auto' }}
            columns={columns}
          />
        </SummaryWrapper>
      )}
    </SBoxContent>
  );
};

export default IncidentSummary;
