import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { billMultipleDetentions } from 'Api/DetentionShipment';
import { SWrapper } from './AddToBillingCharges.styles';
import { useColumns, getInitialValues } from './AddToBillingCharges.data';

const AddToBillingCharges = ({ open, onClose, onSuccess, data }) => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);

  const onSubmit = async () => {
    try {
      const formData = new FormData();

      values.forEach((el, i) => {
        formData.append(`detention[${i}][id]`, el.id);
        formData.append(`detention[${i}][rate]`, Number(el.rate || 0));
        formData.append(`detention[${i}][duration]`, Number(el.duration || 0));
        formData.append(`detention[${i}][total_sum]`, Number(el.rate || 0) * Number(el.duration || 0));
        formData.append(`detention[${i}][bill_rate_type_id]`, el.bill_rate_type_id);
      });

      await billMultipleDetentions(formData);
      showToaster({ type: 'success', message: 'Detentions have been successfully billed!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(data),
    onSubmit,
  });

  const totalAmount = useMemo(() => {
    return values.reduce((a, b) => a + Number(b.duration || 0) * Number(b.rate || 0), 0);
  }, values);

  const columns = useColumns({ values, touchedErrors, handleChange });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Bill Detention (Shipment Billing Charges)'
      className='modified-scrollbar'
      $bgColor={palette.gray0}
      $maxWidth='900px'
      $minWidth='900px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Bill Detention',
          onClick: () => handleSubmit(values),
        },
      ]}
    >
      <SWrapper>
        <div className='detentions-table-wrapper'>
          <MaterialTableWrapper data={values} style={{ backgroundColor: palette.white }} columns={columns} />
          <div className='total-due-wrapper'>
            <Typography variant='s2'>Total</Typography>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(totalAmount)}
            </Typography>
          </div>
        </div>
      </SWrapper>
    </Modal>
  );
};

export default AddToBillingCharges;
