import axios from 'services/axios';

export async function saveFinishLater(id, body) {
  const { data } = await axios.post(`/staff/draft-step-number/${id}`, body);
  return data;
}

export async function getDepartments(params) {
  const { data } = await axios.get(`/customer/departments`, { params });
  return data;
}

export async function getJobApplications(params) {
  const { data } = await axios.get(`/job-application`, { params });
  return data;
}

export async function getGroups(params) {
  const { data } = await axios.get(`/group`, { params });
  return data;
}

export async function createStaffPolicies(body) {
  const { data } = await axios.post(`/staff/save-policies`, body);
  return data;
}

export async function getStaffPolicies(id, params) {
  const { data } = await axios.get(`/staff/get-policies/${id}`, { params });
  return data;
}

export async function uploadStaffDocument(file, staffId) {
  const formData = new FormData();
  formData.append('staff_id', staffId || '');
  formData.append('file_upload', file);
  formData.append('source', 'general');

  const { data } = await axios.post(`/staff/upload-document`, formData);
  return data;
}

export async function getStaffBookedShipments(id, payload) {
  const { page, itemsPerPage, query, from_date, to_date, sort } = payload;

  const params = {
    page,
    itemsPerPage,
    'filters[all]': query,
    'filters[from]': from_date,
    'filters[to]': to_date,
  };

  if (sort?.field) {
    params[`sort[0][${sort.field}]`] = sort.sortBy;
  }

  const { data } = await axios.get(`customer/staff/${id}/shipments`, { params });
  return data;
}
