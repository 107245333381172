import { disabledIds } from '../Equipment.data';

export const isValidRow = (row) => {
  const {
    equipment_id,
    equipment_type_id,
    make,
    model_id,
    owner_operator,
    state,
    length,
    width,
    height,
    cargo_maximum_capacity_weight,
    gross_vehicle_weight,
    license_plate_number,
    leasing_company_name,
  } = row;

  return (
    !!equipment_id &&
    !!equipment_type_id &&
    !!make &&
    !!model_id &&
    (owner_operator?.value === '1' ||
      (owner_operator?.value === '2' && leasing_company_name) ||
      owner_operator?.value === '3') &&
    license_plate_number &&
    !!state &&
    !!gross_vehicle_weight &&
    (disabledIds.includes(equipment_type_id.id)
      ? true
      : !!length && !!width && !!height && !!cargo_maximum_capacity_weight)
  );
};
