import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import uuid from 'react-uuid';
import { Modal } from 'react-bootstrap';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as EditIcon } from 'assets/sidemanu/edit.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { DeleteCommodityType, PostCommodityType, UpdateCommodityType } from 'Api/Planner';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from '../../helpers/CustomInput';
import sm from './CommoditySettingsModal.module.css';

const CommoditySettingsModal = ({ show, onChangeShow, commodity, updateCommodityAll }) => {
  const { use } = useTheme();
  const [countAdded, setCountAdded] = useState(0);
  const [loading, setLoading] = useState(false);
  const formDataCommodity = useMemo(() => {
    return commodity.map((el) => ({ ...el, title: el.label }));
  }, [commodity]);

  const [ticked, setTicked] = useState([]);

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };
  const onSubmit = () => {};

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
        marginLeft: 4,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
      styleButton: {
        padding: '6px 11px',
        margin: '0',
      },
      styleTitle: {
        fontSize: 14,
      },
      title: {
        paddingLeft: 10,
        height: 22,
      },
      icon: {
        width: 9,
        height: 9,
      },
      deleteField: {
        width: 12,
        height: 12,
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const updateRequest = (el, arrayHelpers) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const { key, title, description } = el;
    UpdateCommodityType(key, { title, description })
      .then((res) => {
        if (res && res?.data) {
          const newType = {
            default: 0,
            id: res.data.id,
            key: res.data.id,
            label: res.data.title,
            title: res.data.title,
            description: res.data.description,
          };

          setTicked([...ticked, { ...el }]);
          const index = commodity.findIndex((item) => item.id === res.data.id);
          const mappedData = commodity.map((item) => (item.id !== res.data.id ? item : newType));
          updateCommodityAll(mappedData);
          arrayHelpers.replace(index + countAdded, newType);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createRequest = (el, arrayHelpers, index) => {
    if (loading) {
      return;
    }

    setLoading(true);
    PostCommodityType({ title: el.title, description: el.description })
      .then((res) => {
        if (res && res?.data) {
          const newType = {
            default: 0,
            id: res.data.id,
            key: res.data.id,
            label: res.data.title,
            title: res.data.title,
            description: res.data.description,
          };
          setTicked([...ticked, { ...el }]);
          updateCommodityAll([...commodity, newType]);
          arrayHelpers.remove(index);
          arrayHelpers.push(newType);
          setCountAdded((p) => p - 1);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTick = (el, index, values, getFieldHelpers, errors, arrayHelpers) => {
    const title = getFieldHelpers(`shipmentTypes[${index}][title]`);
    title.setTouched('Required');
    if (values.shipmentTypes?.[index].title.trim(' ') === '') {
      title.setValue('');
    } else {
      if ((!errors && !errors?.shipmentTypes) || !errors.shipmentTypes?.[index]) {
        const requestType = formDataCommodity.find((item) => String(item.key) === String(el.key));
        if (requestType?.key) {
          updateRequest(el, arrayHelpers);
        } else {
          createRequest(el, arrayHelpers, index);
        }
      }
    }
  };
  const onCancelEdit = (values, key) => {
    const editedElement = values.shipmentTypes.find((el) => String(el.key) === String(key));
    setTicked([...ticked, { ...editedElement }]);
  };

  const onEdit = (key) => {
    const editedItem = ticked.filter((el) => String(el.key) !== String(key));
    setTicked(editedItem);
  };

  const onDelete = (arrayHelpers, index, el) => {
    if (!el.id) {
      arrayHelpers.remove(index);
      setCountAdded((p) => p - 1);
    } else {
      if (loading) {
        return;
      }

      setLoading(true);

      DeleteCommodityType({ id: el.key })
        .then((res) => {
          if (res && res.success) {
            const filteredCommodityAll = commodity.filter((item) => String(item.key) !== String(el.key));
            updateCommodityAll([...filteredCommodityAll]);
            arrayHelpers.remove(index);
          }
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setTicked([...formDataCommodity]);
  }, [formDataCommodity]);

  const initialValue = useMemo(() => {
    return { shipmentTypes: [...formDataCommodity] };
  }, [formDataCommodity]);

  return (
    <Modal
      show={show}
      centered
      enforceFocus={false}
      className={sm.container_wrapper}
      dialogClassName={sm.dialogClassName}
      contentClassName={sm.contentClassName}
    >
      <div className={sm.container} style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className={sm.header} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
          <Typography variant='h5'>Commodity Types</Typography>
          <div onClick={() => onChangeShow(false)} className={sm.close_modal_btn}>
            <DeleteIcon className={sm.close_modal_svg} height={15} width={15} />
          </div>
        </div>

        <div className={sm.body}>
          <Formik onSubmit={onSubmit} initialValues={initialValue} enableReinitialize>
            {({ values, getFieldHelpers, errors }) => {
              return (
                <Form>
                  <FieldArray
                    name='shipmentTypes'
                    render={(arrayHelpers) => {
                      return (
                        <>
                          <CustomButton
                            type='secondary'
                            title='Add Commodity'
                            styleButton={styles.addAnotherButton}
                            styleTitle={styles.addAnotherTitle}
                            onClick={() => {
                              setCountAdded((p) => p + 1);
                              arrayHelpers.insert(countAdded, {
                                title: '',
                                isNew: true,
                                key: uuid(),
                              });
                            }}
                            leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                          />
                          <div className='d-flex gap-2'>
                            <div className='w-50'>
                              <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
                                COMMODITY
                              </Typography>
                            </div>
                            <div className='w-50'>
                              <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
                                DESCRIPTION
                              </Typography>
                            </div>
                            <div className='d-flex justify-content-end' style={{ minWidth: '90px' }}>
                              <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
                                ACTIONS
                              </Typography>
                            </div>
                          </div>
                          <Divider margin='0' />
                          {values?.shipmentTypes?.map((el, index) => {
                            const namePrefix = `shipmentTypes[${index}]`;
                            const savedCharge = ticked?.find((item) => String(item.key) === String(el.key));
                            if (savedCharge) {
                              return (
                                <div key={namePrefix}>
                                  <div className={sm.data_wrapper}>
                                    <Typography variant='s2' style={{ width: '50%' }}>
                                      {savedCharge.title}
                                    </Typography>
                                    <Typography variant='s2' style={{ width: '50%' }}>
                                      {savedCharge.description}
                                    </Typography>

                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(savedCharge.key, getFieldHelpers);
                                      }}
                                      style={{ minWidth: '90px' }}
                                      className='d-flex justify-content-end'
                                    >
                                      <EditIcon style={{ cursor: 'pointer' }} />
                                    </div>
                                  </div>
                                  <div className={sm.line} />
                                </div>
                              );
                            }
                            return (
                              <div key={namePrefix} className={sm.shipment_types_wrapper}>
                                <div className={sm.title_wrapper}>
                                  <Field
                                    name={`${namePrefix}[title]`}
                                    style={styles.title}
                                    component={CustomInput}
                                    labelStyle={styles.labelStyle}
                                    validate={validateStopPointInput}
                                    placeholder='Commodity Type'
                                    className={sm.input_basic_details}
                                  />
                                  <ErrorMessage
                                    name={`${namePrefix}[title]`}
                                    render={(error) => (
                                      <Typography variant='c2' style={styles.error}>
                                        {error}
                                      </Typography>
                                    )}
                                  />
                                </div>
                                <div className={sm.title_wrapper}>
                                  <Field
                                    name={`${namePrefix}[description]`}
                                    style={styles.title}
                                    component={CustomInput}
                                    placeholder='Description'
                                    labelStyle={styles.labelStyle}
                                    className={sm.input_basic_details}
                                  />
                                </div>
                                <div
                                  className='d-flex align-items-center justify-content-end gap-2'
                                  style={{ minWidth: '90px' }}
                                >
                                  {!el.isNew && (
                                    <CustomButton
                                      type='secondary'
                                      styleButton={styles.styleButton}
                                      styleTitle={styles.styleTitle}
                                      onClick={() => onCancelEdit(values, el.key)}
                                      leftIcon={
                                        <DeleteIcon style={styles.icon} fill={use(palette.gray700, palette.gray700)} />
                                      }
                                    />
                                  )}

                                  <CustomButton
                                    type='primary'
                                    styleButton={styles.styleButton}
                                    styleTitle={styles.styleTitle}
                                    onClick={() => onTick(el, index, values, getFieldHelpers, errors, arrayHelpers)}
                                    leftIcon={<TickIcon style={styles.icon} fill={use(palette.white, palette.white)} />}
                                  />
                                  {!el.default && (
                                    <div style={{ margin: '0' }} onClick={() => onDelete(arrayHelpers, index, el)}>
                                      <MinusIcon fill={palette.indigo500} style={styles.deleteField} />
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <footer className={sm.footer} style={{ backgroundColor: use(palette.white, palette.dark800) }} />
      </div>
    </Modal>
  );
};
export default CommoditySettingsModal;
