import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 600px;
  background: ${palette.gray0};
  padding: 12px;
  border-radius: 8px;

  .card-body {
    display: flex;
    column-gap: 16px;
  }

  .service-img {
    background-color: ${palette.white};
    border-radius: 5px;
    padding: 4px;
    width: 100px;
    height: 50px;

    img {
      width: 92px;
      height: 42px;
      object-fit: contain;
    }
  }

  .addons-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    span {
      font-family: 'inter', sans-serif;
      font-size: 12px;
      line-height: 12px;
      color: gray;
    }

    ul {
      list-style-type: none;
    }
  }

  .addon-plans-list {
    padding: 0;
    margin-top: 15px;

    li {
      padding: 0;
      margin: 5px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 10px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
`;
