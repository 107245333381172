import React, { useState, useEffect, useContext } from 'react';
import './sendmsgLater.css';
import DatePicker from 'react-datepicker';
import TimeInput from 'react-time-picker-input';
import moment from 'moment';
import useDateFormat from 'hooks/useDateFormat';
import ChatAppServices from '../../../services/chatApp';
import { LayoutContext } from '../../layout';

const SendMessageLater = ({ setsheduleMsg, chatUser, activeChannel }) => {
  const { formatDateTime } = useDateFormat();
  const { timeZone } = useContext(LayoutContext);
  const [startDate, setStartDate] = useState(new Date());
  const [date, setdate] = useState(null);
  const [time, setTime] = useState('10:00');
  const [message, setMessage] = useState('');
  const [dateData, setDateData] = useState(null);
  const [wait, setwait] = useState(false);
  const [warning, setwarning] = useState('Please wait ....');

  const seduleMessage = () => {
    setwait(true);
    if (!dateData) {
      setwarning('Please select date and time ');
      return;
    }
    if (!message) {
      setwarning('Please enter message');
      return;
    }
    setwarning('Please wait ....');

    const data = {
      sender_id: chatUser.id,
      receiver_id: activeChannel?.data?.cid,
      message_text: message,
      send_later_time: dateData,
      timezone_id: timeZone.id,
    };
    ChatAppServices.sendMessageLater(data)
      .then(() => {
        setwait(false);
        setsheduleMsg(false);
      })
      .catch(() => {
        setwait(false);
        setsheduleMsg(false);
      });
  };

  useEffect(() => {
    if (startDate != null) {
      const string = `${startDate}`;
      const arr = string.split(' ');
      setdate(`${arr[0]}, ${arr[1]} ${arr[2]}, ${arr[3]}`);
    }
  }, [startDate]);

  useEffect(() => {
    if (date != null) {
      const day = moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
      setDateData(day);
    }
  }, [date, time, dateData]);

  return (
    <div className='modalContainer add-new-channel'>
      <div className='addon-modal-area' style={{ width: '320px' }}>
        <div className='send-message-later-header'>
          <p>Set Date and Time</p>
        </div>
        <div className=' time-input send-message-later-body '>
          {wait && (
            <div className='wait-for-res' onClick={() => setwait(false)}>
              <p>{warning}</p>
            </div>
          )}
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            inline
            calendarClassName='calender-class'
          />
          <div className='time-input-feild'>
            <p>Send at:</p>
            <TimeInput
              value={time}
              hour12Format
              eachInputDropdown
              // manuallyDisplayDropdown
              onChange={(dateString) => setTime(dateString)}
            />
          </div>
        </div>
        <div className=' time-input send-message-later-body '>
          <textarea
            name=''
            className='send-later-text'
            placeholder='Message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            id=''
            // cols="38"
            // rows="3"
          />
          {dateData !== null && <p className='confirm-message'>Message will be sent on {formatDateTime(dateData)}</p>}
        </div>
        <div className='send-message-later-footer'>
          <button className='btn cancel' onClick={() => setsheduleMsg(false)}>
            Cancel
          </button>
          <button
            className='btn '
            style={{
              background: '#4F5AED',
              color: '#ffff',
              border: 'none',
            }}
            onClick={seduleMessage}
          >
            Send Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendMessageLater;
