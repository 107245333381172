import React from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/drivers/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/drivers/secondClose.svg';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { uploadDocument } from 'Api/JobPositions';
import { SBigUploadWrapper, SFileUploaded } from '../Steps.styles';

const MedicalCard = ({ form, companyId }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();

  const { values, handleChange, handleBlur, touchedErrors } = form;

  const uploadMedicalCard = async (files) => {
    try {
      const response = await uploadDocument(files?.[0], companyId);
      if (response?.document_path) {
        handleChange('medicalCardUrl', response.document_path);
      }
    } catch (e) {
      showToaster({ type: 'error', message: 'Unable to upload file' });
    }
  };

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={4}>
        <Grid item xs={12}>
          <InputLabel required>Attach Medical Examiner’s Card</InputLabel>
          <SBigUploadWrapper>
            <div className='uploader-content'>
              <UploadIcon width={56} height={52} />
              {values.medicalCardUrl ? (
                <SFileUploaded style={{ backgroundColor: use('#f0f1fa', '#20223F'), margin: 0 }}>
                  <CheckIcon />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <DeleteIcon className='delete-icon' onClick={() => handleChange('medicalCardUrl', null)} />
                </SFileUploaded>
              ) : (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), margin: '2px 0' }}>
                  <span style={{ color: use(palette.indigo500, palette.gray200) }}>Upload document</span> or drag and
                  drop
                </Typography>
              )}
            </div>
            {!values.medicalCardUrl && (
              <ImageDropZone
                onDrop={uploadMedicalCard}
                width='100%'
                height='100%'
                deletable={false}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
                accept={['application/pdf']}
              />
            )}
          </SBigUploadWrapper>
          <ErrorMessage error={touchedErrors.medicalCardUrl} />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            required
            width='100%'
            name='expirationDate'
            label='Expiry Date'
            disablePast
            onBlur={handleBlur}
            value={values.expirationDate}
            onChange={(val) => handleChange('expirationDate', val)}
            error={touchedErrors.expirationDate}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default MedicalCard;
