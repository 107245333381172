import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .row-container {
    width: 100%;
    height: ${({ $count }) => ($count < 3 ? '100%' : '50%')};
    display: flex;
    align-items: center;
  }

  img {
    width: 50%;
    height: 100%;
    object-fit: cover !important;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
    vertical-align: middle;
  }
`;
