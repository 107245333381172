import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Tabs from 'common/Tabs';
import Pagination from 'common/Pagination';
import HeaderStar from 'components/HeaderStar';
import TableSkeleton from 'common/TableSkeleton';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import AbsencesCalendar from 'componentsV2/Absence/AbsencesCalendar';
import AddUpdateAbsence from 'componentsV2/Absence/AddUpdateAbsence';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { deleteAbsence, getAbsenceList } from 'Api/Absence';
import { getPlannerPopoverSettings, getTabs } from 'Api/Planner';
import { SHeaderWrapper } from 'pages/Absence/Absence.styles';

import TableFilters from './components/TableFilters';
import { actionsColumn, initialFilters, settingsDataMapper, useColumns } from './AbsencesTable.data';

const AbsencesTable = () => {
  const showToaster = useShowToaster();
  const [searchParams, setSearchParams] = useSearchParams();
  const openCreate = searchParams.get('openCreate');
  const [absenceModalOpen, setAbsenceModalOpen] = useState(openCreate || false);
  const [tabs, setTabs] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [calendarIndex, setCalendarIndex] = useState(Date.now());
  const [absenceData, setAbsenceData] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [absenceToUpdate, setAbsenceToUpdate] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('year').toDate(),
    end: moment().endOf('year').toDate(),
  });
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all' },
    searchValue: '',
    allValue: '',
    selectedValues: [],
    locations: [],
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: switchRadioButtons?.amount || '1',
      date: switchRadioButtons?.date || '1',
      time: switchRadioButtons?.time || '1',
    },
    forAll: false,
    planerHeaderSwitch: { planerSwitch: '0' },
    applyAllUsersModal: { value: '0' },
  });

  const getAbsences = async () => {
    setLoading(true);
    try {
      const sortField = selectedFilters.sort?.field ? `sort[][${selectedFilters.sort.field}]` : undefined;
      const params =
        selectedFilters.tab === 4
          ? {
              page: 1,
              itemsPerPage: 10000,
            }
          : {
              page: selectedFilters.page,
              itemsPerPage: selectedFilters.itemsPerPage,
              query: debouncedSearch || undefined,
              type: selectedFilters.tab || undefined,
              from_date: moment(dateRange.start).format('MM/DD/YYYY'),
              to_date: moment(dateRange.end).format('MM/DD/YYYY'),
            };

      if (selectedFilters.sort?.field && selectedFilters.tab !== 4) {
        params[sortField] = selectedFilters.sort.sortBy;
      }

      const [response, { data }] = await Promise.all([getAbsenceList(params), getTabs({ type: 'absence' })]);

      let allCount = 0;

      const convertedTabs = data.map((item) => {
        if (item.count) {
          allCount += item.count;
        }
        return {
          label: item.data.name,
          value: item.data.name === 'Calendar' ? 4 : item.data.filters.status[0],
          count: item.data.name === 'Calendar' ? null : item.count || 0,
        };
      });
      setTabs([{ label: 'All', value: 0, count: allCount }, ...convertedTabs]);
      setAbsenceData(response);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onCalendarItemClick = (absence) => {
    if (!absence) {
      showToaster({ type: 'error', message: 'Attendance is not found!' });
      return;
    }
    setAbsenceToUpdate(absence);
    setAbsenceModalOpen(true);
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    return cols;
  };

  const getSettings = () => {
    getPlannerPopoverSettings({ type: 'absence' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: settingsDataMapper(key), value: key, checked: value });
          });
          const tableOrder = dragItems.map((value, index) => {
            return {
              title: settingsDataMapper(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const sortingQuery = (field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({ ...prevState, sort: { field, sortBy: direction } }));
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const handleTabChange = (tab) => {
    setSearch('');
    setSelectedFilters((prevState) => ({ ...prevState, tab }));
  };

  const downloadImage = (file) => {
    if (file.includes('.pdf')) {
      fetch(file, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'driver.pdf';
          link.click();
        });
    } else {
      fetch(file, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/png' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'driver.png';
          link.click();
        });
    }
  };

  const onDelete = async (data) => {
    try {
      const { id, user_type } = data;
      await deleteAbsence(user_type, id);
      await getAbsences();
      showToaster({ type: 'success', message: 'Attendance has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onView = (row) => {
    setAbsenceToUpdate(row);
    setAbsenceModalOpen(true);
  };

  const onDownload = (row) => {
    if (!row?.absence_document) {
      return;
    }
    downloadImage(row?.absence_document);
  };

  const onAbsenceModalClose = () => {
    setAbsenceModalOpen(false);
    setAbsenceToUpdate(null);
  };

  useEffect(() => {
    getAbsences();
  }, [selectedFilters, debouncedSearch, dateRange]);

  useEffect(() => {
    searchParams.delete('openCreate');
    setSearchParams(searchParams);
    getSettings();
  }, []);

  const columns = useColumns({
    sort: selectedFilters.sort,
    sortingQuery,
  });

  return (
    <div className='mb-5'>
      <SHeaderWrapper>
        <HeaderStar title='Attendance' />
        <CustomButton
          title='Add Attendance'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          leftIcon={<PlusIcon fill={palette.white} className='me-2' />}
          onClick={() => setAbsenceModalOpen(true)}
        />
      </SHeaderWrapper>
      <TableFilters
        search={search}
        setSearch={setSearch}
        dateRange={dateRange}
        setDateRange={setDateRange}
        filter={filter}
        setFilter={setFilter}
        dragItem={dragItem}
        getSettings={getSettings}
        onChangeOrder={setDragItem}
        onSetTableColumn={setTableColumn}
        switchRadioButtons={switchRadioButtons}
        onSetSwitchRadioButtons={setSwitchRadioButtons}
        tableColumn={tableColumn}
        settingsOpen={settingsOpen}
        setSettingsOpen={setSettingsOpen}
      />
      <Tabs tabs={tabs} activeTab={selectedFilters.tab} handleTabChange={handleTabChange} />
      {selectedFilters.tab === 4 ? (
        <AbsencesCalendar updateIndex={calendarIndex} onItemClick={onCalendarItemClick} />
      ) : loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={absenceData.data}
          rowPerPage={100000}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: absenceData,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={[...filterTableColumn(columns), actionsColumn({ onView, onDelete, onDownload })]}
          onRowClick={(e, row) => onView(row)}
        />
      )}
      {absenceModalOpen && (
        <AddUpdateAbsence
          open={absenceModalOpen}
          onClose={onAbsenceModalClose}
          absence={absenceToUpdate}
          onSuccess={() => {
            getAbsences();
            setCalendarIndex(Date.now());
          }}
        />
      )}
    </div>
  );
};

export default AbsencesTable;
