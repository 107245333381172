import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: ${({ $width }) => $width || '100%'};

  & .google-autocomplete {
    background-color: ${palette.white};
    color: rgb(22, 29, 50);
    width: 100%;
    padding: ${({ $padding, $size }) => $padding || ($size === 'small' ? '2px 12px' : '6px 12px')};
    border-radius: 6px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 0 0 1px rgb(70 79 96 / 16%), 0 2px 5px rgb(89 96 120 / 10%);
    border: none;
    font-size: 14px;
    line-height: 20px;

    &:focus-visible {
      outline: none;
    }

    &:disabled {
      background-color: ${palette.gray0};
    }
  }
`;
