import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import SummaryModal from './SummaryModal';

const ShipmentSummary = ({ shipmentId, children, className }) => {
  const [openSummary, setOpenSummary] = useState(null);

  return (
    <span onClick={(e) => e.stopPropagation()} className={className}>
      {children || (
        <Typography
          variant='s3'
          style={{ color: palette.indigo500, cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => setOpenSummary(true)}
        >
          SS
        </Typography>
      )}
      {!!openSummary && (
        <SummaryModal open={openSummary} onClose={() => setOpenSummary(false)} shipmentId={shipmentId} />
      )}
    </span>
  );
};

export default ShipmentSummary;
