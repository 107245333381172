import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import Search from 'common/Search';
import Pagination from 'common/Pagination';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import PurchaseCard from 'componentsV2/CardManagement/PurchaseCard';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import useShowToaster from 'hooks/useShowToaster';
import { palette, STRIPE_MODULE } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useDebounce from 'hooks/useDebounce';
import { getTabs } from 'Api/Planner';
import { getCardDetails, getCards } from 'Api/CardManagement';
import SetPin from './components/SetPin';
import SetLimit from './components/SetLimit';
import LinkCard from './components/LinkCard';
import LoadCash from './components/LoadCash';
import RemoveCard from './components/RemoveCard';
import CardLimits from './components/CardLimits';
import CashHistory from './components/CashHistory';
import CardDetails from './components/CardDetails';
import UpdateStatus from './components/UpdateStatus';
import SetRestriction from './components/SetRestriction';
import FeatureNotAvailable from '../FeatureNotAvailable';
import { initialFilters, filterTableTop, useColumns, statusFilterOptions } from './Cards.data';
import { STableHeader, STitleWrapper, SWrapper, SBackdrop } from './Cards.styles';

const Cards = () => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { stripeOnboarding, stripeModuleData } = useSelector((state) => state.stripe);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openLoadCash, setOpenLoadCash] = useState(false);
  const [openSetLimit, setOpenSetLimit] = useState(false);
  const [openSetPin, setOpenSetPin] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [openLimits, setOpenLimits] = useState(false);
  const [openUpdateRestriction, setOpenUpdateRestriction] = useState(false);
  const [openFeatureNotAvailable, setOpenFeatureNotAvailable] = useState(false);
  const [openCardDetails, setOpenCardDetails] = useState(false);
  const [openCashHistory, setOpenCashHistory] = useState(false);
  const [warningText, setWarningText] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const debouncedSearch = useDebounce(search, 500);
  const [filter, setFilter] = useState({ filterTableTop });
  const [selectedRow, setSelectedRow] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [openPurchase, setOpenPurchase] = useState(false);

  const getCardsList = async () => {
    setLoading(true);
    try {
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.page_size,
        query: debouncedSearch || undefined,
        user_type: selectedFilters.tab || undefined,
        'filter[status][]': selectedFilters?.status?.value || undefined,
      };

      const response = await getCards(params);
      setCards(response);
      if (selectedRow) {
        const selected = response?.data?.find((i) => i.id === selectedRow.id);
        setSelectedRow(selected);
      }
      setFilter((prevState) => {
        const newFilterTableTop = prevState.filterTableTop;
        newFilterTableTop[0].count = response.total_cards;
        return {
          ...prevState,
          filterTableTop: newFilterTableTop,
        };
      });
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const getDetails = async (cardNumber) => {
    try {
      setLoadingDetails(true);
      const { data } = await getCardDetails({ cardNumber });
      setCardDetails({
        ...data,
        limits: !!data.limits && !data.limits?.length ? [data.limits] : data.limits,
        timeRestrictions:
          !!data.timeRestrictions && !data.timeRestrictions.length ? [data.timeRestrictions] : data.timeRestrictions,
        locations: !!data.locations && !data.locations?.length ? [data.locations] : data.locations,
        infos: !!data.infos && !data.infos?.length ? [data.infos] : data.infos,
      });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDetails(false);
    }
  };

  const getTabsData = async () => {
    try {
      const { data } = await getTabs({ type: 'card_management_card_details' });
      const newFilterTableTop = [
        filter.filterTableTop[0],
        { ...filter.filterTableTop[1], count: data[2].count },
        { ...filter.filterTableTop[2], count: data[1].count },
      ];
      setFilter((prevState) => ({ ...prevState, filterTableTop: newFilterTableTop }));
    } catch (e) {
      // Do nothing
    }
  };

  const onChangeTab = (n) => {
    setSearch('');
    setSelectedFilters({ ...selectedFilters, page: 1, tab: n.value });
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const onAssignCard = (row) => {
    setSelectedRow(row);
    setOpenLinkModal(true);
  };

  const onDeleteCard = (row) => {
    setSelectedRow(row);
    setOpenRemoveModal(true);
  };

  const onLoadCash = (row) => {
    setSelectedRow(row);
    setOpenLoadCash(true);
  };

  const onSetPin = (row) => {
    setSelectedRow(row);
    setOpenSetPin(true);
  };

  const onUpdateStatus = (row) => {
    setSelectedRow(row);
    setOpenUpdateStatus(true);
  };

  const onSetRestriction = async (row) => {
    try {
      await getDetails(row.card_number);
      setSelectedRow(row);
      setOpenUpdateRestriction(true);
    } catch (e) {
      // Do nothing
    }
  };

  const onSetLimit = async (row) => {
    try {
      await getDetails(row.card_number);
      setSelectedRow(row);
      setOpenSetLimit(true);
    } catch (e) {
      // Do nothing
    }
  };

  const onCardDetails = async (row) => {
    try {
      await getDetails(row.card_number);
      setSelectedRow(row);
      setOpenCardDetails(true);
    } catch (e) {
      // Do nothing
    }
  };

  const onViewLimits = async (row) => {
    try {
      await getDetails(row.card_number);
      setSelectedRow(row);
      setOpenLimits(true);
    } catch (e) {
      // Do nothing
    }
  };

  const onViewCashHistory = (row) => {
    setSelectedRow(row);
    setOpenCashHistory(true);
  };

  useEffect(() => {
    getCardsList();
  }, [selectedFilters, debouncedSearch]);

  useEffect(() => {
    getTabsData();
  }, []);

  const PaginationComponent = () => (
    <Pagination
      rowPerPage={selectedFilters.page_size}
      data={cards}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
      rowsPerPageOptions={[10, 50, 100, 150]}
    />
  );

  const columns = useColumns({
    onAssignCard,
    onDeleteCard,
    onLoadCash,
    onSetLimit,
    onSetPin,
    onUpdateStatus,
    onCardDetails,
    onViewLimits,
    onSetRestriction,
    onViewCashHistory,
    setOpenFeatureNotAvailable,
  });

  return (
    <SWrapper>
      <STableHeader>
        <STitleWrapper>
          <Typography variant='h1'>Cards</Typography>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {cards?.active_cards} active out of {cards?.total_cards || 0}
          </Typography>
          <div className='d-flex gap-2 mt-4'>
            <Search search={search} onChange={setSearch} />
            <div>
              <Autocomplete
                width='150px'
                options={statusFilterOptions}
                value={selectedFilters.status}
                onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, status: val }))}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </div>
          </div>
        </STitleWrapper>
        <div>
          <div className='d-flex'>
            <CustomButton
              width={12}
              height={12}
              type='secondary'
              title='Purchase Card'
              leftIcon={<CardIcon style={{ marginRight: 10 }} />}
              styleButton={{ padding: '6px 12px' }}
              onClick={() => {
                if (
                  stripeOnboarding?.details_submitted &&
                  stripeModuleData[STRIPE_MODULE.CARD_MANAGEMENT]?.status === 1
                ) {
                  setOpenPurchase(true);
                } else {
                  setWarningText('This feature is only available with the Truckin Digital Cash Card.');
                  setOpenFeatureNotAvailable(true);
                }
              }}
            />
            <CustomButton
              width={12}
              height={12}
              type='primary'
              title='Link Card'
              leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
              styleButton={{ padding: '6px 12px' }}
              onClick={() => setOpenLinkModal(true)}
            />
          </div>
        </div>
      </STableHeader>
      <SwitchRadio
        name='tableTopTabMenu'
        items={filter.filterTableTop}
        value={selectedFilters.tab}
        type='tab'
        onChange={onChangeTab}
        plus={false}
      />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={cards?.data}
          rowPerPage={selectedFilters.page_size}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
          onRowClick={(e, rowData) => {
            onCardDetails(rowData);
          }}
        />
      )}
      {openCardDetails && (
        <CardDetails
          open={openCardDetails}
          onClose={() => setOpenCardDetails(false)}
          cardDetails={cardDetails}
          rowData={selectedRow}
          onSetPin={onSetPin}
          onSetLimit={onSetLimit}
          onViewSpendLimit={onViewLimits}
          onUpdateSuccess={(cardNumber) => {
            getDetails(cardNumber);
            getCardsList();
          }}
        />
      )}
      {openLinkModal && (
        <LinkCard
          open={openLinkModal}
          onClose={() => {
            setOpenLinkModal(false);
            setSelectedRow(null);
          }}
          assignData={selectedRow}
          onSuccess={() => {
            getCardsList();
            getTabsData();
          }}
        />
      )}
      {openRemoveModal && (
        <RemoveCard
          open={openRemoveModal}
          onClose={() => {
            setOpenRemoveModal(false);
            setSelectedRow(null);
          }}
          data={selectedRow}
          onSuccess={() => {
            getCardsList();
            getTabsData();
          }}
        />
      )}
      {openLoadCash && (
        <LoadCash
          open={openLoadCash}
          onClose={() => {
            setOpenLoadCash(false);
            setSelectedRow(null);
          }}
          data={selectedRow}
          onSuccess={getCardsList}
        />
      )}
      {openSetLimit && (
        <SetLimit
          open={openSetLimit}
          onClose={() => {
            setOpenSetLimit(false);
            setSelectedRow(null);
          }}
          data={selectedRow}
          cardDetails={cardDetails}
          onSuccess={getCardsList}
        />
      )}
      {openSetPin && (
        <SetPin open={openSetPin} onClose={() => setOpenSetPin(false)} data={selectedRow} onSuccess={getCardsList} />
      )}
      {openUpdateStatus && (
        <UpdateStatus
          open={openUpdateStatus}
          onClose={() => {
            setOpenUpdateStatus(false);
            setSelectedRow(null);
          }}
          data={selectedRow}
          onSuccess={getCardsList}
        />
      )}
      {openUpdateRestriction && (
        <SetRestriction
          open={openUpdateRestriction}
          onClose={() => setOpenUpdateRestriction(false)}
          data={selectedRow}
          onSuccess={getCardsList}
          cardDetails={cardDetails}
        />
      )}
      {openLimits && (
        <CardLimits
          open={openLimits}
          onClose={() => setOpenLimits(false)}
          cardDetails={cardDetails}
          data={selectedRow}
          onSuccess={(cardNumber) => {
            getCardsList();
            getDetails(cardNumber);
          }}
        />
      )}
      {openCashHistory && (
        <CashHistory open={openCashHistory} onClose={() => setOpenCashHistory(false)} data={selectedRow} />
      )}
      {openFeatureNotAvailable && (
        <FeatureNotAvailable
          open={openFeatureNotAvailable}
          onClose={() => {
            setOpenFeatureNotAvailable(false);
            setWarningText(null);
          }}
          text={warningText}
        />
      )}
      {openPurchase && (
        <PurchaseCard open={openPurchase} onClose={() => setOpenPurchase(false)} onSuccess={getCardsList} />
      )}
      <SBackdrop open={loadingDetails}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SWrapper>
  );
};

export default Cards;
