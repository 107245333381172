import React, { useState } from 'react';
import HeaderStar from 'components/HeaderStar';
import StatsSection from './StatsSection';
import TableSection from './TableSection';
import { SHeaderWrapper, SPageWrapper } from './Incidents.styles';

const Incidents = () => {
  const [refreshData, setRefreshData] = useState(null);

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Incidents' />
      </SHeaderWrapper>
      <StatsSection refreshData={refreshData} />
      <TableSection refreshData={refreshData} setRefreshData={setRefreshData} />
    </SPageWrapper>
  );
};

export default Incidents;
