import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SCargo = styled.div`
  background: ${palette.gray0};
  padding: 24px;
  border-radius: 10px;

  .cargo-content {
    border: 1px solid ${palette.gray50};
    border-radius: 10px;
    background: ${palette.white};
  }

  .edit-action {
    transform: rotate(90deg);
    cursor: pointer;
  }

  .cargo-table {
    overflow: auto;
    padding: 12px;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${palette.gray300};
      border-radius: 8px;
    }
  }

  .navigate-button {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
