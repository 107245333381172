import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import person from 'assets/images/person.svg';
import vehicle from 'assets/icons/equipment/Vehicle.svg';
import trailer from 'assets/icons/equipment/Trailer.svg';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert } from 'Api/AlertCenter';

import Map from '../components/Map';
import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { ALERT_TYPE, statusOptions } from '../TakeActions.data';

const ModalWithMap = ({ open, onClose, alert, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const latitude = alert?.latitude || alert?.location_data?.latitude || alert?.location_data?.[0]?.latitude;
  const longitude = alert?.longitude || alert?.location_data?.longitude || alert?.location_data?.[0]?.longitude;
  const heading = alert?.heading || alert?.location_data?.heading || alert?.location_data?.[0]?.heading;

  const handleSubmit = async () => {
    if (!status) {
      setError(true);
      return;
    }

    try {
      await updateAlert(alert.id, { status: status.value });
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onStatusChange = (val) => {
    setStatus(val);
    setError(false);
  };

  const initMap = useCallback(() => {
    TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
    const map = new TrimbleMaps.Map({
      container: 'alertViewMap',
      style: TrimbleMaps.Common.Style.BASIC,
      center: new TrimbleMaps.LngLat(longitude, latitude),
    });
    const svgContent = document.createElement('div');
    svgContent.innerHTML = `
      <div class='myMap_driver_marker_container'>
        <img src='${
          [ALERT_TYPE.VEHICLE_UNAUTHORIZED_MOVE, ALERT_TYPE.TRAILER_UNAUTHORIZED_MOVE].includes(
            alert?.alert_name?.alert_name
          )
            ? alert.equipment_type === 1
              ? vehicle
              : trailer
            : alert?.driver_details?.image || person
        }' alt='person'>
      </div>
    `;

    const marker = new TrimbleMaps.Marker({
      draggable: false,
      element: svgContent,
      rotation: heading || '',
    })
      .setLngLat([longitude, latitude])
      .addTo(map);
    map.setZoom(15);
    map.on('load', () => {
      marker.addTo(map);
    });
  }, []);

  useEffect(() => {
    if (latitude) {
      initMap();
    }
  }, [alert]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      $maxWidth='800px'
      $minWidth='800px'
      $bgColor={palette.gray0}
      padding='0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        ...(alert.shipment_id &&
        ![ALERT_TYPE.VEHICLE_UNAUTHORIZED_MOVE, ALERT_TYPE.TRAILER_UNAUTHORIZED_MOVE].includes(
          alert?.alert_name?.alert_name
        )
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => {
                  navigate(`/shipment/${alert.shipment_id}`);
                  onClose();
                },
              },
            ]
          : []),
        ...(alert.equipment_type && alert.equipment_id
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => {
                  navigate(
                    `/equipment-profile/${alert.equipment_type === 1 ? 'vehicle' : 'trailer'}/${alert.equipment_id}`
                  );
                  onClose();
                },
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose}>
          {!!latitude && <Map />}
        </AlertInfo>
        <div className='py-4 px-3'>
          <Autocomplete
            width='260px'
            required
            label='Action'
            name='action'
            options={statusOptions}
            value={status}
            onChange={(e, val) => onStatusChange(val)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            error={error && 'Required'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalWithMap;
