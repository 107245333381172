import React, { useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { ReactComponent as CopyIcon } from 'assets/icons/createShipment/copyBg.svg';

import { Typography } from 'components/Typography';
import CustomTimeInput from 'components/CustomTimeInput';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { SCHEDULED_DATE_TYPE } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { palette } from 'utils/constants';
import { UpdateStopPointWorkingHours } from 'Api/StopPoint';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import styles from './WorkingHours.module.css';

const WorkingHoursEdit = ({ setEdit, stopPointsWorkingHours, getStopPointWorkingHours }) => {
  const { id } = useParams();
  const showToaster = useShowToaster();

  const initialValue = useMemo(() => {
    return {
      working_hour_by: '1',
      week_days: [],
    };
  }, []);

  const onSave = async (values) => {
    const params = {
      working_hour_by: Number(values?.working_hour_by),
    };
    params.week_days = {};
    values?.week_days?.forEach((d, i) => {
      params.week_days[i] = { ...d, week_day: d.week_day?.toString() };
    });

    UpdateStopPointWorkingHours(params, id)
      .then(async (res) => {
        if (res) {
          await getStopPointWorkingHours(id);
          setEdit(false);
        }
      })
      .catch((e) => showToaster({ type: 'error', message: getErrorMessage(e) }));
  };

  return (
    <div>
      <Formik onSubmit={onSave} initialValues={initialValue}>
        {({ values, submitForm, errors, getFieldHelpers, setFieldValue }) => {
          return (
            <WorkingHoursEditForm
              values={values}
              errors={errors}
              setEdit={setEdit}
              submitForm={submitForm}
              setFieldValue={setFieldValue}
              getFieldHelpers={getFieldHelpers}
              getStopPointWorkingHours={getStopPointWorkingHours}
              stopPointsWorkingHours={stopPointsWorkingHours}
            />
          );
        }}
      </Formik>
    </div>
  );
};

const WorkingHoursEditForm = ({
  errors,
  setEdit,
  values,
  submitForm,
  setFieldValue,
  getFieldHelpers,
  stopPointsWorkingHours,
}) => {
  const [weekDays, setWeekDays] = useState(stopPointsWorkingHours.data.stop_point_working_hour.map((d) => +d.week_day));
  const checkboxes = useMemo(() => {
    return [
      { label: 'Mon', longName: 'Monday', week_day: 0 },
      { label: 'Tue', longName: 'Tuesday', week_day: 1 },
      { label: 'Wed', longName: 'Wednesday', week_day: 2 },
      { label: 'Thu', longName: 'Thursday', week_day: 3 },
      { label: 'Fri', longName: 'Friday', week_day: 4 },
      { label: 'Sat', longName: 'Saturday', week_day: 5 },
      { label: 'Sun', longName: 'Sunday', week_day: 6 },
    ];
  }, []);

  const getMomentByTime = (time) => {
    if (time) {
      return moment(`${moment().format('YYYY-MM-DD')} ${time}`);
    }
    return moment();
  };

  const isSameTime = (a, b) => {
    return a.isSame(b, 'hour') && a.isSame(b, 'minute');
  };

  const isAfterTime = (a, b) => {
    if (a.isSame(b, 'hour')) {
      return a.isAfter(b, 'minute');
    }
    return a.isAfter(b, 'hour');
  };

  const validateStartTime = (value, end) => {
    if (!value) {
      return 'Required';
    }
    if (value !== '' && end !== '') {
      const startDate = getMomentByTime(value);
      const endDate = getMomentByTime(end);

      if (value === '00:00') {
        return ' ';
      }

      if (isAfterTime(startDate, endDate) || isSameTime(startDate, endDate)) {
        return 'Does not match';
      }
    }
  };

  const validateEndTime = (value, start) => {
    if (!value) {
      return 'Required';
    }
    if (start !== '' && value !== '') {
      const startDate = getMomentByTime(start);
      const endDate = getMomentByTime(value);

      if (isAfterTime(startDate, endDate) || isSameTime(startDate, endDate)) {
        return 'Does not match';
      }
    }
  };

  const onClickCopy = (el, index, formValues, getFieldHelpers, errors) => {
    const activeStart = formValues.week_days[index].start;
    const activeEnd = formValues.week_days[index].end;
    const activeError = errors?.week_days && errors?.week_days?.[index];
    formValues.week_days.forEach((item, i) => {
      const formStart = getFieldHelpers(`week_days[${i}].start`);
      const formEnd = getFieldHelpers(`week_days[${i}].end`);
      if (i === index) return null;
      if (!activeError) {
        formStart.setValue(activeStart);
        formEnd.setValue(activeEnd);
      }
    });
  };

  const stylesWH = useMemo(() => {
    return {
      time: {
        width: 107,
        height: 32,
        borderRadius: 8,
        paddingLeft: 2,
      },
      day: {
        margin: '0 10px 0 -10px',
        width: 80,
        textAlign: 'end',
        height: 34,
        lineHeight: '34px',
        display: 'inline-block',
      },
      to: {
        margin: '0 10px 0',
        height: 34,
        lineHeight: '34px',
        display: 'inline-block',
      },
      copy: {
        margin: '0 10px 0',
        color: palette.indigo500,
        cursor: 'pointer',
      },
      copyIcon: {
        marginRight: 8,
      },
    };
  }, [palette]);

  const toggleWeekDay = (week_day) => {
    const newWeekDays = [...weekDays];
    if (newWeekDays?.includes(week_day)) {
      const index = newWeekDays.indexOf(week_day);
      newWeekDays.splice(index, 1);
    } else {
      newWeekDays.push(week_day);
    }
    setWeekDays(newWeekDays);
  };

  useEffect(() => {
    setFieldValue('working_hour_by', stopPointsWorkingHours?.data?.working_hour_by?.toString());
    if (stopPointsWorkingHours?.data?.stop_point_working_hour?.length) {
      stopPointsWorkingHours?.data?.stop_point_working_hour?.forEach((d, index) => {
        setFieldValue(`week_days[${index}]`, {
          start: d.start,
          end: d.end,
          week_day: +d.week_day,
        });
      });
    }
  }, [stopPointsWorkingHours]);

  return (
    <Form>
      <div className={styles.workingHoursEditWrapper}>
        <div className={styles.header2}>
          <Typography variant='h2' style={{ color: palette.gray900 }}>
            Working Hours
          </Typography>
          <div className={styles.actions}>
            <CustomButton
              onClick={() => {
                setEdit(false);
              }}
              type='secondary'
              title='Cancel'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              className={styles.action}
            />
            <CustomButton
              onClick={submitForm}
              type='primary'
              title='Save'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              className={styles.action}
            />
          </div>
        </div>
        {Array(3)
          .fill(0)
          .map((item, index) => {
            return (
              <div key={item || index}>
                <Field
                  type='radio'
                  name='working_hour_by'
                  value={String(index + 1)}
                  component={(props) => (
                    <CustomRadioButton {...props}>
                      <Typography variant='s2' style={{ marginLeft: 8 }}>
                        {SCHEDULED_DATE_TYPE[index].label}
                      </Typography>
                    </CustomRadioButton>
                  )}
                />
              </div>
            );
          })}
        {!!values?.working_hour_by && (
          <FieldArray
            name='week_days'
            render={(arrayHelpers) => {
              return (
                <div className={styles.times_wrapper}>
                  {/* ///days */}
                  <div style={{ marginLeft: 25 }} className={styles.input_checkbox}>
                    {checkboxes.map((el) => {
                      return (
                        <div key={uuid()}>
                          <CustomCheckbox
                            type='button'
                            checked={weekDays?.includes(+el.week_day)}
                            onChange={() => {
                              const foundedIndex = values?.week_days?.findIndex(
                                (formItem) => +formItem.week_day === +el.week_day
                              );
                              if (foundedIndex !== -1) {
                                arrayHelpers.remove(foundedIndex);
                              } else {
                                let element = stopPointsWorkingHours?.data?.stop_point_working_hour?.find(
                                  (d) => +d.week_day === +el.week_day
                                );
                                if (!element) {
                                  element = {
                                    start: '00:00',
                                    end: '00:00',
                                    week_day: el.week_day,
                                  };
                                }
                                arrayHelpers.insert(
                                  +el.week_day -
                                    checkboxes.reduce((acc, c) => {
                                      if (c.week_day < el.week_day && !weekDays?.includes(c.week_day)) {
                                        acc += 1;
                                      }
                                      return acc;
                                    }, 0),
                                  element
                                );
                              }
                              toggleWeekDay(+el.week_day);
                            }}
                            style={{ margin: '-22px 8px 0 0' }}
                          >
                            {el.label}
                          </CustomCheckbox>
                        </div>
                      );
                    })}
                  </div>

                  {weekDays?.sort()?.map((week_day, index) => {
                    let el = stopPointsWorkingHours?.data?.stop_point_working_hour?.find(
                      (d) => +d.week_day === +week_day
                    );
                    if (!el) {
                      el = {
                        start: '00:00',
                        end: '00:00',
                        week_day,
                      };
                    }
                    const namePrefix = `week_days[${index}]`;
                    const name = checkboxes.find((c) => +c.week_day === Number(el?.week_day))?.longName;
                    return (
                      <div key={week_day} className={styles.times}>
                        <Typography variant='s2' style={stylesWH.day}>
                          {name}
                        </Typography>
                        <div className={styles.error_wrapper}>
                          <Field
                            name={`${namePrefix}.start`}
                            type='text'
                            inputType='time'
                            style={stylesWH.time}
                            component={CustomTimeInput}
                            validate={(value) => validateStartTime(value, values?.week_days?.[index]?.end)}
                          />
                          <ErrorMessage
                            name={`${namePrefix}.start`}
                            render={(error) => (
                              <Typography variant='c2' style={{ color: palette.red500 }}>
                                {error}
                              </Typography>
                            )}
                          />
                        </div>

                        <Typography variant='s2' style={stylesWH.to}>
                          to
                        </Typography>

                        <div className={styles.error_wrapper}>
                          <Field
                            name={`${namePrefix}.end`}
                            type='text'
                            inputType='time'
                            style={stylesWH.time}
                            component={CustomTimeInput}
                            validate={(value) => validateEndTime(value, values?.week_days?.[index]?.start)}
                          />
                          <ErrorMessage
                            name={`${namePrefix}.end`}
                            render={(error) => (
                              <Typography variant='c2' style={{ color: palette.red500 }}>
                                {error}
                              </Typography>
                            )}
                          />
                        </div>

                        <Typography
                          variant='s2'
                          style={stylesWH.copy}
                          className={styles.copy_times_all_days}
                          onClick={() => onClickCopy(el, index, values, getFieldHelpers, errors)}
                        >
                          <CopyIcon style={stylesWH.copyIcon} fill={palette.indigo500} />
                          Copy time to all days
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
        )}
      </div>
    </Form>
  );
};
export default WorkingHoursEdit;
