import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  background-color: ${({ $disabled }) => ($disabled ? palette.gray50 : palette.white)};
  min-width: 92px;
  cursor: pointer;

  &.selected {
    border: 2px solid ${palette.indigo500};
    transform: scale(1.1);
  }

  .card-content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }

  .payment-info-icon {
    position: absolute;
    left: -6px;
    bottom: -6px;
    background-color: ${palette.white};
    border-radius: 50%;
  }
`;
