import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from 'context/themeContext';
import { CreateCommodityType, DeleteCommodityType, GetCommodityTypes, UpdateCommodityType } from 'Api/CompanySettings';
import { getErrorMessage } from 'utils/error';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import useShowToaster from 'hooks/useShowToaster';
import CustomButton from 'components/CustomButton/CustomButton';
import ItemForm from './ItemForm/ItemForm';
import styles from './ComodityTypes.module.css';
import { SActions, SAddMore, SContentRow, SRow, SWrapper } from '../Payroll/Payroll.styles';

const CommodityTypes = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [commodityTypes, setCommodityTypes] = useState([]);
  const [forms, setForms] = useState([]);
  const [editItems, setEditItems] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const getCommodityTypes = async () => {
    GetCommodityTypes().then((res) => {
      setCommodityTypes(res.data);
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCommodityTypes().then();
  }, []);

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewCommodityType = async (values, id) => {
    if (loadingCreate) {
      return;
    }

    setLoadingCreate(true);
    try {
      const data = { title: values?.type, description: values.description };
      await CreateCommodityType(data);
      await getCommodityTypes();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Commodity type has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const updateCommodityType = async (values, id) => {
    try {
      const data = { title: values?.type, description: values.description };
      await UpdateCommodityType(id, data);
      await getCommodityTypes();
      onCancel(id);
      showToaster({ type: 'success', message: 'Commodity type has been successfully updated!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const deleteItem = async (id) => {
    setLoadingDelete(true);
    try {
      await DeleteCommodityType(id);
      const newCommodityTypes = commodityTypes.filter((item) => item.id !== id);
      setCommodityTypes(newCommodityTypes);
      showToaster({ type: 'success', message: 'Commodity type has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const toggleEdit = (id) => {
    setEditItems([...editItems, id]);
  };
  const onCancel = (id) => {
    const newEditItems = editItems.filter((item) => item !== id);
    setEditItems(newEditItems);
  };

  return (
    <SWrapper>
      <div className={styles.header}>
        <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
          Commodity Types
        </Typography>
        <div className={styles.actions}>
          {isConfiguringStep && (
            <>
              {' '}
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('saveAndNext')}
                type='primary'
                title={isConfiguringStep ? 'Save and Next' : 'Save'}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
            </>
          )}
        </div>
      </div>
      <SRow>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            COMMODITY
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DESCRIPTION
          </Typography>
        </div>
        <div className='settings-header-item'>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
              ACTIONS
            </Typography>
          </div>
        </div>
      </SRow>
      {commodityTypes?.map((item) => {
        return editItems.includes(item.id) ? (
          <ItemForm
            item={item}
            editable
            key={item.id}
            onCancel={() => onCancel(item?.id)}
            onSubmit={(values) => updateCommodityType(values, item.id)}
            onDelete={() => deleteItem(item.id)}
            loading={loadingCreate}
          />
        ) : (
          <SContentRow key={item.id}>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {item.title}
              </Typography>
            </div>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {item.description}
              </Typography>
            </div>
            <SActions className='settings-table-item settings-table-actions-wrapper'>
              {loadingDelete ? (
                <span className='loading-wrapper'>
                  <CircularProgress size={16} />
                </span>
              ) : (
                <span className='action-wrapper' onClick={() => toggleEdit(item.id)}>
                  <EditIcon width={11} height={11} />
                </span>
              )}
            </SActions>
          </SContentRow>
        );
      })}
      {forms.map((item) => (
        <ItemForm
          item={item}
          key={item.id}
          onSubmit={(values) => createNewCommodityType(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
          loading={loadingCreate}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200), cursor: 'pointer' }}>
          Add Commodity
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default CommodityTypes;
