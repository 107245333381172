import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import person from 'assets/images/person.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { updateAlert } from 'Api/AlertCenter';
import { getCustomerDelayCode } from 'Api/Customers';
import { addReasonForShipmentDelay } from 'Api/Planner';

import Map from '../components/Map';
import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';

const DelayedShipment = ({ open, onClose, alert, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [delayReason, setDelayReason] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [contacts, setContacts] = useState([]);

  const handleSubmit = async () => {
    try {
      await addReasonForShipmentDelay({
        shipment_id: alert.shipment_id,
        delay_reason_id: delayReason.id,
        shipment_stop_id: alert.shipment_stop_id,
      });
      await updateAlert(alert.id, { status: 'RS' });
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await getCustomerDelayCode({ id: alert?.customer_id });
      setContacts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const initMap = useCallback(() => {
    TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
    const map = new TrimbleMaps.Map({
      container: 'alertViewMap',
      style: TrimbleMaps.Common.Style.BASIC,
      center: new TrimbleMaps.LngLat(alert.longitude, alert.latitude),
    });
    const svgContent = document.createElement('div');
    svgContent.innerHTML = `
                             <div class='myMap_driver_marker_container'>
                                 <img src='${alert?.driver_details?.image || person}' alt=''>
                             </div>`;

    const marker = new TrimbleMaps.Marker({
      draggable: false,
      element: svgContent,
    })
      .setLngLat([alert?.longitude, alert?.latitude])
      .addTo(map);
    map.setZoom(15);
    map.on('load', () => {
      marker.addTo(map);
    });
  }, []);

  const onDelayReasonChange = (val) => {
    setDelayReason(val);
    setError(null);
  };

  useEffect(() => {
    if (alert?.latitude) {
      initMap();
    }
  }, [alert]);

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      $maxWidth='850px'
      $minWidth='850px'
      $bgColor={palette.gray0}
      padding='0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'navigate',
          type: 'primary',
          title: 'View',
          onClick: () => {
            navigate(`/shipment/${alert.shipment_id}`);
            onClose();
          },
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-4'>
        <AlertInfo alert={alert} onUserClick={onClose}>
          <Map />
        </AlertInfo>
        <div className='py-4 px-3'>
          <Autocomplete
            required
            width='380px'
            label={
              <Typography>
                <WarningIcon fill={palette.orange500} className='me-2' />
                Select Delay Reason
              </Typography>
            }
            name='reason'
            options={contacts}
            value={delayReason}
            getOptionLabel={(option) => `${option.reason?.title}-${option.reason?.code}`}
            onChange={(e, val) => onDelayReasonChange(val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={error && 'Required'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DelayedShipment;
