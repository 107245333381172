import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-2.svg';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import ActionButtons from 'common/ActionButtons';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import ThreeDotActions from 'common/ThreeDotActions';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { CURRENCY, formatNumber, palette } from 'utils/constants';
import { BILLING_GRAND_TOTAL_CURRENCY } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { blockNonPositiveNumbers, blockNotNumberChars, transformYupErrorsIntoObject } from 'utils/helpers';
import { getShipmentChargeType } from 'Api/Planner';
import { createQuoteLineItem, deleteQuoteLineItem, updateQuoteLineItem } from 'Api/Shipment';

import classNames from 'classnames';
import InfoItem from './InfoItem';
import { initialCharge } from '../CreateQuote.data';
import { validationSchema } from '../validationSchema';
import { SBilling, STable, SAddMore } from '../CreateQuote.styles';

const Billing = ({ form, quote, shouldSave }) => {
  const showToaster = useShowToaster();
  const [chargeTypes, setChargeTypes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateChargeLoading, setUpdateChargeLoading] = useState(null);
  const [deleteChargeLoading, setDeleteChargeLoading] = useState(null);
  const editMode = !!quote;

  const { values, handleChange, touchedErrors, setErrors, setTouched } = form;
  const currencySymbol = CURRENCY[values.line_items_currency?.title?.toLowerCase()]?.symbol;

  const getChargeType = async () => {
    try {
      const { data } = await getShipmentChargeType();
      setChargeTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onChargeTypeChange = (value, index) => {
    handleChange(`line_items[${index}].charge_type`, value);
    if (value.type.account_type === 'Expense' && Number(values.line_items[index].rate) > 0) {
      handleChange(`line_items[${index}].rate`, `-${values.line_items[index].rate}`);
    }
    if (value.type.account_type !== 'Expense' && Number(values.line_items[index].rate) < 0) {
      handleChange(`line_items[${index}].rate`, `${Math.abs(values.line_items[index].rate)}`);
    }
  };

  const onSaveCharge = async (charge, index) => {
    try {
      if (isCurrentItemLoading(index)) {
        return;
      }

      await validationSchema.validateAt(`line_items[${index}]`, values, {
        strict: true,
        abortEarly: false,
      });

      setUpdateChargeLoading(index);

      let newItem;

      if (charge.isNew) {
        const body = {
          quote_id: quote.id,
          charge_type_id: charge.charge_type.id,
          quantity: charge.quantity,
          rate: charge.rate,
          sub_total: Number(charge.quantity || 0) * Number(charge.rate || 0),
          currency_id: values.line_items_currency?.id || null,
        };

        const { data } = await createQuoteLineItem(body);
        newItem = data;
      } else {
        const body = {
          charge_type_id: charge.charge_type.id,
          quantity: charge.quantity,
          rate: charge.rate,
          sub_total: Number(charge.quantity || 0) * Number(charge.rate || 0),
          currency_id: values.line_items_currency?.id || null,
        };

        await updateQuoteLineItem(charge.id, body);
      }

      showToaster({
        type: 'success',
        message: `Additional line item has been ${charge.isNew ? 'added' : 'updated'} successfully!`,
      });

      handleChange(`line_items[${index}]`, { ...charge, id: newItem?.id || charge.id, isEdit: false, isNew: false });
    } catch (e) {
      const { validationErrors, validationTouched } = transformYupErrorsIntoObject(e);

      if (Object.keys(validationErrors)?.length) {
        setErrors(validationErrors, { shouldValidate: false });
        setTouched(validationTouched, { shouldValidate: false });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setUpdateChargeLoading(null);
    }
  };

  const onDeleteCharge = async (charge, index) => {
    try {
      if (isCurrentItemLoading(index)) {
        return;
      }

      if (!charge.isNew) {
        setDeleteChargeLoading(index);
        await deleteQuoteLineItem(charge.id);
        showToaster({ type: 'success', message: `Additional line item has been deleted successfully!` });
      }

      handleChange(
        `line_items`,
        values.line_items.filter((item, i) => i !== index)
      );
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setDeleteChargeLoading(null);
    }
  };

  const isCurrentItemLoading = (index) => {
    return updateChargeLoading === index || deleteChargeLoading === index;
  };

  const onAddCharge = () => {
    handleChange('line_items', [...values.line_items, { ...initialCharge, id: uuid() }]);
  };

  const onEditCharge = (index) => {
    handleChange(`line_items[${index}].isEdit`, true);
  };

  useEffect(() => {
    getChargeType();
  }, []);

  return (
    <SBilling>
      <div className='mb-3'>
        <Typography variant='s1'>Additional Line Items</Typography>
      </div>
      <div className='create-quote-section'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>CHARGE TYPE</th>
              <th>QTY</th>
              <th>RATE</th>
              <th>SUB-TOTAL</th>
              <th style={{ width: '100px' }} />
            </tr>
          </thead>
          <tbody>
            {values.line_items.map((charge, index) => (
              <tr
                className={classNames('body-row', {
                  'unsaved-animation': !!quote && !!shouldSave && (!!charge.isEdit || !!charge.isNew),
                })}
                key={charge.id}
              >
                <td>
                  <div>
                    {editMode && !charge.isEdit && !charge.isNew ? (
                      <InfoItem content={charge.charge_type?.title || '-'} />
                    ) : (
                      <Autocomplete
                        width='400px'
                        name={`line_items[${index}].charge_type`}
                        labelKey='title'
                        options={chargeTypes}
                        value={charge.charge_type}
                        onChange={(e, val) => onChargeTypeChange(val, index)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                      />
                    )}
                    <ErrorMessage error={touchedErrors.line_items?.[index]?.charge_type} />
                  </div>
                </td>
                <td>
                  <div>
                    {editMode && !charge.isEdit && !charge.isNew ? (
                      <InfoItem content={charge.quantity || '-'} />
                    ) : (
                      <Input
                        width='100px'
                        type='number'
                        placeholder='1'
                        name={`line_items[${index}].quantity`}
                        value={charge.quantity}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          blockNonPositiveNumbers(e);
                          blockNotNumberChars(e);
                        }}
                      />
                    )}
                    <ErrorMessage error={touchedErrors.line_items?.[index]?.quantity} />
                  </div>
                </td>
                <td>
                  <div>
                    {editMode && !charge.isEdit && !charge.isNew ? (
                      <InfoItem content={charge.rate || '-'} />
                    ) : (
                      <InputWithIcon
                        icon={currencySymbol}
                        width='150px'
                        type='number'
                        placeholder='0.00'
                        name={`line_items[${index}].rate`}
                        value={charge.rate}
                        onChange={handleChange}
                        onKeyDown={blockNonPositiveNumbers}
                      />
                    )}
                    <ErrorMessage error={touchedErrors.line_items?.[index]?.rate} />
                  </div>
                </td>
                <td>
                  <div>
                    <Typography variant='s2'>
                      {currencySymbol}
                      {formatNumber(Number(charge.quantity || 0) * Number(charge.rate || 0))}
                    </Typography>
                  </div>
                </td>
                <td>
                  {editMode && (
                    <ActionButtons
                      data={charge}
                      onEdit={() => onEditCharge(index)}
                      onSave={() => onSaveCharge(charge, index)}
                      onDelete={() => onDeleteCharge(charge, index)}
                      loadingSave={updateChargeLoading === index}
                      loadingDelete={deleteChargeLoading === index}
                    />
                  )}
                </td>
              </tr>
            ))}
            <tr className='total-row'>
              <td>
                <SAddMore onClick={onAddCharge}>
                  <PlusIcon />
                  <Typography variant='s2' style={{ color: palette.indigo500 }}>
                    Add Another...
                  </Typography>
                </SAddMore>
              </td>
              <td />
              <td />
              <td>
                <div>
                  <InputLabel variant='c3'>TOTAL ADDITIONAL</InputLabel>
                  <Typography variant='s2'>
                    {currencySymbol}
                    {formatNumber(
                      values.line_items.reduce((acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0), 0)
                    )}
                  </Typography>
                </div>
              </td>
              <td>
                <ThreeDotActions
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  actionButton={
                    <div className='d-flex justify-content-between align-items-center gap-2'>
                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                        {values.line_items_currency?.title}
                      </Typography>
                      <ArrowIcon fill={palette.gray700} style={{ transform: `rotate(90deg)` }} />
                    </div>
                  }
                >
                  {BILLING_GRAND_TOTAL_CURRENCY.map((item) => (
                    <li
                      key={item.id}
                      style={{
                        backgroundColor: item.id === values.line_items_currency?.id ? palette.indigo500 : '',
                        color: item.id === values.line_items_currency?.id ? palette.white : palette.gray900,
                      }}
                      onClick={() => {
                        handleChange('line_items_currency', item);
                        setAnchorEl(null);
                      }}
                    >
                      {item.title}
                    </li>
                  ))}
                </ThreeDotActions>
              </td>
            </tr>
          </tbody>
        </STable>
      </div>
    </SBilling>
  );
};

export default Billing;
