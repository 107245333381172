import React, { memo, useMemo } from 'react';
import { palette } from 'utils/constants';
import DefaultAvatar from 'assets/icons/profile.svg';
import { Typography } from 'components/Typography';
import styles from './Profile.module.css';

const ProfilePreview = ({ userData }) => {
  const passwordCount = Array(11).fill(0);

  const cache = useMemo(() => Date.now().toString(), [userData]);

  return (
    <div className={styles.profile_wrapper}>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Picture
        </Typography>
        <figure className={styles.profile_image_wrapper}>
          {userData.profile_logo === null ? (
            <img src={DefaultAvatar} alt='img' width={48} height={48} style={{ borderRadius: '50%' }} />
          ) : (
            <img src={`${userData?.profile_logo}?cache=${cache}`} alt='img' width={48} height={48} />
          )}
        </figure>
      </div>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Name
        </Typography>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {userData?.first_name} {userData?.last_name}
        </Typography>
      </div>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Phone Number
        </Typography>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {userData?.phone_number}
        </Typography>
      </div>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Email Address
        </Typography>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {userData?.email}
        </Typography>
      </div>
      <div className={styles.physical_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Physical Address
        </Typography>

        <div className={styles.physical_info_wrapper}>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {userData?.address1}
            {userData?.address2 ? ',' : ''} {userData?.address2}
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {userData?.city?.name}, {userData?.state?.name} {userData?.zip}
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {userData?.country?.sortname}
          </Typography>
        </div>
      </div>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Current Password
        </Typography>
        <div className={styles.passDot_wrapper}>
          {passwordCount.map((_, index) => {
            return (
              <span key={_ || index} className={styles.pass_dot}>
                {' '}
              </span>
            );
          })}
        </div>
      </div>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Department
        </Typography>
        <Typography className={styles.departmentInfo} variant='c1' style={{ color: palette.gray700 }}>
          {userData?.department?.department_name}
        </Typography>
      </div>
      <div className={styles.basic_wrapper}>
        <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
          Title
        </Typography>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {userData?.title}
        </Typography>
      </div>
    </div>
  );
};

export default memo(ProfilePreview);
