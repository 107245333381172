import styled from 'styled-components';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { palette } from '../../../../utils/constants';

export const SInfoActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
`;

export const STextButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: 12px;
    font-family: Inter, sans-serif;
    text-transform: none;
    color: ${palette.indigo500};
    padding: 0 4px;

    .MuiButton-startIcon {
      visibility: hidden;
      margin-right: 4px;

      .MuiSvgIcon-root {
        width: 12px;
        height: 12px;
      }
    }

    :hover {
      background-color: transparent;
      text-decoration: underline;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    :hover {
      .MuiButton-startIcon {
        visibility: visible;
      }
    }
  }
`;

export const SDotsWrapper = styled.div`
  cursor: pointer;

  .dots {
    display: inline-flex;
  }

  .popover-actions {
    list-style-type: none;
    background: #ffffff;
    box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0;
  }
`;

export const SPopover = styled(Popover)`
  &.MuiPopover-root {
    .actions-wrapper {
      list-style-type: none;
      background: #ffffff;
      box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 6px 4px;

      li {
        text-transform: none;
        cursor: pointer;
        width: 100%;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        border-radius: 4px;

        &.delete-action {
          color: ${palette.red500};
        }

        :hover {
          background-color: ${palette.indigo0};
        }
      }
    }
  }
`;
