import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Loader from 'common/Loader';
import HeaderStar from 'components/HeaderStar';
import ThreeDotActions from 'common/ThreeDotActions';
import QuickbooksSync from 'componentsV2/QuickbooksSync';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getAccountsList } from 'Api/Accounts';
import { palette } from 'utils/constants';
import { initialFilters } from 'pages/Accounting/Accounts/Accounts.data';

import AddAccount from '../components/AddAccount';
import AccountCard from '../components/AccountCard';
import AccountsFilter from '../components/AccountsFilter';
import AccountsAccordion from './components/AccountsAccordion';
import { SHeaderWrapper } from '../Accounts.styles';
import { SCardsWrapper, SWrapper } from './LeftSection.styles';

const LeftSection = ({ activeAccount, updateActiveAccount, accountToEdit, setAccountToEdit, refreshIndex }) => {
  const didMountRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = searchParams.get('id');
  const openCreate = searchParams.get('openCreate');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddAccount, setOpenAddAccount] = useState(openCreate || false);
  const [filters, setFilters] = useState(initialFilters);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const filteredAccounts = useMemo(() => {
    const { hideInactive, account_type, search } = filters;
    return accounts.filter(
      (item) =>
        (!account_type || account_type.id === 99999 || account_type.id === item.account_type.id) &&
        (!search ||
          item.account_name.toLowerCase().includes(search.toLowerCase()) ||
          String(item.account_code).toLowerCase().includes(search.toLowerCase())) &&
        (item.account_status === 1 || !hideInactive)
    );
  }, [accounts, filters]);

  const groupedAccounts = useMemo(() => {
    return filteredAccounts.reduce((acc, cur) => {
      const existIndex = acc.findIndex((i) => i.key === cur.account_type.account_type);

      if (existIndex === -1) {
        acc.push({ key: cur.account_type.account_type, accounts: [cur] });
      } else {
        acc[existIndex].accounts.push(cur);
      }

      return acc;
    }, []);
  }, [filteredAccounts]);

  const onFilterChange = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const getAccounts = async (noNavigate) => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data);

      if (noNavigate) {
        setLoading(false);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onAccountClick = (account) => {
    searchParams.set('id', account.id);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setLoading(true);
    getAccounts();
    searchParams.delete('openCreate');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      getAccounts(true);
    } else {
      didMountRef.current = true;
    }
  }, [refreshIndex]);

  useEffect(() => {
    if (accounts.length) {
      const acc = accounts.find((i) => Number(i.id) === Number(accountId));
      updateActiveAccount(acc || accounts[0]);
    }
  }, [accountId, accounts]);

  return (
    <SWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Chart of Accounts' />
        <div className='right-section'>
          <CustomButton
            title='Add'
            type='primary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            leftIcon={<PlusIcon fill={palette.white} className='me-2' />}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={() => setOpenAddAccount(true)}
          />
          <QuickbooksSync action='accounts' onSuccess={getAccounts} />
          <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl} size={18}>
            <li>
              <label htmlFor='hideInactive' className='d-flex align-items-center gap-2 m-0 pointer'>
                <CustomCheckbox
                  id='hideInactive'
                  checked={filters.hideInactive}
                  onChange={(checked) => onFilterChange('hideInactive', checked)}
                />
                Hide Inactive
              </label>
            </li>
            <li>
              <label htmlFor='hideBalances' className='d-flex align-items-center gap-2 m-0 pointer'>
                <CustomCheckbox
                  id='hideBalances'
                  checked={filters.hideBalances}
                  onChange={(checked) => onFilterChange('hideBalances', checked)}
                />
                Hide Balances
              </label>
            </li>
          </ThreeDotActions>
        </div>
      </SHeaderWrapper>
      <AccountsFilter filters={filters} onFilterChange={onFilterChange} />
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <SCardsWrapper>
          {filters.account_type && filters.account_type.account_type !== 'All Accounts'
            ? filteredAccounts?.map((item) => (
                <AccountCard
                  data={item}
                  key={item.id}
                  onClick={() => onAccountClick(item)}
                  hideBalances={filters.hideBalances}
                  className={activeAccount?.id === item.id ? 'selected' : ''}
                />
              ))
            : groupedAccounts.map((item) => (
                <AccountsAccordion
                  key={item.key}
                  title={item.key}
                  accounts={item.accounts}
                  activeAccount={activeAccount}
                  onAccountClick={onAccountClick}
                  hideBalances={filters.hideBalances}
                />
              ))}
        </SCardsWrapper>
      )}
      {(openAddAccount || !!accountToEdit) && (
        <AddAccount
          open={openAddAccount || !!accountToEdit}
          onClose={() => {
            setOpenAddAccount(false);
            setAccountToEdit(null);
          }}
          onSuccess={getAccounts}
          account={accountToEdit}
        />
      )}
    </SWrapper>
  );
};

export default LeftSection;
