import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px;
  width: 80%;
  max-width: 1600px;
  min-width: 1000px;
  margin: 0 auto;

  .addons-header {
    display: flex;
    justify-content: space-between;
  }

  .addon-actions {
    display: flex;
    column-gap: 16px;

    button {
      width: 160px;
      justify-content: center;
    }
  }
`;

export const SAddonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
  padding: 8px 20px 24px;
  border-bottom: 1px solid ${palette.gray50};
  margin: 48px 0 24px;
`;
