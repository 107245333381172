import React, { createContext, useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/constants';
import { BILLING_GRAND_TOTAL_CURRENCY } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getCustomerPaymentTerm, getShipmentChargeType } from 'Api/Planner';
import styles from './billingCharges.module.css';
import BillingChargesBody from './BillingChargesBody';
import SkilletBillCharges from './SkilletBillCharges';
import BillingChargesHeader from './BillingChargesHeader';

export const BillingChargesContext = createContext();

const BillingChargeChild = ({
  initialValue,
  selectedBillTo,
  updateBillToSelected,
  infoHeader,
  contactData,
  getContacts,
  onChangeContactEditModal,
  selectedContact,
  updateContactSelected,
  chargeData,
  allChargesData,
  indexPrefix,
  isRecurring,
  setUpdateBillData,
}) => {
  const [shipmentChargeType, setShipmentChargeType] = useState([]);
  const [paymentTermData, setPaymentTermData] = useState({});
  const [selectedCharges, setSelectedCharges] = useState([]);
  const [editMood, setEditMood] = useState(true);
  const [selectedGrandTotalForm, setSelectedGrandTotalForm] = useState([]);
  const { billToData } = useSelector((state) => state?.shipments);
  const [count, setCount] = useState(0);
  const [chargeErrors, setChargeErrors] = useState({});

  const onSubmitChild = () => {
    setEditMood(true);
  };

  const updatePaymentTermData = (data) => {
    setPaymentTermData(data);
  };

  const updateSelectedCharges = (data) => {
    setSelectedCharges(data);
  };

  const updateSelectedGrandTotal = (data) => {
    setSelectedGrandTotalForm((prevState) => prevState.map((item, i) => (i === indexPrefix ? data : item)));
  };

  const getChargeType = () => {
    return getShipmentChargeType().then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item?.title,
            key: item.id,
            labelSelected: null,
          };
        });
        setShipmentChargeType(newData);
        return newData;
      }
      return res;
    });
  };

  useEffect(() => {
    const customer_id = initialValue?.customer_id;

    if (allChargesData?.[indexPrefix]?.billing_customer) {
      const klir = allChargesData?.[indexPrefix]?.billing_customer;

      updateBillToSelected({
        ...klir,
        label: klir.company_name,
        key: klir.id,
        labelSelected: null,
      });
    }
    getContacts(customer_id, true).then();
  }, [billToData, initialValue]);

  useEffect(() => {
    getChargeType().then();

    if (initialValue.billing_charges?.length > 0) {
      setSelectedCharges(initialValue.billing_charges);
    }
    infoHeader?.shipment_billing?.[0]?.currency_id === 1
      ? setSelectedGrandTotalForm([BILLING_GRAND_TOTAL_CURRENCY[0]])
      : setSelectedGrandTotalForm([BILLING_GRAND_TOTAL_CURRENCY[1]]);
  }, []);

  useEffect(() => {
    const data = allChargesData?.map((item) => {
      return item.currency_id === 1 ? BILLING_GRAND_TOTAL_CURRENCY[0] : BILLING_GRAND_TOTAL_CURRENCY[1];
    });
    setSelectedGrandTotalForm(data);
  }, [allChargesData]);

  useEffect(() => {
    let subTotal = 0;
    selectedCharges?.forEach((charge) => {
      if (charge?.quantity && charge?.rate) {
        subTotal = +subTotal + Number(charge.quantity) * Number(charge.rate);
      }
    });
    setCount(numberWithCommas(subTotal.toFixed(2)));
  }, [selectedCharges]);

  useEffect(() => {
    const first_customer_id = chargeData?.[0]?.customer_id;
    if (!!first_customer_id || +first_customer_id === 0) {
      getCustomerPaymentTerm({ customer_id: first_customer_id }).then((res) => updatePaymentTermData({ ...res?.data }));
    }
  }, [chargeData]);

  return (
    <Formik onSubmit={onSubmitChild} initialValues={{ ...initialValue }}>
      {({ values, submitForm, isValid, touched, setTouched, validateForm, setFieldError, setFieldValue }) => {
        return (
          <BillingChargesContext.Provider
            value={{
              values,
              isValid,
              submitForm,
              getContacts,
              selectedContact,
              updateBillToSelected,
              contactDataObj: contactData,
              updateContactSelected,
              paymentTermData,
              updatePaymentTermData,
              selectedGrandTotalForm: selectedGrandTotalForm?.[indexPrefix],
              updateSelectedGrandTotal,
              selectedBillTo,
              editMood,
              setEditMood,
              shipmentChargeType,
              selectedCharges,
              updateSelectedCharges,
              getChargeType,
              validateForm,
              touched,
              setTouched,
              chargeData,
              indexPrefix,
              count,
              setCount,
              setFieldError,
              onChangeContactEditModal,
              allChargesData: allChargesData?.[indexPrefix],
              setFieldValue,
              infoHeader,
              setUpdateBillData,
            }}
          >
            {editMood ? (
              <SkilletBillCharges isRecurring={isRecurring} />
            ) : (
              <div className={styles.billing_container}>
                <BillingChargesHeader
                  isRecurring={isRecurring}
                  shipment={infoHeader}
                  setChargeErrors={setChargeErrors}
                />
                <BillingChargesBody
                  shipment={infoHeader}
                  isRecurring={isRecurring}
                  chargeErrors={chargeErrors}
                  setChargeErrors={setChargeErrors}
                />
              </div>
            )}
          </BillingChargesContext.Provider>
        );
      }}
    </Formik>
  );
};

export default BillingChargeChild;
