import React from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useForm from 'hooks/useForm';
import { validationSchema } from './validationSchema';
import { monthDays, monthWeekOptions, weekDays } from '../../Tables/Tables.data';
import { SAutocomplete } from '../../Tables/Tables.styles';
import { SCustomModal, SRadio, SRow } from './RecurringModal.styles';

export default function RecurringModal({ open, onClose, data, handleSubmit, hideAmortized, isAddition, width }) {
  const { use } = useTheme();

  const {
    values,
    handleSubmit: handleFormSubmit,
    handleChange,
  } = useForm({
    initialValues: data,
    onSubmit: handleSubmit,
    validationSchema,
  });

  const onTypeChange = (value) => {
    if (value === '1') {
      handleChange('month_week_settlement', 1);
      handleChange('every_settlement', 0);
      handleChange('amortized_settlement', 0);
      handleChange(isAddition ? 'one_time_addition' : 'one_time_deduction', 0);
    }
    if (value === '2') {
      handleChange('month_week_settlement', 0);
      handleChange('every_settlement', 1);
      handleChange('amortized_settlement', 0);
      handleChange(isAddition ? 'one_time_addition' : 'one_time_deduction', 0);
    }
    if (value === '3') {
      handleChange('month_week_settlement', 0);
      handleChange('every_settlement', 0);
      handleChange('amortized_settlement', 1);
      handleChange(isAddition ? 'one_time_addition' : 'one_time_deduction', 0);
    }
    if (value === '4') {
      handleChange('month_week_settlement', 0);
      handleChange('every_settlement', 0);
      handleChange('amortized_settlement', 0);
      handleChange(isAddition ? 'one_time_addition' : 'one_time_deduction', 1);
    }
  };

  const onMonthWeekChange = (value) => {
    handleChange('month_week', value);
    handleChange('day', value === 'Week' ? 'Monday' : '1st');
  };

  return (
    <SCustomModal
      showModal={!!open}
      onHide={onClose}
      $bgColor={palette.gray0}
      $minWidth={width}
      headerTitle='Set Interval'
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Set Interval',
          onClick: handleFormSubmit,
        },
      ]}
    >
      <form>
        <Typography variant='s2' style={{ display: 'inline-block', marginBottom: '8px' }}>
          Interval Type
        </Typography>
        <SRow>
          <SRadio
            type='radio'
            name='recurring-type'
            value='1'
            checked={values.month_week_settlement === 1}
            onChange={(e) => onTypeChange(e.target.value)}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
            Every
          </Typography>
          <SAutocomplete
            width='118px'
            value={values.day || null}
            onChange={(e, value) => handleChange('day', value)}
            options={values.month_week === 'Month' ? monthDays : weekDays}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
            per
          </Typography>
          <SAutocomplete
            width='100px'
            value={values.month_week || null}
            onChange={(e, value) => onMonthWeekChange(value)}
            options={monthWeekOptions}
          />
        </SRow>
        <Divider margin='0 0 12px' />
        <SRow>
          <SRadio
            type='radio'
            name='recurring-type'
            value='2'
            checked={values.every_settlement === 1}
            onChange={(e) => onTypeChange(e.target.value)}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
            Every Settlement
          </Typography>
        </SRow>
        {!hideAmortized && (
          <>
            {/* <Divider margin='0 0 12px' /> */}
            {/* <SRow> */}
            {/*  <SRadio */}
            {/*    type='radio' */}
            {/*    name='recurring-type' */}
            {/*    value='3' */}
            {/*    checked={values.every_settlement === 0 && values.amortized_settlement === 1} */}
            {/*    onChange={(e) => onTypeChange(e.target.value)} */}
            {/*  /> */}
            {/*  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}> */}
            {/*    Amortized */}
            {/*  </Typography> */}
            {/*  <SInputWrapper> */}
            {/*    {currency} */}
            {/*    <SInput */}
            {/*      type='number' */}
            {/*      name='amortized_total' */}
            {/*      placeholder='0.00' */}
            {/*      value={values.amortized_total} */}
            {/*      onChange={handleChange} */}
            {/*      error={touchedErrors.amortized_total} */}
            {/*      style={{ boxShadow: 'none' }} */}
            {/*    /> */}
            {/*  </SInputWrapper> */}
            {/*  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}> */}
            {/*    over */}
            {/*  </Typography> */}
            {/*  <SInput */}
            {/*    type='number' */}
            {/*    name='amortized_months' */}
            {/*    placeholder='00' */}
            {/*    width='60px' */}
            {/*    value={values.amortized_months} */}
            {/*    onChange={handleChange} */}
            {/*  /> */}
            {/*  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}> */}
            {/*    settlements, deducted on each settlement. */}
            {/*  </Typography> */}
            {/* <SInput */}
            {/*  type='number' */}
            {/*  name='amortized_month' */}
            {/*  placeholder='00' */}
            {/*  width='60px' */}
            {/*  value={values.amortized_month} */}
            {/*  onChange={handleChange} */}
            {/* /> */}
            {/* </SRow> */}
            <Divider margin='0 0 12px' />
            <SRow>
              <SRadio
                type='radio'
                name='recurring-type'
                value='4'
                checked={values.one_time_deduction === 1}
                onChange={(e) => onTypeChange(e.target.value)}
              />
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                One Time Deduction
              </Typography>
            </SRow>
          </>
        )}
        {!!isAddition && (
          <>
            <Divider margin='0 0 12px' />
            <SRow>
              <SRadio
                type='radio'
                name='recurring-type'
                value='4'
                checked={values.one_time_addition === 1}
                onChange={(e) => onTypeChange(e.target.value)}
              />
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                One Time Addition
              </Typography>
            </SRow>
          </>
        )}
      </form>
    </SCustomModal>
  );
}
