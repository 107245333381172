import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { getPaymentTerms } from 'Api/Vendors';
import { SFormRow } from '../AddBill.styles';

const GeneralInfo = ({ bill, values, handleChange, touchedErrors, readOnly }) => {
  const [paymentTerms, setPaymentTerms] = useState([]);
  const didMountRef = useRef(false);

  const { payment_term, date_entered } = values || {};

  const getPaymentTermOptions = async () => {
    try {
      const { data } = await getPaymentTerms();
      setPaymentTerms(
        (data || []).map((item) => ({ ...item, name: item.id === 4 ? 'Due on Discount' : `Net ${item.net_day}` }))
      );
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (payment_term && date_entered && (bill ? didMountRef.current : true)) {
      handleChange('bill_due_date', moment(values.date_entered).add(payment_term.net_day, 'days').toDate());
    }
    didMountRef.current = true;
  }, [payment_term, date_entered]);

  useEffect(() => {
    getPaymentTermOptions();
  }, []);

  return (
    <SFormRow className='mt-4'>
      <DatePicker
        required
        label='Date Entered'
        name='date_entered'
        value={values.date_entered}
        onChange={(val) => handleChange('date_entered', val)}
        disabled={readOnly}
        error={touchedErrors.date_entered}
      />
      <div>
        <Autocomplete
          required
          width='180px'
          label='Vendor Payment Term'
          name='payment_term'
          options={paymentTerms}
          value={values.payment_term}
          getOptionLabel={(option) => `Net ${option.net_day}`}
          onChange={(e, val) => handleChange('payment_term', val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          error={touchedErrors.payment_term}
          disabled={readOnly}
        />
      </div>
      <DatePicker
        required
        label='Bill Due Date'
        name='bill_due_date'
        value={values.bill_due_date}
        onChange={(val) => handleChange('bill_due_date', val)}
        disabled={readOnly}
        error={touchedErrors.bill_due_date}
      />
    </SFormRow>
  );
};

export default GeneralInfo;
