import React, { useContext, useEffect, useState } from 'react';
import './TrackingHistory.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import step from 'assets/icons/drivers/Step.svg';
import { Icons } from 'assets/icons';
import { TrackingHistoryData } from 'Api/Equipment';
import useDateFormat from 'hooks/useDateFormat';
import { EquipmentDetailsContext } from '../../../EquipmentDetails';
import DateRange from '../DateRangePicker/DateRangePicker';
import { EquipmentDetailsContext2 } from '../../../EquipmentProfileTrailer/EquipmentProfileTrailer';
import '../DateRangePicker/DateRangePicker.css';

const TrackingHistory = ({ timeLineClose, trailer, equipmentId }) => {
  const { formatDate, timeFormat, convertToCustomerTime } = useDateFormat();
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const { use } = useTheme();
  const {
    dateRange,
    setDateRange,
    setRangeName,
    rangeName,
    profileData,
    setHistoryCoords,
    setHistoryData,
    historyData,
    stopPointZoom,
    resetMyRoute,
    selectedHistoryItem,
    setSelectedHistoryItem,
  } = useContext(trailer === 'true' ? EquipmentDetailsContext2 : EquipmentDetailsContext);
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const getTrackingHistory = (data) => {
    const converted = data.reduce((acc, cur, index) => {
      const lastItem = acc[acc.length - 1];

      if (index === 0) {
        return [{ ...cur, distance: 0, seconds: 0, background: 'black', start_date: cur.created_at }];
      }

      const lon1 = lastItem.longitude;
      const lat1 = lastItem.latitude;
      const lon2 = cur.longitude;
      const lat2 = cur.latitude;
      const dist = CalcDistance(lat1, lon1, lat2, lon2);
      const distance = dist < 0.5 ? 0 : dist;

      if (lastItem && lastItem.distance === 0 && distance === 0) {
        const a = moment(lastItem.start_date).set('seconds', 0);
        const b = moment(cur.created_at).set('seconds', 0);
        const seconds = b.diff(a, 'seconds') || 0;

        return [
          ...acc.slice(0, acc.length - 1),
          {
            ...cur,
            start_date: lastItem.start_date,
            created_at: cur.created_at,
            seconds,
            distance,
            background: 'red',
          },
        ];
      }

      const a = moment(lastItem.created_at).set('seconds', 0);
      const b = moment(cur.created_at).set('seconds', 0);
      const seconds = b.diff(a, 'seconds') || 0;

      acc.push({
        ...cur,
        start_date: cur.created_at,
        distance,
        seconds,
        background: 'black',
      });
      return acc;
    }, []);

    const withBlackDots = converted.reduce((acc, cur) => {
      if (cur.distance === 0) {
        const a = moment(cur.start_date).set('seconds', 0);
        const b = moment(cur.created_at).set('seconds', 0);
        const seconds = b.diff(a, 'seconds') || 0;

        if (seconds !== 0) {
          const lastItem = acc[acc.length - 1];
          const a = moment(lastItem?.created_at).set('seconds', 0);
          const b = moment(cur.start_date).set('seconds', 0);
          const seconds = b.diff(a, 'seconds') || 0;

          acc.push({ ...cur, seconds, created_at: cur.start_date, background: 'black' });
        }
      }

      acc.push(cur);
      return acc;
    }, []);

    return withBlackDots.map((item, index) => {
      let seconds = item.seconds;

      const days = Math.floor(seconds / (24 * 3600));
      seconds -= days * 24 * 3600;

      // hours
      const hrs = Math.floor(seconds / 3600);
      seconds -= hrs * 3600;

      // minutes
      const minutes = Math.floor(seconds / 60);

      const duration = days ? `${days}d ${hrs}h` : hrs ? `${hrs}h ${minutes}m` : `${minutes}m`;

      return { ...item, duration, id: `${index}-${item.id}` };
    });
  };

  async function TrackingHistory() {
    setLoading(true);
    TrackingHistoryData(
      equipmentId,
      moment(new Date(dateRange?.start)).format('L'),
      moment(new Date(dateRange?.end)).format('L')
    )
      .then((res) => {
        setLoading(false);
        const data = getTrackingHistory(res);

        data.sort(function compare(a, b) {
          const dateA = moment(a.created_at);
          const dateB = moment(b.created_at);
          return dateA.isAfter(dateB) ? -1 : 1;
        });
        const reversedArr = data.map((_, i, a) => {
          return {
            ...a[a.length - i - 1],
            initialIndex: a.length - i - 1,
            isLiveMode:
              moment(dateRange?.end).format('L') === moment(new Date()).format('L') ||
              moment(dateRange?.start).format('L') === moment(new Date()).format('L'),
          };
        });

        setHistoryCoords(reversedArr);

        setHistoryData(data);
        if (data?.length === 0) {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function CalcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const lat11 = toRad(lat1);
    const lat22 = toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat11) * Math.cos(lat22);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }
  useEffect(() => {
    if (dateRange?.start && dateRange?.end) {
      setTitle(`${formatDate(new Date(dateRange?.start))} - ${formatDate(new Date(dateRange?.end))}`);
      TrackingHistory();
    }
  }, [dateRange]);

  useEffect(() => {
    if (profileData?.location_target === 'Driver' && profileData?.drivers) {
      if (profileData?.drivers[0]?.driver_gps?.location) {
        const input = profileData?.drivers[0]?.driver_gps?.location;
        const fields = input.split(',');
        setStreet(fields[0]);
        setCity(fields[1]);
        setState(fields[2]);
        setZip(fields[3]);
      } else {
        const input = profileData?.last_known_location;
        const fields = input.split(',');
        setStreet(fields[0]);
        setCity(fields[1]);
        setState(fields[2]);
        setZip(fields[3]);
      }
    } else if (profileData?.location_target === 'Equipment') {
      if (profileData?.equipment_gps?.location) {
        const input = profileData?.equipment_gps?.location;
        const fields = input.split(',');
        setStreet(fields[0]);
        setCity(fields[1]);
        setState(fields[2]);
        setZip(fields[3]);
      } else {
        const input = profileData?.equipment_gps?.location;
        const fields = input?.split(',');
        if (fields) {
          setStreet(fields[0]);
          setCity(fields[1]);
          setState(fields[2]);
          setZip(fields[3]);
        }
      }
    }
  }, [profileData]);

  const ClearTrackingHistory = () => {
    timeLineClose();
    setHistoryData([]);
    setHistoryCoords([]);
  };

  return (
    <div
      className='equipment-tracking-history-section '
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.gray700, palette.gray200),
        border: use(palette.gray50, palette.dark600),
      }}
    >
      <div className='col-12 d-flex justify-content-between'>
        <div className='second-section w-100'>
          <div
            className='title d-flex justify-content-between gap-2'
            style={{ borderBottomColor: use(palette.gray50, palette.dark600) }}
          >
            <span>Timeline</span>
            <div className='date-range equipment-datepicker'>
              <PrimaryBtn icon={Icons.calendar} title={title} onClick={() => setIsShowDatePicker(true)} />
              <span className='datePicker-input'>
                {isShowDatePicker && (
                  <DateRange
                    title='All Time'
                    dateTimeRange={dateRange}
                    resetMyRoute={resetMyRoute}
                    dateSelect={dateSelect}
                    rangeName={rangeName}
                    setRangeName={setRangeName}
                    setIsShowDatePicker={setIsShowDatePicker}
                    // timeLineOpen={timeLineOpen}
                  />
                )}
              </span>
            </div>
            <div>
              <span
                className='Tracking-history-close-button'
                onClick={() => {
                  ClearTrackingHistory();
                }}
              >
                Clear
              </span>
            </div>
          </div>
          <div>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  position: 'fixed',
                  left: '10%',
                  top: '50%',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div className='mt-3'>
                {moment(dateRange?.start).format('L') === moment(new Date()).format('L') ||
                moment(dateRange?.end).format('L') === moment(new Date()).format('L') ? (
                  <div className='live-tracking-equipment-section d-flex' onClick={() => ClearTrackingHistory()}>
                    {historyData?.length ? (
                      <div className='live-tracking-left-section'>
                        <div>
                          <img src={step} alt='' style={{ marginLeft: '8px' }} className='steps-image' />
                        </div>
                        <div style={{ marginLeft: '8px' }} className='dashed-border' />
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='live-tracking-right-section'>
                      {historyData?.length ? (
                        <div>
                          <span className='tracking-history-live-tracking-text'>Live Tracking...</span>
                          <div>
                            <div className='live-tracking-third-section'>
                              {profileData?.location_target === 'Driver' ? (
                                profileData?.location_target === 'Driver' && profileData?.drivers ? (
                                  <div>
                                    <span
                                      style={{
                                        color: use(palette.dark800, palette.gray200),
                                      }}
                                    >
                                      {profileData?.drivers[0]?.driver_gps?.location ? (
                                        <>
                                          <span>{street ? `${street}.` : ''}</span> <br />
                                          <span>{city ? `${city},` : ''}</span>
                                          <span>{state ? `${state},` : ''}</span>
                                          <span>{zip ? `${zip},` : ''}</span>
                                        </>
                                      ) : (
                                        <>
                                          <span>{street ? `${street},` : ''}</span> <br />
                                          <span>{city ? `${city},` : ''}</span>
                                          <span>{state ? `${state},` : ''}</span>
                                          <span>{zip ? `${zip},` : ''}</span>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <span
                                    style={{
                                      color: use(palette.dark800, palette.gray200),
                                    }}
                                  >
                                    <span>{street ? `${street},` : ''}</span> <br />
                                    <span>{city ? `${city},` : ''}</span>
                                    <span>{state ? `${state},` : ''}</span>
                                    <span>{zip ? `${zip},` : ''}</span>
                                  </span>
                                )
                              ) : profileData?.location_target === 'Equipment' ? (
                                <span
                                  style={{
                                    color: use(palette.dark800, palette.gray200),
                                  }}
                                >
                                  {profileData?.equipment_gps?.location ? (
                                    <>
                                      <span>{street ? `${street},` : ''}</span> <br />
                                      <span>{city ? `${city},` : ''}</span>
                                      <span>{state ? `${state},` : ''}</span>
                                      <span>{zip ? `${zip},` : ''}</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>{street ? `${street},` : ''}</span> <br />
                                      <span>{city ? `${city},` : ''}</span>
                                      <span>{state ? `${state},` : ''}</span>
                                      <span>{zip ? `${zip},` : ''}</span>
                                    </>
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              <span
                                className='table-data-text f-12 m-0'
                                style={{
                                  color: use(palette.gray500, palette.gray400),
                                }}
                              >
                                {profileData?.location_target === 'Driver' ? (
                                  profileData?.drivers ? (
                                    profileData?.drivers[0]?.driver_gps?.updated_at ? (
                                      profileData?.drivers[0]?.driver_gps?.updated_at && (
                                        <div
                                          style={{
                                            color: use(palette.dark800, palette.gray200),
                                          }}
                                        />
                                      )
                                    ) : profileData?.last_known_update && profileData?.last_known_update ? (
                                      convertToCustomerTime(profileData?.last_known_update)
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    profileData?.last_known_update && (
                                      <div
                                        className='flex-row d-flex fade-id-row'
                                        style={{
                                          color: use(palette.dark800, palette.gray200),
                                        }}
                                      >
                                        {profileData?.last_known_update && profileData?.last_known_update
                                          ? convertToCustomerTime(profileData?.last_known_update)
                                          : ''}
                                      </div>
                                    )
                                  )
                                ) : profileData?.location_target === 'Equipment' &&
                                  profileData?.equipment_gps?.updated_at ? (
                                  <div
                                    style={{
                                      color: use(palette.dark800, palette.gray200),
                                    }}
                                  >
                                    {profileData?.equipment_gps?.updated_at && profileData?.equipment_gps?.updated_at
                                      ? convertToCustomerTime(profileData?.equipment_gps?.updated_at)
                                      : ''}
                                  </div>
                                ) : (
                                  profileData?.last_known_update && (
                                    <div
                                      style={{
                                        color: use(palette.dark800, palette.gray200),
                                      }}
                                    >
                                      {profileData?.last_known_update && profileData?.last_known_update
                                        ? convertToCustomerTime(profileData?.last_known_update)
                                        : ''}
                                    </div>
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {historyData?.length ? (
                  historyData?.map((v, i) => (
                    <div
                      key={v.id}
                      onClick={() => {
                        setSelectedHistoryItem(v);
                        stopPointZoom(i);
                      }}
                      className={`hover-show-selection ${v?.background === 'red' ? 'pointer' : ''} ${
                        selectedHistoryItem?.id === v.id ? 'selected' : ''
                      }`}
                      id={v.id}
                    >
                      <div
                        className='live-tracking-equipment-section ms-2 '
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <div className='live-tracking-third-section'>
                          <span>
                            <span className='live-tracking-time-decor'>{v?.location}</span>
                          </span>
                          <div className='d-flex align-items-center'>
                            <div>
                              <p
                                className='table-data-text f-12 m-0'
                                style={{
                                  color: use(palette.gray500, palette.gray400),
                                }}
                              >
                                {convertToCustomerTime(v.created_at, `MMM D, YYYY ${timeFormat}`)}
                              </p>
                            </div>
                            <div
                              className='live-tracking-time ms-2 live-tracking-time-decor'
                              style={{
                                color: use(palette.gray500, palette.gray400),
                              }}
                            />
                          </div>
                        </div>
                        <div className={historyData.length - 1 > i ? 'live-tracking-left-section' : ''}>
                          <div style={{ whiteSpace: 'nowrap', width: '40px' }}>
                            <div>
                              <img
                                src={v?.background === 'red' ? Icons.RedSteps : Icons.steps}
                                alt=''
                                className='steps-image'
                              />
                            </div>
                            <div className='second-section-border' />
                            {i !== historyData.length - 1 && (
                              <>
                                <span
                                  className='live-tracking-time-decor table-data-text f-12'
                                  style={{
                                    backgroundColor: v?.background === 'red' ? palette.red0 : '',
                                    color: v?.background === 'red' ? palette.red500 : '',
                                    fontWeight: v?.background === 'red' ? 500 : '',
                                  }}
                                >
                                  {v.duration}
                                </span>
                                <span className='live-tracking-time ms-1 table-data-text f-12'>
                                  {v.distance * 0.621371 === 0 ? 0 : (v.distance * 0.621371).toFixed(2)} Miles
                                </span>
                              </>
                            )}
                            <div className='second-section-border' />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className='no-tracking-history'>This vehicle does not have any tracking history.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingHistory;
