import moment from 'moment';

export const getTimeTracked = (data) => {
  if (data?.end_time) {
    return `${data?.hours_tracked || 0}h ${data?.minutes_tracked || 0}m`;
  }

  const diff = moment().diff(data.start_time);
  const diffInMinutes = diff / 1000 / 60;
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = Math.floor(diffInMinutes % 60);

  return `${hours}h ${minutes}m`;
};

export const timeTrackingConvertor = (trackingData, user_type) => {
  let allUsersTotalHours = 0;
  let allUsersTotalMinutes = 0;
  let allUsersPayPerHour = 0;
  let allUsersEstimatedCost = 0;

  const newData = trackingData.data.map((item) => {
    const hours = item.time_trackings.reduce((a, b) => Number(a) + Number(b.hours_tracked || 0), 0);
    const minutes = item.time_trackings.reduce((a, b) => {
      return b.end_time
        ? Number(a) + Number(b.minutes_tracked || 0)
        : Number(a) + moment().diff(b.start_time, 'minutes');
    }, 0);

    const hoursToMinutes = hours * 60 + minutes;
    const totalHours = Math.floor(hoursToMinutes / 60);
    const totalMinutes = hoursToMinutes % 60;

    allUsersTotalHours += totalHours;
    allUsersTotalMinutes += totalMinutes;
    allUsersPayPerHour += Number(item.compensation_details?.pay_per_hour || 0);

    const estimatedCost = (totalHours + totalMinutes / 60) * Number(item.compensation_details?.pay_per_hour || 0);
    allUsersEstimatedCost += estimatedCost;

    return { ...item, totalHours, totalMinutes, user_type };
  });

  if (newData.length) {
    const allUsersHoursToMinutes = allUsersTotalHours * 60 + allUsersTotalMinutes;
    allUsersTotalHours = Math.floor(allUsersHoursToMinutes / 60);
    allUsersTotalMinutes = allUsersHoursToMinutes % 60;

    const allUsersData = {
      type: 'allUsers',
      totalHours: allUsersTotalHours,
      totalMinutes: allUsersTotalMinutes,
      payPerHour: allUsersPayPerHour,
      estimatedCost: allUsersEstimatedCost,
      total_time_worked: trackingData.total_time_worked,
      total_pay_per_hours: trackingData.total_pay_per_hours,
      total_estimated_cost: trackingData.total_estimated_cost,
    };

    newData.unshift(allUsersData);
  }

  return { ...trackingData, data: newData };
};

export const sendEmailConverter = (data) => {
  const formData = new FormData();
  formData.append('file', data.blob);
  formData.append('statement_from', moment(data.dateRange.start).format('MM/DD/YYYY'));
  formData.append('statement_to', moment(data.dateRange.end).format('MM/DD/YYYY'));
  formData.append('user_type', data.user_type);
  formData.append('user_id', data.id);

  return formData;
};
