import React from 'react';
import { Typography } from 'components/Typography';
import Requirement from '../../components/Requirement';
import { SRequirementsWrapper } from '../Steps.styles';

const Requirements = ({ requirements, companyName }) => {
  return (
    <SRequirementsWrapper>
      <Typography variant='s1'>To qualify with {companyName}, you must meet the following criteria:</Typography>
      {requirements?.map((item) => (
        <Requirement text={item.title} key={item.id} />
      ))}
    </SRequirementsWrapper>
  );
};

export default Requirements;
