import React, { useContext, useEffect, useState } from 'react';
import { TableContext } from 'context/tableContext';
import { UpdateCustomersPopoverSettings } from 'Api/Customers';
import styles from 'components/PopoverSettings/PopoverSettings.module.css';
import TableColumn from 'components/PopoverSettings/TableColumn';
import ColumnOrder from 'components/PopoverSettings/ColumnOrder';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomButton from 'components/CustomButton/CustomButton';

const PopoverSettingsEquipmentAtSopPoint = ({ filter, updateFilter, setShowPopover, tableColumn, dragItem }) => {
  const { onChangeOrder, onsStTableColumn } = useContext(TableContext);

  const [localState, setLocalState] = useState([]);
  const [localDragItem, setLocalDragItem] = useState([]);
  const [switchRadioButtonsLocal] = useState(filter.switchRadioButtons);
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

  function UpdateTableSettings(dataJson) {
    return UpdateCustomersPopoverSettings(dataJson)
      .then((res) => res)
      .catch(() => {
        // Do nothing
      });
  }

  const onChangeTick = (value) => {
    updateFilter({ ...filter, forAll: value });
  };

  const onCancel = () => {
    setShowPopover(false);
  };

  const onClickApply = () => {
    const { forAll } = filter;

    updateFilter({ ...filter, tableColumn: localState, switchRadioButtons: switchRadioButtonsLocal });
    onsStTableColumn([...localState]);
    onChangeOrder(localDragItem);
    const updateData = {
      type: 'equipmentAtStopPoints',
      forAll,
      data: {
        columns: {},
        columns_order: {},
        date: +switchRadioButtonsLocal.dateFormat,
        time: +switchRadioButtonsLocal.timeFormat,
      },
    };
    localState.forEach((el) => {
      const { value, checked } = el;
      updateData.data.columns[value] = checked;
    });

    updateData.data.columns_order = localDragItem.map((el) => el.value);

    UpdateTableSettings(updateData).then((res) => res && res?.data && setShowPopover(false));
  };

  useEffect(() => {
    setLocalDragItem(dragItem);
  }, [dragItem]);

  return (
    <div>
      <div className={styles.popoverSettings_title_wrapper}>
        <span>Table Settings</span>
      </div>

      <TableColumn
        localState={localState}
        tableColumn={tableColumn}
        setLocalState={(param) => setLocalState(param)}
        localDragItem={localDragItem}
        onChangeOrderLocal={(items) => setLocalDragItem(items)}
      />

      <ColumnOrder localDragItem={localDragItem} onChangeOrderLocal={(items) => setLocalDragItem(items)} />
      <div style={{ marginBottom: 32 }} />
      <div style={{ borderBottom: '1px solid #D5DBE5', margin: '16px 0', height: '1px' }} />
      <div className={styles.popoverSettings_footer}>
        {user?.department?.department_name === 'Management' && (
          <CustomCheckbox style={{ marginLeft: 10 }} checked={filter.forAll} onChange={onChangeTick}>
            <span className={styles.footer_title_checkbox}>Apply to all users</span>
          </CustomCheckbox>
        )}

        <div className={styles.footer_right}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '2px 8px', marginRight: 16 }}
            onClick={onCancel}
          />
          <CustomButton type='primary' title='Apply' styleButton={{ padding: '2px 8px' }} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default PopoverSettingsEquipmentAtSopPoint;
