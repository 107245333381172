import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/sidemanu/settings-20px.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { FileCopy } from '@material-ui/icons';
import useShowToaster from 'hooks/useShowToaster';
import HeaderStar from 'components/HeaderStar';
import ExportCsv from 'componentsV2/ExportCsv';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getAppUrl } from 'utils/helpers';
import { SActions, SHeaderWrapper } from './PageHeader.styles';

const buttonStyles = {
  padding: '6px 12px',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 500,
  margin: 0,
  height: '33px',
};

const PageHeader = ({ onAddReferrer, hideAction, showCopyUrl, title, showCopyPositionUrl, onAddJobPosition }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;
  const navigate = useNavigate();
  const showToaster = useShowToaster();

  const onGearClick = () => {
    navigate('/company-settings', { state: { tabId: 11 } });
  };

  const onCopyUrl = async () => {
    const url = getAppUrl();

    if (!url || !dot) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
      return;
    }

    try {
      await navigator.clipboard.writeText(`${url}/job-positions/${dot}`);
      showToaster({ type: 'success', message: 'URL copied successfully!' });
    } catch (err) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
    }
  };

  const onCopyJobUrl = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const url = getAppUrl();

    if (!url || !dot) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
      return;
    }

    try {
      await navigator.clipboard.writeText(`${url}/job-positions/${dot}`);
      showToaster({ type: 'success', message: 'URL copied successfully!' });
    } catch (err) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
    }
  };

  return (
    <SHeaderWrapper>
      <HeaderStar title={title} />
      <SActions>
        <ExportCsv type={title === 'Job Positions' ? 'job-positions' : title.toLowerCase()} height={33} dot={dot} />
        {!hideAction && (
          <CustomButton
            type='secondary'
            title='Add Referrer'
            leftIcon={<PlusIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={buttonStyles}
            onClick={onAddReferrer}
          />
        )}
        {showCopyUrl && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title='Share'
            leftIcon={<ShareIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={buttonStyles}
            onClick={onCopyUrl}
          />
        )}

        {showCopyPositionUrl && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title='Copy Public Job Posts URL'
            leftIcon={<FileCopy fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={buttonStyles}
            onClick={onCopyJobUrl}
          />
        )}

        {onAddJobPosition && (
          <CustomButton
            type='secondary'
            title='Add Job Position'
            leftIcon={<PlusIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={buttonStyles}
            onClick={onAddJobPosition}
          />
        )}

        <CustomButton
          type='secondary'
          title=''
          leftIcon={<SettingsIcon fill={palette.gray700} />}
          styleButton={buttonStyles}
          onClick={onGearClick}
        />
      </SActions>
    </SHeaderWrapper>
  );
};

export default PageHeader;
