import React, { useMemo, useState, useEffect, useRef } from 'react';
import moment from 'moment/moment';
import { Dropdown } from 'react-bootstrap';
import More from 'assets/icons/drivers/more.svg';
import Pagination from 'common/Pagination';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import RequestHeader from 'components/RequestHeader/RequestHeader';
import RequestListAddModal from 'components/RequestListAddModal/RequestListAddModal';
import RequestListUpdateModal from 'components/RequestListUpdateModal/RequestListUpdateModal';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { TableContext } from 'context/tableContext';
import { getTabs, getRequestType } from 'Api/Driver';
import { getPlannerPopoverSettings } from 'Api/Planner';
import { RequestData, AddRequestDetail, updateRequestDetail, getRequestData } from 'Api/Request';
import AddTab from './AddTab';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';
import { useColumns, initialFilters } from './RequestList.data';
import './RequestList.css';

const RequestList = () => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [openAddTab, setOpenAddTab] = useState(false);
  const [tabToEdit, setTabToEdit] = useState(null);
  const [requestsData, setRequestsData] = useState({ data: [] });
  const [openUpdateRequest, setOpenUpdateRequest] = useState(false);
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [getPlanerFilterData, setGetPlanerFilterData] = useState({});
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const [dragItem, setDragItem] = useState([]);
  const [filterTableTop, setFilterTableTop] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [updateRequestData, setUpdateRequestData] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});
  const didMountRef = useRef(false);

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });

  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all' },
    searchValue: '',
    allValue: '',
    selectedValues: [],
    locations: [],
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: switchRadioButtons?.amount || '1',
      date: switchRadioButtons?.date || '1',
      time: switchRadioButtons?.time || '1',
    },
    forAll: false,
    planerHeaderSwitch: { planerSwitch: '0' },
    applyAllUsersModal: { value: '0' },
  });

  const getRequests = async (data) => {
    const { advancedFilter = {} } = data || {};
    const { status: advancedStatus, type: advancedType, name, request_id, requestDateRange } = advancedFilter;

    try {
      const { page, itemsPerPage, type, status, user_type } = selectedFilters || {};
      const sortField = `sort[][${sort.field}]`;

      const params = {
        page,
        itemsPerPage,
        [sortField]: sort.sortBy,
        type: advancedType?.length ? advancedType : type.length ? type : undefined,
        status: advancedStatus?.length ? advancedStatus : status.length ? status : undefined,
        user_type: user_type.length ? user_type : undefined,
        name: name || undefined,
        request_id: request_id?.length ? request_id.toString() : undefined,
        requestDateRange: undefined,
        start_date: requestDateRange?.[0] ? moment(requestDateRange?.[0]).format('MM/DD/YYYY') : undefined,
        end_date: requestDateRange?.[1] ? moment(requestDateRange?.[1]).format('MM/DD/YYYY') : undefined,
      };
      const res = await getRequestData(params);
      const { data } = await getTabs('request');

      const filterTableTop = [
        ...(data?.map((tab, index) => ({
          key: tab.data.name,
          label: tab.data.name,
          value: index,
          count: tab.data.name === 'Pending' ? tab.count : undefined,
          filters: tab.data.filters,
          tabId: tab.id,
          system: tab.system,
        })) || []),
      ];

      setFilterTableTop([...filterTableTop]);
      setRequestsData(res);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getRequestTypes = async () => {
    try {
      const { data } = await getRequestType();
      setRequestType(data);
    } catch (e) {
      // Do nothing
    }
  };

  function onCheckMultiselectData(checkboxes, checkbox) {
    const map = {
      group_id: 'Tags',
      status_id: 'Status',
    };
    const filtersType = checkbox.value;
    const filtersValue = checkboxes.filter((el) => el.value === checkbox.value).map((el) => el.id);
    const checkboxData = checkboxes
      .filter((el) => el.value === checkbox.value)
      .map((el) => {
        return {
          ...el,
          title: el.value,
          searchValue: el.title,
        };
      });

    if (checkboxData.length > 1) {
      const mappedData = showHeaderFilters.map((el) => {
        if (el.value === checkbox.value) {
          return {
            ...el,
            searchValue: checkboxData,
          };
        }
        return el;
      });
      const includes = showHeaderFilters.find((el) => el.value === checkbox.value);
      includes
        ? setShowHeaderFilters([...mappedData])
        : setShowHeaderFilters([
            ...mappedData,
            {
              ...checkbox,
              title: map[checkbox.value],
              searchValue: checkbox.title,
            },
          ]);
    } else {
      const founded = showHeaderFilters.find((el) => el.value === checkbox.value);

      if (founded) {
        let mappedData;
        if (checkboxData.length === 0) {
          mappedData = showHeaderFilters.filter((el) => el.value !== checkbox.value);
        } else {
          mappedData = showHeaderFilters.map((el) => {
            if (el.value === founded.value) {
              return {
                ...el,
                searchValue: checkboxData,
              };
            }
            return el;
          });
        }

        setShowHeaderFilters([...mappedData]);
      } else {
        const firstElement = checkboxData[0];
        const { id, value, key, searchValue } = firstElement;
        const showData = { id, key, searchValue, title: map[value], value };
        setShowHeaderFilters([...showHeaderFilters, showData]);
      }
    }
    setGetPlanerFilterData({
      ...getPlanerFilterData,
      [filtersType]: filtersValue,
    });
    getDriver({ filtersType, filtersValue });
  }

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({
        ...col,
        hidden: !tableColumn?.find((el) => el.value === value)?.checked,
      });
    });
    return cols;
  };

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const mapperSettingsData = (key) => {
    const map = {
      id: 'ID',
      name: 'Name',
      req_start_datetime: 'Request Date(s)',
      request_type: 'Request Type',
      req_status: 'Status',
      note: 'Request Notes',
    };
    return map[key] ? map[key] : key;
  };

  const getSettings = () => {
    getPlannerPopoverSettings({ type: 'request' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({
              title: mapperSettingsData(key),
              value: key,
              checked: value,
            });
          });
          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
          setFilter({
            ...filter,
            switchRadioButtons: {
              date,
              amount,
              time,
            },
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getDriver = ({ filtersType, filtersValue, reset = false, remainingObj }) => {
    setLoading(true);
    return RequestData({
      page: requestsData?.current_page,
      itemsPerPage: selectedFilters.itemsPerPage,
      filtersType,
      filtersValue,
      remainingData: reset ? {} : remainingObj || getPlanerFilterData,
    })
      .then((res) => {
        setRequestsData({
          ...res,
          data: inserthourOfService(res.data || []),
        });
      })
      .finally(() => setLoading(false));
  };

  function onSearchLocations(locations) {
    if (locations.length > 0) {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      const index = filtered.findIndex((el) => Array.isArray(el));
      const locationIndex = locations[0].valuePrefix === 'origin_location' ? index : index + 1;
      filtered.splice(locationIndex, 0, [...locations]);
      setShowHeaderFilters([...filtered]);
    } else {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      setShowHeaderFilters([...filtered]);
    }
  }

  function onChangeMultiselectSearchBy(data) {
    const origin = showHeaderFilters.map((el) => el?.value === 'scheduled_origin');
    const destination = showHeaderFilters.find((el) => el?.value === 'scheduled_destination');
    if ((data.value === 'scheduled_origin' || data.value === 'scheduled_destination') && (!origin || !destination)) {
      const updatedData = { ...filter, searchBy: data };
      updateFilter(updatedData);
    } else {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'all' && !values.includes(data.value)) updateData.allValue = '';
      if (data.value === 'name' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
    }
  }

  function onSearchMultiDropDown() {
    const founded = showHeaderFilters.find((el) => +el.id === +filter.searchBy.id);
    const customersSearchValue = filter.customerSelectData.map((el) => ({
      ...el,
      searchValue: el.title,
    }));
    const customersIds = filter.customerSelectData.map((el) => el.id);
    const filtersType = filter.searchBy.value;
    let filtersValue;
    switch (filtersType) {
      case 'customers':
        filtersValue = customersIds;
        break;
      case 'name':
        filtersValue = filter.allValue;
        break;
      case 'all':
        filtersValue = filter.allValue;
        break;
      default:
    }

    if (!founded && founded?.value !== 'customers') {
      let showData;
      if (filter.searchBy.value === 'customers') {
        if (filter.customerSelectData.length > 0) {
          showData = [{ ...filter.searchBy, searchValue: customersSearchValue }, ...showHeaderFilters];
          setGetPlanerFilterData({
            ...getPlanerFilterData,
            [filtersType]: filtersValue,
          });
          getDriver({ filtersType, filtersValue });
        } else {
          showData = [...showHeaderFilters];
        }
      } else if (filtersValue) {
        showData = [{ ...filter.searchBy, searchValue: filtersValue }, ...showHeaderFilters];
        setGetPlanerFilterData({
          ...getPlanerFilterData,
          [filtersType]: filtersValue,
        });
        getDriver({ filtersType, filtersValue });
      } else {
        showData = [...showHeaderFilters];
      }
      setShowHeaderFilters(showData);
    } else if (!!filtersValue && typeof filtersValue === 'string') {
      const mapped = showHeaderFilters.map((el) => {
        if (+el.id === +founded.id) {
          return { ...el, searchValue: filtersValue };
        }
        return el;
      });
      if (filtersValue !== founded.searchValue) {
        setGetPlanerFilterData({
          ...getPlanerFilterData,
          [filtersType]: filtersValue,
        });
        getDriver({ filtersType, filtersValue });
      }
      setShowHeaderFilters([...mapped]);
    } else if (founded?.value === 'customers') {
      if (customersSearchValue.length !== 0) {
        const showData = showHeaderFilters.map((data) => {
          if (data.value === 'customers') {
            return { ...data, searchValue: [...customersSearchValue] };
          }
          return data;
        });
        setShowHeaderFilters([...showData]);
        setGetPlanerFilterData({
          ...getPlanerFilterData,
          [filtersType]: filtersValue,
        });
        getDriver({ filtersType, filtersValue });
      } else {
        const showData = showHeaderFilters.filter((data) => !['customers'].includes(data.value));
        setGetPlanerFilterData({
          ...getPlanerFilterData,
          [filtersType]: filtersValue,
        });
        setShowHeaderFilters([...showData]);
        getDriver({ filtersType, filtersValue });
      }
    } else {
      const filtered = showHeaderFilters.filter((el) => Number(el?.id) !== Number(founded?.id));
      setShowHeaderFilters([...filtered]);
      setGetPlanerFilterData({
        ...getPlanerFilterData,
        [filtersType]: filtersValue,
      });
      getDriver({ filtersType, filtersValue });
    }
  }

  const inserthourOfService = (drivers) => {
    return drivers.map((driver) => {
      return {
        ...driver,
      };
    });
  };

  const switcherData = useMemo(() => {
    return [...filterTableTop];
  }, [filterTableTop]);

  const onChangeTab = (val) => {
    setSearch('');
    setAdvancedFilter({});
    const { value } = val;
    const currentTab = {
      ...(filterTableTop.find((tab) => tab.value === value) || {}),
    };
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, tab: value, page: 1, ...(currentTab?.filters || {}) }));
  };

  const onEditTableTab = (item) => {
    setTabToEdit(item);
    setOpenAddTab(true);
  };

  const createRequest = (createReqInfo) => {
    setCreateLoading(true);
    AddRequestDetail(createReqInfo)
      .then(() => {
        setOpenCreateRequest(false);
        showToaster({ type: 'success', message: 'Request has been added successfully!' });
        setLoading(true);
        getRequests();
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  const updateRequest = (updatedRequestInfo) => {
    setCreateLoading(true);
    const { index, id, ...payload } = updatedRequestInfo;
    updateRequestDetail(payload, id)
      .then(() => {
        setOpenUpdateRequest(false);
        showToaster({ type: 'success', message: 'Request has been updated successfully!' });
        setLoading(true);
        getRequests();
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'type') {
          requestData[key] = value.map((el) => el.type);
        } else if (key === 'requestDateRange') {
          requestData[key] = value;
        } else {
          requestData[key] = value.map((el) => el.id);
        }
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.name;
    } else {
      filterObject.name = search;
    }
    setLoading(true);
    getRequests({ advancedFilter: convertFilterData(filterObject) });
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getRequests({ advancedFilter: convertFilterData(advancedFilter) });
    didMountRef.current = true;
  }, [sort, selectedFilters]);

  useEffect(() => {
    getSettings();
    getRequestTypes();
  }, []);

  const columns = useColumns({ sortingQuery, sort });

  return (
    <>
      <div className='mt-4 driver-list request-list'>
        <TableContext.Provider
          value={{
            filter,
            dragItem,
            switchRadioButtons,
            onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
            tableColumn,
            onsStTableColumn: (v) => setTableColumn(v),
            getSettings,
            updateFilter,
            onChangeOrder: (items) => setDragItem(items),
            driverData: requestsData?.data,
            createModalCLose,
            updateOnCreate,
            onSearchLocations,
            onSearchMultiDropDown,
            onCheckMultiselectData,
            onChangeMultiselectSearchBy,
          }}
        >
          <AdvancedFilter
            onSearch={onSearch}
            open={advancedOpen}
            setSearch={setSearch}
            filter={advancedFilter}
            requestTypes={requestType}
            setFilter={setAdvancedFilter}
          >
            {({ onSearchFilter }) => {
              return (
                <RequestHeader
                  search={search}
                  setSearch={setSearch}
                  onSearch={onSearchFilter}
                  setModalShow={setOpenCreateRequest}
                  advancedOpen={advancedOpen}
                  setAdvancedOpen={setAdvancedOpen}
                />
              );
            }}
          </AdvancedFilter>
          <SwitchRadio
            name='tableTopTabMenu'
            items={[...(switcherData || [])]}
            value={selectedFilters.tab}
            type='tab'
            onChange={(value) => onChangeTab(value)}
            plus
            onEdit={onEditTableTab}
            onClickPlus={() => setOpenAddTab(true)}
          />
        </TableContext.Provider>
        <div
          className={
            requestsData?.data?.length > 0 && requestsData?.data?.length < 3
              ? ' table-wrapper row-limited-height'
              : ' table-wrapper'
          }
        >
          {loading ? (
            <TablePreLoader />
          ) : (
            <MaterialTableWrapper
              data={requestsData.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: palette.white }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: requestsData,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={filterTableColumn(columns)}
              onRowClick={(e, rowData) => {
                if (
                  (rowData?.req_status === 'A' || rowData?.req_status === 'Approved') &&
                  rowData?.request_type === 'Time Off'
                ) {
                  return;
                }
                setOpenUpdateRequest(true);
                setUpdateRequestData(rowData);
              }}
              actionsColumnIndex={-1}
              actions={[
                (rowData) => ({
                  icon: () => {
                    if (
                      (rowData?.req_status === 'A' || rowData?.req_status === 'Approved') &&
                      rowData?.request_type === 'Time Off'
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className=''
                        style={{ width: '100%', backgroundColor: 'red' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Dropdown className='driver-document-document'>
                          <Dropdown.Toggle className='menu-drop' id='dropdown-basic'>
                            <img src={More} alt='' className='hidden icon' />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='dropdown-menu-contain' rootCloseEvent='mousedown'>
                            <Dropdown.Item
                              className='drop-text-contain'
                              href='#/action-1'
                              onClick={() => {
                                setOpenUpdateRequest(true);
                                setUpdateRequestData(rowData);
                              }}
                            >
                              <p className='edit-text'>Update</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    );
                  },
                }),
              ]}
            />
          )}
        </div>
      </div>
      {!!openCreateRequest && (
        <RequestListAddModal
          show={!!openCreateRequest}
          onHide={() => setOpenCreateRequest(false)}
          createRequest={createRequest}
          createLoading={createLoading}
          requestType={requestType}
        />
      )}
      {!!openUpdateRequest && (
        <RequestListUpdateModal
          show={!!openUpdateRequest}
          onHide={() => setOpenUpdateRequest(false)}
          createLoading={createLoading}
          requestType={requestType}
          updateRequestData={updateRequestData}
          updateRequest={updateRequest}
        />
      )}
      {!!openAddTab && (
        <AddTab
          open={openAddTab}
          onClose={() => setOpenAddTab(false)}
          tab={tabToEdit}
          onSuccess={() => {
            setTabToEdit(null);
            getRequests();
          }}
          onDeleteSuccess={() => {
            setTabToEdit(null);
            onChangeTab({ value: 0 });
          }}
        />
      )}
    </>
  );
};

export default RequestList;
