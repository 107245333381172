import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import Add from 'assets/icons/drivers/add.svg';
import Copy from 'assets/icons/drivers/copy.svg';
import More from 'assets/icons/drivers/more.svg';
import ColumnHeader from 'common/ColumnHeader';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import License from 'assets/icons/drivers/license.svg';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { staffDocumentData, StaffUpdateDocumentData, DriverRemoveDocumentData, AddStaffDocument } from 'Api/Staff';
import DriverLicense from '../DriverLicense/DriverLicense';
import MaterialTableWrapper from '../MaterialTableWrapper';
import UpdateDocument from '../StaffUpdateDocumentModal/StaffUpdateDocumentModal';
import MyDocumentModal from '../AddStaffDocumentModal/AddStaffDocumentModal';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';

const StaffDocument = () => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [modalShow, setModalShow] = React.useState(false);
  const [updateModalShow, setUpdateModalShow] = React.useState(false);
  const [driverIndex, setDriverIndex] = React.useState(0);
  const { id } = useParams();
  const { use } = useTheme();
  const [createLoading, setCreateLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [updateDocumentData, setUpdateDocumentData] = useState([]);
  const [sort, setSort] = useState({ field: 'id', sortBy: 'desc' });

  useEffect(() => {
    getDriverData();
  }, []);

  const getDriverData = async () => {
    staffDocumentData(id).then((res) => {
      setDocuments(res?.data);
    });
  };

  const AddUpdateDocuments = async (documentsInfo, type, onHide = null, successMsg = '') => {
    setCreateLoading(true);
    if (type === 'add') {
      const requestPostData = {
        staff_id: +id,
        document_name: [],
        exp_date: [],
        document_path: [],
      };
      for (const val of documentsInfo?.addDocument || {}) {
        if (val?.document_name && !val?.document_path) {
          showToaster({ type: 'error', message: 'Please upload document' });
          return;
        }
        const date = val?.certification_exp_date ? moment(val?.certification_exp_date).format('YYYY-MM-DD') : null;
        requestPostData.exp_date.push(val.no_expiration ? null : date);
        requestPostData.document_name.push(val?.name);
        requestPostData.document_path.push(val?.certificate_path);
      }
      AddStaffDocument(requestPostData)
        .then(() => {
          setCreateLoading(false);
          getDriverData();
          if (onHide) {
            onHide();
          }
          showToaster({ type: 'success', message: successMsg });
        })
        .catch((error) => {
          setCreateLoading(false);
          showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
        });
    } else {
      const requestUpdatePostData = {
        id: [],
        document_name: [],
        exp_date: [],
        document_path: [],
      };
      if (documentsInfo?.document_name && !documentsInfo?.document_path) {
        showToaster({ type: 'error', message: 'Please upload document' });
        return;
      }
      const date = moment(documentsInfo.exp_date || new Date())?.format('YYYY-MM-DD');
      requestUpdatePostData.exp_date.push(documentsInfo.no_expiration ? null : date);
      requestUpdatePostData.document_name.push(documentsInfo.document_name);
      requestUpdatePostData.document_path.push(documentsInfo.document_path);
      requestUpdatePostData.id.push(documentsInfo.id);

      StaffUpdateDocumentData(requestUpdatePostData, id)
        .then(() => {
          if (onHide) {
            onHide();
          }
          showToaster({ type: 'success', message: successMsg });
          getDriverData();
          setCreateLoading(false);
        })
        .catch((error) => {
          setCreateLoading(false);
          showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
        });
    }
  };

  const deleteDocument = (item) => {
    DriverRemoveDocumentData(item.id)
      .then(() => {
        showToaster({ type: 'success', message: 'Document has been deleted successfully!' });
        getDriverData();
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
      });
  };

  async function downloadImage(doc) {
    if (doc?.document_path.includes('.pdf')) {
      fetch(doc?.document_path, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `${doc?.document_name}.pdf`;
          link.click();
        });
    } else {
      fetch(doc?.document_path, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/png' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `${doc?.document_name}.png`;
          link.click();
        });
    }
  }

  function sortingQuery(field) {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
    const sortedData = (documents || []).sort((a, b) => {
      if (field === 'document_name') {
        return direction === 'asc'
          ? a.document_name > b.document_name
            ? 1
            : -1
          : a.document_name < b.document_name
          ? 1
          : -1;
      }
      if (field === 'exp_date') {
        return direction === 'asc' ? (a.exp_date > b.exp_date ? 1 : -1) : a.exp_date < b.exp_date ? 1 : -1;
      }

      return 0;
    });

    setDocuments(sortedData);
  }

  const columns = [
    {
      field: 'name',
      title: <ColumnHeader title='NAME' field='document_name' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <div onClick={() => setDriverIndex(rowData?.tableData?.id)}>
          <div className='document-details-wrap'>
            <p className='document-details-name'>{rowData?.document_name}</p>
            <img src={Copy} className='hidden-icon' alt='Copy' />
          </div>
        </div>
      ),
    },
    {
      field: 'certification_exp_date',
      title: <ColumnHeader title='EXPIRY' field='exp_date' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <div onClick={() => setDriverIndex(rowData?.tableData?.id)}>
          <div className='document-details-wrap-right'>
            <p className='document-details-value'>
              {' '}
              {rowData?.exp_date ? formatDate(rowData?.exp_date || new Date()) : 'No Expiration'}
            </p>
            <div className='hidden-icon'>
              <Dropdown className='driver-document-document' menuRole='menu' drop='drop'>
                <Dropdown.Toggle className='menu-drop' id='dropdown-basic'>
                  <img src={More} alt='' className='hidden icon' />
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-menu-contain'>
                  <Dropdown.Item className='drop-text-contain' href='#/action-1' onClick={() => downloadImage(rowData)}>
                    <p className='edit-text'>Download</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='drop-text-contain'
                    href='#/action-2'
                    onClick={() => {
                      setUpdateDocumentData({
                        ...rowData,
                        index: rowData?.tableData?.id,
                        no_expiration: !rowData.exp_date,
                      });
                      setUpdateModalShow(true);
                    }}
                  >
                    <p className='edit-text'>Edit</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='drop-text-contain'
                    href='#/action-3'
                    onClick={() => deleteDocument(rowData, rowData?.tableData?.id)}
                  >
                    <p className='delete-text'>Delete</p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div
      className='Driver-document-tab driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div
        className='driver-document'
        style={{
          borderColor: use(palette.gray50, palette.darkborder),
          backgroundColor: use(palette.white, palette.dark800),
        }}
      >
        {modalShow && (
          <MyDocumentModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            modalShow={modalShow}
            loading={createLoading}
            AddUpdateDocuments={AddUpdateDocuments}
            id={id}
          />
        )}
        {updateModalShow && (
          <UpdateDocument
            show={updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            updateDocumentData={updateDocumentData}
            setUpdateDocumentData={setUpdateDocumentData}
            loading={createLoading}
            AddUpdateDocuments={AddUpdateDocuments}
            id={id}
          />
        )}

        <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
          <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Documents
          </p>
          <PrimaryBtn
            icon={Add}
            style={{ color: use(palette.gray700, palette.gray200) }}
            title='Add Document'
            onClick={() => setModalShow(true)}
          />
        </div>

        <div className='document-details'>
          <MaterialTableWrapper
            data={documents}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            columns={columns}
          />
        </div>
      </div>
      <DriverLicense
        picture={documents?.[driverIndex]?.document_path ? documents?.[driverIndex]?.document_path : License}
      />
    </div>
  );
};

export default StaffDocument;
