import styled from 'styled-components';

export const SOfferVehicle = styled.div`
  width: 100%;
  margin-bottom: 16px;

  .drivers-table {
    width: calc(100% - 400px);
  }

  .offer-options {
    width: 400px;
  }
`;
