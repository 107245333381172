import styled from 'styled-components';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';

export const SButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
`;

export const SBigButton = styled.button`
  width: 300px;
  border: 0;
  outline: 0;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-radius: 12px;

  color: ${palette.gray700};

  :hover {
    background-color: ${palette.gray50};
  }
`;

export const SAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    flex: 1;

    .MuiInputBase-root {
      min-height: unset;
      height: 24px;
      max-width: 300px;

      &.MuiOutlinedInput-root {
        padding: 2px 24px 2px 8px;
      }

      .MuiAutocomplete-endAdornment {
        top: calc(50% - 12px);
      }
    }
  }
`;

export const SPhoneAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding-left: ${({ $country }) => ($country ? '32px' : '')};
    }
  }
`;

export const SOption = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 1px 8px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const SInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const SWarning = styled.div`
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 4px 6px rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(8px);
  border-radius: 6px;
  padding: 10px 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: #222834;
  cursor: pointer;
  background: rgba(241, 214, 147, 0.3);
  color: rgba(189, 155, 25, 1);
  margin-bottom: 12px;

  span {
    color: #4f5aed;
    font-weight: bold;
    cursor: pointer;
  }
`;
