import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SSimpleList, SSimpleListRow } from 'pages/Accounting/Payables/Payables.styles';

const AccountsList = ({ data, selected, updateSelected }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <SSimpleList>
      <thead>
        <tr>
          <th>VENDOR</th>
          <th className='text-right'>TOTAL</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <SSimpleListRow
            key={item.id}
            className={`pointer ${selected?.id === item.id ? 'selected-row' : ''}`}
            onClick={() => updateSelected(item)}
          >
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {item.name}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {currency}
                {formatNumber(item.recurring_bill_total)}
              </Typography>
            </td>
            <td />
          </SSimpleListRow>
        ))}
      </tbody>
    </SSimpleList>
  );
};

export default AccountsList;
