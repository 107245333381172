import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getAwardExpirationData, getBidExpirationData } from './helpers';

const OfferExpiration = ({ offerOptions, awardDate }) => {
  const { convertToCustomerTime } = useDateFormat();
  const [expiration, setExpiration] = useState([0, 0, 0]);
  const [expireDate, setExpireDate] = useState('');

  const getExpirationData = () => {
    const { expire_date } = awardDate
      ? getAwardExpirationData(offerOptions, awardDate)
      : getBidExpirationData(offerOptions);

    const days = moment(expire_date).diff(moment().utc(), 'days');
    const hours = moment(expire_date).diff(moment().utc(), 'hours') % 24;
    const minutes = moment(expire_date).diff(moment().utc(), 'minutes') % 60;

    setExpiration([days, hours < 0 ? '00' : hours.toString(), minutes < 0 ? '00' : minutes.toString()]);
    setExpireDate(expire_date);
  };

  useEffect(() => {
    if (!offerOptions) {
      return;
    }
    getExpirationData();

    const intervalId = setInterval(() => {
      getExpirationData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [offerOptions, awardDate]);

  return (
    <Typography variant='b2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
      {moment(expireDate).diff(moment(), 'minutes') < 0 ? (
        <>Expired on {convertToCustomerTime(expireDate)}</>
      ) : (
        <>
          {!awardDate && 'Expires in'} {expiration[0] ? `${expiration[0]}d` : ''} {expiration[1]}h{' '}
          {expiration[2]?.length === 1 ? `0${expiration[2]}` : expiration[2]}m {!!awardDate && 'remaining'}
        </>
      )}
    </Typography>
  );
};

export default OfferExpiration;
