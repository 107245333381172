import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import classes from './validationlModal.module.scss';
import { useTheme } from '../../../context/themeContext';
import { palette } from '../../../utils/constants';
import { Typography } from '../../Typography';
import CustomButton from '../../CustomButton/CustomButton';

const ValidationModal = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();

  const styles = useMemo(() => {
    return {
      modal: {
        width: 360,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      styleButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 16,
      },
    };
  }, [use, palette]);

  const onClickProceed = () => {
    onAddBtn(data.offer);
  };

  const onClickNeverMind = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      size='lg'
      centered
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      backdropClassName={classes.backDropModal}
      dialogClassName='dialogClassName'
    >
      <div className={classNames(classes.modalWrapper, use(classes.light, classes.dark))}>
        <div className={classes.header}>
          <Typography variant='s2'>{data.header}</Typography>
        </div>
        <div className={classes.line} />
        <div className={classes.body}>
          <Typography variant='s3'>{data.title}</Typography>
          {/*    <Typography variant={'s3'}> */}
          {/*        Are you sure you want to cancel “Shipment 232344”? Once cancelled the */}
          {/*        customer will not be billed and will move the shipment to cancelled shipments. */}
          {/*    </Typography> */}
        </div>
        {/* <div className={classes.line}/> */}
        <div className={classes.buttons}>
          <CustomButton type='danger' title='Yes, Proceed' styleButton={styles.styleButton} onClick={onClickProceed} />

          <CustomButton
            type='secondary'
            title='Nevermind'
            styleButton={styles.styleButton}
            onClick={onClickNeverMind}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ValidationModal;
