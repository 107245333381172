import React from 'react';
import moment from 'moment';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-filled.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/equipment/Phone.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { formatPhoneNumber } from 'utils/helpers';
import BidDate from './components/BidDate';
import RowActions from './components/RowActions';
import OfferExpiration from './components/OfferExpiration';
import { SCarrierNameImage } from './ShipmentDetails.styles';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
};

const bidStatus = {
  1: { label: 'BID RECEIVED', color: palette.orange500, bgColor: palette.orange0 },
  2: { label: 'OFFER DECLINED', color: palette.red500, bgColor: palette.red0 },
  3: { label: 'AWARDED', color: palette.green500, bgColor: palette.green0 },
  4: { label: 'AWARD ACCEPTED', color: palette.green500, bgColor: palette.green0 },
  5: { label: 'AWARD DECLINED', color: palette.red500, bgColor: palette.red0 },
  6: { label: 'BID DECLINED', color: palette.red500, bgColor: palette.red0 },
  7: { label: 'EXPIRED', color: palette.red500, bgColor: palette.red0 },
  8: { label: 'AWARDED & DISPATCHED', color: palette.green500, bgColor: palette.green0 },
};

export const useColumns = ({
  shipment,
  onAwardBid,
  onNoteClick,
  onDeclineBid,
  onRevertOffer,
  onRevertAward,
  sort,
  sortingQuery,
  onUpdatePreferBid,
  offerOptions,
  loadingAction,
}) => {
  const { convertToCustomerTime } = useDateFormat();
  const { offer_status, loaded_miles, shipment_billing, brokerage_dispatch, assigned_to1, dispatched_by_user } =
    shipment || {};
  const isDispatched = offer_status === 'awarded_and_dispatched';
  const assignedTo = isDispatched
    ? { id: brokerage_dispatch?.carrier?.id || assigned_to1, type: brokerage_dispatch ? 'carrier' : 'driver' }
    : null;

  const loadedMiles = Math.round(loaded_miles || 0);
  const shipmentAmount = shipment_billing?.reduce((acc, cur) => acc + Number(cur.total_amount), 0);

  return [
    {
      field: 'preferred',
      title: <ColumnHeader title='PREF' field='preferred' />,
      render: (row) =>
        !!row.carrier && (
          <StarIcon
            fill={row.carrier?.preferred ? palette.amber500 : palette.white}
            stroke={row.carrier?.preferred ? undefined : palette.gray500}
            width={16}
            height={17}
            className='pointer ms-2'
            onClick={(e) => {
              e.stopPropagation();
              onUpdatePreferBid(row);
            }}
          />
        ),
    },
    {
      field: 'bid_by',
      title: <ColumnHeader title='BID BY' field='bid_by' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <SCarrierNameImage>
          {row.carrier?.logo_path || row.driver?.image ? (
            <img
              src={row.carrier?.logo_path || row.driver?.image}
              alt='img'
              width={30}
              height={30}
              className='carrier-image'
            />
          ) : row.driver ? (
            <ProfileIcon width={30} height={30} className='carrier-image' fill={palette.gray400} />
          ) : (
            <CarrierIcon width={30} height={30} className='carrier-image' />
          )}
          <div className='d-flex flex-column align-items-start gap-1'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.carrier?.name || `${row.driver.fname} ${row.driver.lname}`}{' '}
              {!!row.offer_accepted && !!row.carrier && !!row.shipment_stops_schedule_dates && (
                <NoteIcon
                  width={14}
                  height={14}
                  className='pointer'
                  onClick={(e) => {
                    e.stopPropagation();
                    onNoteClick(row, shipment);
                  }}
                />
              )}
              {row.carrier?.status_id === 1 && (
                <Tooltip title='WARNING: Carrier is not onboarded, profile is in pending status!'>
                  <WarningIcon fill={palette.red500} />
                </Tooltip>
              )}
            </Typography>
            {!!row.selected_contact_user && (
              <>
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  {row.selected_contact_user.contact_name}
                </Typography>
                <Typography
                  variant='b2'
                  as='a'
                  href={`tel:${row.selected_contact_user.phone_number}`}
                  style={{ color: palette.gray500, textDecoration: 'none' }}
                >
                  <PhoneIcon fill={palette.indigo500} /> {formatPhoneNumber(row.selected_contact_user.phone_number)}
                </Typography>
                <Typography
                  variant='b2'
                  as='a'
                  href={`mailto:${row.selected_contact_user.contact_email}`}
                  style={{ color: palette.gray500, textDecoration: 'none' }}
                >
                  <MailIcon fill={palette.indigo500} /> {row.selected_contact_user.contact_email}
                </Typography>
              </>
            )}
          </div>
        </SCarrierNameImage>
      ),
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        if (isDispatched) {
          const isDispatchedTo = assignedTo?.type === 'carrier' ? row.carrier?.id === assignedTo?.id : row.driver?.id;

          if (isDispatchedTo) {
            return (
              <Chip
                label={bidStatus[8]?.label}
                labelColor={bidStatus[8]?.color}
                bgColor={bidStatus[8]?.bgColor}
                fontSize='11px'
                fontWeight={500}
              />
            );
          }
        }

        return (
          <div className='d-flex flex-column align-items-start gap-1'>
            {!row.offer_accepted && row.shipment_offer?.expiration_date ? (
              <Chip
                label={bidStatus[7]?.label}
                labelColor={bidStatus[7]?.color}
                bgColor={bidStatus[7]?.bgColor}
                fontSize='11px'
                fontWeight={500}
              />
            ) : (
              <Chip
                label={bidStatus[row.offer_accepted]?.label || 'OFFERED'}
                labelColor={bidStatus[row.offer_accepted]?.color || palette.gray700}
                bgColor={bidStatus[row.offer_accepted]?.bgColor || palette.gray0}
                fontSize='11px'
                fontWeight={500}
              />
            )}
            {row.offer_accepted === 2 && !!row.decline_reason && (
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Reason: {row.decline_reason.name}
              </Typography>
            )}
            {row.offer_accepted === 6 && !!row.declined_by && (
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                {row.declined_by.first_name} {row.declined_by.last_name}
              </Typography>
            )}
            {row.offer_accepted === 4 && (
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                {convertToCustomerTime(row.award_accepted_date)}
              </Typography>
            )}
            {row.offer_accepted === 3 && <OfferExpiration offerOptions={offerOptions} awardDate={row.award_date} />}
          </div>
        );
      },
    },
    {
      field: 'bid_date',
      title: <ColumnHeader title='BID DATE' field='bid_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (row.bid_date && !!row.offer_accepted ? <BidDate bidDate={row.bid_date} /> : '-'),
    },
    {
      field: 'rpm',
      title: <ColumnHeader title='RPM' field='rpm' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.bid_amount ? `$${formatNumber(row.rpm)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'location',
      title: <ColumnHeader title='DRIVER LOCATION' field='location' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.city?.name && row.state?.short_name ? `${row.city.name}, ${row.state.short_name}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'empty_miles',
      title: <ColumnHeader title='EMPTY MILES' field='empty_miles' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {Math.round(row.eta?.empty_miles || row?.empty_miles || 0)}
        </Typography>
      ),
    },
    {
      field: 'loaded_miles',
      title: <ColumnHeader title='LOADED MILES' field='loaded_miles' sort={sort} onClick={sortingQuery} />,
      render: () => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {loadedMiles}
        </Typography>
      ),
    },
    {
      field: 'meet_eta',
      title: <ColumnHeader title='MEETS DEADLINES' field='meet_eta' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography
          variant='b2'
          style={{ color: row.meet_eta === 'No' ? palette.red500 : palette.gray700 }}
          className={row.meet_eta === 'No' ? 'pointer' : ''}
          onClick={(e) => {
            e.stopPropagation();
            if (row.meet_eta === 'No') {
              onNoteClick(row, shipment);
            }
          }}
        >
          {row.meet_eta}
        </Typography>
      ),
    },
    {
      field: 'eta',
      title: <ColumnHeader title='ETA' field='eta' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <div className='d-flex flex-column'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.eta?.eta_hours || '-'}
          </Typography>
          {!!row.driver_available_from && !!row.eta && (
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {convertToCustomerTime(moment(row.driver_available_from).add(row.eta.minutes, 'minutes'))}
            </Typography>
          )}
        </div>
      ),
    },
    {
      field: 'eta_margin',
      title: <ColumnHeader title='ETA MARGIN' field='eta_margin' sort={sort} onClick={sortingQuery} />,
      render: (row) =>
        row.eta && row.eta_margin_labels ? (
          <Tooltip title={row.eta_margin > 0 ? 'Driver is early' : 'Driver will be late'}>
            <span>
              <Typography
                variant='b2'
                style={{ color: row.eta_margin > 0 ? palette.green500 : palette.red500 }}
                className='d-flex align-items-center'
              >
                <>
                  {row.eta_margin !== 0 && (
                    <ArrowUp
                      fill={row.eta_margin > 0 ? palette.green500 : palette.red500}
                      style={{ transform: row.eta_margin > 0 ? 'rotate(180deg)' : '' }}
                    />
                  )}
                  {row.eta_margin_labels[0] ? `${row.eta_margin_labels[0]}d` : ''} {row.eta_margin_labels[1]}h{' '}
                  {row.eta_margin_labels[2]?.length === 1 ? `0${row.eta_margin_labels[2]}` : row.eta_margin_labels[2]}m
                </>
              </Typography>
            </span>
          </Tooltip>
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        ),
    },
    {
      field: 'offered_date',
      title: <ColumnHeader title='OFFERED DATE' field='updated_at' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.shipment_offer?.offer_date
            ? convertToCustomerTime(`${row.shipment_offer.offer_date} ${row.shipment_offer.offer_time} `)
            : '-'}
        </Typography>
      ),
    },
    {
      field: 'offered_amount',
      title: <ColumnHeader title='OFFERED AMOUNT' field='offered_amount' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          ${formatNumber(row.posted_amount)}
        </Typography>
      ),
    },
    {
      field: 'bid_amount',
      title: <ColumnHeader title='BID AMOUNT' field='bid_amount' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          ${formatNumber(row.bid_amount)}
        </Typography>
      ),
    },
    {
      field: 'margin',
      title: <ColumnHeader title='MARGIN' field='margin' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const percent = row.bid_amount ? ((shipmentAmount - Number(row.bid_amount)) * 100) / shipmentAmount : null;
        return (
          <Typography
            variant='b2'
            style={{
              color: !percent || percent === 0 ? palette.gray700 : percent > 0 ? palette.green500 : palette.red500,
            }}
          >
            {percent !== null ? (
              <>
                {percent < 0 ? '-' : percent > 0 ? '+' : ''}
                {formatNumber(Math.abs(percent))}%
              </>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    ...(assignedTo
      ? [
          {
            field: 'dispatched_by_user',
            title: <ColumnHeader title='DISPATCHED BY' field='dispatched_by_user' />,
            render: () => (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {dispatched_by_user ? `${dispatched_by_user.first_name} ${dispatched_by_user.last_name}` : 'System'}
              </Typography>
            ),
          },
          {
            field: 'awarded_by',
            title: <ColumnHeader title='AWARDED BY' field='awarded_by' />,
            render: (row) => (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {row.awarded_by_user ? `${row.awarded_by_user.first_name} ${row.awarded_by_user.last_name}` : 'System'}
              </Typography>
            ),
          },
        ]
      : [
          {
            field: 'actions',
            title: <ColumnHeader title='ACTIONS' field='actions' />,
            render: (row) => (
              <RowActions
                bid={row}
                shipment={shipment}
                onRevertOffer={onRevertOffer}
                onAwardBid={onAwardBid}
                onDeclineBid={onDeclineBid}
                onRevertAward={onRevertAward}
                loading={loadingAction}
              />
            ),
          },
        ]),
  ];
};
