import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as ArrowLeft } from 'assets/icons/vehicles/sub-right.svg';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { blockNotNumberChars } from 'utils/helpers';
import AddressFields from '../../components/AddressFields';
import { applicantStatuses } from './ApplicantInfo.data';
import { SFlexGridItem, SInput, SMaskWrapper } from '../Steps.styles';

const ApplicantInfo = ({ form, applicantInfo, ...basicInfo }) => {
  const { use } = useTheme();
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [formattedEin, setFormattedEin] = useState('');
  const { values, handleChange, touchedErrors } = form;

  const getMaskedValue = (value) => {
    return value.split('').map((item, index) => {
      if (index < 5) {
        return <span>*</span>;
      }

      return <span>{item}</span>;
    });
  };

  const handleEinChange = (event) => {
    const input = event.target.value.replace('-', '').substring(0, 9);
    const cursorPosition = event.target.selectionStart;

    if (event.nativeEvent.inputType === 'deleteContentBackward' && cursorPosition === 3) {
      setTimeout(() => {
        event.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
      }, 0);
    } else if (event.nativeEvent.inputType === 'deleteContentForward' && cursorPosition === 2) {
      setTimeout(() => {
        event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      }, 0);
    }

    setFormattedEin(input.replace(/([a-zA-Z0-9]{2})([a-zA-Z0-9]{0,7})/, '$1-$2'));
    handleChange('ein', input);
  };

  useLayoutEffect(() => {
    setInputWidth(inputRef?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    if (applicantInfo) {
      return;
    }

    const { fullName, socialNumber, addresses } = basicInfo;
    handleChange('fullName', fullName);
    handleChange('socialNumber', socialNumber);
    handleChange('country', addresses?.[0]?.country);
    handleChange('state', addresses?.[0]?.state);
    handleChange('city', addresses?.[0]?.city);
    handleChange('address1', addresses?.[0]?.address1);
    handleChange('address2', addresses?.[0]?.address2 || '');
    handleChange('zipcode', addresses?.[0]?.zipcode);
    if (
      applicantInfo?.ein &&
      applicantInfo?.job_application?.job_applicant_information?.applicant_status === 'company'
    ) {
      setFormattedEin(applicantInfo.ein.replace(/([a-zA-Z0-9]{2})([a-zA-Z0-9]{0,7})/, '$1-$2'));
    }
  }, []);

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={2}>
        <SFlexGridItem item xs={12} className='mb-3'>
          <Typography variant='s2'>Applicant Status:</Typography>
          {applicantStatuses.map((item) => (
            <CustomRadioButton
              key={item.value}
              field={{
                name: 'applicantStatus',
                value: item.value,
                onChange: handleChange,
                checked: values.applicantStatus === item.value,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                {item.label}
              </Typography>
            </CustomRadioButton>
          ))}
        </SFlexGridItem>
        <Grid container item xs={12} columnSpacing={1.5}>
          <Grid item>
            <ArrowLeft style={{ verticalAlign: 'top' }} />
          </Grid>
          <Grid container item xs={11} columnSpacing={2.5} rowSpacing={2}>
            {values.applicantStatus === 'individual' ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Input
                    name='fullName'
                    placeholder='Brandon Westervelt'
                    label='Full Name'
                    onChange={handleChange}
                    value={values.fullName}
                    error={touchedErrors.fullName}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SMaskWrapper $maxWidth={inputWidth - 24}>
                    <SInput
                      type='number'
                      name='socialNumber'
                      placeholder='000-00-0000'
                      label='Social Security Number'
                      onChange={(e) => {
                        if (e.target.value.length > 9) {
                          return;
                        }
                        handleChange(e);
                      }}
                      onKeyDown={blockNotNumberChars}
                      onCopy={(e) => e.preventDefault()}
                      value={values.socialNumber}
                      error={touchedErrors.socialNumber}
                      required
                      ref={inputRef}
                    />
                    <div className='input-mask' onClick={() => inputRef?.current?.focus()}>
                      {getMaskedValue(values.socialNumber)}
                    </div>
                  </SMaskWrapper>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <Input
                    name='companyName'
                    label='Company Name'
                    onChange={handleChange}
                    value={values.companyName}
                    error={touchedErrors.companyName}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    name='ein'
                    placeholder='00-0000000'
                    label='EIN'
                    onChange={handleEinChange}
                    value={formattedEin}
                    error={touchedErrors.ein}
                    required
                  />
                </Grid>
              </>
            )}
            <AddressFields form={form} />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ApplicantInfo;
