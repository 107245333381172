import React from 'react';
import { useNavigate } from 'react-router-dom';
import Chip from 'common/Chip';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import classes from './Accidents.module.css';
import RowActions from './RowActions';

export const useColumns = ({
  sort,
  sortingQuery,
  setFaultCode,
  onGeneratePdf,
  setShowMoreOpen,
  setSelectedIncident,
  setCreateOrAssignOpen,
}) => {
  const { formatDate } = useDateFormat();
  const navigate = useNavigate();

  return [
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: palette.dark800 }}>{report_number || '-'}</span>;
      },
    },
    {
      field: 'type',
      title: <ColumnHeader title='Type' field='type' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { type } = rowData;
        return <Typography>{type || '-'}</Typography>;
      },
    },
    {
      field: 'incident_id',
      title: <ColumnHeader title='Incident ID' field='incident_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { incident } = rowData;
        const { incident_id } = incident || {};
        return (
          <div>
            {incident_id ? (
              <div className='d-flex align-items-center gap-3 pointer'>
                <span style={{ color: palette.dark800 }}>{incident_id}</span>
              </div>
            ) : (
              <span
                className={classes.viewButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIncident(rowData);
                  setFaultCode(e.currentTarget);
                  setCreateOrAssignOpen(true);
                }}
              >
                Create or Assign Incident
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='collision_date_time' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { collision_date_time } = rowData;
        return <span style={{ color: palette.dark800 }}>{formatDate(collision_date_time) || '-'}</span>;
      },
    },
    {
      field: 'driver',
      title: <ColumnHeader title='Driver' />,
      render: (rowData) => {
        return rowData.driver ? (
          <div className='d-flex align-items-center gap-3'>
            <UserInfo
              size='28px'
              data={{
                ...rowData,
                id: rowData.driver?.id,
                name: `${rowData.driver?.fname} ${rowData?.driver?.lname}`,
                image: rowData.driver?.image,
                phone_number: rowData.driver?.phone_number,
                email: rowData.driver?.email,
                status: rowData.driver?.status_data,
                user_type: 'driver',
              }}
            />
          </div>
        ) : (
          <div />
        );
      },
    },
    {
      field: 'vehicle',
      title: <ColumnHeader title='Vehicle' />,
      render: (rowData) => {
        const { vehicle } = rowData;
        const { equipment_id, equipment_type } = vehicle || {};
        return (
          <span style={{ color: palette.dark800 }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {equipment_type?.title || ''}
                </Typography>
              </div>
            ) : (
              <div />
            )}
          </span>
        );
      },
    },
    {
      field: 'trailer',
      title: <ColumnHeader title='Trailer' />,
      render: (rowData) => {
        const { trailer } = rowData;
        const { equipment_type, length, equipment_id } = trailer || {};
        return (
          <span style={{ color: palette.dark800 }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {length?.length || ''} {equipment_type?.title || ''}
                </Typography>
              </div>
            ) : (
              <div />
            )}
          </span>
        );
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='Status' />,
      render: (row) => {
        const { status } = row;
        return (
          <Chip
            fontWeight={500}
            fontSize='11px'
            bgColor={status.bg_color}
            labelColor={status.color}
            label={status.name}
          />
        );
      },
    },
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { accident_location_state_id } = rowData;
        return <span style={{ color: palette.dark800 }}>{accident_location_state_id || '-'}</span>;
      },
    },
    {
      field: 'violation_description',
      title: <ColumnHeader title='Description' />,
      render: (rowData) => {
        const { violation_description } = rowData;
        return (
          <span style={{ color: palette.dark800 }}>
            {violation_description?.slice(0, 40)}
            {violation_description?.length > 40 && (
              <Typography
                variant='c1'
                style={{ color: palette.indigo500, marginLeft: 10 }}
                onClick={() => setShowMoreOpen(violation_description)}
              >
                Read More
              </Typography>
            )}
          </span>
        );
      },
    },
    {
      field: 'shipment_id',
      title: <ColumnHeader title='Shipment' field='shipment_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { shipment_id } = rowData;
        return (
          <span
            style={{ color: palette.dark800 }}
            onClick={(e) => {
              e.stopPropagation();
              if (shipment_id) {
                navigate(`/shipment/${shipment_id}`);
              }
            }}
          >
            {shipment_id || '-'}
          </span>
        );
      },
    },
    {
      field: 'claimed',
      title: <ColumnHeader title='Insurance Claimed' />,
      render: (rowData) => {
        const { cost_analysis_cargo_insurance_claim } = rowData;
        return <span style={{ color: palette.dark800 }}>{cost_analysis_cargo_insurance_claim ? 'Yes' : 'No'}</span>;
      },
    },
    {
      field: 'cost_analysis_total_cost',
      title: <ColumnHeader title='Total Cost' field='cost_analysis_total_cost' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { cost_analysis_total_cost } = rowData;
        return <span style={{ color: palette.dark800 }}>{cost_analysis_total_cost || '-'}</span>;
      },
    },
    {
      field: 'actions',
      title: <ColumnHeader title='' />,
      render: (row) => <RowActions onGeneratePdf={() => onGeneratePdf(row.id)} />,
    },
  ];
};
