import { ACCOUNT_TYPE } from 'utils/constants';

export const getInitialValues = (account) => {
  return {
    account_type: account?.account_type || null,
    cost_type: account?.cost_type || null,
    account_code: account?.account_code || '',
    account_name: account?.account_name || '',
    account_bank_name: account?.account_bank_name || '',
    account_number: account?.account_number || '',
    account_routing: account?.account_routing || '',
    account_starting_check: account?.account_starting_check || '',
    description: account?.description || '',
    interest_rate: account?.interest_rate || '',
    loan_term: account?.loan_term || '',
    loan_term_type: account?.loan_term_type || 'years',
    loan_amount: account?.loan_amount || '',
    has_opening_balance: false,
    opening_balance: '',
    opening_date: null,
    current_balance: '',
    compounding: compoundingOptions.find((i) => i.id === Number(account?.compounding)) || null,
    add_recurring_bill: false,
    vendor: null,
    next_payment_due: null,
    account_logo: account?.account_logo || null,
    address: account?.address1 || null,
    address2: account?.address2 || null,
    country: account?.country || null,
    state: account?.state || null,
    city: account?.city || null,
    zipcode: account?.zipcode || null,
  };
};

export const accountTypeInputMapper = {
  cost_type: [ACCOUNT_TYPE.EXPENSE],
  account_bank_name: [ACCOUNT_TYPE.BANK],
  account_number: [ACCOUNT_TYPE.BANK, ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CURRENT_LIABILITY],
  account_routing: [ACCOUNT_TYPE.BANK],
  account_starting_check: [ACCOUNT_TYPE.BANK],
  loan: [ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CREDIT_CARD],
  openingBalance: [ACCOUNT_TYPE.BANK, ACCOUNT_TYPE.LONG_TERM_LIABILITY, ACCOUNT_TYPE.CURRENT_LIABILITY],
};

export const compoundingOptions = [
  { id: 1, name: 'Monthly (APR)' },
  { id: 2, name: 'Annually (APY)' },
];

export const plaidAccountType = {
  credit: ACCOUNT_TYPE.CREDIT_CARD,
  loan: ACCOUNT_TYPE.LOAN,
};

export const hasFeed = [ACCOUNT_TYPE.BANK, ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CREDIT_CARD];
