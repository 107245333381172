import React, { useContext } from 'react';

import './driverinfo.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import ChatIcon from 'assets/icons/header/chat-20px.svg';
import { LayoutContext } from 'components/layout';

const DriverInfo = ({ driverData, deleteDriverModal, driver }) => {
  const { dot_num } = useContext(LayoutContext);

  const [drivers, Driver_Id, Eq_id, title, rowId, status] = driverData;
  const { use } = useTheme();

  const navigate = useNavigate();

  const Driverinfo = drivers;
  const DriverId = Driver_Id;
  const Equipment_id = Eq_id;
  const Equipment_title = title;
  return (
    <div
      className='equipment-list-driver-info-wrapper'
      style={{ backgroundColor: use(palette.white, palette.dark800) }}
    >
      {Driverinfo.map(
        (info) =>
          DriverId === info?.id && (
            <div key={info?.id}>
              <div
                className='driver-info-popover-header'
                style={{ backgroundColor: use(palette.white, palette.dark800) }}
              >
                <div className='d-flex p-0 align-items-center'>
                  <div className='user-img-wrapper' style={{ cursor: 'pointer' }}>
                    {/* <img className="user-img" src={info.image} /> */}
                    <img src={info?.image ? `${info?.image}` : userDefault} className='user-img' alt='' />
                    <span
                      className={
                        info?.status_data?.driver_status === 'Available'
                          ? ' ellipse available '
                          : info?.status_data?.driver_status === 'On Shipment'
                          ? 'ellipse on-shipment'
                          : info?.status_data?.driver_status === 'Out of Service'
                          ? 'ellipse out-of-service-status'
                          : info?.status_data?.driver_status === 'Terminated'
                          ? 'ellipse terminated'
                          : info?.status_data?.driver_status === 'Quit'
                          ? 'ellipse quit'
                          : info?.status_data?.driver_status === 'Not Available'
                          ? 'not-available-status-ellipse'
                          : ''
                      }
                    />
                  </div>
                  <div
                    onClick={() => {
                      navigate(`/driver-profile/${DriverId}/general`);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className='user-name ms-2' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {info.fname}
                    </span>
                    <span className='user-name' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {info.lname}
                    </span>
                  </div>
                </div>
              </div>
              <div className='ms-2 mt-2 me-2'>
                <div className='mb-2 list'>
                  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M13.8992 10.5979C13.8686 10.4884 13.8099 10.389 13.7289 10.3099C13.648 10.2308 13.5478 10.1748 13.4385 10.1477C12.356 9.85602 11.3261 9.39248 10.3873 8.77431C10.2948 8.71539 10.1889 8.68153 10.0798 8.67601C9.97064 8.67049 9.86193 8.69349 9.76412 8.74279C9.12376 9.06539 8.6214 9.61279 8.35089 10.2827C7.46307 9.67834 6.63635 8.98682 5.88275 8.21822C5.12374 7.45577 4.4409 6.61946 3.84414 5.72144C4.20704 5.57304 4.53639 5.35169 4.81226 5.07079C5.03769 4.84374 5.22555 4.58148 5.36865 4.29407C5.41603 4.19505 5.43782 4.0855 5.43198 3.97565C5.42614 3.8658 5.39287 3.75924 5.33527 3.66593C4.72337 2.71501 4.26509 1.67155 3.97768 0.57479C3.95112 0.464349 3.89585 0.363105 3.81758 0.281555C3.73931 0.200006 3.64091 0.141121 3.53257 0.111006C3.06337 -0.022493 2.56872 -0.0358011 2.09317 0.0722807C1.61763 0.180363 1.23245 0.356246 0.808488 0.730135C-0.44636 1.83678 -0.0283202 3.19765 0.639346 5.04152C1.2625 6.73231 2.46208 8.52891 4.01551 10.1026C5.56895 11.6763 7.35162 12.8876 9.02301 13.5247C9.7558 13.8179 10.5342 13.9772 11.322 13.9953C12.0294 14.0258 12.7128 13.9206 13.2805 13.3424C13.6597 12.956 13.8023 12.5606 13.9103 12.1018C14.0336 11.6078 14.0297 11.0901 13.8992 10.5979Z'
                      fill='#868FA0'
                    />
                  </svg>
                  <span className=' ms-2'>
                    <a href='tel:{info.phone_number}' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {info.phone_number}
                    </a>
                  </span>
                </div>
                <div className='mb-2'>
                  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M3 2.5C1.89543 2.5 1 3.39543 1 4.5V11.5C1 12.6046 1.89543 13.5 3 13.5H13C14.1046 13.5 15 12.6046 15 11.5V4.5C15 3.39543 14.1046 2.5 13 2.5H3ZM4.3976 4.86401C4.04635 4.64448 3.58363 4.75126 3.3641 5.10251C3.14457 5.45376 3.25135 5.91647 3.6026 6.13601L7.6026 8.63601C7.8458 8.78801 8.1544 8.78801 8.3976 8.63601L12.3976 6.13601C12.7489 5.91647 12.8556 5.45376 12.6361 5.10251C12.4166 4.75126 11.9539 4.64448 11.6026 4.86401L8.0001 7.11557L4.3976 4.86401Z'
                      fill='#868FA0'
                    />
                  </svg>
                  <span className=' info-link ms-2'>
                    <a href='mailto:{info.email}' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {info.email}
                    </a>
                  </span>
                </div>
                <div className='mb-2'>
                  <img src={ChatIcon} alt='' />
                  <span
                    className='info-link ms-2'
                    onClick={() => navigate(`/chat/${dot_num}_driver_group_${info.id}`)}
                    style={{ color: use(palette.dark800, palette.gray200), cursor: 'pointer' }}
                  >
                    Chat
                  </span>
                </div>
              </div>
              {!driver && (
                <div className='driver-info-popover-footer'>
                  <button
                    className='vehicle-list-remove-driver remove-driver-button'
                    onClick={() =>
                      deleteDriverModal(info?.id, rowId, info?.fname, info.lname, Equipment_id, Equipment_title, status)
                    }
                  >
                    Remove Driver
                  </button>
                </div>
              )}
            </div>
          )
      )}
    </div>
  );
};

export default DriverInfo;
