import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import RecurringModal from '../../../components/RecurringModal';
import { monthDays, monthWeekOptions, weekDays } from '../../Tables.data';
import { SAutocomplete, SInput, SRecurringWrapper, SFlexWrapper, SInputWrapper } from '../../Tables.styles';

const Recurring = ({ data, onDayChange, onMonthWeekChange, onAmortizedFieldChange, handleSubmit }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const [open, setOpen] = useState(false);

  return (
    <SFlexWrapper>
      {!!data.every_settlement && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          Every Settlement
        </Typography>
      )}
      {!!data.amortized_settlement &&
        ((data.edit || data.isNew) && !data.is_balance_deduction ? (
          <>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
              Amortized
            </Typography>
            <SInputWrapper>
              {currency}
              <SInput
                type='number'
                placeholder='0.00'
                value={data.amortized_total}
                onChange={(e) => onAmortizedFieldChange(data.id, 'amortized_total', e.target.value)}
                style={{ boxShadow: 'none' }}
              />
            </SInputWrapper>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
              over
            </Typography>
            <SInput
              type='number'
              placeholder='00'
              width='60px'
              value={data.amortized_months}
              onChange={(e) => onAmortizedFieldChange(data.id, 'amortized_months', e.target.value)}
            />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
              settlements, deducted on each settlement.
            </Typography>
          </>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
            Amortized ${data.amortized_total} over {data.amortized_months} settlements, deducted on each settlement.
          </Typography>
        ))}
      {!!data.month_week_settlement &&
        ((data.edit || data.isNew) && !data.is_balance_deduction ? (
          <SRecurringWrapper>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
              Every
            </Typography>
            <SAutocomplete
              width={data.month_week === 'Month' ? '68px' : '114px'}
              value={data.day || null}
              onChange={(e, value) => onDayChange(data.id, value)}
              options={data.month_week === 'Month' ? monthDays : weekDays}
              className='week-days'
            />
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
              per
            </Typography>
            <SAutocomplete
              width='88px'
              value={data.month_week || null}
              onChange={(e, value) => onMonthWeekChange(data.id, value)}
              options={monthWeekOptions}
            />
          </SRecurringWrapper>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
            Every <Chip label={data.day} fontSize='12px' fontWeight='500' /> per {data.month_week}
          </Typography>
        ))}
      {!!data.one_time_deduction && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          One Time Deduction
        </Typography>
      )}
      {data.isNew && !data.is_balance_deduction && <GearIcon onClick={() => setOpen(true)} />}
      {open && (
        <RecurringModal
          open={open}
          onClose={() => setOpen(false)}
          data={data}
          handleSubmit={(updatedData) => {
            handleSubmit(data.id, updatedData);
            setOpen(false);
          }}
        />
      )}
    </SFlexWrapper>
  );
};

export default Recurring;
