import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { AddTab, DeleteTab, UpdateTab } from 'Api/Planner';
import { palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { ALL_TAB_FILTER_ID } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import styles from 'components/PlanerModalContent/PlanerModalContent.module.css';
import styles1 from './ShipmentModalContenet.module.css';

const EQUIPMENT_TYPES = {
  VEHICLE: 'vehicle',
  TRAILER: 'trailers',
  GROUP: 'groups',
  STATUS: 'status',
};

const ShipmentModalContent = ({
  filterByGroupsData,
  filterByEquipmentTypeData,
  onClickButtons,
  initialValue,
  filter,
  updateFilter,
  customers,
  stopPointsData,
  typeofDataJson,
}) => {
  const { use } = useTheme();

  const [customer, setCustomer] = useState(false);
  const [stopPoint, setStopPoint] = useState(false);
  const [mySwitch, setMySwitch] = useState(false);
  const [isEdi, setIsEdi] = useState(false);
  const [isDryRun, setIsDryRun] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [selectDataCustomers, setSelectDataCustomers] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [stopPointSelected, setStopPointSelected] = useState({});
  const departmentName =
    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.department?.department_name;
  const [multiSelectGroups, setMultiSelectGroups] = useState([]);

  const getShipmentType = (options) => {
    const data = [];

    function recursive(submenu) {
      submenu.forEach((item) => {
        if (
          item?.typeData &&
          (item?.typeData === EQUIPMENT_TYPES.VEHICLE || item.typeData === EQUIPMENT_TYPES.TRAILER)
        ) {
          data.push(item);
        } else {
          item?.submenu && recursive(item.submenu);
        }
      });
    }

    recursive(options);
    return data;
  };

  const getGroups = (options) => {
    const data = [];

    function recursive(submenu) {
      submenu.forEach((item) => {
        if (item?.typeData && item?.typeData === EQUIPMENT_TYPES.GROUP) {
          data.push({ ...item, label: item?.title, key: item.id });
        } else {
          item?.submenu && recursive(item.submenu);
        }
      });
    }

    recursive(options);
    return data;
  };

  const addTabModal = (dataJson) => {
    AddTab(dataJson).then((res) => {
      if (res && res.data) {
        onClickButtons(false, res.data, { type: 'add', data: res.data });
      }
    });
  };

  const updateTabModal = (dataJson) => {
    UpdateTab({ id: initialValue.id, dataJson }).then((res) => {
      if (res && res.data) {
        onClickButtons(false, res.data, { type: 'update', data: res?.data });
      }
    });
  };

  function onChangeMultiselectGroups(value) {
    const { id } = value;
    if (multiSelectGroups.includes(id)) {
      const newGroups = multiSelectGroups.filter((el) => el !== id);
      setMultiSelectGroups(newGroups);
    } else {
      setMultiSelectGroups([...multiSelectGroups, id]);
    }
  }

  function onChangeMultiselectCustomers(values) {
    setSelectDataCustomers(values);
  }

  function onChangeStopPont(value) {
    setStopPointSelected(value);
  }

  function onChangeShipmentTypesItems(id) {
    if (equipmentTypes.includes(id)) {
      const filtered = equipmentTypes.filter((el) => +el !== +id);
      setEquipmentTypes([...filtered]);
    } else {
      setEquipmentTypes([...equipmentTypes, id]);
    }
  }

  function onChangeTick(data) {
    const applyAllUsersModal = { ...filter.applyAllUsersModal, value: String(data) };
    updateFilter({ ...filter, applyAllUsersModal });
  }

  function onDelete() {
    DeleteTab(initialValue.id).then((res) => {
      if (res && res?.data) {
        onClickButtons(
          false,
          {
            key: 'all',
            label: 'All',
            value: 1,
            id: ALL_TAB_FILTER_ID,
            count: 0,
          },
          { type: 'delete', data: initialValue.id }
        );
      }
    });
  }

  function onSubmit(values) {
    const { tab_name, below_margin, above_margin } = values;
    const dataJson = {
      type: typeofDataJson,
      forAll: filter.applyAllUsersModal.value,
      data: {
        name: tab_name,
        filters: {
          status: statuses,
          equipment_types: mySwitch ? equipmentTypes : [],
          groups: multiSelectGroups,
          customers: customer ? (selectDataCustomers || []).map((i) => i.id) : [],
          stop_point: stopPoint ? stopPointSelected : {},
          is_edi: isEdi,
          is_dry_run: isDryRun,
          above_margin,
          below_margin,
        },
      },
    };
    !initialValue?.name ? addTabModal(dataJson) : updateTabModal(dataJson);
  }

  const style = useMemo(() => {
    return {
      labelNameInput: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      styleNameInput: {
        width: 414,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
    };
  }, [palette, use]);

  const validateRequired = (value) => {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  };

  useEffect(() => {
    const {
      name,
      status,
      groups,
      equipment_types,
      customers: initialCustomers,
      stop_point,
      is_edi,
      is_dry_run,
    } = initialValue || {};
    if (name) {
      initialCustomers?.length > 0 && setCustomer(true);
      groups?.length > 0 && setMySwitch(true);
      !!is_edi && setIsEdi(true);
      !!is_dry_run && setIsDryRun(true);
      typeof stop_point === 'object' && Object.keys(stop_point)?.length > 0 && setStopPoint(true);
      status && setStatuses(status);
      setSelectDataCustomers((initialCustomers || []).map((id) => customers.find((i) => i.id === id)));
      setEquipmentTypes(equipment_types);
      setMultiSelectGroups(groups);
      setStopPointSelected(stop_point);
      setMySwitch(!!equipment_types?.length || false);
    }
  }, [initialValue]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        tab_name: initialValue.name ? initialValue.name : '',
        above_margin: initialValue.above_margin ? initialValue.above_margin : '',
        below_margin: initialValue.below_margin ? initialValue.below_margin : '',
      }}
    >
      {({ submitForm }) => (
        <Form>
          <div className={styles.container}>
            <div className={styles.filed_error_wrapper}>
              <Field
                required
                name='tab_name'
                label='Tab name'
                component={CustomInput}
                validate={validateRequired}
                style={style.styleNameInput}
                labelStyle={style.labelNameInput}
              />
              <ErrorMessage
                name='tab_name'
                render={(error) => (
                  <Typography variant='c2' style={style.error}>
                    {error}
                  </Typography>
                )}
              />
            </div>

            <div className={styles.select_wrapper}>
              <CustomCheckbox checked={customer} onChange={(value) => setCustomer(value)}>
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Customer
                </Typography>
              </CustomCheckbox>
              <div className={styles1.customer_select_wrapper}>
                <Autocomplete
                  multiple
                  name='customer'
                  labelKey='label'
                  options={customers || []}
                  limitTags={2}
                  value={selectDataCustomers}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, values) => onChangeMultiselectCustomers(values)}
                  disabled={!customer}
                />
              </div>

              <div className={styles.tab_stopPoint}>
                <CustomCheckbox checked={stopPoint} onChange={(value) => setStopPoint(value)}>
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Stop Point
                  </Typography>
                </CustomCheckbox>

                <CustomSelect
                  isSearchable
                  disabled={!stopPoint}
                  options={stopPointsData}
                  onChange={onChangeStopPont}
                  styles={{ height: 32, marginTop: 12 }}
                  defaultActiveValue={stopPointSelected}
                  dropDownStyles={{ flexGrow: 0, minWidth: '100%' }}
                  isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 12 }}
                />
              </div>

              <div
                className={`
                                ${styles.switch_wrap}
                                ${styles[use('light', 'dark')]}
                                `}
              >
                <Form.Check
                  type='switch'
                  id='custom-switch'
                  label='Equipment Type'
                  checked={mySwitch}
                  onChange={() => setMySwitch((prev) => !prev)}
                />
              </div>

              {/* // Shipment Type */}
              {mySwitch && (
                <div className={styles.equipment_mode_wrapper}>
                  <div className={styles.equipment_childes}>
                    {getShipmentType(filterByEquipmentTypeData)?.map((item) => {
                      return (
                        <div key={item.id} className={styles.equipment_child}>
                          <CustomCheckbox
                            checked={equipmentTypes.includes(item.id)}
                            onChange={() => onChangeShipmentTypesItems(item.id)}
                          >
                            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                              {item.title}
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* // groups */}
              <div style={{ marginTop: 32 }}>
                <InputLabel className='ms-0'>If has Tag</InputLabel>
                <CustomSelect
                  multi
                  isSearchable
                  styles={{ height: 32 }}
                  labelStyle={{ marginBottom: 3 }}
                  dropDownStyles={{ flexGrow: 0 }}
                  valueMultiArr={multiSelectGroups}
                  onChange={onChangeMultiselectGroups}
                  options={getGroups(filterByGroupsData)}
                  isSearchableStyles={{ width: '100%', borderRadius: 6 }}
                />
              </div>

              <div
                className={`
                                ${styles.switch_wrap}
                                ${styles[use('light', 'dark')]}
                                `}
              >
                <Form.Check
                  type='switch'
                  id='custom-switch-cloud'
                  label='Is EDI/API'
                  checked={isEdi}
                  onChange={() => setIsEdi((prev) => !prev)}
                />
              </div>

              <div
                className={`
              ${styles.switch_wrap}
              ${styles[use('light', 'dark')]}
              `}
              >
                <Form.Check
                  type='switch'
                  label='Is Dry Run'
                  checked={isDryRun}
                  onChange={() => setIsDryRun((prev) => !prev)}
                />
              </div>

              <div className={styles.filed_error_wrapper} style={{ marginTop: 32 }}>
                <Field
                  name='below_margin'
                  label='If Margin is below %'
                  component={CustomInput}
                  style={style.styleNameInput}
                  labelStyle={style.labelNameInput}
                />
              </div>

              <div className={styles.filed_error_wrapper} style={{ marginTop: 32 }}>
                <Field
                  name='above_margin'
                  label='If Margin is above %'
                  component={CustomInput}
                  style={style.styleNameInput}
                  labelStyle={style.labelNameInput}
                />
              </div>
            </div>
          </div>
          <div className={styles.footer} style={{ background: use(palette.white, palette.dark800) }}>
            {departmentName === 'Management' && !initialValue?.name ? (
              <CustomCheckbox
                checked={Number(filter?.applyAllUsersModal.value)}
                onChange={() => onChangeTick(Number(filter?.applyAllUsersModal.value) ? 0 : 1)}
              >
                <div className='footer_title_checkbox'>
                  <span>Apply to all users</span>
                </div>
              </CustomCheckbox>
            ) : initialValue.name ? (
              <CustomButton
                title='Remove'
                type='danger'
                styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                onClick={onDelete}
              />
            ) : (
              <div />
            )}

            <div className={styles.footer_right}>
              <CustomButton
                title='Cancel'
                type='secondary'
                onClick={() => onClickButtons(false, null, { type: 'cancel', data: null })}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
              />
              <CustomButton
                title={initialValue.name ? 'Update Tab' : 'Add Tab'}
                type='primary'
                onClick={submitForm}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShipmentModalContent;
