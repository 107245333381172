import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import useDateFormat from 'hooks/useDateFormat';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import { numberFormatter } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import { SStatBoxWrapper } from '../../StatsSection.styles';

const StatBox = ({ title, data, range, onRangeChange, loading, onTitleClick = () => null }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const { amountDiff, milesAmountDiff, perMileDiff, current_year } = data || {};

  return (
    <SStatBoxWrapper>
      <div className='d-flex align-items-center justify-content-between mb-2 title-wrapper'>
        <span
          className={`d-inline-flex gap-1 ${title === 'closed receivables' && 'pointer'}`}
          onClick={() => (title === 'closed receivables' ? onTitleClick('paid_invoices') : null)}
        >
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '150px', height: '18px' }} />
          ) : (
            <Typography variant='c3' style={{ color: palette.gray700 }}>
              {title.toUpperCase()}
            </Typography>
          )}
          {title === 'closed receivables' && <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)' }} />}
        </span>
        <DateRangePicker
          width='230px'
          size='small'
          initialRangeName={`${formatDate(range.start)} - ${formatDate(range.end)}`}
          dateRange={range}
          setDateRange={onRangeChange}
          type='pastDates'
          labelType='showDates'
        />
      </div>
      <div>
        {loading ? (
          <div className='d-flex align-items-center gap-2'>
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
            {title === 'gross transport revenue' && (
              <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
            )}
          </div>
        ) : (
          <div className='d-flex align-items-center gap-3'>
            <div className='d-flex align-items-baseline gap-1'>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                {title !== 'miles' && `${currency}`}
                {formatNumber(
                  title === 'gross transport revenue' ? current_year?.per_mile : current_year,
                  title === 'miles' ? 0 : 2
                )}
              </Typography>
              {title === 'gross transport revenue' && (
                <Typography variant='c2' style={{ color: palette.gray500 }}>
                  per Mile
                </Typography>
              )}
            </div>
            {title === 'gross transport revenue' && (
              <div className='d-flex align-items-baseline gap-1'>
                <Typography variant='h5' style={{ color: palette.gray900 }}>
                  {currency}
                  {numberFormatter(current_year?.amount, 2)}
                </Typography>
                {title === 'gross transport revenue' && (
                  <Typography variant='c2' style={{ color: palette.gray500 }}>
                    {range.start && range.end ? `${formatDate(range.start)} - ${formatDate(range.end)}` : 'All Time'}
                  </Typography>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className='d-flex mt-3 gap-1'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '150px', height: '18px' }} />
        ) : (
          <>
            {title === 'miles' && milesAmountDiff !== 0 && (
              <>
                <ArrowUp
                  fill={milesAmountDiff < 0 ? palette.red500 : palette.green500}
                  style={{ transform: milesAmountDiff < 0 ? 'rotate(180deg)' : '' }}
                />
                <Typography
                  variant='c2'
                  style={{
                    color:
                      milesAmountDiff === 0 ? palette.gray700 : milesAmountDiff < 0 ? palette.red500 : palette.green500,
                  }}
                >
                  {formatNumber(Math.abs(Math.ceil(milesAmountDiff)), 0)} miles since last month
                </Typography>
              </>
            )}
            {title === 'gross transport revenue' && perMileDiff !== 0 && (
              <>
                <ArrowUp
                  fill={perMileDiff < 0 ? palette.red500 : palette.green500}
                  style={{ transform: perMileDiff < 0 ? 'rotate(180deg)' : '' }}
                />
                <Typography
                  variant='c2'
                  style={{
                    color: perMileDiff === 0 ? palette.gray700 : perMileDiff < 0 ? palette.red500 : palette.green500,
                  }}
                >
                  {formatNumber(Math.abs(perMileDiff))} per mile since last month
                </Typography>
              </>
            )}
            {title === 'closed receivables' && amountDiff !== 0 && (
              <>
                <ArrowUp
                  fill={amountDiff < 0 ? palette.red500 : palette.green500}
                  style={{ transform: amountDiff < 0 ? 'rotate(180deg)' : '' }}
                />
                <Typography
                  variant='c2'
                  style={{
                    color: amountDiff === 0 ? palette.gray700 : amountDiff < 0 ? palette.red500 : palette.green500,
                  }}
                >
                  {formatNumber(Math.abs(amountDiff))}
                  {amountDiff < 0 ? ' less ' : ' more '} since last month
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    </SStatBoxWrapper>
  );
};

export default StatBox;
