import { array, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = array().of(
  object().shape({
    payment_date: validator.requiredDate(),
    pay_reference: string().trim().required('Required').nullable(),
    payment_method: object()
      .shape({ value: string().required('Required') })
      .required('Required')
      .nullable(),
    account: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    discount: number()
      .test('maxDiscount', 'Discount cannot be greater than open balance', function (discount) {
        const { open_balance } = this.parent;
        return !discount || discount <= open_balance;
      })
      .nullable(),
    amount_paid: number().required('Required').nullable(),
    credit_used: number().test('credit_used', 'Invalid', (val, context) => {
      const openBalance = context.parent.open_balance - (context.parent.discount || 0);

      if (context.parent.apply_credit && (val > openBalance || val > context.parent.credits)) {
        return context.createError({
          message: context.parent.credits > openBalance ? `Max ${openBalance}` : `Max ${context.parent.credits}`,
        });
      }
      return true;
    }),
  })
);
