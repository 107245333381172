import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 16px;

  .add-action {
    display: inline-flex;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
    margin-bottom: 8px;
  }

  .first-line {
    display: flex;
    column-gap: 32px;
    width: 70%;
    min-width: 600px;

    & > div {
      flex-grow: 1;
    }
  }
`;

export const SFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const SFormRow = styled.div`
  display: flex;
  column-gap: 16px;

  .icon-wrapper {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;

    .delete-icon {
      display: inline-block;
      padding: 3px;
      cursor: pointer;
    }

    svg {
      display: block;
    }

    .empty-icon {
      width: 16px;
    }
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const STotalSection = styled.div`
  margin-top: -16px;
  margin-right: 30px;

  .total-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 46px;
    padding: 12px 0;
    border-bottom: 1px solid ${palette.gray50};
    :first-child {
      padding-top: 0;
    }
  }
`;

export const SAttachmentWrapper = styled.div`
  margin-top: 24px;

  .upload-document-deposit {
    width: 100%;
    position: relative;
    border: 1px dashed ${palette.gray200};
    background: ${palette.white};
    color: ${palette.gray900};
  }

  .upload-document-disabled {
    background: ${palette.gray0};
  }
`;

export const SOption = styled.li`
  &&&.MuiAutocomplete-option {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    padding: 4px 8px;
  }
`;

export const SAppliedPaymentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${palette.white};
  border: 1px solid ${palette.gray50};

  .payments-header {
    padding: 16px 20px 0;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
`;
