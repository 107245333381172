import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import sm from './ImageDropZone.module.css';

const ImageDropZone = ({
  img,
  onDrop,
  width,
  height,
  multiple,
  accept,
  deletable,
  name,
  onDelete,
  className,
  customStyle = {},
  id,
  deletedComponent,
  children,
  disabled,
}) => {
  const { use } = useTheme();
  return (
    <div className={classNames(sm.container, className)} style={{ ...customStyle }}>
      <Dropzone
        onDrop={(props) => onDrop(props, name)}
        multiple={multiple || false}
        accept={accept || ['image/jpeg', 'image/png', 'image/svg+xml']}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ width, height }}>
            <input {...getInputProps()} id={id} />
            {img && <img src={img} alt='img' width={width} height={height} style={{ width, height }} />}
            {!!children && !!deletedComponent && (
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                {!!children && children}
                {!!deletedComponent && deletedComponent}
              </div>
            )}
          </div>
        )}
      </Dropzone>
      {deletable && !deletedComponent && (
        <div
          className={sm.deleteBtn_wrapper}
          style={{ backgroundColor: use(palette.white, palette.red800) }}
          onClick={() => onDelete(name)}
        >
          <DeleteIcon />
        </div>
      )}
    </div>
  );
};

export default ImageDropZone;
