import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import uuid from 'react-uuid';
import { palette } from 'utils/constants';
import { ReactComponent as PlusIcon, ReactComponent as PLusIcon } from 'assets/icons/plus.svg';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { getInitialStopData } from 'components/CreateShipment/helpers';
import sm from '../../ShipmentStops.module.css';
import { getLegTemplate, getStopTemplate, getStopTemplateLTL } from '../constants';

import CustomButton from '../../../../CustomButton/CustomButton';
import CustomRadioButton from '../../../../CustomRadioButton';

const PopoverCreatShipment = ({
  arrayHelpers,
  values,
  show,
  setShow,
  type,
  legLength,
  optimizeRoute,
  setOptimizeRoute,
  shipmentSettings,
}) => {
  const { use } = useTheme();
  const popoverData = useMemo(() => {
    return [
      { title: 'Pick Up', id: 1 },
      { title: 'Delivery', id: 2 },
      { title: 'Waypoint', id: 3 },
    ];
  }, []);

  const addStop = (id) => {
    setShow(false);
    !optimizeRoute && setOptimizeRoute(false);
    const stopType = id.toString();
    const number = 1 + Number(values?.legs?.length);
    switch (type) {
      case 'leg':
        arrayHelpers.push({
          number,
          stops: shipmentSettings ? [getInitialStopData(shipmentSettings, stopType)] : getLegTemplate(stopType),
        });
        break;
      case 'stop':
        arrayHelpers.insert(
          1,
          shipmentSettings ? getInitialStopData(shipmentSettings, stopType) : getStopTemplate(stopType)
        );
        break;
      default:
    }
  };

  const popover = (
    <Popover
      id='popover-basic'
      className={sm.popoverCreatShipment}
      style={{
        zIndex: 2000,
        backgroundColor: use(palette.white, palette.dark800),
      }}
    >
      <Popover.Body>
        <div className={sm.popover_wrapper}>
          {popoverData.map((item) => {
            const { id, title } = item;
            return (
              <Typography
                key={id}
                variant='s2'
                style={{ marginBottom: 5, cursor: 'pointer' }}
                onClick={() => addStop(item.id)}
              >
                {title}
              </Typography>
            );
          })}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={sm.add_long_button_wrapper}>
      <div
        className={sm.add_long_button}
        style={{
          backgroundColor: use(palette.gray0, palette.dark800),
        }}
      >
        {legLength > 1 && (
          <div className={sm.legNumber_wrapper} style={{ backgroundColor: use(palette.indigo50, palette.indigo200) }}>
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              {1 + Number(values?.legs?.length)}
            </Typography>
          </div>
        )}
        <OverlayTrigger trigger='click' placement='bottom' overlay={popover} rootClose onToggle={setShow} show={show}>
          <div
            onClick={() => setShow(true)}
            style={{
              width: 24,
              height: 24,
              borderRadius: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: palette.indigo500,
            }}
          >
            <PLusIcon fill={palette.white} />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default PopoverCreatShipment;

export const PopoverCreatShipmentItem = ({
  arrayHelpersPrefix,
  indexPrefix,
  arrayHelpers,
  stops,
  stop,
  show,
  setShow,
  index,
  deletePopover,
  iconComponent,
  legs,
  onClickOutSide,
  isLTL = false,
  shipmentSettings,
}) => {
  const { use } = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(stop?.stop_point_type);
  const type = stops?.[index]?.stop_point_type;

  const popoverData = useMemo(() => {
    return [
      { title: 'Pick Up', id: 1, value: 'pickUp' },
      { title: 'Delivery', id: 2, value: 'delivery' },
      { title: 'Waypoint', id: 3, value: 'waypoint' },
    ];
  }, []);

  const validationDelete = (type) => {
    let deleted;
    const stopsObj = {
      pickUp: 0,
      delivery: 0,
      waypoint: 0,
    };
    legs?.forEach((leg) => {
      leg?.stops.forEach((stop) => {
        switch (Number(stop?.stop_point_type)) {
          case 1:
            stopsObj.pickUp += 1;
            break;
          case 2:
            stopsObj.delivery += 1;
            break;
          case 3:
            stopsObj.waypoint += 1;
            break;
          default:
        }
      });
    });

    if (type === 1) {
      stopsObj.pickUp > 1 ? (deleted = true) : (deleted = false);
    } else if (type === 2) {
      stopsObj.delivery > 1 ? (deleted = true) : (deleted = false);
    } else if (type === 3) {
      deleted = true;
    }

    return deleted;
  };

  const onChangeHandler = (e, id) => {
    const canIMove = validationDelete(type);
    switch (e.target.value) {
      case 'pickUp':
        canIMove && setDefaultChecked(1);
        break;
      case 'delivery':
        canIMove && setDefaultChecked(2);
        break;
      case 'waypoint':
        canIMove && setDefaultChecked(3);
        break;
      default:
    }
    if (canIMove) {
      const newStop = shipmentSettings
        ? getInitialStopData(shipmentSettings, id)
        : isLTL
        ? getStopTemplateLTL(id)
        : getStopTemplate(id);
      arrayHelpers.replace(index, newStop);
      setShowPopover(false);
    }
  };

  const deletePopoverHandler = () => {
    const canIMove = validationDelete(type);
    if (stops?.length === 1) {
      canIMove && arrayHelpersPrefix.remove(indexPrefix);
    } else {
      canIMove && arrayHelpers.remove(index);
    }
    setShowPopover(false);
  };

  const selectPlusItem = (id) => {
    if (isLTL) {
      arrayHelpers.insert(
        index + 1,
        shipmentSettings ? getInitialStopData(shipmentSettings, id) : getStopTemplateLTL(id)
      );
    } else {
      arrayHelpers.insert(index + 1, shipmentSettings ? getInitialStopData(shipmentSettings, id) : getStopTemplate(id));
    }
    setShow(false);
  };

  const popover = (
    <Popover
      id='popover-basic'
      className={sm.popoverCreatShipment}
      style={{
        zIndex: 2000,
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray100, palette.gray700),
      }}
    >
      <Popover.Body>
        <div className={sm.popover_wrapper}>
          {popoverData.map((item) => {
            const { id, title } = item;
            return (
              <Typography
                key={uuid()}
                variant='s2'
                style={{ marginBottom: 5, cursor: 'pointer' }}
                onClick={() => selectPlusItem(id)}
              >
                {title}
              </Typography>
            );
          })}
        </div>
      </Popover.Body>
    </Popover>
  );

  const popoverDelete = (
    <Popover
      id='popover-basic'
      className={sm.popoverCreatShipment}
      style={{
        zIndex: 2000,
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray100, palette.gray700),
      }}
    >
      <Popover.Body>
        <div className={sm.popover_wrapper}>
          {popoverData.map((item) => {
            const { id, title, value } = item;
            return (
              <CustomRadioButton
                key={id}
                field={{
                  name: 'typesDeletePopover',
                  value,
                  onChange: (e) => onChangeHandler(e, id),
                  checked: defaultChecked === id,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  {title}
                </Typography>
              </CustomRadioButton>
            );
          })}
          <div className={sm['delete-button-popover']}>
            <div className={sm.line} />
            <CustomButton
              type='danger'
              title='Delete Stop'
              styleButton={{ padding: '2px 8px', margin: 0 }}
              onClick={deletePopoverHandler}
            />
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return deletePopover ? (
    <OverlayTrigger
      show={showPopover}
      onToggle={setShowPopover}
      overlay={popoverDelete}
      trigger='click'
      placement='bottom'
      rootClose
    >
      {!!iconComponent && iconComponent}
    </OverlayTrigger>
  ) : (
    <div className={sm.add_long_button_item_wrapper}>
      <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={popover}
        rootClose
        onToggle={(v) => {
          if (!!onClickOutSide && typeof onClickOutSide === 'function') {
            onClickOutSide();
          } else {
            setShow(v);
          }
        }}
        show={!onClickOutSide && show}
      >
        <div className={sm.pickUp_plus}>
          <PlusIcon />
        </div>
      </OverlayTrigger>
    </div>
  );
};
