import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Benefits } from 'pages/CompanySettings/pagesComponents';

const BenefitsSettings = ({ open, onClose }) => {
  return (
    <Modal
      showModal={!!open}
      onHide={onClose}
      $width='900px'
      $bgColor={palette.gray0}
      headerTitle='Benefit Types'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done', className: 'modal-cancel-btn' }]}
    >
      <Benefits isModalView />
    </Modal>
  );
};

export default BenefitsSettings;
