import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import plus from 'assets/icons/drivers/plus.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import { DatePicker } from 'common/Pickers';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { uploadDriverDocument } from 'Api/Staff';
import useShowToaster from 'hooks/useShowToaster';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import ImageDropZone from '../ImgageDropZone';
import { addDocValidationSchema } from '../Drivers/AddDriver/ValidationSchema';

export default function MyDocumentModal({ AddUpdateDocuments, loading, id, ...props }) {
  const { use } = useTheme();
  const showToaster = useShowToaster();

  const addSubmitDocuments = (values) => {
    let isSubmitted = true;
    values.addDocument.forEach((item) => {
      if (item.name && !item.certificate_path) {
        showToaster({ type: 'error', message: 'Document is required!' });
        isSubmitted = false;
      }
    });
    if (isSubmitted) {
      AddUpdateDocuments(values, 'add', props.onHide, 'Documents has been successfully created');
    }
  };

  const formik = useFormik({
    initialValues: {
      addDocument: [
        {
          name: '',
          certification_exp_date: null,
          certificate_path: '',
          driver_id: id,
          no_expiration: false,
        },
      ],
    },
    onSubmit: addSubmitDocuments,
    validationSchema: addDocValidationSchema,
  });

  const uploadDocument = (file, propertyName, index) => {
    uploadDriverDocument(file[0], id).then((res) => {
      formik.setFieldValue(`addDocument[${index}].${'certificate_path'}`, res.data.document_path);
    });
  };

  const remove = (index) => {
    const temp = formik.values.addDocument;
    temp.splice(index, 1);
    formik.setFieldValue('addDocument', temp);
  };

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' dialogClassName='modal-doc-80w' centered>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Add Document&#40;s&#41;
          </h3>
          <p className='subline' style={{ color: use(palette.gray900, palette.gray50) }}>
            You can Drag & Drop multiple documents.
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='driver-style-wrap'
        style={{ backgroundColor: use(palette.whiteBackground, palette.dark800) }}
      >
        {formik?.values?.addDocument?.map((docs, index) => {
          return (
            <div key={docs.certificate_path}>
              <div className='add-document-header'>
                <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                  Document Name
                </p>
                <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                  Expiry
                </p>
              </div>
              <div className='mb-4'>
                <div className='d-flex  expiry-container mb-0'>
                  <div className='expiry-input' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                    <input
                      type='text'
                      placeholder='Document Name'
                      style={{ backgroundColor: use(palette.white, palette.dark800) }}
                      id={`addDocument[${index}].name`}
                      name={`addDocument[${index}].name`}
                      onBlur={formik.handleBlur}
                      value={formik.values.addDocument[index].name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='mt-2'>
                    <DatePicker
                      width='170px'
                      name={`addDocument[${index}].certification_exp_date`}
                      value={
                        formik?.values?.addDocument?.[index]?.no_expiration
                          ? null
                          : formik?.values?.addDocument?.[index]?.certification_exp_date
                      }
                      borderRadius='0 6px 6px 0'
                      onChange={(date) => {
                        formik.setFieldValue(`addDocument[${index}].certification_exp_date`, date);
                      }}
                      onBlur={formik.handleBlur}
                      disabled={!!formik?.values?.addDocument?.[index]?.no_expiration}
                      error={
                        formik.touched?.addDocument?.[index]?.certification_exp_date &&
                        formik.errors?.addDocument?.[index]?.certification_exp_date
                      }
                    />
                  </div>
                  {docs?.certificate_path ? (
                    <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => formik.setFieldValue(`addDocument[${index}].certificate_path`, null)}
                      />
                    </p>
                  ) : (
                    <div className='ms-2 mt-2 attach-file' style={{ position: 'relative' }}>
                      <label htmlFor='myFile' className='text-style upload'>
                        <span className='upload-icon'>
                          <img src={plus} alt='plus' />
                        </span>
                        <span className='text-style choose-file '>Choose File</span>
                      </label>
                      <ImageDropZone
                        onDrop={(file) => uploadDocument(file, 'attachment', index)}
                        width='100%'
                        height='100%'
                        name='attachment'
                        id='attachment'
                        deletable={false}
                        onBlur={formik.handleBlur}
                        customStyle={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                        }}
                        accept={['application/pdf']}
                      />
                    </div>
                  )}
                  {index > 0 ? (
                    <div className='cross-container-document' onClick={() => remove(index)}>
                      <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M1.79114 0.721559C1.49577 0.426193 1.01689 0.426193 0.721524 0.721559C0.426158 1.01693 0.426159 1.49581 0.721524 1.79117L3.93037 5.00002L0.721555 8.20883C0.426189 8.50419 0.426189 8.98308 0.721555 9.27844C1.01692 9.57381 1.4958 9.57381 1.79117 9.27844L4.99998 6.06963L8.20883 9.27848C8.50419 9.57384 8.98308 9.57384 9.27844 9.27848C9.57381 8.98311 9.57381 8.50423 9.27844 8.20886L6.06959 5.00002L9.27847 1.79114C9.57384 1.49577 9.57384 1.01689 9.27847 0.721524C8.9831 0.426158 8.50422 0.426159 8.20886 0.721524L4.99998 3.9304L1.79114 0.721559Z'
                          fill='#ff0000'
                        />
                      </svg>
                    </div>
                  ) : (
                    <span />
                  )}
                </div>
                <div style={{ marginTop: '4px', marginLeft: '250px' }}>
                  <CustomCheckbox
                    name={`addDocument[${index}].no_expiration`}
                    checked={formik?.values?.addDocument[index]?.no_expiration}
                    onChange={(checked) => {
                      formik.setFieldValue(`addDocument[${index}].no_expiration`, checked);
                    }}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      No Expiration
                    </Typography>
                  </CustomCheckbox>
                </div>
              </div>
            </div>
          );
        })}
        <div
          className='add-document-link'
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray200, palette.boxShadow),
          }}
        >
          <div className='ms-2 '>
            <label
              className='text-style upload'
              onClick={() =>
                formik.setFieldValue('addDocument', [
                  ...(formik.values.addDocument || []),
                  {
                    name: '',
                    certification_exp_date: null,
                    certificate_path: '',
                    no_expiration: false,
                    driver_id: id,
                  },
                ])
              }
            >
              <span className='upload-icon'>
                <img src={plus} alt='plus' />
              </span>
              <span className='text-style '>Add Document</span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn-container'>
          <Button className='cancel-button' onClick={props.onHide}>
            Cancel
          </Button>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              style={{ opacity: formik.isValid ? 1 : 0.7 }}
              disabled={!formik.isValid}
              className='next-step'
              type='submit'
              onClick={formik.handleSubmit}
            >
              Add Document&#40;s&#41;
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
