import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const SSectionWrapper = styled.div`
  width: ${({ $isPaid }) => ($isPaid ? '100%' : '50%')};
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    box-shadow: inset -5px 0px 20px 7px #f3f3f3;
  }

  &:last-child {
    background-color: ${palette.gray0};
  }

  @media (max-width: 1000px) {
    width: 100%;
    min-height: ${({ $isPaid }) => ($isPaid ? '100vh' : '50vh')};
    padding: 24px 12px;
  }
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;
