import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/root.reducer';
import stripeReducer from './reducers/stripe.reducer';
import plannerReducer from './reducers/planner.reducer';
import billingReducer from './reducers/billing.reducer';
import shipmentsReducer from './reducers/shipments.reducer';
import stopPointReducer from './reducers/stopPoint.reducer';
import onboardingReducer from './reducers/onboarding.reducer';
import headerReducer from './reducers/header.reducer';

export default configureStore({
  reducer: {
    root: rootReducer,
    planner: plannerReducer,
    shipments: shipmentsReducer,
    stopPoints: stopPointReducer,
    billing: billingReducer,
    stripe: stripeReducer,
    onboarding: onboardingReducer,
    header: headerReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
