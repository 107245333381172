import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .header-item:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 160px;
  }

  .header-item:nth-child(2) {
    width: 150px;
  }

  .header-item:nth-child(3) {
    width: 80px;
  }

  .header-item:nth-child(4) {
    width: 80px;
  }

  .header-item:nth-child(5) {
    width: 50px;
  }

  .header-item:nth-child(6) {
    width: 40px;
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;

export const SWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid ${palette.gray50};
  background: #f7f9fc;

  .flex-item:nth-child(1) {
    width: 160px;
  }

  .flex-item:nth-child(2) {
    width: 150px;
  }

  .flex-item:nth-child(3) {
    width: 80px;
  }

  .flex-item:nth-child(4) {
    width: 80px;
  }

  .flex-item:nth-child(5) {
    width: 50px;
  }

  .flex-item:nth-child(6) {
    width: 40px;
  }
  
  .actions-wrapper {
    visibility: hidden;
  }

  :hover {
    background: #f7f9fc;

    .actions-wrapper {
      visibility: visible;
    }
`;
