import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { shareEbol } from 'Api/Shipment';
import { validationSchema } from './validationSchema';

const ShareEbol = ({ open, onClose, ebolId }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await shareEbol({ email: values.email, ebol_id: ebolId });
      showToaster({ type: 'success', message: 'Electronic bill of lading shared successfully!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage('Error') });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { email: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Share eBol'
      $bgColor={palette.gray0}
      $maxWidth='400px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'share',
          type: 'primary',
          title: 'Share',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <Input
          required
          label='Email'
          type='email'
          name='email'
          placeholder='Email'
          value={values.email}
          onChange={handleChange}
          error={touchedErrors.email}
        />
      </div>
    </Modal>
  );
};

export default ShareEbol;
