import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Modal } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { palette } from 'utils/constants';
import Pagination from 'common/Pagination';
import { useTheme } from 'context/themeContext';
import useDebounce from 'hooks/useDebounce';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import AddIncidentModal from 'components/AddIncidentModal';
import { getAllIncidents } from 'Api/DotMonitoring';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getMergedUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import Popover from '@mui/material/Popover';
import {
  getAccident,
  getAccidents,
  getAccidentsTabsCount,
  getSearchAutocomplete,
  updateCollision,
} from 'Api/AccidentsAndCrashes';
import { SBackdrop } from 'pages/InvoicePayment/InvoicePayment.styles';
import AddAccidents from 'pages/AccidentsAndCrashes/AddAccidents/AddAccidents';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import SearchAutoFill from 'common/SearchAutoFill';
import Autocomplete from 'common/Autocomplete';
import { initialFilters } from '../TableSection.data';
import classes from './Accidents.module.css';
import Description from './Description';
import CreateOrAssign from './CreateOrAssign';
import SelectExistingIncident from '../../../DotMonitoring/TableSection/SafetyRecords/SelectExistingIncident';
import { useColumns } from './Accidents.data';
import { generatePdf } from './generatePdf';

let controller = null;

const Accidents = ({ refreshStats }) => {
  const { use } = useTheme();
  const location = useLocation();
  const { formatDateTime } = useDateFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const openCreate = searchParams.get('openCreate');
  const showToaster = useShowToaster();
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [violations, setViolations] = useState({ data: [] });
  const [tabs, setTabs] = useState([]);
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 500);
  const [dayFilter, setDayFilter] = useState();
  const [sort, setSort] = useState({ field: '', sortBy: '' });

  const [openCreateIncident, setOpenCreateIncident] = useState(false);
  const [editData, setEditData] = useState(null);
  const [refreshData, setRefreshData] = useState(null);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  // const [selectExistingOpen, setSelectExisting] = useState(false);
  const [faultCode, setFaultCode] = useState(null);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [createOrAssignOpen, setCreateOrAssignOpen] = useState(faultCode);
  const [openAddAccident, setOpenAddAccident] = useState(openCreate || false);
  const [accidentToEdit, setAccidentToEdit] = useState(null);
  const [selectExistingOpen, setSelectExisting] = useState(false);
  const [allIncident, setAllIncident] = useState(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [reportPdfUrl, setReportPdfUrl] = useState('');

  const [loadingPdf, setLoadingPdf] = useState(false);

  const onChangeTab = (tab) => {
    setSearch('');
    setDayFilter({});
    setSelectedFilters({ ...selectedFilters, tab: tab.id });
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  useEffect(() => {
    getIncidents();
    getAutocompleteValues();

    searchParams.delete('openCreate');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    controller && controller.abort();
    controller = new AbortController();

    const params = {
      page: selectedFilters.page,
      itemsPerPage: selectedFilters.itemsPerPage,
      'filters[query]': debouncedSearch || undefined,
      'filters[from]': dayFilter?.from || undefined,
      'filters[to]': dayFilter?.to || undefined,
      'filters[status_id]': selectedFilters?.tab || undefined,
      'filters[type]': selectedFilters?.type !== 'All' ? selectedFilters?.type : undefined,
    };

    setLoading(!sort.field);
    if (sort.field) {
      params[`sort[][${sort.field}]`] = sort.sortBy;
    }

    getAccidents(params, controller.signal)
      .then((res) => {
        setViolations(res);
        setLoading(false);
      })
      .catch(() => {
        // Do nothing
      });
  }, [selectedFilters, dayFilter, debouncedSearch, sort, refreshData]);

  useEffect(() => {
    const params = {
      query: debouncedSearch || undefined,
    };
    getTabsCount(params);
  }, [selectedFilters, debouncedSearch, refreshData]);

  const getTabsCount = async (params) => {
    const { data } = await getAccidentsTabsCount(params);
    // const allTab = {
    //   count: data.all,
    //   label: 'All',
    //   key: 0,
    //   value: 0,
    //   id: 0,
    // };

    const localTabs = [];
    data.forEach((item) => {
      localTabs.push({
        count: item.accident_count,
        label: item.status,
        key: item.status_id,
        value: item.status_id,
        id: item.status_id,
      });
    });
    setTabs(localTabs);
  };

  useEffect(() => {
    if (location?.state?.openCreate) {
      setOpenAddAccident(true);
    }
  }, [location?.state]);

  const createIncident = (row) => {
    setCreateOrAssignOpen(false);
    setSelectedIncident(null);
    setFaultCode(null);
    setEditData(row);
    setOpenCreateIncident(true);
  };

  const selectIncident = (row) => {
    setEditData(row);
    setCreateOrAssignOpen(false);
    setFaultCode(null);
    setSelectExisting(true);
  };

  // const onSelectDate = (dates) => {
  //   const { start, end } = dates;
  //   const startFormat = moment(start).format('YYYY-MM-DD');
  //   const endFormat = moment(end).format('YYYY-MM-DD');
  //   setDayFilter({
  //     from: startFormat,
  //     to: endFormat,
  //   });
  // };

  const getIncidents = async () => {
    try {
      const params = {
        page: 1,
        itemsPerPage: 10000,
      };

      const response = await getAllIncidents(params);
      setAllIncident(response?.data);
    } catch (e) {
      // do something
    } finally {
      // do nothing
    }
  };

  const updateIncident = (body) => {
    updateCollision(editData.id, body)
      .then(() => {
        showToaster({ type: 'success', message: 'Success!' });
        setRefreshData(new Date().getTime());
        setEditData(null);
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const onRowClick = (row) => {
    setAccidentToEdit(row);
    setOpenAddAccident(true);
  };

  const onGeneratePdf = async (accidentId) => {
    try {
      setLoadingPdf(true);
      const { data } = await getAccident(accidentId);
      const { url } = await generatePdf({ accident: data, formatDateTime });
      const docs = [url];

      if (data.police_report_attachment && data.police_report_attachment?.endsWith('.pdf')) {
        docs.push(data.police_report_attachment);
      }

      if (data?.incident?.statement_document && data?.incident?.statement_document?.endsWith('.pdf')) {
        docs.push(data?.incident?.statement_document);
      }

      if (data.shipment_hazmat_disposal_attachment && data.shipment_hazmat_disposal_attachment?.endsWith('.pdf')) {
        docs.push(data.shipment_hazmat_disposal_attachment);
      }

      if (data.alcohol_test_attachment && data.alcohol_test_attachment?.endsWith('.pdf')) {
        docs.push(data.alcohol_test_attachment);
      }

      if (data.drug_test_attachment && data.drug_test_attachment?.endsWith('.pdf')) {
        docs.push(data.drug_test_attachment);
      }

      const { url: mergedUrl } = await getMergedUrl(docs);
      setReportPdfUrl(mergedUrl);
    } catch (e) {
      console.log(e, 'e');
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingPdf(false);
    }
  };

  const getAutocompleteValues = async () => {
    const params = {
      type: ['driver_name', 'incident_id', 'vehicle_id', 'trailer_id'],
    };
    const { data } = await getSearchAutocomplete(params);
    setAutocompleteOptions(data);
  };

  const onAddAccidentClose = () => {
    setOpenAddAccident(false);
    setAccidentToEdit(null);
    setRefreshData(new Date().getTime());
    refreshStats();
  };

  const columns = useColumns({
    sort,
    sortingQuery,
    setFaultCode,
    onGeneratePdf,
    setShowMoreOpen,
    setSelectedIncident,
    setCreateOrAssignOpen,
  });

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-3'>
          <SearchAutoFill search={search} onChange={setSearch} className='search-input' options={autocompleteOptions} />
          <Autocomplete
            width='200px'
            options={['All', 'Reportable', 'Not Reportable']}
            value={selectedFilters.type}
            onChange={(e, val) => setSelectedFilters({ ...selectedFilters, type: val })}
          />
        </div>
        <CustomButton
          type='primary'
          title='Create Report'
          leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
          styleButton={{ padding: '6px 12px', margin: 0, whiteSpace: 'nowrap' }}
          onClick={() => setOpenAddAccident(true)}
        />
      </div>
      <div>
        <SwitchRadio
          type='tab'
          items={tabs}
          onChange={onChangeTab}
          name='tableTopTabMenu'
          value={selectedFilters.tab}
        />
      </div>
      <div>
        {loading ? (
          <EquipmentPreloaderSkeleton />
        ) : (
          <MaterialTableWrapper
            data={violations.data}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: () =>
                Pagination({
                  data: violations,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            columns={columns}
            onRowClick={(e, row) => onRowClick(row)}
          />
        )}
      </div>
      {showMoreOpen && (
        <Description open={showMoreOpen} onClose={() => setShowMoreOpen(false)} description={showMoreOpen} />
      )}
      {selectExistingOpen && (
        <SelectExistingIncident
          open={selectExistingOpen}
          incidents={allIncident}
          onClose={() => {
            setSelectedIncident(null);
            setSelectExisting(false);
            setEditData(null);
          }}
          onSuccess={(data) => {
            const body = {
              incident_id: data.id,
              collision_date_time: editData.collision_date_time,
            };
            updateIncident(body);
            setSelectExisting(false);
          }}
        />
      )}
      {openCreateIncident && (
        <AddIncidentModal
          open={openCreateIncident}
          onClose={() => setOpenCreateIncident(false)}
          onSuccess={(res) => {
            if (res.id) {
              const body = {
                incident_id: res.id,
                driver_id: res.driver_id,
                collision_date_time: editData.collision_date_time,
              };
              updateIncident(body);
            }
          }}
          incident={editData}
          driverId={editData?.driver?.id}
          firstName={editData?.driver?.fname}
          lastName={editData?.driver?.lname}
          dotMonitoring
        />
      )}
      <Popover
        disableScrollLock
        anchorPosition={{ top: 299, left: 669 }}
        open={createOrAssignOpen}
        anchorEl={faultCode}
        onClose={() => {
          setSelectedIncident(null);
          setFaultCode(null);
          setCreateOrAssignOpen(false);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '40px' }}
      >
        <div style={{ width: '200px' }}>
          <CreateOrAssign
            rowData={selectedIncident}
            createIncident={createIncident}
            setSelectExisting={selectIncident}
          />
        </div>
      </Popover>
      <Modal
        className='xeris-modal'
        onCloseModal={onAddAccidentClose}
        backdrop='static'
        centered
        disableEscapeKeyDown
        disableEnforceFocus
        open={openAddAccident}
      >
        <Box className={classes.addModal}>
          <AddAccidents accidentId={accidentToEdit?.id} close={onAddAccidentClose} />
        </Box>
      </Modal>
      {!!reportPdfUrl && (
        <ViewPdf
          open={!!reportPdfUrl}
          title='Accident Report'
          onClose={() => setReportPdfUrl('')}
          pdfUrl={reportPdfUrl}
        />
      )}
      <SBackdrop open={loadingPdf}>
        <CircularProgress size={30} style={{ marginTop: '30px' }} />
        <Typography variant='h1' style={{ color: palette.white }}>
          Processing...
        </Typography>
      </SBackdrop>
    </div>
  );
};

export default Accidents;
