import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';

import { ReactComponent as LocationIcon } from 'assets/icons/locationPDF.svg';
import { ReactComponent as GlobeIcon } from 'assets/icons/globePDF.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailPDF.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phonePDF.svg';
import SignatureCanvas from 'react-signature-canvas';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import { DATE_FORMAT, formatNumber, getAlphabet, palette, TIME_FORMAT } from 'utils/constants';
import Input from 'common/Input';
import ViewPdf from 'components/ViewPdf';
import PhoneInput from 'common/PhoneInput';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import { formatPhoneNumber, getEquipmentRequiredTitle, getStopName } from 'utils/helpers';
import { equipmentTypeText } from 'components/TableShipments/helpers/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import classNames from 'classnames';
import classes from './Confirmation.module.scss';

const Confirmation = ({ company, shipment, form, barcodeImage, setBarcodeImage, signPad }) => {
  const {
    company_name,
    company_email,
    logo,
    address1,
    city,
    state,
    country,
    zipcode,
    terms_and_conditions,
    show_website,
    website,
    customer_timezone,
    phone_number: companyPhone,
    date_time_format_settings,
  } = company || {};
  const {
    shipment_id,
    shipment_stops,
    brokerage_dispatch,
    shipment_notes,
    empty_miles,
    loaded_miles,
    driver_amount,
    carrier_pay,
    dispatched_by_user,
  } = shipment || {};
  const { carrier, contact_user, trailer_length, trailer_type, vehicle_id, vehicle_type } = brokerage_dispatch || {};
  const { phone_number: dispatchedPhone, first_name, last_name } = dispatched_by_user || {};
  const { contact_name, contact_email, phone_number } = contact_user || {};
  const [notes, setNotes] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];
  const timeFormat = TIME_FORMAT[date_time_format_settings?.time_format] || TIME_FORMAT[1];

  const { values, handleChange, touchedErrors } = form;

  const shippersAndConsignees = shipment_stops
    ?.filter((stop) => Number(stop.stop_point_type) === 1 || Number(stop.stop_point_type) === 2)
    .map((el) => {
      if (Number(el.stop_point_type) === 1) {
        return { ...el, type: 'Shipper' };
      }
      return { ...el, type: 'Consignee' };
    });

  const pickupStops = shippersAndConsignees?.filter((stop) => Number(stop.stop_point_type) === 1);

  const total_weight = pickupStops?.reduce((acc, cur) => acc + Number(cur.weight), 0);
  const total_qty = pickupStops?.reduce((acc, cur) => acc + cur.quantity, 0);

  useEffect(() => {
    if (shipment) {
      if (shipment_notes && shipment_notes.length) {
        shipment_notes.forEach((note) => {
          if (note.title === 'Confirmation Note') {
            setNotes(note.notes);
          }
        });
      }
    }
  }, [shipment]);

  const onClear = () => {
    signPad?.current?.clear();
    handleChange('signature', null);
  };

  useEffect(() => {
    if (shipment_id && values.reference_id) {
      const image = getBarcode(values.reference_id);
      setBarcodeImage(image);
    }
  }, [shipment_id, values.reference_id]);

  const getBarcode = (id, displayValue = true) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, id, { displayValue });
    return canvas.toDataURL('image/jpeg');
  };

  return (
    <div className={classes.confirmationWrapper}>
      <div className={classes.confirmationInner}>
        <div className='d-flex flex-column gap-3'>
          <div className={classes.logoWrapper}>
            <img src={logo} alt='' />
          </div>
          <div className='d-flex flex-column'>
            <Typography variant='b3'>{company_name}</Typography>
            <Typography variant='s3' style={{ color: palette.gray900 }}>
              {address1},
            </Typography>
            <Typography variant='s3' style={{ color: palette.gray900 }}>
              {city?.name}, {state?.short_name || state?.name} {zipcode}
            </Typography>
            <Typography variant='s3' style={{ color: palette.gray900 }}>
              {country?.name}
            </Typography>
          </div>
          <div>
            {!!dispatchedPhone && (
              <div className='d-flex align-items-center gap-1'>
                <Typography variant='button2'>Telephone Number:</Typography>
                <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
                  <Typography variant='overLine2'>{formatPhoneNumber(companyPhone)}</Typography>
                </a>
              </div>
            )}
            {(first_name || last_name) && (
              <div className='d-flex gap-1'>
                <Typography variant='button2'>Dispatched By:</Typography>
                <Typography variant='overLine2'>
                  {first_name} {last_name}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex flex-column gap-3'>
          <Typography variant='title1' style={{ color: palette.black }}>
            Load Tender and Rate Agreement
          </Typography>
          <div className='d-flex justify-content-between align-items-start w-100 gap-3'>
            <div className='d-flex flex-column'>
              <div className='d-flex gap-1'>
                <Typography variant='button2'>Confirmation Date:</Typography>
                <Typography variant='overLine2'>
                  {moment().tz(customer_timezone)?.format(`${dateFormat} ${timeFormat} z`)}{' '}
                </Typography>
              </div>

              <div className='d-flex gap-2 flex-wrap'>
                <div>
                  <div className='d-flex gap-1'>
                    <Typography variant='button2'>Reference ID:</Typography>
                    <Typography variant='overLine2'>{shipment_id}</Typography>
                  </div>

                  <div className='d-flex gap-1'>
                    <Typography variant='button2'>Shipment Date:</Typography>
                    <Typography variant='overLine2'>
                      {moment(shipment_stops?.[0]?.scheduled_date).format(dateFormat)}
                    </Typography>
                  </div>

                  <div className='d-flex gap-1'>
                    <Typography variant='button2'>Delivery Date:</Typography>
                    <Typography variant='overLine2'>
                      {moment(shipment_stops?.[shipment_stops.length - 1]?.scheduled_date).format(dateFormat)}
                    </Typography>
                  </div>
                </div>
                <div>{!!barcodeImage && <img src={barcodeImage} width={200} height={90} alt='barcode' />}</div>
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className={classes.carrierDetail}>
              <Typography variant='button2' style={{ color: palette.white }}>
                Carrier
              </Typography>
              <Typography variant='button2' style={{ color: palette.white, width: '200px' }}>
                Carrier Contact
              </Typography>
            </div>
          </div>

          <div className='d-flex'>
            <div className={classes.carrierAddressDetail}>
              <div className='d-flex flex-column'>
                <Typography variant='s3'>{carrier?.name}</Typography>
                <Typography variant='s3'>{carrier?.address1}</Typography>
                {!!carrier?.address2 && <Typography variant='s3'>{carrier.address2}</Typography>}
                <Typography variant='s3'>
                  {carrier?.city?.name}, {carrier?.state?.short_name} {carrier?.zipcode}
                </Typography>
              </div>

              <div className='d-flex flex-column' style={{ width: '200px' }}>
                <Typography variant='s3'>{contact_name}</Typography>
                <a href={`tel:${phone_number}`} style={{ textDecoration: 'none', height: '20px' }}>
                  <Typography variant='s3'>{phone_number}</Typography>
                </a>
                <a href={`mailto:${contact_email}`} style={{ textDecoration: 'none', height: '20px' }}>
                  <Typography variant='s3'>{contact_email}</Typography>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.line} style={{ marginTop: 30 }} />

      <div className='mt-3'>
        <div className={classes.shipmentDetails}>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Typography variant='button2'>Shipment Details</Typography>
          </div>

          <div className='d-flex flex-wrap gap-3 w-100'>
            {shippersAndConsignees?.map((stop, index) => (
              <div className='d-flex flex-column gap-2' style={{ width: '49%' }} key={stop.id}>
                <Typography variant='button2' style={{ color: getStopName(stop.stop_point_type).color }}>
                  STOP {getAlphabet(index)}
                  {getStopName(stop?.stop_point_type).type}
                </Typography>
                <Typography variant='s3'>{stop?.stop_point?.location_name}</Typography>
                <Typography variant='s3'>{stop?.stop_point?.address1}</Typography>
                <Typography variant='s3'>
                  {stop?.stop_point.city?.name}, {stop?.stop_point?.state?.short_name}{' '}
                  {stop?.stop_point.stop_point?.zipcode}
                </Typography>
                {stop?.scheduled_type && (
                  <div className='d-flex gap-1 flex-wrap'>
                    <Typography variant='button2' style={{ whiteSpace: 'nowrap' }}>
                      Schedule Date Type:
                    </Typography>
                    <Typography variant='overLine2' style={{ whiteSpace: 'nowrap' }}>
                      {SCHEDULED_DATE_TYPE_OBJ?.[stop?.scheduled_type]?.label}
                    </Typography>
                  </div>
                )}
                {stop?.scheduled_date && (
                  <div className='d-flex gap-1'>
                    <Typography variant='button2'>Schedule Date/Time:</Typography>
                    <Typography variant='overLine2'>
                      {' '}
                      {`${moment(stop?.scheduled_date).format(dateFormat)},`} {stop?.from && `${stop?.from}`}{' '}
                      {stop?.to && [1, 3].includes(Number(stop?.scheduled_type)) && ` to ${stop?.to}`}
                    </Typography>
                  </div>
                )}
                {!!stop?.equipment_type_data && (
                  <div className='d-flex gap-1'>
                    <Typography variant='button2'>Equipment Required:</Typography>
                    <Typography variant='overLine2'>
                      {' '}
                      {stop?.equipment_type_length_value?.length}{' '}
                      {getEquipmentRequiredTitle(stop?.equipment_type_data)?.title}
                    </Typography>
                  </div>
                )}
                <div className='d-flex gap-1'>
                  <Typography variant='button2'>Equipment Action:</Typography>
                  <Typography variant='overLine2'>
                    {' '}
                    {equipmentTypeText({
                      type: stop?.stop_point_type,
                      action: stop?.equipment_action,
                      equipment: { equipment_id: vehicle_id },
                      equipment_tbd: !!vehicle_id,
                    })}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.line} style={{ marginTop: 10 }} />

      <div className={classes.chargesWrapper}>
        <Typography variant='button2'>Carrier Pay</Typography>
        <div className={classNames(['d-flex justify-content-between w-100 mt-3', classes.carrierPay])}>
          <Typography variant='button2' style={{ color: palette.indigo700, width: '18%' }}>
            Item
          </Typography>
          <Typography variant='button2' style={{ color: palette.indigo700, width: '36%' }}>
            Description
          </Typography>
          <Typography variant='button2' style={{ color: palette.indigo700, width: '19%' }}>
            Quantity
          </Typography>
          <Typography variant='button2' style={{ color: palette.indigo700, width: '13%' }}>
            Rate
          </Typography>
          <Typography variant='button2' style={{ color: palette.indigo700, width: '14%' }}>
            Amount USD
          </Typography>
        </div>
        {carrier_pay?.map((billing) => {
          return (
            <div className={classNames(['d-flex justify-content-between w-100 mt-1', classes.carrierPay])}>
              <Typography variant='s3' style={{ width: '18%' }}>
                {billing?.type_name?.title}
              </Typography>
              <Typography variant='s3' style={{ width: '36%' }}>
                {billing?.description}
              </Typography>
              <Typography variant='s3' style={{ width: '19%' }}>
                {formatNumber(billing?.quantity)}
              </Typography>
              <Typography variant='s3' style={{ width: '13%' }}>
                {formatNumber(billing?.rate)}
              </Typography>
              <Typography variant='s3' style={{ width: '14%' }}>
                {formatNumber(billing.total)}
              </Typography>
            </div>
          );
        })}
      </div>

      <table className='w-100 mt-3'>
        <thead>
          <tr style={{ borderBottom: '1px solid gray' }}>
            <th>Stop Point</th>
            <th>Total Pieces</th>
            <th>Total Weight</th>
            <th style={{ textAlign: 'end' }}>Total Carrier Pay</th>
          </tr>
        </thead>
        <tbody>
          {pickupStops?.map((stop) => {
            return (
              <tr>
                <td>
                  <Typography variant='s3'> {stop.stop_point?.location_name}</Typography>
                </td>
                <td>
                  <Typography variant='s3'> {formatNumber(stop.quantity)}</Typography>
                </td>
                <td>
                  <Typography variant='s3'>{formatNumber(stop.weight)}</Typography>
                </td>
                <td />
              </tr>
            );
          })}
          <tr style={{ borderTop: '1px solid gray' }}>
            <td />
            <td>{formatNumber(total_qty)}</td>
            <td>{formatNumber(total_weight)}</td>
            <td style={{ textAlign: 'end' }}>$ {formatNumber(driver_amount)}</td>
          </tr>
        </tbody>
      </table>

      <div className={classes.chargesWrapper} style={{ width: '40%' }}>
        <div className={classNames(['d-flex justify-content-between w-100 mt-3', classes.carrierPay])}>
          <Typography variant='button2'>Loaded Miles</Typography>
          <Typography variant='button2'>Empty Miles</Typography>
          <Typography variant='button2'>Total Miles</Typography>
        </div>
        <div className={classes.line} style={{ height: '1px' }} />
        <div className={classNames(['d-flex justify-content-between w-100 mt-1', classes.carrierPay])}>
          <Typography variant='s3'>{Number(loaded_miles).toFixed()}</Typography>
          <Typography variant='s3'>{Number(empty_miles).toFixed()}</Typography>
          <Typography variant='s3'>
            {Number(Number(loaded_miles).toFixed()) + Number(Number(empty_miles).toFixed())}
          </Typography>
        </div>
      </div>

      {!!notes && (
        <div className={classes.chargesWrapper}>
          <Typography variant='button2'>Confirmation Notes</Typography>
          <div className='d-flex flex-column w-100 mt-1'>
            <Typography variant='s3'>{notes}</Typography>
          </div>
        </div>
      )}

      <div className={classes.driverDetailWrapper}>
        <div className={classes.driverDetail}>
          <div className='w-100'>
            <div className={`${classes.driverNameWrapper} gap-2`}>
              <Typography variant='button1' style={{ color: palette.white }}>
                Carrier Signature
              </Typography>
              <Typography variant='button1' style={{ color: palette.white, width: '130px' }}>
                Date
              </Typography>
            </div>
            <div className={classes.driverDataWrapper}>
              <div className='d-flex flex-column gap-2'>
                <div className={classes.signature_area}>
                  <SignatureCanvas
                    onBegin={() => {
                      handleChange('signature', 'Klir');
                    }}
                    clearOnResize={false}
                    canvasProps={{ className: classes.sigCanvas }}
                    penColor='black'
                    ref={signPad}
                  />
                  <ErrorMessage error={touchedErrors.signature} />
                </div>
                <div className='d-flex gap-2 w-100'>
                  <Input
                    error={touchedErrors.signature_first_name}
                    className='w-100'
                    value={values.signature_first_name}
                    placeholder='First, Last Name'
                    onChange={(e) => handleChange('signature_first_name', e.target.value)}
                  />
                  <div className='d-flex align-items-center gap-3 me-2'>
                    <span onClick={onClear} className={classes.clear}>
                      Clear
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <DatePicker
                  width='150px'
                  name='signature_date'
                  value={values.signature_date}
                  onChange={(val) => handleChange('signature_date', val)}
                  disabled
                  error={touchedErrors.signature_date}
                />
              </div>
            </div>
          </div>
          <div className='w-100'>
            <div className={classes.driverNameWrapper}>
              <Typography variant='button1' style={{ color: palette.white }}>
                Driver Name
              </Typography>
              <Typography variant='button1' style={{ color: palette.white }}>
                Driver Telephone
              </Typography>
            </div>
            <div className={classes.driverDataWrapper}>
              <Input
                error={touchedErrors.driver_name}
                value={values.driver_name}
                placeholder='Driver Name'
                size='small'
                onChange={(e) => handleChange('driver_name', e.target.value)}
              />
              <PhoneInput
                name='driver_phone_number'
                placeholder='+1 (555) 555-1234'
                onChange={(val) => handleChange('driver_phone_number', val)}
                value={values.driver_phone_number}
                onlyCountries={['us', 'ca', 'mx']}
                error={touchedErrors.driver_phone_number}
              />
            </div>
            <div className={classes.driverNameWrapper}>
              <Typography variant='button1' style={{ color: palette.white }}>
                Truck
              </Typography>
              <Typography variant='button1' style={{ color: palette.white }}>
                Trailer
              </Typography>
              <Typography variant='button1' style={{ color: palette.white }}>
                Equipment Type
              </Typography>
            </div>
            <div className={classes.driverDataWrapper}>
              <Input
                error={touchedErrors.truck_id}
                value={values.truck_id}
                size='small'
                placeholder='Truck'
                onChange={(e) => handleChange('truck_id', e.target.value)}
                style={{ width: 120 }}
              />
              <Input
                error={touchedErrors.trailer_id}
                value={values.trailer_id}
                size='small'
                placeholder='Trailer'
                onChange={(e) => handleChange('trailer_id', e.target.value)}
                style={{ width: 120 }}
                disabled={!trailer_type && vehicle_type?.id === 1}
              />
              {trailer_type ? (
                <Typography variant='s3'>
                  {trailer_length?.length || ''} {trailer_length?.unit || ''} {trailer_type?.title || ''}
                </Typography>
              ) : (
                <Typography variant='s3'>
                  {vehicle_type?.id === 1 ? 'Truck (Power Only)' : vehicle_type?.title}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>

      {!!terms_and_conditions && (
        <div className={classes.chargesWrapper}>
          <Typography variant='button2'>Terms and Conditions</Typography>
          <div className='mt-3'>
            <Typography variant='title2' dangerouslySetInnerHTML={{ __html: terms_and_conditions }} />
          </div>
        </div>
      )}

      <div className={classes.confirmationFooter}>
        <div className={classes.footerItem}>
          <div className='d-flex gap-2 '>
            <LocationIcon />
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              Address
            </Typography>
          </div>
          <div className='d-flex flex-column'>
            <Typography variant='c2'>{address1}</Typography>
            <Typography variant='c2'>
              {city?.name}, {state?.short_name} {zipcode}
            </Typography>
          </div>
        </div>
        <div className={classes.footerItem}>
          <div className='d-flex gap-2'>
            <GlobeIcon />
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              Online
            </Typography>
          </div>
          <Typography variant='c2'>{show_website && website ? website : '-'}</Typography>
        </div>
        <div className={classes.footerItem}>
          <div className='d-flex gap-2'>
            <MailIcon />
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              Email
            </Typography>
          </div>
          <Typography variant='c2'>{company_email}</Typography>
        </div>
        <div className={classes.footerItem}>
          <div className='d-flex gap-2'>
            <PhoneIcon />
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              Phone
            </Typography>
          </div>
          <Typography variant='c2'>{formatPhoneNumber(companyPhone)}</Typography>
        </div>
      </div>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </div>
  );
};

export default Confirmation;
