import React, { useContext, useMemo } from 'react';
import { AddFavorites, RemoveFavorites } from 'Api/Layout';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import starIcon from 'assets/icons/star-filled.png';
import starIconOutLine from 'assets/icons/star-filled-outline.png';
import styles from './HeaderStar.module.css';
import { LayoutContext } from '../layout';

const HeaderStar = ({ title, isBeta = false }) => {
  const { favorites, screen, onAddFavorites, onDeleteFavorites } = useContext(LayoutContext);
  const { use } = useTheme();
  const screenId = screen?.id;
  const showPopoverFavorites = useMemo(() => !!favorites.find((f) => f.id === screenId), [favorites, screenId]);

  const onClickStarIcon = () => {
    if (showPopoverFavorites) {
      RemoveFavorites(screen?.id).then(() => {
        onDeleteFavorites(screen.id);
      });
    } else {
      AddFavorites(screen?.id)
        .then(() => {
          const newFavorites = { id: screen.id, path: `/${screen.slug}`, subTitle: screen.name };
          onAddFavorites(newFavorites);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  return (
    <div className={styles.title_wrap}>
      <span style={{ color: use(palette.gray900, palette.gray50) }}>{title}</span>
      {isBeta && <div className={styles.betaBtn}>BETA</div>}
      <img
        width={14}
        height={14}
        alt='starIconHeader'
        src={showPopoverFavorites ? starIcon : starIconOutLine}
        onClick={onClickStarIcon}
      />
    </div>
  );
};

export default HeaderStar;
