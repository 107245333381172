import React from 'react';
import { Field, FieldArray } from 'formik';
import { palette } from 'utils/constants';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { useTheme } from 'context/themeContext';
import sm from '../../Documents/Documents.module.css';
import CustomSelect from '../../ShipmentStops/helpers/CustomSelect';
import CustomInput from '../../helpers/CustomInput';
import { Typography } from '../../../Typography';
import ImageDropZone from '../../../ImgageDropZone';
import FileActions from '../../Documents/helpers/FileActions';
import CustomButton from '../../../CustomButton/CustomButton';
import { getDocumentTemplate } from '../../ShipmentStops/helpers/constants';

const DocsLTL = (props) => {
  const { use } = useTheme();
  const {
    docs,
    namePrefix,
    documentsStyles,
    docType,
    indexPrefix,
    onChangeDocumentSelect,
    onChangeReferenceId,
    values,
    onDelete,
    documentsUploadFiles,
    onDrop,
    onDownload,
    errorsObj,
    setErrorsObj,
    title,
    setFieldValue,
  } = props;

  function onDeleteRow(index, arrayHelpers) {
    const clone = { ...errorsObj };
    delete clone?.[indexPrefix]?.docs?.[index];
    setErrorsObj(clone);
    onDelete(indexPrefix, index, true, values);
    docs.length > 1 && arrayHelpers.remove(index);
  }

  function onDeleteSelect(name, idxPref, index) {
    setFieldValue(name, '');
    const clone = { ...errorsObj };
    const thisObj = clone?.[idxPref]?.docs?.[index];
    if (typeof thisObj === 'object' && !Array.isArray(thisObj) && Object.keys(thisObj).length > 3) {
      delete clone?.[idxPref]?.docs?.[index];
      setErrorsObj(clone);
    }
  }

  return (
    <div className={sm.docLtl_container} style={{ backgroundColor: use(palette.gray0, palette.dark900) }}>
      <Typography variant='h5' style={{ color: palette.indigo500 }}>
        {title}
      </Typography>
      <FieldArray
        name={`${namePrefix}.docs`}
        render={(arrayHelpers) => {
          return (
            <div style={{ marginTop: 8 }}>
              {docs.map((doc, index) => {
                const PLACE = `${namePrefix}[docs][${index}]`;
                const foundedError = errorsObj?.[indexPrefix]?.docs?.[index];
                const thisFile = documentsUploadFiles?.[indexPrefix]?.[index];
                return (
                  <div key={PLACE} className={sm.documents_wrapper} style={{ display: 'flex' }}>
                    <div className={sm.error_wrapper}>
                      <Field
                        name={`${PLACE}.documentName`}
                        component={CustomSelect}
                        menuStyles={{ width: 400 }}
                        options={docType}
                        deleted
                        onDelete={() => onDeleteSelect(`${PLACE}.documentName`, indexPrefix, index)}
                        onChange={() => onChangeDocumentSelect(indexPrefix, index)}
                        styles={documentsStyles.select}
                      />
                      {!!foundedError?.documentName && (
                        <Typography
                          variant='c2'
                          style={{
                            fontSize: 12,
                            color: use(palette.red500, palette.red800),
                          }}
                        >
                          {foundedError?.documentName}
                        </Typography>
                      )}
                    </div>

                    <div>
                      <Field
                        name={`${PLACE}.documentReferenceId`}
                        component={CustomInput}
                        style={documentsStyles.input}
                        labelStyle={{ margin: 0 }}
                        placeholder='Reference ID'
                        onChange={() => onChangeReferenceId(indexPrefix, index)}
                        value={docs?.[index]?.documentReferenceId}
                        // value={docs?.[indexPrefix]?.[index].documentReferenceId}
                      />
                      {foundedError?.documentReferenceId && (
                        <Typography
                          variant='c2'
                          style={{
                            fontSize: 12,
                            color: use(palette.red500, palette.red800),
                          }}
                        >
                          {foundedError?.documentReferenceId}
                        </Typography>
                      )}
                    </div>

                    <div>
                      <div className={sm.upload_wrapper}>
                        <ImageDropZone
                          onDrop={(acceptedFiles) => onDrop(acceptedFiles, indexPrefix, index)}
                          height={32}
                          accept={['application/pdf']}
                          deletable
                          maxSize={1000000}
                          onDelete={onDelete}
                          deletedComponent={
                            thisFile ? (
                              <FileActions
                                onDelete={() => onDelete(indexPrefix, index, false, values)}
                                onDownload={() => onDownload(indexPrefix, index)}
                              />
                            ) : (
                              <div />
                            )
                          }
                        >
                          <div className={sm.chooseFile_wrapper}>
                            {thisFile?.name ? (
                              <>
                                <AttachmentIcon fill={use(palette.indigo500, palette.indigo500)} />
                                <Typography variant='s2' style={documentsStyles.chooseFile}>
                                  {thisFile?.name}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <PlusIcon fill={use(palette.indigo500, palette.indigo500)} />
                                <Typography variant='s2' style={documentsStyles.chooseFile}>
                                  Choose File
                                </Typography>
                              </>
                            )}
                          </div>
                        </ImageDropZone>
                      </div>

                      {foundedError?.file && (
                        <Typography
                          variant='c2'
                          style={{
                            marginLeft: 20,
                            fontSize: 12,
                            color: use(palette.red500, palette.red800),
                          }}
                        >
                          {foundedError?.file}
                        </Typography>
                      )}
                    </div>

                    {values?.shipmentsDoc?.[indexPrefix]?.docs?.length > 1 && (
                      <div onClick={() => onDeleteRow(index, arrayHelpers)}>
                        <MinusIcon className={sm.minusIcon} />
                      </div>
                    )}
                  </div>
                );
              })}
              <div
                style={{
                  display: 'block',
                }}
              >
                <CustomButton
                  type='secondary'
                  title='Add Document'
                  leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                  styleButton={documentsStyles.addADocument}
                  styleTitle={documentsStyles.addADocumentTitle}
                  onClick={() => arrayHelpers.push(getDocumentTemplate())}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default DocsLTL;
