import React, { useEffect, useState } from 'react';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import InputLabel from 'common/InputLabel';
import { InputWithIcon, InputWithText } from 'common/Input';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import { assignUser, getLimitTypes } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { SCustomModal } from '../../Cards.styles';

const SetLimit = ({ open, onClose, onSuccess, data, cardDetails, limitToUpdate }) => {
  const showToaster = useShowToaster();
  const [limitOptions, setLimitOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { limitId, limit, autoRollMax } = limitToUpdate || {};

  const getLimitOptions = async () => {
    try {
      const { data } = await getLimitTypes();
      setLimitOptions(data);
      if (limitId) {
        const limit = data.find((i) => i.code === limitId);
        handleChange('limitId', limit || null);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let limitIndex = 0;
      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider.id);

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (cardDetails?.limits?.length) {
        cardDetails.limits.forEach((item, index) => {
          formData.append(`limits[${index}][limitId]`, item.limitId);
          formData.append(`limits[${index}][limit]`, item.limit);
          formData.append(`limits[${index}][hours]`, item.hours);
          formData.append(`limits[${index}][minHours]`, item.minHours);
          formData.append(`limits[${index}][autoRollMap]`, item.autoRollMap);
          formData.append(`limits[${index}][autoRollMax]`, item.autoRollMax);
          limitIndex++;
        });
      }

      formData.append(`limits[${limitIndex}][limitId]`, values.limitId.code);
      formData.append(`limits[${limitIndex}][limit]`, values.limit);
      formData.append(`limits[${limitIndex}][hours]`, 0);
      formData.append(`limits[${limitIndex}][minHours]`, 0);
      formData.append(`limits[${limitIndex}][autoRollMap]`, 127);
      formData.append(`limits[${limitIndex}][autoRollMax]`, values.autoRollMax || 0);

      await assignUser(formData);
      showToaster({ type: 'success', message: 'Card limits have been successfully updated' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, dirty } = useForm({
    initialValues: {
      limitId: null,
      limit: limit || '',
      autoRollMax: autoRollMax || '',
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    getLimitOptions();
  }, []);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Set Spend Limit'
      $bgColor={palette.gray0}
      $maxWidth='440px'
      $minWidth='440px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Set Spend Limit',
          disabled: !dirty || loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='mb-2 d-flex flex-column gap-3'>
        <div>
          <Autocomplete
            required
            name='limitId'
            label='Category'
            labelKey='description'
            options={limitOptions}
            value={values.limitId}
            onChange={(e, val) => handleChange('limitId', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.limitId}
            disabled={!!limitId}
          />
        </div>
        <div>
          <InputLabel required>Amount Per Transaction</InputLabel>
          {values?.limitId?.limit_type === 'GAL' ? (
            <InputWithText
              required
              text='GAL'
              name='limit'
              type='number'
              className='w-100'
              onKeyDown={blockNonPositiveNumbers}
              onChange={handleChange}
              value={values.limit}
              placeholder='0'
            />
          ) : (
            <InputWithIcon
              required
              name='limit'
              type='number'
              className='w-100'
              onKeyDown={blockNonPositiveNumbers}
              onChange={handleChange}
              value={values.limit}
              placeholder='0.00'
            />
          )}
          <ErrorMessage error={touchedErrors.limit} />
        </div>
        <InputWithIcon
          required
          name='autoRollMax'
          label='Maximum Amount Per Day'
          type='number'
          className='w-100'
          onKeyDown={blockNonPositiveNumbers}
          onChange={handleChange}
          value={values.autoRollMax}
          placeholder='0.00'
          error={touchedErrors.autoRollMax}
        />
      </div>
    </SCustomModal>
  );
};

export default SetLimit;
