import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .time-worked {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #464f60;
    margin: 0;
    width: 80px;
    text-align: left;
  }

  .time-worked-wrap {
    width: 346px;
    height: 14px;
    background: ${palette.indigo50};
    border-radius: 2px;
    overflow: hidden;

    .time-worked-wrap-sub {
      height: 14px;
      background: ${palette.indigo400};
      border-radius: 2px;
    }
  }
`;
