export const timeOptions = [
  { value: 'minute', name: 'minute(s)' },
  { value: 'hour', name: 'hour(s)' },
  { value: 'day', name: 'day(s)' },
];

export const autoAwardTimeOptions = [
  { value: 'minute', name: 'minute(s)' },
  { value: 'hour', name: 'hour(s)' },
  { value: 'day', name: 'day(s)' },
  { value: null, name: 'Disabled' },
];

export const getInitialValues = (data, settings) => {
  const {
    expire_shipment_offer,
    expire_shipment_offer_unit,
    auto_repost,
    retract_carrier_award,
    retract_carrier_award_unit,
    auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit,
    auto_dispatch,
    require_minimum_margin,
    require_minimum_margin_percent,
  } = data || {};

  const {
    expire_shipment_offer: settings_expire_shipment_offer,
    expire_shipment_offer_unit: settings_expire_shipment_offer_unit,
    auto_repost: settings_auto_repost,
    retract_carrier_award: settings_retract_carrier_award,
    retract_carrier_award_unit: settings_retract_carrier_award_unit,
    auto_award_first_lowest_amount: settings_auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit: settings_auto_award_first_lowest_amount_unit,
    auto_dispatch: settings_auto_dispatch,
    require_minimum_margin: settings_require_minimum_margin,
    require_minimum_margin_percent: settings_require_minimum_margin_percent,
  } = settings || {};

  return {
    expire_shipment_offer: expire_shipment_offer || settings_expire_shipment_offer || '1',
    expire_shipment_offer_unit:
      timeOptions.find((i) => i.value === (expire_shipment_offer_unit || settings_expire_shipment_offer_unit)) ||
      timeOptions[2],
    auto_repost: !!auto_repost || !!settings_auto_repost || false,
    retract_carrier_award: retract_carrier_award || settings_retract_carrier_award || '30',
    retract_carrier_award_unit:
      timeOptions.find((i) => i.value === (retract_carrier_award_unit || settings_retract_carrier_award_unit)) ||
      timeOptions[0],
    auto_award_first_lowest_amount: auto_award_first_lowest_amount || settings_auto_award_first_lowest_amount || '',
    auto_award_first_lowest_amount_unit:
      autoAwardTimeOptions.find(
        (i) => i.value === (auto_award_first_lowest_amount_unit || settings_auto_award_first_lowest_amount_unit)
      ) || autoAwardTimeOptions[3],
    auto_dispatch: !!auto_dispatch || !!settings_auto_dispatch || false,
    require_minimum_margin: !auto_award_first_lowest_amount_unit
      ? false
      : !!require_minimum_margin || !!settings_require_minimum_margin || false,
    require_minimum_margin_percent: require_minimum_margin_percent || settings_require_minimum_margin_percent || '',
  };
};
