import React, { useEffect, useState } from 'react';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CreateShipment/helpers/CustomInput';
import './Row.css';

const Row = ({ rowData, offer, offerState, revertOfferForVehicle }) => {
  const showToaster = useShowToaster();
  const [value, setValue] = useState(rowData?.driver1_cost?.toFixed(2) || '');
  const [declineReason, setDeclineReason] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [buttonState, setButtonState] = useState('Send offer');

  useEffect(() => {
    const item = offerState?.find((item) => item.equipment_id === rowData.equipment_id);
    if (!item) {
      setButtonState('Send offer');
      return;
    }

    setValue(item.offer_amount);
    setDeclineReason(item?.decline_reason);
    item.suggestion && setSuggestion(item.suggestion);
    switch (item?.driver_acceptance) {
      case 0:
        setButtonState('Loading');
        return;
      case 1:
        setButtonState('Accepted');
        return;
      case 2:
        setButtonState('Declined');
        return;
      default:
        setButtonState('Send offer');
    }
  }, [offerState]);

  return (
    <div>
      <div className='d-flex align-items-center gap-2'>
        <div className='d-flex flex-column'>
          <CustomInput
            inputType='number'
            value={value}
            labelStyle={{ marginLeft: 8, marginTop: 0 }}
            leftIcon={<DollarIcon height={32} />}
            disabled={buttonState !== 'Send offer'}
            onKeyDown={blockNonPositiveNumbers}
            onChange={(e) => setValue(e.target.value)}
            style={{
              width: 100,
              borderRadius: 6,
              marginLeft: -8,
              paddingLeft: 15,
              height: 32,
              outline: 'none',
            }}
          />
          {buttonState === 'Accepted' && (
            <div className='mt-2' style={{ color: palette.dark800 }}>
              <span
                className='offer_status'
                style={{
                  backgroundColor: '#E1FCEF',
                  color: '#14804A',
                  padding: '2px 6px',
                  borderRadius: '4px',
                }}
              >
                Accepted
              </span>
            </div>
          )}
          {buttonState === 'Declined' && (
            <div className='d-flex flex-column mt-2'>
              {/* <div style={{ color: palette.dark800 }}> */}
              {/*    <span className={'offer_status'} */}
              {/*          style={{ */}
              {/*            backgroundColor: '#FAF0F3', */}
              {/*            color: '#D12953', */}
              {/*            padding: '2px 6px', */}
              {/*            borderRadius: '4px' */}
              {/*          }} */}
              {/*    > */}
              {/*      Declined */}
              {/*    </span> */}
              {/* </div> */}
              <span
                className='mt-2'
                style={{
                  color: '#D12953',
                }}
              >
                {declineReason.name}
              </span>
              {declineReason.id === 2 && (
                <span
                  className='mt-2'
                  style={{
                    color: '#D12953',
                  }}
                >
                  wants ${suggestion}{' '}
                </span>
              )}
            </div>
          )}
        </div>
        {buttonState === 'Send offer' && (
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Send Offer'
            rightIcon={<ArrowLeft fill={palette.white} style={{ marginLeft: 10, transform: 'rotate(180deg)' }} />}
            styleButton={{ height: 32, marginLeft: 8, marginTop: 0, width: 110 }}
            onClick={() => {
              setButtonState('Loading');
              offer(rowData, value);
            }}
            disabled={!value}
          />
        )}
        {buttonState === 'Loading' && (
          <div className='ms-2'>
            <button className='loadingWrapper d-flex justify-content-center'>
              <div className='btnSpinner' />
            </button>
          </div>
        )}
        {buttonState !== 'Send offer' && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            rightIcon={<RevertIcon />}
            styleButton={{ height: 32, marginTop: 0 }}
            onClick={() => {
              const offerId = offerState.find((offer) => offer.equipment_id === rowData.equipment_id).id;
              revertOfferForVehicle(offerId);
              setButtonState('Send offer');
              showToaster({ type: 'success', message: 'Shipment offer has been reverted successfully!' });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Row;
