import React from 'react';
import Chip from 'common/Chip';
import useDateFormat from 'hooks/useDateFormat';
import { SWrapper } from './PayPeriod.styles';

const PayPeriod = ({ start, end }) => {
  const { formatDate } = useDateFormat();
  return (
    <SWrapper>
      <Chip label={formatDate(start)} />
      -
      <Chip label={formatDate(end)} />
    </SWrapper>
  );
};

export default PayPeriod;
