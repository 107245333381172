import React from 'react';
import { useSelector } from 'react-redux';
import Divider from 'common/Divider';
import Tooltip from 'common/Tooltip';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import { formatNumber } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { paymentMethods } from 'pages/Accounting/Payables/components/MarkPaid/MarkPaid.data';
import { STable, STableRow } from 'pages/Accounting/Payables/Payables.styles';
import { SAppliedPaymentsWrapper } from '../AddBill.styles';

const AppliedPayments = ({ bill }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return (
    <SAppliedPaymentsWrapper>
      <div className='payments-header'>
        <Typography variant='h2' style={{ marginBottom: 16 }}>
          Payments Applied
        </Typography>
      </div>
      <Divider />
      <div className='mb-2'>
        <STable>
          <thead>
            <tr className='nowrap'>
              <th>PAYMENT DATE</th>
              <th>PAY REFERENCE</th>
              <th>PAY METHOD</th>
              <th>PAYMENT ACCOUNT</th>
              <th>AMOUNT PAID</th>
            </tr>
          </thead>
          <tbody>
            {bill.payments?.map((payment) => (
              <STableRow key={payment.pay_reference}>
                <td>
                  <Typography variant='s2'>{formatDate(payment.payment_date)}</Typography>
                </td>
                <td>
                  <Typography variant='s2'>
                    <Tooltip title={payment.pay_reference}>
                      <span>*****{payment.pay_reference?.slice(-5)}</span>
                    </Tooltip>
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2'>
                    {paymentMethods.find((i) => i.value === payment.pay_method)?.name || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2'>{payment.bank_account?.account_name}</Typography>
                </td>
                <td>
                  <Typography variant='s2'>
                    {currency}
                    {formatNumber(payment.amount_paid)}
                  </Typography>
                </td>
              </STableRow>
            ))}
            <tr className='no-border'>
              <td />
              <td />
              <td />
              <td>
                <Typography variant='button2'>Bill Total Due</Typography>
              </td>
              <td>
                <Typography variant='h5'>
                  {currency}
                  {formatNumber(bill.amount_due)}
                </Typography>
              </td>
            </tr>
            <tr className='no-border'>
              <td />
              <td />
              <td />
              <td>
                <Typography variant='button2'>Payments Applied</Typography>
              </td>
              <td>
                <Typography variant='h5'>
                  {currency}
                  {formatNumber(bill.amount_paid)}
                </Typography>
              </td>
            </tr>
            <tr className='no-border'>
              <td />
              <td />
              <td />
              <td>
                <Typography variant='button2'>Open Balance</Typography>
              </td>
              <td>
                <Typography variant='h5'>
                  {currency}
                  {formatNumber(bill.open_balance)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </STable>
        {!bill.payments?.length && <NoRecords />}
      </div>
    </SAppliedPaymentsWrapper>
  );
};

export default AppliedPayments;
