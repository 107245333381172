import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SAccordion, SWrapper } from './LoanSummary.styles';

const LoanSummary = ({ account }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const { currency } = useSelector((state) => state.root);
  const { interest_rate, loan_term, loan_term_type, loan_amount, compounding } = account || {};
  const interest = Number(compounding) === 1 ? interest_rate / 100 / 12 : (1 + interest_rate / 100) ** (1 / 12) - 1;
  const numberOfPayments = loan_term * (loan_term_type === 'months' ? 1 : 12);

  const monthlyPayment =
    loan_amount * ((interest * (1 + interest) ** numberOfPayments) / ((1 + interest) ** numberOfPayments - 1));
  const totalCost = monthlyPayment * numberOfPayments;
  const totalInterest = totalCost - loan_amount;

  return (
    <SWrapper className='mb-3'>
      <SAccordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ArrowUp className='accordion-arrow' />} id='panel1a-header'>
          <div>
            <Typography variant='s2' style={{ color: palette.gray800 }}>
              SUMMARY
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <div className='d-flex gap-5'>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Monthly Payment
              </Typography>
              <Typography variant='s1'>
                {currency}
                {formatNumber(monthlyPayment)}
              </Typography>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Total Interest
              </Typography>
              <Typography variant='s1'>
                {currency}
                {formatNumber(totalInterest)}
              </Typography>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Total Cost of Loan
              </Typography>
              <Typography variant='s1'>
                {currency}
                {formatNumber(totalInterest + loan_amount)}
              </Typography>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Number of Payments
              </Typography>
              <Typography variant='s1'>{numberOfPayments}</Typography>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Interest Rate
              </Typography>
              <Typography variant='s1'>{interest_rate}%</Typography>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Loan Terms
              </Typography>
              <Typography variant='s1'>
                {loan_term} {loan_term_type}
              </Typography>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='c1' style={{ color: palette.gray700 }}>
                Compounding
              </Typography>
              <Typography variant='s1'>{Number(compounding) === 1 ? 'Monthly (APR)' : 'Yearly (APY)'}</Typography>
            </div>
          </div>
        </AccordionDetails>
      </SAccordion>
    </SWrapper>
  );
};

export default LoanSummary;
