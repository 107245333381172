import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 8, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Equipment ID', 8, top);
  doc.text('Type', 34, top);
  doc.text('Description', 68, top);
  doc.text('Status', 110, top);
  doc.text('Service Date', 132, top);
  doc.text('Serviced At', 160, top);
  doc.text('Cost', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, top, formatDateTime, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${data.equipment?.equipment_id || '-'}`, 8, top);
  doc.text(`${data.record_type?.type || '-'}`, 34, top);
  doc.text(`${data.description?.length <= 30 ? data.description || '-' : data.description.substring(0, 30)}`, 68, top);
  doc.text(`${data.status?.status || '-'}`, 110, top);
  doc.text(`${formatDateTime(data.service_date_time)}`, 132, top);
  doc.text(
    `${data.service_at?.name?.length <= 20 ? data.service_at?.name || '-' : data.service_at?.name.substring(0, 20)}`,
    160,
    top
  );
  doc.text(`${currency}${formatAmount(data.cost)}`, pageWidth - 8, top, { align: 'right' });
};

const createTotalRow = (doc, totalCost, currency, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top, pageWidth - 8, top);

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Total', 8, top + 5);
  doc.text(`${currency}${formatAmount(totalCost)}`, pageWidth - 8, top + 5, { align: 'right' });

  doc.setLineWidth(0.1);
  doc.line(8, top + 8, pageWidth - 8, top + 8);
};

const createTableRows = (doc, records, formatDateTime, currency) => {
  if (!records?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  records.forEach((record, index) => {
    createTableRow(doc, record, top + index * 5, formatDateTime, currency);
  });

  return top + records.length * 5;
};

export const generatePDF = async (
  reportItems,
  totalCost,
  download,
  dateRange,
  formatDateTime,
  formatDate,
  convertToCustomerTime,
  currency
) => {
  if (!reportItems?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Maintenance Cost', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageRecords = reportItems.slice(0, 40);
  const restRecords = reportItems.slice(40);
  let totalRowStart;

  totalRowStart = createTableRows(doc, firstPageRecords, formatDateTime, currency);

  for (let i = 0; i < (restRecords?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageRecords = restRecords.slice(i * 45, (i + 1) * 45);
    totalRowStart = createTableRows(doc, currentPageRecords, formatDateTime, currency);
  }

  createTotalRow(doc, totalCost, currency, totalRowStart);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({
    title: `Maintenance Cost - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}`,
  });

  const url = doc.output('datauristring', { filename: 'Maintenance Cost' });
  const blob = doc.output('blob');

  if (download) {
    doc.save(`Maintenance Cost - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}.pdf`);
  }
  return { blob, url };
};
