import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import UserInfo from 'components/UserInfo';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import { numberWithCommas, palette } from 'utils/constants';
import ColumnHeader from 'common/ColumnHeader';

export const initialFilters = {
  tab: 0,
  page: 1,
  itemsPerPage: 25,
  type: [],
  status: ['Pending'],
  user_type: [],
};

export const useColumns = ({ sortingQuery, sort }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'id',
      width: '100%',
      title: <ColumnHeader title='ID' field='request_id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => <span style={{ color: use(palette.gray900, palette.white) }}>{rowData?.request_id}</span>,
    },
    {
      field: 'name',
      title: <ColumnHeader title='NAME' field='name' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <UserInfo
          size='46px'
          data={{
            ...(rowData || {}),
            id: rowData.driver_staff_id,
            image: rowData.profile_image,
          }}
        />
      ),
    },
    {
      field: 'req_start_datetime',
      title: <ColumnHeader title='REQUEST DATE(S)' field='req_start_datetime' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const a = moment(rowData?.req_start_datetime).startOf('day');
        const b = moment(rowData?.req_end_datetime).add(1, 'day').startOf('day');
        const day = Math.floor(moment.duration(b.diff(a)).asDays());
        return rowData?.request_type === 'Need Maintenance' ||
          rowData?.request_type === 'Request Call from Dispatch' ||
          rowData?.request_type === 'Truck Broke Down' ? (
          '-'
        ) : rowData?.request_type === 'Time Off' ||
          rowData?.request_type === 'Cash Advance' ||
          rowData?.request_type === 'Fuel Advance' ? (
          <>
            <div className='sub-wraper'>
              <div
                className='date-container'
                style={{
                  backgroundColor: use('#F0F2F7', palette.dark600),
                  color: use(palette.gray600, palette.gray400),
                }}
              >
                <p className='sub-text'>{formatDate(`${rowData?.req_start_datetime}`)}</p>
              </div>
              {rowData?.request_type === 'Time Off' && (
                <>
                  <span>-</span>
                  <div
                    className='date-container'
                    style={{
                      backgroundColor: use('#F0F2F7', palette.dark600),
                      color: use(palette.gray600, palette.gray400),
                    }}
                  >
                    <p className='sub-text'>{formatDate(`${rowData?.req_end_datetime}`)}</p>
                  </div>
                </>
              )}
            </div>
            {rowData?.request_type === 'Time Off' ? (
              <p className='table-data-text absence-days' style={{ color: use(palette.gray700, palette.gray200) }}>
                {day === 0 ? '1 Day(s)' : day ? `${day} Day(s)` : '-'}
              </p>
            ) : (
              <p className='table-data-text absence-days' style={{ color: use(palette.gray700, palette.gray200) }}>
                {rowData.request_amount
                  ? `${currency}${numberWithCommas(Number(rowData.request_amount).toFixed(2))}`
                  : '-'}
              </p>
            )}
          </>
        ) : (
          <div className='cash-container'>
            <div
              className='date-container cash-container-type'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{formatDate(`${rowData?.req_start_datetime}`)}</p>
            </div>
          </div>
        );
      },
    },
    {
      field: 'request_type',
      title: <ColumnHeader title='REQUEST TYPE' field='request_type' />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
          {rowData?.request_type}
        </p>
      ),
    },
    {
      field: 'req_status',
      title: <ColumnHeader title='STATUS' field='req_status' onClick={sortingQuery} sort={sort} />,

      render: (rowData) =>
        rowData?.req_status === 'Pending' || rowData?.req_status === 'P' ? (
          <div
            className='status-req break-container'
            style={{
              backgroundColor: use(palette.orangeBackground1, palette.orangeBackground),
            }}
          >
            <p className='break-text text-status pending-text' style={{ fontSize: '13px' }}>
              {rowData?.req_status === 'P' ? 'PENDING' : rowData?.req_status?.toUpperCase()}
            </p>
          </div>
        ) : rowData?.req_status === 'Approved' || rowData?.req_status === 'A' ? (
          <>
            <div
              className='status-req success-container'
              style={{
                backgroundColor: use(palette.lightgreen, palette.darkgreen),
              }}
            >
              <p className='success-text' style={{ fontSize: '13px' }}>
                {rowData?.req_status === 'A' ? 'APPROVED' : rowData?.req_status?.toUpperCase()}
              </p>
            </div>
            {rowData?.updated_by ? (
              <p className='pt-1'> {`by ${rowData?.updated_by?.first_name} ${rowData?.updated_by?.last_name}`}</p>
            ) : null}
          </>
        ) : rowData?.req_status === 'Rejected' || rowData?.req_status === 'R' ? (
          <>
            <div
              className='status-req warning-container'
              style={{
                backgroundColor: palette.red0,
              }}
            >
              <p className='warning-text' style={{ fontSize: '13px' }}>
                {rowData?.req_status === 'R' ? 'REJECTED' : rowData?.req_status?.toUpperCase()}
              </p>
            </div>
            {rowData?.updated_by ? (
              <p className='pt-1'> {`by ${rowData?.updated_by?.first_name} ${rowData?.updated_by?.last_name}`}</p>
            ) : null}
          </>
        ) : null,
    },
    {
      field: 'note',
      title: <ColumnHeader title='REQUEST NOTES' field='note' />,
      render: (rowData) => (
        <div className='more-dowpdown-container' onClick={(e) => e.stopPropagation()}>
          <p
            className='table-data-text'
            style={{
              color: use(palette.gray700, palette.gray200),
              whiteSpace: 'break-spaces',
              minWidth: rowData?.note?.length > 40 ? '440px' : '',
            }}
          >
            {rowData?.note}
          </p>
        </div>
      ),
    },
  ];
};
