import React from 'react';
import achImg from 'assets/images/onboarding/ach.png';
import { ReactComponent as Card } from 'assets/icons/card.svg';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { creditCard } from 'components/Billing/PaymentMethod/PaymentMethod.data';
import { SBoxWrapper } from '../TransactionDetails.styles';

const Overview = ({ transaction, transactionData }) => {
  const { formatDate } = useDateFormat();
  const showToaster = useShowToaster();
  const { payee, account, memo } = transactionData || {};
  const { payment_intent, payment_method } = transaction?.transaction_details || {};
  const { id, charges } = payment_intent || {};
  const { card, type, us_bank_account } = payment_method || {};

  return (
    <div className='d-flex mt-3'>
      <SBoxWrapper>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Last update
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {charges?.data?.length ? formatDate(charges.data[charges.data.length - 1].created * 1000) : '-'}
          </Typography>
        </div>
      </SBoxWrapper>
      <SBoxWrapper>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Account
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {account?.account_name || '-'}
          </Typography>
        </div>
      </SBoxWrapper>
      <SBoxWrapper>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Memo
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {memo || '-'}
          </Typography>
        </div>
      </SBoxWrapper>
      <SBoxWrapper>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Payee
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {payee?.name || '-'}
          </Typography>
        </div>
      </SBoxWrapper>
      <SBoxWrapper>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Payment Method
          </Typography>
          <div className='d-flex align-items-center gap-1'>
            {type === 'card' && (creditCard[card?.brand] || <Card width={40} height={40} />)}
            {type === 'us_bank_account' && <img alt='ach' src={achImg} height={50} />}
            {type === 'link' && <Link width={24} height={24} className='me-1' />}
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {type === 'card' && `••••${card.last4}`}
              {type === 'us_bank_account' && `••••${us_bank_account.last4}`}
              {type === 'link' && `Link`}
            </Typography>
          </div>
        </div>
      </SBoxWrapper>
      <SBoxWrapper>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Reference
          </Typography>
          <div
            className='d-flex align-items-center gap-2 pointer'
            onClick={() => {
              navigator.clipboard.writeText(id).then(() => {
                showToaster({ type: 'success', message: 'Reference is successfully copied!' });
              });
            }}
          >
            <Typography variant='b2'>{id}</Typography>
            <Copy width={12} height={12} fill={palette.gray500} className='pointer' />
          </div>
        </div>
      </SBoxWrapper>
    </div>
  );
};

export default Overview;
