import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik, setNestedObjectValues } from 'formik';
import moment from 'moment';
import { getAlphabet, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { revertLastAction } from 'Api/Planner';
import useShowToaster from 'hooks/useShowToaster';
import { convertToCustomerTime } from 'utils/helpers';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CustomButton from 'components/CustomButton/CustomButton';
import { Typography } from 'components/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GetSettingsShipments } from 'Api/CompanySettings';
import { validationSchema } from 'components/TableRowEditModalTypes/CheckInOutModal/validationSchema';
import classes from './checkInOut.module.scss';
import PickUpInModal from './types/PickUpInModal';
import DeliveryInModal from './types/DeliveryInModal';
import WayPointInModal from './types/WayPointInModal';

const CheckInOutModal = ({ isOpen, setIsOpen, onAddBtn, data, shipment, onRevertSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [shipmentSettings, setShipmentSettings] = useState(null);
  const getStopName = useCallback((type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400) },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500) },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText) },
    };
    return TYPE_STOPS[type];
  }, []);

  const submit = (validateForm, resetForm, submitForm, values) => {
    if (
      !!shipment?.shipment_billing?.[0]?.billing_customer?.customer_payment_term?.require_signature_on_stop &&
      !values.signature &&
      !values.check_in &&
      Number(data?.stop_point_type) !== 3
    ) {
      showToaster({ type: 'warning', message: 'Save signature to continue' });
    } else {
      const arrival_date = moment(values.arrival_date);
      const departure_date = data.arrival_date ? moment(values.departure_date) : null;
      if (departure_date && departure_date.isBefore(arrival_date)) {
        showToaster({ type: 'error', message: 'Departure date cannot be before Arrival date.' });
      } else if ((departure_date && !departure_date.isValid()) || (arrival_date && !arrival_date.isValid())) {
        showToaster({ type: 'error', message: 'Arrival or departure date is invalid!' });
      } else {
        validateForm().then((errors) => {
          const isValid = Object.keys(errors).length === 0;
          if (isValid) {
            setLoading(true);
            onAddBtn(validateForm, resetForm, submitForm, values, setLoading).finally(() => setLoading(false));
          }
        });
      }
    }
  };

  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );

  function getInitialValueInType(type, data) {
    switch (type) {
      case 1:
        return {
          arrival_date: data.arrival_date
            ? data.arrival_date
            : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
          departure_date: data.departure_date
            ? data.departure_date
            : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
          equipment_action: data.equipment_action,
          equipment_id: data.equipment?.id ? data.equipment?.id : null,
          actual_quantity: '',
          actual_weight: '',
          bill_lading_id: '',
          seal_id: '',
          bill_type: false,
          dock_high: false,
          stackable: false,
          hazardous_materials: false,
          check_in: !data.arrival_date,
          stop_point_id: data.id,
          reported_weight_type: data.reported_weight_type,
          stop_point_type: data.stop_point_type,
          equipment_tbd: data.equipment_tbd,
          signature: '',
          bill_of_lading_ref: '',
          bill_of_lading: '',
          fname: '',
          lname: '',
          cargos: data.cargos || [],
        };
      case 2:
        return {
          arrival_date: data.arrival_date
            ? data.arrival_date
            : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
          departure_date: data.departure_date
            ? data.departure_date
            : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
          equipment_action: data.equipment_action,
          equipment_id: data.equipment?.id ? data.equipment?.id : null,
          cargo_onBoard: '1',
          bill_type: false,
          dock_high: false,
          proof_of_delivery_name: '',
          signature_name: '',
          check_in: !data.arrival_date,
          stop_point_id: data.id,
          reported_weight_type: data.reported_weight_type,
          stop_point_type: data.stop_point_type,
          signature: '',
          bill_of_lading_ref: '',
          bill_of_lading: '',
          drop_id: shipment?.equipment?.hooked_to?.id,
        };
      case 3:
        return {
          arrival_date: data.arrival_date
            ? data.arrival_date
            : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
          departure_date: data.departure_date
            ? data.departure_date
            : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
          equipment_action: data.equipment_action,
          equipment_id: data.equipment?.id ? data.equipment?.id : null,
          bill_type: false,
          dock_high: false,
          labor_required: false,
          check_in: !data.arrival_date,
          stop_point_id: data.id,
          reported_weight_type: data.reported_weight_type,
          stop_point_type: data.stop_point_type,
          proof_of_delivery_name: '',
          signature: '',
          bill_of_lading_ref: '',
          bill_of_lading: '',
          signature_name: '',
          drop_id: shipment?.equipment?.hooked_to?.id,
          equipment_tbd: data.equipment_tbd,
        };
      default:
    }
  }

  const initialValue = useMemo(() => {
    switch (Number(data?.stop_point_type)) {
      case 1:
        return getInitialValueInType(Number(data?.stop_point_type), data);
      case 2:
        return getInitialValueInType(Number(data?.stop_point_type), data);
      case 3:
        return getInitialValueInType(Number(data?.stop_point_type), data);
      default:
    }
  }, [data]);

  const StopType = useMemo(() => {
    switch (Number(data?.stop_point_type)) {
      case 1:
        return PickUpInModal;
      case 2:
        return DeliveryInModal;
      case 3:
        return WayPointInModal;
      default:
    }
  }, [data]);

  const getSettingsShipments = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getSettingsShipments();
  }, []);

  return (
    <div>
      <Formik
        initialValues={{ ...initialValue }}
        enableReinitialize
        onSubmit={() => {}}
        validationSchema={
          Number(data?.stop_point_type) === 1 && !!data?.arrival_date && shipmentSettings?.require_actual_data
            ? validationSchema
            : undefined
        }
      >
        {({ values, resetForm, submitForm, validateForm, setFieldValue, touched, setTouched, errors }) => {
          const typeData = getStopName(Number(data?.stop_point_type));
          return (
            <Form>
              <ModalWrapper
                autoFocus
                width={900}
                minHeight={600}
                backdrop='static'
                isOpen={isOpen}
                title={!data.arrival_date ? 'Check In' : 'Check Out'}
                subTitle={subTitle}
                styleTitle={{ fontSize: 16 }}
                onHide={() => setIsOpen(false)}
                dialogClassName='dialogClassName'
                styleBody={{ height: 600, overflowY: 'auto', padding: '20px' }}
                footer={
                  <div
                    className={classes.modal_footer_lane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    {shipment?.shipment_stops?.[0].arrival_date && (
                      <CustomButton
                        type='yellow'
                        title='Revert Last Action'
                        onClick={() => {
                          setLoadingRevert(true);
                          revertLastAction([shipment.shipment_id])
                            .then(() => {
                              showToaster({ type: 'success', message: 'Last action has been reverted' });
                              onRevertSuccess();
                              setIsOpen(false);
                            })
                            .finally(() => {
                              setLoadingRevert(false);
                            });
                        }}
                        styleButton={{ padding: '2px 8px', marginTop: 0, background: 'rgb(180, 137, 9)' }}
                        disabled={loadingRevert}
                      />
                    )}
                    <CustomButton
                      type='primary'
                      leftIcon={
                        loading ? (
                          <CircularProgress
                            style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }}
                          />
                        ) : (
                          <div />
                        )
                      }
                      title={!data.arrival_date ? 'Check In' : 'Check Out'}
                      onClick={() => {
                        setTouched(setNestedObjectValues(values, true));
                        submit(validateForm, resetForm, submitForm, values);
                      }}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                      disabled={loading}
                    />
                  </div>
                }
              >
                <div>
                  <div className={classes.header}>
                    <Typography variant='c1' style={{ color: typeData?.color }}>
                      STOP {getAlphabet(data?.index)}
                      {typeData?.type}
                    </Typography>
                    <div className={classes.headerSubTitle}>
                      <div className='d-flex gap-2'>
                        <Typography variant='s2'>{data?.stop_point?.location_name}</Typography>
                        <Typography variant='s3'>
                          {Number(data?.index) + 1} of {data?.shipment_stops?.length}
                        </Typography>
                      </div>
                      <div className={classes.subTitle}>
                        <Typography className={classes.address1} variant='s3'>
                          {data?.stop_point?.address1},&nbsp;
                        </Typography>
                        <Typography variant='s3'>{data?.stop_point?.city?.name},&nbsp;</Typography>
                        <Typography variant='s3'>{data?.stop_point?.state?.name}&nbsp;</Typography>
                        <Typography variant='s3'>{data?.stop_point?.zipcode}</Typography>
                      </div>
                    </div>
                  </div>

                  <StopType
                    values={values}
                    stop={data}
                    setFieldValue={setFieldValue}
                    shipment={shipment}
                    imageData={imageData}
                    setImageData={setImageData}
                    touched={touched}
                    errors={errors}
                    shipmentSettings={shipmentSettings}
                  />
                </div>
              </ModalWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CheckInOutModal;
