import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { palette } from 'utils/constants';
import { PusherJs } from 'common/pusher';
import ConfirmModal from 'common/ConfirmModal';
import { deleteDispatch, getReservation, UpdateShare } from 'Api/Planner';
import { getShipmentInfo, updateCellularTracking } from 'Api/Shipment';
import { onChangeActiveTab } from 'store/reducers/shipments.reducer';
import { GetShipmentDetention } from 'Api/DetentionShipment';
import { handleToaster, updateRowEditItem } from 'store/reducers/root.reducer';
import { ReactComponent as DetentionIcon } from 'assets/icons/detention.svg';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import RestrictedCheckIn from 'components/TableShipments/components/RestrictedCheckIn';
import RequestCellularTracking from 'components/TableShipments/components/RequestCellularTracking';
import { getUserProfile } from 'Api/User';
import Cargo from 'componentsV2/Commodity/Cargo';
import { Typography } from 'components/Typography';
import BidHistory from 'components/TableShipments/detailsRow/steps/BidHistory';
import { revertAllOffers } from 'Api/OfferedShipments';
import { Helmet } from 'react-helmet';
import Notes from './steps/Notes';
import Documents from './steps/Documents';
import DetailsHeader from './DetailsHeader';
import OverView from './steps/OverView/OverView';
import Detention from './steps/Detention/Detention';
import BillingCharges from './steps/BillingCharges';
import TrackingHistory from './steps/TrackingHistory';
import ShipmentsEvents from './steps/ShipmentsEvents';
import { SHIPMENT_DETAILS_TABS } from '../helpers/constants';
import TablePlannerRowEditChildes from '../../TablePlaner/helpers/TablePlannerRowEditChildes';
import TableShipmentRowEditChildes from '../TableShipmentRowEditChildes';

import classes from './details.module.css';
import DriverPays from './steps/DriverPays';
import ElectronicBillOfLading from './steps/ElectronicBillOfLading';
import CarrierPays from './steps/CarrierPays';
import { getAverageTimeConverted } from '../../StopPoint/Walmart/helpers';
import { compareTimeDurations, sumDurations } from '../BillOfLading/helpers/utils';

const ShipmentsDetails = () => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const { state: rowData } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const { id } = useParams();
  const { activeTabDetails } = useSelector((state) => state?.shipments);
  const [infoHeader, setInfoHeader] = useState({});
  const [tableShipmentModalObj, setTableShipmentModalObj] = useState({});
  const [unReserveEndPointData, setUnReserveEndPointData] = useState({});
  const [user, setUser] = useState({});
  const [reserveResponse, setReserveResponse] = useState({});
  const isShipmentTable = useMemo(() => infoHeader?.assigned_to1 !== undefined, [infoHeader]);

  const [detentions, setDetentions] = useState({ data: [] });
  const [loadingDetention, setLoadingDetention] = useState(false);
  const [GPS, setGPS] = useState(null);
  const [openRestrictedCheckIn, setOpenRestrictedCheckIn] = useState(false);
  const [openRequestTracking, setOpenRequestTracking] = useState(false);
  const [openStopTracking, setOpenStopTracking] = useState(false);
  const [openConfirmCarrierCheckin, setOpenConfirmCarrierCheckin] = useState(null);
  const [driverPayRefreshIndex, setDriverPayRefreshIndex] = useState(Date.now());

  const getDetention = useCallback(
    async ({ page, page_size }) => {
      setLoadingDetention(true);
      try {
        const params = { page, page_size, shipment_id: id };
        const detention = await GetShipmentDetention(params);
        setDetentions(detention);
        setLoadingDetention(false);
      } catch (e) {
        setLoadingDetention(false);
      }
    },
    [id]
  );

  const getHeaderInfo = () => {
    getShipmentInfo(id)
      .then((res) => res?.data && setInfoHeader(res.data))
      .catch(() => {
        // Do nothing
      });
  };

  const getUser = async () => {
    try {
      const { data } = await getUserProfile();
      setUser(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    bindPusher();
    getUser();
  }, []);

  const bindPusher = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const channelShipmentProgress = `private-shipmentData-update.${dot}`;

    PusherJs.unsubscribe(channelShipmentProgress);
    const channelShipmentProgress_table = PusherJs.subscribe(channelShipmentProgress);

    channelShipmentProgress_table.bind('shipment-update', (e) => {
      if (e?.message?.data?.id === id) {
        getHeaderInfo();
      }
    });
  };

  const getUnReserveData = ({ item }) => {
    const shId = Object.keys(reserveResponse).length > 0 ? reserveResponse?.id : infoHeader?.shipment_reserve?.id;
    const rData =
      Object.keys(reserveResponse).length > 0
        ? {
            ...infoHeader,
            shipment_reserve: reserveResponse,
          }
        : infoHeader;

    getReservation({ shipment_id: shId })
      .then((res) => {
        if (res?.data) {
          setUnReserveEndPointData(res?.data);
          setTableShipmentModalObj({ item, rowData: rData });
        } else {
          dispatch(handleToaster({ visible: true, type: 'warning', message: res?.message }));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const revertOffers = async () => {
    try {
      await revertAllOffers({ shipment_id: infoHeader.shipment_id, resend: 0 });
      showToaster({ type: 'success', message: 'All offers have been successfully revered!' });
      getHeaderInfo();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const revertDispatch = async () => {
    try {
      await deleteDispatch({ shipment_id: infoHeader.shipment_id });
      if (infoHeader?.brokerage_dispatch) {
        navigate(
          `/planner/carrier-dispatch?id=${infoHeader.shipment_id}&carrier=${infoHeader.brokerage_dispatch.carrier_id}`
        );
      } else {
        navigate(`/planner/dispatch?id=${infoHeader.shipment_id}&equipment=${infoHeader.equipment?.id}`);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  function onClickHeaderOverLayOption(item) {
    if (item.route) {
      +item.id === 18 && navigate(`/${item.route}/${id}/dispatch`);
      +item.id === 16 && navigate(`/${item.route}/${id}/new`);
    } else {
      switch (item?.id) {
        case 1:
          dispatch(updateRowEditItem({ ...infoHeader, label: 'Shipment' }));
          break;
        case 2:
          const dropTrailerRequired =
            infoHeader?.shipment_stops &&
            !infoHeader?.shipment_stops?.[0]?.arrival_date &&
            Number(infoHeader?.shipment_stops?.[0]?.equipment_action) === 2 &&
            infoHeader.equipment?.hooked_to;

          const shouldConfirm =
            infoHeader?.status?.id === 1 && infoHeader?.dispatch_type === 'external_carrier' && !item.checkInConfirmed;
          if (shouldConfirm) {
            setOpenConfirmCarrierCheckin(item);
            return;
          }

          if (dropTrailerRequired && !infoHeader?.brokerage_dispatch?.carrier_id) {
            setOpenRestrictedCheckIn(true);
            return;
          }

          setTableShipmentModalObj({ item, rowData: infoHeader });
          break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 13:
        case 43:
        case 61:
        case 97:
          setTableShipmentModalObj({ item, rowData: infoHeader });
          break;
        case 8:
        case 11:
        case 12:
        case 14:
        case 24:
          setTableShipmentModalObj({ item, rowData: infoHeader });
          break;
        case 19:
          setTableShipmentModalObj({ item, rowData: [infoHeader] });
          break;
        case 112:
          getUnReserveData({ item });
          break;
        case 17:
          setTableShipmentModalObj({ item, rowData: [infoHeader] });
          break;
        case 44:
          setOpenRequestTracking(true);
          break;
        case 45:
          setOpenStopTracking(true);
          break;
        case 20:
          navigate(`/planner/plan?id=${infoHeader.shipment_id}&action=dispatch`);
          break;
        case 21:
          navigate(`/planner/plan?id=${infoHeader.shipment_id}&action=offer`);
          break;
        case 22:
          revertOffers();
          break;
        case 23:
          revertDispatch();
          break;
        default:
      }
    }
  }

  const stopSharing = () => {
    UpdateShare(id, undefined)
      .then(() => {
        showToaster({ type: 'success', message: 'Sharing stopped successfully!' });
        getHeaderInfo();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const stopCellularTracking = async () => {
    try {
      const body = {
        customer_dot: user?.customer?.dot,
        carrier_id: infoHeader?.brokerage_dispatch?.carrier_id,
        shipment_id: id,
        status: 'stopped',
      };
      await updateCellularTracking(body);
      setOpenStopTracking(false);
      getHeaderInfo();
      showToaster({ type: 'success', message: 'Tracking has been stopped!' });
    } catch (e) {
      showToaster({ type: 'success', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  function onSubmitShipmentRowEditChildes(data) {
    const ids = data?.lastData?.item.id;
    const { res } = data;
    switch (+ids) {
      case 2:
      case 61:
        getHeaderInfo();
        break;
      case 12:
        !!res?.data && setReserveResponse(res?.data);
        setInfoHeader({});
        getHeaderInfo();
        break;
      case 5:
        setTableShipmentModalObj({});
        getHeaderInfo();
        break;
      case 6:
        getHeaderInfo();
        break;
      case 11:
        const queryParams = new URLSearchParams();
        res?.forEach((shipmentId) => {
          queryParams.append('id', shipmentId);
        });
        navigate(`/planner?${queryParams.toString()}`);
        break;
      case 13:
        navigate(`/shipment/${id}`, { state: { defaultTab: 6 } });
        break;
      case 14:
        getHeaderInfo();
        break;
      case 43:
        getHeaderInfo();
        break;
      case 112:
        setInfoHeader({});
        getHeaderInfo();
        break;
      case 8:
      case 24:
        navigate('/planner');
        break;
      case 7:
        UpdateShare(id, res.customer || undefined)
          .then((result) => {
            showToaster({ type: 'success', message: result.data?.message || 'Success!' });
            getHeaderInfo();
          })
          .catch((err) => {
            if (err.response) {
              showToaster({ type: 'error', message: err.response.data.error });
            }
          });
        break;
      case 17:
        navigate('/shipments');
        break;
      case 19:
        navigate('/planner', { state: { allValue: data.lastData.rowData[0].shipment_id } });
        break;
      case 97:
        getHeaderInfo();
        break;
      default:
    }
  }

  const currentTab = useMemo(() => {
    switch (+activeTabDetails.id) {
      case 1:
        return (
          <OverView
            infoHeader={infoHeader}
            getHeaderInfo={getHeaderInfo}
            isShipmentTable={isShipmentTable}
            onCheckInOutClick={() => onClickHeaderOverLayOption({ id: 2, name: 'Check In/Out' })}
            onDryRunClick={() => onClickHeaderOverLayOption({ id: 5, name: 'Dry Run (TONU)' })}
          />
        );
      case 2:
        return <ShipmentsEvents infoHeader={infoHeader} />;
      case 3:
        return <TrackingHistory GPS={GPS} infoHeader={infoHeader} />;
      case 4:
        return <BillingCharges infoHeader={infoHeader} />;
      case 5:
        return <Documents infoHeader={infoHeader} />;
      case 6:
        return <Notes />;
      case 7:
        return <Detention shipment={infoHeader} />;
      case 8:
        return infoHeader.brokerage_dispatch ? (
          <CarrierPays
            infoHeader={infoHeader}
            getHeaderInfo={getHeaderInfo}
            driverPayRefreshIndex={driverPayRefreshIndex}
          />
        ) : (
          <DriverPays
            infoHeader={infoHeader}
            getHeaderInfo={getHeaderInfo}
            driverPayRefreshIndex={driverPayRefreshIndex}
          />
        );
      case 9:
        return <ElectronicBillOfLading infoHeader={infoHeader} />;
      case 10:
        return <BidHistory shipment={infoHeader} />;
      case 11:
        return <Cargo shipment={infoHeader} />;
      default:
    }
  }, [activeTabDetails, infoHeader, isShipmentTable, GPS]);

  const detentionBox = useMemo(() => {
    if (isShipmentTable) {
      const { customer_contact_book } = infoHeader?.shipment_option || {};
      const { contact_email, contact_name, phone_number, email_updates, sms_updates } = customer_contact_book || {};
      const emailHTML = (
        <div className='d-flex align-items-center'>
          <div style={{ margin: '0 4px' }} className={classes.stopCountWrapper}>
            <Typography variant='overLine'>EMAIL</Typography>
          </div>
          <a href={`mailto:${contact_email}`}>{contact_email}</a>
        </div>
      );

      const smsHTML = (
        <div className='d-flex align-items-center'>
          <div style={{ margin: '0 4px' }} className={classes.stopCountWrapper}>
            <Typography variant='overLine'>SMS</Typography>
          </div>
          <a href={`tel:${phone_number}`}>{phone_number}</a>&nbsp;
        </div>
      );

      const sms = +sms_updates === 1 ? smsHTML : '';
      const email =
        +email_updates === 1 ? (
          +sms_updates === 1 ? (
            <div className='d-flex align-items-center'>and&nbsp;{emailHTML}</div>
          ) : (
            emailHTML
          )
        ) : (
          ''
        );

      if (loadingDetention) {
        return (
          <div style={{ marginTop: 12 }}>
            <Skeleton style={{ width: '35%' }} />
            <Skeleton style={{ width: '25%' }} />
            <Skeleton style={{ width: '35%' }} />
            <Skeleton style={{ width: '25%' }} />
          </div>
        );
      }
      return (
        <div style={{ marginTop: 12 }}>
          {compareTimeDurations(
            getAverageTimeConverted(infoHeader.scheduled_duration),
            sumDurations([
              infoHeader?.deadhead_drive_time,
              infoHeader?.shipment_drive_time,
              ...[
                ...(infoHeader?.shipment_stops || []).map((item) =>
                  getAverageTimeConverted(item.stop_point?.average_waiting_time)
                ),
              ],
            ])
          ) ? (
            <div className={classNames('d-flex align-items-center')}>
              <DetentionIcon style={{ marginRight: 8 }} fill={palette.red400} />
              <Typography as='div' variant='s2' className='d-flex align-items-center'>
                You have scheduled this shipment for pick up on{' '}
                {formatDate(infoHeader?.shipment_stops?.[0]?.scheduled_date)} {infoHeader?.shipment_stops?.[0]?.to} and
                final delivery on{' '}
                {formatDate(
                  infoHeader?.shipment_stops?.[Number(infoHeader?.shipment_stops?.length) - 1]?.scheduled_date
                )}{' '}
                {infoHeader?.shipment_stops?.[Number(infoHeader?.shipment_stops?.length) - 1]?.to} which is total
                scheduled duration {getAverageTimeConverted(infoHeader.scheduled_duration)} but this route requires at
                minimum{' '}
                {sumDurations([
                  infoHeader?.deadhead_drive_time,
                  infoHeader?.shipment_drive_time,
                  ...[
                    ...(infoHeader?.shipment_stops || []).map((item) =>
                      getAverageTimeConverted(item.stop_point?.average_waiting_time)
                    ),
                  ],
                ])}{' '}
                !
              </Typography>
            </div>
          ) : (
            <div />
          )}
          {!!customer_contact_book && (
            <div className={classNames('d-flex align-items-center')}>
              <DetentionIcon style={{ marginRight: 8 }} fill={palette.indigo500} />
              <Typography as='div' variant='s2' className='d-flex align-items-center'>
                Tracking is enabled and sent to {contact_name} {sms} {email}
              </Typography>
              <Typography
                variant='s2'
                onClick={stopSharing}
                style={{ color: palette.red400, textDecoration: 'underline', marginLeft: 12, cursor: 'pointer' }}
              >
                Stop Sharing
              </Typography>
            </div>
          )}
          {detentions?.data?.map((el) => {
            const location_name = el?.shipment_stop?.stop_point?.location_name;
            const { duration_days: days, duration_hours: hours, duration_minutes: minutes } = el || {};
            return (
              <div key={el.id} style={{ cursor: 'default' }} className={classNames('d-flex align-items-center mt-1')}>
                <DetentionIcon style={{ marginRight: 8 }} fill={palette.red400} />
                <Typography variant='s2'>
                  Detention detected for&nbsp;
                  {!!+days && `${days}d `}
                  {(!!+hours || +hours === 0) && `${hours}h `}
                  {(!!+minutes || +minutes === 0) && `${minutes}m `}
                  at {location_name}!
                </Typography>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }, [isShipmentTable, detentions, loadingDetention, use, palette, infoHeader]);

  useEffect(() => {
    isShipmentTable && getDetention({ page: 1, page_size: 500 });
  }, [isShipmentTable]);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const GPS = PusherJs.subscribe(`private-equipmentLocation-Update.${user?.customer?.dot}`);
      setGPS(GPS);
    } catch (e) {
      // Do nothing
    }

    if (rowData?.defaultTab !== undefined) {
      dispatch(onChangeActiveTab(SHIPMENT_DETAILS_TABS.find((item) => item.id === Number(rowData?.defaultTab))));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getHeaderInfo();
    return () => dispatch(onChangeActiveTab(SHIPMENT_DETAILS_TABS[0]));
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Shipment {id || ''} | Truckin Digital</title>
      </Helmet>
      <DetailsHeader
        GPS={GPS}
        user={user}
        data={infoHeader}
        getHeaderInfo={getHeaderInfo}
        isShipmentTable={isShipmentTable}
        refreshDriverPay={() => setDriverPayRefreshIndex(Date.now())}
        onClickOption={onClickHeaderOverLayOption}
      >
        {detentionBox}
      </DetailsHeader>
      {currentTab}
      {isShipmentTable ? (
        <TableShipmentRowEditChildes
          data={tableShipmentModalObj}
          onSubmit={onSubmitShipmentRowEditChildes}
          getShipment={getHeaderInfo}
        />
      ) : (
        <TablePlannerRowEditChildes
          data={tableShipmentModalObj}
          onSubmit={onSubmitShipmentRowEditChildes}
          unReserveEndPointData={unReserveEndPointData}
          getShipment={getHeaderInfo}
        />
      )}
      {openRestrictedCheckIn && (
        <RestrictedCheckIn
          open={openRestrictedCheckIn}
          onClose={() => setOpenRestrictedCheckIn(false)}
          shipment={infoHeader}
          onSuccess={getHeaderInfo}
        />
      )}
      {openRequestTracking && (
        <RequestCellularTracking
          open={openRequestTracking}
          onClose={() => setOpenRequestTracking(false)}
          shipment={infoHeader}
          onSuccess={getHeaderInfo}
        />
      )}
      {openStopTracking && (
        <ConfirmModal
          open={openStopTracking}
          title='Stop Cellular Tracking'
          text={`Are you sure you want to stop cellular tracking for shipment ${id}?`}
          btnText='Stop Tracking'
          onClose={() => setOpenStopTracking(false)}
          onConfirm={stopCellularTracking}
        />
      )}
      {!!openConfirmCarrierCheckin && (
        <ConfirmModal
          open={!!openConfirmCarrierCheckin}
          title='Warning!'
          width='560px'
          text={
            <div>
              <Typography variant='s1' as='p' style={{ color: palette.gray700 }}>
                The shipment has not yet been confirmed by {infoHeader?.brokerage_dispatch?.carrier?.name}.
              </Typography>
              <Typography variant='s1' as='p' style={{ color: palette.gray700 }}>
                Are you sure you want to proceed without a signed confirmation?
              </Typography>
            </div>
          }
          btnType='primary'
          btnText='Proceed'
          onClose={() => setOpenConfirmCarrierCheckin(null)}
          onConfirm={() => {
            onClickHeaderOverLayOption({ ...openConfirmCarrierCheckin, checkInConfirmed: true });
            setOpenConfirmCarrierCheckin(null);
          }}
        />
      )}
    </div>
  );
};

export default ShipmentsDetails;
