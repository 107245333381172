import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import './AddDriverStyle.css';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import CustomButton from 'components/CustomButton/CustomButton';
import AddUpdatePrevEmployment from 'componentsV2/PreviousEmployment/components/AddUpdatePrevEmployment';
import { addEmploymentDataConverter } from 'componentsV2/PreviousEmployment/components/AddUpdateModal/AddUpdateModal.data';
import { saveFinishLater } from 'Api/DriverV2';
import { createPrevEmployment, getPrevEmployment, updatePrevEmployment } from 'Api/PreviousEmployment';

const PreviousEmployment = ({
  nextStep,
  showError,
  prevStep,
  onSkip,
  Cancel,
  stepsDone,
  setStepsDone,
  driverId,
  previousEmployment,
  draftDriver,
  getDrivers,
}) => {
  const [onNextLoading, setOnNextLoading] = useState(false);

  const onNextStep = async (values) => {
    try {
      setOnNextLoading(true);
      const promises = values.map((employer) => {
        const formData = addEmploymentDataConverter(employer, 'driver', driverId);
        if (employer.id) {
          return updatePrevEmployment(formData);
        }
        return createPrevEmployment(formData);
      });
      await Promise.all(promises);

      const { data } = await getPrevEmployment({
        id: driverId,
        userType: 'driver',
        params: {
          page: 1,
          itemsPerPage: 10000,
        },
      });

      nextStep(data, null, `Previous Employment has been ${stepsDone ? 'updated' : 'created'}`);
    } catch (e) {
      showError(getErrorMessage(e));
    } finally {
      setOnNextLoading(false);
    }
  };

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isMedicalBackground: true,
    }));
    prevStep();
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 3,
    };

    saveFinishLater(driverId, body).then(() => {
      getDrivers();
      Cancel();
    });
  };

  return (
    <>
      <Modal.Body style={{ backgroundColor: palette.white }} className='add-driver-modal-body driver-style-wrap'>
        <AddUpdatePrevEmployment
          previousEmployments={previousEmployment}
          onSave={onNextStep}
          userType='driver'
          userId={driverId}
        />
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: palette.white, borderColor: palette.gray50 }}>
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            <Button className='cancel-button ms-0' onClick={onSkip}>
              Skip
            </Button>
            {onNextLoading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <CustomButton
                title='Next Step'
                type='primary'
                disabled={onNextLoading}
                buttonProps={{ type: 'submit', form: 'previous-employment-form', onClick: undefined }}
                styleTitle={{ fontSize: 12, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: '0 16px 0 0' }}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default PreviousEmployment;
