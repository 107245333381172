import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'common/Modal';
import useForm from 'hooks/useForm';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import Input, { InputWithIcon } from 'common/Input';
import { getErrorMessage } from 'utils/error';
import { getAccountsList } from 'Api/Accounts';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { validationSchema } from './validationSchema';

import UserInfo from '../../../../../components/UserInfo';
import { getInitialValues } from './MarkPaid.data';
import { STable, STableRow } from '../../PayrollAndSettlements.styles';
import { markSettlementPaid } from '../../../../../Api/Payroll';
import { paymentMethods } from '../../../../Accounting/Payables/components/MarkPaid/MarkPaid.data';

const MarkPaid = ({ open, onClose, onSuccess, settlements }) => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = { settlements: [] };
      values.forEach((formValue, index) => {
        body.settlements.push({
          id: settlements?.[index]?.id,
          reference: values[index].reference,
          paid_amount: Number(formValue.paid_amount),
          paid_by_account_id: Number(formValue.paid_by_account.id),
          payment_method: values[index].payment_method?.value,
        });
      });
      const { data } = await markSettlementPaid(body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(settlements),
    onSubmit,
    validationSchema,
  });

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.account_type.id === ACCOUNT_TYPE.BANK));
    } catch (e) {
      // Do nothing
    }
  };

  const totalPaid = useMemo(() => {
    return values.reduce((acc, cur) => acc + Number(cur.paid_amount) || 0, 0);
  }, [values]);

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      $width='55vw'
      $maxWidth='55vw'
      $minWidth='900px'
      headerTitle='Mark Paid'
      $bgColor={palette.white}
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Mark Paid',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        <STable>
          <thead>
            <tr className='nowrap'>
              <th>Name</th>
              <th>Amount</th>
              <th>Reference</th>
              <th>Method</th>
              <th>Funding Account</th>
            </tr>
          </thead>
          <tbody>
            {values.map((settlement, index) => {
              const row = settlements?.[index];
              return (
                <STableRow key={`${settlement?.id || index}`}>
                  <td>
                    {row?.user ? (
                      <UserInfo
                        size='32px'
                        statusSize='7px'
                        data={{
                          ...(row.user || {}),
                          name: `${row.user.fname || row.user.first_name} ${row.user.lname || row.user.last_name}`,
                          image: row.user.image || row.user.profile_logo,
                          status: row.user?.status_data?.driver_status || row.user?.status_data?.id || row.user.status,
                          user_type: row.user_type === 'App\\Driver' ? 'driver' : 'staff',
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <InputWithIcon
                      width='200px'
                      type='number'
                      name='paid_amount'
                      placeholder='0.00'
                      value={settlement.paid_amount}
                      onKeyDown={blockNonPositiveNumbers}
                      error={touchedErrors[index]?.paid_amount}
                      onChange={(e) => handleChange(`${index}.paid_amount`, e.target.value)}
                    />
                  </td>
                  <td>
                    <Input
                      required
                      name={`${index}.reference`}
                      value={values?.[index].reference}
                      error={touchedErrors?.[index]?.reference}
                      style={{ width: '200px' }}
                      onChange={(e) => handleChange(`${index}.reference`, e.target.value)}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      width='180px'
                      name='payment_method'
                      options={paymentMethods}
                      value={values?.[index].payment_method}
                      onChange={(e, val) => handleChange(`${index}.payment_method`, val)}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      error={touchedErrors[index]?.payment_method}
                    />
                  </td>
                  <td>
                    <div>
                      <Autocomplete
                        width='200px'
                        name='paid_by_account'
                        labelKey='account_name'
                        options={accounts}
                        value={settlement?.paid_by_account}
                        onChange={(e, val) => handleChange(`${index}.paid_by_account`, val)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        error={touchedErrors[index]?.paid_by_account}
                      />
                    </div>
                  </td>
                </STableRow>
              );
            })}
          </tbody>
        </STable>
        <div style={{ marginLeft: -16 }} className='w-100 d-flex align-items-center justify-content-end gap-4 mt-3'>
          <Typography variant='s2' style={{ color: palette.gray900 }}>
            Total
          </Typography>
          <Typography variant='button2' style={{ color: palette.gray900 }}>
            {currency}
            {formatNumber(totalPaid)}
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default MarkPaid;
