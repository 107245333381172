import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/sidemanu/edit.svg';
import HeaderStar from 'components/HeaderStar';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getTabDepartments, getUserTabSettings } from 'Api/Dashboard';
import { useAuth } from 'context/auth.context';
import EditWidgets from './EditWidgets';
import { dashboardWidgets, updateWidgets } from './Dashboard.data';
import { SWidgetContainer, SHeaderWrapper, SPageWrapper } from './Dashboard.styles';

const Widgets = () => {
  const [widgets, setWidgets] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const { value: userData } = useAuth();
  const [filteredWidgets, initialEditWidgets] = useMemo(() => {
    return [
      widgets.map((item) => ({
        ...item,
        widgets: item.widgets.filter(
          (widget) =>
            widget.active &&
            widget.departments.some((i) => i.department_name === userData.user?.department?.department_name)
        ),
      })),
      widgets.reduce((acc, cur) => {
        const current = {
          ...cur,
          widgets: cur.widgets.filter((widget) =>
            widget.departments.some((i) => i.department_name === userData.user?.department?.department_name)
          ),
        };

        if (current.widgets.length) {
          acc.push(current);
        }

        return acc;
      }, []),
    ];
  }, [widgets, userData.user?.department?.department_name]);

  const getUserWidgets = async () => {
    try {
      const { data } = await getUserTabSettings();
      const { data: widgetsDepartments } = await getTabDepartments();
      const departments = widgetsDepartments.map((item) => {
        return {
          ...item,
          dashboard_tab_permission: (item.dashboard_tab_permission || [])
            .filter((i) => !!i?.department?.department_name)
            .map((i) => i.department),
        };
      });
      const sortedWidgets = updateWidgets(dashboardWidgets, data?.length ? data : dashboardWidgets, departments);
      setWidgets(sortedWidgets);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getUserWidgets();
  }, []);

  return (
    <SPageWrapper>
      <SHeaderWrapper className={`${isEdit ? 'mb-0' : ''}`}>
        <HeaderStar title='Dashboard' />
        <div className='d-flex align-items-center gap-3'>
          {!isEdit && (
            <CustomButton
              width={12}
              height={12}
              type='secondary'
              title='Edit Dashboard'
              leftIcon={<EditIcon style={{ marginRight: 10 }} />}
              styleButton={{ padding: '6px 12px' }}
              onClick={() => setIsEdit(true)}
            />
          )}
        </div>
      </SHeaderWrapper>
      <div className='page-content'>
        {!isEdit ? (
          filteredWidgets.map(
            (widget) =>
              !!widget.widgets?.length && (
                <div key={widget.id} className='d-flex flex-column gap-2'>
                  {widget.title && (
                    <div className='d-flex align-items-center justify-content-between'>
                      <Typography variant='h5' style={{ color: palette.gray900 }}>
                        {widget.title}
                      </Typography>
                      {widget.actionButtons}
                    </div>
                  )}
                  <div className='d-flex flex-wrap w-100'>
                    {widget.widgets.map((subWidget) => {
                      return (
                        <SWidgetContainer className={`w-${subWidget.width}`} key={subWidget.id}>
                          {subWidget.component}
                        </SWidgetContainer>
                      );
                    })}
                  </div>
                </div>
              )
          )
        ) : (
          <EditWidgets
            initialWidgets={initialEditWidgets}
            onClose={() => setIsEdit(false)}
            onSuccess={getUserWidgets}
          />
        )}
      </div>
    </SPageWrapper>
  );
};

export default Widgets;
