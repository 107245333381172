import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';
import { exportInCSV } from 'pages/DotMonitoring/StatsSection/components/IncidentSummary/IncidentSummary.data';
import { getIncidentSummary } from 'Api/Dashboard';
import { useColumns } from './IncidentSummary.data';

const IncidentSummary = () => {
  const [loading, setLoading] = useState(false);
  const [countsData, setCountsData] = useState({});
  const [year, setYear] = useState(moment().format('YYYY'));
  const [month, setMonth] = useState(moment().format('MMM'));
  const [anchorTag, setAnchorTag] = useState('');

  const getCounts = async () => {
    try {
      setLoading(true);
      let dateSpan = {};

      if (month) {
        const monthYear = moment(`${month} ${year}`, 'MMM YYYY');
        const startOfMonth = monthYear.startOf('month').format('MM/DD/YYYY');
        const endOfMonth = monthYear.endOf('month').format('MM/DD/YYYY');
        dateSpan = { start: startOfMonth, end: endOfMonth };
      } else {
        const wholeYear = moment(year, 'YYYY');
        const startOfYear = wholeYear.startOf('year').format('MM/DD/YYYY');
        const endOfYear = wholeYear.endOf('year').format('MM/DD/YYYY');
        dateSpan = { start: startOfYear, end: endOfYear };
      }

      const { data } = await getIncidentSummary({
        from_date: dateSpan.start,
        to_date: dateSpan.end,
      });
      setCountsData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (countsData?.incident_type?.length) {
      exportInCSV(countsData.incident_type, setAnchorTag);
    }
  }, [countsData?.incident_type]);

  useEffect(() => {
    getCounts();
  }, [month, year]);

  const columns = useColumns();

  return (
    <div className='box-content-wrapper'>
      <SBoxContent style={{ rowGap: '8px', maxHeight: '450px', minHeight: '300px', overflowY: 'scroll' }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
              Incident Summary
            </Typography>
            <span className='receivable-chart-section-tag-indigo' style={{ margin: 0 }}>
              {countsData?.total_incident}
            </span>
          </div>
          <div className='d-flex align-items-center gap-2 mt-1'>
            <Autocomplete
              size='small'
              width='75px'
              name='month'
              placeholder='Month'
              options={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
              value={month}
              onChange={(e, val) => setMonth(val)}
              disableClearable={false}
            />
            <Autocomplete
              size='small'
              width='75px'
              name='year'
              placeholder='Year'
              options={['2023', '2024']}
              value={year}
              onChange={(e, val) => setYear(val)}
            />
          </div>
          <a
            href={anchorTag}
            download='incident_summary.csv'
            className={`export-csv-btn ${!anchorTag ? 'non-clickable' : ''}`}
          >
            <ExportIcon fill={palette.indigo500} />
            <span>CSV</span>
          </a>
        </div>
        {!loading && !!countsData && (
          <MaterialTableWrapper
            data={countsData?.incident_type}
            style={{ backgroundColor: palette.white }}
            columns={columns}
          />
        )}
      </SBoxContent>
    </div>
  );
};

export default IncidentSummary;
