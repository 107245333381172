import React, { useEffect, useState } from 'react';
import './GeneralInformation.css';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import button from 'assets/icons/drivers/button.svg';
import close from 'assets/icons/drivers/close.svg';
import editIcon from 'assets/icons/drivers/edit-white-icon.png';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { palette, TRANSMISSION_TYPE } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import Chip from 'common/Chip';
import Radio from 'common/Radio';
import ViewPdf from 'components/ViewPdf';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { uploadDriverDocument } from 'Api/Driver';
import useFlag from 'assets/icons/drivers/usaFlag.svg';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import maxico from 'assets/icons/drivers/mexico.png';
import fast from 'assets/icons/drivers/fast.svg';
import tsa from 'assets/icons/drivers/tsa.svg';
import twic from 'assets/icons/drivers/twic.svg';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import upload from 'assets/icons/drivers/upload.png';
import profile from 'assets/icons/drivers/user-profile.svg';
import useDateFormat from 'hooks/useDateFormat';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { blockNonPositiveNumbers, formatPhoneNumber } from 'utils/helpers';
import { getApplicant } from 'Api/Applicants';
import { getCountries } from 'Api/JobPositions';
import Input, { InputWithText } from 'common/Input';
import { getEquipmentTypes } from 'Api/Equipment';
import AddressFields from 'common/AddressFields';
import useForm from 'hooks/useForm';
import ViewApplicantModal from 'pages/ApplicantsAndReferrals/components/ViewApplicantModal';
import ImageDropZone from '../ImgageDropZone';
import { validationSchema } from './validationSchema';

const whitelist = ['US', 'CA', 'MX'];

const GeneralInformation = ({ updateGeneralProfile, driverInfo, onSuccess, loading, setGenerateIdModalShow }) => {
  const { value: userData } = useAuth();
  const { formatDate } = useDateFormat();
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [toggle, setToggle] = useState(false);
  const [citizenships, setCitizenships] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [trailerTypes, setTrailerTypes] = useState([]);
  const [customName, setCustomName] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const saveGeneral = (values) => {
    const {
      country_data,
      can_travel_canada,
      can_travel_mexico,
      can_travel_usa,
      state_data,
      driver_city,
      city_data,
      driver_state,
      driver_country,
      citizenship_data,
      citizenship,
      has_twic,
      has_fast,
      has_hazmat,
      dob,
      has_tsa,
      custom_name,
      allowed_vehicle_types,
      allowed_trailer_types,
    } = values;
    const payload = {
      ...driverInfo,
      ...values,
      dob: dob ? moment(dob).format('YYYY-MM-DD') : null,
      driver_city: city_data?.id || driver_city,
      driver_state: state_data?.id || driver_state,
      driver_country: country_data?.id || driver_country,
      citizenship: citizenship_data?.id || citizenship,
      allowed_vehicle_types: allowed_vehicle_types.map((i) => i.id),
      allowed_trailer_types: allowed_vehicle_types.some((i) => i.id === 1)
        ? allowed_trailer_types.map((i) => i.id)
        : [],
    };

    payload.certifications = {
      ...driverInfo.certifications,
      has_twic,
      has_fast,
      has_tsa,
      has_hazmat,
      custom_name: custom_name.toString(),
      can_travel_canada,
      can_travel_mexico,
      can_travel_usa,
    };

    if (payload?.application_upload && payload?.application_upload.includes('https')) {
      delete payload.application_upload;
    }
    if (payload?.driver_licence_upload && payload?.driver_licence_upload.includes('https')) {
      delete payload.driver_licence_upload;
    }
    if (payload?.driver_licence_back_upload && payload?.driver_licence_back_upload.includes('https')) {
      delete payload.driver_licence_back_upload;
    }
    updateGeneralProfile(payload, null, successResponse, 'Driver has been updated successfully');
  };

  const formik = useForm({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone_number: '',
      dob: new Date(),
      application_upload: '',
      driver_address: '',
      driver_address2: '',
      driver_country: null,
      driver_state: null,
      driver_city: null,
      driver_zip: '',
      country_data: null,
      state_data: null,
      city_data: null,
      citizenship: null,
      citizenship_data: null,
      passport: '',
      driver_id: '',
      can_travel_canada: false,
      can_travel_mexico: false,
      can_travel_usa: false,
      custom_name: [],
      has_twic: false,
      has_fast: false,
      has_tsa: false,
      has_hazmat: false,
      has_custom: false,
      emergency_contact_first_name: '',
      emergency_contact_last_name: '',
      emergency_contact_number: '',
      years_experience: '',
      transmission_type: null,
      transmission_speed: null,
      allowed_vehicle_types: [],
      allowed_trailer_types: [],
    },
    onSubmit: saveGeneral,
    validationSchema,
  });

  const { values, handleChange, touchedErrors } = formik;

  const getEquipmentType = async () => {
    try {
      const { data } = await getEquipmentTypes({}, userData.user?.customer?.dot);
      setVehicleTypes(data.filter((i) => i.vehicle_type_id === 1));
      setTrailerTypes(data.filter((i) => i.vehicle_type_id === 2));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    formik.setFieldValue('fname', driverInfo?.fname);
    formik.setFieldValue('lname', driverInfo?.lname);
    formik.setFieldValue('emergency_contact_first_name', driverInfo?.emergency_contact_first_name);
    formik.setFieldValue('emergency_contact_last_name', driverInfo?.emergency_contact_last_name);
    formik.setFieldValue('email', driverInfo?.email);
    formik.setFieldValue('phone_number', driverInfo?.phone_number);
    formik.setFieldValue('emergency_contact_number', driverInfo?.emergency_contact_number);
    formik.setFieldValue('passport', driverInfo?.passport);
    formik.setFieldValue('can_travel_canada', driverInfo?.certifications?.can_travel_canada);
    formik.setFieldValue('can_travel_mexico', driverInfo?.certifications?.can_travel_mexico);
    formik.setFieldValue('can_travel_usa', driverInfo?.certifications?.can_travel_usa);
    formik.setFieldValue('citizenship', driverInfo?.citizenship);
    formik.setFieldValue('dob', driverInfo?.dob ? moment(driverInfo?.dob).toDate() : new Date());
    formik.setFieldValue('driver_address', driverInfo?.driver_address);
    formik.setFieldValue('driver_address2', driverInfo?.driver_address2);
    formik.setFieldValue('driver_country', driverInfo?.driver_country);
    formik.setFieldValue('driver_state', driverInfo?.driver_state);
    formik.setFieldValue('driver_city', driverInfo?.driver_city);
    formik.setFieldValue('citizenship_data', driverInfo?.citizenship_data || null);
    formik.setFieldValue('city_data', driverInfo?.city_data);
    formik.setFieldValue('country_data', driverInfo?.country_data);
    formik.setFieldValue('state_data', driverInfo?.state_data);
    formik.setFieldValue('driver_zip', driverInfo?.driver_zip);
    formik.setFieldValue('driver_id', driverInfo?.driver_id);
    formik.setFieldValue('application_upload', driverInfo?.application_upload);
    formik.setFieldValue('years_experience', driverInfo?.years_experience || '');
    formik.setFieldValue(
      'has_twic',
      driverInfo?.certifications?.has_twic === 1 ? true : driverInfo?.certifications?.has_twic
    );
    formik.setFieldValue(
      'has_fast',
      driverInfo?.certifications?.has_fast === 1 ? true : driverInfo?.certifications?.has_fast
    );
    formik.setFieldValue(
      'has_tsa',
      driverInfo?.certifications?.has_tsa === 1 ? true : driverInfo?.certifications?.has_tsa
    );
    formik.setFieldValue(
      'has_hazmat',
      driverInfo?.certifications?.has_hazmat === 1 ? true : driverInfo?.certifications?.has_hazmat
    );
    formik.setFieldValue('transmission_type', driverInfo?.transmission_type || null);
    formik.setFieldValue('transmission_speed', driverInfo?.transmission_speed || '');
    if (vehicleTypes.length && driverInfo?.allowed_vehicle_types?.length) {
      formik.setFieldValue(
        'allowed_vehicle_types',
        driverInfo?.allowed_vehicle_types?.map((i) => vehicleTypes.find((item) => item.id === i)).filter((i) => !!i)
      );
    }

    if (trailerTypes.length && driverInfo?.allowed_trailer_types?.length) {
      formik.setFieldValue(
        'allowed_trailer_types',
        driverInfo?.allowed_trailer_types?.map((i) => trailerTypes.find((item) => item.id === i)).filter((i) => !!i)
      );
    }

    if (driverInfo?.certifications?.custom_name) {
      const customsName = driverInfo?.certifications?.custom_name.split(',');
      formik.setFieldValue('custom_name', customsName);
    }
  }, [driverInfo, vehicleTypes, trailerTypes]);

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], driverInfo?.id).then((res) => {
      formik.setFieldValue(propertyName, res.data.document_path);
    });
  };

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const onSelectCitizen = (value) => {
    formik.setFieldValue('citizenship_data', value);

    if (value?.id === 231) {
      formik.setFieldValue('can_travel_usa', true);
    }
  };

  const getAllCountries = async () => {
    try {
      const { data } = await getCountries();
      setCitizenships(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getApplicantById = async () => {
    try {
      const response = await getApplicant(driverInfo?.application_id);
      setSelectedApplicant(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleVehiclesChange = (e, value, reason, details) => {
    if (details?.option?.title === 'Select All') {
      formik.setFieldValue(
        'allowed_vehicle_types',
        formik.values.allowed_vehicle_types.length === vehicleTypes.length ? [] : vehicleTypes
      );
      return;
    }
    formik.setFieldValue('allowed_vehicle_types', value);
  };

  const handleTrailersChange = (e, value, reason, details) => {
    if (details?.option?.title === 'Select All') {
      formik.setFieldValue(
        'allowed_trailer_types',
        formik.values.allowed_trailer_types.length === trailerTypes.length ? [] : trailerTypes
      );
      return;
    }
    formik.setFieldValue('allowed_trailer_types', value);
  };

  const handleAddressChange = (event, value) => {
    const addressFieldsMapper = {
      address: 'driver_address',
      address2: 'driver_address2',
      city: 'city_data',
      state: 'state_data',
      country: 'country_data',
      zipcode: 'driver_zip',
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  useEffect(() => {
    getAllCountries();
    getEquipmentType();
  }, []);

  return (
    <div
      className='generalInformation driver-style-wrap'
      style={{
        backgroundColor: palette.white,
        borderColor: palette.gray50,
      }}
    >
      <div className='header' style={{ borderBottomColor: palette.gray50 }}>
        <p className='heading' style={{ color: palette.gray900 }}>
          General Information
        </p>
        {!toggle ? (
          <div>{!hideSensitiveInfo && <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />}</div>
        ) : (
          <div>
            <img src={close} alt='' onClick={() => setToggle(!toggle)} className='tick-close' />
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <img src={button} alt='' onClick={formik.handleSubmit} className='tick-close' />
            )}
          </div>
        )}
      </div>
      <div className='upload-wrapper' style={{ borderBottomColor: palette.gray50 }}>
        {toggle ? (
          <div
            className='license-view pointer'
            onClick={() => setGenerateIdModalShow(true)}
            style={{ backgroundColor: palette.white, position: 'relative' }}
          >
            <div className='horizontal'>
              <div className='horizontal-card-container driver-style-wrap' style={{ borderColor: palette.gray50 }}>
                <div className='id-header' style={{ backgroundColor: palette.indigo500 }}>
                  <div className='header-logoCover'>
                    <p className='company-name'>{userData?.user?.customer?.company_name || ''}</p>
                  </div>
                </div>
                <div className='sub-container'>
                  <div>
                    <img src={driverInfo?.image || profile} alt='driver' className='driver-image' />
                  </div>
                  <div className='driver-details'>
                    <div className='driver-details-basic'>
                      <p className='driver-name' style={{ color: palette.dark900 }}>{`${
                        driverInfo?.first_name || driverInfo?.fname || ''
                      } ${driverInfo?.last_name || driverInfo?.lname || ''}`}</p>
                      <p className='driver-company' style={{ color: palette.gray700 }}>
                        {driverInfo?.app?.driver_type === 'company'
                          ? 'Company Driver'
                          : driverInfo?.app?.driver_type === 'owner'
                          ? 'Sub-Contract Driver'
                          : driverInfo?.title
                          ? driverInfo?.title
                          : ''}{' '}
                      </p>
                      {driverInfo?.app?.driver_type === 'owner' ? (
                        <p className='owner-type' style={{ color: palette.dark900 }}>
                          {driverInfo?.app?.operator?.owner_operator_name || ''}
                        </p>
                      ) : null}
                    </div>
                    <div className='driver-details-advance'>
                      <p className='driver-number' style={{ color: palette.dark900 }}>
                        {driverInfo?.driver_id
                          ? driverInfo?.driver_id
                          : driverInfo?.certifications?.driver_id
                          ? driverInfo?.certifications?.driver_id
                          : driverInfo?.id}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='license-view-edit-icon'>
              <img src={editIcon} alt='editIcon' className='license-image' />
            </div>
          </div>
        ) : (
          <div
            className='upload-document-info'
            style={{
              backgroundColor: palette.gray50,
              borderColor: palette.gray50,
            }}
          >
            <div className='horizontal'>
              <div className='horizontal-card-container driver-style-wrap' style={{ borderColor: palette.gray50 }}>
                <div className='id-header' style={{ backgroundColor: palette.indigo500 }}>
                  <div className='header-logoCover'>
                    <p className='company-name'>{userData?.user?.customer?.company_name || ''}</p>
                  </div>
                </div>
                <div className='sub-container'>
                  <div>
                    <img src={driverInfo?.image || profile} alt='driver' className='driver-image' />
                  </div>
                  <div className='driver-details'>
                    <div className='driver-details-basic'>
                      <p className='driver-name' style={{ color: palette.dark900 }}>{`${
                        driverInfo?.first_name || driverInfo?.fname || ''
                      } ${driverInfo?.last_name || driverInfo?.lname || ''}`}</p>
                      <p className='driver-company' style={{ color: palette.gray700 }}>
                        {driverInfo?.app?.driver_type === 'company'
                          ? 'Company Driver'
                          : driverInfo?.app?.driver_type === 'owner'
                          ? 'Sub-Contract Driver'
                          : driverInfo?.title
                          ? driverInfo?.title
                          : ''}{' '}
                      </p>
                      {driverInfo?.app?.driver_type === 'owner' ? (
                        <p className='owner-type' style={{ color: palette.dark900 }}>
                          {driverInfo?.app?.operator?.owner_operator_name || ''}
                        </p>
                      ) : null}
                    </div>
                    <div className='driver-details-advance'>
                      <p className='driver-number' style={{ color: palette.dark900 }}>
                        {driverInfo?.driver_id
                          ? driverInfo?.driver_id
                          : driverInfo?.certifications?.driver_id
                          ? driverInfo?.certifications?.driver_id
                          : driverInfo?.id}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {toggle ? (
        <div className='information-edit-mode' style={{ backgroundColor: palette.white }}>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text col-6 m-0' style={{ color: palette.gray400 }}>
                First Name
              </p>
            </div>
            <div className='col-6'>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.dark900,
                }}
                className='input-type'
                name='fname'
                id='fname'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.fname}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text col-6 m-0' style={{ color: palette.gray400 }}>
                Last Name
              </p>
            </div>
            <div className='col-6'>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.dark900,
                }}
                className='input-type'
                name='lname'
                id='lname'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lname}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Phone Number
              </p>
            </div>
            <div className='col-6'>
              <PhoneInput
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                }}
                addInternationalOption={false}
                className='input-type'
                value={formik.values.phone_number}
                countries={whitelist}
                onChange={(val) => formik.setFieldValue('phone_number', val)}
                defaultCountry='US'
                placeholder='(555) 555-1234'
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Email
              </p>
            </div>
            <div className='col-6'>
              <input
                className='input-type'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.dark900,
                }}
                type='text'
                name='email'
                id='email'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Date of Birth
              </p>
            </div>
            <div className='col-6'>
              <DatePicker
                size='small'
                name='dob'
                value={formik.values.dob}
                onChange={(date) => formik.setFieldValue('dob', date)}
                onBlur={formik.handleBlur}
                disableFuture
                error={formik.touched.dob && formik.errors.dob}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Driver Experience
              </p>
            </div>
            <div className='col-6'>
              <InputWithText
                type='number'
                size='small'
                text='years'
                name='years_experience'
                bgColor={palette.gray50}
                value={formik.values.years_experience}
                onChange={(e) => formik.setFieldValue('years_experience', e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text col-6 m-0' style={{ color: palette.gray400 }}>
                Emergency Contact First Name
              </p>
            </div>
            <div className='col-6'>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.dark900,
                }}
                className='input-type'
                name='emergency_contact_first_name'
                id='emergency_contact_first_name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.emergency_contact_first_name}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text col-6 m-0' style={{ color: palette.gray400 }}>
                Emergency Contact Last Name
              </p>
            </div>
            <div className='col-6'>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.dark900,
                }}
                className='input-type'
                name='emergency_contact_last_name'
                id='emergency_contact_last_name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.emergency_contact_last_name}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Emergency Contact Number
              </p>
            </div>
            <div className='col-6'>
              <PhoneInput
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                }}
                addInternationalOption={false}
                className='input-type'
                value={formik.values.emergency_contact_number}
                countries={whitelist}
                onChange={(val) => {
                  formik.setFieldValue('emergency_contact_number', val || '');
                }}
                defaultCountry='US'
                placeholder='(555) 555-1234'
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Application
              </p>
            </div>
            {driverInfo?.application_id ? (
              <div className='col-6'>
                <p className='value contact view-DOCS' style={{ color: palette.indigo500 }} onClick={getApplicantById}>
                  View
                </p>
              </div>
            ) : (
              <div className='col-6'>
                {formik?.values?.application_upload ? (
                  <div className='application'>
                    <p className='attachment-wrapper' style={{ backgroundColor: '#f0f1fa' }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => formik.setFieldValue('application_upload', null)}
                      />
                    </p>
                  </div>
                ) : (
                  <div className='add-employer' style={{ position: 'relative', alignItems: 'center' }}>
                    <img src={upload} alt='upload' />
                    <button className='add-employer-button'>Upload Application</button>
                    <ImageDropZone
                      onDrop={(file) => uploadDocument(file, 'application_upload')}
                      width='100%'
                      height='100%'
                      name='small'
                      deletable={false}
                      customStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                      accept={['application/pdf', 'image/jpeg', 'image/png']}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='col-12 d-flex information-edit-mode-sections' style={{ borderBottomColor: palette.gray50 }}>
            <div className='col-6'>
              <p className='table-data-text property' style={{ color: palette.gray400 }}>
                Address
              </p>
            </div>
            <div className='col-6'>
              <AddressFields
                size='small'
                hideLabel
                isOneColumn
                values={{
                  address: values.driver_address,
                  address2: values.driver_address2,
                  city: values.city_data,
                  state: values.state_data,
                  country: values.country_data,
                  zipcode: values.driver_zip,
                }}
                handleChange={handleAddressChange}
                touchedErrors={touchedErrors}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50, color: palette.dark900 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Citizenship
              </p>
            </div>
            <div className='col-6'>
              <Autocomplete
                size='small'
                name='citizenship_data'
                options={citizenships}
                value={formik.values.citizenship_data}
                onChange={(e, val) => onSelectCitizen(val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Passport #
              </p>
            </div>
            <div className='col-6'>
              <input
                className='input-type'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.dark900,
                }}
                type='text'
                name='passport'
                id='passport'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.passport}
              />
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Can Travel To
              </p>
            </div>
            <div className='col-6 d-flex can-travel-wrap'>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  type='checkbox'
                  name='can_travel_usa'
                  id='can_travel_usa'
                  value={formik.values.can_travel_usa}
                  checked={formik.values.can_travel_usa}
                  onChange={() => formik.setFieldValue('can_travel_usa', !formik.values.can_travel_usa)}
                />
                <span className='information-edit-checkbox-title' style={{ color: palette.dark900 }}>
                  USA
                </span>
              </div>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  type='checkbox'
                  name='can_travel_canada'
                  id='can_travel_canada'
                  value={formik.values.can_travel_canada}
                  checked={formik.values.can_travel_canada}
                  onChange={() => formik.setFieldValue('can_travel_canada', !formik.values.can_travel_canada)}
                />
                <span className='information-edit-checkbox-title' style={{ color: palette.dark900 }}>
                  Canada
                </span>
              </div>
              <div className='d-flex align-items-center  can-travel-wrap-sub'>
                <input
                  type='checkbox'
                  name='can_travel_mexico'
                  id='can_travel_mexico'
                  value={formik.values.can_travel_mexico}
                  checked={formik.values.can_travel_mexico}
                  onChange={() => formik.setFieldValue('can_travel_mexico', !formik.values.can_travel_mexico)}
                />
                <span className='information-edit-checkbox-title' style={{ color: palette.dark900 }}>
                  Mexico
                </span>
              </div>
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Approved for:
              </p>
            </div>
            <div className='col-6 d-flex can-approved-wrap' style={{ marginTop: '4px' }}>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_fast'
                  id='has_fast'
                  value={formik.values.has_fast}
                  checked={formik.values.has_fast}
                  onChange={() => formik.setFieldValue('has_fast', !formik.values.has_fast)}
                />
                <label className='text-style' htmlFor='flexCheckDefault' style={{ color: palette.gray700 }}>
                  FAST
                </label>
              </div>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_tsa'
                  id='has_tsa'
                  onChange={() => formik.setFieldValue('has_tsa', !formik.values.has_tsa)}
                  value={formik.values.has_tsa}
                  checked={formik.values.has_tsa}
                />
                <label className='text-style' htmlFor='flexCheckDefault' style={{ color: palette.gray700 }}>
                  TSA
                </label>
              </div>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_twic'
                  id='has_twic'
                  onChange={() => formik.setFieldValue('has_twic', !formik.values.has_twic)}
                  value={formik.values.has_twic}
                  checked={formik.values.has_twic}
                />
                <label className=' text-style' htmlFor='flexCheckDefault' style={{ color: palette.gray700 }}>
                  TWIC
                </label>
              </div>
              <div className='d-flex align-items-center can-travel-wrap-sub'>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_hazmat'
                  id='has_hazmat'
                  onChange={() => formik.setFieldValue('has_hazmat', !formik.values.has_hazmat)}
                  value={formik.values.has_hazmat}
                  checked={formik.values.has_hazmat}
                />
                <label className=' text-style' htmlFor='flexCheckDefault' style={{ color: palette.gray700 }}>
                  HAZMAT
                </label>
              </div>
              <div className='d-flex align-items-center flex-wrap'>
                <div className='d-flex align-items-center flex-wrap'>
                  {formik.values.custom_name.map((val, index) => (
                    <span className='d-flex justify-content-center align-items-center' key={`${val + index}`}>
                      <input
                        className='check-input'
                        type='checkbox'
                        name={val}
                        id={val}
                        checked
                        onChange={() => {
                          const temp = formik?.values?.custom_name || [];
                          temp?.splice(index, 1);
                          formik.setFieldValue('custom_name', [...(temp || [])]);
                        }}
                      />
                      <label className='text-style' htmlFor='flexCheckDefault' style={{ color: palette.gray700 }}>
                        {val}
                      </label>
                    </span>
                  ))}
                </div>
                <input
                  className='check-input'
                  type='checkbox'
                  name='has_custom'
                  id='has_custom'
                  value={formik.values.has_custom}
                  checked={formik.values.has_custom}
                  onChange={formik.handleChange}
                />
                <label className='text-style' htmlFor='flexCheckDefault' style={{ color: palette.gray700 }}>
                  Add Custom:
                </label>
                {formik.values.has_custom && (
                  <div className='d-flex align-items-center gap-2'>
                    <div
                      className='custume'
                      style={{
                        backgroundColor: palette.white,
                        borderColor: palette.gray50,
                        width: '140px',
                      }}
                    >
                      <input
                        type='text'
                        maxLength='4'
                        className='input-field'
                        placeholder='4 characters max'
                        name='custom_name'
                        id='custom_name'
                        onChange={(event) => setCustomName(event.target.value)}
                        value={customName}
                        style={{
                          backgroundColor: palette.white,
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            formik.setFieldValue('custom_name', [...formik.values.custom_name, customName]);
                            setCustomName('');
                          }
                        }}
                      />
                    </div>
                    {!!customName.length && (
                      <CheckIcon
                        className='pointer'
                        width={18}
                        height={18}
                        onClick={() => {
                          formik.setFieldValue('custom_name', [...formik.values.custom_name, customName]);
                          setCustomName('');
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Vehicle Transmission Type
              </p>
            </div>
            <div className='col-6 d-flex can-travel-wrap'>
              <div className='d-flex flex-wrap gap-2 align-items-center'>
                <Radio
                  id='automatic'
                  name='transmission_type'
                  label='Automatic'
                  value={1}
                  checked={formik.values.transmission_type === 1}
                  onChange={() => formik.setFieldValue('transmission_type', 1)}
                />
                <Radio
                  id='manual'
                  name='transmission_type'
                  label='Manual'
                  value={2}
                  checked={formik.values.transmission_type === 2}
                  onChange={() => formik.setFieldValue('transmission_type', 2)}
                />
                {formik.values.transmission_type === 2 && (
                  <Input
                    style={{ width: '80px' }}
                    size='small'
                    type='number'
                    name='transmission_speed'
                    placeholder='Speed'
                    value={formik.values.transmission_speed}
                    onChange={(e) => formik.setFieldValue('transmission_speed', e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                )}
                <Radio
                  id='any'
                  name='transmission_type'
                  label='Any'
                  value={3}
                  checked={formik.values.transmission_type === 3}
                  onChange={() => formik.setFieldValue('transmission_type', 3)}
                />
              </div>
            </div>
          </div>
          <div
            className='col-12 d-flex align-items-center information-edit-mode-sections'
            style={{ borderBottomColor: palette.gray50 }}
          >
            <div className='col-6'>
              <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                Allowed Vehicle Types
              </p>
            </div>
            <div className='col-6 d-flex'>
              <Autocomplete
                multiple
                size='small'
                name='allowed_vehicle_types'
                labelKey='title'
                placeholder={formik.values.allowed_vehicle_types.length ? '' : 'Select..'}
                limitTags={1}
                options={[{ id: 'all', title: 'Select All' }, ...vehicleTypes]}
                value={formik.values.allowed_vehicle_types}
                onChange={handleVehiclesChange}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id ||
                  (option.title === 'Select All' && vehicleTypes?.length === formik.values.allowed_vehicle_types.length)
                }
              />
            </div>
          </div>
          {formik.values.allowed_vehicle_types?.some((i) => i.id === 1) && (
            <div
              className='col-12 d-flex align-items-center information-edit-mode-sections'
              style={{ borderBottomColor: palette.gray50 }}
            >
              <div className='col-6'>
                <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                  Allowed Trailer Types
                </p>
              </div>
              <div className='col-6 d-flex'>
                <Autocomplete
                  multiple
                  size='small'
                  name='allowed_trailer_types'
                  placeholder={formik.values.allowed_trailer_types.length ? '' : 'Select..'}
                  labelKey='title'
                  limitTags={1}
                  options={[{ id: 'all', title: 'Select All' }, ...trailerTypes]}
                  value={formik.values.allowed_trailer_types}
                  onChange={handleTrailersChange}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id ||
                    (option.title === 'Select All' &&
                      trailerTypes?.length === formik.values.allowed_trailer_types.length)
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='information'>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Full Name</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.fname} {driverInfo?.lname}
            </p>
          </div>

          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Phone Number</p>
            <div className='value' onClick={(e) => e.stopPropagation()}>
              <Typography variant='b2' className='list-driver-phone'>
                <a href={`tel:${driverInfo?.phone_number}`}>
                  {driverInfo?.phone_number ? formatPhoneNumber(driverInfo.phone_number) : '-'}
                </a>
              </Typography>
            </div>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Email</p>
            <p className='value contact'>{driverInfo?.email}</p>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Date of Birth</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.dob ? formatDate(driverInfo?.dob) : '-'}
            </p>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Emergency Contact Name</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.emergency_contact_first_name} {driverInfo?.emergency_contact_last_name}{' '}
              {!(driverInfo?.emergency_contact_first_name && driverInfo?.emergency_contact_last_name) ? '-' : ''}
            </p>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Driver Experience</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.years_experience
                ? `${driverInfo.years_experience} ${driverInfo.years_experience > 1 ? 'years' : 'year'}`
                : '-'}
            </p>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Emergency Contact Telephone</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.emergency_contact_number || '-'}
            </p>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Application</p>
            {driverInfo?.application_id ? (
              <p className='value contact view-DOCS' onClick={getApplicantById}>
                View
              </p>
            ) : driverInfo?.application_upload ? (
              <p className='value contact view-DOCS' onClick={() => setPdfUrl(driverInfo?.application_upload)}>
                View
              </p>
            ) : (
              <p className='value contact'>-</p>
            )}
          </div>
          <div className='info-container address border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Address</p>
            <div className='address-details'>
              <p className='value' style={{ color: palette.gray900 }}>
                {driverInfo?.driver_address}
              </p>
              <p className='value' style={{ color: palette.gray900 }}>
                {driverInfo?.driver_address2}
              </p>
              <p className='value' style={{ color: palette.gray900 }}>{`${driverInfo?.city_data?.name || ''}${
                driverInfo?.state_data?.name ? ',' : ''
              } ${driverInfo?.state_data?.name || ''}  ${driverInfo?.driver_zip || ''}`}</p>
              <p className='value' style={{ color: palette.gray900 }}>
                {driverInfo?.country_data?.name}
              </p>
            </div>
          </div>
          <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
            <p className='property'>Citizenship</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.citizenship_data?.name ?? driverInfo?.citizenship}
            </p>
          </div>
          {!hideSensitiveInfo && (
            <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
              <p className='property'>Passport #</p>
              <p className='value' style={{ color: palette.gray900 }}>
                {driverInfo?.passport}
              </p>
            </div>
          )}
          <div
            className='info-container h-auto border-block'
            style={{ borderBottomColor: palette.gray50, minHeight: '40px' }}
          >
            <p className='property'>Can Travel To</p>
            <p className='value' style={{ color: palette.gray900 }}>
              {driverInfo?.certifications?.can_travel_usa ? <img src={useFlag} alt='USA-Flag' /> : null}
              {driverInfo?.certifications?.can_travel_mexico ? (
                <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
              ) : null}
              {driverInfo?.certifications?.can_travel_canada ? <img src={turkeyFlag} alt='canada-Flag' /> : null}
            </p>
          </div>
          <div
            className='info-container h-auto border-block'
            style={{ borderBottomColor: palette.gray50, minHeight: '40px' }}
          >
            <p className='property'>Approved for</p>
            <div
              style={{
                width: '60%',
              }}
            >
              <p className='value justify-content-end' style={{ color: palette.gray900 }}>
                {driverInfo?.certifications?.has_tsa ? (
                  <img src={tsa} alt='tsa' className='approvedFor-tsa-icon' />
                ) : null}
                {driverInfo?.certifications?.has_twic ? (
                  <img src={twic} alt='twic' className='approvedFor-twic-icon' />
                ) : null}
                {driverInfo?.certifications?.has_fast ? (
                  <img src={fast} alt='fast' className='approvedFor-fast-icon' />
                ) : null}
                {driverInfo?.certifications?.has_hazmat ? (
                  <Typography variant='c1' style={{ color: palette.orange400 }}>
                    HAZMAT
                  </Typography>
                ) : null}
              </p>
              {driverInfo?.certifications?.custom_name && (
                <div style={{ textAlign: 'right' }}>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {driverInfo.certifications.custom_name.split(',').join(', ')}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div
            className='info-container h-auto border-block'
            style={{ borderBottomColor: palette.gray50, minHeight: '40px' }}
          >
            <p className='property'>Transmission Type</p>
            <div style={{ width: '60%' }} className='d-flex justify-content-end flex-wrap gap-1'>
              <Chip
                label={`${TRANSMISSION_TYPE[driverInfo?.transmission_type]}${
                  driverInfo?.transmission_type === 2 && !!driverInfo?.transmission_speed
                    ? ` ${driverInfo?.transmission_speed} speed`
                    : ''
                }`}
              />
            </div>
          </div>
          <div
            className='info-container h-auto border-block pt-1 pb-1'
            style={{ borderBottomColor: palette.gray50, minHeight: '40px' }}
          >
            <p className='property'>Allowed Vehicle Types</p>
            <div style={{ width: '60%' }} className='d-flex justify-content-end flex-wrap gap-1'>
              {formik.values.allowed_vehicle_types?.length === vehicleTypes.length ? (
                <Chip label='Any' />
              ) : (
                formik.values.allowed_vehicle_types?.map((i) => <Chip label={i.title} key={i.id} />)
              )}
            </div>
          </div>
          <div
            className='info-container h-auto border-block pt-1 pb-1'
            style={{ borderBottomColor: palette.gray50, minHeight: '40px' }}
          >
            <p className='property'>Allowed Trailer Types</p>
            <div style={{ width: '60%' }} className='d-flex justify-content-end flex-wrap gap-1'>
              {formik.values.allowed_trailer_types?.length === trailerTypes.length ? (
                <Chip label='Any' />
              ) : (
                formik.values.allowed_trailer_types?.map((i) => <Chip label={i.title} key={i.id} />)
              )}
            </div>
          </div>
        </div>
      )}
      <ViewPdf open={!!pdfUrl} title='View Document' pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
      {!!selectedApplicant && (
        <ViewApplicantModal applicant={selectedApplicant} onClose={() => setSelectedApplicant(null)} isDriverProfile />
      )}
    </div>
  );
};

export default GeneralInformation;
