import React from 'react';
import { useNavigate } from 'react-router-dom';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { RECURRING_LANE_STATUS } from 'components/TablePlaner/constats';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import useDateFormat from 'hooks/useDateFormat';
import { CURRENCY, formatNumber, palette, STOP_POINT_TAB } from 'utils/constants';
import classes from 'components/TableShipments/tableShipments.module.css';
import hexRgb from 'hex-rgb';

export const tabs = [
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'ended',
    label: 'Ended',
  },
  {
    key: 'cancelled',
    label: 'Canceled',
  },
];

export const locationFilters = {
  origin_city: '',
  origin_state: '',
  destination_city: '',
  destination_state: '',
  scheduled: [null, null],
};

const planAheadType = {
  1: 'Create to Planner',
  2: 'Auto Dispatch to Driver',
  3: 'Auto Dispatch to Carrier',
  4: 'Auto Offer',
};

export const useColumns = ({ sort, sortingQuery, equipmentTypeText }) => {
  const navigate = useNavigate();
  const { formatDate } = useDateFormat();

  const onCustomerClick = (e, billing) => {
    e.stopPropagation();
    navigate(`/customer-profile/${billing?.billing_customer?.id}`);
  };

  return [
    {
      field: 'name',
      title: <ColumnHeader title='LANE NAME' field='name' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {row.shipment_lane.name}
        </Typography>
      ),
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' sort={sort} onClick={sortingQuery} />,
      render: (row) =>
        row.shipment_type !== 'ltl' || (row.shipment_type === 'ltl' && row.shipment_billing?.length === 1) ? (
          <Typography
            variant='s2'
            style={{ color: palette.gray700 }}
            onClick={(e) => onCustomerClick(e, row.shipment_billing[0])}
          >
            {row.shipment_billing[0]?.billing_customer?.company_name || '-'}
          </Typography>
        ) : (
          <OverLayTriggerHoverWrapper
            overlay={row.shipment_billing?.map((el) => {
              return (
                <Typography
                  as='p'
                  key={el?.id}
                  variant='s2'
                  style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                  onClick={(e) => onCustomerClick(e, el)}
                >
                  {el?.billing_customer?.company_name}
                  {el?.billing_customer?.customer_id ? ` - ${el.billing_customer.customer_id} ` : ''}&nbsp;
                </Typography>
              );
            })}
          >
            <div className={classes.multi}>
              <Typography as='div' variant='overLine2'>
                Multi-Customer
              </Typography>
              <div className={classes.verticalLine} />
            </div>
          </OverLayTriggerHoverWrapper>
        ),
    },
    {
      field: 'type',
      title: <ColumnHeader title='TYPE' field='type' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const typeLength = row.shipment_stops[0]?.equipment_type_length_value?.length;
        const equipmentType = row.shipment_stops[0]?.equipment_type_data?.title;
        const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;
        const firstNullArrivalDate = row.shipment_stops.find((stop) => !stop.arrival_date);

        return (
          <div className='d-flex flex-column'>
            <span style={{ color: palette.dark800 }}>{type}</span>
            <Typography variant='s3' style={{ color: palette.dark800 }}>
              {!firstNullArrivalDate?.stop_point_type
                ? '-'
                : equipmentTypeText({
                    type: firstNullArrivalDate.stop_point_type,
                    action: firstNullArrivalDate.equipment_action,
                    equipment: firstNullArrivalDate.equipment,
                    equipment_tbd: firstNullArrivalDate.equipment_tbd,
                  })}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'amount',
      title: <ColumnHeader title='AMOUNT' field='amount' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        let totalAmount = 0;

        row?.shipment_billing?.forEach((el) => {
          totalAmount += Number(el?.total_amount);
        });
        const formattedAmount = formatNumber(totalAmount);
        const currency = Object.values(CURRENCY).find(
          (item) => item.id === Number(row?.shipment_billing[0]?.currency_id)
        );

        return (
          <Typography variant='s3' style={{ color: palette.dark800 }}>
            {currency?.symbol || '$'}
            {formattedAmount}
          </Typography>
        );
      },
    },
    {
      field: 'start_date',
      title: <ColumnHeader title='START DATE' field='start_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {formatDate(row.shipment_lane?.start_date)}
        </Typography>
      ),
    },
    {
      field: 'end_date',
      title: <ColumnHeader title='END DATE' field='end_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {formatDate(row.shipment_lane?.end_date)}
        </Typography>
      ),
    },
    {
      field: 'groups',
      title: <ColumnHeader title='TAGS' field='groups' />,
      render: (row) => {
        const shipmentBilling = row.shipment_billing?.filter((i) => !!i.groups);

        return shipmentBilling?.map((el, i) => {
          const colorCode = hexRgb(el.groups.color);
          const textColor =
            colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? palette.white : palette.gray900;
          return (
            <div key={el?.id || i} className='flex-row d-flex table_planer_groups_wrapper'>
              <div
                className={`${classes.shortName}`}
                style={{
                  color: textColor,
                  backgroundColor: el?.groups?.color,
                }}
              >
                {el?.groups?.short_name}
              </div>
            </div>
          );
        });
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Chip
          label={row.shipment_lane.status?.toUpperCase()}
          labelColor={RECURRING_LANE_STATUS[row.shipment_lane.status]?.color}
          bgColor={RECURRING_LANE_STATUS[row.shipment_lane.status]?.bgColor}
          fontSize='12px'
          fontWeight={500}
        />
      ),
    },
    {
      field: 'action',
      title: <ColumnHeader title='ACTION' field='action' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {planAheadType[row.shipment_lane?.plan_ahead_type || '1']}
        </Typography>
      ),
    },
    {
      field: 'shipments_created',
      title: (
        <ColumnHeader title='SHIPMENTS CREATED' field='shipments_created' sort={sort} sortingQuery={sortingQuery} />
      ),
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {row.shipment_lane.shipments_created} out of {row.shipment_lane.shipments_count}
        </Typography>
      ),
    },
    {
      field: 'origin',
      title: <ColumnHeader title='ORIGIN' field='origin' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const originStopName = row.shipment_stops[0]?.stop_point?.location_name;
        const city = row.shipment_stops[0]?.stop_point?.city?.name;
        const id = row.shipment_stops[0]?.stop_point?.id;
        const state = row.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.zipcode;

        return (
          <>
            <Typography
              variant='b3'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {originStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
          </>
        );
      },
    },
    {
      field: 'destination',
      title: <ColumnHeader title='DESTINATION' field='destination' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const destinationStopName = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = row.shipment_stops[row.shipment_stops.length - 1]?.stop_point?.id;

        return (
          <>
            <Typography
              variant='b3'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {destinationStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
          </>
        );
      },
    },
    {
      field: 'miles',
      title: <ColumnHeader title='MILES' field='miles' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {Math.round(row.loaded_miles)}
        </Typography>
      ),
    },
  ];
};
