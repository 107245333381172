import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import right from 'assets/icons/drivers/right.svg';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { getShipmentType } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette, statusColor } from 'utils/constants';
import { findNextStop } from 'pages/Accounting/Receivables/TableSection/TableSection.data';
import DriversPopup from '../shared/DriversPopup';
import RowActions from '../shared/RowActions';

export const useColumns = ({ onUploadDocuments, onViewNotes, sort, sortingQuery }) => {
  const navigate = useNavigate();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='s2'>{row.shipment?.shipment_id}</Typography>
            {!!row.shipment?.shipment_notes?.length && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onViewNotes(row);
                }}
              >
                <NotesIcon width={14} height={14} />
              </div>
            )}
            {(!!row.shipment?.is_edi_api || !!row.shipment?.is_edi_api) && <CloudIcon fill={palette.green400} />}
          </div>
        );
      },
    },
    {
      field: 'reference_id',
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.reference_id}
        </Typography>
      ),
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.billing_customer.company_name}
        </Typography>
      ),
    },
    {
      field: 'shipment_type',
      title: <ColumnHeader title='SHIPMENT TYPE' field='shipment_type' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {getShipmentType(row.shipment?.shipment_type)}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      title: <ColumnHeader title='EQUIPMENT' field='equipment' />,
      render: (row) =>
        row.shipment?.brokerage_dispatch || row.shipment?.equipment ? (
          <>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.shipment?.brokerage_dispatch
                ? row.shipment?.brokerage_dispatch?.vehicle_id
                : row.shipment?.equipment?.equipment_id}
              &nbsp;
            </Typography>
            <Typography variant='overLine' style={{ color: palette.gray500, textTransform: 'uppercase' }}>
              {row.shipment?.brokerage_dispatch
                ? row.shipment?.brokerage_dispatch?.vehicle_type?.title
                : row.shipment?.equipment?.equipment_type?.title}
            </Typography>
            {(!!row.shipment?.last_hooked_equipment || !!row.shipment?.brokerage_dispatch?.trailer_type) && (
              <>
                <TwoWaysIcon style={{ margin: '0 8px' }} />
                <Typography variant='overLine2' style={{ textTransform: 'uppercase' }}>
                  {row.shipment?.last_hooked_equipment?.equipment_id || row.shipment?.brokerage_dispatch?.trailer_id}
                  &nbsp;
                  <Typography variant='overLine'>
                    {row.shipment?.last_hooked_equipment?.length?.length ||
                      row.shipment?.brokerage_dispatch?.trailer_length?.length}
                    -
                    {row.shipment?.last_hooked_equipment?.equipment_type?.title ||
                      row.shipment?.brokerage_dispatch?.trailer_type?.title}
                  </Typography>
                </Typography>
              </>
            )}
          </>
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        ),
    },
    {
      field: 'driver',
      title: <ColumnHeader title='DRIVER' field='driver' />,
      render: (row) => {
        const driver1 = row?.shipment?.assigned_to_1;
        const driver2 = row?.shipment?.assigned_to_2;

        return row.shipment?.brokerage_dispatch || driver1 ? (
          <div>
            {row.shipment?.brokerage_dispatch ? (
              <div className='mb-2'>
                {row.shipment.brokerage_dispatch.driver_name && (
                  <div className='details-container details-container--shipment-audit details-container---staff'>
                    <div className='image-container' style={{ width: '30px', height: '30px' }}>
                      <img
                        src={userDefault}
                        className='user-image'
                        style={{ width: '30px', height: '30px' }}
                        alt='driver'
                      />
                    </div>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {row.shipment.brokerage_dispatch.driver_name}
                    </Typography>
                  </div>
                )}
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {row.shipment.brokerage_dispatch.carrier?.name}
                </Typography>
              </div>
            ) : (
              <div>
                {row.shipment?.shipment_type !== 'split-parent' && driver1 && (
                  <div className='details-container details-container--shipment-audit details-container---staff'>
                    <div className='image-container' style={{ width: '36px', height: '36px' }}>
                      <img
                        src={driver1.image ? driver1.image : userDefault}
                        className='user-image'
                        style={{ width: '36px', height: '36px' }}
                        alt='driver'
                      />
                      <div
                        className='user-active-indicator'
                        style={{
                          backgroundColor: statusColor[driver1.status_data?.driver_status || 'Logged Off']?.color,
                        }}
                      />
                    </div>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver1.fname || ''} {driver1.lname || ''}
                    </Typography>
                  </div>
                )}
                {row.shipment?.shipment_type !== 'split-parent' && driver2 && (
                  <div className='details-container details-container--shipment-audit details-container---staff'>
                    <div className='image-container' style={{ width: '36px', height: '36px' }}>
                      <img
                        src={driver2.image ? driver2.image : userDefault}
                        className='user-image'
                        style={{ width: '36px', height: '36px' }}
                        alt='driver'
                      />
                      <div
                        className='user-active-indicator'
                        style={{
                          backgroundColor: statusColor[driver2.status_data?.driver_status || 'Logged Off']?.color,
                        }}
                      />
                    </div>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver2.fname || ''} {driver2.lname || ''}
                    </Typography>
                  </div>
                )}
                {row.shipment?.shipment_type === 'split-parent' && (
                  <DriversPopup shipmentId={row.shipment_id} masterShipmentDriver={row.shipment?.assigned_to_1} />
                )}
              </div>
            )}
          </div>
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'pw',
      title: <ColumnHeader title='PW' field='pw' />,
      render: (row) => (row.paperwork_received ? <img src={right} alt='' /> : <DeleteIcon fill={palette.red500} />),
    },
    {
      field: 'stops',
      title: <ColumnHeader title='STOPS' field='stops' />,
      render: (row) => {
        const count = findNextStop(row.shipment);

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {count} of {row.shipment?.stops_count}
          </Typography>
        );
      },
    },
    {
      field: 'origin',
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      render: (row) => {
        const city = row.origin_stop?.stop_point?.city?.name || '';
        const state = row.origin_stop?.stop_point?.state?.short_name || '';
        const zip = row.origin_stop?.stop_point?.zipcode || '';

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {city ? `${city},` : ''} {state} {zip}
          </Typography>
        );
      },
    },
    {
      field: 'destination',
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      render: (row) => {
        const city = row.destination_stop?.stop_point?.city?.name || '';
        const state = row.destination_stop?.stop_point?.state?.short_name || '';
        const zip = row.destination_stop?.stop_point?.zipcode || '';

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {city ? `${city},` : ''} {state} {zip}
          </Typography>
        );
      },
    },
    {
      field: 'end_date_time',
      title: <ColumnHeader title='COMPLETE DATE' field='end_date_time' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.shipment?.end_date_time ? formatDate(row.shipment.end_date_time) : '-'}
        </Typography>
      ),
    },
    {
      field: 'miles',
      title: <ColumnHeader title='MILES' field='miles' />,
      render: (row) => {
        const loaded = row.shipment?.loaded_miles ? Math.round(row.shipment.loaded_miles) : 0;
        const empty = row.shipment?.empty_miles ? Math.round(row.shipment.empty_miles) : 0;
        const total = loaded + empty;

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {total}
          </Typography>
        );
      },
    },
    {
      field: 'total_amount',
      title: <ColumnHeader title='AMOUNT' field='total_amount' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.total_amount)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      title: <ColumnHeader title='' />,
      render: (row) => (
        <RowActions
          row={row}
          onEditCharges
          onViewShipment={() => navigate(`/shipment/${row?.shipment?.shipment_id}`)}
          onViewNotes={() => onViewNotes(row)}
          onUploadDocuments={() => onUploadDocuments(row)}
        />
      ),
    },
  ];
};
