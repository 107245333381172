import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const InfoItem = ({ title, titleProps, content = '', children }) => {
  return (
    <div className='d-flex flex-column align-items-start gap-1'>
      <Typography variant='b2' style={{ color: palette.gray500 }} {...titleProps}>
        {title}
      </Typography>
      {children || (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {content}
        </Typography>
      )}
    </div>
  );
};

export default InfoItem;
