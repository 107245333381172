import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import classNames from 'classnames';
import useShowToaster from 'hooks/useShowToaster';
import {
  CreateSettingMobileApps,
  GetDepartments,
  GetSettingMobileApps,
  GetSettingsAlerts,
  UpdateSettingsAlert,
} from 'Api/CompanySettings';
import { useTheme } from 'context/themeContext';
import { debounce, palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import { ALERT_TYPE } from 'componentsV2/AlertCenter/TakeActions/TakeActions.data';
import styles from './Alerts.module.css';

const Alerts = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const [alerts, setAlerts] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [tabData, setTabData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const getAlertsData = () => {
    return GetSettingsAlerts().then((res) => {
      setAlerts(res.data);
    });
  };

  const getDepartments = () => {
    return GetDepartments().then((res) => {
      const selectData = res?.data.map((el) => {
        return {
          label: el?.department_name,
          value: el?.id,
        };
      });
      setDepartments(selectData);
    });
  };

  const onClickTab = (item) => {
    setActiveTab(item?.id);
    setTabData(alerts[activeTab]);
  };

  useEffect(() => {
    getDepartments().then();
  }, []);

  useEffect(() => {
    getAlertsData().then();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [activeTab]);

  useEffect(() => {
    const index = alerts?.findIndex((i) => i.id === activeTab);
    if (alerts && index !== -1 && alerts[index]) {
      const data = alerts[index];
      setTabData(data);
    }
  }, [alerts, activeTab]);

  const initialValue = useMemo(() => {
    const initial = tabData?.alerts.map((el) => {
      const department_id = el?.linked_department?.map((i) => i?.department_id);
      return {
        department_id: department_id || [],
        alert_status: Number(el?.alert_status === 1),
        alert_name: el?.alert_name,
        alert_trigger: el?.alert_trigger,
        id: el?.id,
      };
    });
    return {
      rows: initial ? [...initial] : [],
    };
  }, [tabData?.alerts]);

  return (
    <Formik onSubmit={() => {}} enableReinitialize initialValues={{ ...initialValue }}>
      {({ validateField, getFieldHelpers, ...props }) => {
        return (
          <div className={styles.headerWrapper}>
            <div className={styles.alerts_header}>
              <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
                Alerts
              </Typography>
              <div className={styles.actions}>
                {isConfiguringStep && (
                  <>
                    {' '}
                    <CustomButton
                      onClick={onCancelPrevPage}
                      type='secondary'
                      title='Back'
                      styleButton={{ margin: 0 }}
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                    />
                    <CustomButton
                      onClick={onSaveAndNext}
                      type='secondary'
                      title='Skip'
                      styleButton={{ margin: 0 }}
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                    />
                    <CustomButton
                      onClick={onSaveAndNext}
                      type='primary'
                      title={isConfiguringStep ? 'Save and Next' : 'Save'}
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                      styleButton={{ margin: 0 }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={`${styles.tabs_wrapper} ${styles.horizontal_tabs}`}>
              {alerts?.map((e) => {
                return (
                  <div
                    key={e.id}
                    onClick={() => onClickTab(e)}
                    className={classNames([styles.tab, activeTab === e?.id && styles.active_tab])}
                  >
                    <span className={classNames([styles.title, activeTab === e?.id && styles.title_white])}>
                      {e?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className={styles.subtitle_wrapper}>
              <div className={styles.width30}>
                <Typography variant='overLine'>NAME</Typography>
              </div>
              <div className={styles.width35}>
                <Typography variant='overLine'>ALERT TRIGGER</Typography>
              </div>
              <div className={styles.width35}>
                <Typography variant='overLine'>DEPARTMENT</Typography>
              </div>
            </div>
            <AlertsForm
              {...props}
              validateField={validateField}
              getFieldHelpers={getFieldHelpers}
              alerts={alerts}
              departments={departments}
              tabData={tabData}
              activeTab={activeTab}
              onClickTab={onClickTab}
            />
          </div>
        );
      }}
    </Formik>
  );
};

function AlertsForm({ values, setFieldValue, departments, tabData, activeTab }) {
  const showToaster = useShowToaster();
  const [mobileApps, setMobileApps] = useState(null);
  const [alertValues, setAlertValues] = useState({});

  const putSettingsAlert = ({ id, body }) => {
    UpdateSettingsAlert({ id, body })
      .then((r) => r)
      .catch(() => {
        // Do nothing
      });
  };

  const getMobileApps = () => {
    return GetSettingMobileApps().then((res) => {
      setMobileApps(res?.data);
    });
  };

  const onClockOutChange = async (field, value) => {
    setMobileApps((prevState) => ({ ...prevState, [field]: value }));
    try {
      await CreateSettingMobileApps({
        moving_two_hr_n: Number(mobileApps?.moving_two_hr_n),
        moment_deducted_hr: mobileApps?.moment_deducted_hr,
        moment_deducted_min: mobileApps?.moment_deducted_min,
        clock_out_notify_min: mobileApps?.clock_out_notify_min,
        [field]: value,
      });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onChangeMultiSelect = (val, formItems, activeData) => {
    const body = {
      id: activeData?.id,
      alert_name: activeData?.alert_name,
      alert_trigger: activeData?.alert_trigger,
      alert_status: Number(formItems?.alert_status),
      linked_department: [],
      alert_other_data: {
        other_data: {},
      },
    };
    val.forEach((department_id) => body.linked_department.push({ department_id }));

    if (alertValues[activeData?.id]) {
      switch (activeData?.alert_name) {
        case ALERT_TYPE.DRIVER_NOT_MOVING:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.hours = alertValues[activeData.id][0];
          }
          if (alertValues[activeData.id][1]) {
            body.alert_other_data.other_data.minutes = alertValues[activeData.id][1];
          }
          break;
        case ALERT_TYPE.VEHICLE_UNAUTHORIZED_MOVE:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.miles = alertValues[activeData.id][0];
          }
          break;
        case ALERT_TYPE.EQUIPMENT_AT_LOCATION_DURATION:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.days = alertValues[activeData.id][0];
          }
          if (alertValues[activeData.id][1]) {
            body.alert_other_data.other_data.hours = alertValues[activeData.id][1];
          }
          break;
        case ALERT_TYPE.ACCOUNT_LOW_BALANCE_THRESHOLD:
        case ALERT_TYPE.TRANSACTION_OVER_AMOUNT:
        case ALERT_TYPE.CARD_HOLDER_BALANCE_THRESHOLD:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.amount = alertValues[activeData.id][0];
          }
          break;
        case ALERT_TYPE.AGING_IS_OVER:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.days = alertValues[activeData.id][0];
          }
          break;
        case ALERT_TYPE.RATING_IS_LOWER:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.rating = alertValues[activeData.id][0];
          }
          break;
        default:
          break;
      }
    }

    putSettingsAlert({ id: activeData?.id, body });
  };

  const onChangeCheckBox = (val, formItems, activeData) => {
    const body = {
      id: activeData?.id,
      alert_name: activeData?.alert_name,
      alert_trigger: activeData?.alert_trigger,
      alert_status: +val,
      linked_department: [],
      alert_other_data: {
        other_data: {},
      },
    };
    formItems?.department_id?.forEach((department_id) => body.linked_department.push({ department_id }));

    if (alertValues[activeData?.id]) {
      switch (activeData?.alert_name) {
        case ALERT_TYPE.DRIVER_NOT_MOVING:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.hours = alertValues[activeData.id][0];
          }
          if (alertValues[activeData.id][1]) {
            body.alert_other_data.other_data.minutes = alertValues[activeData.id][1];
          }
          break;
        case ALERT_TYPE.VEHICLE_UNAUTHORIZED_MOVE:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.miles = alertValues[activeData.id][0];
          }
          break;
        case ALERT_TYPE.EQUIPMENT_AT_LOCATION_DURATION:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.days = alertValues[activeData.id][0];
          }
          if (alertValues[activeData.id][1]) {
            body.alert_other_data.other_data.hours = alertValues[activeData.id][1];
          }
          break;
        case ALERT_TYPE.ACCOUNT_LOW_BALANCE_THRESHOLD:
        case ALERT_TYPE.TRANSACTION_OVER_AMOUNT:
        case ALERT_TYPE.CARD_HOLDER_BALANCE_THRESHOLD:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.amount = alertValues[activeData.id][0];
          }
          break;
        case ALERT_TYPE.AGING_IS_OVER:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.days = alertValues[activeData.id][0];
          }
          break;
        case ALERT_TYPE.RATING_IS_LOWER:
          if (alertValues[activeData.id][0]) {
            body.alert_other_data.other_data.rating = alertValues[activeData.id][0];
          }
          break;
        default:
          break;
      }
    }

    putSettingsAlert({ id: activeData?.id, body });
  };

  const debounceInputs = useMemo(() => {
    return debounce(({ id, body }) => {
      putSettingsAlert({ id, body });
    }, 400);
  }, []);

  const onAlertChange = (alert, index, value, activeData) => {
    let currentAlertValues = alertValues[alert?.id];
    if (!Array.isArray(currentAlertValues)) {
      currentAlertValues = [];
    }
    currentAlertValues[index] = value.toString();
    setAlertValues((prevValue) => {
      const newValues = { ...prevValue };
      if (!Array.isArray(newValues[alert?.id])) {
        newValues[alert?.id] = [];
      }
      newValues[alert?.id][index] = value;
      return newValues;
    });
    const body = {
      id: alert?.id,
      alert_name: alert?.alert_name,
      alert_trigger: alert?.alert_trigger,
      alert_status: Number(alert?.alert_status),
      linked_department: [],
      alert_other_data: {
        other_data: {},
      },
    };
    switch (activeData?.alert_name) {
      case ALERT_TYPE.DRIVER_NOT_MOVING:
        if (currentAlertValues[0]) {
          body.alert_other_data.other_data.hours = currentAlertValues[0];
        }
        if (currentAlertValues[1]) {
          body.alert_other_data.other_data.minutes = currentAlertValues[1];
        }
        break;
      case ALERT_TYPE.VEHICLE_UNAUTHORIZED_MOVE:
        if (currentAlertValues[0]) {
          body.alert_other_data.other_data.miles = currentAlertValues[0];
        }
        break;
      case ALERT_TYPE.EQUIPMENT_AT_LOCATION_DURATION:
        if (currentAlertValues[0]) {
          body.alert_other_data.other_data.days = currentAlertValues[0];
        }
        if (currentAlertValues[1]) {
          body.alert_other_data.other_data.hours = currentAlertValues[1];
        }
        break;
      case ALERT_TYPE.ACCOUNT_LOW_BALANCE_THRESHOLD:
      case ALERT_TYPE.TRANSACTION_OVER_AMOUNT:
      case ALERT_TYPE.CARD_HOLDER_BALANCE_THRESHOLD:
        if (currentAlertValues[0]) {
          body.alert_other_data.other_data.amount = currentAlertValues[0];
        }
        break;
      case ALERT_TYPE.AGING_IS_OVER:
        if (currentAlertValues[0]) {
          body.alert_other_data.other_data.days = currentAlertValues[0];
        }
        break;
      case ALERT_TYPE.RATING_IS_LOWER:
        if (currentAlertValues[0]) {
          body.alert_other_data.other_data.rating = currentAlertValues[0];
        }
        break;
      default:
        break;
    }

    alert?.department_id?.forEach((department_id) => body.linked_department.push({ department_id }));
    debounceInputs({ id: alert?.id, body });
  };

  const renderItemTrigger = useCallback(
    (item, activeData) => {
      let index = 0;
      const arr = item?.alert_trigger?.split(' ').map((e) => {
        if (e === '____') {
          const currentIndex = index;
          index++;
          return (
            <>
              <input
                className={styles.counts_input}
                type='number'
                value={alertValues?.[item?.id]?.[currentIndex]}
                disabled={!item?.alert_status}
                style={{
                  width: 50,
                  textAlign: 'center',
                  borderBottom: '1px solid rgb(70, 79, 96)',
                  borderTop: '1px solid transparent',
                  borderLeft: '1px solid transparent',
                  borderRight: '1px solid transparent',
                }}
                onChange={(e) => onAlertChange(item, currentIndex, e.target.value, activeData)}
              />{' '}
            </>
          );
        }
        return `${e} `;
      });
      return arr;
    },
    [alertValues]
  );

  useEffect(() => {
    setAlertValues(() => {
      const newValues = {};
      tabData?.alerts?.forEach((el) => {
        if (!!el.hasOwnProperty('alert_other_data') && !!el?.alert_other_data?.other_data) {
          const obj = JSON.parse(el?.alert_other_data?.other_data);
          newValues[el?.id] = Object.values(obj || {});
        }
      });
      return newValues;
    });
  }, [tabData]);

  useEffect(() => {
    if (activeTab === 2) {
      getMobileApps();
    }
  }, [activeTab]);

  return (
    <Form>
      <div className={styles.alerts_wrapper}>
        <div className={styles.wrapper}>
          <FieldArray
            name='rows'
            render={() => {
              return (
                <>
                  {values?.rows?.map((item, idx) => {
                    const namePrefix = `rows[${idx}]`;
                    const activeData = tabData?.alerts?.find((el) => Number(el?.id) === Number(item?.id));
                    return (
                      <div key={item.id} className={styles.row}>
                        <div className={styles.width30}>
                          <label className={styles.switch_label}>
                            <CustomCheckbox
                              name={`${namePrefix}.alert_status`}
                              onChange={(v) => {
                                setFieldValue(`${namePrefix}.alert_status`, Number(v));
                                onChangeCheckBox(v, item, activeData);
                              }}
                              type='switch'
                              smail={false}
                              checked={!!values.rows?.[idx]?.alert_status}
                            />
                            <Typography variant='s2'>{item?.alert_name}</Typography>
                          </label>
                        </div>
                        <div className={styles.width35}>
                          <Typography variant='overLine2'>{renderItemTrigger(item, activeData)}</Typography>
                        </div>
                        <div className={styles.width35}>
                          <Field
                            name={`${namePrefix}.department_id`}
                            isMulti
                            component={MultiSelect}
                            options={departments}
                            className={classNames(styles.multiselect, { [styles.disabled]: !item?.alert_status })}
                            classContainer={styles.multiselectContainer}
                            ss
                            disabled={!item?.alert_status}
                            onChange={(o, v) => onChangeMultiSelect(v, item, activeData)}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {activeTab === 2 && (
                    <div className={styles.row}>
                      <label className={styles.switch_label} style={{ flex: '30%' }}>
                        <CustomCheckbox
                          name='moving_two_hr_n'
                          onChange={(v) => onClockOutChange('moving_two_hr_n', Number(v))}
                          type='switch'
                          smail={false}
                          checked={!!mobileApps?.moving_two_hr_n}
                        />
                        <Typography variant='s2'>Clock Out Driver</Typography>
                      </label>
                      <Typography variant='overLine2' style={{ flex: '35%' }}>
                        If no movement detected for{' '}
                        <input
                          name='moment_deducted_hr'
                          className={styles.counts_input}
                          disabled={!mobileApps?.moving_two_hr_n}
                          type='number'
                          style={{
                            width: 50,
                            textAlign: 'center',
                            borderBottom: '1px solid rgb(70, 79, 96)',
                            borderTop: '1px solid transparent',
                            borderLeft: '1px solid transparent',
                            borderRight: '1px solid transparent',
                          }}
                          onChange={(e) => onClockOutChange('moment_deducted_hr', Number(e.target.value))}
                          value={mobileApps?.moment_deducted_hr || '0'}
                        />{' '}
                        hour(s){' '}
                        <input
                          name='moment_deducted_min'
                          className={styles.counts_input}
                          disabled={!mobileApps?.moving_two_hr_n}
                          type='number'
                          style={{
                            width: 50,
                            textAlign: 'center',
                            borderBottom: '1px solid rgb(70, 79, 96)',
                            borderTop: '1px solid transparent',
                            borderLeft: '1px solid transparent',
                            borderRight: '1px solid transparent',
                          }}
                          onChange={(e) => onClockOutChange('moment_deducted_min', Number(e.target.value))}
                          value={mobileApps?.moment_deducted_min || '0'}
                        />{' '}
                        minute(s) after{' '}
                        <input
                          name='clock_out_notify_min'
                          className={styles.counts_input}
                          disabled={!mobileApps?.moving_two_hr_n}
                          type='number'
                          style={{
                            width: 50,
                            textAlign: 'center',
                            borderBottom: '1px solid rgb(70, 79, 96)',
                            borderTop: '1px solid transparent',
                            borderLeft: '1px solid transparent',
                            borderRight: '1px solid transparent',
                          }}
                          onChange={(e) => onClockOutChange('clock_out_notify_min', Number(e.target.value))}
                          value={mobileApps?.clock_out_notify_min || '0'}
                        />{' '}
                        minute(s) from Notification.
                      </Typography>
                      <div style={{ flex: '35%' }} />
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
    </Form>
  );
}

export default Alerts;
