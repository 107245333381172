export const containTypes = ['description', 'amount'];
export const matchPatterns = ['contains', 'is exact'];

export const getInitialValues = (rule) => {
  return {
    id: rule?.id || Date.now(),
    contains_type: rule?.contains_type || 'description',
    isNew: !rule?.account,
    contains: rule?.contains || [{ value: '', isNew: true }],
    account: rule?.account || null,
    payee: rule?.payee || null,
    match_pattern: rule?.is_exact ? 'is exact' : 'contains',
    memo: rule?.memo || '',
  };
};

export const convertRuleData = (rule, accounts, payees) => {
  return {
    ...rule,
    account: accounts.find((i) => i.id === rule.itemized_as_account_id),
    payee: payees.find((i) => Number(i.id) === Number(rule.payee_id) && i.type === rule.payee_type),
    contains: (rule.contains || '').split(',').map((i) => ({ value: i, isNew: false })),
  };
};
