import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import NoRecords from 'common/NoRecords';
import ColumnHeader from 'common/ColumnHeader';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import TransactionDetails from 'pages/Accounting/Accounts/TransactionDetails';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { deleteBankAccountTransaction, getBankAccountTransactions } from 'Api/Accounts';

import BankAccountFormItem from './BankAccountFormItem';
import { SAddMore, STable, STableHeader, STransactionsTableWrapper } from '../../RightSection.styles';
import ViewPdf from '../../../../../../components/ViewPdf';
import { SEND_PAYMENT_KEYS } from '../../../../../../components/SendPaymentModal/constants';

const BankAccountTransactions = ({
  transactions,
  setTransactions,
  account,
  accounts,
  payees,
  search,
  filters,
  dateRange,
  getPayees,
  onUpdateSuccess,
  onDeleteSuccess,
  refreshIndex,
  refreshAccountsList,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewTransaction, setViewTransaction] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [sort, setSort] = useState({ field: 'date', sortBy: 'desc' });
  const firstTransactionInFuture = transactions.data.find((item) => moment(item.date).isAfter(moment()));

  const getTransactions = async () => {
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page: filters.page,
        itemsPerPage: filters.itemsPerPage,
        account_id: account.id,
        start_date: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        query: search || undefined,
        [sortField]: sort.sortBy,
        id: sort.field === 'date' && sort.sortBy === 'desc' ? 'desc' : undefined,
      };

      const response = await getBankAccountTransactions(params);
      const convertedData = response.data.map((item) => ({
        ...item,
        payment: Math.abs(item.payment),
        payee: (payees || []).find((i) => Number(i.id) === Number(item.payee_id) && i.type === item.payee_type) || null,
        account: (accounts || []).find((acc) => acc.id === Number(item.account)) || null,
      }));

      setTransactions({ ...response, data: convertedData });
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteTransaction = async () => {
    setLoadingDelete(true);
    try {
      await deleteBankAccountTransaction(transactionToDelete.id);
      onDeleteSuccess(transactionToDelete.id);
      setTransactionToDelete(null);
      showToaster({ type: 'success', message: 'Transaction has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onRowClick = (transaction) => {
    if (account.system_account_type !== 2 || !transaction.stripe_synced || !account || !transaction.group_id) {
      return;
    }

    setViewTransaction(transaction);

    // navigate(`/accounting/accounts/${account.id}/transaction/${transaction.group_id}/${account.stripe_account_id}`);
  };

  const onClickRowItem = ({ field, row }) => {
    if (field === 'reference_id') {
      if (row?.pdf_check_file_link) {
        setPdfUrl(row?.pdf_check_file_link);
      } else {
        showToaster({ type: 'warning', message: "Check doesn't exists" });
      }
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [{ id: Date.now(), date: new Date() }, ...prevState]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const onCreateSuccess = (item) => {
    getTransactions();
    deleteFormItem(item.id);
    refreshAccountsList();
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const listenEmitter = ({ detail }) => {
    const keys = [
      SEND_PAYMENT_KEYS.STAFF,
      SEND_PAYMENT_KEYS.VENDORS,
      SEND_PAYMENT_KEYS.DRIVERS,
      SEND_PAYMENT_KEYS.OWNER_OPERATORS,
    ];
    if (keys.includes(detail.activeName)) {
      setLoading(true);
      getTransactions();
      setForms([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTransactions();
    setForms([]);
  }, [account?.id, dateRange, filters, search, sort, refreshIndex]);

  useEffect(() => {
    window.addEventListener('on_print_check_complete', listenEmitter);
    return () => window.removeEventListener('on_print_check_complete', listenEmitter);
  }, []);

  return (
    <>
      <STransactionsTableWrapper>
        <div className='table-container fixed-header'>
          <STable>
            <thead>
              <tr>
                <STableHeader $width='157px'>
                  <ColumnHeader title='DATE' field='date' color={palette.gray700} onClick={sortingQuery} sort={sort} />
                </STableHeader>
                <th>REFERENCE ID</th>
                <STableHeader $width='320px'>
                  <ColumnHeader
                    title='PAYEE'
                    field='payee_id'
                    color={palette.gray700}
                    onClick={sortingQuery}
                    sort={sort}
                  />
                </STableHeader>
                <STableHeader $width='320px'>
                  <ColumnHeader
                    title='ACCOUNT'
                    field='account'
                    color={palette.gray700}
                    onClick={sortingQuery}
                    sort={sort}
                  />
                </STableHeader>
                <th>MEMO</th>
                <th>PAYMENT</th>
                <th>DEPOSIT</th>
                <th>BALANCE</th>
                <th />
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {account.system_account_type !== 2 && (
                  <tr>
                    <td>
                      <SAddMore onClick={addNewForm}>
                        <PlusIcon />
                        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                          Add Another...
                        </Typography>
                      </SAddMore>
                    </td>
                  </tr>
                )}
                {forms.map((item) => (
                  <BankAccountFormItem
                    key={item.id}
                    account={account}
                    accounts={accounts}
                    payees={payees}
                    getPayees={getPayees}
                    onDelete={() => deleteFormItem(item.id)}
                    onCreateSuccess={() => onCreateSuccess(item)}
                    form={item}
                  />
                ))}
                {transactions.data.map((item) => (
                  <BankAccountFormItem
                    key={item.id}
                    account={account}
                    accounts={accounts}
                    payees={payees}
                    transaction={item}
                    onDelete={() => setTransactionToDelete(item)}
                    onCreateSuccess={() => onCreateSuccess(item)}
                    onUpdateSuccess={onUpdateSuccess}
                    getPayees={getPayees}
                    firstInFuture={item.id === firstTransactionInFuture?.id}
                    onRowClick={() => onRowClick(item)}
                    onClickRowItem={onClickRowItem}
                  />
                ))}
              </tbody>
            )}
          </STable>
          {!!loading && <TableSkeleton />}
          {!transactions.data?.length && !forms?.length && !loading && <NoRecords />}
        </div>
        {!!viewTransaction && (
          <TransactionDetails
            open={!!viewTransaction}
            onClose={() => setViewTransaction(null)}
            transactionData={viewTransaction}
            account={account}
            onRefundSuccess={getTransactions}
          />
        )}
        <ConfirmationModal
          open={!!transactionToDelete}
          title='Transaction'
          onClose={() => setTransactionToDelete(null)}
          onConfirm={deleteTransaction}
          disabled={loadingDelete}
        />
      </STransactionsTableWrapper>
      {pdfUrl && <ViewPdf pdfUrl={pdfUrl} open={!!pdfUrl} onClose={() => setPdfUrl(null)} />}
    </>
  );
};

export default BankAccountTransactions;
