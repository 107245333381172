import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  width: 230px;
  height: 28px;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  line-height: 20px;
  font-style: normal;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;
  border: none !important;
  font-family: 'Inter', sans-serif;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 0 0 1px rgba(104, 113, 130, 0.16);

  .react-datepicker-wrapper {
    display: flex;
  }
`;
