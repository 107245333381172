import React, { useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Icons } from 'assets/icons';
import { LicenseState, getEquipmentRequiredVehicles, getEquipmentRequiredTrailers, Dimension } from 'Api/Equipment';
import style from './AddEquipment.module.css';
import Documents from './Documents';
import Services from './Services/Services';
import Hardware from './Hardware/Hardware';
import Ownership from './Ownership/Ownership';
import TrailerInfo from './EquipmentInfo/TrailerInfo';
import EquipmentType from './EquipmentType/EquipmentType';
import EquipmentInfo from './EquipmentInfo/EquipmentInfo';
import TrailerServices from './Services/TrailerServices';
import { autoFillData, lengthData } from './EquipmentInfo/AutofillData';

const AddEquipment = ({ onSuccess, handleClose, setAddEquipmentSteps, defaultType, onChangeTab }) => {
  const [equipmentType, setEquipmentType] = useState();
  const [getVinData, setGetVinData] = useState();
  const [response, setResponse] = useState();
  const [plateState, setPlateState] = useState([]);
  const [vehicleType, setVehicleType] = useState(false);
  const [purchaseDevice, setPurchaseDevice] = useState('purchase');

  const getEquipmentType = (type) => {
    setEquipmentType(type);
  };
  const [status, setStatus] = useState(1);
  const [hardwareSteps, setHardwareSteps] = useState(false);
  const [equipmentAutofillData, setEquipmentAutofillData] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [dimensionData, setDimensionData] = useState();
  const [getEquipmentData, setgetEquipmentData] = useState({});
  const [lengthByVehicle, setLengthByVehicle] = useState();
  const [selectedVehicleLenghtData, setSelectedVehicleLenghtData] = useState();

  const [equipment, setEquipment] = useState({
    equipmentType: {},
    equipmentInfo: {},
    ownership: {},
    insuranceAndOthers: {},
    res: {},
  });

  // const [useFromCompanySettingsData, setUseFromCompanySettingsData] = useState({
  //   physical_insurance_policy: false,
  //   liability_insurance_policy: false,
  //   motor_cargo_insurance_policy: false,
  //   general_liability_insurance_policy: false,
  //   ifta_sticker_attach: false,
  //   highway_tax_2290: false,
  //   kyu_license: false,
  // });
  //
  // const [insuranceStepFileNames, setInsuranceStepFileNames] = useState({
  //   dot_attach: '',
  //   physical_insurance_policy_attach: '',
  //   liability_insurance_policy_attach: '',
  //   motor_cargo_insurance_policy_attach: '',
  //   general_liability_insurance_policy_attach: '',
  //   ifta_sticker_attach: '',
  //   highway_tax_date_attach: '',
  //   kyu_id_attach: '',
  // });

  const getAllVinData = (data) => {
    setGetVinData(data?.Results[0]);
  };
  const statusHandler = (status) => {
    setStatus(status);
  };
  const nextStep = (formValue) => {
    if (status === 1) {
      const { values } = formValue || {};
      setEquipment({
        ...equipment,
        equipmentType: values,
      });

      if (values.type === 'trailer') {
        const arr = autoFillData.filter((v) => v.id === values.equipment_type_id);
        setEquipmentAutofillData(arr);
      }
    }
    if (status === 2) {
      const { values } = formValue || {};
      setEquipment({
        ...equipment,
        equipmentInfo: values,
      });
    }
    if (status === 3) {
      const { values } = formValue || {};
      setEquipment({
        ...equipment,
        ownership: values,
      });
    }

    if (status === 4) {
      setResponse(formValue);
    }

    if (status === 6) {
      const { values } = formValue || {};

      onSubmitForm({ values });
    }
    setStatus(status < 6 ? status + 1 : '');
  };

  const DimensionData = async () => {
    Dimension(getEquipmentData?.equipment_type_id, getEquipmentData?.length).then((res) =>
      setDimensionData(res?.data[0])
    );
  };

  useEffect(() => {
    if (equipment?.equipmentType?.type !== 'vehicle') {
      setVehicleType(true);
    } else {
      setVehicleType(false);
    }
  }, [equipment]);

  useEffect(() => {
    DimensionData();
  }, [getEquipmentData]);

  const prevStep = (step, formValues) => {
    if (step === 4) {
      setEquipment({
        ...equipment,
        insuranceAndOthers: formValues,
      });
      // setUseFromCompanySettingsData(useFromSettings);
      // setInsuranceStepFileNames(fileNames);
    }
    setStatus(status - 1);
  };

  const Cancel = () => {
    if (status > 4) {
      onSuccess(equipment?.equipmentInfo?.equipment_id);
    }
    handleClose();
  };
  const [data, setData] = useState([]);

  const handleReset = () => {
    setHardwareSteps(false);
  };
  const onSubmitForm = () => {
    handleClose();
  };

  const LicenseStateID = async () => {
    const promises = [];

    ['231', '38', '142'].map((id) => {
      promises.push(LicenseState(id));
      return id;
    });

    const response = await Promise.all(promises);
    const finalRes = [...response[0].data.data, ...response[1].data.data, ...response[2].data.data];
    const PlateStateData = finalRes.map((v) => {
      return { label: v.name, value: v.id };
    });
    setPlateState(PlateStateData);
  };
  const HardwareSteps = () => {
    setHardwareSteps(true);
    setAddEquipmentSteps(true);
  };

  const getVehicleList = useCallback(() => {
    getEquipmentRequiredVehicles()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.title,
              value: el.id,
            };
          });
          setVehicleList(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);
  const getTrailerList = useCallback(() => {
    getEquipmentRequiredTrailers()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.title,
              value: el.id,
            };
          });
          setTrailerList(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  useEffect(() => {
    LicenseStateID();
    getVehicleList();
    getTrailerList();
  }, []);

  const LengthDataByVehicle = (value) => {
    setLengthByVehicle(value);
  };

  const selectedVehicleLength = (value) => {
    setSelectedVehicleLenghtData(value);
  };

  return (
    <div className={style['main-equipment']}>
      <div className={style['equipment-main-modal-wrapper']} style={{ position: 'relative' }}>
        <div style={{ top: '0.7rem' }}>
          {!hardwareSteps ? (
            <p className={style['add-equipment-modal-text']}>Add Equipment</p>
          ) : (
            <div className='d-flex align-items-center mt-3' onClick={handleReset}>
              <img src={Icons?.CrossRedIcon} alt='' onClick={handleReset} />
              <p className={style['add-equipment-modal-text']}>
                <span className='mt-3 ms-4'>Purchase Hardware</span>
              </p>
            </div>
          )}
        </div>
        {!hardwareSteps ? (
          <Modal.Header className={style['add-equipment-modal-header']}>
            <div className={style['add-steps-wrapper']}>
              <div className={status >= 1 ? style['step-heading-active'] : style['steps-heading']}>
                {status > 1 ? (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      className={style['equipment-check-box']}
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Type</span>
                  </div>
                ) : (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      type='radio'
                      name=''
                      disabled={!(status >= 1)}
                      checked={status >= 1}
                      onClick={() => statusHandler(1)}
                      className={style['input-style']}
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Type</span>
                  </div>
                )}

                <div className={style['equipment-progress']}>
                  <div
                    className={
                      status === 1
                        ? style['equipment-incomplete-progressbar']
                        : status > 1
                        ? style['equipment-complete-progressbar']
                        : ''
                    }
                    role='progressbar'
                    // style={{}}
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 2 ? style['step-heading-active'] : style['steps-heading']}>
                {status > 2 ? (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      className={style['equipment-check-box']}
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Equipment Info</span>
                  </div>
                ) : (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 2}
                      checked={status === 2}
                      onClick={() => statusHandler(2)}
                      className={style['input-style']}
                      style={{ marginBottom: '0px' }}
                    />
                    <span> Equipment Info</span>
                  </div>
                )}

                <div className={style['equipment-progress']}>
                  <div
                    className={
                      status === 2
                        ? style['equipment-incomplete-progressbar']
                        : status > 2
                        ? style['equipment-complete-progressbar']
                        : ''
                    }
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 3 ? style['step-heading-active'] : style['steps-heading']}>
                {status > 3 ? (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      className={style['equipment-check-box']}
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Ownership</span>
                  </div>
                ) : (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 3}
                      checked={status === 3}
                      onClick={() => statusHandler(3)}
                      className={style['input-style']}
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Ownership</span>
                  </div>
                )}

                <div className={style['equipment-progress']}>
                  <div
                    className={
                      status === 3
                        ? style['equipment-incomplete-progressbar']
                        : status > 3
                        ? style['equipment-complete-progressbar']
                        : ''
                    }
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 4 ? style['step-heading-active'] : style['steps-heading']}>
                {status > 4 ? (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      className={style['equipment-check-box']}
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Documents</span>
                  </div>
                ) : (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 4}
                      checked={status === 4}
                      onClick={() => statusHandler(4)}
                      className={style['input-style']}
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Documents</span>
                  </div>
                )}

                <div className={style['equipment-progress']}>
                  <div
                    className={
                      status === 4
                        ? style['equipment-incomplete-progressbar']
                        : status > 4
                        ? style['equipment-complete-progressbar']
                        : ''
                    }
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 5 ? style['step-heading-active'] : style['steps-heading']}>
                {status > 5 ? (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      className={style['equipment-check-box']}
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Connected Services</span>
                    {/* <span>Insurance & Others</span> */}
                  </div>
                ) : (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 5}
                      checked={status === 5}
                      onClick={() => statusHandler(5)}
                      className={style['input-style']}
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Connected Services</span>

                    {/* <span>Insurance & Others</span> */}
                  </div>
                )}

                <div className={style['equipment-progress']}>
                  <div
                    className={
                      status === 5
                        ? style['equipment-incomplete-progressbar']
                        : status > 5
                        ? style['equipment-complete-progressbar']
                        : ''
                    }
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 6 ? style['step-heading-active'] : style['steps-heading']}>
                {status > 6 ? (
                  <div className={style['rounded-checkbox-wrapper']}>
                    <input
                      className={style['equipment-check-box']}
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Hardware</span>
                  </div>
                ) : (
                  <div>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 6}
                      checked={status === 6}
                      onClick={() => statusHandler(6)}
                      className={style['input-style']}
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Hardware</span>
                  </div>
                )}

                <div className={style['equipment-progress']}>
                  <div
                    className={
                      status === 6
                        ? style['equipment-incomplete-progressbar']
                        : status > 6
                        ? style['equipment-complete-progressbar']
                        : ''
                    }
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
            </div>
          </Modal.Header>
        ) : (
          ''
        )}
      </div>
      {status === 1 && (
        <div>
          <EquipmentType
            nextStep={nextStep}
            prevStep={prevStep}
            getEquipmentType={getEquipmentType}
            onChangeTab={onChangeTab}
            Cancel={Cancel}
            data={data}
            getEquipmentData={getEquipmentData}
            setgetEquipmentData={setgetEquipmentData}
            trailerList={trailerList}
            vehicleList={vehicleList}
            lengthData={lengthData}
            equipmentType={equipment.equipmentType}
            getAllVinData={getAllVinData}
            values={{}}
            setData={setData}
            LengthDataByVehicle={LengthDataByVehicle}
            selectedVehicleLength={selectedVehicleLength}
            defaultType={defaultType}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          {equipmentType === 'vehicle' ? (
            <EquipmentInfo
              setData={setData}
              nextStep={nextStep}
              getVinData={getVinData}
              Cancel={Cancel}
              equipmentInfo={equipment.equipmentInfo}
              data={data}
              prevStep={prevStep}
              lengthData={lengthData}
              equipment={equipment.equipmentType}
              dimensionData={dimensionData}
              lengthByVehicle={lengthByVehicle}
              selectedVehicleLenghtData={selectedVehicleLenghtData}
              onSuccess={onSuccess}
            />
          ) : (
            <TrailerInfo
              dimensionData={dimensionData}
              setData={setData}
              nextStep={nextStep}
              getVinData={getVinData}
              equipmentAutofillData={equipmentAutofillData}
              equipmentInfo={equipment.equipmentInfo}
              lengthData={lengthData}
              equipment={equipment.equipmentType}
              Cancel={Cancel}
              prevStep={prevStep}
              selectedVehicleLenghtData={selectedVehicleLenghtData}
              onSuccess={onSuccess}
            />
          )}
        </div>
      )}
      {status === 3 && (
        <div>
          <Ownership
            setData={setData}
            nextStep={nextStep}
            data={data}
            plateState={plateState}
            equipment={equipment}
            getAllVinData={getAllVinData}
            Cancel={Cancel}
            ownership={equipment.ownership}
            prevStep={prevStep}
            vehicleType={vehicleType}
          />
        </div>
      )}
      {status === 4 && (
        <div>
          <Documents
            nextStep={nextStep}
            prevStep={prevStep}
            Cancel={Cancel}
            equipment={equipment}
            equipmentData={response}
            equipmentType={equipmentType}
          />
        </div>
      )}
      {status === 5 && (
        <div>
          {equipmentType === 'vehicle' ? (
            <Services setData={setData} nextStep={nextStep} Cancel={Cancel} response={response} prevStep={prevStep} />
          ) : (
            <TrailerServices
              setData={setData}
              nextStep={nextStep}
              Cancel={Cancel}
              response={response}
              prevStep={prevStep}
            />
          )}
        </div>
      )}
      {status === 6 && (
        <div>
          <Hardware
            purchaseDevice={purchaseDevice}
            setPurchaseDevice={setPurchaseDevice}
            setData={setData}
            nextStep={nextStep}
            Cancel={Cancel}
            equipmentType={equipmentType}
            prevStep={prevStep}
            HardwareSteps={HardwareSteps}
            equipment={equipment}
            onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default AddEquipment;
