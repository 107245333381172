import React from 'react';
import { useSelector } from 'react-redux';
import UserInfo from 'components/UserInfo';
import Row from 'components/OfferVehicle/Row';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import LocationSelect from 'components/OfferVehicle/LocationSelect';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { formatAmount } from 'services/IFTAServices';

export const useColumns = ({
  data,
  offer,
  index,
  mapValues,
  offerState,
  selectedRows,
  setOpenModal,
  setSelectedRows,
  setOneEquipmentData,
  revertOfferForVehicle,
}) => {
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: '',
      title: (
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
          {!offerState?.length && (
            <CustomCheckbox
              labelStyle={{ marginTop: 0 }}
              checked={selectedRows.length === (data?.length || 0) - (offerState?.length || 0)}
              onChange={() => {
                if (selectedRows.length === (data.length || 0) - (offerState?.length || 0)) {
                  setSelectedRows([]);
                } else {
                  const selectableRows = data?.filter((item) => {
                    return !offerState?.some((offer) => offer.equipment_id === item.equipment_id);
                  });
                  setSelectedRows(selectableRows || []);
                }
              }}
            />
          )}
          {!!selectedRows.length && (
            <CustomButton
              width={12}
              height={12}
              type='primary'
              title='Bulk Offer'
              styleButton={{ padding: '4px 12px', margin: 0 }}
              onClick={() => setOpenModal(true)}
            />
          )}
        </div>
      ),
      render: (rowData) => {
        const checked = selectedRows.find((item) => item.equipment_id === rowData.equipment_id);

        if (!offerState?.some((offer) => offer.equipment_id === rowData.equipment_id)) {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <CustomCheckbox
                labelStyle={{ marginTop: 5 }}
                checked={!!checked}
                disabled={offerState?.some((offer) => offer.equipment_id === rowData.equipment_id)}
                onChange={() => {
                  if (checked) {
                    const newData = selectedRows.filter((item) => item.equipment_id !== rowData.equipment_id);
                    setSelectedRows(newData);
                  } else {
                    setSelectedRows((prevState) => [...prevState, rowData]);
                  }
                }}
              />
            </div>
          );
        }
        return null;
      },
    },
    {
      title: 'Shipment ID',
      field: 'shipment_id',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => (
        <div>
          <p className='shipment-id' style={{ color: palette.gray900 }}>
            {rowData?.shipmentId}
          </p>
        </div>
      ),
    },
    {
      title: 'Vehicle',
      field: 'VehicleID',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => (
        <div>
          <p className='shipment-id' style={{ color: palette.gray900 }}>
            {rowData?.equipment?.equipment_id}
          </p>
        </div>
      ),
    },
    {
      title: 'Trailer',
      field: 'Trailer',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
        minWidth: '200px',
      },
      render: (rowData) => (
        <div className='d-flex flex-column'>
          <div className='equipment-container d-flex align-items-center'>
            <span
              className='dispatch-details-text'
              style={{
                color: palette.gray700,
              }}
            >
              {' '}
              {rowData?.equipment?.hooked_to?.equipment_number}
            </span>
            &nbsp;
            <span className='dispatch-details-text'> {rowData?.equipment?.hooked_to?.equipment_type?.title} </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Compensation',
      field: 'Compensation',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => (
        <div>
          {rowData?.equipment?.drivers[0]?.compensation_details?.pay_option === 'mile' && (
            <div className='d-flex flex-column'>
              <Typography variant='s2'>Pay Per Mile</Typography>
              <span>
                {currency}
                {formatAmount(rowData?.equipment?.drivers[0]?.compensation_details?.mile_loaded_amt)} Loaded
              </span>
              <span>
                {currency}
                {formatAmount(rowData?.equipment?.drivers[0]?.compensation_details?.mile_empty_amt)} Empty
              </span>
            </div>
          )}
          {rowData?.equipment?.drivers[0]?.compensation_details?.pay_option === 'percentage' && (
            <div className='d-flex flex-column'>
              <Typography variant='s2'>Percentage</Typography>
              <span>{rowData?.equipment?.drivers[0]?.compensation_details?.shipment_percentage}% of Shipment</span>
            </div>
          )}

          {rowData?.equipment?.drivers[0]?.compensation_details?.pay_option === 'per_hour' && (
            <div className='d-flex flex-column'>
              <Typography variant='s2'>Per Hour</Typography>
              <span>
                {currency}
                {formatAmount(rowData?.equipment?.drivers[0]?.compensation_details?.per_hour_worked)} Per Hour
              </span>
            </div>
          )}
          {rowData?.equipment?.drivers[0]?.compensation_details?.pay_option === 'flat_rate' && (
            <div className='d-flex flex-column'>
              <Typography variant='s2'>Flat Rate</Typography>
              <span>
                {currency}
                {formatAmount(rowData?.equipment?.drivers[0]?.compensation_details?.flat_rate_amount)}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Driver',
      field: 'Driver',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return rowData?.equipment?.drivers.map((d) => {
          return (
            <UserInfo
              data={{
                ...d,
                name: `${d.fname} ${d.lname}`,
                status: d.status_data?.driver_status,
                user_type: 'driver',
              }}
            />
          );
        });
      },
    },
    {
      title: 'Assign Driver From',
      field: 'AssignDriverFrom',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <LocationSelect
            rowData={rowData}
            mapValues={mapValues}
            setOneEquipmentData={(a, b, c, d) => setOneEquipmentData(a, b, c, d, index)}
          />
        );
      },
    },
    {
      title: 'Empty MIles',
      field: 'EmptyMIles',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <p className='table-data-text' style={{ color: palette.gray700 }}>
            {Number(rowData?.empty_miles).toFixed()}
          </p>
        );
      },
    },
    {
      title: 'Loaded Miles',
      field: 'LoadedMiles',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <p className='table-data-text' style={{ color: palette.gray700 }}>
            {Number(rowData?.loaded_miles).toFixed()}
          </p>
        );
      },
    },
    {
      title: 'ETA',
      field: 'ETA',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <div>
            <p className='dispatch-details-text'>{rowData?.eta}</p>
          </div>
        );
      },
    },
    {
      title: 'Cost',
      field: 'Cost',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => (
        <Row
          rowData={rowData}
          offer={(rowData, value) => offer(rowData, value)}
          offerState={offerState}
          revertOfferForVehicle={revertOfferForVehicle}
        />
      ),
    },
  ];
};
