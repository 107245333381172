import React from 'react';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import InspectionsChart from '../Charts/InspectionsChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const DriverInspections = ({ inspections, driverInspectionsYear, onDriverInspectionYearChange }) => {
  return (
    <SBoxWrapper $minWidth='600px'>
      <Typography variant='s2'>DRIVER INSPECTIONS</Typography>
      <div className='d-flex align-items-center justify-content-between me-3'>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            INSPECTION TOTAL
          </Typography>
          <Typography variant='h5' style={{ color: palette.indigo500, textAlign: 'center' }}>
            {formatNumber(inspections?.totalAmount, 0)}
          </Typography>
        </div>
        <Autocomplete
          options={['All', 2021, 2022, 2023, 2024]}
          style={{ width: '90px' }}
          value={driverInspectionsYear}
          onChange={(e, val) => {
            onDriverInspectionYearChange(val);
          }}
          isOptionEqualToValue={(option, value) => option === value}
        />
        <div className='d-flex flex-column gap-1'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            OUT OF SERVICE TOTAL
          </Typography>
          <Typography variant='h5' style={{ color: palette.red500, textAlign: 'center' }}>
            {formatNumber(inspections?.outOfServiceAmount, 0)}
          </Typography>
        </div>
      </div>

      <div className='chart-wrapper'>
        <InspectionsChart monthsData={inspections?.monthsData} />
      </div>
    </SBoxWrapper>
  );
};

export default DriverInspections;
