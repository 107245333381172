import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { getPayableAgingDetails, getPayableAgingSummary } from 'Api/Reports';
import { vendorsListConverter } from './converters';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { STableFilters, STableWrapper, STable } from '../ReportDetails.styles';

const VendorList = ({ title }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [reportItems, setReportItems] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [dateRange, setDateRange] = useState({
    start: startDate ? moment(startDate).toDate() : null,
    end: endDate ? moment(endDate).toDate() : null,
  });
  const didMountRef = useRef(false);

  const getVendorsList = async () => {
    try {
      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
      };
      const { data } = await getPayableAgingSummary(params);
      const { data: totalData } = await getPayableAgingDetails(params);
      const convertedData = vendorsListConverter(data?.[0]);
      setReportItems(convertedData || []);
      setTotalData(totalData[0] || {});
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    } finally {
      setLoading(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const data = { reportItems, totalData, title };
      const { url } = await generatePDF(data, download, dateRange, dateFormat);
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }

    const titles = {
      list: 'List',
      current: 'Current',
      '1-30': '1-30',
      '31-60': '31-60',
      '61-90': '61-90',
      '91+': '91+',
      total: 'Total',
    };

    const vendors = reportItems.map((vendor) => ({
      list: vendor.vendor || '',
      current: vendor.current?.total_amount || 0,
      '1-30': vendor['1-30']?.total_amount || 0,
      '31-60': vendor['31-60']?.total_amount || 0,
      '61-90': vendor['61-90']?.total_amount || 0,
      '91+': vendor['91+']?.total_amount || 0,
      total: vendor.total || 0,
    }));

    const total = [
      {
        list: 'Total',
        current: totalData.Current?.total_amount || 0,
        '1-30': totalData['1-30']?.total_amount || 0,
        '31-60': totalData['31-60']?.total_amount || 0,
        '61-90': totalData['61-90']?.total_amount || 0,
        '91+': totalData['91+']?.total_amount || 0,
        total: reportItems.reduce((acc, cur) => acc + cur.total, 0),
      },
    ];

    const arrayToConvert = [titles, ...vendors, ...total];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('download', title);
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getVendorsList();
    didMountRef.current = true;
  }, [dateRange]);

  useEffect(() => {
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams, { replace: true });
  }, []);

  return (
    <PageWrapper loading={loading} title={title}>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName={dateRange.start ? 'Custom Range' : 'All Time'}
              dateRange={dateRange}
              setDateRange={setDateRange}
              type='allTime'
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportItems.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        <STable>
          <thead>
            <tr className='header-row'>
              <th>LIST</th>
              <th>CURRENT</th>
              <th>1-30</th>
              <th>31-60</th>
              <th>61-90</th>
              <th>91+</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {reportItems.map((item) => (
              <tr key={item.vendor} className='body-row'>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.vendor}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatNumber(item.current.total_amount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatNumber(item['1-30'].total_amount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatNumber(item['31-60'].total_amount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatNumber(item['61-90'].total_amount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatNumber(item['91+'].total_amount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatNumber(item.total)}
                  </Typography>
                </td>
              </tr>
            ))}
            {reportItems.length !== 0 && (
              <tr className='body-row'>
                <td>
                  <Typography variant='button2'>Total</Typography>
                </td>
                <td>
                  <Typography variant='button2'>{formatNumber(totalData.Current?.total_amount || 0)}</Typography>
                </td>
                <td>
                  <Typography variant='button2'>{formatNumber(totalData['1-30']?.total_amount || 0)}</Typography>
                </td>
                <td>
                  <Typography variant='button2'>{formatNumber(totalData['31-60']?.total_amount || 0)}</Typography>
                </td>
                <td>
                  <Typography variant='button2'>{formatNumber(totalData['61-90']?.total_amount || 0)}</Typography>
                </td>
                <td>
                  <Typography variant='button2'>{formatNumber(totalData['91+']?.total_amount || 0)}</Typography>
                </td>
                <td>
                  <Typography variant='button2'>
                    {formatNumber(reportItems.reduce((acc, cur) => acc + cur.total, 0))}
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </STable>
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title={title} />}
    </PageWrapper>
  );
};

export default VendorList;
