import jsPDF from 'jspdf';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import globeIcon from 'assets/icons/globe.png';
import locationIcon from 'assets/icons/location.png';

const createFooter = ({ doc, accountingSettings, companyProfile, page = 1, totalPages = 1 }) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const { website, show_website, show_billing, receivable_email } = accountingSettings || {};
  const { address1, address2, city_id, state_id, zip, phone_number } = companyProfile || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 26, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4, pageHeight - 20, pageWidth / 4, pageHeight - 10);
    doc.line(pageWidth / 2 + 1, pageHeight - 20, pageWidth / 2 + 1, pageHeight - 10);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 5, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.text('Address', 13, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 10, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);

    doc.setFontSize(8);
    doc.setTextColor('#171C26');
    doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 8, pageHeight - 13);
    doc.text(`${city_id?.name}, ${state_id?.name} ${zip}`, 8, pageHeight - 9, { maxWidth: 45 });

    if (show_website === 1) {
      doc.text(`${website || '-'}`, pageWidth / 4 + 5, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 4 + 5, pageHeight - 13);
    }
    if (show_billing) {
      doc.text(`${receivable_email || '-'}`, pageWidth / 2 + 6, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 2 + 6, pageHeight - 13);
    }
    doc.text(`${phone || '-'}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);
  }
};

export const generatePDF = async (data) => {
  const { companyProfile, accountingSettings, values } = data;

  const {
    ach_enabled,
    ach_account_number,
    ach_routing_number,
    wire_enabled,
    wire_account_number,
    wire_routing_number,
    bank_name,
    address: address_line1,
    address2: address_line2,
    zipcode: bank_zip_code,
    city,
    state,
  } = values || {};

  const { address1, address2, city_id, state_id, zip, company_logo, company_name, phone_number } = companyProfile || {};

  const { ein } = accountingSettings || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${company_logo}?cache=${Math.random()}`, 'JPEG', pageWidth / 2 - 30, 8, 60, 12, 'Logo', 'FAST');

  // Company Info
  doc.setFontSize(9);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${company_name}`, pageWidth / 2, 26, { align: 'center' });
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, pageWidth / 2, 30, { align: 'center' });
  doc.text(`${city_id?.name}, ${state_id?.short_name} ${zip}`, pageWidth / 2, 34, { align: 'center' });
  doc.text(`${phone}`, pageWidth / 2, 38, { align: 'center' });

  doc.setFontSize(14);
  doc.text('Funds Transfer Instructions', pageWidth / 2, 54, { align: 'center' });

  // Company Information
  doc.setFontSize(13);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Company Information:', pageWidth / 2, 74, { align: 'center' });
  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 25, 75, pageWidth / 2 + 25, 75);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);
  doc.text(company_name, pageWidth / 2, 80, { align: 'center' });
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, pageWidth / 2, 84, { align: 'center' });
  doc.text(`${city_id?.name}, ${state_id?.short_name} ${zip}`, pageWidth / 2, 88, { align: 'center' });
  if (ein) {
    doc.text(`(EIN ${ein})`, pageWidth / 2, 94, { align: 'center' });
  }

  // Bank Information
  doc.setFontSize(13);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Bank Information:', pageWidth / 2, 114, { align: 'center' });
  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 20, 115, pageWidth / 2 + 20, 115);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);
  doc.text(bank_name, pageWidth / 2, 120, { align: 'center' });
  doc.text(`${address_line1}${address_line2 ? `, ${address_line2}` : ''}`, pageWidth / 2, 124, { align: 'center' });
  doc.text(`${city?.name}, ${state?.short_name} ${bank_zip_code}`, pageWidth / 2, 128, { align: 'center' });

  // ACH
  if (ach_enabled) {
    doc.setFontSize(10);
    doc.text('If sending           , please use this account and routing number:', pageWidth / 2, 140, {
      align: 'center',
    });
    doc.setFont('Inter', 'normal', 700);
    doc.text('ACH', pageWidth / 2 - 34, 140);
    doc.line(pageWidth / 2 - 34, 141, pageWidth / 2 - 26, 141);

    doc.text('Accounting Number', pageWidth / 2 - 22, 148, { align: 'center' });
    doc.text('Routing Number', pageWidth / 2 + 22, 148, { align: 'center' });

    doc.setFont('Inter', 'normal', 400);
    doc.text(ach_account_number, pageWidth / 2 - 22, 154, { align: 'center' });
    doc.text(ach_routing_number, pageWidth / 2 + 22, 154, { align: 'center' });
  }

  // Wire
  if (wire_enabled) {
    doc.setFontSize(10);
    doc.text(
      'If sending           , please use this account and routing number:',
      pageWidth / 2,
      ach_enabled ? 166 : 140,
      {
        align: 'center',
      }
    );
    doc.setFont('Inter', 'normal', 700);
    doc.text('Wire', pageWidth / 2 - 34, ach_enabled ? 166 : 140);
    doc.line(pageWidth / 2 - 34, ach_enabled ? 167 : 141, pageWidth / 2 - 26, ach_enabled ? 167 : 141);

    doc.text('Accounting Number', pageWidth / 2 - 22, ach_enabled ? 174 : 148, { align: 'center' });
    doc.text('Routing Number', pageWidth / 2 + 22, ach_enabled ? 174 : 148, { align: 'center' });

    doc.setFont('Inter', 'normal', 400);
    doc.text(wire_account_number, pageWidth / 2 - 22, ach_enabled ? 180 : 154, { align: 'center' });
    doc.text(wire_routing_number, pageWidth / 2 + 22, ach_enabled ? 180 : 154, { align: 'center' });
  }

  const totalPages = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({ doc, accountingSettings, companyProfile, page, totalPages });
  }

  const url = doc.output('datauristring');
  const blob = doc.output('blob');

  return { blob, url };
};
