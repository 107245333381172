import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { getCarrierGps, trackingHistoryData } from 'Api/Planner';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as LiveIcon } from 'assets/icons/drivers/Step.svg';
import { PusherJs } from 'common/pusher';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { getStops } from 'Api/Shipment';
import CustomButton from 'components/CustomButton/CustomButton';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import classes from './trackingHistory.module.css';
import TruckHistoryMap from '../../../../Map/MapComponents/TruckHistoryMap/TruckHistoryMap';

const TrackingHistory = ({ infoHeader }) => {
  const { value } = useAuth();
  const { user } = value;
  const navigate = useNavigate();
  const { convertToCustomerTime } = useDateFormat();
  const [mapInstance, setMapInstance] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [legs, setLegs] = useState([]);
  const [loading, setLoading] = useState(false);
  const isShipmentTable = useMemo(() => infoHeader?.assigned_to1 !== undefined, [infoHeader]);

  const { brokerage_dispatch } = infoHeader || {};
  const { carrier } = brokerage_dispatch || {};

  const getTrackingHistory = (data) => {
    const converted = data.reduce((acc, cur, index) => {
      const lastItem = acc[acc.length - 1];

      if (index === 0) {
        return [{ ...cur, distance: 0, seconds: 0, background: 'black', start_date: cur.created_at }];
      }

      const lon1 = lastItem.longitude;
      const lat1 = lastItem.latitude;
      const lon2 = cur.longitude;
      const lat2 = cur.latitude;
      const dist = CalcDistance(lat1, lon1, lat2, lon2);
      const distance = dist < 0.5 ? 0 : dist;

      if (lastItem && lastItem.distance === 0 && distance === 0) {
        const a = moment(lastItem.start_date).set('seconds', 0);
        const b = moment(cur.created_at).set('seconds', 0);
        const seconds = b.diff(a, 'seconds') || 0;

        return [
          ...acc.slice(0, acc.length - 1),
          {
            ...cur,
            start_date: lastItem.start_date,
            created_at: cur.created_at,
            seconds,
            distance,
            background: 'red',
          },
        ];
      }

      const a = moment(lastItem.created_at).set('seconds', 0);
      const b = moment(cur.created_at).set('seconds', 0);
      const seconds = b.diff(a, 'seconds') || 0;

      acc.push({
        ...cur,
        start_date: cur.created_at,
        distance,
        seconds,
        background: 'black',
      });
      return acc;
    }, []);

    const withBlackDots = converted.reduce((acc, cur) => {
      if (cur.distance === 0) {
        const a = moment(cur.start_date).set('seconds', 0);
        const b = moment(cur.created_at).set('seconds', 0);
        const seconds = b.diff(a, 'seconds') || 0;

        if (seconds !== 0) {
          const lastItem = acc[acc.length - 1];
          const a = moment(lastItem?.created_at).set('seconds', 0);
          const b = moment(cur.start_date).set('seconds', 0);
          const seconds = b.diff(a, 'seconds') || 0;

          acc.push({ ...cur, seconds, created_at: cur.start_date, background: 'black' });
        }
      }

      acc.push(cur);
      return acc;
    }, []);

    return withBlackDots.map((item, index) => {
      let seconds = item.seconds;

      const days = Math.floor(seconds / (24 * 3600));
      seconds -= days * 24 * 3600;

      // hours
      const hrs = Math.floor(seconds / 3600);
      seconds -= hrs * 3600;

      // minutes
      const minutes = Math.floor(seconds / 60);

      const duration = days ? `${days}d ${hrs}h` : hrs ? `${hrs}h ${minutes}m` : `${minutes}m`;

      return { ...item, duration, id: `${index}-${item.id}` };
    });
  };

  async function getHistory({ id, start, end }) {
    setLoading(true);
    (carrier ? getCarrierGps(id) : trackingHistoryData(id, start, end))
      .then((response) => {
        const result = response?.data || response;
        // setHistoryData(result);
        // const data = [];
        // const duplicateKeys = result.map((r) => r.location);
        // const keys = [...new Set(duplicateKeys)];
        //
        // keys.map((key) => {
        //   const allLocations = result.filter((f) => f.location === key);
        //   if (allLocations && allLocations.length >= 2) {
        //     const a = moment(allLocations[0].created_at); // now
        //     const b = moment(allLocations[allLocations.length - 1].created_at);
        //     let seconds = b.diff(a, 'seconds');
        //     const days = Math.floor(seconds / (24 * 3600));
        //     seconds -= days * 24 * 3600;
        //
        //     // hours
        //     const hrs = Math.floor(seconds / 3600);
        //     seconds -= hrs * 3600;
        //
        //     // minutes
        //     const minutes = Math.floor(seconds / 60);
        //     seconds -= minutes * 60;
        //     const durationFinal = days ? `${days}d ${hrs}h` : hrs ? `${hrs}h ${minutes}m` : `${minutes}m`;
        //
        //     const lon1 = allLocations[0]?.longitude;
        //     const lat1 = allLocations[0]?.latitude;
        //     const lat2 = allLocations[allLocations.length - 1].latitude;
        //     const lon2 = allLocations[allLocations.length - 1].longitude;
        //
        //     allLocations[allLocations.length - 1].distance = CalcDistance(lat1, lon1, lat2, lon2);
        //     allLocations[allLocations.length - 1].duration = durationFinal;
        //     allLocations[allLocations.length - 1].background = 'red';
        //     data.push(allLocations[allLocations.length - 1]);
        //   } else if (allLocations && allLocations.length === 1) {
        //     const startDate = moment(allLocations[0].created_at); // no
        //     const endDate = moment(result[0].created_at);
        //     let seconds = startDate.diff(endDate, 'seconds');
        //     const days = Math.floor(seconds / (24 * 3600));
        //     seconds -= days * 24 * 3600;
        //     // hours
        //     const hrs = Math.floor(seconds / 3600);
        //     seconds -= hrs * 3600;
        //     // minutes
        //     const minutes = Math.floor(seconds / 60);
        //     seconds -= minutes * 60;
        //
        //     const durationFinal = days ? `${days}d ${hrs}h` : hrs ? `${hrs}h ${minutes}m` : `${minutes}m`;
        //
        //     const lon1 = allLocations[0]?.longitude;
        //     const lat1 = allLocations[0]?.latitude;
        //     const lat2 = result[0].latitude;
        //     const lon2 = result[0].longitude;
        //
        //     allLocations[0].distance = CalcDistance(lat1, lon1, lat2, lon2);
        //     allLocations[0].duration = durationFinal;
        //     allLocations[0].background = 'black';
        //
        //     if (days > 0 || hrs > 0 || (minutes > 0 && !carrier) || carrier) {
        //       data.push(allLocations[0]);
        //     }
        //   }
        //   return key;
        // });

        const data = getTrackingHistory([
          ...(!carrier
            ? [
                {
                  id: Math.random(),
                  equipment_id: infoHeader?.driver_from_gps_lat?.equipment_id,
                  latitude: infoHeader?.driver_from_gps_lat,
                  longitude: infoHeader?.driver_from_gps_long,
                  hardware_model: null,
                  created_at: infoHeader?.created_at,
                  location: infoHeader?.assigned_to_1?.location,
                },
              ]
            : []),
          ...result,
        ]);

        // data.sort(function compare(a, b) {
        //   const dateA = new Date(a.created_at);
        //   const dateB = new Date(b.created_at);
        //   return dateB - dateA;
        // });

        data.sort(function compare(a, b) {
          const dateA = moment(a.created_at);
          const dateB = moment(b.created_at);
          return dateA.isAfter(dateB) ? -1 : 1;
        });

        // const lng = infoHeader?.driver_from_gps_long;
        // const lat = infoHeader?.driver_from_gps_lat;
        // const equipment_id = infoHeader?.driver_from_gps_lat?.equipment_id;

        // if (data.length && data.length > 1) {
        //   data.unshift({
        //     id: 453453,
        //     equipment_id,
        //     latitude: lat,
        //     longitude: lng,
        //     battery: null,
        //     location: infoHeader?.driver1?.location,
        //     distance: 0,
        //   });
        // }

        setHistoryData(data);
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function CalcDistance(lat11, lon1, lat22, lon2) {
    const R = 6371; // km
    const lat1 = toRad(lat11);
    const lat2 = toRad(lat22);
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  /** Map Data * */
  const mapDriverArr = useMemo(() => {
    const lng = infoHeader?.driver_from_gps_long;
    const lat = infoHeader?.driver_from_gps_lat;

    return [
      {
        id: infoHeader?.equipment?.id,
        img: infoHeader?.assigned_to_1?.image,
        miles: infoHeader?.empty_miles?.toFixed(),
        routeProps: {},
        marker: { lat, lng, draggable: false },
      },
    ];
  }, [infoHeader]);
  const stopPointZoom = (el) => {
    mapInstance.on('load', () => {
      mapInstance.zoomTo(17, {
        animate: true,
        duration: 5000,
      });
    });

    if (el?.longitude && el?.longitude) {
      mapInstance.setCenter({
        lat: el.latitude,
        lon: el.longitude,
      });
      mapInstance.zoomTo(17, { animate: true, duration: 5000 });
    }
  };

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  }, []);
  const getStopsData = () => {
    getStops({ shipment_id: infoHeader?.shipment_id }).then(
      (res) => res?.data && setLegs(orderingInLegsStops(res?.data))
    );
  };

  const bindPusher = () => {
    const channelCarrierLocation = `private-update-tracking-location.${user?.customer?.dot}`;

    PusherJs.unsubscribe(channelCarrierLocation);
    const channelCarrierLocation_table = PusherJs.subscribe(channelCarrierLocation);

    channelCarrierLocation_table.bind('update-tracking-location', (e) => {
      if (e?.message?.data?.shipment_id === infoHeader?.shipment_id && carrier) {
        getHistory({ id: infoHeader?.shipment_id });
        getStopsData();
      }
    });
  };

  const overViewRoute = useMemo(() => {
    const data = [];
    legs.forEach((leg) => {
      leg?.stops.forEach((stop) => {
        const { geofencing_latitude: latitude, geofencing_longitude: longitude } = stop?.stop_point || {};
        data.push({ latitude, longitude, stopPoint: true, stop_point_type: stop?.stop_point_type });
      });
    });
    return data;
  }, [legs]);
  const secondRouteArr = useMemo(() => {
    const data = [{ stops: [], routeOptions: { routeColor: palette.green400 } }];
    historyData.forEach((el) => {
      data[0].stops.push({
        stop_point_type: el?.background === 'red' ? 2 : 1,
        latitude: el?.latitude,
        longitude: el?.longitude,
        stopPoint: true,
      });
    });
    return data;
  }, [historyData]);
  /** Map Data * */

  /** Live-Data * */
  const liveData = useMemo(() => {
    let street;
    let city;
    let zip;
    let state;
    let updatedAt;
    const equipment = infoHeader?.equipment;
    if (equipment?.location_target === 'Driver' && !!equipment?.drivers) {
      const updated = equipment?.drivers?.[0]?.driver_gps?.updated_at || '';
      const { location } = equipment?.drivers?.[0] || {};
      const fields = location?.split(',');
      street = fields?.[0];
      city = fields?.[1];
      state = fields?.[2];
      zip = fields?.[3];
      updatedAt = updated;
    } else if (equipment?.location_target === 'Equipment') {
      const location = equipment?.equipment_gps?.location;
      const fields = location?.split(',');
      street = fields?.[0];
      city = fields?.[1];
      state = fields?.[2];
      zip = fields?.[3];
      updatedAt = equipment?.equipment_gps?.updated_at;
    }
    return { street, state, city, zip, updatedAt };
  }, [infoHeader]);

  const liveShowCond = useMemo(() => {
    const start = infoHeader?.start_date_time ? infoHeader.start_date_time : '';
    const end = infoHeader?.end_date_time ? infoHeader.end_date_time : moment();
    return (
      (moment(start).format('L') === moment(new Date()).format('L') ||
        moment(end).format('L') === moment(new Date()).format('L')) &&
      !!historyData.length
    );
  }, [infoHeader, historyData]);

  /** Live-Data * */
  useEffect(() => {
    if (!!isShipmentTable && !!infoHeader?.start_date_time && !carrier) {
      const id = infoHeader?.equipment?.id;
      const format1 = 'YYYY-MM-DD HH:mm:ss';
      let timeZone;
      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const nowDate =
        !!timeZone?.zone_name && moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format(format1);
      const now = new Date();
      const year = now.getUTCFullYear();
      const month = String(now.getUTCMonth() + 1).padStart(2, '0');
      const day = String(now.getUTCDate()).padStart(2, '0');
      const hour = String(now.getUTCHours()).padStart(2, '0');
      const minute = String(now.getUTCMinutes()).padStart(2, '0');
      const second = String(now.getUTCSeconds()).padStart(2, '0');
      const formattedDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
      const start = infoHeader?.start_date_time ? infoHeader?.start_date_time : nowDate;
      const end = infoHeader?.end_date_time ? moment(infoHeader.end_date_time).format(format1) : formattedDate;

      start && end && getHistory({ id, start, end }).then();
    }
  }, [infoHeader, isShipmentTable]);

  useEffect(async () => {
    if (carrier && infoHeader?.shipment_id) {
      await getHistory({ id: infoHeader?.shipment_id });
    }
  }, [carrier, infoHeader?.shipment_id]);

  const startAndEnd = useMemo(() => {
    try {
      const start = infoHeader?.start_date_time
        ? convertToCustomerTime(infoHeader?.start_date_time, null, true)
        : convertToCustomerTime(new Date(), null, true);
      const end = infoHeader?.end_date_time
        ? convertToCustomerTime(infoHeader.end_date_time, null, true)
        : convertToCustomerTime(new Date(), null, true);
      return { start, end };
    } catch (e) {
      // Do nothing
    }
  }, [infoHeader, isShipmentTable]);

  useEffect(() => {
    if (infoHeader?.shipment_id) {
      getStopsData();
    }
  }, [infoHeader?.shipment_id]);

  useEffect(() => {
    bindPusher();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.flexAlign}>
          <Typography style={{ marginRight: 12 }} variant='h4'>
            Tracking History
          </Typography>
          {infoHeader?.start_date_time && (
            <div className={classes.DatePickerRange}>
              <CalendarIcon style={{ marginRight: 6, marginBottom: 1 }} />
              <Typography>
                {startAndEnd?.start} -&nbsp;
                {startAndEnd?.end}
              </Typography>
            </div>
          )}
        </div>
        {!carrier && (
          <CustomButton
            type='primary'
            title='View Equipment'
            styleButton={{ padding: '6px 8px', margin: 0, fontSize: 14 }}
            onClick={() => {
              infoHeader?.equipment?.equipment_id &&
                navigate(`/equipment-profile/vehicle/${infoHeader?.equipment?.id}`);
            }}
          />
        )}
      </div>
      <div className={classes.body}>
        {((!!isShipmentTable && !!infoHeader?.start_date_time && !carrier) || carrier) && (
          <div className={classes.bodyLeft}>
            <Typography as='div' style={{ margin: '6px 0 0 21px' }} variant='h5'>
              Timeline
            </Typography>
            <div className={classes.line} />

            <div className={classes.containerData}>
              {loading ? (
                <SkeletonList />
              ) : (
                <>
                  {!!liveShowCond && !carrier && (
                    <div className={classes.itemWrapperLive}>
                      <div className={classes.itemIconWrapperGreen}>
                        <LiveIcon />
                        <div className={classes.verticalLineDashed} />
                      </div>
                      <div className={classes.greenTexts}>
                        <Typography variant='s2' style={{ color: palette.green500 }}>
                          Live Tracking...
                        </Typography>
                        <Typography variant='s2' className={classes.greenTextData}>
                          <Typography variant='overLine' style={{ fontSize: 14, fontWeight: 400 }}>
                            {liveData?.street ? `${liveData?.street},` : ''}
                          </Typography>
                          <Typography variant='overLine' style={{ fontSize: 14, fontWeight: 400 }}>
                            {liveData?.city ? `${liveData?.city},` : ''}
                            {liveData?.state ? `${liveData?.state},` : ''}
                            {liveData?.zip ? `${liveData?.zip},` : ''}
                          </Typography>
                          <Typography variant='overLine'>
                            {liveData?.updatedAt ? convertToCustomerTime(liveData.updatedAt) : ''}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  )}

                  {historyData.map((el, index) => {
                    if (el) {
                      const { stop, distance, background, location, duration, created_at } = el || {};
                      const miles = parseInt(distance, 10) * 0.621371;

                      return (
                        <div key={el?.id} className={classes.itemWrapper} onClick={() => stopPointZoom(el, index)}>
                          <div className={classes.itemIconWrapper}>
                            <div className={classNames(classes.stop, { [classes.redStop]: background === 'red' })}>
                              <Typography variant='c1'>{stop}</Typography>
                            </div>
                            {index !== historyData.length - 1 && <div className={classes.verticalLine} />}
                          </div>

                          <div className={classes.itemTexts}>
                            {index !== historyData.length - 1 && (
                              <div className={classes.positionBlock}>
                                <div className={classes.times}>
                                  <Typography variant='overLine'>{duration}</Typography>
                                </div>
                              </div>
                            )}

                            <div className={classes.timesWrapper}>
                              {background === 'red' ? (
                                <div style={{ marginBottom: 35 }}>
                                  <Typography variant='overLine2'>{location}</Typography>
                                  <Typography as='p' variant='overLine2' style={{ marginBottom: 0 }}>
                                    {convertToCustomerTime(created_at)}
                                    {duration && ` - Not moving`}
                                    <Typography
                                      variant='overLine2'
                                      style={{ color: palette.red400 }}
                                    >{` [${duration}]`}</Typography>
                                  </Typography>
                                  {index !== historyData.length - 1 && (
                                    <div className={classes.miles}>
                                      <Typography variant='c2'>
                                        {miles === 0 ? 0 : miles.toFixed(2)}&nbsp;Miles
                                      </Typography>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div style={{ marginBottom: 35 }}>
                                  <Typography variant='b2' style={{ background: palette.gray50 }}>
                                    {location}
                                  </Typography>
                                  <Typography as='p' variant='overLine' style={{ marginBottom: 0 }}>
                                    {convertToCustomerTime(created_at, 'lll')}
                                  </Typography>
                                  {index !== historyData.length - 1 && (
                                    <div className={classes.miles}>
                                      <Typography variant='c2'>
                                        {miles === 0 ? 0 : miles.toFixed(2)}&nbsp;Miles
                                      </Typography>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </>
              )}
            </div>
          </div>
        )}

        <div className={classes.bodyRight}>
          <TruckHistoryMap
            hookTo={!!infoHeader?.equipment?.hooked_to}
            infoHeader={infoHeader}
            classContainer={classes.map}
            markerToolTip
            dragRotate={false}
            stopIconColor
            driversArr={mapDriverArr}
            secondRouteArr={secondRouteArr}
            stopPointArr={overViewRoute}
            // setMapInstance={(map) => mapInstance = map}
            setMapInstance={(map) => setMapInstance(map)}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackingHistory;

const SkeletonList = () => {
  return (
    <div className={classes.skeletonList}>
      {Array(5)
        .fill(0)
        .map((e, i) => {
          return (
            <div key={e || i} className={classes.cardSkeleton}>
              <div className='d-flex'>
                <Skeleton style={{ width: '5%', borderRadius: '50%' }} className='mt-2 me-1' />
                <Skeleton style={{ width: '90%' }} className='mt-2' />
              </div>
              <div className='d-flex'>
                <Skeleton style={{ width: '5%' }} className='me-1' />
                <Skeleton style={{ width: '50%' }} />
              </div>
              <div className='d-flex'>
                <Skeleton style={{ width: '5%' }} className='me-1' />
                <Skeleton style={{ width: '70%' }} />
              </div>
              <div className='d-flex'>
                <Skeleton style={{ width: '5%' }} className='mb-2 me-1' />
                <Skeleton style={{ width: '60%' }} className='mb-2' />
              </div>
            </div>
          );
        })}
    </div>
  );
};
