import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import clockIcon from 'assets/icons/clock.svg';
import personIcon from 'assets/icons/person.svg';
import './Map.css';
import { Icons } from 'assets/icons';
import { mapFlag } from 'Api/Equipment';
import { PusherJs } from 'common/pusher';
import { useNavigate } from 'react-router-dom';
import { onChangeInventoryTab } from 'store/reducers/stopPoint.reducer';
import useDateFormat from 'hooks/useDateFormat';
import { STOP_POINT_TAB } from 'utils/constants';
import { EquipmentDetailsContext2 } from '../../EquipmentProfileTrailer/EquipmentProfileTrailer';
import { EquipmentDetailsContext } from '../../EquipmentDetails';
import { animateMarker, getLiveMarker, getMarker } from './helpers';

const Map = ({
  latitude,
  longitude,
  mapLayersValues,
  profileData,
  heading,
  trailerID,
  ismapstyleChange,
  reloadMap,
  stopPointVisible,
  POI,
  stopPointAll,
}) => {
  const {
    historyCoords,
    stopPointZoom,
    timeLine,
    setMapInstance,
    isCenter,
    setResetMyRoute,
    setDriverSpeed,
    connectedServicesData,
    selectedHistoryItem,
    setSelectedHistoryItem,
  } = useContext(trailerID === 'trailer' ? EquipmentDetailsContext2 : EquipmentDetailsContext);

  const { convertToCustomerTime } = useDateFormat();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coords, setCoords] = useState(null);
  const [myCustomMap, setMyCustomMap] = useState(null);
  const [liveCoords, setLiveCoords] = useState([]);
  const [stopPointMarker, setStopPointMarker] = useState([]);
  const [speedForDriver, setSpeedForDriver] = useState(0);
  const [speedForDriverData, setSpeedForDriverData] = useState(false);
  const isInitialMount = useRef(true);
  const dataRaw = localStorage.getItem('user');
  const data = JSON.parse(dataRaw);
  const StopPointArr = [];
  let GPS;
  let DRIVERGPS;
  const markers = [];
  let mapCoordinates = [];
  const initialRouteRendered = useRef(false);
  const [lastCoords, setLastCoords] = useState([]);

  const [isTrackingHistory, setIsTrackingHistory] = useState(false);
  useEffect(() => {
    setIsTrackingHistory(isCenter);
    if (myCustomMap) {
      myCustomMap.stop();
      myCustomMap.remove();
    }
  }, [isCenter]);

  useEffect(() => {
    if (
      profileData &&
      connectedServicesData &&
      connectedServicesData[0] &&
      connectedServicesData[0]?.equipment_connected_services[0]?.provider_device_id
    ) {
      localStorage.setItem('equipment_id', profileData.id);
      localStorage.setItem(
        'provider_device_id',
        trailerID === 'trailer' && profileData?.hooked_to
          ? profileData?.hooked_to?.equipment_connected_services_device?.provider_device_id
          : connectedServicesData[0]?.equipment_connected_services[0]?.provider_device_id
      );
      const body = {
        equipment_id: trailerID === 'trailer' && profileData?.hooked_to ? profileData?.hooked_to?.id : profileData.id,
        provider_device_id:
          trailerID === 'trailer' && profileData?.hooked_to
            ? profileData?.hooked_to?.equipment_connected_services_device?.provider_device_id
            : connectedServicesData[0]?.equipment_connected_services[0]?.provider_device_id,
      };
      mapFlag({ body })
        .then()
        .catch(() => {
          // Do nothing
        });
    }
  }, [connectedServicesData]);

  const bindPusher = () => {
    if (trailerID === 'trailer' && profileData?.hooked_to) {
      GPS = PusherJs.subscribe(`private-equipmentLocation-Update.${data?.customer?.dot}.${profileData?.hooked_to?.id}`);
    } else {
      GPS = PusherJs.subscribe(`private-equipmentLocation-Update.${data?.customer?.dot}.${profileData?.id}`);
    }
    if (trailerID === 'trailer' && profileData?.hooked_to) {
      DRIVERGPS = PusherJs.subscribe(
        `private-driverLocation-Update.${data?.customer?.dot}.${profileData?.hooked_to?.drivers?.[0]?.id}`
      );
    } else {
      DRIVERGPS = PusherJs.subscribe(
        `private-driverLocation-Update.${data?.customer?.dot}.${profileData?.drivers?.[0]?.id}`
      );
    }
  };

  useEffect(() => {
    if (!coords && longitude && latitude) {
      setCoords([{ longitude, latitude }]);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!Object.keys(profileData).length) return;
      bindPusher();
      // MapCoords()
      //     .then((mapHistory) => {
      init([], isTrackingHistory, liveCoords, speedForDriver, setSpeedForDriver, setSpeedForDriverData);
      // })
      // .catch(() => {
      //     init([]);
      // });

      // eslint-disable-next-line no-inner-declarations
      async function init(mapHistory, isCenter, liveCoords, speedForDriver, setSpeedForDriver, setSpeedForDriverData) {
        TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
        const myMap = new TrimbleMaps.Map({
          container: 'myMap',
          style: TrimbleMaps.Common.Style.BASIC,
          center: new TrimbleMaps.LngLat(longitude, latitude),
          zoom: 3,
        });
        if (myMap) {
          setMyCustomMap(myMap);
          setMapInstance(myMap);
        }
        if (reloadMap) {
          unSubscribePushers();
          bindPusher();
        }

        const marker = new TrimbleMaps.Marker({
          draggable: false,
          anchor: 'center',
          rotation: heading || '',
          element: getMarker(profileData, trailerID, speedForDriver),
        });

        if (longitude && latitude && coords) {
          coords.forEach(() => {
            if (historyCoords?.length && historyCoords?.some((i) => !i.isLiveMode)) {
              return;
            }
            marker.setLngLat([longitude, latitude]).addTo(myMap);
            markers.push(marker);
          });

          myMap.on('load', async () => {
            myMap.zoomTo(14, {
              animate: !ismapstyleChange,
              duration: 5000,
            });
            myMap.addControl(new TrimbleMaps.FullscreenControl());
            myMap.addControl(new TrimbleMaps.NavigationControl());

            const formattedHistory = mapHistory.map((m) => {
              return {
                longitude: parseFloat(m.longitude),
                latitude: parseFloat(m.latitude),
              };
            });
            formattedHistory.reverse();

            myMap.addLayer({
              id: 'truck-stops',
              type: 'symbol',
              source: 'Base',
              'source-layer': 'poi',
              paint: {
                'icon-halo-color': 'rgba(255, 255, 255, 1)',
                'icon-halo-width': 5,
                'icon-translate': [0, -1],
                'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                'text-halo-width': 1.5,
                'text-color': 'rgba(5, 57, 150, 1)',
              },
              layout: {
                'icon-image': 'poi_truck_stop',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 9, 0.7, 12, 1],
                'text-field': ['step', ['zoom'], '', 12, ['get', 'name']],
                'text-font': ['Roboto Regular'],
                'text-anchor': 'top',
                'text-size': 11,
                'text-offset': [0, 1],
              },
              filter: ['match', ['get', 'type'], ['truck_stop'], true, false],
            });
            myMap.setLayoutProperty('truck-stops', 'visibility', 'none');

            myMap.on('click', 'truck-stops', (evt) => {
              const popupLocation = evt.features[0].geometry.coordinates.slice();
              const popupContent = `Name: ${evt.features[0].properties.name}<br />
                    ID: ${evt.features[0].properties.poi}<br />
                    Set: ${evt.features[0].properties.set}`;

              new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent).addTo(myMap);
            });

            let TriggerInterval;
            if (
              profileData?.location_target === 'Driver' ||
              (trailerID === 'trailer' && profileData.hooked_to?.location_target === 'Driver')
            ) {
              DRIVERGPS?.bind('client-equipment-location', (data) => {
                if (typeof data === 'string') {
                  data = JSON.parse(data.replace(/:\s*0+(\d+)/g, (match, p1) => `: "${p1}"`));
                }
                if (marker) {
                  clearInterval(TriggerInterval);
                  setSpeedForDriver(data?.message?.data?.speed_mph);
                  if (speedForDriverData !== true) {
                    setSpeedForDriverData(true);
                  }
                  setDriverSpeed(data?.message?.data?.speed_mph);
                  const oldLat = marker.getLngLat()?.lat;
                  const oldLng = marker.getLngLat()?.lng;
                  const lat = data?.message?.data?.latitude;
                  const lng = data?.message?.data?.longitude;
                  const is_hooked = data?.message?.data?.is_hooked;
                  const speed = Number(data?.message?.data?.speed_mph) || 0;
                  const mapMarker = marker.getElement();
                  const liveMarker = getLiveMarker(profileData, trailerID, speed, is_hooked, null);
                  const liveSrc = liveMarker.getAttribute('src');

                  mapMarker.setAttribute('src', liveSrc);
                  animateMarker(marker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                  if (data?.message?.data?.heading) {
                    marker.setRotation(data?.message?.data?.heading);
                  }
                  setLiveCoords({
                    latitude: data?.message?.data?.latitude,
                    longitude: data?.message?.data?.longitude,
                  });

                  setLastCoords((prevState) => [
                    {
                      latitude: prevState[1]?.latitude,
                      longitude: prevState[1]?.longitude,
                    },
                    {
                      latitude: data?.message?.data?.latitude,
                      longitude: data?.message?.data?.longitude,
                    },
                  ]);
                }
              });
            } else {
              GPS &&
                GPS.bind('equipment-location', (data) => {
                  if (typeof data === 'string') {
                    data = JSON.parse(data.replace(/:\s*0+(\d+)/g, (match, p1) => `: "${p1}"`));
                  }
                  if (marker) {
                    setDriverSpeed(data?.message?.data?.speed_mph);
                    const oldLat = marker.getLngLat()?.lat;
                    const oldLng = marker.getLngLat()?.lng;
                    const lat = data?.message?.data?.latitude;
                    const lng = data?.message?.data?.longitude;
                    const engine_state = data?.message?.data?.engine_state;
                    const speed = Number(data?.message?.data?.speed_mph) || 0;
                    const mapMarker = marker.getElement();
                    const liveMarker = getLiveMarker(
                      profileData,
                      trailerID,
                      speed,
                      !!profileData.hooked_to,
                      engine_state
                    );
                    const liveSrc = liveMarker.getAttribute('src');
                    mapMarker.setAttribute('src', liveSrc);

                    animateMarker(marker, { lat, lng }, { lat: oldLat, lng: oldLng }, speed);

                    setLastCoords((prevState) => [
                      {
                        latitude: prevState[1]?.latitude,
                        longitude: prevState[1]?.longitude,
                      },
                      {
                        latitude: data?.message?.data?.latitude,
                        longitude: data?.message?.data?.longitude,
                      },
                    ]);
                    if (data?.message?.data?.heading) {
                      marker.setRotation(data?.message?.data?.heading);
                    }
                    const arrayMarker = [...mapCoordinates];
                    if (
                      arrayMarker &&
                      arrayMarker.length &&
                      arrayMarker.some(
                        (a) =>
                          parseFloat(a.longitude) !== parseFloat(data?.message?.data?.longitude) ||
                          parseFloat(a.latitude) !== parseFloat(data?.message?.data?.latitude)
                      )
                    ) {
                      arrayMarker.push({
                        longitude: data?.message?.data?.longitude,
                        latitude: data?.message?.data?.latitude,
                        heading: data?.message?.data?.heading,
                      });
                      mapCoordinates = arrayMarker;
                    } else {
                      arrayMarker.push({
                        longitude: data?.message?.data?.longitude,
                        latitude: data?.message?.data?.latitude,
                        heading: data?.message?.data?.heading,
                      });
                      mapCoordinates = arrayMarker;
                      if (data?.message?.data?.heading) {
                        marker.setRotation(data?.message?.data?.heading);
                      }
                    }
                  }
                });
            }
          });
        }
      }
    }
    return () => {
      unSubscribePushers();
    };
  }, [coords, isTrackingHistory, reloadMap, historyCoords]);

  useEffect(() => {
    if (
      historyCoords?.length &&
      historyCoords[historyCoords.length - 1].isLiveMode &&
      lastCoords[1]?.latitude &&
      lastCoords[1]?.longitude &&
      (lastCoords[1]?.latitude !== lastCoords[0]?.latitude ||
        lastCoords[1]?.longitude !== lastCoords[0]?.longitude ||
        !initialRouteRendered.current)
    ) {
      const routeId = `myRoute-${Math.random()}`;
      const myRoute = new TrimbleMaps.Route({
        routeId,
        isDraggable: false,
        stops: [
          initialRouteRendered.current ? lastCoords[0] : historyCoords[historyCoords.length - 1],
          lastCoords[1],
        ].map((tracking) => new TrimbleMaps.LngLat(tracking?.longitude, tracking?.latitude)),
        frameRoute: false,
        routeColor: '#4F5AED',
        routeWidth: 5,
        showStops: false,
      });
      if (myRoute) {
        setResetMyRoute(myRoute);
      }
      setTimeout(() => {
        myRoute.addTo(myCustomMap);
      }, 1000);
      initialRouteRendered.current = true;
    }
  }, [historyCoords, lastCoords]);

  useEffect(() => {
    if (!myCustomMap) return;
    if (!historyCoords) return;

    const markerElements = [];

    setTimeout(() => {
      historyCoords?.forEach((tracking, i) => {
        if (tracking.background === 'red') {
          const input = tracking.location;
          const fields = input.split(',');
          const street = fields[0];
          const City = fields[1];
          const State = fields[2];
          const Zip = fields[3];
          const el = document.createElement('div');
          el?.classList?.add(`cust-marker${i}`);
          const htmlContent = `<div id='image' style='background: url(${Icons.RedSteps}) no-repeat; height: 26px; width: 26px;'/>`;
          el.innerHTML = htmlContent;
          const popupContent = `
            <div style='padding: 8px'>
              <p style='font-size: 15px; font-weight: 700; margin-bottom: 4px'>
                ${street ? `${street}` : ''}</br>
                ${City ? `${City},` : ''} ${State ? `${State},` : ''} ${Zip ? `${Zip}` : ''}
              </p>
              <p style='font-size: 14px; font-weight: 400; margin-bottom: 4px'>
                ${convertToCustomerTime(tracking.created_at)}
              </p>
              <div style='display: ${tracking?.driver ? 'flex' : 'none'}; align-items: center; column-gap: 8px'>
                <img src='${personIcon}' alt='person' width='16' />
                <p style='font-size: 14px; font-weight: 400; margin-bottom: 0'>
                   ${tracking.driver?.fname} ${tracking.driver?.lname}
                </p>
              </div>
              <div style='display: flex; align-items: center; column-gap: 8px'>
                <img src='${clockIcon}' alt='clock' />
                <p style='font-size: 14px; font-weight: 400; margin-bottom: 0'>
                   Stopped for ${tracking.duration}
                </p>
              </div>
            </div>
          `;

          const marker = new TrimbleMaps.Marker({
            element: el,
            color: 'red',
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .setPopup(new TrimbleMaps.Popup().setHTML(popupContent))
            .addTo(myCustomMap);

          const markerElement = marker.getElement();

          const listener = () => {
            stopPointZoom(tracking.initialIndex);
            setSelectedHistoryItem(tracking);
          };

          markerElement.addEventListener('click', listener);

          if (selectedHistoryItem && selectedHistoryItem?.id === tracking.id) {
            markerElement.click();
          }

          markerElements.push({ element: markerElement, listener });

          return marker;
        }
        // const input = tracking.location;
        // const fields = input.split(',');
        // const street = fields[0];
        // const City = fields[1];
        // const State = fields[2];
        // const Zip = fields[3];
        // const el = document.createElement('div');
        // el.classList.add(`black-marker${i}`);
        // // el.title = "Marker with a label and an icon";
        // const htmlContent = `<div id='image' style='background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;'/>`;
        // el.innerHTML = htmlContent;
        // const marker = new TrimbleMaps.Marker({
        //   color: 'blue',
        //   element: el,
        // })
        //   .setLngLat([tracking?.longitude, tracking?.latitude])
        //   .setPopup(
        //     new TrimbleMaps.Popup().setHTML(
        //       `<p>${street ? `${street},` : ''}</br>${City ? `${City},` : ''} ${State ? `${State},` : ''} ${
        //         Zip ? `${Zip},` : ''
        //       }</br>${moment(tracking.created_at).format('L hh:mm A')}</p>`
        //     )
        //   )
        //   .addTo(myCustomMap);
        // return marker;
      });
      // }, 3000);
      // setTimeout(() => {

      const routeId = `myRoute-${Math.random()}`;
      const myRoute = new TrimbleMaps.Route({
        routeId,
        isDraggable: false,
        stops: [...historyCoords.map((tracking) => new TrimbleMaps.LngLat(tracking?.longitude, tracking?.latitude))],
        frameRoute: false,
        routeColor: '#4F5AED',
        routeWidth: 5,
        showStops: false,
      });
      if (myRoute) {
        setResetMyRoute(myRoute);
      }
      myRoute.addTo(myCustomMap);
    }, 2000);

    return () => {
      if (markerElements?.length) {
        markerElements.forEach((item) => {
          item.element?.removeEventListener('click', item.listener);
        });
      }
    };
  }, [timeLine, myCustomMap, historyCoords, selectedHistoryItem?.id]);

  useEffect(() => {
    if (!myCustomMap) return;
    mapLayersValues && mapLayersValues === 'SATELLITE'
      ? myCustomMap.setStyle(TrimbleMaps.Common.Style.SATELLITE)
      : mapLayersValues === 'TRANSPORTATION_DARK'
      ? myCustomMap.setStyle(TrimbleMaps.Common.Style.TRANSPORTATION_DARK)
      : myCustomMap.setStyle(TrimbleMaps.Common.Style.BASIC);
  }, [mapLayersValues, myCustomMap]);

  const unSubscribePushers = () => {
    if (GPS) {
      if (trailerID === 'trailer' && profileData?.hooked_to) {
        GPS.unsubscribe(`private-equipmentLocation-Update.${data?.customer?.dot}.${profileData?.hooked_to?.id}`);
      } else {
        GPS.unsubscribe(`private-equipmentLocation-Update.${data?.customer?.dot}.${profileData?.id}`);
      }
      GPS = null;
    }
    if (DRIVERGPS) {
      if (trailerID === 'trailer' && profileData?.hooked_to) {
        DRIVERGPS.unsubscribe(
          `private-driverLocation-Update.${data?.customer?.dot}.${profileData?.hooked_to?.drivers?.[0]?.id}`
        );
      } else {
        DRIVERGPS.unsubscribe(`private-driverLocation-Update.${data?.customer?.dot}.${profileData?.drivers?.[0]?.id}`);
      }
      DRIVERGPS = null;
    }
  };

  useEffect(() => {
    if (
      profileData &&
      connectedServicesData &&
      connectedServicesData[0] &&
      connectedServicesData[0]?.equipment_connected_services[0]?.provider_device_id
    ) {
      localStorage.setItem('equipment_id', profileData.id);
      localStorage.setItem(
        'provider_device_id',
        trailerID === 'trailer' && profileData?.hooked_to
          ? profileData?.hooked_to?.equipment_connected_services_device?.provider_device_id
          : connectedServicesData[0]?.equipment_connected_services[0]?.provider_device_id
      );

      const body = {
        equipment_id: trailerID === 'trailer' && profileData?.hooked_to ? profileData?.hooked_to?.id : profileData.id,
        provider_device_id:
          trailerID === 'trailer' && profileData?.hooked_to
            ? profileData?.hooked_to?.equipment_connected_services_device?.provider_device_id
            : connectedServicesData[0]?.equipment_connected_services[0]?.provider_device_id,
      };
      mapFlag({ body })
        .then()
        .catch(() => {
          // Do nothing
        });
    }

    return () => {
      if (!PusherJs) return;

      const eqpId = localStorage.getItem('equipment_id');
      const pId = localStorage.getItem('provider_device_id');

      if (eqpId) {
        const body = {
          equipment_id: trailerID === 'trailer' && profileData?.hooked_to ? profileData?.hooked_to?.id : profileData.id,
          provider_device_id: pId,
          delete_flag: 1,
        };

        mapFlag({ body })
          .then()
          .catch(() => {
            // Do nothing
          });
        localStorage.removeItem('equipment_id');
        localStorage.removeItem('provider_device_id');
      }
    };
  }, []);

  function handleSpanClick(stopId, count, equipmentType) {
    dispatch(onChangeInventoryTab(equipmentType));
    navigate(`/stop-point/${stopId}/${STOP_POINT_TAB.INVENTORY}`);
  }

  useEffect(() => {
    if (!myCustomMap) return;
    if (stopPointVisible) {
      if (stopPointAll.length > 1) {
        stopPointAll?.forEach((StopPointsData, i) => {
          const address1 = StopPointsData?.value?.address1;
          const address2 = StopPointsData?.value?.address2;
          const city = StopPointsData?.value?.city;
          const state = StopPointsData?.value?.state;
          const zipcode = StopPointsData?.value?.zipcode;
          const country = StopPointsData?.value?.country;
          const StopPointName = StopPointsData?.value?.current_location;
          const stop_point_vehicles_count = StopPointsData?.value?.stop_point_vehicles_count;
          const stop_point_trailers_count = StopPointsData?.value?.stop_point_trailers_count;
          const stopId = StopPointsData?.id;

          const el = document.createElement('div');
          el?.classList?.add(`black-marker${i}`);
          // Create a popup
          const popup = new TrimbleMaps.Popup({
            offset: 0,
          }).setHTML(
            `<span style="font-family: 'Inter', sans-serif; font-size: 18px; font-weight: bold; color: #000000; display: block; margin-bottom: 10px;">${StopPointName}</span>
              <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${address1}</span>
              <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${address2}</span>
              <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${city}, ${state} ${zipcode}</span>
              <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${country}</span>
              <div style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">Current Vehicles - <span style="font-weight: bold; color: #4F5AED; cursor: pointer" id='equipment-map-vehicle-in-stop'>${stop_point_vehicles_count}</span></div>
              <div style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">Current Trailers - <span style="font-weight: bold; color: #4F5AED; cursor: pointer" id='equipment-map-trailer-in-stop'>${stop_point_trailers_count}</span></div>
             `
          );

          popup.on('open', () => {
            const vehicleSpan = document.getElementById('equipment-map-vehicle-in-stop');
            vehicleSpan?.addEventListener('click', () => handleSpanClick(stopId, stop_point_vehicles_count, 1));

            const trailerSpan = document.getElementById('equipment-map-trailer-in-stop');
            trailerSpan?.addEventListener('click', () => handleSpanClick(stopId, stop_point_trailers_count, 2));
          });

          const ele = document.createElement('div');
          ele?.classList?.add(`cust-marker${i}`);
          ele.innerHTML = `<div id='image' style='background: url(${Icons.StopPoint}) no-repeat; height: 26px; width: 26px;'/>`;
          const marker2 = new TrimbleMaps.Marker({
            element: ele,
            color: 'red',
          })
            .setLngLat([StopPointsData?.value?.longitude, StopPointsData?.value?.latitude])
            .setPopup(popup)
            .addTo(myCustomMap);
          StopPointArr.push(marker2);
          setStopPointMarker(StopPointArr);
        });
      }
    } else if (stopPointMarker.length) {
      stopPointMarker.map((M) => M.remove());
    }
  }, [myCustomMap, stopPointAll, stopPointVisible]);

  useEffect(() => {
    if (!myCustomMap) return;

    if (myCustomMap && myCustomMap.isStyleLoaded()) {
      const poiVisible = myCustomMap?.getLayer('truck-stops');
      if (POI) {
        myCustomMap.setLayoutProperty('truck-stops', 'visibility', 'visible');
      } else if (poiVisible) {
        myCustomMap.setLayoutProperty('truck-stops', 'visibility', 'none');
      }
    }
  }, [myCustomMap, POI]);

  return (
    <div className='map_container_equipment'>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
      </div>
    </div>
  );
};

export default Map;
