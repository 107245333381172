import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as DotIcon } from 'assets/icons/dot.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SAddressFields, SCompanyInfo, SHeader } from '../ShipmentBid.styles';

const Header = ({ company, shipment }) => {
  const matches = useMediaQuery('(max-width:600px)');

  const { company_name, address1, city, state, phone_number, logo, company_email, zipcode, dot } = company || {};
  const { shipment_id } = shipment || {};

  return (
    <SHeader>
      <SCompanyInfo>
        <div className='logo-wrapper'>{logo && <img src={logo} alt='Company logo' height={48} />}</div>
        {!matches && (
          <Typography variant='h2' style={{ textAlign: 'center' }}>
            Shipment {shipment_id}
          </Typography>
        )}
        <SAddressFields>
          <Typography variant='s2'>{company_name}</Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {address1}
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {city?.name}, {state?.short_name || state?.name} {zipcode}
          </Typography>
          <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }} className='d-flex align-items-center gap-1'>
              <PhoneIcon width={21} height={21} fill={palette.indigo500} /> {formatPhoneNumberIntl(phone_number)}
            </Typography>
          </a>
          <a href={`mailto:${company_email}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }} className='d-flex align-items-center gap-1'>
              <MailIcon fill={palette.indigo500} /> {company_email}
            </Typography>
          </a>
          <a
            href={`https://safer.fmcsa.dot.gov/query.asp?query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${dot}`}
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Typography variant='b2' style={{ color: palette.gray700 }} className='d-flex align-items-center gap-1'>
              <DotIcon width={18} height={18} fill={palette.indigo500} /> {dot}
            </Typography>
          </a>
        </SAddressFields>
      </SCompanyInfo>
    </SHeader>
  );
};

export default Header;
