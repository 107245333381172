import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const STitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 6px;

  :hover {
    background-color: ${palette.gray0};
  }
`;

export const SSidebarHeader = styled.div`
  padding: 0 8px;
  position: absolute;
  width: 400px;
  top: 3px;

  .table-header-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`;

export const SCalendar = styled.div`
  flex: 1;
  overflow: auto;

  .react-calendar-timeline {
    .rct-scroll {
      ::-webkit-scrollbar {
        display: none;
      }
    }

    .rct-header-root {
      background-color: ${palette.white};
      border-bottom: none;

      > div:first-child {
        border-right: 1px solid ${palette.gray100};
        border-bottom: 1px solid ${palette.gray100};
      }

      .rct-calendar-header {
        border-top: 1px solid ${palette.gray100};
        border-left: none;
        border-bottom: none;
        border-right: none;
      }

      .rct-dateHeader-primary {
        pointer-events: none;
        background-color: ${palette.white};
        color: ${palette.indigo500};
        border-bottom: 1px solid ${palette.gray100};
        border-left: 1px solid ${palette.gray100};
        border-right: none;

        span {
          //display: none;
        }

        &:hover {
          background-color: ${palette.indigo50};
        }
      }

      .rct-dateHeader {
        &:not(.rct-dateHeader-primary) {
          background-color: ${palette.white};
          border-bottom: 1px solid ${palette.gray100};
          border-left: 1px solid ${palette.gray100};
          color: ${palette.gray700};
          font-weight: 500;
          font-family: 'Inter', sans-serif;
          font-size: 11px;
          line-height: 110%;
          text-align: center;

          &:hover {
            background-color: ${palette.indigo50};
          }
        }
      }
    }

    .rct-outer {
      .rct-sidebar {
        border-right: 1px solid ${palette.gray100};

        .rct-sidebar-row {
          padding: 0;
          border-bottom: 1px solid ${palette.gray50};

          &.rct-sidebar-row-odd {
            background-color: ${palette.white};
          }
        }
      }

      .rct-vertical-lines {
        .rct-vl {
          z-index: 2 !important;
          border-left: 1px solid ${palette.gray100};
        }
      }

      .rct-horizontal-lines {
        > div {
          border-bottom: 1px solid ${palette.gray50};
        }

        .rct-hl-odd {
          background-color: ${palette.white};
        }
      }

      .rct-items {
        .rct-item {
          background: ${palette.white} !important;
          border: none !important;
          z-index: 3 !important;

          .rct-item-content {
            width: 100%;
            padding: 0;
            border-radius: 4px;

            .timeline-item {
              height: 100%;
              border-radius: 4px;
              padding: 0 6px;
              border: none;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`;
