import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SButtonWrapper = styled.div`
  position: relative;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;

  .items-count {
    position: absolute;
    top: -14px;
    right: -14px;
    color: ${palette.white};
    border-radius: 24px;
    border: 2px solid ${palette.white};
    background-color: ${palette.indigo500};

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    min-width: 26px;
    height: 26px;
  }
`;
