import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 600px;
  border: 1px solid ${palette.gray50};
  border-radius: 8px;
  background-color: ${palette.white};

  .feature-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    padding: 8px;
  }

  .feature-body {
    position: relative;
    padding: 12px 12px 32px 24px;
    height: calc(100% - 40px);

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 0;
      margin: 0;
    }
  }

  .label-chip {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background: ${palette.indigo500};
    border-radius: 10px;
    height: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

export const SAccordion = styled(Accordion)`
  &&&.MuiAccordion-root {
    box-shadow: none;
    position: static;
    border-radius: 8px;

    && .MuiAccordionSummary-root {
      padding: 0;
      min-height: unset;
      align-items: center;
      position: static;
      transform: unset;

      .MuiAccordionSummary-content {
        display: flex;
        justify-content: center;
        margin: 0;
      }
    }

    && .MuiAccordionDetails-root {
      padding: 0;
    }

    .expand-arrow {
      transform: rotate(90deg);
      transition: 0.4s;

      &.reverse {
        transform: rotate(-90deg);
      }
    }
  }
`;
