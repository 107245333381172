import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { STATUS_FILTER_DATA_PENDING } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { initialValues } from './AdvancedFilter.data';
import { SDatePicker, SWrapper } from './AdvancedFilter.styles';

const AdvancedFilter = ({ filters, updateFilters, loading, groups, stopPoints, customers, carriers, drivers }) => {
  const [pickupRange, setPickupRange] = useState(filters.pickupRange);
  const [deliveryRange, setDeliveryRange] = useState(filters.deliveryRange);

  const onSubmit = (values) => {
    updateFilters((prevState) => ({ ...prevState, ...values, pickupRange, deliveryRange }));
  };

  const { values, handleChange, handleSubmit, resetForm } = useForm({
    initialValues: {
      shipment_id: filters.shipment_id || '',
      reference_id: filters.reference_id || '',
      group: filters.group || [],
      statuses: filters.statuses || [],
      stop_points: filters.stop_points || [],
      customers: filters.customers || [],
      carriers: filters.carriers || [],
      drivers: filters.drivers || [],
      origin_city: filters.origin_city || '',
      origin_state: filters.origin_state || '',
      origin_zipcode: filters.origin_zipcode || '',
      origin_miles: filters.origin_miles || '50',
      destination_city: filters.destination_city || '',
      destination_state: filters.destination_state || '',
      destination_miles: filters.destination_miles || '50',
      destination_zipcode: filters.destination_zipcode || '',
      offer_to_drivers: filters.offer_to_drivers || [],
      offer_to_carriers: filters.offer_to_carriers || [],
    },
    onSubmit,
    enableReinitialize: true,
  });

  const onRemoveRange = (type) => {
    if (type === 'pickup') {
      setPickupRange([null, null]);
    } else {
      setDeliveryRange([null, null]);
    }
  };

  const onClearAll = () => {
    updateFilters((prevState) => ({ ...prevState, ...initialValues }));
    resetForm();
    onRemoveRange('pickup');
    onRemoveRange('delivery');
  };

  return (
    <SWrapper>
      <div className='d-flex gap-4'>
        <div>
          <div className='w-100'>
            <Input
              label='Shipment ID'
              width='200px'
              name='shipment_id'
              placeholder='Shipment ID'
              onChange={handleChange}
              value={values.shipment_id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
          <div className='w-100 mt-2'>
            <Input
              label='Reference ID'
              width='200px'
              name='reference_id'
              placeholder='Reference ID'
              onChange={handleChange}
              value={values.reference_id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              label='Status'
              width='200px'
              labelKey='title'
              multiple
              limitTags={2}
              placeholder={values.statuses?.length ? '' : 'Select Status'}
              options={STATUS_FILTER_DATA_PENDING}
              value={values.statuses}
              onChange={(e, val) => handleChange('statuses', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
        </div>
        <div>
          <div className='w-100'>
            <Autocomplete
              label='Tags'
              width='200px'
              labelKey='title'
              multiple
              limitTags={2}
              options={groups}
              placeholder={values.group?.length ? '' : 'Select Tags'}
              value={values.group}
              onChange={(e, val) => handleChange('group', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              label='Stop Point'
              width='200px'
              labelKey='location_name'
              multiple
              limitTags={2}
              options={stopPoints}
              placeholder={values.stop_points?.length ? '' : 'Select Stop Point'}
              value={values.stop_points}
              onChange={(e, val) => handleChange('stop_points', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              label='Customer'
              width='200px'
              labelKey='company_name'
              multiple
              limitTags={2}
              options={customers}
              placeholder={values.customers?.length ? '' : 'Select Customer'}
              value={values.customers}
              onChange={(e, val) => handleChange('customers', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
        </div>
        <div>
          <div className='w-100'>
            <InputLabel className='mb-0'>Origin</InputLabel>
            <div className='d-flex gap-2'>
              <Input
                name='origin_city'
                placeholder='City'
                onChange={handleChange}
                value={values.origin_city}
                labelProps={{ className: 'mb-0' }}
              />
              <Input name='origin_state' placeholder='State' onChange={handleChange} value={values.origin_state} />
              <div>
                <Input
                  name='origin_zipcode'
                  placeholder='Zip Code'
                  onChange={handleChange}
                  value={values.origin_zipcode}
                />
              </div>
              <div>
                <Input name='origin_miles' placeholder='Miles' onChange={handleChange} value={values.origin_miles} />
              </div>
            </div>
          </div>
          <div className='w-100 mt-2'>
            <InputLabel className='mb-0'>Destination</InputLabel>
            <div className='d-flex gap-2'>
              <Input
                name='destination_city'
                placeholder='City'
                onChange={handleChange}
                value={values.destination_city}
              />
              <Input
                name='destination_state'
                placeholder='State'
                onChange={handleChange}
                value={values.destination_state}
              />
              <div>
                <Input
                  name='destination_zipcode'
                  placeholder='Zip Code'
                  onChange={handleChange}
                  value={values.destination_zipcode}
                />
              </div>
              <div>
                <Input
                  name='destination_miles'
                  placeholder='Miles'
                  onChange={handleChange}
                  value={values.destination_miles}
                />
              </div>
            </div>
          </div>
          <div className='w-100 mt-2'>
            <InputLabel className='mb-0'>Offered To</InputLabel>
            <div className='d-flex gap-2'>
              <Autocomplete
                multiple
                limitTags={2}
                placeholder={values.offer_to_drivers?.length ? '' : 'Select Drivers..'}
                options={drivers}
                value={values.offer_to_drivers || []}
                onChange={(e, val) => handleChange('offer_to_drivers', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                labelProps={{ className: 'mb-0' }}
                getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                getTagLabel={(option) => `${option.fname} ${option.lname}`}
              />
              <Autocomplete
                multiple
                limitTags={2}
                placeholder={values.offer_to_carriers?.length ? '' : 'Select Carriers..'}
                options={carriers}
                value={values.offer_to_carriers || []}
                onChange={(e, val) => handleChange('offer_to_carriers', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                labelProps={{ className: 'mb-0' }}
              />
            </div>
          </div>
        </div>

        <div>
          <div className='w-100 d-flex flex-column'>
            <InputLabel className='mb-0'>Pick Up Date Range</InputLabel>
            <div className='position-relative'>
              <SDatePicker
                selectsRange
                renderStart={false}
                shouldCloseOnSelect={false}
                startDate={pickupRange[0]}
                endDate={pickupRange[1]}
                placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                onChange={(dates) => setPickupRange(dates)}
              />
              <DeleteIcon className='delete-icon' onClick={() => onRemoveRange('pickup')} />
            </div>
          </div>
          <div className='w-100 d-flex flex-column mt-2'>
            <InputLabel className='mb-0'>Delivery Date Range</InputLabel>
            <div className='position-relative'>
              <SDatePicker
                selectsRange
                renderStart={false}
                shouldCloseOnSelect={false}
                startDate={deliveryRange[0]}
                endDate={deliveryRange[1]}
                placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                onChange={(dates) => setDeliveryRange(dates)}
              />
              <DeleteIcon className='delete-icon' onClick={() => onRemoveRange('delivery')} />
            </div>
          </div>
        </div>
      </div>
      <div className='w-100 d-flex justify-content-center mt-3'>
        <CustomButton
          type='secondary'
          title='Clear All'
          className='ms-3'
          onClick={onClearAll}
          styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
          disabled={loading}
        />
        <CustomButton
          type='primary'
          onClick={handleSubmit}
          disabled={loading}
          leftIcon={
            loading ? (
              <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
            ) : (
              <div />
            )
          }
          title='Search'
          className='ms-3'
          styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
        />
      </div>
    </SWrapper>
  );
};

export default AdvancedFilter;
