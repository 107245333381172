import React from 'react';
import ReactCodeInput from 'react-code-input';
import ErrorMessage from 'common/message/errorMessage';

const ForthStep = ({
  handleSubmission,
  handleResendOtp,
  otpTimer,
  phoneNumber,
  errorCode,
  setDisplayErrorCode,
  displayErrorCode,
  successMsg,
  setdisplayOtpSuccessMsg,
  displayOtpSuccessMsg,
  verifyCode,
  handleCodeChange,
}) => {
  // for timer
  let seconds = otpTimer % 60;
  let minutes = Math.floor(otpTimer / 60);
  minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;

  return (
    <>
      <div className='modal-header-custom'>
        <h1 className='welcome-bg'>Two-step authentication</h1>
        <p className='welcome-label'>
          Please check your phone and enter the 5 digit verification code sent to
          {phoneNumber ? ` ${phoneNumber}` : ''}
        </p>
      </div>
      <div className='modal-body-custom '>
        <ErrorMessage message={errorCode || ''} close={() => setDisplayErrorCode(false)} show={displayErrorCode} />
        <ErrorMessage
          successMsg={successMsg || ''}
          close={() => setdisplayOtpSuccessMsg(false)}
          show={displayOtpSuccessMsg}
        />

        <ReactCodeInput type='text' value={verifyCode} fields={5} onChange={handleCodeChange} />

        <div className='timmer-btn'>
          <p style={{ color: otpTimer === 0 ? 'gray' : '' }}>{`${minutes}:${seconds}`}</p>
        </div>
      </div>
      <div className='modal-footer-custom' style={{ justifyContent: 'space-between' }}>
        <button
          className='button-footer-resend'
          style={{
            background: otpTimer === 0 ? '#4F5AED' : '',
            color: otpTimer === 0 ? '#FFFFFF' : 'gray',
          }}
          onClick={handleResendOtp}
        >
          Resend
        </button>

        <button className='button-footer btn' onClick={handleSubmission}>
          Continue
        </button>
      </div>
    </>
  );
};

export default ForthStep;
