import { date, object } from 'yup';
import { validator } from 'utils/helpers';
import moment from 'moment/moment';

export const validationSchema = object().shape({
  scheduled_date: validator.requiredDate(),
  scheduled_date_to: date().when('scheduled_type', {
    is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
    then: validator.requiredDate().test('Invalid', 'Start date cannot be after end date', (value, context) => {
      const { scheduled_date, from, to } = context.parent || {};
      if (
        !scheduled_date ||
        !from ||
        !to ||
        !moment(scheduled_date).isValid() ||
        !moment(from).isValid() ||
        !moment(to).isValid()
      ) {
        return true;
      }

      return moment(`${moment(scheduled_date).format('YYYY-MM-DD')} ${moment(from).format('HH:mm')}`).isSameOrBefore(
        `${moment(value).format('YYYY-MM-DD')} ${moment(to).format('HH:mm')}`
      );
    }),
    otherwise: validator.date(),
  }),
  from: validator.requiredDate(),
  to: date().when('scheduled_type', {
    is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
});
