import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { handleToaster } from 'store/reducers/root.reducer';
import sm from './BillingCharges.module.css';

import CustomButton from '../../CustomButton/CustomButton';
import { ShipmentContext } from '../Layout';
import { BillingChargesContext } from './index';
import useShowToaster from '../../../hooks/useShowToaster';

const BillingChargesFooter = () => {
  const { use } = useTheme();
  const { setStep, onCloseModal } = useContext(ShipmentContext);
  const { disableNextStep, submitForm, validateForm, values, createShipmentData } = useContext(BillingChargesContext);
  const dispatch = useDispatch();
  const showToaster = useShowToaster();

  const validateSubTotal = (charges) => {
    const foundedZeroSubTotal = charges.find((charge) => {
      const { rate, qty } = charge || {};
      const subTotal = (+qty * +rate).toFixed(2);
      if (+subTotal === 0) {
        return charge;
      }
      return false;
    });
    return !foundedZeroSubTotal;
  };

  function validateBillingCharges(billingCharges) {
    if (!Array.isArray(billingCharges) || billingCharges.length === 0) {
      return {
        success: false,
        message: 'Please add and save billing charges details in order to proceed ',
      };
    }

    const isValid = true;
    const messages = [];

    for (let i = 0; i < billingCharges.length; i++) {
      // const charge = billingCharges[i];
      // if (!charge.hasOwnProperty('charges')) {
      //   messages.push(`Missing Charge  at index ${i + 1}`);
      //   isValid = false;
      // } else if (!charge.charges.hasOwnProperty('id')) {
      //   messages.push(`Missing Charge  at index ${i + 1}`);
      //   isValid = false;
      // }
      //
      // if (!charge.hasOwnProperty('qty')) {
      //   messages.push(`Missing Qty property at index ${i + 1}`);
      //   isValid = false;
      // }
      //
      // if (!charge.hasOwnProperty('rate')) {
      //   messages.push(`Missing Rate property at index ${i + 1}`);
      //   isValid = false;
      // }
    }

    return {
      success: isValid,
      message: messages.join(', '),
    };
  }

  const onNext = () => {
    validateForm().then((res) => {
      const isValid = Object.keys(res).length === 0;
      const isValidSubTotals = validateSubTotal(values?.billingCharges);

      const validationResult = validateBillingCharges(createShipmentData?.billingCharges);
      if (!validationResult.success) {
        showToaster({ type: 'error', message: validationResult.message });
        return;
      }

      submitForm();
      if (!!isValid && !isValidSubTotals) {
        const message = 'Charge amount cannot be 0';
        dispatch(handleToaster({ message, visible: true, type: 'error' }));
      }
      !!isValid && !!isValidSubTotals && setStep((p) => p + 1);
    });
  };

  return (
    <div className={sm.billing_charges_footer} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <div>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onCloseModal}
        />
      </div>

      <div className={sm.right_buttons}>
        <CustomButton
          type='secondary'
          title='Back'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={() => {
            setStep((p) => p - 1);
          }}
        />

        <CustomButton
          type='secondary'
          title='Save as Draft'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={() => {
            // updateShipmentData({ ...createShipmentData, ...values });
            localStorage.setItem('createShipmentData', JSON.stringify({ ...createShipmentData, ...values }));
            onCloseModal();
            // optimizeRoute ? setOptimizeRoute(false) : setStep((p) => p - 1);
          }}
        />

        <CustomButton
          type='primary'
          title='Next Step'
          disabled={disableNextStep}
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onNext}
          buttonProps={{
            type: 'submit',
          }}
        />
      </div>
    </div>
  );
};

export default BillingChargesFooter;
