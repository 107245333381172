import { palette } from 'utils/constants';

export const statues = {
  driving: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    name: 'DRIVING',
  },
  sleeperBed: {
    bgColor: '#000',
    color: '#FFF',
    name: 'SLEEPER',
  },
  offDuty: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    name: 'OFF DUTY',
  },
  yardMove: {
    bgColor: '#F0F1FA',
    color: '#5A6376',
    name: 'YARD MOVE',
  },
  personalConveyance: {
    bgColor: palette.red0,
    color: palette.indigo500,
    name: 'PERSONAL CONVEYANCE',
  },
  disconnected: {
    bgColor: palette.red0,
    color: palette.red400,
    name: 'DISCONNECTED',
  },
  waitingTime: {
    bgColor: '#F0F1FA',
    color: '#5A6376',
    name: 'WAITING TIME',
  },
  intLocation: {
    bgColor: '#F0F1FA',
    color: '#5A6376',
    name: 'INT LOCATION',
  },
  onDuty: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    name: 'ON DUTY',
  },
};
