import axios from '../services/axios';

export async function GetShipmentDetention(params) {
  const { data } = await axios.get(`customer/shipments/detention`, {
    params,
  });
  return data;
}

export async function updateBillDetention({ id, body }) {
  const { data } = await axios.put(`equipment/detention/${id}`, body);
  return data;
}

export async function billMultipleDetentions(body) {
  const { data } = await axios.post(`equipment/detention`, body);
  return data;
}

export async function markDetentionsBilled(body) {
  const { data } = await axios.post(`equipment/update-detention`, body);
  return data;
}

export async function deleteBillDetention(id) {
  const { data } = await axios.delete(`equipment/detention-bill/${id}`);
  return data;
}

export async function checkoutTrailer(id, body) {
  const { data } = await axios.post(`/customer/trailer/checkout/${id}`, body);
  return data;
}

export async function getEquipmentAtStopNote(id, params) {
  const { data } = await axios.get(`/equipment/equipment-at-stop-point-notes/${id}`, {
    params,
  });
  return data;
}

export async function addEquipmentAtStopNote(id, body) {
  const { data } = await axios.post(`/equipment/equipment-at-stop-point-notes/${id}`, body);
  return data;
}
