import React, { useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import PhoneInput from 'react-phone-number-input';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';

import { updateCustomer } from 'Api/Customers';
import { getErrorMessage } from 'utils/error';
import { checkDot, createCarrier } from 'Api/Carriers';
import useShowToaster from 'hooks/useShowToaster';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { SApprovedFor } from 'components/Carriers/AddCarrier/AddCarrier.styles';
import SplashScreen from 'pages/OnboardCarrier/components/splashScreen/splashScreen';
import styles from 'components/Profile/Profile.module.css';
import { validationBasicSchema } from './ValidationSchema';

const AddCarrierStep1 = ({ nextStep, Cancel, basicDetails = {}, customer, getCarriers }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const addressRef = useRef(null);
  const [addressInfo, setAddressInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOnboarding, setLoadingOnboarding] = useState(false);
  const [loadingDotData, setLoadingDotData] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const steps = ['Gathering report from FMCSA..'];

  const {
    phone_number,
    mc,
    name,
    dot,
    scac,
    email,
    address,
    address2,
    country,
    state,
    city,
    zipcode,
    can_travel_to,
    approved_for,
    preferred,
  } = basicDetails || {};

  const onNextStep = (values) => {
    const { country, state, city, name, address, zipcode, address2, logo, email, dot, mc, scac, phone_number } = values;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('logo', logo);
    formData.append('mc', mc || '');
    formData.append('dot', dot);
    formData.append('scac', scac);
    formData.append('phone_number', phone_number);
    formData.append('email', email);
    formData.append('address1', address);
    formData.append('address2', address2 || '');
    formData.append('address', address);
    formData.append('zipcode', zipcode);
    formData.append('country_id', country?.id);
    formData.append('state_id', state?.id);
    formData.append('city_id', city?.id);
    if (values.can_travel_to.length) {
      values.can_travel_to.forEach((item, index) => {
        formData.append(`can_travel_to[${index}]`, item);
      });
    }
    if (values.approved_for.length) {
      values.approved_for.forEach((item, index) => {
        formData.append(`approved_for[${index}]`, item);
      });
    }
    formData.append('approved_for_custom', values.approved_for?.includes('4') ? values.approved_for_custom : '');
    formData.append('preferred', Number(values.preferred));
    formData.append('require_trailer_interchange', Number(values.require_trailer_interchange));
    formData.append('trailer_interchange', values.require_trailer_interchange ? values.trailer_interchange : '');
    formData.append(
      'trailer_interchange_from',
      values.require_trailer_interchange ? moment(values.trailer_interchange_from).format('YYYY/MM/DD') : ''
    );
    formData.append(
      'trailer_interchange_expires',
      values.require_trailer_interchange ? moment(values.trailer_interchange_expires).format('YYYY/MM/DD') : ''
    );
    if (!customer.basicDetails.carrier) {
      setLoading(true);
      createCarrier(formData)
        .then((res) => {
          nextStep({ formikData: { ...values, carrier: res.data.id } }, `Carrier has been created successfully`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToaster({ type: 'error', message: getErrorMessage(error) });
        });
    } else {
      const patchData = Object.keys({}).filter((item) => !!{}[item]);
      updateCustomer(customer.basicDetails.customer, patchData)
        .then((res) => {
          nextStep({ formikData: { ...values, customer: res.data[0].id } }, `Customer has been updated successfully`);
        })
        .catch((error) => {
          showToaster({ type: 'error', message: getErrorMessage(error) });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      mc: mc || '',
      name: name || '',
      dot: dot || '',
      scac: scac || '',
      phone_number: phone_number || '',
      email: email || '',
      address: address || '',
      address2: address2 || undefined,
      state: state || null,
      country: country || null,
      city: city || null,
      zipcode: zipcode || '',
      can_travel_to: can_travel_to || ['1'],
      approved_for: approved_for || [],
      preferred: !!preferred,
      approved_for_custom: '',
      require_trailer_interchange: false,
      trailer_interchange: '',
      trailer_interchange_from: null,
      trailer_interchange_expires: null,
      logo: '',
    },
    validationSchema: validationBasicSchema,
    onSubmit: onNextStep,
    validateOnMount: true,
  });
  const whitelist = ['US', 'CA', 'MX'];

  function handleChange(event, countryCode) {
    formik.setFieldValue(
      'can_travel_to',
      event
        ? [...formik.values.can_travel_to, countryCode]
        : formik.values.can_travel_to.filter((code) => code !== countryCode)
    );
  }

  const handleFieldChange = (event, value) => {
    if (event?.target) {
      const { name, value } = event.target;
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(event, value);
    }
  };

  function handleApprovedForChange(event, doc) {
    formik.setFieldValue(
      'approved_for',
      event ? [...formik.values.approved_for, doc] : formik.values.approved_for.filter((code) => code !== doc)
    );
  }

  const sendOnboardingLink = () => {
    const { country, state, city, name, address, address2, logo, email, dot, mc, scac, phone_number, zipcode } =
      formik.values;
    const formData = new FormData();
    formData.append('onboard', 1);
    formData.append('name', name);
    formData.append('logo', logo);
    formData.append('mc', mc || '');
    formData.append('dot', dot);
    formData.append('scac', scac);
    formData.append('phone_number', phone_number);
    formData.append('email', email);
    formData.append('address1', address);
    formData.append('address2', address2 || '');
    formData.append('zipcode', zipcode);
    formData.append('address', address);
    formData.append('country_id', country?.id);
    formData.append('state_id', state?.id);
    formData.append('city_id', city?.id);
    if (formik.values.can_travel_to.length) {
      formik.values.can_travel_to.forEach((item, index) => {
        formData.append(`can_travel_to[${index}]`, item);
      });
    }
    if (formik.values.approved_for.length) {
      formik.values.approved_for.forEach((item, index) => {
        formData.append(`approved_for[${index}]`, item);
      });
    }
    formData.append('preferred', Number(formik.values.preferred));
    formData.append(
      'approved_for_custom',
      formik.values.approved_for?.includes('4') ? formik.values.approved_for_custom : ''
    );
    formData.append('require_trailer_interchange', Number(formik.values.require_trailer_interchange));
    formData.append(
      'trailer_interchange',
      formik.values.require_trailer_interchange ? formik.values.trailer_interchange : ''
    );
    formData.append(
      'trailer_interchange_from',
      formik.values.require_trailer_interchange
        ? moment(formik.values.trailer_interchange_from).format('YYYY/MM/DD')
        : ''
    );
    formData.append(
      'trailer_interchange_expires',
      formik.values.require_trailer_interchange
        ? moment(formik.values.trailer_interchange_expires).format('YYYY/MM/DD')
        : ''
    );
    setLoadingOnboarding(true);
    createCarrier(formData)
      .then(() => {
        Cancel();
        getCarriers();
        showToaster({ type: 'success', message: 'Onboarding link sent successfully' });
        setLoadingOnboarding(false);
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) });
        setLoadingOnboarding(false);
      });
  };

  const onDotChange = async (val) => {
    const value = (val || '').trim();
    formik.setFieldValue('dot', value);
    if (value.length !== 7) {
      return;
    }
    try {
      setLoadingDotData(true);
      const { data } = await checkDot(value);

      if (!data?.error?.message && !data?.data?.message) {
        const { phone, legalName, phyStreet, phyCountry, phyState, phyCity, phyZipcode } = data?.content?.carrier || {};
        const { docketNumber } = data?.docket_info || {};

        if (phyCountry || phyState || phyCity) {
          setAddressInfo({ country: phyCountry, state: phyState, city: phyCity });
        }

        formik.setValues((prevState) => ({
          ...prevState,
          name: legalName || formik.values.name,
          phone_number: phone ? `+1${phone.replaceAll(/[- +()]/g, '')}` : value.phone,
          address: phyStreet || '',
          zipcode: phyZipcode || formik.values.zipcode,
          mc: docketNumber || '',
          // mc: mc_mx_ff_numbers || '',
        }));
      }

      setLoadingDotData(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      setLoadingDotData(false);
      // do nothing
    }
  };

  const handleDrop = (files) => {
    if (files && files[0]) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      formik.setFieldValue('logo', file);
    }
  };

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className='basic-container driver-style-wrap'>
          <div className='d-flex gap-4'>
            <div>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Carrier DOT <span className='required'>*</span>
              </Typography>
              <div className='d-flex flex-column'>
                <Input
                  name='dot'
                  onChange={(e) => onDotChange(e.target.value)}
                  value={formik.values.dot}
                  onBlur={formik.handleBlur}
                  placeholder='Carrier DOT'
                  style={{ minWidth: 200, marginTop: 8 }}
                />
                {formik.touched.dot && formik.errors.dot ? (
                  <span className='error-message-carrier'>{formik.errors.dot}</span>
                ) : null}
              </div>
            </div>

            <div>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Carrier MC
              </Typography>
              <div className='d-flex flex-column'>
                <Input
                  name='mc'
                  onChange={formik.handleChange}
                  value={formik.values.mc}
                  onBlur={formik.handleBlur}
                  placeholder='Carrier MC'
                  style={{ minWidth: 200, marginTop: 8 }}
                />
              </div>
            </div>

            <div>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Carrier Name <span className='required'>*</span>
              </Typography>
              <div className='d-flex flex-column w-100'>
                <Input
                  name='name'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  placeholder='Carrier Name'
                  style={{ minWidth: 200, marginTop: 8 }}
                />

                {formik.touched.name && formik.errors.name ? (
                  <span className='error-message-carrier'>{formik.errors.name}</span>
                ) : null}
              </div>
            </div>
            <div>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                SCAC
              </Typography>
              <div className='d-flex flex-column w-100'>
                <Input
                  name='scac'
                  onChange={formik.handleChange}
                  value={formik.values.scac}
                  onBlur={formik.handleBlur}
                  placeholder='SCAC'
                  style={{ minWidth: 200, marginTop: 8 }}
                />

                {formik.touched.name && formik.errors.name ? (
                  <span className='error-message-carrier'>{formik.errors.scac}</span>
                ) : null}
              </div>
            </div>
          </div>

          <div className='d-flex gap-4' style={{ marginTop: '20px' }}>
            <div>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Phone Number <span className='required'>*</span>
              </Typography>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  addInternationalOption={false}
                  className='input-field-phone'
                  value={formik.values.phone_number}
                  countries={whitelist}
                  name='phone_number'
                  onChange={(val) => formik.setFieldValue('phone_number', val)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                />
              </div>
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <span className='error-message-carrier'>{formik.errors.phone_number}</span>
              ) : null}
            </div>
            <div>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Email <span className='required'>*</span>
              </Typography>
              <Input
                name='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                placeholder='Email'
                style={{ minWidth: 252, marginTop: 8 }}
              />
              {formik.touched.email && formik.errors.email ? (
                <span className='error-message-carrier'>{formik.errors.email}</span>
              ) : null}
            </div>
            <div className='d-flex flex-column  gap-2'>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                  marginBottom: 6,
                }}
              >
                Upload Logo
              </Typography>
              <div className='d-flex  gap-4 align-items-start'>
                <Uploader
                  type={2}
                  uploaderLabel='Select Attachment'
                  document={formik.values.logo}
                  onDrop={handleDrop}
                  style={{ marginTop: 8 }}
                  onRemove={() => {
                    setImageUrl(null);
                    formik.setFieldValue('logo', '');
                  }}
                />
                <label className={styles.avatar_wrapper}>
                  <figure className={styles.profile_image_wrapper}>
                    {!formik.values.logo ? (
                      <span />
                    ) : (
                      <img
                        src={imageUrl}
                        alt='img'
                        width={100}
                        height={100}
                        style={{ borderRadius: '50%', objectFit: 'contain' }}
                      />
                    )}
                  </figure>
                </label>
              </div>
            </div>
          </div>

          {loadingDotData && (
            <div style={{ height: 0 }}>
              <SplashScreen steps={steps} />
            </div>
          )}

          <div className='d-flex flex-column gap-3 mt-3'>
            <div className='d-flex gap-2'>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Set as preferred carrier?
              </Typography>
              <div className='d-flex gap-3'>
                <CustomCheckbox
                  checked={formik.values.preferred}
                  onChange={(event) => formik.setFieldValue('preferred', event)}
                />
              </div>
            </div>

            <div className='d-flex gap-2'>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Can Travel To? <span className='required'>*</span>
              </Typography>

              <div className='d-flex gap-3'>
                <CustomCheckbox
                  checked={formik.values.can_travel_to?.includes('1')}
                  onChange={(event) => handleChange(event, '1')}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    USA
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={formik.values.can_travel_to?.includes('2')}
                  onChange={(event) => handleChange(event, '2')}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Canada
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={formik.values.can_travel_to?.includes('3')}
                  onChange={(event) => handleChange(event, '3')}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Mexico
                  </Typography>
                </CustomCheckbox>
              </div>
              {formik.touched.can_travel_to && formik.errors.can_travel_to ? (
                <span className='error-message-carrier'>{formik.errors.can_travel_to}</span>
              ) : null}
            </div>

            <SApprovedFor>
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Approved For
              </Typography>
              <div className='d-flex gap-3'>
                <CustomCheckbox
                  checked={formik.values.approved_for?.includes('1')}
                  onChange={(event) => handleApprovedForChange(event, '1')}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    FAST
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={formik.values.approved_for?.includes('2')}
                  onChange={(event) => handleApprovedForChange(event, '2')}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    TSA
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={formik.values.approved_for?.includes('3')}
                  onChange={(event) => handleApprovedForChange(event, '3')}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    TWIC
                  </Typography>
                </CustomCheckbox>
                <div className='d-flex align-items-center gap-3'>
                  <CustomCheckbox
                    checked={formik.values.approved_for?.includes(4)}
                    onChange={(event) => handleApprovedForChange(event, '4')}
                  >
                    <Typography variant='s2' style={{ marginLeft: '8px' }}>
                      Custom
                    </Typography>
                  </CustomCheckbox>
                  {formik.values.approved_for?.includes('4') && (
                    <Input
                      name='approved_for_custom'
                      value={formik.values.approved_for_custom}
                      onChange={(e) => formik.setFieldValue('approved_for_custom', e.target.value)}
                    />
                  )}
                </div>
              </div>
            </SApprovedFor>
          </div>

          <div style={{ marginTop: '20px', marginBottom: '8px' }}>
            <Typography
              variant='s2'
              style={{
                color: use(palette.gray700, palette.gray200),
              }}
            >
              Address <span className='required'>*</span>
            </Typography>
          </div>
          <div className='w-50'>
            <AddressFields
              values={formik.values}
              handleChange={handleFieldChange}
              hideLabel
              defaultAddress={addressInfo}
              ref={addressRef}
              limitCountries
              isOneRow
            />
          </div>

          <div className='mt-4'>
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                checked={formik.values.require_trailer_interchange}
                name='require_trailer_interchange'
                onChange={(checked) => {
                  formik.setFieldValue('require_trailer_interchange', checked);
                }}
              />

              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  whiteSpace: 'nowrap',
                }}
              >
                Require Trailer Interchange
              </Typography>
            </div>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-baseline gap-2' style={{ marginTop: 10 }}>
                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Carrier Trailer Interchange
                </span>
                <InputWithIcon
                  name='trailer_interchange'
                  type='number'
                  disabled={!formik.values.require_trailer_interchange}
                  value={formik.values.trailer_interchange}
                  onChange={(e) => formik.setFieldValue('trailer_interchange', e.target.value)}
                  leftIcon={<DollarIcon height={32} />}
                />
                {formik.touched.trailer_interchange && formik.errors.trailer_interchange ? (
                  <span className='error-message-carrier'>{formik.errors.trailer_interchange}</span>
                ) : null}

                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  is required for owned equipment usage. Agreement is from
                </span>
                <div>
                  <DatePicker
                    width='150px'
                    name='trailer_interchange_from'
                    value={formik.values.trailer_interchange_from}
                    onChange={(val) => formik.setFieldValue('trailer_interchange_from', val)}
                    maxDate={
                      formik.values.trailer_interchange_expires ? formik.values.trailer_interchange_expires : null
                    }
                    disablePast
                    onBlur={formik.handleBlur}
                    disabled={!formik.values.require_trailer_interchange}
                    error={formik.touched.trailer_interchange_from && formik.errors.trailer_interchange_from}
                  />
                </div>

                <span
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  and expires
                </span>
                <div>
                  <DatePicker
                    width='150px'
                    name='trailer_interchange_expires'
                    value={formik.values.trailer_interchange_expires}
                    onChange={(val) => formik.setFieldValue('trailer_interchange_expires', val)}
                    minDate={
                      formik.values.trailer_interchange_from ? formik.values.trailer_interchange_from : new Date()
                    }
                    onBlur={formik.handleBlur}
                    disablePast
                    disabled={!formik.values.require_trailer_interchange || !formik.values.trailer_interchange_from}
                    error={formik.touched.trailer_interchange_expires && formik.errors.trailer_interchange_expires}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <CustomButton
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '6px 12px' }}
          type='secondary'
          title='Cancel'
          onClick={Cancel}
        />

        <CustomButton
          styleTitle={{ fontSize: 14, color: formik.isValid ? palette.white : 'black' }}
          styleButton={{
            padding: '6px 12px',
            background: formik.isValid ? palette.green500 : 'transparent',
          }}
          leftIcon={
            loadingOnboarding ? (
              <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
            ) : (
              <div />
            )
          }
          disabled={!formik.isValid}
          type='secondary'
          title='Send Onboard Link'
          onClick={sendOnboardingLink}
        />

        <CustomButton
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '6px 12px' }}
          type='primary'
          title='Next Step'
          leftIcon={
            loading ? (
              <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
            ) : (
              <div />
            )
          }
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        />
      </Modal.Footer>
    </Form>
  );
};

export default AddCarrierStep1;
