import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getAssignToGroupBillingCharges } from 'Api/Planner';

const UpdateGroup = ({ open, onClose, onSuccess, shipmentData }) => {
  const [editedShipmentData, setEditedShipmentData] = useState([]);
  const [filterByGroupsData, setFilterByGroupsData] = useState([]);

  useEffect(() => {
    setEditedShipmentData(shipmentData.shipment_billing.map((billing) => ({ ...billing })));
  }, [shipmentData]);

  const handleInputChange = (index, field, newValue) => {
    const updatedShipmentData = [...editedShipmentData];
    updatedShipmentData[index][field] = newValue;
    setEditedShipmentData(updatedShipmentData);
  };

  useEffect(() => {
    getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          setFilterByGroupsData(res.data);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<>Update Tag(s)</>}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'done',
          type: 'primary',
          title: 'Update',
          onClick: () => onSuccess(editedShipmentData),
        },
      ]}
    >
      <div>
        {editedShipmentData.map((billing, index) => {
          return (
            <div className='mt-2' key={billing.id}>
              <Typography variant='b2'>Tag - {billing.billing_customer?.company_name}</Typography>
              <Autocomplete
                labelKey='group_name'
                options={filterByGroupsData}
                value={billing.groups}
                onChange={(e, val) => handleInputChange(index, 'groups', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default UpdateGroup;
