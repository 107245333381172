import React, { useEffect, useState } from 'react';
import deleteIcon from 'assets/icons/delete.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as DeleteIconThin } from 'assets/icons/deleteThin.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtubeIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/drivers/secondClose.svg';

import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import Input, { InputWithIcon, Textarea } from 'common/Input';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import {
  CreateCategory,
  CreateCompanyResource,
  DeleteCategory,
  GetCategories,
  GetDepartments,
  UpdateCompanyResources,
} from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './AddMaterial.data';
import { SUploaderWrapper } from './AddMaterial.styles';

const AddMaterial = ({ open, onClose, onSuccess, material }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [value, setValue] = useState('');

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('category_id', values.category_id);
      formData.append('description', values.description);
      if (values.video) {
        formData.append('video', values.video);
      }
      if (values.document) {
        formData.append('document', values.document);
      }
      values?.visible_to_id?.forEach((item) => {
        formData.append('visible_to_id[]', item.id);
      });
      if (material) {
        await UpdateCompanyResources(material.id, formData);
      } else {
        await CreateCompanyResource(formData);
      }
      showToaster({ type: 'success', message: `Material has been successfully ${material ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(material),
    validationSchema,
    onSubmit,
  });

  const getCategories = async () => {
    try {
      const { data } = await GetCategories();
      setCategories(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await GetDepartments();
      setDepartments(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const uploadDocument = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    handleChange('document', file[0]);
  };

  const resetUploadedScreenshot = (e) => {
    e.stopPropagation();
    handleChange('document', null);
  };

  const onDeleteItem = async (e, el) => {
    e.stopPropagation();
    await DeleteCategory(el?.id);
    await getCategories();
  };

  const onAddCategory = async () => {
    if (!value) {
      return;
    }

    try {
      setCategories([...categories, { id: categories?.length, name: value }]);
      await CreateCategory({ name: value });
      setValue('');
      await getCategories();
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCategories();
    getDepartments();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={material ? 'Update Material' : 'Add Material'}
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      backdrop='static'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: material ? 'Update' : 'Add Material',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='mb-5 d-flex flex-column gap-4'>
        <Input
          required
          name='title'
          label='Title'
          value={values.title}
          onChange={handleChange}
          error={touchedErrors.title}
        />
        <div>
          <InputLabel required>Category</InputLabel>
          <CustomSelect
            name='category_id'
            options={categories}
            styles={{ height: 32 }}
            component={CustomSelect}
            img
            imgStyle={{ width: 14, height: 14, marginTop: '-5px' }}
            imgDefault={deleteIcon}
            defaultText='Select Category...'
            keyName='id'
            labelName='name'
            field={{ value: values.category_id }}
            onChange={(val) => handleChange('category_id', val?.id)}
            onDeleteItem={onDeleteItem}
            footer={
              <div
                style={{
                  background: use(palette.gray50, palette.dark600),
                  width: 410,
                  margin: '0 -10px',
                  padding: '8px 0',
                  transform: 'translateY(2px)',
                }}
              >
                <CustomInput
                  value={value}
                  labelStyle={{ margin: 0 }}
                  style={{
                    width: 398,
                    borderRadius: 6,
                    marginLeft: 8,
                  }}
                  placeholder='Add Custom...'
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      onAddCategory();
                    }
                  }}
                  rightIcon={
                    <>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddCategory();
                        }}
                      >
                        <CheckIcon width={11} height={11} />
                      </span>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setValue('');
                        }}
                      >
                        <DeleteIconThin width={11} height={11} fill={palette.red500} />
                      </span>
                    </>
                  }
                  rightIconStyle={{
                    display: 'flex',
                    gap: '10px',
                    margin: '0 10px 2px 0',
                    maxWidth: '50px',
                  }}
                />
              </div>
            }
          />
          <ErrorMessage error={touchedErrors.category_id} />
        </div>
        <Textarea
          required
          rows={3}
          label='Description'
          name='description'
          value={values.description}
          onChange={handleChange}
          error={touchedErrors.description}
        />
        <InputWithIcon
          name='video'
          label='Video URL'
          placeholder='Paste video URL here'
          value={values.video}
          onChange={handleChange}
          icon={<YoutubeIcon />}
          error={touchedErrors.video}
        />
        <div>
          <InputLabel>Upload Document</InputLabel>
          <SUploaderWrapper>
            <div className='upload-label'>
              <UploadIcon width={46} height={42} />
              {values?.document ? (
                <div className='d-flex align-items-center gap-2'>
                  <CheckIcon />
                  <Typography variant='s2' style={{ color: palette.green400 }}>
                    File Uploaded
                  </Typography>
                  <CrossIcon className='pointer' onClick={resetUploadedScreenshot} />
                </div>
              ) : (
                <Typography variant='s2'>
                  <span className='upload-text'>Upload Document</span> or drag and drop
                </Typography>
              )}
            </div>
            <ImageDropZone
              onDrop={uploadDocument}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              maxSize={5242880}
              accept={['application/pdf']}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: values.document ? -1 : 1,
              }}
            />
          </SUploaderWrapper>
        </div>
        <div>
          <Autocomplete
            multiple
            required
            label='Visible To'
            id='visible_to_id'
            name='visible_to_id'
            labelKey='department_name'
            options={departments}
            value={values.visible_to_id}
            onChange={(e, value) => handleChange('visible_to_id', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            error={touchedErrors.visible_to_id}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddMaterial;
