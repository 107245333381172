import React, { useEffect, useState } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import ColumnOrder from 'components/PopoverSettings/ColumnOrder';
import TableColumn from 'components/PopoverSettings/TableColumn';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import styles from 'components/PopoverSettings/PopoverSettings.module.css';
import { updateTableSettings } from 'Api/User';

export const amountSwitch = [
  { key: 'without_k', label: '10,000', value: 1 },
  { key: 'with_k', label: '10k', value: 2 },
];

const TableSettings = ({
  filter,
  updateFilter,
  setShowPopover,
  switchRadioButtons,
  tableColumn,
  dragItem,
  onChangeOrder,
  setTableColumn,
}) => {
  const [localState, setLocalState] = useState([]);
  const [localDragItem, setLocalDragItem] = useState([]);
  const [switchRadioButtonsLocal, setSwitchRadioButtonsLocal] = useState({});
  const user = JSON.parse(localStorage.getItem('user'));

  const onChangeTick = (value) => {
    updateFilter({ ...filter, forAll: value });
  };

  const onCancel = () => {
    setShowPopover(false);
  };

  const onClickApply = async () => {
    try {
      const { forAll } = filter;

      updateFilter({ ...filter, tableColumn: localState, switchRadioButtons: switchRadioButtonsLocal });
      setTableColumn([...localState]);
      onChangeOrder(localDragItem);
      const updateData = {
        type: 'shipment_offer',
        forAll,
        data: {
          columns: {},
          columns_order: {},
        },
      };
      localState.forEach((el) => {
        const { value, checked } = el;
        updateData.data.columns[value] = checked;
      });

      updateData.data.columns_order = localDragItem.map((el) => el.value);

      await updateTableSettings(updateData);
      setShowPopover(false);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setSwitchRadioButtonsLocal(switchRadioButtons);
  }, [switchRadioButtons]);

  useEffect(() => {
    setLocalDragItem(dragItem);
  }, [dragItem]);

  return (
    <div>
      <div className={styles.popoverSettings_title_wrapper}>
        <span>Table Settings</span>
      </div>
      <TableColumn
        localState={localState}
        tableColumn={tableColumn}
        setLocalState={(param) => setLocalState(param)}
        localDragItem={localDragItem}
        onChangeOrderLocal={(items) => setLocalDragItem(items)}
      />
      <ColumnOrder localDragItem={localDragItem} onChangeOrderLocal={(items) => setLocalDragItem(items)} />
      <div style={{ borderBottom: '1px solid #D5DBE5', margin: '32px 0 16px', height: '1px' }} />
      <div className={styles.popoverSettings_footer}>
        {user?.department?.department_name === 'Management' && (
          <CustomCheckbox style={{ marginLeft: 10 }} checked={filter.forAll} onChange={onChangeTick}>
            <span className={styles.footer_title_checkbox}>Apply to all users</span>
          </CustomCheckbox>
        )}

        <div className={styles.footer_right}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '2px 8px', marginRight: 16 }}
            onClick={onCancel}
          />

          <CustomButton type='primary' title='Apply' styleButton={{ padding: '2px 8px' }} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default TableSettings;
