import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import useShowToaster from 'hooks/useShowToaster';
import { deleteReferrer, sendEmailOrSMS } from 'Api/Applicants';
import { getAppUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { SDotsWrapper, SPopover } from '../ReferrersTable.styles';

const ReferrerInfoActions = ({ referrer, onDeleteSuccess = () => null }) => {
  const showToaster = useShowToaster();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;

  const handleDeleteReferrer = async () => {
    setAnchorEl(null);
    try {
      await deleteReferrer(referrer.id);
      showToaster({ type: 'success', message: 'Referrer has been successfully deleted' });
      onDeleteSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const handleGenerateQR = async () => {
    const canvas = document.getElementById('generated-qr');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setAnchorEl(null);
  };

  const handleSendEmail = async () => {
    setAnchorEl(null);
    try {
      await sendEmailOrSMS(referrer.id);
      showToaster({ type: 'success', message: 'Email/SMS has been successfully sent' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    }
  };

  return (
    <SDotsWrapper className='action-dots'>
      <span className='dots' onClick={(event) => setAnchorEl(event.currentTarget)}>
        <ActionDots width={13} height={13} />
      </span>
      <SPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='actions-wrapper'>
          <li onClick={handleSendEmail}>Send {referrer.email ? 'to Email' : 'SMS'}</li>
          <li onClick={handleGenerateQR}>Generate QR Code</li>
          <li onClick={handleDeleteReferrer} className='delete-action'>
            Delete
          </li>
        </div>
      </SPopover>
      <QRCode
        id='generated-qr'
        value={`${getAppUrl()}/job-positions/${dot}?token=${referrer.url}`}
        size={300}
        includeMargin
        style={{ display: 'none' }}
      />
    </SDotsWrapper>
  );
};

export default ReferrerInfoActions;
