import React, { useMemo } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { getAlphabet, palette } from 'utils/constants';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { Formik } from 'formik';
import { validationSchema } from 'components/TableRowEditModalTypes/CopyShipment/validationSchema';
import useShowToaster from 'hooks/useShowToaster';
import CopyStop from './types/CopyStop';
import classes from './copyShipment.module.scss';

const styles = {
  modal: {
    width: 540,
    overflow: 'hidden',
    maxHeight: '90%',
    top: '50%',
    left: '50%',
    boxShadow: 24,
    borderRadius: '8px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    bgcolor: palette.white,
  },
  modalBody: {
    padding: 0,
    borderRadius: 6,
    backgroundColor: palette.white,
  },
  inputStyle: {
    width: 170,
    height: 32,
    borderRadius: 6,
    padding: '0 6px',
  },
  labelStyle: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  error: {
    color: palette.red500,
  },
};

const CopyShipment = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const showToaster = useShowToaster();
  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );

  const initialValue = useMemo(() => {
    const stops = data.shipment_stops.reduce((acc, cur, index) => {
      acc[index] = {
        scheduled_date: null,
        scheduled_date_to: null,
        scheduled_type: cur.scheduled_type,
      };
      return acc;
    }, []);
    return {
      copy_count: '',
      reference_id: '',
      stops,
    };
  }, [data.shipment_stops]);

  const onSubmit = async (validateForm, resetForm, submitForm, values) => {
    submitForm();
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      let notValidStopIndex = null;

      values.stops.forEach((stop, index, arr) => {
        if (index === 0) {
          return;
        }

        const currentStopScheduledDate = stop.scheduled_date;
        const prevStopScheduledDate = [1, 3].includes(Number(arr[index - 1].scheduled_type))
          ? arr[index - 1].scheduled_date_to
          : arr[index - 1].scheduled_date;

        if (moment(currentStopScheduledDate).isBefore(prevStopScheduledDate)) {
          notValidStopIndex = index;
        }
      });

      if (notValidStopIndex !== null) {
        showToaster({
          type: 'error',
          message: `Stop ${getAlphabet(notValidStopIndex)} cannot be scheduled before Stop ${getAlphabet(
            notValidStopIndex - 1
          )}!`,
        });
        return;
      }

      onAddBtn(validateForm, resetForm, submitForm, values);
    }
  };

  return (
    <Modal
      size='lg'
      centered
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik
        initialValues={{ ...initialValue }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={() => {}}
      >
        {({ values, resetForm, submitForm, setFieldValue, validateForm, touched, errors }) => {
          return (
            <form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: palette.white }}>
                  <Typography variant='button2'>Copy Shipment</Typography>
                  <Typography variant='overLine'>{subTitle}</Typography>
                </div>
                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: palette.gray0,
                    borderColor: palette.gray50,
                  }}
                >
                  <div className='d-flex gap-5'>
                    <div className={classes.errorWrapper}>
                      <CustomInput
                        minCount={1}
                        maxCount={50}
                        type='number'
                        roundNumber
                        name='copy_count'
                        label='Copy Qty'
                        required
                        placeholder='Copy Qty'
                        value={values.copy_count}
                        onChange={(e) => setFieldValue('copy_count', e.target.value)}
                        style={styles.inputStyle}
                        labelStyle={styles.labelStyle}
                      />
                      <ErrorMessage error={touched.copy_count && errors.copy_count} />
                    </div>
                    <div className={classes.errorWrapper}>
                      <CustomInput
                        name='reference_id'
                        label='Reference ID'
                        required
                        placeholder='Reference ID'
                        value={values.reference_id}
                        onChange={(e) => setFieldValue('reference_id', e.target.value)}
                        style={styles.inputStyle}
                        labelStyle={styles.labelStyle}
                      />
                      <ErrorMessage error={touched.reference_id && errors.reference_id} />
                    </div>
                  </div>
                  <div className={classes.switchers}>
                    {data.shipment_stops.map((item, index) => (
                      <CopyStop
                        key={item.id}
                        data={item}
                        index={index}
                        values={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                      />
                    ))}
                  </div>
                </div>

                <div className={classes.modalFooter} style={{ backgroundColor: palette.white }}>
                  <div className={classes.modalFooterLane} style={{ backgroundColor: palette.white }}>
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Copy Shipment'
                      onClick={() => onSubmit(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                    />
                  </div>
                </div>
              </Modal.Body>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CopyShipment;
