import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { updateShouldUpdateConnectedServices } from 'store/reducers/root.reducer';
import { activeService, deactivateAllService } from 'Api/Driver';
import { connectQuickbooksService } from 'Api/AccountingReceivables';
import { getConnectedServices, getConnectedServiceSettings } from 'Api/DriverV2';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import HeaderStar from '../HeaderStar';
import './ConnectedServiceModule.css';

function ConnectServiceModal({ modalProps, selectValue, setPdfLink, onSuccess }) {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getServiceInfo = async () => {
    try {
      const { data } = await getConnectedServiceSettings(selectValue.id);
      const fields = selectValue.fields.filter((field) => field.field_belongs_to === null);

      setFields(fields.map((item) => ({ ...item, settings: data.find((i) => i.custom_field_id === item.id) })));
    } catch (e) {
      // Do nothing
    }
  };

  const onChangeFieldValue = (value, index) => {
    const tempFields = [...fields];
    tempFields[index].value = value;
    setFields(tempFields);
  };

  const connect = () => {
    let hasError = '';
    for (const field of fields) {
      if (!field.value) {
        hasError = `Please fill ${field.field_name}`;
        break;
      }
    }
    if (hasError) {
      showToaster({ type: 'error', message: hasError });
      return;
    }
    setIsLoading(true);
    const payloadFields = fields.map((field) => ({
      id: field.id,
      custom_field_value: field.value,
    }));

    if (selectValue.id === 57) {
      connectQuickbooksService({ connected_service_id: 57, fields: payloadFields })
        .then((resp) => {
          if (resp?.error || !resp?.success === false) {
            showToaster({ type: 'error', message: resp?.error || resp?.message || 'Something went wrong!' });
            return;
          }
          window.open(resp.authorization_url, '_self');
          modalProps.onHide(selectValue);
          onSuccess();
        })
        .catch((error) => {
          showToaster({ type: 'error', message: getErrorMessage(error) });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      activeService({ serviceId: selectValue.id, isAllService: true, fields: payloadFields })
        .then((resp) => {
          if (resp?.error) {
            showToaster({ type: 'error', message: resp?.error });
            return;
          }
          dispatch(updateShouldUpdateConnectedServices(Date.now()));
          showToaster({ type: 'success', message: 'Connection to service established!' });
          modalProps.onHide(selectValue);
          onSuccess();
          setIsLoading(false);
        })
        .catch((error) => {
          showToaster({ type: 'error', message: getErrorMessage(error) });
          setIsLoading(false);
        });
    }
  };

  const remove = () => {
    setIsLoading(true);
    deactivateAllService({ connected_service_id: selectValue.id })
      .then(() => {
        dispatch(updateShouldUpdateConnectedServices(Date.now()));
        modalProps.onHide(selectValue);
        showToaster({ type: 'success', message: 'Service has been removed' });
        onSuccess();
        setIsLoading(false);
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) });
        setIsLoading(false);
      });
  };

  const onViewGuide = () => {
    if (selectValue.guide_type === 'pdf') {
      setPdfLink(selectValue.guide);
      return;
    }

    window.open(selectValue.guide, '_blank');
  };

  useEffect(() => {
    getServiceInfo();
  }, []);

  return (
    <Modal {...modalProps} size='md' enforceFocus={false} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header
        closeButton
        className='connect-modal-header-footer'
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title
          id='contained-modal-title-vcenter'
          style={{ color: use('#171c26', palette.gray200), width: '100%' }}
        >
          <div className='heading-container-connet-service'>
            <div className='Connected-services-modal-header-icon-wrapper'>
              <img src={selectValue?.logo} alt='' className='Connected-services-modal-header-icon' />
            </div>
            <span className='provider_name_header'>{selectValue?.provider_name || ''}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: use(palette.gray0, palette.dark800) }} className='driver-style-wrap'>
        <p
          className='text-style'
          style={{ marginLeft: '0', marginBottom: '8px', color: use(palette.dark700, palette.gray200) }}
        >
          {selectValue?.description}{' '}
        </p>
        {fields.map((field, index) => (
          <div key={field.field_name} className='Connect-feilds-container'>
            <p className='text-style' style={{ marginLeft: '0', color: use(palette.dark700, palette.gray200) }}>
              {field.field_name} <span className='required'>*</span>
            </p>
            <input
              type='text'
              value={
                !!selectValue?.connected && !!field?.settings?.custom_field_value
                  ? field.settings.custom_field_value
                  : field.value
              }
              onChange={(event) => onChangeFieldValue(event.target.value, index)}
              className='connect-modal-input'
              disabled={!!selectValue?.connected}
              style={{
                backgroundColor: use(palette.gray0, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer
        className='connect-modal-header-footer'
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Button
          className='Cancel-modal-btn Cancel-modal-btn-connected-service-footer'
          onClick={() => modalProps.onHide()}
        >
          Cancel
        </Button>
        {!!selectValue?.guide && (
          <Button className='Cancel-modal-btn' onClick={onViewGuide}>
            View Guide
          </Button>
        )}
        {selectValue?.purchase_option === 1 && (
          <a
            href={
              selectValue?.purchase_link.includes('https://')
                ? selectValue?.purchase_link
                : `https://${selectValue?.purchase_link}`
            }
            target='_blank'
            rel='noreferrer'
          >
            <Button className='Cancel-modal-btn'>Purchase</Button>
          </a>
        )}
        {isLoading ? (
          <div>
            <CircularProgress size={30} />
          </div>
        ) : selectValue?.connected ? (
          <Button className='next-step' onClick={() => remove()} autoFocus>
            Remove
          </Button>
        ) : selectValue.id === 57 ? (
          <div className='QuickBookBtn' onClick={() => connect()} />
        ) : (
          <Button className='Connect-modal-btn' onClick={() => connect()}>
            Connect
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const ConnectedServicesModule = () => {
  const { use } = useTheme();
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [tabServices, setTabServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);

  const getAllConnectedServices = async () => {
    try {
      setLoading(true);
      const { data } = await getConnectedServices();
      const tempTabs = [];
      const tempServices = [...(data || [])];
      let value = 1;
      for (const service of tempServices) {
        const hasServiceIndex = tempTabs.findIndex((tab) => tab.label === service.type);
        if (hasServiceIndex === -1) {
          value += 1;
          tempTabs.push({ label: service.type, count: 1, key: service.type, value });
        } else {
          tempTabs[hasServiceIndex].count += 1;
        }
      }
      tempTabs.unshift({ label: 'All', count: tempServices?.length, key: 'all', value: 1 });
      setTabs(tempTabs);
      setTabServices(tempServices);
      setAllServices(tempServices);
      setLoading(false);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllConnectedServices();
  }, []);

  const onChangeTab = (tabValue) => {
    setTab(tabValue);
    if (tabValue.value === 1) {
      // show all data
      setTabServices([...allServices]);
    } else {
      const getSelectedTab = tabs.find((service) => service.value === tabValue.value);
      setTabServices(allServices.filter((service) => service.type === getSelectedTab.label));
    }
  };

  const onHide = (val) => {
    actionButtons(val, true);
  };

  const actionButtons = (val, action) => {
    if (val) {
      const tempAllServices = [...allServices];
      const tempTabServices = [...tabServices];
      const getAllServiceIndex = allServices.findIndex((service) => service.id === val.id);
      const tabServiceIndex = tabServices.findIndex((service) => service.id === val.id);
      tempAllServices[getAllServiceIndex].connected = action;
      tempTabServices[tabServiceIndex].connected = action;
      setAllServices(tempAllServices);
      setTabServices(tempTabServices);
    }
    setSelectedService(null);
  };

  if (loading) {
    return <TablePreLoader />;
  }

  return (
    <>
      <div className={selectedService ? 'fade modal-backdrop show' : 'modal'}>
        {!!selectedService && (
          <ConnectServiceModal
            modalProps={{
              show: !!selectedService,
              onHide,
              backdrop: true,
            }}
            onSuccess={() => {
              getAllConnectedServices();
              setTab(1);
            }}
            selectValue={selectedService}
            setPdfLink={setPdfLink}
          />
        )}
      </div>
      <div className='Connected-service-header-main'>
        <HeaderStar title='Connected Services' />
      </div>
      <div>
        <SwitchRadio
          name='tableTopTabMenu'
          items={tabs}
          value={tab}
          type='tab'
          onChange={(v) => onChangeTab(v)}
          plus={false}
        />
        <table className='table table-hover' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
          <tr
            className='connected-services-table-row'
            style={{
              backgroundColor: use(palette.white, palette.dark600),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          >
            <th
              className='connected-services-table-head table-head service'
              style={{ color: use(palette.gray700, palette.gray200), width: 370 }}
            >
              Services
            </th>
            <th
              className='connected-services-table-head table-head'
              style={{ color: use(palette.gray700, palette.gray200) }}
            >
              Type
            </th>
            <th
              className='connected-services-table-head table-head'
              style={{ color: use(palette.gray700, palette.gray200) }}
            >
              Description
            </th>

            <th />
          </tr>
          {tabServices.map((value) => (
            <tr
              className='connected-services-table-row'
              style={{ borderColor: use(palette.gray50, palette.darkborder), cursor: 'pointer' }}
              onClick={() => {
                if (!value.available) {
                  return;
                }
                setSelectedService(value);
              }}
            >
              <td className='d-flex align-items-center service-provider' style={{ width: 370, height: '100%' }}>
                <span className='servie-logo service-logo-image-module'>
                  <img src={value.logo} alt='' className='service-logo-image service-logo-image-module-img' />
                </span>
                <span className='table-service ms-2' style={{ color: use(palette.dark900, palette.gray200) }}>
                  {value.provider_name}
                </span>
              </td>
              <td>
                <span className='table-content' style={{ color: use(palette.dark700, palette.gray200) }}>
                  {value.type}
                </span>
              </td>
              <td>
                <span className='table-content' style={{ color: use(palette.dark700, palette.gray200) }}>
                  {value.description}
                </span>
              </td>
              <td className='service-action px-2'>
                {!value.available ? (
                  <Typography variant='s2' style={{ color: palette.gray500 }} className='nowrap'>
                    Not Available
                  </Typography>
                ) : !value.connected ? (
                  <button onClick={() => setSelectedService(value)} className='connect-button'>
                    Connect
                  </button>
                ) : value.status === 0 ? (
                  <button className='not-valid-button'>Invalid Key</button>
                ) : (
                  <button onClick={() => setSelectedService(value)} className='disconnect-button'>
                    Remove
                  </button>
                )}
              </td>
            </tr>
          ))}
        </table>
        <ViewPdf open={!!pdfLink} title='Guide' pdfUrl={pdfLink} onClose={() => setPdfLink(null)} />
      </div>
    </>
  );
};

export default ConnectedServicesModule;
