import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { formatNumber, palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(7);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('MONTH OF COLLISION', 16, top);
  doc.text('NUMBER OF POWER UNITS PER MONTH', 68, top - 2, { align: 'center', maxWidth: 30 });
  doc.text('NUMBER OF MILES DRIVEN', 110, top - 2, { align: 'center', maxWidth: 30 });
  doc.text('NUMBER OF COLLISIONS', 150, top - 2, { align: 'center', maxWidth: 30 });
  doc.text('COLLISION FREQUENCY BY MILLION MILES', pageWidth - 30, top - 2, { align: 'center', maxWidth: 30 });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
};

const createTableRow = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(data.monthName, 16, top);
  doc.text(data.power_unit_count ? String(data.power_unit_count) : '0', 68, top, { align: 'center' });
  doc.text(formatNumber(data.miles_driven), 110, top, { align: 'center' });
  doc.text(data.total_collision_count ? String(data.total_collision_count) : '0', 150, top, {
    align: 'center',
  });
  doc.text(formatNumber(data.collision_frequency_per_million_miles), pageWidth - 30, top, { align: 'center' });
};

const createTotalRow = (doc, vendors, totalData, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top, pageWidth - 16, top);

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Total', 16, top + 5);
  doc.text(totalData.power_unit_count ? String(totalData.power_unit_count) : '0', 68, top + 5, {
    align: 'center',
  });
  doc.text(formatNumber(totalData.miles_driven || 0), 110, top + 5, { align: 'center' });
  doc.text(totalData.total_collision_count ? String(totalData.total_collision_count) : '0', 150, top + 5, {
    align: 'center',
  });
  doc.text(formatNumber(totalData.collision_frequency_per_million_miles || 0), pageWidth - 30, top + 5, {
    align: 'center',
  });

  doc.setLineWidth(0.1);
  doc.line(16, top + 8, pageWidth - 16, top + 8);
};

const createTableRows = (doc, vendors) => {
  if (!vendors?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  vendors.forEach((vendor, index) => {
    createTableRow(doc, vendor, top + index * 5);
  });

  return top + vendors.length * 5;
};

export const generatePDF = async (data, download, year, dateFormat) => {
  const { reportItems, totalData, title } = data || {};
  if (!reportItems?.length) {
    return;
  }

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(title || 'Fleet Safest Year', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  doc.text(year, pageWidth / 2, 28, {
    align: 'center',
  });
  doc.setFont('Inter', 'normal', 400);
  doc.text(dateFormat.convertToCustomerTime(), pageWidth - 16, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 30, pageWidth - 16, 30);

  const totalRowStart = createTableRows(doc, reportItems);

  createTotalRow(doc, reportItems, totalData, totalRowStart);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);

    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);

    createTableHeader(doc);
  }

  const url = doc.output('datauristring', { filename: 'Fleet Safest Year' });
  const blob = doc.output('blob', { filename: 'Fleet Safest Year' });

  if (download) {
    doc.save(`Fleet-Safest-Year.pdf`);
  }
  return { blob, url };
};
