import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';

import license from 'assets/icons/drivers/license.svg';
import licenseDark from 'assets/icons/drivers/licenseDark.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';

import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { createCarrierDocument, deleteCarrierDocument, getCarrierDocs, getCarrierStaticDocs } from 'Api/Carriers';
import moment from 'moment/moment';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import styles from './documents.module.css';
import DocumentsItem from './DocumentsItem';
import AddDocumentModal from './AddDocumentModal';

const Documents = () => {
  const refFigure = useRef(null);
  const { use } = useTheme();
  const { id } = useParams();
  const showToaster = useShowToaster();
  const [docsFilesList, setDocsFilesList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [nameTop, setNameTop] = useState(false);
  const [typeTop, setTypeTop] = useState(false);
  const [loadingFilesList, setLoadingFilesList] = useState(false);

  function filterAndTransform(documentsObject) {
    const {
      master_agreement,
      authority,
      cargo_insurance,
      cargo_insurance_exp_date,
      general_liability,
      general_liability_exp_date,
      liability_insurance,
      liability_insurance_exp_date,
      reference_sheet,
      trailer_interchange_expires,
      trailer_interchange_and_indemnity_agreement,
      w9,
    } = documentsObject || {};

    return [
      {
        isStatic: true,
        type: 'master_agreement',
        name: 'Master Agreement',
        path: master_agreement || null,
      },
      {
        isStatic: true,
        type: 'w9',
        name: 'W9',
        path: w9 || null,
      },
      {
        isStatic: true,
        type: 'authority',
        name: 'Authority',
        path: authority || null,
      },
      {
        isStatic: true,
        type: 'reference_sheet',
        name: 'Reference Sheet',
        path: reference_sheet || null,
      },
      {
        isStatic: true,
        type: 'liability_insurance',
        name: 'Auto Liability Insurance',
        path: liability_insurance || null,
        expiration_date: liability_insurance_exp_date,
      },
      {
        isStatic: true,
        type: 'cargo_insurance',
        name: 'Cargo Insurance',
        path: cargo_insurance || null,
        expiration_date: cargo_insurance_exp_date,
      },
      {
        isStatic: true,
        type: 'general_liability',
        name: 'General Liability',
        path: general_liability || null,
        expiration_date: general_liability_exp_date,
      },
      {
        isStatic: true,
        type: 'trailer_interchange_and_indemnity_agreement',
        name: 'Trailer Interchange and Indemnity Agreement',
        path: trailer_interchange_and_indemnity_agreement || null,
        expiration_date: trailer_interchange_expires,
      },
    ];
  }

  const getFilesList = () => {
    setLoadingFilesList(true);
    getCarrierStaticDocs(id).then((res) => {
      let filteredDocuments = [];
      if (res.data) {
        filteredDocuments = [...filterAndTransform(res.data)];
      }
      getCarrierDocs(id).then((res) => {
        const allDocuments = filteredDocuments.concat(res.data);
        setDocsFilesList(allDocuments);
        setLoadingFilesList(false);
      });
    });
  };

  const sortBy = (type) => {
    let sortedName = [];
    if (type === 'name') {
      !nameTop
        ? (sortedName = docsFilesList.sort((a, b) => (a.reference_id < b.reference_id ? -1 : 1)))
        : (sortedName = docsFilesList.sort((a, b) => (a.reference_id > b.reference_id ? -1 : 1)));
      setDocsFilesList([...sortedName]);
      setNameTop((prev) => !prev);
    } else {
      !typeTop
        ? (sortedName = docsFilesList.sort((a, b) => (a.type?.name < b.type?.name ? -1 : 1)))
        : (sortedName = docsFilesList.sort((a, b) => (a.type?.name > b.type?.name ? -1 : 1)));
      setDocsFilesList([...sortedName]);
      setTypeTop((prev) => !prev);
    }
  };

  const onClickBlock = (doc) => {
    setSelectedFiles(doc);
  };

  const onSubmitModalDocument = (values, validateForm, resetForm, submitForm) => {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0 && values.doc !== '';
      if (isValid) {
        const formData = new FormData();
        formData.append(`name`, values.name);
        values.expire_date &&
          formData.append(`expiration_date`, values.no_expiry ? '' : moment(values.expire_date).format('YYYY/MM/DD'));
        formData.append(`file`, values.doc);
        formData.append(`carrier_id`, id);
        createCarrierDocument(formData)
          .then(() => {
            setIsOpen(false);
            getFilesList();
            showToaster({ type: 'success', message: `Successfully added!` });
          })
          .catch((err) => {
            showToaster({ type: 'error', message: getErrorMessage(err) });
          });
      }
    });
  };

  const onDeleteItem = (e, doc) => {
    if (doc?.id) {
      deleteCarrierDocument(doc.id)
        .then(() => {
          showToaster({ type: 'success', message: `Successfully added!` });
          getFilesList();
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  useEffect(() => {
    getFilesList();
  }, []);

  return (
    <div className={classNames(styles.container, use(styles.light, styles.dark))}>
      <div className={styles.documents_block}>
        {loadingFilesList ? (
          <div className={styles.skeletonWrapper}>
            <Skeleton style={{ width: '20%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '30%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
          </div>
        ) : (
          <>
            <div className={styles.documents_wrapper}>
              <Typography variant='h2'>Documents</Typography>
              <CustomButton
                type='secondary'
                title='Add Document'
                styleButton={{ margin: 0 }}
                onClick={() => setIsOpen(true)}
                leftIcon={<PlusIcon fill={use(palette.gray700, palette.gray50)} style={{ marginRight: 10 }} />}
              />
            </div>

            <div className={styles.documents_data}>
              <div className={styles.sorting_wrapper}>
                <div onClick={() => sortBy('name')} className={styles.sort_item} style={{ width: '100%' }}>
                  <Typography variant='overLine' style={{ margin: 8 }}>
                    NAME
                  </Typography>
                </div>
                <div onClick={() => sortBy('type')} className={styles.sort_item}>
                  <Typography variant='overLine'>EXPIRY</Typography>
                </div>
                <div className={styles.sort_item} />
              </div>

              <div className={styles.files_wrapper}>
                {docsFilesList.map((doc) => {
                  return (
                    <DocumentsItem
                      key={doc.id || doc.path}
                      doc={doc}
                      onClickBlock={onClickBlock}
                      onDelete={onDeleteItem}
                      onSubmitEdit={() => getFilesList()}
                      carrierId={id}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>

      {/* //License Block */}
      <div className={styles.iframe_wrapper}>
        <figure ref={refFigure} style={{ width: 430, height: 580 }}>
          <iframe
            onLoad={() => {
              if (selectedFiles?.path) {
                refFigure.current.style.width = '100%';
                refFigure.current.style.height = '100%';
                refFigure.current.style.margin = '0';
              }
            }}
            src={selectedFiles?.path ? selectedFiles?.path : use(license, licenseDark)}
            style={{ height: '100%', width: '100%', borderRadius: 8 }}
            title='license'
          />
        </figure>
      </div>

      {!!isOpen && <AddDocumentModal isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={onSubmitModalDocument} />}
    </div>
  );
};

export default Documents;
