import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: calc(100% + 40px);
  height: 100%;
  margin-left: -28px;
  position: relative;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;

  .restriction-content-wrapper {
    margin-top: -84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }

  .purchase-addon {
    width: fit-content;
    cursor: pointer;
    color: ${palette.indigo500};

    :hover {
      text-decoration: underline;
    }
  }
`;

export const SGradientBox = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 75vh;
  z-index: -1;
  background: linear-gradient(
    122deg,
    #ffffff 16%,
    #f9f7ff,
    #f1edff,
    #f9f7ff 42%,
    #ffffff 47%,
    #fff3f6,
    #ffe9ed,
    #fff3f6 68%,
    #ffffff
  );
  background-blend-mode: screen;

  :after {
    content: '';
    height: 100px;
    width: 100%;
    display: inline-block;
    background: linear-gradient(to bottom, transparent, white);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  :before {
    content: '';
    height: 100px;
    width: 100%;
    display: inline-block;
    background: linear-gradient(to top, transparent, white);
    position: absolute;
    top: 0;
    left: 0;
  }
`;
