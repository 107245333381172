import React, { useContext, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { ReactComponent as FullIcon } from 'assets/icons/createShipment/fullTruckload.svg';
import { ReactComponent as LessThenTruckload } from 'assets/icons/createShipment/lessThenTruckload.svg';
import Radio from 'common/Radio';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getShipmentsSingle } from 'Api/Planner';

import uuid from 'react-uuid';
import { initialRecurs } from 'components/CreateShipment/Schedule/Schedule.data';
import moment from 'moment';
import { validationSchema } from './validationSchema';
import { ShipmentContext } from '../Layout';
import Frequency from './TypeOfShipmentHelpers/Frequency';
import ShipmentDetails from './TypeOfShipmentHelpers/ShipmentDetails';
import { TypeOfShipmentFooter } from './TypeOfShipmentHelpers/TypeOfShipmentFooter';
import { fillBillChargesData, fillStopPointData } from '../ShipmentStops/helpers/constants';
import sm from './TypeOfShipment.module.css';

const TypeOfShipment = () => {
  const {
    onCloseModal,
    setStep,
    createShipmentData,
    updateShipmentData,
    updateShipmentType,
    detailCopyShipmentData,
    detailCopyShipmentLTLData,
    updateSelectedCharges,
    updateBillToSelected,
    setActiveBillChargeIndex,
    updateContactSelected,
    updateSelectedGrandTotal,
  } = useContext(ShipmentContext);
  const [loading, setLoading] = useState(false);

  const copyUpdateCreatShipmentData = (data, valuesFirstStep) => {
    const stops = data?.shipment_stops;
    const billing = data.shipment_type === 'ltl' ? data?.shipment_billing : data?.shipment_billing[0];
    const legs = [];
    const ltlLegs = [];
    const billingCharges = [];
    stops.forEach((stop) => {
      let foundedLeg = legs.find((leg) => leg.number === stop.leg);
      if (!foundedLeg) {
        legs.push({ number: stop.leg });
        foundedLeg = legs[legs.length - 1];
      }
      if (!foundedLeg.stops) {
        foundedLeg.stops = [];
      }
      foundedLeg.stops.push(
        fillStopPointData(
          stop.stop_point_type,
          Number(stop.stop_point_type) === 2
            ? {
                ...stop,
                connected_cargos: (stop.connected_cargos || []).map((i) => ({
                  ...i,
                  stopIndex: `${i.shipment_stop.order_in_leg || 'A'}`,
                })),
              }
            : stop,
          Number(createShipmentData?.frequency) === 2
        )
      );
    });

    if (data.shipment_type === 'ltl') {
      const sortedStops = stops.sort((a, b) => {
        if (a.slave_stop?.order_in_leg > b.slave_stop?.order_in_leg) {
          return 1;
        }
        if (a.slave_stop?.order_in_leg < b.slave_stop?.order_in_leg) {
          return -1;
        }
        return 0;
      });

      sortedStops?.length &&
        sortedStops.forEach((stop) => {
          const slaveStopNumber = Number(stop.slave_stop.shipment_id.split('-')[1]);
          let foundedLeg = ltlLegs.find((leg) => leg.number === slaveStopNumber);
          if (!foundedLeg) {
            ltlLegs.push({ number: slaveStopNumber });
            foundedLeg = ltlLegs[ltlLegs.length - 1];
          }
          if (!foundedLeg.stops) {
            foundedLeg.stops = [];
          }
          foundedLeg.stops.push(
            fillStopPointData(
              stop.stop_point_type,
              Number(stop.stop_point_type) === 2
                ? {
                    ...stop,
                    connected_cargos: (stop.connected_cargos || []).map((i) => ({
                      ...i,
                      stopIndex: `${i.shipment_stop.order_in_leg || 'A'}`,
                    })),
                  }
                : stop,
              Number(createShipmentData?.frequency) === 2
            )
          );
        });
    }

    let ltlBillings;

    if (data.shipment_type !== 'ltl') {
      billing?.billing_charges.forEach((bill) => {
        billingCharges.push(fillBillChargesData(bill));
      });
    } else {
      ltlBillings = billing.map((item) => {
        const convertedBillingCharges = item.billing_charges.map((i) => fillBillChargesData(i));

        return {
          ...item,
          billingCharges: convertedBillingCharges,
          group: item.groups?.id || '',
          customer_id: item.billing_customer?.id,
        };
      });
    }

    const { frequency, type, shipment_copy_id, laneName, shipment_details_type } = valuesFirstStep;

    const dataToUpdate = {
      frequency,
      type,
      shipment_copy_id,
      laneName,
      shipment_details_type,
    };

    if (Number(frequency) === 2) {
      dataToUpdate.recurs = initialRecurs.map((i) => ({
        ...i,
        checked: 1,
        dateSettings: [
          {
            id: uuid(),
            day_wise_pickup_time: data.shipment_stops[0].from,
            day_wise_pickup_time_to: data.shipment_stops[0].to,
            day_wise_delivery_time_after: data.shipment_stops.map((item, index) => {
              const isTimeBefore =
                index !== 0
                  ? moment(item.from, 'HH:mm').isBefore(moment(data.shipment_stops[index - 1].from, 'HH:mm'))
                  : null;

              return index === 0
                ? ''
                : Math.floor(
                    moment(`${item.scheduled_date} ${item.from}`).diff(
                      moment(`${data.shipment_stops[index - 1].scheduled_date} ${data.shipment_stops[index - 1].from}`),
                      'days'
                    ) + (isTimeBefore ? 1 : 0)
                  ) || '0';
            }),
            delivery_time: data.shipment_stops.map((item, index) => (index === 0 ? '' : item.from)),
            delivery_time_to: data.shipment_stops.map((item, index) => (index === 0 ? '' : item.to)),
          },
        ],
      }));
    }

    if (data.shipment_type === 'ltl') {
      dataToUpdate.shipmentsBilling = ltlBillings;
      dataToUpdate.shipmentsLegs = ltlLegs;
    } else {
      dataToUpdate.group = billing?.groups?.id || '';
      dataToUpdate.customer_id = billing?.billing_customer?.id;
      dataToUpdate.currency_id = billing?.currency_id;
      dataToUpdate.reference_id = billing?.reference_id;
      dataToUpdate.contact_user_id = billing?.contact_user_id;
      dataToUpdate.billingCharges = billingCharges;
      dataToUpdate.legs = legs;
    }

    updateShipmentData(dataToUpdate);

    if (data.shipment_type === 'ltl') {
      const selectedCharges = {};
      ltlBillings.forEach((item, index) => {
        selectedCharges[index] = item.billingCharges;
      });
      updateSelectedCharges(selectedCharges);
    } else {
      updateSelectedCharges({ 0: billingCharges });
    }
  };

  const getCopyShipmentSingle = (values, type) => {
    const shipmentType = type === undefined ? (values?.type ? +values.type : 1) : type;

    /** TL * */
    if (+shipmentType === 1) {
      setLoading(true);
      getShipmentsSingle({ shipment_copy_id: values.shipment_copy_id })
        .then((res) => res && res?.data && copyUpdateCreatShipmentData(res?.data, values))
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          setLoading(false);
        });
    }

    /** LTL * */
    if (+shipmentType === 2) {
      setLoading(true);
      getShipmentsSingle({ shipment_copy_id: values.shipment_copy_id })
        .then((res) => res && res?.data && copyUpdateCreatShipmentData(res?.data, values))
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onNext = (values) => {
    const { type, frequency, laneName, shipment_details_type, shipment_copy_id, copy_count } = values;
    const oneTime = +frequency === 1;
    const data = {
      type,
      frequency,
      copy_count,
      shipment_copy_id,
      shipment_details_type,
    };
    if (!oneTime) {
      data.laneName = laneName;
    }

    updateShipmentData({ ...createShipmentData, ...data });
    setStep((p) => p + 1);
  };

  const initialValue = useMemo(() => {
    return {
      type: createShipmentData?.type ? createShipmentData?.type : '1',
      frequency: createShipmentData?.frequency ? createShipmentData?.frequency : '1',
      laneName: createShipmentData?.laneName ? createShipmentData?.laneName : '',
      shipment_details_type: createShipmentData?.shipment_details_type
        ? createShipmentData?.shipment_details_type
        : '1',
      shipment_copy_id: createShipmentData?.shipment_copy_id ? createShipmentData?.shipment_copy_id : '',
      copy_count: createShipmentData?.copy_count ? createShipmentData?.copy_count : '1',
    };
  }, [createShipmentData]);

  const onChangeType = (value, formValues) => {
    const newShipmentData = { ...formValues, type: value };
    updateBillToSelected({});
    setActiveBillChargeIndex(0);
    updateContactSelected({});
    updateSelectedCharges({});

    if (+value === 1) {
      updateShipmentType(1);
      newShipmentData.type = '1';
      newShipmentData.frequency = createShipmentData?.frequency;
      newShipmentData.copy_count = createShipmentData?.copy_count;
      newShipmentData.laneName = createShipmentData?.laneName;
      newShipmentData.shipment_copy_id = createShipmentData?.shipment_copy_id;
      newShipmentData.shipment_details_type = createShipmentData?.shipment_details_type;
    } else if (+value === 2) {
      // LTL
      delete newShipmentData.billingCharges;
      delete newShipmentData.groups;
      delete newShipmentData.reference_id;
      delete newShipmentData.contact_user_id;
      delete newShipmentData.currency_id;
      delete newShipmentData.customer_id;
      newShipmentData.type = '2';
      updateShipmentType(2);
    }
    updateShipmentData(newShipmentData);
  };

  return (
    <div className={sm.typeOfShipment}>
      <div className={sm.body_wrapper}>
        <div>
          <Formik enableReinitialize onSubmit={onNext} initialValues={initialValue} validationSchema={validationSchema}>
            {({ values, setFieldValue, submitForm, touched, errors }) => {
              return (
                <Form>
                  <Typography variant='s1'>Please select shipment type</Typography>
                  <div className={sm.line} />
                  <div style={{ marginBottom: 40 }}>
                    <div className={sm.type_shipment_child}>
                      <Radio
                        label='Full Truckload (TL)'
                        name='type'
                        id='type-1'
                        value='1'
                        checked={values.type === '1'}
                        onChange={() => onChangeType('1', values)}
                        gap='8px'
                      />
                      <FullIcon
                        style={{ marginTop: 10 }}
                        fill={Number(values?.type) === 1 ? palette.indigo500 : palette.gray500}
                      />
                    </div>
                    <div className={sm.line} />

                    <div className={sm.type_shipment_child}>
                      <Radio
                        label='Less Than Truckload (LTL)'
                        name='type'
                        id='type-2'
                        value='2'
                        checked={values.type === '2'}
                        onChange={() => onChangeType('2', values)}
                        gap='8px'
                      />
                      <LessThenTruckload
                        style={{ marginTop: 10 }}
                        fill={Number(values?.type) === 2 ? palette.indigo500 : palette.gray500}
                      />
                    </div>
                    <div className={sm.line} />
                  </div>
                  <Frequency values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} />
                  <ShipmentDetails
                    values={values}
                    updateShipmentData={updateShipmentData}
                    updateSelectedCharges={updateSelectedCharges}
                    getCopyShipmentSingle={getCopyShipmentSingle}
                    detailCopyShipmentData={
                      Number(createShipmentData.type) === 2 ? detailCopyShipmentLTLData : detailCopyShipmentData
                    }
                    updateSelectedGrandTotal={updateSelectedGrandTotal}
                  />
                  <TypeOfShipmentFooter onNext={submitForm} onCloseModal={onCloseModal} loading={loading} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TypeOfShipment;
