import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;
  margin-top: 12px;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 12px;
  }

  td {
    padding: 12px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-wrapper {
    visibility: hidden;
  }

  .row-item-wrapper {
    display: flex;
    align-items: center;
    column-gap: 6px;
    white-space: nowrap;
  }

  :hover {
    background-color: ${palette.gray0};

    .actions-wrapper {
      visibility: visible;
    }
  }
`;

export const SAddAction = styled.span`
  display: inline-flex;
  padding: 3px;
  cursor: pointer;
  border-radius: 4px;

  :hover {
    background-color: ${palette.indigo50};
  }

  svg path {
    fill: ${palette.indigo500};
  }
`;
