import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Input from 'common/Input';
import Divider from 'common/Divider';
import InputLabel from 'common/InputLabel';
import DatePicker from 'common/DatePicker';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomTimeInput from 'components/CustomTimeInput';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import AddStopPointModal from 'components/CreateShipment/CreateModals/addStopPointModal';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { getAlphabet, palette } from 'utils/constants';
import { getAllTrailers } from 'Api/Map';
import { getEquipmentTypes } from 'Api/Equipment';
import { GetEquipmentTrailer } from 'Api/StopPoint';
import { createQuickEquipment } from 'Api/Shipment';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  SCHEDULED_DATE_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getStopName } from 'componentsV2/Shipment/SplitShipment/SplitShipment.data';
import { customerStopPoint } from 'Api/Planner';
import { SAddStops } from './AddStops.styles';

const AddStops = ({ form, shipmentId, dimensions }) => {
  const showToaster = useShowToaster();
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [localEquipmentIdData, setLocalEquipmentIdData] = useState([]);
  const [droppedTrailerIds, setDroppedTrailerIds] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [openAddStopPoint, setOpenAddStopPoint] = useState(false);
  const [thirdPartyLoading, setThirdPartyLoading] = useState(false);

  const { value: userData } = useAuth();
  const { values, handleChange, touchedErrors, setFieldValue, setFieldTouched } = form;

  const getEquipmentType = async () => {
    try {
      const { data } = await getEquipmentTypes({}, userData.user?.customer?.dot);
      setEquipmentTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getStopPoints = async (setNewAdded) => {
    try {
      const { data } = await customerStopPoint(undefined, { only_active: 1, for_shipment: shipmentId });
      setStopPoints(data);
      if (setNewAdded) {
        handleChange('stop_point', data[data.length - 1]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipmentIdInType = async () => {
    try {
      const { data } = await getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8' } });
      setLocalEquipmentIdData(data);
      // setEquipmentId([...equipmentId]);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailers = async () => {
    try {
      const { data } = await GetEquipmentTrailer({
        'sort[][equipment_id]': 'desc',
        stop_point_id: Number(values.stop_point?.id),
      });
      setDroppedTrailerIds(data.map((i) => i.equipment_id));
    } catch (e) {
      // Do nothing
    }
  };

  const onAddCustomTrailer = async (stop, index) => {
    const { custom_trailer_type, custom_trailer_id, custom_trailer_length } = stop || {};

    if (!custom_trailer_type || !custom_trailer_id || !custom_trailer_length) {
      return;
    }

    try {
      setThirdPartyLoading(true);
      const body = {
        equipment_type_id: custom_trailer_type.key,
        equipment_id: custom_trailer_id,
        length_id: custom_trailer_length.key,
      };
      const { data } = await createQuickEquipment(body);
      const label = `${data?.equipment_id || ''}  (${data?.make || ''}  ${data?.model_id || ''})`;
      const newValue = {
        ...data,
        key: data.id,
        label,
        labelSelected: null,
      };
      setLocalEquipmentIdData([...localEquipmentIdData, newValue]);
      showToaster({ type: 'success', message: 'Success' });
      handleChange(`stops[${index}].custom_trailer_id`, '');
      handleChange(`stops[${index}].custom_trailer_type`, null);
      handleChange(`stops[${index}].custom_trailer_length`, null);
      handleChange(`stops[${index}].equipment_id`, data);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setThirdPartyLoading(false);
    }
  };

  useEffect(() => {
    getEquipmentType();
    getEquipmentIdInType();
    getStopPoints();
  }, []);

  useEffect(() => {
    if (!values.stop_point?.id) {
      return;
    }

    getTrailers();
  }, [values.stop_point]);

  return (
    <SAddStops>
      <div className='d-flex align-items-center gap-3'>
        <div>
          <Autocomplete
            required
            width='540px'
            name='location_name'
            placeholder='Select Stop Point'
            options={stopPoints}
            value={values.stop_point}
            onChange={(e, val) => handleChange('stop_point', val)}
            getOptionLabel={(option) =>
              `${option.location_name} - ${option.address1 || ''}, ${option.city?.name || ''}, ${
                option.state?.short_name || ''
              } ${option.zipcode || ''}`
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.location_name} - {option.address1 || ''}, {option.city?.name || ''},{' '}
                {option.state?.short_name || ''} {option.zipcode || ''}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.stop_point}
          />
        </div>
        <Typography
          variant='s2'
          style={{ color: palette.indigo500 }}
          onClick={() => setOpenAddStopPoint(true)}
          className='pointer'
        >
          <PlusIcon /> Add Stop Point
        </Typography>
      </div>
      <Divider />
      {values.stops.map((stop, index) => (
        <div key={stop.id} className='mb-5'>
          <div className='d-flex gap-5 mb-2'>
            <Typography variant='c1' style={{ color: getStopName(Number(stop?.stop_point_type)).color }}>
              SHIPMENT {`${shipmentId}-S${stop.sectionIndex + 1}`}
            </Typography>
            <Typography variant='c1' style={{ color: getStopName(Number(stop?.stop_point_type)).color }}>
              STOP {getAlphabet(stop.stopIndex)} {getStopName(Number(stop?.stop_point_type)).type}
            </Typography>
          </div>
          <div className='d-flex align-items-center gap-3 mb-2'>
            <InputLabel className='add-stop-form-label'>Scheduled Date Type</InputLabel>
            <div>
              <Autocomplete
                size='small'
                width='350px'
                name={`stops[${index}].scheduled_type`}
                labelKey='label'
                options={SCHEDULED_DATE_TYPE}
                value={stop.scheduled_type}
                onChange={(e, val) => handleChange(`stops[${index}].scheduled_type`, val)}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                error={touchedErrors.stops?.[index]?.scheduled_type}
              />
            </div>
          </div>
          <div className='d-flex align-items-center gap-3 mb-2'>
            <InputLabel className='add-stop-form-label'>Scheduled Date/Time</InputLabel>
            <div>
              <DatePicker
                width='136px'
                name={`stops[${index}].scheduled_date`}
                value={stop.scheduled_date}
                dateFormat='MM/dd/yyyy'
                onChange={(val) => handleChange(`stops[${index}].scheduled_date`, val)}
              />
              <ErrorMessage error={touchedErrors.stops?.[index]?.scheduled_date} />
            </div>
            <div>
              <CustomTimeInput
                field={{ name: `stops[${index}].from`, value: stop.from }}
                form={{ setFieldValue, setFieldTouched }}
                style={{ width: '80px', justifyContent: 'center' }}
              />
              <ErrorMessage error={touchedErrors.stops?.[index]?.from} />
            </div>
            {[1, 3].includes(stop.scheduled_type?.key) && (
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='b2'>to</Typography>
                <div>
                  <DatePicker
                    width='136px'
                    name={`stops[${index}].scheduled_date_to`}
                    value={stop.scheduled_date_to}
                    dateFormat='MM/dd/yyyy'
                    onChange={(val) => handleChange(`stops[${index}].scheduled_date_to`, val)}
                  />
                  <ErrorMessage error={touchedErrors.stops?.[index]?.scheduled_date} />
                </div>
                <CustomTimeInput
                  field={{ name: `stops[${index}].to`, value: stop.to }}
                  form={{ setFieldValue, setFieldTouched }}
                  style={{ width: '80px', justifyContent: 'center' }}
                />
                <ErrorMessage error={touchedErrors.stops?.[index]?.to} />
              </div>
            )}
          </div>

          {Number(stop.stop_point_type) === 1 && (
            <div className='d-flex align-items-center gap-3 mb-2'>
              <InputLabel className='add-stop-form-label'>Equipment Required</InputLabel>
              <div>
                <Autocomplete
                  size='small'
                  width='214px'
                  name={`stops[${index}].equipment_type`}
                  labelKey='title'
                  options={equipmentTypes}
                  value={stop.equipment_type}
                  onChange={(e, val) => {
                    handleChange(`stops[${index}].equipment_type`, val);
                    handleChange(`stops[${index}].equipment_type_length`, null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  error={touchedErrors.stops?.[index]?.equipment_type}
                />
              </div>
              {stop.equipment_type?.vehicle_type_id === 2 && (
                <div>
                  <Autocomplete
                    size='small'
                    width='120px'
                    name={`stops[${index}].equipment_type_length`}
                    options={dimensions.filter(
                      (i) => !!i.length_info && Number(i.equipment_type_id) === Number(stop?.equipment_type?.id)
                    )}
                    value={stop.equipment_type_length}
                    onChange={(e, val) => handleChange(`stops[${index}].equipment_type_length`, val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(option) => `${option.length_info?.length} ${option.length_info?.unit}`}
                    error={touchedErrors.stops?.[index]?.equipment_type_length}
                  />
                </div>
              )}
            </div>
          )}
          <div className='d-flex align-items-center gap-3 mb-2'>
            <InputLabel className='add-stop-form-label'>Equipment Action</InputLabel>
            <div>
              <Autocomplete
                width='350px'
                size='small'
                name={`stops[${index}].equipment_action`}
                labelKey='label'
                isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                value={stop.equipment_action}
                onChange={(e, val) => handleChange(`stops[${index}].equipment_action`, val)}
                options={
                  Number(stop?.stop_point_type) === 2
                    ? EQUIPMENT_ACTION_DELIVERY
                    : stop.equipment_type?.vehicle_type_id === 2
                    ? EQUIPMENT_ACTION
                    : stop.equipment_type?.vehicle_type_id === 1
                    ? [EQUIPMENT_ACTION[0]]
                    : []
                }
                disabled={Number(stop.stop_point_type) === 1 && !stop?.equipment_type}
                error={touchedErrors.stops?.[index]?.equipment_action}
              />
            </div>
          </div>

          {Number(stop.stop_point_type) === 1 &&
            (stop.equipment_action?.key === 2 || stop.equipment_action?.key === 3) && (
              <div className='d-flex align-items-center gap-3 mb-2'>
                <InputLabel className='add-stop-form-label'>Equipment</InputLabel>
                <SubRight width={20} />
                <div>
                  <div>
                    <Autocomplete
                      size='small'
                      width='314px'
                      name={`stops[${index}].equipment_id`}
                      options={localEquipmentIdData.filter((i) => droppedTrailerIds.includes(i.id))}
                      value={stop.equipment_id}
                      onChange={(e, val) => handleChange(`stops[${index}].equipment_id`, val)}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) =>
                        `${option?.equipment_id || ''} (${option?.make || ''} ${option?.model_id || ''})`
                      }
                      disabled={stop.equipment_tbd}
                      error={touchedErrors.stops?.[index]?.equipment_id}
                    />
                  </div>
                  <div>
                    <div style={{ maxWidth: '314px', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                      <CustomCheckbox
                        name={`stops[${index}].thirdParty`}
                        checked={stop.thirdParty}
                        onChange={(checked) => {
                          if (checked) {
                            handleChange(`stops[${index}].thirdParty`, true);
                            handleChange(`stops[${index}].equipment_tbd`, false);
                            handleChange(`stops[${index}].equipment_id`, null);
                          } else {
                            handleChange(`stops[${index}].thirdParty`, false);
                          }
                        }}
                      >
                        <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                          3rd party
                        </Typography>
                      </CustomCheckbox>
                      <CustomCheckbox
                        name={`stops[${index}].equipment_tbd`}
                        checked={stop.equipment_tbd}
                        onChange={(checked) => {
                          if (checked) {
                            handleChange(`stops[${index}].equipment_tbd`, true);
                            handleChange(`stops[${index}].thirdParty`, false);
                            handleChange(`stops[${index}].equipment_id`, null);
                          } else {
                            handleChange(`stops[${index}].equipment_tbd`, false);
                          }
                        }}
                      >
                        <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                          To be determined (TBD)
                        </Typography>
                      </CustomCheckbox>
                    </div>
                    {stop.thirdParty && (
                      <div className='d-flex gap-3 mt-3 mb-2'>
                        <Input
                          width='100px'
                          size='small'
                          name={`stops[${index}].custom_trailer_id`}
                          placeholder='ID'
                          value={stop.custom_trailer_id}
                          onChange={handleChange}
                        />
                        <Autocomplete
                          width='120px'
                          size='small'
                          labelKey='title'
                          placeholder='Type'
                          options={equipmentTypes.filter((item) => item.vehicle_type_id === 2)}
                          value={stop.custom_trailer_type}
                          onChange={(e, val) => handleChange(`stops[${index}].custom_trailer_type`, val)}
                        />
                        <Autocomplete
                          width='100px'
                          size='small'
                          labelKey='label'
                          placeholder='Length'
                          options={dimensions.filter(
                            (i) =>
                              !!i.length_info && Number(i.equipment_type_id) === Number(stop?.custom_trailer_type?.id)
                          )}
                          value={stop.custom_trailer_length}
                          onChange={(e, val) => handleChange(`stops[${index}].custom_trailer_length`, val)}
                          getOptionLabel={(option) => `${option.length_info?.length} ${option.length_info?.unit}`}
                        />
                        <div className='d-flex gap-2'>
                          <CustomButton
                            type='secondary'
                            leftIcon={<DeleteIcon fill={palette.red500} width={11} height={11} />}
                            styleButton={{ padding: '5px 8px', margin: 0 }}
                            onClick={() => {
                              handleChange(`stops[${index}].custom_trailer_id`, '');
                              handleChange(`stops[${index}].custom_trailer_type`, null);
                              handleChange(`stops[${index}].custom_trailer_length`, null);
                              handleChange(`stops[${index}].thirdParty`, false);
                              handleChange(`stops[${index}].equipment_id`, null);
                            }}
                            disabled={thirdPartyLoading}
                          />
                          <CustomButton
                            type='primary'
                            onClick={() => onAddCustomTrailer(stop, index)}
                            leftIcon={<CheckIcon fill={palette.white} width={11} height={11} />}
                            styleButton={{ padding: '5px 8px', marginTop: 0 }}
                            disabled={thirdPartyLoading}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
      {!!openAddStopPoint && (
        <AddStopPointModal
          show={openAddStopPoint}
          getCustomerStopPoint={() => getStopPoints(true)}
          onChangeShow={setOpenAddStopPoint}
          wrapperClassName='position-fixed'
          containerClassName='add-stop-point-modal-body'
        />
      )}
    </SAddStops>
  );
};

export default AddStops;
