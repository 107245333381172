import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from 'common/InputLabel';
import Input, { Textarea } from 'common/Input';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { yesNoOptions } from '../../ApplyJob.data';
import { SFlexGridItem } from '../Steps.styles';

const Other = ({ form, onGetQuestions, questions, currentEmployment }) => {
  const { use } = useTheme();
  const { values, setValues, handleChange, touchedErrors } = form;

  useEffect(() => {
    const questionIds = [];

    setValues({
      questions: questions.map((item) => {
        questionIds.push(item.id);
        return item.question === 'Currently employed with' && !!currentEmployment?.employerName
          ? currentEmployment?.employerName
          : '';
      }),
    });

    onGetQuestions(questionIds);
  }, [questions]);

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        {questions?.map((item, index) => (
          <Grid item xs={12} key={item.id}>
            {item.option === 'Yes or No' && (
              <>
                <InputLabel required>{item.question}</InputLabel>
                <SFlexGridItem>
                  {yesNoOptions.map((item) => (
                    <CustomRadioButton
                      key={item.value}
                      field={{
                        name: `questions[${index}]`,
                        value: item.value,
                        checked: values?.questions?.[index] === item.value,
                        onChange: handleChange,
                      }}
                    >
                      <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                        {item.label}
                      </Typography>
                    </CustomRadioButton>
                  ))}
                </SFlexGridItem>
                <ErrorMessage error={touchedErrors?.questions?.[index]} />
              </>
            )}
            {item.option === 'Line Reply' && (
              <Input
                name={`questions[${index}]`}
                label={item.question}
                onChange={handleChange}
                value={values?.questions?.[index] || ''}
                error={touchedErrors?.questions?.[index]}
                required
              />
            )}
            {item.option === 'Multi-Line Reply' && (
              <Textarea
                name={`questions[${index}]`}
                label={item.question}
                onChange={handleChange}
                value={values?.questions?.[index] || ''}
                error={touchedErrors?.questions?.[index]}
                rows={3}
                required
              />
            )}
          </Grid>
        ))}
      </Grid>
    </form>
  );
};

export default Other;
