import axios from '../services/axios';

export async function getTimeTrackingList(params) {
  const { data } = await axios.get('customer/payroll/time-tracking', {
    params,
  });
  return data;
}

export async function createTimeTracking(formData) {
  const { data } = await axios.post(`customer/payroll/time-tracking`, formData);
  return data;
}

export async function deleteTimeTrackingRecord(id) {
  const { data } = await axios.delete(`customer/payroll/delete-time-track-record/${id}/remove`);
  return data;
}

export async function updateTimeTracking(id, body) {
  const { data } = await axios.put(`customer/payroll/time-tracking/${id}`, body);
  return data;
}

export async function sendEmail(formData) {
  const { data } = await axios.post(`customer/payroll/time-tracking/send-email`, formData);
  return data;
}
