import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './StaffListStyle.css';
import 'flag-icon-css/css/flag-icons.min.css';
import uuid from 'react-uuid';
import moment from 'moment';
import hexRgb from 'hex-rgb';
import Dialog from '@mui/material/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import UserInfo from 'components/UserInfo';
import StaffHeader from 'components/StaffHeader';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import TableFooter from 'components/TableFooter/TableFooter';
import TableWrapper from 'components/TableWrapper';
import useDateFormat from 'hooks/useDateFormat';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import CustomizedSnackbars from 'components/toast/Toast';
import TabUpdateStaffModal from 'components/AddTabStaffModal/AddTabStaffModal';

import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { getErrorMessage } from 'utils/error';
import { MaterialTableSort } from 'components/MaterialTableWrapper';
import { palette, statusColor } from 'utils/constants';
import { deleteTab, getTabs, hourOfServiceAll, Staff } from 'Api/Staff';
import { getBillToBillingCharges, getPlannerPopoverSettings } from 'Api/Planner';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';

const StaffTableList = () => {
  const { theme, use } = useTheme();
  const { formatDate, formatDateTime } = useDateFormat();
  const myTableRef = useRef(null).current;
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [staffData, setStaffData] = useState({});
  const [UpdateModalShow, setUpdateModalShow] = useState(false);
  const [AddModalShow, setAddModalShow] = useState(false);
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [getPlanerFilterData, setGetPlanerFilterData] = useState({});
  const [customers, setCustomers] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [hourService, setHourService] = useState([]);
  const [removeTabModalOpen, setRemoveTabModalOpen] = useState(null);
  const [removeTabloading, setRemoveTabloading] = useState(null);
  const [filterTableTop, setFilterTableTop] = useState([]);
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [sortData] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [search, setSearch] = useState('');

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const locationInitialValue = {
    city: { searchValue: '', value: 'City', key: uuid() },
    state: { searchValue: '', value: 'State', key: uuid() },
    zipCode: { searchValue: '', value: 'Postcode', key: uuid() },
    miles: { searchValue: '', value: 'Radius', key: uuid() },
  };
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Active', value: 'active' },
    searchValue: '',
    allValue: '',
    selectedValues: [],
    locations: [],
    origin_location: locationInitialValue,
    destination_location: locationInitialValue,
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: switchRadioButtons?.amount || '1',
      date: switchRadioButtons?.date || '1',
      time: switchRadioButtons?.time || '1',
    },
    forAll: false,
    planerHeaderSwitch: { planerSwitch: '0' },
    applyAllUsersModal: { value: '0' },
  });
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});

  function filterDataTable(data) {
    if (!data) return [];
    return data?.filter((data) => {
      const { hire_date } = data || {};
      const { phone_number, first_name, last_name, login_status, last_login_at } = data?.staff || {};
      const { group_name } = data?.staff?.groups[0] || {};
      const { department_name } = data?.department || {};

      const firstName = first_name;
      const lastName = last_name;
      const phone = phone_number;
      const loginStatus = login_status;
      const department = department_name;
      const group = group_name;
      const joinDate = hire_date;
      const origin = last_login_at;

      let cond;
      switch (filter?.searchBy?.value) {
        case 'all':
          cond = !filter.allValue
            ? true
            : filterByValue([firstName, lastName, phone, loginStatus, department, group, joinDate, origin]);
          break;
        case 'name':
          cond = !filter.allValue ? true : filterByValue([`${firstName} ${lastName}`]);
          break;
        default:
          cond = true;
      }
      return cond;
    });
  }

  const updateFilterCount = (res) => {
    const tempFilterTableTop = [...filterTableTop];
    tempFilterTableTop[0].count = res.total;
    setFilterTableTop([...tempFilterTableTop]);
  };

  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[][${field}]`;
    }

    getSortedDocuments(sortField, sortBy);
  }

  const getSortedDocuments = (sortField, sortBy) => {
    const payload = { page: '1', itemsPerPage: rowPerPage, [sortField]: sortBy };
    Staff(payload).then((res) => {
      setStaffData(res);
    });
  };

  const onReset = (filterData = null, isClearAll) => {
    for (const multiOption of []) {
      for (const opt of multiOption.submenu) {
        if (opt.isChecked) {
          opt.isChecked = false;
          opt.apply = false;
        }
      }
    }
    let params = { page: 1, itemsPerPage: rowPerPage, 'sort[][id]': 'desc', 'sort[][login_status]': 'asc' }; //
    if (filterData) {
      params = { ...params, ...filterData };
    }
    setLoading(true);
    const { group_id, status } = params;
    let payload = { ...params, login_status: status?.toString(), group: group_id?.toString() };
    delete payload.can_delete;
    delete payload.group_id;
    delete payload.status_id;
    delete payload.status;
    if (!payload.group) {
      delete payload.group;
    }
    if (!filterData) {
      payload = {
        page: 1,
        itemsPerPage: rowPerPage,
        'sort[][id]': 'desc',
        'sort[][login_status]': 'asc',
        login_status:
          filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
      };
    }
    Staff(payload)
      .then((res) => {
        setStaffData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
        if (isClearAll) {
          updateFilterCount(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };
  const onPageChange = (event, page) => {
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filterData = {
      page,
      itemsPerPage: rowPerPage,
      'sort[][id]': 'desc',
      'sort[][login_status]': 'asc',
      login_status:
        filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
    };

    Staff(filterData)
      .then((res) => {
        setStaffData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
      })
      .finally(() => setLoading(false));
  };

  const onChangeAll = useCallback(
    (value) => {
      let params = { query: value, page: 1, itemsPerPage: rowPerPage, ...advancedFilter };
      if (!value) {
        params = {
          query: value,
          page: 1,
          itemsPerPage: rowPerPage,
          'sort[][id]': 'desc',
          'sort[][login_status]': 'asc',
          login_status:
            filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
          ...advancedFilter,
          joinDateRange: undefined,
          join_date_from: advancedFilter?.joinDateRange?.[0]
            ? moment(advancedFilter?.joinDateRange?.[0]).format('MM/DD/YYYY')
            : undefined,
          join_date_to: advancedFilter?.joinDateRange?.[1]
            ? moment(advancedFilter?.joinDateRange?.[1]).format('MM/DD/YYYY')
            : undefined,
        };
      }
      Staff(params).then((res) => {
        setStaffData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
      });
    },
    [advancedFilter]
  );

  const navigate = useNavigate();
  const columns = useMemo(() => {
    return [
      {
        field: 'first_name',
        title: <MaterialTableSort title='NAME' field='first_name' sortingQuery={sortingQuery} nested_field='staff' />,
        render: (rowData) => (
          <UserInfo
            size='48px'
            data={{
              ...(rowData.staff || {}),
              name: `${rowData.staff?.first_name || ''} ${rowData.staff?.last_name || ''}`,
              image: rowData.staff?.profile_logo,
              status: rowData.staff?.login_status,
              user_type: 'staff',
            }}
          />
        ),
      },
      {
        field: 'phone_number',
        title: (
          <MaterialTableSort title='PHONE NUMBER' field='phone_number' sortingQuery={sortingQuery} isSorting='false' />
        ),
        render: (rowData) => {
          const countryCode = parsePhoneNumberFromString(rowData?.staff?.phone_number || '')?.country?.toLowerCase();
          return (
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
              {countryCode && <span className={`flag-icon flag-icon-${countryCode}`} />}
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.staff?.phone_number ? rowData?.staff?.phone_number : '-'}
              </p>
            </div>
          );
        },
      },
      {
        field: 'login_status',
        title: <MaterialTableSort title='STATUS' field='login_status' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <div className='status-container-wrap'>
              {rowData?.login_status ? (
                <div
                  className='warning-status-container'
                  style={{
                    backgroundColor: use(
                      statusColor[rowData?.login_status]?.bgColor,
                      statusColor[rowData?.login_status]?.darkBgColor
                    ),
                  }}
                >
                  <p
                    className='warning-status-text'
                    style={{
                      color: use(
                        statusColor[rowData?.login_status]?.color,
                        statusColor[rowData?.login_status]?.darkColor
                      ),
                    }}
                  >
                    {rowData?.login_status}
                  </p>
                </div>
              ) : (
                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                  {' '}
                  -
                </p>
              )}
            </div>
          );
        },
      },
      {
        field: 'department',
        title: (
          <MaterialTableSort
            title='DEPARTMENT'
            field='department_name'
            sortingQuery={sortingQuery}
            nested_field='department'
          />
        ),
        render: (rowData) => (
          <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData?.department?.department_name}
          </p>
        ),
      },
      {
        field: 'groups',
        title: (
          <MaterialTableSort
            title='Tags'
            field='groups'
            isSorting='false'

            //   sortingQuery={sortingQuery}
          />
        ),
        render: (rowData) => {
          const groups = rowData?.staff?.groups;

          return (
            <div className={`flex-row d-flex ${rowData.id ? 'rowData_wrapper' : ''}`}>
              {groups?.length > 0 ? (
                groups.map((el) => {
                  const colorCode = hexRgb(el.color);
                  const TextColor =
                    colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
                  return (
                    <div
                      key={el.id}
                      className='tablePlaner-shortName'
                      style={{
                        backgroundColor: el.color,
                        color: TextColor,
                      }}
                    >
                      {el?.short_name}
                    </div>
                  );
                })
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      },
      {
        field: 'hire_date',
        title: <MaterialTableSort title='JOIN DATE' field='hire_date' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData?.hire_date ? formatDate(`${rowData?.hire_date}`) : ''}
            </p>
          );
        },
      },
      {
        field: 'last_login_at',
        title: (
          <MaterialTableSort
            title='LAST LOGIN'
            field='last_login_at'
            sortingQuery={sortingQuery}
            nested_field='staff'
          />
        ),
        render: (rowData) => (
          <div className='more-dowpdown-container' onClick={(e) => e.stopPropagation()}>
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.staff?.last_login_at
                ? formatDateTime(moment.utc(`${rowData?.staff?.last_login_at}`).local())
                : '-'}
            </p>
          </div>
        ),
      },
      {
        field: 'last_login_at',
        title: (
          <MaterialTableSort
            title='LAST LOGIN'
            field='last_login_at'
            //   sortingQuery={sortingQuery}
          />
        ),
        render: (rowData) => {
          return (
            <div className='status-container-wrap'>
              <div
                className='warning-status-container'
                style={{
                  backgroundColor: use(
                    statusColor[rowData?.status_title]?.bgColor,
                    statusColor[rowData?.status_title]?.darkBgColor
                  ),
                }}
              >
                <p
                  className='warning-status-text'
                  style={{
                    color: use(
                      statusColor[rowData?.status_title]?.color,
                      statusColor[rowData?.status_title]?.darkColor
                    ),
                  }}
                >
                  {rowData?.status_title}
                </p>
              </div>
            </div>
          );
        },
      },
    ];
  }, [staffData, filter.switchRadioButtons, theme, sortData, tableColumn, dragItem, switchRadioButtons, isAdded]);

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    const filterData = {
      page: 1,
      itemsPerPage: rowPage,
      'sort[][id]': 'desc',
      'sort[][login_status]': 'asc',
      login_status:
        filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
    }; //
    Staff(filterData)
      .then((res) => {
        setStaffData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
      })
      .finally(() => setLoading(false));
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col });
    });
    return cols;
  };

  const mapperSettingsData = (key) => {
    const map = {
      first_name: 'Name',
      phone_number: 'Phone Number',
      login_status: 'Status',
      department: 'Department',
      groups: 'Tags',
      hire_date: 'Join Date',
      last_login_at: 'Last Login',
    };
    return map[key] ? map[key] : key;
  };

  const getSettings = () => {
    getPlannerPopoverSettings({ type: 'staff' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });
          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
          setFilter({
            ...filter,
            switchRadioButtons: {
              date,
              amount,
              time,
            },
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getStaff = ({ filtersType, filtersValue, reset = false, remainingObj }) => {
    setLoading(true);
    return Staff({
      page: staffData?.current_page,
      'sort[][id]': 'desc',
      'sort[][login_status]': 'asc',
      itemsPerPage: rowPerPage,
      login_status:
        filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
      filtersType,
      filtersValue,
      remainingData: reset ? {} : remainingObj || getPlanerFilterData,
    })
      .then((res) => {
        setStaffData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
      })
      .finally(() => setLoading(false));
  };

  function find(string, chunk) {
    return String(string).toLowerCase().indexOf(chunk.toLowerCase()) === 0;
  }
  function filterByValue(criterionArr) {
    return criterionArr.some((criterion) => {
      return find(criterion, filter.allValue);
    });
  }

  function onSearchLocations(locations) {
    if (locations.length > 0) {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      const index = filtered.findIndex((el) => Array.isArray(el));
      const locationIndex = locations[0].valuePrefix === 'origin_location' ? index : index + 1;
      filtered.splice(locationIndex, 0, [...locations]);
      setShowHeaderFilters([...filtered]);
    } else {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      setShowHeaderFilters([...filtered]);
    }
  }

  function onChangeMultiselectSearchBy(data) {
    const origin = showHeaderFilters.map((el) => el?.value === 'scheduled_origin');
    const destination = showHeaderFilters.find((el) => el?.value === 'scheduled_destination');
    if ((data.value === 'scheduled_origin' || data.value === 'scheduled_destination') && (!origin || !destination)) {
      const updatedData = { ...filter, searchBy: data };
      updateFilter(updatedData);
    } else {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'all' && !values.includes(data.value)) updateData.allValue = '';
      if (data.value === 'name' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
    }
  }

  const multiSelectOptionsDriver = useMemo(() => {
    return {
      title: 'All',
      id: 999,
      value: 'all',
      key: 9999,
      submenu: [
        {
          title: 'Active',
          id: 1,
          value: 'active',
          key: 1,
        },
        {
          title: 'View Inactive',
          id: 2,
          value: 'viewInactive',
          key: 2,
        },
      ],
    };
  }, []);

  function onSearchMultiDropDown(query) {
    if (typeof query === 'string') {
      onChangeAll(query);
      return;
    }

    const founded = showHeaderFilters.find((el) => +el.id === +filter.searchBy.id);
    const customersSearchValue = filter.customerSelectData.map((el) => ({ ...el, searchValue: el.title }));
    const customersIds = filter.customerSelectData.map((el) => el.id);
    const filtersType = filter.searchBy.value;
    let filtersValue;
    switch (filtersType) {
      case 'customers':
        filtersValue = customersIds;
        break;
      case 'name':
        filtersValue = filter.allValue;
        break;
      case 'all':
        filtersValue = filter.allValue;
        break;
      default:
    }

    if (!founded && founded?.value !== 'customers') {
      let showData;
      if (filter.searchBy.value === 'customers') {
        if (filter.customerSelectData.length > 0) {
          showData = [{ ...filter.searchBy, searchValue: customersSearchValue }, ...showHeaderFilters];
          setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
          getStaff({ filtersType, filtersValue });
        } else {
          showData = [...showHeaderFilters];
        }
      } else {
        if (filtersValue) {
          showData = [{ ...filter.searchBy, searchValue: filtersValue }, ...showHeaderFilters];
          setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
          getStaff({ filtersType, filtersValue });
        } else {
          showData = [...showHeaderFilters];
        }
      }
      setShowHeaderFilters(showData);
    } else {
      if (!!filtersValue && typeof filtersValue === 'string') {
        const mapped = showHeaderFilters.map((el) => {
          if (+el.id === +founded.id) {
            return { ...el, searchValue: filtersValue };
          }
          return el;
        });
        if (filtersValue !== founded.searchValue) {
          setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
          getStaff({ filtersType, filtersValue });
        }
        setShowHeaderFilters([...mapped]);
      } else {
        if (founded?.value === 'customers') {
          if (customersSearchValue.length !== 0) {
            const showData = showHeaderFilters.map((data) => {
              if (data.value === 'customers') {
                return { ...data, searchValue: [...customersSearchValue] };
              }
              return data;
            });
            setShowHeaderFilters([...showData]);
            setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
            getStaff({ filtersType, filtersValue });
          } else {
            const showData = showHeaderFilters.filter((data) => !['customers'].includes(data.value));
            setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
            setShowHeaderFilters([...showData]);
            getStaff({ filtersType, filtersValue });
          }
        } else {
          const filtered = showHeaderFilters.filter((el) => Number(el?.id) !== Number(founded?.id));
          setShowHeaderFilters([...filtered]);
          setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
          getStaff({ filtersType, filtersValue });
        }
      }
    }
  }

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={staffData?.total}
        totalLength={staffData?.data?.length}
        lastPage={staffData?.last_page}
        currentPage={staffData?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setStaffData(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const getFilterCustomerData = () => {
    getBillToBillingCharges().then((res) => {
      const newCustomers = res.data.map((el) => ({ id: el.id, title: el.company_name }));
      setCustomers(newCustomers);
    });
  };

  useEffect(() => {
    if (filter.searchBy?.value === 'customers') {
      getFilterCustomerData();
    }
  }, [filter.searchBy]);

  useEffect(() => {
    setLoading(true);
    getSettings();
    getStaffs();
  }, [filter?.searchBy?.title]);

  const getStaffs = () => {
    Promise.allSettled([
      Staff({
        page: 1,
        itemsPerPage: rowPerPage,
        'sort[][id]': 'desc',
        'sort[][login_status]': 'asc',
        login_status:
          filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
        ...advancedFilter,
        joinDateRange: undefined,
        join_date_from: advancedFilter?.joinDateRange?.[0]
          ? moment(advancedFilter?.joinDateRange?.[0]).format('MM/DD/YYYY')
          : undefined,
        join_date_to: advancedFilter?.joinDateRange?.[1]
          ? moment(advancedFilter?.joinDateRange?.[1]).format('MM/DD/YYYY')
          : undefined,
      }),
      getTabs('staff'),
      hourOfServiceAll(),
    ])
      .then((responses) => {
        let filterTableTop = [{ key: 'all', label: 'All', value: 0, count: 0 }];
        if (responses[0].status === 'fulfilled') {
          setStaffData({
            ...responses[0].value,
            data: inserthourOfService(responses[0].value.data || [], responses[2]?.value?.data || []),
          });
          filterTableTop[0].count = responses[0].value.total;
        }
        if (responses[1].status === 'fulfilled') {
          filterTableTop = [
            ...filterTableTop,
            ...(responses[1].value.data || []).map((tab, index) => ({
              key: tab.data.name,
              label: tab.data.name,
              value: index + 1,
              count: tab.count,
              filters: tab.data.filters,
              tabId: tab.id,
              system: tab.system,
              user_id: tab.user_id,
            })),
          ];
        }
        if (responses[2].status === 'fulfilled') {
          setHourService(responses[2].value.data || []);
        }
        setFilterTableTop([...filterTableTop]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const inserthourOfService = (drivers, services) => {
    return drivers.map((driver) => {
      const getSeriveById = services.find((service) => service.driver_id === driver.id) || {};
      return {
        ...getSeriveById,
        ...driver,
      };
    });
  };

  const switcherData = useMemo(() => {
    return [...filterTableTop];
  }, [filterTableTop]);

  const onChangeTab = (val) => {
    const { value } = val;
    setTab(value);
    const getTab = { ...(filterTableTop.find((tab) => tab.value === value) || {}) };
    onReset(getTab?.filters);
  };

  const remove = () => {
    const { tabId, value } = removeTabModalOpen;
    const getTabIdIndex = filterTableTop.findIndex((tab) => tab.value === value);
    setRemoveTabloading(true);
    if (getTabIdIndex >= 0) {
      deleteTab({ tab: tabId })
        .then(() => {
          onSuccess('Tab has been deleted successfully');
          const tempTabs = [...filterTableTop];
          tempTabs.splice(getTabIdIndex, 1);
          setFilterTableTop(tempTabs);
          setRemoveTabModalOpen(null);
          setRemoveTabloading(false);
          setUpdateModalShow(false);
        })
        .catch((error) => {
          setRemoveTabloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  function onEditTableTab(item) {
    const { label, filters, tabId, system, value, key, count } = item;
    const initialValue = {
      label,
      key,
      filters,
      tabId,
      system,
      value,
      count,
    };
    setTabModalEditData(initialValue);
    setUpdateModalShow(true);
  }

  const addTabs = () => {
    getTabs().then((response) => {
      let filterTableTopTemp = [filterTableTop[0]];
      filterTableTopTemp = [
        ...filterTableTopTemp,
        ...(response.data || []).map((tab, index) => ({
          key: tab.data.name,
          label: tab.data.name,
          value: index + 1,
          count: tab.count,
          filters: tab.data.filters,
          tabId: tab.id,
          system: tab.system,
          user_id: tab.user_id,
        })),
      ];
      setFilterTableTop([...filterTableTopTemp]);
    });
  };

  const handleClose = () => {
    setRemoveTabModalOpen(null);
  };

  const handleClickOpen = (selectedTab) => {
    setRemoveTabModalOpen(selectedTab);
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value) && key !== 'joinDateRange') {
        requestData[key] = value.map((el) => el.id).toString();
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    setLoading(true);
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.query;
    } else {
      filterObject.query = search;
    }

    const params = {
      page: 1,
      itemsPerPage: rowPerPage,
      'sort[][id]': 'desc',
      'sort[][login_status]': 'asc',
      login_status:
        filter?.searchBy?.title === 'Active' ? '1,2,3,4' : filter?.searchBy?.title === 'View Inactive' ? '5' : '',
      ...convertFilterData(filterObject),
      joinDateRange: undefined,
      join_date_from: advancedFilter?.joinDateRange?.[0]
        ? moment(advancedFilter?.joinDateRange?.[0]).format('MM/DD/YYYY')
        : undefined,
      join_date_to: advancedFilter?.joinDateRange?.[1]
        ? moment(advancedFilter?.joinDateRange?.[1]).format('MM/DD/YYYY')
        : undefined,
    };

    Promise.allSettled([Staff(params), getTabs('staff'), hourOfServiceAll()])
      .then((responses) => {
        let filterTableTop = [{ key: 'all', label: 'All', value: 0, count: 0 }];
        if (responses[0].status === 'fulfilled') {
          setStaffData({
            ...responses[0].value,
            data: inserthourOfService(responses[0].value.data || [], responses[2]?.value?.data || []),
          });
          filterTableTop[0].count = responses[0].value.total;
        }
        if (responses[1].status === 'fulfilled') {
          filterTableTop = [
            ...filterTableTop,
            ...(responses[1].value.data || []).map((tab, index) => ({
              key: tab.data.name,
              label: tab.data.name,
              value: index + 1,
              count: tab.count,
              filters: tab.data.filters,
              tabId: tab.id,
              system: tab.system,
              user_id: tab.user_id,
            })),
          ];
        }
        if (responses[2].status === 'fulfilled') {
          setHourService(responses[2].value.data || []);
        }
        setFilterTableTop([...filterTableTop]);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className='mt-4 driver-list'>
        <TableContext.Provider
          value={{
            filter,
            dragItem,
            switchRadioButtons,
            onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
            tableColumn,
            onsStTableColumn: (v) => setTableColumn(v),
            getSettings,
            customers,
            updateFilter,
            onChangeOrder: (items) => setDragItem(items),
            staffData: staffData?.data,
            onSearchLocations,
            onSearchMultiDropDown,
            onChangeMultiselectSearchBy,
            setIsAdded,
            onChangeAll,
            multiSelectOptionsDriver,
          }}
        >
          <AdvancedFilter
            onSearch={onSearch}
            open={advancedOpen}
            filter={advancedFilter}
            setFilter={setAdvancedFilter}
            setSearch={setSearch}
          >
            {({ onSearchFilter }) => {
              return (
                <StaffHeader
                  getStaff={getStaffs}
                  search={search}
                  setSearch={setSearch}
                  advancedOpen={advancedOpen}
                  setAdvancedOpen={setAdvancedOpen}
                  onSearch={onSearchFilter}
                />
              );
            }}
          </AdvancedFilter>
          <SwitchRadio
            name='tableTopTabMenu'
            items={[...(switcherData || [])]}
            value={tab}
            type='tab'
            onChange={(value) => onChangeTab(value)}
            plus
            onEdit={onEditTableTab}
            onClickPlus={() => setAddModalShow(true)}
          />
        </TableContext.Provider>
        {loading ? (
          <TablePreLoader />
        ) : (
          <div className='tableFixHead table-fixed-header-340'>
            <TableWrapper
              data={filterDataTable(staffData?.data)}
              rowPerPage={rowPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: PaginationComponent,
              }}
              columns={filterTableColumn(columns)}
              onRowClick={(e, rowData) => {
                if (rowData?.staff?.id) {
                  navigate(`/staff-profile/${rowData?.staff?.id}/general`, { state: { from: 'staff' } });
                }
              }}
            />
          </div>
        )}
        {!!removeTabModalOpen && (
          <div>
            <Dialog
              open={!!removeTabModalOpen}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Confirm Your Action</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Are you sure you want to remove tab?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className='cancel-button' onClick={handleClose}>
                  Cancel
                </Button>
                {removeTabloading ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <Button className='next-step remove-modal-action-btn' onClick={() => remove()} autoFocus>
                    Remove
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>

      {UpdateModalShow && (
        <TabUpdateStaffModal
          show={UpdateModalShow}
          onHide={() => setUpdateModalShow(false)}
          onSuccess={onSuccess}
          showError={showError}
          save={addTabs}
          initialValue={tabModalEditData}
          tabName='Update Tab'
          onClickCross={(e, selectedTab) => {
            handleClickOpen(selectedTab);
          }}
        />
      )}
      {AddModalShow && (
        <TabUpdateStaffModal
          show={AddModalShow}
          onHide={() => setAddModalShow(false)}
          onSuccess={onSuccess}
          showError={showError}
          save={addTabs}
          tabName='Add Tab'
        />
      )}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default StaffTableList;
