import styled from 'styled-components';
import CustomModal from 'components/CustomModal/CustomModal';
import { palette } from 'utils/constants';

export const SCustomModal = styled(CustomModal)`
  padding-right: 0 !important;

  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    width: ${({ $width }) => $width || ''};
    height: auto;

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: ${({ $height }) => $height || '100%'};
      min-height: ${({ $minHeight }) => $minHeight || ''};
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      border-top: 1px solid ${palette.gray50};
    }
  }

  &.modified-scrollbar {
    .customBody {
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #9e9e9e;
        border-radius: 8px;
      }
    }
  }
`;
