import React from 'react';
import { useSelector } from 'react-redux';
import shipmentGeneral from 'assets/icons/drivers/shipmentGeneral.svg';
import requestGeneral from 'assets/icons/drivers/requestGeneral.svg';
import milesGeneral from 'assets/icons/drivers/milesGeneral.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import './OwnerActivity.css';

const OwnerActivity = ({ staffStatsData }) => {
  const { use } = useTheme();
  const numberFormatter = Intl.NumberFormat('en-US');
  const { currency } = useSelector((state) => state.root);

  const activity = [
    {
      icon: shipmentGeneral,
      detailName: 'shipments',
      detail: numberFormatter.format(staffStatsData?.shipments_completed || 0),
    },
    {
      icon: requestGeneral,
      detailName: 'Gross Revenue',
      detail: `${currency}${numberFormatter.format(staffStatsData?.gross_revenue || 0)}`,
    },
    {
      icon: milesGeneral,
      detailName: 'Miles Driven',
      detail: numberFormatter.format(staffStatsData?.miles_driven || 0),
    },
  ];
  return (
    <div
      className='driver-activity driver-style-wrap owner-activity'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
        minHeight: '100px',
      }}
    >
      {activity.map((item) => {
        return (
          <div
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              borderColor: use(palette.gray50, palette.darkborder),
              borderBottom: 'none',
            }}
            className={
              item.detailName === 'Miles Driven' || item.detailName === 'Incidents'
                ? 'driver-activity-wrap last-activity'
                : 'driver-activity-wrap '
            }
            key={item.detailName}
          >
            <img src={item.icon} alt='' className='activity-image' />
            <div className='activity-details'>
              <p className='detail-name'>{item.detailName}</p>
              <p className='detail' style={{ color: use(palette.gray900, palette.gray50) }}>
                {item.detail}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OwnerActivity;
