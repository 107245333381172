import axios from 'services/axios';

export async function getAllModules(params) {
  const { data } = await axios.get('stripe-connect/active-modules', { params });
  return data;
}

export async function checkModuleStatus(body) {
  const { data } = await axios.post('stripe-connect/check-module', body);
  return data;
}

export async function activateModule(body) {
  const { data } = await axios.post('stripe-connect/activate-module', body);
  return data;
}

export async function checkOnboarding() {
  const { data } = await axios.get('card-management/stripe/check-onboarding');
  return data;
}
