import React from 'react';
import searchIcon from 'assets/icons/header/Search.png';
import styles from './TransactionsSearch.module.css';

const TransactionsSearch = ({ search, onChange }) => {
  return (
    <div className={styles.filtersWrapper} style={{ marginBottom: 16, marginTop: 16 }}>
      <div className='header-search'>
        <div className='header-search-container d-flex'>
          <div>
            <input
              className={styles.searchInput}
              type='text'
              value={search}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Search'
            />
            <div className='input-group-addon-search'>
              <img src={searchIcon} alt='search' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TransactionsSearch;
