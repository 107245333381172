import React, { useState } from 'react';
import moment from 'moment';
import { ReactComponent as UploadIcon } from 'assets/icon.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/drivers/indigoDownload.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import useShowToaster from 'hooks/useShowToaster';
import { getHeaders } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { importBankFeedCsv, importTransactionsCsv } from 'Api/Accounts';
import { bankFeedMapper, transactionsMapper } from './ImportTransactions.data';
import { SAttachedDoc, SCountWrapper, SDownload, SDownloadWrapper, SUploadWrapper } from './ImportTransactions.styles';

const ImportCsv = ({ open, onClose, onSuccess, account }) => {
  const showToaster = useShowToaster();
  const [importFile, setImportFile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  function bankCsvToArray(str) {
    const arr = getHeaders(str, bankFeedMapper);

    const importTransactionsData = {
      date: [],
      reference_id: [],
      description: [],
      memo: [],
      payment: [],
      deposit: [],
    };
    if (arr?.length) {
      for (let i = 0; i < arr?.length; i++) {
        importTransactionsData.date.push(arr[i]?.date ? moment(arr[i].date).format('YYYY-MM-DD HH:mm:ss') : null);
        importTransactionsData.reference_id.push(arr[i]?.reference_id || null);
        importTransactionsData.description.push(arr[i]?.description || null);
        importTransactionsData.memo.push(arr[i]?.memo || null);
        importTransactionsData.payment.push(Number(arr[i]?.payment) || 0);
        importTransactionsData.deposit.push(Number(arr[i]?.deposit) || 0);
      }
    }
    setData(importTransactionsData);
  }

  function transactionsCsvToArray(str) {
    const arr = getHeaders(str, transactionsMapper);

    const importTransactionsData = {
      date: [],
      reference_id: [],
      payee: [],
      account: [],
      memo: [],
      debit: [],
      credit: [],
    };
    if (arr?.length) {
      for (let i = 0; i < arr?.length; i++) {
        importTransactionsData.date.push(arr[i]?.date ? moment(arr[i].date).format('YYYY-MM-DD HH:mm:ss') : null);
        importTransactionsData.reference_id.push(arr[i]?.reference_id || null);
        importTransactionsData.payee.push(arr[i]?.payee || null);
        importTransactionsData.account.push(arr[i]?.account || null);
        importTransactionsData.memo.push(arr[i]?.memo || null);
        importTransactionsData.debit.push(Number(arr[i]?.debit) || 0);
        importTransactionsData.credit.push(Number(arr[i]?.credit) || 0);
      }
    }
    setData(importTransactionsData);
  }

  const importTransactions = async () => {
    setLoading(true);
    try {
      const importData = {
        account_id: account.id,
        ...data,
      };
      if (account.account_type?.id === ACCOUNT_TYPE.BANK) {
        await importBankFeedCsv(importData);
      } else {
        await importTransactionsCsv(importData);
      }
      showToaster({ type: 'success', message: 'Transactions have been successfully imported!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const onFileUpload = (file) => {
    setImportFile(file[0]);
    const input = file[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      if (account.account_type?.id === ACCOUNT_TYPE.BANK) {
        bankCsvToArray(text);
      } else {
        transactionsCsvToArray(text);
      }
    };
    reader.readAsText(input);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Import from CSV'
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='600px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Upload',
          onClick: importTransactions,
          disabled: !importFile || !data || loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>1</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2'>
            <Typography as='div' variant='s2'>
              Download the CSV Template
            </Typography>
            <Typography as='div' variant='s2' style={{ color: palette.gray500 }}>
              First download the CSV template. Then fill out asked data in that template.
            </Typography>
            <SDownloadWrapper
              href={`${process.env.REACT_APP_BASE_URL}${
                account.account_type?.id === ACCOUNT_TYPE.BANK
                  ? '/uploads/csv/ImportTransactions.csv'
                  : '/uploads/csv/AccountTransactions.csv'
              }`}
              target='_blank'
            >
              <SDownload>
                <DownloadIcon />
                <Typography variant='s2' style={{ color: palette.indigo500, textDecoration: 'none' }}>
                  Download CSV Template
                </Typography>
              </SDownload>
            </SDownloadWrapper>
          </div>
        </div>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>2</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2 w-100'>
            <Typography as='div' variant='s2'>
              Upload filled CSV file here:
            </Typography>
            {importFile ? (
              <SAttachedDoc>
                <AttachmentIcon fill={palette.indigo500} />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  {importFile?.name}
                </Typography>
                <CancelIcon
                  fill={palette.red500}
                  className='pointer'
                  onClick={() => {
                    setImportFile(null);
                    setData(null);
                  }}
                />
              </SAttachedDoc>
            ) : (
              <SUploadWrapper>
                <label htmlFor='myFile' className='text-style csv-text-style'>
                  <UploadIcon width={30} height={30} fill={palette.gray300} />
                  <Typography variant='s2' style={{ color: palette.indigo500, marginTop: '10px' }}>
                    Upload File
                    <Typography variant='s2'> or drag and drop</Typography>
                  </Typography>
                </label>
                <ImageDropZone
                  onDrop={(file) => onFileUpload(file)}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['text/csv']}
                />
              </SUploadWrapper>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportCsv;
