import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { palette } from 'utils/constants';

export const SUserInfo = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  width: fit-content;

  .user-name-wrapper {
    display: inline-flex;
    flex-direction: column;
  }

  .user-type {
    font-size: 14px;
    color: ${palette.gray700};
  }

  .phone-icon {
    margin-left: 8px;

    svg path {
      fill: ${palette.indigo400};
    }
  }

  .profile-image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-image {
      width: ${({ $size }) => $size || '40px'};
      height: ${({ $size }) => $size || '40px'};
      border-radius: 50%;
      object-fit: cover;
    }

    .profile-active-indicator {
      position: absolute;
      right: 2px;
      bottom: 2px;
      background: #38a06c;
      box-shadow: 0 0 0 2px #ffffff;
      width: ${({ $statusSize }) => $statusSize || '8px'};
      height: ${({ $statusSize }) => $statusSize || '8px'};
      border-radius: 50%;
    }
  }
`;

export const SPaper = styled(Paper)`
  &.MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.08);

    .user-info-header {
      padding: 12px 16px;
      background-color: ${palette.gray0};
      border-bottom: 1px solid ${palette.gray50};
      border-radius: 8px 8px 0 0;
    }

    .user-info-content {
      ul {
        list-style-type: none;
        padding: 8px 6px;
        margin: 0;

        li {
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.02em;
          text-align: left;
          color: ${palette.gray700};
          padding: 4px 10px;
          cursor: pointer;
          border-radius: 4px;
          display: flex;
          align-items: center;
          column-gap: 8px;

          &.remove-action {
            color: ${palette.red500};
          }

          a {
            text-decoration: none;
            color: ${palette.gray700};
          }

          svg path {
            fill: ${palette.gray400};
          }

          :hover {
            background-color: ${palette.indigo0};
          }
        }
      }
    }
  }
`;

export const SNameImage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .profile-image-container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      border-radius: 40px;
      object-fit: cover;
    }

    .profile-active-indicator {
      position: absolute;
      right: 0;
      bottom: 0;
      background: #38a06c;
      box-shadow: 0 0 0 2px #ffffff;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
`;
