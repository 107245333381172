import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import hexRgb from 'hex-rgb';
import call from 'assets/icons/drivers/call.svg';
import down from 'assets/icons/drivers/down.svg';
import edit from 'assets/icons/drivers/edit.svg';
import reset from 'assets/icons/drivers/reset.svg';
import inbox from 'assets/icons/drivers/inbox.svg';
import comment from 'assets/icons/drivers/comment.svg';
import download from 'assets/icons/drivers/download.svg';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import CustomizedSnackbars from 'components/toast/Toast';
import AddGroups from 'components/AddGroupModal/AddGroupModal';
import ResetPassword from 'components/ResetPassword/ResetPassword';
import TimeCardId from 'components/StaffProfile/components/TimeCardId';
import StaffProfileTab from 'components/StaffProfileTab/StaffProfileTab';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import GenerateId from 'components/GenerateIdCardModal/GenerateIdCardModal';
import StaffUpdateModal from 'components/StatusUpdateModal/StaffStatusUpdateModal';
import UpdateDepartmentDetailModal from 'components/UpdateDepartmentStaffModal/UpdateDepartmentDetailModal';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { palette, statusColor } from 'utils/constants';
import { AddMedicalBackground } from 'Api/Driver';
import {
  AddCompensation,
  staffCompansationData,
  StaffGeneral,
  staffRequestData,
  UpdateCompensation,
  UpdateStaffBasicDetail,
  staffAbsenceData,
  updateAbsenceAllowence,
  createSalesCommission,
} from 'Api/Staff';
import { getUserProfile } from 'Api/User';

const StaffProfile = () => {
  const location = useLocation();
  const { formatDateTime, formatDate } = useDateFormat();
  const [statusUpdateModalShow, setStatusUpdateModalShow] = useState(false);
  const [detailUpdateModalShow, setDetailUpdateModalShow] = useState(false);
  const [addGroupModalShow, setAddGroupModalShow] = useState(false);
  const [generateIdModalShoew, setGenerateIdModalShoew] = useState(false);
  const [resetPinModalShoew, setResetPinModalShoew] = useState(false);
  const [driverStatus, setDriverStatus] = useState({});
  const [driverGeneral, setDriverGeneral] = useState();
  const [staffLicence, setStaffLicence] = useState();
  const [driverCompansation, setDriverCompansation] = useState([]);
  const [driverRequest, setDriverRequest] = useState([]);
  const [driverOtherData, setDriverOtherData] = useState();
  const [driverMedical, setDriverMedical] = useState([]);
  const [driverAbsence, setDriverAbsence] = useState();
  const [driverGroupsData, setDriverGroupsData] = useState([]);
  const [staffDepartment, setStaffDepartment] = useState({});
  const [isChange, setIsChange] = useState(false);
  const [isChangeRequest, setIsChangeRequest] = useState(false);
  const [refreshIndex, setRefreshIndex] = useState(Date.now());
  const [nfcIdOpen, setNfcIdOpen] = useState(false);
  const [commissionsIndex, setCommissionsIndex] = useState(null);
  const [fromStaff, setFromStaff] = useState(false);

  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { value, setAuth } = useAuth();

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { use } = useTheme();

  useEffect(() => {
    StaffGeneral({ id }).then((res) => {
      setCompanyDetails(res?.company_details);
      setDriverGeneral(res.data.staff);
      setDriverOtherData(res.data);
      setDriverGroupsData(res.data.staff_group);
      setStaffDepartment(res?.data?.department);
      setDriverStatus(res.data?.staff);
      setStaffLicence(res.data);
    });
    staffCompansationData({ id }).then((res) => {
      setDriverCompansation(res?.data);
    });
  }, [refreshIndex]);

  useEffect(() => {
    AbsenceAllownce();
  }, [isChange, isChangeRequest]);

  const AbsenceAllownce = (year) => {
    staffAbsenceData({
      page: '1',
      sort: 'asc',
      staff_id: id,
      year: year ?? Number(moment(new Date()).format('yyyy')),
    }).then((res) => {
      setDriverAbsence(res);
    });
  };

  useEffect(() => {
    staffRequestData({ page: '1', sort: 'asc', staff_id: id }).then((res) => {
      setDriverRequest(res);
    });
  }, [isChangeRequest]);

  const GetCompensation = (id) => {
    staffCompansationData({ id }).then((res) => {
      setDriverCompansation(res?.data);
    });
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const updateAbsenceAllowanceData = (updatedAllowance, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    const payload = { ...updatedAllowance };
    updateAbsenceAllowence(payload, id)
      .then((res) => {
        const updatedData = { ...driverAbsence, ...updatedAllowance, ...res?.data };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        AbsenceAllownce();
        setDriverAbsence(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const updateProfile = (updatedDriverInfo, onHide = null, onSuccess = null, successMsg = '', setUniqueEmailError) => {
    if (updatedDriverInfo?.hasOwnProperty('upload_identification_card')) {
      delete updatedDriverInfo.upload_identification_card;
    }

    setLoading(true);
    UpdateStaffBasicDetail(updatedDriverInfo, id)
      .then((res) => {
        const updatedData = { ...driverOtherData, ...driverGeneral, ...updatedDriverInfo, ...res?.data };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverGeneral(updatedData);
        setDriverOtherData(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        if (getErrorMessage(error) === 'The email has already been taken.') {
          setUniqueEmailError('The email has already been taken.');
        }
        setLoading(false);
      });
  };

  const updateCommissions = async (compensation) => {
    try {
      const body = {
        customer_id: [],
        type: [],
        flat_per_shipment: [],
        percentage_per_shipment: [],
        percentage_after_driver_pay: [],
        end_date: [],
        is_active: [],
      };
      compensation.commissions.forEach((item) => {
        body.customer_id.push(item.customer.id);
        body.type.push(Number(item.type));
        body.flat_per_shipment.push(item.type === 1 ? Number(item.flat_per_shipment) : 0);
        body.percentage_per_shipment.push(item.type === 2 ? Number(item.percentage_per_shipment) : 0);
        body.percentage_after_driver_pay.push(item.type === 3 ? Number(item.percentage_after_driver_pay) : 0);
        body.end_date.push(moment(item.end_date || new Date()).format('YYYY-MM-DD'));
        body.is_active.push(item.is_active);
      });

      return createSalesCommission(id, body);
    } catch (e) {
      // Do nothing
    }
  };

  const updateCompensation = (updatedCompensationInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);

    const staffInfo = {
      ...updatedCompensationInfo,
      each_booked_shipment: updatedCompensationInfo.each_booked_shipment ? 1 : 0,
      tax_type_id: +updatedCompensationInfo.tax_type_id,
      routing_confirmed: updatedCompensationInfo.routing_confirmed,
      account: updatedCompensationInfo.account,
      account_confirmed: updatedCompensationInfo.account_confirmed,
      per_hr_amt: Number(updatedCompensationInfo.per_hr_amt || 0),
      staff_id: String(id),
      per_hr_option: updatedCompensationInfo.per_hr ? 1 : 0,
      flat_or_per: Number(updatedCompensationInfo.flat_or_per) || null,
    };
    if (updatedCompensationInfo.flat_or_per === '1') {
      delete staffInfo?.percentage;
    } else if (updatedCompensationInfo.flat_or_per === '2') {
      delete staffInfo?.flat_amt;
    }
    if (staffInfo.per_hr === 0) {
      delete staffInfo?.per_hr_amt;
    }

    if (driverCompansation?.length === 0) {
      AddCompensation(staffInfo)
        .then(async (res) => {
          const updatedData = { ...driverCompansation, ...updatedCompensationInfo, ...res?.data };
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          if (updatedCompensationInfo.sales_commission_option) {
            await updateCommissions(updatedCompensationInfo);
            setCommissionsIndex(Date.now());
          }
          setDriverCompansation(updatedData);
          GetCompensation(id);
          setLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setLoading(false);
        });
    } else {
      UpdateCompensation(staffInfo, id)
        .then(async (res) => {
          const updatedData = { ...driverCompansation, ...updatedCompensationInfo, ...res?.data };
          if (onHide) {
            onHide();
          }
          if (updatedCompensationInfo.sales_commission_option) {
            await updateCommissions(updatedCompensationInfo);
            setCommissionsIndex(Date.now());
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          setDriverCompansation(updatedData);
          GetCompensation(id);
          setLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setLoading(false);
        });
    }
  };

  const updateMedicalData = (updatedMedicalInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    const payload = { ...updatedMedicalInfo };
    AddMedicalBackground(payload, id)
      .then((res) => {
        const updatedData = { ...driverMedical, ...updatedMedicalInfo, ...res?.data };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverMedical(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const getUser = async () => {
    try {
      const { data } = await getUserProfile();
      const user = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('user', JSON.stringify({ ...user, ...data }));
      setAuth({ ...value, user: { ...value.user, ...data } });
    } catch (e) {
      // Do nothing
    }
  };

  const updateGroupProfile = (updatedDriverGroupsInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    UpdateStaffBasicDetail({ group_id: updatedDriverGroupsInfo.map((group) => group.id) }, id)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        if (Number(id) === Number(value.user?.id)) {
          getUser();
        }
        setDriverGroupsData(updatedDriverGroupsInfo);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const updateDepartmentProfile = (updatedDriverGroupsInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    UpdateStaffBasicDetail({ department_id: updatedDriverGroupsInfo?.department_id }, id)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        if (Number(id) === Number(value.user?.id)) {
          const user = JSON.parse(localStorage.getItem('user'));
          localStorage.setItem(
            'user',
            JSON.stringify({ ...user, department: updatedDriverGroupsInfo?.department || staffDepartment })
          );
          setAuth({
            ...value,
            user: { ...value.user, department: updatedDriverGroupsInfo?.department || staffDepartment },
          });
        }

        setStaffDepartment((prevState) => updatedDriverGroupsInfo?.department || prevState);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const updateDriverStatus = (updatedDriverGroupsInfo) => {
    setLoading(true);
    UpdateStaffBasicDetail(updatedDriverGroupsInfo, id)
      .then(() => {
        setStatusUpdateModalShow(false);
        onSuccess('Status has been updated successfully');
        setDriverStatus(updatedDriverGroupsInfo);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const continueAdding = () => {
    navigate(`/staff`, {
      state: { openCreate: true, openStep: driverOtherData.draft_step_number, currentDriverId: id },
    });
  };

  useEffect(() => {
    if (location?.state?.from) {
      setFromStaff(true);
    }
  }, [location?.state?.from]);

  const goBack = () => {
    if (fromStaff) {
      navigate('/staff', {
        state: { openCreate: false },
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <div className='profile-container driver-style-wrap'>
      <div className='back-navigate-container' onClick={() => goBack()}>
        <img src={BackArrow} alt='Back-Arrow' />
        <p className='navigater-page-name'>Go Back</p>
      </div>
      <div className='user-profile-header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div>
          <div className='user-details-container'>
            <div className='user-image-container'>
              <img
                src={driverGeneral?.profile_logo ? driverGeneral?.profile_logo : userDefault}
                alt='user'
                className='userImage'
              />
              <div
                className='active-indicator'
                style={{
                  backgroundColor: use(
                    statusColor[driverStatus?.status?.staff_status]?.color,
                    statusColor[driverStatus?.status?.staff_status]?.darkColor
                  ),
                }}
              />
            </div>
            <div className='user-details'>
              <div className='user-name-and-actions-container'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  {driverGeneral?.first_name || ''} {driverGeneral?.last_name || ''}
                </h2>
                <div className='call-to-action-icons'>
                  <a href={`mailto:${driverGeneral?.email}`}>
                    <img src={inbox} alt='inbox-icon' />
                  </a>
                  <img src={comment} alt='comment-icon' />
                  <a href={`tel:${driverGeneral?.phone_number}`}>
                    <img src={call} alt='call-icon' />
                  </a>
                </div>
              </div>
              <div className='user-country-and-status-container'>
                <div
                  className='user-status'
                  style={{
                    backgroundColor: use(
                      statusColor[driverStatus?.status?.staff_status]?.bgColor,
                      statusColor[driverStatus?.status?.staff_status]?.darkBgColor
                    ),
                  }}
                >
                  <p
                    className='user-status-tag'
                    style={{
                      color: use(
                        statusColor[driverStatus?.status?.staff_status]?.color,
                        statusColor[driverStatus?.status?.staff_status]?.darkColor
                      ),
                    }}
                  >
                    {driverStatus?.status?.staff_status}
                  </p>
                </div>
                <div className='user-dropdown-icon'>
                  <img src={down} alt='Down-icon' onClick={() => setStatusUpdateModalShow(true)} />
                  {statusUpdateModalShow && (
                    <StaffUpdateModal
                      show={statusUpdateModalShow}
                      onHide={() => setStatusUpdateModalShow(false)}
                      name={`${driverGeneral?.first_name} ${driverGeneral?.last_name}`}
                      id={id}
                      driverStatus={driverStatus?.login_status}
                      updateDriverStatus={updateDriverStatus}
                      loading={loading}
                      onSuccess={onSuccess}
                    />
                  )}
                </div>
              </div>
            </div>
            {driverOtherData?.draft_step_number && (
              <div className='draft ms-5' onClick={continueAdding}>
                <span className='draft-link'>Continue Adding Staff </span>
              </div>
            )}
          </div>
          {driverStatus?.status?.staff_status === 'Terminated' ? (
            <div className='tagline-container-wrap'>
              <p>{`Driver has been terminated ${
                driverStatus?.terminated_date_time
                  ? `on ${formatDateTime(driverStatus?.terminated_date_time, true)}`
                  : ''
              } because ${driverStatus?.terminated_note || ''}`}</p>
            </div>
          ) : driverStatus?.status?.staff_status === 'Not Available' ? (
            <div className='tagline-container-wrap'>
              <p>
                Driver not available until{' '}
                {driverStatus?.driver_not_available_until_date
                  ? `on ${formatDateTime(driverStatus?.driver_not_available_until_date, true)}`
                  : ''}{' '}
                {driverStatus?.driver_not_available_until_time || ''}. Notes: {driverStatus?.note_not_available || ''}
              </p>
            </div>
          ) : driverStatus?.status?.staff_status === 'Out of Service' ? (
            <div className='tagline-container-wrap'>
              <p>
                Driver is Out of Service{' '}
                {driverStatus?.out_of_service_date_time
                  ? `on ${formatDateTime(driverStatus?.out_of_service_date_time, true)}`
                  : ''}{' '}
                Notes: {driverStatus?.out_of_service_reason || ''}
              </p>
            </div>
          ) : driverStatus?.status?.staff_status === 'Quit' ? (
            <div className='tagline-container-wrap'>
              <p>
                Driver has been Quit{' '}
                {driverStatus?.quite_date_time ? `on ${formatDateTime(driverStatus?.quite_date_time, true)}` : ''}{' '}
                because Notes: {driverStatus?.quit_note || ''}
              </p>
            </div>
          ) : null}
        </div>
        <div className='btn-container'>
          <PrimaryBtn icon={reset} title='Reset Password' onClick={() => setResetPinModalShoew(true)} />
          {resetPinModalShoew && (
            <ResetPassword
              show={resetPinModalShoew}
              onHide={() => setResetPinModalShoew(false)}
              name={`${driverGeneral?.first_name} ${driverGeneral?.last_name}`}
              driverId={id}
              showError={showError}
              onSuccess={onSuccess}
            />
          )}
          <PrimaryBtn icon={download} title='Generate ID' onClick={() => setGenerateIdModalShoew(true)} />
          <GenerateId
            show={generateIdModalShoew}
            onHide={() => setGenerateIdModalShoew(false)}
            driverInfo={driverGeneral}
            companyInfo={companyDetails}
          />
        </div>
      </div>
      <div className='user-profile-sub-header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Tags</p>
            <img src={edit} alt='edit' onClick={() => setAddGroupModalShow(true)} />
            {addGroupModalShow && (
              <AddGroups
                show={addGroupModalShow}
                onHide={() => setAddGroupModalShow(false)}
                showError={showError}
                onSuccess={onSuccess}
                driverGroupInfo={driverGroupsData}
                updateGroupProfile={updateGroupProfile}
                loading={loading}
              />
            )}
          </div>
          <div className='data-group-wraper'>
            {[...(driverGroupsData || [])].splice(0, 4)?.map((group) => {
              const colorCode = hexRgb(group.color);
              const TextColor =
                colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
              return (
                <div className='user-data-group' style={{ backgroundColor: group.color }}>
                  <p className='group-text' style={{ color: TextColor }}>
                    {group.group_name}
                  </p>
                </div>
              );
            })}
            {driverGroupsData?.length > 4 ? <span>...</span> : null}
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Department</p>
            <img src={edit} alt='edit' onClick={() => setDetailUpdateModalShow(true)} />
            {detailUpdateModalShow && (
              <UpdateDepartmentDetailModal
                show={detailUpdateModalShow}
                onHide={() => setDetailUpdateModalShow(false)}
                showError={showError}
                onSuccess={onSuccess}
                staffDepartmentInfo={staffDepartment}
                updateDepartmentProfile={updateDepartmentProfile}
                loading={loading}
              />
            )}
          </div>
          <div>
            <p className='user-data-text ' style={{ color: use(palette.gray900, palette.gray50) }}>
              {staffDepartment?.department_name}
            </p>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Join Date</p>
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {driverOtherData?.hire_date ? formatDate(new Date(driverOtherData?.hire_date)) : '01/12/2022'}
            </p>
          </div>
        </div>
        <div
          className='user-data-container user-data-container-last'
          style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}
        >
          <div className='user-data'>
            <p className='user-data-head'>Time Card NFC ID</p>
            <img src={edit} alt='edit' onClick={() => setNfcIdOpen(true)} />
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {driverOtherData?.nfc_id || '-'}
            </p>
          </div>
          {nfcIdOpen && (
            <TimeCardId
              open={nfcIdOpen}
              onClose={() => setNfcIdOpen(false)}
              userData={driverOtherData}
              onSuccess={(nfc_id) => setDriverOtherData((prevState) => ({ ...prevState, nfc_id }))}
            />
          )}
        </div>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <StaffProfileTab
        loading={loading}
        driverInfo={driverGeneral}
        onSuccess={onSuccess}
        updateProfile={updateProfile}
        updateCompensation={updateCompensation}
        driverCompansation={driverCompansation}
        driverMedical={driverMedical}
        updateMedicalData={updateMedicalData}
        driverRequest={driverRequest}
        updateAbsenceAllowanceData={updateAbsenceAllowanceData}
        showError={showError}
        driverAbsence={driverAbsence}
        driverOtherData={driverOtherData}
        staff_id={id}
        staffLicence={staffLicence}
        setIsChange={setIsChange}
        isChange={isChange}
        setIsChangeRequest={setIsChangeRequest}
        isChangeRequest={isChangeRequest}
        absenceDetails={AbsenceAllownce}
        staff_name={`${driverGeneral?.first_name} ${driverGeneral?.last_name}`}
        setRefreshIndex={setRefreshIndex}
        commissionsIndex={commissionsIndex}
      />
    </div>
  );
};
export default StaffProfile;
