import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Skeleton } from '@material-ui/lab';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';
import { defaultDataset, options, textCenter, textCenterDefault } from './DoughnutChart.data';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
  datasets,
  labels,
  total,
  loading,
  title,
  header,
  hoverText = null,
  centerText = null,
  isMapsPage,
}) => {
  const { use } = useTheme();
  const plugins = [];
  const style = isMapsPage
    ? { background: '#ffffff', height: '103px', width: '103px', borderRadius: '50%' }
    : { aspectRatio: '1', maxHeight: '280px' };

  const data = {
    labels: [],
    datasets,
  };

  const hoverTextDefault = {
    id: 'hoverText',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      ctx.save();

      if (_active.length) {
        ctx.font = '700 32px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${_active[0]?.element?.$context?.raw}`, width / 2, height / 2 - 10);

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${labels[_active[0]?.index]}`, width / 2, height / 2 + 16);
      }
    },
  };

  if (hoverText) {
    plugins.push(hoverText);
  } else {
    plugins.push(hoverTextDefault);
  }

  if (centerText) {
    plugins.push(centerText);
  } else {
    plugins.push(textCenter);
  }

  return (
    <SBoxContent>
      {header}
      {!header && !!title && (
        <Typography variant='s2' style={{ color: use(palette.gray700) }}>
          {title}
        </Typography>
      )}
      {total === 0 ? (
        <div className='d-flex align-items-center justify-content-center p4' style={style}>
          <Doughnut data={defaultDataset} options={options} plugins={[textCenterDefault]} />
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center' style={style}>
          {loading ? (
            <Skeleton variant='circle' height='100%' style={{ aspectRatio: '1' }} />
          ) : (
            <Doughnut data={data} options={options} plugins={plugins} />
          )}
        </div>
      )}
    </SBoxContent>
  );
};

export default DoughnutChart;
