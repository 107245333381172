import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SWrapper } from './DetailsComponents.styles';

const YTDDeductions = ({ data, gross }) => {
  const [expanded, setExpanded] = useState(false);
  const { types, deductions_sum } = data ?? {};

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <SWrapper $badgeColor={palette.amber400}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ArrowUp width={20} height={20} className='accordion-arrow' fill={palette.gray700} />}
          id='panel1a-header'
        >
          <div className='w-100 d-flex justify-content-between'>
            <Typography variant='s2' style={{ color: palette.gray900 }} className='d-flex align-items-center gap-1'>
              <span className='detail-badge' />
              Deductions - {formatNumber((deductions_sum * 100) / gross)}%
            </Typography>
            <Typography variant='button2' style={{ color: palette.gray900 }}>
              ${formatNumber(deductions_sum)}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          {(types.deductions || []).map((item) => (
            <div className='w-100 d-flex justify-content-between mb-1' key={item.id}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {item.type}
              </Typography>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                ${formatNumber(item.amount)}
              </Typography>
            </div>
          ))}
          {!types.deductions?.length && <NoRecords />}
        </AccordionDetails>
      </Accordion>
    </SWrapper>
  );
};

export default YTDDeductions;
