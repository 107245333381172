import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './AddCustomerStyle.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import BasicDetails from './BasicDetails';
import CustomizedSnackbars from '../../toast/Toast';
import ContactBook from './ContactBook';
import PaymentTerms from './PaymentTerms';
import DelayCodes from './DelayCodes';
import FuelSurchargeMatrix from './FuelSurchargeMatrix';
import LinehaulRateMatrix from './LinehaulRateMatrix';

const AddCustomer = ({ close, getCustomers }) => {
  const { use } = useTheme();
  const [status, setStatus] = useState(1);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const [stepsDone, setStepsDone] = useState({
    isBasicDetailsSaved: false,
    isContactBook: false,
    isPaymentTerms: false,
    isDelayCodes: false,
    isFuelSurchargeMatrix: false,
  });
  const [customer, setCustomer] = useState({
    basicDetails: {},
    basicDetailsStates: {},
    contactBook: {},
    paymentTerms: {},
    delayCodes: {},
    fuelSurchargeMatrix: {},
    linehaulRateMatrix: {},
  });
  const statusHandler = (status) => {
    setStatus(status);
  };
  const nextStep = (formValue, message) => {
    if (status === 1) {
      const { formikData, stateData } = formValue || {};
      setCustomer({
        ...customer,
        basicDetails: formikData,
        basicDetailsStates: stateData,
      });
    }
    if (status === 2) {
      setCustomer({
        ...customer,
        contactBook: formValue,
      });
    }
    if (status === 3) {
      setCustomer({
        ...customer,
        paymentTerms: formValue,
      });
    }
    if (status === 4) {
      setCustomer({
        ...customer,
        delayCodes: formValue,
      });
    }
    if (status === 5) {
      setCustomer({
        ...customer,
        fuelSurchargeMatrix: formValue,
      });
    }
    if (status >= 5) {
      document.getElementById('driver-container').scrollLeft += 400;
    }
    setStatus(status + 1);
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const prevStep = () => {
    if (status <= 5) {
      document.getElementById('driver-container').scrollLeft -= 400;
    }
    setStatus(status - 1);
  };

  const Cancel = () => {
    close();
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  return (
    <>
      {status !== 8 && (
        <div
          className='main-modal-wrapper'
          style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative' }}
        >
          <div style={{ position: 'absolute', top: '0.7rem', margin: '0 0 20px' }}>
            <p className='add-customer-modal-text' style={{ color: use('#171c26', palette.gray200) }}>
              Add Customer
            </p>
          </div>

          <Modal.Header className='add-customer-modal-header driver-style-wrap'>
            <div className='steps-wrapper' id='driver-container'>
              <div className={status >= 1 ? 'step-heading-active' : 'steps-heading'}>
                {status > 1 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                      readOnly
                    />
                    <span>Basic Details</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={!(status >= 1)}
                      checked={status >= 1}
                      readOnly
                      onClick={() => statusHandler(1)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Basic Details</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 1 ? 'incomplete-progressbar' : status > 1 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 2 ? 'step-heading-active' : 'steps-heading'}>
                {status > 2 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                      readOnly
                    />
                    <span>Contact Book</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 2}
                      checked={status === 2}
                      readOnly
                      onClick={() => statusHandler(2)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Contact Book</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 2 ? 'incomplete-progressbar' : status > 2 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 3 ? 'step-heading-active' : 'steps-heading'}>
                {status > 3 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                      readOnly
                    />
                    <span>Terms & Requirements</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 3}
                      checked={status === 3}
                      readOnly
                      onClick={() => statusHandler(3)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Terms & Requirements</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 3 ? 'incomplete-progressbar' : status > 3 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 4 ? 'step-heading-active' : 'steps-heading'}>
                {status > 4 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                      readOnly
                    />
                    <span>Delay Reason Codes</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 4}
                      checked={status === 4}
                      readOnly
                      onClick={() => statusHandler(4)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Delay Reason Codes</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 4 ? 'incomplete-progressbar' : status > 4 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 5 ? 'step-heading-active' : 'steps-heading'}>
                {status > 5 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                      readOnly
                    />
                    <span>Fuel Surcharges Matrix</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 5}
                      checked={status === 5}
                      readOnly
                      onClick={() => statusHandler(5)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Fuel Surcharges Matrix</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 5 ? 'incomplete-progressbar' : status > 5 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 6 ? 'step-heading-active' : 'steps-heading'}>
                {status > 6 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                      readOnly
                    />
                    <span>Fuel Surcharges Matrix</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 6}
                      checked={status === 6}
                      readOnly
                      onClick={() => statusHandler(6)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Linehaul Rate Matrix</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 6 ? 'incomplete-progressbar' : status > 6 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
            </div>
          </Modal.Header>
        </div>
      )}
      {status === 1 && (
        <div>
          <BasicDetails
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            basicDetails={customer.basicDetails}
            stepsDone={stepsDone.isBasicDetailsSaved}
            basicDetailsStates={customer.basicDetailsStates}
            customer={customer}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          <ContactBook
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isContactBook}
            setStepsDone={setStepsDone}
            customer={customer}
          />
        </div>
      )}
      {status === 3 && (
        <div>
          <PaymentTerms
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPreviousEmployement}
            paymentTerms={customer.paymentTerms}
            setStepsDone={setStepsDone}
            customer={customer}
          />
        </div>
      )}
      {status === 4 && (
        <div>
          <DelayCodes
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isCompensation}
            setStepsDone={setStepsDone}
            customer={customer}
          />
        </div>
      )}
      {status === 5 && (
        <div>
          <FuelSurchargeMatrix
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPolices}
            setStepsDone={setStepsDone}
            customer={customer}
          />
        </div>
      )}
      {status === 6 && (
        <div>
          <LinehaulRateMatrix
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPolices}
            setStepsDone={setStepsDone}
            customer={customer}
            getCustomers={getCustomers}
          />
        </div>
      )}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default AddCustomer;
