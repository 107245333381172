import { useDispatch } from 'react-redux';
import { handleToaster } from 'store/reducers/root.reducer';

const useShowToaster = () => {
  const dispatch = useDispatch();
  return ({ message, type }) => {
    dispatch(handleToaster({ visible: true, message, type }));
  };
};

export default useShowToaster;
