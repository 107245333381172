import { date, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  date_time: date().when('employment_status', {
    is: 'Inactive',
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  status: number().when('employment_status', {
    is: 'Inactive',
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  note: string().when('employment_status', {
    is: 'Inactive',
    then: string().trim().required('Required').nullable(),
    otherwise: string().trim().nullable(),
  }),
  rehire_status: string().when('employment_status', {
    is: 'Inactive',
    then: string().trim().required('Required').nullable(),
    otherwise: string().trim().nullable(),
  }),
});
