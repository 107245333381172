import styled from 'styled-components';

export const SPageWrapper = styled.div`
  padding-top: 16px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .menu-left-section {
    display: flex;
    align-items: center;
    column-gap: 36px;

    .menu-left-section__pay-period {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .MuiChip-root {
        margin-top: 2px;
      }
    }
  }
`;
