import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import Label from 'pages/Payroll/shared/Label';
import PayPeriod from 'pages/Payroll/shared/PayPeriod';
import PayTypeChip from 'pages/Payroll/shared/PayTypeChip';
import { getYearToDateSummary } from 'Api/Payroll';
import { SPickerWrapper } from 'pages/Payroll/Settlement/Tables/Tables.styles';
import { SInfoItem, SRow, SWrapper } from '../UserInfo.styles';

const StaffInfo = ({ data, setData, totalAdditions, totalDeductions, totalSettlement }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [payDateEdit, setPayDateEdit] = useState(false);
  const [yearToDate, setYearToDate] = useState(null);
  const { user, pay_period_start, pay_period_end, set_payment_date, gross } = data || {};

  const onPayDateChange = (date) => {
    setData({ ...data, set_payment_date: moment(date).format('MM/DD/YYYY') });
    setPayDateEdit(false);
  };

  const getYearToDateData = async () => {
    try {
      const { data } = await getYearToDateSummary({ user_type: 1, user_id: user.id });
      setYearToDate(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getYearToDateData();
  }, []);

  return (
    <SWrapper>
      <SRow>
        <SInfoItem>
          <Label>Pay Period</Label>
          <PayPeriod start={pay_period_start} end={pay_period_end} />
        </SInfoItem>
        <SInfoItem onMouseEnter={() => setPayDateEdit(true)} onMouseLeave={() => setPayDateEdit(false)}>
          <Label>Set Pay Date</Label>
          {payDateEdit ? (
            <SPickerWrapper style={{ width: '120px' }}>
              <DatePicker
                showYearDropdown
                selected={moment(set_payment_date).toDate() || new Date()}
                placeholderText='mm/dd/yyyy'
                dateFormat='MM/dd/yyyy'
                minDate={new Date()}
                onChange={onPayDateChange}
              />
            </SPickerWrapper>
          ) : (
            <Chip style={{ width: '120px' }} label={formatDate(set_payment_date)} />
          )}
        </SInfoItem>
        <SInfoItem>
          <Label>Pay Type</Label>
          <PayTypeChip data={user?.compensation_details} />
        </SInfoItem>
      </SRow>
      <Divider margin='16px 0' />
      <SRow>
        {!!user?.compensation_details?.per_hr_option && (
          <SInfoItem>
            <Label>YTD - Hourly Earnings</Label>
            <Typography variant='s2'>
              {currency}
              {formatNumber(yearToDate?.per_hour_amount)}
            </Typography>
          </SInfoItem>
        )}
        {!!user?.compensation_details?.each_booked_shipment && (
          <SInfoItem>
            <Label>YTD - Shipments Booked</Label>
            <Typography variant='s2'>
              {currency}
              {formatNumber(yearToDate?.shipment_amount)}
            </Typography>
          </SInfoItem>
        )}
        {!!user?.compensation_details?.sales_commission_option && (
          <SInfoItem>
            <Label>YTD - Sales Agent Commissions</Label>
            <Typography variant='s2'>
              {currency}
              {formatNumber(yearToDate?.commission_amount)}
            </Typography>
          </SInfoItem>
        )}
        <SInfoItem>
          <Label>Current - Gross Revenue</Label>
          <Typography variant='s2'>
            {currency}
            {formatNumber(gross)}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Current - Deductions</Label>
          <Typography variant='s2' style={{ color: use(palette.red500, palette.red800) }}>
            - {currency}
            {formatNumber(totalDeductions)}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Current - Additions</Label>
          <Typography variant='s2' style={{ color: use(palette.green500, palette.green400) }}>
            + {currency}
            {formatNumber(totalAdditions)}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Total Settlement</Label>
          <Typography variant='s2' style={{ color: use(palette.indigo500, palette.indigo500) }}>
            {totalSettlement < 0 && '- '}
            {currency}
            {formatNumber(Math.abs(totalSettlement))}
          </Typography>
        </SInfoItem>
      </SRow>
    </SWrapper>
  );
};

export default StaffInfo;
