import React from 'react';
import { ReactComponent as Empty } from 'assets/icons/empty.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as Success } from 'assets/icons/succes2.svg';
import { ReactComponent as Loading } from 'assets/icons/arrows-circular.svg';
import { DocumentTableSort } from 'components/DocumentsTableSort/DocumentsTableSort';
import { palette } from 'utils/constants';
import UserInfo from 'components/StopPoint/UserInfo';
import { Typography } from 'components/Typography';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import { WEEK_DAYS } from 'components/StopPoint/Walmart/constants/constants';
import styles from 'components/StopPoint/StopPoint.module.css';
import { useTheme } from 'context/themeContext';

export const cargoOnboardStatuses = [
  {
    id: '1',
    field: 'inbound_loaded',
    label: 'Inbound Loaded',
    color: palette.indigo500,
    backgroundColor: palette.indigo0,
    icon: <Success className='me-1' />,
  },
  {
    id: '2',
    field: 'outbound_loaded',
    label: 'Outbound Loaded',
    color: palette.green500,
    backgroundColor: palette.green0,
    icon: <Reload className='me-1' />,
  },
  {
    id: '3',
    field: 'empty',
    label: 'Empty',
    color: palette.gray600,
    backgroundColor: palette.gray50,
    icon: <Empty className='me-1' />,
  },
  {
    id: '4',
    field: 'loading',
    label: 'Loading',
    color: palette.orange500,
    backgroundColor: palette.orange0,
    icon: <Loading width={15} height={15} fill={palette.gray400} className='me-1' />,
  },
  {
    id: '',
    field: 'unknown',
    label: 'Unknown',
    color: palette.gray600,
    backgroundColor: palette.gray50,
  },
];

export const cargoOnboardIconMapper = {
  1: <Success className='me-1' />,
  2: <Reload className='me-1' />,
  3: <Empty className='me-1' />,
  4: <Loading width={15} height={15} fill={palette.gray400} className='me-1' />,
};

export const initialFilters = {
  page: 1,
  page_size: 50,
};

export const useColumns = ({ sortingQuery }) => {
  const { use } = useTheme();
  return [
    {
      field: 'stop_point_id',
      title: <DocumentTableSort title='ID' field='stop_point_id' sortingQuery={sortingQuery} />,
      cellStyle: { width: '10%' },
      width: '10%',
      headerStyle: { width: '10%' },
      render: (rowData) => {
        const id = rowData?.stop_point_id;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{id}</span>;
      },
    },
    {
      field: 'location_name',
      title: <DocumentTableSort title='LOCATION NAME' field='location_name' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const location_name = rowData?.location_name;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{location_name}</span>;
      },
    },
    {
      field: 'address1',
      title: <DocumentTableSort title='ADDRESS' field='address1' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const { address1 } = rowData;
        const { address2 } = rowData;
        const city = rowData.city?.name;
        const state = rowData.state?.name;
        const country = rowData?.country?.sortname;
        const { zipcode } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {address1 && `${address1}, `}
            {address2 && `${address2}, `}
            {city && `${city}, `}
            {state && `${state}, `}
            {zipcode && `${zipcode},`}
            {country && `${country}`}
          </span>
        );
      },
    },
    {
      field: 'primary_contact_name',
      title: (
        <DocumentTableSort title='PRIMARY CONTACT NAME' field='primary_contact_name' sortingQuery={sortingQuery} />
      ),
      render: (rowData) => {
        const founded = rowData?.primary_contact_book;

        return (
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {founded ? (
              <UserInfo data={founded} />
            ) : (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <span>-</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'status_id',
      title: <DocumentTableSort title='STATUS' field='status_id' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const status = rowData?.status_id;
        return status === 1 ? (
          <span
            style={{
              fontSize: 11,
              fontWeight: 500,
              borderRadius: 4,
              padding: '2px 6px',
              color: '#14804A',
              backgroundColor: '#E1FCEF',
            }}
          >
            ACTIVE
          </span>
        ) : (
          <span
            style={{
              fontSize: 11,
              fontWeight: 500,
              borderRadius: 4,
              padding: '2px 6px',
              color: '#5A6376',
              backgroundColor: '#F0F2F7',
            }}
          >
            INACTIVE
          </span>
        );
      },
    },
    {
      field: 'average_wait_time',
      title: <DocumentTableSort title='Avg. Wait Time' field='average_waiting_time' sortingQuery={sortingQuery} />,
      render: (rowData) => getAverageTimeConverted(rowData?.average_waiting_time),
    },
    {
      field: 'working_hour_by',
      title: <DocumentTableSort title='WORKING HOURS' field='working_hour_by' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const data = { closed: [] };
        rowData?.stop_point_working_hour?.forEach((w) => {
          const time = `${w.start}-${w.end}`;
          if (!Array.isArray(data[time])) {
            data[time] = [];
          }
          const founded = WEEK_DAYS.find((d) => d.id === +w.week_day);
          data[time].push(founded);
        });

        WEEK_DAYS.forEach((day) => {
          const founded = rowData?.stop_point_working_hour?.findIndex((w) => +w.week_day === +day.id);
          if (founded === -1) {
            data.closed.push(day);
          }
        });

        if (!rowData?.working_hour_by) {
          return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <span>-</span>
            </div>
          );
        }

        return (
          <div>
            <div>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {rowData?.working_hour_by === 1
                  ? 'First Come First Serve (FCFS)'
                  : rowData?.working_hour_by === 2
                  ? 'By Appointment'
                  : 'By Time Window'}
              </Typography>
            </div>
            <div className={styles.info_section}>
              {!!rowData?.stop_point_working_hour && !!data && (
                <>
                  {Object.keys(data).map((time) => {
                    const value = data[time];
                    const isClosed = time === 'closed';

                    if (isClosed) {
                      return null;
                    }
                    return (
                      <div
                        key={time}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 5,
                        }}
                      >
                        {value?.map((d) => {
                          return (
                            <Typography
                              key={d?.day[0]}
                              variant='s2'
                              style={{
                                width: 26,
                                height: 20,
                                marginRight: 6,
                                textAlign: 'center',
                                padding: '1px 6px',
                                borderRadius: '4px',
                                backgroundColor: '#F0F2F7',
                                color: palette.gray600,
                              }}
                            >
                              {d?.day[0]}
                            </Typography>
                          );
                        })}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '86px',
                            textAlign: 'center',
                          }}
                        >
                          <div className={styles.date}>
                            <Typography
                              variant='c2'
                              style={{
                                height: 20,
                                padding: '1px 6px',
                                borderRadius: '4px',
                                backgroundColor: '#F0F2F7',
                                color: use(palette.gray600, palette.gray200),
                              }}
                            >
                              {time}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {!!data.closed?.length && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                      {data.closed?.map((time, index) => (
                        <>
                          <Typography
                            key={time?.day[0]}
                            variant='s2'
                            style={{
                              width: 26,
                              height: 20,
                              marginRight: 6,
                              textAlign: 'center',
                              padding: '1px 6px',
                              borderRadius: '4px',
                              backgroundColor: '#F0F2F7',
                              color: palette.red500,
                            }}
                          >
                            {time?.day[0]}
                          </Typography>
                          {index === Number(data.closed?.length) - 1 && (
                            <Typography variant='s2' style={{ color: palette.gray700, width: 86 }}>
                              Closed
                            </Typography>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'equipments_at_stop_vehicles',
      title: <DocumentTableSort title='Vehicles Count' field='stop_point_vehicles_count' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const vehicles_count = rowData?.stop_point_vehicles_count;
        return (
          <div style={{ display: 'flex', gap: 25 }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Vehicles
              </Typography>
              {vehicles_count ? (
                <span
                  style={{
                    backgroundColor: '#E9EDF5',
                    padding: '0 8px',
                    borderRadius: 8,
                    fontSize: 11,
                  }}
                >
                  {vehicles_count}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'equipments_at_stop_trailers',
      title: <DocumentTableSort title='Trailers Count' field='stop_point_trailers_count' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const trailers_count = rowData?.stop_point_trailers_count;
        return (
          <div style={{ display: 'flex', gap: 25 }}>
            <div style={{ display: 'flex', gap: 16 }}>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Trailers
              </Typography>
              {trailers_count ? (
                <span
                  style={{
                    backgroundColor: '#E9EDF5',
                    padding: '0 8px',
                    borderRadius: 8,
                    fontSize: 11,
                  }}
                >
                  {trailers_count}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        );
      },
    },
  ];
};
