import moment from 'moment';
import { ALERT_TYPE } from 'componentsV2/AlertCenter/TakeActions/TakeActions.data';

const insuranceTypesMapper = {
  [ALERT_TYPE.AUTOMOBILE_LIABILITY]: 'liability',
  [ALERT_TYPE.PHYSICAL_INSURANCE]: 'physical',
};

export const insuranceSettingsConverter = (settings, alertType) => {
  const settingPrefix = insuranceTypesMapper[alertType];

  const IsActive = settings?.[`${settingPrefix}_insurance_policy_active`] || 0;
  const policy_id = settings?.[`${settingPrefix}_insurance_policy_id`] || '';
  const expire_date = settings?.[`${settingPrefix}_insurance_expire_date`]
    ? moment(settings?.[`${settingPrefix}_insurance_expire_date`]).toDate()
    : null;
  const attachment = settings?.[`${settingPrefix}_insurance_policy_attach`] || '';

  const isAvailable = policy_id && attachment && !!IsActive;

  return {
    policy_id,
    expire_date,
    attachment,
    isAvailable,
  };
};
