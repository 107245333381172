import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import './StatusUpdateModal.css';

export default function StaffUpdateModal({ ...props }) {
  const { use } = useTheme();
  const formik = useFormik({
    initialValues: {
      login_status: '',
      status: '',
    },
  });

  const statusUpdate = () => {
    const { login_status } = formik?.values || {};
    let status = {};
    if (login_status === 1) {
      status = {
        id: 1,
        staff_status: 'Available',
      };
    } else if (login_status === 2) {
      status = {
        id: 2,
        staff_status: 'Logged Off',
      };
    } else if (login_status === 3) {
      status = {
        id: 3,
        staff_status: 'Away',
      };
    } else if (login_status === 4) {
      status = {
        id: 4,
        staff_status: 'Do Not Disturb',
      };
    } else if (login_status === 5) {
      status = {
        id: 5,
        staff_status: 'Disabled',
      };
    }
    props.updateDriverStatus({ ...formik.values, status });
  };

  useEffect(() => {
    if (props.driverStatus) {
      formik.setFieldValue('login_status', props.driverStatus);
    }
  }, [props.driverStatus]);

  return (
    <div className='modal-container'>
      <Modal {...props} dialogClassName='modal-dialog-box' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
          closeButton
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Update Status for {props.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{ backgroundColor: use(palette.white, palette.dark900) }}
        >
          <p className='subline' style={{ color: use(palette.gray900, palette.gray200) }}>
            Change status
          </p>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='login_status'
              onChange={() => {
                formik.setFieldValue('login_status', 1);
              }}
              value={formik.values.login_status}
              checked={formik.values.login_status === 1}
            />

            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Available
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='login_status'
              onChange={() => {
                formik.setFieldValue('login_status', 2);
              }}
              value={formik.values.login_status}
              checked={formik.values.login_status === 2}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Logged Off
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='login_status'
              onChange={() => {
                formik.setFieldValue('login_status', 3);
              }}
              value={formik.values.login_status}
              checked={formik.values.login_status === 3}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Away
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='login_status'
              onChange={() => {
                formik.setFieldValue('login_status', 4);
              }}
              value={formik.values.login_status}
              checked={formik.values.login_status === 4}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Do Not Disturb
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='login_status'
              onChange={() => {
                formik.setFieldValue('login_status', 5);
              }}
              value={formik.values.login_status}
              checked={formik.values.login_status === 5}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Disabled
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark800),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.white, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' onClick={statusUpdate}>
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
