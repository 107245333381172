import axios from '../services/axios';

export async function sendSmSChatMessage(jsonData) {
  const { data } = await axios.post('sms-chat/send-chat-sms-message', jsonData);
  return data;
}
export async function sendGroupChatMessage(jsonData) {
  const { data } = await axios.post('sms-chat/send-group-chat-sms', jsonData);
  return data;
}

export async function getStaffUsers(params) {
  const { data } = await axios.get('/user/staff', { params: { page: 1, itemsPerPage: 1000, ...params } });
  return data;
}
export async function getTwilioPhoneNumbers() {
  const { data } = await axios.get('/twilio/get-phone-number');
  return data;
}

export async function selectGroupPhoneNumber(jsonData) {
  const { data } = await axios.put('sms-chat/select-group-phone-number', jsonData);
  return data;
}

export async function sendAnnouncementMessage(jsonData) {
  const { data } = await axios.post('sms-chat/send-announcement-message', jsonData);
  return data;
}
