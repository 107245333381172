import styled from 'styled-components';

export const SUnderlineText = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  color: #4f5aed;

  :hover {
    text-decoration: underline;
  }
`;
