import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { speedLimitOptions } from './Charts.data';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const SpeedLimitChart = ({ speedLimit }) => {
  const data = {
    labels: (speedLimit || []).map((item) => item.speed_limit),
    datasets: [
      {
        label: '',
        data: speedLimit.map((item) => item.total_count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.4)',
          'rgba(54, 162, 235, 0.4)',
          'rgba(255, 206, 86, 0.4)',
          'rgba(75, 192, 192, 0.4)',
          'rgba(153, 102, 255, 0.4)',
          'rgba(255, 159, 64, 0.4)',
          'rgba(45, 159, 64, 0.4)',
        ],
        hoverBackgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
          'rgba(255, 206, 86)',
          'rgba(75, 192, 192)',
          'rgba(153, 102, 255)',
          'rgba(255, 159, 64)',
          'rgba(45, 159, 64)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <PolarArea data={data} options={speedLimitOptions} />;
};

export default SpeedLimitChart;
