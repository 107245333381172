import { array, date, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  medicalExaminersResults: array().of(
    object().shape({
      expiry_date: validator.date(),
    })
  ),
  mvrCheckResults: array().of(
    object().shape({
      mvr_check_date: validator.pastDate(),
      mvr_next_check_date: validator.date(),
    })
  ),
  drugTestResults: array().of(
    object().shape({
      drug_checked_date: validator.pastDate(),
    })
  ),
  criminalCheckResults: array().of(
    object().shape({
      criminal_check_date: validator.pastDate(),
    })
  ),
  fmcsaResults: array().of(
    object().shape({
      fmcsa_checked_date: validator.pastDate(),
      fmcsa_next_check_date: date().when('no_expiry', {
        is: (no_expiry) => !no_expiry,
        then: validator.date(),
        otherwise: date().nullable(),
      }),
    })
  ),
  pspResults: array().of(
    object().shape({
      psp_check_date: validator.pastDate(),
    })
  ),
});
