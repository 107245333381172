import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { DatePicker, TimePicker } from 'common/Pickers';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getCustomerDelayCode } from 'Api/Customers';
import useDateFormat from 'hooks/useDateFormat';
import { customerTimeToUtc } from 'utils/helpers';
import { getStopEtaChanges, updateStopETA } from 'Api/Shipment';
import { Typography } from 'components/Typography';
import { validationSchema } from './validationSchema';

const UpdateETA = ({ open, onClose, shipment, stop, onSuccess = () => null }) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [delayReasonCodes, setDelayReasonCodes] = useState([]);
  const [stopChanges, setStopChanges] = useState(null);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const date = moment(values.eta_date).format('YYYY-MM-DD');
      const time = moment(values.eta_time).format('HH:mm');

      const body = {
        eta: customerTimeToUtc(`${date} ${time}`, 'YYYY-MM-DDTHH:mm:ss.SSSSS[Z]'),
        delay_reason_id: values.delay_reason?.id,
      };
      await updateStopETA(stop.id, body);
      showToaster({ type: 'success', message: 'ETA has been updated successfully!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      delay_reason: stop.delay_reason || null,
      eta_date: stop.eta ? moment(convertToCustomerTime(stop.eta)).toDate() : null,
      eta_time: stop.eta ? moment(convertToCustomerTime(stop.eta)).toDate() : null,
    },
    onSubmit,
    validationSchema,
  });

  const getDelayReasonCodes = async () => {
    try {
      const { data } = await getCustomerDelayCode({ id: shipment?.shipment_billing[0]?.billing_customer?.id });
      setDelayReasonCodes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getChangeLog = async () => {
    try {
      const { data } = await getStopEtaChanges(stop.id);
      if (data.length) {
        setStopChanges(data[0]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDelayReasonCodes();
    getChangeLog();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Update ETA'
      $bgColor={palette.gray0}
      $maxWidth='376px'
      $minWidth='376px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'Upload',
          type: 'primary',
          title: 'Update',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <div className='mb-4'>
          <InputLabel required>ETA Date/Time</InputLabel>
          <div className='d-flex gap-2'>
            <DatePicker
              name='eta_date'
              value={values.eta_date}
              onChange={(val) => handleChange('eta_date', val)}
              disablePast
              disabled={!!stop.arrival_date && !!values.eta_date}
            />
            <TimePicker
              width='130px'
              value={values.eta_time}
              onChange={(time) => handleChange('eta_time', time)}
              disabled={!!stop.arrival_date && !!values.eta_date}
            />
          </div>
          <ErrorMessage error={touchedErrors.eta_date || touchedErrors.eta_time} />
        </div>
        <div>
          <Autocomplete
            label='Select Delay Reason from Delay Reasons List'
            name='delay_reason'
            options={delayReasonCodes}
            value={values.delay_reason}
            onChange={(e, val) => handleChange('delay_reason', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.reason.title} ${option.edi_api_code}`}
            error={touchedErrors.delay_reason}
          />
        </div>
        {!!stopChanges && (
          <div className='mt-3 d-flex flex-column gap-2'>
            {!!stopChanges.updated_by && (
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Updated By - {stopChanges.updated_by.first_name} {stopChanges.updated_by.last_name}
              </Typography>
            )}
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              {stopChanges.description || '-'}
            </Typography>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UpdateETA;
