import React, { useState } from 'react';
import Box from '@mui/material/Box';
import './ManageTypes.css';
import { useFormik } from 'formik';
import axios from 'axios';
import { Icons } from 'assets/icons';
import authHeader from 'services/auth-header';
import { palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import styles from 'components/UpdateDocumentType/UpdateDocumentType.module.css';
import cross from 'assets/icons/documents/cross.svg';
import check from 'assets/icons/documents/check.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height: 'auto',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  p: 4,
  padding: 0,
  borderRadius: '8px',
};

const ManageTypes = ({ handleManageTypesClose, manageTypes, getManageType, MaintenanceRecordManageType }) => {
  const [showcustom, setShowCustomOpen] = useState(false);
  const handleShowCustomOpen = () => {
    setShowCustomOpen(true);
    formik.setFieldValue('type', '');
  };
  const handleShowCustomClose = () => setShowCustomOpen(false);
  const [typeErr, setTypeErr] = useState(false);
  const [typeErrData, setTypeErrData] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;
  const TypeErrOpen = (message, id) => {
    setTypeErr(true);
    setTypeErrData({ message, id });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
    },
    onSubmit: (values) => {
      axios.post(`${API_URL}/equipment/maintenance-type`, values, { headers: authHeader() }).then((res) => {
        if (res) {
          handleShowCustomClose();
          getManageType(true);
          MaintenanceRecordManageType();
        }
      });
    },
  });
  const RemoveManageType = (id) => {
    axios
      .delete(`${API_URL}/equipment/maintenance-type/${id}`, { headers: authHeader() })
      .then((res) => {
        if (res) {
          getManageType(true);
          MaintenanceRecordManageType();
        }
      })
      .catch(() => TypeErrOpen('Not allowed', id));
  };

  return (
    <div className='container'>
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit}>
          <div className='mt-3 ms-2 mb-3'>
            <span className='manage-type-header'>Manage Types</span>
          </div>
          <div className='container manage-type-table'>
            <table className='table'>
              <tbody>
                {manageTypes &&
                  manageTypes.map((v) => (
                    <tr key={v.id}>
                      <td className='manage-type-title'>
                        <div className='d-flex justify-content-between'>
                          <span>{v?.type}</span>
                          <span style={{ color: '#D12953' }}>
                            {typeErr && typeErrData?.id === v?.id ? typeErrData?.message : ''}{' '}
                          </span>
                          {!v?.is_default ? (
                            <span onClick={() => RemoveManageType(v?.id)}>
                              {' '}
                              <DeleteIcon width={9} height={9} fill={palette.red500} />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {showcustom === true ? (
            <div className=' mt-2'>
              <div
                className=' container d-flex mt-1justify-content-start align-items-center  manage-type-border-line'
                style={{ paddingBottom: '10px' }}
              >
                <input
                  placeholder='Enter Custom Type'
                  name='type'
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  id='manage-types-input'
                  type='text'
                />
                <div className={styles.listIcons}>
                  <div className={styles.crossBox} onClick={handleShowCustomClose}>
                    <img
                      src={cross}
                      width={20}
                      height={20}
                      style={{ padding: 3, cursor: 'pointer' }}
                      alt='cross'
                      className={styles.plus_icon}
                    />
                  </div>
                  <button className={styles.checkBox} style={{ border: 'none' }} type='submit'>
                    <img
                      src={check}
                      width={20}
                      height={20}
                      style={{ padding: 3, cursor: 'pointer' }}
                      alt='cross'
                      className={styles.plus_icon}
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ' '
          )}
          <div>
            <div className='manage-type-add-custom ms-3 mt-2' onClick={handleShowCustomOpen}>
              <span>
                <img src={Icons.AddBlueIcon} alt='' />
              </span>{' '}
              Add Custom
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button className='manage-type-modal-done-button' onClick={handleManageTypesClose}>
              Close
            </button>
            <button
              onClick={handleManageTypesClose}
              className='manage-type-modal-done-button'
              style={{ background: '#4F5AED', color: '#fff' }}
            >
              Done
            </button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default ManageTypes;
