import React, { useEffect } from 'react';
import axios from 'axios';
import authHeader from 'services/auth-header';
import { Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import './addhook.css';

const API_URL = process.env.REACT_APP_API_URL;

const UnHooked = ({
  handleClose,
  unHookedData,
  HookedErrShow,
  GetHookedError,
  UnhookedMsgClose,
  UnHoookedMsgShow,
  onSuccess = () => null,
}) => {
  const { use } = useTheme();

  useEffect(() => {
    GetHookedError();
  }, []);

  const [id, eq_id, length, unit, title] = unHookedData;
  const Unhooked = (id) => {
    const body = { hooked_to_id: null };
    axios.put(`${API_URL}/equipment/${id}`, body, { headers: authHeader() }).then((res) => {
      if (res?.status === 200) {
        onSuccess();
        handleClose();
        nextAvailable();
        UnHoookedMsgShow(res.data?.data);
        setTimeout(() => {
          UnhookedMsgClose();
        }, 3000);
      } else {
        HookedErrShow();
      }
    });
  };
  async function Trailer() {
    const { data } = await axios.get(`${API_URL}/equipment/hook-to/${id}`, {
      headers: authHeader(),
    });
    return data;
  }

  const { isError, isLoading, error } = useQuery('hooked', Trailer);
  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }
  async function nextAvailable() {
    const values = { equipment_id: id };
    axios.post(`${API_URL}/equipment/next-available-location`, values, {
      headers: authHeader(),
    });
  }
  return (
    <div className='equipment-list-unhooked-trailer' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <Modal.Body className='remove-driver-dailogue-body'>
        <div className='remove-driver-dailogue-wrapper'>
          <h6 className='remove-modal-header' style={{ color: use(palette.dark800, palette.gray200) }}>
            Unhook Trailer
          </h6>
          <p className='remove-driver-paragraph' style={{ color: use(palette.dark800, palette.gray200) }}>
            Are you sure you want to unhook {eq_id} ({length} {unit} {title}) ?
          </p>
          <div className='d-flex justify-content-end remove-driver-footer mt-3'>
            <button
              type='button'
              className='cancel-button'
              onClick={handleClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            <button className='unhooked-button' type='submit' onClick={() => Unhooked(id)}>
              Yes, Unhook
            </button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default UnHooked;
