import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SFlexWrapper } from '../HoursTracked.styles';

const HoursWorkedId = ({ data }) => {
  return (
    <SFlexWrapper>
      <Typography variant='s2'>{data.id}</Typography>
      {(data.startedPrior || data.endedAfter) && data.user_type === 2 && (
        <Tooltip
          title='Driver has worked outside working hours, please take action to correct hours.'
          placement='right'
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '12px',
              },
            },
          }}
        >
          <WarningIcon className='warning-icon' fill={palette.red500} />
        </Tooltip>
      )}
    </SFlexWrapper>
  );
};

export default HoursWorkedId;
