import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { DatePicker, TimePicker } from 'common/Pickers';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { updateRecurringLaneStop, UpdateStopPointStop } from 'Api/Shipment';
import { validationSchema } from './validationSchema';

const UpdateStopSchedule = ({ open, onClose, swapData, isRecurring, onSuccess }) => {
  const showToaster = useShowToaster();
  const { formatDateTime } = useDateFormat();
  const [loading, setLoading] = useState(false);

  const stopToSwap = swapData.items[swapData.destinationIndex];
  const prevStop = swapData.items[swapData.destinationIndex - 1];
  const nextStop = swapData.items[swapData.destinationIndex + 1];

  const onSubmit = async (values) => {
    try {
      const isValidDate = validateDate(values);

      if (!isValidDate) {
        showToaster({
          type: 'error',
          message: 'Selected date should be between previous and next stop scheduled dates!',
        });
        return;
      }

      setLoading(true);
      const formData = new URLSearchParams();
      formData.append('stop_point_id', stopToSwap.stop_point?.id);
      formData.append('bill_type', stopToSwap.bill_type);
      formData.append('stop_point_type', stopToSwap.stop_point_type);
      formData.append('scheduled_date', moment(values.scheduled_date).format('YYYY-MM-DD'));
      formData.append('from', moment(values.from).format('HH:mm'));
      formData.append(
        'scheduled_date_to',
        moment(values.scheduled_date_to || values.scheduled_date).format('YYYY-MM-DD')
      );
      formData.append('to', moment(values.to || values.from).format('HH:mm'));

      isRecurring
        ? await updateRecurringLaneStop(stopToSwap.id, formData)
        : await UpdateStopPointStop({ params: formData, id: stopToSwap.id });

      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      scheduled_type: Number(stopToSwap.scheduled_type),
      scheduled_date: stopToSwap.scheduled_date ? moment(stopToSwap.scheduled_date).toDate() : null,
      from: stopToSwap.from ? moment(stopToSwap.from, 'HH:mm').toDate() : null,
      scheduled_date_to: stopToSwap.scheduled_date_to ? moment(stopToSwap.scheduled_date_to).toDate() : null,
      to: stopToSwap.to ? moment(stopToSwap.to, 'HH:mm').toDate() : null,
    },
    onSubmit,
    validationSchema,
  });

  const validateDate = (currentStop) => {
    const prevStopScheduledDate = prevStop
      ? [1, 3].includes(Number(prevStop.scheduled_type))
        ? `${prevStop.scheduled_date_to || prevStop.scheduled_date} ${prevStop.to || prevStop.from}`
        : `${prevStop.scheduled_date} ${prevStop.from}`
      : null;
    const nextStopScheduledDate = nextStop ? `${nextStop.scheduled_date} ${nextStop.from}` : null;
    const currentScheduledDate = `${moment(currentStop.scheduled_date).format('YYYY-MM-DD')} ${moment(
      currentStop.from
    ).format('HH:mm')}`;
    const currentScheduledDateTo = `${moment(currentStop.scheduled_date_to).format('YYYY-MM-DD')} ${moment(
      currentStop.to
    ).format('HH:mm')}`;

    return (
      moment(currentScheduledDate).isBetween(
        moment(prevStopScheduledDate || '1970-01-01'),
        moment(nextStopScheduledDate || moment().add(1000, 'years'))
      ) &&
      ([1, 3].includes(Number(currentStop.scheduled_type))
        ? moment(currentScheduledDateTo).isBetween(
            moment(prevStopScheduledDate || '1970-01-01'),
            moment(nextStopScheduledDate || moment().add(1000, 'years'))
          )
        : true)
    );
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Swap Stop'
      $bgColor={palette.gray0}
      $maxWidth='576px'
      $minWidth='576px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'swap',
          type: 'primary',
          title: 'Swap',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <div className='mb-5'>
          <Typography variant='s2'>Please, update scheduled date to fit in previous and next stop dates!</Typography>
        </div>
        {prevStop && (
          <div>
            <Typography variant='s2' style={{ color: palette.gray500 }}>
              Previous Stop Scheduled Date -
            </Typography>{' '}
            <Typography variant='s2'>{formatDateTime(`${prevStop.scheduled_date} ${prevStop.from}`)}</Typography>{' '}
            {[1, 3].includes(Number(prevStop.scheduled_type)) && (
              <>
                <Typography variant='s2' style={{ color: palette.gray500 }} className='ms-1 me-1'>
                  to
                </Typography>{' '}
                <Typography variant='s2'>
                  {formatDateTime(
                    `${prevStop.scheduled_date_to || prevStop.scheduled_date} ${prevStop.to || prevStop.from}`
                  )}
                </Typography>
              </>
            )}
          </div>
        )}
        <div className='d-flex gap-2 mb-4 mt-4'>
          <div className='d-flex justify-content-start align-items-start gap-2'>
            <div>
              <DatePicker
                width='130px'
                size='small'
                name='scheduled_date'
                value={values.scheduled_date}
                onChange={(date) => handleChange('scheduled_date', date)}
                error={touchedErrors.scheduled_date}
              />
            </div>
            <div>
              <TimePicker
                size='small'
                width='100px'
                value={values.from}
                onChange={(time) => handleChange('from', time)}
                error={touchedErrors.from}
              />
            </div>
          </div>
          {[1, 3].includes(Number(stopToSwap.scheduled_type)) && (
            <div className='d-flex justify-content-start align-items-center gap-2'>
              <Typography variant='s2'>to</Typography>
              <div>
                <DatePicker
                  width='130px'
                  size='small'
                  name='scheduled_date_to'
                  value={values.scheduled_date_to}
                  onChange={(date) => handleChange('scheduled_date_to', date)}
                  error={touchedErrors.scheduled_date_to}
                  minDate={moment(values.scheduled_date).toDate()}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={values.to}
                  onChange={(time) => handleChange('to', time)}
                  error={touchedErrors.to}
                />
              </div>
            </div>
          )}
        </div>
        {nextStop && (
          <div>
            <Typography variant='s2' style={{ color: palette.gray500 }}>
              Next Stop Scheduled Date -
            </Typography>{' '}
            <Typography variant='s2'>{formatDateTime(`${nextStop.scheduled_date} ${nextStop.from}`)}</Typography>{' '}
            {[1, 3].includes(Number(nextStop.scheduled_type)) && (
              <>
                <Typography variant='s2' style={{ color: palette.gray500 }} className='ms-1 me-1'>
                  to
                </Typography>{' '}
                <Typography variant='s2'>
                  {formatDateTime(
                    `${nextStop.scheduled_date_to || nextStop.scheduled_date} ${nextStop.to || nextStop.from}`
                  )}
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UpdateStopSchedule;
