import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Uploader from 'common/Uploader';
import { Textarea } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import ChatAppServices from 'services/chatApp';
import { useAuth } from 'context/auth.context';
import { validationSchema } from './validationSchema';
import { SWrapper } from './AddNotes.styles';

const AddNotes = ({ chatUser, activeChannel, onSuccess }) => {
  const showToaster = useShowToaster();
  const { value: userData } = useAuth();

  const onAddNote = async (values, resetForm) => {
    try {
      const formData = new FormData();
      formData.append('chat_id', chatUser.id);
      formData.append('title', `Note by ${userData.user?.first_name} ${userData.user?.last_name}`);
      formData.append('notes', values.notes);
      if (values.attachment) {
        formData.append('attachment', values.attachment);
      }
      formData.append('chat_user_id', activeChannel.data.cid);
      await ChatAppServices.addNotes(formData);
      resetForm();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const { values, handleChange, handleSubmit, isValid, touchedErrors, resetForm } = useForm({
    initialValues: {
      title: '',
      notes: '',
      attachment: null,
    },
    validationSchema,
    onSubmit: (values) => onAddNote(values, resetForm),
  });

  return (
    <SWrapper>
      <div className='body-wrapper'>
        <Textarea
          rows={3}
          name='notes'
          placeholder='Message'
          value={values.notes}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && isValid) {
              event.preventDefault();
              handleSubmit();
            }
          }}
          error={touchedErrors.notes}
        />
        <Uploader
          type={2}
          label='Attachment'
          document={values.attachment}
          onDrop={(files) => handleChange('attachment', files[0])}
          onRemove={() => handleChange('attachment', null)}
          accept={['application/pdf', 'image/jpeg', 'image/png']}
        />
        <div className='d-flex justify-content-end'>
          <CustomButton
            title='Add'
            type='primary'
            styleTitle={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 500, marginLeft: '5px' }}
            styleButton={{ padding: '6px 12px', margin: 0, width: 'fit-content' }}
            leftIcon={<PlusIcon fill={palette.white} />}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </SWrapper>
  );
};

export default AddNotes;
