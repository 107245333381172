import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as EdiApiIcon } from 'assets/icons/vehicles/green.svg';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import { getShipmentType } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import { getPlannerStatus } from 'components/TableShipments/helpers/constants';
import RowActions from 'pages/OfferedShipments/ShipmentsTable/components/RowActions';
import OfferExpiration from 'pages/OfferedShipments/ShipmentDetails/components/OfferExpiration';
import { offerStatus } from 'pages/OfferedShipments/ShipmentsTable/ShipmentsTable.data';
import { SGroup, SInfoItem, SWrapper } from './Header.styles';

const Header = ({
  shipment,
  offerOptions,
  onRevertAll,
  onCancelShipment,
  onViewNotes,
  onRevertAndResend,
  onExtendExpiration,
}) => {
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);
  const { shipment_id } = shipment || {};
  const {
    expire_shipment_offer,
    expire_shipment_offer_unit,
    retract_carrier_award,
    retract_carrier_award_unit,
    auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit,
    require_minimum_margin,
    require_minimum_margin_percent,
    auto_repost,
    auto_dispatch,
  } = offerOptions || {};

  const { totalDollar } = useMemo(() => {
    let totalDollar = 0;
    let totalCad = 0;

    shipment.shipment_billing?.forEach((el) => {
      const amount = el.total_amount || 0;
      if (Number(el.currency_id) === 1) {
        totalDollar += amount;
      } else {
        totalCad += amount;
      }
    });

    const statusData = getPlannerStatus(shipment);

    return { totalDollar, totalCad, statusData };
  }, [shipment]);

  return (
    <SWrapper>
      <div onClick={() => navigate(-1)} className='navigate-back-button'>
        <ArrowLeft width={10} height={10} />
        <Typography variant='c1' style={{ color: palette.indigo500 }}>
          Back
        </Typography>
      </div>
      {(!!shipment.is_edi_api || !!shipment.manual_edi_api) && (
        <div className='d-flex align-items-center gap-3 mt-1'>
          <EdiApiIcon />
          <Typography variant='c2' style={{ color: palette.gray500 }}>
            EDI/API SHIPMENT
          </Typography>
        </div>
      )}
      <div className='d-flex justify-content-between align-items-end'>
        <div className='d-flex align-items-center gap-4 mt-3'>
          <Typography variant='h1'> Shipment {shipment_id}</Typography>
          <div className='d-flex flex-grow-1'>
            <SInfoItem>
              <div className='nowrap'>
                <Typography variant='s2'>{shipment?.shipment_id} (REF: </Typography>
                {(shipment?.shipment_billing && shipment?.shipment_type !== 'ltl') ||
                (shipment?.shipment_type === 'ltl' && shipment?.shipment_billing.length === 1) ? (
                  <Typography variant='s3' style={{ color: palette.dark800 }}>
                    {shipment?.shipment_billing?.[0]?.reference_id}
                  </Typography>
                ) : (
                  <Tooltip
                    title={shipment?.shipment_billing?.map((el) => (
                      <Typography
                        variant='s2'
                        as='p'
                        key={el?.reference_id}
                        style={{ color: palette.white, margin: 0 }}
                      >
                        {el?.reference_id}
                      </Typography>
                    ))}
                  >
                    <span>
                      <Typography variant='s3' style={{ color: palette.dark800, whiteSpace: 'nowrap' }}>
                        Multi-Ref LTL
                      </Typography>
                    </span>
                  </Tooltip>
                )}
                )
              </div>
            </SInfoItem>
            <SInfoItem>
              {shipment?.shipment_billing && shipment.shipment_billing?.length === 1 ? (
                <Typography variant='s3' style={{ color: palette.dark800 }}>
                  {shipment?.shipment_billing?.[0]?.billing_customer?.company_name}&nbsp;
                  {shipment.shipment_type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
                </Typography>
              ) : shipment?.shipment_billing && shipment?.shipment_billing.length > 1 ? (
                <Tooltip
                  title={shipment?.shipment_billing.map((el, i) => (
                    <Typography
                      key={el?.id || i}
                      as='p'
                      variant='s2'
                      style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer', color: palette.white }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/customer-profile/${el?.billing_customer?.id}`);
                      }}
                    >
                      {el?.billing_customer?.company_name}
                    </Typography>
                  ))}
                >
                  <span>
                    <Typography variant='s3' style={{ color: palette.dark800, whiteSpace: 'nowrap' }}>
                      Multi-Customer LTL
                    </Typography>
                  </span>
                </Tooltip>
              ) : null}
            </SInfoItem>
            {shipment?.shipment_billing[0]?.groups && (
              <SInfoItem>
                <SGroup $bgColor={shipment?.shipment_billing[0]?.groups?.color}>
                  <Typography variant='c3'>{shipment?.shipment_billing[0]?.groups?.short_name}</Typography>
                </SGroup>
              </SInfoItem>
            )}
            <SInfoItem>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {shipment.shipment_stops?.length} Stops
              </Typography>
            </SInfoItem>
            <SInfoItem>
              <Chip
                label={offerStatus[shipment.offer_status]?.label}
                labelColor={offerStatus[shipment.offer_status]?.color}
                bgColor={offerStatus[shipment.offer_status]?.bgColor}
                fontSize='11px'
                fontWeight={500}
              />
            </SInfoItem>
            <SInfoItem>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {currency} {formatNumber(totalDollar)}
              </Typography>
            </SInfoItem>
            <SInfoItem>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {shipment?.loaded_miles?.toFixed()} Miles
              </Typography>
            </SInfoItem>
            <SInfoItem>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {getShipmentType(shipment.shipment_type)}
              </Typography>
            </SInfoItem>
            <SInfoItem>
              <OfferExpiration offerOptions={offerOptions} />
            </SInfoItem>
            {offerOptions?.offered_by && (
              <SInfoItem>
                <div className='d-flex align-items-center gap-2'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Offered By
                  </Typography>
                  <UserInfo
                    size='30px'
                    data={{
                      ...offerOptions.offered_by,
                      name: `${offerOptions.offered_by.first_name} ${offerOptions.offered_by.last_name}`,
                      image: offerOptions.offered_by.profile_logo,
                      user_type: 'staff',
                    }}
                  />
                </div>
              </SInfoItem>
            )}
          </div>
        </div>
        <RowActions
          shipment={shipment}
          onRevertAndResend={onRevertAndResend}
          onRevertAll={onRevertAll}
          onCancelShipment={onCancelShipment}
          onViewNotes={onViewNotes}
          onExtendExpiration={onExtendExpiration}
        />
      </div>
      <div className='d-flex align-items-center mt-3'>
        <Typography variant='b2' style={{ color: palette.indigo500 }} className='nowrap'>
          Offer Set Options <ArrowRight fill={palette.indigo500} className='ms-2' />
        </Typography>
        <SInfoItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Expire Shipment Offer After {expire_shipment_offer} {expire_shipment_offer_unit}(s)
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Retract Carrier Award if Not Confirmed After {retract_carrier_award} {retract_carrier_award_unit}(s)
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Auto Award First Lowest Amount After {auto_award_first_lowest_amount}{' '}
            {!auto_award_first_lowest_amount_unit ? `- Disabled` : `${auto_award_first_lowest_amount_unit}(s)`}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Require Minimum Margin
            {!require_minimum_margin ? ` - No` : ` ${require_minimum_margin_percent}%`}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Auto Repost After Offer is Expired {auto_repost ? `- Yes` : `- No`}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Auto Dispatch After Award is Accepted {auto_dispatch ? `- Yes` : `- No`}
          </Typography>
        </SInfoItem>
      </div>
    </SWrapper>
  );
};

export default Header;
