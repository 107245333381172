import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { FastField, Form, Formik } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import classes from './reserveShipment.module.scss';

const ReserveShipment = ({ isOpen, setIsOpen, onAddBtn }) => {
  const { use } = useTheme();
  const styles = useMemo(() => {
    return {
      styleBody: {
        maxHeight: 600,
        overflowY: 'auto',
        padding: '20px',
      },
      modal: {
        width: 440,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
      labelStyle: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      inputDriver: {
        height: 32,
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      selectStyle: {
        height: 32,
        width: 221,
        borderRadius: '4px 0 0 4px',
        marginLeft: 36,
      },
      dropDownStyles: {
        flexGrow: 0,
        width: 221,
      },
      menuStyle: {
        width: 221,
        marginLeft: 36,
      },
    };
  }, [use, palette]);

  const initialValue = useMemo(() => {
    return {
      reserve_duration: '15',
    };
  }, []);

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik initialValues={{ ...initialValue }} enableReinitialize onSubmit={() => {}}>
        {({ values, resetForm, submitForm, validateForm }) => {
          return (
            <Form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <Typography variant='button2'>Reserve Shipment</Typography>
                </div>

                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: use(palette.gray0, palette.dark800),
                    borderColor: use(palette.gray50, palette.gray700),
                  }}
                >
                  <Typography variant='s2' style={{ color: palette.orange500 }}>
                    <WarningIcon style={{ marginRight: 6 }} />
                    Reserving this shipment does not allow other users to make updates to this shipment.
                  </Typography>

                  <div style={{ marginTop: 20 }}>
                    <FastField
                      value='15'
                      type='radio'
                      name='reserve_duration'
                      component={(props) => (
                        <CustomRadioButton {...props}>
                          <Typography variant='s2' style={{ marginLeft: 8 }}>
                            15 minutes
                          </Typography>
                        </CustomRadioButton>
                      )}
                    />
                  </div>
                  <div>
                    <FastField
                      value='30'
                      type='radio'
                      name='reserve_duration'
                      component={(props) => (
                        <CustomRadioButton {...props}>
                          <Typography variant='s2' style={{ marginLeft: 8 }}>
                            30 minutes
                          </Typography>
                        </CustomRadioButton>
                      )}
                    />
                  </div>
                  <div>
                    <FastField
                      value='1'
                      type='radio'
                      name='reserve_duration'
                      component={(props) => (
                        <CustomRadioButton {...props}>
                          <Typography variant='s2' style={{ marginLeft: 8 }}>
                            1 hour
                          </Typography>
                        </CustomRadioButton>
                      )}
                    />
                  </div>
                  <div>
                    <FastField
                      value='always'
                      type='radio'
                      name='reserve_duration'
                      component={(props) => (
                        <CustomRadioButton {...props}>
                          <Typography variant='s2' style={{ marginLeft: 8 }}>
                            Until Shipment is Dispatched
                          </Typography>
                        </CustomRadioButton>
                      )}
                    />
                  </div>
                </div>

                {/* // footer */}
                <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <div
                    className={classes.modalFooterLane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Reserve'
                      onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ReserveShipment;
