import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import DisputedActions from 'pages/Payroll/shared/DisputedActions';
import NoRecords from 'pages/Payroll/Settlement/components/NoRecords';
import DeclineReason from 'pages/Payroll/Settlement/components/DeclineReason';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { SInput, STable, SWrapper } from '../Tables.styles';

const Commissions = ({ commissions, updateCommissions, settlement }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [commissionToDecline, setCommissionToDecline] = useState(null);

  const onMouseEnter = (row) => {
    setActionsVisible(row.id);
    // const newCommissions = commissions.map((item) =>
    //   item.id === row.id ? { ...item, edit: true } : { ...item, edit: false }
    // );
    // updateCommissions(newCommissions);
  };

  const onMouseLeave = () => {
    setActionsVisible(null);
    // const newCommissions = commissions.map((item) => (item.id === row.id ? { ...item, edit: false } : item));
    // updateCommissions(newCommissions);
  };

  const onAmountChange = (id, value) => {
    const newCommissions = commissions.map((item) => (item.id === id ? { ...item, pay_amount: value } : item));
    updateCommissions(newCommissions);
  };

  const onDecline = async (reason) => {
    const newCommissions = commissions.map((item) =>
      item.id === commissionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
    );
    updateCommissions(newCommissions);
    setCommissionToDecline(null);
  };

  const onApprove = (commissions) => {
    const newCommissions = commissions.map((item) =>
      item.id === commissions.id ? { ...item, dispute_approved: true } : item
    );
    updateCommissions(newCommissions);
  };

  return (
    <SWrapper>
      <Typography variant='h3'>Shipment Commission</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>SHIPMENT ID</th>
            <th>PAY TYPE</th>
            <th>SHIPMENT GROSS</th>
            <th>RATE</th>
            <th>AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {commissions.map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
              >
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {item.billing?.shipment_id}
                  </Typography>
                </td>
                <td>
                  {item.pay_type === 1 && <Chip label='Flat Per Shipment' />}
                  {item.pay_type === 2 && <Chip label='% of Shipment Gross' />}
                  {item.pay_type === 3 && <Chip label='% of Shipment After Driver Pay' />}
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {currency}
                    {formatNumber(item.billing?.total_amount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {item.pay_type === 1 ? `${currency}${formatNumber(item.pay_rate)}` : `${Number(item.pay_rate)}%`}
                  </Typography>
                </td>
                <td>
                  {item.edit ? (
                    <SInput
                      type='number'
                      value={item.pay_amount || ''}
                      onChange={(e) => onAmountChange(item.id, e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                    />
                  ) : (
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {currency}
                      {formatNumber(item.pay_amount)}
                    </Typography>
                  )}
                </td>
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''} ${
                    item.dispute_approved ? 'dispute-approved-actions' : ''
                  }`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setCommissionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </STable>
      {!commissions.length && <NoRecords />}
      <DeclineReason open={!!commissionToDecline} onClose={() => setCommissionToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default Commissions;
