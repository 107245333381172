import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0 48px;
  min-height: 300px;
  position: relative;

  .setting-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .absence-input {
    width: 100px;
    color: ${palette.gray200};
  }

  .staff-attendance-section {
    margin-top: 40px;
  }
`;

export const SInputLabel = styled.div`
  color: ${palette.gray500};
  border: 1px solid ${palette.gray200};
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 0 0 1px rgb(104 113 130 / 16%);
  border-left: none;
  border-radius: 0 6px 6px 0;
  padding: 5px 10px;
`;

export const SCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;
