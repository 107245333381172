import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import DoughnutChart from 'pages/Dashboard/components/Charts/DoughnutChart';
import { getDriverExperience } from 'Api/AccidentsAndCrashes';
import { SBoxWrapper } from '../../StatsSection.styles';

const labels = {
  0: 'Less than 3 months',
  1: '3 - 12 months',
  2: '1 - 2 years',
  3: '3 - 5 years',
  4: '6 - 10 years',
  5: '10+ years',
};

export const DriverExperience = ({ statsRefreshIndex }) => {
  const [driverExperience, setDriverExperience] = useState({});
  const [loading, setLoading] = useState(false);

  const getChartData = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverExperience();
      setDriverExperience(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, [statsRefreshIndex]);

  const datasets = [
    {
      data: driverExperience?.driver_experience?.map((factor) => factor.total_count),
      backgroundColor: [
        palette.indigo400,
        palette.green400,
        palette.red400,
        palette.orange400,
        palette.amber300,
        palette.gray400,
      ],
      borderColor: [
        palette.indigo400,
        palette.green400,
        palette.red400,
        palette.orange400,
        palette.amber300,
        palette.gray400,
      ],
      hoverBackgroundColor: [
        palette.indigo500,
        palette.green500,
        palette.red500,
        palette.orange500,
        palette.amber400,
        palette.gray500,
      ],
      hoverBorderColor: [
        palette.indigo500,
        palette.green500,
        palette.red500,
        palette.orange500,
        palette.amber400,
        palette.gray500,
      ],
      borderWidth: 1,
    },
  ];

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={driverExperience?.total_accidents}
        header={
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2'>DRIVER EXPERIENCE</Typography>
            <Typography variant='c3' style={{ color: palette.gray700 }}>
              HOW LONG DRIVER WAS EXPERIENCED
            </Typography>
          </div>
        }
      />
    </SBoxWrapper>
  );
};
