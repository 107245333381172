import { paymentMethods } from '../../../../Accounting/Payables/components/MarkPaid/MarkPaid.data';

export const getInitialValues = (settlement) => {
  return {
    paid_by_account: null,
    reference: '',
    paid_amount: settlement.amount ? Number(String(settlement.amount).split(',').join('.')).toFixed(2) : '',
    payment_method: settlement.payment_method
      ? paymentMethods.find((i) => i.value === settlement.payment_method) || null
      : null,
  };
};
