import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  flex-shrink: 0;
  min-width: 420px;
  border-right: 1px solid ${palette.gray100};
  padding: 24px 20px 0 0;
`;

export const SCardsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
`;
