import moment from 'moment';
import { equipmentTypeText } from 'components/TableShipments/helpers/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { DATE_FORMAT, getAlphabet } from 'utils/constants';
import { getEquipmentRequiredTitle, getStopName } from 'utils/helpers';

const getCoordsX = (index) => {
  switch (index) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 8:
    case 10:
      return 8;
    case 1:
    case 3:
    case 5:
    case 7:
    case 9:
    case 11:
      return 114;
    default:
  }
};

const getCoordsY = (index) => {
  switch (index) {
    case 0:
    case 1:
      return 62;
    case 2:
    case 3:
      return 100;
    case 4:
    case 5:
      return 134;
    case 6:
    case 7:
      return 168;
    case 8:
    case 9:
      return 202;
    default:
  }
};

export const generateShipmentDetails = (doc, pageWidth, shippersAndConsignees, vehicle_id, company) => {
  let end;
  const { date_time_format_settings } = company || {};
  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];

  [...(shippersAndConsignees || [])]?.forEach((stop, index, array) => {
    doc.setFontSize(8);
    doc.setTextColor(getStopName(stop.stop_point_type).color);
    doc.setFont('Inter', 'normal', 700);
    doc.text(
      `STOP ${getAlphabet(index)}${getStopName(stop?.stop_point_type).type}`,
      getCoordsX(index),
      getCoordsY(index) + 4
    );

    // doc.setFontSize(10);
    // doc.setTextColor('#21467b');
    // doc.setFont('Inter', 'normal', 700);
    // doc.text(`${stop.type}`, getCoordsX(index), getCoordsY(index) + 4);

    doc.setFontSize(8);
    doc.setTextColor('#000000');
    doc.setFont('Inter', 'normal', 400);
    doc.text(`${stop.stop_point.location_name}`, getCoordsX(index), getCoordsY(index) + 8);
    doc.text(
      `${stop.stop_point.address1}${stop.stop_point.address2 ? `, ${stop.stop_point.address2}` : ''}`,
      getCoordsX(index),
      getCoordsY(index) + 12
    );
    doc.text(
      `${stop.stop_point.city?.name}, ${stop.stop_point.state?.short_name} ${stop.stop_point.zipcode}`,
      getCoordsX(index),
      getCoordsY(index) + 16
    );

    doc.setFont('Inter', 'normal', 700);
    doc.text(`Schedule Date Type:`, getCoordsX(index), getCoordsY(index) + 20);
    doc.text(`Schedule Date Time:`, getCoordsX(index), getCoordsY(index) + 24);
    if (index === 0) {
      doc.text(`Equipment Required:`, getCoordsX(index), getCoordsY(index) + 28);
      doc.text(`Equipment Action:`, getCoordsX(index), getCoordsY(index) + 32);
    } else {
      doc.text(`Equipment Action:`, getCoordsX(index), getCoordsY(index) + 28);
    }

    doc.setFont('Inter', 'normal', 400);
    doc.text(
      `${SCHEDULED_DATE_TYPE_OBJ?.[stop?.scheduled_type]?.label}`,
      getCoordsX(index) + 30,
      getCoordsY(index) + 20
    );
    doc.text(
      `${moment(stop?.scheduled_date).format(dateFormat)}, ${stop?.from ? `${stop?.from}` : ''} ${
        stop?.to && [1, 3].includes(Number(stop?.scheduled_type)) ? ` to ${stop?.to}` : ''
      } `,
      getCoordsX(index) + 30,
      getCoordsY(index) + 24
    );
    if (index === 0) {
      doc.text(
        `${stop?.equipment_type_length_value?.length || ''} ${
          getEquipmentRequiredTitle(stop?.equipment_type_data)?.title
        }`,
        getCoordsX(index) + 30,
        getCoordsY(index) + 28
      );
      doc.text(
        `${equipmentTypeText({
          type: stop?.stop_point_type,
          action: stop?.equipment_action,
          equipment: { equipment_id: vehicle_id },
          equipment_tbd: !!vehicle_id,
        })} `,
        getCoordsX(index) + 28,
        getCoordsY(index) + 32
      );
    } else {
      doc.text(
        `${equipmentTypeText({
          type: stop?.stop_point_type,
          action: stop?.equipment_action,
          equipment: { equipment_id: vehicle_id },
          equipment_tbd: !!vehicle_id,
        })} `,
        getCoordsX(index) + 28,
        getCoordsY(index) + 28
      );
    }

    if (index === array.length - 1) {
      if (array.length > 4) {
        doc.addPage();
        end = 20;
      } else {
        end = getCoordsY(index) + 32;
      }
    }
  });

  return end;
};
