import React from 'react';
import HeaderStar from 'components/HeaderStar';
import ShipmentsTable from './ShipmentsTable';
import { SHeaderWrapper, SPageWrapper } from './OfferedShipments.styles';

const OfferedShipments = () => {
  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Offered Shipments' />
      </SHeaderWrapper>
      <ShipmentsTable />
    </SPageWrapper>
  );
};

export default OfferedShipments;
