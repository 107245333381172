import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  .refund-icon {
    transform: rotate(180deg);
  }
`;

export const SDetailItems = styled.div`
  margin-top: 40px;

  .items-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: max-content;
  }
`;

export const SDetailItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 0;

  & > :first-child {
    min-width: 200px;
    max-width: 240px;
    flex: 0 0 20%;
  }
`;

export const SBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-left: none;
  padding: 12px 16px 8px;

  :first-child {
    padding-left: 2px;
  }

  :last-child {
    padding-right: 2px;
    border-right: none;
  }
`;
