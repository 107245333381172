import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SAddressFields, SCompanyInfo, SHeader } from '../CarrierContactBook.styles';

const Header = ({ company }) => {
  const { company_name, address1, city, state, phone_number, logo, company_email, zipcode } = company || {};

  return (
    <SHeader>
      <SCompanyInfo>
        <div className='logo-wrapper'>{logo && <img src={logo} alt='Company logo' height={48} />}</div>
        <SAddressFields>
          <Typography variant='s2'>{company_name}</Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {address1}
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {city?.name}, {state?.short_name || state?.name} {zipcode}
          </Typography>
          <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {formatPhoneNumberIntl(phone_number)}
            </Typography>
          </a>
          <a href={`mailto:${company_email}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {company_email}
            </Typography>
          </a>
        </SAddressFields>
      </SCompanyInfo>
    </SHeader>
  );
};

export default Header;
