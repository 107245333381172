import React, { useState } from 'react';
import moment from 'moment';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SCustomModal } from 'pages/Payroll/Settlement/Settlements.styles';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import DatePicker from 'common/DatePicker';
import { checkoutTrailer } from 'Api/DetentionShipment';
import { Typography } from 'components/Typography';

const CheckoutTrailer = ({ open, onClose, onSuccess, data }) => {
  const localData = JSON.parse(localStorage.getItem('user'));
  const timeZone = JSON.parse(localData?.customer?.timezone);
  const dateFormatted = timeZone.zone_name.replace(/ - /g, '/');
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const checkoutDroppedTrailer = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      const date = moment(values.checkout_date).format('YYYY-MM-DD HH:mm:ss');
      formData.append('checkout_date', moment.tz(date, dateFormatted).utc().format('YYYY-MM-DD HH:mm:ss'));

      // data?.equipment?.id for detention page, data?.equipment_id for equipment profile
      await checkoutTrailer(data?.equipment?.id || data?.equipment_id, formData);
      showToaster({ type: 'success', message: 'Trailer has been successfully checked out!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: { checkout_date: new Date(moment().tz(dateFormatted).format('YYYY-MM-DD HH:mm:ss')) },
    onSubmit: checkoutDroppedTrailer,
  });

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Checkout Trailer'
      $maxWidth='470px'
      $minWidth='470px'
      $bgColor={palette.gray0}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Proceed',
          className: 'modal-submit-btn',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <form>
        <Typography variant='s2' as='p' style={{ color: use(palette.gray700, palette.gray50), marginBottom: '14px' }}>
          Are you sure you want to stop detention and set checkout date?
        </Typography>
        <DatePicker
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={10}
          name='checkout_date'
          value={values.checkout_date}
          minDate={new Date(data?.arrived_date)}
          dateFormat='MM/dd/yyyy HH:mm:ss'
          showTimeInput
          onChange={(val) => {
            handleChange('checkout_date', val);
          }}
        />
      </form>
    </SCustomModal>
  );
};

export default CheckoutTrailer;
