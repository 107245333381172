import { string, object } from 'yup';

export const validationSchema = object({
  invoice_pay_term: string().required('Invoice Pay Terms is required'),
  customTerm: string().when('invoice_pay_term', {
    is: 'custom',
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  net_days: string().when(['invoice_pay_term'], {
    is: 'custom',
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
