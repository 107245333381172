import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -31)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
  allTimeStart: null,
  allTimeEnd: null,
  startOfNextYear: new Date(new Date().getFullYear() + 1, 0, 1),
  endOfNextYear: new Date(new Date().getFullYear() + 1, 11, 31, 23, 59, 59, 999),
};

export const liveOptions = [
  {
    label: 'Live',
    range: () => {
      return {
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
        label: 'Live',
      };
    },
  },
  {
    label: 'Yesterday',
    range: () => {
      return {
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
        label: 'Yesterday',
      };
    },
  },
  {
    label: 'Today',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Today',
      };
    },
  },
  {
    label: 'This Week',
    range: () => {
      return {
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        label: 'This Week',
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
        label: 'Last 7 Days',
      };
    },
  },
  {
    label: 'This Month',
    range: () => {
      return {
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        label: 'This Month',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
        label: 'Last 30 Days',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: defineds.startOfYear,
        endDate: defineds.endOfToday,
        label: 'This Year',
      };
    },
  },
  {
    label: 'All Time',
    range: () => {
      return {
        startDate: defineds.allTimeStart,
        endDate: defineds.allTimeEnd,
        label: 'All Time',
      };
    },
  },
  {
    label: 'Custom Range',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Custom Range',
      };
    },
  },
];

export const fullOptions = [
  {
    label: 'Today',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Today',
      };
    },
  },
  {
    label: 'Yesterday',
    range: () => {
      return {
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
        label: 'Yesterday',
      };
    },
  },
  {
    label: 'This Week',
    range: () => {
      return {
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        label: 'This Week',
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
        label: 'Last 7 Days',
      };
    },
  },
  {
    label: 'This Month',
    range: () => {
      return {
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        label: 'This Month',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
        label: 'Last 30 Days',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: defineds.startOfYear,
        endDate: defineds.endOfToday,
        label: 'This Year',
      };
    },
  },
  {
    label: 'All Time',
    range: () => {
      return {
        startDate: defineds.allTimeStart,
        endDate: defineds.allTimeEnd,
        label: 'All Time',
      };
    },
  },
];

export const sideBarAllTime = [
  {
    label: 'All Time',
    range: () => {
      return {
        startDate: defineds.allTimeStart,
        endDate: defineds.allTimeEnd,
        label: 'All Time',
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
        label: 'Last 7 Days',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
        label: 'Last 30 Days',
      };
    },
  },
  {
    label: 'This Month',
    range: () => {
      return {
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        label: 'This Month',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
        label: 'This Year',
      };
    },
  },
  {
    label: 'Last Year',
    range: () => {
      return {
        startDate: defineds.startOflastYear,
        endDate: defineds.endOflastYear,
        label: 'Last Year',
      };
    },
  },
  {
    label: 'Next Year',
    range: () => {
      return {
        startDate: defineds.startOfNextYear,
        endDate: defineds.endOfNextYear,
        label: 'Next Year',
      };
    },
  },
  {
    label: 'Custom Range',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Custom Range',
      };
    },
  },
];

export const sideBar = [
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
        label: 'Last 7 Days',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
        label: 'Last 30 Days',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
        label: 'This Year',
      };
    },
  },
  {
    label: 'Last Year',
    range: () => {
      return {
        startDate: defineds.startOflastYear,
        endDate: defineds.endOflastYear,
        label: 'Last Year',
      };
    },
  },
  {
    label: 'Next Year',
    range: () => {
      return {
        startDate: defineds.startOfNextYear,
        endDate: defineds.endOfNextYear,
        label: 'Next Year',
      };
    },
  },
  {
    label: 'Custom Range',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Custom Range',
      };
    },
  },
];

export const pastDates = [
  {
    label: 'All Time',
    range: () => {
      return {
        startDate: defineds.allTimeStart,
        endDate: defineds.allTimeEnd,
        label: 'All Time',
      };
    },
  },
  {
    label: 'Today',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Today',
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
        label: 'Last 7 Days',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
        label: 'Last 30 Days',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
        label: 'This Year',
      };
    },
  },
  {
    label: 'Last Year',
    range: () => {
      return {
        startDate: defineds.startOflastYear,
        endDate: defineds.endOflastYear,
        label: 'Last Year',
      };
    },
  },
  {
    label: 'Custom Range',
    range: () => {
      return {
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: 'Custom Range',
      };
    },
  },
];
