import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { DepartmentsEmail } from 'Api/Staff';
import CustomSelect from '../CustomSelect/CustomSelect';
import './UpdateDepartmentStaff.css';

export default function UpdateDetailsModal({ ...props }) {
  const { use } = useTheme();
  const [department, setDepartment] = useState([]);

  const formik = useFormik({
    initialValues: {
      department_id: '',
      department: '',
    },
  });

  useEffect(() => {
    if (props.staffDepartmentInfo) {
      formik.setFieldValue('department_id', props.staffDepartmentInfo?.id);
    }
  }, [props.staffDepartmentInfo]);

  useEffect(() => {
    DepartmentsEmail().then((res) => {
      setDepartment(res?.data || []);
    });
  }, []);
  const AddType = () => {
    props.updateDepartmentProfile(
      formik.values,
      props.onHide,
      props.onSuccess,
      'Department type has been updated successfully'
    );
  };

  return (
    <div className='modal-container'>
      <Modal
        {...props}
        // dialogClassName="modal-dialog-box"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.white, palette.darkborder),
          }}
        >
          <div style={{ marginRight: '20px' }}>
            <p
              className='text-style'
              style={{
                color: use(palette.gray700, palette.gray200),
                marginLeft: '0',
              }}
            >
              Department <span className='required'>*</span>
            </p>
            <div className='d-flex flex-column'>
              <div style={{ marginRight: '20px', width: '100%' }}>
                <div className='custom-select-wrap-contain departmentModal'>
                  <CustomSelect
                    defaultValue='Select Department'
                    options={department?.map((item) => item)} // deaprtment will be change
                    onChange={(value) => {
                      const getdepart = department.find((operator) => +operator.id === +value);
                      formik.setFieldValue('department_id', value);

                      formik.setFieldValue('department', {
                        department_name: getdepart?.department_name,
                        department_email: getdepart?.department_email,
                        id: getdepart?.id,
                      });
                    }}
                    styles={{
                      borderBottomLeftRadius: '0px',
                      borderTopLeftRadius: '0px',
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.department_id}
                    id='department_id'
                    name='department_id'
                    patchProperty='id'
                    propertyName='department_name'
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                className='next-step'
                disabled={!formik.values.department_id || !Number(formik.values.department_id)}
                type='submit'
                onClick={AddType}
              >
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
