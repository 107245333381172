import React from 'react';
import HeaderStar from 'components/HeaderStar';
import HardwareList from './PurchaseHardware/HardwareList';

const Hardware = () => {
  return (
    <div className='equipment-table-header mt-3'>
      <div className='d-flex justify-content-between mb-3'>
        <HeaderStar title='Web Store' />
      </div>
      <HardwareList />
    </div>
  );
};

export default Hardware;
