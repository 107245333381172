import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { TableShipmentsContext } from 'context/tableContext';
import { palette } from 'utils/constants';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { getCarriers } from 'Api/Carriers';
import { getAllTrailers, getAllVehicles } from 'Api/Map';
import { getDrivers } from 'Api/EquipmentProfile';
import Chip from 'common/Chip';
import PopoverSettings from 'components/PopoverSettings/PopoverSettings';

import TableHeaderFilterByShow from 'components/TablePlaner/helpers/TableHeaderFilterByShow';
import { ALL_STATUES, STATUS_FILTER_DATA_SHIPMENT } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomDatePicker from 'components/CustomDatePicker';

const ShipmentsFilter = () => {
  const { use } = useTheme();
  const hasBrokerage = useHasBrokerage();
  const { billOfLadingTitle } = useSelector((state) => state.root);
  const [show, setShow] = useState(false);
  const [pickUpDateRange, setPickUpDateRange] = useState([null, null]);
  const [deliveryDateRange, setDeliveryDateRange] = useState([null, null]);

  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [trailerLoading, setTrailerLoading] = useState(false);
  const [driverLoading, setDriverLoading] = useState(false);

  const [carriers, setCarriers] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [trailerData, setTrailerData] = useState([]);
  const [driverData, setDriverData] = useState([]);

  const initialStyle = useMemo(() => {
    return {
      date: {
        minWidth: 220,
        margin: 0,
        height: 32,
        transform: 'translateY(1px)',
        borderRadius: 4,
        justifyContent: 'flex-start',
        fontSize: 16,
      },
      inputLabel: {
        display: 'flex',
      },
      radius: {
        background: '#f7f9fc',
        height: '100%',
        whiteSpace: 'nowrap',
        fontSize: 14,
        color: '#687182',
        padding: '6px 6px',
        lineHeight: '20px',
        borderRadius: '0px 6px 6px 0px',
        boxShadow: '0 1px 2px rgb(0 0 0 / 6%), 0 0 0 1px rgb(104 113 130 / 16%)',
      },
      dateLabel: {
        marginTop: 0,
      },
    };
  }, [use, palette]);

  const initialValues = {
    shipment_id: '',
    reference: '',
    bill_of_lading_id: '',

    status: [],
    group: [],
    stopPoint: [],

    vehicle: [],
    trailer: [],
    driver: [],
    carrier: [],

    customer: [],
    pickupDateRange: '',
    deliveryDateRange: '',

    origin_city: '',
    origin_state: '',
    origin_zipcode: '',
    origin_miles: 50,

    destination_city: '',
    destination_state: '',
    destination_zipCode: '',
    destination_miles: 50,
  };

  const {
    loading,
    triggerAdvancedClear,
    filter,
    dragItem,
    customers,
    getSettings,
    updateFilter,
    stopPoints,
    switchRadioButtons,
    multiSelectOptions,
    onsStTableColumn,
    onChangeOrder,
    onSetSwitchRadioButtons,
    tableColumn,
    groups,
    onReset,
    statuses,
    setGroups,
    equipments,
    showHeaderFilters,
    setStatuses,
    setEquipments,
    disabledFilters,
    filterByGroupsData,
    onDeleteManyDataItem,
    localFilterCheckboxes,
    onDeleteStopPointItem,
    onDeleteLocationsItems,
    onApplyFilterCheckboxes,
    setLocalFilterCheckboxes,
    filterByEquipmentTypeData,
  } = useContext(TableShipmentsContext);

  const handleAdvancedSearch = (values) => {
    onApplyFilterCheckboxes({
      checkboxes: {
        status: values.status,
        groups: values.group,
        equipment_types: [],
        customer: values.customer,
        stop_point: values.stopPoint,
        vehicle: values.vehicle,
        trailer: values.trailer,
        driver: values.driver,
        carrier: values.carrier,
        shipment_id: values.shipment_id ? [{ label: values.shipment_id, value: values.shipment_id }] : undefined,
        reference_id: values.reference ? [{ label: values.reference, value: values.reference }] : undefined,
        bill_of_lading_id: values.bill_of_lading_id
          ? [{ label: values.bill_of_lading_id, value: values.bill_of_lading_id }]
          : undefined,
      },
      shipment_id: values.shipment_id ? [values.shipment_id] : undefined,
      reference_id: values.reference ? [values.reference] : undefined,
      bill_of_lading_id: values.bill_of_lading_id ? [values.bill_of_lading_id] : undefined,
      customers: values.customer.map((item) => item.id),
      status: values.status.map((item) => item.id),
      vehicles: values.vehicle.map((item) => item.id),
      trailers: values.trailer.map((item) => item.id),
      drivers: values.driver.map((item) => item.id),
      groups: values.group.map((item) => item.id),
      stop_points: values.stopPoint.map((item) => item.id),
      carrier_ids: values.carrier.map((item) => item.id),
      valuePickupDate: valuePickupDate || undefined,
      valueDeliveryDate: valueDeliveryDate || undefined,
      origin: {
        origin_city: values.origin_city,
        origin_state: values.origin_state,
        origin_zipcode: values.origin_zipcode,
        origin_miles: values.origin_miles,
      },
      destination: {
        destination_city: values.destination_city,
        destination_state: values.destination_state,
        destination_zipCode: values.destination_zipCode,
        destination_miles: values.destination_miles,
      },
    });
  };

  const form = useForm({
    initialValues,
    onSubmit: handleAdvancedSearch,
  });
  const { values, handleChange, resetForm } = form;

  const GetDrivers = async () => {
    try {
      setDriverLoading(true);
      const drivers = await getDrivers();
      setDriverData(drivers.data);
    } catch (e) {
      // Do nothing
    } finally {
      setDriverLoading(false);
    }
  };

  const GetAllVehicles = async () => {
    try {
      setVehicleLoading(true);
      const vehicles = await getAllVehicles(null, null, null, null);
      setVehicleData(vehicles.data);
    } catch (e) {
      // Do nothing
    } finally {
      setVehicleLoading(false);
    }
  };
  const GetAllTrailers = async () => {
    try {
      setTrailerLoading(true);
      const trailers = await getAllTrailers(null, null, null, null);
      setTrailerData(trailers.data);
    } catch (e) {
      // Do nothing
    } finally {
      setTrailerLoading(false);
    }
  };

  const getCarriersList = async () => {
    try {
      const { data } = await getCarriers();
      setCarriers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(async () => {
    if (hasBrokerage) {
      getCarriersList();
    }
    GetDrivers().catch();
    GetAllVehicles().catch();
    GetAllTrailers().catch();
  }, []);

  const onChangePickUpRange = (dates) => {
    setPickUpDateRange(dates);
  };

  const onChangeDeliveryRange = (dates) => {
    setDeliveryDateRange(dates);
  };

  const clearSearchValues = () => {
    setPickUpDateRange([null, null]);
    setDeliveryDateRange([null, null]);
    resetForm();
    onReset();
  };

  useEffect(() => {
    if (triggerAdvancedClear) {
      setPickUpDateRange([null, null]);
      setDeliveryDateRange([null, null]);
      resetForm();
    }
  }, [triggerAdvancedClear]);

  const valuePickupDate = useMemo(() => {
    if (!pickUpDateRange[0]) return '';
    const fromValue = moment(pickUpDateRange[0]).format('MM/DD/YYYY');
    if (!pickUpDateRange[1]) return fromValue;
    const toValue = moment(pickUpDateRange[1]).format('MM/DD/YYYY');

    return `${fromValue} - ${toValue}`;
  }, [pickUpDateRange]);

  const valueDeliveryDate = useMemo(() => {
    const fromValue = deliveryDateRange[0] && moment(deliveryDateRange[0]).format('MM/DD/YYYY');
    const toValue = deliveryDateRange[1] && moment(deliveryDateRange[1]).format('MM/DD/YYYY');
    const isValidTo = !moment(toValue).isValid();

    if (fromValue && isValidTo) {
      return fromValue;
    }
    return fromValue && toValue ? `${fromValue} - ${toValue}` : '';
  }, [deliveryDateRange]);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettings
          is='shipment-history'
          filter={filter}
          dragItem={dragItem}
          typeDataJson='shipment-history'
          tableColumn={tableColumn}
          getSettings={getSettings}
          updateFilter={updateFilter}
          onChangeOrder={onChangeOrder}
          onsStTableColumn={onsStTableColumn}
          switchRadioButtons={switchRadioButtons}
          setShowPopover={(isOpen) => setShow(isOpen)}
          onSetSwitchRadioButtons={onSetSwitchRadioButtons}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popOverContent}
          rootClose={show}
          onToggle={setShow}
          show={show}
          defaultShow={false}
          delay={0}
        >
          <div className='top_popover' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
          </div>
        </OverlayTrigger>
      </div>

      {
        <div className='mb-3'>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12} container columnSpacing={5} rowSpacing={3}>
              <Grid item xs={2}>
                <div className='w-100'>
                  <Typography variant='b2'>Shipment ID</Typography>
                  <Input
                    name='shipment_id'
                    placeholder='Shipment ID'
                    onChange={(e) => handleChange(`shipment_id`, e.target.value)}
                    value={values.shipment_id}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.shiftKey === false) {
                        e.preventDefault();
                        form.handleSubmit();
                      }
                    }}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Reference ID</Typography>
                  <Input
                    name='reference'
                    placeholder='Reference ID'
                    onChange={(e) => handleChange(`reference`, e.target.value)}
                    value={values.reference}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.shiftKey === false) {
                        e.preventDefault();
                        form.handleSubmit();
                      }
                    }}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>{billOfLadingTitle} ID</Typography>
                  <Input
                    name='bill_of_lading_id'
                    placeholder={`${billOfLadingTitle} ID`}
                    onChange={(e) => handleChange(`bill_of_lading_id`, e.target.value)}
                    value={values.bill_of_lading_id}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.shiftKey === false) {
                        e.preventDefault();
                        form.handleSubmit();
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className='w-100'>
                  <Typography variant='b2'>Status</Typography>
                  <Autocomplete
                    labelKey='title'
                    multiple
                    limitTags={2}
                    placeholder={values.status.length ? '' : 'Select Status'}
                    options={ALL_STATUES}
                    value={values.status}
                    onChange={(e, val) => handleChange('status', val)}
                    isOptionEqualToValue={(option, value) => option.value === value?.value || option.value === value}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Tags</Typography>
                  <Autocomplete
                    labelKey='title'
                    multiple
                    limitTags={2}
                    options={filterByGroupsData}
                    placeholder={values.group.length ? ' ' : 'Select Tags'}
                    value={values.group}
                    onChange={(e, val) => handleChange('group', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Stop Point</Typography>
                  <Autocomplete
                    labelKey='label'
                    multiple
                    limitTags={2}
                    options={stopPoints}
                    placeholder={values.stopPoint.length ? '' : 'Select Stop Point'}
                    value={values.stopPoint}
                    onChange={(e, val) => handleChange('stopPoint', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className='w-100'>
                  <Typography variant='b2'>Vehicle ID</Typography>
                  <Autocomplete
                    labelKey='equipment_id'
                    multiple
                    limitTags={2}
                    loading={vehicleLoading}
                    placeholder={values.vehicle.length ? '' : 'Select Vehicle'}
                    options={vehicleData}
                    value={values.vehicle}
                    getOptionLabel={(option) => {
                      return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                    }}
                    onChange={(e, val) => handleChange('vehicle', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Trailer ID</Typography>
                  <Autocomplete
                    labelKey='equipment_id'
                    multiple
                    placeholder={values.trailer.length ? '' : 'Select Trailer'}
                    limitTags={2}
                    loading={trailerLoading}
                    options={trailerData}
                    value={values.trailer}
                    getOptionLabel={(option) => {
                      return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                    }}
                    onChange={(e, val) => handleChange('trailer', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Driver</Typography>
                  <Autocomplete
                    multiple
                    loading={driverLoading}
                    placeholder={values.driver.length ? '' : 'Select Driver'}
                    limitTags={2}
                    getOptionLabel={(option) => {
                      return option ? `${option.fname} ${option.lname}` : '';
                    }}
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          size='medium'
                          label={`${option.fname} ${option.lname}`}
                          labelColor={palette.indigo500}
                          fontWeight={500}
                          bgColor={palette.indigo0}
                          deleteIcon={<DeleteIcon />}
                          height='20px'
                          limitTags={2}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    options={driverData}
                    value={values.driver}
                    onChange={(e, val) => handleChange('driver', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className='w-100 '>
                  <Typography variant='b2'>Customer</Typography>
                  <Autocomplete
                    labelKey='label'
                    multiple
                    limitTags={2}
                    options={customers}
                    placeholder={values.customer.length ? '' : 'Select Customer'}
                    value={values.customer}
                    onChange={(e, val) => handleChange('customer', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
                <div className='w-100 d-flex flex-column mt-2'>
                  <Typography variant='b2'>Pick Up Date Range</Typography>
                  <CustomDatePicker
                    selectsRange
                    renderStart={false}
                    valueToShow={valuePickupDate}
                    style={initialStyle.date}
                    shouldCloseOnSelect={false}
                    endDate={pickUpDateRange[1]}
                    startDate={pickUpDateRange[0]}
                    labelStyle={initialStyle.dateLabel}
                    placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                    onChange={(dates) => onChangePickUpRange(dates)}
                  />
                  <div className='w-100 d-flex flex-column mt-3'>
                    <Typography variant='b2'>Delivery Date Range</Typography>
                    <CustomDatePicker
                      selectsRange
                      renderStart={false}
                      valueToShow={valueDeliveryDate}
                      style={initialStyle.date}
                      shouldCloseOnSelect={false}
                      endDate={deliveryDateRange[1]}
                      startDate={deliveryDateRange[0]}
                      labelStyle={initialStyle.dateLabel}
                      placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                      onChange={(dates) => onChangeDeliveryRange(dates)}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className='w-100'>
                  <Typography variant='b2'>Origin</Typography>
                  <div className='d-flex gap-2'>
                    <Input
                      name='origin_city'
                      style={{ width: '100%' }}
                      placeholder='City'
                      onChange={(e) => handleChange(`origin_city`, e.target.value)}
                      value={values.origin_city}
                    />
                    <Input
                      name='origin_state'
                      style={{ width: 80 }}
                      placeholder='State'
                      onChange={(e) => handleChange(`origin_state`, e.target.value)}
                      value={values.origin_state}
                    />
                    <div style={initialStyle.inputLabel}>
                      <Input
                        name='origin_zipcode'
                        style={{ width: 90, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        placeholder='Zip Code'
                        onChange={(e) => handleChange(`origin_zipcode`, e.target.value)}
                        value={values.origin_zipcode}
                      />
                      <div style={initialStyle.radius}>Zip Code</div>
                    </div>
                    <div style={initialStyle.inputLabel}>
                      <Input
                        style={{ width: 50, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        name='origin_miles'
                        placeholder='Miles'
                        onChange={(e) => handleChange(`origin_miles`, e.target.value)}
                        value={values.origin_miles}
                      />
                      <div style={initialStyle.radius}>Miles</div>
                    </div>
                  </div>
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Destination</Typography>
                  <div className='d-flex gap-2'>
                    <Input
                      name='destination_city'
                      style={{ width: '100%' }}
                      placeholder='City'
                      onChange={(e) => handleChange(`destination_city`, e.target.value)}
                      value={values.destination_city}
                    />
                    <Input
                      name='destination_state'
                      style={{ width: 80 }}
                      placeholder='State'
                      onChange={(e) => handleChange(`destination_state`, e.target.value)}
                      value={values.destination_state}
                    />
                    <div style={initialStyle.inputLabel}>
                      <Input
                        name='destination_zipCode'
                        style={{ width: 90, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        placeholder='Zip Code'
                        onChange={(e) => handleChange(`destination_zipCode`, e.target.value)}
                        value={values.destination_zipCode}
                      />
                      <div style={initialStyle.radius}>Zip Code</div>
                    </div>
                    <div style={initialStyle.inputLabel}>
                      <Input
                        style={{ width: 50, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        name='destination_miles'
                        placeholder='Miles'
                        onChange={(e) => handleChange(`destination_miles`, e.target.value)}
                        value={values.destination_miles}
                      />
                      <div style={initialStyle.radius}>Miles</div>
                    </div>
                  </div>
                </div>
                {!!hasBrokerage && (
                  <div className='w-100 mt-2'>
                    <div className='mt-2'>
                      <Autocomplete
                        label='Carrier'
                        width='200px'
                        multiple
                        limitTags={2}
                        placeholder={values.carrier?.length ? '' : 'Select Carriers..'}
                        options={carriers}
                        value={values.carrier || []}
                        onChange={(e, val) => handleChange('carrier', val)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        labelProps={{ className: 'mb-0' }}
                      />
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: 2 }} container spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                {/* <Grid item> */}
                {/*  <CustomButton */}
                {/*    type='secondary' */}
                {/*    title='Close' */}
                {/*    className='ms-3' */}
                {/*    styleButton={{ padding: '5px 15px', margin: 0, fontSize: 12 }} */}
                {/*    onClick={() => setAdvancedOpen(false)} */}
                {/*  /> */}
                {/* </Grid> */}
                <Grid item>
                  <CustomButton
                    type='secondary'
                    title='Clear All'
                    className='ms-3'
                    onClick={() => clearSearchValues()}
                    styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    type='primary'
                    onClick={form.handleSubmit}
                    title='Search'
                    disabled={loading}
                    leftIcon={
                      loading ? (
                        <CircularProgress
                          style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }}
                        />
                      ) : (
                        <div />
                      )
                    }
                    className='ms-3'
                    styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
      <TableHeaderFilterByShow
        groups={groups}
        type='history'
        onReset={onReset}
        statuses={statuses}
        setGroups={setGroups}
        equipments={equipments}
        data={showHeaderFilters}
        setStatuses={setStatuses}
        setEquipments={setEquipments}
        disabledFilters={disabledFilters}
        multiSelectOptions={multiSelectOptions}
        filterByGroupsData={filterByGroupsData}
        statusData={STATUS_FILTER_DATA_SHIPMENT}
        onDeleteManyDataItem={onDeleteManyDataItem}
        localFilterCheckboxes={localFilterCheckboxes}
        onDeleteStopPointItem={onDeleteStopPointItem}
        onDeleteLocationsItems={onDeleteLocationsItems}
        onApplyFilterCheckboxes={onApplyFilterCheckboxes}
        setLocalFilterCheckboxes={setLocalFilterCheckboxes}
        filterByEquipmentTypeData={filterByEquipmentTypeData}
      />
    </div>
  );
};

export default ShipmentsFilter;
