import styled from 'styled-components';
import { palette } from '../../../../utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  display: flex;
  text-align: center;
  padding: 24px 0 8px 0;
  justify-content: space-between;
  border-bottom: 1px solid #e9edf5;
`;

export const SPrimaryActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  gap: 8px;
`;

export const SActionsArea = styled.div`
  display: flex;
  align-items: center;
`;

export const SShowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isVisible ? '#4F5AED' : '#FFFFFF')};
  border: ${(props) => (props.isVisible ? '1px solid #4F5AED' : '1px solid #e9edf5')};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  :hover {
    border: ${(props) => (props.isVisible ? '1px solid #3944db' : '1px solid #dee0e3')};
    background-color: ${(props) => (props.isVisible ? '#3944db' : '#FFFFFF')};
  }
`;

export const SKey = styled.div`
  display: flex;
`;

export const SCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 9px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #e9edf5;
  transform: translateX(-1px);

  :hover {
    border: 1px solid #dee0e3;
  }

  :active {
    outline: 1px solid lightskyblue;
  }
`;

export const SReset = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 9px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #e9edf5;
  transform: translateX(-1px);
  margin-left: -2px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  :hover {
    border: 1px solid #dee0e3;
  }

  :active {
    outline: 1px solid lightskyblue;
  }
`;

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
  transition: background 0.3s;

  .settings-table-item:nth-child(1) {
    width: 40%;
  }

  .settings-table-item:nth-child(2) {
    width: 30%;
  }

  .settings-table-item:nth-child(3) {
    width: 30%;
  }

  .settings-table-actions-wrapper {
    visibility: hidden;
  }

  :hover {
    background: #f7f9fc;

    .settings-table-actions-wrapper {
      visibility: visible;
    }
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .settings-header-item:nth-child(1) {
    width: 40%;
  }

  .settings-header-item:nth-child(2) {
    width: 30%;
  }

  .settings-header-item:nth-child(3) {
    width: 30%;
  }
`;

export const SInputWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid ${palette.gray50};
  background: #f7f9fc;

  .flex-item:nth-child(1) {
    width: 40%;
  }

  .flex-item:nth-child(2) {
    width: 30%;
  }

  .flex-item:nth-child(3) {
    width: 30%;
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;
