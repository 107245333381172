import moment from 'moment/moment';

export const initialValues = {
  urs_biennial_renewal_date: null,
  urs_biennial_active: false,
  mcs_biennial_update_date: null,
  mcs_biennial_active: false,
  ucr_annual_due_date: null,
  ucr_annual_active: false,
  clearing_house_renewal_date: null,
  clearing_house_active: false,
  scac_code_renewal_date: null,
  scac_code_active: false,
  cvor_certificate_renewal_date: null,
  cvor_certificate_active: false,
};

export const updateComplianceConverter = (values) => {
  const {
    mcs_biennial_update_date,
    mcs_biennial_active,
    ucr_annual_due_date,
    ucr_annual_active,
    urs_biennial_renewal_date,
    urs_biennial_active,
    clearing_house_renewal_date,
    clearing_house_active,
    scac_code_renewal_date,
    scac_code_active,
    cvor_certificate_renewal_date,
    cvor_certificate_active,
  } = values || {};

  return {
    urs_biennial_renewal_date: urs_biennial_renewal_date
      ? moment(urs_biennial_renewal_date).format('MM/DD/YYYY')
      : null,
    urs_biennial_active,
    mcs_biennial_update_date: mcs_biennial_update_date ? moment(mcs_biennial_update_date).format('MM/DD/YYYY') : null,
    mcs_biennial_active,
    ucr_annual_due_date: ucr_annual_due_date ? moment(ucr_annual_due_date).format('MM/DD/YYYY') : null,
    ucr_annual_active,
    clearing_house_renewal_date: clearing_house_renewal_date
      ? moment(clearing_house_renewal_date).format('MM/DD/YYYY')
      : null,
    clearing_house_active,
    scac_code_renewal_date: scac_code_renewal_date ? moment(scac_code_renewal_date).format('MM/DD/YYYY') : null,
    scac_code_active,
    cvor_certificate_renewal_date: cvor_certificate_renewal_date
      ? moment(cvor_certificate_renewal_date).format('MM/DD/YYYY')
      : null,
    cvor_certificate_active,
  };
};
