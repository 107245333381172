import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { ReactComponent as TruckinLogo } from 'assets/icons/logo/truckinLogo.svg';
import './splashScreen.css';

const SplashScreen = ({ steps }) => {
  const [countOfProgress, setCountOfProgess] = useState(0);
  const [stepCount, setstepCount] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      for (let i = 1; i <= 100; i++) {
        setCountOfProgess(i);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (stepCount <= steps?.length) {
      const interval = setInterval(() => {
        setstepCount((counter) => counter + 1);
      }, 3000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [stepCount]);

  return (
    <div className='splash-screen-loading'>
      <div className='splash-logo'>
        <TruckinLogo width={80} height={80} />
      </div>
      <ProgressBar now={countOfProgress} />
      <div>
        <p className='subtitle-s2' style={{ color: '#171C26', marginTop: '8px' }}>
          {steps ? steps[stepCount <= steps?.length ? stepCount : steps.length - 1] : 'Pulling Up Profile..'}
        </p>
      </div>
    </div>
  );
};

export default SplashScreen;
