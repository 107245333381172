import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as TruckinLogo } from 'assets/icons/logo/truckinLogo.svg';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { disconnectQuickbooksService } from 'Api/DriverV2';
import { SDisconnectQuickbooks } from './DisconnectQuickbooks.styles';

const DisconnectQuickbooks = () => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [searchParams] = useSearchParams();
  const realmId = searchParams.get('realmId');
  const { value: userData } = useAuth();

  const navigateOnError = () => {
    if (!userData?.user || !Object.keys(userData?.user || {}).length) {
      navigate('/login', { replace: true });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const disconnectQuickbooks = async (realmId) => {
    if (!realmId) {
      showToaster({ type: 'error', message: 'Something went wrong, please try to disconnect again!' });
      navigateOnError();
      return;
    }

    try {
      await disconnectQuickbooksService({ connected_service_id: 57, realmId });
      window.open('https://www.truckindigital.com/quickbooks-disconnected', '_self');
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong, please try to disconnect again!' });
      navigateOnError();
    }
  };

  useEffect(() => {
    disconnectQuickbooks(realmId);
  }, []);

  return (
    <SDisconnectQuickbooks>
      <div className='loading-logo'>
        <TruckinLogo width={80} height={80} />
      </div>
    </SDisconnectQuickbooks>
  );
};

export default DisconnectQuickbooks;
