import styled from 'styled-components';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin: 28px 0 40px;

  .Table-loader-wrap {
    margin-top: 0;
  }
`;

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-top: 1px solid ${palette.gray50};
  border-bottom: 1px solid ${palette.gray50};

  .settings-header-item:nth-child(1) {
    width: 40%;
  }

  .settings-header-item:nth-child(2) {
    width: 30%;
  }

  .settings-header-item:nth-child(3) {
    width: 30%;
  }
`;

export const STable = styled.table`
  width: 100%;

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 10px 12px;
      white-space: nowrap;
    }

    .settings-gear {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    &.bg-gray {
      background: ${palette.gray0};
    }

    &.disputed {
      background: ${palette.red0};
      border-bottom: none;
    }

    &.dispute-approved {
      background: ${palette.green0};
      border-bottom: none;
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    .action-wrapper-edit {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      visibility: hidden;

      :hover span {
        text-decoration: underline;
      }
    }

    td {
      padding: 12px 10px;
    }

    :hover {
      background: ${palette.gray0};

      .action-buttons {
        visibility: visible;
      }

      .action-wrapper {
        visibility: visible;
      }

      .action-wrapper-edit {
        visibility: visible;
      }
    }
  }

  .total-row {
    td {
      padding: 12px 10px;
    }

    .align-end {
      text-align: end;
    }
  }

  .disputed-actions {
    background: ${palette.red0};

    &.actions-visible {
      background: ${palette.gray0};
    }

    &.dispute-approved-actions {
      background: ${palette.green0};
    }
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background: ${palette.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
  padding: 0 8px;
  width: 184px;
  height: 24px;

  input {
    width: inherit;
  }

  .arrow-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .react-datepicker-wrapper {
    width: 100% !important;

    .custom-date-picker-input {
      width: 100%;
      justify-content: flex-start;
      column-gap: 8px;
      margin: 0;
    }
  }

  .disabled-date-picker {
    background-color: ${palette.gray0} !important;

    span {
      color: ${palette.gray400} !important;
    }
  }

  .react-datepicker__year-dropdown {
    background-color: ${palette.white};

    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }

    .react-datepicker__year-option {
      background-color: ${palette.white};
      padding: 2px 4px;
      font-size: 14px;

      &:hover {
        background-color: ${palette.gray50};
      }

      &.react-datepicker__year-option--selected_year {
        background-color: ${palette.indigo500};
        color: ${palette.white};
      }
    }

    .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation {
      display: none;
    }
  }
`;

export const SInput = styled(Input)`
  input {
    width: ${({ width }) => width || '90px'};
    padding: 2px 12px;
    height: 24px;
  }
`;

export const SAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    .MuiInputBase-root {
      min-height: unset;
      height: 24px;
      max-width: 300px;

      &.MuiOutlinedInput-root {
        padding: 2px 24px 2px 8px;
      }

      .MuiAutocomplete-endAdornment {
        top: calc(50% - 12px);
      }
    }
  }
`;

export const SCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const SRecurringWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;
`;

export const SInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 2px 5px rgba(89, 96, 120, 0.1);
  border-radius: 6px;
  padding: 0 8px;
`;
