import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import Tabs from 'common/Tabs';
import Divider from 'common/Divider';
import HeaderStar from 'components/HeaderStar';
import useDebounce from 'hooks/useDebounce';
import { useAuth } from 'context/auth.context';
import useDateFormat from 'hooks/useDateFormat';
import { getAvailableDrivers, getDriverAvailability } from 'Api/PlannerV2';
import PlannerMatch from './components/PlannerMatch';
import AvailabilityTable from './components/AvailabilityTable';
import AvailabilityFilters from './components/AvailabilityFilters';
import AvailabilityCalendar from './components/AvailabilityCalendar';
import { availabilityDataConverter } from './converters';
import { initialFilters, initialTabs } from './Availability.data';
import { SPageWrapper, SHeaderWrapper } from './Availability.styles';

const Availability = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { value: userData } = useAuth();
  const { convertToCustomerTime } = useDateFormat();
  const [activeTab, setActiveTab] = useState(tab ? initialTabs.find((i) => i.key === tab)?.value || 0 : 0);
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters, groups: userData.user?.groups || [] });
  const [drivers, setDrivers] = useState([]);
  const [tabs, setTabs] = useState(initialTabs);
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const controller = useRef(new AbortController());
  const didMountRef = useRef(false);

  const getPlannerMatchTabCount = async () => {
    try {
      const params = {
        page: 1,
        itemsPerPage: 1,
        start_date: selectedFilters.dateRange[0]
          ? moment(selectedFilters.dateRange[0]).format('YYYY-MM-DD')
          : undefined,
        end_date: selectedFilters.dateRange[1] ? moment(selectedFilters.dateRange[1]).format('YYYY-MM-DD') : undefined,
        query: debouncedSearch || undefined,
        driver_ids: selectedFilters.drivers.map((i) => i.id),
        equipment_ids: selectedFilters.equipment.map((i) => i.id),
        group_ids: selectedFilters.groups.map((i) => i.id),
        city: selectedFilters.city && selectedFilters.state ? selectedFilters.city : undefined,
        state: selectedFilters.city && selectedFilters.state ? selectedFilters.state : undefined,
        zipcode: selectedFilters.zipcode ? selectedFilters.zipcode : undefined,
        miles:
          ((selectedFilters.city && selectedFilters.state) || selectedFilters.zipcode) && selectedFilters.miles
            ? selectedFilters.miles
            : undefined,
        radius: selectedFilters.radius ? selectedFilters.radius : undefined,
        date_span: selectedFilters.date_span ? selectedFilters.date_span : 0,
      };

      const response = await getAvailableDrivers(params, controller?.current?.signal);

      setTabs((prevState) =>
        prevState.map((item) => (item.value === 2 ? { ...item, count: response.total || 0 } : item))
      );
    } catch (e) {
      // Do nothing
    }
  };

  const getAvailabilities = async () => {
    try {
      if (controller?.current) {
        controller.current.abort();
        controller.current = new AbortController();
      }

      setLoading(true);
      const params = {
        start_date: selectedFilters.dateRange[0]
          ? moment(selectedFilters.dateRange[0]).format('YYYY-MM-DD')
          : undefined,
        end_date: selectedFilters.dateRange[1] ? moment(selectedFilters.dateRange[1]).format('YYYY-MM-DD') : undefined,
        query: debouncedSearch || undefined,
        driver_ids: selectedFilters.drivers.map((i) => i.id),
        equipment_ids: selectedFilters.equipment.map((i) => i.id),
        group_ids: selectedFilters.groups.map((i) => i.id),
        city: selectedFilters.city && selectedFilters.state ? selectedFilters.city : undefined,
        state: selectedFilters.city && selectedFilters.state ? selectedFilters.state : undefined,
        zipcode: selectedFilters.zipcode ? selectedFilters.zipcode : undefined,
        miles:
          ((selectedFilters.city && selectedFilters.state) || selectedFilters.zipcode) && selectedFilters.miles
            ? selectedFilters.miles
            : undefined,
      };

      const { data } = await getDriverAvailability(params, controller?.current?.signal);
      setTabs((prevState) => prevState.map((item) => (item.value === 0 ? { ...item, count: data.length } : item)));
      setDrivers(
        availabilityDataConverter(
          data,
          convertToCustomerTime,
          selectedFilters.dateRange,
          activeTab === 0 ? 'list' : 'calendar'
        )
      );
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
    const tab = tabs.find((i) => i.value === newValue);
    searchParams.set('tab', tab.key);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (activeTab === 2 && tabs[2].hasOwnProperty('count')) {
      return;
    }

    getAvailabilities();

    if (!didMountRef.current) {
      getPlannerMatchTabCount();
      didMountRef.current = true;
    }
  }, [selectedFilters, debouncedSearch, activeTab]);

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Availability' />
      </SHeaderWrapper>
      <AvailabilityFilters
        search={search}
        setSearch={setSearch}
        filters={selectedFilters}
        updateFilters={setSelectedFilters}
        tab={activeTab}
      />
      <Tabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      <Divider margin='0 0 8px' />
      <div>
        {activeTab === 0 && (
          <AvailabilityTable
            drivers={drivers}
            setDrivers={setDrivers}
            getAvailabilities={getAvailabilities}
            filters={selectedFilters}
            loading={loading}
          />
        )}
        {activeTab === 1 && <AvailabilityCalendar drivers={drivers} filters={selectedFilters} loading={loading} />}
        {activeTab === 2 && (
          <PlannerMatch
            filters={selectedFilters}
            search={debouncedSearch}
            updateTabCount={(count) =>
              setTabs((prevState) => prevState.map((item) => (item.value === 2 ? { ...item, count } : item)))
            }
          />
        )}
      </div>
    </SPageWrapper>
  );
};

export default Availability;
