import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Modal } from 'react-bootstrap';
import authHeader from 'services/auth-header';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import './reserveEquipment.css';

const UnreservedEquipment = ({
  handleClose,
  unReseverdEqId,
  UnReserveSuccessShow,
  UnReserveSuccessClose,
  onSuccess,
}) => {
  const [id, row_id, title, equipment_id] = unReseverdEqId;
  const API_URL = process.env.REACT_APP_API_URL;
  async function unreserved() {
    const { data } = await axios.get(`${API_URL}/equipment-reserve/${id}`, {
      headers: authHeader(),
    });
    return data;
  }
  const { use } = useTheme();

  const { data, isError, isLoading, error } = useQuery('unreserve', unreserved);
  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }
  const UnReserved = (id, row_id) => {
    axios.delete(`${API_URL}/equipment-reserve/${id}`, { headers: authHeader() }).then((res) => {
      handleClose();
      if (res.status === 200) {
        UnReserveSuccessShow(row_id);
      }
      if (onSuccess) {
        onSuccess();
      }
      setTimeout(() => {
        UnReserveSuccessClose();
      }, 3000);
    });
  };

  return (
    <div
      className='equipment-list-unreserved-equipment'
      style={{ backgroundColor: use(palette.white, palette.dark800), width: '600px' }}
    >
      <Modal.Body className='remove-driver-dailogue-body'>
        <div className='remove-driver-dailogue-wrapper'>
          <h6 className='remove-modal-header' style={{ color: use(palette.dark800, palette.gray200) }}>
            Unreserve Equipment
          </h6>
          {data?.message ? (
            <p className='remove-driver-paragraph' style={{ color: use(palette.dark800, palette.gray200) }}>
              Are you sure you want to unreserve {title} {equipment_id} {data?.message.toString().split('.')[1]} ?
            </p>
          ) : (
            <p className='remove-driver-paragraph' style={{ color: use(palette.dark800, palette.gray200) }}>
              Are you sure you want to unreserve {title} {equipment_id}
              <span> reserved by </span>
              {data?.data?.user?.first_name} {data?.data?.user?.last_name} for next {data?.data?.remaining_time} ?
            </p>
          )}
          <div className='d-flex justify-content-end remove-driver-footer mt-3'>
            <button
              className='unreserve-cancel-button'
              onClick={handleClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            <button className='unreserved-button' type='submit' onClick={() => UnReserved(id, row_id)}>
              Yes, Unreserve
            </button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default UnreservedEquipment;
