import React from 'react';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import ColumnHeader from '../ColumnHeader';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
};

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

const EmptyCell = () => {
  const { use } = useTheme();
  return (
    <Typography variant='b2' style={{ color: use(palette.gray300, palette.gray200) }}>
      N/A
    </Typography>
  );
};

export const useReferralsColumns = ({ sortingQuery, sort }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'full_name',
      title: <ColumnHeader title='NAME' field='full_name' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
          {row.full_name}
        </Typography>
      ),
    },
    {
      field: 'telephone',
      title: <Title title='PHONE OR EMAIL' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.telephone || row.email}
        </Typography>
      ),
    },
    {
      field: 'referred_on',
      title: <ColumnHeader title='REFERRED ON' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (row) =>
        row.created_at ? (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {formatDate(row.created_at)}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'position',
      title: <Title title='POSITION' />,
      render: (row) =>
        row?.job_positing?.job_position_type ? (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.job_positing.job_position_type.name}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'referred_by',
      title: <Title title='REFERRED BY' />,
      render: (row) =>
        row?.referral ? (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.referral.referral_name}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'application_submitted',
      title: <Title title='APPLICATION SUBMITTED' />,
      render: (row) => (
        <div style={{ marginLeft: '48px' }}>
          {row.job_positing ? (
            <Chip size='small' label='Yes' labelColor={palette.green500} bgColor={palette.green0} />
          ) : (
            <Chip size='small' label='No' labelColor={palette.red500} bgColor={palette.red0} />
          )}
        </div>
      ),
    },
  ];
};
