import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import { Typography } from 'components/Typography';
import { formatNumber, palette, VALUE_TYPES } from 'utils/constants';
import { getCommodity, getMeasurementTypes, getQuantityTypes, getWeightTypes } from 'Api/Planner';
import NoRecords from 'common/NoRecords';
import FormRow from './FormRow';
import { initialValues, getCargoTotals } from '../ShipmentCommodity.data';
import { SAddMore, STable } from '../ShipmentCommodity.styles';

const CargoTable = ({
  stopId,
  quoteId,
  isRecurring,
  onCreateSuccess = () => null,
  onUpdateSuccess = () => null,
  onDeleteSuccess = () => null,
  readOnly,
  createMode,
  form,
  hideAddNew,
  equipment,
}) => {
  const { currency, weightUnit, qtyUnit } = useSelector((state) => state.root);
  const [commodityTypes, setCommodityTypes] = useState([]);
  const [weightTypes, setWeightTypes] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);

  const { values, handleChange, setValues } = form;

  const equipmentType = equipment?.length?.length_info
    ? `${equipment.length.length_info.length}${equipment.length.length_info.unit} ${equipment.title}`
    : equipment?.title;
  const equipmentHeightFeet = !!equipment?.length?.height && equipment.length.height / 12;
  const equipmentCapacity =
    !!equipment?.length?.height &&
    !!equipment?.length?.width &&
    !!equipment?.length?.length_info?.length &&
    equipmentHeightFeet * (equipment.length.width / 12) * equipment.length.length_info.length;
  const totalValues = getCargoTotals(values, equipmentHeightFeet);

  const getAdditionalData = async () => {
    try {
      const [{ data: commodity }, { data: measurement }, { data: quantity }, { data: weight }] = await Promise.all([
        getCommodity(),
        getMeasurementTypes(),
        getQuantityTypes(),
        getWeightTypes(),
      ]);
      setCommodityTypes(commodity || []);
      setWeightTypes(weight || []);
      setMeasurementTypes(measurement || []);
      setQuantityTypes(quantity || []);

      if (!stopId) {
        setValues((prevState) =>
          prevState.map((item) => {
            return {
              ...item,
              expected_quantity_type: item.isNew
                ? quantity?.find((el) => el.name.toLowerCase() === qtyUnit) || quantity?.[0] || null
                : item.expected_quantity_type,
              expected_weight_type: item.isNew
                ? weight?.find((el) => el.unit.toLowerCase() === weightUnit) || weight?.[0] || null
                : item.expected_weight_type,
              expected_dimension_unit: item.isNew ? measurement?.[1] || null : item.expected_dimension_unit,
            };
          })
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onCommodityChange = (index, value) => {
    if (value.description) {
      handleChange(index, { ...values[index], commodity: value, description: value.description });
      return;
    }

    handleChange(`${index}.commodity`, value);
  };

  const onAddRow = () => {
    handleChange(values.length, {
      ...initialValues,
      expected_quantity_type: values[0]?.expected_quantity_type || quantityTypes?.[0] || null,
      expected_weight_type: values[0]?.expected_weight_type || weightTypes?.[0] || null,
      expected_weight_unit: values[0]?.expected_weight_unit || VALUE_TYPES?.[1] || null,
      expected_dimension_unit: values[0]?.expected_dimension_unit || measurementTypes?.[1] || null,
      value_type: values[0]?.value_type || VALUE_TYPES?.[0] || null,
      shipment_stop: values[0]?.shipment_stop || null,
      id: uuid(),
    });
  };

  useEffect(() => {
    getAdditionalData();
  }, []);

  return (
    <div>
      <STable>
        <thead>
          <tr className='header-row'>
            {!createMode && <th>SHIPPER</th>}
            {!createMode && <th>DESTINATION</th>}
            <th>COMMODITY *</th>
            <th>DESCRIPTION</th>
            <th>QTY *</th>
            {!createMode && <th>ACTUAL QTY</th>}
            <th>WEIGHT *</th>
            {!createMode && <th>ACTUAL WEIGHT</th>}
            <th>VALUE *</th>
            {!createMode && <th>ACTUAL VALUE</th>}
            <th colSpan={3}>DIMENSIONS *</th>
            <th>UNIT OF MEASURE *</th>
            <th>STACKABLE</th>
            <th>SKU BARCODE *</th>
            {!createMode && <th>UNKNOWN</th>}
            <th />
          </tr>
        </thead>
        <tbody>
          {values.map((item, index) => (
            <FormRow
              key={item.id}
              form={form}
              index={index}
              cargo={item}
              weightTypes={weightTypes}
              quantityTypes={quantityTypes}
              measurementTypes={measurementTypes}
              commodityTypes={commodityTypes}
              onCommodityChange={onCommodityChange}
              stopId={stopId}
              quoteId={quoteId}
              isRecurring={isRecurring}
              onCreateSuccess={onCreateSuccess}
              onUpdateSuccess={onUpdateSuccess}
              onDeleteSuccess={onDeleteSuccess}
              readOnly={readOnly}
              createMode={createMode}
            />
          ))}
          {!!values.length && (
            <tr className='body-row'>
              <td colSpan={createMode ? 2 : 4}>
                <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                  Total:
                </Typography>
              </td>
              <td>
                <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                  {totalValues.expected_quantity || '0'} {values[0].expected_quantity_type?.name}
                </Typography>
              </td>
              {!createMode && (
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                    {totalValues.reported_quantity || ''}{' '}
                    {!!totalValues.reported_quantity && values[0].reported_quantity_type?.name}
                  </Typography>
                </td>
              )}
              <td>
                <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                  {formatNumber(totalValues.expected_weight)} {values[0].expected_weight_type?.unit}
                </Typography>
              </td>
              {!createMode && (
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                    {totalValues.reported_weight ? formatNumber(totalValues.reported_weight) : ''}{' '}
                    {!!totalValues.reported_weight && `${values[0].reported_weight_type?.unit || ''}`}
                  </Typography>
                </td>
              )}
              <td>
                <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                  {currency}
                  {formatNumber(totalValues.value)} Flat
                </Typography>
              </td>
              {!createMode && (
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                    {totalValues.reported_value ? formatNumber(totalValues.reported_value) : ''}{' '}
                    {!!totalValues.reported_value && `Flat`}
                  </Typography>
                </td>
              )}
              <td colSpan={8}>
                {!!totalValues.volumeInCubicFeet && (
                  <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                    {formatNumber(totalValues.volumeInCubicFeet)} CU. FT.
                  </Typography>
                )}
              </td>
            </tr>
          )}
          {!!values.length &&
            !!equipment &&
            (!!equipmentCapacity || (!!equipmentHeightFeet && !!totalValues.totalStackableHeight)) && (
              <tr className='body-row'>
                <td colSpan={createMode ? 5 : 10} />
                <td colSpan={6}>
                  {!!equipmentHeightFeet && totalValues.totalStackableHeight ? (
                    <div className='d-flex align-items-center'>
                      <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                        {formatNumber(equipment.length.height)}{' '}
                        <Typography variant='c3' className='me-1'>
                          Inches
                        </Typography>{' '}
                        Usable Height {equipmentType}
                        {!!totalValues.totalStackableHeight &&
                          (totalValues.totalStackableHeight > equipmentHeightFeet ? (
                            <WarningIcon fill={palette.red500} className='ms-2' />
                          ) : (
                            <CheckIcon fill={palette.green500} className='ms-2' />
                          ))}
                      </Typography>
                      {!!totalValues.totalStackableHeight && totalValues.totalStackableHeight > equipmentHeightFeet && (
                        <Typography variant='s2' style={{ color: palette.red500 }} className='nowrap'>
                          The cargo for this shipment may not fit the selected equipment type due to stackable height.
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <div className='d-flex align-items-center'>
                      <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                        {formatNumber(equipmentCapacity)}{' '}
                        <Typography variant='c3' className='me-1'>
                          CU. FT.
                        </Typography>{' '}
                        Usable Capacity {equipmentType}
                        {!!totalValues.volumeInCubicFeet &&
                          (totalValues.volumeInCubicFeet > equipmentCapacity ? (
                            <WarningIcon fill={palette.red500} className='ms-2' />
                          ) : (
                            <CheckIcon fill={palette.green500} className='ms-2' />
                          ))}
                      </Typography>
                      {!!totalValues.volumeInCubicFeet && totalValues.volumeInCubicFeet > equipmentCapacity && (
                        <Typography variant='s2' style={{ color: palette.red500 }} className='nowrap'>
                          The cargo for this shipment may not fit the selected equipment type.
                        </Typography>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            )}
        </tbody>
      </STable>
      {!values?.length && <NoRecords />}
      <div className='mt-3'>
        {!readOnly && !hideAddNew && (
          <SAddMore onClick={() => onAddRow()}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another
            </Typography>
          </SAddMore>
        )}
      </div>
    </div>
  );
};

export default CargoTable;
