import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import CustomerStatement from 'pages/Reports/ReportDetails/CustomerStatement';

const CustomerStatementModal = ({ open, onClose, customer }) => {
  return (
    <Modal
      showModal={!!open}
      onHide={onClose}
      $width='1300px'
      $bodyMaxHeight='calc(100vh - 250px)'
      $bgColor={palette.gray0}
      headerTitle='Customer Statement'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done', className: 'modal-cancel-btn' }]}
    >
      <CustomerStatement isModalView customer={customer} />
    </Modal>
  );
};

export default CustomerStatementModal;
