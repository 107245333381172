import React, { useMemo } from 'react';
import './CustomPhoneInput.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useField } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from '../Typography';

const CustomPhoneInput = ({ field, label, required, ...props }) => {
  const { use } = useTheme();
  const [filedForm, , helpers] = useField(field?.name || 'no-name');

  const onChange = (phone) => {
    props?.onChange && props.onChange(`+${phone}`);
    helpers?.setValue(`+${phone}`);
  };
  const initialStyles = useMemo(() => {
    return {
      input: {
        height: 32,
        boxShadow: '0 1px 1px rgb(0 0 0 / 10%), 0 0 0 1px rgb(70 79 96 / 16%), 0 2px 5px rgb(89 96 120 / 10%)',
        border: 'none',
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.dark800, palette.gray200),
      },
      button: {
        height: 32,
        boxShadow: '0 1px 1px rgb(0 0 0 / 10%), 0 0 0 1px rgb(70 79 96 / 16%), 0 2px 5px rgb(89 96 120 / 10%)',
        border: 'none',
        backgroundColor: use(palette.gray0, palette.dark700),
      },
      dropdown: {
        backgroundColor: use(palette.gray0, palette.dark800),
      },
      required: {
        fontSize: 18,
        marginLeft: 3,
        color: palette.indigo500,
      },
    };
  }, [palette, use]);
  return (
    <label className='custom_phone_input' style={{ marginTop: 4, ...props.labelStyle }}>
      {!!label && <Typography variant='s2'>{label}</Typography>}
      {!!required && <span style={initialStyles.required}>*</span>}
      <PhoneInput
        {...field}
        {...props}
        country='us'
        value={props?.value !== undefined ? props.value : filedForm.value}
        onChange={onChange}
        inputStyle={{ ...initialStyles.input, ...props.inputStyle }}
        buttonStyle={{ ...initialStyles.button, ...props.buttonStyle }}
        dropdownStyle={{ ...initialStyles.dropdown, ...props.dropdownStyle }}
        dropdownClass={use('dropDown_custom_phone_light', 'dropDown_custom_phone_dark')}
      />
    </label>
  );
};

export default CustomPhoneInput;
