import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { clearLoginLock, getLogHistory } from 'Api/SecurityLog';
import { useColumns } from './LogHistory.data';

const LogHistory = ({ open, onClose, securityLog, onClearLockSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [loadingClearLock, setLoadingClearLock] = useState(false);
  const [logData, setLogData] = useState({ data: [] });
  const [filters, setFilters] = useState({ page: 1, itemsPerPage: 25 });

  const getLogData = async () => {
    setLoading(true);

    try {
      const params = {
        page: filters.page,
        itemsPerPage: filters.itemsPerPage,
      };

      const response = await getLogHistory(securityLog.id, params);
      setLogData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const clearLock = async (userId) => {
    if (loadingClearLock) {
      return;
    }

    try {
      setLoadingClearLock(true);
      await clearLoginLock({ user_id: userId });
      showToaster({ type: 'success', message: 'User has been successfully unlocked!' });
      getLogData();
      onClearLockSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingClearLock(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getLogData();
  }, [securityLog?.id, filters]);

  const columns = useColumns({ clearLock, securityLog, page: filters.page });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Security Log${
        securityLog?.user_details?.full_name ? ` - ${securityLog.user_details.full_name}` : ''
      }`}
      $bgColor={palette.gray0}
      $maxWidth='1000px'
      $minWidth='1000px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
        },
      ]}
    >
      <div>
        {loading ? (
          <TableSkeleton loading={loading} />
        ) : (
          <MaterialTableWrapper
            data={logData?.data}
            rowPerPage={filters.itemsPerPage}
            style={{ backgroundColor: palette.white }}
            components={{
              Pagination: () =>
                Pagination({
                  data: logData,
                  rowPerPage: filters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            columns={columns}
          />
        )}
      </div>
    </Modal>
  );
};

export default LogHistory;
