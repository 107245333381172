import React from 'react';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { getCompanyVsOwnerOptions } from './Charts.data';

const CompanyVsOwnerChart = ({ companyVsOwner }) => {
  const data = {
    labels: ['Company', 'Owner Operator'],
    datasets: [
      {
        data: [companyVsOwner?.Company?.total_count, companyVsOwner?.['O/O']?.total_count],
        backgroundColor: [palette.green400, palette.indigo400],
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getCompanyVsOwnerOptions(companyVsOwner)} data={data} />;
};

export default CompanyVsOwnerChart;
