import React from 'react';
import Divider from 'common/Divider';
import { STableWrapper } from '../Incidents.styles';
import IncidentsTable from './Tables/Incidents';

const TableSection = ({ refreshData, setRefreshData }) => {
  return (
    <STableWrapper>
      <Divider margin='2px 0 16px 0' />
      <IncidentsTable refreshData={refreshData} setRefreshData={setRefreshData} />
    </STableWrapper>
  );
};

export default TableSection;
