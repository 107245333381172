import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import { ReactComponent as ArrowRightHover } from 'assets/icons/arrow_right.svg';
import { palette } from 'utils/constants';
import { SWrapper } from './PositionCard.styles';

const PositionCard = ({ data, onClick }) => {
  return (
    <SWrapper onClick={onClick}>
      <div className='mb-1'>
        <Typography variant='title1'>{data.name}</Typography>
      </div>
      <Grid container spacing={0.5}>
        {(data?.states || []).map((item) => (
          <Grid item key={item.state.id}>
            <Chip label={item.state?.short_name || item.state?.name} size='small' />
          </Grid>
        ))}
      </Grid>
      <ArrowRight className='arrow-right' fill={palette.indigo500} />
      <ArrowRightHover className='arrow-right-hover' fill={palette.indigo500} />
    </SWrapper>
  );
};

export default PositionCard;
