import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Select from 'react-select';
import axios from 'axios';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import authHeader from 'services/auth-header';
import { ShipmentId, StopPointId } from 'Api/Equipment';
import './DropTrailerStatus.css';
import Autocomplete from 'common/Autocomplete';
import { GetEquipmentCargoOnboardTypes } from 'Api/StopPoint';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  borderRadius: '6px',
  p: 0,
};

const DropTrailerStatus = ({ handleDropTrailerClose, init, trailerID }) => {
  const [stopPoints, setStopPoints] = useState([]);
  const [allShipmentId, setAllShipmentId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shipmentID, setShipmentID] = useState();
  const [stopPointName, setStopPointName] = useState();
  const [cargoTypes, setCargoTypes] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const selectInputRef = useRef();

  const getCargoTypes = async () => {
    try {
      const { data } = await GetEquipmentCargoOnboardTypes();
      setCargoTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    ShipmentId().then((res) => {
      if (res && res?.data) {
        const filterByData = res.data.map((el) => {
          const lastIndex = (el.shipment_stops?.length || 0) - 1;
          const origin = el.shipment_stops[0]?.stop_point;
          const destination = el.shipment_stops[lastIndex]?.stop_point;
          return {
            label: `Shipment ${el.shipment_id} - ${origin?.location_name} to ${destination?.location_name}`,
            value: el.shipment_id,
            id: el.id,
          };
        });
        setAllShipmentId(filterByData);
      }
    });
    getCargoTypes();
  }, []);

  useEffect(() => {
    if (shipmentID) {
      StopPointId(shipmentID).then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.stop_point.location_name,
              value: el.stop_point_id,
              id: el.id,
            };
          });
          setStopPoints(filterByData);
        }
      });
    }
  }, [shipmentID]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 30,
      minHeight: 32,
      minWidth: 263,
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,
    }),
  };
  const API_URL = process.env.REACT_APP_API_URL;

  const { values, setFieldValue, handleSubmit, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      status_id: '6',
      stop_point_id: '',
      cargo_onboard_id: '',
      shipment_id: '',
      arrive_date: moment(new Date()).format('YYYY-MM-DD'),
    },
    onSubmit: async (values) => {
      const Data = {
        status_id: '6',
        stop_point_id: values?.stop_point_id,
        cargo_onboard_id: values?.cargo_onboard_id?.id,
        shipment_id: values?.shipment_id ? values?.shipment_id : '',
        arrive_date: values.arrive_date ? values.arrive_date : '',
        drop_trailer_cargo_onboard_id: values?.cargo_onboard_id?.id,
        drop_trailer_shipment_id: values?.shipment_id ? values?.shipment_id : '',
        drop_trailer_stop_point_id: stopPointName || '',
      };
      try {
        setLoading(true);
        await axios
          .put(`${API_URL}/equipment/equipment-status/${trailerID}`, Data, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res) {
              setLoading(false);
              handleDropTrailerClose();
              init();
            }
          });
      } catch (err) {
        if (err) {
          setLoading(false);
          handleClick();
        }
      }
    },
  });

  const onClear = () => {
    selectInputRef.current.clearValue();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <div>
            <p className='DropTrailerHeader'>Drop Trailer</p>
          </div>
          <div className='DropTrailerBody'>
            <div className='mt-3'>
              <div className='d-flex mb-2'>
                <span className='DropTrailerFormHeader'>
                  Select the Shipment ID <span style={{ color: 'blue' }}>*</span>
                </span>
              </div>
              <Select
                options={allShipmentId}
                styles={customStyles}
                placeholder='Select the Shipment ID'
                onChange={(e) => {
                  setFieldValue('shipment_id', e?.value);
                  setShipmentID(e?.id);
                  onClear();
                }}
                blurInputOnSelect
              />
            </div>
            <div className='mt-3'>
              <div className='d-flex mb-2'>
                <span className='DropTrailerFormHeader'>
                  Select the Stop Point to Drop Trailer at <span style={{ color: 'blue' }}>*</span>
                </span>
              </div>
              <Select
                options={stopPoints}
                styles={customStyles}
                ref={selectInputRef}
                placeholder='Select the Stop Point to Drop Trailer at'
                onChange={(e) => {
                  setFieldValue('stop_point_id', e?.id ? String(e?.id) : '');
                  setStopPointName(e?.label);
                }}
                blurInputOnSelect
              />
            </div>
            <div className='mt-3'>
              <Autocomplete
                required
                label='Cargo Onboard'
                name='cargo_onboard_id'
                labelKey='title'
                options={cargoTypes || []}
                value={values.cargo_onboard_id}
                onChange={(e, value) => setFieldValue('cargo_onboard_id', value)}
                isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
                error={touched.cargo_onboard_id && errors.cargo_onboard_id}
              />
            </div>
          </div>
          <div className='DropTrailerFormFooter'>
            <button className='DropTrailerFormCancelButton' onClick={handleDropTrailerClose}>
              Cancel
            </button>
            {loading ? (
              <CircularProgress style={{ marginTop: '7px', marginRight: '5px', width: '1.9rem', height: '1.9rem' }} />
            ) : (
              <button
                className='DropTrailerFormAddRecordButton'
                type='submit'
                // onClick={handleSubmit}
              >
                Update
              </button>
            )}
          </div>
        </Box>
      </form>
      <Stack spacing={2} sx={{ width: '100%' }} className='toast-wrapper-error'>
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert onClose={handleCloseAlert} severity='error' sx={{ width: '100%' }}>
            All fields are mandatory
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default DropTrailerStatus;
