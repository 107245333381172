import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 16px;
  text-align: center;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-title {
    margin: 12px 0 4px;
  }
`;
