import { array, date, object, ref, string } from 'yup';
import { validator } from 'utils/helpers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = array().of(
  object().shape({
    name: string().trim().required('Name is required').nullable(),
    start_date: validator.pastRequiredDate().max(ref('end_date'), "Start date can't be after end date").nullable(),
    end_date: validator.pastRequiredDate().min(ref('start_date'), '').nullable(),
    attempts: array().of(
      object().shape({
        request_date: validator.pastRequiredDate('Request date is required'),
        request_form_path: string().when('request_type', {
          is: (request_type) => request_type === 'Pdf',
          then: string().trim().required('Required').nullable(),
          otherwise: string().trim().nullable(),
        }),
        request_form_source: string().trim().required('Transmit Method is required').nullable(),
        response: string().trim().required('Response is required').nullable(),
        request_type: string().trim().required('Request Method is required').nullable(),
        email: string().when('request_form_source', {
          is: (request_form_source) => request_form_source === 'Email',
          then: string().required('Email is required').email('Invalid Email Address'),
          otherwise: string().trim().nullable(),
        }),
        fax: string().when('request_form_source', {
          is: (request_form_source) => request_form_source === 'Fax',
          then: string().matches(phoneRegExp, 'Fax number is not valid').required('Fax number is required'),
          otherwise: string().trim().nullable(),
        }),
        response_path: string().when('response', {
          is: (response) => response === 'Verified',
          then: string().trim().required('Required').nullable(),
          otherwise: string().trim().nullable(),
        }),
        noteDate: date().when('haveNote', {
          is: true,
          then: validator.pastRequiredDate(),
          otherwise: validator.date(),
        }),
        noteTitle: string().when('haveNote', {
          is: true,
          then: string().trim().required('Title is required!').nullable(),
          otherwise: string().trim().nullable(),
        }),
        noteNotes: string().when('haveNote', {
          is: true,
          then: string().trim().required('Note is required!').nullable(),
          otherwise: string().trim().nullable(),
        }),
      })
    ),
  })
);
