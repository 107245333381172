import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Radio from 'common/Radio';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { getCustomersList } from 'Api/Customers';
import { getCustomerSalesDetail } from 'Api/Reports';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { reportItemsConverter, transformReportItems } from './CustomerSalesDetail.data';
import { STable, STableFilters, STableWrapper } from '../ReportDetails.styles';

const CustomerSalesDetail = () => {
  const dateFormat = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [pdfUrl, setPdfUrl] = useState('');
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [type, setType] = useState('accrual');
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportItems, setReportItems] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());

  const getCustomerSalesData = async () => {
    try {
      setLoading(true);
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const customerIds = selectedCustomers.map((el) => el.id);

      const params = {
        start_date: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        type: type === 'paid' ? type : undefined,
        customer_id: customerIds.length ? customerIds : undefined,
      };
      const response = await getCustomerSalesDetail(params, controller.current?.signal);
      const convertedData = reportItemsConverter(response);
      setReportItems(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const getCustomers = async () => {
    try {
      setLoadingCustomers(true);
      const { data } = await getCustomersList({ page: 1, itemsPerPage: 10000 });
      setCustomers(data.map((el) => ({ id: el.id, title: el.company_name })));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingCustomers(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const convertedItems = transformReportItems(reportItems, true);
      const { url } = await generatePDF(convertedItems, download, dateRange, dateFormat, currency);
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }

    const titles = {
      invoice_id: 'Invoice ID',
      reference_id: 'Reference Number',
      customer: 'Customer',
      aging: 'Aging',
      sent_date: 'Sent Date',
      paid_date: 'Paid Date',
      amount: 'Amount',
      balance: 'Balance',
    };

    const convertedItems = transformReportItems(reportItems);

    const invoiceRecords = convertedItems.map((item) => ({
      invoice_id: item.isCustomer
        ? item.customerName || '-'
        : item.isTotal
        ? `Total ${item.customerName || ''}`
        : item.invoice_id || '-',
      reference_id: item.reference_id || '-',
      customer: item.customer?.company_name || '-',
      aging: item.age || '-',
      sent_date: item.customer_billed_date ? dateFormat.formatDate(item.customer_billed_date) : '-',
      paid_date: item.customer_payment_date ? dateFormat.formatDate(item.customer_payment_date) : '-',
      amount: item.isTotal ? item.total_amount || '-' : item.amount || '-',
      balance: item.isTotal ? item.total_balance || '-' : item.open_balance || '-',
    }));

    const arrayToConvert = [titles, ...invoiceRecords];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `Sales by Customer Detail - ${
        dateRange.start && dateRange.end
          ? `${dateFormat.formatDate(dateRange.start)} - ${dateFormat.formatDate(dateRange.end)}`
          : 'All Time'
      }`
    );
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setInitialLoading(true);
    }
    getCustomerSalesData();
    didMountRef.current = true;
  }, [dateRange, type, selectedCustomers]);

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <PageWrapper loading={initialLoading} title='Sales by Customer Detail'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName='All Time'
              type='allTime'
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={3}
              value={selectedCustomers}
              options={customers}
              loading={loadingCustomers}
              placeholder={!selectedCustomers.length ? 'Select Customer..' : ''}
              onChange={(e, val) => setSelectedCustomers(val)}
              getTagLabel={(option) => (option ? option?.title : '')}
              getOptionLabel={(option) => (option ? option?.title : '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <div className='d-flex align-items-center gap-3'>
              <Radio label='Accrual' value='accrual' checked={type === 'accrual'} onChange={() => setType('accrual')} />
              <Radio label='Cash' value='paid' checked={type === 'paid'} onChange={() => setType('paid')} />
            </div>
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportItems.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        <STable>
          <thead>
            <tr className='header-row'>
              <th>INVOICE ID</th>
              <th>REFERENCE NUMBER</th>
              <th>CUSTOMER</th>
              <th>AGING</th>
              <th>SENT DATE</th>
              <th>PAID DATE</th>
              <th>AMOUNT</th>
              <th>BALANCE</th>
            </tr>
          </thead>
          <tbody>
            {reportItems.map((customer) => (
              <>
                <tr key={customer.id} className='body-row'>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {customer.customerName || '-'}
                    </Typography>
                  </td>
                  <td colSpan={7} />
                </tr>
                {customer.invoices.map((invoice) => (
                  <tr key={invoice.id} className='body-row'>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {invoice.invoice_id || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {invoice.reference_id || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {customer.customerName || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {invoice.age || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {invoice.customer_billed_date ? dateFormat.formatDate(invoice.customer_billed_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {invoice.customer_payment_date ? dateFormat.formatDate(invoice.customer_payment_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {currency}
                        {formatAmount(invoice.amount)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {currency}
                        {formatAmount(invoice.open_balance)}
                      </Typography>
                    </td>
                  </tr>
                ))}
                <tr key={customer.id} className='body-row'>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      Total {customer.customerName || '-'}
                    </Typography>
                  </td>
                  <td colSpan={5} />
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {currency}
                      {formatAmount(customer.total_amount)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {currency}
                      {formatAmount(customer.total_balance)}
                    </Typography>
                  </td>
                </tr>
                <tr style={{ height: '60px' }} />
              </>
            ))}
          </tbody>
        </STable>
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Sales by Customer Detail' />
      )}
    </PageWrapper>
  );
};

export default CustomerSalesDetail;
