export const getApplyBody = (data, transaction, applyNotMatch) => {
  const { reference_id, payment, deposit, account_id } = transaction;
  const transactionAmount = Number(payment || deposit);
  const totalAmount = data.reduce((a, b) => a + b.total_amount, 0);
  const totalAmountWithoutApplied = totalAmount - (applyNotMatch?.total_amount || 0);
  let remainedTransactionAmount = transactionAmount;

  const body = {
    type: deposit > 0 ? (data[0].billing ? 'open_invoice' : 'standalone_invoice') : 'open_bill',
    id: [],
    payment_date: [],
    pay_reference: [],
    amount_paid: [],
    balance_due: [],
    account_id: [],
    payment_method: [],
    discount: [],
    credit_used: [],
    over_payment: [],
    invoice_id: [],
  };

  data.forEach((item) => {
    const invoice_amount = item.total_amount;
    let amount_paid;

    if (applyNotMatch?.id !== item.id) {
      amount_paid = invoice_amount > transactionAmount ? transactionAmount : invoice_amount;
    } else {
      amount_paid = transactionAmount - totalAmountWithoutApplied;
    }

    if (amount_paid <= 0) {
      return;
    }

    let actual_amount_paid;
    let balance_due;

    if (amount_paid > invoice_amount) {
      if (amount_paid > transactionAmount) {
        actual_amount_paid = transactionAmount;
      } else {
        actual_amount_paid = invoice_amount;
      }
    } else {
      actual_amount_paid = amount_paid;
    }

    if (applyNotMatch?.id !== item.id) {
      if (invoice_amount <= remainedTransactionAmount) {
        balance_due = 0;
      } else {
        balance_due = invoice_amount - remainedTransactionAmount;
      }
    } else {
      balance_due = totalAmount - transactionAmount;
    }

    remainedTransactionAmount -= actual_amount_paid;

    body.id.push(item.id);
    body.payment_date.push(item.due_date);
    body.pay_reference.push(reference_id);
    body.amount_paid.push(actual_amount_paid);
    body.balance_due.push(balance_due);
    body.account_id.push(account_id);
    body.payment_method.push(item.payment_method || null);
    body.invoice_id.push(item.invoice_id || null);
    body.discount.push(0);
    body.credit_used.push(0);
    body.over_payment.push(0);
  });

  return body;
};

export const convertBillingData = (data, transaction, { accounts, payees }) => {
  const hasMatched = transaction.status === 'Matched';
  const isInvoice = transaction.deposit > 0;
  const matchedInvoice = data.find((item) => {
    const customer = item.billing?.billing_customer || item.customer;
    const amount = item.billing?.total_amount || item.total_amount;

    return (
      transaction.payee_type === 'customer' &&
      Number(amount) === Number(transaction.deposit) &&
      customer?.id === transaction.payee_id
    );
  });

  const matchedBill = data.find(
    (item) =>
      transaction.payee_type === 'vendor' &&
      Number(item.amount_due) === Number(transaction.payment) &&
      item.vendor?.id === transaction.payee_id
  );

  return data.map((item) => {
    const payee = payees.find((i) =>
      isInvoice
        ? Number(i.id) === Number(item.billing?.billing_customer?.id || item.customer?.id) && i.type === 'customer'
        : Number(i.id) === Number(item.vendor.id) && i.type === 'vendor'
    );
    const account = isInvoice ? accounts.find((acc) => Number(acc.id) === payee.account_id) : item.account;

    return {
      ...item,
      total_amount: isInvoice ? Number(item.billing?.open_balance || item.open_balance) : item.open_balance,
      payee_name: isInvoice
        ? item.billing?.billing_customer?.company_name || item.customer?.company_name
        : item.vendor.name,
      payee_id: isInvoice ? item.billing?.billing_customer?.id || item.customer?.id : item.vendor.id,
      billed_date: isInvoice ? item.customer_billed_date : item.date_entered,
      due_date: isInvoice ? item.due_date : item.bill_due_date,
      reference: isInvoice ? item.invoice_id : item.reference_id,
      isSelected: hasMatched && (isInvoice ? matchedInvoice?.id === item.id : matchedBill?.id === item.id),
      account,
      payee,
    };
  });
};
