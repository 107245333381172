import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SAcceptAward = styled.div`
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 626px;
  max-width: 50%;
  min-width: 356px;
  overflow-y: auto;
  padding: 24px 20px;
  position: absolute;
  border-radius: 8px;
  overflow-x: hidden;
  background: ${palette.white}BB;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 0 1px rgba(152, 161, 178, 0.1), 0 1px 4px rgba(69, 75, 87, 0.12), 0 0 2px rgba(0, 0, 0, 0.08);
  z-index: 4;
`;
