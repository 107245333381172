import axios from 'axios';
import AuthService from './auth.service';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  async (req) => {
    const user = await JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
      req.headers.Authorization = `Bearer ${user.accessToken}`;
    }
    return req;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (/unauthenticated/i.test(error?.response?.data?.error?.message)) {
      AuthService.logout().finally(() => {
        localStorage.clear();
        window.location.href = '/login';
      });
    }
    throw error;
  }
);

export default instance;
