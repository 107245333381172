import React, { useMemo } from 'react';
import moment from 'moment';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg';
import CustomDatePicker from '../CustomDatePicker';

import styles from './FilterPlaner.module.css';

const SearchPlaner = ({
  filter,
  isDriverFilter,
  dateRangeLocation,
  onChangeAll,
  onChangeLocationValues,
  onChangeLocationDatePicker,
}) => {
  const { use } = useTheme();
  const searchValue = filter.searchBy.value;

  const { origin_city, origin_state, destination_city, destination_state, scheduled_from, scheduled_to } =
    filter?.locations || {};

  const valueDatePicker = useMemo(() => {
    const fromValue = scheduled_from?.searchValue;
    const toValue = scheduled_to?.searchValue;
    const isValidTo = !moment(toValue).isValid();

    if (fromValue && isValidTo) {
      return fromValue;
    }
    return fromValue && toValue ? `${fromValue} - ${toValue}` : '';
  }, [filter?.locations]);

  const initialStyle = useMemo(() => {
    return {
      filed: {
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.dark800, palette.white),
        boxShadow: use(
          '0 1px 2px rgba(0, 0, 0, 0.06),0 0 3px rgba(104, 113, 130, 0.16)',
          '0 1px 2px rgba(0, 0, 0, 0.06), 0 0 0 1px rgba(104, 113, 130, 0.16)'
        ),
      },
      date: {
        minWidth: 220,
        margin: 0,
        height: 30,
        transform: 'translateY(1px)',
        borderRadius: 6,
        justifyContent: 'flex-start',
      },
      dateLabel: {
        marginTop: 0,
      },
      miles_wrapper: {
        backgroundColor: use(palette.white, palette.dark800),
      },
    };
  }, [use, palette]);

  return searchValue === 'locations' ? (
    <div className={styles.searchPlaner_wrapper}>
      <input
        type='text'
        placeholder='Origin'
        style={initialStyle.filed}
        className={styles.city_input}
        value={origin_city.searchValue}
        onChange={(e) => onChangeLocationValues('origin_city', e)}
      />

      <input
        type='text'
        placeholder='State'
        style={initialStyle.filed}
        value={origin_state.searchValue}
        className={styles.state_input}
        onChange={(e) => onChangeLocationValues('origin_state', e)}
      />

      <ArrowRightIcon style={{ margin: '0 11.25px' }} />

      <input
        type='text'
        placeholder='Destination'
        style={initialStyle.filed}
        value={destination_city.searchValue}
        className={[styles.city_input, styles.destination].join(' ')}
        onChange={(e) => onChangeLocationValues('destination_city', e)}
      />

      <input
        type='text'
        placeholder='State'
        style={initialStyle.filed}
        className={styles.state_input}
        value={destination_state.searchValue}
        onChange={(e) => onChangeLocationValues('destination_state', e)}
      />

      <div className={styles.miles_zipCode_wrapper}>
        <CustomDatePicker
          selectsRange
          renderStart={false}
          valueToShow={valueDatePicker}
          style={initialStyle.date}
          shouldCloseOnSelect={false}
          endDate={dateRangeLocation[1]}
          startDate={dateRangeLocation[0]}
          labelStyle={initialStyle.dateLabel}
          placeholderText='MM/DD/YYYY - MM/DD/YYYY'
          onChange={(dates) => onChangeLocationDatePicker(dates)}
        />
      </div>
    </div>
  ) : (
    <div className={styles.searchPlaner_wrapper}>
      <input
        type='text'
        placeholder='Search'
        value={filter.allValue}
        style={initialStyle.filed}
        onChange={(e) => onChangeAll(e.target.value)}
        className={isDriverFilter ? styles['search_input-driver-filter'] : styles.search_input}
      />
    </div>
  );
};

export default SearchPlaner;
