import React, { useState } from 'react';
import playVideoIcon from 'assets/images/icons-video.png';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import ViewPdf from 'components/ViewPdf';
import CountInput from 'common/CountInput';
import ViewVideo from 'components/ViewVideo';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SBodyWrapper, SPairedWith } from './HardwareDetails.styles';

const HardwareDetails = ({ open, onClose, onViewPaired, hardware, count, handleCountChange, handleAddToCart }) => {
  const [openVideo, setOpenVideo] = useState(false);
  const [activePreview, setActivePreview] = useState(0);
  const [techSpecUrl, setTechSpecUrl] = useState(null);

  const addHardwareToCart = async () => {
    handleAddToCart();
    onClose();
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={hardware.name}
      $maxWidth='80vw'
      $minWidth='1200px'
      $bgColor={palette.white}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done' }]}
    >
      <SBodyWrapper>
        <div className='left-section'>
          <img className='hardware-image' alt='' src={hardware.images[activePreview].image} />
          {hardware.images?.length > 1 && (
            <div className='hardware-image-icons'>
              {hardware.images.map((img, idx) => (
                <img
                  key={img?.id}
                  className={`hardware-image-icon ${idx !== activePreview ? 'blur' : ''}`}
                  src={img.image}
                  alt=''
                  onClick={() => setActivePreview(idx)}
                />
              ))}
            </div>
          )}
          <div className='d-flex flex-column gap-2 co'>
            {!!hardware?.guide && (
              <span
                className='tech-specs-button'
                onClick={() => {
                  if (hardware.guide_type === 'pdf') {
                    setTechSpecUrl(hardware.guide);
                  } else {
                    window.open(hardware.guide, '_blank');
                  }
                }}
              >
                View Guide
              </span>
            )}
            {!!hardware?.video_link && (
              <div className='video-link-wrapper' onClick={() => setOpenVideo(true)}>
                <img src={playVideoIcon} alt='play-video' height='36px' />
                <Typography className='underline-text' variant='s2' style={{ color: palette.indigo500 }}>
                  Watch Video
                </Typography>
              </div>
            )}
          </div>
          <div className='count-input-wrapper'>
            <div className='d-flex align-items-center gap-3 mb-3 mt-2'>
              <CountInput value={count} handleChange={handleCountChange} />
              <div className='d-inline-flex align-items-center gap-1'>
                <Typography
                  variant='s2'
                  style={{ color: palette.indigo500, cursor: 'pointer' }}
                  onClick={addHardwareToCart}
                >
                  Add To Cart
                </Typography>
              </div>
            </div>
            <div className='separator' />
            <div className='finance-agreement'>
              {!!hardware.financed && (
                <>
                  <p className='finance-agreement-heading'>Finance Agreement</p>
                  <div className='d-flex align-items-center gap-2 mb-1'>
                    <p className='finance-agreement-text'>
                      ${formatNumber(hardware.financed)}
                      <span>
                        {' '}
                        + finance charge / unit, paid monthly{' '}
                        {hardware.contract_for_mo ? `for ${hardware.contract_for_mo} months` : ''}
                      </span>{' '}
                    </p>
                    <Chip
                      label='BASED UPON CREDIT APPROVAL'
                      labelColor={palette.green400}
                      bgColor={palette.green0}
                      fontWeight={500}
                      fontSize='12px'
                    />
                  </div>
                  <div className='separator' />
                </>
              )}
              <p className='finance-agreement-heading'>Credit Card / ACH</p>
              <p className='finance-agreement-text'>
                ${formatNumber(hardware.price)}
                <span> for one-time payment</span>{' '}
              </p>
            </div>
          </div>
          {!!hardware.warranty?.length && (
            <div className='mt-4'>
              <p className='heading'>Warranty & Refunds</p>
              <ul>
                {hardware.warranty.map((data) => (
                  <li key={data.id}>
                    <p className='m-0'>➝ {data.warranty}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className='right-section'>
          <div className='descrtption'>
            <p className='heading'>Description</p>
            <p className='text'>{hardware.description}</p>
          </div>
          <div className='d-flex gap-4 mt-2'>
            <div>
              <Typography variant='s1'>Make:</Typography> <Typography variant='b1'>{hardware.hardware_make}</Typography>
            </div>
            <div>
              <Typography variant='s1'>Model:</Typography>{' '}
              <Typography variant='b1'>{hardware.hardware_model}</Typography>
            </div>
          </div>
          {!!hardware.features?.length && (
            <div className='mt-4'>
              <p className='heading'>What’s Included?</p>
              <p className='text' style={{ margin: '0px' }}>
                This item includes the following:
              </p>
              <ul>
                {hardware.features.map((data) => (
                  <li key={data.title}>
                    <p className='m-0'>➝ {data.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!!hardware.technical_spec?.length && (
            <div className='mt-4'>
              <p className='heading'>Technical Specs</p>
              <ul>
                {hardware.technical_spec.map((data) => (
                  <li key={data.id}>
                    <p className='m-0'>➝ {data.technical_spec}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!!hardware.requirement?.length && (
            <div className='mt-4'>
              <p className='heading'>Requirements</p>
              <ul>
                {hardware.requirement.map((data) => (
                  <li key={data.id}>
                    <p className='m-0'>➝ {data.requirement}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!!hardware.hardware_pair_with?.length && (
            <div className='mt-4'>
              <p className='heading'>Products Related To This Item</p>
              <div className='d-flex flex-wrap gap-2'>
                {hardware.hardware_pair_with.map((data) => (
                  <SPairedWith key={data.id}>
                    {!!data.images?.[0]?.image && (
                      <img src={data.images[0].image} width={100} height={60} alt='hardware' />
                    )}
                    <div>
                      <Typography variant='s1'>{data.name}</Typography>
                      <div className='d-flex align-items-center gap-4 mt-1'>
                        <span className='paired-hardware-price'>
                          ${formatNumber(data.price)}
                          <span> for one-time payment</span>{' '}
                        </span>
                        <span className='view-button' onClick={() => onViewPaired(data)}>
                          View
                        </span>
                      </div>
                    </div>
                  </SPairedWith>
                ))}
              </div>
            </div>
          )}
        </div>
      </SBodyWrapper>
      {openVideo && <ViewVideo open={openVideo} onClose={() => setOpenVideo(false)} videoUrl={hardware?.video_link} />}
      <ViewPdf open={!!techSpecUrl} title='Guide' pdfUrl={techSpecUrl} onClose={() => setTechSpecUrl(null)} />
    </Modal>
  );
};

export default HardwareDetails;
