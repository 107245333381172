import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  card_holder_type: object()
    .shape({ value: string().trim().required('Required') })
    .required('Required')
    .nullable(),
  card_holder_name: string().trim().required('Required').nullable(),
  card_holder: object().when('card_holder_type', {
    is: 'individual',
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  front: string().when('card_holder_type', {
    is: (type) => type?.value === 'individual',
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  back: string().when('card_holder_type', {
    is: (type) => type?.value === 'individual',
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  address: string().trim().required('Required'),
  address2: string().trim().nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required'),
  shipping_method: object()
    .shape({ value: string().required('Required').nullable() })
    .required('Required')
    .nullable(),
  quantity: number().required('Required').nullable(),
});
