import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const CreateOrAssign = ({ setSelectExisting, rowData, createIncident }) => {
  const { use } = useTheme();

  return (
    <div className='d-flex gap-3 flex-column m-3'>
      <Typography
        variant='s2'
        style={{ color: use(palette.indigo500), cursor: 'pointer' }}
        onClick={() => {
          createIncident(rowData);
        }}
      >
        Create Incident
      </Typography>
      <Typography
        variant='s2'
        style={{ color: use(palette.indigo500), cursor: 'pointer' }}
        onClick={() => {
          setSelectExisting(rowData);
        }}
      >
        Select Existing Incident
      </Typography>
    </div>
  );
};

export default CreateOrAssign;
