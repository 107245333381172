import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin: 8px 8px 32px;
  padding: 16px;
  background-color: ${palette.red0};
  border: 1px solid ${palette.red50};
  border-radius: 4px;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  svg path {
    fill: ${palette.red500};
  }
`;

export const SReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
