import React, { useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import axios from 'services/axios';
import './adddriver.css';
import { DriversList } from 'Api/Equipment';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ConfirmAssign from './ConfirmAssign';

const AddDriver = ({ handleClose, DriversAddedmsgModal, onSuccess = () => null, vehicle }) => {
  const { use } = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (values, confirmed) => {
    if (
      !confirmed &&
      !!vehicle.transmission_type &&
      !!values.driver_id?.transmission_type &&
      ((values.driver_id?.transmission_type === 1 && vehicle.transmission_type !== 1) ||
        (values.driver_id?.transmission_type === 2 && vehicle.transmission_type !== 2) ||
        (values.driver_id?.transmission_type === 2 &&
          vehicle.transmission_type === 2 &&
          !!values.driver_id?.transmission_speed &&
          !!vehicle?.transmission_speed &&
          values.driver_id?.transmission_speed !== vehicle?.transmission_speed))
    ) {
      setOpenConfirmation(true);
      return;
    }

    setLoading(true);
    axios
      .post(`/equipment-driver`, { ...values, driver_id: values.driver_id.value })
      .then(({ data }) => {
        handleClose();
        onSuccess(data?.data);
        DriversAddedmsgModal(values.equipment_id);
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      equipment_id: vehicle.id,
    },
    onSubmit: (values) => onSubmit(values, false),
  });

  const { data, isError, isLoading, error } = useQuery('uniquedriverList', DriversList);

  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }

  const arr = data.data.map((t) => ({
    ...t,
    label: `${t.fname} ${t.lname}`,
    value: t.id,
  }));

  return (
    <ModalBody className='p-0' style={{ backgroundColor: use(palette.white, palette.dark800), borderRadius: '12px' }}>
      <div className='equipment-list-add-driver'>
        <form onSubmit={formik.handleSubmit}>
          <div className='add-driver-modal-header pt-2 ps-3'>
            <Typography variant='s2'>Add Driver(s)</Typography>
          </div>
          <div className='add-drivers-modal-body' style={{ backgroundColor: '#F7F9FC' }}>
            <Typography variant='s2' style={{ color: use(palette.gray700) }}>
              Driver*
            </Typography>
            <Autocomplete
              options={arr}
              onChange={(e, val) => formik.setFieldValue('driver_id', val)}
              value={formik.values.driver_id || null}
              labelKey='label'
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </div>
          <div className='add-driver-modal-footer d-flex justify-content-end'>
            <button
              type='button'
              className='add-driver-modal-cancel-button'
              onClick={handleClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            <button type='submit' className='add-driver-modal-add-driver-button' disabled={loading}>
              Add Driver
            </button>
          </div>
        </form>
      </div>
      {openConfirmation && (
        <ConfirmAssign
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          vehicle={vehicle}
          driver={formik.values.driver_id}
          onConfirm={() => {
            onSubmit(formik.values, true);
            setOpenConfirmation(false);
          }}
        />
      )}
    </ModalBody>
  );
};

export default AddDriver;
