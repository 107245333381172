export const COUNTRIES = [
  { key: 'US', name: 'United States', id: 231, phonecode: 1 },
  { key: 'CA', name: 'Canada', id: 38, phonecode: 1 },
  { key: 'MX', name: 'Mexico', id: 142, phonecode: 52 },
];

export const defaultInvoicePayTerm = {
  id: 3,
  title: 'Days',
  discount: '0',
  default: 1,
  net_day: '90',
};

export const basicInfoInitialValues = {
  name: '',
  dot: '',
  mc: '',
  phoneNumber: '',
  email: '',
  address1: '',
  address2: '',
  country: null,
  state: null,
  city: null,
  zipCode: '',
  require_trailer_interchange: false,
  trailer_interchange: '',
  trailer_interchange_from: '',
  trailer_interchange_expires: '',
  logo: '',
};

export const getBasicInfoInitialValues = (basicInfo) => {
  const {
    name,
    dot,
    mc,
    scac,
    phone_number,
    address1,
    address2,
    email,
    country,
    city,
    state,
    zipcode,
    can_travel_to,
    approved_for,
    approved_for_custom,
    require_trailer_interchange,
    trailer_interchange,
    trailer_interchange_from,
    trailer_interchange_expires,
    logo_path,
  } = basicInfo || {};

  return {
    name,
    dot,
    mc,
    phoneNumber: phone_number,
    email,
    address1,
    address2: address2 || '',
    country,
    state,
    city,
    scac: scac || '',
    zipCode: zipcode,
    can_travel_to: can_travel_to || [],
    approved_for: approved_for || [],
    approved_for_custom: approved_for_custom || '',
    require_trailer_interchange: !!require_trailer_interchange,
    trailer_interchange: trailer_interchange || '',
    trailer_interchange_from: trailer_interchange_from || '',
    trailer_interchange_expires: trailer_interchange_expires || '',
    logo: logo_path || '',
  };
};

export const getOperationsInitialValues = (basicInfo) => {
  const { operating_states, equipment_types } = basicInfo || {};

  return {
    operating_states: operating_states || [],
    equipment_types: equipment_types || [],
  };
};

export const getPayTermsInitialValues = (paymentTerms, company) => {
  const {
    address1,
    address2,
    country,
    city,
    state,
    zipcode,
    pay_term_types,
    pay_factoring,
    factoring_company,
    email,
    quick_pay,
    payment_method,
    bank_name,
    bank_routing,
    bank_account,
    bank_direct_deposit_form,
    third_party_name,
    third_party_id,
    bank_account_verified,
    bank_account_mismatch_reason,
  } = paymentTerms || {};

  const { default_invoice_pay_term } = company || {};

  return {
    invoicePayTerm: pay_term_types || default_invoice_pay_term,
    payFactoring: !!pay_factoring,
    factoringCompany: factoring_company,
    email,
    address: address1,
    address2,
    country,
    city,
    state,
    zipcode,
    quick_pay: !!quick_pay,
    payment_method: payment_method || 'check',
    bank_name: bank_name || '',
    bank_routing: bank_routing || '',
    routing_confirmed: bank_routing || '',
    bank_account: bank_account || '',
    account_confirmed: bank_account || '',
    bank_direct_deposit_form: bank_direct_deposit_form || '',
    third_party_name: third_party_name || '',
    third_party_id: third_party_id || '',
    bank_account_verified,
    bank_account_mismatch_reason: bank_account_mismatch_reason || '',
  };
};

export const getPayTermsDefault = (company) => {
  const { default_invoice_pay_term } = company || {};

  return {
    invoicePayTerm: default_invoice_pay_term || defaultInvoicePayTerm,
    payFactoring: false,
    factoringCompany: '',
    email: '',
    address: '',
    address2: '',
    country: null,
    city: null,
    state: null,
    zipcode: '',
    quick_pay: false,
    payment_method: 'check',
    bank_name: '',
    bank_routing: '',
    routing_confirmed: '',
    bank_account: '',
    account_confirmed: '',
    bank_direct_deposit_form: '',
    third_party_name: '',
    third_party_id: '',
    bank_account_verified: false,
  };
};

export const contactBookInitialValues = {
  id: Date.now(),
  contact_name: '',
  contact_email: '',
  phone_number: '',
  contact_type_id: null,
  portal_access: false,
  receive_offers: false,
  check_calls: false,
  isNew: true,
};

export const getContactBookInitialValues = (contacts) => {
  return contacts?.length
    ? contacts?.map((item) => {
        return {
          id: item.id,
          contact_name: item.contact_name,
          contact_email: item.contact_email,
          phone_number: item.phone_number,
          contact_type_id: item.contact_type,
          portal_access: item.portal_access,
          receive_offers: item.receive_offers,
          check_calls: item.check_calls,
        };
      })
    : [contactBookInitialValues];
};

export const getDocumentsInitialValues = (staticDocuments, documents) => {
  const {
    authority,
    cargo_insurance,
    cargo_insurance_exp_date,
    liability_insurance,
    liability_insurance_exp_date,
    reference_sheet,
    w9,
    general_liability_exp_date,
    general_liability,
  } = staticDocuments || {};

  return {
    w9,
    authority,
    reference_sheet,
    liability_insurance_exp_date,
    liability_insurance,
    cargo_insurance_exp_date,
    cargo_insurance,
    general_liability_exp_date,
    general_liability,
    documents: documents?.length
      ? documents.map((item) => {
          return {
            id: item.id,
            name: item.name,
            file: item.path,
            expiration_date: item.expiration_date,
            isNew: false,
            no_expiration: !item.expiration_date,
          };
        })
      : [
          // {
          //   name: '',
          //   expiration_date: '',
          //   file: '',
          //   isNew: true,
          // },
        ],
  };
};

export const getMasterInitialValues = (carrier) => {
  const { phone_number } = carrier || {};
  return {
    printedName: '',
    phone: phone_number || '',
    title: '',
    signature: null,
  };
};

export const getIndemityInitialValues = (carrier) => {
  const { phone_number } = carrier || {};
  return {
    printedName: '',
    phone: phone_number || '',
    title: '',
    signature: null,
  };
};
