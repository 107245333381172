import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ResetPinModal.css';
import ReactCodeInput from 'react-code-input';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { resetPin } from 'Api/Driver';
import { getErrorMessage } from 'utils/error';
import { resetPinValidation } from '../Drivers/AddDriver/ValidationSchema';

export default function ResetPinModal({ ...props }) {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      login_pin: null,
    },
    validationSchema: resetPinValidation,
  });

  useEffect(() => {
    formik.setFieldValue('login_pin', '');
  }, []);

  const ResetPin = () => {
    const { login_pin } = formik.values;
    setLoading(true);
    resetPin(props?.driverId, login_pin)
      .then(() => {
        formik.setFieldValue('login_pin', null);
        props.onHide();
        props.onSuccess('PIN has been successfully reset!');
        setLoading(false);
      })
      .catch((error) => {
        props.showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  return (
    <div className='modal-container driver-style-wrap'>
      <Modal enforceFocus={false} {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          className='ResetPin-Header'
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <p className='heading-resetpin' style={{ color: use(palette.gray900, palette.gray50) }}>
            Reset PIN
          </p>
          <p className='resetpin-subline' style={{ color: use(palette.gray500, palette.gray50) }}>
            You are resetting a PIN for {props.name}
          </p>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='modal-body-custom resetpin-modal-custom'>
            <p style={{ color: use(palette.gray900, palette.gray50) }}>Please enter a new 4-digit PIN</p>
            <ReactCodeInput
              type='text'
              value={formik?.values?.login_pin || 0}
              fields={4}
              onChange={(val) => formik.setFieldValue('login_pin', val)}
              name='login_pin'
              id='login_pin'
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                className='next-step'
                disabled={!formik.isValid}
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
                onClick={ResetPin}
                type='submit'
              >
                Continue
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
