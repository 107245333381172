import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as TruckinDigitalLogo } from 'assets/icons/logo/truckinDigital.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SCallButton, SHeaderWrapper, SStepsWrapper } from './Header.styles';

const Steps = () => {
  const location = useLocation();
  const { plans, onboardingSettings } = useSelector((state) => state.onboarding);
  // const steps = ['Account', 'Select Plan', 'Company', 'Add-Ons', 'Hardware', 'Account Summary'];
  const steps =
    plans?.[0]?.plan_types?.length === 1
      ? !!onboardingSettings && !onboardingSettings.is_require_payment_enable
        ? ['Account', 'Company']
        : ['Account', 'Company', 'Account Summary']
      : ['Account', 'Select Plan', 'Company', 'Account Summary'];

  return (
    <SStepsWrapper
      $bgColor={
        ['/onboarding/company', '/onboarding/account-summary'].includes(location.pathname)
          ? palette.gray0
          : palette.white
      }
    >
      {steps.map((step, idx) => (
        <Fragment key={step}>
          <button
            className={
              (location.pathname === '/onboarding/account' && idx === steps.indexOf('Account')) ||
              (location.pathname === '/onboarding/select-plan' && idx === steps.indexOf('Select Plan')) ||
              (location.pathname === '/onboarding/company' && idx === steps.indexOf('Company')) ||
              // (location.pathname === '/onboarding/addons' && idx === 3) ||
              // (location.pathname === '/onboarding/hardware' && idx === 4) ||
              (location.pathname === '/onboarding/account-summary' && idx === steps.indexOf('Account Summary'))
                ? 'step-button active'
                : 'step-button'
            }
          >
            {step}
          </button>
          <div className='steps-horizontal-divider' style={{ display: idx === steps.length - 1 ? 'none' : '' }} />
        </Fragment>
      ))}
    </SStepsWrapper>
  );
};

const Header = () => {
  return (
    <SHeaderWrapper>
      <div className='onboarding-header'>
        <div>
          <TruckinDigitalLogo width={200} height={40} fill={palette.white} className='main-logo' />
        </div>
        <div>
          <SCallButton href='tel:+1866-300-7701'>
            <PhoneIcon fill={palette.indigo500} />
            <Typography variant='s2'>+1 (866) 300-7701</Typography>
          </SCallButton>
        </div>
      </div>
      <Steps />
    </SHeaderWrapper>
  );
};

export default Header;
