import React, { useState } from 'react';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Tooltip from 'common/Tooltip';
import ViewPdf from 'components/ViewPdf';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import { getMergedUrl } from 'utils/helpers';
import { generatePdf } from './generatePdf';
import { ViewIncidentBody } from './ViewIncidentBody';
import './ViewIncidentModal.css';

const ViewIncidentModal = ({ open, onClose, incident, rules, setRuleToView, onEdit, hideEdit }) => {
  const { formatDateTime } = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState('');

  const onPrintClick = async () => {
    try {
      const { url } = generatePdf(incident, formatDateTime);
      const docs = [url];

      const incidentDocs = incident.incident_record_documents?.filter((file) => file?.path?.endsWith('.pdf'));
      incidentDocs.forEach((doc) => {
        docs.push(`${doc.path}?cache=${Math.random()}`);
      });
      if (incident.statement_document) {
        docs.push(`${incident.statement_document}?cache=${Math.random()}`);
      }
      const { url: mergedUrl } = await getMergedUrl(docs);
      setPdfUrl(mergedUrl);
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex align-items-center'>
              {incident?.incident_id && <Typography variant='s1'>{incident?.incident_id} -&nbsp;</Typography>}
              <Typography variant='s1'>
                {incident?.custom_incident_type || incident?.incident_type?.type || ''}
              </Typography>
            </div>
            <UserInfo
              size='44px'
              data={{
                ...(incident?.staff?.staff ? incident?.staff?.staff : incident.driver),
                id: incident?.staff?.staff ? incident?.staff?.staff?.id : incident.driver?.id,
                name: incident?.staff?.staff
                  ? `${incident?.staff?.staff?.first_name} ${incident?.staff?.staff?.last_name}`
                  : `${incident.driver?.fname} ${incident.driver?.lname}`,
                image: incident?.staff?.staff ? incident?.staff?.staff?.profile_logo : incident.driver?.image,
                user_type: incident?.staff?.staff ? 'staff' : 'driver',
              }}
            />
          </div>
          <div className='d-flex flex-column gap-3'>
            {!hideEdit && (
              <div className='d-flex align-items-center justify-content-end gap-3' style={{ height: '34px' }}>
                <PrintIcon className='pointer' onClick={onPrintClick} />
                <img src={moreInfo} alt='' onClick={() => onEdit(incident)} />
              </div>
            )}
            {rules?.some((rule) => rule?.incident_types?.some((type) => type?.id === incident.incident_type?.id)) && (
              <div className='d-flex align-items-center gap-1'>
                <Typography variant='s2'>Related To:</Typography>
                {rules
                  ?.filter((rule) => rule?.incident_types?.some((type) => type?.id === incident.incident_type?.id))
                  .map((rule) => {
                    return (
                      <Tooltip
                        title={
                          <div style={{ maxWidth: '600px' }}>
                            <Typography variant='s2' style={{ whiteSpace: 'normal' }}>
                              {rule.description}
                            </Typography>
                          </div>
                        }
                        arrow={false}
                        placement='bottom'
                        componentsProps={{
                          tooltip: {
                            sx: {
                              color: palette.gray700,
                              bgcolor: palette.white,
                              fontSize: 14,
                              fontFamily: 'Inter',
                              whiteSpace: 'nowrap',
                              maxWidth: 'unset',
                              border: '1px solid #dadde9',
                            },
                          },
                        }}
                      >
                        <div
                          key={rule.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            setRuleToView(rule);
                          }}
                        >
                          <Chip
                            label={rule.title}
                            bgColor={palette.indigo0}
                            fontSize='13px'
                            fontWeight='400'
                            labelColor={palette.indigo500}
                            sx={{
                              '& .MuiChip-label': {
                                cursor: 'pointer',
                              },
                            }}
                          />
                        </div>
                      </Tooltip>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      }
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='1200px'
      className='modified-scrollbar'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <ViewIncidentBody incident={incident} />
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Incident' />}
    </Modal>
  );
};

export default ViewIncidentModal;
