import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({
  row,
  onEdit,
  onViewLogs,
  onPreviewQuote,
  onSendQuote,
  onShareLink,
  onAcceptQuote,
  onCancelQuote,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'edit':
        onEdit();
        break;
      case 'viewLogs':
        onViewLogs();
        break;
      case 'preview':
        onPreviewQuote();
        break;
      case 'sendQuote':
        onSendQuote();
        break;
      case 'shareLink':
        onShareLink();
        break;
      case 'acceptQuote':
        onAcceptQuote();
        break;
      case 'cancel':
        onCancelQuote();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={(event) => onActionClick(event, 'edit')}>Edit</li>
      <li onClick={(event) => onActionClick(event, 'viewLogs')}>Event Log</li>
      {row.quote_pdf_path && <li onClick={(event) => onActionClick(event, 'preview')}>Preview</li>}
      {row.status_id === 1 && <li onClick={(event) => onActionClick(event, 'sendQuote')}>Send Quote</li>}
      {[1, 2, 3, 4].includes(row.status_id) && (
        <li onClick={(event) => onActionClick(event, 'shareLink')}>Share Link</li>
      )}
      {row.status_id === 2 && <li onClick={(event) => onActionClick(event, 'acceptQuote')}>Accept Quote</li>}
      <li className='delete-action' onClick={(event) => onActionClick(event, 'cancel')}>
        Cancel
      </li>
    </ThreeDotActions>
  );
};

export default RowActions;
