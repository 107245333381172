import starIcon from "./icons/drivers/staricon.png";
import filter from "./icons/drivers/filter.png";
import arrowup from "./icons/drivers/arrowup.png";
import DatePicker from "./icons/drivers/date-picker.png";
import add from "./icons/drivers/add.png";
import up from "./icons/drivers/up.png";
import down from "./icons/drivers/down.png";
import icon from "./icons/drivers/icon.svg";
import warning from "./icons/drivers/waring.png";
import user from "./icons/equipment/person.png";
import buttonIcon from "./icons/equipment/button.png";
import bookmark from "./icons/drivers/bookmark.png";
import ColumnSorting from "./icons/equipment/column-sorting.png";
import GoodSignal from "./icons/equipment/Signal-medium.png";
import success from "./icons/equipment/successmsgicon.png";
import Err from "./icons/equipment/equipmentError.png";
import PoorSignal from "./icons/equipment/PoorSignal.png";
import StrongSignal from "./icons/equipment/StrongSignal.png";
import EquipmentProfileIcon from "./icons/equipment/equipmentProfileIcon.svg";
import menuIcon from "./icons/header/menu.png";
import menuOnhide from './icons/equipment/menuOnhide.png'
import menuOndisplay from './icons/equipment/menuOndisplay.png'
import trend from './icons/equipment/trend.png'
import data from './icons/equipment/data.png'
import doller from './icons/equipment/doller.png'
import Petrol from './icons/equipment/Petrol.svg'
import Profile from './icons/equipment/person.png'
import Mexico from './icons/equipment/Mexico.svg'
import Canada from './icons/equipment/Canada.svg'
import US from './icons/equipment/US.svg'
import bookmarkfilled from './icons/equipment/bookmarkfilled.png'
import editTabs from './icons/equipment/editTabs.png'
import Battery from './icons/equipment/battery.png'
import SettingFilled from './icons/equipment/settings-filled.svg'
import TrashFilled from './icons/equipment/trash-filled.png'
import VehiclePlusTrailer from './icons/equipment/VehiclePlusTrailer.svg'
import Trailers from './icons/equipment/Trailer.svg'
import LocationStep from './icons/equipment/location-Step.svg'
import PopUp from './icons/equipment/pop-up.png'
import More from './icons/drivers/more.svg'
import NotMoving from './icons/equipment/notMoving.png'
import stepA from './icons/equipment/stepA.png'
import stepB from './icons/equipment/stepB.png'
import frame from './icons/equipment/frame.svg'
import attachfile from './icons/equipment/attach-icon.svg'
import calendar from './icons/equipment/calendar.png'
import search from './icons/equipment/Search.png'
import AddBlueIcon from './icons/equipment/add-blue-icon.png'
import MapLayers from './icons/equipment/overlay.png'
import MapZoomingPlus from './icons/equipment/map-zooming-plus.png'
import MapZoomingMinus from './icons/equipment/map-zooming-minus.png'
import Vehicle from './icons/equipment/Vehicle.svg'
import ZoomOut from './icons/equipment/Zoomout.png'
import ZoomIn from './icons/equipment/zooming-in.png'
import Downloads from './icons/equipment/download.svg'
import DownloadIcon from './icons/download2.svg'
import Doc from './icons/equipment/doc.png'
import Right from './icons/drivers/right.svg'
import CrossRedIcon from './icons/equipment/cross-red-icon.png'
import Hardware from './icons/equipment/Hardware.svg'
import Refresh from './icons/drivers/refresh.png'
import Cart from './icons/equipment/cart.svg'
import Minus from './icons/minus.svg'
import Plus from './icons/plus.svg'
import HardwareD from './icons/equipment/Hardware.svg';
import SamaraDoorMonitor from './icons/equipment/samaraDoorMonitor.svg';
import SamaraELD from './icons/equipment/SamaraELD.svg';
import Add from './icons/equipment/Add.svg';
import Email from './icons/equipment/Email.svg'
import Phone from './icons/equipment/Phone.svg'
import SamsaraCam from './icons/equipment/Samsara-cam.svg'
import LowBattery from './icons/equipment/LowBattery.svg'
import MediumBattery from './icons/equipment/MediumBattery.svg'
import steps from './icons/equipment/Step.png'
import Location from './icons/equipment/location.svg'
import Maintenance from './icons/equipment/maintenance.svg'
import EnginIcon from './icons/equipment/enginicon.svg'
import Driver from './icons/equipment/Driver.svg'
import RedSteps from './icons/equipment/RedPoint.svg'
import ConnectV from './icons/equipment/connect.png'
import LinebtwPoint from "./icons/equipment/linebtwpoint.svg";
import DotbtwPoint from "./icons/equipment/dotbtwpoint.svg";
import AddPointIcon from "./icons/equipment/AddpointIcon.svg";
import Available from "./icons/Available.svg";
import OnShipment from "./icons/OnShipment.svg";
import OnBreak from "./icons/OnBreak.svg";
import NotAssigned from './icons/NotAssigned.svg'
import DorpDownArrow from './icons/DropDownArrow.svg'
import GreyVehicle from './icons/equipment/GreyVehicle.svg'
import GreyColorVehicleTrailer from './icons/equipment/GreyVehiclePlusTrailer.svg'
import GreenCircleGreenSquare from './icons/equipment/GreenCircleGreenSquare.svg'
import GreenSquare from './icons/equipment/GreenSquare.svg'
import GreyColorTrailer from './icons/equipment/GreyTrailer.svg'
import LastStopImg from './icons/marker.svg'
import StopPointNew from "./icons/equipment/StopPointNewIcon.png"
import GreenCircle from "./icons/equipment/GreenCircle.svg"
import PurpleSquare from "./icons/equipment/PurpleSquare.svg"
import Search from "./icons/SearchIcon.png"
import ImportIcon from './icons/equipment/ImportIcon.png'
import UploadIcon from './icons/upload.svg'
import Samsara from './images/Samsara.png'
import StopPoint from './icons/createShipment/MapMarker.svg'
import StopPointGreen from './icons/createShipment/mapMarkerGreen.svg'
import MoreVerticle from './icons/vehicles/more.svg'
import Info from 'assets/icons/info.svg'

export const Icons = {
  Mexico,
  Samsara,
  MoreVerticle,
  Search,
  ImportIcon,
  StopPointNew,
  LastStopImg,
  GreyVehicle,
  GreyColorTrailer,
  GreyColorVehicleTrailer,
  DorpDownArrow,
  OnShipment,
  OnBreak,
  Available,
  NotAssigned,
  AddPointIcon,
  RedSteps,
  LinebtwPoint,
  DotbtwPoint,
  ConnectV,
  Trailers,
  Driver,
  EnginIcon,
  Location,
  HardwareD,
  Maintenance,
  steps,
  Email,
  Petrol,
  SamsaraCam,
  Phone,
  LowBattery,
  MediumBattery,
  SamaraELD,
  SamaraDoorMonitor,
  VehiclePlusTrailer,
  Add,
  Minus,
  Plus,
  Canada,
  attachfile,
  Refresh,
  Hardware,
  MapZoomingMinus,
  calendar,
  Cart,
  US,
  Downloads,
  DownloadIcon,
  MapLayers,
  CrossRedIcon,
  AddBlueIcon,
  frame,
  search,
  ZoomOut,
  ZoomIn,
  MapZoomingPlus,
  NotMoving,
  stepA,
  stepB,
  PopUp,
  starIcon,
  StrongSignal,
  PoorSignal,
  filter,
  arrowup,
  LocationStep,
  SettingFilled,
  add,
  up,
  More,
  down,
  Right,
  icon,
  bookmark,
  warning,
  user,
  buttonIcon,
  editTabs,
  DatePicker,
  ColumnSorting,
  GoodSignal,
  success,
  Err,
  TrashFilled,
  menuIcon,
  data,
  menuOnhide,
  doller,
  menuOndisplay,
  trend,
  bookmarkfilled,
  EquipmentProfileIcon,
  Battery,
  Profile,
  Vehicle,
  Doc,
  UploadIcon,
  GreenCircle,
  PurpleSquare,
  GreenCircleGreenSquare,
  StopPoint,
  StopPointGreen,
  GreenSquare,
  Info
};

const WhiteAlert = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 8C15.5 12.1422 12.1423 15.5 8 15.5C3.8577 15.5 0.5 12.1422 0.5 8C0.5 3.85781 3.8577 0.5 8 0.5C12.1423 0.5 15.5 3.85781 15.5 8ZM6.37799 6.32184H9.02997V11.9711C9.02997 12.5179 8.58667 12.9612 8.03983 12.9612C7.49298 12.9612 7.04968 12.5179 7.04968 11.9711V8.07782H6.37799C5.89309 8.07782 5.5 7.68473 5.5 7.19983C5.5 6.71493 5.89309 6.32184 6.37799 6.32184ZM6.76221 4.16559C6.76221 4.00973 6.7899 3.86436 6.84483 3.72949C6.90431 3.59045 6.98466 3.47036 7.08636 3.36924C7.18805 3.26812 7.30655 3.1881 7.44229 3.12907C7.50403 3.103 7.56759 3.08269 7.63342 3.06813C7.71696 3.04984 7.80367 3.04059 7.89356 3.04059C8.04611 3.04059 8.19002 3.07004 8.32577 3.12907C8.36254 3.14498 8.39795 3.16248 8.432 3.18144C8.52416 3.23279 8.60724 3.29543 8.6817 3.36924C8.78339 3.47036 8.86375 3.59045 8.92322 3.72949C8.9541 3.79981 8.97725 3.87294 8.99178 3.94889C9.0054 4.01876 9.01221 4.09099 9.01221 4.16559C9.01221 4.24956 9.00358 4.33116 8.98633 4.41027C8.9718 4.47799 8.95046 4.5439 8.92322 4.608C8.86375 4.74287 8.78339 4.86081 8.6817 4.96194C8.62949 5.01374 8.57319 5.06001 8.51236 5.10076C8.45425 5.13947 8.39205 5.17333 8.32577 5.20211C8.19002 5.26113 8.04611 5.29059 7.89356 5.29059C7.81411 5.29059 7.73739 5.28348 7.66339 5.26914C7.58666 5.25447 7.51311 5.23213 7.44229 5.20211C7.30655 5.14308 7.18805 5.06306 7.08636 4.96194C6.98466 4.86081 6.90431 4.74287 6.84483 4.608C6.7899 4.46896 6.76221 4.32145 6.76221 4.16559Z"
      fill="#DADCFF"
    />
  </svg>
);
const reserveEquipment = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2C0.5 0.895431 1.39543 0 2.5 0H9.5C10.6046 0 11.5 0.895431 11.5 2V13.5993C11.5 13.8965 11.1873 14.0899 10.9213 13.9572L6.4467 11.723C6.16546 11.5826 5.83454 11.5826 5.55329 11.723L1.07868 13.9572C0.812734 14.0899 0.5 13.8965 0.5 13.5993V2Z"
      fill="#767FFB"
    />
  </svg>
);
const engineEquipment = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z"
      fill="#38A06C"
    />
  </svg>
);
const alertMsg = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z"
      fill="#C97A20"
    />
    <path
      d="M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z"
      fill="#C97A20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z"
      fill="#C97A20"
    />
  </svg>
);
const PetrolIcon = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.1999 2.2001C1.1999 1.31644 1.91625 0.600098 2.7999 0.600098H5.9999C6.88356 0.600098 7.5999 1.31644 7.5999 2.2001V5.56848C7.81117 5.50365 8.0517 5.53062 8.20212 5.57092C8.59788 5.66617 9.03942 5.92449 9.05461 6.55018L9.05468 10.3858C9.05468 10.7666 9.42007 11.0794 9.84633 11.0794C10.2726 11.0794 10.6227 10.7666 10.6227 10.3858V10.1818L10.4553 5.62529L10.4402 5.27169C10.4402 5.27169 10.3183 3.96606 9.80077 3.10918C9.75542 3.02816 9.695 2.94714 9.63457 2.86611L9.63331 2.86442C9.49629 2.67401 9.25271 2.42914 8.99392 2.18438C8.92873 2.11784 8.77819 2.00045 8.65017 1.90061C8.56881 1.83717 8.49653 1.78081 8.46104 1.7491C8.46104 1.7491 8.41538 1.70831 8.41538 1.66751V1.50433C8.41538 1.44987 8.46104 1.40908 8.5067 1.40908C8.53718 1.39542 8.58284 1.39542 8.65898 1.42264C8.6666 1.42949 8.67805 1.43291 8.6895 1.43632C8.70088 1.43972 8.71226 1.44311 8.71983 1.44987C8.71983 1.44987 10.4401 2.04824 11.2013 3.50357C11.3384 3.73479 11.4602 4.15639 11.521 4.41483L11.5284 4.43858C11.578 4.599 11.6787 4.92421 11.4906 5.04051C11.2429 5.21255 11.2439 5.34012 11.2464 5.66409C11.2467 5.69866 11.247 5.73557 11.247 5.77491L11.4144 10.372C11.4144 11.1609 10.6989 11.8001 9.81593 11.8001C8.93294 11.8001 8.21743 11.1608 8.21743 10.372V6.63179C8.20226 6.50941 8.06527 6.40059 7.91299 6.40059C7.85214 6.40059 7.79119 6.41415 7.74553 6.44138L7.69987 6.48218C7.65378 6.52335 7.61568 6.57236 7.5999 6.62917V12.2001H7.7999C8.13127 12.2001 8.3999 12.4687 8.3999 12.8001C8.3999 13.1315 8.13127 13.4001 7.7999 13.4001H0.999902C0.668531 13.4001 0.399902 13.1315 0.399902 12.8001C0.399902 12.4687 0.668532 12.2001 0.999902 12.2001H1.1999V2.2001ZM2.3999 2.6001C2.3999 2.15827 2.75807 1.8001 3.1999 1.8001H5.5999C6.04173 1.8001 6.3999 2.15827 6.3999 2.6001V5.4001C6.3999 5.84193 6.04173 6.2001 5.5999 6.2001H3.1999C2.75807 6.2001 2.3999 5.84193 2.3999 5.4001V2.6001Z"
      fill="#868FA0"
    />
  </svg>
);
const satelight = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.20022 2.25556C9.26833 2.11572 9.40323 2.01964 9.55849 2.00042C9.5731 1.9998 9.58789 1.99989 9.60261 2.00051C11.8881 2.00051 13.75 3.85041 13.75 6.1212C13.7616 6.24928 13.7185 6.37624 13.6312 6.4712C13.5438 6.56606 13.4203 6.62013 13.2909 6.62013C13.1615 6.62013 13.038 6.56606 12.9507 6.4712C12.8633 6.37624 12.8202 6.24928 12.8317 6.1212C12.8317 4.34522 11.39 2.91273 9.60252 2.91273C9.44623 2.90854 9.3027 2.82574 9.22141 2.69296C9.14013 2.56018 9.13211 2.3955 9.20022 2.25556Z"
      fill="#38A06C"
    />
    <path
      d="M9.20301 4.05788C9.28543 3.916 9.4377 3.8286 9.60252 3.8286C10.8699 3.82868 11.91 4.86194 11.9099 6.12107C11.9099 6.28482 11.822 6.43611 11.6792 6.51799C11.5365 6.59986 11.3606 6.59986 11.2179 6.51799C11.075 6.43611 10.9872 6.28482 10.9872 6.12107C10.9872 5.35688 10.3717 4.74541 9.60252 4.74541C9.4377 4.74541 9.28542 4.65812 9.20301 4.51624C9.1206 4.37446 9.1206 4.19966 9.20301 4.05788Z"
      fill="#38A06C"
    />
    <path
      d="M9.83429 6.9169C9.83429 7.50062 9.358 7.97382 8.77046 7.97382C8.18292 7.97382 7.70663 7.50062 7.70663 6.9169C7.70663 6.33318 8.18292 5.85999 8.77046 5.85999C9.358 5.85999 9.83429 6.33318 9.83429 6.9169Z"
      fill="#38A06C"
    />
    <path
      d="M3.44775 6.29748C3.63558 6.11117 3.89107 6.00436 4.15588 6.00052C4.43149 5.99668 4.69648 6.10398 4.89136 6.29752L9.21744 10.5955C9.61285 10.9884 9.61269 11.6364 9.21744 12.0297C8.45321 12.7901 7.4155 13.2193 6.33293 13.2196C5.86689 13.2198 5.41829 13.1411 5 12.9963V13.2504C5 13.5945 4.76832 13.8844 4.45247 13.9728C4.39798 13.9907 4.33975 14.0004 4.27925 14.0004L4.26574 14.0003L4.25 14.0004C3.83579 14.0004 3.5 13.6646 3.5 13.2504V12.077C2.73023 11.3378 2.24961 10.303 2.25 9.16323C2.25031 8.0877 2.6823 7.05674 3.44775 6.29748Z"
      fill="#38A06C"
    />
  </svg>
);
const Cross = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79114 0.721559C1.49577 0.426193 1.01689 0.426193 0.721524 0.721559C0.426158 1.01693 0.426159 1.49581 0.721524 1.79117L3.93037 5.00002L0.721555 8.20883C0.426189 8.50419 0.426189 8.98308 0.721555 9.27844C1.01692 9.57381 1.4958 9.57381 1.79117 9.27844L4.99998 6.06963L8.20883 9.27848C8.50419 9.57384 8.98308 9.57384 9.27844 9.27848C9.57381 8.98311 9.57381 8.50423 9.27844 8.20886L6.06959 5.00002L9.27847 1.79114C9.57384 1.49577 9.57384 1.01689 9.27847 0.721524C8.9831 0.426158 8.50422 0.426159 8.20886 0.721524L4.99998 3.9304L1.79114 0.721559Z"
      fill="#D12953
"
    />
  </svg>
);
const Speed = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 8C15.5 12.1422 12.1423 15.5 8 15.5C3.8577 15.5 0.5 12.1422 0.5 8C0.5 3.85781 3.8577 0.5 8 0.5C12.1423 0.5 15.5 3.85781 15.5 8ZM6.37799 6.32184H9.02997V11.9711C9.02997 12.5179 8.58667 12.9612 8.03983 12.9612C7.49299 12.9612 7.04968 12.5179 7.04968 11.9711V8.07782H6.37799C5.89309 8.07782 5.5 7.68473 5.5 7.19983C5.5 6.71493 5.89309 6.32184 6.37799 6.32184ZM6.76221 4.16559C6.76221 4.00973 6.7899 3.86436 6.84483 3.72949C6.90431 3.59045 6.98466 3.47036 7.08636 3.36924C7.18805 3.26812 7.30655 3.1881 7.44229 3.12907C7.50404 3.103 7.56759 3.08269 7.63342 3.06813C7.71696 3.04984 7.80367 3.04059 7.89356 3.04059C8.04611 3.04059 8.19002 3.07004 8.32577 3.12907C8.36254 3.14499 8.39795 3.16248 8.432 3.18144C8.52416 3.23279 8.60724 3.29543 8.6817 3.36924C8.78339 3.47036 8.86375 3.59045 8.92322 3.72949C8.9541 3.79981 8.97725 3.87294 8.99178 3.94889C9.0054 4.01876 9.01221 4.09099 9.01221 4.16559C9.01221 4.24956 9.00358 4.33116 8.98633 4.41027C8.9718 4.47799 8.95046 4.5439 8.92322 4.608C8.86375 4.74287 8.78339 4.86081 8.6817 4.96194C8.62949 5.01374 8.57319 5.06001 8.51236 5.10076C8.45425 5.13947 8.39205 5.17333 8.32577 5.20211C8.19002 5.26113 8.04611 5.29059 7.89356 5.29059C7.81411 5.29059 7.73739 5.28348 7.66339 5.26914C7.58666 5.25447 7.51311 5.23213 7.44229 5.20211C7.30655 5.14308 7.18805 5.06306 7.08636 4.96194C6.98466 4.86081 6.90431 4.74287 6.84483 4.608C6.7899 4.46896 6.76221 4.32145 6.76221 4.16559Z"
      fill="#4F5AED"
    />
  </svg>
);
const MobileIcon =  <svg
width="16"
height="16"
viewBox="0 0 16 16"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  fillRule="evenodd"
  clipRule="evenodd"
  d="M13.0001 3C13 1.89544 12.1046 1.00001 11.0001 1.00001L5.00004 1C3.89547 1 3.00004 1.89543 3.00003 3L3 13C3 14.1046 3.89543 15 5 15H11.0001C12.1047 15 13.0001 14.1046 13.0001 13L13.0001 3ZM11.5 11.5C11.5 11.7761 11.2761 12 11 12H4.99999C4.72385 12 4.49999 11.7761 4.49999 11.5L4.5 3C4.5 2.72386 4.72386 2.5 5 2.5L6 2.5L6.15532 3.12127C6.21096 3.34385 6.41095 3.5 6.64039 3.5H9.35961C9.58905 3.5 9.78904 3.34385 9.84468 3.12127L10 2.5L11 2.5C11.2761 2.5 11.5 2.72386 11.5 3L11.5 11.5ZM7.24462 13.5C7.24462 13.9074 7.58698 14.25 7.99462 14.25C8.402 14.25 8.74435 13.9238 8.74462 13.5C8.74462 13.0924 8.402 12.75 7.99462 12.75C7.58698 12.75 7.24462 13.0924 7.24462 13.5Z"
  fill="#38A06C"
/>
</svg>
const equipmentSave = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M11.2566 0.696937C11.562 0.97671 11.5829 1.45113 11.3031 1.75658C9.70642 3.49978 8.92632 4.57968 7.77309 6.6432C7.32867 7.43841 6.84517 8.52389 6.46682 9.42869C6.2792 9.87738 6.12024 10.2748 6.00826 10.56C5.9523 10.7024 5.90815 10.8167 5.87811 10.895L5.84393 10.9844L5.83533 11.0071L5.83277 11.0139C5.7397 11.2612 5.52321 11.4416 5.26307 11.4882C5.00292 11.5349 4.73744 11.4411 4.56424 11.2415L0.68352 6.76883C0.412063 6.45596 0.445632 5.98228 0.758497 5.71082C1.07136 5.43936 1.54505 5.47293 1.8165 5.7858L4.887 9.32468C4.94789 9.17528 5.01349 9.01609 5.08294 8.85002C5.46531 7.9356 5.97564 6.78473 6.4637 5.91142C7.67357 3.74656 8.52187 2.57224 10.1969 0.743431C10.4767 0.43798 10.9511 0.417164 11.2566 0.696937Z" fill="#4F5AED"/>
</svg>
export {
  WhiteAlert,
  reserveEquipment,
  engineEquipment,
  alertMsg,
  Speed,
  PetrolIcon,
  satelight,
  Cross,
  MobileIcon,
  equipmentSave
};
