import moment from 'moment';

export const getInitialValues = (absence) => {
  const { reason, absence_start_date, absence_end_date, absence_details, internal_notes, type, absence_document } =
    absence || {};

  return {
    driver_staff_id: null,
    reason_title: reason || '',
    absence_start_date: absence_start_date ? moment(absence_start_date).toDate() : null,
    absence_end_date: absence_end_date ? moment(absence_end_date).toDate() : null,
    absence_details: absence_details || '',
    internal_notes: internal_notes || '',
    type: type || '1',
    absence_document: absence_document || '',
  };
};
