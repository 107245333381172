import React, { useState, useLayoutEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Input from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { blockNotNumberChars } from 'utils/helpers';
import { SAddMore, SInput, SMaskWrapper } from '../Steps.styles';

const BasicInfo = ({ form }) => {
  const matches = useMediaQuery('(max-width:768px)');
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);
  const { values, setValues, handleChange, handleBlur, touchedErrors } = form;

  const getMaskedValue = (value) => {
    return value.split('').map((item, index) => {
      if (index < 5) {
        return <span>*</span>;
      }

      return <span>{item}</span>;
    });
  };

  const handleAddressChange = (event, value, index) => {
    const addressFieldsMapper = {
      address: 'address1',
      address2: 'address2',
      city: 'city',
      state: 'state',
      country: 'country',
      zipcode: 'zipcode',
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(`addresses.${index}.${addressFieldsMapper[name]}`, value);
    } else {
      handleChange(`addresses.${index}.${addressFieldsMapper[event]}`, value);
    }
  };

  const addNewAddress = () => {
    handleChange(`addresses.${values.addresses.length}`, {
      id: Math.random(),
      start_date: null,
      end_date: null,
      current: 0,
      address1: '',
      address2: '',
      country: null,
      state: null,
      city: null,
      zipcode: '',
    });
  };

  const removeForm = (i) => {
    const newValues = values.addresses.filter((item, index) => index !== i);
    handleChange('addresses', newValues);
  };

  useLayoutEffect(() => {
    setInputWidth(inputRef?.current?.offsetWidth);
  }, []);

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12} sm={6}>
          <Input
            name='fullName'
            placeholder='Brandon Westervelt'
            label='Full Name'
            onChange={handleChange}
            value={values.fullName}
            error={touchedErrors.fullName}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SMaskWrapper $maxWidth={inputWidth - 24}>
            <SInput
              autoComplete='off'
              type='number'
              name='socialNumber'
              placeholder='000-00-0000'
              label='Social Security Number'
              onChange={(e) => {
                if (e.target.value.length > 9) {
                  return;
                }
                handleChange(e);
              }}
              onKeyDown={blockNotNumberChars}
              onCopy={(e) => e.preventDefault()}
              value={values.socialNumber}
              error={touchedErrors.socialNumber}
              required
              ref={inputRef}
            />
            <div className='input-mask' onClick={() => inputRef?.current?.focus()}>
              {getMaskedValue(values.socialNumber)}
            </div>
          </SMaskWrapper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            name='phoneNumber'
            placeholder='+1 (555) 555-1234'
            label='Phone Number'
            onChange={(val) => handleChange('phoneNumber', val)}
            value={values.phoneNumber}
            error={touchedErrors.phoneNumber}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='email'
            placeholder='example@email.com'
            label='Email Address'
            onChange={handleChange}
            value={values.email}
            error={touchedErrors.email}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            required
            width='100%'
            name='birthDate'
            label='Date Of Birth'
            disableFuture
            onBlur={handleBlur}
            value={values.birthDate}
            onChange={(val) => handleChange('birthDate', val)}
            error={touchedErrors.birthDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
          <Input
            name='emergency_contact_first_name'
            placeholder='Jeffrey'
            label='Emergency Contact First Name'
            onChange={handleChange}
            value={values.emergency_contact_first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='emergency_contact_last_name'
            placeholder='Westervelt'
            label='Emergency Contact Last Name'
            onChange={handleChange}
            value={values.emergency_contact_last_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            name='emergency_contact_number'
            placeholder='+1 (555) 555-1234'
            label='Emergency Contact Phone Number'
            onChange={(val) => handleChange('emergency_contact_number', val)}
            value={values.emergency_contact_number}
            error={touchedErrors.emergency_contact_number}
          />
        </Grid>
        {!matches && <Grid item xs={6} />}
        {values.addresses.map((address, i) => (
          <Grid key={address.id} item xs={12}>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div className='d-flex align-items-center gap-5'>
                <InputLabel required={i === 0 || !address.noPreviousAddress} className='mb-0'>
                  {address.current ? 'Current' : 'Previous'} Address
                </InputLabel>
                <div className='d-flex align-items-center gap-2'>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    From
                  </Typography>
                  <DatePicker
                    width='160px'
                    name={`addresses.${i}.start_date`}
                    value={address.start_date}
                    onChange={(date) => handleChange(`addresses.${i}.start_date`, date)}
                    onBlur={handleBlur}
                    disableFuture
                    error={touchedErrors?.addresses?.[i]?.start_date}
                    disabled={address.noPreviousAddress && i > 0}
                  />
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    To
                  </Typography>
                  <DatePicker
                    width='160px'
                    name={`addresses.${i}.end_date`}
                    value={address.end_date}
                    onChange={(date) => handleChange(`addresses.${i}.end_date`, date)}
                    onBlur={handleBlur}
                    disableFuture
                    error={touchedErrors?.addresses?.[i]?.end_date}
                    disabled={address.noPreviousAddress && i > 0}
                  />
                </div>
                {i === 1 && (
                  <CustomCheckbox
                    checked={!!address.noPreviousAddress}
                    onChange={(checked) => {
                      if (checked) {
                        setValues((prevState) => ({
                          ...prevState,
                          addresses: [
                            prevState.addresses[0],
                            {
                              id: Math.random(),
                              start_date: null,
                              end_date: null,
                              current: 0,
                              address1: '',
                              address2: '',
                              country: null,
                              state: null,
                              city: null,
                              zipcode: '',
                              noPreviousAddress: checked,
                            },
                          ],
                        }));
                      } else {
                        handleChange(`addresses.${i}.noPreviousAddress`, checked);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      No previous address
                    </Typography>
                  </CustomCheckbox>
                )}
              </div>
              {i > 1 && (
                <DeleteIcon
                  width={12}
                  height={12}
                  fill={palette.red500}
                  onClick={() => removeForm(i)}
                  className='delete-icon'
                />
              )}
            </div>
            <AddressFields
              required
              hideLabel
              values={{
                address: address.address1,
                address2: address.address2,
                country: address.country,
                city: address.city,
                state: address.state,
                zipcode: address.zipcode,
              }}
              touchedErrors={{
                address: touchedErrors?.addresses?.[i]?.address1,
                address2: touchedErrors?.addresses?.[i]?.address2,
                country: touchedErrors?.addresses?.[i]?.country,
                city: touchedErrors?.addresses?.[i]?.city,
                state: touchedErrors?.addresses?.[i]?.state,
                zipcode: touchedErrors?.addresses?.[i]?.zipcode,
              }}
              handleChange={(e, val) => handleAddressChange(e, val, i)}
              disabled={address.noPreviousAddress && i > 0}
            />
          </Grid>
        ))}
        {!values.addresses?.[1]?.noPreviousAddress && (
          <Grid item xs={12} sm={6}>
            <SAddMore onClick={addNewAddress}>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Add another address...
              </Typography>
            </SAddMore>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default BasicInfo;
