import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import Search from 'common/Search';
import Pagination from 'common/Pagination';
import Autocomplete from 'common/Autocomplete';
import TableWrapper from 'components/TableWrapper';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import DateRangePicker from 'common/DateRangePicker';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getReceivablesTableData } from 'Api/AccountingReceivables';
import AddNotes from '../shared/AddNotes';
import UploadDocument from '../shared/UploadDocument';
import { filterOptions, initialFilters } from '../TableSection.data';
import { useColumns } from './MissingPaperwork.data';
import { SFiltersWrapper, SSearchWrapper } from '../../Receivables.styles';

const MissingPaperwork = ({ getCounts, stopPoints, customers, groups }) => {
  const { use } = useTheme();
  const [data, setData] = useState({ data: [] });
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [modalData, setModalData] = useState({});
  const [search, setSearch] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortByDate, setSortByDate] = useState(null);
  const [sort, setSort] = useState({ field: 'end_date_time', sortBy: 'desc' });
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [filterType, setFilterType] = useState(filterOptions[0]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const getTableData = async () => {
    try {
      setLoading(true);
      const { page, itemsPerPage } = selectedFilters || {};
      const filter = `filters[${filterType.value}]`;
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage,
        [filter]: debouncedSearch || undefined,
        'sort[][end_date_time]': sortByDate || undefined,
        'filters[tab]': 'missing_paperwork',
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.end ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[is_brokered]': selectedFilters.is_brokered ? 1 : undefined,
        'filters[customer_id]': selectedFilters.customers?.length
          ? selectedFilters.customers.map((i) => i.id)
          : undefined,
        'filters[group_id]': selectedFilters.groups?.length ? selectedFilters.groups.map((i) => i.id) : undefined,
        'filters[stop_point_id]': selectedFilters.stopPoints?.length
          ? selectedFilters.stopPoints.map((i) => i.id)
          : undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getReceivablesTableData(params);
      setData(response);
      getCounts();
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onUploadDocuments = (row) => {
    setModalData(row);
    setOpenUploadDocument(true);
  };

  const onViewNotes = (row) => {
    setModalData(row);
    setOpenAddNotes(true);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getTableData();
  }, [dateRange, selectedFilters, debouncedSearch, sortByDate, sort]);

  const columns = useColumns({ onUploadDocuments, onViewNotes, sort, sortingQuery });

  return (
    <>
      <SFiltersWrapper>
        <ThreeDotActions
          anchorEl={filterAnchorEl}
          setAnchorEl={setFilterAnchorEl}
          actionButton={
            <CustomButton
              type='secondary'
              title=''
              leftIcon={
                <TwoWaysIcon width={16} height={16} fill={palette.gray700} style={{ transform: 'rotate(90deg)' }} />
              }
              styleButton={{ padding: '8px 12px', margin: 0 }}
              onClick={() => null}
            />
          }
          popoverStyles={{ margin: '10px 0 0 70px' }}
        >
          <div style={{ padding: '0 8px' }}>
            <CustomRadioButton
              field={{
                name: 'sort_by_date',
                value: 'desc',
                onChange: () => setSortByDate('desc'),
                checked: sortByDate === 'desc',
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700) }}>
                Recent
              </Typography>
            </CustomRadioButton>
            <CustomRadioButton
              field={{
                name: 'sort_by_date',
                value: 'asc',
                onChange: () => setSortByDate('asc'),
                checked: sortByDate === 'asc',
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700) }}>
                Oldest
              </Typography>
            </CustomRadioButton>
          </div>
        </ThreeDotActions>
        <DateRangePicker
          top='-168px'
          dateRange={dateRange}
          initialRangeName='All Time'
          setDateRange={setDateRange}
          type='allTime'
        />
        <SSearchWrapper>
          <div>
            <Autocomplete
              width='150px'
              labelKey='label'
              options={filterOptions}
              value={filterType}
              onChange={(e, val) => {
                setFilterType(val);
                setSelectedFilters(initialFilters);
              }}
            />
          </div>
          {filterType?.value === 'customer' ? (
            <Autocomplete
              multiple
              width='380px'
              labelKey='company_name'
              disableClearable={false}
              options={customers}
              value={selectedFilters.customers}
              placeholder={selectedFilters.customers?.length ? '' : 'Select..'}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customers: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              className='filter-dropdown'
              limitTags={2}
            />
          ) : filterType?.value === 'stop_point' ? (
            <Autocomplete
              multiple
              width='380px'
              labelKey='location_name'
              disableClearable={false}
              options={stopPoints}
              value={selectedFilters.stopPoints}
              placeholder={selectedFilters.stopPoints?.length ? '' : 'Select..'}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, stopPoints: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              className='filter-dropdown'
              limitTags={2}
            />
          ) : filterType?.value === 'group' ? (
            <Autocomplete
              multiple
              width='380px'
              labelKey='group_name'
              disableClearable={false}
              options={groups}
              value={selectedFilters.groups}
              placeholder={selectedFilters.groups?.length ? '' : 'Select..'}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              className='filter-dropdown'
              limitTags={2}
            />
          ) : (
            <Search search={search} onChange={setSearch} className='search-input' />
          )}
        </SSearchWrapper>
        <div className='d-flex align-items-center gap-2'>
          <CustomCheckbox
            type='switch'
            name='is_brokered'
            checked={!!selectedFilters.is_brokered}
            onChange={(checked) => setSelectedFilters((prevState) => ({ ...prevState, is_brokered: checked }))}
            smail={false}
          />
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Brokered Shipment Only
          </Typography>
        </div>
      </SFiltersWrapper>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={data?.data}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: () =>
                Pagination({
                  data,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                  rowsPerPageOptions: [25, 50, 100, 150],
                }),
            }}
            onRowClick={(e, rowData) => onUploadDocuments(rowData)}
            columns={columns}
          />
        </div>
      )}
      {openUploadDocument && (
        <UploadDocument
          open={openUploadDocument}
          onClose={() => setOpenUploadDocument(false)}
          onSuccess={() => {
            getCounts();
            getTableData();
          }}
          shipmentData={modalData}
        />
      )}
      {openAddNotes && (
        <AddNotes
          open={openAddNotes}
          onClose={() => setOpenAddNotes(false)}
          data={modalData}
          onSuccess={getTableData}
        />
      )}
    </>
  );
};

export default MissingPaperwork;
