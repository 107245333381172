import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import CustomSelect from '../CustomSelect/CustomSelect';
import './RequestListAddModal.css';
import { requestListValidationSchema } from '../Drivers/AddDriver/ValidationSchema';

export default function RequestModal({ show, requestType, createRequest, createLoading, onHide }) {
  const { value } = useAuth();
  const { user } = value;
  const { currency } = useSelector((state) => state.root);
  const [type] = useState(null);
  const { use } = useTheme();

  const formik = useFormik({
    initialValues: {
      request_type: '',
      start_date: new Date(),
      end_date: new Date(),
      request_notes: '',
      request_amount: '',
      shipment_id: '',
    },
    validationSchema: requestListValidationSchema,
  });

  const saveRequest = () => {
    const { start_date, end_date } = formik.values;
    const startDate = start_date
      ? moment(start_date || new Date()).format('MM/DD/YYYY')
      : moment(new Date()).format('MM/DD/YYYY');
    const endDate = end_date
      ? moment(end_date || new Date()).format('MM/DD/YYYY')
      : moment(new Date()).format('MM/DD/YYYY');
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    let payload = {
      ...formik.values,
      driver_staff_id: user.id,
      user_type: 'staff',
      start_date: startDate,
      end_date: endDate,
    };
    if (!formik.values.request_type === 'Time Off') {
      payload = { ...payload, start_date: currentDate, end_date: currentDate };
    }
    if (
      formik.values.request_type === 'Need Maintenance' ||
      formik.values.request_type === 'Request Call from Dispatch' ||
      formik.values.request_type === 'Truck Broke Down'
    ) {
      payload = { ...payload, start_date: null, end_date: null };
    }
    createRequest(payload);
  };

  return (
    <div>
      <Modal
        show={show}
        dialogClassName='modal-80w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        enforceFocus={false}
        backdrop='static'
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Add Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='request-modal-body-box request-add-list'
          style={{
            background: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='request-modal-wrap'>
            <div className='request-container-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Request Type <span className='required'>*</span>
              </p>
              <CustomSelect
                options={requestType?.map((item) => item?.type)}
                defaultValue='Select Type...'
                value={formik.values.request_type}
                className='text-style ms-0  p-2 select-group'
                onChange={(value) => {
                  const getRequestType = requestType.find((type) => {
                    return type.type === value;
                  });
                  if (!getRequestType) {
                    return;
                  }

                  if (value === 'Pickup Number' || value === 'Delivery Number' || value === 'Time Off') {
                    formik.setFieldValue('request_amount', '');
                  }

                  if (
                    value === 'Time Off' ||
                    value === 'Cash Advance' ||
                    value === 'Time Off' ||
                    value === 'Fuel Advance'
                  ) {
                    formik.setFieldValue('shipment_id', '');
                  }
                  formik.setFieldValue('request_type', value);
                }}
                propertyName='type'
              />
            </div>
            <div className='request-container-wrapper'>
              <DatePicker
                required
                label='Request Start Date'
                name='start_date'
                value={formik.values.start_date}
                onChange={(date) => {
                  formik.setFieldValue('start_date', date);
                  if (moment(date).isValid() && moment(formik.values.end_date).isValid()) {
                    if (date.getTime() > formik.values.end_date.getTime()) {
                      formik.setFieldValue('end_date', date);
                    }
                  }
                }}
                disabled={
                  formik.values.request_type === 'Time Off'
                    ? false
                    : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                    ? true
                    : formik.values.request_type === 'Pickup Number' || formik.values.request_type === 'Delivery Number'
                    ? true
                    : formik.values.request_type === 'Need Maintenance'
                    ? true
                    : formik.values.request_type === 'Request Call from Dispatch'
                    ? true
                    : formik.values.request_type === 'Truck Broke Down'
                }
                onBlur={formik.handleBlur}
                disablePast
                error={formik.touched.start_date && formik.errors.start_date}
              />
            </div>
            <div className='request-container-wrapper'>
              <DatePicker
                required
                label='Request End Date'
                name='end_date'
                value={formik.values.end_date}
                onBlur={formik.handleBlur}
                onChange={(date) => {
                  formik.setFieldValue('end_date', date);
                  if (moment(date).isValid() && moment(formik.values.start_date).isValid()) {
                    if (date.getTime() < formik.values.start_date.getTime()) {
                      formik.setFieldValue('start_date', date);
                    }
                  }
                }}
                disabled={
                  formik.values.request_type === 'Time Off'
                    ? false
                    : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                    ? true
                    : formik.values.request_type === 'Pickup Number' || formik.values.request_type === 'Delivery Number'
                    ? true
                    : formik.values.request_type === 'Need Maintenance'
                    ? true
                    : formik.values.request_type === 'Request Call from Dispatch'
                    ? true
                    : formik.values.request_type === 'Truck Broke Down'
                }
                disablePast
                error={formik.touched.end_date && formik.errors.end_date}
              />
            </div>
            <div className='request-container-wrapper'>
              <InputLabel
                className='mb-0'
                required={
                  formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                }
              >
                Amount
              </InputLabel>
              <div>
                <input
                  type='number'
                  className='reason-input'
                  style={{
                    border: `1px solid ${palette.gray200}`,
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                  id='request_amount'
                  name='request_amount'
                  placeholder={`${currency} 0.00`}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue('request_amount', e.target.value);
                  }}
                  value={formik.values.request_amount}
                  disabled={
                    formik.values.request_type === 'Time Off'
                      ? true
                      : !!(
                          formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number' ||
                          formik.values.request_type === 'Need Maintenance' ||
                          formik.values.request_type === 'Truck Broke Down' ||
                          formik.values.request_type === 'Request Call from Dispatch' ||
                          formik.values.request_type === 'Change Scheduled Date and Time'
                        )
                  }
                />
              </div>
            </div>
            {type === 'driver' && (
              <div className='request-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{
                    marginLeft: '0',
                    color:
                      formik.values.request_type === 'Time Off'
                        ? use(palette.gray300, palette.gray200)
                        : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                        ? use(palette.gray300, palette.gray200)
                        : formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number'
                        ? use(palette.gray700, palette.gray200)
                        : use(palette.gray300, palette.gray200),
                  }}
                >
                  Shipment ID
                </p>
                <div>
                  <CustomSelect
                    options={[]}
                    defaultValue='Select Shipment Id...'
                    value={formik.values.shipment_id}
                    className='text-style ms-0  p-2 select-group'
                    onChange={(value) => {
                      formik.setFieldValue('shipment_id', value);
                    }}
                    propertyName='shipment_id'
                    disabled={
                      formik.values.request_type === 'Time Off'
                        ? true
                        : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                        ? true
                        : !(
                            formik.values.request_type === 'Pickup Number' ||
                            formik.values.request_type === 'Delivery Number'
                          )
                    }
                  />
                </div>
              </div>
            )}

            <div className='request-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ marginLeft: '0', color: palette.gray700 }}>
                Request Notes
              </p>
              <div>
                <textarea
                  className='request-text-area'
                  id='request_notes'
                  name='request_notes'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.request_notes}
                  placeholder='Write request notes'
                  style={{ background: palette.white, color: palette.gray900 }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.boxShadow2),
              }}
              onClick={onHide}
              disabled={createLoading}
            >
              Cancel
            </Button>
            {createLoading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                disabled={!formik.isValid || !!createLoading}
                onClick={saveRequest}
                className='next-step'
                type='submit'
              >
                Add Request
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
