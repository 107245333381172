import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getAllVehicles } from 'Api/Map';
import { assignVehicleToDriver } from 'Api/DriverV2';

const AssignVehicle = ({ open, onClose, driverId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(false);

  const getVehicles = async () => {
    try {
      const { data } = await getAllVehicles(null, null, null, { allFilterData: { status_id: 4 } });
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const assignVehicle = async () => {
    try {
      setLoading(true);
      const body = {
        driver_id: driverId,
        equipment_id: selectedVehicle.id,
      };

      await assignVehicleToDriver(body);
      showToaster({ type: 'success', message: 'Vehicle has been successfully assigned to driver!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Assign Vehicle'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Assign Vehicle',
          disabled: !selectedVehicle || loading,
          onClick: assignVehicle,
        },
      ]}
    >
      <Autocomplete
        required
        label='Vehicle'
        labelKey='equipment_id'
        placeholder='Select Vehicle'
        options={vehicles}
        value={selectedVehicle}
        getOptionLabel={(option) => {
          return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
        }}
        style={{ minWidth: 150 }}
        onChange={(e, val) => {
          setSelectedVehicle(val);
        }}
        isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
      />
    </Modal>
  );
};

export default AssignVehicle;
