import { date } from 'yup';
import moment from 'moment';
import set from 'lodash/set';
import PDFMerger from 'pdf-merger-js';
import { formatPhoneNumber as formatPhone, formatPhoneNumberIntl } from 'react-phone-number-input';
import { palette } from './constants';

export const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
export const phoneRegex2 =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // Doesn't allow + sign

export const regExp = {
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/,
  POSITIVE_NUMBER: /^[1-9]\d*$/,
};

export const blockNotNumberChars = (event) =>
  ['e', 'E', '+', '-', '.', ','].includes(event.key) && event.preventDefault();
export const blockNonPositiveNumbers = (event) =>
  ['e', 'E', '+', '-', 'ArrowUp', 'ArrowDown'].includes(event.key) && event.preventDefault();

export const getAppUrl = () => {
  if (window === undefined) {
    return null;
  }

  const pathArray = window.location.href.split('/');
  const protocol = pathArray[0];
  const host = pathArray[2];
  return `${protocol}//${host}`;
};

export const twoDecimals = (number) => {
  const splitNumber = number.toString().split('.');
  const rightSide = splitNumber[1]?.slice(0, 2);

  return `${splitNumber[0]}.${rightSide?.length === 1 ? `${rightSide}0` : rightSide || '00'}`;
};

export function formatAmount(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    /* empty */
  }
}

export const convertToCustomerTime = (date, format) => {
  const localData = JSON.parse(localStorage.getItem('user'));
  const timeZone = JSON.parse(localData?.customer?.timezone);
  const dateFormatted = timeZone.zone_name.replace(/ - /g, '/');

  return moment.tz(moment.utc(date), dateFormatted).format(format || 'MM/DD/YYYY');
};

export const customerTimeToUtc = (date, format) => {
  const localData = JSON.parse(localStorage.getItem('user'));
  const timeZone = JSON.parse(localData?.customer?.timezone);
  const dateFormatted = timeZone.zone_name.replace(/ - /g, '/');

  return moment
    .tz(date, dateFormatted)
    .utc()
    .format(format || 'YYYY-MM-DD HH:mm:ss');
};

export function sortAndConcatenate(arg1, arg2) {
  const arg1Parts = arg1.split('_');
  const arg2Parts = arg2.split('_');

  const arg1Num = parseInt(arg1Parts[arg1Parts.length - 1], 10);
  const arg2Num = parseInt(arg2Parts[arg2Parts.length - 1], 10);

  if (arg1Num < arg2Num) {
    return `${arg1}_${arg2}`;
  }
  return `${arg2}_${arg1}`;
}

export function getLastIdFromString(inputString) {
  const parts = inputString.split('_');
  const lastId = parts[parts.length - 1];
  return parseInt(lastId, 10);
}

export const downloadCsv = async (string, fileName = 'data.csv', onError = () => null) => {
  try {
    const url = window.URL.createObjectURL(new Blob([string], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    onError();
  }
};

export const weekdaysBetween = (d1, d2, isoWeekday) => {
  const start = moment(d1);
  const end = moment(d2);

  const daysToAdd = (7 + isoWeekday - start.isoWeekday()) % 7;
  const nextTuesday = start.clone().add(daysToAdd, 'days');

  if (nextTuesday.isAfter(end)) {
    return 0;
  }

  const weeksBetween = end.diff(nextTuesday, 'weeks');
  return weeksBetween + 1;
};

export function getShipmentType(type) {
  const shipmentTypeMap = {
    ltl: 'LTL',
    slave: 'LTL',
    'split-parent': 'MASTER SPLIT TL',
    'split-children': 'SPLIT',
  };

  return shipmentTypeMap[type] || 'TL';
}

export function getEquipmentRequiredTitle(type) {
  if (+type.id === 1) {
    return { ...type, title: 'Truck (Power Only)' };
  }
  return { ...type };
}

export function formatSeconds(seconds) {
  let totalSeconds = seconds;

  const days = Math.floor(totalSeconds / (24 * 3600));

  // If more than one day, return "24h+"
  if (days > 0) {
    return '24h+';
  }

  totalSeconds %= 24 * 3600;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);

  let result = '';
  if (hours) {
    result += `${hours}h `;
  }
  if (minutes) {
    if (minutes < 2 && !hours) {
      minutes = 1;
    }
    result += `${minutes}m`;
  }

  if (result === '') {
    return '1m';
  }

  return result.trim();
}

export function getIframeSource(input) {
  return new Promise((resolve, reject) => {
    if (input instanceof File) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(input);
    } else {
      resolve(input);
    }
  });
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const withCode = formatPhoneNumberIntl(phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`);
  const withoutCode = formatPhone(phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`);
  const countryCode = withCode.split(' ')[0];

  return `${countryCode} ${withoutCode}`;
};

export const downloadDirectDeposit = () => {
  const url =
    process.env.REACT_APP_BASE_URL === 'https://staging.truckindigital.com'
      ? 'https://truckindigital-staging.s3.amazonaws.com/Direct+Deposit+Authorization.doc'
      : 'https://truckindigital-production.s3.us-east-2.amazonaws.com/Direct+Deposit+Authorization.doc';

  window.open(url, '_blank');
};

export const removeCommas = (input) => {
  if (!input) {
    return '';
  }
  const stringInput = input.toString();

  return stringInput.replace(/,/g, '');
};

export const numberFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0.00';
};

export const getStopName = (type) => {
  const TYPE_STOPS = {
    1: { type: ' : PICK UP', color: palette.green500, title: 'pick up' },
    2: { type: ' : DELIVERY', color: palette.red500, title: 'Delivery' },
    3: { type: ' : WAYPOINT', color: palette.blueText, title: 'Waypoint' },
  };
  return TYPE_STOPS[type];
};

export const formatInputNumber = (value) => {
  const numericValue = value.replace(/[^0-9]/g, '');

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericValue / 100);
};

export function extractDates(inputStr) {
  const regex = /(\d{2}\/\d{2}\/\d{4}) - (\d{2}\/\d{2}\/\d{4})/;
  const match = inputStr.match(regex);

  if (match) {
    return {
      startDate: match[1],
      endDate: match[2],
    };
  }
  return null;
}

export function millisecondsToInterval(ms) {
  const millisecondsPerMinute = 60 * 1000;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;

  const days = Math.floor(ms / millisecondsPerDay);
  ms -= days * millisecondsPerDay;
  const hours = Math.floor(ms / millisecondsPerHour);
  ms -= hours * millisecondsPerHour;
  const minutes = Math.floor(ms / millisecondsPerMinute);

  let interval = '';
  if (days > 0) {
    interval += `${days}d `;
  }
  if (hours > 0) {
    interval += `${hours}h `;
  }
  if (minutes > 0) {
    interval += `${minutes}m`;
  }

  // Trim the output to remove any leading or trailing whitespace
  return interval.trim();
}

export const sumDurations = (durations) => {
  let totalMinutes = 0;
  for (const duration of durations) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    const parts = duration?.split(' ');
    for (const part of parts) {
      if (part) {
        if (part.endsWith('d')) {
          days = parseInt(part, 10);
        } else if (part.endsWith('h')) {
          hours = parseInt(part, 10);
        } else if (part.endsWith('m')) {
          minutes = parseInt(part, 10);
        }
      }
    }
    totalMinutes += days * 24 * 60 + hours * 60 + minutes;
  }
  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return `${days}d ${hours}h ${minutes}m`;
};

export const getHeaders = (standardizedStr, mapper) => {
  const str = standardizedStr.replace(/;/g, ',');
  const lines = str.split('\n');

  const headers = lines[0].split(',').map((heading) => mapper[heading.trim()] || heading.trim());

  const rows = lines.slice(1).filter((val) => val);

  return rows.map((row) => {
    const values = row.split(',');
    const rowObject = {};
    let columnIndex = 0;
    let tempValue = '';

    for (const value of values) {
      if (value.startsWith('"')) {
        tempValue = value.slice(1);
      } else if (value.endsWith('"')) {
        tempValue += `,${value.slice(0, -1)}`;
        rowObject[headers[columnIndex]] = tempValue;
        tempValue = '';
        columnIndex++;
      } else if (tempValue) {
        tempValue += `,${value}`;
      } else {
        rowObject[headers[columnIndex]] = value;
        columnIndex++;
      }
    }

    return rowObject;
  });
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const getGroupShortName = (groupName) => {
  const words = groupName.split(/\s+/);

  if (words.length >= 4) {
    const shortName = words
      .slice(0, 4)
      .map((word) => word[0])
      .join('');
    return shortName.toUpperCase();
  }
  const withoutSpaces = groupName.replaceAll(' ', '');

  if (withoutSpaces.length < 5) {
    return withoutSpaces.toUpperCase();
  }

  const firstThreeChars = withoutSpaces.slice(0, 3).toUpperCase();
  const lastChar = withoutSpaces[withoutSpaces.length - 1].toUpperCase();

  return `${firstThreeChars}${lastChar}`;
};

export const getMergedUrl = async (docs) => {
  try {
    const merger = new PDFMerger();

    for (const file of docs) {
      await merger.add(file);
    }

    const mergedPdf = await merger.saveAsBlob();
    return { url: URL.createObjectURL(mergedPdf), blob: mergedPdf };
  } catch (e) {
    // Do nothing
  }
};

export const checkPasswordStrength = (password) => {
  let strength = 0;
  const criteria = [
    { regex: /.{8,}/, message: 'At least 8 characters' },
    { regex: /[A-Z]/, message: 'At least one uppercase letter' },
    { regex: /[a-z]/, message: 'At least one lowercase letter' },
    { regex: /[0-9]/, message: 'At least one number' },
    { regex: /[^A-Za-z0-9]/, message: 'At least one special character' },
  ];

  criteria.forEach((criterion) => {
    if (criterion.regex.test(password)) {
      strength += 1;
    }
  });

  switch (strength) {
    case 5:
      return { strength: 'Strong', color: palette.green500 };
    case 4:
      return { strength: 'Good', color: palette.green300 };
    case 3:
      return { strength: 'Medium', color: palette.amber600 };
    default:
      return { strength: 'Weak', color: palette.red500 };
  }
};

export const validator = {
  date: () => date().typeError('Invalid Date').nullable(),
  requiredDate: (message) =>
    date()
      .required(message || 'Required')
      .typeError('Invalid Date')
      .nullable(),
  pastDate: (message) =>
    date()
      .typeError('Invalid Date')
      .max(moment().endOf('day').toDate(), message || `Date can't be in the future`)
      .nullable(),
  pastRequiredDate: (message) =>
    date()
      .required(message || 'Required')
      .typeError('Invalid Date')
      .max(moment().endOf('day').toDate(), `Date can't be in the future`)
      .nullable(),
  futureDate: (message) =>
    date()
      .typeError('Invalid Date')
      .min(moment().startOf('day').toDate(), message || `Date can't be in the past`)
      .nullable(),
  futureRequiredDate: (message) =>
    date()
      .required(message || 'Required')
      .typeError('Invalid Date')
      .min(moment().startOf('day').toDate(), `Date can't be in the past`)
      .nullable(),
};

export const transformYupErrorsIntoObject = (errors) => {
  const validationErrors = {};
  const validationTouched = {};

  errors?.inner?.forEach((error) => {
    if (error.path !== undefined) {
      set(validationErrors, error.path, error?.errors?.[0]);
      set(validationTouched, error.path, true);
    }
  });

  return { validationErrors, validationTouched };
};
