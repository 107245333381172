import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useDateFormat from 'hooks/useDateFormat';
import { convertToCustomerTime } from 'utils/helpers';
import UserInfo from '../UserInfo';

const ActivityLogList = ({ activityLog }) => {
  const { dateTimeFormat } = useDateFormat();

  return (
    <List sx={{ width: '100%' }}>
      {activityLog?.map((item) => {
        return (
          <div key={item.id}>
            <ListItem alignItems='flex-start' style={{ paddingLeft: 0 }}>
              <UserInfo
                hideName
                hidePopup={!item.change_by_user_details && !item.change_by_driver_details}
                data={{
                  id: item.change_by_user_id,
                  image:
                    item.change_by_user_type === 'staff'
                      ? item?.change_by_user_details?.profile_logo
                      : item?.change_by_driver_details?.profile_logo,
                  phone_number:
                    item.change_by_user_type === 'staff'
                      ? item.change_by_user_details?.phone_number
                      : item?.change_by_driver_details?.phone_number,
                  email:
                    item.change_by_user_type === 'staff'
                      ? item.change_by_user_details?.email
                      : item?.change_by_driver_details?.email,
                  status:
                    item.change_by_user_type === 'staff'
                      ? item.change_by_user_details?.login_status
                      : item?.change_by_driver_details?.status,
                  name:
                    item.change_by_user_type === 'staff' && item.change_by_user_details
                      ? `${item.change_by_user_details?.first_name} ${item.change_by_user_details?.last_name}`
                      : item?.change_by_driver_details
                      ? `${item?.change_by_driver_details?.first_name} ${
                          item?.change_by_driver_details?.last_name ? item?.change_by_driver_details?.last_name : ''
                        }`
                      : null,
                  user_type: item.change_by_user_type || 'staff',
                }}
                additionalInfo={
                  <ListItemText
                    sx={{ fontSize: '0.9rem' }}
                    primary={item.change_to}
                    secondary={convertToCustomerTime(item?.created_at, dateTimeFormat)}
                  />
                }
              />
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};

export default ActivityLogList;
