import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import cross from 'assets/icons/drivers/cross.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import right from 'assets/icons/drivers/right.svg';
import { UpdateCompensationOwner, AddCompensationOwner, getTaxType, uploadDriverDocument } from 'Api/Driver';
import { getErrorMessage } from 'utils/error';
import { saveFinishLater } from 'Api/OwnerOperatorV2';
import ImageDropZone from '../../ImgageDropZone/index';
import CustomSelect from '../../CustomSelect/CustomSelect';
import { CompensationOwnerValidationSchema } from '../AddDriver/ValidationSchema';

const paymentConditions = [
  {
    paymentCondition: 'Detention Pay;',
    timePeriod: 'per hour',
    after: 'hour(s) has passed',
    name1: 'detention_pay_option',
    name2: 'detention_amt',
    name3: 'detention_hrs',
  },
  {
    paymentCondition: 'Layover Pay',
    timePeriod: 'for each layover',
    name1: 'layover_option',
    name2: 'layover_amt',
  },
  {
    paymentCondition: 'Stop off Pay',
    timePeriod: 'per each stop off',
    after: 'stops',
    name1: 'stopoff_option',
    name2: 'per_stop_amt',
    name3: 'no_of_stops',
  },
  // {
  //   paymentCondition: 'Live Unload Pay',
  //   timePeriod: 'per live unload',
  //   name1: 'live_unload_option',
  //   name2: 'unload_amt'
  // },
  {
    paymentCondition: 'Truck Order Not Used Pay',
    timePeriod: 'per each truck order not used',
    name1: 'truck_order_unused_option',
    name2: 'each_truck_order_unused_amt',
  },
  // {
  //   paymentCondition: 'Break Down Pay',
  //   timePeriod: 'per every hover broken down',
  //   name1: 'breakdown_option',
  //   name2: 'breakdown_amt'
  // },
  // {
  //   paymentCondition: 'Tarp Pay',
  //   timePeriod: 'per each re-delivery of a shipment',
  //   name1: 'tarp_pay_option',
  //   name2: 'each_stop_amt'
  // },
  // {
  //   paymentCondition: 'Re-Delivery Pay',
  //   timePeriod: 'per each stop if tarp type shipment',
  //   name1: 'redelivery_option',
  //   name2: 'after_first_attempt_amt'
  // }
];

const AddDriverListStep2 = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  compensation = {},
  stepsDone,
  setStepsDone,
  operatorId,
  email_id,
  CompId,
  draftDriver,
}) => {
  const { use } = useTheme();
  const [onNextloading, setOnNextloading] = useState(false);
  const [taxOption, setTaxOption] = useState([]);

  const {
    operator_id,
    tax_id,
    bank_name,
    routing,
    routing_confirmed,
    account_confirmed,
    account,
    deposit_form_path,
    pay_option_id,
    pay_per_mile_loaded,
    pay_per_mile_empty,
    pay_by_percentage,
    pay_stubs_option,
    pay_stubs_email,
    detention_pay_option,
    detention_amt,
    detention_hrs,
    layover_option,
    layover_amt,
    stopoff_option,
    per_stop_amt,
    no_of_stops,
    live_unload_option,
    breakdown_amt,
    unload_amt,
    truck_order_unused_option,
    each_truck_order_unused_amt,
    breakdown_option,
    redelivery_option,
    after_first_attempt_amt,
    tarp_pay_option,
    each_stop_amt,
    tax_type_id,
    per_hour_worked,
    break_time_allowed,
    breaks_allowed_in_a_day,
  } = compensation || {};

  const formik = useFormik({
    initialValues: {
      operator_id: operator_id || operatorId,
      tax_id: tax_id || '',
      tax_type_id: tax_type_id || '',
      bank_name: bank_name || '',
      routing: routing || '',
      routing_confirmed: routing_confirmed || '',
      account: account || '',
      account_confirmed: account_confirmed || '',
      deposit_form_path: deposit_form_path || '',
      pay_option_id: pay_option_id || '1',
      pay_per_mile_loaded: pay_per_mile_loaded || 0,
      pay_per_mile_empty: pay_per_mile_empty || 0,
      pay_by_percentage: pay_by_percentage || 0,
      pay_stubs_option: pay_stubs_option || false,
      pay_stubs_email: pay_stubs_email || '',
      detention_pay_option: detention_pay_option || false,
      detention_amt: detention_amt || 0,
      detention_hrs: detention_hrs || 1,
      layover_option: layover_option || false,
      layover_amt: layover_amt || 0,
      stopoff_option: stopoff_option || false,
      per_stop_amt: per_stop_amt || 0,
      no_of_stops: no_of_stops || 0,
      live_unload_option: live_unload_option || false,
      unload_amt: unload_amt || 0,
      truck_order_unused_option: truck_order_unused_option || false,
      each_truck_order_unused_amt: each_truck_order_unused_amt || 0,
      breakdown_option: breakdown_option || 0,
      breakdown_amt: breakdown_amt || 0,
      redelivery_option: redelivery_option || false,
      after_first_attempt_amt: after_first_attempt_amt || 0,
      tarp_pay_option: tarp_pay_option || false,
      each_stop_amt: each_stop_amt || 0,
      per_hour_worked: per_hour_worked || 0,
      break_time_allowed: break_time_allowed || 0,
      breaks_allowed_in_a_day: breaks_allowed_in_a_day || 0,
    },
    validationSchema: CompensationOwnerValidationSchema,
  });

  const onPrevStep = () => {
    setStepsDone({
      ...stepsDone,
      isBasicDetailsSaved: true,
    });
    prevStep();
  };

  const onNextStep = () => {
    if (stepsDone) {
      setOnNextloading(true);
      UpdateCompensationOwner(formik.values, CompId)
        .then((res) => {
          setOnNextloading(false);
          nextStep(
            { formikData: formik.values, CompId: res?.data?.id },
            `Compensation has been ${stepsDone ? 'updated' : 'created'}`
          );
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      setOnNextloading(true);
      AddCompensationOwner(formik.values)
        .then((res) => {
          setOnNextloading(false);
          nextStep(
            { formikData: formik.values, CompId: res?.data?.id },
            `Compensation has been ${stepsDone ? 'updated' : 'created'}`
          );
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  useEffect(() => {
    if (!stepsDone) {
      formik.setFieldValue('tax_id', '');
    }

    getTaxType().then((res) => {
      setTaxOption(res.data);
    });
  }, []);

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0])
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 2,
    };

    saveFinishLater(operatorId, body).then(() => {
      Cancel();
    });
  };

  return (
    <>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <CompensationContent
          formik={formik}
          use={use}
          uploadDocument={uploadDocument}
          taxOption={taxOption}
          email_id={email_id}
        />
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <button
                type='button'
                onClick={onNextStep}
                className='next-step-btn'
                disabled={!formik.isValid}
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
              >
                Next Step
              </button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddDriverListStep2;

export const CompensationContent = ({ formik, use, taxOption, uploadDocument, email_id }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <div className='step-wrapper driver-style-wrap'>
      <div className='d-flex'>
        <div className='flex-col'>
          <p className='text-style tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
            Tax ID <span className='required'>*</span>
          </p>
          <div
            className='tax-id-input'
            style={{
              backgroundColor: use(palette.white, palette.dark600),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          >
            <input
              type='text'
              style={{ backgroundColor: use(palette.white, palette.dark600) }}
              className='input-field'
              name='tax_id'
              id='tax_id'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.tax_id}
            />
          </div>
          {formik.touched.tax_id && formik.errors.tax_id ? (
            <span className='error-message'>{formik.errors.tax_id}</span>
          ) : null}
        </div>
        <div className='custom-select-wrap-contain'>
          <div className='flex-col'>
            <p className='text-style tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
              Tax Type <span className='required'>*</span>
            </p>
            <CustomSelect
              defaultValue='Select'
              options={taxOption}
              id='tax_type_id'
              name='tax_type_id'
              value={formik.values.tax_type_id}
              onBlur={formik.handleBlur}
              onChange={(value) => formik.setFieldValue('tax_type_id', value)}
            />
          </div>
        </div>
      </div>
      <div className='bank-details'>
        <p className='bank-details-heading' style={{ color: use(palette.gray900, palette.gray200) }}>
          Bank Details
        </p>
      </div>
      <div className='d-flex'>
        <div style={{ marginRight: '20px' }}>
          <div className='bank-details-sub'>
            <p className='bank-details-sub-heading tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
              Bank Details
            </p>
          </div>
          <div className='bank-name'>
            <div className='bank-name-conatiner'>
              <p className='bank-name-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                Bank Name
              </p>
            </div>
            <input
              type='text'
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
              name='bank_name'
              id='bank_name'
              onChange={formik.handleChange}
              value={formik.values.bank_name}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.bank_name && formik.errors.bank_name ? (
            <span className='error-message'>{formik.errors.bank_name}</span>
          ) : null}
          <div className='d-flex'>
            <div className='flex-col'>
              <div className='d-flex bank-routing'>
                <div className='bank-name-conatiner'>
                  <p className='bank-name-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                    Routing #
                  </p>
                </div>
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  name='routing'
                  id='routing'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.routing}
                />
              </div>
              {formik.touched.routing && formik.errors.routing ? (
                <span className='error-message'>{formik.errors.routing}</span>
              ) : null}
            </div>

            <div className='re-enter-routing '>
              <input
                type='text'
                placeholder='Re-enter Routing #'
                className='re-enter-routing-text'
                name='routing_confirmed'
                id='routing_confirmed'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.routing_confirmed}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
            </div>
          </div>
          {formik.touched.routing_confirmed && formik.errors.routing_confirmed ? (
            <span className='error-message'>{formik.errors.routing_confirmed}</span>
          ) : null}
          <div className='d-flex'>
            <div className='flex-col'>
              <div className='d-flex bank-routing'>
                <div className='bank-name-conatiner'>
                  <p className='bank-name-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                    Account #
                  </p>
                </div>
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  name='account'
                  id='account'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account}
                />
              </div>
              {formik.touched.account && formik.errors.account ? (
                <span className='error-message'>{formik.errors.account}</span>
              ) : null}
            </div>

            <div className='re-enter-routing'>
              <input
                type='text'
                placeholder='Re-enter Account #'
                className='re-enter-routing-text'
                name='account_confirmed'
                id='account_confirmed'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_confirmed}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
            </div>
          </div>
          {formik.touched.account_confirmed && formik.errors.account_confirmed ? (
            <span className='error-message'>{formik.errors.account_confirmed}</span>
          ) : null}
        </div>
        <div className='upload-wrapper' style={{ position: 'relative' }}>
          <p className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
            Upload Direct Deposit Form
          </p>
          {formik?.values?.deposit_form_path ? (
            <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
              <img src={right} alt='attachment' className='attachment-icon' />
              <span className='text-style' style={{ color: '#38A06C' }}>
                File Uploaded
              </span>
              <img
                src={cross}
                alt='cross'
                className='upload-cancel-icon'
                onClick={() => formik.setFieldValue('deposit_form_path', null)}
              />
            </p>
          ) : (
            <div
              className='upload-document-deposit'
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            >
              <label htmlFor='myFile' className='text-style upload-label'>
                <p className='upload-document-icon'>
                  <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M3.46967 4.78033C3.17678 4.48744 3.17678 4.01256 3.46967 3.71967L6.46967 0.71967C6.76256 0.426777 7.23744 0.426777 7.53033 0.71967L10.5303 3.71967C10.8232 4.01256 10.8232 4.48744 10.5303 4.78033C10.2374 5.07322 9.76256 5.07322 9.46967 4.78033L7.75 3.06066L7.75 9.75C7.75 10.1642 7.41421 10.5 7 10.5C6.58579 10.5 6.25 10.1642 6.25 9.75L6.25 3.06066L4.53033 4.78033C4.23744 5.07322 3.76256 5.07322 3.46967 4.78033Z'
                      fill='#E5E5E5'
                    />
                    <path
                      d='M1.5 9.75C1.5 9.33579 1.16421 9 0.75 9C0.335786 9 0 9.33579 0 9.75V11.7461C0 12.7126 0.783502 13.4961 1.75 13.4961H12.25C13.2165 13.4961 14 12.7126 14 11.7461V9.75C14 9.33579 13.6642 9 13.25 9C12.8358 9 12.5 9.33579 12.5 9.75V11.7461C12.5 11.8842 12.3881 11.9961 12.25 11.9961H1.75C1.61193 11.9961 1.5 11.8842 1.5 11.7461V9.75Z'
                      fill='#E5E5E5'
                    />
                  </svg>
                </p>
                <p>
                  <span style={{ color: use(palette.indigo500, palette.indigo500) }}> Upload document</span>
                </p>
              </label>
              <ImageDropZone
                onDrop={(file) => uploadDocument(file, 'deposit_form_path')}
                width='100%'
                height='100%'
                name='small'
                deletable={false}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
                accept={['application/pdf', 'application/msword']}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <p className='payment-option-heading' style={{ color: use(palette.gray900, palette.gray200) }}>
          Payment Option
        </p>
        <p
          className='text-style payment-details-sub-heading text-focus'
          style={{ color: use(palette.gray900, palette.gray200) }}
        >
          How does this driver get paid?
        </p>
        <div className='d-flex' style={{ marginTop: '12px' }}>
          <p className='payment-option'>
            <input
              checked={formik.values.pay_option_id === '1'}
              value={formik.values.pay_option_id}
              type='radio'
              name='pay_option_id'
              onChange={() => formik.setFieldValue('pay_option_id', '1')}
              className='radio-button'
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Per Mile
            </span>
          </p>
          <p className='payment-option'>
            <input
              type='radio'
              name='pay_option_id'
              checked={formik.values.pay_option_id === '2'}
              value={formik.values.pay_option_id}
              onChange={() => formik.setFieldValue('pay_option_id', '2')}
              className='radio-button'
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              By Percentage
            </span>
          </p>
          <p className='payment-option'>
            <input
              type='radio'
              name='pay_option_id'
              checked={formik.values.pay_option_id === '3'}
              value={formik.values.pay_option_id}
              onChange={() => formik.setFieldValue('pay_option_id', '3')}
              className='radio-button'
              defaultChecked
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Per Hour
            </span>
          </p>
          <p className='payment-option'>
            <input
              type='radio'
              name='pay_option_id'
              checked={formik.values.pay_option_id === '4'}
              value={formik.values.pay_option_id}
              onChange={() => formik.setFieldValue('pay_option_id', '4')}
              className='radio-button'
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Flat Rate
            </span>
          </p>
        </div>
        {formik.values.pay_option_id === '1' ? (
          <div>
            <div className='per-hour-work'>
              <span
                className='doller-icon'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: palette.gray400,
                }}
              >
                {currency}
              </span>
              <input
                type='number'
                placeholder='0.00'
                name='pay_per_mile_loaded'
                id='pay_per_mile_loaded'
                onBlur={formik.handleBlur}
                onKeyDown={(event) => {
                  ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                }}
                onChange={(event) => {
                  if (event.target.value < 0) {
                    return;
                  }
                  formik.setFieldValue('pay_per_mile_loaded', event.target.value);
                }}
                value={formik.values.pay_per_mile_loaded}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
              <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                Loaded
              </span>
            </div>
            <div className='per-hour-work'>
              <span
                className='doller-icon'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: palette.gray400,
                }}
              >
                {currency}
              </span>
              <input
                type='number'
                placeholder='0.00'
                name='pay_per_mile_empty'
                id='pay_per_mile_empty'
                onBlur={formik.handleBlur}
                onKeyDown={(event) => {
                  ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                }}
                onChange={(event) => {
                  if (event.target.value < 0) {
                    return;
                  }
                  formik.setFieldValue('pay_per_mile_empty', event.target.value);
                }}
                value={formik.values.pay_per_mile_empty}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
              <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                Empty
              </span>
            </div>
          </div>
        ) : formik.values.pay_option_id === '2' ? (
          <div className='per-hour-work'>
            <span
              className='doller-icon'
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            >
              %
            </span>
            <input
              type='number'
              placeholder='0.00'
              name='pay_by_percentage'
              id='pay_by_percentage'
              onBlur={formik.handleBlur}
              onKeyDown={(event) => {
                ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
              }}
              onChange={(event) => {
                if (event.target.value < 0) {
                  return;
                }
                formik.setFieldValue('pay_by_percentage', event.target.value);
              }}
              value={formik.values.pay_by_percentage}
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            />
            <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
              of each shipment
            </span>
          </div>
        ) : (
          formik.values.pay_option_id === '3' && (
            <>
              <div className='per-hour-work'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0.00'
                  name='per_hour_worked'
                  id='per_hour_worked'
                  onBlur={formik.handleBlur}
                  onKeyDown={(event) => {
                    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('per_hour_worked', event.target.value);
                  }}
                  value={formik.values.per_hour_worked}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
                <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                  per hour worked
                </span>
              </div>
              <div className='d-flex breaks-value-wrapper align-items-center'>
                <div className='breaks-input-wrapper'>
                  <input
                    type='number'
                    placeholder='0'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                    name='breaks_allowed_in_a_day'
                    id='breaks_allowed_in_a_day'
                    onBlur={formik.handleBlur}
                    onKeyDown={(event) => {
                      ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                    }}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        return;
                      }
                      formik.setFieldValue('breaks_allowed_in_a_day', event.target.value);
                    }}
                    value={formik.values.breaks_allowed_in_a_day}
                  />
                </div>
                <p className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Breaks Allowed In Given Work Day for{' '}
                </p>
                <div className='break-minutes p-1'>
                  <input
                    type='number'
                    placeholder='0'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                    name='break_time_allowed'
                    id='break_time_allowed'
                    onBlur={formik.handleBlur}
                    onKeyDown={(event) => {
                      ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                    }}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        return;
                      }
                      formik.setFieldValue('break_time_allowed', event.target.value);
                    }}
                    value={formik.values.break_time_allowed}
                  />
                  <span
                    className=' d-flex justify-content-center text-style'
                    style={{ color: use(palette.gray700, palette.gray200) }}
                  >
                    minutes
                  </span>
                </div>
              </div>
            </>
          )
        )}
        <div className='checkbox-wrapper'>
          <div className='checkbox owner-checkbox'>
            <input
              type='checkbox'
              className='checkbox-style'
              name='pay_stubs_option'
              id='pay_stubs_option'
              value={formik.values.pay_stubs_option}
              checked={formik.values.pay_stubs_option}
              onChange={() => {
                formik.setFieldValue('pay_stubs_option', !formik.values.pay_stubs_option);
                formik.setFieldValue('pay_stubs_email', !formik.values.pay_stubs_option ? email_id : '');
              }}
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              I want to email pay stubs
            </span>
          </div>
        </div>
        <div className='d-flex'>
          <div className='flex-col'>
            <div className='d-flex bank-routing email-owner-wrap'>
              <div className='bank-name-conatiner'>
                <p
                  className='bank-name-style owner-email-style'
                  style={{ color: use(palette.gray500, palette.gray200) }}
                >
                  To
                </p>
              </div>
              <input
                type='text'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
                name='pay_stubs_email'
                id='pay_stubs_email'
                placeholder='example@email.com'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pay_stubs_email}
              />
            </div>
            {formik.touched.pay_stubs_email && formik.errors.pay_stubs_email ? (
              <span className='error-message'>{formik.errors.pay_stubs_email}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <p className='other-payment-conditions-heading' style={{ color: use(palette.gray900, palette.gray200) }}>
          Other Payment Conditions
        </p>
        {paymentConditions.map((value) => (
          <div
            key={value.name1}
            className='d-flex align-items-center justify-content-start mt-2 mb-2 paymentConditionsSwitch'
          >
            <div className='form-switch switch-wrapper'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                name={value.name1}
                id={value.name1}
                value={formik.values[value.name1]}
                checked={formik.values[value.name1]}
                onChange={() => formik.setFieldValue(value.name1, !formik.values[value.name1])}
              />
              <label
                className='text-style'
                htmlFor='flexSwitchCheckChecked'
                style={{ color: use(palette.gray700, palette.gray200) }}
              >
                {value.paymentCondition}
              </label>
            </div>
            <div className='mb-2'>
              <div className='per-day-work'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: !formik.values[value.name1] ? palette.gray0 : palette.white,
                    borderColor: palette.gray50,
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0.00'
                  id={value.name2}
                  name={value.name2}
                  onBlur={formik.handleBlur}
                  onKeyDown={(event) => {
                    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue(value.name2, event.target.value);
                  }}
                  style={{
                    backgroundColor: !formik.values[value.name1] ? palette.gray0 : palette.white,
                    borderColor: palette.gray50,
                  }}
                  value={formik.values[value.name2]}
                  disabled={!formik.values[value.name1]}
                />
                <span
                  className='text-style input-text-compansation'
                  style={{ color: use(palette.gray500, palette.gray200) }}
                >
                  {value.timePeriod}
                </span>
              </div>
            </div>
            {value.after ? (
              <div className='d-flex align-items-center'>
                <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                  after
                </span>
                <div className='payment-option-second-input '>
                  <input
                    type='number'
                    placeholder='1'
                    id={value.name3}
                    name={value.name3}
                    disabled={!formik.values[value.name1]}
                    min={1}
                    onBlur={formik.handleBlur}
                    style={{
                      backgroundColor: !formik.values[value.name1] ? palette.gray0 : palette.white,
                      borderColor: palette.gray50,
                    }}
                    value={formik.values[value.name3]}
                    onKeyDown={(event) => {
                      ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                    }}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        return;
                      }
                      formik.setFieldValue(value.name3, event.target.value);
                    }}
                  />
                </div>
                <span className='text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                  {value.after}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
