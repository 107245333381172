import React, { useState } from 'react';
import { palette } from 'utils/constants';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Chip from 'common/Chip';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import TableFooter from 'components/TableFooter/TableFooter';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import useDateFormat from 'hooks/useDateFormat';
import classes from './plannedLoadsTable.module.css';

const EquipmentLogsTable = ({ logs, rowPerPage, setRowPerPage, setPage }) => {
  const [openViewPDF, setOpenViewPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const { convertToCustomerTime, formatDate } = useDateFormat();

  const columns = [
    {
      title: (
        <Typography variant='overLine' style={{ color: palette.gray700, textTransform: 'uppercase' }}>
          Changed Section
        </Typography>
      ),
      field: 'change_description',
      render: (rowData) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {rowData.change_description}
        </Typography>
      ),
    },
    {
      title: (
        <Typography variant='overLine' style={{ color: palette.gray700, textTransform: 'uppercase' }}>
          Changed From / To
        </Typography>
      ),
      field: 'change_source',
      render: (rowData) => {
        const { change_from } = rowData;
        const { change_to } = rowData;
        const { change_type } = rowData;
        return (
          <div className='d-flex align-items-center gap-3' style={{ color: palette.gray700 }}>
            <Chip
              disableRipple
              onClick={
                (change_type === 'image' || change_type === 'equipment_document') && change_from
                  ? () => onViewPDF(change_from)
                  : undefined
              }
              label={
                change_type === 'date' && change_from
                  ? formatDate(change_from)
                  : (change_type === 'image' || change_type === 'equipment_document') && change_from
                  ? 'VIEW OLD ATTACHMENT'
                  : change_from || '-'
              }
            />{' '}
            <ArrowRight fill={palette.gray500} />{' '}
            <Chip
              disableRipple
              onClick={
                (change_type === 'image' || change_type === 'equipment_document') && change_to
                  ? () => onViewPDF(change_to)
                  : undefined
              }
              label={
                change_type === 'date' && change_to
                  ? formatDate(change_to)
                  : (change_type === 'image' || change_type === 'equipment_document') && change_to
                  ? 'VIEW NEW ATTACHMENT'
                  : change_to || '-'
              }
            />
          </div>
        );
      },
    },
    {
      title: (
        <Typography variant='overLine' style={{ color: palette.gray700, textTransform: 'uppercase' }}>
          Changed By
        </Typography>
      ),
      field: 'created_at',
      render: (rowData) => {
        const user = rowData.change_by_user_details;
        return (
          <div className='flex-row d-flex'>
            {rowData?.change_by_user_id === -1 ? (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                System on {convertToCustomerTime(rowData.created_at)}
              </Typography>
            ) : user ? (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {user.first_name} {user.last_name} on {convertToCustomerTime(rowData.created_at)}
              </Typography>
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                -
              </Typography>
            )}
          </div>
        );
      },
    },
  ];

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
    setPage(page);
  };

  const onViewPDF = (link) => {
    setPdfUrl(link);
    setOpenViewPDF(true);
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={logs.total}
        lastPage={logs.last_page}
        totalLength={logs.data?.length}
        currentPage={logs.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };
  return (
    <div className={classes.container}>
      <div style={{ margin: 1 }}>
        <MaterialTableWrapper
          data={logs.data}
          rowPerPage={rowPerPage}
          style={{ backgroundColor: palette.white }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      </div>
      {openViewPDF && (
        <ViewPdf title='View Invoice(s)' open={openViewPDF} pdfUrl={pdfUrl} onClose={() => setOpenViewPDF(false)} />
      )}
    </div>
  );
};

export default EquipmentLogsTable;
