import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { Icons } from 'assets/icons';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { combineSamsaraAndCsv } from 'Api/EquipmentImport';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import styles from '../ImportEquipments/ImportVehicle/ImportCSV.module.css';

const styleImportCSV = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '460px',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
};

const UploadCsv = ({ open, onClose, samsaraData, onUploadSuccess }) => {
  const showToaster = useShowToaster();
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('import_file', file);
    formData.append('connected_service_id', 50);

    samsaraData.forEach((item) => {
      const samsara_data = {
        equipment_id: item.equipment_id,
        serial_id: item.serial_id,
        equipment_ownership: { license_plate_number: item.license_plate_number },
        model_id: item.model_id || '',
        provider_device_id: item.provider_device_id || '',
      };
      formData.append('samsara_data[]', JSON.stringify(samsara_data));
    });

    try {
      const { data } = await combineSamsaraAndCsv(formData);
      onUploadSuccess(data);
      setFile(null);
      setFileName(null);
    } catch (err) {
      showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong' });
    }
    setLoading(false);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileName();
  };

  const downloadFile = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportTrailers.csv`;
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleClose = () => {
    onClose();
    setFile(null);
    setFileName(null);
    setLoading(false);
  };

  return (
    <Modal open={open}>
      <Box sx={styleImportCSV}>
        <div>
          <div className={styles.Header}>
            <h5 className={styles.textContent}>Import from CSV</h5>
          </div>
          <div className={styles.body}>
            <div className='d-flex items-center p-2 ms-3'>
              <div className='mt-3'>
                <span className={styles.leftNumbers}>1</span>
                <div
                  style={{
                    borderLeft: '1px solid #D5DAE5',
                    height: '100%',
                    marginLeft: '8px',
                  }}
                />
                <span className={styles.leftNumbers}>2</span>
              </div>
              <div>
                <div className={styles.CSVTemplate}>
                  <span className={styles.textCSV}>Download the Trailers CSV Template</span>
                  <p className={styles.textContent2}>
                    First download the CSV template. Fill out asked data in that template then upload filled template
                    below.
                  </p>
                  <button className={styles.button} onClick={downloadFile}>
                    <img src={Icons.DownloadIcon} alt='Download' />
                    Download Trailers CSV Template
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.uploadFiles}>
              <span className={styles.uploadText}>Upload filled CSV file here:</span>
              <div className={!fileName ? styles.uploadFiles2 : styles.uploadFile}>
                <label className={styles.recordFormRepairReceipt}>
                  <span>
                    <input
                      name='repair_receipt_attach'
                      onChange={(e) => {
                        setFileName(e.currentTarget.files[0].name);
                        setFile(e.currentTarget.files[0]);
                      }}
                      type='file'
                      accept='.csv'
                      style={{ display: 'none' }}
                    />

                    {fileName ? (
                      <div className='d-flex'>
                        <div className={styles.attachfile}>
                          <img src={Icons?.attachfile} alt='' style={{ width: '15px', height: '15px' }} />
                          <span className={styles.recordFormHeader}>{fileName}</span>
                        </div>
                        <span onClick={handleRemove}>
                          <img
                            src={Icons.CrossRedIcon}
                            alt=''
                            className='ms-2'
                            style={{ height: '10px', width: '10px' }}
                          />
                        </span>
                      </div>
                    ) : (
                      <>
                        <div className='d-flex justify-content-center'>
                          <img src={uploadLicense} alt='' />
                        </div>
                        <p className='d-flex justify-content-center '>
                          <span style={{ color: '#4F5AED', marginRight: '2px' }}>Upload file</span>
                          <span className={styles.recordFormHeader}> or drag and drop</span>
                        </p>
                      </>
                    )}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <button className={styles.buttonCancel} onClick={handleClose}>
              Cancel
            </button>
            {loading ? (
              <CircularProgress style={{ height: '20px', width: '20px', marginTop: '16px', marginRight: '14px' }} />
            ) : (
              <button className={styles.buttonUploadText} onClick={handleSubmit}>
                Upload
              </button>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UploadCsv;
