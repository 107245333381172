import React, { forwardRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { createCityStateCountry } from 'Api/CompanySettings';
import { SWrapper } from './AddressAutocomplete.styles';
import InputLabel from '../InputLabel';
import ErrorMessage from '../ErrorMessage';

const AddressAutocomplete = forwardRef(
  (
    {
      onSuccess = () => null,
      onError = () => null,
      changeAddress = () => null,
      label,
      required,
      error,
      width,
      className = '',
      options = {},
      size,
      ...props
    },
    ref
  ) => {
    const onSelect = async (data) => {
      const lat = data?.geometry?.location?.lat();
      const lng = data?.geometry?.location?.lng();

      const zip = data?.address_components?.find((addr) => addr.types?.includes('postal_code'));
      const address = data.formatted_address?.split(',')[0];
      changeAddress({ address, zip: zip?.long_name, formatted_address: data.formatted_address, lat, lng });

      const city = data?.address_components?.find((addr) => addr.types?.includes('locality'));
      const state = data?.address_components?.find((addr) => addr.types?.includes('administrative_area_level_1'));
      const country = data?.address_components?.find((addr) => addr.types?.includes('country'));

      try {
        const body = {
          country: {
            name: country?.long_name,
            sortname: country?.short_name,
          },
          state: {
            name: state?.long_name,
            short_name: state?.short_name,
          },
          city: {
            name: city?.long_name,
          },
        };

        const response = await createCityStateCountry(body);
        if (address && zip) {
          response.data.address = address;
          response.data.zip = zip?.long_name;
        }
        onSuccess(response.data);
      } catch (e) {
        onError(data.formatted_address);
      }
    };

    return (
      <SWrapper $width={width} $size={size}>
        {label && <InputLabel required={required}>{label}</InputLabel>}
        <Autocomplete
          ref={ref}
          autoComplete='off'
          placeholder='Address'
          className={`google-autocomplete ${className}`}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          options={{ types: ['address'], ...options }}
          onPlaceSelected={onSelect}
          {...props}
        />
        <ErrorMessage error={error} />
      </SWrapper>
    );
  }
);

export default AddressAutocomplete;
