import React, { useState } from 'react';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import searchIcon from 'assets/icons/header/Search.png';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import calendar from 'assets/icons/drivers/calendar.svg';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import styles from './CustomerLogFilters.module.css';

const CustomerLogFilters = ({ search, onChange, onSelectDate }) => {
  const { use } = useTheme();
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [rangeName, setRangeName] = useState();
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });

  const dateSelect = (dates) => {
    setDateRange(dates);
    onSelectDate(dates);
  };

  return (
    <div className={styles.filtersWrapperLogs}>
      <div>
        <div className='d-flex align-items-center'>
          <span className={styles.logHeader} style={{ color: use(palette.gray900, palette.gray50) }}>
            Log
          </span>
          <div>
            <input
              className={styles.searchInput}
              type='text'
              value={search}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Search'
            />
            <div className='input-group-addon-search'>
              <img src={searchIcon} alt='search' />
            </div>
          </div>
          <div className={styles.calendarWrapper}>
            {/* <DateRange dateTimeRange={dateRange} dateSelect={dateSelect} rangeName={rangeName} setRangeName={setRangeName} />
             */}
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '117px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerLogFilters;
