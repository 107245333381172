import { array, date, number, object } from 'yup';
import { singleCargoValidationSchema } from 'componentsV2/Commodity/ShipmentCommodity/validationSchema';
import { validator } from 'utils/helpers';
import moment from 'moment';

export const validationSchema = (allowPastDate) =>
  object().shape({
    stop_point_id: number().required('Required').typeError('Required').nullable(),
    equipment_type: number().when('stop_point_type', {
      is: (stop_point_type) => Number(stop_point_type) === 1,
      then: number().required('Required').typeError('Required').nullable(),
    }),
    equipment_type_length: number().when(['stop_point_type', 'equipment_type'], {
      is: (stop_point_type, equipment_type) => Number(stop_point_type) === 1 && Number(equipment_type) >= 12,
      then: number().required('Required').typeError('Required').nullable(),
    }),
    equipment_action: number().required('Required').typeError('Required').nullable(),
    scheduled_type: number().required('Required').typeError('Required').nullable(),
    scheduled_date: allowPastDate ? validator.requiredDate() : validator.futureRequiredDate(),
    scheduled_date_to: date().when('scheduled_type', {
      is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
      then: validator.requiredDate().test('Invalid', 'Start date cannot be after end date', (value, context) => {
        const { scheduled_date, from, to } = context.parent || {};
        if (
          !scheduled_date ||
          !from ||
          !to ||
          !moment(scheduled_date).isValid() ||
          !moment(from).isValid() ||
          !moment(to).isValid()
        ) {
          return true;
        }

        return moment(`${moment(scheduled_date).format('YYYY-MM-DD')} ${moment(from).format('HH:mm')}`).isSameOrBefore(
          `${moment(value).format('YYYY-MM-DD')} ${moment(to).format('HH:mm')}`
        );
      }),
      otherwise: date().nullable(),
    }),
    from: validator.requiredDate(),
    to: date().when('scheduled_type', {
      is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
      then: validator.requiredDate(),
      otherwise: date().nullable(),
    }),
    cargos: array().when('stop_point_type', {
      is: (stop_point_type) => Number(stop_point_type) === 1,
      then: singleCargoValidationSchema,
    }),
  });
