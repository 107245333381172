import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { palette } from 'utils/constants';

export const STableWrapper = styled.div`
  padding-bottom: 24px;
`;

export const SLinkText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${palette.indigo500};
  padding-left: 8px;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SDotsWrapper = styled.div`
  cursor: pointer;

  .dots {
    display: inline-flex;
    width: 20px;
    height: 20px;
  }

  .popover-actions {
    list-style-type: none;
    background: #ffffff;
    box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0;
  }
`;

export const SPopover = styled(Popover)`
  &.MuiPopover-root {
    .MuiPaper-root {
      margin-top: -12px;
    }

    .actions-wrapper {
      list-style-type: none;
      background: #ffffff;
      box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 6px 4px;

      li {
        text-transform: none;
        cursor: pointer;
        width: 100%;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        border-radius: 4px;

        &.delete-action {
          color: ${palette.red500};
        }

        :hover {
          background-color: ${palette.indigo0};
        }
      }
    }
  }
`;

export const SActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 12px;

  .billed-toggle {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`;

export const SUnderlineText = styled.div`
  display: flex;
  width: fit-content;
`;

export const SAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 12px;
`;
