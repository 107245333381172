import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function getShipmentAudit(filter) {
  const params = {
    ...filter,
  };
  const { data } = await axios.get(`${API_URL}/customer/shipments/audit`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function Shipment({
  page,
  itemsPerPage,
  sort,
  filtersType,
  filtersValue,
  remainingData,
  allFilterData,
  status_ids,
  signal,
}) {
  const params = {
    page,
    itemsPerPage,
  };
  if (!allFilterData) {
    if (filtersValue) params[`filters[${filtersType}]`] = filtersValue;
    if (typeof remainingData === 'object') {
      Object.keys(remainingData).forEach((key) => {
        if (filtersType !== key) {
          const value = remainingData[key];
          if (value) params[`filters[${key}]`] = value;
        }
      });
    }
  } else {
    Object.keys(allFilterData).forEach((key) => {
      params[`filters[${key}]`] = allFilterData[key];
    });
  }

  if (sort?.length > 0) {
    sort.forEach((el, index) => {
      const { type, sortBy } = el;
      params[`sort[${index}][${type}]`] = sortBy;
    });
  }

  if (status_ids.length) {
    status_ids.forEach((item) => {
      params[`filters[status_ids][]`] = item;
    });
  }

  const { data } = await axios.get(`${API_URL}/customer/shipments/audit`, {
    headers: authHeader(),
    params,
    signal,
  });
  return data;
}

export async function clearShipmentAuditData(shipmentId) {
  const url = `${API_URL}/customer/shipments/audit`;
  const { data } = await axios.post(
    url,
    {
      ...shipmentId,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateDriverPay(updatePayData, shipmentId) {
  const url = `${API_URL}/customer/shipments/audit/${shipmentId}`;
  const { data } = await axios.put(
    url,
    {
      ...updatePayData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}
