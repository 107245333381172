import axios from 'axios';
import { getAppUrl } from 'utils/helpers';
import authHeader from './auth-header';

const CryptoJS = require('crypto-js');

const API_URL = process.env.REACT_APP_API_URL;
const url = getAppUrl();

class AuthService {
  login(email, password, redirect) {
    let params;
    if (!redirect) {
      params = {
        email,
        password,
      };
    } else {
      params = {
        redirect,
      };
    }
    const prodUrl = 'https://app.truckindigital.com';
    const betaUrl = 'https://app.beta.truckindigital.com';

    return axios.post(`${API_URL}/user/login`, params).then((response) => {
      if (url === prodUrl && response?.data?.user?.customer?.beta) {
        const newWindow = window.open(betaUrl, '_blank');

        setTimeout(() => {
          newWindow.postMessage(JSON.stringify({ email, password }), betaUrl);
        }, 5000);
        return;
      }

      if (url === betaUrl && !response?.data?.user?.customer?.beta) {
        const newWindow = window.open(prodUrl, '_blank');

        setTimeout(() => {
          newWindow.postMessage(JSON.stringify({ email, password }), prodUrl);
        }, 5000);
        return;
      }

      if (response.data?.chat_token) {
        localStorage.setItem('chatToken', JSON.stringify(response.data.chat_token));
      }
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));

      if (rememberMe) {
        const encPasd = CryptoJS.AES.encrypt(password, 'my-secret-key@123').toString();
        const oldData = JSON.parse(localStorage.getItem('userCred'));
        const userCred = [];
        const userData = {
          userName: email,
          password: encPasd,
        };
        if (oldData !== null) {
          const isFound = oldData.some((d) => {
            return d.userName === email;
          });
          if (isFound) {
            userCred.push(...oldData);
          } else userCred.push(...oldData, userData);
        } else {
          userCred.push(userData);
        }
        localStorage.setItem('userCred', JSON.stringify(userCred));
      }

      if (response.data.token.access_token) {
        response.data.user.accessToken = response.data.token.access_token;
        localStorage.setItem('accountStatus', JSON.stringify(response.data.customer_status_data));
        localStorage.setItem('user', JSON.stringify(response.data.user));
      }
      return { user: response.data.user, accountStatus: response.data.customer_status_data };
    });
  }

  firstTimeLogin(email) {
    return axios
      .post(`${API_URL}/user/staff/first`, {
        email,
      })
      .then((response) => {
        return response;
      });
  }

  sendOtp() {
    return axios.put(`${API_URL}/otp/send`, {}, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  verifyOtp(verification_code) {
    return axios.post(`${API_URL}/otp/verify`, { verification_code }, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  loginSetPwd(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Accept: '*/*',
        Authorization: `Bearer ${user.accessToken}`,
      },
      url: `${API_URL}/user/login/first`,
      data,
    };

    return axios(config)
      .then((response) => {
        return response;
      })
      .catch(() => {
        // Do nothing
      });
  }

  logout(removeFromStorage = true) {
    return axios
      .put(`${API_URL}/user/logout`, {}, { headers: authHeader() })
      .then(() => {
        !!removeFromStorage && localStorage.removeItem('user');
        !!removeFromStorage && localStorage.removeItem('rememberMe');
      })
      .catch(() => {
        // Do nothing
      });
  }

  register(username, email, password) {
    return axios.post(`${API_URL}/signup`, {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  removeUserToken() {
    try {
      const user = this.getCurrentUser();
      user.accessToken = null;
      localStorage.setItem('user', JSON.stringify(user));
    } catch (e) {
      /* empty */
    }
  }
}

export default new AuthService();
