import React, { useCallback, useEffect, useState } from 'react';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import TableWrapper from 'components/TableWrapper';
import { palette } from 'utils/constants';
import { getRecurringLanes } from 'Api/Planner';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import useDebounce from 'hooks/useDebounce';
import { getRecurringLaneCounts } from 'Api/Shipment';
import moment from 'moment';
import { useColumns, tabs, locationFilters } from './RecurringLanes.data';
import TableFilters from './TableFilters';
import { STabs } from './RecurringLanes.styles';

const RecurringLanes = ({ setExportLanesParams }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lanesData, setLanesData] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25, customers: [] });
  const [sort, setSort] = useState({ field: 'id', sortBy: 'desc' });
  const [locations, setLocations] = useState(locationFilters);
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [counts, setCounts] = useState([]);
  const debouncedSearch = useDebounce(search, 500);
  const debouncedLocations = useDebounce(locations, 500);

  const getLanesData = async () => {
    setLoading(true);
    try {
      const sortField = `sort[${sort.field}]`;
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        [sortField]: sort.sortBy,
        'filters[status]': tabs[activeTab].label,
        'filters[customers]': selectedFilters.customers.map((i) => i.id),
        'filters[all]': debouncedSearch || undefined,
        'filters[origin_city]': debouncedLocations.origin_city || undefined,
        'filters[origin_state]': debouncedLocations.origin_state || undefined,
        'filters[destination_city]': debouncedLocations.destination_city || undefined,
        'filters[destination_state]': debouncedLocations.destination_state || undefined,
        'filters[scheduled_from]': debouncedLocations.scheduled?.[0]
          ? moment(debouncedLocations.scheduled[0]).format('YYYY-MM-DD')
          : undefined,
        'filters[scheduled_to]': debouncedLocations.scheduled?.[1]
          ? moment(debouncedLocations.scheduled[1]).format('YYYY-MM-DD')
          : undefined,
      };
      setExportLanesParams({ ...params, page: undefined, itemsPerPage: undefined });

      const [response, { data }] = await Promise.all([getRecurringLanes(params), getRecurringLaneCounts()]);
      setLanesData(response);
      setCounts([data.active || 0, data.ended || 0, data.cancelled || 0]);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const onRowClick = (e, row) => {
    navigate(`/lane/${row.id}`, { state: row });
  };

  const getStatusParamsInStatus = useCallback((scheduledDate, status, pendingDispatch) => {
    let bgStatus;
    let colorStatus;
    let statusName;
    if (pendingDispatch) {
      bgStatus = '#F0F2F7';
      colorStatus = '#5A6376';
      statusName = `Pending Dispatch`;
    } else {
      if (status === 'Offered') {
        bgStatus = '#F0F2F7';
        colorStatus = '#5A6376';
        statusName = `Offered`;
      } else if (status >= 24) {
        const daysCount = Math.floor(status / 24);
        bgStatus = '#EBF0FA';
        colorStatus = '#2264E6';
        statusName = `+${daysCount} DAYS SCHEDULED`;
      } else if (status >= 4 && status < 24) {
        bgStatus = '#EBF0FA';
        colorStatus = '#2264E6';
        statusName = 'SCHEDULED WITHIN 24H';
      } else if (status >= 0 && status < 4) {
        bgStatus = '#FFFAEB';
        colorStatus = '#B48909';
        statusName = 'SCHEDULED WITHIN 24H';
      } else if (status < 0) {
        bgStatus = '#FAF0F3';
        colorStatus = '#D12953';
        statusName = 'BEHIND SCHEDULE';
      }
    }

    return { bgStatus, colorStatus, statusName };
  }, []);

  const equipmentTypeText = ({ type, action, equipment, equipment_tbd }) => {
    if (+type === 1) {
      const map = {
        1: 'Live Load',
        2: equipment_tbd ? `Hook (TBD)` : `Hook to ${equipment?.equipment_id}`,
        3: equipment_tbd ? `Drop Trailer & Hook (TBD)` : `Drop Trailer & Hook to ${equipment?.equipment_id}`,
      };
      return map[action];
    }
    if (+type === 2) {
      const map = {
        1: 'Live Unload',
        2: 'Drop Trailer',
      };
      return map[action];
    }
    if (+type === 3) {
      const map = {
        1: 'Live Unload',
        2: `Drop Trailer`,
        3: equipment_tbd ? `Drop Trailer & Hook (TBD)` : `Drop Trailer & Hook to ${equipment?.equipment_id}`,
        4: 'Other',
      };
      return map[action];
    }
  };

  useEffect(() => {
    getLanesData();
  }, [selectedFilters, sort, debouncedSearch, debouncedLocations, activeTab]);

  const columns = useColumns({ sort, sortingQuery, getStatusParamsInStatus, equipmentTypeText });

  return (
    <div>
      <div className='mt-4 mb-4'>
        <TableFilters
          search={search}
          setSearch={setSearch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          locations={locations}
          setLocations={setLocations}
        />
      </div>
      <STabs value={Number(activeTab) || 0} onChange={(e, newValue) => setActiveTab(newValue)}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            id={`tab-${index}`}
            value={index}
            label={tab.label}
            iconPosition='end'
            disableRipple
            icon={<span>{counts[index] || 0}</span>}
          />
        ))}
      </STabs>
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={lanesData.data}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{ backgroundColor: palette.white }}
            components={{
              Pagination: () =>
                Pagination({
                  data: lanesData,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            columns={columns}
            onRowClick={onRowClick}
          />
        </div>
      )}
    </div>
  );
};

export default RecurringLanes;
