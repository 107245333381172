import moment from 'moment';

export const getManufacturedYear = (year) => {
  if (moment(year, 'MM/YYYY', true).isValid()) {
    return moment(year, 'MM/YYYY').format('YYYY');
  }
  return moment(year).format('YYYY');
};

export const personTakingReport = [
  {
    name: 'Driver',
    value: `App\Driver`,
  },
  {
    name: 'Staff',
    value: `App\Staff`,
  },
];

export const yesOrNoOptions = [
  {
    name: 'Yes',
    value: 1,
  },
  {
    name: 'No',
    value: 0,
  },
];

export const getAccidentReportInitialValues = ({
  user,
  selectedDriver,
  selectedShipment,
  selectedVehicle,
  selectedTrailer,
  vehiclesList,
  trailersList,
}) => {
  return {
    report_date_time: new Date(),
    person_taking_report_type: personTakingReport[1],
    person_taking_report: user,
    driver: selectedDriver || null,
    driver_injured: null,
    injury_type: '',
    treating_facility: '',
    injury_worker_comp_recordable: null,
    treating_facility_telephone: '',
    injury_osha_reportable: null,
    accident_date_time: '',
    accident_location: '',
    weather_condition: null,
    road_condition: null,
    light_condition: null,
    police_report: '',
    police_report_number: '',
    police_report_attachment: null,
    citation_issued: '',
    citation_issued_violation_count: '',
    vehicle_unit: selectedVehicle ? vehiclesList.find((el) => el.equipment_id === selectedVehicle.equipment_id) : null,
    vehicle_year: selectedVehicle?.year ? moment(selectedVehicle.year).format('YYYY') : '',
    vehicle_make: selectedVehicle?.make ? selectedVehicle.make : '',
    vehicle_owner: selectedVehicle?.equipment_ownership?.owner ? selectedVehicle.equipment_ownership.owner : '',
    vehicle_damage: '',
    trailer_unit: selectedTrailer ? trailersList.find((el) => el.equipment_id === selectedTrailer.equipment_id) : null,
    trailer_year:
      selectedTrailer?.year && selectedTrailer?.year !== 'Invalid date'
        ? Number(getManufacturedYear(selectedTrailer.year)) || ''
        : '',
    trailer_make: selectedTrailer?.make ? selectedTrailer.make : '',
    trailer_owner: selectedTrailer?.equipment_ownership?.owner ? selectedTrailer.equipment_ownership.owner : '',
    trailer_damage: '',
    witnesses: [],
    shipment_related: selectedShipment ? 1 : 0,
    report_shipment: selectedShipment || null,
    shipment_commodities_hauled: '',
    shipment_under_dispatch: '',
    shipment_shipper: null,
    shipment_consignee: null,
    shipment_hazmat: '',
    shipment_hazmat_class: '',
    shipment_hazmat_release: '',
    shipment_hazmat_release_details: '',
    shipment_hazmat_disposal_details: '',
    shipment_hazmat_disposal_attachment: null,
    shipment_cargo_damage: '',
    shipment_cargo_damage_details: '',
    driver_statement: '',
    driver_statement_attachment: null,
    driver_logs_attachment: null,
  };
};

export const getLogbookInitialValues = (accident) => {
  if (accident?.logbook?.length) {
    return accident.logbook.map((item) => ({
      id: item.id,
      logbook_date: moment(item.logbook_date).toDate(),
      logbook_attachment: item.logbook_attachment || null,
      hos_daily_log: item.hos_daily_log || null,
    }));
  }

  return [{ id: Math.random(), logbook_date: new Date(), logbook_attachment: null, hos_daily_log: null, isNew: true }];
};

export const getCollisionCostInitialValues = ({ selectedVehicle, selectedTrailer }) => {
  return {
    cost_analysis_vehicle_damage_estimate: false,
    cost_analysis_vehicle_damage_description: '',
    vehicle_collision_cost: [
      {
        id: Date.now(),
        description: '',
        vendor: null,
        bill: null,
        amount: '',
      },
    ],
    vehicle_collision_photos: [],
    cost_analysis_vehicle_estimate_attachment: null,
    cost_analysis_vehicle_insurance_claim: false,
    cost_analysis_vehicle_insurance_company_name: '',
    cost_analysis_vehicle_insurance_claim_number: '',
    cost_analysis_vehicle_insurance_policy_number: selectedVehicle?.equipment_insurance?.physical_insurance_policy_id
      ? selectedVehicle?.equipment_insurance?.physical_insurance_policy_id
      : '',

    cost_analysis_trailer_damage_estimate: false,
    cost_analysis_trailer_damage_description: '',
    trailer_collision_cost: [
      {
        id: Date.now(),
        description: '',
        vendor: null,
        bill: null,
        amount: '',
      },
    ],
    trailer_collision_photos: [],
    cost_analysis_trailer_estimate_attachment: null,
    cost_analysis_trailer_insurance_claim: false,
    cost_analysis_trailer_insurance_company_name: '',
    cost_analysis_trailer_insurance_claim_number: '',
    cost_analysis_trailer_insurance_policy_number: selectedTrailer?.equipment_insurance?.physical_insurance_policy_id
      ? selectedTrailer?.equipment_insurance?.physical_insurance_policy_id
      : '',

    cost_analysis_cargo_damage_estimate: false,
    cost_analysis_cargo_damage_description: '',
    cargo_collision_cost: [
      {
        id: Date.now(),
        description: '',
        vendor: null,
        bill: null,
        amount: '',
      },
    ],
    cargo_collision_photos: [],
    cost_analysis_cargo_estimate_attachment: null,
    cost_analysis_cargo_insurance_claim: false,
    cost_analysis_cargo_insurance_company_name: '',
    cost_analysis_cargo_insurance_claim_number: '',
    cost_analysis_cargo_insurance_policy_number: selectedVehicle?.equipment_insurance
      ?.general_liability_insurance_policy_id
      ? selectedVehicle?.equipment_insurance?.general_liability_insurance_policy_id
      : '',

    cost_analysis_liability_damage_estimate: false,
    cost_analysis_liability_damage_description: '',
    liability_collision_cost: [
      {
        id: Date.now(),
        description: '',
        vendor: null,
        bill: null,
        amount: '',
      },
    ],
    liability_collision_photos: [],
    cost_analysis_liability_estimate_attachment: null,
    cost_analysis_liability_insurance_claim: false,
    cost_analysis_liability_insurance_company_name: '',
    cost_analysis_liability_insurance_claim_number: '',
    cost_analysis_liability_insurance_policy_number: selectedVehicle?.equipment_insurance
      ?.general_liability_insurance_policy_id
      ? selectedVehicle?.equipment_insurance?.general_liability_insurance_policy_id
      : '',

    cost_analysis_total_cost: '',

    vehicle_damage_customer: null,
    vehicle_damage_invoice: null,

    trailer_damage_customer: null,
    trailer_damage_invoice: null,

    cargo_damage_customer: null,
    cargo_damage_invoice: null,

    liability_damage_customer: null,
    liability_damage_invoice: null,
  };
};

export const getChecklistInitialValues = () => {
  return {
    driver_family_notified: false,
    driver_family_notified_to_first_name: '',
    driver_family_notified_to_last_name: '',
    driver_family_notified_to_telephone: '',
    driver_family_notified_datetime: '',

    tow_service_notified: false,
    tow_service_notified_name: '',
    tow_service_notified_address: '',
    tow_service_notified_telephone: '',
    tow_service_notified_datetime: '',

    alcohol_test_conducted: false,
    alcohol_test_attachment: null,

    drug_test_conducted: false,
    drug_test_attachment: null,

    hazmat_disposal_report_gathered: false,
    hazmat_disposal_report_attachment: null,
  };
};
