import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #e9edf5;
  padding: 16px 0;
`;

export const SHeader = styled.div`
  display: flex;
  text-align: center;
  padding: 24px 0 8px 0;
  justify-content: space-between;
`;

export const SActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
