import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { assignUser, getLimitTypes } from 'Api/CardManagement';
import RowActions from './RowActions';
import SetLimit from '../SetLimit';
import { STable, SText } from './CardLimits.styles';
import { SBackdrop, SCustomModal } from '../../Cards.styles';
import { SHeaderWrapper } from '../CardDetails/CardDetails.styles';

const CardLimits = ({ open, onClose, onSuccess, cardDetails, data }) => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [openSetLimit, setOpenSetLimit] = useState(false);
  const [limitToUpdate, setLimitToUpdate] = useState(null);
  const [categories, setCategories] = useState([]);
  const { limits, cardNumber } = cardDetails || {};

  const onUpdate = (item) => {
    setLimitToUpdate(item);
    setOpenSetLimit(true);
  };

  const onRemove = async (item) => {
    setLoading(true);
    try {
      const newLimits = limits.filter((i) => i.limitId !== item.limitId);

      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (newLimits?.length) {
        newLimits.forEach((item, index) => {
          formData.append(`limits[${index}][limitId]`, item.limitId);
          formData.append(`limits[${index}][limit]`, item.limit);
          formData.append(`limits[${index}][hours]`, item.hours);
          formData.append(`limits[${index}][minHours]`, item.minHours);
          formData.append(`limits[${index}][autoRollMap]`, item.autoRollMap);
          formData.append(`limits[${index}][autoRollMax]`, item.autoRollMax);
        });
      } else {
        formData.append(`limits[]`, '');
      }
      await assignUser(formData);
      showToaster({ type: 'success', message: 'Card limits have been successfully updated' });
      onSuccess(cardNumber);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const getLimitOptions = async () => {
    try {
      const { data } = await getLimitTypes();
      setCategories(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getLimitOptions();
  }, []);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <SHeaderWrapper>
          <Typography variant='h5'>Spend Limits</Typography>
          <CustomButton
            type='secondary'
            title='Add New'
            onClick={() => setOpenSetLimit(true)}
            className='edit-button'
            styleButton={{ padding: '6px 12px', marginTop: 0 }}
          />
        </SHeaderWrapper>
      }
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='800px'
      padding='24px 0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <div style={{ padding: '0 24px' }}>
        <STable>
          <thead>
            <tr>
              <th>CATEGORY</th>
              <th>AMOUNT PER TRANSACTION</th>
              <th>MAX AMOUNT PER DAY</th>
              <th>MEASURE TYPE</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {limits?.map((item) => (
              <tr key={item.limitId}>
                <td>
                  <SText>{item.limitId}</SText>
                </td>
                <td>
                  <SText>{formatNumber(item.limit)}</SText>
                </td>
                <td>
                  <SText>{formatNumber(item.autoRollMax)}</SText>
                </td>
                <td>
                  <SText className='ms-4'>
                    {categories.find((i) => i.code === item.limitId)?.limit_type === 'GAL' ? 'GAL' : currency}
                  </SText>
                </td>
                <td>
                  <RowActions onUpdate={() => onUpdate(item)} onRemove={() => onRemove(item)} />
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!limits?.length && (
          <div className='text-center mt-2'>
            <Typography>No Records</Typography>
          </div>
        )}
      </div>
      {openSetLimit && (
        <SetLimit
          open={openSetLimit}
          onClose={() => {
            setOpenSetLimit(false);
            setLimitToUpdate(null);
          }}
          data={data}
          cardDetails={cardDetails}
          onSuccess={() => onSuccess(cardNumber)}
          limitToUpdate={limitToUpdate}
        />
      )}
      <SBackdrop open={loading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default CardLimits;
