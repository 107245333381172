import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import starIconOutLine from 'assets/icons/star-filled-outline.png';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import { ReactComponent as StarFilled } from 'assets/icons/star-filled.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { detailsPageMapper, reportTitleMapper } from 'pages/Reports/Reports.data';
import { SAccordion, SItem, STableWrapper, SWrapper } from './ReportAccordion.styles';

const readyReports = Object.keys(detailsPageMapper).map(Number); // Delete this when all report pages are ready

const ReportAccordion = ({ data, title, onStarClick }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);

  const onCategoryClick = (id) => {
    if (id === 23) {
      // IFTA
      navigate('/ifta');
      return;
    }

    if (readyReports.includes(id)) {
      navigate(`/reports/${id}`);
    } else {
      showToaster({
        type: 'error',
        message: 'This report is currently unavailable as we are upgrading the reports module.',
      });
    }
  };

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <SWrapper>
      <SAccordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ArrowUp className='accordion-arrow' />} id='panel1a-header'>
          <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray50) }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <STableWrapper>
            {(data.id === 1 ? data.favourite_sub_category : data.sub_category)?.map((item) => (
              <SItem key={item.id} onClick={() => onCategoryClick(item.id)}>
                <div className='title-wrapper'>
                  {item.is_favourite ? (
                    <StarFilled
                      fill={palette.indigo400}
                      onClick={(e) => onStarClick(e, item)}
                      className='item-star-icon'
                    />
                  ) : (
                    <img
                      width={14}
                      height={14}
                      alt='Star icon'
                      src={starIconOutLine}
                      className='item-star-icon'
                      onClick={(e) => onStarClick(e, item)}
                    />
                  )}
                  <Typography variant='s2'>{reportTitleMapper[item.sub_category] || item.sub_category}</Typography>
                </div>
                <ArrowRight className='arrow-icon' />
              </SItem>
            ))}
          </STableWrapper>
        </AccordionDetails>
      </SAccordion>
    </SWrapper>
  );
};

export default ReportAccordion;
