import React from 'react';

const DarkOverlay = ({ visible, styles, onClick = () => null }) => {
  return visible ? (
    <div
      onClick={onClick}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: 'rgba(0,0,0,0.5)',
        ...styles,
      }}
    />
  ) : null;
};

export default DarkOverlay;
