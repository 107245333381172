import { twoDecimals } from 'pages/Payroll/Settlement/helpers';

export const hoursWorkedConverter = (data) => {
  if (!data) {
    return {};
  }

  const { user } = data;

  const pay_per_hour = Number(user?.compensation_details?.per_hour_worked || user?.compensation_details?.per_hr_amt);
  let totalHours = 0;
  let totalMinutes = 0;

  const timeTracking = data?.time_trackings?.map((item) => {
    const hoursTracked = Number(item.hours_tracked || 0) + Number(item.minutes_tracked || 0) / 60;
    const amount = twoDecimals(hoursTracked * pay_per_hour);
    totalHours += Number(item.hours_tracked || 0);
    totalMinutes += Number(item.minutes_tracked || 0);

    return { ...item, amount };
  });

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  return { ...data, time_trackings: timeTracking, totalHours, totalMinutes };
};
