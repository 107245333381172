import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getCustomerPayTerms, createCustomPayTerm, updateCustomPayTerm, deleteCustomPayTerm } from 'Api/Customers';
import ItemForm from './ItemForm';
import { SWrapper, SHeader, SRow, SContentRow, SActions, SAddMore } from './PaymentTerms.styles';

const PaymentTerms = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [forms, setForms] = useState([]);
  const [editItems, setEditItems] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const getPaymentTerms = async () => {
    try {
      const { data } = await getCustomerPayTerms();
      setPaymentTerms(data);
    } catch (e) {
      // Do nothing
    }
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createPaymentTerm = async (values, id) => {
    setLoadingCreate(true);
    try {
      await createCustomPayTerm(values.title, values.discount || '0', values.net_day);
      await getPaymentTerms();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Payment term has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const updatePaymentTerm = async (values, id) => {
    try {
      const body = {
        title: values.title,
        discount: values.discount,
        net_day: values.net_day,
      };
      await updateCustomPayTerm({ id, body });
      await getPaymentTerms();
      onCancel(id);
      showToaster({ type: 'success', message: 'Payment term has been successfully updated!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const deletePaymentTerm = async (item) => {
    try {
      await deleteCustomPayTerm(item?.id);
      const newPaymentTerms = paymentTerms.filter((el) => el.id !== item?.id);
      setPaymentTerms(newPaymentTerms);
      showToaster({ type: 'success', message: 'Payment term has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const toggleEdit = (id) => {
    setEditItems([...editItems, id]);
  };

  const onCancel = (id) => {
    const newEditItems = editItems.filter((item) => item !== id);
    setEditItems(newEditItems);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getPaymentTerms();
  }, []);

  return (
    <SWrapper>
      <SHeader>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Payment Terms
        </Typography>
        <div className='header-actions'>
          {isConfiguringStep && (
            <>
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('saveAndNext')}
                type='primary'
                title={isConfiguringStep ? 'Save and Next' : 'Save'}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
            </>
          )}
        </div>
      </SHeader>
      <SRow>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            TERM NAME
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            PAYMENT NET
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            DISCOUNT
          </Typography>
        </div>
        <div className='settings-header-item' />
      </SRow>
      {paymentTerms?.map((item) => {
        return editItems.includes(item.id) ? (
          <ItemForm
            editable
            item={item}
            key={item.id}
            onCancel={() => onCancel(item?.id)}
            onSubmit={(values) => updatePaymentTerm(values, item.id)}
            onDelete={() => deletePaymentTerm(item)}
            loading={loadingCreate}
          />
        ) : (
          <SContentRow key={item.id}>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {item.default && item.title === 'Days' ? `${item.net_day} ${item.title}` : `${item.title}`}
              </Typography>
            </div>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {item.net_day}
              </Typography>
            </div>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {`${item.discount}%`}
              </Typography>
            </div>
            <SActions className='settings-table-item settings-table-actions-wrapper'>
              {!item.default && (
                <span className='action-wrapper' onClick={() => toggleEdit(item.id)}>
                  <EditIcon width={11} height={11} />
                </span>
              )}
            </SActions>
          </SContentRow>
        );
      })}
      {forms.map((item) => (
        <ItemForm
          key={item.id}
          onSubmit={(values) => createPaymentTerm(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
          loading={loadingCreate}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          Add Payment Term...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default PaymentTerms;
