import React, { Fragment } from 'react';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { STable } from 'pages/Reports/ReportDetails/ReportDetails.styles';
import ColumnHeader from 'common/ColumnHeader';

const DetailedTable = ({ reportData, type, sort, sortingQuery }) => {
  const { convertToCustomerTime } = useDateFormat();

  return (
    <STable>
      <thead>
        <tr className='header-row'>
          <th>
            <ColumnHeader title='LOCATION NAME' />
          </th>
          <th>
            <ColumnHeader title={type === 'equipment' ? 'EQUIPMENT' : 'DRIVER'} />
          </th>
          <th>
            <ColumnHeader title='CUSTOMER' />
          </th>
          <th>
            <ColumnHeader title='SHIPMENT ID' />
          </th>
          <th>
            <ColumnHeader title='ARRIVED DATE' field='arrived_date' sort={sort} onClick={sortingQuery} />
          </th>
          <th>
            <ColumnHeader title='DEPARTED DATE' field='departed_date' sort={sort} onClick={sortingQuery} />
          </th>
          <th>
            <ColumnHeader
              title='WAIT TIME'
              field='wait_time'
              sort={sort}
              onClick={sortingQuery}
              className='justify-content-end'
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {reportData.map((detail) => (
          <Fragment key={detail.id}>
            {detail.data.map((item) => (
              <tr key={item.id} className='body-row'>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.location_name}
                  </Typography>
                </td>
                {type === 'equipment' ? (
                  <td className='nowrap'>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.equipment?.equipment_id || ''}
                    </Typography>{' '}
                    {item.equipment?.length && (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.equipment.length.length || ''}
                        {item.equipment.length.unit || ''}{' '}
                      </Typography>
                    )}
                    {item.equipment?.equipment_type && (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.equipment.equipment_type.title || ''}
                        {item.equipment.equipment_type.title === 'Truck'
                          ? item.equipment.truck_type === 1
                            ? ' (Day-Cab)'
                            : ' (Sleeper)'
                          : ''}
                      </Typography>
                    )}
                  </td>
                ) : (
                  <td className='nowrap'>
                    {item.driver ? (
                      <UserInfo
                        size='32px'
                        statusSize='7px'
                        data={{
                          ...(item.driver || {}),
                          name: `${item.driver?.fname} ${item.driver?.lname}`,
                          user_type: 'driver',
                        }}
                      />
                    ) : (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        -
                      </Typography>
                    )}
                  </td>
                )}
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.shipment_billing ? `${item.shipment_billing.billing_customer?.customer_name}` : '-'}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.shipment_id || '-'}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.arrived_date ? convertToCustomerTime(item.arrived_date, null, true) : '-'}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.departed_date ? convertToCustomerTime(item.departed_date, null, true) : '-'}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <div className='d-flex gap-1 justify-content-end'>
                    {(!!item.duration_days || item.duration_days === 0) && (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.duration_days}d{' '}
                      </Typography>
                    )}
                    {(!!item.duration_hours || item.duration_hours === 0) && (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.duration_hours}h{' '}
                      </Typography>
                    )}
                    {(!!item.duration_minutes || item.duration_minutes === 0) && (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.duration_minutes}m
                      </Typography>
                    )}
                  </div>
                </td>
              </tr>
            ))}
            <tr className='body-row total-row'>
              <td className='nowrap' colSpan={6}>
                <Typography variant='button2'>
                  {type === 'equipment'
                    ? `${detail.data[0]?.equipment?.equipment_id || ''} ${
                        detail.data[0]?.equipment?.length?.length || ''
                      } ${detail.data[0]?.equipment?.length?.unit || ''} ${
                        detail.data[0]?.equipment?.equipment_type?.title || ''
                      } ${
                        detail.data[0]?.equipment?.equipment_type?.title === 'Truck'
                          ? detail.data[0]?.equipment?.truck_type === 1
                            ? ' (Day-Cab)'
                            : ' (Sleeper)'
                          : ''
                      }`
                    : `${detail.data[0]?.driver?.fname} ${detail.data[0]?.driver?.lname}`}{' '}
                  Average Wait Time
                </Typography>
              </td>
              <td className='nowrap'>
                <div className='d-flex justify-content-end'>
                  <Typography variant='button2'>
                    (Visited {detail.data.length} Time{detail.data.length > 1 ? 's' : ''}){' '}
                    {getAverageTimeConverted(detail.totalWaitTimeMinutes / detail.data.length)}
                  </Typography>
                </div>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </STable>
  );
};

export default DetailedTable;
