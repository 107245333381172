import React from 'react';
import style from './AlertIcon.module.css';
import { useTheme } from '../../../../../context/themeContext';
import { palette } from '../../../../../utils/constants';

const FaultCode = ({ faultCodeData }) => {
  const { use } = useTheme();
  return (
    <>
      <div className={style.faultcode}>
        <p style={{ color: use(palette.dark800, palette.gray200) }}>
          Fault Detected :<span className={style.AlertCounting}>{faultCodeData.length}</span>
        </p>
      </div>
      <div className={style.foultCode} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        {faultCodeData &&
          faultCodeData.map((v) => (
            <div style={{ backgroundColor: use(palette.white, palette.dark800) }} key={v.id}>
              <div className='mt-2'>
                <span className={style.faultcodeTitle} style={{ color: use(palette.dark800, palette.gray200) }}>
                  {v?.fault_title}
                </span>
                <p className={style.faultcodeDescription} style={{ color: use(palette.dark800, palette.gray200) }}>
                  {v?.fault_code_description}
                </p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default FaultCode;
