import React, { useState } from 'react';
import Tabs from 'common/Tabs';
import Divider from 'common/Divider';
import Details from '../components/Details';
import Compare from '../components/Compare';
import YearToDate from '../components/YearToDate';
import { tabs } from './SettlementDetails.data';
import { STabContent, SWrapper } from './SettlementDetails.styles';

const SettlementDetails = ({ settlement }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  return (
    <SWrapper>
      <Tabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      <Divider margin='0' />
      <STabContent>
        {activeTab === 0 && <Details settlement={settlement} />}
        {activeTab === 1 && <YearToDate settlement={settlement} />}
        {activeTab === 2 && <Compare settlement={settlement} />}
      </STabContent>
    </SWrapper>
  );
};

export default SettlementDetails;
