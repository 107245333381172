import React from 'react';
import { FastField, Field } from 'formik';
import person from 'assets/images/person.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg';
import { InputWithIcon } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { validateRequired } from 'components/AddEditModalStops/validates';
import ErrorMessage from 'common/ErrorMessage';
import classes from '../replaceDriver.module.scss';

const Driver = ({ index, setValues, data, values, drivers, touched, errors, hasSecond }) => {
  function onChangeSwitchers(name, v, setValues) {
    setValues((prevState) => ({ ...prevState, [name]: v }));
  }

  const onInputChange = (event, name) => {
    setValues((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  return (
    <div>
      <div className={classes.switchWrapper}>
        <Field>
          {({ field, form }) => (
            <CustomCheckbox
              name={`replace${index}`}
              field={field}
              form={form}
              type='switch'
              smail={false}
              withinForm
              style={{ marginTop: -8 }}
              checked={!!values[`replace${index}`]}
              onChange={(v) => onChangeSwitchers(`replace${index}`, v, setValues, values)}
            />
          )}
        </Field>
        <div className={classes.switchTextWrapper}>
          <Typography variant='s2'>Replace Driver {hasSecond && index}</Typography>
        </div>
      </div>

      {values[`replace${index}`] && (
        <div style={{ marginLeft: 35 }}>
          <div className={classes.childContainer}>
            <div className='d-flex align-items-start' style={{ width: '220px' }}>
              <div className={classes.imgWrapper}>
                <img src={data.image || person} alt='driver' />
                <div className={classes.statusImg} />
              </div>
              <Typography className={classes.name} variant='s2'>
                {data?.fname} {data?.lname}
              </Typography>
              <ArrowRightIcon className={classes.icon} />
            </div>

            <div style={{ width: '100%', maxWidth: 220 }}>
              <FastField
                name={`assigned_to${index}`}
                validate={validateRequired}
                component={CustomSelect}
                styles={{ height: 32, borderRadius: 6 }}
                options={drivers}
                shouldUpdate={() => true}
              />
              <ErrorMessage error={touched?.[`assigned_to${index}`] && errors?.[`assigned_to${index}`]} />
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', gap: 10, alignItems: 'baseline' }}>
            <div className='d-flex gap-3'>
              <InputWithIcon
                required
                width='220px'
                placeholder='Pay amount for old driver'
                name={`previous_driver${index}_amount`}
                onChange={(event) => onInputChange(event, `previous_driver${index}_amount`)}
                error={touched?.[`previous_driver${index}_amount`] && errors?.[`previous_driver${index}_amount`]}
              />
              <InputWithIcon
                required
                width='220px'
                placeholder='Pay amount for new driver'
                name={`amount${index}`}
                onChange={(event) => onInputChange(event, `amount${index}`)}
                error={touched?.[`amount${index}`] && errors?.[`amount${index}`]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Driver;
