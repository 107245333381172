import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getIFTAPolicy, updateIFTAPolicy } from 'Api/Insurance';
import InsuranceFields from '../components/InsuranceFields';
import { getInitialValues, iftaFields } from './IFTA.data';
import { validationSchema } from './validationSchema';
import { SWrapper } from '../Equipment.styles';

const IFTA = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('ifta_sticker_active', Number(values.ifta_sticker_active));
      formData.append('ifta_sticker_no', values.ifta_sticker_no || '');
      formData.append(
        'ifta_sticker_expire_date',
        values.ifta_sticker_no_expire ? '' : moment(values.ifta_sticker_expire_date).format('YYYY-MM-DD')
      );
      if (typeof values.ifta_sticker_attach !== 'string') {
        formData.append('ifta_sticker_attach', values.ifta_sticker_attach);
      }

      await updateIFTAPolicy(formData);
      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const getSettings = async () => {
    try {
      const { data } = await getIFTAPolicy();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='IFTA'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={form.handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      <div>{loading ? <Loader loading={loading} /> : <InsuranceFields field={iftaFields} form={form} />}</div>
    </SWrapper>
  );
};

export default IFTA;
