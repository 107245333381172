import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBillingCardsFeatures,
  getBillingCardsPrice,
  getBillingDetail,
  getPaymentMethod,
} from 'store/actions/billing.actions';
import {
  toggleManageModalIsVisible,
  // toggleUpdatePaymentMethodModalIsVisible,
  toggleUpDownGradeModalIsVisible,
} from 'store/reducers/billing.reducer';
import { useAuth } from 'context/auth.context';
import PlanLicense from 'components/Billing/PlanLicense';
import SelectPlanModal from 'components/Billing/SelectPlanModal';
import HardwareLicenses from './HardwareLicenses';
import CustomModal from '../CustomModal/CustomModal';
import UpgradeModal from './UpgradeModal/UpgradeModal';
import Transactions from './Transactions/Transactions';
import ManageModale from './ManageModale/ManageModale';
import BillingHeader from './BillingHeader/BillingHeader';
import AdditionalServices from './AdditionalServices/AdditionalServices';

const Billing = () => {
  const [updateTransactionsIndex, setUpdateTransactionsIndex] = useState(Date.now());
  const [cancelAccount, setCancelAccount] = useState(false);
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const dispatch = useDispatch();
  const { value, setAuth } = useAuth();
  const {
    billingDetail,
    manageModalIsVisible,
    upDownGradeModalIsVisible,
    // updatePaymentMethodModalVisible,
    paymentRequired,
  } = useSelector((state) => state.billing);

  // const onUpdateMethodSuccess = () => {
  //   dispatch(getPaymentMethod());
  //   dispatch(toggleUpdatePaymentMethodModalIsVisible(false));
  // };

  useEffect(() => {
    dispatch(getPaymentMethod());
    dispatch(getBillingDetail());
    dispatch(getBillingCardsPrice());
    dispatch(getBillingCardsFeatures());
    setAuth({ ...value, shouldUpdateStatus: Date.now() }); // To trigger useEffect in layout which updates account status
  }, []);

  useEffect(() => {
    if (!billingDetail) {
      return;
    }

    if (
      paymentRequired?.is_require_payment_enable === 0 &&
      accountStatus?.account_status === 'trialexpired' &&
      !billingDetail?.customer_plan?.is_payment_initiated
    ) {
      dispatch(toggleUpDownGradeModalIsVisible(true));
    }
  }, [billingDetail, accountStatus]);

  return (
    <>
      <BillingHeader billingDetail={billingDetail} cancelAccount={cancelAccount} setCancelAccount={setCancelAccount} />
      <Transactions
        title='Open Bills'
        status='Pending Payment,Pending Financing,Failed Payment,Declined Payment,Processing'
        updateIndex={updateTransactionsIndex}
      />
      <PlanLicense />
      <AdditionalServices onPurchaseSuccess={() => setUpdateTransactionsIndex(Date.now())} />
      <HardwareLicenses />
      <Transactions title='Transactions' updateIndex={updateTransactionsIndex} />
      {/* <UpdatePaymentMethod */}
      {/*  open={updatePaymentMethodModalVisible} */}
      {/*  onClose={() => dispatch(toggleUpdatePaymentMethodModalIsVisible(false))} */}
      {/*  onSuccess={onUpdateMethodSuccess} */}
      {/* /> */}
      <CustomModal
        showModal={upDownGradeModalIsVisible}
        styleBody={{ width: 1160, backgroundColor: '#F7F9FC' }}
        onHide={() => dispatch(toggleUpDownGradeModalIsVisible(false))}
        headerTitle={
          paymentRequired?.is_require_payment_enable === 0 && accountStatus?.account_status === 'trialexpired'
            ? 'Trial Expired - Please Select Plan'
            : paymentRequired?.is_require_payment_enable === 0 && billingDetail?.payment_method_bypass
            ? 'Select Plan'
            : 'Upgrade/Downgrade Plan'
        }
        styleButtons={{ padding: '6px 12px' }}
        customBody={{ borderRadius: '0 0 20px 20px' }}
        footer={false}
        backdrop='static'
      >
        {paymentRequired?.is_require_payment_enable === 0 &&
        (accountStatus?.account_status === 'trialexpired' || billingDetail?.payment_method_bypass) ? (
          <SelectPlanModal
            onSuccess={() => setUpdateTransactionsIndex(Date.now())}
            onCancelAccount={() => setCancelAccount(true)}
          />
        ) : (
          <UpgradeModal
            onSuccess={() => setUpdateTransactionsIndex(Date.now())}
            onCancelAccount={() => setCancelAccount(true)}
          />
        )}
      </CustomModal>
      <CustomModal
        showModal={manageModalIsVisible}
        styleBody={{ width: 850, backgroundColor: '#FFFFFF' }}
        onHide={() => dispatch(toggleManageModalIsVisible(false))}
        headerTitle='Manage User Licenses'
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <ManageModale />
      </CustomModal>
    </>
  );
};

export default Billing;
