import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import Divider from 'common/Divider';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import TrackingDetailsModal from 'components/TrackinDetailModal/TrackingDetailModal';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import useDebounce from 'hooks/useDebounce';
import {
  getTimeTrackingList,
  createTimeTracking,
  updateTimeTracking,
  deleteTimeTrackingRecord,
} from 'Api/TimeTracking';
import { customerTimeToUtc } from 'utils/helpers';
import ConfirmationModal from 'common/ConfirmationModal';
import { STabs } from 'pages/ApplicantsAndReferrals/ApplicantsAndReferrals.styles';
import { getPayPeriod } from 'Api/PayrollSettings';
import { getCurrentPayPeriod } from 'pages/Payroll/PayrollAndSettlements/helpers';
import TableFilters from '../TableFilters';
import { useTimeTrackingColumns } from './TimeTrackingTable.data';
import { timeTrackingConvertor } from '../../TimeTracking.convertors';
import { STableWrapper } from '../../TimeTracking.styles';

export const initialFilters = {
  page: 1,
  page_size: 50,
};

const TimeTrackingTable = () => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [activeTab, setActiveTab] = useState(2);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeTrackingData, setTimeTrackingData] = useState({ data: [] });
  const [tableIndex, setTableIndex] = useState(Date.now());
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [selectedFilters] = useState(initialFilters);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedTrucking, setSelectedTrucking] = useState(null);
  const [openAddUpdateRecord, setOpenAddUpdateRecord] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [rangeName, setRangeName] = useState('Custom Range');
  const [exportParams, setExportParams] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(30, 'days').toDate(),
    end: moment().toDate(),
  });
  const debouncedSearch = useDebounce(search);
  const didMountRef = useRef(false);

  const getTimeTracking = async () => {
    setLoading(true);
    if (!didMountRef?.current) {
      const { data } = await getPayPeriod();
      const range = getCurrentPayPeriod(data);
      didMountRef.current = true;
      setDateRange({ start: moment(range.start).toDate(), end: moment(range.end).toDate() });
      return;
    }

    const { page, page_size } = selectedFilters || {};

    const params = {
      page,
      page_size,
      query: debouncedSearch || undefined,
      user_type: activeTab,
      start_time: moment(dateRange.start).format('YYYY-MM-DD'),
      end_time: moment(dateRange.end).format('YYYY-MM-DD'),
    };
    setExportParams({ ...params, page: undefined, page_size: undefined });

    try {
      const response = await getTimeTrackingList(params);
      const data = timeTrackingConvertor(response.data, activeTab);

      setTimeTrackingData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const addUpdateTracking = async (updatedTrackingInfo) => {
    setUpdateLoading(true);
    const payload = { ...updatedTrackingInfo };
    const diffInMinutes = Math.abs(moment(payload.end_time || new Date()).diff(payload.start_time, 'minutes'));
    const hours_tracked = Math.floor(diffInMinutes / 60);
    const minutes_tracked = diffInMinutes % 60;

    const start_time_customer = moment(payload.start_time).format('YYYY-MM-DD HH:mm:ss');
    const end_time_customer = payload.end_time ? moment(payload.end_time).format('YYYY-MM-DD HH:mm:ss') : null;

    try {
      if (selectedTrucking) {
        await updateTimeTracking(selectedTrucking.id, {
          ...payload,
          user_id: selectedTrucking.user_id,
          user_type: selectedTrucking.user_type,
          start_time: customerTimeToUtc(start_time_customer),
          end_time: end_time_customer && customerTimeToUtc(end_time_customer),
          hours_tracked,
          minutes_tracked,
          user: undefined,
          start_location_lat: undefined,
          start_location_long: undefined,
          end_location_lat: undefined,
          end_location_long: undefined,
        });
      } else {
        const formData = new FormData();

        const {
          user,
          start_time_street,
          start_time_city,
          start_time_state_code,
          start_time_country,
          start_time_zip,
          end_time_street,
          end_time_city,
          end_time_state_code,
          end_time_country,
          end_time_zip,
          start_location_lat,
          start_location_long,
          end_location_lat,
          end_location_long,
        } = payload;

        formData.append('user_id', user.id);
        formData.append('user_type', activeTab);

        if (start_time_street) {
          formData.append('start_time_street', start_time_street);
        }
        if (start_time_city) {
          formData.append('start_time_city', start_time_city);
        }
        if (start_time_state_code) {
          formData.append('start_time_state_code', start_time_state_code);
        }
        if (start_time_country) {
          formData.append('start_time_country', start_time_country);
        }
        if (start_time_zip) {
          formData.append('start_time_zip', start_time_zip);
        }
        if (start_location_lat) {
          formData.append('start_location_lat', start_location_lat);
        }
        if (start_location_long) {
          formData.append('start_location_long', start_location_long);
        }

        if (end_time_street) {
          formData.append('end_time_street', end_time_street);
        }
        if (end_time_city) {
          formData.append('end_time_city', end_time_city);
        }
        if (end_time_state_code) {
          formData.append('end_time_state_code', end_time_state_code);
        }
        if (end_time_country) {
          formData.append('end_time_country', end_time_country);
        }
        if (end_time_zip) {
          formData.append('end_time_zip', end_time_zip);
        }
        if (end_location_lat) {
          formData.append('end_location_lat', end_location_lat);
        }
        if (end_location_long) {
          formData.append('end_location_long', end_location_long);
        }

        formData.append('start_time', customerTimeToUtc(start_time_customer));
        if (end_time_customer) {
          formData.append('end_time', customerTimeToUtc(end_time_customer));
        }
        formData.append('hours_tracked', hours_tracked);
        formData.append('minutes_tracked', minutes_tracked);

        await createTimeTracking(formData);
      }

      showToaster({
        type: 'success',
        message: `Time tracking record has been successfully ${selectedTrucking ? 'updated' : 'created'}!`,
      });
      setTableIndex(Date.now());
      setOpenAddUpdateRecord(false);
      setSelectedTrucking(null);
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setUpdateLoading(false);
  };

  const deleteTimeTracking = async () => {
    try {
      setLoadingDelete(true);
      await deleteTimeTrackingRecord(recordToDelete);
      showToaster({
        type: 'success',
        message: `Record has been successfully deleted!`,
      });
      getTimeTracking();
      setRecordToDelete(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onDelete = (id) => {
    setRecordToDelete(id);
  };

  const onUpdateTimeTracking = (row) => {
    setSelectedTrucking(row);
    setOpenAddUpdateRecord(true);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleAccordion = (row) => {
    const newData = timeTrackingData.data.map((item) => {
      if (row.id !== item.id) {
        return item;
      }
      return { ...item, isActive: !item.isActive };
    });
    setTimeTrackingData((prevState) => ({ ...prevState, data: newData }));
  };

  useEffect(() => {
    getTimeTracking();
  }, [selectedFilters, sort, dateRange, tableIndex, debouncedSearch, activeTab]);

  const columns = useTimeTrackingColumns({
    sortingQuery,
    sort,
    onDelete,
    toggleAccordion,
    onUpdateTimeTracking,
    dateRange,
  });

  return (
    <STableWrapper>
      <STabs value={activeTab} onChange={handleTabChange}>
        <Tab label='Drivers' value={2} id='tab-1' />
        <Tab label='Staff' value={1} id='tab-2' />
      </STabs>
      <Divider />
      {showPicker && (
        <div style={{ position: 'relative', left: '143px', bottom: '57px' }}>
          <DateRange
            title='All Time'
            dateTimeRange={dateRange}
            dateSelect={setDateRange}
            setRangeName={setRangeName}
            setIsShowDatePicker={setShowPicker}
          />
        </div>
      )}
      <TableFilters
        search={search}
        setSearch={setSearch}
        rangeName={rangeName}
        showPicker={showPicker}
        exportParams={exportParams}
        setShowPicker={setShowPicker}
        setOpenAddUpdateRecord={setOpenAddUpdateRecord}
        activeTab={activeTab}
      />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={timeTrackingData?.data}
          onRowClick={(e, rowData) => {
            if (rowData.type === 'allUsers') {
              return;
            }
            toggleAccordion(rowData);
          }}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          // components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {!!openAddUpdateRecord && (
        <TrackingDetailsModal
          show={!!openAddUpdateRecord}
          onHide={() => {
            setOpenAddUpdateRecord(false);
            setSelectedTrucking(null);
          }}
          trackingDetails={selectedTrucking}
          updateTimeTracking={addUpdateTracking}
          loading={updateLoading}
          userType={activeTab === 1 ? 'staff' : 'driver'}
        />
      )}
      {!!recordToDelete && (
        <ConfirmationModal
          width='510px'
          open={!!recordToDelete}
          onClose={() => setRecordToDelete(null)}
          headerTitle='Delete Record'
          text='Are you sure you want to delete this time tracking record?'
          onConfirm={deleteTimeTracking}
          disabled={loadingDelete}
        />
      )}
    </STableWrapper>
  );
};

export default TimeTrackingTable;
