import React, { useEffect, useState } from 'react';
import './GeneralInformationTab.css';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getCustomerMoney, UpdateCustomersGeneralProfile } from 'Api/Customers';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import Autocomplete from 'common/Autocomplete';
import { getAccountsList } from 'Api/Accounts';
import AddressFields from 'common/AddressFields';
import MoneyChart from 'components/GeneralInformationTab/Charts/MoneyChart';
import { SChartWrapper } from 'components/GeneralInformationTab/GeneralInformationTan.styles';
import { formatPhoneNumber } from 'utils/helpers';
import useForm from 'hooks/useForm';
import validationSchema from './ValidationSchema';
import CustomInput from '../CreateShipment/helpers/CustomInput';
import { Typography } from '../Typography';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

const whitelist = ['US', 'CA', 'MX'];

const GeneralInformationTab = ({ customerInfo, onSuccess, loading, customer }) => {
  const { use } = useTheme();
  const [toggle, setToggle] = useState(false);
  const showToaster = useShowToaster();
  const [accounts, setAccounts] = useState([]);
  const [customerMoney, setCustomerMoney] = useState([]);

  const formik = useForm({
    initialValues: {
      companyName: '',
      companyDot: '',
      phone_number: '',
      customer_address: '',
      country_data: null,
      state_data: null,
      city_data: null,
      customer_zip: '',
      customer_address2: '',
      equipment_vehicle_age: null,
      equipment_trailer_age: null,
      equipment_vehicle_age_flag: null,
      equipment_trailer_age_flag: null,
      account: null,
    },
    validationSchema,
  });
  const { values, handleChange, touchedErrors } = formik;

  useEffect(() => {
    formik.setFieldValue('companyName', customerInfo?.companyName);
    formik.setFieldValue('companyDot', customerInfo?.companyDot);
    formik.setFieldValue('phone_number', customerInfo?.phone_number);
    formik.setFieldValue('customer_address', customerInfo?.customer_address);
    formik.setFieldValue('customer_address2', customerInfo?.customer_address2);
    formik.setFieldValue('city_data', customerInfo?.city_data);
    formik.setFieldValue('country_data', customerInfo?.country_data);
    formik.setFieldValue('state_data', customerInfo?.state_data);
    formik.setFieldValue('customer_zip', customerInfo?.customer_zip);
    formik.setFieldValue('equipment_vehicle_age', customerInfo?.equipment_restrictions?.equipment_vehicle_age);
    formik.setFieldValue('equipment_trailer_age', customerInfo?.equipment_restrictions?.equipment_trailer_age);
    formik.setFieldValue(
      'equipment_vehicle_age_flag',
      !!customerInfo?.equipment_restrictions?.equipment_vehicle_age_flag
    );
    formik.setFieldValue(
      'equipment_trailer_age_flag',
      customerInfo?.equipment_restrictions?.equipment_trailer_age_flag
    );
    formik.setFieldValue('account', customerInfo?.account || null);
  }, [customerInfo]);

  const saveGeneral = () => {
    formik.validateForm(formik.values).then((res) => {
      if (isEmpty(res)) {
        const payload = {
          customer_id: customer?.customer_id,
          general_ledger_id: customer?.general_ledger_id,
          company_name: formik.values.companyName,
          telephone_number: formik.values.phone_number,
          address1: formik.values.customer_address,
          address2: formik.values.customer_address2,
          country_id: formik.values.country_data.id,
          state_id: formik.values.state_data.id,
          city_id: formik.values.city_data.id,
          zipcode: formik.values.customer_zip,
          company_dot_mc: formik.values.companyDot,
          equipment_vehicle_age: formik.values.equipment_vehicle_age,
          equipment_trailer_age: formik.values.equipment_trailer_age,
          equipment_trailer_age_flag: Number(formik.values.equipment_trailer_age_flag),
          equipment_vehicle_age_flag: Number(formik.values.equipment_vehicle_age_flag),
          account_id: formik.values.account?.id,
        };
        UpdateCustomersGeneralProfile(payload, customer.id)
          .then(() => {
            onSuccess();
            setToggle(!toggle);
          })
          .catch((err) => {
            showToaster({ type: 'error', message: getErrorMessage(err) });
          });
      } else {
        showToaster({ type: 'error', message: Object.values(res)[0] });
      }
    });
  };

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.account_type.id === ACCOUNT_TYPE.INCOME));
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerMoneyData = async () => {
    try {
      const { data } = await getCustomerMoney(customer.id);
      setCustomerMoney(data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleAddressChange = (event, value) => {
    const addressFieldsMapper = {
      address: 'customer_address',
      address2: 'customer_address2',
      city: 'city_data',
      state: 'state_data',
      country: 'country_data',
      zipcode: 'customer_zip',
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (!customer?.id) {
      return;
    }
    getCustomerMoneyData();
  }, [customer]);

  return (
    <div>
      <div
        className='generalInformationTab'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
          <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            General Information
          </p>
          {!toggle ? (
            <div>
              <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
            </div>
          ) : (
            <div>
              <button onClick={() => setToggle(!toggle)} className='driver-compansation-cancel-btn'>
                Cancel
              </button>
              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <button onClick={() => saveGeneral()} className='driver-compansation-save-btn'>
                  Save
                </button>
              )}
            </div>
          )}
        </div>
        {toggle ? (
          <div className='d-flex ' style={{ backgroundColor: use(palette.gray0, palette.dark900) }}>
            <div className='information-edit-mode-tab'>
              <div
                className='information-edit-mode-sections'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder), maxWidth: '350px' }}
              >
                <p className='table-data-text col-6' style={{ color: use(palette.gray400, palette.gray200) }}>
                  Company Name <span className='required'>*</span>
                </p>
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use('black', palette.gray600),
                  }}
                  className='input-type'
                  name='companyName'
                  id='companyName'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.companyName}
                />
              </div>
              {formik.touched.companyName && formik.errors.companyName ? (
                <span className='tab-error-message'>{formik.errors.companyName}</span>
              ) : null}
              <div
                className='information-edit-mode-sections'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder), maxWidth: '350px' }}
              >
                <p className='table-data-text' style={{ color: use(palette.gray400, palette.gray200) }}>
                  Phone Number <span className='required'>*</span>
                </p>
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  addInternationalOption={false}
                  className='input-type'
                  value={formik.values.phone_number}
                  countries={whitelist}
                  onChange={(val) => formik.setFieldValue('phone_number', val, true)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                />
                {formik.touched.phone_number && formik.errors.phone_number ? (
                  <span className='tab-error-message'>{formik.errors.phone_number}</span>
                ) : null}
              </div>
              <div className='information-edit-mode-sections' style={{ maxWidth: '350px' }}>
                <p className='table-data-text' style={{ color: use(palette.gray400, palette.gray200) }}>
                  Account <span className='required'>*</span>
                </p>
                <Autocomplete
                  name='account'
                  labelKey='account_name'
                  options={accounts}
                  value={formik.values.account}
                  onChange={(e, val) => formik.setFieldValue('account', val)}
                  style={{ maxWidth: '600px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </div>
              <div
                className='information-edit-mode-sections'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder), maxWidth: '350px' }}
              >
                <p className='table-data-text col-6' style={{ color: use(palette.gray400, palette.gray200) }}>
                  Equipment Restrictions
                </p>
              </div>

              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  value={formik.values.equipment_vehicle_age_flag}
                  checked={!!formik.values.equipment_vehicle_age_flag}
                  type='switch'
                  onChange={(val) => formik.setFieldValue('equipment_vehicle_age_flag', val, true)}
                />
                <span>Equipment Vehicles Age, must be under</span>
                <div style={{ width: 50 }}>
                  <CustomInput
                    type='number'
                    id='equipment_vehicle_age'
                    name='equipment_vehicle_age'
                    value={!formik.values.equipment_vehicle_age_flag ? '' : formik.values.equipment_vehicle_age}
                    disabled={!formik.values.equipment_vehicle_age_flag}
                    labelStyle={{ margin: 0 }}
                    style={{
                      width: 50,
                      height: 32,
                      borderRadius: 6,
                    }}
                    onChange={formik.handleChange}
                  />
                </div>

                <span>years old.</span>
              </div>

              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  onChange={(val) => formik.setFieldValue('equipment_trailer_age_flag', val, true)}
                  checked={!!formik.values.equipment_trailer_age_flag}
                  type='switch'
                />
                <span>Equipment Trailers Age, must be under</span>
                <div style={{ width: 50, marginTop: '10px' }}>
                  <CustomInput
                    id='equipment_trailer_age'
                    name='equipment_trailer_age'
                    type='number'
                    value={!formik.values.equipment_trailer_age_flag ? '' : formik.values.equipment_trailer_age}
                    disabled={!formik.values.equipment_trailer_age_flag}
                    labelStyle={{ margin: 0 }}
                    style={{
                      width: 50,
                      height: 32,
                      borderRadius: 6,
                    }}
                    onChange={formik.handleChange}
                  />
                </div>

                <span>years old.</span>
              </div>
            </div>
            <div className='information-edit-mode-tab'>
              <div
                className='information-edit-mode-sections'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder), maxWidth: '350px' }}
              >
                <p className='table-data-text' style={{ color: use(palette.gray400, palette.gray200) }}>
                  Company DOT / MC
                </p>
                <input
                  className='input-type'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use('black', palette.gray600),
                  }}
                  type='text'
                  name='companyDot'
                  id='companyDot'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.companyDot}
                />
              </div>
              {formik.touched.companyDot && formik.errors.companyDot ? (
                <span className='tab-error-message'>{formik.errors.companyDot}</span>
              ) : null}
              <div
                className='information-edit-mode-sections'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder), maxWidth: '350px' }}
              >
                <AddressFields
                  required
                  size='small'
                  isOneColumn
                  values={{
                    address: values.customer_address,
                    address2: values.customer_address2,
                    city: values.city_data,
                    state: values.state_data,
                    country: values.country_data,
                    zipcode: values.customer_zip,
                  }}
                  handleChange={handleAddressChange}
                  touchedErrors={touchedErrors}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex'>
            <div className='information'>
              <div
                className='info-container border-block'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='property'>Company Name</p>
                <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                  {customerInfo?.companyName}
                </p>
              </div>

              <div
                className='info-container border-block'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='property'>Phone Number</p>
                <div onClick={(e) => e.stopPropagation()}>
                  <Typography variant='b2' className='list-driver-phone'>
                    <a href={`tel:${customerInfo?.phone_number}`}>
                      {customerInfo?.phone_number ? formatPhoneNumber(customerInfo?.phone_number) : '-'}
                    </a>
                  </Typography>
                </div>
              </div>
              <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
                <p className='property'>Account</p>
                <p className='value' style={{ color: palette.gray900 }}>
                  {customerInfo?.account?.account_name || '-'}
                </p>
              </div>
              <div className='info-container border-block'>
                <p className='property'>Equipment Restrictions</p>
                <div className='d-flex gap-2 mt-3'>
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Equipment Vehicles Age, must be under
                  </Typography>
                  {customerInfo?.equipment_restrictions?.equipment_vehicle_age_flag ? (
                    <span className='number'>{customerInfo?.equipment_restrictions?.equipment_vehicle_age}</span>
                  ) : (
                    <span className='number'>-</span>
                  )}
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    years old.
                  </Typography>
                </div>
              </div>

              <div className='info-container border-block'>
                <div className='d-flex gap-2 mt-2'>
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Equipment Trailers Age, must be under
                  </Typography>
                  {customerInfo?.equipment_restrictions?.equipment_trailer_age_flag ? (
                    <span className='number'>{customerInfo?.equipment_restrictions?.equipment_trailer_age}</span>
                  ) : (
                    <span className='number'>-</span>
                  )}
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    years old.
                  </Typography>
                </div>
              </div>
            </div>
            <div className='information'>
              <div className='info-container border-block'>
                <p className='property'>Company DOT / MC</p>
                <p className='value'>{customerInfo?.companyDot || '-'}</p>
              </div>
              <div
                className='info-container address border-block'
                style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='property'>Address</p>
                <div className='address-details'>
                  <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                    {customerInfo?.customer_address}
                  </p>
                  <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                    {customerInfo?.customer_address2}
                  </p>
                  <p
                    className='value'
                    style={{
                      color: use(palette.gray900, palette.gray50),
                    }}
                  >
                    {customerInfo?.state_data?.name}, {customerInfo?.city_data?.name}, {customerInfo?.customer_zip}
                  </p>
                  <p className='value' style={{ color: use(palette.gray900, palette.gray50) }}>
                    {customerInfo?.country_data?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='mt-4'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Money Billed in Over Last 12 Months
        </Typography>
        <SChartWrapper>
          <MoneyChart monthsData={customerMoney} />
        </SChartWrapper>
      </div>
    </div>
  );
};

export default GeneralInformationTab;
