import React, { useEffect, useState } from 'react';
import moment from 'moment';
import right from 'assets/icons/drivers/right.svg';
import upload from 'assets/icons/drivers/upload.png';
import cross from 'assets/icons/drivers/secondClose.svg';
import { uploadDriverDocument } from 'Api/Driver';
import { getDriverStaff } from 'Api/Request';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Input, { Textarea } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import ImageDropZone from 'components/ImgageDropZone';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { createAbsence, getAbsenceTypes, updateAbsence } from 'Api/Absence';
import { getInitialValues } from 'componentsV2/Absence/AddUpdateAbsence/AddUpdateAbsence.data';
import { Typography } from 'components/Typography';
import { validationSchema } from './validationSchema';

const AddUpdateAbsence = ({ open, onClose, absence, onSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [driverStaffList, setDriverStaffList] = useState([]);
  const [absenceTypes, setAbsenceTypes] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        driver_staff_id: values.driver_staff_id.id,
        user_type: values.driver_staff_id.user_type,
        reason_title: values.reason_title,
        absence_start_date: moment(values.absence_start_date).format('MM/DD/YYYY'),
        absence_end_date: moment(values.absence_end_date).format('MM/DD/YYYY'),
        absence_details: values.absence_details,
        type: Number(values.type),
        internal_notes: values.internal_notes,
        absence_document: values.absence_document,
      };

      if (absence) {
        await updateAbsence(body, absence.id);
      } else {
        await createAbsence(body);
      }

      showToaster({ type: 'success', message: `Attendance has been successfully ${absence ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, touchedErrors } = useForm({
    initialValues: getInitialValues(absence),
    validationSchema,
    onSubmit,
  });

  const getAbsenceType = async () => {
    try {
      const res = await getAbsenceTypes();
      setAbsenceTypes(res || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getAllDriverStaffData = async () => {
    try {
      const res = await getDriverStaff();
      const convertedData = res.map((item) => ({
        ...item,
        name: `${item.name} (${item.user_type})`,
      }));

      setDriverStaffList(convertedData);

      if (absence) {
        handleChange(
          'driver_staff_id',
          convertedData.find((i) => i.id === absence.driver_staff_id && i.user_type === absence.user_type)
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  const uploadLicenseDocument = (file) => {
    uploadDriverDocument(file[0]).then((res) => {
      handleChange('absence_document', res.data.document_path);
    });
  };

  useEffect(() => {
    getAllDriverStaffData();
    getAbsenceType();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={absence ? 'Update Attendance' : 'Add Attendance'}
      $bgColor={palette.gray0}
      backdrop='static'
      $maxWidth={absence ? '1200px' : '480px'}
      $minWidth={absence ? '1200px' : '480px'}
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: absence ? 'Update Attendance' : 'Add Attendance',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex gap-4'>
        <div className={`d-flex flex-column gap-4 ${!absence ? 'w-100' : ''}`}>
          <div>
            <Autocomplete
              required
              label='Driver/Staff'
              name='driver_staff_id'
              placeholder='Select Driver/Staff'
              options={driverStaffList}
              value={values.driver_staff_id}
              onChange={(e, value) => handleChange('driver_staff_id', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.driver_staff_id}
              disabled={absence}
            />
          </div>
          <div>
            <Input
              required
              label='Reason Title'
              name='reason_title'
              placeholder='Reason Title'
              onChange={handleChange}
              value={values.reason_title}
              error={touchedErrors.reason_title}
            />
          </div>
          <div>
            <DatePicker
              required
              label='Attendance Start Date'
              width='100%'
              name='absence_start_date'
              onBlur={handleBlur}
              value={values.absence_start_date}
              onChange={(val) => handleChange('absence_start_date', val)}
              error={touchedErrors.absence_start_date}
            />
          </div>
          <div>
            <DatePicker
              required
              label='Attendance End Date'
              width='100%'
              name='absence_end_date'
              onBlur={handleBlur}
              value={values.absence_end_date}
              onChange={(val) => handleChange('absence_end_date', val)}
              error={touchedErrors.absence_end_date}
            />
          </div>
          <div>
            <Textarea
              required
              rows={3}
              label='Driver Notes'
              name='absence_details'
              placeholder='Write driver notes...'
              value={values.absence_details}
              onChange={handleChange}
              error={touchedErrors.absence_details}
            />
          </div>
          <div>
            <Textarea
              required
              rows={3}
              label='Internal Notes'
              name='internal_notes'
              placeholder='Write internal notes...'
              value={values.internal_notes}
              onChange={handleChange}
              error={touchedErrors.internal_notes}
            />
          </div>
          <div>
            <InputLabel>Type</InputLabel>
            <div className='absence-card-type card-type'>
              {absenceTypes.map((item) => (
                <div className='card-type-container' key={item.id}>
                  <input
                    type='radio'
                    name='type'
                    onChange={handleChange}
                    value={item.id}
                    checked={Number(values.type) === item.id}
                  />
                  <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className='driver-style-wrap' style={{ marginRight: '20px' }}>
            <InputLabel>Upload Supporting Document</InputLabel>
            {values?.absence_document ? (
              <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={() => handleChange('absence_document', null)}
                />
              </p>
            ) : (
              <div
                className='upload-selector'
                style={{
                  position: 'relative',
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <label htmlFor='myFile' className='text-style'>
                  <span className='upload-icon'>
                    <img src={upload} alt='upload' />
                  </span>
                  <span style={{ color: '#4F5AED' }}>Upload Document</span>
                </label>
                <ImageDropZone
                  onDrop={uploadLicenseDocument}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                  }}
                  accept={['application/pdf', 'image/jpeg', 'image/png']}
                />
              </div>
            )}
          </div>
        </div>
        {!!absence && (
          <div className='uploaded-document-update-absence'>
            {absence.absence_document ? (
              <iframe src={absence.absence_document} className='w-100 h-100' title='Doc' />
            ) : (
              <Typography variant='s2'>No Document Uploaded</Typography>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddUpdateAbsence;
