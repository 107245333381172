import React from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/download2.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { STableHeader } from './Header.styles';

const Header = ({ title = 'Result', onDownloadPdf, onCsvExport, disabled }) => {
  return (
    <STableHeader>
      <Typography variant='h3'>{title}</Typography>
      <div className='flex-right'>
        <div className='download-icon-text'>
          <DownloadIcon />
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Download:
          </Typography>
        </div>
        <CustomButton
          className='action-button'
          title='PDF'
          type='secondary'
          onClick={onDownloadPdf}
          disabled={disabled}
        />
        {onCsvExport && (
          <CustomButton
            className='action-button'
            title='CSV'
            type='secondary'
            onClick={onCsvExport}
            disabled={disabled}
          />
        )}
      </div>
    </STableHeader>
  );
};

export default Header;
