import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import Search from 'common/Search';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getCarriers } from 'Api/Carriers';
import { customerStopPoint } from 'Api/Planner';
import { getGroups } from 'Api/OfferedShipments';
import { getCustomersList } from 'Api/Customers';
import { getDrivers } from 'Api/EquipmentProfile';
import TableSettings from '../TableSettings';
import FilterOptions from '../FilterOptions';
import AdvancedFilter from '../AdvancedFilter';
import { SWrapper } from './TableHeader.styles';

const TableHeader = ({
  search,
  setSearch,
  selectedFilters,
  setSelectedFilters,
  dragItem,
  getSettings,
  tableColumn,
  setDragItem,
  setTableColumn,
  loading,
}) => {
  const [openSettings, setOpenSettings] = useState(false);
  const [groups, setGroups] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [openAdvanced, setOpenAdvanced] = useState(false);

  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Active', value: 'active' },
    searchValue: '',
    allValue: '',
    selectedValues: [],
    locations: [],
    customerSelectData: [],
    tableColumn,
    forAll: false,
    applyAllUsersModal: { value: '0' },
  });

  const getGroupOptions = async () => {
    try {
      const { data } = await getGroups();
      setGroups(data.map((item) => ({ ...item, title: item.group_name, id: item.id })));
    } catch (e) {
      // Do nothing
    }
  };

  const getStopPoints = async () => {
    try {
      const { data } = await customerStopPoint();
      setStopPoints(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ page: 1, page_size: 10000 });
      setCustomers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCarriersList = async () => {
    try {
      const { data } = await getCarriers();
      setCarriers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getGroupOptions();
    getStopPoints();
    getCustomers();
    getCarriersList();
    getDriversList();
  }, []);

  const popoverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: palette.white }}>
      <Popover.Body>
        <TableSettings
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={setFilter}
          tableColumn={tableColumn}
          setShowPopover={setOpenSettings}
          onChangeOrder={setDragItem}
          setTableColumn={setTableColumn}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <SWrapper>
      <div className='d-flex align-items-center gap-3'>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popoverContent}
          rootClose={openSettings}
          onToggle={setOpenSettings}
          show={openSettings}
          defaultShow={false}
          delay={0}
        >
          <div className='table-settings-icon'>
            <DataTableIcon widht={14} height={14} fill={palette.gray700} />
          </div>
        </OverlayTrigger>
        <Search search={search} onChange={setSearch} width='250px' />
        <CustomButton
          type='primary'
          title=''
          leftIcon={
            openAdvanced ? (
              <MinusIcon fill='#fff' style={{ margin: 8 }} />
            ) : (
              <PlusIcon fill='#fff' style={{ margin: 8 }} />
            )
          }
          className='ms-3'
          styleButton={{ height: 28, margin: 0 }}
          onClick={() => setOpenAdvanced((prevState) => !prevState)}
        />
      </div>
      {openAdvanced && (
        <AdvancedFilter
          filters={selectedFilters}
          updateFilters={setSelectedFilters}
          loading={loading}
          groups={groups}
          stopPoints={stopPoints}
          customers={customers}
          carriers={carriers}
          drivers={drivers}
        />
      )}
      <FilterOptions filter={selectedFilters} updateFilter={setSelectedFilters} groups={groups} />
    </SWrapper>
  );
};

export default TableHeader;
