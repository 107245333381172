import React from 'react';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import RowActions from './components/RowActions';
import UserInfo from '../Cards/components/UserInfo';
import { SUserInfo } from '../Cards/Cards.styles';

const typeMapper = {
  Capture: { color: palette.green500, bgColor: palette.green0 },
  Refund: { color: palette.red500, bgColor: palette.red0 },
  Declined: { color: palette.red500, bgColor: palette.red0 },
  'Cash Advance': { color: palette.orange500, bgColor: palette.orange0 },
  SUCCEEDED: { color: palette.orange500, bgColor: palette.orange0 },
};

export const initialFilters = {
  tab: '',
  page: 1,
  page_size: 10,
  category: null,
  status: null,
  cardHolders: [],
  providers: [],
};

export const transactionStatuses = [
  { id: 1, name: 'Capture' },
  { id: 2, name: 'Cash Advance' },
  { id: 3, name: 'Declined' },
  { id: 4, name: 'Load Cash' },
];

export const filterTableTop = [
  {
    key: 'all',
    label: 'All',
    value: '',
    id: '',
    count: 0,
  },
  {
    key: 'driver',
    label: 'Driver',
    value: 'driver',
    id: 'driver',
    count: 0,
  },
  {
    key: 'staff',
    label: 'Staff',
    value: 'staff',
    id: 'staff',
    count: 0,
  },
  {
    key: 'other',
    label: 'Other',
    value: 'other',
    id: 'other',
    count: 0,
  },
];

const Title = ({ title }) => {
  return <Typography variant='overLine'>{title}</Typography>;
};

export const useColumns = ({ setOpenFeatureNotAvailable, onAssignTransaction, onViewReceipt }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'card_holder',
      title: <Title title='CARD HOLDER' />,
      render: ({ card_holder_id, id, card_holder_name, phone_number, email, profile_image, user_type, provider_id }) =>
        user_type ? (
          <UserInfo
            data={{
              user: {
                id: card_holder_id,
                first_name: card_holder_name?.split(' ')[0] || '',
                last_name: card_holder_name?.split(' ')[1] || '',
                phone_number,
                email,
                profile_logo: profile_image,
              },
              user_type,
            }}
          />
        ) : (
          <SUserInfo
            onClick={(e) => {
              e.stopPropagation();
              onAssignTransaction({ id, provider_id });
            }}
          >
            <div className='assign-user'>
              <PlusIcon width={12} height={12} />
            </div>
            <Typography variant='s2' style={{ color: use(palette.indigo500) }} className='user-name'>
              Assign User
            </Typography>
          </SUserInfo>
        ),
    },
    {
      field: 'service',
      title: <Title title='SERVICE' />,
      render: (row) =>
        row.provider_logo ? (
          <img src={row.provider_logo} alt='card logo' height={22} />
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            -
          </Typography>
        ),
    },
    {
      field: 'card',
      title: <Title title='CARD' />,
      render: (row) =>
        row.card_id ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              <span style={{ fontSize: '8px' }}>●●●●</span> {row.card_id.slice(-4)}
            </Typography>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {row?.card?.exp}
            </Typography>
          </div>
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            -
          </Typography>
        ),
    },
    {
      field: 'date',
      title: <Title title='DATE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.date}
        </Typography>
      ),
    },
    {
      field: 'vendor',
      title: <Title title='VENDOR' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.vendor?.name || '-'}
        </Typography>
      ),
    },
    {
      field: 'reference',
      title: <Title title='REFERENCE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.invoice || '-'}
        </Typography>
      ),
    },
    {
      field: 'category',
      title: <Title title='CATEGORY' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.category?.description || '-'}
        </Typography>
      ),
    },
    {
      field: 'address',
      title: <Title title='LOCATION' />,
      render: (row) => (
        <div className='d-flex flex-column'>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {!!row.cities?.name && `${row.cities?.name},`}{' '}
            {!!(row.states?.short_name || row.states?.name) && `${row.states?.short_name || row.states?.name}`}
          </Typography>
        </div>
      ),
    },
    {
      field: 'cost_per_gallon',
      title: <Title title='COST PER GALLON' />,
      render: (row) => {
        const totalGallons = Number(row.gallons || 1);
        const totalAmount = Number(row.amount || 0);

        const costPerGallon = totalAmount / totalGallons;

        return (
          <div className='d-flex flex-column'>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {currency}
              {formatNumber(costPerGallon)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'fees',
      title: <Title title='FEES' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {Number(row.fees) ? `${currency}${formatNumber(row.fees)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      title: <Title title='EQUIPMENT' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.equipment_id || '-'}
        </Typography>
      ),
    },
    {
      field: 'gallons',
      title: <Title title='QTY' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.gallons || '-'}
        </Typography>
      ),
    },
    {
      field: 'amount',
      title: <Title title='AMOUNT' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {currency}
          {formatNumber(row?.amount)}
        </Typography>
      ),
    },
    {
      field: 'receipt',
      title: <Title title='RECEIPT' />,
      render: (row) =>
        row.receipt ? (
          <Typography variant='s2' style={{ color: palette.indigo500 }} onClick={() => onViewReceipt(row.receipt)}>
            View
          </Typography>
        ) : (
          '-'
        ),
    },
    {
      field: 'type',
      title: <Title title='TYPE' />,
      render: (row) => (
        <Chip
          size='small'
          label={row.status?.toUpperCase()}
          labelColor={typeMapper[row.status]?.color}
          bgColor={typeMapper[row.status]?.bgColor}
        />
      ),
    },
    {
      field: 'actions',
      title: <Title title='' />,
      render: (row) => <RowActions row={row} setOpenFeatureNotAvailable={setOpenFeatureNotAvailable} />,
    },
  ];
};
