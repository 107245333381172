import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import HeaderStar from 'components/HeaderStar';
import StripeConnection from 'componentsV2/StripeConnection';
import { STRIPE_MODULE } from 'utils/constants';
import StatsSection from './StatsSection';
import TableSection from './TableSection';
import { SHeaderWrapper, SPageWrapper } from './Receivables.styles';

const Receivables = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [statsRefreshIndex, setStatsRefreshIndex] = useState(Date.now());

  const activeTab = searchParams.get('tab') || 'missing_paperwork';

  const handleTabChange = (newValue) => {
    searchParams.set('tab', newValue);
    setSearchParams(searchParams);
  };

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Receivables' />
        <StripeConnection module={STRIPE_MODULE.ACCOUNTS_RECEIVABLES} />
      </SHeaderWrapper>
      <StatsSection changeTab={handleTabChange} statsRefreshIndex={statsRefreshIndex} />
      <TableSection
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        refreshStats={() => setStatsRefreshIndex(Date.now())}
      />
    </SPageWrapper>
  );
};

export default Receivables;
