import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import useShowToaster from 'hooks/useShowToaster';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getCustomerInfo, getOpenJobPosition } from 'Api/JobPositions';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import Footer from '../components/Footer';
import { SActions, SBackButton, SDescription, SLink } from './PositionDetails.styles';
import { SGradientBox, SHeader, SPageWrapper, SContent } from '../PublicPositions.styles';

const PositionDetails = () => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const matches = useMediaQuery('(max-width:768px)');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const { companyId, id } = useParams();
  const referral_token = searchParams.get('token');

  const onApply = () => {
    if (referral_token) {
      navigate(`/job-positions/${companyId}/${id}/apply?token=${referral_token}`);
      return;
    }
    navigate(`/job-positions/${companyId}/${id}/apply`);
  };

  const getPosition = async () => {
    try {
      const response = await getOpenJobPosition(companyId, id);
      setData(response.data);
    } catch (e) {
      showToaster({ message: 'Job is not found', type: 'error' });
      navigate(`/job-positions/${companyId}`);
    }
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getCustomerInfo(companyId);
      setCompanyInfo(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getPosition();
    getCompanyInfo();
  }, []);

  return (
    <div>
      <SGradientBox />
      <SPageWrapper>
        <SHeader>
          {companyInfo?.logo_path && <img src={companyInfo.logo_path} alt='Company logo' height={48} />}
        </SHeader>
        <SContent>
          <SBackButton>
            <ArrowLeft width={10} height={8} />
            <SLink to={`/job-positions/${companyId}`}>Open Positions</SLink>
          </SBackButton>
          <SActions>
            <Typography variant={matches ? 'h5' : 'h1'} style={{ color: use(palette.gray900, palette.gray200) }}>
              {data?.name}
            </Typography>
            <CustomButton
              title='Apply Now'
              styleButton={{ padding: '6px 22px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
              onClick={onApply}
            />
          </SActions>
          {data && (
            <SDescription>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </SDescription>
          )}
        </SContent>
        <Footer />
      </SPageWrapper>
    </div>
  );
};

export default PositionDetails;
