import React, { useEffect, useState } from 'react';
import './Customers.css';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { startCase, toLower } from 'lodash';

import Pagination from 'common/Pagination';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { allTab, initialFilters, mapperSettingsData, useColumns } from 'components/Customers/Customers.data';
import { customerPayTerms, customerStatuses, getCustomerPopoverSettings, getCustomersList } from 'Api/Customers';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';
import CustomersHeader from '../CustomersHeader';
import MaterialTableWrapper from '../MaterialTableWrapper';
import EquipmentPreloaderSkeleton from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';

const downloadCsv = async (data) => {
  try {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `customers.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    // Do nothing
  }
};

const Customers = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [search, setSearch] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [payTerms, setPayTerms] = useState([]);
  const [customers, setCustomers] = useState({ data: [] });
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all' },
    allValue: '',
    shipmentValue: '',
    selectedValues: [],
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: '1',
      date: '1',
      time: '1',
    },
    forAll: false,
    filterTableTop: [],
    applyAllUsersModal: { value: '0' },
  });

  const getCustomers = async (data) => {
    const { isExport, advancedFilter: advancedFilterProps, page: pageProps } = data || {};
    const filterAdvanced = advancedFilterProps || convertFilterData(advancedFilter);
    try {
      setLoading(true);
      const params = {
        page: pageProps || selectedFilters.page,
        page_size: selectedFilters.itemsPerPage,
        'status[][0]': selectedFilters.tab || undefined,
        export: isExport || undefined,
        ...filterAdvanced,
      };

      pageProps && setSelectedFilters((p) => ({ ...p, page: pageProps }));

      if (selectedFilters?.sort?.sortField) {
        params[selectedFilters?.sort?.sortField] = selectedFilters?.sort?.sortBy;
      }

      const response = await getCustomersList(params);
      if (isExport && typeof response === 'string') {
        downloadCsv(response);
        return;
      }

      const { data: tabData } = await customerStatuses();
      const newData = tabData.map((el) => ({
        ...el,
        count: el.customers_count,
        label: startCase(toLower(el?.title)),
        key: uuid(),
        value: uuid(),
      }));
      setStatuses(newData);
      setFilter((prevState) => ({
        ...prevState,
        filterTableTop: [{ ...allTab, count: response.total }, ...newData],
      }));

      setCustomers(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getSettings = () => {
    getCustomerPopoverSettings({ type: 'customer' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const onChangeTab = (item) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, tab: item.id });
  };

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const getPayTerms = () => {
    customerPayTerms().then((res) => {
      if (res.data) {
        setPayTerms(res.data);
      }
    });
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    return cols;
  };

  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[][${field}]`;
    }

    setSelectedFilters({ ...selectedFilters, sort: { sortField, sortBy } });
  }

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'customer_id' || key === 'company_name' || key === 'general_ledger_id') {
          value.forEach((el, index) => {
            requestData[`${key}[][${index}]`] = el.title;
          });
        } else if (key === 'group_id') {
          requestData[key] = value.map((el) => el.id).toString();
        } else {
          value.forEach((el, index) => {
            requestData[`${key}[][${index}]`] = el.id;
          });
        }

        // requestData[`${key}[]`] = value.map((el) => el.id)
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.query;
    } else {
      filterObject.query = search;
    }

    getCustomers({ advancedFilter: convertFilterData(filterObject), page: 1 }).catch();
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    getSettings();
    getPayTerms();
  }, [createModalCLose]);

  useEffect(() => {
    getCustomers();
  }, [selectedFilters]);

  const columns = useColumns({ sortingQuery });

  return (
    <div>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          tableColumn,
          getSettings,
          updateFilter,
          updateOnCreate,
          createModalCLose,
          planerData: customers.data,
          onChangeOrder: (items) => setDragItem(items),
          onsStTableColumn: (v) => setTableColumn(v),
        }}
      >
        <AdvancedFilter
          statuses={statuses}
          payTerms={payTerms}
          onSearch={onSearch}
          open={advancedOpen}
          setSearch={setSearch}
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
        >
          {({ onSearchFilter }) => {
            return (
              <CustomersHeader
                filter={filter}
                search={search}
                dragItem={dragItem}
                setSearch={setSearch}
                onSearch={onSearchFilter}
                tableColumn={tableColumn}
                getSettings={getSettings}
                updateFilter={updateFilter}
                advancedOpen={advancedOpen}
                setAdvancedOpen={setAdvancedOpen}
                getCustomers={() => getCustomers({ isExport: false })}
                exportCsv={() => getCustomers({ isExport: true })}
              />
            );
          }}
        </AdvancedFilter>
        <SwitchRadio
          name='tableTopTabMenu'
          items={filter.filterTableTop}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </TableContext.Provider>

      {loading ? (
        <EquipmentPreloaderSkeleton />
      ) : (
        <MaterialTableWrapper
          data={customers?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          onRowClick={(e, rowData) => navigate(`/customer-profile/${rowData.id}`)}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: () =>
              Pagination({
                data: customers,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={filterTableColumn(columns)}
        />
      )}
    </div>
  );
};

export default Customers;
