import React from 'react';
import { Textarea } from 'common/Input';
import { Typography } from 'components/Typography';

const PaymentTerms = ({ form }) => {
  const { values, handleChange, touchedErrors } = form;

  return (
    <div className='w-100'>
      <div className='mb-2'>
        <Typography variant='s1'>Payment Terms</Typography>
      </div>
      <div>
        <Textarea
          rows={4}
          name='payment_term_notes'
          value={values.payment_term_notes}
          onChange={handleChange}
          error={touchedErrors.payment_term_notes}
        />
      </div>
    </div>
  );
};

export default PaymentTerms;
