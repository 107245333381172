import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { LayoutContext } from '../layout';
import './SenderInfo.css';

const SenderInfo = ({ senderData, sendMessage }) => {
  const { use } = useTheme();
  const { dot_num } = useContext(LayoutContext);
  const navigate = useNavigate();

  const toDriverProfile = () => {
    if (senderData.id) {
      navigate(`/driver-profile/${senderData.id}/general`);
    }
  };

  return (
    <div className='document-list-sender-info-wrapper' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <div>
        <div
          className='sender-info-popover-header'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          onClick={toDriverProfile}
        >
          <div className='d-flex p-0 align-items-center'>
            <div className='user-img-wrapper'>
              <img className='user-img' src={senderData.image || userDefault} alt='klir' />
            </div>
            <span className='user-name ms-2' style={{ color: use(palette.dark800, palette.gray200) }}>
              {senderData.name}
            </span>
          </div>
        </div>
        <div className='ms-2 mt-2 me-2'>
          <div className='mb-2 list'>
            <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M13.8992 10.5979C13.8686 10.4884 13.8099 10.389 13.7289 10.3099C13.648 10.2308 13.5478 10.1748 13.4385 10.1477C12.356 9.85602 11.3261 9.39248 10.3873 8.77431C10.2948 8.71539 10.1889 8.68153 10.0798 8.67601C9.97064 8.67049 9.86193 8.69349 9.76412 8.74279C9.12376 9.06539 8.6214 9.61279 8.35089 10.2827C7.46307 9.67834 6.63635 8.98682 5.88275 8.21822C5.12374 7.45577 4.4409 6.61946 3.84414 5.72144C4.20704 5.57304 4.53639 5.35169 4.81226 5.07079C5.03769 4.84374 5.22555 4.58148 5.36865 4.29407C5.41603 4.19505 5.43782 4.0855 5.43198 3.97565C5.42614 3.8658 5.39287 3.75924 5.33527 3.66593C4.72337 2.71501 4.26509 1.67155 3.97768 0.57479C3.95112 0.464349 3.89585 0.363105 3.81758 0.281555C3.73931 0.200006 3.64091 0.141121 3.53257 0.111006C3.06337 -0.022493 2.56872 -0.0358011 2.09317 0.0722807C1.61763 0.180363 1.23245 0.356246 0.808488 0.730135C-0.44636 1.83678 -0.0283202 3.19765 0.639346 5.04152C1.2625 6.73231 2.46208 8.52891 4.01551 10.1026C5.56895 11.6763 7.35162 12.8876 9.02301 13.5247C9.7558 13.8179 10.5342 13.9772 11.322 13.9953C12.0294 14.0258 12.7128 13.9206 13.2805 13.3424C13.6597 12.956 13.8023 12.5606 13.9103 12.1018C14.0336 11.6078 14.0297 11.0901 13.8992 10.5979Z'
                fill='#868FA0'
              />
            </svg>
            <span className=' ms-2'>
              <a
                href='tel:{info.phone_number}'
                style={{
                  color: use(palette.dark800, palette.gray200),
                }}
              >
                {senderData.phone_number}
              </a>
            </span>
          </div>
          <div className='mb-2'>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 2.5C1.89543 2.5 1 3.39543 1 4.5V11.5C1 12.6046 1.89543 13.5 3 13.5H13C14.1046 13.5 15 12.6046 15 11.5V4.5C15 3.39543 14.1046 2.5 13 2.5H3ZM4.3976 4.86401C4.04635 4.64448 3.58363 4.75126 3.3641 5.10251C3.14457 5.45376 3.25135 5.91647 3.6026 6.13601L7.6026 8.63601C7.8458 8.78801 8.1544 8.78801 8.3976 8.63601L12.3976 6.13601C12.7489 5.91647 12.8556 5.45376 12.6361 5.10251C12.4166 4.75126 11.9539 4.64448 11.6026 4.86401L8.0001 7.11557L4.3976 4.86401Z'
                fill='#868FA0'
              />
            </svg>
            <span className=' info-link ms-2'>
              <a
                href='mailto:{info.email}'
                style={{
                  color: use(palette.dark800, palette.gray200),
                }}
              >
                {senderData.email}
              </a>
            </span>
          </div>
          {sendMessage ? (
            <div className='mb-2' onClick={() => navigate(`/chat/${dot_num}_driver_group_${senderData.id}`)}>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M5 11.5C5 8.73858 7.23858 6.5 10 6.5H15C17.2091 6.5 19 8.29086 19 10.5V11.8333C19 14.0425 17.2091 15.8333 15 15.8333H9.18667C8.85096 15.8333 8.52066 15.9178 8.2262 16.0791L5.74012 17.4402C5.40691 17.6226 5 17.3815 5 17.0016V11.5ZM9 12.25C9.55228 12.25 10 11.8023 10 11.25C10 10.6977 9.55228 10.25 9 10.25C8.44772 10.25 8 10.6977 8 11.25C8 11.8023 8.44772 12.25 9 12.25ZM13 11.25C13 11.8023 12.5523 12.25 12 12.25C11.4477 12.25 11 11.8023 11 11.25C11 10.6977 11.4477 10.25 12 10.25C12.5523 10.25 13 10.6977 13 11.25ZM15 12.25C15.5523 12.25 16 11.8023 16 11.25C16 10.6977 15.5523 10.25 15 10.25C14.4477 10.25 14 10.6977 14 11.25C14 11.8023 14.4477 12.25 15 12.25Z'
                  fill='#868FA0'
                />
              </svg>
              <span className=' info-link ms-2'>
                <a
                  style={{
                    color: use(palette.dark800, palette.gray200),
                  }}
                >
                  Chat
                </a>
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SenderInfo;
