import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-top: 64px;

  .MuiTable-root {
    table-layout: fixed !important;

    th:last-child {
      width: 50px !important;
    }
  }

  .downgrade-msg {
    display: flex;
    column-gap: 8px;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    background: ${palette.amber50};
  }

  .red-hover {
    cursor: pointer;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;
