import React from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { readyMissingPaperworkInvoicesOptions } from './Charts.data';

const ReadyMissingPaperworkInvoicesChart = ({ monthsData }) => {
  const { currency } = useSelector((state) => state.root);

  const data = {
    labels: (monthsData || []).map((item) => `${item.month}`),
    datasets: [
      {
        fill: true,
        label: 'Ready to Invoice',
        data: monthsData?.map((i) => i.ready) || [],
        borderColor: palette.indigo500,
        pointBackgroundColor: palette.indigo500,
        backgroundColor: 'rgba(79, 90, 237, 0.2)',
      },
      {
        fill: false,
        label: 'Missing Paperwork',
        data: monthsData?.map((i) => i.missing) || [],
        borderColor: palette.red500,
        pointBackgroundColor: palette.red500,
      },
    ],
  };

  return <Line options={readyMissingPaperworkInvoicesOptions(currency)} data={data} />;
};

export default ReadyMissingPaperworkInvoicesChart;
