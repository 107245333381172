import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;
  margin-top: 12px;

  th {
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;
    font-size: 12px;
    padding: 8px 6px;
  }

  td {
    padding: 12px 6px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }
`;

export const SLabel = styled.p`
  color: ${palette.gray400};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0 0 4px 0;
`;

export const SText = styled.p`
  color: ${palette.gray700};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0;

  .dots {
    font-size: 9px;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;

  .MuiChip-label {
    font-weight: 400 !important;
    font-size: 13px !important;
  }
`;
