import React, { useMemo } from 'react';
import { motionColors, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import useDateFormat from 'hooks/useDateFormat';
import classes from './MotionsLogs.module.css';
import Chip from '../../../../../../common/Chip';
import { formatSeconds } from '../../../../../../utils/helpers';

const MotionLogsTable = ({ logs }) => {
  const { theme, use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();

  const columns = useMemo(() => {
    return [
      {
        title: (
          <Typography
            variant='overLine'
            style={{ color: use(palette.gray700, palette.gray200), textTransform: 'uppercase' }}
          >
            Date / Time
          </Typography>
        ),
        field: 'change_description',
        render: (rowData) => {
          const { date } = rowData || {};
          return (
            <span
              style={{
                color: use(palette.gray600, palette.gray200),
              }}
            >
              {convertToCustomerTime(date)}
            </span>
          );
        },
      },
      {
        title: (
          <Typography
            variant='overLine'
            style={{ color: use(palette.gray700, palette.gray200), textTransform: 'uppercase' }}
          >
            Location
          </Typography>
        ),
        field: 'change_source',
        render: (rowData) => {
          const { location } = rowData;
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              <span style={{ color: use(palette.dark800, palette.gray200) }}>{location}</span>
            </span>
          );
        },
      },
      {
        title: (
          <Typography
            variant='overLine'
            style={{ color: use(palette.gray700, palette.gray200), textTransform: 'uppercase' }}
          >
            Motion Status
          </Typography>
        ),
        field: 'status',
        render: (rowData) => {
          const { motion, duration } = rowData;
          return (
            <div className='flex-row d-flex'>
              <span
                style={{
                  color: use(palette.gray600, palette.gray200),
                }}
              >
                <Chip
                  fontWeight={500}
                  fontSize='11px'
                  bgColor={motionColors[motion]?.bgColor}
                  labelColor={motionColors[motion]?.color}
                  style={{ textTransform: 'uppercase' }}
                  label={`${motion} ${formatSeconds(duration)}`}
                />
              </span>
            </div>
          );
        },
      },
    ];
  }, [theme]);

  return (
    <div className={classes.container}>
      <div style={{ margin: 1 }}>
        <MaterialTableWrapper
          data={logs}
          rowPerPage={logs?.length}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: () => null }}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default MotionLogsTable;
