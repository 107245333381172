import styled from 'styled-components';

export const SAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ $gap }) => $gap || '16px'};

  .address-inputs {
    display: flex;
    flex-direction: ${({ $isOneRow }) => ($isOneRow ? 'row' : 'column')};
    align-items: flex-end;
    gap: ${({ $gap }) => $gap || '16px'};
  }
`;

export const SFlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ $gap, $isOneColumn, $onlyCountryStateCity }) =>
    $gap || ($isOneColumn && !$onlyCountryStateCity ? '8px' : '16px')};

  .address-input-wrapper {
    width: ${({ $isOneRow, $isOneColumn, $onlyCountryStateCity, $gap }) =>
      $isOneRow
        ? $onlyCountryStateCity
          ? 'calc(100% / 3 - 12px)'
          : 'calc(25% - 12px)'
        : $onlyCountryStateCity || $isOneColumn
        ? '100%'
        : `calc(50% - ${$gap ? parseInt($gap, 10) / 2 : 8}px)`};
  }

  @media (max-width: 576px) {
    flex-direction: column;

    .address-input-wrapper {
      width: 100%;
    }
  }
`;
