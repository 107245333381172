export const carriersMapper = {
  'Carrier DOT': 'dot',
  'Carrier Name': 'name',
  Email: 'email',
  Phone: 'phone_number',
  'Primary Contact Name': 'contact_name',
  'Primary Contact Phone': 'contact_phone',
  'Primary Contact Email': 'contact_email',
  'Can Travel Canada': 'can_travel_to_canada',
  'Can Travel Mexico': 'can_travel_to_mexico',
  'Can Travel USA': 'can_travel_to_usa',
};
