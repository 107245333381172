import { createSlice } from '@reduxjs/toolkit';
import { WALMART_TABS } from 'components/StopPoint/Walmart/constants/constants';

const initialState = {
  activeTabWalmart: WALMART_TABS[0],
  inventoryEquipmentType: 1,
  historyEquipmentType: 1,
  cargoOnboardIds: [],
  stopPoints: [],
  dateRange: {
    start: null,
    end: null,
  },
  inventoryDateRange: {
    start: null,
    end: null,
  },
  inventoryRangeName: 'All Time',
};

export const counterSlice = createSlice({
  name: 'stopPoints',
  initialState,
  reducers: {
    onChangeActiveTab: (state, action) => {
      state.activeTabWalmart = action.payload;
    },
    onChangeInventoryTab: (state, action) => {
      state.inventoryEquipmentType = action.payload;
    },
    onChangeEquipmentHistoryTab: (state, action) => {
      state.historyEquipmentType = action.payload;
    },
    onChangeStopPoints: (state, action) => {
      state.stopPoints = action.payload;
    },
    onDateRangeChange: (state, action) => {
      state.dateRange = action.payload;
    },
    onInventoryDateRangeChange: (state, action) => {
      state.inventoryDateRange = action.payload;
    },
    onInventoryRangeNameChange: (state, action) => {
      state.inventoryRangeName = action.payload;
    },
    onCargoOnboardChange: (state, action) => {
      state.cargoOnboardIds = action.payload;
    },
  },
});

export const {
  onChangeActiveTab,
  onChangeStopPoints,
  onChangeInventoryTab,
  onChangeEquipmentHistoryTab,
  onDateRangeChange,
  onCargoOnboardChange,
  onInventoryDateRangeChange,
  onInventoryRangeNameChange,
} = counterSlice.actions;

export default counterSlice.reducer;
