const paymentMethods = {
  online: 'Online (Credit Card, ACH & Wallets)',
  credit_card: 'Credit Card',
  wire: 'Wire',
  ach: 'ACH',
  check: 'Check',
  cash: 'Cash',
};

const paymentMethodStart = {
  1: 217,
  2: 207,
  3: 197,
  4: 182,
  5: 176,
};

export const generatePaymentMethods = ({ doc, page, instructions, payment_method, stripePaymentLink }) => {
  const methodsCount = payment_method.length;

  // Left side
  payment_method.forEach((method, index) => {
    if (index === 0) {
      doc.setFont('Inter', 'normal', 700);
      doc.text('Make Payment:', 8, page !== 1 ? 33 : paymentMethodStart[methodsCount] + 26);
    }
    doc.setFont('Inter', 'normal', 400);
    doc.text(
      paymentMethods[method],
      34,
      page !== 1 ? 33 + index * 12 : paymentMethodStart[methodsCount] + 26 + index * 12
    );
    if (method === 'online' && stripePaymentLink) {
      doc.setTextColor('#4F5AED');
      doc.textWithLink(
        'View and make payment now',
        34,
        page !== 1 ? 37 + index * 12 : paymentMethodStart[methodsCount] + 30 + index * 12,
        {
          url: stripePaymentLink,
        }
      );
      doc.setTextColor('#000000');
    }

    if (method === 'check') {
      doc.setFont('Inter', 'normal', 400);
      doc.text(
        'Enclose check to address above with invoice number in your memo.',
        34,
        page !== 1 ? 37 + index * 12 : paymentMethodStart[methodsCount] + 30 + index * 12,
        { maxWidth: 80 }
      );
    }

    if (method === 'cash') {
      doc.setFont('Inter', 'normal', 400);
      doc.text('In person', 34, page !== 1 ? 37 + index * 12 : paymentMethodStart[methodsCount] + 30 + index * 12);
    }

    if (
      instructions &&
      ((instructions?.ach_enabled && method === 'ach') || (instructions?.wire_enabled && method === 'wire'))
    ) {
      // Fund Transfer Instructions
      doc.setFont('Inter', 'normal', 700);
      doc.text(
        'Account Number',
        46,
        page !== 1 ? 36 + index * 12 : paymentMethodStart[methodsCount] + 29 + index * 12,
        {
          align: 'center',
        }
      );
      doc.text(
        'Routing Number',
        80,
        page !== 1 ? 36 + index * 12 : paymentMethodStart[methodsCount] + 29 + index * 12,
        {
          align: 'center',
        }
      );

      doc.setFont('Inter', 'normal', 400);
      doc.text(
        `to ${instructions?.bank_name}`,
        40.8,
        page !== 1 ? 33 + index * 12 : paymentMethodStart[methodsCount] + 26 + index * 12
      );
      doc.text(
        instructions?.[`${method}_account_number`],
        46,
        page !== 1 ? 39 + index * 12 : paymentMethodStart[methodsCount] + 32 + index * 12,
        {
          align: 'center',
        }
      );
      doc.text(
        instructions?.[`${method}_routing_number`],
        80,
        page !== 1 ? 39 + index * 12 : paymentMethodStart[methodsCount] + 32 + index * 12,
        {
          align: 'center',
        }
      );
    }
  });
};
