import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from 'common/Tabs';
import ExportCsv from 'componentsV2/ExportCsv';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { useTheme } from 'context/themeContext';
import PaidSettlements from 'pages/Payroll/PayrollAndSettlements/Tables/PaidSettlements';
import DateRangePicker from 'common/DateRangePicker';
import ReadySettlements from '../../Tables/ReadySettlements';
import UpcomingSettlements from '../../Tables/UpcomingSettlements';
import DisputedSettlements from '../../Tables/DisputedSettlements';
import ProcessedSettlements from '../../Tables/ProcessedSettlements';
import Search from '../Search';
import { SFiltersWrapper, SWrapper } from './TableSection.styles';

const TableSection = ({ status }) => {
  const { use } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { defaultTab } = location?.state || {};
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const debouncedSearch = useDebounce(search, 500);

  const tabs = useMemo(
    () => [
      {
        value: 2,
        label: 'Upcoming',
        count: status?.upcoming?.status_count || 0,
        component: ({ debouncedSearch }) => <UpcomingSettlements search={debouncedSearch} />,
      },
      {
        value: 1,
        label: 'Ready',
        count: status?.ready?.status_count || 0,
        component: ({ debouncedSearch }) => <ReadySettlements search={debouncedSearch} />,
      },
      {
        value: 3,
        label: 'Processed',
        count: status?.processed?.status_count || 0,
        component: ({ debouncedSearch, dateRange }) => (
          <ProcessedSettlements search={debouncedSearch} dateRange={dateRange} />
        ),
      },
      {
        value: 4,
        label: 'Disputed',
        count: status?.disputed?.status_count || 0,
        component: ({ debouncedSearch }) => <DisputedSettlements search={debouncedSearch} />,
      },
      {
        value: 5,
        label: 'Paid',
        count: null,
        component: ({ debouncedSearch, dateRange }) => (
          <PaidSettlements search={debouncedSearch} dateRange={dateRange} />
        ),
      },
    ],
    [status]
  );

  const [activeTab, setActiveTab] = useState(defaultTab || tabs[0].value);

  const handleTabChange = (tab) => {
    setSearch('');
    setActiveTab(tab);
  };

  useEffect(() => {
    if (defaultTab) {
      navigate('.', { state: { ...(location.state || {}), defaultTab: null } });
    }
  }, []);

  return (
    <SWrapper>
      <Typography variant='h3' style={{ color: use(palette.gray900, palette.gray200) }}>
        Settlements
      </Typography>
      <SFiltersWrapper>
        <Search search={search} onChange={setSearch} />
        {[3, 5].includes(activeTab) && (
          <DateRangePicker
            top='-168px'
            type='allTime'
            dateRange={dateRange}
            initialRangeName='All Time'
            setDateRange={setDateRange}
            deletedStaticRangesIndexes={activeTab === 5 ? [5] : []}
            maxDate={activeTab === 5 ? new Date() : undefined}
          />
        )}
        <ExportCsv type={`${tabs.find((tab) => tab.value === activeTab).label.toLowerCase()}-settlements`} />
      </SFiltersWrapper>
      <Tabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      {tabs.find((tab) => tab.value === activeTab).component({ debouncedSearch, dateRange })}
    </SWrapper>
  );
};

export default TableSection;
