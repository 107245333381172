import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SCountWrapper = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid ${palette.gray100};
  text-align: center;
  border-radius: 5px;
  background-color: white;
  padding: 0 8px;
`;

export const SDownloadTemplate = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  .download-template-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid ${palette.gray100};
    background-color: ${palette.white};
    padding: 6px 12px;
  }
`;
