import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { startOfYear, endOfYear } from 'date-fns';
import Radio from 'common/Radio';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { getCost } from 'Api/Reports';
import { getPayeeList } from 'Api/Accounts';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { reportItemsConverter, transformReportItemsCsv, transformReportItemsPdf } from './Cost.data';
import { STable, STableFilters, STableWrapper } from '../ReportDetails.styles';

const Cost = ({ title, costTypeId }) => {
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [pdfUrl, setPdfUrl] = useState('');
  const [payees, setPayees] = useState([]);
  const [type, setType] = useState('accrual');
  const [loading, setLoading] = useState(false);
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [reportItems, setReportItems] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: startDate ? moment(startDate).toDate() : startOfYear(new Date()),
    end: endDate ? moment(endDate).toDate() : endOfYear(new Date()),
  });
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());

  const getCostData = async () => {
    try {
      setLoading(true);
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      let payeesList = payees;
      if (!payees.length) {
        const { data } = await getPayeeList();
        payeesList = data;
        setPayees(data);
      }

      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        type: type === 'cash' ? type : undefined,
      };
      const response = await getCost(costTypeId, params, controller.current?.signal);
      const convertedData = reportItemsConverter(response, payeesList);
      setReportItems(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const convertedItems = transformReportItemsPdf(reportItems);
      const { url } = await generatePDF(
        convertedItems,
        title,
        download,
        dateRange,
        formatDate,
        convertToCustomerTime,
        currency
      );
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }

    const titles = {
      account_name: 'Account/Payee',
      reference_id: 'Reference',
      memo: 'Memo',
      date: 'Date',
      amount: 'Amount',
      percentage: '% of Expense',
      miles: 'Cost Per Mile',
    };

    const convertedItems = transformReportItemsCsv(reportItems);

    const transactionRecords = convertedItems.map((item) => ({
      account_name: item.payee?.name || item.account_name || '-',
      reference_id: item.reference_id || '-',
      memo: item.memo || '-',
      date: item.date ? formatDate(item.date) : '-',
      amount: item.amount || '-',
      percentage: item.percentage || '-',
      miles: item.miles ? item.miles.toFixed(4) : '-',
    }));

    const arrayToConvert = [titles, ...transactionRecords];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `${title} - ${
        dateRange.start && dateRange.end ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}` : 'All Time'
      }`
    );
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setInitialLoading(true);
    }
    getCostData();
    didMountRef.current = true;
  }, [dateRange, type]);

  useEffect(() => {
    if (startDate) {
      setIsCustomRange(true);
    }
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams, { replace: true });
  }, []);

  return (
    <PageWrapper loading={initialLoading} title={title}>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName={isCustomRange ? 'Custom Range' : 'This Year'}
              type='allTime'
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <div className='d-flex align-items-center gap-3'>
              <Radio label='Accrual' value='accrual' checked={type === 'accrual'} onChange={() => setType('accrual')} />
              <Radio label='Cash' value='cash' checked={type === 'cash'} onChange={() => setType('cash')} />
            </div>
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportItems.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        <STable>
          <thead>
            <tr className='header-row'>
              <th>ACCOUNT/PAYEE</th>
              <th>REFERENCE</th>
              <th>MEMO</th>
              <th>DATE</th>
              <th>AMOUNT</th>
              <th>% OF EXPENSE</th>
              <th>COST PER MILE</th>
            </tr>
          </thead>
          <tbody>
            {reportItems.map((item) => (
              <>
                <tr key={item.id} className='body-row'>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {item.account_name || '-'}
                    </Typography>
                  </td>
                  <td colSpan={3} />
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {currency}
                      {formatAmount(item.amount)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {formatAmount(item.percentage)}%
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {formatAmount(item.miles, 4)}
                    </Typography>
                  </td>
                </tr>
                {item.transactions.map((transaction) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {transaction.payee?.name || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {transaction.reference_id || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {transaction.memo || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {formatDate(transaction.date)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {currency}
                        {formatAmount(transaction.amount)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {formatAmount(transaction.percentage)}%
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {formatAmount(transaction.miles, 4)}
                      </Typography>
                    </td>
                  </tr>
                ))}
                <tr style={{ height: '60px' }} />
              </>
            ))}
          </tbody>
        </STable>
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title={title} />}
    </PageWrapper>
  );
};

export default Cost;
