import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import React from 'react';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import styles from './ViewReasonModal.module.css';

const ViewReasonModal = ({ show, onClose }) => {
  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Agreement Declined Reason</Typography>
        </div>
        <div className={styles.body}>
          <Typography variant='b2'>
            Agreement Declined Reason goes here. It will explain why transaction declined. Thank you!
          </Typography>
        </div>
        <div className={styles.footer}>
          <CustomButton type='secondary' title='Okay' styleButton={{ padding: '2px 8px' }} onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};
export default ViewReasonModal;
