import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import TotalOpenReceivable from 'pages/Dashboard/components/Charts/TotalOpenReceivables';
import { getTotalOpenReceivable } from 'Api/Dashboard';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';

const TotalOpenReceivables = () => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [countsData, setCountsData] = useState({});
  const [chartMonthsData, setChartMonthsData] = useState([]);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getTotalOpenReceivable({
        from_date: moment().subtract(11, 'month').startOf('month').format('MM/DD/YYYY'),
        to_date: moment().endOf('month').format('MM/DD/YYYY'),
      });
      setCountsData(data);

      const fillArray = (startingPoint) => {
        const arr = [];
        for (let i = 0; i < 12; i++) {
          arr.push((startingPoint + i) % 12 || 12);
        }
        return arr;
      };

      const monthsArr = fillArray(Number(moment().subtract(11, 'month').format('M')));
      const monthsArrReversed = monthsArr.reverse();

      const openReceivablesData = monthsArr.map((val, i) => {
        const month = moment().subtract(i, 'month').format('MMMM YYYY');
        return { month, amount: 0 };
      });

      data?.open_receivable?.forEach((i) => {
        openReceivablesData[monthsArrReversed?.indexOf(i.month)].amount = i.total_amount;
      });
      setChartMonthsData(openReceivablesData.reverse());
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <SBoxContent>
        <div
          className='d-flex justify-content-between'
          style={{ height: '66px', borderBottom: `1px solid ${palette.gray100}` }}
        >
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Total Open Receivables
            </Typography>
            <div>
              {loading ? (
                <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px' }} />
              ) : (
                <Typography variant='button2' style={{ display: 'flex' }}>
                  {countsData?.open_receivable_total_count}
                </Typography>
              )}
            </div>
          </div>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2' style={{ color: palette.gray700, textAlign: 'end' }}>
              Total
            </Typography>
            <div>
              {loading ? (
                <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px' }} />
              ) : (
                <Typography variant='button2' style={{ display: 'flex' }}>
                  {currency}
                  {countsData?.open_receivable_total_amount}
                </Typography>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <Skeleton variant='rect' animation='wave' height='100%' width='100%' style={{ borderRadius: '10px' }} />
        ) : (
          <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
            {countsData.open_receivable?.length === 0 ? (
              <Typography variant='h2' style={{ color: palette.gray500 }}>
                No stats to show
              </Typography>
            ) : (
              <TotalOpenReceivable monthsData={chartMonthsData} />
            )}
          </div>
        )}
      </SBoxContent>
    </div>
  );
};

export default TotalOpenReceivables;
