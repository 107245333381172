import { array, number, object, string } from 'yup';

export const validationSchema = object({
  plan_ahead_type: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),

  driver: object().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 2,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  carrier: object().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 3,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  carrier_contact_user: object().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 3,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  flat_rate: string().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 2 || plan_ahead_type?.id === 3,
    then: string().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  location_lat: number().when(['plan_ahead_type', 'start_from_type'], {
    is: (plan_ahead_type, start_from_type) =>
      (plan_ahead_type?.id === 2 || plan_ahead_type?.id === 3) && start_from_type === 2,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  location_lng: number().when(['plan_ahead_type', 'start_from_type'], {
    is: (plan_ahead_type, start_from_type) =>
      (plan_ahead_type?.id === 2 || plan_ahead_type?.id === 3) && start_from_type === 2,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),

  drivers: array().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 4,
    then: array().of(
      object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable()
    ),
    otherwise: array().nullable(),
  }),
  carriers: array().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 4,
    then: array().of(
      object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable()
    ),
    otherwise: array().nullable(),
  }),
  next_available_prior_to_pickup: number().when(['plan_ahead_type', 'driver_status'], {
    is: (plan_ahead_type, driver_status) => plan_ahead_type?.id === 4 && driver_status?.some((i) => i.id === 2),
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  offer_amount: string().when('plan_ahead_type', {
    is: (plan_ahead_type) => plan_ahead_type?.id === 4,
    then: string().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
