import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import { getOrdinal } from '../Incidents.data';
import { SContentRow } from '../RecommendedActions/RecommendedActions.styles';
import { SAddMore } from '../Incidents.styles';

const RecommendedActionsEdit = ({ open, onClose, form, actionTakenOptions, title }) => {
  const { handleChange, values } = form;

  const onAddMore = () => {
    handleChange(`recommended_actions[${values.recommended_actions.length}]`, {
      rowId: Date.now(),
      action: '',
    });
  };

  const onDeleteRow = (index) => {
    handleChange(
      'recommended_actions',
      values.recommended_actions.filter((item) => item.rowId !== index)
    );
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Recommended Actions  ${title ? `- ${title}` : ''}`}
      $maxWidth='470px'
      $minWidth='470px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Close' }]}
    >
      <div>
        {values.recommended_actions.map((action, i) => (
          <SContentRow key={action.rowId}>
            <div className='table-item'>
              <Typography variant='s2'>{getOrdinal(i + 1)}</Typography>
            </div>
            <div className='table-item'>
              <Autocomplete
                name={`recommended_actions[${i}]`}
                placeholder='Select Recommended Action...'
                labelKey='action'
                options={actionTakenOptions || []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={action}
                onChange={(e, value) => handleChange(`recommended_actions[${i}]`, { ...value, rowId: action.rowId })}
                width='160px'
              />
            </div>
            <div className='table-item icon-wrapper'>
              <span className='delete-icon' onClick={() => onDeleteRow(action.rowId)}>
                <DeleteIcon fill={palette.red500} />
              </span>
            </div>
          </SContentRow>
        ))}
        <SAddMore onClick={onAddMore}>
          <PlusIcon fill='#4F5AED' />
          <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
            Add Action Type...
          </Typography>
        </SAddMore>
      </div>
    </Modal>
  );
};

export default RecommendedActionsEdit;
