import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import MaterialTableWrapper, { MaterialTableSort } from '../MaterialTableWrapper';
import '../ShipmentTab/ShipmentTab.css';
import CustomSelect from '../CustomSelect/CustomSelect';
import './StaffUserModal.css';

export default function StaffUserModal({ ...props }) {
  const { use } = useTheme();

  return (
    <Modal {...props} size='lg' centered fullscreen='lg-down' dialogClassName='modal-custom-width'>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Import Staff User&#40;s&#41;
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='driver-style-wrap staff-user-modal'
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
      >
        <div className='table-wrap'>
          <div className='sub-table-container'>
            <MaterialTableWrapper
              columns={[
                {
                  title: <MaterialTableSort title='Name' field='name' />,
                  field: 'name',
                  headerStyle: {
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                  },
                },
                {
                  title: <MaterialTableSort title='PHONE NUMBER' field='phoneNumber' />,
                  field: 'phoneNumber',
                  headerStyle: {
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                  },
                },
                {
                  title: <MaterialTableSort title='EMAIL' field='email' />,
                  field: 'email',
                  headerStyle: {
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                  },
                },
                {
                  title: <MaterialTableSort title='TITLE' field='title' />,
                  field: 'title',
                  headerStyle: {
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                  },
                },
                {
                  title: <MaterialTableSort title='DEPARTMENT' field='department' />,
                  field: 'department',
                  headerStyle: {
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                  },
                },
                {
                  title: <MaterialTableSort title='TAGS' field='group' />,
                  field: 'group',
                  headerStyle: {
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                  },
                },
              ]}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              data={[
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
                {
                  name: (
                    <div className='d-flex justify-content-around align-items-center'>
                      <div>
                        <input
                          className='check-input StaffuserModalCheck'
                          type='checkbox'
                          name='can_travel_usa'
                          id='can_travel_usa'
                        />
                      </div>
                      <div>
                        <p className='pt-3 shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          232344322122
                        </p>
                      </div>
                    </div>
                  ),
                  phoneNumber: (
                    <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                      +1 (555) 555-1234
                    </p>
                  ),
                  email: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        example@gmail.com
                      </p>
                    </div>
                  ),
                  title: (
                    <div className='equipment-container'>
                      <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Manager
                      </p>
                    </div>
                  ),
                  department: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect
                          defaultValue='Select Department'
                          id='license_type'
                          name='name'
                          patchProperty='name'
                        />
                      </div>
                    </div>
                  ),
                  group: (
                    <div style={{ marginRight: '20px' }}>
                      <div className='custom-select-wrap-contain'>
                        <CustomSelect defaultValue='Select Tag' id='license_type' name='name' patchProperty='name' />
                      </div>
                    </div>
                  ),
                },
              ]}
              options={{
                sorting: true,
                rowStyle: {
                  borderBottom: 'none',
                },
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn-container'>
          <Button className='cancel-button' onClick={props.onHide}>
            Cancel
          </Button>
          {props.loading ? (
            <CircularProgress size={30} />
          ) : (
            <Button onClick={props.onHide}>Import User&#40;s&#41;</Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
