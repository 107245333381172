import React, { useEffect, useState } from 'react';
import { MessageInput, MessageList, useChannelStateContext } from 'stream-chat-react';
import RecordingIcon from 'assets/icons/header/recording.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send-sms.svg';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import SendMessageLater from 'components/chatAppComponents/sendMesgLater/SendMessageLater';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import AddNotes from 'components/chatAppComponents/AddNotes';

const MessageInner = ({
  setVoiceRecording,
  activeChannel,
  chatUser,
  sheduleMsg,
  setsheduleMsg,
  checkSMSLicence,
  setpinnedMessage,
  setMuted,
  onAddNoteSuccess,
}) => {
  const { formatDateTime, convertToCustomerTime } = useDateFormat();
  const [sentLatter, setsentLatter] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isSMSDisabled, setIsSMSDisabled] = useState(false);
  const [customSmsMessage, setCustomSmsMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionType, setActionType] = useState('Chat');

  useEffect(() => {
    if (activeChannel) {
      setIsSMS(activeChannel?.cid?.includes('anonymous'));
      setIsSMSDisabled(activeChannel?.cid.includes('anonymous'));
    }
  }, [activeChannel]);

  const handleChange = (event) => {
    setCustomSmsMessage(event.target.value);
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      checkSMSLicence(customSmsMessage);
      setCustomSmsMessage('');
    }
  };

  const { messages } = useChannelStateContext();

  useEffect(() => {
    const incomingMessages = document.querySelectorAll('.str-chat__message--other .str-chat__message-text-inner');
    Array.prototype.filter.call(incomingMessages, (testElement) => {
      if (testElement.innerText.includes('[SMS]')) {
        testElement.classList.add('sms-incoming');
        testElement.innerText = testElement.innerText.replaceAll('[SMS]', '');
      }
    });

    const sendMessages = document.querySelectorAll('.str-chat__message--me .str-chat__message-text-inner');
    Array.prototype.filter.call(sendMessages, (testElement) => {
      if (testElement.innerText.includes('[SMS]')) {
        testElement.classList.add('sms');
        testElement.innerText = testElement.innerText.replaceAll('[SMS]', '');
      }
    });
  }, [messages]);

  function customDateFormat(date) {
    return convertToCustomerTime(date);
  }

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'chat':
        setIsSMS(false);
        setActionType('Chat');
        break;
      case 'sms':
        setIsSMS(true);
        setActionType('SMS');
        break;
      case 'notes':
        setActionType('Internal Notes');
        break;
      default:
    }
  };

  return (
    <>
      {sheduleMsg && (
        <SendMessageLater setsheduleMsg={setsheduleMsg} chatUser={chatUser} activeChannel={activeChannel} />
      )}
      <MessageList
        formatDate={customDateFormat}
        onlySenderCanEdit
        messageActions={['edit', 'delete', 'pin', 'quote', 'react', 'reply']}
      />
      <div className='send_input-box'>
        <div className='action-type-dropdown'>
          <ThreeDotActions
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            actionButton={
              <div className='d-flex justify-content-between align-items-center gap-3'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {actionType}
                </Typography>
                <ArrowDown />
              </div>
            }
          >
            <li onClick={(event) => onActionClick(event, 'chat')}>Chat</li>
            {activeChannel?.type !== 'truckindigital' && !isSMSDisabled && (
              <li onClick={(event) => onActionClick(event, 'sms')}>SMS</li>
            )}
            <li onClick={(event) => onActionClick(event, 'notes')}>Internal Notes</li>
          </ThreeDotActions>
        </div>
        {!activeChannel?.data?.open_chat && (
          <div className='close-message-by'>
            {' '}
            Chat closed
            {activeChannel?.data?.chat_closed_by && (
              <>
                <span> by</span> <span className='chat_closed_by'> {activeChannel?.data?.chat_closed_by} </span>
              </>
            )}
            {activeChannel?.data?.chat_closed_date_time && (
              <span>at {formatDateTime(activeChannel?.data?.chat_closed_date_time)}</span>
            )}
          </div>
        )}
        {/* {activeChannel?.type !== 'truckindigital' && ( */}
        {/*  <div className='d-flex align-items-center gap-2 ms-2' style={{ marginBottom: '15px' }}> */}
        {/*    <div className='d-flex align-items-center gap-2 ms-2'> */}
        {/*      <CustomCheckbox */}
        {/*        type='switch' */}
        {/*        checked={isSMS} */}
        {/*        name='after_specific_shipment_complated' */}
        {/*        onChange={setIsSMS} */}
        {/*        disabled={isSMSDisabled} */}
        {/*      /> */}
        {/*      <small>SMS</small> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* )} */}

        {!isSMS && actionType !== 'Internal Notes' && (
          <img src={RecordingIcon} alt='' className='recordingIcon' onClick={() => setVoiceRecording(true)} />
        )}

        {actionType !== 'Internal Notes' &&
          (!isSMS ? (
            <>
              <MessageInput mentionAllAppUsers />
              <div className='send-letter-btn' onClick={() => setsentLatter(!sentLatter)}>
                {sentLatter && (
                  <ul className='send-latter'>
                    <li onClick={() => setsheduleMsg(!sheduleMsg)}>Send Later</li>
                    <li onClick={() => setpinnedMessage(true)}>Pinned Messages</li>
                    <li onClick={() => setMuted(true)}>Channel Status</li>
                  </ul>
                )}
                <ActionDots />
              </div>
            </>
          ) : (
            <>
              <textarea
                className='custom-message-input'
                placeholder='Send an SMS'
                value={customSmsMessage}
                onKeyDown={onEnterPress}
                onChange={handleChange}
              />
              <div
                className='pointer align-self-center'
                onClick={() => {
                  checkSMSLicence(customSmsMessage);
                  setCustomSmsMessage('');
                }}
              >
                <SendIcon with={18} />
              </div>
            </>
          ))}
        {actionType === 'Internal Notes' && (
          <AddNotes chatUser={chatUser} activeChannel={activeChannel} onSuccess={onAddNoteSuccess} />
        )}
      </div>
    </>
  );
};

export default MessageInner;
