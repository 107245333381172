import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Modal, Button } from 'react-bootstrap';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon, Textarea } from 'common/Input';
import ImageDropZone from 'components/ImgageDropZone';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { SAddMore } from 'components/Carriers/AddCarrier/AddCarrier.styles';
import AddVendor from 'pages/Accounting/Vendors/components/AddVendor';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import { getVendorsList } from 'Api/Vendors';
import {
  createCargoCollisionCost,
  createCargoDamagePhoto,
  createCostAnalysis,
  createLiabilityDamageCost,
  createLiabilityDamagePhoto,
  createTrailerCollisionCost,
  createTrailerDamagePhoto,
  createVehicleCollisionCost,
  createVehicleDamagePhoto,
} from 'Api/AccidentsAndCrashes';
import Chip from 'common/Chip';
import Autocomplete from 'common/Autocomplete';
import { getOpenInvoicesTableData } from 'Api/AccountingReceivables';
import Photos from './components/Photos';
import { getCollisionCostInitialValues } from '../AddAccidents.data';
import CollisionCostRow from './components/CollisionCostRow';

const CollisionCost = ({ Cancel, accident, id, selectedVehicle, selectedTrailer, customers }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vehicleInvoices, setVehicleInvoices] = useState([]);
  const [loadingInvoices, setloadingInvoices] = useState(false);
  const [trailerInvoices, setTrailerInvoices] = useState([]);
  const [cargoInvoices, setCargoInvoices] = useState([]);
  const [liabilityInvoices, setLiabilityInvoices] = useState([]);
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const [onSubmitAction, setOnSubmitAction] = useState('save');

  const handleCollisionSubmit = async () => {
    setLoading(true);
    const {
      cost_analysis_vehicle_damage_estimate,
      cost_analysis_vehicle_estimate_attachment,
      cost_analysis_vehicle_insurance_claim,
      cost_analysis_vehicle_insurance_company_name,
      cost_analysis_vehicle_insurance_claim_number,
      cost_analysis_vehicle_insurance_policy_number,
      cost_analysis_trailer_damage_estimate,
      cost_analysis_trailer_damage_description,
      cost_analysis_trailer_estimate_attachment,
      cost_analysis_trailer_insurance_claim,
      cost_analysis_trailer_insurance_company_name,
      cost_analysis_trailer_insurance_claim_number,
      cost_analysis_trailer_insurance_policy_number,
      cost_analysis_cargo_damage_estimate,
      cost_analysis_cargo_damage_description,
      cost_analysis_cargo_estimate_attachment,
      cost_analysis_cargo_insurance_claim,
      cost_analysis_cargo_insurance_company_name,
      cost_analysis_cargo_insurance_claim_number,
      cost_analysis_cargo_insurance_policy_number,
      cost_analysis_liability_damage_estimate,
      cost_analysis_liability_damage_description,
      cost_analysis_liability_estimate_attachment,
      cost_analysis_liability_insurance_claim,
      cost_analysis_liability_insurance_company_name,
      cost_analysis_liability_insurance_claim_number,
      cost_analysis_liability_insurance_policy_number,
      cost_analysis_total_cost,
      vehicle_collision_cost,
      vehicle_collision_photos,
      trailer_collision_cost,
      trailer_collision_photos,
      cargo_collision_cost,
      cargo_collision_photos,
      liability_collision_cost,
      liability_collision_photos,
      vehicle_damage_invoice,
      trailer_damage_invoice,
      cargo_damage_invoice,
      liability_damage_invoice,
    } = values || {};
    const formData = new FormData();
    formData.append('cost_analysis_vehicle_damage_estimate', Number(cost_analysis_vehicle_damage_estimate));
    if (cost_analysis_vehicle_damage_estimate) {
      cost_analysis_vehicle_estimate_attachment?.length &&
        formData.append('cost_analysis_vehicle_estimate_attachment', cost_analysis_vehicle_estimate_attachment[0]);
      formData.append('cost_analysis_vehicle_insurance_claim', Number(cost_analysis_vehicle_insurance_claim));
      if (cost_analysis_vehicle_insurance_claim && cost_analysis_vehicle_insurance_company_name) {
        formData.append('cost_analysis_vehicle_insurance_company_name', cost_analysis_vehicle_insurance_company_name);
      }
      if (cost_analysis_vehicle_insurance_claim && cost_analysis_vehicle_insurance_claim_number) {
        formData.append('cost_analysis_vehicle_insurance_claim_number', cost_analysis_vehicle_insurance_claim_number);
      }
      if (cost_analysis_vehicle_insurance_claim && cost_analysis_vehicle_insurance_policy_number) {
        formData.append('cost_analysis_vehicle_insurance_policy_number', cost_analysis_vehicle_insurance_policy_number);
      }
      if (vehicle_damage_invoice) {
        formData.append('vehicle_damage_invoice_id', vehicle_damage_invoice.invoice_id);
      }
    }

    formData.append('cost_analysis_trailer_damage_estimate', Number(cost_analysis_trailer_damage_estimate));

    if (cost_analysis_trailer_damage_estimate) {
      formData.append('cost_analysis_trailer_damage_description', cost_analysis_trailer_damage_description);
      cost_analysis_trailer_estimate_attachment?.length &&
        formData.append('cost_analysis_trailer_estimate_attachment', cost_analysis_trailer_estimate_attachment[0]);
      formData.append('cost_analysis_trailer_insurance_claim', Number(cost_analysis_trailer_insurance_claim));

      if (cost_analysis_trailer_insurance_company_name && cost_analysis_trailer_insurance_claim) {
        formData.append('cost_analysis_trailer_insurance_company_name', cost_analysis_trailer_insurance_company_name);
      }

      if (cost_analysis_trailer_insurance_claim_number && cost_analysis_trailer_insurance_claim) {
        formData.append('cost_analysis_trailer_insurance_claim_number', cost_analysis_trailer_insurance_claim_number);
      }

      if (cost_analysis_trailer_insurance_policy_number && cost_analysis_trailer_insurance_claim) {
        formData.append('cost_analysis_trailer_insurance_policy_number', cost_analysis_trailer_insurance_policy_number);
      }

      if (trailer_damage_invoice) {
        formData.append('trailer_damage_invoice_id', trailer_damage_invoice.invoice_id);
      }
    }

    formData.append('cost_analysis_cargo_damage_estimate', Number(cost_analysis_cargo_damage_estimate));

    if (cost_analysis_cargo_damage_estimate) {
      formData.append('cost_analysis_cargo_damage_description', cost_analysis_cargo_damage_description);
      cost_analysis_cargo_estimate_attachment?.length &&
        formData.append('cost_analysis_cargo_estimate_attachment', cost_analysis_cargo_estimate_attachment[0]);
      formData.append('cost_analysis_cargo_insurance_claim', Number(cost_analysis_cargo_insurance_claim));

      if (cost_analysis_cargo_insurance_claim && cost_analysis_cargo_insurance_company_name) {
        formData.append('cost_analysis_cargo_insurance_company_name', cost_analysis_cargo_insurance_company_name);
      }

      if (cost_analysis_cargo_insurance_claim && cost_analysis_cargo_insurance_claim_number) {
        formData.append('cost_analysis_cargo_insurance_claim_number', cost_analysis_cargo_insurance_claim_number);
      }

      if (cost_analysis_cargo_insurance_claim && cost_analysis_cargo_insurance_policy_number) {
        formData.append('cost_analysis_cargo_insurance_policy_number', cost_analysis_cargo_insurance_policy_number);
      }

      if (cargo_damage_invoice) {
        formData.append('cargo_damage_invoice_id', cargo_damage_invoice.invoice_id);
      }
    }

    formData.append('cost_analysis_liability_damage_estimate', Number(cost_analysis_liability_damage_estimate));

    if (cost_analysis_liability_damage_estimate) {
      formData.append('cost_analysis_liability_damage_description', cost_analysis_liability_damage_description);

      cost_analysis_liability_estimate_attachment?.length &&
        formData.append('cost_analysis_liability_estimate_attachment', cost_analysis_liability_estimate_attachment[0]);

      formData.append('cost_analysis_liability_insurance_claim', Number(cost_analysis_liability_insurance_claim));

      if (cost_analysis_liability_insurance_claim && cost_analysis_liability_insurance_company_name) {
        formData.append(
          'cost_analysis_liability_insurance_company_name',
          cost_analysis_liability_insurance_company_name
        );
      }

      if (cost_analysis_liability_insurance_claim && cost_analysis_liability_insurance_claim_number) {
        formData.append(
          'cost_analysis_liability_insurance_claim_number',
          cost_analysis_liability_insurance_claim_number
        );
      }

      if (cost_analysis_liability_insurance_claim && cost_analysis_liability_insurance_policy_number) {
        formData.append(
          'cost_analysis_liability_insurance_policy_number',
          cost_analysis_liability_insurance_policy_number
        );
      }

      if (cost_analysis_total_cost) {
        formData.append('cost_analysis_total_cost', cost_analysis_total_cost);
      }

      if (liability_damage_invoice) {
        formData.append('liability_damage_invoice_id', liability_damage_invoice.invoice_id);
      }
    }

    try {
      await createCostAnalysis(accident?.id || id, formData);
      if (vehicle_collision_cost?.length) {
        const body = vehicle_collision_cost.map((item) => ({
          accounting_vendor_id: item?.vendor?.id || '',
          accounting_bill_id: item.bill?.id === '*' ? null : item.bill?.id || null,
          amount: item.amount || 0,
          description: item.description,
          bill_pending: item.bill?.id === '*' ? 1 : 0,
        }));
        await createVehicleCollisionCost(accident?.id || id, body);
      }

      if (vehicle_collision_photos?.length) {
        const newPhotos = vehicle_collision_photos.filter((item) => !item.photo_path);
        for (const item of newPhotos) {
          const photoData = new FormData();
          photoData.append('photo_path', item[0]);
          await createVehicleDamagePhoto(accident?.id || id, photoData);
        }
      }

      if (trailer_collision_cost?.length) {
        const body = trailer_collision_cost.map((item) => ({
          accounting_vendor_id: item?.vendor?.id || '',
          accounting_bill_id: item.bill?.id === '*' ? null : item.bill?.id || null,
          amount: item.amount || 0,
          description: item.description,
          bill_pending: item.bill?.id === '*' ? 1 : 0,
        }));
        await createTrailerCollisionCost(accident?.id || id, body);
      }

      if (trailer_collision_photos?.length) {
        const newPhotos = trailer_collision_photos.filter((item) => !item.photo_path);
        for (const item of newPhotos) {
          const photoData = new FormData();
          photoData.append('photo_path', item[0]);
          await createTrailerDamagePhoto(accident?.id || id, photoData);
        }
      }

      if (cargo_collision_cost?.length) {
        const body = cargo_collision_cost.map((item) => ({
          accounting_vendor_id: item?.vendor?.id || '',
          accounting_bill_id: item.bill?.id === '*' ? null : item.bill?.id || null,
          amount: item.amount || 0,
          description: item.description,
          bill_pending: item.bill?.id === '*' ? 1 : 0,
        }));
        await createCargoCollisionCost(accident?.id || id, body);
      }

      if (cargo_collision_photos?.length) {
        const newPhotos = cargo_collision_photos.filter((item) => !item.photo_path);
        for (const item of newPhotos) {
          const photoData = new FormData();
          photoData.append('photo_path', item[0]);
          await createCargoDamagePhoto(accident?.id || id, photoData);
        }
      }

      if (liability_collision_cost?.length) {
        const body = liability_collision_cost.map((item) => ({
          accounting_vendor_id: item?.vendor?.id || '',
          accounting_bill_id: item.bill?.id === '*' ? null : item.bill?.id || null,
          amount: item.amount || 0,
          description: item.description,
          bill_pending: item.bill?.id === '*' ? 1 : 0,
        }));
        await createLiabilityDamageCost(accident?.id || id, body);
      }

      if (liability_collision_photos?.length) {
        const newPhotos = liability_collision_photos.filter((item) => !item.photo_path);
        for (const item of newPhotos) {
          const photoData = new FormData();
          photoData.append('photo_path', item[0]);
          await createLiabilityDamagePhoto(accident?.id || id, photoData);
        }
      }

      if (onSubmitAction === 'close') {
        Cancel();
      }
      showToaster({ type: 'success', message: 'Success!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, setValues } = useForm({
    initialValues: getCollisionCostInitialValues({ selectedVehicle, selectedTrailer }),
    onSubmit: handleCollisionSubmit,
  });

  const removeRow = (type, index) => {
    const newCollisionCost = values[`${type}_collision_cost`].filter((_, idx) => idx !== index);
    setValues({ ...values, [`${type}_collision_cost`]: newCollisionCost });
  };

  const addRow = (type) => {
    const newCollisionCost = [
      ...values[`${type}_collision_cost`],
      {
        id: Date.now(),
        description: '',
        vendor: null,
        bill: null,
        amount: '',
      },
    ];
    setValues({ ...values, [`${type}_collision_cost`]: newCollisionCost });
  };

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      data.unshift({ id: '*', name: '+ Add Vendor' });
      setVendors(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getOpenShipmentInvoices = async (customer_id, type) => {
    try {
      setloadingInvoices(true);
      const params = {
        page: 1,
        itemsPerPage: 10000,
        'filters[customer_id]': [customer_id],
        'sort[][customer_billed_date]': 'desc',
      };

      const { data } = await getOpenInvoicesTableData(params);
      if (type === 'vehicle') {
        setVehicleInvoices(data);
      } else if (type === 'trailer') {
        setTrailerInvoices(data);
      } else if (type === 'cargo') {
        setCargoInvoices(data);
      } else if (type === 'liability') {
        setLiabilityInvoices(data);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setloadingInvoices(false);
    }
  };

  const onSave = () => {
    setOnSubmitAction('save');
    handleSubmit();
  };

  const onSaveAndClose = () => {
    setOnSubmitAction('close');
    handleSubmit();
  };

  const handleVehicleCustomerChange = (customer) => {
    setValues((prevState) => ({
      ...prevState,
      vehicle_damage_customer: customer,
      vehicle_damage_invoice: null,
    }));
    getOpenShipmentInvoices(customer.id, 'vehicle');
    handleChange('vehicle_damage_invoice', null);
  };

  const handleTrailerCustomerChange = (customer) => {
    setValues((prevState) => ({
      ...prevState,
      trailer_damage_customer: customer,
      trailer_damage_invoice: null,
    }));
    getOpenShipmentInvoices(customer.id, 'trailer');
  };

  const handleCargoCustomerChange = (customer) => {
    setValues((prevState) => ({
      ...prevState,
      cargo_damage_customer: customer,
      cargo_damage_invoice: null,
    }));
    getOpenShipmentInvoices(customer.id, 'cargo');
  };

  const handleLiabilityCustomerChange = (customer) => {
    setValues((prevState) => ({
      ...prevState,
      liability_damage_customer: customer,
      liability_damage_invoice: null,
    }));
    getOpenShipmentInvoices(customer.id, 'liability');
  };

  useEffect(() => {
    getVendors();
  }, []);

  useEffect(() => {
    const costsArray = [
      values.vehicle_collision_cost,
      values.trailer_collision_cost,
      values.cargo_collision_cost,
      values.liability_collision_cost,
    ];
    let totalSum = 0;
    for (const key in costsArray) {
      if (costsArray.hasOwnProperty(key)) {
        const array = costsArray[key];
        totalSum += array.reduce((acc, item) => acc + Number(item.amount) || 0, 0);
      }
    }
    handleChange('cost_analysis_total_cost', totalSum);
  }, [
    values.vehicle_collision_cost,
    values.trailer_collision_cost,
    values.cargo_collision_cost,
    values.liability_collision_cost,
  ]);

  useEffect(() => {
    if (accident) {
      const {
        cost_analysis_vehicle_damage_estimate,
        cost_analysis_vehicle_estimate_attachment,
        cost_analysis_vehicle_insurance_claim,
        cost_analysis_vehicle_insurance_company_name,
        cost_analysis_vehicle_insurance_claim_number,
        cost_analysis_vehicle_insurance_policy_number,
        cost_analysis_trailer_damage_estimate,
        cost_analysis_trailer_damage_description,
        cost_analysis_trailer_estimate_attachment,
        cost_analysis_trailer_insurance_claim,
        cost_analysis_trailer_insurance_company_name,
        cost_analysis_trailer_insurance_claim_number,
        cost_analysis_trailer_insurance_policy_number,
        cost_analysis_cargo_damage_estimate,
        cost_analysis_cargo_damage_description,
        cost_analysis_cargo_estimate_attachment,
        cost_analysis_cargo_insurance_claim,
        cost_analysis_cargo_insurance_company_name,
        cost_analysis_cargo_insurance_claim_number,
        cost_analysis_cargo_insurance_policy_number,
        cost_analysis_liability_damage_estimate,
        cost_analysis_liability_damage_description,
        cost_analysis_liability_estimate_attachment,
        cost_analysis_liability_insurance_claim,
        cost_analysis_liability_insurance_company_name,
        cost_analysis_liability_insurance_claim_number,
        cost_analysis_liability_insurance_policy_number,
        cost_analysis_total_cost,
        vehicle_collision_costs,
        vehicle_photos,
        trailer_collision_costs,
        trailer_photos,
        cargo_collision_costs,
        cargo_photos,
        liability_collision_costs,
        liability_photos,
        vehicle_damage_invoice,
        trailer_damage_invoice,
        cargo_damage_invoice,
        liability_damage_invoice,
      } = accident || {};

      setValues({
        cost_analysis_vehicle_damage_estimate,
        cost_analysis_vehicle_estimate_attachment,
        cost_analysis_vehicle_insurance_claim,
        cost_analysis_vehicle_insurance_company_name,
        cost_analysis_vehicle_insurance_claim_number,
        cost_analysis_vehicle_insurance_policy_number,
        cost_analysis_trailer_damage_estimate,
        cost_analysis_trailer_damage_description,
        cost_analysis_trailer_estimate_attachment,
        cost_analysis_trailer_insurance_claim,
        cost_analysis_trailer_insurance_company_name,
        cost_analysis_trailer_insurance_claim_number,
        cost_analysis_trailer_insurance_policy_number,
        cost_analysis_cargo_damage_estimate,
        cost_analysis_cargo_damage_description,
        cost_analysis_cargo_estimate_attachment,
        cost_analysis_cargo_insurance_claim,
        cost_analysis_cargo_insurance_company_name,
        cost_analysis_cargo_insurance_claim_number,
        cost_analysis_cargo_insurance_policy_number,
        cost_analysis_liability_damage_estimate,
        cost_analysis_liability_damage_description,
        cost_analysis_liability_estimate_attachment,
        cost_analysis_liability_insurance_claim,
        cost_analysis_liability_insurance_company_name,
        cost_analysis_liability_insurance_claim_number,
        cost_analysis_liability_insurance_policy_number,
        cost_analysis_total_cost,
        vehicle_collision_cost: vehicle_collision_costs || [],
        vehicle_collision_photos: vehicle_photos || [],
        trailer_collision_cost: trailer_collision_costs || [],
        trailer_collision_photos: trailer_photos || [],
        cargo_collision_cost: cargo_collision_costs || [],
        cargo_collision_photos: cargo_photos || [],
        liability_collision_cost: liability_collision_costs || [],
        liability_collision_photos: liability_photos || [],

        vehicle_damage_customer: customers.find((el) => el.id === vehicle_damage_invoice?.customer_id) || null,
        vehicle_damage_invoice: vehicle_damage_invoice || null,

        trailer_damage_customer: customers.find((el) => el.id === trailer_damage_invoice?.customer_id) || null,
        trailer_damage_invoice: trailer_damage_invoice || null,

        cargo_damage_customer: customers.find((el) => el.id === cargo_damage_invoice?.customer_id) || null,
        cargo_damage_invoice: cargo_damage_invoice || null,

        liability_damage_customer: customers.find((el) => el.id === liability_damage_invoice?.customer_id) || null,
        liability_damage_invoice: liability_damage_invoice || null,
      });
    }
  }, [accident]);

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-accident-modal-body'>
        <div>
          <div className='d-flex align-items-center gap-2 mt-2'>
            <CustomCheckbox
              name='cost_analysis_vehicle_damage_estimate'
              type='switch'
              smail={false}
              checked={values.cost_analysis_vehicle_damage_estimate}
              onChange={(checked) => handleChange('cost_analysis_vehicle_damage_estimate', checked)}
            />
            <Typography variant='s2'>Vehicle Damage Estimate</Typography>
          </div>

          {!!values.cost_analysis_vehicle_damage_estimate && (
            <div style={{ marginLeft: '58px' }}>
              <div className='d-flex  mt-1 '>
                <div>
                  <InputLabel>Collision Cost</InputLabel>
                  {values.vehicle_collision_cost.map((row, index) => (
                    <CollisionCostRow
                      row={row}
                      index={index}
                      type='vehicle'
                      vendors={vendors}
                      removeRow={removeRow}
                      setValues={setValues}
                      handleChange={handleChange}
                      setOpenAddVendor={setOpenAddVendor}
                    />
                  ))}
                  <SAddMore onClick={() => addRow('vehicle')}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Add Another
                    </Typography>
                  </SAddMore>
                </div>
              </div>

              <div className='mt-3'>
                <Photos values={values} handleChange={handleChange} type='vehicle' />
              </div>

              <div className='d-flex flex-column  mt-3'>
                <InputLabel>Attach Estimate / Receipt</InputLabel>
                <div>
                  {values?.cost_analysis_vehicle_estimate_attachment ? (
                    <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => handleChange('cost_analysis_vehicle_estimate_attachment', null)}
                      />
                    </p>
                  ) : (
                    <div
                      className='upload-document-deposit'
                      style={{
                        background: use(palette.white, palette.dark800),
                        color: use(palette.gray900, palette.gray50),
                        borderColor: use(palette.gray200, palette.gray700),
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <label htmlFor='myFile' className='text-style upload-label'>
                        <p className='upload-document-icon'>
                          <img src={uploadLicense} alt='' />
                        </p>
                        <p className='upload-document-text'>
                          <span style={{ color: '#4F5AED' }}> Upload Document </span>
                          <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                        </p>
                      </label>
                      <input
                        type='file'
                        id='myFile'
                        name='filename'
                        onChange={(file) => {
                          handleChange('cost_analysis_vehicle_estimate_attachment', file);
                        }}
                        style={{ display: 'none' }}
                      />
                      <ImageDropZone
                        onDrop={(file) => handleChange('cost_analysis_vehicle_estimate_attachment', file)}
                        width='100%'
                        height='100%'
                        name='small'
                        deletable={false}
                        maxSize={1000000}
                        customStyle={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                        }}
                        accept={['application/pdf', 'image/jpeg', 'image/png']}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column mt-3'>
                <CustomCheckbox
                  checked={values.cost_analysis_vehicle_insurance_claim}
                  onChange={(checked) => handleChange('cost_analysis_vehicle_insurance_claim', checked)}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Insurance Claim?
                  </Typography>
                </CustomCheckbox>
              </div>

              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Company Name</InputLabel>
                <Input
                  name='cost_analysis_vehicle_insurance_company_name'
                  value={values.cost_analysis_vehicle_insurance_company_name}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_vehicle_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Claim Number</InputLabel>
                <Input
                  name='cost_analysis_vehicle_insurance_claim_number'
                  value={values.cost_analysis_vehicle_insurance_claim_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_vehicle_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Policy Number</InputLabel>
                <Input
                  name='cost_analysis_vehicle_insurance_policy_number'
                  value={values.cost_analysis_vehicle_insurance_policy_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_vehicle_insurance_claim}
                />
              </div>

              <div className='d-flex gap-3 mt-3 mb-2 align-items-center'>
                <div>
                  <Autocomplete
                    width='200px'
                    labelKey='company_name'
                    placeholder='Customer'
                    label='Select Customer'
                    name='vehicle_damage_customer'
                    value={values.vehicle_damage_customer}
                    options={customers}
                    onChange={(e, val) => handleVehicleCustomerChange(val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='200px'
                    placeholder='Invoice'
                    labelKey='invoice_id'
                    label='Select Open Invoice'
                    loading={loadingInvoices}
                    name='vehicle_damage_customer'
                    value={values.vehicle_damage_invoice}
                    options={vehicleInvoices}
                    onChange={(e, val) => handleChange('vehicle_damage_invoice', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>

                <div>
                  <InputWithIcon
                    type='number'
                    name='amount'
                    label='Amount of Invoice'
                    placeholder='0.00'
                    value={values.vehicle_damage_invoice?.total_amount || ''}
                    disabled
                  />
                </div>

                {!!values.vehicle_damage_invoice && (
                  <div>
                    <InputLabel>Status</InputLabel>
                    <Chip
                      label={moment().isAfter(values.vehicle_damage_invoice?.due_date) ? 'PAST DUE' : 'OPEN'}
                      labelColor={
                        moment().isAfter(values.vehicle_damage_invoice?.due_date) ? palette.red500 : palette.green500
                      }
                      bgColor={
                        moment().isAfter(values.vehicle_damage_invoice?.due_date) ? palette.red0 : palette.green0
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className='d-flex align-items-center gap-2 mt-2'>
            <CustomCheckbox
              name='cost_analysis_trailer_damage_estimate'
              type='switch'
              smail={false}
              checked={values.cost_analysis_trailer_damage_estimate}
              onChange={(checked) => handleChange('cost_analysis_trailer_damage_estimate', checked)}
            />
            <Typography variant='s2'>Trailer Damage Estimate</Typography>
          </div>

          {!!values.cost_analysis_trailer_damage_estimate && (
            <div style={{ marginLeft: '58px' }}>
              <div className='d-flex  mt-1 '>
                <div>
                  <InputLabel>Collision Cost</InputLabel>
                  {values.trailer_collision_cost.map((row, index) => (
                    <CollisionCostRow
                      row={row}
                      index={index}
                      type='trailer'
                      vendors={vendors}
                      removeRow={removeRow}
                      setValues={setValues}
                      handleChange={handleChange}
                    />
                  ))}
                  <SAddMore onClick={() => addRow('trailer')}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Add Another
                    </Typography>
                  </SAddMore>
                </div>
              </div>

              <div className='mt-3'>
                <Textarea
                  label='Description of Damage'
                  rows={3}
                  name='cost_analysis_trailer_damage_description'
                  value={values.cost_analysis_trailer_damage_description}
                  onChange={handleChange}
                />
              </div>

              <div className='mt-3'>
                <Photos values={values} handleChange={handleChange} type='trailer' />
              </div>

              <div className='d-flex flex-column  mt-3'>
                <InputLabel>Attach Estimate / Receipt</InputLabel>
                <div>
                  {values?.cost_analysis_trailer_estimate_attachment ? (
                    <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => handleChange('cost_analysis_trailer_estimate_attachment', null)}
                      />
                    </p>
                  ) : (
                    <div
                      className='upload-document-deposit'
                      style={{
                        background: use(palette.white, palette.dark800),
                        color: use(palette.gray900, palette.gray50),
                        borderColor: use(palette.gray200, palette.gray700),
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <label htmlFor='myFile' className='text-style upload-label'>
                        <p className='upload-document-icon'>
                          <img src={uploadLicense} alt='' />
                        </p>
                        <p className='upload-document-text'>
                          <span style={{ color: '#4F5AED' }}> Upload Document </span>
                          <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                        </p>
                      </label>
                      <input
                        type='file'
                        id='myFile'
                        name='filename'
                        onChange={(file) => {
                          handleChange('cost_analysis_trailer_estimate_attachment', file);
                        }}
                        style={{ display: 'none' }}
                      />
                      <ImageDropZone
                        onDrop={(file) => handleChange('cost_analysis_trailer_estimate_attachment', file)}
                        width='100%'
                        height='100%'
                        name='small'
                        deletable={false}
                        maxSize={1000000}
                        customStyle={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                        }}
                        accept={['application/pdf', 'image/jpeg', 'image/png']}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column mt-3'>
                <CustomCheckbox
                  checked={values.cost_analysis_trailer_insurance_claim}
                  onChange={(checked) => handleChange('cost_analysis_trailer_insurance_claim', checked)}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Insurance Claim?
                  </Typography>
                </CustomCheckbox>
              </div>

              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Company Name</InputLabel>
                <Input
                  name='cost_analysis_trailer_insurance_company_name'
                  value={values.cost_analysis_trailer_insurance_company_name}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_trailer_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Claim Number</InputLabel>
                <Input
                  name='cost_analysis_trailer_insurance_claim_number'
                  value={values.cost_analysis_trailer_insurance_claim_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_trailer_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Policy Number</InputLabel>
                <Input
                  name='cost_analysis_trailer_insurance_policy_number'
                  value={values.cost_analysis_trailer_insurance_policy_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_trailer_insurance_claim}
                />
              </div>
              <div className='d-flex gap-3 mt-3 mb-2 align-items-center'>
                <div>
                  <Autocomplete
                    width='200px'
                    labelKey='company_name'
                    placeholder='Customer'
                    label='Select Customer'
                    name='trailer_damage_customer'
                    value={values.trailer_damage_customer}
                    options={customers}
                    onChange={(e, val) => handleTrailerCustomerChange(val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='200px'
                    placeholder='Invoice'
                    labelKey='invoice_id'
                    label='Select Open Invoice'
                    loading={loadingInvoices}
                    name='trailer_damage_customer'
                    value={values.trailer_damage_invoice}
                    options={trailerInvoices}
                    onChange={(e, val) => handleChange('trailer_damage_invoice', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>

                <div>
                  <InputWithIcon
                    type='number'
                    name='amount'
                    label='Amount of Invoice'
                    placeholder='0.00'
                    value={values.trailer_damage_invoice?.total_amount || ''}
                    disabled
                  />
                </div>

                {!!values.trailer_damage_invoice && (
                  <div>
                    <InputLabel>Status</InputLabel>
                    <Chip
                      label={moment().isAfter(values.trailer_damage_invoice?.due_date) ? 'PAST DUE' : 'OPEN'}
                      labelColor={
                        moment().isAfter(values.trailer_damage_invoice?.due_date) ? palette.red500 : palette.green500
                      }
                      bgColor={
                        moment().isAfter(values.trailer_damage_invoice?.due_date) ? palette.red0 : palette.green0
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className='d-flex align-items-center gap-2 mt-2'>
            <CustomCheckbox
              name='cost_analysis_cargo_damage_estimate'
              type='switch'
              smail={false}
              checked={values.cost_analysis_cargo_damage_estimate}
              onChange={(checked) => handleChange('cost_analysis_cargo_damage_estimate', checked)}
            />
            <Typography variant='s2'>Cargo Damage Estimate</Typography>
          </div>

          {!!values.cost_analysis_cargo_damage_estimate && (
            <div style={{ marginLeft: '58px' }}>
              <div className='d-flex  mt-1 '>
                <div>
                  <InputLabel>Collision Cost</InputLabel>
                  {values.cargo_collision_cost.map((row, index) => (
                    <CollisionCostRow
                      row={row}
                      index={index}
                      type='cargo'
                      vendors={vendors}
                      removeRow={removeRow}
                      setValues={setValues}
                      handleChange={handleChange}
                    />
                  ))}
                  <SAddMore onClick={() => addRow('cargo')}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Add Another
                    </Typography>
                  </SAddMore>
                </div>
              </div>

              <div className='mt-3'>
                <Textarea
                  label='Description of Damage'
                  rows={3}
                  name='cost_analysis_cargo_damage_description'
                  value={values.cost_analysis_cargo_damage_description}
                  onChange={handleChange}
                />
              </div>

              <div className='mt-3'>
                <Photos values={values} handleChange={handleChange} type='cargo' />
              </div>

              <div className='d-flex flex-column  mt-3'>
                <InputLabel>Attach Estimate / Receipt</InputLabel>
                <div>
                  {values?.cost_analysis_cargo_estimate_attachment ? (
                    <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => handleChange('cost_analysis_cargo_estimate_attachment', null)}
                      />
                    </p>
                  ) : (
                    <div
                      className='upload-document-deposit'
                      style={{
                        background: use(palette.white, palette.dark800),
                        color: use(palette.gray900, palette.gray50),
                        borderColor: use(palette.gray200, palette.gray700),
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <label htmlFor='myFile' className='text-style upload-label'>
                        <p className='upload-document-icon'>
                          <img src={uploadLicense} alt='' />
                        </p>
                        <p className='upload-document-text'>
                          <span style={{ color: '#4F5AED' }}> Upload Document </span>
                          <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                        </p>
                      </label>
                      <input
                        type='file'
                        id='myFile'
                        name='filename'
                        onChange={(file) => {
                          handleChange('cost_analysis_cargo_estimate_attachment', file);
                        }}
                        style={{ display: 'none' }}
                      />
                      <ImageDropZone
                        onDrop={(file) => handleChange('cost_analysis_cargo_estimate_attachment', file)}
                        width='100%'
                        height='100%'
                        name='small'
                        deletable={false}
                        maxSize={1000000}
                        customStyle={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                        }}
                        accept={['application/pdf', 'image/jpeg', 'image/png']}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column mt-3'>
                <CustomCheckbox
                  checked={values.cost_analysis_cargo_insurance_claim}
                  onChange={(checked) => handleChange('cost_analysis_cargo_insurance_claim', checked)}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Insurance Claim?
                  </Typography>
                </CustomCheckbox>
              </div>

              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Company Name</InputLabel>
                <Input
                  name='cost_analysis_cargo_insurance_company_name'
                  value={values.cost_analysis_cargo_insurance_company_name}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_cargo_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Claim Number</InputLabel>
                <Input
                  name='cost_analysis_cargo_insurance_claim_number'
                  value={values.cost_analysis_cargo_insurance_claim_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_cargo_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Policy Number</InputLabel>
                <Input
                  name='cost_analysis_cargo_insurance_policy_number'
                  value={values.cost_analysis_cargo_insurance_policy_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_cargo_insurance_claim}
                />
              </div>
              <div className='d-flex gap-3 mt-3 mb-2 align-items-center'>
                <div>
                  <Autocomplete
                    width='200px'
                    labelKey='company_name'
                    placeholder='Customer'
                    label='Select Customer'
                    name='cargo_damage_customer'
                    value={values.cargo_damage_customer}
                    options={customers}
                    onChange={(e, val) => handleCargoCustomerChange(val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='200px'
                    placeholder='Invoice'
                    labelKey='invoice_id'
                    label='Select Open Invoice'
                    loading={loadingInvoices}
                    name='cargo_damage_customer'
                    value={values.cargo_damage_invoice}
                    options={cargoInvoices}
                    onChange={(e, val) => handleChange('cargo_damage_invoice', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>

                <div>
                  <InputWithIcon
                    type='number'
                    name='amount'
                    label='Amount of Invoice'
                    placeholder='0.00'
                    value={values.cargo_damage_invoice?.total_amount || ''}
                    disabled
                  />
                </div>

                {!!values.cargo_damage_invoice && (
                  <div>
                    <InputLabel>Status</InputLabel>
                    <Chip
                      label={moment().isAfter(values.cargo_damage_invoice?.due_date) ? 'PAST DUE' : 'OPEN'}
                      labelColor={
                        moment().isAfter(values.cargo_damage_invoice?.due_date) ? palette.red500 : palette.green500
                      }
                      bgColor={moment().isAfter(values.cargo_damage_invoice?.due_date) ? palette.red0 : palette.green0}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className='d-flex align-items-center gap-2 mt-2'>
            <CustomCheckbox
              name='cost_analysis_liability_damage_estimate'
              type='switch'
              smail={false}
              checked={values.cost_analysis_liability_damage_estimate}
              onChange={(checked) => handleChange('cost_analysis_liability_damage_estimate', checked)}
            />
            <Typography variant='s2'>Liability Damage Estimate</Typography>
          </div>

          {!!values.cost_analysis_liability_damage_estimate && (
            <div style={{ marginLeft: '58px' }}>
              <div className='d-flex  mt-1 '>
                <div>
                  <InputLabel>Collision Cost</InputLabel>
                  {values.liability_collision_cost.map((row, index) => (
                    <CollisionCostRow
                      row={row}
                      index={index}
                      type='liability'
                      vendors={vendors}
                      removeRow={removeRow}
                      setValues={setValues}
                      handleChange={handleChange}
                    />
                  ))}
                  <SAddMore onClick={() => addRow('liability')}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Add Another
                    </Typography>
                  </SAddMore>
                </div>
              </div>

              <div className='mt-3'>
                <Textarea
                  label='Description of Damage'
                  rows={3}
                  name='cost_analysis_liability_damage_description'
                  value={values.cost_analysis_liability_damage_description}
                  onChange={handleChange}
                />
              </div>

              <div className='mt-3'>
                <Photos values={values} handleChange={handleChange} type='liability' />
              </div>

              <div className='d-flex flex-column  mt-3'>
                <InputLabel>Attach Estimate / Receipt</InputLabel>
                <div>
                  {values?.cost_analysis_liability_estimate_attachment ? (
                    <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => handleChange('cost_analysis_liability_estimate_attachment', null)}
                      />
                    </p>
                  ) : (
                    <div
                      className='upload-document-deposit'
                      style={{
                        background: use(palette.white, palette.dark800),
                        color: use(palette.gray900, palette.gray50),
                        borderColor: use(palette.gray200, palette.gray700),
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <label htmlFor='myFile' className='text-style upload-label'>
                        <p className='upload-document-icon'>
                          <img src={uploadLicense} alt='' />
                        </p>
                        <p className='upload-document-text'>
                          <span style={{ color: '#4F5AED' }}> Upload Document </span>
                          <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                        </p>
                      </label>
                      <input
                        type='file'
                        id='myFile'
                        name='filename'
                        onChange={(file) => {
                          handleChange('cost_analysis_liability_estimate_attachment', file);
                        }}
                        style={{ display: 'none' }}
                      />
                      <ImageDropZone
                        onDrop={(file) => handleChange('cost_analysis_liability_estimate_attachment', file)}
                        width='100%'
                        height='100%'
                        name='small'
                        deletable={false}
                        maxSize={1000000}
                        customStyle={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                        }}
                        accept={['application/pdf', 'image/jpeg', 'image/png']}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column mt-3'>
                <CustomCheckbox
                  checked={values.cost_analysis_liability_insurance_claim}
                  onChange={(checked) => handleChange('cost_analysis_liability_insurance_claim', checked)}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Insurance Claim?
                  </Typography>
                </CustomCheckbox>
              </div>

              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Company Name</InputLabel>
                <Input
                  name='cost_analysis_liability_insurance_company_name'
                  value={values.cost_analysis_liability_insurance_company_name}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_liability_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Claim Number</InputLabel>
                <Input
                  name='cost_analysis_liability_insurance_claim_number'
                  value={values.cost_analysis_liability_insurance_claim_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_liability_insurance_claim}
                />
              </div>
              <div className='d-flex flex-column mt-3 w-25'>
                <InputLabel>Insurance Policy Number</InputLabel>
                <Input
                  name='cost_analysis_liability_insurance_policy_number'
                  value={values.cost_analysis_liability_insurance_policy_number}
                  onChange={handleChange}
                  disabled={!values.cost_analysis_liability_insurance_claim}
                />
              </div>
              <div className='d-flex gap-3 mt-3 mb-2 align-items-center'>
                <div>
                  <Autocomplete
                    width='200px'
                    labelKey='company_name'
                    placeholder='Customer'
                    label='Select Customer'
                    name='liability_damage_customer'
                    value={values.liability_damage_customer}
                    options={customers}
                    onChange={(e, val) => handleLiabilityCustomerChange(val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='200px'
                    placeholder='Invoice'
                    labelKey='invoice_id'
                    label='Select Open Invoice'
                    loading={loadingInvoices}
                    name='liability_damage_customer'
                    value={values.liability_damage_invoice}
                    options={liabilityInvoices}
                    onChange={(e, val) => handleChange('liability_damage_invoice', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </div>

                <div>
                  <InputWithIcon
                    type='number'
                    name='amount'
                    label='Amount of Invoice'
                    placeholder='0.00'
                    value={values.liability_damage_invoice?.total_amount || ''}
                    disabled
                  />
                </div>

                {!!values.liability_damage_invoice && (
                  <div>
                    <InputLabel>Status</InputLabel>
                    <Chip
                      label={moment().isAfter(values.liability_damage_invoice?.due_date) ? 'PAST DUE' : 'OPEN'}
                      labelColor={
                        moment().isAfter(values.liability_damage_invoice?.due_date) ? palette.red500 : palette.green500
                      }
                      bgColor={
                        moment().isAfter(values.liability_damage_invoice?.due_date) ? palette.red0 : palette.green0
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-column mt-3 w-25'>
          <InputLabel>Total Estimated Costs</InputLabel>

          <InputWithIcon
            type='number'
            name='cost_analysis_total_cost'
            value={values.cost_analysis_total_cost}
            onKeyDown={blockNonPositiveNumbers}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Close
            </Button>
          </div>
          <div className='pagination-btn'>
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save'
              disabled={loading}
              onClick={onSave}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save & Go To List'
              disabled={loading}
              onClick={onSaveAndClose}
            />
          </div>
        </div>
      </Modal.Footer>
      {openAddVendor && (
        <AddVendor open={openAddVendor} onClose={() => setOpenAddVendor(false)} onSuccess={getVendors} />
      )}
    </Form>
  );
};

export default CollisionCost;
