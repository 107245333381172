import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import './DriverDocument.css';
import Add from 'assets/icons/drivers/add.svg';
import Copy from 'assets/icons/drivers/copy.svg';
import More from 'assets/icons/drivers/more.svg';
import {
  driverDocumentData,
  DriverAddDocumentData,
  DriverUpdateDocumentData,
  DriverRemoveDocumentData,
} from 'Api/Driver';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import License from 'assets/icons/drivers/license.svg';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import DriverLicense from '../DriverLicense/DriverLicense';
import UpdateDocument from '../UpdateDocument/UpdateDocument';
import MyDocumentModal from '../AddDocumentModal/AddDocumentModal';
import DriverLicenseComp from './DriverLicense';
import MaterialTableWrapper, { MaterialTableSort } from '../MaterialTableWrapper';

const DriverDocument = ({ driverInfo, updateProfile, loading }) => {
  const { id } = useParams();
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [modalShow, setModalShow] = React.useState(false);
  const [updateModalShow, setUpdateModalShow] = React.useState(false);
  const [driverIndex, setDriverIndex] = React.useState(0);
  const [createLoading, setCreateLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [updateDocumentData, setUpdateDocumentData] = useState([]);

  const showError = (message) => {
    showToaster({ type: 'error', message });
  };

  const onSuccess = (message) => {
    if (message) {
      showToaster({ type: 'success', message });
    }
  };

  function init() {
    driverDocumentData({ driver_id: id, page: 1, itemsPerPage: 25 }).then((res) => {
      setDocuments(res?.data);
    });
  }

  useEffect(() => {
    init();
  }, []);

  const AddUpdateDocuments = async (documentsInfo, type, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);
    if (type === 'add') {
      Promise.allSettled([
        ...(documentsInfo?.addDocument || []).map((item) => {
          const date = item?.certification_exp_date ? moment(item?.certification_exp_date).format('YYYY-MM-DD') : null;
          return DriverAddDocumentData({
            ...item,
            certification_exp_date: item.no_expiry ? null : date,
            no_expiry: !date ? true : item.no_expiry,
          });
        }),
      ])
        .then((reponses) => {
          const updatedDocuments = [...documents];
          reponses.forEach((item) => {
            if (item.status === 'fulfilled') {
              updatedDocuments.push(item.value.data);
            } else if (item.status === 'rejected') {
              showError(getErrorMessage(item));
            }
          });
          setDocuments([...updatedDocuments]);
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          setCreateLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setCreateLoading(false);
        });
    } else {
      const { index, certification_exp_date, tableData, no_expiry, ...info } = documentsInfo;
      const date = certification_exp_date ? moment(certification_exp_date).format('YYYY-MM-DD') : null;
      DriverUpdateDocumentData(
        {
          ...info,
          type: info.type || 1,
          certification_exp_date: no_expiry ? null : date,
        },
        documentsInfo.id
      )
        .then(() => {
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          init();
          setCreateLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setCreateLoading(false);
        });
    }
  };

  const deleteDocument = (item, index) => {
    DriverRemoveDocumentData(item.id, { type: item.type })
      .then(() => {
        const tempDoc = [...documents];
        tempDoc.splice(index, 1);
        setDocuments(tempDoc);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
      });
  };

  async function downloadImage(file) {
    if (file.includes('.pdf')) {
      fetch(file, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'driver.pdf';
          link.click();
        });
    } else {
      fetch(file, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/png' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'driver.png';
          link.click();
        });
    }
  }

  function sortingQuery(field, sortBy) {
    const sortField = `sort[][${field}]`;

    getSortedDocuments(sortField, sortBy);
  }

  const getSortedDocuments = (sortField, sortBy) => {
    driverDocumentData({ driver_id: id, page: 1, itemsPerPage: 25, sortField, sortBy }).then((res) => {
      setDocuments(res?.data);
    });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        title: <MaterialTableSort title='NAME' field='name' sortingQuery={sortingQuery} />,
        render: (rowData) => (
          <div onClick={() => setDriverIndex(rowData?.tableData?.id)}>
            <div className='document-details-wrap'>
              <p className='document-details-name'>{rowData?.name}</p>
              <img src={Copy} className='hidden-icon' alt='Copy' />
            </div>
          </div>
        ),
      },
      {
        field: 'certification_exp_date',
        title: <MaterialTableSort title='EXPIRY' field='certification_exp_date' sortingQuery={sortingQuery} />,
        render: (rowData) => (
          <div onClick={() => setDriverIndex(rowData?.tableData?.id)}>
            <div className='document-details-wrap-right'>
              <p className='document-details-value'>
                {' '}
                {rowData?.certification_exp_date
                  ? formatDate(rowData?.certification_exp_date || new Date())
                  : 'No Expiry'}
              </p>
              <div className='hidden-icon'>
                <Dropdown className='driver-document-document' menuRole='menu' drop='drop'>
                  <Dropdown.Toggle className='menu-drop' id='dropdown-basic'>
                    <img src={More} alt='' className='hidden icon' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-menu-contain'>
                    <Dropdown.Item
                      className='drop-text-contain'
                      href='#/action-1'
                      onClick={() => downloadImage(rowData?.certificate_path)}
                    >
                      <p className='edit-text'>Download</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='drop-text-contain'
                      href='#/action-2'
                      onClick={() => {
                        setUpdateDocumentData({ ...rowData, index: rowData?.tableData?.id });
                        setUpdateModalShow(true);
                      }}
                    >
                      <p className='edit-text'>Edit</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='drop-text-contain'
                      href='#/action-3'
                      onClick={() => deleteDocument(rowData, rowData?.tableData?.id)}
                    >
                      <p className='delete-text'>Delete</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ),
      },
    ];
  }, [use, documents]);

  return (
    <div
      className='Driver-document-tab  driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div className='d-flex flex-column gap-5' style={{ width: '33%' }}>
        <div
          className='driver-document'
          style={{
            borderColor: use(palette.gray50, palette.darkborder),
            backgroundColor: use(palette.white, palette.dark800),
            width: '100%',
          }}
        >
          {modalShow && (
            <MyDocumentModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              modalShow={modalShow}
              onSuccess={onSuccess}
              showError={showError}
              loading={createLoading}
              AddUpdateDocuments={AddUpdateDocuments}
              id={id}
            />
          )}
          {updateModalShow && (
            <UpdateDocument
              show={updateModalShow}
              onHide={() => setUpdateModalShow(false)}
              updateDocumentData={updateDocumentData}
              setUpdateDocumentData={setUpdateDocumentData}
              onSuccess={onSuccess}
              loading={createLoading}
              AddUpdateDocuments={AddUpdateDocuments}
              showError={showError}
              id={id}
            />
          )}

          <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
            <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
              Documents
            </p>
            <PrimaryBtn
              icon={Add}
              style={{ color: use(palette.gray700, palette.gray200) }}
              title='Add Document'
              onClick={() => setModalShow(true)}
            />
          </div>
          <div className='document-details'>
            <MaterialTableWrapper
              data={documents}
              style={{ backgroundColor: use(palette.white, palette.dark800), maxHeight: 'maxContent' }}
              columns={columns}
            />
          </div>
        </div>

        <div
          className='generalInformation driver-style-wrap'
          style={{
            backgroundColor: palette.white,
            borderColor: palette.gray50,
            width: '100%',
          }}
        >
          <DriverLicenseComp driverInfo={driverInfo} updateGeneralProfile={updateProfile} loading={loading} />
        </div>
      </div>
      <DriverLicense
        picture={documents[driverIndex]?.certificate_path ? documents[driverIndex]?.certificate_path : License}
      />
    </div>
  );
};

export default DriverDocument;
