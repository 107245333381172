import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Check } from 'assets/icons/checkGreen.svg';
import Divider from 'common/Divider';
import ErrorMessage from 'common/ErrorMessage';
import PlanCard from 'components/Billing/PlanCard';
import { Typography } from 'components/Typography';
import ShippingAddress from 'components/ShippingAddress';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { ADDON_PLAN_ID, formatNumber, palette } from 'utils/constants';
import { getBillingDetail, updateExpiredCustomPlan } from 'store/actions/billing.actions';
import { setBillingDetail, toggleUpDownGradeModalIsVisible } from 'store/reducers/billing.reducer';
import { getPremiumPlans } from 'Api/Onboarding';
import { updatePlans } from 'store/reducers/onboarding.reducer';
import { planType } from 'components/customerOnboardingComponents/data';
import styles from 'components/Billing/UpgradeModal/UpgradeModal.module.css';
import { getHardwareList } from 'Api/Hardware';
import { GetBillingDetail, getPrices, GetPurchaseAddonList } from 'Api/Billing';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';
import Addons from './Addons';
import Hardware from './Hardware';
import OrderSummary from './OrderSummary';
import PaymentMethod from './PaymentMethod';
// import UpdatePaymentMethod from './UpdatePaymentMethod';
import { validationSchema1, validationSchema2 } from './validationSchema';
import { SFormWrapper } from './SelectPlanModal.styles';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SelectPlanModal = ({ onSuccess, onCancelAccount }) => {
  const errorRef = useRef(null);
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const { paymentRequired, paymentMethod, billingDetail, upDownGradeModalIsVisible } = useSelector(
    (state) => state.billing
  );
  const { plans } = useSelector((state) => state.onboarding);
  const { value: userData } = useAuth();
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const { customer_plan, promo_code } = billingDetail || {};

  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [numberOfUsers, setNumberOfUsers] = useState(billingDetail?.customer_plan?.user_licence_count);
  const [onCredit] = useState(true);
  const [showRequired, setShowRequired] = useState(false);
  const [showCouponErr, setShowCouponErr] = useState(false);
  const [coupon, setCoupon] = useState(promo_code?.code || '');
  const [hardware, setHardware] = useState([]);
  const [addons, setAddons] = useState([]);
  const [openHardware, setOpenHardware] = useState(false);
  const [selectedHardware, setSelectedHardware] = useState([]);
  const [openAddon, setOpenAddon] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState(billingDetail?.customer_plan?.plan);
  const [prices, setPrices] = useState(null);
  // const [updatePaymentMethodType, setUpdatePaymentMethodType] = useState(null);

  const totalPlanAndAddons = (prices?.plan_price?.total_amount || 0) + (prices?.addon_prices?.total_amount || 0);
  const processingFee = !Number(totalPlanAndAddons) ? 0 : Number(totalPlanAndAddons || 0) * 0.0299 + 0.3;
  const financingDisabled = selectedPlanType?.duration === 'monthly' || Number(totalPlanAndAddons) < 5000;
  const isBroker = userData?.user?.customer?.customer_type === 'carrier_broker';

  const onSubmit = (values) => {
    if (!values.allow) {
      setShowRequired(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setLoading(true);
    dispatch(
      updateExpiredCustomPlan(
        {
          plan: {
            plan_type_membership_id: selectedPlanType.membership_plan_id,
            license_count: values.number_of_users,
          },
          add_on: selectedAddons
            .filter((i) => !!Number(i.quantity))
            .map((item) => ({
              id: item.activePlan.id,
              quantity: Number(item.quantity),
              add_custom_fields: item.add_custom_fields,
            })),
          hardware: {
            shipping_address1: values?.shipping_address1 || '',
            shipping_address2: values?.shipping_address2 || '',
            shipping_city: values?.shipping_city?.id || null,
            shipping_state: values?.shipping_state?.id || null,
            shipping_country: values?.shipping_country?.id || null,
            shipping_zip: values?.shipping_zip || '',
            hardware: selectedHardware.filter((i) => !!Number(i.quantity)).map((item) => item.id),
            quantity: selectedHardware.filter((i) => !!Number(i.quantity)).map((item) => Number(item.quantity)),
            amount: prices.hardware_price?.total_amount || 0,
          },
          hardware_state: values?.shipping_state?.id || null,
          promo_code: values?.coupon_code ? values?.coupon_code : '',
          payment_method: selectedPaymentMethod,
        },
        () => {
          if (accountStatus?.account_status === 'trialexpired') {
            setTimeout(() => {
              GetBillingDetail()
                .then((res) => {
                  dispatch(setBillingDetail({ ...res.data?.billing_details, promo_code: res.data.promo_code }));
                  if (res.data?.customer_status_data) {
                    localStorage.setItem('accountStatus', JSON.stringify(res.data.customer_status_data));
                  }
                  dispatch(toggleUpDownGradeModalIsVisible(false));
                  showToaster({ type: 'success', message: 'Plan has been successfully updated!' });
                  onSuccess();
                })
                .catch(() => {
                  /* empty */
                });
              setLoading(false);
            }, 20000);
          } else {
            dispatch(getBillingDetail());
            dispatch(toggleUpDownGradeModalIsVisible(false));
            showToaster({ type: 'success', message: 'Plan has been successfully updated!' });
            onSuccess();
            setLoading(false);
          }
        },
        (e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
          setLoading(false);
        }
      )
    );
  };

  const { values, setFieldValue, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      number_of_users: customer_plan?.user_licence_count ? customer_plan?.user_licence_count : 0,
      coupon_code: promo_code?.code || '',
      shipping_address1: '',
      shipping_address2: '',
      shipping_country: null,
      shipping_state: null,
      shipping_city: null,
      shipping_zip: '',
      terms_and_conditions: false,
      privacy_policy: false,
      billing_policy: false,
    },
    onSubmit,
    validationSchema: selectedHardware.length ? validationSchema1 : validationSchema2,
  });

  const getPlans = async () => {
    try {
      const response = await getPremiumPlans({ customer_type: userData?.user?.customer?.customer_type || 'carrier' });
      const data = response.map((plan) => {
        const sortedPlanTypes = plan.plan_types.sort((a, b) => a.amount - b.amount);
        return {
          ...plan,
          plan_types: sortedPlanTypes.filter(
            (item) =>
              !(
                (!!paymentRequired?.hide_yearly_plan && item.duration === 'yearly') ||
                (!!paymentRequired?.hide_3_year_plan && item.duration === '3-Year')
              )
          ),
        };
      });
      if (data[0].plan_types?.length === 1) {
        setSelectedPlanType(data[0].plan_types[0]);
      }
      dispatch(updatePlans(data));
    } catch (e) {
      // Do nothing
    }
  };

  const getHardware = async () => {
    try {
      const { data } = await getHardwareList({ itemsPerPage: 1000 });
      setHardware(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getAddons = async () => {
    try {
      const { data } = await GetPurchaseAddonList();
      setAddons(data);

      if (isBroker) {
        const brokerageAddon = data.find((item) => item.plan_id === ADDON_PLAN_ID.BROKERAGE);
        setSelectedAddons((prevState) => [
          ...prevState,
          { ...brokerageAddon, quantity: 1, activePlan: brokerageAddon.plans[0] },
        ]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getPriceData = async () => {
    try {
      const body = {
        plan: {
          plan_type_membership_id: selectedPlanType.membership_plan_id,
          license_count: values.number_of_users,
        },
        add_on: selectedAddons.map((item) => ({ id: item.activePlan?.id, quantity: Number(item.quantity || 0) })),
        hardware: selectedHardware.map((item) => ({ id: item.id, quantity: Number(item.quantity) })),
        hardware_state: userData?.user?.state?.id,
        promo_code: coupon || null,
      };

      const data = await getPrices(body);
      setPrices(data);

      setShowCouponErr(!!coupon && !data.promo_code);
    } catch (e) {
      // Do nothing
    }
  };

  // const onUpdateMethodSuccess = () => {
  //   dispatch(getPaymentMethod());
  //   setUpdatePaymentMethodType(null);
  // };

  const onPaymentMethodChange = (method) => {
    // if (method === 'credit-card') {
    //   if (paymentMethod?.payment_method !== 'credit-card') {
    //     showToaster({
    //       type: 'success',
    //       message: 'You need to change your default payment method to credit card to proceed!',
    //     });
    //     setUpdatePaymentMethodType('credit-card');
    //     return;
    //   }
    //   setOnCredit(true);
    // }
    //
    // if (method === 'ach') {
    //   if (paymentMethod?.payment_method !== 'ach') {
    //     showToaster({
    //       type: 'success',
    //       message: 'You need to change your default payment method to ACH to proceed!',
    //     });
    //     setUpdatePaymentMethodType('ach');
    //     return;
    //   }
    //   setOnCredit(false);
    // }

    setSelectedPaymentMethod(method);
  };

  const selectPlan = (plan) => {
    const activePlan = billingDetail?.customer_plan?.plan;
    const goBackToMonthly =
      billingDetail?.customer_plan?.customer_status === 'active' && plan.amount < activePlan?.amount;

    if (goBackToMonthly) {
      showToaster({
        type: 'error',
        message: `You have pre-paid for ${activePlan?.duration} recurring and cannot revert to ${plan?.duration} until your agreement has expired.`,
      });
      return;
    }

    setSelectedPlanType(plan);
  };

  const onApplyCoupon = (isCancel) => {
    if (isCancel) {
      setFieldValue('coupon_code', '');
      setCoupon('');
      setShowCouponErr(false);
    } else {
      setCoupon(values.coupon_code);
    }
  };

  const onLicenseCountChange = (value) => {
    setFieldValue('number_of_users', value);
    setNumberOfUsers(+value);
  };

  const onChangeHardwareQuantity = (hardware, quantity) => {
    setSelectedHardware((prevState) =>
      prevState.map((item) => (item.id === hardware.id ? { ...item, quantity: quantity || 0 } : item))
    );
  };

  const onChangeAddonQuantity = (addon, quantity) => {
    setSelectedAddons((prevState) =>
      prevState.map((item) => (item.id === addon.id ? { ...item, quantity: quantity || 0 } : item))
    );
  };

  const onDeleteHardware = (hardware) => {
    setSelectedHardware((prevState) => prevState.filter((item) => item.id !== hardware.id));
  };

  const onDeleteAddon = (addon) => {
    setSelectedAddons((prevState) => prevState.filter((item) => item.id !== addon.id));
  };

  const onContinue = () => {
    if (!Number(numberOfUsers)) {
      showToaster({ type: 'error', message: 'License count cannot be empty!' });
      return;
    }
    if (!values?.terms_and_conditions || !values?.privacy_policy || !values?.billing_policy) {
      errorRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    handleSubmit();
  };

  useEffect(() => {
    getPriceData();
  }, [
    selectedAddons,
    selectedHardware,
    numberOfUsers,
    selectedPaymentMethod,
    selectedPlanType.membership_plan_id,
    coupon,
  ]);

  useEffect(() => {
    dispatch(getBillingDetail());
    getPlans();
    getHardware();
    getAddons();
  }, []);

  useEffect(() => {
    if (selectedPaymentMethod === 'financing' && financingDisabled) {
      setSelectedPaymentMethod('');
    }
  }, [selectedPaymentMethod, financingDisabled]);

  return (
    <SFormWrapper>
      <div className={styles.modal_body_wrapper}>
        <div className={`${styles.top2} mt-4`}>
          <div className={styles.top2_item}>
            <div className={styles.mini_row}>
              <Check className={styles.check} />
            </div>
            <div className={styles.mini_row}>
              <Typography className={styles.text} variant='c1' style={{ color: palette.indigo500 }}>
                Support Included
              </Typography>
            </div>
          </div>
          <div className={styles.top2_item}>
            <div className={styles.mini_row}>
              <Check className={styles.check} />
            </div>
            <div className={styles.mini_row}>
              <Typography className={styles.text} variant='c1' style={{ color: palette.indigo500 }}>
                Billed Per User (Driver & Staff)
              </Typography>
            </div>
          </div>
          <div className={styles.top2_item}>
            <div className={styles.mini_row}>
              <Check className={styles.check} />
            </div>
            <div className={styles.mini_row}>
              <Typography className={styles.text} variant='c1' style={{ color: palette.indigo500 }}>
                Cancel Anytime
              </Typography>
            </div>
          </div>
        </div>
        {plans?.[0]?.plan_types?.length > 1 && (
          <div className={styles.card_block}>
            {plans?.[0]?.plan_types?.map((item) => (
              <PlanCard plan={item} isActive={selectedPlanType?.id === item.id} selectPlan={selectPlan} />
            ))}
          </div>
        )}

        <div className={styles.paymentType_title}>
          <div className='line-wrapper'>
            <Typography variant='h5'>Payment Method</Typography>
            <Typography variant='h5' className='ms-5'>
              Order Summary
            </Typography>
            <div className='d-flex align-items-center gap-3'>
              <span className='navigate-button' onClick={() => setOpenAddon(true)}>
                Need Addons?
              </span>
              <span className='navigate-button' onClick={() => setOpenHardware(true)}>
                Need Hardware?
              </span>
            </div>
          </div>
        </div>

        <div className='d-flex gap-4'>
          <div>
            <PaymentMethod
              selectedPaymentMethod={selectedPaymentMethod}
              onPaymentMethodChange={onPaymentMethodChange}
              financingDisabled={financingDisabled}
              selectedPlanType={selectedPlanType}
              totalPlanAndAddons={totalPlanAndAddons}
              processingFee={processingFee}
              onCredit={onCredit}
              prices={prices}
            />
            {!!selectedHardware?.length && (
              <div style={{ padding: '0 20px', marginTop: '24px' }}>
                <ShippingAddress values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
              </div>
            )}
          </div>
          <OrderSummary
            values={values}
            handleChange={handleChange}
            onApplyCoupon={onApplyCoupon}
            showCouponErr={showCouponErr}
            selectedAddons={selectedAddons}
            selectedPlanType={selectedPlanType}
            selectedHardware={selectedHardware}
            paymentMethod={selectedPaymentMethod}
            prices={prices}
            numberOfUser={numberOfUsers}
            onLicenseCountChange={onLicenseCountChange}
            onChangeAddonQuantity={onChangeAddonQuantity}
            onChangeHardwareQuantity={onChangeHardwareQuantity}
            onDeleteHardware={onDeleteHardware}
            onDeleteAddon={onDeleteAddon}
          />
        </div>
        <div className={`${styles.bottom} mt-0`}>
          <div className={styles.info_block}>
            <div className={styles.info_item}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Per User Cost
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  $
                  {formatNumber(
                    Number(selectedPlanType?.amount || 0) / planType[selectedPlanType?.duration].monthsCount
                  )}
                </Typography>
                <span className={styles.duration}>MONTHLY</span>
              </div>
            </div>
            {!!prices?.promo_code && (
              <div className={styles.item_line}>
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  Per User Cost (After Promo)
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    $
                    {formatNumber(
                      (Number(selectedPlanType?.amount || 0) / planType[selectedPlanType?.duration].monthsCount) *
                        (1 - (prices.promo_code?.value || 0) / 100)
                    )}
                  </Typography>
                  <span className={styles.duration}>MONTHLY</span>
                </div>
              </div>
            )}
            <div className={styles.item_line}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Recurring Total
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  ${formatNumber(totalPlanAndAddons - Number(prices?.addon_prices?.total_setup_fees || 0))}
                </Typography>
                <span className={styles.duration}>{selectedPlanType?.duration?.toUpperCase()}</span>
              </div>
            </div>
            <div className={styles.item_line}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Billed Today
              </Typography>
              <div>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  ${formatNumber(totalPlanAndAddons + (selectedPaymentMethod === 'credit-card' ? processingFee : 0))}
                </Typography>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              By clicking continue, you must also check and agree to the following below:
            </Typography>
            <div className='mb-1'>
              <CustomCheckbox
                name='terms_and_conditions'
                checked={!!values.terms_and_conditions}
                onChange={(checked) => handleChange('terms_and_conditions', checked)}
              >
                <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 8 }}>
                  I agree to the{' '}
                  <a
                    className='AccountSummaryTermsConditions'
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.truckindigital.com/legal/terms-and-conditions'
                  >
                    Terms & Conditions
                  </a>
                </Typography>
              </CustomCheckbox>
            </div>
            <div className='mb-1'>
              <CustomCheckbox
                name='privacy_policy'
                checked={!!values.privacy_policy}
                onChange={(checked) => handleChange('privacy_policy', checked)}
              >
                <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 8 }}>
                  I agree to the{' '}
                  <a
                    className='AccountSummaryTermsConditions'
                    href='https://www.truckindigital.com/legal/privacy-policy'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Privacy Policy.
                  </a>
                </Typography>
              </CustomCheckbox>
            </div>
            <div className='mb-1'>
              <CustomCheckbox
                name='billing_policy'
                checked={!!values.billing_policy}
                onChange={(checked) => handleChange('billing_policy', checked)}
              >
                <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 8 }}>
                  I agree to the{' '}
                  <a
                    className='AccountSummaryTermsConditions'
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.truckindigital.com/legal/billing-policy'
                  >
                    Billing Policy.
                  </a>
                </Typography>
              </CustomCheckbox>
            </div>
            <div>
              <CustomCheckbox
                name='allow'
                checked={!!values.allow}
                onChange={(checked) => {
                  setFieldValue('allow', checked);
                  setShowRequired(false);
                }}
              >
                <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8, color: palette.gray700 }}>
                  I authorize Truckin Digital to bill my payment method on {formatDate(moment())} and recurring each
                  month for ${formatNumber(totalPlanAndAddons - Number(prices?.addon_prices?.total_setup_fees || 0))}.
                </Typography>
              </CustomCheckbox>
            </div>
            {(showRequired ||
              touchedErrors?.terms_and_conditions ||
              touchedErrors?.privacy_policy ||
              touchedErrors?.billing_policy) && <ErrorMessage error='Required' />}
            <div ref={errorRef} />
            <Divider margin='12px 0 0' />
          </div>

          <div className='legend'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              All prices are in US Dollars (USD).
            </Typography>
            <Divider margin='4px 0' />
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              {planType[selectedPlanType.duration]?.infoText1}
            </Typography>
            <Divider margin='4px 0' />
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              {planType[selectedPlanType.duration]?.infoText2}
            </Typography>
            {paymentMethod === 'credit-card' && (
              <>
                <Divider margin='4px 0' />
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  Your credit card will be charged for $1.00 authorization, and refunded immediately. The Order License
                  Agreement and Invoice will be emailed to you separately.
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer_buttons}>
        {paymentRequired?.is_require_payment_enable === 0 && accountStatus?.account_status === 'trialexpired' ? (
          <CustomButton
            type='secondary'
            onClick={onCancelAccount}
            title='Cancel account'
            styleButton={{ margin: 10, padding: '6px 12px' }}
            styleTitle={{
              fontSize: 12,
              marginTop: 1,
            }}
          />
        ) : (
          <CustomButton
            type='secondary'
            onClick={() => dispatch(toggleUpDownGradeModalIsVisible(!upDownGradeModalIsVisible))}
            title='Cancel'
            styleButton={{ margin: 10, padding: '6px 12px' }}
            styleTitle={{
              fontSize: 12,
              marginTop: 1,
            }}
          />
        )}
        <CustomButton
          type='primary'
          onClick={onContinue}
          title='Continue'
          disabled={!selectedPaymentMethod}
          styleButton={{ margin: '10px 4px 10px 0', padding: '6px 12px' }}
          styleTitle={{
            fontSize: 12,
            marginTop: 1,
          }}
        />
      </div>
      {/* <Elements stripe={stripePromise}> */}
      {/*  {!!updatePaymentMethodType && ( */}
      {/*    <UpdatePaymentMethod */}
      {/*      open={!!updatePaymentMethodType} */}
      {/*      onClose={() => setUpdatePaymentMethodType(null)} */}
      {/*      type={updatePaymentMethodType} */}
      {/*      onSuccess={onUpdateMethodSuccess} */}
      {/*      onCreditCardSuccess={() => { */}
      {/*        setSelectedPaymentMethod('credit-card'); */}
      {/*        setOnCredit(true); */}
      {/*      }} */}
      {/*      onAchSuccess={() => { */}
      {/*        setSelectedPaymentMethod('ach'); */}
      {/*        setOnCredit(false); */}
      {/*      }} */}
      {/*    /> */}
      {/*  )} */}
      {/* </Elements> */}
      {openHardware && (
        <Hardware
          open={openHardware}
          onClose={() => setOpenHardware(false)}
          hardware={hardware}
          selectedHardware={selectedHardware}
          handleSubmit={setSelectedHardware}
        />
      )}
      {openAddon && (
        <Addons
          open={openAddon}
          onClose={() => setOpenAddon(false)}
          addons={addons}
          selectedAddons={selectedAddons}
          handleSubmit={setSelectedAddons}
          selectedPlanType={selectedPlanType}
          prices={prices}
        />
      )}
      <SpinnerLoadingMultipleTexts
        isVisible={loading}
        fullScreen
        animationDuration={8}
        textStyle={{ fontSize: '28px', fontFamily: 'Inter, sans-serif', fontWeight: 700, color: palette.gray700 }}
        texts={[
          'Preparing your account for activation..',
          'Please do not close or refresh this page..',
          'Please review the emails sent to you. One contains the invoice for payment, and the other includes the agreement for finalizing the activation..',
        ]}
      />
    </SFormWrapper>
  );
};

export default SelectPlanModal;
