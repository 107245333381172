import React, { useMemo } from 'react';
import moment from 'moment';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import sm from './FilterPlaner.module.css';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import SearchPlaner from './SearchPlaner';
import CustomDatePicker from '../CustomDatePicker';

const FilterPlaner = ({
  filter,
  updateFilter,
  customers,
  stopPoints,
  stopPointCondition,
  isDriverFilter,
  dateRangeStopPoint,
  setDateRangeLocation,
  equipments,
  drivers,
  carriers,
  dateRangeLocation,
  onChangeAll,
  onChangeCustomers,
  onChangeEquipment,
  onChangeDriver,
  onChangeCarrier,
  onChangeStopPointSelect,
  onDeleteStopPointSelect,
  onChangeDatePickerStopPoint,
  onChangeLocationValues,
  onChangeLocationDatePicker,
}) => {
  const { use } = useTheme();
  const searchValue = filter.searchBy.value;
  const stopPointsAllValues = filter.stopPointAll;

  const valueDatePicker = useMemo(() => {
    const fromValue = stopPointsAllValues?.stop_point_scheduled_from?.searchValue;
    const toValue = stopPointsAllValues?.stop_point_scheduled_to?.searchValue;
    const isValidTo = !moment(toValue).isValid();

    if (fromValue && isValidTo) {
      return fromValue;
    }
    return fromValue && toValue ? `${fromValue} - ${toValue}` : '';
  }, [filter?.stopPointAll]);

  const valueArrCustomSelect = useMemo(() => {
    return filter.customerSelectData.map((el) => el.id);
  }, [filter]);

  const valueArrEquipmentSelect = useMemo(() => {
    if (searchValue === 'equipment') {
      return filter.equipmentSelectedData.map((el) => el.id);
    }
  }, [filter]);

  const valueArrDriversSelect = useMemo(() => {
    if (searchValue === 'driver') {
      return filter.driverSelectedData.map((el) => el.id);
    }
  }, [filter]);

  const valueArrCarriersSelect = useMemo(() => {
    if (searchValue === 'carrier') {
      return filter.carrierSelectedData.map((el) => el.id);
    }
  }, [filter]);

  const styles = useMemo(() => {
    return {
      dropDown: {
        height: 30,
        marginTop: 1,
        width: 261,
        borderRadius: '0 6px 6px 0',
      },
      isSearchable: {
        width: 261,
        marginTop: 1,
        height: 30,
        borderRadius: '0 6px 6px 0',
      },
      date: {
        minWidth: 220,
        margin: 0,
        height: 30,
        transform: 'translateY(1px)',
        borderRadius: 6,
        justifyContent: 'flex-start',
      },
      dateLabel: {
        marginTop: 0,
      },
    };
  }, [palette, use]);

  return searchValue === 'customers' ? (
    <CustomSelect
      multi
      onChange={onChangeCustomers}
      options={customers}
      isSearchable
      styles={styles.dropDown}
      dropDownStyles={{ flexGrow: 0 }}
      valueMultiArr={valueArrCustomSelect}
      isSearchableStyles={styles.isSearchable}
    />
  ) : searchValue === 'equipment' ? (
    <CustomSelect
      multi
      onChange={onChangeEquipment}
      options={equipments}
      isSearchable
      styles={styles.dropDown}
      dropDownStyles={{ flexGrow: 0 }}
      valueMultiArr={valueArrEquipmentSelect}
      isSearchableStyles={styles.isSearchable}
    />
  ) : searchValue === 'driver' ? (
    <CustomSelect
      multi
      onChange={onChangeDriver}
      options={drivers}
      isSearchable
      styles={styles.dropDown}
      dropDownStyles={{ flexGrow: 0 }}
      valueMultiArr={valueArrDriversSelect}
      isSearchableStyles={styles.isSearchable}
    />
  ) : searchValue === 'carrier' ? (
    <CustomSelect
      multi
      onChange={onChangeCarrier}
      options={carriers}
      isSearchable
      styles={styles.dropDown}
      dropDownStyles={{ flexGrow: 0 }}
      valueMultiArr={valueArrCarriersSelect}
      isSearchableStyles={styles.isSearchable}
    />
  ) : searchValue === 'stop_point' ? (
    <div className={sm.stop_point_wrapper}>
      <CustomSelect
        deleted
        options={stopPoints}
        isSearchable
        styles={styles.dropDown}
        onChange={onChangeStopPointSelect}
        onDelete={onDeleteStopPointSelect}
        dropDownStyles={{ flexGrow: 0 }}
        isSearchableStyles={styles.isSearchable}
        deleteWidthCondition={stopPointCondition}
        defaultActiveValue={filter.stopPointAll.stop_point}
      />

      <div className={sm.miles_zipCode_wrapper}>
        <CustomDatePicker
          selectsRange
          renderStart={false}
          style={styles.date}
          valueToShow={valueDatePicker}
          shouldCloseOnSelect={false}
          labelStyle={styles.dateLabel}
          endDate={dateRangeStopPoint[1]}
          startDate={dateRangeStopPoint[0]}
          placeholderText='MM/DD/YYYY - MM/DD/YYYY'
          onChange={(dates) => onChangeDatePickerStopPoint(dates)}
        />
      </div>
    </div>
  ) : (
    <SearchPlaner
      filter={filter}
      updateFilter={updateFilter}
      isDriverFilter={isDriverFilter}
      dateRangeLocation={dateRangeLocation}
      setDateRangeLocation={setDateRangeLocation}
      onChangeAll={onChangeAll}
      onChangeLocationValues={onChangeLocationValues}
      onChangeLocationDatePicker={onChangeLocationDatePicker}
    />
  );
};

export default FilterPlaner;
