import moment from 'moment';

export const initialStats = {
  driverInspections: {},
  vehicleInspections: {},
  statsSection: {
    'Inspections total (All Time)': {},
    'Violation total (All Time)': {},
    'Crashes (All Time)': {},
  },
};

const transformArray = (inputArray, isDriver) => {
  const outputArray = [];

  let currentMonth = '';
  let inspectionTotal = 0;
  let outOfServiceTotal = 0;

  for (const entry of inputArray) {
    const entryDate = moment(entry.month).toDate();
    entryDate.setDate(5);
    const entryMonth = entryDate.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (currentMonth !== entryMonth) {
      if (currentMonth !== '') {
        outputArray.push({
          month: currentMonth,
          inspection: inspectionTotal,
          outOfService: outOfServiceTotal,
        });
      }

      currentMonth = entryMonth;
      inspectionTotal = 0;
      outOfServiceTotal = 0;
    }

    inspectionTotal += isDriver ? entry.driver_inspection_total_count : entry.vehicle_inspection_total_count;
    outOfServiceTotal += isDriver
      ? entry.driver_inspection_out_of_service_count
      : entry.vehicle_inspection_out_of_service_count;
  }

  // Push the last month's totals
  if (currentMonth !== '') {
    outputArray.push({
      month: currentMonth,
      inspection: inspectionTotal,
      outOfService: outOfServiceTotal,
    });
  }

  return outputArray;
};

const getTotalCount = (data, field) => {
  return data.reduce((a, b) => a + b[field], 0);
};

export const statsDataConverter = (data) => {
  const { graphs, inspections, violations, crashes, total_counts } = data || {};

  // Driver Graph
  const driverData = transformArray(graphs.inspections, true);
  const driverTotal = getTotalCount(graphs.inspections, 'driver_inspection_total_count');
  const driverOutOfServiceTotal = getTotalCount(graphs.inspections, 'driver_inspection_out_of_service_count');

  // Vehicle Graph
  const vehicleData = transformArray(graphs.vehicles);
  const vehicleTotal = getTotalCount(graphs.vehicles, 'vehicle_inspection_total_count');
  const vehicleOutOfServiceTotal = getTotalCount(graphs.vehicles, 'vehicle_inspection_out_of_service_count');

  // Unsafe Driving
  const unsafeTotal = total_counts?.inspections || 0;
  const unsafeCurrentMonth = inspections?.find(
    (el) => moment(el.month).get('month') === moment().get('month')
  )?.total_count;

  const unsafePrevMonth = inspections?.find(
    (el) => moment(el.month).get('month') === moment().subtract(1, 'month').get('month')
  )?.total_count;

  const unsafeDiff = (unsafeCurrentMonth || 0) - (unsafePrevMonth || 0);

  // Hours of Service
  const hosTooal = total_counts?.violations || 0;
  const hosCurrentMonth = violations?.find(
    (el) => moment(el.month).get('month') === moment().get('month')
  )?.total_count;
  const hosPrevMonth = violations?.find(
    (el) => moment(el.month).get('month') === moment().subtract(1, 'month').get('month')
  )?.total_count;
  const hosDiff = (hosCurrentMonth || 0) - (hosPrevMonth || 0);

  // Crashes
  const crashesTotal = total_counts?.crashes || 0;
  const crashesCurrentMonth =
    crashes.find((el) => moment(el.month).get('month') === moment().get('month'))?.total_count || 0;
  const crashesPrevMonth =
    crashes?.find((el) => moment(el.month).get('month') === moment().subtract(1, 'month').get('month'))?.total_count ||
    0;
  const crashesDiff = (crashesCurrentMonth || 0) - (crashesPrevMonth || 0);

  return {
    driverInspections: {
      monthsData: driverData,
      totalAmount: driverTotal,
      outOfServiceAmount: driverOutOfServiceTotal,
    },
    vehicleInspections: {
      monthsData: vehicleData,
      totalAmount: vehicleTotal,
      outOfServiceAmount: vehicleOutOfServiceTotal,
    },
    statsSection: {
      'Inspections total (All Time)': { amountDiff: unsafeDiff, currentMonth: unsafeTotal || 0 },
      'Violation total (All Time)': { amountDiff: hosDiff, currentMonth: hosTooal || 0 },
      'Crashes (All Time)': { amountDiff: crashesDiff, currentMonth: crashesTotal || 0 },
    },
  };
};
