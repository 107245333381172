import React from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { Typography } from '../Typography';

const typeMapper = {
  Capture: { color: '#14804A', bgColor: '#E1FCEF' },
  Refund: { color: palette.red500, bgColor: palette.red0 },
  'Cash Advance': { color: palette.orange500, bgColor: palette.orange0 },
  SUCCEEDED: { color: palette.orange500, bgColor: palette.orange0 },
};

export const initialFilters = {
  page: 1,
  page_size: 10,
  merchant: null,
  category: null,
  status: null,
  cardHolders: [],
  providers: [],
};

export const serviceProviders = [
  { title: 'WEX EFS Fleet Card', id: 44 },
  { title: 'Truckin Digital Cash Card', id: 222 }, // Update id when ready
];

export const transactionStatuses = [
  { id: 1, name: 'Capture' },
  { id: 2, name: 'Cash Advance' },
  { id: 3, name: 'Declined' },
  { id: 4, name: 'Load Cash' },
];

export const filterTableTop = [
  {
    key: 'all',
    label: 'All',
    value: '',
    id: '',
    count: 0,
  },
  {
    key: 'driver',
    label: 'Driver',
    value: 'driver',
    id: 'driver',
    count: 0,
  },
  {
    key: 'staff',
    label: 'Staff',
    value: 'staff',
    id: 'staff',
    count: 0,
  },
];

const Title = ({ title }) => {
  return <Typography variant='overLine'>{title}</Typography>;
};

export const useColumns = ({ onViewReceipt }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'service',
      title: <Title title='SERVICE' />,
      render: (row) =>
        row.provider_logo ? (
          <img src={row.provider_logo} alt='card logo' height={18} />
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), marginLeft: '24px' }}>
            -
          </Typography>
        ),
    },
    {
      field: 'card',
      title: <Title title='CARD' />,
      render: (row) =>
        row.card_id && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              <span style={{ fontSize: '8px' }}>●●●●</span> {row.card_id.slice(-4)}
            </Typography>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {row?.card?.exp}
            </Typography>
          </div>
        ),
    },
    {
      field: 'date',
      title: <Title title='DATE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.date ? formatDate(row.date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'vendor',
      title: <Title title='VENDOR' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.vendor?.name || '-'}
        </Typography>
      ),
    },
    {
      field: 'reference',
      title: <Title title='REFERENCE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.invoice || '-'}
        </Typography>
      ),
    },
    {
      field: 'category',
      title: <Title title='CATEGORY' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.category?.description || '-'}
        </Typography>
      ),
    },
    {
      field: 'address',
      title: <Title title='LOCATION' />,
      render: (row) => (
        <div className='d-flex flex-column'>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {!!row.cities?.name && `${row.cities?.name},`}{' '}
            {!!(row.states?.short_name || row.states?.name) && `${row.states?.short_name || row.states?.name}`}
          </Typography>
        </div>
      ),
    },
    {
      field: 'cost_per_gallon',
      title: <Title title='COST PER GALLON' />,
      render: (row) => {
        const totalGallons = Number(row.gallons || 1);
        const totalAmount = Number(row.amount || 0);

        const costPerGallon = totalAmount / totalGallons;

        return (
          <div className='d-flex flex-column'>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {currency}
              {formatNumber(costPerGallon)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'fees',
      title: <Title title='FEES' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {Number(row.fees) ? `${currency}${formatNumber(row.fees)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      title: <Title title='EQUIPMENT' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.equipment_id || '-'}
        </Typography>
      ),
    },
    {
      field: 'gallons',
      title: <Title title='QTY' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.gallons || '-'}
        </Typography>
      ),
    },
    {
      field: 'amount',
      title: <Title title='AMOUNT' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {currency}
          {formatNumber(row?.amount)}
        </Typography>
      ),
    },
    {
      field: 'receipt',
      title: <Title title='RECEIPT' />,
      render: (row) =>
        row.receipt ? (
          <Typography variant='s2' style={{ color: palette.indigo500 }} onClick={() => onViewReceipt(row.receipt)}>
            View
          </Typography>
        ) : (
          '-'
        ),
    },
    {
      field: 'type',
      title: <Title title='TYPE' />,
      render: (row) => (
        <Chip
          size='small'
          label={row.status?.toUpperCase()}
          labelColor={typeMapper[row.status]?.color}
          bgColor={typeMapper[row.status]?.bgColor}
        />
      ),
    },
  ];
};
