import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;
`;

export const STable = styled.table`
  margin-top: 24px;
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell {
    width: 100px;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;
