import React, { useState } from 'react';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import { SActionsWrapper, SDotsWrapper, SPopover } from '../DetentionTable.styles';

const RowActions = ({ rowData, onBill, onCheckout, onModifyDuration }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onDotsClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onBillClick = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    onBill();
  };

  const onModifyDurationClick = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    onModifyDuration();
  };

  const onCheckoutClick = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    onCheckout();
  };

  const onClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <SActionsWrapper>
      <SDotsWrapper className='action-dots'>
        <span className='dots' onClick={onDotsClick}>
          <ActionDots width={15} height={15} />
        </span>
        <SPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className='actions-wrapper'>
            <li onClick={onBillClick}>{rowData?.billed_at ? 'Revert Bill' : 'Bill Detention'}</li>
            {!!rowData?.departed_date && <li onClick={onModifyDurationClick}>Modify Duration</li>}
            {rowData?.equipment?.equipment_type_id >= 12 && !rowData?.departed_date && (
              <li onClick={onCheckoutClick}>Checkout Trailer</li>
            )}
          </div>
        </SPopover>
      </SDotsWrapper>
    </SActionsWrapper>
  );
};

export default RowActions;
