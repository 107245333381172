import { createSlice } from '@reduxjs/toolkit';
import { RECURRING_TABS } from 'components/TablePlaner/constats';

const initialState = {
  laneModalIsOpen: false,
  activeTabRecurring: RECURRING_TABS[0],
  stopPoints: [],
  billToData: [],
  assignToGroupsData: [],
};
export const counterSlice = createSlice({
  name: 'planner',
  initialState,
  reducers: {
    editLaneModalIsOpen: (state, action) => {
      state.laneModalIsOpen = action.payload;
    },
    onChangeActiveTab: (state, action) => {
      state.activeTabRecurring = action.payload;
    },
    onChangeStopPoints: (state, action) => {
      state.stopPoints = action.payload;
    },
    updateBillToData: (state, action) => {
      state.billToData = action.payload;
    },
    updateAssignToGroup: (state, action) => {
      state.assignToGroupsData = action.payload;
    },
  },
});

export const { editLaneModalIsOpen, onChangeActiveTab, updateBillToData, updateAssignToGroup } = counterSlice.actions;

export default counterSlice.reducer;
