import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SCompany = styled.div`
  background: #f7f9fc;
  margin: 0 auto;
  height: calc(100vh - 112px);
  overflow: auto;
  padding: 0 48px 48px;

  .company-content-wrapper {
    max-width: 1200px;
    display: flex;
    column-gap: 24px;
    margin: 0 auto;
  }

  .promo-code {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  @media (max-width: 1000px) {
    padding: 0 16px;

    .company-content-wrapper {
      flex-direction: column;
      row-gap: 24px;
    }
  }
`;

export const SAddressWrapper = styled.div`
  margin-bottom: 16px;

  .google-autocomplete {
    height: 40px;
    font-size: 16px;
    color: #000;

    ::placeholder {
      color: ${palette.gray500};
    }
  }
`;
