import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Tooltip from 'common/Tooltip';
import Divider from 'common/Divider';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import Input, { Textarea } from 'common/Input';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import AddActionTaken from 'components/AddIncidentModal/components/AddActionTaken';
import AddIncidentType from 'components/AddIncidentModal/components/AddIncidentType';
import { SAddressWrapper } from 'components/TransationTab/components/AddTransaction/AddTransaction.styles';
import { COUNTRIES } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { getStaffUsers } from 'Api/chat';
import { getIncidentTypes, getRulesAndConduct } from 'Api/CompanySettings';
import { getCities, getStates } from 'Api/JobPositions';
import { createStaffIncident, getActionTaken, updateStaffIncident, getIncidentRecommendedAction } from 'Api/Incidents';
import { getReportNumberList } from 'Api/DotMonitoring';
import IncidentPhotos from 'components/AddIncidentModal/components/IncidentPhotos';
import { generateId, getInitialValues } from './AddStaffIncident.data';
import { validationSchema } from './validationSchema';

const AddStaffIncident = ({ open, onClose, staffId, firstName, lastName, incident, onSuccess }) => {
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const { convertToCustomerTime, formatDateTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [actionTaken, setActionTaken] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [addTypeOpen, setAddTypeOpen] = useState(false);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [staffUsers, setStaffUsers] = useState([]);
  const [recommendedAction, setRecommendedAction] = useState(null);
  const [rules, setRules] = useState([]);
  const [reportNumbers, setReportNumbers] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      let updatedData;

      if (incident) {
        const body = {
          staff_id: values.staff?.staff?.id || staffId,
          incident_id: values.incident_id || '',
          report_number: values.report_number,
          incident_date: values.incident_date ? moment(values.incident_date).format('YYYY-MM-DD') : null,
          incident_time: values.incident_date ? moment(values.incident_date).format('HH:mm:ss') : null,
          address: `${values.country.name}, ${values.state.name}, ${values.city.name}`,
          country_id: values.country.id,
          city_id: values.city.id,
          state_id: values.state.id,
          incident_type: values.incident_type.id,
          incident_record_documents: values.document_path || [],
          incident_record_images: values.incident_record_images || [],
          action_taken: values.action_taken?.id
            ? values.action_taken?.id
            : !values.canReview
            ? actionTaken[0]
            : undefined,
          report_details: values.report_details || null,
          reason: values.reason || '',
          notes: values.notes || null,
          added_by_id: values.added_by.id,
        };

        const { data } = await updateStaffIncident(incident.id, body);
        updatedData = data;
      } else {
        const formData = new FormData();
        formData.append('staff_id', values.staff?.staff?.id || staffId);
        if (values.incident_id) {
          formData.append('incident_id', values.incident_id);
        }
        if (values.report_number) {
          formData.append('report_number', values.report_number);
        }
        if (values.incident_date) {
          formData.append('incident_date', moment(values.incident_date).format('YYYY-MM-DD'));
          formData.append('incident_time', moment(values.incident_date).format('HH:mm:ss'));
        }
        formData.append('address', `${values.country.name}, ${values.state.name}, ${values.city.name}`);
        formData.append('country_id', values.country.id);
        formData.append('city_id', values.city.id);
        formData.append('state_id', values.state.id);
        formData.append('incident_type', values.incident_type.id);
        formData.append(
          'action_taken',
          values.action_taken?.id ? values.action_taken?.id : !values.canReview ? actionTaken[0] : null
        );
        if (values.report_details) {
          formData.append('report_details', values.report_details);
        }
        if (values.notes) {
          formData.append('notes', values.notes);
        }
        if (values.reason) {
          formData.append('reason', values.reason);
        }
        formData.append('added_by_id', values.added_by.id);
        values.incident_record_images.forEach((image, i) => {
          formData.append(`incident_record_images[${i}]`, image);
        });
        values.document_path.forEach((doc, i) => {
          formData.append(`document_path[${i}][name]`, doc.name);
          formData.append(`document_path[${i}][path]`, doc.path);
        });

        await createStaffIncident(formData);
      }
      showToaster({ type: 'success', message: `Incident has been successfully ${incident ? 'updated' : 'created'}!` });
      onSuccess(updatedData);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues: getInitialValues(
      incident,
      convertToCustomerTime(),
      firstName,
      lastName,
      convertToCustomerTime(new Date(), 'MM/DD/YYYY HH:mm')
    ),
    validationSchema,
    onSubmit,
  });

  const getIncidentTypeOptions = async () => {
    try {
      const { data } = await getIncidentTypes();
      setIncidentTypes([...data, { id: 'add-custom', type: '+ Add Custom' }]);
    } catch (e) {
      // Do nothing
    }
  };

  const getStaffList = async () => {
    try {
      const { data } = await getStaffUsers();
      setStaffUsers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getActionTakenOptions = async () => {
    try {
      const { data } = await getActionTaken();
      setActionTaken([...data, { id: 'add-custom', action: '+ Add Custom' }]);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRecommendedAction = async (incident_type, user_id) => {
    try {
      const params = {
        user_type: 'staff',
        user_id,
        incident_type,
      };
      const data = await getIncidentRecommendedAction(params);
      setRecommendedAction(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getReportNumbers = async () => {
    try {
      const { data } = await getReportNumberList();
      setReportNumbers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  useEffect(() => {
    if (values.canReview && recommendedAction?.recommended_action?.length) {
      handleChange('action_taken', recommendedAction.recommended_action[0]);
    }
  }, [recommendedAction]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    handleChange('added_by', user);
    getIncidentTypeOptions();
    getActionTakenOptions();
    getRules();
    if (!staffId) {
      getStaffList();
    }
    getReportNumbers();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={incident ? 'Update Incident' : 'Add Incident'}
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      backdrop='static'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: incident ? 'Update' : values.canReview ? 'Add Incident' : 'Submit for Review',
          disabled: loading || (!values.staff?.staff?.id && !staffId),
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-4'>
        {!staffId && (
          <div>
            <Autocomplete
              required
              label='Staff User'
              name='staff'
              placeholder='Select Staff'
              options={staffUsers}
              value={values.staff}
              onChange={(e, value) => {
                handleChange('staff', value);
                handleChange(
                  'incident_id',
                  generateId(
                    value.staff?.first_name,
                    value.staff?.last_name,
                    convertToCustomerTime(new Date(), 'MM/DD/YYYY HH:mm')
                  )
                );
                if (values.canReview && values.incident_type) {
                  getRecommendedAction(values.incident_type.id, value.staff?.id);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => {
                return option ? `${option.staff?.first_name} ${option.staff?.last_name}` : '';
              }}
              error={touchedErrors.staff}
            />
          </div>
        )}
        <div>
          <Tooltip
            open={!!recommendedAction}
            title={
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center gap-2'>
                  <InfoIcon width={16} height={16} />
                  <Typography variant='s1'>Recommended Action</Typography>
                </div>
                <Divider margin='2px 0 16px 0' />
                {rules?.some((rule) => rule?.incident_types?.some((type) => type?.id === values.incident_type?.id)) && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      maxWidth: '500px',
                      marginBottom: '16px',
                    }}
                  >
                    <Typography variant='s2'>Related to:</Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '4px',
                        maxWidth: '425px',
                      }}
                    >
                      {rules
                        ?.filter((rule) => rule?.incident_types?.some((type) => type?.id === values.incident_type?.id))
                        .map((rule) => {
                          return (
                            <div
                              key={rule.id}
                              onClick={() => {
                                onClose();
                                navigate('/rules-conduct');
                              }}
                            >
                              <Chip
                                label={rule.title}
                                bgColor={palette.indigo0}
                                labelColor={palette.indigo500}
                                sx={{
                                  '& .MuiChip-label': {
                                    cursor: 'pointer',
                                  },
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                <Typography variant='s2' style={{ maxWidth: '500px', whiteSpace: 'normal', marginBottom: '16px' }}>
                  Based on this {values.incident_type?.type} incident and {firstName || values.staff?.staff?.first_name}{' '}
                  {lastName || values.staff?.staff?.last_name}'s record, our recommendation is to{' '}
                  {recommendedAction?.recommended_action?.length ? 'issue' : ''}{' '}
                  {recommendedAction?.recommended_action?.length ? (
                    <Typography variant='button2'>{recommendedAction?.recommended_action?.[0]?.action}</Typography>
                  ) : (
                    'review record (high risk - too many same incidents)'
                  )}
                  .
                </Typography>
                <Typography variant='s2' style={{ maxWidth: '500px', whiteSpace: 'normal', marginBottom: '8px' }}>
                  Reason for our recommendation is that there{' '}
                  {recommendedAction?.old_incidents?.length === 1 ? 'is' : 'are'}
                  {recommendedAction?.old_incidents?.length ? ` ${recommendedAction.old_incidents.length}` : ' no'}{' '}
                  record(s) for this type of incident on file {recommendedAction?.old_incidents?.length ? ':' : '.'}
                </Typography>
                {!!recommendedAction?.old_incidents?.length && (
                  <div style={{ maxWidth: '500px', whiteSpace: 'normal' }}>
                    <ul>
                      {recommendedAction?.old_incidents.map((incident) => {
                        return (
                          <li style={{ marginBottom: '4px' }}>
                            {incident?.incident_type?.type} occurred on{' '}
                            {formatDateTime(`${incident?.incident_date} ${incident?.incident_time}`)}
                            <ul>
                              <li>Action Taken: {incident?.incident_action_taken?.action}</li>
                              <li>
                                Action By: {incident?.added_by?.first_name} {incident?.added_by?.last_name}
                              </li>
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            }
            placement='right-end'
            arrow={false}
            componentsProps={{
              tooltip: {
                sx: {
                  color: palette.gray700,
                  bgcolor: palette.white,
                  fontSize: 14,
                  fontFamily: 'Inter',
                  whiteSpace: 'nowrap',
                  maxWidth: 'unset',
                  border: '1px solid #dadde9',
                  marginLeft: '40px !important',
                  marginTop: '100px !important',
                },
              },
            }}
          >
            <Autocomplete
              required
              label='Type of Incident'
              name='incident_type'
              labelKey='type'
              value={values.incident_type}
              onChange={(e, value) => {
                if (value?.id === 'add-custom') {
                  setAddTypeOpen(true);
                  return;
                }
                handleChange('incident_type', value);
                if (values.canReview && (values.staff || staffId)) {
                  getRecommendedAction(value.id, values.staff?.staff?.id || staffId);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={incidentTypes}
              error={touchedErrors.incident_type}
            />
          </Tooltip>
        </div>
        <Input
          required
          label='Incident ID'
          name='incident_id'
          value={values.incident_id}
          onChange={handleChange}
          disabled
        />
        <div>
          <Autocomplete
            label='Report Number'
            name='report_number'
            placeholder='Select Report Number'
            options={reportNumbers}
            value={values.report_number}
            onChange={(e, value) => {
              handleChange('report_number', value);
            }}
            disableClearable={false}
            isOptionEqualToValue={(option, value) => option === value}
            error={touchedErrors.report_number}
          />
        </div>
        <div>
          <DateTimePicker
            required
            label='Date + Time'
            name='incident_date'
            value={values.incident_date}
            onChange={(val) => handleChange('incident_date', val)}
            disableFuture
            onBlur={handleBlur}
            error={touchedErrors.incident_date}
          />
        </div>
        <div>
          <InputLabel required>Location of Incident</InputLabel>
          <SAddressWrapper>
            <div className='flex-grow-1'>
              <Autocomplete
                name='country'
                placeholder='Country..'
                value={values.country}
                onChange={(e, value) => {
                  handleChange('country', value);
                  handleChange('state', null);
                  handleChange('city', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={COUNTRIES}
                error={touchedErrors.country}
              />
            </div>
            <div className='flex-grow-1'>
              <Autocomplete
                name='state'
                placeholder='Select State..'
                value={values.state}
                onChange={(e, value) => {
                  handleChange('state', value);
                  handleChange('city', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={states}
                error={touchedErrors.state}
              />
            </div>
            <div className='flex-grow-1'>
              <Autocomplete
                name='city'
                placeholder='Select City..'
                value={values.city}
                onChange={(e, value) => handleChange('city', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={cities}
                error={touchedErrors.city}
              />
            </div>
          </SAddressWrapper>
        </div>
        <Textarea
          required
          rows={3}
          label='Report Details'
          name='report_details'
          value={values.report_details}
          onChange={handleChange}
          error={touchedErrors.report_details}
        />
        <Textarea
          rows={3}
          label='Reason(s) given by Person Implicated'
          name='reason'
          value={values.reason}
          onChange={handleChange}
        />
        <IncidentPhotos
          values={values}
          handleChange={handleChange}
          userId={staffId}
          userType='staff'
          name='document_path'
        />
        <IncidentPhotos
          values={values}
          handleChange={handleChange}
          userId={staffId}
          userType='staff'
          name='incident_record_images'
        />
        <div className='position-relative'>
          <Autocomplete
            required={values.canReview}
            label='Action Taken'
            name='action_taken'
            labelKey='action'
            value={!values.canReview ? actionTaken[0] : values.action_taken}
            onChange={(e, value) => {
              if (value?.id === 'add-custom') {
                setAddActionOpen(true);
                return;
              }
              handleChange('action_taken', value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  <div className='d-flex align-items-center gap-2'>
                    <Typography variant='s2'>{option.action}</Typography>
                    {recommendedAction?.recommended_action?.[0]?.id === option.id && (
                      <Chip label='RECOMMENDED' bgColor={palette.orange0} labelColor={palette.orange500} size='small' />
                    )}
                  </div>
                </li>
              );
            }}
            options={actionTaken}
            disabled={!values.canReview}
            error={touchedErrors.action_taken}
          />
          {!!recommendedAction?.recommended_action?.length &&
            recommendedAction?.recommended_action?.[0]?.id === values.action_taken?.id && (
              <div className='position-absolute' style={{ top: '29px', left: '110px' }}>
                <Chip label='RECOMMENDED' bgColor={palette.orange0} labelColor={palette.orange500} size='small' />
              </div>
            )}
        </div>
        <Textarea
          rows={3}
          label='Notes'
          name='notes'
          value={values.notes}
          disabled={!values.canReview}
          onChange={handleChange}
          error={touchedErrors.notes}
        />
        <Input label='Action By' value={`${values.added_by?.first_name} ${values.added_by?.last_name}`} disabled />
      </div>
      {addTypeOpen && (
        <AddIncidentType
          open={addTypeOpen}
          onClose={() => setAddTypeOpen(false)}
          onSuccess={(newType) => {
            getIncidentTypeOptions();
            if (newType) {
              handleChange('incident_type', newType);
            }
          }}
        />
      )}
      {addActionOpen && (
        <AddActionTaken
          open={addActionOpen}
          onClose={() => setAddActionOpen(false)}
          onSuccess={(newAction) => {
            getActionTakenOptions();
            if (newAction) {
              handleChange('action_taken', newAction);
            }
          }}
        />
      )}
    </Modal>
  );
};

export default AddStaffIncident;
