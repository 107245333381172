import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { Modal } from '@mui/material';
import { Button } from 'react-bootstrap';
import { number, object } from 'yup';

import useForm from '../../../../hooks/useForm';
import classes from './ChangeOrAddModal.module.scss';
import AddAccount from '../../../../pages/Accounting/Accounts/components/AddAccount';
import Autocomplete from '../../../../common/Autocomplete';
import { Typography } from '../../../Typography';

const ChangeOrAddModal = ({
  accounts,
  typeOptionBank,
  defaultPaidByAccount,
  onClose,
  onSelectAccount,
  onSuccessAddedAccount,
}) => {
  const [isOpenNewAccount, setIsOpenNewAccount] = useState(false);

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    onSubmit,
    initialValues: { paid_by_account: null },
    validationSchema: object().shape({
      paid_by_account: object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable(),
    }),
  });

  function onSubmit(values) {
    onSelectAccount(values);
  }

  const styleBackDrop = useMemo(() => {
    return {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }, []);

  useEffect(() => {
    handleChange('paid_by_account', defaultPaidByAccount);
  }, [defaultPaidByAccount]);

  return (
    <Modal open>
      <Box sx={styleBackDrop}>
        <div className={classes.modal}>
          <div className={classes.header}>
            <Typography variant='s' varinat='title1'>
              Select Payment Method
            </Typography>
          </div>
          <div className={classes.body}>
            <span onClick={() => setIsOpenNewAccount(true)} className={classes.newAccount}>
              + New Account
            </span>
            <Autocomplete
              required
              options={accounts}
              label='Payment Method'
              name='paid_by_account'
              labelKey='account_name'
              value={values.paid_by_account}
              error={touchedErrors?.paid_by_account}
              onChange={(e, val) => handleChange('paid_by_account', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
          <div className={classes.footer}>
            <Button style={{ margin: 0 }} className='cancel-button' onClick={onClose}>
              Cancel
            </Button>
            <Button type='submit' style={{ marginRight: 0 }} className='next-step' onClick={handleSubmit}>
              Select Account
            </Button>
          </div>
        </div>
        {isOpenNewAccount && (
          <AddAccount
            uploaderLabel='Upload logo'
            disabledAccountType
            defaultAccountType={typeOptionBank}
            open={isOpenNewAccount}
            onSuccess={onSuccessAddedAccount}
            className={classes.addAccountModal}
            onClose={() => setIsOpenNewAccount(false)}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ChangeOrAddModal;
