import styled from 'styled-components';

export const SWrapper = styled.div`
  min-width: 180px;
  svg {
    cursor: pointer;
  }
`;

export const SInputWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
