import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { DatePicker } from 'common/Pickers';
import { useTheme } from 'context/themeContext';
import { blockNotNumberChars } from 'utils/helpers';
import { requestStaffValidationSchema } from '../Drivers/AddDriver/ValidationSchema';
import './StaffRequestAddModal.css';

export default function StaffRequestAddModal({ onSuccess, createRequest, createLoading, id, name, ...props }) {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);

  const formik = useFormik({
    initialValues: {
      start_date: new Date(),
      end_date: new Date(),
      request_notes: '',
      req_status: 'Pending',
      user_details: null,
      request_type: 'Time Off',
      request_amount: null,
      shipment_id: null,
      name,
      user_type: 'staff',
    },
    validationSchema: requestStaffValidationSchema,
  });

  const saveRequest = () => {
    const { start_date, end_date, request_amount } = formik.values;
    const startDate = start_date ? moment(start_date || new Date()).format('MM/DD/YYYY') : new Date();
    const endDate = end_date ? moment(end_date || new Date()).format('MM/DD/YYYY') : new Date();
    const driverId = +id;
    const amount = request_amount === '' ? null : +request_amount;

    let payload = {
      ...formik.values,
      start_date: startDate,
      end_date: endDate,
      driver_staff_id: driverId,
      request_amount: amount,
    };
    if (formik.values.request_type !== 'Time Off') {
      payload = { ...payload, start_date: null, end_date: null };
    }
    createRequest(payload, props.onHide, successResponse, 'Request has been added successfully');
  };

  const successResponse = (msg) => {
    onSuccess(msg);
  };

  return (
    <div>
      <Modal
        {...props}
        dialogClassName='modal-80w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        enforceFocus={false}
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='request-modal-body-box driver-style-wrap'
          style={{
            background: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='request-modal-wrap'>
            <div className='request-container-wrapper request-type-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Request Type <span className='required'>*</span>
              </p>
              <div className='request-type-opt'>
                <p className='payment-option'>
                  <input
                    checked={formik.values.request_type === 'Time Off'}
                    value={formik.values.request_type}
                    type='radio'
                    name='request_type'
                    onChange={() => formik.setFieldValue('request_type', 'Time Off')}
                    className='radio-button'
                    defaultChecked
                  />
                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Time Off
                  </span>
                </p>
                <p className='payment-option'>
                  <input
                    type='radio'
                    name='request_type'
                    checked={formik.values.request_type === 'Cash Advance'}
                    value={formik.values.request_type}
                    onChange={() => formik.setFieldValue('request_type', 'Cash Advance')}
                    className='radio-button'
                  />
                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Cash Advance{' '}
                  </span>
                </p>
              </div>
            </div>
            {formik.values.request_type === 'Time Off' ? (
              <div>
                <div className='request-container-wrapper'>
                  <DatePicker
                    required
                    label='Request Start Date'
                    name='start_date'
                    value={formik.values.start_date}
                    onChange={(date) => {
                      formik.setFieldValue('start_date', date);
                      if (moment(date).isValid() && moment(formik.values.end_date).isValid()) {
                        if (date.getTime() > formik.values.end_date.getTime()) {
                          formik.setFieldValue('end_date', date);
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.start_date && formik.errors.start_date}
                  />
                </div>
                <div className='request-container-wrapper'>
                  <DatePicker
                    required
                    label='Request End Date'
                    name='end_date'
                    value={formik.values.end_date}
                    onChange={(date) => {
                      formik.setFieldValue('end_date', date);
                      if (moment(date).isValid() && moment(formik.values.start_date).isValid()) {
                        if (date.getTime() < formik.values.start_date.getTime()) {
                          formik.setFieldValue('start_date', date);
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.end_date && formik.errors.end_date}
                  />
                </div>
              </div>
            ) : null}

            {formik.values.request_type === 'Cash Advance' && (
              <div className='request-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{
                    marginLeft: '0',
                    color:
                      formik.values.request_type === 'Time Off'
                        ? use(palette.gray300, palette.gray200)
                        : formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number'
                        ? use(palette.gray300, palette.gray200)
                        : use(palette.gray700, palette.gray200),
                  }}
                >
                  Amount <span className='required'>*</span>
                </p>
                <div>
                  <input
                    type='number'
                    className='reason-input'
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                    }}
                    id='request_amount'
                    name='request_amount'
                    placeholder={`${currency} Amount`}
                    onBlur={formik.handleBlur}
                    onKeyDown={blockNotNumberChars}
                    onChange={(e) => {
                      formik.setFieldValue('request_amount', e.target.value);
                    }}
                    value={formik.values.request_amount}
                  />
                </div>
              </div>
            )}

            <div className='request-container-wrapper'>
              <p className='text-style abcense-text-style'>Request Notes</p>
              <div>
                <textarea
                  className='request-text-area'
                  id='request_notes'
                  name='request_notes'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.request_notes}
                  placeholder='Write request notes...'
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {createLoading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button disabled={!formik.isValid} onClick={() => saveRequest()} className='next-step' type='submit'>
                Add Request
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
