import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useChannelStateContext } from 'stream-chat-react';
import call from 'assets/icons/equipment/call.svg';
import sendMessageAudio from 'assets/SendMessage.wav';
import customersIcon from 'assets/sidemanu/customers.svg';
import ReciveMessageAudio from 'assets/ReceiveMessage.wav';
import profile from 'assets/icons/drivers/user-profile.svg';
import ClockIcon from 'assets/icons/header/clock-filled.svg';
import mailfilled from 'assets/icons/equipment/mail-filled.svg';
import documentSmall from 'assets/icons/header/document-small.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/mute.svg';
import { ReactComponent as UnmuteIcon } from 'assets/icons/unmute.svg';
import Avatar from 'common/Avatar';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { SimpleLoader } from 'common/Loader';
import PurchaseNumber from '../PurchaseNumber/PurchaseNumber';

const CustomChannelHeader = ({
  setactiveChannel,
  chatUser,
  setShowMember,
  setShowShedulemsg,
  messageData,
  setNotes,
  isLicence,
  showLicence,
  setshowLicence,
  notesData,
  setShowGuide,
  updateMutedStatus,
  setUpdateMutedStatus,
}) => {
  const showToaster = useShowToaster();
  const { channel } = useChannelStateContext();
  const [show, setshow] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [muted, setMuted] = useState(channel.muteStatus().muted);
  const navigate = useNavigate();

  useEffect(() => {
    setactiveChannel(channel);
  }, []);

  const u = channel.state.members;
  const memberList = [];
  Object.keys(u).forEach((key) => {
    const value = u[key];
    memberList.push(value);
  });

  const handleSheduleMsg = () => {
    setShowShedulemsg(true);
    setShowMember(true);
  };

  const handleMembers = () => {
    setShowMember(true);
    setShowShedulemsg(false);
  };

  const handleRedirect = (cid) => {
    const myArray = cid.split('_');
    if (cid.includes('driver_group')) {
      navigate(`/driver-profile/${myArray[myArray.length - 1]}/general`);
    } else if (cid.includes('staff_group')) {
      navigate(`staff-profile/${myArray[myArray.length - 1]}/general`);
    }
  };

  const selectPhoneNumber = () => {
    navigate('/billing');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderChanelName = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);
        if (chatUser.id === sender?.user?.id) {
          return receiver?.user?.name;
        }
        return sender?.user?.name;
      }
      return channel.data.name;
    }
  };

  const renderPhone = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);
        if (chatUser.id === sender?.user?.id) {
          return receiver?.user?.phone_number;
        }
        return sender?.user?.phone_number;
      }
      return channel.data.phone_number;
    }
  };

  const renderEmail = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);
        if (chatUser.id === sender?.user?.id) {
          return receiver?.user?.email;
        }
        return sender?.user?.email;
      }
      return channel.data.email;
    }
  };

  const getGroupImages = () => {
    const members = Object.values(channel?.state?.members);
    const memberImages = members.map((i) => i.user.image);

    if (memberImages.length > 1) {
      return memberImages;
    }

    return null;
  };

  const getChanelImag = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);
        if (chatUser.id === sender?.user?.id) {
          return receiver?.user?.image;
        }
        return sender?.user?.image;
      }
      return channel.data.image ? channel.data.image : profile;
    }
  };

  const muteChannel = async () => {
    try {
      setLoading(true);
      await channel.mute();
      setUpdateMutedStatus(Date.now());
      showToaster({ type: 'success', message: `${renderChanelName() || 'Channel'} has been muted!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const unmuteChannel = async () => {
    try {
      setLoading(true);
      await channel.unmute();
      setUpdateMutedStatus(Date.now());
      showToaster({ type: 'success', message: `${renderChanelName() || 'Channel'} has been unmuted!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const popoverProfile = (
    <Popover>
      <Popover.Body>
        <div className='popover-profile-body'>
          <div className='popover-content'>
            <div className='avtar_box pointer' onClick={() => handleRedirect(channel.cid)}>
              <img src={getChanelImag() || profile} alt='' className='group_user_avatar' />
            </div>
            <p>{renderChanelName() || 'Channel'}</p>
          </div>
          <div className='popover-content-two'>
            {renderPhone() && (
              <div className='member-detail'>
                <img src={call} alt='' />
                <a href={`tel:${renderPhone()}`}>
                  <p>{renderPhone()}</p>
                </a>
              </div>
            )}
            {renderEmail() && (
              <div className='member-detail'>
                <img src={mailfilled} alt='' />
                <a href={`mailto:${renderEmail()}`}>
                  <p>{renderEmail()}</p>
                </a>
              </div>
            )}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    setMuted(channel.muteStatus().muted);
  }, [updateMutedStatus]);

  return (
    <div className='str-chat__header-livestream'>
      {showLicence && (
        <div className='check-licence-message' onClick={() => setshowLicence(false)}>
          {isLicence === 1 ? (
            <>
              You do not have the "Two-Way SMS" add-on license. Please purchase the license first.
              <span
                style={{ margin: '0 16px 0 16px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGuide(true);
                }}
              >
                View Guide
              </span>
              <span onClick={selectPhoneNumber}> Enable </span>
            </>
          ) : (
            <>
              <div>
                You do not have a license purchased. &nbsp;
                <span onClick={() => navigate('/billing?addonId=7&planId=41')}> Please purchase SMS addon first. </span>
              </div>
              <span
                style={{ margin: '0 16px 0 16px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGuide(true);
                }}
              >
                View Guide
              </span>
            </>
          )}
        </div>
      )}

      <div className='header-item'>
        <div className='channle_img'>
          <div className='channel-preview__content-name channel-name'>
            {channel.type !== 'truckindigital' ? (
              <OverlayTrigger
                trigger='click'
                placement='bottom'
                rootClose={show}
                onToggle={setshow}
                show={show}
                overlay={popoverProfile}
              >
                <div className='channel-header-name-image'>
                  <div className='userAvatar'>
                    <img src={getChanelImag() || profile} alt='img' />
                  </div>
                  <p style={{ margin: '0px', cursor: 'pointer' }}>{renderChanelName() || 'Channel'}</p>
                </div>
              </OverlayTrigger>
            ) : (
              <div className='channel-header-name-image'>
                <div className='me-2'>
                  <Avatar images={getGroupImages()} />
                </div>
                <p style={{ margin: '0px', cursor: 'pointer' }}>{channel?.data.name || 'Channel'}</p>
              </div>
            )}
            <div style={{ marginLeft: '4px' }}>
              {loading ? (
                <SimpleLoader loading={loading} size={16} />
              ) : muted ? (
                <div
                  className='mute_icon_wrapper'
                  onClick={(e) => {
                    e.preventDefault();
                    unmuteChannel();
                  }}
                >
                  <MuteIcon />
                </div>
              ) : (
                <div
                  className='unmute_icon_wrapper'
                  onClick={(e) => {
                    e.preventDefault();
                    muteChannel();
                  }}
                >
                  <UnmuteIcon />
                </div>
              )}
            </div>
          </div>
          <audio controls className='send_message_audio' id='myAudioTagID' src={sendMessageAudio} />
          <audio controls className='send_message_audio' id='ReceivedAudioTagID' src={ReciveMessageAudio} />
        </div>
        <div className='members_icons'>
          <p>
            <img src={documentSmall} alt='' onClick={() => setNotes(true)} />
          </p>
          {notesData.length !== 0 && <p>{notesData.length}</p>}
        </div>
        {messageData.length !== 0 && (
          <div className='members_icons'>
            <img src={ClockIcon} alt='' onClick={handleSheduleMsg} />
            <p>{messageData.length}</p>
          </div>
        )}
        {channel.type === 'truckindigital' && memberList.length > 1 && (
          <div className='members_icons'>
            <img src={customersIcon} alt='' onClick={handleMembers} />
            <p>{memberList.length}</p>
          </div>
        )}
      </div>
      <Modal
        size='sm'
        show={open}
        onHide={handleClose}
        centered
        id='add-day-modal'
        dialogClassName='modal-30w'
        enforceFocus={false}
      >
        <PurchaseNumber open={open} close={handleClose} cid={channel?.cid} />
      </Modal>
    </div>
  );
};

export default CustomChannelHeader;
