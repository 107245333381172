import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Deductions } from 'pages/CompanySettings/pagesComponents';

const DeductionsSettings = ({ open, onClose }) => {
  return (
    <Modal
      showModal={!!open}
      onHide={onClose}
      $width='1000px'
      $bgColor={palette.gray0}
      headerTitle='Deduction Types'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done', className: 'modal-cancel-btn' }]}
    >
      <Deductions isModalView />
    </Modal>
  );
};

export default DeductionsSettings;
