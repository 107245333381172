import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SAccordionContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 12px;
  align-items: center;
  transition: 0.4s;

  .accordion-container-sub {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & > :nth-child(1) {
    width: 25%;
  }

  & > :nth-child(2) {
    width: 40%;
  }

  & > :nth-child(3) {
    width: 15%;
  }

  & > :nth-child(4) {
    width: 18%;
  }

  & > :nth-child(5) {
    width: 2%;
  }
`;

export const SNameImage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .profile-image-container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      border-radius: 40px;
      object-fit: cover;
    }

    .profile-active-indicator {
      position: absolute;
      right: 0;
      bottom: 0;
      background: #38a06c;
      box-shadow: 0 0 0 2px #ffffff;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
`;

export const SSubTableContainer = styled.div`
  cursor: pointer;
  margin-top: 12px;
  border-left: 1px solid ${palette.gray50};

  .table-data-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    color: #464f60;
  }

  .success-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    gap: 6px;
    background: #e1fcef;
    border-radius: 4px;
    width: max-content;
  }

  .success-container .success-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    /* color: #14804A; */
    margin: 0px;
  }

  .break-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    gap: 6px;
    background: #fcf2e6;
    border-radius: 4px;
    width: max-content;
  }

  .break-container .break-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #aa5b00;
    margin: 0px;
  }

  .warning-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    gap: 6px;
    background: #faf0f3;
    border-radius: 4px;
    width: max-content;
  }

  .warning-container .warning-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #d12953;
    margin: 0px;
  }

  .custom-table-wraper {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: auto;
  }

  .custom-table-header-wraper {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    display: table-header-group;
  }

  .custom-table-row-wraper {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    transition: all 300ms ease 0s;
    cursor: pointer;

    &.custom-table-row-wraper-header td {
      font-family: 'Inter', sans-serif !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      color: #464f60 !important;
      white-space: nowrap;
      padding: 8px 16px;
    }

    td {
      color: inherit;
      width: auto;
      box-sizing: border-box;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      text-align: left;
      display: table-cell;
      padding: 16px;
      line-height: 1.43;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      letter-spacing: 0.01071em;
      vertical-align: inherit;
      white-space: nowrap !important;
    }
  }
`;
