import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { documentType } from 'Api/Document';
import { getSlaveShipments, postShipmentDocument } from 'Api/Shipment';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import ItemForm from './ItemForm';
import { validationSchema } from './ItemForm/validationSchema';
import { SRowUpload, SAddMore } from './UploadShipmentDocuments.styles';

const initialValues = [
  {
    id: 1,
    reference_id: '',
    type: null,
    doc: null,
    shipment_id: null,
    shipment_type: '',
    shipment_stop: null,
  },
];

const UploadShipmentDocuments = ({ open, onClose, shipmentData }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [slaves, setSlaves] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoadingSubmit(true);
      const promises = values.map((row) => {
        const formData = new FormData();
        formData.append('shipment_id', row?.shipment_id?.shipment_id || shipmentData?.shipment_id);
        if (row?.shipment_stop) {
          formData.append('shipment_stop_id', row?.shipment_stop?.id);
        }
        formData.append(`reference_id`, row?.reference_id);
        formData.append(`type`, row?.type.id);
        formData.append(`doc`, row?.doc);
        return postShipmentDocument({ formData });
      });
      await Promise.all(promises);
      showToaster({ type: 'success', message: 'The documents have been successfully updated!' });
      navigate(`/shipment/${shipmentData?.shipment_id}`, { state: { defaultTab: 5 } });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSubmit(false);
    }
  };

  function getSlaves() {
    const body = {
      shipment_id: shipmentData.shipment_id,
    };
    getSlaveShipments(body).then((res) => {
      if (res) {
        if (res.data && res.data.length) {
          const options = res.data.map((el) => {
            return {
              ...el,
              key: el.shipment_id,
              label: `${el.shipment_id} ${el.shipment_billing?.[0]?.billing_customer?.company_name}`,
              labelSelected: null,
            };
          });
          setSlaves(options);
        }
      }
    });
  }

  const { handleChange, handleSubmit, values, touchedErrors, setValues } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const getDocumentTypes = async () => {
    setLoading(true);
    try {
      const { data } = await documentType({ shipment_id_required: 1 });
      setDocumentTypes(data.filter((el) => !el.hidden));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const addNewForm = () => {
    handleChange(values.length, {
      id: Math.random(),
      reference_id: '',
      type: null,
      doc: null,
    });
  };

  const deleteFormItem = (index) => {
    const newValues = values.filter((item, i) => i !== index);
    setValues(newValues);
  };

  useEffect(() => {
    getDocumentTypes();

    if (shipmentData.shipment_type === 'ltl') {
      getSlaves();
    }
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Upload Document
          <Typography as='div' variant='b2' style={{ color: use(palette.gray500) }}>
            Shipment {shipmentData?.shipment_id}{' '}
            {shipmentData.shipment_type !== 'ltl' &&
              !!shipmentData?.shipment_billing &&
              `- ${shipmentData?.shipment_billing?.[0].billing_customer?.company_name}`}
          </Typography>
        </>
      }
      $bgColor={palette.gray0}
      $maxWidth={shipmentData.shipment_type === 'ltl' ? '1200px' : '1000px'}
      $minWidth={shipmentData.shipment_type === 'ltl' ? '1200px' : '1000px'}
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Upload Documents',
          disabled: loadingSubmit || values.length === 0,
          onClick: handleSubmit,
        },
      ]}
    >
      {loading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <CircularProgress size={30} />
        </div>
      ) : (
        <div className='d-flex flex-column align-items-start'>
          <SRowUpload>
            <div className='table-upload-header-item'>
              <Typography variant='s2'>
                Reference <span style={{ color: use(palette.indigo500) }}>*</span>
              </Typography>
            </div>
            {shipmentData.shipment_type === 'ltl' && (
              <div className='table-upload-header-item'>
                <Typography variant='s2'>
                  Shipment ID/Customer <span style={{ color: use(palette.indigo500) }}>*</span>
                </Typography>
              </div>
            )}
            <div className='table-upload-header-item'>
              <Typography variant='s2'>Shipment Stop</Typography>
            </div>
            <div className='table-upload-header-item'>
              <Typography variant='s2'>
                Document Type <span style={{ color: use(palette.indigo500) }}>*</span>
              </Typography>
            </div>
            <div className='table-upload-header-item' />
          </SRowUpload>
          {values.map((item, i) => (
            <ItemForm
              key={item.id}
              index={i}
              values={item}
              types={documentTypes}
              handleChange={handleChange}
              slaves={slaves}
              shipmentType={shipmentData.shipment_type}
              stops={shipmentData.shipment_stops}
              onCancel={() => deleteFormItem(i)}
              touchedErrors={touchedErrors?.[i]}
            />
          ))}
          <SAddMore onClick={addNewForm}>
            <PlusIcon fill='#4F5AED' />
            <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
              Add Another
            </Typography>
          </SAddMore>
        </div>
      )}
    </Modal>
  );
};

export default UploadShipmentDocuments;
