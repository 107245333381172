import React from 'react';
import { COST_TYPE } from 'utils/constants';
import Costs from './Tables/Costs';
import OpenBills from './Tables/OpenBills';
import PaidBills from './Tables/PaidBills';
import PastDueBills from './Tables/PastDueBills';
import RecurringBills from './Tables/RecurringBills';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  vendor: null,
};

export const tabs = [
  {
    key: 'openBills',
    label: 'Open Bills',
    component: ({ refreshStats }) => <OpenBills refreshStats={refreshStats} />,
  },
  {
    key: 'pastDueBills',
    label: 'Past Due',
    component: ({ refreshStats }) => <PastDueBills refreshStats={refreshStats} />,
  },
  {
    key: 'paidBills',
    label: 'Paid Bills',
    component: ({ refreshStats }) => <PaidBills refreshStats={refreshStats} />,
  },
  {
    key: 'recurringBills',
    label: 'Recurring Bills',
    component: ({ refreshStats }) => <RecurringBills refreshStats={refreshStats} />,
  },
  {
    key: 'variable',
    label: 'Variable Cost',
    component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.VARIABLE} refreshStats={refreshStats} />,
  },
  {
    key: 'fixed',
    label: 'Fixed Cost',
    component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.FIXED} refreshStats={refreshStats} />,
  },
  {
    key: 'maintenance',
    label: 'Maintenance Cost',
    component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.MAINTENANCE} refreshStats={refreshStats} />,
  },
  {
    key: 'admin',
    label: 'Administrative Cost',
    component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.ADMINISTRATIVE} refreshStats={refreshStats} />,
  },
];

export const recurringOption = {
  Weekly: 'week',
  Monthly: 'month',
};
