import React from 'react';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SWrapper } from './DetailsComponents.styles';

const YTDTakeHome = ({ yearToDate, gross }) => {
  const { take_home } = yearToDate ?? {};

  return (
    <SWrapper $badgeColor={palette.green400}>
      <div className='w-100 d-flex justify-content-between'>
        <Typography variant='s2' style={{ color: palette.gray900 }} className='d-flex align-items-center gap-1'>
          <span className='detail-badge' />
          Take Home - {formatNumber((take_home * 100) / gross)}%
        </Typography>
        <Typography variant='button2' style={{ color: palette.gray900 }}>
          ${formatNumber(take_home)}
        </Typography>
      </div>
    </SWrapper>
  );
};

export default YTDTakeHome;
