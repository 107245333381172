import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as TruckinLogo } from 'assets/sidemanu/logo.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import { Typography } from 'components/Typography';
import Deposit from 'componentsV2/CardManagement/Deposit';
import Withdraw from 'componentsV2/CardManagement/Withdraw';
import Transfer from 'componentsV2/CardManagement/Transfer';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatNumber, palette } from 'utils/constants';

const Accounts = ({ isOnboardingPassed, accounts }) => {
  const { currency } = useSelector((state) => state.root);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);

  return (
    <div className='card-accounts'>
      <div className='card-accounts-header'>
        <Typography variant='h2'>Card Accounts</Typography>
      </div>
      <div className='card-accounts-details'>
        {isOnboardingPassed && accounts.length !== 0 ? (
          <>
            <div className='detail-row'>
              <div className='d-flex gap-1'>
                <TruckinLogo fill={palette.indigo500} />
                <div className='account-name'>
                  <Typography variant='button2' style={{ lineHeight: '28px' }}>
                    {accounts[1].account_name}
                  </Typography>
                  <div className='d-flex align-items-center gap-2'>
                    <ArrowIcon />
                    <Typography variant='c1' style={{ color: palette.gray500 }}>
                      Account Number
                    </Typography>
                  </div>
                  <div className='d-flex align-items-center gap-2'>
                    <ArrowIcon fill={palette.white} />
                    <Typography variant='c1' style={{ color: palette.gray500 }}>
                      Routing Number
                    </Typography>
                  </div>
                </div>
              </div>
              <div className='balance'>
                <Typography variant='h2'>
                  {currency}
                  {formatNumber(accounts[1].account_balance)}
                </Typography>
                <Typography variant='c1'>•••• •••• •••• {accounts[1].account_number?.slice(-4)}</Typography>
                <Typography variant='c1'>{accounts[1].account_routing}</Typography>
              </div>
              <div className='actions'>
                <CustomButton
                  type='secondary'
                  title='Deposit'
                  styleButton={{ padding: '2px 8px', height: '28px', margin: 0 }}
                  onClick={() => setOpenDeposit(true)}
                />
                <CustomButton
                  type='secondary'
                  title='Withdraw'
                  styleButton={{ padding: '2px 8px', height: '28px', margin: 0 }}
                  onClick={() => setOpenWithdraw(true)}
                />
              </div>
            </div>
            <div className='detail-row'>
              <div className='d-flex gap-1'>
                <TruckinLogo fill={palette.indigo500} />
                <div className='account-name'>
                  <Typography variant='button2' style={{ lineHeight: '28px' }}>
                    {accounts[0].account_name}
                  </Typography>
                  <div className='d-flex align-items-center gap-2'>
                    <ArrowIcon />
                    <Typography variant='c1' style={{ color: palette.gray500 }}>
                      Account Number
                    </Typography>
                  </div>
                  <div className='d-flex align-items-center gap-2'>
                    <ArrowIcon fill={palette.white} />
                    <Typography variant='c1' style={{ color: palette.gray500 }}>
                      Routing Number
                    </Typography>
                  </div>
                </div>
              </div>
              <div className='balance'>
                <Typography variant='h2'>
                  {currency}
                  {formatNumber(accounts[0].account_balance)}
                </Typography>
                <Typography variant='c1'>{accounts[0].account_number?.slice(-4)}</Typography>
                <Typography variant='c1'>{accounts[0].account_routing}</Typography>
              </div>
              <div className='actions'>
                <CustomButton
                  type='secondary'
                  title='Transfer'
                  styleButton={{ padding: '2px 8px', height: '28px', margin: 0 }}
                  onClick={() => setOpenTransfer(true)}
                />
                <CustomButton
                  type='secondary'
                  title='Withdraw'
                  styleButton={{ padding: '2px 8px', height: '28px', margin: 0 }}
                  onClick={() => setOpenWithdraw(true)}
                />
              </div>
            </div>
          </>
        ) : (
          <div className='feat-not-available'>
            <Typography variant='s2'>This feature is only available with Truckin Digital Cash Card</Typography>
          </div>
        )}
      </div>
      {openWithdraw && <Withdraw open={openWithdraw} onClose={() => setOpenWithdraw(false)} />}
      {openDeposit && <Deposit open={openDeposit} onClose={() => setOpenDeposit(false)} />}
      {openTransfer && <Transfer open={openTransfer} onClose={() => setOpenTransfer(false)} accounts={accounts} />}
    </div>
  );
};

export default Accounts;
