import moment from 'moment';

export const initialFilters = {
  dateRange: [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
  city: '',
  state: '',
  zipcode: '',
  miles: '',
  drivers: [],
  equipment: [],
  groups: [],
  radius: '50',
  date_span: '3',
};

export const initialTabs = [
  { label: 'List', value: 0, key: 'list' },
  { label: 'Timeline', value: 1, key: 'calendar' },
  { label: 'Planner Match', value: 2, key: 'planner-match' },
];
