import React from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';
import ImageDropZone from 'components/ImgageDropZone';
import { SAttachmentWrapper } from './Attachment.styles';

const Attachment = ({ attachment, setAttachment, label }) => {
  return (
    <SAttachmentWrapper>
      <InputLabel>{label}</InputLabel>
      <div>
        {attachment ? (
          <p className='attachment-wrapper' style={{ backgroundColor: palette.indigo0 }}>
            <img src={right} alt='attachment' className='attachment-icon' />
            <span className='text-style' style={{ color: palette.green400 }}>
              File Uploaded
            </span>
            <img src={cross} alt='cross' className='upload-cancel-icon' onClick={() => setAttachment(null)} />
          </p>
        ) : (
          <div className='upload-document-deposit'>
            <div className='text-style upload-label'>
              <p className='upload-document-icon'>
                <img src={uploadLicense} alt='' />
              </p>
              <p className='upload-document-text'>
                <span style={{ color: palette.indigo500 }}> Upload Document </span>
                <span style={{ color: palette.gray700 }}> or drag and drop</span>
              </p>
            </div>
            <ImageDropZone
              onDrop={(file) => setAttachment(file[0])}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              maxSize={1000000}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
              accept={['image/jpeg', 'image/png']}
            />
          </div>
        )}
      </div>
    </SAttachmentWrapper>
  );
};

export default Attachment;
