import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { getTrackingCost, requestCellularTracking, updateCellularTracking } from 'Api/Shipment';
import { getErrorMessage } from 'utils/error';
import { calculateFullDays } from './RequestCellularTracking.data';

const RequestCellularTracking = ({ open, onClose, shipment, onSuccess }) => {
  const { value } = useAuth();
  const { user } = value;
  const showToaster = useShowToaster();
  const [trackingCost, setTrackingCost] = useState(null);
  const [loading, setLoading] = useState(false);
  const driverPhone = parsePhoneNumber(`+${shipment?.brokerage_dispatch?.driver_phone_number}`);
  const countryCode = driverPhone?.country?.toLowerCase();

  const scheduledDuration = useMemo(() => {
    const firstStopDate = `${shipment?.shipment_stops?.[0]?.scheduled_date} ${shipment?.shipment_stops?.[0]?.to}`;
    const lastStopDate = `${shipment?.shipment_stops?.[Number(shipment?.shipment_stops?.length) - 1]?.scheduled_date} ${
      shipment?.shipment_stops?.[Number(shipment?.shipment_stops?.length) - 1]?.to
    }`;

    return getAverageTimeConverted(moment(lastStopDate).diff(moment(firstStopDate), 'minutes'));
  }, [shipment]);

  const getTrackingCosts = async () => {
    try {
      const { price } = await getTrackingCost();
      setTrackingCost(price);
    } catch (e) {
      // Do nothing
    }
  };

  const requestTracking = async () => {
    try {
      setLoading(true);
      const body = {
        shipment_id: shipment.shipment_id,
      };
      await requestCellularTracking(body);
      showToaster({ type: 'success', message: 'Cellular tracking has been successfully requested!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const cancelTrackingRequest = async () => {
    try {
      setLoading(true);
      const body = {
        customer_dot: user?.customer?.dot,
        carrier_id: shipment?.brokerage_dispatch?.carrier_id,
        shipment_id: shipment.shipment_id,
        status: 'cancelled',
      };
      await updateCellularTracking(body);
      showToaster({ type: 'success', message: 'Tracking request has been successfully canceled!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrackingCosts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Request Cellular Tracking'
      $bgColor={palette.white}
      $maxWidth='810px'
      $minWidth='810px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: shipment?.cellular_tracking_status === 'requested' ? 'danger' : 'primary',
          title: shipment?.cellular_tracking_status === 'requested' ? 'Cancel Request' : 'Request Tracking',
          onClick: shipment?.cellular_tracking_status === 'requested' ? cancelTrackingRequest : requestTracking,
          disabled: loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-2'>
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='button2'>Tracking Cost:</Typography>
          <Typography variant='s2'>
            ${formatNumber(Number(trackingCost) * calculateFullDays(scheduledDuration))} (Shipment{' '}
            {shipment?.shipment_id} / Max {calculateFullDays(scheduledDuration)}{' '}
            {calculateFullDays(scheduledDuration) > 1 ? 'Days' : 'Day'})
          </Typography>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='button2'>Telephone:</Typography>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
            <span className={`flag-icon flag-icon-${countryCode || 'us'}`} />
            <Typography variant='s2'>{driverPhone?.formatInternational()}</Typography>
          </div>
        </div>
        <Typography variant='button2'>
          Terms and Conditions:{' '}
          <Typography variant='s2'>
            By clicking the request tracking button below, you approve Truckin Systems LLC to track your carrier and
            bill you $2.00 per day which ever is sooner: for maximum {calculateFullDays(scheduledDuration)}{' '}
            {calculateFullDays(scheduledDuration) > 1 ? 'days' : 'day'} or if shipment is completed. You understand that
            the tracking uses cellular device data fields, cell tower data, and WiFi access point array data to return
            latitude/longitude coordinates every minute.
            <br />
            There are no refunds if tracking is stopped after it was started as we only initiate billing once driver
            starts tracking.
          </Typography>
        </Typography>
      </div>
    </Modal>
  );
};

export default RequestCellularTracking;
