import React, { useState } from 'react';
import Modal from 'common/Modal';
import Divider from 'common/Divider';
import { Textarea } from 'common/Input';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { editStandaloneInvoices, deleteInvoiceAttachment } from 'Api/AccountingReceivables';
import BillTo from './components/BillTo';
import Attachment from './components/Attachment';
import ChargesForm from './components/ChargesForm';
import { validationSchema } from './validationSchema';
import { getInitialValues, getRequestBody } from './EditInvoice.data';
import { SBodyWrapper } from './EditInvoice.styles';

const EditInvoice = ({ open, onClose, onSuccess, invoice }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (attachmentsToDelete.length) {
        const promises = attachmentsToDelete.map((attachment) => deleteInvoiceAttachment(attachment.id));
        await Promise.all(promises);
      }

      const body = getRequestBody(values);
      await editStandaloneInvoices(invoice.id, body);
      showToaster({ type: 'success', message: 'Invoice has been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, handleBlur, touchedErrors, dirty } = useForm({
    initialValues: getInitialValues(invoice),
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Edit Invoice'
      $bgColor={palette.gray0}
      $width='1300px'
      $maxWidth='90vw'
      $minWidth='1200px'
      className='modified-scrollbar'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', margin: 10, fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Edit Invoice',
          disabled: loading || !dirty,
          onClick: handleSubmit,
        },
      ]}
    >
      <SBodyWrapper>
        <div className='first-line'>
          <DatePicker
            required
            width='200px'
            name='bill_date'
            label='Date Billed'
            value={values.bill_date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('bill_date', val)}
            error={touchedErrors.bill_date}
          />
          <DatePicker
            required
            name='due_date'
            label='Date Due'
            width='200px'
            value={values.due_date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('due_date', val)}
            error={touchedErrors.due_date}
          />
        </div>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='is_auto_debt_enabled'
              checked={!!values.is_auto_debt_enabled}
              onChange={(checked) => handleChange('is_auto_debt_enabled', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              Allow auto-debiting for this invoice
            </Typography>
          </div>
          {!!values.is_auto_debt_enabled && (
            <div className='d-flex flex-column gap-1 ms-4'>
              <Divider margin='0 0 4px' />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                Payment Method:
              </Typography>
              <div className='d-flex gap-4'>
                <CustomRadioButton
                  field={{
                    name: 'payment_method',
                    value: 'credit_card',
                    onChange: () => handleChange('payment_method', 'credit_card'),
                    checked: values.payment_method === 'credit_card',
                  }}
                >
                  <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                    Credit Card
                  </Typography>
                </CustomRadioButton>
                <CustomRadioButton
                  field={{
                    name: 'payment_method',
                    value: 'ach',
                    onChange: () => handleChange('payment_method', 'ach'),
                    checked: values.payment_method === 'ach',
                  }}
                >
                  <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                    ACH
                  </Typography>
                </CustomRadioButton>
              </div>
            </div>
          )}
        </div>
        <BillTo values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        <ChargesForm values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              Notes
            </Typography>
          </div>
          <Textarea
            rows={6}
            name='note'
            placeholder='Public note...'
            value={values.note}
            onChange={handleChange}
            error={touchedErrors.note}
          />
        </div>
        <div className='d-flex flex-column'>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            Attachment(s)
          </Typography>
          <Attachment values={values} handleChange={handleChange} setAttachmentsToDelete={setAttachmentsToDelete} />
        </div>
      </SBodyWrapper>
    </Modal>
  );
};

export default EditInvoice;
