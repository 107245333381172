import React, { useState, useEffect, useMemo } from 'react';
import './vehicle.css';
import moment from 'moment/moment';
import IFTAServices, { getSamsaraVehicle, formatAmount } from 'services/IFTAServices';
import TopArrowActive from 'assets/icons/tableSortArrowTopActive.svg';
import TopArrow from 'assets/icons/tableSortArrowTop.svg';
import DownArrow from 'assets/icons/tableSortArrowDown.svg';
import DownArrowActive from 'assets/icons/tabelSortArrowDownActive.svg';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from 'components/Typography';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { quarters } from '../IFTA.data';
import QuarterYearFilter from '../QuarterYearFilter';
import VehicleSelect from './VehicleSelect';
import ExportData from './ExportData';

const Vehicle = ({ connectedService }) => {
  const showToaster = useShowToaster();
  const [vehicleData, setVehicleData] = useState([]);
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [startDate, setStartDate] = useState(moment().startOf('year').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(moment().set('month', 2).endOf('month').format('MM/DD/YYYY'));
  const [sortBy, setSortBy] = useState('vehicle_id');
  const [fuelTypeData, setFuelTypeData] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [quarter, setQuarter] = useState(quarters[0]);
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false);

  const getSamsaraVehicleData = async (params) => {
    try {
      setLoading(true);
      const data = await getSamsaraVehicle(params);
      setVehicleData(data);
    } catch (e) {
      setVehicleData([]);
      const message = getErrorMessage(e);
      if (message) {
        showToaster({ message, type: 'error' });
      }
    }
    setLoading(false);
  };

  const IFTAData = (data) => {
    IFTAServices.getVehicleData(data).then((res) => setVehicleData(res.data));
  };

  const getFuelTypes = () => {
    IFTAServices.getFuelTypes().then((res) => {
      setFuelTypeData(res.data.data);
      setSelected(res.data.data[0]);
    });
  };

  useEffect(() => {
    getFuelTypes();
  }, []);

  useEffect(() => {
    if (!selectedData?.id || !selected?.id) {
      return;
    }

    const data = {
      from_date: moment(startDate).format('MM/DD/Y'),
      to_date: moment(endDate).format('MM/DD/Y'),
      fuel_type: selected.id,
      equipment_id: selectedData.id,
      sortBy,
      sortingOrder,
    };

    if (connectedService) {
      if (connectedService?.success) {
        const params = {
          quarter: quarter?.key,
          year,
          fuel_type: selected.id,
          equipment_id: selectedData.id,
          [`sort[][${sortBy}]`]: sortingOrder,
          page: 1,
          itemsPerPage: 1000,
        };

        getSamsaraVehicleData(params);
        return;
      }
      IFTAData(data);
    }
  }, [startDate, endDate, sortingOrder, sortBy, selectedData, selected, connectedService]);

  const fuelTypeOptions = useMemo(() => {
    return (fuelTypeData || [])
      .filter((item) => ![2, 4].includes(item.id))
      .map((option) => ({ ...option, key: option.id, label: option.type, labelSelected: null }));
  }, [fuelTypeData]);

  return (
    <div className='fuel-price-layout'>
      <div className='fuel-price-header'>
        <div className='fuel-inner-header'>
          <div>
            <QuarterYearFilter
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              quarter={quarter}
              setQuarter={setQuarter}
              onYearChange={setYear}
            />
          </div>
          <div>
            <CustomSelect
              value={selected}
              defaultActiveValue={fuelTypeOptions[0]}
              onChange={setSelected}
              options={fuelTypeOptions}
              styles={{ height: 32, width: 140 }}
            />
          </div>
          <VehicleSelect
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <ExportData
          vehicleData={vehicleData}
          year={year}
          quarter={quarter?.key}
          fuelType={selected}
          selectedData={selectedData}
        />
      </div>
      {!vehicleData?.data && loading ? (
        <TablePreLoader styleWrapper={{ marginTop: '30px' }} />
      ) : (
        <ShortTable
          vehicleData={vehicleData}
          setSortingOrder={setSortingOrder}
          sortingOrder={sortingOrder}
          setSortBy={setSortBy}
          sortBy={sortBy}
          connectedService={connectedService}
        />
      )}
    </div>
  );
};

export default Vehicle;

const ShortTable = ({ vehicleData, setSortingOrder, sortingOrder, setSortBy, sortBy, connectedService }) => {
  const handleShortingByVehicleID = () => {
    setSortBy('vehicle_id');
    if (sortingOrder === 'asc') {
      setSortingOrder('desc');
    } else if (sortingOrder === 'desc') {
      setSortingOrder('asc');
    }
  };

  const handleShortingByJurisdiction = () => {
    setSortBy('jurisdiction');
    if (sortingOrder === 'asc') {
      setSortingOrder('desc');
    } else if (sortingOrder === 'desc') {
      setSortingOrder('asc');
    }
  };

  return (
    <div style={{ padding: '30px 0' }} id='vehicle_download'>
      <div className='jurisdiction-heading-top'>
        <span className='title'>{formatAmount(vehicleData?.total_miles, 0)}</span>
        <span className='subtitle'>Total IFTA Miles</span>
        {connectedService?.success && connectedService?.data && (
          <span className='service-info'>
            IFTA data is provided by
            <Typography variant='s2' style={{ color: palette.gray900, marginLeft: '4px' }}>
              {connectedService.data.provider_name}
            </Typography>
            {connectedService.data?.logo && (
              <img src={connectedService.data.logo} alt={connectedService.data.provider_name} height={46} />
            )}
          </span>
        )}
      </div>

      <div className='fuel-table-font'>
        <ul className='fuel-price-history-table-header upperCase' style={{ marginTop: '20px' }}>
          <li className='sorting-option'>
            Vehicle ID
            <div className='arrow-btn' onClick={handleShortingByVehicleID}>
              <img src={sortingOrder === 'asc' && sortBy === 'vehicle_id' ? TopArrowActive : TopArrow} alt='' />
              <img src={sortingOrder === 'desc' && sortBy === 'vehicle_id' ? DownArrowActive : DownArrow} alt='' />
            </div>
          </li>
          <li className='sorting-option'>
            Jurisdiction
            <div className='arrow-btn' onClick={handleShortingByJurisdiction}>
              <img src={sortingOrder === 'asc' && sortBy === 'jurisdiction' ? TopArrowActive : TopArrow} alt='' />
              <img src={sortingOrder === 'desc' && sortBy === 'jurisdiction' ? DownArrowActive : DownArrow} alt='' />
            </div>
          </li>
          <li>Miles</li>
          <li>Diesel Gallons</li>
        </ul>
        {vehicleData?.data?.map((data) => (
          <ul key={data.vehicle_id} className='fuel-price-history-table-header fuel-table-text '>
            <li>{data.vehicle_id} </li>
            <li>{data.jurisdiction}</li>
            <li>{formatAmount(Math.ceil(data.total), 0)}</li>
            <li>{data.gallons}</li>
          </ul>
        ))}
      </div>
    </div>
  );
};
