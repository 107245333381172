import React, { useEffect, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import Lottie from 'react-lottie';
import person from 'assets/icons/equipment/person.png';
import animationData from 'assets/animations/confetti.json';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import { getAlphabet, palette } from 'utils/constants';
import MapWidget from '../MapWidget';
import AcceptAward from '../AcceptAward';
import { STOP_POINT_TYPES } from '../../ShipmentBid.data';
import { SMapWrapper } from './Map.styles';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData,
  renderer: 'svg',
};

const Map = ({ shipment, getShipment, showAnimation }) => {
  const { planner, bid } = shipment || {};
  const [startingLocation, setStartingLocation] = useState(null);
  const [startRoute, setStartRoute] = useState(null);
  const [startMarker, setStartMarker] = useState(null);
  const [map, setMap] = useState(null);

  const initMap = () => {
    TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
    const routes = JSON.parse(planner.shipment_route_direction.route);
    const stops = planner.shipment_stops;
    const myMap = new TrimbleMaps.Map({
      container: 'myMap',
      style: TrimbleMaps.Common.Style.BASIC,
      center: routes[0]?.lng ? new TrimbleMaps.LngLat(routes[0]?.lng, routes[0]?.lat) : new TrimbleMaps.LngLat(-97, 40),
      zoom: 4,
      trackResize: true,
    });

    setMap(myMap);

    myMap.once('load', () => {
      routes.forEach((route, index, arr) => {
        const stopPointType = Number(stops[index]?.stop_point_type);
        const alphabet = getAlphabet(index);
        const prevSameStops = arr.slice(0, index).filter((i) => i.lat === route.lat && i.lng === route.lng);
        const marginTop = `${prevSameStops.length * 22}px`;

        const svgContent = document.createElement('div');

        svgContent.innerHTML = `
                             <div class='myMap_marker_container ${STOP_POINT_TYPES[stopPointType]?.className}'>
                                 <img src="${mapMarkerIcon}" alt=''>
                                 <div class='myMap_marker_text_wrapper' style='margin-top: ${marginTop}'>
                                   <span>STOP ${alphabet} : ${STOP_POINT_TYPES[stopPointType]?.label}</span>
                                 </div> 
                             </div>
                             `;
        new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([route.lng, route.lat])
          .addTo(myMap);
      });

      new TrimbleMaps.Route({
        routeId: 1,
        stops: routes.map((route) => {
          return { ...new TrimbleMaps.LngLat(route.lng, route.lat), stopPoint: true };
        }),
        routeColor: palette.indigo400,
        routeWidth: 5,
        showStops: false,
      }).addTo(myMap);
    });
  };

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (!map || !map.isStyleLoaded() || !startingLocation?.longitude || !startingLocation?.latitude) {
      return;
    }

    const routes = JSON.parse(planner.shipment_route_direction.route);

    if (startRoute) {
      startRoute.remove();
    }

    if (startMarker) {
      startMarker.remove();
    }

    const svgContent = document.createElement('div');

    svgContent.innerHTML = `
                             <div class='myMap_marker_container'>
                               <img src="${person}" alt=''>
                               <div class='myMap_marker_text_wrapper driver_marker'>
                                 <span>DEAD HEAD ${Math.round(startingLocation.empty_miles || 0)} Miles</span>
                               </div> 
                             </div>
                           `;

    const marker = new TrimbleMaps.Marker({
      draggable: false,
      element: svgContent,
    })
      .setLngLat([startingLocation?.longitude, startingLocation?.latitude])
      .addTo(map);

    const route = new TrimbleMaps.Route({
      routeId: 2,
      stops: [
        { ...new TrimbleMaps.LngLat(startingLocation?.longitude, startingLocation?.latitude), stopPoint: true },
        { ...new TrimbleMaps.LngLat(routes[0].lng, routes[0].lat), stopPoint: true },
      ],
      routeColor: palette.goldDark,
      routeWidth: 5,
      showStops: false,
    }).addTo(map);

    setStartMarker(marker);
    setStartRoute(route);
  }, [startingLocation, map]);

  return (
    <SMapWrapper>
      <MapWidget
        shipment={shipment}
        onSuccess={getShipment}
        onDeclineSuccess={getShipment}
        onStartingLocationChange={setStartingLocation}
      />
      <div id='myMap' className='shipment-bid-map' />
      {bid?.offer_accepted === 3 && <AcceptAward shipment={shipment} onSuccess={getShipment} />}
      {!!showAnimation && (
        <div className='confetti-animation'>
          <Lottie speed={2.5} options={animationOptions} height='100%' width='100%' isClickToPauseDisabled />
        </div>
      )}
    </SMapWrapper>
  );
};

export default Map;
