import React from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { skipSteps } from '../ApplyJob.data';
import { SFooter, SPrimaryButton, SSecondaryButton } from '../ApplyJob.styles';

const ApplyJobFooter = ({ step, currentStep, stepsCount, onBack, onNext, onSkip, disabled }) => {
  return (
    <SFooter step={step}>
      {step !== 0 && (
        <SSecondaryButton onClick={onBack} disabled={disabled}>
          <ArrowLeft />
          Back
        </SSecondaryButton>
      )}
      <div className='next-actions'>
        {skipSteps.includes(currentStep?.key) && (
          <SSecondaryButton onClick={onSkip} disabled={disabled} width='80px' $justify='center'>
            Skip
          </SSecondaryButton>
        )}
        {step === stepsCount - 2 ? (
          <SPrimaryButton onClick={currentStep.onNext || onNext} disabled={disabled}>
            Submit
          </SPrimaryButton>
        ) : (
          <SPrimaryButton onClick={currentStep.onNext || onNext} disabled={disabled} className='icon-button'>
            Next
            <ArrowRight />
          </SPrimaryButton>
        )}
      </div>
    </SFooter>
  );
};

export default ApplyJobFooter;
