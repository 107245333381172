export const getInitialValues = (settings) => {
  const { kyu_active, kyu_id, kyu_attach } = settings || {};

  return {
    kyu_active: !!kyu_active,
    kyu_id: kyu_id || '',
    kyu_attach: kyu_attach || null,
  };
};

export const kyuFields = {
  id: 1,
  title: 'KYU',
  policy_type: 'kyu',
  active: 'kyu_active',
  policy_id: 'kyu_id',
  attachment: 'kyu_attach',
};
