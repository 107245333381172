import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import button from 'assets/icons/drivers/button.svg';
import close from 'assets/icons/drivers/close.svg';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import AddressFields from 'common/AddressFields';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import CustomSelect from '../CustomSelect/CustomSelect';
import { validationSchema } from './validationSchema';
import './StaffGeneralInfo.css';

const GeneralInformation = ({
  updateGeneralProfile,
  staffInfo,
  onSuccess,
  loading,
  toggle,
  setToggle,
  driverOtherData,
}) => {
  const { formatDate } = useDateFormat();
  const user = JSON.parse(localStorage.getItem('user'));
  const [uniqueEmailError, setUniqueEmailError] = useState('');
  const [driverLicense] = useState([
    {
      name: 'License',
      id: 1,
    },
    {
      name: 'Passport',
      id: 2,
    },
    {
      name: 'ID',
      id: 3,
    },
  ]);

  const { use } = useTheme();

  const saveGeneral = (values) => {
    const payload = {
      ...values,
      address1: values?.address,
      country_id: values?.country?.id,
      state_id: values?.state?.id,
      city_id: values?.city?.id,
      zip: values?.zipcode,
      hire_date: values?.hire_date ? moment(values.hire_date).format('YYYY-MM-DD') : null,
      dob: values?.dob ? moment(values.dob).format('YYYY-MM-DD') : null,
      photo_identification_expiration: values?.photo_identification_expiration
        ? moment(values.photo_identification_expiration).format('YYYY-MM-DD')
        : null,
      photo_identification: values.photo_identification || null,
      photo_identification_type:
        values.photo_identification_type === 'Select Identification Type..' ? 0 : values.photo_identification_type || 0,
    };
    updateGeneralProfile(payload, null, successResponse, 'Staff has been updated successfully', setUniqueEmailError);
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      title: '',
      hire_date: new Date(),
      dob: null,
      address: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      zipcode: '',
      email: '',
      phone_number: '',
      photo_identification: '',
      photo_identification_expiration: new Date(),
      photo_identification_type: null,
      upload_identification_card: '',
      emergency_contact_first_name: '',
      emergency_contact_last_name: '',
      emergency_contact_number: '',
    },
    onSubmit: saveGeneral,
    validationSchema,
  });

  useEffect(() => {
    if (staffInfo) {
      formik.setFieldValue('first_name', staffInfo.first_name);
      formik.setFieldValue('title', staffInfo.title);
      formik.setFieldValue('last_name', staffInfo.last_name);
      formik.setFieldValue('email', staffInfo.email);
      formik.setFieldValue('phone_number', staffInfo.phone_number);
      formik.setFieldValue('address', staffInfo.address1);
      formik.setFieldValue('address2', staffInfo.address2);
      formik.setFieldValue('country', staffInfo.country);
      formik.setFieldValue('state', staffInfo.state);
      formik.setFieldValue('city', staffInfo.city);
      formik.setFieldValue('zipcode', staffInfo.zip);
      formik.setFieldValue('emergency_contact_first_name', staffInfo.emergency_contact_first_name || '');
      formik.setFieldValue('emergency_contact_last_name', staffInfo.emergency_contact_last_name || '');
      formik.setFieldValue('emergency_contact_number', staffInfo.emergency_contact_number || '');
      if (staffInfo.dob) {
        formik.setFieldValue('dob', moment(staffInfo.dob).toDate());
      }
    }
    if (driverOtherData) {
      formik.setFieldValue('photo_identification', driverOtherData.photo_identification);
      formik.setFieldValue('photo_identification_type', driverOtherData.photo_identification_type);
      if (driverOtherData.hire_date) {
        formik.setFieldValue('hire_date', moment(driverOtherData.hire_date).toDate());
      }
      formik.setFieldValue('photo_identification_type', driverOtherData.photo_identification_type);
      if (driverOtherData.photo_identification_expiration) {
        formik.setFieldValue(
          'photo_identification_expiration',
          moment(driverOtherData.photo_identification_expiration).toDate()
        );
      }
    }
  }, [staffInfo, driverOtherData]);

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const handleFieldChange = (event, value) => {
    if (event?.target) {
      const { name, value } = event.target;
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(event, value);
    }
  };

  return (
    <div
      className='generalInformation staff-generalInformation driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          General Information
        </p>
        {!toggle ? (
          staffInfo?.email !== user.customer.email && (
            <div>
              <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
            </div>
          )
        ) : (
          <div>
            <img src={close} alt='' onClick={() => setToggle(!toggle)} className='tick-close' />
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <img src={button} alt='' onClick={formik.handleSubmit} className='tick-close' />
            )}
          </div>
        )}
      </div>
      {toggle ? (
        <div className='information-edit-mode' style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <div className='staff-generalInformation-edit-left'>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Full Name <span className='required'>*</span>
              </p>
              <div className='staff-info-input'>
                <input
                  type='text'
                  className='reason-input first'
                  placeholder='Title'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                  }}
                  name='title'
                  id='title'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                />
                <input
                  type='text'
                  className='reason-input second'
                  placeholder='First Name'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                  }}
                  name='first_name'
                  id='first_name'
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                  onBlur={formik.handleBlur}
                />
                <input
                  type='text'
                  className='reason-input third'
                  placeholder='Last Name'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                  }}
                  name='last_name'
                  id='last_name'
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div />
            </div>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Phone Number <span className='required'>*</span>
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  // borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    width: '100%',
                  }}
                  addInternationalOption={false}
                  className='input-field-phone'
                  value={formik.values.phone_number}
                  onChange={(val) => formik.setFieldValue('phone_number', val)}
                  defaultCountry='US'
                  placeholder='Phone Number'
                />
              </div>
              <div />
            </div>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Email <span className='required'>*</span>
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  // borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use('black', palette.gray600),
                  }}
                  className='input-type info-email'
                  name='email'
                  id='email'
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue('email', e.target.value);
                    setUniqueEmailError('');
                  }}
                  value={formik.values.email}
                />
              </div>
              <ErrorMessage error={uniqueEmailError} />
              <div />
            </div>
            <div className='add-incident-container-wrapper'>
              <DatePicker
                required
                width='100%'
                name='hire_date'
                label='Hire Date'
                value={formik.values.hire_date}
                onChange={(date) => formik.setFieldValue('hire_date', date)}
                onBlur={formik.handleBlur}
                error={formik.touched.hire_date && formik.errors.hire_date}
              />
            </div>
            <div className='add-incident-container-wrapper'>
              <DatePicker
                name='dob'
                width='100%'
                label='Date of Birth'
                value={formik.values.dob}
                onChange={(date) => formik.setFieldValue('dob', date)}
                onBlur={formik.handleBlur}
                disableFuture
                error={formik.touched.dob && formik.errors.dob}
              />
            </div>
          </div>
          <div className='staff-generalInformation-edit-left'>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Photo Identification #
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  // borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use('black', palette.gray600),
                  }}
                  className='input-type info-email'
                  name='photo_identification'
                  id='photo_identification'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.photo_identification}
                />
              </div>
              <div />
            </div>
            <div className='add-incident-container-wrapper'>
              <DatePicker
                width='100%'
                name='photo_identification_expiration'
                label='Photo Identification Expiration'
                value={formik.values.photo_identification_expiration}
                onChange={(date) => formik.setFieldValue('photo_identification_expiration', date)}
                onBlur={formik.handleBlur}
                error={formik.touched.photo_identification_expiration && formik.errors.photo_identification_expiration}
              />
            </div>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Photo Identification Type
              </p>
              <CustomSelect
                defaultValue='Select Identification Type..'
                options={driverLicense}
                onChange={(value) => {
                  formik.setFieldValue('photo_identification_type', value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.photo_identification_type}
                id='photo_identification_type'
                name='photo_identification_type'
                // patchProperty="name"
              />
              <div />
            </div>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Emergency Contact First Name
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  // borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use('black', palette.gray600),
                  }}
                  className='input-type info-email'
                  name='emergency_contact_first_name'
                  id='emergency_contact_first_name'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.emergency_contact_first_name}
                />
              </div>
              <div />
            </div>
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Emergency Contact Last Name
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  // borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use('black', palette.gray600),
                  }}
                  className='input-type info-email'
                  name='emergency_contact_last_name'
                  id='emergency_contact_last_name'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.emergency_contact_last_name}
                />
              </div>
              <div />
            </div>
          </div>
          <div className='staff-generalInformation-edit-right'>
            <AddressFields values={formik.values} handleChange={handleFieldChange} required={false} />
            <div className='add-incident-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: use(palette.gray700, palette.gray50) }}>
                Emergency Contact Phone Number
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  // borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    width: '100%',
                  }}
                  addInternationalOption={false}
                  className='input-field-phone'
                  value={formik.values.emergency_contact_number}
                  onChange={(val) => formik.setFieldValue('emergency_contact_number', val)}
                  defaultCountry='US'
                  placeholder='Phone Number'
                />
              </div>
              <div />
            </div>
          </div>
        </div>
      ) : (
        <div className='information ccox-section'>
          <div className='staff-info-wrapper'>
            <div className='incident-details staff-info-wrapper-left'>
              <p className='property'>Full Name</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>{`${
                staffInfo?.first_name || ''
              } ${staffInfo?.last_name || ''}`}</p>
            </div>
            <div className='incident-details staff-info-wrapper-right'>
              <p className='property'>Photo Identification</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>
                {driverOtherData?.photo_identification || ''}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-right'>
              <p className='property'>Title</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>
                {staffInfo?.title || ''}
              </p>
            </div>
          </div>

          <div className='staff-info-wrapper'>
            <div className='incident-details staff-info-wrapper-left'>
              <p className='property'>Phone Number</p>
              <p className='property-detail choose-file' style={{ color: use(palette.gray900, palette.gray200) }}>
                {staffInfo?.phone_number || ''}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-left'>
              <p className='property'>Photo Identification Expiration</p>
              <p
                className='property-detail'
                style={{
                  color: use(palette.gray900, palette.gray200),
                }}
              >
                {driverOtherData?.photo_identification_expiration
                  ? formatDate(driverOtherData?.photo_identification_expiration)
                  : '-'}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-right'>
              <p className='property'>Address</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>
                <p>{`${staffInfo?.address1 || ''}`}</p>
                <p>{`${staffInfo?.address2 || ''}`}</p>
                <p>{`${staffInfo?.city?.name || ''}${staffInfo?.state?.name ? ',' : ''} ${
                  staffInfo?.state?.name || ''
                }  ${staffInfo?.zip || ''}`}</p>
                <p>{`${staffInfo?.country?.name || ''}`}</p>
              </p>
            </div>
          </div>
          <div className='staff-info-wrapper'>
            <div className='incident-details staff-info-wrapper-left'>
              <p className='property'>Email</p>
              <p className='property-detail choose-file' style={{ color: use(palette.gray900, palette.gray200) }}>
                {staffInfo?.email || ''}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-right'>
              <p className='property'>Photo Identification Type</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>
                {Number(formik.values.photo_identification_type) === 1
                  ? 'License'
                  : Number(formik.values.photo_identification_type) === 2
                  ? 'Passport'
                  : Number(formik.values.photo_identification_type) === 3
                  ? 'ID'
                  : ''}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-right'>
              <p className='property'>Date of Birth</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>
                {staffInfo?.dob ? formatDate(staffInfo.dob) : '-'}
              </p>
            </div>
          </div>
          <div className='staff-info-wrapper'>
            <div className='incident-details staff-info-wrapper-left'>
              <p className='property'>Emergency Contact Name</p>
              <p className='property-detail' style={{ color: use(palette.gray900, palette.gray200) }}>
                {staffInfo?.emergency_contact_first_name || staffInfo?.emergency_contact_last_name
                  ? `${staffInfo?.emergency_contact_first_name} ${staffInfo?.emergency_contact_last_name}`
                  : '-'}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-right'>
              <p className='property' style={{ whiteSpace: 'nowrap' }}>
                Emergency Contact Phone Number
              </p>
              <p className='property-detail choose-file' style={{ color: use(palette.gray900, palette.gray200) }}>
                {staffInfo?.emergency_contact_number || '-'}
              </p>
            </div>
            <div className='incident-details staff-info-wrapper-right' />
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralInformation;
