import { number, object } from 'yup';

export const validationSchema = object().shape({
  amortized_total: number().when(['every_settlement', 'amortized_settlement'], {
    is: (every_settlement, amortized_settlement) => every_settlement === 0 && amortized_settlement === 1,
    then: number().required('This filed is required').nullable(),
    otherwise: number().nullable(),
  }),
  amortized_month: number().when(['every_settlement', 'amortized_settlement'], {
    is: (every_settlement, amortized_settlement) => every_settlement === 0 && amortized_settlement === 1,
    then: number().required('This filed is required').nullable(),
    otherwise: number().nullable(),
  }),
  amortized_months: number().when(['every_settlement', 'amortized_settlement'], {
    is: (every_settlement, amortized_settlement) => every_settlement === 0 && amortized_settlement === 1,
    then: number().required('This filed is required').nullable(),
    otherwise: number().nullable(),
  }),
});
