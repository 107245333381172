import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { blockNotNumberChars } from 'utils/helpers';
import { getApplicantTypes, getEquipmentTypes, getOpenJobPositions } from 'Api/JobPositions';

const Position = ({ form, companyId, positionId }) => {
  const { use } = useTheme();
  const [applicantTypes, setApplicantTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [positions, setPositions] = useState([]);

  const { values, setValues, handleChange, touchedErrors } = form;

  const getApplicants = async () => {
    try {
      const response = await getApplicantTypes();
      setApplicantTypes(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipments = async () => {
    try {
      const response = await getEquipmentTypes(companyId);
      setEquipments((response?.data || []).filter((item) => item.id !== 1 && item.id !== 10));
    } catch (e) {
      // Do nothing
    }
  };

  const getPositions = async () => {
    try {
      const response = await getOpenJobPositions(companyId);
      const { data } = response;
      const publicJobs = (response?.data || []).filter((item) => item?.status?.name === 'Public');
      setPositions(publicJobs);
      if (!values.position) {
        const currentJob = data.find((item) => item.id === Number(positionId));
        handleChange('position', currentJob || null);
        handleChange('applicantType', currentJob.job_applicant_type || null);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getApplicants();
    getEquipments();
    getPositions();
  }, []);

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            name='applicantType'
            label='Applicant type'
            value={values.applicantType}
            onChange={(e, value) => {
              handleChange('applicantType', value);
              handleChange('equipment', []);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={applicantTypes}
            error={touchedErrors.applicantType}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name='position'
            label='Position'
            value={values.position}
            onChange={(e, value) => {
              setValues((prevState) => ({
                ...prevState,
                position: value,
                applicantType: value.job_applicant_type,
              }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={positions}
            error={touchedErrors.position}
            required
          />
        </Grid>
        {values?.applicantType?.id === 1 && (
          <Grid item xs={12}>
            <Autocomplete
              multiple
              required
              name='equipment'
              label='Equipment'
              labelKey='title'
              value={values.equipment}
              onChange={(e, value) => handleChange('equipment', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={equipments}
              error={touchedErrors.equipment}
            />
          </Grid>
        )}
        <Grid container item xs={12} columnSpacing={1.5} alignItems='center'>
          <Grid item xs={12}>
            <InputLabel required>Experience</InputLabel>
          </Grid>
          <Grid item>
            <Input
              type='number'
              name='years'
              className='number-input'
              value={values.years}
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              placeholder='00'
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray200) }}>
              Years,
            </Typography>
          </Grid>
          <Grid item>
            <Input
              type='number'
              name='months'
              className='number-input'
              value={values.months}
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              placeholder='00'
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray200) }}>
              months.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ErrorMessage error={touchedErrors.years || touchedErrors.months} />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Position;
