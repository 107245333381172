import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  position: relative;

  .settings-header {
    margin-bottom: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    .form-field-row {
      display: flex;
      align-items: center;
      column-gap: 8px;

      :not(:last-child) {
        border-bottom: 1px solid ${palette.gray50};
        padding-bottom: 18px;
      }
    }
  }
`;

export const SMergedInputs = styled.div`
  display: flex;

  input {
    border-radius: 6px 0 0 6px;
  }

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 33px;
  }
`;
