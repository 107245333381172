import React, { useState } from 'react';
import { ReactComponent as EyeIcon } from 'assets/icons/eyeS.svg';
import Input from 'common/Input';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { changePassword } from 'Api/Customers';
import { checkPasswordStrength } from 'utils/helpers';
import { validationSchema } from './validationSchema';

const ChangePassword = ({ open, onClose }) => {
  const showToaster = useShowToaster();
  const [strength, setStrength] = useState({ strength: '', color: '' });
  const [inputType, setInputType] = useState({
    old_password: 'password',
    new_password: 'password',
    confirm_password: 'password',
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      if (strength.strength < 3) {
        showToaster({ type: 'error', message: 'Password is weak, please pick better password!' });
        return;
      }

      setLoading(true);

      const body = {
        old_password: values.old_password,
        new_password: values.new_password,
      };

      const response = await changePassword(body);
      if (response.success) {
        showToaster({ type: 'success', message: 'Password updated successfully!' });
        onClose();
      } else {
        showToaster({ type: 'error', message: response.message });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    onSubmit,
    validationSchema,
  });

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    handleChange('new_password', value);
    setStrength(checkPasswordStrength(value));
  };

  const onEyeClick = (field) => {
    setInputType((prevState) => ({ ...prevState, [field]: prevState[field] === 'password' ? 'text' : 'password' }));
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Change Password'
      $bgColor={palette.gray0}
      $maxWidth='400px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'update',
          type: 'primary',
          title: 'Update Password',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <Input
          required
          type={inputType.old_password}
          label='Enter Current Password'
          name='old_password'
          values={values.old_password}
          onChange={handleChange}
          endAdornment={
            <EyeIcon
              fill={palette.gray300}
              width={16}
              height={16}
              className='end-adornment'
              onClick={() => onEyeClick('old_password')}
            />
          }
          error={touchedErrors.old_password}
        />
        <div>
          <Input
            required
            type={inputType.new_password}
            label='Enter New Password'
            name='new_password'
            values={values.new_password}
            onChange={handlePasswordChange}
            error={touchedErrors.new_password}
            endAdornment={
              <EyeIcon
                fill={palette.gray300}
                width={16}
                height={16}
                className='end-adornment'
                onClick={() => onEyeClick('new_password')}
              />
            }
          />
          {strength.strength && (
            <div className='d-flex gap-1 mt-1'>
              <Typography variant='b2'>Strength:</Typography>
              <Typography variant='b2' style={{ color: strength.color }}>
                {strength.strength}
              </Typography>
            </div>
          )}
        </div>
        <Input
          required
          type={inputType.confirm_password}
          label='Re-Enter New Password'
          name='confirm_password'
          values={values.confirm_password}
          onChange={handleChange}
          error={touchedErrors.confirm_password}
          endAdornment={
            <EyeIcon
              fill={palette.gray300}
              width={16}
              height={16}
              className='end-adornment'
              onClick={() => onEyeClick('confirm_password')}
            />
          }
        />
      </div>
    </Modal>
  );
};
export default ChangePassword;
