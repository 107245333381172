import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'common/Tooltip';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { STable, STableRow, STransactionsWrapper } from 'pages/Accounting/Payables/Payables.styles';

const Transactions = ({ account, transactions }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  if (!account) {
    return null;
  }

  return (
    <STransactionsWrapper>
      <div className='table-title'>
        <Typography variant='h3'>{account.account_name}</Typography>
      </div>
      <div className='table-container fixed-header'>
        <STable>
          <thead>
            <tr>
              <th>DATE</th>
              <th>REFERENCE ID</th>
              <th>PAYEE</th>
              <th>ACCOUNT</th>
              <th>MEMO</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((item) => (
              <STableRow key={item.id}>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {formatDate(item.date)}
                  </Typography>
                </td>
                <td>
                  <Tooltip title={item.reference_id}>
                    <span style={{ color: palette.gray700 }}>*****{item.reference_id?.slice(-5)}</span>
                  </Tooltip>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700, whiteSpace: 'noWrap' }}>
                    {item.payee?.name || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {item.account?.account_name || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {item.memo || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(item.debit)}
                  </Typography>
                </td>
              </STableRow>
            ))}
          </tbody>
        </STable>
        {!transactions?.length && <NoRecords />}
      </div>
    </STransactionsWrapper>
  );
};

export default Transactions;
