import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { sendResetPasswordEmail } from 'Api/User';

export default function ResetPassword({ ...props }) {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
  });

  const ResetPin = () => {
    setLoading(true);
    sendResetPasswordEmail({ email: formik.values.email })
      .then(() => {
        formik.setFieldValue('email', null);
        props.onHide();
        props.onSuccess('Email for reset password has been successfully sent!');
        setLoading(false);
      })
      .catch((error) => {
        props.showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  return (
    <div className='modal-container'>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Reset Password for {props?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.white, palette.darkborder),
          }}
        >
          <div className='content-wrapper-date'>
            <p className='text-style' style={{ color: use(palette.gray700, palette.gray50), marginLeft: '0' }}>
              Email Address
            </p>
            <div
              className='driverId-container'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                borderColor: use(palette.white, palette.darkborder),
              }}
            >
              <input
                type='text'
                className='email'
                id='email'
                placeholder='example@email.com'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  color: use(palette.gray700, palette.gray50),
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button disabled={!formik.values.email} className='next-step' type='submit' onClick={ResetPin}>
                Reset Password
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
