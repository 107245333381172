import moment from 'moment/moment';

export const getInitialValues = (transaction, form) => {
  const { date, deposit, memo, payee, account, payment, reconciled, reference_id } = transaction || {};

  return {
    date: date || form?.date ? new Date(date || form.date) : null,
    reference_id: reference_id || '',
    payee: payee || null,
    account: account || null,
    memo: memo || '',
    reconciled: !!reconciled,
    payment: payment || '',
    deposit: deposit || '',
  };
};

export const checkTransactionDateValidity = (date) => {
  if (!moment(date).isValid()) {
    return true;
  }

  const fiveYearsAgo = moment().subtract(5, 'years');
  const fiveYearsFromNow = moment().add(5, 'years');

  return !moment(date).isBetween(fiveYearsAgo, fiveYearsFromNow, undefined, '[]');
};
