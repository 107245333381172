import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import Chip from 'common/Chip';
import { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { updateBalanceDeduction } from 'Api/Driver';
import { updateStaffBalanceDeduction } from 'Api/Staff';
import { getInitialValues, getPaymentAmount } from './EditBalance.data';
import { SActions } from './EditBalance.styles';

const FormRow = ({ settlement, index, userType, balance, onSuccess, onOpenSettlementPdf }) => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const isProcessed = settlement[`${userType}_settlement`].status_id === 3;

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const updatedPaymentAmount = getPaymentAmount(balance.payment_amount, values.extra_payment, values.less_payment);

      const body = {
        settlement_id: settlement.settlement_id,
        amount: updatedPaymentAmount,
        less_payment: values.less_payment,
        extra_payment: values.extra_payment,
        balance: settlement.remainingBalance + Number(settlement.amount) - updatedPaymentAmount,
      };

      if (userType === 'driver') {
        const { data } = await updateBalanceDeduction(settlement[`${userType}_deduction_id`], body);
        showToaster({ type: 'success', message: 'Balance has been successfully updated!' });
        handleChange('edit', false);
        onSuccess(data.settlement);
      } else {
        const { data } = await updateStaffBalanceDeduction(settlement[`${userType}_deduction_id`], body);
        showToaster({ type: 'success', message: 'Balance has been successfully updated!' });
        handleChange('edit', false);
        onSuccess(data.settlement);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: getInitialValues(settlement, balance),
    onSubmit,
  });

  return (
    <tr className='body-row'>
      <td style={{ width: '5%' }}>
        <Typography variant='s2' style={{ display: 'inline-block' }}>
          {index + 1}
        </Typography>
      </td>
      <td style={{ width: '25%' }}>
        <div className='d-flex flex-column align-items-center'>
          <Typography variant='s2' style={{ display: 'inline-block' }}>
            {formatDate(settlement[`${userType}_settlement`].pay_period_start)} to{' '}
            {formatDate(settlement[`${userType}_settlement`].pay_period_end)}
          </Typography>
          <Typography variant='s2' style={{ display: 'inline-block' }}>
            (
            <Typography
              variant='s2'
              style={{
                display: 'inline-block',
                textDecoration: 'underline',
                color: isProcessed ? palette.indigo500 : palette.gray900,
                cursor: isProcessed ? 'pointer' : 'auto',
              }}
              onClick={() => onOpenSettlementPdf(settlement.settlement_id)}
            >
              ID#{settlement.settlement_id}
            </Typography>
            )
          </Typography>
        </div>
      </td>
      <td style={{ width: '10%' }}>
        <Typography variant='s2' style={{ display: 'inline-block' }}>
          {currency}
          {formatNumber(settlement.amount)}
        </Typography>
      </td>
      <td className='centered' style={{ width: '15%' }}>
        {values.edit ? (
          <InputWithIcon
            required
            type='number'
            placeholder='0.00'
            value={values.extra_payment}
            onChange={(e) => handleChange(`extra_payment`, e.target.value)}
            onKeyDown={blockNonPositiveNumbers}
            disabled={Number(values.less_payment) > 0}
          />
        ) : (
          <Typography variant='s2' style={{ display: 'inline-block' }}>
            {currency}
            {formatNumber(values.extra_payment)}
          </Typography>
        )}
      </td>
      <td className='centered' style={{ width: '15%' }}>
        {values.edit ? (
          <InputWithIcon
            required
            type='number'
            placeholder='0.00'
            value={values.less_payment}
            onChange={(e) => handleChange(`less_payment`, e.target.value)}
            onKeyDown={blockNonPositiveNumbers}
            disabled={Number(values.extra_payment) > 0}
          />
        ) : (
          <Typography variant='s2' style={{ display: 'inline-block' }}>
            {currency}
            {formatNumber(values.less_payment)}
          </Typography>
        )}
      </td>
      <td style={{ width: '10%' }}>
        {isProcessed ? (
          <div className='d-flex flex-column align-items-center'>
            <Chip labelColor={palette.green500} bgColor={palette.green0} label='PROCESSED' />
            <Typography variant='s2' style={{ display: 'inline-block' }}>
              (
              <Typography
                variant='s2'
                style={{
                  display: 'inline-block',
                  textDecoration: 'underline',
                  color: palette.indigo500,
                  cursor: 'pointer',
                }}
                onClick={() => onOpenSettlementPdf(settlement.settlement_id)}
              >
                ID#{settlement.settlement_id}
              </Typography>
              )
            </Typography>
          </div>
        ) : (
          <Chip labelColor={palette.gray600} bgColor={palette.gray200} label='PENDING' />
        )}
      </td>
      <td style={{ width: '10%' }}>
        <Typography variant='s2' style={{ display: 'inline-block' }}>
          {currency}
          {formatNumber(settlement.remainingBalance)}
        </Typography>
      </td>
      <td style={{ width: '10%' }}>
        {!isProcessed && (
          <SActions className='settings-table-item settings-table-actions-wrapper'>
            {!values.edit ? (
              <span className='action-wrapper' onClick={() => handleChange(`edit`, true)}>
                <EditIcon width={11} height={11} />
              </span>
            ) : (
              <SActions className='flex-item'>
                <CustomButton
                  width={12}
                  height={12}
                  type='secondary'
                  title=''
                  styleButton={{ padding: '7px 8px', margin: 0 }}
                  onClick={() => handleChange(`edit`, false)}
                  leftIcon={<Delete style={{ fill: palette.gray500 }} />}
                />
                <CustomButton
                  width={12}
                  height={12}
                  type='primary'
                  title=''
                  leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
                  styleButton={{ padding: '7px 8px', margin: 0 }}
                  onClick={handleSubmit}
                  disabled={loading}
                />
              </SActions>
            )}
          </SActions>
        )}
      </td>
    </tr>
  );
};

export default FormRow;
