import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { getAccountsList } from 'Api/Accounts';
import { getPaymentSource, getStats } from 'Api/CardManagement';
import Accounts from './components/Accounts';
import DepositWithdrawalSource from './components/DepositWithdrawalSource';
import TransferHistory from './TransferHistory';
import { SBackButton, SFlexWrapper, SLink, SWrapper } from './ManageBalance.styles';

const ManageBalance = () => {
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);
  const [stats, setStats] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [externalAccount, setExternalAccount] = useState(null);
  const [isOnboardingPassed, setIsOnboardingPassed] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.is_truckindigital === 1));
    } catch (e) {
      // Do nothing
    }
  };

  const getExternalAccount = async () => {
    try {
      const res = await getPaymentSource();
      setIsOnboardingPassed(res.status);
      if (res.status) {
        setExternalAccount(res.data);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getAllStats = async () => {
    setLoading(true);
    try {
      const { data } = await getStats();
      setStats(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStats();
    getAccounts();
    getExternalAccount();
  }, []);

  return (
    <div>
      <SBackButton onClick={() => navigate('/card-management')}>
        <ArrowLeft width={10} height={8} />
        <SLink to='/card-management'>Card Management</SLink>
      </SBackButton>
      <Typography variant='h1'>Manage Card Balance</Typography>
      <Divider margin='16px 0' />
      <SWrapper>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          Available Balance
        </Typography>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '80px', height: '28px' }} />
        ) : (
          <Typography variant='h2'>
            {currency}
            {formatNumber(stats?.balance || 0)}
          </Typography>
        )}
      </SWrapper>
      <SFlexWrapper>
        <DepositWithdrawalSource isOnboardingPassed={isOnboardingPassed} externalAccount={externalAccount} />
        <Accounts isOnboardingPassed={isOnboardingPassed} accounts={accounts} />
      </SFlexWrapper>
      <Divider margin='0 0 48px' />
      <TransferHistory />
    </div>
  );
};

export default ManageBalance;
