import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({
  row,
  onSendInvoice,
  onMarkPaid,
  onRevertInvoice,
  onDeleteInvoice,
  onViewPDF,
  onUploadDocuments,
  onViewNotes,
  onViewShipment,
  onEditCharges,
  onEditInvoice,
  onRefundAndRevert,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'sendInvoice':
        onSendInvoice();
        break;
      case 'editInvoice':
        onEditInvoice();
        break;
      case 'markPaid':
        onMarkPaid();
        break;
      case 'viewPDF':
        onViewPDF();
        break;
      case 'uploadDocuments':
        onUploadDocuments();
        break;
      case 'notes':
        onViewNotes();
        break;
      case 'revertInvoice':
        onRevertInvoice();
        break;
      case 'refundAndRevert':
        onRefundAndRevert();
        break;
      case 'deleteInvoice':
        onDeleteInvoice();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {onSendInvoice && <li onClick={(event) => onActionClick(event, 'sendInvoice')}>Send Invoice</li>}
      {onEditInvoice && <li onClick={(event) => onActionClick(event, 'editInvoice')}>Edit Invoice</li>}
      {onMarkPaid && <li onClick={(event) => onActionClick(event, 'markPaid')}>Mark Paid</li>}
      {onViewShipment && <li onClick={onViewShipment}>View Shipment</li>}
      {onViewPDF && <li onClick={(event) => onActionClick(event, 'viewPDF')}>View Invoice</li>}
      {onEditCharges && (
        <li
          onClick={() =>
            navigate(`/shipment/${row?.shipment?.shipment_id}?isEdit=true`, { state: { ...row, defaultTab: 4 } })
          }
        >
          Edit Charges
        </li>
      )}
      {onUploadDocuments && <li onClick={(event) => onActionClick(event, 'uploadDocuments')}>Upload Documents</li>}
      {onViewNotes && <li onClick={(event) => onActionClick(event, 'notes')}>Notes</li>}
      {onRevertInvoice && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'revertInvoice')}>
          Revert Invoice
        </li>
      )}
      {onRefundAndRevert && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'refundAndRevert')}>
          {row.is_stripe ? 'Refund and Revert' : 'Revert'}
        </li>
      )}
      {onDeleteInvoice && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'deleteInvoice')}>
          Delete Invoice
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
