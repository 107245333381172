import React, { useContext, useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import './CarrierProfileTab.css';
import CustomerLogTab from 'components/CustomerLogTab/LogTab';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { CarrierContext } from 'context/tableContext';
import GeneralInformationTab from '../GeneralInformationTab/GeneralInformationTab';
import ContactBookTab from '../ContactBookTab/ContactBookTab';
import PaymentTermsTab from '../PaymentTermsTab/PaymentTermsTab';
import DocumentsTab from '../DocumentsTab';
import CarrierTransactionsTab from '../CarrierShipmentsTab';
import NotesTab from '../NotesTab';
import OperationsTab from '../OperationTab/OperationTab';
import SafetyRecords from '../SafetyRecords/SafetyRecords';
import CarrierBillTab from '../CarrierBillTab';

export default function CarrierProfileTab({ carrier, onSuccess, company }) {
  const location = useLocation();
  const [key, setKey] = useState('General');
  const { use } = useTheme();
  const isDarkMode = use(palette.white, palette.dark800) !== '#FFFFFF';
  const { carrier_notes_count, open_bill_count } = carrier || {};

  const { openEdit } = useContext(CarrierContext);

  useEffect(() => {
    if (openEdit) {
      setKey(openEdit);
    }
  }, [openEdit]);

  useEffect(() => {
    if (location.state?.activeTab) {
      setKey(location.state.activeTab);
    }
  }, [location.state?.activeTab]);

  return (
    <Tabs
      id='controlled-tab-example'
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className={`mb-3 profile-tab justify-content-start ${isDarkMode ? 'dark-mode-tab' : ''}`}
      style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
    >
      <Tab eventKey='General' className='profile-tab-content' unmountOnExit title='General'>
        <GeneralInformationTab carrierInfo={carrier} onSuccess={onSuccess} />
      </Tab>
      <Tab eventKey='Operations' className='profile-tab-content' unmountOnExit title='Operations'>
        <OperationsTab carrierInfo={carrier} onSuccess={onSuccess} />
      </Tab>
      <Tab eventKey='Safety Records' className='profile-tab-content' unmountOnExit title='Safety Records'>
        <SafetyRecords />
      </Tab>
      <Tab eventKey='Contact Book' unmountOnExit className='profile-tab-content' title='Contact Book'>
        <ContactBookTab />
      </Tab>
      <Tab eventKey='Payment Terms' unmountOnExit className='profile-tab-content' title='Payment Terms'>
        <PaymentTermsTab company={company} />
      </Tab>
      <Tab eventKey='Shipments' unmountOnExit className='profile-tab-content' title='Shipments'>
        <CarrierTransactionsTab carrier={carrier} />
      </Tab>
      <Tab
        eventKey='Bills'
        unmountOnExit
        className='profile-tab-content'
        title={
          <>
            Bills
            {!!open_bill_count && <span className='carrier-bills-count'>{open_bill_count} </span>}
          </>
        }
      >
        {carrier && <CarrierBillTab carrier={carrier} onSuccess={onSuccess} />}
      </Tab>
      <Tab eventKey='Documents' unmountOnExit className='profile-tab-content' title='Documents'>
        <DocumentsTab />
      </Tab>
      <Tab eventKey='Log' unmountOnExit className='profile-tab-content' title='Log'>
        <CustomerLogTab type='carrier' />
      </Tab>
      <Tab
        eventKey='Notes'
        unmountOnExit
        className='profile-tab-content'
        title={
          <>
            Notes
            {!!carrier_notes_count && <span className='carrier-notes-count'>{carrier_notes_count} </span>}
          </>
        }
      >
        <NotesTab onSuccess={onSuccess} />
      </Tab>
    </Tabs>
  );
}
