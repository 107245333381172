import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import DefaultAvatar from 'assets/icons/profile.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import person from 'assets/images/person.svg';

import { getAlphabet, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getShipmentsEvents, resendShipmentConfirmation } from 'Api/Shipment';
import { getBrokerageSetting } from 'Api/CompanySettings';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import TableFooter from 'components/TableFooter/TableFooter';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { useSelector } from 'react-redux';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import { getErrorMessage } from 'utils/error';
import { generatePDF } from 'pages/CarrierConfirmation/GenerateCarrierConfirmation/helpers';
import { reviseConfirmation } from 'Api/Carriers';
import classes from './shipmentsEvents.module.css';

const ShipmentsEvents = ({ infoHeader }) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const { theme, use } = useTheme();
  const { id } = useParams();
  const { currency } = useSelector((state) => state.root);
  const navigate = useNavigate();
  const myTableRef = useRef(null).current;
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [shipmentEventsData, setShipmentEventsData] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState(null);
  const [barcodeImage, setBarcodeImage] = useState(null);

  const columns = useMemo(() => {
    return [
      {
        field: 'event',
        title: <Typography variant='c3'>EVENT</Typography>,
        render: (rowData) => {
          const { other_data, type } = rowData || {};
          const { file_path } = other_data || {};
          let foundedStop = null;
          if (rowData?.shipment_stop) {
            foundedStop = infoHeader.shipment_stops.findIndex((stop) => stop.id === rowData?.shipment_stop);
          }
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>
                {checkNullOrUndefined(rowData?.type?.title)}
                {foundedStop?.toString() && <span> {getAlphabet(foundedStop)}</span>}
              </Typography>
              {infoHeader?.communication_error > 0 && rowData.type?.id === 38 && (
                <WarningIcon fill={palette.red500} className='ms-1' />
              )}
              {!!file_path && (
                <Typography
                  variant='s2'
                  style={{ color: palette.indigo500, marginLeft: 10 }}
                  onClick={() => setPdfUrl(file_path)}
                >
                  View
                </Typography>
              )}
              {(type?.id === 40 || type?.id === 43) && !infoHeader?.brokerage_dispatch?.confirmation_signed && (
                <Typography
                  variant='s2'
                  style={{ color: palette.indigo500, marginLeft: 10 }}
                  onClick={viewConfirmation}
                >
                  View
                </Typography>
              )}
              {(type?.id === 40 || type?.id === 43) && !infoHeader?.brokerage_dispatch?.confirmation_signed && (
                <Typography
                  variant='s2'
                  style={{ color: palette.indigo500, marginLeft: 10 }}
                  onClick={() => resend(type.id)}
                >
                  Resend
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: 'event_description',
        title: <Typography variant='c3'>EVENT DESCRIPTION</Typography>,
        render: (rowData) => {
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{checkNullOrUndefined(rowData?.description)}</Typography>
            </div>
          );
        },
      },
      {
        field: 'equipment_id',
        title: <Typography variant='c3'>EQUIPMENT ID</Typography>,
        render: (rowData) => {
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{checkNullOrUndefined(rowData?.equipment?.equipment_id)}</Typography>
            </div>
          );
        },
      },
      {
        field: 'miles_traveled',
        title: <Typography variant='c3'>MILES TRAVELED</Typography>,
        render: (rowData) => {
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{checkNullOrUndefined(rowData?.miles_traveled?.toFixed())}</Typography>
            </div>
          );
        },
      },
      {
        field: 'location',
        title: <Typography variant='c3'>LOCATION</Typography>,
        render: (rowData) => {
          const { location } = rowData;
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{location || '-'}</Typography>
            </div>
          );
        },
      },
      {
        field: 'date',
        title: <Typography variant='c3'>DATE</Typography>,
        render: (rowData) => {
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{convertToCustomerTime(rowData?.date)}</Typography>
            </div>
          );
        },
      },
      {
        field: 'action_by',
        title: <Typography variant='c3'>ACTION BY</Typography>,
        render: (rowData) => {
          const {
            id,
            first_name,
            last_name,
            profile_logo,
            image,
            fname,
            lname,
            name: carrierName,
            logo_path,
          } = rowData?.action_by || {};
          const { api_name, other_data } = rowData;
          const isCarrier = rowData?.action_by_type;
          const actionType = rowData?.action_by_type?.split('\\')?.[1];
          const isUser = String(actionType).toLowerCase() === 'user';
          const url = isUser ? 'staff-profile' : 'driver-profile';
          if (api_name) {
            return (
              <div className={classNames(classes.colWrapper, classes.logoWrapper)}>
                <figure>
                  <img
                    style={{
                      display: 'flex',
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                    }}
                    src={truckinLogo}
                    alt='img'
                  />
                </figure>
                <Typography variant='s3' style={{ color: palette.indigo500 }}>
                  {api_name}
                </Typography>
              </div>
            );
          }
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                rowData.action_by && navigate(`/${url}/${id}/general`);
              }}
              className={classNames(classes.colWrapper, classes.logoWrapper)}
            >
              <figure>
                {rowData.action_by ? (
                  actionType === 'Carrier' ? (
                    logo_path ? (
                      <img src={logo_path} width='100%' height='100%' alt='img' />
                    ) : (
                      <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                    )
                  ) : (
                    <img src={isUser ? profile_logo || person : image || DefaultAvatar} alt='action_img' />
                  )
                ) : (
                  <img
                    style={{
                      display: 'flex',
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                    }}
                    src={truckinLogo}
                    alt='img'
                  />
                )}
              </figure>
              {rowData.action_by ? (
                <Typography variant='s3' style={{ color: palette.indigo500 }}>
                  {isUser ? first_name : fname}&nbsp;
                  {isUser ? last_name : lname}
                  {isCarrier ? carrierName : ''}
                </Typography>
              ) : other_data?.cellular_carrier_driver_name ? (
                <Typography variant='s3' style={{ color: palette.indigo500 }}>
                  {other_data?.cellular_carrier_driver_name}
                </Typography>
              ) : (
                <Typography variant='s3' style={{ color: palette.indigo500 }}>
                  System
                </Typography>
              )}
            </div>
          );
        },
      },
    ];
  }, [shipmentEventsData.data, theme, checkNullOrUndefined]);

  const shippersAndConsignees = infoHeader.shipment_stops
    ?.filter((stop) => Number(stop.stop_point_type) === 1 || Number(stop.stop_point_type) === 2)
    .map((el) => {
      if (Number(el.stop_point_type) === 1) {
        return { ...el, type: 'Shipper' };
      }
      return { ...el, type: 'Consignee' };
    });

  const pickupStops = shippersAndConsignees?.filter((stop) => Number(stop.stop_point_type) === 1);

  const total_weight = pickupStops?.reduce((acc, cur) => acc + Number(cur.weight), 0);
  const total_qty = pickupStops?.reduce((acc, cur) => acc + cur.quantity, 0);

  function checkNullOrUndefined(val) {
    return val === null || val === undefined ? '-' : val;
  }

  const getBarcode = (id, displayValue = true) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, id, { displayValue });
    return canvas.toDataURL('image/jpeg');
  };

  const getCompanyProfile = () => {
    const today = moment().format('MM/DD/YYYY HH:MM');
    const garlaxDate = today.replace(/[^0-9]/g, '');
    const barNumber = id ? `${id}-${garlaxDate}` : null;

    const image = getBarcode(barNumber);
    setBarcodeImage(image);
    getBrokerageSetting()
      .then((res) => {
        if (res?.data) {
          setCompany(res?.data);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        // Do nothing
      });
  };

  const viewConfirmation = async () => {
    const { url } = await generatePDF({
      shipment: infoHeader,
      company,
      shippersAndConsignees,
      currency,
      barcodeImage,
      pickupStops,
      total_weight,
      total_qty,
      values: infoHeader?.brokerage_dispatch,
    });
    setPdfUrl(url);
  };

  const resend = async (type) => {
    switch (type) {
      case 40:
        await resendConfirmation();
        break;
      case 43:
        revise();
        break;
      default:
    }
  };

  const resendConfirmation = async () => {
    const formData = new FormData();
    formData.append('shipment_id', id);
    try {
      const { success, message } = await resendShipmentConfirmation(formData);
      if (success) {
        showToaster({ type: 'success', message });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
  };

  const revise = () => {
    const formData = new FormData();
    formData.append('shipment_id', id);
    reviseConfirmation(formData)
      .then(() => {
        showToaster({ type: 'success', message: 'Success' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const getCols = (data) => {
    const { page = 1, itemsPerPage, loading = true } = data || {};
    const params = { page, itemsPerPage: itemsPerPage || rowPerPage };
    !!loading && setLoading(true);
    getShipmentsEvents({ shipment_id: id, params })
      .then((res) => setShipmentEventsData(res))
      .finally(() => !!loading && setLoading(false));
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    getCols({ page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
    const lastPage = shipmentEventsData.last_page;
    if (lastPage !== 1) getCols({ page, itemsPerPage: rowPerPage });
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={shipmentEventsData.total}
        lastPage={shipmentEventsData.last_page}
        totalLength={shipmentEventsData.data.length}
        currentPage={shipmentEventsData.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  useEffect(() => {
    getCompanyProfile();
    getCols();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant='h4'>Shipment Events</Typography>
      </div>

      <div style={{ margin: 1 }}>
        {loading ? (
          <EquipmentPreloaderSkeleton />
        ) : (
          <MaterialTableWrapper
            data={shipmentEventsData.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{ Pagination: PaginationComponent }}
            columns={columns}
          />
        )}
      </div>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </div>
  );
};

export default ShipmentsEvents;
