import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import authHeader from 'services/auth-header';
import './addhook.css';

const API_URL = process.env.REACT_APP_API_URL;

const UnHooked = ({ handleClose, unHookedData, UnHoookedMsgShow, UnhookedMsgClose, onSuccess = () => null }) => {
  const [row_id, id, title, Eq_id] = unHookedData;

  const Unhooked = (id) => {
    const body = { hooked_to_id: null };
    axios.put(`${API_URL}/equipment/${id}`, body, { headers: authHeader() }).then((res) => {
      UnHoookedMsgShow(res.data?.data);
      onSuccess();
      handleClose();
      nextAvailable().then();
      setTimeout(() => {
        UnhookedMsgClose();
      }, 3000);
    });
  };

  async function Trailer() {
    const { data } = await axios.get(`${API_URL}/equipment/hook-to/${id}`, {
      headers: authHeader(),
    });
    return data;
  }

  const { isError, isLoading, error } = useQuery('hooked', Trailer);
  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }

  async function nextAvailable() {
    const values = { equipment_id: row_id };
    axios.post(`${API_URL}/equipment/next-available-location`, values, {
      headers: authHeader(),
    });
  }

  return (
    <div className='equipment-list-unhooked-trailer'>
      <div className='remove-driver-dailogue-body'>
        <div className='remove-driver-dailogue-wrapper' style={{ background: '#fff' }}>
          <h6 className='remove-modal-header'>Unhook Vehicle</h6>
          <p className='remove-driver-paragraph'>
            Are you sure you want to unhook {title} {Eq_id} ?
          </p>
          <div className='d-flex justify-content-end remove-driver-footer mt-3'>
            <button className='cancel-button' onClick={handleClose}>
              Cancel
            </button>
            <button className='unhooked-button' type='submit' onClick={() => Unhooked(row_id)}>
              Yes, Unhook
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnHooked;
