import React, { useEffect, useState } from 'react';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { useTheme } from 'context/themeContext';
import { getIncidentTabsCount } from 'Api/DotMonitoring';
import { useNavigate } from 'react-router-dom';
import { getDriverHosList } from 'Api/HOS';
import TableFilters from 'pages/Hos-Logbook/TableSection/shared/TableFilters';
import AssignVehicle from './AssignVehicle';
import { useColumns } from './HOS.data';

let controller = null;

const HOSTable = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [driverHos, setDriverHos] = useState({});
  const [assignToDriverId, setAssignToDriverId] = useState(null);
  const [search, setSearch] = useState('');
  const [dutyStatuses, setDutyStatuses] = useState([]);
  const [sort, setSort] = useState({ field: 'time_until_break', sortBy: 'asc' });
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25, tab: 0, topTab: 0 });
  const debouncedDutyStatuses = useDebounce(dutyStatuses, 1000);
  const debouncedSearch = useDebounce(search, 500);

  const getDriverHos = async () => {
    setLoading(true);
    try {
      controller && controller.abort();
      controller = new AbortController();
      const sortField = `sort[][${sort.field}]`;

      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[query]': search || undefined,
        'filters[action_taken]': selectedFilters.tab ? selectedFilters.tab : undefined,
        'filters[risk_level]':
          selectedFilters.topTab === 'H' || selectedFilters.topTab === 'L' ? selectedFilters.topTab : undefined,
        'filters[infraction_type]': selectedFilters.infraction_type?.id || undefined,
        'filters[duty_status]': dutyStatuses.length ? dutyStatuses : undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getDriverHosList(params, controller.signal);
      setDriverHos(response);
    } catch (e) {
      // do something
    } finally {
      setLoading(false);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const getTabsCount = async (params) => {
    const data = await getIncidentTabsCount(params);
    const allTab = {
      count: data.all,
      label: 'All',
      key: 0,
      value: 0,
      id: 0,
    };
    const localTabs = [allTab];
    data.action_taken.forEach((item) => {
      localTabs.push({
        count: item.incident_count,
        label: item.action,
        key: item.id,
        value: item.id,
        id: item.id,
      });
    });
  };

  useEffect(() => {
    const params = {
      'filters[risk_level]':
        selectedFilters.topTab === 'H' || selectedFilters.topTab === 'L' ? selectedFilters.topTab : undefined,
      'filters[infraction_type]': selectedFilters.infraction_type?.id || undefined,
    };
    getTabsCount(params);
  }, [selectedFilters]);

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  useEffect(() => {
    getDriverHos();
  }, [selectedFilters, sort, debouncedDutyStatuses, debouncedSearch]);

  const navigateToEquipmentProfile = (e, id) => {
    e.stopPropagation();
    navigate(`/equipment-profile/vehicle/${id}`);
  };

  const columns = useColumns({
    sort,
    sortingQuery,
    setAssignToDriverId,
    navigateToEquipmentProfile,
  });
  return (
    <div className='d-flex flex-column gap-4'>
      <TableFilters search={search} setSearch={setSearch} setDutyStatuses={setDutyStatuses} />
      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={driverHos?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: () =>
              Pagination({
                data: driverHos,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [10, 25, 50, 100, 250],
              }),
          }}
          onRowClick={(e, rowData) => navigate(`/hos-logbook/${rowData.driver_id}`)}
          columns={columns}
        />
      )}
      {!!assignToDriverId && (
        <AssignVehicle
          open={!!assignToDriverId}
          onClose={() => setAssignToDriverId(null)}
          onSuccess={getDriverHos}
          driverId={assignToDriverId}
        />
      )}
    </div>
  );
};

export default HOSTable;
