import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'react-bootstrap';
import { ReactComponent as Gear } from 'assets/sidemanu/settings-20px.svg';
import Radio from 'common/Radio';
import useForm from 'hooks/useForm';
import Tooltip from 'common/Tooltip';
import Uploader from 'common/Uploader';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import AddAccount from 'pages/Accounting/Accounts/components/AddAccount';
import ChecksToPrintTemplate from 'components/SendPaymentModal/components/PrintChecksModal/ChecksToPrintTemplate/ChecksToPrintTemplate';
import { ACCOUNT_TYPE } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { updateAccount, getAccountsList } from 'Api/Accounts';
import { getCheckSettings, updateCheckSettings } from 'Api/Checks';
import { DEFAULT_CHECK } from './constant';
import classes from './Checks.module.scss';

const Checks = () => {
  const showToaster = useShowToaster();
  const signPad = useRef({});
  const [accounts, setAccounts] = useState([]);
  const [cropper, setCropper] = useState();
  const [cropperImage, setCropperImage] = useState();
  const [cropModalShow, setCropModalShow] = useState(false);
  const [company, setCompany] = useState({});
  const [isOpenAddOrEditModal, setIsOpenAddOrEditModal] = useState(false);

  const { values, setFieldValue, touchedErrors } = useForm({
    initialValues: {
      check_style: 1,
      is_company_logo_shown: false,
      is_bank_logo_show: false,
      is_signature_shown: false,
      paid_by_account: null,
      uploaded_logo: null,
      signature: null,
    },
    onSubmit,
  });

  function onSubmit() {}

  const getProfile = async () => {
    const data = await GetCompanyProfile();
    const company = {
      ...data,
      city: data?.city_id || '',
      state: data?.state_id || '',
      zip: data?.zip || '',
      company_name: data?.company_name || '',
      address1: data?.address1 || '',
      address2: data?.address2 || '',
      logo_path: data?.company_logo || '',
    };
    setCompany(company);
  };

  const getAccounts = useCallback(
    async (isUpdate) => {
      try {
        const { data } = await getAccountsList();
        const filteredData = data.filter((i) => i.account_type.id === ACCOUNT_TYPE.BANK && i.account_status === 1);
        if (isUpdate) {
          const founded = filteredData.find((el) => Number(el?.id) === Number(values?.paid_by_account?.id));
          founded && setFieldValue('paid_by_account', founded);
        }
        setAccounts(filteredData);
        return filteredData;
      } catch (e) {
        // Do nothing
      }
    },
    [values?.paid_by_account]
  );

  const getSettings = async () => {
    try {
      const { data } = await getCheckSettings();
      setFieldValue('check_style', data?.check_style);
      setFieldValue('is_company_logo_shown', !!Number(data?.is_company_logo_shown));
      setFieldValue('is_bank_logo_show', !!Number(data?.is_bank_logo_show));
      setFieldValue('is_signature_shown', !!Number(data?.is_signature_shown));
      setFieldValue('signature', data?.signature);
      if (signPad?.current && data?.signature && typeof data.signature === 'string') {
        fetch(data.signature)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              signPad.current.fromDataURL(reader.result);
            };
            reader.readAsDataURL(blob);
          })
          .catch(() => {
            // Do nothing
          });
      }
      return data;
    } catch (e) {
      // Do nothing
    }
  };

  const saveSettings = async () => {
    try {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('check_style', Number(values.check_style));
      formData.append('is_company_logo_shown', Number(values.is_company_logo_shown));
      formData.append('is_bank_logo_show', Number(values.is_bank_logo_show));
      formData.append('is_signature_shown', Number(values.is_signature_shown));
      if (!values?.signature) {
        formData.append('signature', '');
      } else if (values?.signature instanceof File) {
        formData.append('signature', values.signature);
      }
      formData.append('default_account_id', values.paid_by_account?.id || '');
      await updateCheckSettings(formData);
      showToaster({ type: 'success', message: 'Changes saved successfully!' });
    } catch (e) {
      // Do nothing
    }
  };

  const tooltipTitle = useMemo(() => {
    return values.paid_by_account && !values.paid_by_account?.account_logo
      ? 'There is no logo for this bank account, please upload first'
      : 'Please select the default funding account';
  }, [values.paid_by_account]);

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        setFieldValue('uploaded_logo', blob);
        setCropModalShow(false);
      });
    }
  };

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setCropperImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file);
  };

  const handelSaveUploadedLogo = async () => {
    try {
      if (values.uploaded_logo) {
        const formData = new FormData();
        if (typeof values.paid_by_account === 'object') {
          formData.append('account_logo', values.uploaded_logo);
          values?.paid_by_account?.account_starting_check &&
            formData.append('account_starting_check', values.paid_by_account.account_starting_check);
          formData.append('_method', 'PUT');
        }
        const { data } = await updateAccount(values.paid_by_account.id, formData);
        const filteredData = data.filter((i) => i.account_type.id === ACCOUNT_TYPE.BANK && i.account_status === 1);

        const paid_by_account = filteredData?.find((acc) => acc.id === values?.paid_by_account?.id);
        paid_by_account && setFieldValue('paid_by_account', paid_by_account);
        setAccounts(filteredData);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onSaveSignature = () => {
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      file && setFieldValue('signature', file);
    }, 'image/png');
  };

  const onClearSignature = () => {
    if (signPad?.current?.clear) {
      signPad.current.clear();
      setFieldValue('signature', null);
      setFieldValue('is_signature_shown', false);
    }
  };

  const signatureUrl = useMemo(() => {
    return values.signature
      ? typeof values.signature === 'string'
        ? values.signature
        : URL.createObjectURL(values.signature)
      : '';
  }, [values?.signature]);

  useEffect(() => {
    getProfile();
    getSettings().then((settings) => {
      return getAccounts().then((accounts) => {
        const selectedAccount = accounts.find((account) => account.id === settings?.default_account_id);
        if (selectedAccount) {
          setFieldValue('paid_by_account', selectedAccount);
        }
      });
    });
  }, []);

  return (
    <div className={classes.checksContainer}>
      <div className={classes.quickViewOptions}>
        <div className={classes.autocompleteWrapper}>
          <div>
            <Autocomplete
              options={accounts}
              name='paid_by_account'
              labelKey='account_name'
              value={values.paid_by_account}
              error={touchedErrors?.paid_by_account}
              label='Select default funding bank account'
              onChange={(e, val) => {
                !val?.account_logo && setFieldValue('is_bank_logo_show', false);
                setFieldValue('paid_by_account', val);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>

          <div className={classes.gearWrapper} onClick={() => setIsOpenAddOrEditModal(true)}>
            <Gear width={16} height={16} />
          </div>
        </div>

        {isOpenAddOrEditModal && (
          <AddAccount
            open={isOpenAddOrEditModal}
            uploaderLabel='Upload logo'
            account={values.paid_by_account}
            onSuccess={() => getAccounts(true)}
            onClose={() => setIsOpenAddOrEditModal(false)}
          />
        )}
        {values?.paid_by_account && !values.paid_by_account?.account_logo && (
          <div className='mt-2'>
            <Uploader
              type={1}
              label='Bank Logo'
              uploaderLabel='Upload logo'
              document={values.uploaded_logo}
              accept={['image/jpeg', 'image/png']}
              onDrop={(files) => imageSelected(files[0])}
              onRemove={() => setFieldValue('uploaded_logo', null)}
            />
            {cropModalShow && (
              <ImageCropModal
                style={{ zIndex: 1300 }}
                show={cropModalShow}
                onHide={() => setCropModalShow(false)}
                image={cropperImage}
                getCropData={getCropData}
                setCropper={setCropper}
              />
            )}
            {!!values.uploaded_logo && (
              <div className='w-100 d-flex justify-content-end mt-2'>
                <Button style={{ margin: '0' }} className='next-step px-lg-3' onClick={handelSaveUploadedLogo}>
                  Update Account logo
                </Button>
              </div>
            )}
          </div>
        )}
        <div className={classNames('mt-4', classes.radioButtons)}>
          <Typography variant='s2'>Check Style:</Typography>
          <div className='radio-wrapper'>
            <Radio
              value={1}
              label='Voucher'
              name='check_style'
              checked={+values.check_style === 1}
              onChange={() => setFieldValue('check_style', 1)}
            />
          </div>
          <div className='radio-wrapper'>
            <Radio
              value={2}
              label='Standard'
              name='check_style'
              checked={+values.check_style === 2}
              onChange={() => setFieldValue('check_style', 2)}
            />
          </div>
        </div>
        <div>
          <div className='mt-4'>
            <CustomCheckbox
              name='is_company_logo_shown'
              checked={values.is_company_logo_shown}
              onChange={(checked) => setFieldValue('is_company_logo_shown', checked)}
            >
              <Typography variant='s2' className='ms-2'>
                Show Company logo on checks
              </Typography>
            </CustomCheckbox>
          </div>

          {!values.paid_by_account || !values.paid_by_account?.account_logo ? (
            <Tooltip title={tooltipTitle}>
              <div className={classNames('mt-2', classes.disabled)}>
                <CustomCheckbox
                  name='is_bank_logo_show'
                  checked={values.is_bank_logo_show}
                  disabled={!values.paid_by_account || !values.paid_by_account?.account_logo}
                  onChange={(checked) => setFieldValue('is_bank_logo_show', checked)}
                >
                  <Typography variant='s2' className='ms-2'>
                    Show Bank logo on checks
                  </Typography>
                </CustomCheckbox>
              </div>
            </Tooltip>
          ) : (
            <div className='mt-2'>
              <CustomCheckbox
                name='is_bank_logo_show'
                checked={values.is_bank_logo_show}
                disabled={!values.paid_by_account}
                onChange={(checked) => setFieldValue('is_bank_logo_show', checked)}
              >
                <Typography variant='s2' className='ms-2'>
                  Show Bank logo on checks
                </Typography>
              </CustomCheckbox>
            </div>
          )}
          <div className={classNames('mt-2', { [classes.disabled]: !values.signature })}>
            <CustomCheckbox
              name='is_signature_shown'
              checked={values.is_signature_shown}
              disabled={!values.signature}
              onChange={(checked) => setFieldValue('is_signature_shown', checked)}
            >
              <Typography variant='s2' className='ms-2'>
                Show Signature on checks
              </Typography>
            </CustomCheckbox>
          </div>
        </div>
        <div className={classes.sigCanvasWrapper}>
          <SignatureCanvas
            ref={signPad}
            penColor='black'
            clearOnResize={false}
            onEnd={(e) => onSaveSignature(e)}
            canvasProps={{ className: classes.sigCanvas }}
          />

          <div className={classes.sigCanvasButtons}>
            <Button className='next-step px-lg-3' onClick={saveSettings}>
              Save
            </Button>
            <Button className='cancel-button px-lg-3' onClick={onClearSignature}>
              Clear
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.quickView}>
        <div className={classes.pdfDecoration}>
          {values.check_style === 2 ? (
            Array(3)
              .fill(0)
              .map((el, i) => {
                return (
                  <ChecksToPrintTemplate
                    key={`${el + i}`}
                    bank={values.paid_by_account || {}}
                    company={company}
                    check={DEFAULT_CHECK}
                    memoValue='348757845'
                    checkNumber='0012'
                    useBankLogo={values.is_bank_logo_show}
                    useSignature={values.is_signature_shown}
                    useCompanyLogo={values.is_company_logo_shown}
                    checkStyleVoucher={+values.check_style === 1}
                    signatureUrl={signatureUrl}
                  />
                );
              })
          ) : (
            <ChecksToPrintTemplate
              bank={values.paid_by_account || {}}
              company={company}
              check={DEFAULT_CHECK}
              memoValue='348757845'
              checkNumber='0012'
              useBankLogo={values.is_bank_logo_show}
              useSignature={values.is_signature_shown}
              useCompanyLogo={values.is_company_logo_shown}
              checkStyleVoucher={+values.check_style === 1}
              signatureUrl={signatureUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Checks;
