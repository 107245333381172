import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getCustomerInfo } from 'Api/JobPositions';
import { getInvoiceDetails } from 'Api/AccountingReceivables';

import InvoiceInfo from './components/InvoiceInfo';
import MakePayment from './components/MakePayment';
// import { appearance } from './InvoicePayment.data';
import { SWrapper, SSectionWrapper, SBackdrop } from './InvoicePayment.styles';

const InvoicePayment = () => {
  const { dot, invoiceId, isBatch } = useParams();
  const [searchParams] = useSearchParams();
  const [invoice, setInvoice] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const invoiceData = invoice?.invoice || invoice?.batch;
  const tryAgain = searchParams.get('retry');

  const isPaid = invoice?.due_amount === 0;
  const isFiled = !isPaid && tryAgain !== '1' && invoiceData?.stripe_payment_status === 'requires_payment_method';
  const isProcessing = !isPaid && invoiceData?.stripe_payment_status === 'processing';

  // const stripePromise = useMemo(() => {
  //   if (!invoice?.stripe_account_id) {
  //     return null;
  //   }
  //
  //   return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  //     stripeAccount: invoice.stripe_account_id,
  //   });
  // }, [invoice]);

  const getInvoiceData = async () => {
    try {
      const body = {
        dot_number: dot,
        invoice_id: invoiceId,
        is_batch: !!Number(isBatch),
      };
      const response = await getInvoiceDetails(body);
      setInvoice({ ...response.data, ...response });
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingPayment(false);
    }
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getCustomerInfo(dot);
      setCompanyInfo(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const onPaymentSuccess = () => {
    try {
      setLoadingPayment(true);
      setTimeout(async () => {
        await getInvoiceData();
      }, 6000);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getInvoiceData();
    getCompanyInfo();
  }, []);

  if (!invoice) {
    return null;
  }

  if (loadingPayment) {
    return (
      <SBackdrop open={loadingPayment}>
        <CircularProgress size={30} style={{ marginTop: '30px' }} />
        <Typography variant='h1' style={{ color: palette.white }}>
          Processing...
        </Typography>
      </SBackdrop>
    );
  }

  return (
    <SWrapper>
      <SSectionWrapper $isPaid={isPaid || isProcessing || isFiled}>
        <InvoiceInfo companyInfo={companyInfo} invoice={invoice} />
      </SSectionWrapper>
      {!isPaid && !isProcessing && !isFiled && (
        <SSectionWrapper>
          {!!invoice?.clientSecret && (
            // <Elements stripe={stripePromise} options={{ clientSecret: invoice.clientSecret, appearance }}>
            <MakePayment invoice={invoice} onSuccess={onPaymentSuccess} />
            // </Elements>
          )}
        </SSectionWrapper>
      )}
    </SWrapper>
  );
};

export default InvoicePayment;
