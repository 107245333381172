import { convertToCustomerTime } from 'utils/helpers';

export const getInitialValues = () => {
  return {
    isNew: true,
    date: convertToCustomerTime(Date.now()),
    time: convertToCustomerTime(Date.now(), 'HH:mm:ss'),
    amount: '0',
    quantity: 1,
    per_quantity_amount: '0',
    addition_type: null,
    every_settlement: 0,
    month_week_settlement: 1,
    one_time_addition: 0,
    day: 'Monday',
    month_week: 'Week',
    apply: true,
  };
};
