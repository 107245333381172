import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NoRecords from 'common/NoRecords';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { deleteRecurringBill, getRecurringBill } from 'Api/Payables';
import AddBill from 'pages/Accounting/Payables/components/AddBill';
import RowActions from 'pages/Accounting/Payables/TableSection/shared/RowActions';
import { recurringOption } from 'pages/Accounting/Payables/TableSection/TableSection.data';
import { STable, STableRow, STransactionsWrapper } from 'pages/Accounting/Payables/Payables.styles';

const Transactions = ({ vendor, data, onEditSuccess, onDeleteSuccess }) => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [billToDelete, setBillToDelete] = useState(null);
  const [billToEdit, setBillToEdit] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingBill, setLoadingBill] = useState(false);

  const onDeleteConfirm = async () => {
    try {
      await deleteRecurringBill(billToDelete.id);
      showToaster({ type: 'success', message: 'Recurring bill has been successfully deleted!' });
      onDeleteSuccess();
      setBillToDelete(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onEdit = async (bill) => {
    setLoadingBill(true);
    try {
      const { data } = await getRecurringBill(bill.id);
      setBillToEdit(data);
    } finally {
      setLoadingBill(false);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <STransactionsWrapper>
      {!vendor && (
        <div className='table-title'>
          <Typography variant='h3'>{data.name}</Typography>
        </div>
      )}
      <div className='table-container fixed-header'>
        <STable>
          <thead>
            <tr>
              <th>REFERENCE ID</th>
              <th>OCCURRENCE</th>
              <th>NEXT BILL DATE</th>
              <th>END DATE</th>
              <th>MEMO</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {data.recurring_bills?.map((bill) => (
              <STableRow key={bill.id} className='pointer' onClick={() => onEdit(bill)}>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {bill.reference_id}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    Every {bill.repeat_every_times} {recurringOption[bill.recurring_months]}
                    {bill.repeat_every_times > 1 ? 's' : ''}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {formatDate(bill.bill_due_date)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {bill.end_date ? formatDate(bill.end_date) : '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {bill.memo}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(bill.amount_due)}
                  </Typography>
                </td>
                <td className='actions-cell'>
                  <RowActions onEdit={() => onEdit(bill)} onDelete={() => setBillToDelete(bill)} />
                </td>
              </STableRow>
            ))}
          </tbody>
        </STable>
      </div>
      {!data.recurring_bills?.length && <NoRecords />}
      <ConfirmationModal
        title='Recurring Bill'
        open={!!billToDelete}
        onClose={() => setBillToDelete(null)}
        onConfirm={onDeleteConfirm}
        disabled={loadingDelete}
      />
      {!!billToEdit && (
        <AddBill
          open={!!billToEdit}
          onClose={() => setBillToEdit(null)}
          onSuccess={onEditSuccess}
          bill={billToEdit}
          isRecurring
        />
      )}
      <BackdropLoader loading={loadingBill} />
    </STransactionsWrapper>
  );
};

export default Transactions;
