import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .settings-header-item {
    width: calc(100% / 4 - 130px);
  }

  .settings-header-item:first-child {
    width: 180px;
  }

  .settings-header-item:nth-child(3) {
    width: 130px;
  }

  .settings-header-item:nth-child(4) {
    width: 280px;
  }

  .settings-header-item:nth-child(5) {
    width: 80px;
  }

  .settings-header-item:last-child {
    width: 70px;
  }
`;

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
  transition: background 0.3s;
  cursor: pointer;

  .settings-table-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% / 4 - 130px);
  }

  .settings-table-item:first-child {
    width: 180px;
  }

  .settings-table-item:nth-child(3) {
    width: 130px;
  }

  .settings-table-item:nth-child(4) {
    width: 280px;
  }

  .settings-table-item:nth-child(5) {
    width: 80px;
  }

  .settings-table-item:last-child {
    width: 70px;
  }

  .settings-table-actions-wrapper {
    visibility: hidden;
  }

  :hover {
    background: ${palette.indigo0};

    .settings-table-actions-wrapper {
      visibility: visible;
    }
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const SOption = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 1px 8px;
`;
