import React from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import ErrorMessage from 'common/ErrorMessage';
import classes from './masterAgreement.module.scss';
import PhoneInput from '../../../../common/PhoneInput';

const MasterAgreement = ({ company, carrier, form, signPad }) => {
  const {
    company_name,
    signature,
    signature_first_name,
    signature_last_name,
    master_agreement_title,
    address1,
    city,
    state,
    zipcode,
    phone_number,
    company_email,
  } = company || {};

  const {
    name,
    address1: carrierAddress1,
    city: carrierCity,
    state: carrierState,
    zipcode: carrierZipcode,
    email: carrierEmail,
  } = carrier || {};

  const { values, handleChange, touchedErrors } = form;

  const onClear = () => {
    signPad?.current?.clear();
    handleChange('signature', null);
  };

  return (
    <div id='master-aggrement'>
      <Typography variant='title2'>
        <span>This Agreement made this</span>
        <span
          style={{
            background: '#FFF',
            boxShadow: palette.boxShadow2,
            padding: '2px 6px',
            borderRadius: '6px',
            margin: '0 8px',
          }}
        >
          {moment().format('Do')}
        </span>
        <span> day of</span>
        <span
          style={{
            background: '#FFF',
            boxShadow: palette.boxShadow2,
            padding: '2px 6px',
            borderRadius: '6px',
            margin: '0 8px',
          }}
        >
          {moment().format('MMMM YYYY')}
        </span>
        <span>
          by and between {company_name} located at {address1}, {city?.name}, {state?.short_name} {zipcode} and {name}{' '}
          located at {carrierAddress1}, {carrierCity?.name}, {carrierState?.short_name} {carrierZipcode} (hereafter
          Carrier).
        </span>
      </Typography>

      <div className='mt-3'>
        <Typography variant='title2'>
          WHEREAS, Carrier owns or controls motor vehicles, and is ready, willing and able to operate the vehicles for
          motor cargo transportation services (hereinafter referred to as "Services");
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          WHEREAS, {company_name} licensed by the U.S. Department of Transportation Federal Motor Carrier Safety
          Administration as a Broker of for the Transportation of Freight (excluding household goods) and, as such,
          requires cargo transportation services to meet the shipment needs of its customers; and{' '}
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          WHEREAS, the parties hereto are desirous of entering into this Agreement for the provision of and receipt of
          cargo transportation services according to the terms set forth.{' '}
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          NOW THEREFORE, IN CONSIDERATION of the mutual covenants contained herein, {name} and {company_name} agree as
          follows:{' '}
        </Typography>
      </div>

      <div className='mt-3'>
        <Typography variant='title2' dangerouslySetInnerHTML={{ __html: company?.master_agreement }} />
      </div>

      <div className='mt-3'>
        <Typography variant='title2'>
          IN WITNESS WHEREOF, we have signed this Agreement the date and year first shown above.{' '}
        </Typography>
      </div>

      <div className='d-flex justify-content-between w-100 mt-4'>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s1'>{company_name}</Typography>
            <div className={classes.broker_signature_area}>
              <img src={signature} alt='' />
            </div>
            <div style={{ height: '1px', background: palette.gray100, width: 360 }} />
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Broker, Authorized Signature
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Printed Name
            </Typography>
            <Typography variant='s1'>
              {' '}
              {signature_first_name} {signature_last_name}
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Title
            </Typography>
            <Typography variant='s1'>{master_agreement_title}</Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Company Address
            </Typography>
            <Typography variant='s1'>
              {' '}
              {address1}, {city?.name}, {state?.short_name} {zipcode}
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Phone
            </Typography>
            <Typography variant='s1'>{formatPhoneNumberIntl(phone_number)}</Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Email
            </Typography>
            <Typography variant='s1'>{company_email}</Typography>
          </div>
        </div>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex flex-column gap-1'>
            <div className='d-flex justify-content-between'>
              <Typography variant='s1'>{name}</Typography>
              <div className='d-flex align-items-center gap-3 me-2'>
                <span onClick={onClear} className={classes.clear}>
                  Clear
                </span>
              </div>
            </div>
            <div className={classes.signature_area}>
              <SignatureCanvas
                onBegin={() => {
                  handleChange('signature', 'Klir');
                }}
                clearOnResize={false}
                canvasProps={{ className: classes.sigCanvas }}
                penColor='black'
                ref={signPad}
              />
              <ErrorMessage error={touchedErrors.signature} />
              <div style={{ height: '1px', background: palette.gray100, width: 360 }} />
            </div>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Carrier, Authorized Signature
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Printed Name
            </Typography>
            <Input value={values.printedName} onChange={(e) => handleChange('printedName', e.target.value)} />
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Title
            </Typography>
            <Input value={values.title} onChange={(e) => handleChange('title', e.target.value)} />
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Company Address
            </Typography>
            <Typography variant='s1'>
              {' '}
              {carrierAddress1}, {carrierCity?.name}, {carrierState?.short_name} {carrierZipcode}
            </Typography>
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Phone
            </Typography>
            <PhoneInput
              name='phone'
              placeholder='+1 (555) 555-1234'
              onChange={(val) => handleChange('phone', val)}
              value={values.phone}
              error={touchedErrors.phone}
              required
            />
          </div>
          <div className={classes.boxWrapper}>
            <Typography variant='s1' style={{ color: palette.gray500 }}>
              Email
            </Typography>
            <Typography variant='s1'>{carrierEmail}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterAgreement;
