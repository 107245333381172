export const getInitialValues = (material) => {
  return {
    title: material?.title || '',
    description: material?.description || '',
    category_id: material?.category?.id || '',
    video: material?.video || '',
    document: material?.document || null,
    visible_to_id: material?.visible_to || [],
  };
};
