import React from 'react';
import { Typography } from 'components/Typography';
import { getAlphabet } from 'utils/constants';
import { getStopName } from 'componentsV2/Shipment/SplitShipment/SplitShipment.data';
import { SStopBox } from './StopBox.styles';

const StopBox = ({ stop, index }) => {
  return (
    <SStopBox>
      <Typography variant='c1' style={{ color: getStopName(Number(stop?.stop_point_type)).color }}>
        STOP {getAlphabet(index)} {getStopName(Number(stop?.stop_point_type)).type}
      </Typography>
    </SStopBox>
  );
};

export default StopBox;
