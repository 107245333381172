import React from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import sm from '../TypeOfShipment.module.css';
import CustomButton from '../../../CustomButton/CustomButton';

export const TypeOfShipmentFooter = ({ onCloseModal, onNext, loading }) => {
  const { use } = useTheme();
  return (
    <div className={sm.typeOfShipmentFooter} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <CustomButton
        type='secondary'
        title='Cancel'
        styleButton={{ padding: '6px 12px' }}
        styleTitle={{ fontSize: 14 }}
        onClick={onCloseModal}
      />

      <CustomButton
        type='primary'
        title='Next Step'
        styleButton={{ padding: '6px 12px' }}
        styleTitle={{ fontSize: 14 }}
        onClick={onNext}
        disabled={loading}
        buttonProps={{
          type: 'submit',
        }}
      />
    </div>
  );
};

export default TypeOfShipmentFooter;
