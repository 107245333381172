import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'components/Typography';
import CompanySettings from 'pages/CompanySettings/CompanySettings';
import { updateSetupComplete } from 'Api/User';
import { useAuth } from 'context/auth.context';
import ConfigureSettingsHeader from '../ConfigureSettingsHeader/ConfigureSettingsHeader';
import styles from './ConfigureSettings.module.css';

const ConfigureSettings = () => {
  const navigate = useNavigate();
  const { value, setAuth } = useAuth();

  const onConfigureFinish = async () => {
    await updateSetupComplete();
    const user = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('user', JSON.stringify({ ...user, is_setup_complete: 1 }));
    setAuth({
      ...value,
      user: { ...value.user, is_setup_complete: 1 },
    });

    navigate('/dashboard');
  };

  return (
    <>
      <ConfigureSettingsHeader />
      <div className={styles.wrapper}>
        <div className={styles.subHeader}>
          <Typography variant='h1'>Configure Settings</Typography>
        </div>
        <CompanySettings isConfiguringStep onConfigureFinish={onConfigureFinish} />
      </div>
    </>
  );
};
export default ConfigureSettings;
