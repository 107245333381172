import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { palette } from 'utils/constants';
import { getOnboardingSettings, getPremiumPlans } from 'Api/Onboarding';
import { updatePlans } from 'store/reducers/onboarding.reducer';
import BrokerInfo from './BrokerInfo';
import CarrierInfo from './CarrierInfo';
import AccountType from './AccountType';
import './onboardingAccount.css';

const OnboardingAccount = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [accountType, setAccountType] = useState(1);

  const getPlans = async () => {
    try {
      const settings = await getOnboardingSettings();
      const response = await getPremiumPlans({ customer_type: accountType === 1 ? 'carrier' : 'carrier_broker' });
      const data = response.map((plan) => {
        const sortedPlanTypes = plan.plan_types.sort((a, b) => a.amount - b.amount);
        return {
          ...plan,
          plan_types: sortedPlanTypes.filter(
            (item) =>
              !(
                (!!settings?.hide_yearly_plan && item.duration === 'yearly') ||
                (!!settings?.hide_3_year_plan && item.duration === '3-Year')
              )
          ),
        };
      });
      dispatch(updatePlans(data));
    } catch (e) {
      // Do nothing
    }
  };

  const onContinue = () => {
    setStep((prevState) => prevState + 1);
  };

  const onGoBack = () => {
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    getPlans();
  }, [accountType]);

  return (
    <div className='account-onboarding'>
      <div className='steps'>
        <button
          onClick={() => setStep(1)}
          className='steps-btn'
          style={{ color: step === 1 ? palette.indigo500 : palette.gray600 }}
        >
          Step 1
        </button>
        <div className='horizontal-divider' />
        <button className='steps-btn' style={{ color: step === 2 ? palette.indigo500 : palette.gray600 }}>
          Step 2
        </button>
        {accountType === 3 && (
          <>
            <div className='horizontal-divider' />
            <button className='steps-btn' style={{ color: step === 3 ? palette.indigo500 : palette.gray600 }}>
              Step 3
            </button>
          </>
        )}
      </div>
      {step === 1 && (
        <AccountType accountType={accountType} onAccountTypeChange={setAccountType} onContinue={onContinue} />
      )}
      {step === 2 && <CarrierInfo accountType={accountType} onContinue={onContinue} onGoBack={onGoBack} />}
      {step === 3 && <BrokerInfo onGoBack={onGoBack} />}
    </div>
  );
};

export default OnboardingAccount;
