import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import { Fade, Popper } from '@mui/material';
import userDefault, { ReactComponent as UserIcon } from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';

import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SUserInfo } from '../../Cards.styles';
import { SPaper, SNameImage } from './UserInfo.styles';

const UserInfo = forwardRef(({ data }, ref) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const onMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const { id, first_name, last_name, phone_number, email, profile_logo } = data.user || {};

  return (
    <SUserInfo onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onMouseEnter}>
      <Popper open={open} anchorEl={anchorEl} placement='top-start' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SPaper ref={ref} onClick={(e) => e.stopPropagation()}>
              <div className='user-info-header'>
                <SNameImage>
                  <div className='profile-image-container'>
                    <img src={profile_logo || userDefault} alt='' width={32} height={32} />
                  </div>
                  <Typography variant='b2' style={{ color: use(palette.dark700, palette.gray200) }}>
                    {first_name} {last_name}
                  </Typography>
                </SNameImage>
              </div>
              <div className='user-info-content'>
                <ul>
                  <li onClick={(e) => e.stopPropagation()}>
                    <PhoneIcon width={22} height={22} />
                    <a href={`tel:${phone_number}`}>{phone_number}</a>
                  </li>
                  <li onClick={(e) => e.stopPropagation()}>
                    <MailIcon width={22} height={12} />
                    <a href={`mailto:${email}`}>{email}</a>
                  </li>
                  <li onClick={() => navigate(`/chat`)}>
                    <ChatIcon width={22} height={12} />
                    Chat
                  </li>
                  <li
                    onClick={() =>
                      navigate(
                        data.user_type.toLowerCase() === 'staff'
                          ? `/staff-profile/${id}/general`
                          : `/driver-profile/${id}/general`
                      )
                    }
                  >
                    <UserIcon width={22} height={16} />
                    Profile
                  </li>
                </ul>
              </div>
            </SPaper>
          </Fade>
        )}
      </Popper>
      <img src={profile_logo || userDefault} alt='' className='profile-image' />
      <div className='user-name-wrapper'>
        <Typography variant='s2' style={{ color: use(palette.indigo500) }} className='user-name'>
          {first_name} {last_name}
        </Typography>
        <span className='user-type'>{capitalize(data.user_type)}</span>
      </div>
    </SUserInfo>
  );
});

export default UserInfo;
