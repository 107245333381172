import React, { useEffect, useState } from 'react';
import { getEquipmentStatusCount } from 'Api/Dashboard';
import { palette } from 'utils/constants';
import DoughnutChart from '../../Charts/DoughnutChart';

const labels = {
  0: 'On Shipment',
  1: 'Available',
  2: 'Out of Service',
};

const EquipmentStatus = () => {
  const [loading, setLoading] = useState(false);
  const [equipmentStatusCounts, setEquipmentStatusCounts] = useState(null);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getEquipmentStatusCount();
      setEquipmentStatusCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [
        equipmentStatusCounts?.on_shipment,
        equipmentStatusCounts?.available,
        equipmentStatusCounts?.out_of_service,
      ],
      backgroundColor: [palette.indigo400, palette.green400, palette.red400],

      borderColor: [palette.indigo400, palette.green400, palette.red400],
      hoverBackgroundColor: [palette.indigo500, palette.green500, palette.red500],
      hoverBorderColor: [palette.indigo500, palette.green500, palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={equipmentStatusCounts?.total}
        title='Equipment Status'
      />
    </div>
  );
};

export default EquipmentStatus;
