import React, { useState } from 'react';
import { ReactComponent as OpenArrow } from 'assets/icons/arrowDown.svg';
import { ReactComponent as CloseArrow } from 'assets/icons/arrowUp.svg';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import PreviousEmploymentNotes from 'componentsV2/PreviousEmploymentNotes';
import PreviousEmploymentModal from 'components/PreviousEmploymentModal/PreviousEmploymentModal';
import MedicalAndBackgroundModal from 'components/MedicalAndBackgroundModal/MedicalAndBackgroundModal';
import { onDownload } from 'pages/Accounting/Receivables/TableSection/shared/SendInvoice/SendInvoice.data';
import { palette } from 'utils/constants';
import { RowActions, SubRowActions } from './RowActions';
import { getLatestAttempt } from '../../PreviousEmployment.data';
import { SAccordionContainer, SAddMore, SSubTableContainer } from '../../PreviousEmployment.styles';

const TableAccordion = ({ row, userType, onEditEmployer, deleteEmployer, deleteAttempt, toggleAccordion }) => {
  const [previousEmploymentModal, setPreviousEmploymentModal] = useState(null);
  const [viewModalShow, setViewModalShow] = useState({ isOpen: false, data: {} });
  const latestAttempt = getLatestAttempt(row[`${userType}_employment_attempts`], 'request_date');
  const [rowToViewNotes, setRowToViewNotes] = useState(null);

  const onClickView = (path1) => {
    const data = { imagePath: path1 || '' };
    setViewModalShow({ isOpen: true, data });
  };

  return (
    <div style={{ backgroundColor: row.isActive ? palette.indigo0 : '' }}>
      <SAccordionContainer
        onClick={(e) => {
          e.stopPropagation();
          toggleAccordion(row);
        }}
      >
        <div className='d-flex align-items-center gap-2'>
          {row.isActive ? <CloseArrow fill={palette.gray500} /> : <OpenArrow fill={palette.gray500} />}
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row?.name}
          </Typography>
        </div>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {latestAttempt?.request_date || '-'}
        </Typography>
        <div className='d-flex align-items-center gap-1'>
          <Chip label={row?.start_date} bgColor={palette.gray0} labelColor={palette.gray500} size='small' />
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
          <Chip label={row?.end_date} bgColor={palette.gray0} labelColor={palette.gray500} size='small' />
        </div>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {latestAttempt?.request_type === 'Pdf' ? 'PDF' : latestAttempt?.request_type || '-'}
        </Typography>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {latestAttempt?.request_form_source || '-'}
        </Typography>
        <div className='d-flex align-items-center gap-1'>
          <Chip
            label={latestAttempt?.response}
            bgColor={latestAttempt?.response === 'Verified' ? palette.green0 : palette.orange0}
            labelColor={latestAttempt?.response === 'Verified' ? palette.green500 : palette.orange500}
            size='small'
          />
        </div>
        <div className='d-flex align-items-center gap-4'>
          {!!latestAttempt?.request_type && (
            <SAddMore
              onClick={(e) => {
                e.stopPropagation();
                latestAttempt.request_type === 'Pdf'
                  ? onClickView(latestAttempt.request_form_path)
                  : setPreviousEmploymentModal(row);
              }}
            >
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                View
              </Typography>
            </SAddMore>
          )}
          {!!latestAttempt?.response_path && (
            <SAddMore
              onClick={(e) => {
                e.stopPropagation();
                onDownload(latestAttempt?.response_path, 'response-form');
              }}
            >
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Download
              </Typography>
            </SAddMore>
          )}
        </div>
        <RowActions row={row} onEdit={onEditEmployer} onDelete={deleteEmployer} />
      </SAccordionContainer>
      {row.isActive && (
        <SSubTableContainer style={{ backgroundColor: palette.white }} onClick={(e) => e.stopPropagation()}>
          <table className='custom-table-wrapper'>
            <thead className='custom-table-header-wrapper'>
              <tr className='custom-table-row-wrapper custom-table-row-wrapper-header'>
                <td>
                  <ColumnHeader title='REQUEST DATE' field='request_date' />
                </td>
                <td>
                  <ColumnHeader title='REQUEST TYPE' field='request_type' />
                </td>
                <td>
                  <ColumnHeader title='TRANSMIT METHOD' field='request_form_source' />
                </td>
                <td>
                  <ColumnHeader title='RESPONSE STATUS' field='response' />
                </td>
                <td>
                  <ColumnHeader title='RESPONSE FORM' field='response_path' />
                </td>
                <td>
                  <ColumnHeader title='NOTES' field='date' />
                </td>
                <td />
              </tr>
            </thead>
            <tbody>
              {row[`${userType}_employment_attempts`]?.map((rowData) => {
                return (
                  <tr className='custom-table-row-wrapper' key={rowData.id}>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {rowData?.request_date || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {rowData?.request_type === 'Pdf' ? 'PDF' : rowData?.request_type || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {rowData?.request_form_source || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Chip
                        label={rowData?.response}
                        bgColor={rowData?.response === 'Verified' ? palette.green0 : palette.orange0}
                        labelColor={rowData?.response === 'Verified' ? palette.green500 : palette.orange500}
                        size='small'
                      />
                    </td>
                    <td>
                      <div className='d-flex align-items-center gap-4'>
                        {!!rowData?.request_type && (
                          <SAddMore
                            onClick={(e) => {
                              e.stopPropagation();
                              rowData.request_type === 'Pdf'
                                ? onClickView(rowData.request_form_path)
                                : setPreviousEmploymentModal(row);
                            }}
                          >
                            <Typography variant='s2' style={{ color: palette.indigo500 }}>
                              View
                            </Typography>
                          </SAddMore>
                        )}
                        {!!rowData?.response_path && (
                          <SAddMore
                            onClick={(e) => {
                              e.stopPropagation();
                              onDownload(rowData?.response_path, 'response-form');
                            }}
                          >
                            <Typography variant='s2' style={{ color: palette.indigo500 }}>
                              Download
                            </Typography>
                          </SAddMore>
                        )}
                      </div>
                    </td>
                    <td>
                      <SAddMore
                        onClick={(e) => {
                          e.stopPropagation();
                          setRowToViewNotes(rowData);
                        }}
                      >
                        <Typography variant='s2' style={{ color: palette.indigo500 }}>
                          View
                        </Typography>
                      </SAddMore>
                    </td>
                    <td>
                      <SubRowActions row={rowData} onDelete={deleteAttempt} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SSubTableContainer>
      )}
      {!!viewModalShow.isOpen && (
        <MedicalAndBackgroundModal
          show={viewModalShow.isOpen}
          data={viewModalShow.data}
          onHide={() => setViewModalShow({ isOpen: false, data: {} })}
        />
      )}
      {!!previousEmploymentModal && (
        <PreviousEmploymentModal
          show={!!previousEmploymentModal}
          onHide={() => setPreviousEmploymentModal(null)}
          previousEmployment={previousEmploymentModal}
        />
      )}
      {!!rowToViewNotes && (
        <PreviousEmploymentNotes
          userType={userType}
          open={!!rowToViewNotes}
          onClose={() => setRowToViewNotes(null)}
          attempt={rowToViewNotes}
          employer={row}
        />
      )}
    </div>
  );
};

export default TableAccordion;
