import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import userDefault, { ReactComponent as UserIcon } from 'assets/icons/drivers/user-profile.svg';
import { Typography } from 'components/Typography';
import { palette, statusColor } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { staffStatus } from '../../TimeTracking.data';
import { SPaper } from './UserInfo.styles';
import { SNameImage } from '../TableAccordion/TableAccordion.styles';

const UserInfo = forwardRef(({ data }, ref) => {
  const { use } = useTheme();
  const navigate = useNavigate();

  const { id, first_name, last_name, phone_number, email, image, profile_logo, user_type } = data || {};

  return (
    <SPaper ref={ref} onClick={(e) => e.stopPropagation()}>
      <div className='user-info-header'>
        <SNameImage>
          <div className='profile-image-container'>
            <img src={image || profile_logo || userDefault} alt='' width={32} height={32} />
            <div
              className='profile-active-indicator'
              style={{
                backgroundColor:
                  data.user_type === 2
                    ? use(
                        statusColor[data?.status_data?.driver_status]?.color,
                        statusColor[data?.status_data?.driver_status]?.darkColor
                      )
                    : use(
                        statusColor[staffStatus[data?.login_status]]?.color,
                        statusColor[staffStatus[data?.login_status]]?.darkColor
                      ),
              }}
            />
          </div>
          <Typography variant='b2' style={{ color: use(palette.dark700, palette.gray200) }}>
            {first_name} {last_name}
          </Typography>
        </SNameImage>
      </div>
      <div className='user-info-content'>
        <ul>
          <li onClick={(e) => e.stopPropagation()}>
            <PhoneIcon width={22} height={22} />
            <a href={`tel:${phone_number}`}>{phone_number}</a>
          </li>
          <li onClick={(e) => e.stopPropagation()}>
            <MailIcon width={22} height={12} />
            <a href={`mailto:${email}`}>{email}</a>
          </li>
          <li onClick={() => navigate(`/chat`)}>
            <ChatIcon width={22} height={12} />
            Chat
          </li>
          <li
            onClick={() => navigate(user_type === 1 ? `/staff-profile/${id}/general` : `/driver-profile/${id}/general`)}
          >
            <UserIcon width={22} height={16} />
            Profile
          </li>
        </ul>
      </div>
    </SPaper>
  );
});

export default UserInfo;
