import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { Textarea } from 'common/Input';
import ViewPdf from 'components/ViewPdf';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import ShipmentsWarning from 'componentsV2/Request/ShipmentsWarning';
import { palette, PERMISSIONS } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getMaintenanceData } from 'Api/Request';
import { getAllShipments, getDriverPlannedShipments } from 'Api/Shipment';
import { requestValidationSchema } from '../Drivers/AddDriver/ValidationSchema';
import CustomSelect from '../CustomSelect/CustomSelect';
import './UpdateRequestModal.css';

export default function RequestUpdateModal({
  requestType,
  onSuccess,
  createLoading,
  id,
  name,
  udpdateRequestData,
  updateRequest,
  shipmentList,
  updateAbsence,
  ...props
}) {
  const { use } = useTheme();
  const { currency, permissions } = useSelector((state) => state.root);
  const [shipmentIds, setShipmentIds] = useState([]);
  const [maintenanceData, setMaintenanceData] = useState(null);
  const [image, setImage] = useState(null);
  const [plannedShipments, setPlannedShipments] = useState([]);

  const saveRequest = (values) => {
    const { req_start_datetime, req_end_datetime, request_amount, shipment_id } = values;
    const startDate = req_start_datetime ? moment(req_start_datetime || new Date()).format('YYYY-MM-DD') : new Date();
    const endDate = req_end_datetime ? moment(req_end_datetime || new Date()).format('YYYY-MM-DD') : new Date();
    const driverId = +id;
    const requestId = udpdateRequestData?.id;
    const amount = request_amount === '' ? null : +request_amount;
    const shipment = shipment_id === '' ? null : shipment_id;
    const index = udpdateRequestData?.tableData?.id;
    const payload = {
      ...values,
      req_start_datetime: startDate,
      req_end_datetime: endDate,
      driver_id: driverId,
      requestId,
      index,
      shipment_id: shipment,
      request_amount: amount,
    };

    if (
      udpdateRequestData?.req_status === 'Pending' &&
      values?.req_status === 'Approved' &&
      values.request_type === 'Time Off'
    ) {
      delete payload.absenceType;
      updateRequest(
        { ...payload, type: values.absenceType || null },
        props.onHide,
        successResponse,
        'Request has been updated successfully'
      );
    } else {
      delete payload.absenceType;
      updateRequest(payload, props.onHide, successResponse, 'Request has been updated successfully');
    }
  };

  const formik = useFormik({
    initialValues: {
      req_start_datetime: null,
      req_end_datetime: null,
      note: '',
      req_status: '',
      rej_reason: '',
      driver_id: id,
      user_details: null,
      request_type: '',
      request_amount: null,
      shipment_id: null,
      type: null,
      absenceType: null,
    },
    validationSchema: requestValidationSchema,
    onSubmit: saveRequest,
  });
  const { values } = formik;

  const getShipments = async () => {
    try {
      const response = await getAllShipments();
      const filteredShipmentIds = response.reduce((acc, cur) => {
        if ([6, 7].includes(Number(cur.status_id))) {
          return acc;
        }
        acc.push(cur.shipment_id);
        return acc;
      });

      setShipmentIds(filteredShipmentIds);
    } catch (e) {
      // Do nothing
    }
  };

  const getMaintenance = async () => {
    try {
      const response = await getMaintenanceData(udpdateRequestData.request_type_id);
      setMaintenanceData(response);
    } catch (e) {
      // Do nothing
    }
  };

  const getPlannedShipments = async () => {
    if (
      values.request_type === 'Time Off' &&
      moment(values.req_start_datetime).isValid() &&
      moment(values.req_end_datetime).isValid()
    ) {
      const { data } = await getDriverPlannedShipments({
        driver_id: udpdateRequestData.driver_id,
        start_date: moment(values.req_start_datetime).format('YYYY-MM-DD'),
        end_date: moment(values.req_end_datetime).format('YYYY-MM-DD'),
      });

      if (data?.length) {
        setPlannedShipments(data);
      } else {
        setPlannedShipments([]);
      }
    } else {
      setPlannedShipments([]);
    }
  };

  useEffect(() => {
    formik.setFieldValue('rej_reason', udpdateRequestData?.rej_reason || '');
    formik.setFieldValue(
      'req_start_datetime',
      udpdateRequestData?.req_start_datetime ? new Date(udpdateRequestData?.req_start_datetime) : new Date()
    );
    formik.setFieldValue(
      'req_end_datetime',
      udpdateRequestData?.req_end_datetime ? new Date(udpdateRequestData?.req_end_datetime) : new Date()
    );
    formik.setFieldValue('note', udpdateRequestData?.note);
    formik.setFieldValue(
      'req_status',
      udpdateRequestData?.req_status === 'P' ? 'Pending' : udpdateRequestData?.req_status
    );
    formik.setFieldValue('request_type', udpdateRequestData?.request_type);
    formik.setFieldValue('request_amount', udpdateRequestData?.request_amount || null);
    formik.setFieldValue('shipment_id', udpdateRequestData?.shipment_id || null);
  }, [udpdateRequestData]);

  const successResponse = (msg) => {
    onSuccess(msg);
  };

  useEffect(() => {
    getShipments();

    if (udpdateRequestData?.request_type === 'Maintenance') {
      getMaintenance();
    }
  }, []);

  useEffect(() => {
    getPlannedShipments();
  }, [
    udpdateRequestData?.driver_id,
    udpdateRequestData?.request_type,
    values.req_start_datetime,
    values.req_end_datetime,
  ]);

  return (
    <div>
      <Modal
        {...props}
        dialogClassName='modal-80w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        enforceFocus={false}
        backdrop='static'
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Update Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='request-modal-body-box driver-style-wrap'
          style={{
            background: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='request-modal-wrap position-relative'>
            <div className='request-container-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Request Type <span className='required'>*</span>
              </p>
              <CustomSelect
                options={requestType?.map((item) => item?.type)}
                defaultValue='Select Type...'
                value={formik.values.request_type}
                className='text-style ms-0  p-2 select-group'
                onChange={(value) => {
                  const getRequestType = requestType.find((type) => {
                    return type.type === value;
                  });
                  if (!getRequestType) {
                    return;
                  }
                  if (value === 'Pickup Number' || value === 'Delivery Number' || value === 'Time Off') {
                    formik.setFieldValue('request_amount', '');
                  } else {
                    formik.setFieldValue('request_amount', getRequestType?.amount);
                  }
                  if (
                    value === 'Time Off' ||
                    value === 'Cash Advance' ||
                    value === 'Time Off' ||
                    value === 'Fuel Advance'
                  ) {
                    formik.setFieldValue('shipment_id', '');
                  }
                  formik.setFieldValue('request_type', value);
                }}
                propertyName='type'
              />
            </div>
            <div className='request-container-wrapper'>
              <DatePicker
                required
                label='Request Start Date'
                name='req_start_datetime'
                value={formik.values.req_start_datetime}
                onChange={(date) => {
                  formik.setFieldValue('req_start_datetime', date);
                  if (moment(date).isValid() && moment(formik.values.req_end_datetime).isValid()) {
                    if (date.getTime() > formik.values.req_end_datetime.getTime()) {
                      formik.setFieldValue('req_end_datetime', date);
                    }
                  }
                }}
                onBlur={formik.handleBlur}
                disabled={
                  formik.values.request_type === 'Time Off'
                    ? false
                    : formik.values.request_type === 'Cash Advance' ||
                      formik.values.request_type === 'Fuel Advance' ||
                      formik.values.request_type === 'Need Maintenance' ||
                      formik.values.request_type === 'Request Call from Dispatch' ||
                      formik.values.request_type === 'Truck Broke Down'
                    ? true
                    : !!(
                        formik.values.request_type === 'Pickup Number' ||
                        formik.values.request_type === 'Delivery Number'
                      )
                }
                error={formik.touched.req_start_datetime && formik.errors.req_start_datetime}
              />
            </div>
            <div className='request-container-wrapper'>
              <DatePicker
                required
                label='Request End Date'
                name='req_end_datetime'
                value={formik.values.req_end_datetime}
                onChange={(date) => {
                  formik.setFieldValue('req_end_datetime', date);
                  if (moment(date).isValid() && moment(formik.values.req_start_datetime).isValid()) {
                    if (date.getTime() < formik.values.req_start_datetime.getTime()) {
                      formik.setFieldValue('req_start_datetime', date);
                    }
                  }
                }}
                onBlur={formik.handleBlur}
                disabled={
                  formik.values.request_type === 'Time Off'
                    ? false
                    : formik.values.request_type === 'Cash Advance' ||
                      formik.values.request_type === 'Fuel Advance' ||
                      formik.values.request_type === 'Need Maintenance' ||
                      formik.values.request_type === 'Request Call from Dispatch' ||
                      formik.values.request_type === 'Truck Broke Down'
                    ? true
                    : !!(
                        formik.values.request_type === 'Pickup Number' ||
                        formik.values.request_type === 'Delivery Number'
                      )
                }
                error={formik.touched.req_end_datetime && formik.errors.req_end_datetime}
              />
            </div>
            <div className='request-container-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{
                  marginLeft: '0',
                  color:
                    formik.values.request_type === 'Time Off'
                      ? use(palette.gray300, palette.gray200)
                      : formik.values.request_type === 'Pickup Number' ||
                        formik.values.request_type === 'Delivery Number' ||
                        formik.values.request_type === 'Need Maintenance' ||
                        formik.values.request_type === 'Request Call from Dispatch' ||
                        formik.values.request_type === 'Truck Broke Down'
                      ? use(palette.gray300, palette.gray200)
                      : use(palette.gray700, palette.gray200),
                }}
              >
                Amount <span className='required'>*</span>
              </p>
              <div>
                <input
                  type='number'
                  className='reason-input'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                  }}
                  id='request_amount'
                  name='request_amount'
                  placeholder={`${currency} Amount`}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.request_amount}
                  disabled={
                    formik.values.request_type === 'Time Off'
                      ? true
                      : !!(
                          formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number' ||
                          formik.values.request_type === 'Need Maintenance' ||
                          formik.values.request_type === 'Request Call from Dispatch' ||
                          formik.values.request_type === 'Truck Broke Down'
                        )
                  }
                />
              </div>
            </div>
            <div className='request-container-wrapper'>
              <div>
                <Autocomplete
                  required
                  label='Shipment ID'
                  value={formik.values.shipment_id}
                  options={shipmentIds}
                  onChange={(value) => formik.setFieldValue('shipment_id', value)}
                  disabled={
                    formik.values.request_type === 'Time Off'
                      ? true
                      : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                      ? true
                      : !(
                          formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number'
                        )
                  }
                />
              </div>
            </div>
            <div className='request-container-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Request Notes
              </p>
              <div>
                <textarea
                  className='request-text-area'
                  id='note'
                  name='note'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.note}
                  placeholder='Write request notes...'
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                  }}
                />
                <ErrorMessage error={formik.touched?.note && formik.errors.note} />
              </div>
            </div>
            {udpdateRequestData?.request_type === 'Maintenance' && !!maintenanceData?.equipment && (
              <div>
                <div className='d-flex mb-3'>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Equipment Type</InputLabel>
                    <Typography variant='b2'>
                      {maintenanceData.equipment.equipment_type_id === 1 ? 'Vehicle' : 'Trailer'}
                    </Typography>
                  </div>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Year</InputLabel>
                    <Typography variant='b2'>{maintenanceData.equipment.year}</Typography>
                  </div>
                </div>
                <div className='d-flex mb-3'>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Make</InputLabel>
                    <Typography variant='b2'>{maintenanceData.equipment.make}</Typography>
                  </div>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Model</InputLabel>
                    <Typography variant='b2'>{maintenanceData.equipment.model_id}</Typography>
                  </div>
                </div>
                <div className='d-flex mb-3'>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>VIN</InputLabel>
                    <Typography variant='b2'>{maintenanceData.equipment.vin}</Typography>
                  </div>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Tire Size</InputLabel>
                    <Typography variant='b2'>{maintenanceData.equipment.tire_size || '-'}</Typography>
                  </div>
                </div>
                <div className='d-flex mb-3'>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Owner</InputLabel>
                    <Typography variant='b2'>{maintenanceData.equipment.equipment_ownership?.owner || '-'}</Typography>
                  </div>
                  <div className='w-50'>
                    <InputLabel className='mb-0'>Mileage</InputLabel>
                    <Typography variant='b2'>{maintenanceData.milage || '-'}</Typography>
                  </div>
                </div>
              </div>
            )}
            {udpdateRequestData?.request_type === 'Maintenance' && !!maintenanceData?.defect_types?.length && (
              <div className='d-flex mb-3'>
                <div className='w-100'>
                  <InputLabel className='mb-0'>Defects</InputLabel>
                  <Typography variant='b2'>
                    {maintenanceData.defect_types.map((i) => i.defect.defect).join(', ')}
                  </Typography>
                </div>
              </div>
            )}
            {udpdateRequestData?.request_type === 'Maintenance' &&
              !!maintenanceData?.defect_types?.filter((i) => i.image)?.length && (
                <div className='request-container-wrapper'>
                  <p
                    className='text-style abcense-text-style mb-2'
                    style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                  >
                    Images
                  </p>
                  <div className='d-flex flex-wrap gap-2'>
                    {maintenanceData.defect_types
                      .filter((i) => i.image)
                      .map((item) => (
                        <img
                          src={item.image_file}
                          alt='defect'
                          width='200px'
                          height='200px'
                          onClick={() => setImage(item.image_file)}
                        />
                      ))}
                  </div>
                </div>
              )}
            {udpdateRequestData?.request_type === 'Maintenance' && !!maintenanceData?.signature_file && (
              <div className='request-container-wrapper'>
                <p
                  className='text-style abcense-text-style mb-2'
                  style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                >
                  Signature
                </p>
                <div className='d-flex flex-wrap gap-2 mt-1'>
                  <img src={maintenanceData?.signature_file} alt='signature' width='100%' height='180px' />
                </div>
              </div>
            )}
            <div className='request-container-wrapper'>
              <p className='text-type' style={{ color: use(palette.gray700, palette.gray200) }}>
                Status
              </p>
              <div className='request-card-type card-type'>
                <div className='card-type-container'>
                  <input
                    type='radio'
                    name='req_status'
                    // defaultChecked
                    onChange={formik.handleChange}
                    value='Pending'
                    checked={formik.values.req_status === 'Pending'}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <p className='card-type-name'>Pending</p>
                </div>
                <div className='card-type-container'>
                  <input
                    type='radio'
                    name='req_status'
                    onChange={formik.handleChange}
                    value='Approved'
                    checked={formik.values.req_status === 'Approved'}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <p className='card-type-name'>Accepted</p>
                </div>
                <div className='card-type-container'>
                  <input
                    type='radio'
                    name='req_status'
                    onChange={formik.handleChange}
                    value='Rejected'
                    checked={formik.values.req_status === 'Rejected'}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <p className='card-type-name'>Rejected</p>
                </div>
              </div>
            </div>
            {udpdateRequestData?.req_status === 'Pending' &&
            formik.values?.req_status === 'Approved' &&
            formik.values.request_type === 'Time Off' ? (
              <div className='absence-container-wrapper'>
                <p className='text-type' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Attendance Type
                </p>
                <div className='absence-card-type card-type'>
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='absenceType'
                      onChange={formik.handleChange}
                      value='1'
                      checked={formik.values.absenceType === '1'}
                      disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Excused
                    </p>
                  </div>
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='absenceType'
                      onChange={formik.handleChange}
                      value='2'
                      checked={formik.values.absenceType === '2'}
                      disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Unexcused
                    </p>
                  </div>
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='absenceType'
                      onChange={formik.handleChange}
                      value='3'
                      checked={formik.values.absenceType === '3'}
                      disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Paid Time Off
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <span />
            )}
            {!!plannedShipments?.length && <ShipmentsWarning shipments={plannedShipments} />}
          </div>
          {formik.values?.req_status === 'Rejected' && (
            <Textarea
              required
              rows={3}
              label='Rejected Reason'
              name='rej_reason'
              value={formik.values.rej_reason}
              onChange={(e) => formik.setFieldValue('rej_reason', e.target.value)}
              error={formik.touched?.rej_reason && formik.errors.rej_reason}
              disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
            />
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {createLoading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={formik.handleSubmit} className='next-step' type='submit'>
                Update Request
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <ViewPdf title='View Image' open={!!image} pdfUrl={image} onClose={() => setImage(null)} />
    </div>
  );
}
