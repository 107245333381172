import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import styles from './AdditionalServicesHeader.module.css';

const AdditionalServicesHeader = ({
  selectedFilters,
  setSelectedFilters,
  setPurchaseAddOnsModal,
  totalRecurring,
  brokerageAmount,
}) => {
  const { use } = useTheme();
  const { billingDetail } = useSelector((state) => state.billing);
  const { customer_plan } = billingDetail || {};
  const duration = customer_plan?.plan?.duration;
  const totalRecurringNumber = Number((totalRecurring || '')?.toString()?.replaceAll(',', ''));

  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
      {() => {
        return (
          <Form>
            <div className={styles.addServHeader_wrapper}>
              <div className='d-flex align-items-center gap-3'>
                <Typography variant='h2' style={{ color: palette.gray900 }}>
                  Addon Licenses
                </Typography>
                <div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    {planMapper[duration]?.billingHeaderTitle} -
                  </Typography>{' '}
                  <Typography variant='h5' style={{ color: palette.gray900 }}>
                    ${formatNumber(totalRecurringNumber + brokerageAmount)}
                  </Typography>
                </div>
              </div>
              <div className={styles.header_right}>
                <div>
                  <Field name='cancelled'>
                    {({ field }) => {
                      return (
                        <CustomCheckbox
                          field={field}
                          checked={selectedFilters.showCancelled}
                          name='cancelled'
                          withinForm
                          onChange={(value) => setSelectedFilters({ ...selectedFilters, showCancelled: value })}
                        >
                          <Typography
                            variant='s2'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              marginLeft: 5,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Show Canceled
                          </Typography>
                        </CustomCheckbox>
                      );
                    }}
                  </Field>
                </div>
                <div style={{ marginLeft: 16 }}>
                  <CustomButton
                    type='primary'
                    title='Purchase Add-Ons'
                    styleButton={{ padding: '6px 12px', margin: 0 }}
                    styleTitle={{ fontSize: 14 }}
                    onClick={() => {
                      setPurchaseAddOnsModal(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AdditionalServicesHeader;
