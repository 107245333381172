import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import AddNotesTable from 'components/TableShipments/detailsRow/steps/Notes/AddNotes';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { addNote, deleteNote, getShipmentNotes } from 'Api/Shipment';
import { useColumns } from './AddNotes.data';

const AddNotes = ({ open, onClose, shipmentId }) => {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [notesData, setNotesData] = useState({ data: [] });
  const [rowPerPage, setRowPerPage] = useState(10);
  const [pdfUrl, setPdfUrl] = useState(null);

  const getNotes = (params) => {
    const { hasALoading = true, page = 1, sort, itemsPerPage } = params || {};
    if (hasALoading) setLoading(true);
    getShipmentNotes({
      page,
      itemsPerPage: itemsPerPage || rowPerPage,
      sort,
      shipment_id: shipmentId,
    })
      .then((res) => setNotesData(res))
      .finally(() => setLoading(false));
  };

  const createNote = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => values[key] !== '' && formData.append(key, values[key]));
    return addNote(formData);
  };

  function onDeleteRowItem(rowData) {
    deleteNote(rowData.id).then((res) => !!res?.success && getNotes());
  }

  const onAddNote = (form) => {
    const { validateForm, submitForm, values } = form;
    submitForm();
    validateForm().then((res) => {
      const isValid = Object.keys(res).length === 0;
      if (isValid) {
        const dataBack = { ...values, shipment_id: shipmentId };
        createNote(dataBack).then((res) => {
          if (res && res?.data) {
            form.resetForm();
            getNotes();
          }
        });
      }
    });
  };

  const sortingQuery = (field, sortBy) => {
    let newSortedData;
    if (sortData.find((el) => el.type === field)) {
      newSortedData = sortData.filter((el) => el.type !== field);
      newSortedData.unshift({ type: field, sortBy });
      setSortData(newSortedData);
    } else {
      newSortedData = [{ type: field, sortBy }, ...sortData];
      setSortData((p) => [{ type: field, sortBy }, ...p]);
    }
    getNotes({ sort: newSortedData, hasALoading: false });
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    getNotes({ page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
    const lastPage = notesData.last_page;
    if (lastPage !== 1) getNotes({ page, itemsPerPage: rowPerPage });
  };

  const onOpenNoteDocument = (url) => {
    setPdfUrl(url);
  };

  const columns = useColumns({ onDeleteRowItem, sortingQuery, onOpenNoteDocument });

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Notes'
      $bgColor={palette.white}
      $maxWidth='1100px'
      $minWidth='1100px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <AddNotesTable
        onSubmit={onAddNote}
        styleFormWrapper={{ backgroundColor: use(palette.white), marginTop: '-20px', width: '200px' }}
      />
      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          columns={columns}
          data={notesData.data}
          rowPerPage={rowPerPage}
          components={{
            Pagination: () =>
              Pagination({
                data: notesData,
                rowPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          style={{ backgroundColor: use(palette.white, palette.dark900) }}
        />
      )}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />}
    </Modal>
  );
};

export default AddNotes;
