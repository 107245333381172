import React from 'react';
import { useSelector } from 'react-redux';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';

const Title = ({ title }) => {
  return (
    <Typography variant='overLine' style={{ color: palette.gray900 }}>
      {title}
    </Typography>
  );
};

export const getInitialValues = (data) => {
  return data.map((detention, index) => {
    const paymentTerms = detention?.shipment_billing?.billing_customer?.customer_payment_term;
    const isTrailer = detention?.equipment?.equipment_type?.vehicle_type_id === 2;
    const rate = isTrailer ? paymentTerms?.detention_trailer_dropped_billed_at : paymentTerms?.billed_at;
    const daysHours = isTrailer ? paymentTerms?.detention_trailer_dropped_start_after_per : 'HOURS';
    const bill_rate_type_id = isTrailer && paymentTerms?.detention_trailer_dropped_start_after_per === 'DAYS' ? 2 : 1;
    const duration =
      daysHours === 'HOURS'
        ? (detention.duration_days * 24 + detention.duration_hours + detention.duration_minutes / 60).toFixed(2)
        : (detention.duration_days + detention.duration_hours / 24 + detention.duration_minutes / 1440).toFixed(2);
    const totalAmount = formatNumber(duration * rate);
    return {
      id: detention.id,
      shipmentId: detention.shipment_id,
      duration,
      rate,
      totalAmount,
      locationName: detention.shipment_stop?.stop_point?.location_name,
      bill_rate_type_id,
      index,
    };
  });
};

export const useColumns = ({ handleChange, values, touchedErrors }) => {
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'shipment_id',
      title: <Title title='SHIPMENT ID' />,
      render: (row) => <Typography variant='s2'>{row.shipmentId}</Typography>,
    },
    {
      field: 'location_name',
      title: <Title title='LOCATION NAME' />,
      render: (row) => <Typography variant='s2'>{row.locationName}</Typography>,
    },
    {
      field: 'bill_rate',
      title: <Title title='BILL RATE' />,
      render: (row) => {
        return (
          <Input
            width='100px'
            type='number'
            name={`${row.index}.rate`}
            onChange={handleChange}
            value={values[row.index].rate}
            error={touchedErrors.rate}
          />
        );
      },
    },
    {
      field: 'detention_duration',
      title: <Title title='DETENTION DURATION' />,
      render: (row) => {
        return (
          <Input
            width='100px'
            type='number'
            name={`${row.index}.duration`}
            onChange={handleChange}
            value={values[row.index].duration}
            error={touchedErrors.duration}
          />
        );
      },
    },
    {
      field: 'total_amount',
      title: <Title title='TOTAL AMOUNT' />,
      render: (row) => (
        <Typography variant='s2'>
          {currency}
          {formatNumber(row.rate * row.duration)}
        </Typography>
      ),
    },
  ];
};
