import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-radius: 8px;
  margin-bottom: 40px;
  min-width: 900px;

  .prev-employment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 60px;
    border-bottom: 1px solid ${palette.gray50};
  }
`;

export const SEditWrapper = styled.div`
  margin: 20px 10px;

  .employer-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: ${palette.gray0};
    border: 1px solid ${palette.gray50};
    border-radius: 8px;
    margin: 20px 0;
    padding: 20px;
  }

  .attempt-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: ${palette.gray0};
    border: 1px solid ${palette.gray200};
    border-radius: 8px;
    padding: 20px;
  }

  .uploader-wrapper {
    width: 200px;
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const STableHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;

  & > :nth-child(1) {
    width: 25%;
  }

  & > :nth-child(2) {
    width: 10%;
  }

  & > :nth-child(3) {
    width: 20%;
  }

  & > :nth-child(4) {
    width: 10%;
  }

  & > :nth-child(5) {
    width: 10%;
  }

  & > :nth-child(6) {
    width: 10%;
  }

  & > :nth-child(7) {
    width: 13%;
  }

  & > :nth-child(8) {
    width: 2%;
  }
`;

export const SAccordionContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 12px;
  align-items: center;
  transition: 0.4s;

  & > :nth-child(1) {
    width: 25%;
  }

  & > :nth-child(2) {
    width: 10%;
  }

  & > :nth-child(3) {
    width: 20%;
  }

  & > :nth-child(4) {
    width: 10%;
  }

  & > :nth-child(5) {
    width: 10%;
  }

  & > :nth-child(6) {
    width: 10%;
  }

  & > :nth-child(7) {
    width: 13%;
  }

  & > :nth-child(8) {
    width: 2%;
  }
`;

export const SSubTableContainer = styled.div`
  cursor: pointer;
  margin-top: 12px;
  border-left: 1px solid ${palette.gray50};

  .custom-table-wrapper {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: auto;
  }

  .custom-table-header-wrapper {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    display: table-header-group;
  }

  .custom-table-row-wrapper {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    transition: all 300ms ease 0s;
    cursor: pointer;

    &.custom-table-row-wrapper-header td {
      font-family: 'Inter', sans-serif !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      color: #464f60 !important;
      white-space: nowrap;
      padding: 8px 16px;
    }

    td {
      color: inherit;
      width: auto;
      box-sizing: border-box;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      text-align: left;
      display: table-cell;
      padding: 16px;
      line-height: 1.43;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      letter-spacing: 0.01071em;
      vertical-align: inherit;
      white-space: nowrap !important;
    }
  }
`;
