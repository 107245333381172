import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import { getAlphabet, palette } from 'utils/constants';
import { STOP_POINT_TYPES } from 'pages/Shipment/ShipmentBid/ShipmentBid.data';

export const SMapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);

  .lane-map {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
`;

const LaneMap = ({ stops }) => {
  const [markers, setMarkers] = useState([]);
  const [routs, setRouts] = useState([]);

  const initMap = () => {
    TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
    const myMap = new TrimbleMaps.Map({
      container: 'myMap',
      style: TrimbleMaps.Common.Style.BASIC,
      center: stops[0]?.stop_point?.geofencing_longitude
        ? new TrimbleMaps.LngLat(stops[0]?.stop_point?.geofencing_longitude, stops[0]?.stop_point?.geofencing_latitude)
        : new TrimbleMaps.LngLat(-97, 40),
      zoom: 10,
      trackResize: true,
    });

    if (markers.length) {
      markers.forEach((item) => item?.remove());
    }

    if (routs.length) {
      routs.forEach((item) => item?.remove());
    }

    myMap.once('load', () => {
      const markers = stops.map((stop, index, arr) => {
        const latitude = stop.stop_point.geofencing_latitude;
        const longitude = stop.stop_point.geofencing_longitude;

        if (!longitude || !latitude) {
          return;
        }

        const stopPointType = Number(stop?.stop_point_type);
        const alphabet = getAlphabet(index);

        const prevSameStops = arr.slice(0, index).filter((i) => i.lat === latitude && i.lng === longitude);
        const marginTop = `${prevSameStops.length * 22}px`;
        const svgContent = document.createElement('div');

        svgContent.innerHTML = `
                             <div class='myMap_marker_container ${STOP_POINT_TYPES[stopPointType]?.className}'>
                                 <img src="${mapMarkerIcon}" alt=''>
                                 <div class='myMap_marker_text_wrapper' style='margin-top: ${marginTop}'>
                                   <span>STOP ${alphabet} : ${STOP_POINT_TYPES[stopPointType]?.label}</span>
                                 </div>
                             </div>
                             `;
        return new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([longitude, latitude])
          .addTo(myMap);
      });

      setMarkers(markers);

      const route = new TrimbleMaps.Route({
        routeId: 1,
        stops: stops.map((stop) => {
          return {
            ...new TrimbleMaps.LngLat(stop.stop_point.geofencing_longitude, stop.stop_point.geofencing_latitude),
            stopPoint: true,
          };
        }),
        routeColor: palette.indigo400,
        routeWidth: 6,
        showStops: false,
      }).addTo(myMap);

      setRouts([route]);
    });
  };

  useEffect(() => {
    initMap();
  }, [stops]);

  return (
    <SMapWrapper>
      <div id='myMap' className='lane-map' />
    </SMapWrapper>
  );
};

export default LaneMap;
