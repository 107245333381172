import React, { useState } from 'react';
import XIcon from 'assets/icons/x.png';
import ChatAppServices from 'services/chatApp';

const MessageComponent = ({ m, getSeduleMessage }) => {
  const [wait, setwait] = useState(false);

  const handleDeleteMessage = (Id) => {
    setwait(true);
    ChatAppServices.deleteSendMessageLater(Id)
      .then(() => {
        getSeduleMessage();
        setwait(false);
      })
      .catch(() => setwait(false));
  };

  return (
    <>
      {wait && (
        <div className='wait-for-res' onClick={() => setwait(false)}>
          <p style={{ fontSize: '14px' }}>Please wait ...</p>
        </div>
      )}
      <img src={XIcon} alt='close' className='delete' onClick={() => handleDeleteMessage(m.id)} />
      <p className='scheduled_time'>Scheduled on {m.send_later_time}</p>
      <p className='scheduled_text'>
        {m.message_text.length < 60 ? m.message_text : `${m.message_text.substring(0, 60)}...`}
      </p>
    </>
  );
};

export default MessageComponent;
