import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;

  .multiple-drivers {
    justify-content: flex-start;
  }
`;

export const SContent = styled.div`
  min-height: 90px;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;

  .drivers-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .driver-row {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const SImageContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid rgba(213, 218, 229, 0.32);
  border-radius: 40px;
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  .driver-image {
    box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    border: 1px solid rgba(213, 218, 229, 0.32);
    border-radius: 100%;
    cursor: default;
  }

  .image-indicator {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #38a06c;
    box-shadow: 0 0 0 2px #ffffff;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
`;
