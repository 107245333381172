import styled from 'styled-components';
import { palette } from '../../../../../utils/constants';

export const STable = styled.table`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 8px;

  td {
    padding: 16px 20px;
    border: 1px solid ${palette.gray50};
    min-width: 200px;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }
`;

export const SLabel = styled.p`
  color: ${palette.gray500};
  font-weight: 500;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0 0 4px 0;
`;

export const SText = styled.p`
  color: ${palette.gray900};
  font-weight: 500;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0;
`;

export const SIconWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  :hover {
    background-color: ${palette.indigo0};
  }
`;
