import moment from 'moment/moment';
import { palette } from 'utils/constants';

export const criminalCheckResultOptions = ['Pending', 'Completed'];

export const mvrReasonOptions = [
  'Pre-Employment Screening',
  'Periodic Re-evaluation',
  'Accident or Crash',
  'Monitoring Driving Behavior',
  'Insurance Request',
];

export const drugTestReasonOptions = [
  'Pre Employment',
  'Random',
  'Return to Duty',
  'Post Accident',
  'Follow Up',
  'Reasonable Suspicion',
];

export const fmcsaReasonOptions = ['Pre-employment', 'Annual'];

export const resultOptions = ['Passed', 'Failed', 'Need Additional Verification', 'Pending'];

export const getInitialValues = (data, applicantMedicalAndBackground) => {
  const { medicalExaminersResults, mvrCheckResults, drugTestResults, criminalCheckResults, pspResults, fmcsaResults } =
    data || {};
  const {
    criminal_check_result,
    criminal_checked_date,
    criminal_checked_attachment,
    mvr_check_result,
    mvr_checked_date,
    mvr_check_attachment,
    drug_test_result,
    drug_checked_date: applicantDrugCheckDate,
    drug_test_attachment,
    fmcsa_checked_date: applicantFmcsaCheckedDate,
    medical_examiners_expire_date: applicantMedicalExpireDate,
    examiner_card,
    attach_medical_verification,
    attach_national_registry,
  } = applicantMedicalAndBackground || {};

  return {
    medicalExaminersResults: medicalExaminersResults.length
      ? medicalExaminersResults.map((item) => {
          return {
            id: item.id,
            expiry_date: item.expiry_date ? moment(item.expiry_date).toDate() : null,
            attach_card: item.attach_card || '',
            attach_long_form: item.attach_long_form || '',
            attach_medical_verification: item.attach_medical_verification || '',
            attach_national_registry: item.attach_national_registry || '',
          };
        })
      : [
          {
            isNew: true,
            id: Math.random(),
            expiry_date: applicantMedicalExpireDate ? moment(applicantMedicalExpireDate).toDate() : null,
            attach_card: examiner_card || '',
            attach_long_form: '',
            attach_medical_verification: attach_medical_verification || '',
            attach_national_registry: attach_national_registry || '',
          },
        ],
    mvrCheckResults: mvrCheckResults.length
      ? mvrCheckResults.map((item) => {
          return {
            id: item.id,
            mvr_check_results: item.mvr_check_results || '',
            mvr_check_date: item.mvr_check_date ? moment(item.mvr_check_date).toDate() : null,
            mvr_next_check_date: item.mvr_next_check_date ? moment(item.mvr_next_check_date).toDate() : null,
            attach_mvr_check_result: item.attach_mvr_check_result || '',
          };
        })
      : [
          {
            isNew: true,
            id: Math.random(),
            mvr_check_results: mvr_check_result === 1 ? 'Passed' : mvr_check_result === 0 ? 'Failed' : '',
            mvr_check_date: mvr_checked_date ? moment(mvr_checked_date).toDate() : null,
            mvr_next_check_date: mvr_checked_date ? moment(mvr_checked_date).add(1, 'year').toDate() : null,
            attach_mvr_check_result: mvr_check_attachment || '',
          },
        ],
    drugTestResults: drugTestResults.length
      ? drugTestResults.map((item) => {
          return {
            id: item.id,
            type: item.type || '',
            reason: item.reason || '',
            drug_check_results: item.drug_check_results || '',
            drug_checked_date: item.drug_checked_date ? moment(item.drug_checked_date).toDate() : null,
            attach_drug_check_result: item.attach_drug_check_result || '',
            drug_test_custom_form: item.drug_test_custom_form || '',
          };
        })
      : [
          {
            isNew: true,
            id: Math.random(),
            type: '',
            reason: '',
            drug_check_results: drug_test_result === 1 ? 'Passed' : drug_test_result === 0 ? 'Failed' : '',
            drug_checked_date: applicantDrugCheckDate ? moment(applicantFmcsaCheckedDate).toDate() : null,
            attach_drug_check_result: drug_test_attachment || '',
            drug_test_custom_form: '',
          },
        ],
    criminalCheckResults: criminalCheckResults.length
      ? criminalCheckResults.map((item) => {
          return {
            id: item.id,
            criminal_check_results: item.criminal_check_results || '',
            criminal_check_date: item.criminal_check_date ? moment(item.criminal_check_date).toDate() : null,
            attach_criminal_result: item.attach_criminal_result || '',
          };
        })
      : [
          {
            isNew: true,
            id: Math.random(),
            criminal_check_results:
              criminal_check_result === 1 ? 'Completed' : criminal_check_result === 0 ? 'Pending' : '',
            criminal_check_date: criminal_checked_date ? moment(criminal_checked_date).toDate() : null,
            attach_criminal_result: criminal_checked_attachment || '',
          },
        ],
    fmcsaResults: fmcsaResults.length
      ? fmcsaResults.map((item) => {
          return {
            id: item.id,
            psp_check_date: item.psp_check_date ? moment(item.psp_check_date).toDate() : null,
            attach_psp_check_result: item.attach_psp_check_result || '',

            reason: item.reason || '',
            fmcsa_checked_date: item.fmcsa_checked_date ? moment(item.fmcsa_checked_date).toDate() : null,
            fmcsa_next_check_date: item.fmcsa_next_check_date ? moment(item.fmcsa_next_check_date).toDate() : null,
            attach_fmcsa_result: item.attach_fmcsa_result ? [item.attach_fmcsa_result] : [],
            no_expiry: !item.fmcsa_next_check_date,
          };
        })
      : [
          {
            isNew: true,
            id: Math.random(),
            reason: '',
            fmcsa_checked_date: null,
            fmcsa_next_check_date: null,
            attach_fmcsa_result: [],
            no_expiry: false,
          },
        ],
    pspResults: pspResults.length
      ? pspResults.map((item) => {
          return {
            id: item.id,
            psp_check_date: item.psp_check_date ? moment(item.psp_check_date).toDate() : null,
            attach_psp_check_result: item.attach_psp_check_result || '',
          };
        })
      : [
          {
            isNew: true,
            id: Math.random(),
            psp_check_date: null,
            attach_psp_check_result: '',
          },
        ],
  };
};

export const resultStatuses = {
  Passed: { color: palette.green500, bgColor: palette.green0 },
  Completed: { color: palette.green500, bgColor: palette.green0 },
  Failed: { color: palette.red500, bgColor: palette.red0 },
  'Need Additional Verification': { color: palette.orange500, bgColor: palette.orange0 },
  Pending: { color: palette.gray500, bgColor: palette.gray0 },
  'Pre-Employment Screening': { color: palette.green500, bgColor: palette.green0 },
  'Periodic Re-evaluation': { color: palette.orange500, bgColor: palette.orange0 },
  'Accident or Crash': { color: palette.red500, bgColor: palette.red0 },
  'Monitoring Driving Behavior': { color: palette.gray500, bgColor: palette.gray0 },
  'Insurance Request': { color: palette.gray500, bgColor: palette.gray0 },
  'Pre-employment': { color: palette.indigo500, bgColor: palette.indigo0 },
  Annual: { color: palette.orange500, bgColor: palette.orange0 },
};
