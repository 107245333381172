export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  sort: { field: 'created_at', sortBy: 'desc' },
  range: [null, null],
  shipment_id: '',
  customers: [],
  drivers: [],
  vehicles: [],
  trailers: [],
  statuses: [],
  acknowledged: 'All',
};

export const LOG_STATUS = {
  1: 'Incoming',
  2: 'Outgoing',
  3: 'Error',
};

export const logStatuses = [
  { title: LOG_STATUS['1'], id: 1 },
  { title: LOG_STATUS['2'], id: 2 },
  { title: LOG_STATUS['3'], id: 3 },
];
