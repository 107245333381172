import React from 'react';
import { ReactComponent as TruckinLogo } from 'assets/images/main-logo.svg';
import styles from './ConfigureSettingsHeader.module.css';

const ConfigureSettingsHeader = () => {
  return (
    <div className={styles.confHeader_wrapper}>
      <div>
        <TruckinLogo style={{ fill: '#464F60' }} />
      </div>
    </div>
  );
};
export default ConfigureSettingsHeader;
