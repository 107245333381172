import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import CustomCheckBoxCard from '../../../../../common/CustomCheckBoxCard';
import { SendPaymentModalContext } from '../../../index';
import { getStripeFees } from '../../../../../Api/CardManagement';

const SendPaymentStep2 = () => {
  const { handleChange, values, setStep } = useContext(SendPaymentModalContext);
  const [achPercent, setAchPercent] = useState('');

  const getFees = async () => {
    const { data } = await getStripeFees();
    const achPercent = data?.per_outbound_txn_fee_ach_percent;
    const achPercentStripe = data?.per_outbound_txn_fee_ach_percent_stripe;

    if (!!achPercent && !!achPercentStripe) {
      setAchPercent(parseFloat(achPercent) + parseFloat(achPercentStripe));
    } else if (achPercent) {
      setAchPercent(parseFloat(achPercentStripe));
    } else if (achPercentStripe) {
      setAchPercent(parseFloat(achPercent));
    }
  };

  useEffect(() => {
    getFees();
  }, []);
  return (
    <div className='d-flex flex-column align-items-center gap-1 w-100'>
      <CustomCheckBoxCard
        value={1}
        disabled
        name='wontToPay'
        checked={+values.wontToPay === 1}
        onChange={(e) => handleChange('wontToPay', e.target.value)}
        leftTextProps={{ title: 'ACH', detail: 'Send directly to bank account' }}
        rightTextProps={{ title: `${achPercent}%/transaction ` || '', detail: '3 business days' }}
      />
      <CustomCheckBoxCard
        value={2}
        name='wontToPay'
        checked={+values.wontToPay === 2}
        onChange={(e) => handleChange('wontToPay', e.target.value)}
        leftTextProps={{ title: 'Check', detail: 'Print and mail yourself' }}
        rightTextProps={{ title: 'Free', detail: 'Instant', style: { color: '#14804A' } }}
      />

      <div className='d-flex align-items-center'>
        <Button className='cancel-button mt-2 px-lg-2' onClick={() => setStep((p) => p - 1)}>
          Go Back
        </Button>
        <Button className='next-step mt-2 px-lg-3' onClick={() => setStep((p) => p + 1)}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default SendPaymentStep2;
