import {
  EQUIPMENT_ACTION,
  SCHEDULED_DATE_TYPE,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';

export const shipmentTypes = [
  { id: 1, name: 'Truck Load' },
  { id: 2, name: 'LTL' },
];

export const shipmentDispatchOptions = [
  { id: 1, name: 'Dispatch' },
  { id: 2, name: 'Offer' },
];

export const getInitialValues = (settings, commodityTypes, equipmentTypeOptions) => {
  const {
    shipment_id,
    audit_req_on_complete,
    revert_if_not_available,
    revert_if_attendance,
    allow_past_date,
    require_actual_data,
    default_global_data,
    default_shipment_type,
    default_shipment_dispatch,
    default_shipment_dispatch_option,
    default_pickup_schedule_date_type,
    default_pickup_equipment_type,
    default_pickup_equipment_action,
    default_pickup_commodity,
    default_pickup_bill_type,
    default_pickup_dock_high,
    default_pickup_stackable,
    default_pickup_liftgate_service,
    default_pickup_labor_required,
    default_pickup_hazardous_materials,
    default_delivery_schedule_date_type,
    default_delivery_equipment_action,
    default_delivery_bill_type,
    default_delivery_dock_high,
    default_delivery_labor_required,
    default_waypoint_schedule_date_type,
    default_waypoint_equipment_action,
    default_waypoint_bill_type,
    default_waypoint_dock_high,
    default_waypoint_labor_required,
    hide_qty,
    hide_dimensions,
    hide_value,
    hide_dock_high,
    hide_stackable,
    hide_liftgate_service,
    hide_labor_required,
    hide_hazardous_materials,
    hide_validation,
  } = settings || {};

  return {
    shipment_id: shipment_id || null,
    audit_req_on_complete: !!audit_req_on_complete,
    revert_if_not_available: !!revert_if_not_available,
    revert_if_attendance: !!revert_if_attendance,
    allow_past_date: !!allow_past_date,
    require_actual_data: !!require_actual_data || false,
    default_global_data: !!default_global_data,
    default_shipment_type: shipmentTypes.find((i) => i.id === Number(default_shipment_type)) || null,
    default_shipment_dispatch: !!default_shipment_dispatch,
    default_shipment_dispatch_option:
      shipmentDispatchOptions.find((i) => i.id === Number(default_shipment_dispatch_option)) || null,
    default_pickup_schedule_date_type:
      SCHEDULED_DATE_TYPE.find((i) => i.key === Number(default_pickup_schedule_date_type)) || null,
    default_pickup_equipment_type:
      equipmentTypeOptions.find((i) => i.key === Number(default_pickup_equipment_type)) || null,
    default_pickup_equipment_action:
      EQUIPMENT_ACTION.find((i) => i.key === Number(default_pickup_equipment_action)) || null,
    default_pickup_commodity: commodityTypes.find((i) => i.key === Number(default_pickup_commodity)) || null,
    default_pickup_bill_type: !!default_pickup_bill_type,
    default_pickup_dock_high: !!default_pickup_dock_high,
    default_pickup_stackable: !!default_pickup_stackable,
    default_pickup_liftgate_service: !!default_pickup_liftgate_service,
    default_pickup_labor_required: !!default_pickup_labor_required,
    default_pickup_hazardous_materials: !!default_pickup_hazardous_materials,
    default_delivery_schedule_date_type:
      SCHEDULED_DATE_TYPE.find((i) => i.key === Number(default_delivery_schedule_date_type)) || null,
    default_delivery_equipment_action:
      EQUIPMENT_ACTION_DELIVERY.find((i) => i.key === Number(default_delivery_equipment_action)) || null,
    default_delivery_bill_type: !!default_delivery_bill_type,
    default_delivery_dock_high: !!default_delivery_dock_high,
    default_delivery_labor_required: !!default_delivery_labor_required,
    default_waypoint_schedule_date_type:
      SCHEDULED_DATE_TYPE.find((i) => i.key === Number(default_waypoint_schedule_date_type)) || null,
    default_waypoint_equipment_action:
      EQUIPMENT_ACTION_WAYPOINT.find((i) => i.key === Number(default_waypoint_equipment_action)) || null,
    default_waypoint_bill_type: !!default_waypoint_bill_type,
    default_waypoint_dock_high: !!default_waypoint_dock_high,
    default_waypoint_labor_required: !!default_waypoint_labor_required,
    hide_qty: !!hide_qty,
    hide_dimensions: !!hide_dimensions,
    hide_value: !!hide_value,
    hide_dock_high: !!hide_dock_high,
    hide_stackable: !!hide_stackable,
    hide_liftgate_service: !!hide_liftgate_service,
    hide_labor_required: !!hide_labor_required,
    hide_hazardous_materials: !!hide_hazardous_materials,
    hide_validation: !!hide_validation,
  };
};
