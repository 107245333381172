import styled from 'styled-components';
import { palette } from '../../../../utils/constants';

export const SInput = styled.input`
  outline: none;
  border: none;
  ::placeholder {
    color: ${palette.gray300};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`;
