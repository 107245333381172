import React, { useState, useMemo, useEffect } from 'react';
import { Formik, Form, FastField, Field } from 'formik';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadBig from 'assets/icons/documents/uploadBig.png';

import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomizedSnackbars from 'components/toast/Toast';
import CustomPhoneInput from 'components/CustomPhoneInput';
import ImageDropZone from 'components/ImgageDropZone/index';
import AddressAutocomplete from 'common/AddressAutocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { searchCities, searchCountries, searchStates } from 'Api/Driver';
import { GetCompanyProfile, GetTimeZoneList, UpdateCompanyProfile, uploadCompanyLogo } from 'Api/CompanySettings';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import styles from './CompanyProfile.module.css';
import { validationSchema } from './validationSchema';

const CompanyProfile = ({ isConfiguringStep, onSaveAndNext }) => {
  const [companyProfile, setCompanyProfile] = useState(null);
  const showToaster = useShowToaster();
  const [showMessage, setShowMessage] = React.useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [file, setFile] = useState(null);

  const getCompanyProfile = () => {
    return GetCompanyProfile().then((res) => {
      setCompanyProfile(res);
    });
  };

  useEffect(() => {
    getCompanyProfile().then();
  }, []);

  const handleSubmit = async (values) => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await uploadCompanyLogo(formData);
        if (response && response.path) {
          values = { ...values, company_logo: response.path };
        }
      } catch (e) {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
        return;
      }
    }

    UpdateCompanyProfile({ ...values, time_zone_id: values.time_zone_id.id }).then((res) => {
      if (res && !!res.success) {
        setShowMessage({ ...showMessage, message: `Changes have been successfully updated!`, visible: true });
        getCompanyProfile().then(() => {
          if (isConfiguringStep) {
            onSaveAndNext('saveAndNext');
          }
        });
      }
    });
  };

  const initialValue = useMemo(() => {
    const {
      company_logo,
      company_name,
      dot,
      scac,
      phone_number,
      fax_number,
      sms_primary,
      address1,
      address2,
      country_id,
      state_id,
      city_id,
      zip,
      time_zone_id,
      time_zone_name,
      company_website,
    } = companyProfile || {};
    return {
      company_logo: company_logo || '',
      company_name: company_name || '',
      dot: dot || '',
      scac: scac || '',
      phone_number: phone_number || '',
      fax_number: fax_number || '',
      sms_primary: sms_primary || '',
      address1: address1 || '',
      address2: address2 || '',
      country_id: country_id?.id ? country_id?.id?.toString() : '',
      state_id: state_id?.id ? state_id.id.toString() : '',
      city_id: city_id?.id ? city_id?.id.toString() : '',
      zip: zip || '',
      time_zone_id: time_zone_id ? { id: Number(time_zone_id), zone_name: time_zone_name } : null,
      company_website: company_website || '',
    };
  }, [companyProfile]);

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{ ...initialValue }}
      validationSchema={validationSchema}
    >
      {(props) => {
        return (
          <CompanyProfileForm
            {...props}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            companyProfile={companyProfile}
            isConfiguringStep={isConfiguringStep}
            onSaveAndNext={onSaveAndNext}
            file={file}
            setFile={setFile}
          />
        );
      }}
    </Formik>
  );
};

function CompanyProfileForm({
  values,
  submitForm,
  setFieldValue,
  setValues,
  showMessage,
  setShowMessage,
  companyProfile,
  isConfiguringStep,
  errors,
  touched,
  file,
  setFile,
}) {
  const { use } = useTheme();
  const [showFile, setShowFile] = useState({
    blob: '',
  });
  const [timeZoneList, setTimeZoneList] = useState(null);
  const [cropModalShow, setCropModalShow] = useState(false);
  const [cropper, setCropper] = useState();
  const [, setCountries] = useState(null);
  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);
  const [dropzoneIndex, setDropzoneIndex] = useState(null);

  const Countries = [
    { key: 'US', label: 'United States', country_id: 231, phonecode: 1, labelSelected: null },
    { key: 'CA', label: 'Canada', country_id: 38, phonecode: 1, labelSelected: null },
    { key: 'MX', label: 'Mexico', country_id: 142, phonecode: 52, labelSelected: null },
  ];

  const getCities = (state_id) => {
    return searchCities('', state_id).then((res) => {
      setCities(res.data);
      return res?.data;
    });
  };

  const getStates = (country_id) => {
    return searchStates('', country_id).then((res) => {
      if (res && res?.data) {
        setStates(res?.data);
        return res?.data;
      }
    });
  };

  const onChangeCountry = (e, first) => {
    if (first) {
      getStates(e.country_id).then((data) => {
        const foundedState = data?.find((data) => Number(data?.id) === Number(companyProfile?.state_id?.id));
        setStates(data);
        if (foundedState) {
          setTimeout(() => onChangeState(foundedState, true), 0);
        }
      });
    } else {
      setCities(null);
      setValues({ ...values, state_id: '', city_id: '' });
      getStates(e.country_id).then((data) => setStates(data));
    }

    return false;
  };

  const onChangeState = (e, first) => {
    if (first) {
      getCities(e?.id).then((data) => {
        const foundedData = data?.find((el) => Number(el?.id) === Number(companyProfile?.city_id?.id));
        if (foundedData) {
          setTimeout(() => onChangeCity(foundedData, true), 0);
        }
      });
    } else {
      setValues({ ...values, city_id: '' });
      getCities(e?.id).then((data) => setCities(data));
    }
    return false;
  };

  const onChangeCity = (e, first) => {
    if (!first) setFieldValue('city_id', e?.id);
    return false;
  };

  const onAddressSelect = (data) => {
    setFieldValue('country_id', data.country?.id.toString());
    onChangeCountry({ country_id: data.country?.id }, true);
    setFieldValue('state_id', data.state?.id.toString());
    onChangeState({ id: data.state?.id }, true);
    setFieldValue('city_id', data.city?.id.toString());
  };

  const changeAddress = ({ address, zip }) => {
    setFieldValue('address1', address);
    setFieldValue('zip', zip || values.zip);
  };

  useEffect(() => {
    if (companyProfile) {
      const foundedCountry = Countries.find((s) => s?.country_id === companyProfile?.country_id?.id);
      onChangeCountry(foundedCountry, true);
    }

    if (companyProfile?.company_logo) {
      setShowFile({
        blob: companyProfile.company_logo.includes('https')
          ? `${companyProfile.company_logo}?cache=${Date.now().toString()}`
          : `https://2911911.s3.amazonaws.com/${companyProfile.company_logo}?cache=${Date.now().toString()}`,
      });
    }
  }, [companyProfile]);

  useEffect(() => {
    searchCountries('')
      .then((res) => {
        setCountries(res.data);
      })
      .catch(() => {});
    GetTimeZoneList().then((res) => {
      setTimeZoneList(res.data);
    });
  }, []);

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setShowFile({ blob: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const uploadDocument = async (file) => {
    readFile(file[0]);
    setFile(file[0]);
    setValues({ ...values, company_logo: file[0] });
  };
  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadDocument([blob]);
        setCropModalShow(false);
      });
    }
  };
  const onImageUpload = (file) => {
    setCropModalShow(true);
    readFile(file[0]);
  };

  const onCancel = () => {
    setFile(null);
    setCropModalShow(false);
    if (companyProfile.company_logo) {
      setShowFile({
        blob: companyProfile.company_logo,
      });
    } else {
      setShowFile({ blob: '' });
    }
    setValues({ ...values, company_logo: companyProfile?.company_logo });
    setDropzoneIndex(Date.now());
  };
  const companyProfileStyle = useMemo(() => {
    return {
      company_name: {
        width: 320,
        borderRadius: 6,
        height: 32,
        margin: '0 !important',
        padding: '0 12px',
      },
      company_website: {
        width: 320,
        borderRadius: 6,
        height: 32,
        margin: '0 !important',
        padding: '0 12px',
      },
      dot_number: {
        width: 320,
        borderRadius: 6,
        height: 32,
        padding: '0 12px',
      },
      fax: {
        margin: '10px 0 0 0',
        color: use(palette.gray700, palette.gray200),
      },
      phone_number: {
        height: 32,
        width: 320,
        marginTop: 10,
      },
      address: {
        width: 400,
        borderRadius: 6,
        height: 32,
        padding: '0 12px',
      },
      zip: {
        width: 194,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      time_zone: {
        height: 32,
        width: 320,
        marginTop: 10,
      },
      globalLabel: {
        width: 300,
        margin: '10px 0 0 0',
        color: use(palette.gray700, palette.gray200),
      },
      badge: {
        color: use(palette.gray600, palette.gray200),
      },
    };
  }, [palette, use]);

  return (
    <>
      <Form>
        <div className={`${styles.companyProfile_wrapper} mb-5`}>
          <div className={styles.companyProfile_header}>
            <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
              Company Profile
            </Typography>
            <CustomButton
              onClick={submitForm}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ margin: 0, height: 24 }}
            />
          </div>
          <div className={styles.logo_wrapper}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), width: 300 }}>
              Logo
            </Typography>
            <div className={styles['upload-input']}>
              <div className={`${styles['upload-document']} ${file !== null ? styles['no-border'] : ''}`}>
                <label htmlFor='logo'>
                  <div className={styles.backDropImg}>
                    {!companyProfile?.company_logo && file === null && (
                      <span className={styles['upload-icon']}>
                        <img src={uploadBig} alt='upload' style={{ width: '25px', height: '25px' }} />
                      </span>
                    )}
                    {showFile?.blob && (
                      <img
                        src={showFile.blob}
                        alt='something'
                        style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '8px' }}
                      />
                    )}
                    {file === null && !companyProfile?.company_logo && (
                      <div>
                        <p className={styles['upload-document-drag']} style={{ fontSize: '10px' }}>
                          Upload &nbsp; <span> or drag and drop</span>
                        </p>
                      </div>
                    )}
                  </div>
                </label>
                {file !== null && (
                  <div onClick={(e) => e.stopPropagation()} className={styles['uploaded-icon-box']}>
                    <p className={styles['uploaded-icon']} style={{ fontSize: '10px' }}>
                      <img src={right} alt='icon' />
                      Uploaded
                    </p>
                    <p
                      className={styles['uploaded-icon']}
                      style={{ fontSize: '10px', zIndex: '10', marginLeft: '20px', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCancel();
                      }}
                    >
                      <img src={cross} alt='icon' />
                      Cancel
                    </p>
                  </div>
                )}
                <ImageDropZone
                  onDrop={onImageUpload}
                  width='100%'
                  height='100%'
                  id='logo'
                  deletable={false}
                  accept={['image/jpeg', 'image/png', 'image/svg+xml', 'image/bmp']}
                  key={dropzoneIndex}
                />
                <ErrorMessage error={touched.company_logo && errors.company_logo} />
              </div>
            </div>
            {companyProfile?.company_logo && (
              <label htmlFor='logo' className={styles.replace_wrapper}>
                <Typography variant='s2' style={{ color: use(palette.indigo500) }}>
                  Replace Logo
                </Typography>
              </label>
            )}
          </div>
          <div className={styles.basic_wrapper}>
            <div style={{ display: 'flex', width: 300 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                Company Name
              </Typography>
            </div>
            <div className='disabled-input'>
              <FastField
                name='company_name'
                type='text'
                component={CustomInput}
                style={companyProfileStyle.company_name}
                disabled
              />
              <ErrorMessage error={touched.company_name && errors.company_name} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <div style={{ display: 'flex', width: 300 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                DOT Number
              </Typography>
            </div>
            <div className='disabled-input'>
              <FastField
                name='dot'
                type='text'
                component={CustomInput}
                style={companyProfileStyle.dot_number}
                disabled
              />
              <ErrorMessage error={touched.dot && errors.dot} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <div style={{ display: 'flex', width: 300 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                SCAC
              </Typography>
            </div>
            <div>
              <FastField name='scac' type='text' component={CustomInput} style={companyProfileStyle.dot_number} />
              <ErrorMessage error={touched.scac && errors.scac} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Telephone Number
            </Typography>
            <div>
              <Field
                name='phone_number'
                inputStyle={{ width: 320 }}
                labelStyle={{ marginTop: 10 }}
                countryCodeEditable={false}
                component={CustomPhoneInput}
              />
              <ErrorMessage error={touched.phone_number && errors.phone_number} />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.fax}>
              Fax Number
            </Typography>
            <div className={styles.badge1}>
              <Typography variant='c2' style={companyProfileStyle.badge}>
                Optional
              </Typography>
            </div>
            <div>
              <Field
                name='fax_number'
                inputStyle={{ width: 320 }}
                labelStyle={{ marginTop: 10 }}
                countryCodeEditable={false}
                component={CustomPhoneInput}
              />
            </div>
          </div>
          <div className={styles.address_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Address
            </Typography>

            <div className={styles.address_info_wrapper}>
              <div className={styles.error_wrapper}>
                <AddressAutocomplete
                  onSuccess={onAddressSelect}
                  changeAddress={changeAddress}
                  onChange={(e) => setFieldValue('address1', e.target.value)}
                  value={values.address1}
                  error={touched.address1 && errors.address1}
                  width='400px'
                />
              </div>
              <div>
                <Field
                  name='address2'
                  type='text'
                  component={CustomInput}
                  placeholder='Address 2'
                  style={companyProfileStyle.address}
                />
                <ErrorMessage error={touched.address2 && errors.address2} />
              </div>
              <div className={styles.address_bottom_wrapper}>
                <div className={styles.left_side}>
                  <div className={styles.control}>
                    <Field
                      name='country_id'
                      options={Countries}
                      styles={{ height: 32, width: 194, marginRight: 8 }}
                      component={CustomSelect}
                      keyName='country_id'
                      labelName='label'
                      menuStyles={{ width: 194 }}
                      isSearchable
                      isSearchableStyles={{ width: 194, height: 32 }}
                      onChange={onChangeCountry}
                    />
                    <ErrorMessage error={touched.country_id && errors.country_id} />
                  </div>
                  <div className={styles.control}>
                    <Field
                      name='city_id'
                      options={cities}
                      styles={{ height: 32, width: 194, marginRight: 8 }}
                      component={CustomSelect}
                      keyName='id'
                      labelName='name'
                      menuStyles={{ width: 194 }}
                      isSearchable
                      onChange={onChangeCity}
                      isSearchableStyles={{ width: 194, height: 32 }}
                    />
                    <ErrorMessage error={touched.city_id && errors.city_id} />
                  </div>
                </div>
                <div className={styles.right_side}>
                  <div className={styles.control}>
                    <Field
                      name='state_id'
                      options={states}
                      styles={{ height: 32, width: 194, marginRight: 8 }}
                      component={CustomSelect}
                      keyName='id'
                      labelName='name'
                      menuStyles={{ width: 194 }}
                      isSearchable
                      isSearchableStyles={{ width: 194, height: 32 }}
                      onChange={onChangeState}
                    />
                    <ErrorMessage error={touched.state_id && errors.state_id} />
                  </div>
                  <div className={styles.error_wrapper}>
                    <FastField
                      name='zip'
                      type='text'
                      component={CustomInput}
                      placeholder='Zip Code'
                      style={companyProfileStyle.zip}
                    />
                    <ErrorMessage error={touched.zip && errors.zip} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <Typography variant='s2' style={companyProfileStyle.globalLabel}>
              Time Zone
            </Typography>
            <div>
              <Autocomplete
                width='320px'
                name='time_zone_id'
                labelKey='zone_name'
                options={timeZoneList || []}
                value={values.time_zone_id}
                onChange={(e, value) => setFieldValue('time_zone_id', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touched.time_zone_id && errors.time_zone_id}
              />
            </div>
          </div>
          <div className={styles.basic_wrapper}>
            <div style={{ display: 'flex', width: 300 }}>
              <Typography variant='s2' style={companyProfileStyle.globalLabel}>
                Company Website
              </Typography>
            </div>
            <FastField
              name='company_website'
              type='text'
              component={CustomInput}
              style={companyProfileStyle.company_website}
            />
          </div>
        </div>
      </Form>
      {showMessage?.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      {!!cropModalShow && (
        <ImageCropModal
          backdrop='static'
          show={cropModalShow}
          onHide={onCancel}
          image={showFile}
          getCropData={getCropData}
          setCropper={setCropper}
        />
      )}
    </>
  );
}

export default CompanyProfile;
