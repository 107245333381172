import React from 'react';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { formatPhoneNumber } from 'utils/helpers';
import { SRow } from '../ViewApplicantModal.styles';

const PreviousEmployments = ({ data }) => {
  const { formatDate } = useDateFormat();

  if (!data?.length) {
    return null;
  }

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Previous Employment
      </Typography>
      <Divider margin='8px 0' />
      <div className='overflow-x-auto'>
        <SRow style={{ width: '3500px' }}>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            EMPLOYER NAME
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            EMAIL
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            PHONE NUMBER
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            FAX NUMBER
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            START-END DATE
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            ADDRESS
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            POSITION HELD
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            REASON FOR LEAVING
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            TERMINATED / DISCHARGED / LAID OFF
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            CURRENT EMPLOYER
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            OPERATED A COMMERCIAL MOTOR VEHICLE
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            WERE SUBJECT TO THE FEDERAL MOTOR CARRIER OR TRANSPORT CANADA SAFETY REGULATIONS
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            PERFORMED SAFETY SENSITIVE FUNCTIONS
          </Typography>

          <Typography variant='c1' style={{ color: palette.gray900 }}>
            AREAS DRIVEN
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            MILES DRIVEN
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            PAY RANGE
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            MOST COMMON TRUCK DRIVEN
          </Typography>
          <Typography variant='c1' style={{ color: palette.gray900 }}>
            MOST COMMON TRAILER NAME/SIZE
          </Typography>
        </SRow>
        {data.map((item) => (
          <SRow key={item.id} style={{ width: '3500px' }}>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.employer_name}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.email || '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {formatPhoneNumber(`+${item.phone_no}`)}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.fax ? formatPhoneNumber(`+${item.fax}`) : '-'}
            </Typography>
            <div style={{ color: palette.gray400 }}>
              <Chip label={formatDate(item.start_date)} size='small' /> -{' '}
              <Chip label={item.present ? 'Present' : formatDate(item.end_date)} size='small' />
            </div>
            <div className='d-flex flex-column gap-1'>
              <Typography variant='b2' style={{ color: palette.gray900 }}>
                {item.address ? `${item.address}` : '-'}
              </Typography>
              <Typography variant='b2' style={{ color: palette.gray900 }}>
                {item.city ? `${item.city.name}` : ''}
                {item.state ? `, ${item.state.short_name}` : ''}
                {item.zipcode ? ` ${item.zipcode}` : ''}
              </Typography>
            </div>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.position || '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.reason || '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.terminated ? 'Yes' : 'No'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.current_employer ? 'Yes' : 'No'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.operated_commercial_vehicle ? 'Yes' : 'No'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.subject_to_safety_regulations ? 'Yes' : 'No'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.perform_safety_functions ? 'Yes' : 'No'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.areas_driven || '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.miles_driven ? Math.round(item.miles_driven) : '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.pay_range ? `$${formatNumber(item.pay_range)} /mile` : '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.truck_name || '-'}
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {item.trailer_name_size || '-'}
            </Typography>
          </SRow>
        ))}
      </div>
    </div>
  );
};

export default PreviousEmployments;
