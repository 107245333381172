export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  vendor: null,
};

export const INCIDENT_TABS = [
  { title: 'All Incidents ', id: 0 },
  { title: 'High Risk', id: 'H' },
  { title: 'Low Risk', id: 'L' },
];

export const INFRACTION_TYPES = [
  { title: 'Safety', id: 1 },
  { title: 'Conduct', id: 2 },
];
