export const getInitialValues = (agent) => {
  const { is_active, type, end_date, flat_per_shipment, percentage_per_shipment, percentage_after_driver_pay } =
    agent || {};

  return {
    type: Number(type),
    flat_per_shipment: Number(type) === 1 ? Number(flat_per_shipment).toString() : '',
    percentage_per_shipment: Number(type) === 2 ? Number(percentage_per_shipment).toString() : '',
    percentage_after_driver_pay: Number(type) === 3 ? Number(percentage_after_driver_pay).toString() : '',
    end_date: is_active ? null : new Date(end_date),
    is_active: !!is_active,
  };
};
