import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
`;

export const SYearPicker = styled.div`
  width: 74px;
  padding: 0 8px;
  display: flex;
  column-gap: 2px;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);

  .react-datepicker__input-container input {
    width: 100%;
    font-size: 14px;
    padding: 1px 2px;
  }

  .react-datepicker__year-wrapper {
    max-width: unset;
  }

  && .react-datepicker {
    width: 222px;
  }
`;
