import React from 'react';
import moment from 'moment';
import person from 'assets/images/person.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSmall.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import Status from 'componentsV2/AlertCenter/TakeActions/components/Status';

import { palette } from 'utils/constants';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { SImageWrapper } from './AlertsTable.styles';

export const initialFilters = {
  tab: 0,
  page: 1,
  itemsPerPage: 50,
  dateRange: [null, null],
  sort: { field: 'created_at', sortBy: 'desc' },
  alertTypes: [],
};

export const initialTabs = [
  {
    value: 0,
    system: 1,
    filters: { alert_status: ['N', 'IP'] },
    label: 'All',
    color: null,
    count: 0,
  },
  {
    value: 1,
    system: 1,
    filters: { alert_status: ['N'] },
    label: 'New',
    color: palette.blue500,
    count: 0,
  },
  {
    value: 2,
    system: 1,
    filters: { alert_status: ['IP'] },
    label: 'In Progress',
    color: palette.amber500,
    count: 0,
  },
  {
    value: 3,
    system: 1,
    filters: { alert_status: ['N', 'IP'], preventive_action: 1 },
    label: 'Preventive Action',
    color: palette.red500,
    count: 0,
  },
  {
    value: 4,
    system: 1,
    filters: { alert_status: ['RS'] },
    label: 'Resolved',
    color: palette.green400,
    count: 0,
  },
];

export const useColumns = ({
  sort,
  sortingQuery,
  alerts,
  selectedRowIds,
  handleSelectAll,
  handleSelectRow,
  showCheckboxes,
}) => {
  return [
    {
      field: '',
      title:
        showCheckboxes && alerts.length ? (
          <CustomCheckbox onChange={handleSelectAll} checked={selectedRowIds.length === alerts.length} />
        ) : (
          ''
        ),
      render: (row) =>
        showCheckboxes ? (
          <span onClick={(e) => e.stopPropagation()}>
            <CustomCheckbox
              onChange={(checked) => handleSelectRow(checked, row)}
              checked={selectedRowIds.includes(row.id)}
            />
          </span>
        ) : (
          ''
        ),
    },
    {
      field: 'alert-description',
      title: <ColumnHeader title='ALERT DESCRIPTION' />,
      render: (row) => {
        const preventive = row?.preventive_action;
        const { alert_type } = row;
        return (
          <div className='d-flex flex-column gap-1'>
            <div>
              <Typography variant='s3'>{row.alert_message}</Typography>
            </div>
            {preventive === 1 && alert_type === 'Driver' && (
              <div className='d-flex align-items-center gap-2'>
                <NotesIcon style={{ fill: palette.red500 }} />
                <Typography variant='s2' style={{ color: palette.red500 }}>
                  Driver will be placed Out Of Service when expiry reached.
                </Typography>
              </div>
            )}
            {preventive === 1 && alert_type === 'Staff' && (
              <div className='d-flex align-items-center gap-2'>
                <NotesIcon style={{ fill: palette.red500 }} />
                <Typography variant='s2' style={{ color: palette.red500 }}>
                  Staff will be placed Out Of Service when expiry reached.
                </Typography>
              </div>
            )}
            {preventive === 1 && alert_type === 'Equipment' && (
              <div className='d-flex align-items-center gap-2'>
                <NotesIcon style={{ fill: palette.red500 }} />
                <Typography variant='s2' style={{ color: palette.red500 }}>
                  Equipment will be placed Out Of Service when expiry reached.
                </Typography>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'SOURCE',
      title: <ColumnHeader title='SOURCE' />,
      render: (row) => {
        const image = row?.driver_details?.image || row?.staff_details?.profile_logo;
        const { source } = row;

        return !source ? (
          <span style={{ marginLeft: 30 }}>-</span>
        ) : (
          <SImageWrapper>
            {(row?.driver_details || row?.staff_details) && (
              <figure className='figure'>
                <img className='image' src={image || person} alt='img' />
              </figure>
            )}
            <Typography variant='s2'>{source}</Typography>
          </SImageWrapper>
        );
      },
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='ALERTED ON' field='created_at' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        return <span style={{ color: palette.dark800 }}>{moment(row.created_at).fromNow()}</span>;
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' />,
      render: (row) => <Status alert={row} />,
    },
    {
      field: 'handling_by_user_details',
      title: <ColumnHeader title='ALERT STATUS BY' />,
      render: (row) =>
        row.handling_by_user_details ? (
          <SImageWrapper>
            <figure className='figure'>
              <img className='image' src={row.handling_by_user_details?.profile_logo || person} alt='img' />
            </figure>
            <Typography variant='s2'>
              {row.handling_by_user_details?.first_name} {row.handling_by_user_details?.last_name}
            </Typography>
          </SImageWrapper>
        ) : (
          '-'
        ),
    },
  ];
};

export const statusOptions = [
  { value: 'IP', name: 'In Progress' },
  { value: 'RS', name: 'Resolved' },
];
