import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  type: string().trim().required('Type is required'),
  risk_level: object()
    .shape({ id: number().required('Risk Level is required') })
    .required('Risk Level is required')
    .nullable(),
  infraction_type: object()
    .shape({ id: number().required('Infraction Type is required') })
    .required('Infraction Type is required')
    .nullable(),
});
