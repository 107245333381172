import axios from '../services/axios';

export async function getDriverHosList(params, signal) {
  const { data } = await axios.get(`/customer/safety/hos-logbooks`, { params, signal });
  return data;
}
export async function getDriverHos(id) {
  const { data } = await axios.get(`/customer/safety/hos-logbook/${id}`);
  return data;
}

export async function getDriverHosDailyLogs(id, params, signal) {
  const { data } = await axios.get(`/customer/safety/hos-logbook/${id}/daily-logs-list`, { params, signal });
  return data;
}

export async function getDriverHosDetailedLogs(id, params, signal) {
  const { data } = await axios.get(`/customer/safety/hos-logbook/${id}/detailed-logs-list`, { params, signal });
  return data;
}

export async function getVehicleList(params) {
  const { data } = await axios.get(`customer/safety/accidents/vehicle-list`, { params });
  return data;
}

export async function getTrailerList(params) {
  const { data } = await axios.get(`customer/safety/accidents/trailer-list`, { params });
  return data;
}

export async function createPaperLog(body) {
  const { data } = await axios.post('customer/safety/hos-logbook/paper-log', body);
  return data;
}

export async function getShipmentListForDriver(params) {
  const { data } = await axios.get('customer/dispatched-shipments', { params });
  return data;
}

export async function deletePaperLog(id) {
  const { data } = await axios.delete(`customer/safety/hos-logbook/paper-log/${id}`);
  return data;
}
