import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import {
  GetCompanyProfile,
  getBrokerageSetting,
  getFundsTransferInstructions,
  GetSettingsAccounting,
  updateFundsTransferInstructions,
  getFundsTransferInstructionsBrokerage,
  updateFundsTransferInstructionsBrokerage,
} from 'Api/CompanySettings';
import { generatePDF } from './helpers';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './FundsTransferInstructions.data';
import { SWrapper } from './FundsTransferInstructions.styles';

const FundsTransferInstructions = () => {
  const showToaster = useShowToaster();
  const hasBrokerage = useHasBrokerage();
  const [instructions, setInstructions] = useState(null);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [accountingSettings, setAccountingSettings] = useState(null);
  const [brokerageSettings, setBrokerageSettings] = useState({});
  const [brokerageAccountingSettings, setBrokerageAccountingSettings] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({ id: 1, value: 'ownCompany' });

  const onSubmit = async (values) => {
    try {
      const { blob } = await generatePDF({
        companyProfile: selectedCompany.value === 'ownCompany' ? companyProfile : brokerageSettings,
        accountingSettings: selectedCompany.value === 'ownCompany' ? accountingSettings : brokerageAccountingSettings,
        values,
      });

      const formData = new FormData();
      formData.append('bank_name', values?.bank_name);
      formData.append('bank_address_1', values?.address);
      if (values.address2) {
        formData.append('bank_address_2', values?.address2);
      }
      formData.append('bank_city_id', values.city.id);
      formData.append('bank_state_id', values.state.id);
      formData.append('bank_zip_code', values.zipcode);
      formData.append('bank_country_id', values.country.id);
      formData.append('ach_enabled', values.ach_enabled ? '1' : '0');
      formData.append('ach_account_number', values.ach_enabled ? values?.ach_account_number : '');
      formData.append('ach_routing_number', values.ach_enabled ? values?.ach_routing_number : '');
      formData.append('wire_enabled', values.wire_enabled ? '1' : '0');
      formData.append('wire_account_number', values.wire_enabled ? values?.wire_account_number : '');
      formData.append('wire_routing_number', values.wire_enabled ? values?.wire_routing_number : '');
      formData.append('fti_doc', blob);

      if (selectedCompany.value === 'ownCompany') {
        await updateFundsTransferInstructions(formData);
      } else {
        await updateFundsTransferInstructionsBrokerage(formData);
      }
      getInstructions();
      showToaster({ type: 'success', message: 'Funds Transfer Instructions have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(instructions),
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const getInstructions = async () => {
    try {
      const { data } =
        selectedCompany.value === 'ownCompany'
          ? await getFundsTransferInstructions()
          : await getFundsTransferInstructionsBrokerage();
      setInstructions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
      setSelectedCompany({ id: 1, name: company.company_name, value: 'ownCompany' });
    } catch (e) {
      // Do nothing
    }
  };

  const getBrokerSettings = async () => {
    try {
      const { data } = await getBrokerageSetting();
      setBrokerageSettings({
        ...data,
        city_id: data.city,
        state_id: data.state,
        zip: data.zipcode,
        company_logo: data.logo,
      });
      setBrokerageAccountingSettings({
        website: data.website,
        show_website: data.show_website,
        show_billing: true,
        receivable_email: data.receivable_email,
      });
    } catch (e) {
      // Do nothing
    }
  };

  const getAccountingSettings = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountingSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getInstructions();
  }, [selectedCompany]);

  useEffect(() => {
    getCompanyProfile();
    getAccountingSettings();

    if (hasBrokerage) {
      getBrokerSettings();
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <SWrapper>
      {!!companyProfile && (
        <>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-2'>
              <Typography variant='h2' style={{ color: palette.gray900, whiteSpace: 'nowrap' }}>
                Funds Transfer Instructions
              </Typography>
              {!!hasBrokerage && brokerageSettings.company_name && (
                <Autocomplete
                  width='200px'
                  value={selectedCompany}
                  onChange={(e, val) => setSelectedCompany(val)}
                  options={[
                    { id: 1, name: companyProfile.company_name, value: 'ownCompany' },
                    { id: 2, name: brokerageSettings.company_name, value: 'brokerageCompany' },
                  ]}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              )}
            </div>
            <CustomButton
              onClick={() => {
                if (!values.ach_enabled && !values.wire_enabled) {
                  showToaster({
                    type: 'error',
                    message:
                      'Please enable at least one account type: ACH or Wire. Both options cannot be disabled at the same time.',
                  });
                } else {
                  handleSubmit(values);
                }
              }}
              type='primary'
              title='Save'
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
            />
          </div>
          <div className='d-flex flex-column mt-4'>
            <div className='flex-row'>
              <Typography variant='s1'>This document can be included with your invoices (optional).</Typography>
              {instructions?.fti_doc && (
                <div className='underline-text' onClick={() => setPdfUrl(instructions?.fti_doc)}>
                  View Document
                </div>
              )}
            </div>
            <div className='flex-row'>
              <div className='left'>
                <Typography variant='s2'>Bank Name</Typography>
              </div>
              <div className='right'>
                <Input
                  name='bank_name'
                  value={values.bank_name}
                  onChange={handleChange}
                  error={touchedErrors.bank_name}
                />
              </div>
            </div>
            <div className='flex-row'>
              <div className='left'>
                <Typography variant='s2'>Bank Address</Typography>
              </div>
              <div className='right'>
                <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} hideLabel />
              </div>
            </div>
            <div className='flex-row'>
              <div className='left'>
                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    type='switch'
                    name='ach_enabled'
                    smail={false}
                    checked={!!values.ach_enabled}
                    onChange={(checked) => {
                      if (checked) {
                        handleChange('ach_enabled', checked);
                      } else {
                        setValues((prevState) => ({
                          ...prevState,
                          ach_enabled: checked,
                          ach_account_number: '',
                          confirm_ach_account_number: '',
                          ach_routing_number: '',
                          confirm_ach_routing_number: '',
                        }));
                      }
                    }}
                  />
                  <Typography variant='s2' style={{ marginLeft: 16 }}>
                    ACH Information
                  </Typography>
                </div>
              </div>
              <div className='right'>
                <div className='d-flex flex-column gap-2'>
                  <Input
                    label='Account Number'
                    name='ach_account_number'
                    value={values.ach_account_number}
                    onChange={handleChange}
                    error={touchedErrors.ach_account_number}
                    disabled={!values.ach_enabled}
                  />
                  <Input
                    label='Confirm Account Number'
                    name='confirm_ach_account_number'
                    value={values.confirm_ach_account_number}
                    onChange={handleChange}
                    error={touchedErrors.confirm_ach_account_number}
                    disabled={!values.ach_enabled}
                  />
                  <Input
                    label='Routing Number'
                    name='ach_routing_number'
                    value={values.ach_routing_number}
                    onChange={handleChange}
                    error={touchedErrors.ach_routing_number}
                    disabled={!values.ach_enabled}
                  />
                  <Input
                    label='Confirm Routing Number'
                    name='confirm_ach_routing_number'
                    value={values.confirm_ach_routing_number}
                    onChange={handleChange}
                    error={touchedErrors.confirm_ach_routing_number}
                    disabled={!values.ach_enabled}
                  />
                </div>
              </div>
            </div>
            <div className='flex-row'>
              <div className='left'>
                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    type='switch'
                    name='wire_enabled'
                    smail={false}
                    checked={!!values.wire_enabled}
                    onChange={(checked) => {
                      if (checked) {
                        handleChange('wire_enabled', checked);
                      } else {
                        setValues((prevState) => ({
                          ...prevState,
                          wire_enabled: checked,
                          wire_account_number: '',
                          confirm_wire_account_number: '',
                          wire_routing_number: '',
                          confirm_wire_routing_number: '',
                        }));
                      }
                    }}
                  />
                  <Typography variant='s2' style={{ marginLeft: 16 }}>
                    Wire Information
                  </Typography>
                </div>
              </div>
              <div className='right'>
                <div className='d-flex flex-column gap-2'>
                  <Input
                    label='Account Number'
                    name='wire_account_number'
                    value={values.wire_account_number}
                    onChange={handleChange}
                    error={touchedErrors.wire_account_number}
                    disabled={!values.wire_enabled}
                  />
                  <Input
                    label='Confirm Account Number'
                    name='confirm_wire_account_number'
                    value={values.confirm_wire_account_number}
                    onChange={handleChange}
                    error={touchedErrors.confirm_wire_account_number}
                    disabled={!values.wire_enabled}
                  />
                  <Input
                    label='Routing Number'
                    name='wire_routing_number'
                    value={values.wire_routing_number}
                    onChange={handleChange}
                    error={touchedErrors.wire_routing_number}
                    disabled={!values.wire_enabled}
                  />
                  <Input
                    label='Confirm Routing Number'
                    name='confirm_wire_routing_number'
                    value={values.confirm_wire_routing_number}
                    onChange={handleChange}
                    error={touchedErrors.confirm_wire_routing_number}
                    disabled={!values.wire_enabled}
                  />
                </div>
              </div>
            </div>
          </div>
          {!!pdfUrl && (
            <ViewPdf
              open={!!pdfUrl}
              onClose={() => setPdfUrl(null)}
              pdfUrl={pdfUrl}
              title='Funds Transfer Instructions'
            />
          )}
        </>
      )}
    </SWrapper>
  );
};

export default FundsTransferInstructions;
