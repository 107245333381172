import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import ThreeDotActions from 'common/ThreeDotActions';
import { palette } from 'utils/constants';
import { stopPointTypes } from '../../CreateQuote.data';
import { SAddStopIcon } from '../../CreateQuote.styles';

const AddShipmentStop = ({ onTypeSelect }) => {
  const [anchorEl, setAnchorEl] = useState();

  return (
    <ThreeDotActions
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      actionButton={
        <SAddStopIcon>
          <PlusIcon fill={palette.indigo500} />
        </SAddStopIcon>
      }
    >
      {stopPointTypes.map((item) => (
        <li
          key={item.id}
          onClick={() => {
            onTypeSelect(item);
            setAnchorEl(null);
          }}
        >
          {item.title}
        </li>
      ))}
    </ThreeDotActions>
  );
};

export default AddShipmentStop;
