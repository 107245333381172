import { number, object, string } from 'yup';

export const validationSchema = object({
  location_name: string().trim().required('Required'),
  address: string().trim().required('Required'),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required').nullable(),
  pin: number()
    .test('pin', 'Required', (val) => val && val.toString().length === 6)
    .nullable(),
  timezone: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
