import React, { useEffect, useState } from 'react';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import Search from 'pages/ApplicantsAndReferrals/components/Search';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import { useApplicantsColumns } from 'pages/ApplicantsAndReferrals/components/ApplicantsTable/ApplicantsTable.data';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getApplicantsList } from 'Api/Dashboard';

const Applicants = () => {
  const [loading, setLoading] = useState(true);
  const [applicantsData, setApplicantsData] = useState([]);
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [selectedFilters, setSelectedFilters] = useState({
    page: 1,
    itemsPerPage: 10,
  });

  const getApplicants = async () => {
    const { page, itemsPerPage } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    const params = {
      page,
      itemsPerPage,
      query: debouncedSearch || undefined,
      [sortField]: sort.sortBy,
    };

    try {
      setLoading(true);
      const { data } = await getApplicantsList(params);
      setApplicantsData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getApplicants();
  }, [selectedFilters, sort, debouncedSearch]);

  const PaginationComponent = () => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={applicantsData?.total}
      totalLength={applicantsData?.data?.length}
      lastPage={applicantsData?.last_page}
      currentPage={applicantsData?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
      perPageOptions={[10, 25, 50, 100, 150]}
    />
  );

  const columns = useApplicantsColumns({ sortingQuery, sort });

  return (
    <div className='d-flex flex-column gap-2'>
      <Search search={search} onChange={setSearch} />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={applicantsData?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
    </div>
  );
};

export default Applicants;
