import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SSimpleList, SSimpleListRow } from 'pages/Accounting/Payables/Payables.styles';

const AccountsList = ({ accounts, selected, updateSelected }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <SSimpleList>
      <thead>
        <tr>
          <th>EXPENSE TYPE</th>
          <th>TOTAL</th>
          <th className='text-right'>PERCENT</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {accounts.map((item) => (
          <SSimpleListRow
            key={item.account_id}
            className={`pointer ${selected?.account_id === item.account_id ? 'selected-row' : ''}`}
            onClick={() => updateSelected(item)}
          >
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {item.account_name}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {item.amount < 0 && '-'}
                {currency}
                {formatNumber(Math.abs(item.amount))}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {formatNumber(item.percentage, 1)}%
              </Typography>
            </td>
            <td />
          </SSimpleListRow>
        ))}
      </tbody>
    </SSimpleList>
  );
};

export default AccountsList;
