import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-top: 12px;

  .delete-icon {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;

export const SDatePicker = styled(DatePicker)`
  width: 250px;
  font-size: 14px;
  box-shadow: ${palette.boxShadow2};
  border-radius: 6px;
  padding: 5px 12px 6px;
`;
