import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { getAccountsList } from 'Api/Accounts';
import {
  attachTaxes,
  createDeduction,
  deleteDeduction,
  getDeductionsList,
  getTaxesList,
  updateDeduction,
} from 'Api/PayrollSettings';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import FormItem from '../FormItem';
import { SWrapper, SAddMore, SCircularProgress, SActions, STable, STableRow } from '../Payroll.styles';

const Deductions = ({ isModalView, isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [accounts, setAccounts] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [taxOptions, setTaxOptions] = useState([]);

  const getDeductions = async () => {
    try {
      const { data: accounts } = await getAccountsList();
      const { data } = await getDeductionsList();
      setDeductions(data.map((item) => ({ ...item, account: accounts.find((i) => i.id === item.account_id) || null })));
      setAccounts(
        accounts.filter((i) =>
          [
            ACCOUNT_TYPE.EXPENSE,
            ACCOUNT_TYPE.COST_OF_GOODS_SOLD,
            ACCOUNT_TYPE.LOAN,
            ACCOUNT_TYPE.CURRENT_LIABILITY,
            ACCOUNT_TYPE.LONG_TERM_LIABILITY,
            ACCOUNT_TYPE.OTHER_EXPENSE,
          ].includes(i.account_type.id)
        )
      );
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const getTaxOptions = async () => {
    try {
      const { data } = await getTaxesList();
      setTaxOptions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewDeduction = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = {
        deduction_type: values.type,
        is_percent: values.is_percent,
        amount: Number(values.is_percent ? 0 : values.amount),
        percent: Number(values.is_percent ? values.amount : 0),
        account_id: values.account?.id || 0,
      };
      const { data: deduction } = await createDeduction(data);

      if (values.taxes?.length) {
        const attachTaxesBody = { payroll_tax_id: values.taxes.map((i) => i.id) };
        await attachTaxes(deduction.id, attachTaxesBody);
      }
      await getDeductions();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Deduction has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const editDeduction = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = {
        deduction_type: values.type,
        is_percent: values.is_percent,
        amount: Number(values.is_percent ? 0 : values.amount),
        percent: Number(values.is_percent ? values.amount : 0),
        account_id: values.account?.id || 0,
      };
      const { data: deduction } = await updateDeduction(id, data);

      if (values.taxes?.length) {
        const attachTaxesBody = { payroll_tax_id: values.taxes.map((i) => i.id) };
        await attachTaxes(deduction.id, attachTaxesBody);
      }
      await getDeductions();
      showToaster({ type: 'success', message: 'Deduction has been successfully updated!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const deleteItem = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteDeduction(id);
      const newDeductions = deductions.filter((item) => item.id !== id);
      setDeductions(newDeductions);
      showToaster({ type: 'success', message: 'Deduction has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const onEditClick = (id, isCancel) => {
    setDeductions((prevState) => prevState.map((item) => (item.id === id ? { ...item, isEdit: !isCancel } : item)));
  };

  useEffect(() => {
    if (!isModalView) {
      window.scrollTo(0, 0);
    }
    setLoading(true);
    getTaxOptions();
    getDeductions();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      {!isModalView && (
        <PageHeader
          hideSave
          title='Deductions'
          isConfiguringStep={isConfiguringStep}
          onCancelPrevPage={onCancelPrevPage}
          onSaveAndNext={onSaveAndNext}
        />
      )}
      <STable>
        <thead>
          <tr>
            <th>TYPE OF DEDUCTION</th>
            <th>AMOUNT</th>
            <th>ACCOUNT</th>
            <th>TAXES APPLY AFTER DEDUCT</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {deductions.map((item) =>
            item.isEdit ? (
              <FormItem
                key={item.id}
                data={item}
                accounts={accounts}
                onSubmit={(values) => editDeduction(values, item.id)}
                onDelete={() => onEditClick(item.id, true)}
                loading={loadingCreate}
                taxOptions={taxOptions}
                flatOrPercent
              />
            ) : (
              <STableRow key={item.id}>
                <td>
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    {item.deduction_type}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    {item.is_percent ? `${Number(item.percent)?.toFixed(2)}%` : `$${Number(item.amount)?.toFixed(2)}`}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    {item.account?.account_name || '-'}
                  </Typography>
                </td>
                <td>
                  <div className='d-flex gap-1'>
                    {item.payroll_tax?.length
                      ? item.payroll_tax.map((i) => (
                          <Chip key={i.id} size='small' label={i.tax_type} bgColor={palette.indigo50} />
                        ))
                      : '-'}
                  </div>
                </td>
                <td className='actions-cell'>
                  <SActions>
                    {loadingDelete ? (
                      <span className='loading-wrapper'>
                        <CircularProgress size={16} />
                      </span>
                    ) : (
                      <>
                        <span className='action-wrapper' onClick={() => onEditClick(item.id)}>
                          <EditIcon width={13} height={13} />
                        </span>
                        <span className='action-wrapper' onClick={() => deleteItem(item.id)}>
                          <DeleteIcon width={11} height={11} fill={palette.red500} />
                        </span>
                      </>
                    )}
                  </SActions>
                </td>
              </STableRow>
            )
          )}
          {forms.map((item) => (
            <FormItem
              key={item.id}
              accounts={accounts}
              onSubmit={(values) => createNewDeduction(values, item.id)}
              onDelete={() => deleteFormItem(item.id)}
              loading={loadingCreate}
              taxOptions={taxOptions}
              flatOrPercent
            />
          ))}
        </tbody>
      </STable>
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
          Add Another...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default Deductions;
