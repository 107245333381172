import { formatNumber } from 'utils/constants';

export const generateShipmentCharges = ({
  doc,
  pageWidth,
  chargesStart,
  pickupStops,
  total_weight,
  total_qty,
  shipment,
}) => {
  let chargesEnd = chargesStart + 13;

  const { empty_miles, loaded_miles, carrier_pay, shipment_notes, driver_amount } = shipment || {};

  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#000000');
  doc.setFontSize(8);

  [...(carrier_pay || [])]?.forEach((item, index, array) => {
    // item
    doc.text(`${item?.type_name?.title || ''}`, 8, chargesStart + (index + 1) * 5);

    // description
    doc.text(`${item.description}`, 40, chargesStart + (index + 1) * 5);

    // Quantity
    doc.text(`${formatNumber(item.quantity)}`, 132, chargesStart + (index + 1) * 5);

    // Rate
    doc.text(`${formatNumber(item.rate)}`, 162, chargesStart + (index + 1) * 5);

    // Amount
    doc.text(`${formatNumber(item.total)}`, 188, chargesStart + (index + 1) * 5);

    if (index !== array.length - 1) {
      doc.setDrawColor(188, 194, 206);
      doc.line(8, chargesStart + 1.5 + (index + 1) * 5, pageWidth - 8, chargesStart + 1.5 + (index + 1) * 5);
    }

    doc.setDrawColor(0, 0, 0);
    if (index === array.length - 1) {
      chargesEnd = chargesStart + (index + 1) * 5 + 8;
    }
  });

  doc.setFont('Inter', 'normal', 700);
  doc.text('Stop Point', 8, chargesEnd);
  doc.text('Total Pieces', 80, chargesEnd);
  doc.text('Total Weight', 132, chargesEnd);
  doc.text('Total Carrier Pay', 184, chargesEnd);
  doc.setDrawColor(0, 0, 0);
  doc.line(8, chargesEnd + 1, pageWidth - 8, chargesEnd + 1);

  doc.setFont('Inter', 'normal', 400);
  [...(pickupStops || [])]?.forEach((stop, index, array) => {
    doc.text(`${stop.stop_point?.location_name}`, 8, chargesEnd + (index + 1) * 4);
    doc.text(`${stop.quantity ? formatNumber(stop.quantity) : ''}`, 80, chargesEnd + (index + 1) * 4);
    doc.text(`${stop.weight ? formatNumber(stop.weight) : ''}`, 132, chargesEnd + (index + 1) * 4);

    if (index === array.length - 1) {
      doc.setDrawColor(188, 194, 206);
      doc.line(8, chargesEnd + (index + 1) * 4 + 1, pageWidth - 8, chargesEnd + (index + 1) * 4 + 1);
      doc.text(`${total_qty ? formatNumber(total_qty) : ''}`, 80, chargesEnd + (index + 1) * 4 + 4);
      doc.text(`${total_weight ? formatNumber(total_weight) : ''}`, 132, chargesEnd + (index + 1) * 4 + 4);
      doc.text(`$ ${formatNumber(driver_amount || 0)}`, 188, chargesEnd + (index + 1) * 4 + 4);

      doc.setFont('Inter', 'normal', 700);
      doc.text('Loaded Miles', 8, chargesEnd + (index + 1) * 4 + 11);
      doc.text('Empty Miles', 40, chargesEnd + (index + 1) * 4 + 11);
      doc.text('Total Miles', 70, chargesEnd + (index + 1) * 4 + 11);
      doc.setDrawColor(0, 0, 0);
      doc.line(8, chargesEnd + (index + 1) * 4 + 12, 90, chargesEnd + (index + 1) * 4 + 12);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${loaded_miles ? Number(loaded_miles).toFixed() : ''}`, 8, chargesEnd + (index + 1) * 4 + 16);
      doc.text(`${empty_miles ? Number(empty_miles).toFixed() : ''}`, 40, chargesEnd + (index + 1) * 4 + 16);
      doc.text(`${formatNumber((loaded_miles || 0) + (empty_miles || 0))}`, 70, chargesEnd + (index + 1) * 4 + 16);

      chargesEnd += index * 4 + 8;
    }
  });

  // needs to be fixed
  const notesToShow = shipment_notes.filter((note) => note.title === 'Confirmation Note');
  const notesStart = chargesEnd + 20;
  let notesEnd = notesStart + 12;

  // Shipment notes
  if (notesToShow.length > 0) {
    doc.setFont('Inter', 'normal', 700);
    doc.text('Confirmation Notes', 8, notesStart);

    doc.setFont('Inter', 'normal', 400);

    notesToShow.forEach((note, index) => {
      doc.text(`${note.notes}`, 8, notesStart + (index + 1) * 4, { maxWidth: pageWidth - 16 });
      if (index === notesToShow.length - 1) {
        notesEnd = notesStart + (index + 3) * 4;
      }
    });
  }

  return { chargesEnd: notesEnd || chargesEnd };
};
