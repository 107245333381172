import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import useForm from 'hooks/useForm';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { getCities, getStates } from 'Api/JobPositions';
import { assignUser, searchLocation } from 'Api/CardManagement';
import { locationValidationSchema, timeValidationSchema } from './validationSchema';
import { days, hours, minutes, COUNTRIES } from './SetRestriction.data';
import { SCustomModal } from '../../Cards.styles';
import { SInputWrapper, SFlexWrapper, SSearchInputsWrapper } from './SetRestriction.styles';

const SetRestriction = ({ open, onClose, onSuccess, data, defaultType, timeDataToUpdate }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [type, setType] = useState(defaultType || 1);
  const [locationOptions, setLocationOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { location_restrictions, time_limits } = data || {};

  const searchLocations = async (params) => {
    setLoadingLocations(true);
    try {
      const { data } = await searchLocation(params);
      setLocationOptions(!data?.length && !!data ? [data] : data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingLocations(false);
    }
  };

  const onTimeSubmit = async (values) => {
    if (
      Number(values.startTimeHours) > Number(values.endTimeHours) ||
      (Number(values.startTimeHours) === Number(values.endTimeHours) &&
        Number(values.startTimeMinutes) >= Number(values.endTimeMinutes))
    ) {
      showToaster({ type: 'error', message: "Start time can't be after end time" });
      return;
    }

    setLoading(true);
    try {
      let restrictionIndex = 0;
      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (time_limits?.length) {
        time_limits.forEach((item, index) => {
          if (timeDataToUpdate && timeDataToUpdate.id === item.id) {
            formData.append(`timeRestrictions[${index}][day]`, values.day.value);
            formData.append(
              `timeRestrictions[${index}][startTime]`,
              moment
                .unix(0)
                .set({ hour: values.startTimeHours, minute: values.startTimeMinutes })
                .format('YYYY-MM-DDTHH:mm:ss.sss')
            );
            formData.append(
              `timeRestrictions[${index}][endTime]`,
              moment
                .unix(0)
                .set({ hour: values.endTimeHours, minute: values.endTimeMinutes })
                .format('YYYY-MM-DDTHH:mm:ss.sss')
            );
            return;
          }
          formData.append(`timeRestrictions[${index}][day]`, item.day);
          formData.append(`timeRestrictions[${index}][startTime]`, item.beginTime);
          formData.append(`timeRestrictions[${index}][endTime]`, item.endTime);
          restrictionIndex++;
        });
      }

      if (!timeDataToUpdate) {
        formData.append(`timeRestrictions[${restrictionIndex}][day]`, values.day.value);
        formData.append(
          `timeRestrictions[${restrictionIndex}][startTime]`,
          moment
            .unix(0)
            .set({ hour: values.startTimeHours, minute: values.startTimeMinutes })
            .format('YYYY-MM-DDTHH:mm:ss.sss')
        );
        formData.append(
          `timeRestrictions[${restrictionIndex}][endTime]`,
          moment
            .unix(0)
            .set({ hour: values.endTimeHours, minute: values.endTimeMinutes })
            .format('YYYY-MM-DDTHH:mm:ss.sss')
        );
      }

      await assignUser(formData);
      showToaster({ type: 'success', message: 'Card restrictions have been successfully updated' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onLocationSubmit = async (values) => {
    setLoading(true);
    try {
      let restrictionIndex = 0;
      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (location_restrictions?.length) {
        location_restrictions.forEach((item, index) => {
          if (item.location_details) {
            formData.append(`locations[${index}][location_id]`, item.location_details.location_id);
            formData.append(`locations[${index}][location_name]`, item.location_details.location_name);
            formData.append(`locations[${index}][state_id]`, item.location_details.state_id);
            formData.append(`locations[${index}][city_id]`, item.location_details.city_id);
            formData.append(`locations[${index}][country_id]`, item.location_details.country_id);
            restrictionIndex++;
          }
        });
      }

      if (values.locations.length) {
        values.locations.forEach((item, index) => {
          formData.append(`locations[${restrictionIndex + index}][location_id]`, item.locId);
          formData.append(`locations[${restrictionIndex + index}][location_name]`, item.name);
          formData.append(`locations[${restrictionIndex + index}][state_id]`, item.state);
          formData.append(`locations[${restrictionIndex + index}][city_id]`, item.city);
          formData.append(`locations[${restrictionIndex + index}][country_id]`, item.country);
        });
      }

      await assignUser(formData);
      showToaster({ type: 'success', message: 'Card restrictions have been successfully updated' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const timeForm = useForm({
    initialValues: {
      day: timeDataToUpdate ? days.find((i) => i.value === Number(timeDataToUpdate.day)) : null,
      startTimeHours: timeDataToUpdate ? moment(timeDataToUpdate.beginTime).format('HH') : null,
      startTimeMinutes: timeDataToUpdate ? moment(timeDataToUpdate.beginTime).format('mm') : null,
      endTimeHours: timeDataToUpdate ? moment(timeDataToUpdate.endTime).format('HH') : null,
      endTimeMinutes: timeDataToUpdate ? moment(timeDataToUpdate.endTime).format('mm') : null,
    },
    onSubmit: onTimeSubmit,
    validationSchema: timeValidationSchema,
  });

  const locationForm = useForm({
    initialValues: {
      name: '',
      country: null,
      state: null,
      city: null,
      locations: [],
    },
    onSubmit: onLocationSubmit,
    validationSchema: locationValidationSchema,
  });

  const debouncedValues = useDebounce(locationForm.values, 500);

  useEffect(() => {
    const { name, country, state, city } = debouncedValues || {};
    if (name || country || state || city) {
      const params = {
        name: name || undefined,
        country: country?.key || undefined,
        state: state?.short_name || state?.name || undefined,
        city: city?.name ? city.name.toUpperCase() : undefined,
      };
      searchLocations(params);
    }
  }, [debouncedValues.name, debouncedValues.country, debouncedValues.state, debouncedValues.city]);

  useEffect(() => {
    if (locationForm.values.country) {
      getStatesList({ 'country_id[]': [locationForm.values.country.id] });
    }
  }, [locationForm.values.country]);

  useEffect(() => {
    if (locationForm.values.state) {
      getCitiesList(locationForm.values.state.id);
    }
  }, [locationForm.values.state]);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Set Restriction'
      $bgColor={palette.gray0}
      $maxWidth='540px'
      $minWidth='540px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Set Restriction',
          disabled: loading,
          onClick: type === 1 ? timeForm.handleSubmit : locationForm.handleSubmit,
        },
      ]}
    >
      <div>
        {type === 2 && <Typography variant='s2'>Restrict to NOT ALLOW transactions on selected location(s)</Typography>}
        {!timeDataToUpdate && !defaultType && (
          <SFlexWrapper className='mb-3'>
            <CustomRadioButton
              field={{
                name: 'type',
                value: 1,
                onChange: () => setType(1),
                checked: type === 1,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                By Date and Time
              </Typography>
            </CustomRadioButton>
            <CustomRadioButton
              field={{
                name: 'type',
                value: 2,
                onChange: () => setType(2),
                checked: type === 2,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                By Location
              </Typography>
            </CustomRadioButton>
          </SFlexWrapper>
        )}
        {type === 1 && (
          <div>
            <div className='mb-3'>
              <Autocomplete
                width='300px'
                required
                name='day'
                label='Day'
                options={days}
                value={timeForm.values.day}
                onChange={(e, value) => timeForm.handleChange('day', value)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                error={timeForm.touchedErrors.day}
              />
            </div>
            <div className='mb-3'>
              <InputLabel required>Start Time</InputLabel>
              <div className='d-flex gap-4'>
                <SInputWrapper>
                  <Autocomplete
                    name='startTimeHours'
                    placeholder='Hours'
                    options={hours}
                    value={timeForm.values.startTimeHours}
                    onChange={(e, value) => timeForm.handleChange('startTimeHours', value)}
                    error={timeForm.touchedErrors.startTimeMinutes}
                  />
                </SInputWrapper>
                <SInputWrapper>
                  <Autocomplete
                    name='startTimeMinutes'
                    placeholder='Minutes'
                    options={minutes}
                    value={timeForm.values.startTimeMinutes}
                    onChange={(e, value) => timeForm.handleChange('startTimeMinutes', value)}
                    error={timeForm.touchedErrors.startTimeMinutes}
                  />
                </SInputWrapper>
              </div>
            </div>
            <div className='mb-3'>
              <InputLabel required>End Time</InputLabel>
              <div className='d-flex gap-4'>
                <SInputWrapper>
                  <Autocomplete
                    name='endTimeHours'
                    placeholder='Hours'
                    options={hours}
                    value={timeForm.values.endTimeHours}
                    onChange={(e, value) => timeForm.handleChange('endTimeHours', value)}
                    error={timeForm.touchedErrors.endTimeHours}
                  />
                </SInputWrapper>
                <SInputWrapper>
                  <Autocomplete
                    name='endTimeMinutes'
                    placeholder='Minutes'
                    options={minutes}
                    value={timeForm.values.endTimeMinutes}
                    onChange={(e, value) => timeForm.handleChange('endTimeMinutes', value)}
                    error={timeForm.touchedErrors.endTimeMinutes}
                  />
                </SInputWrapper>
              </div>
            </div>
          </div>
        )}
        {type === 2 && (
          <SSearchInputsWrapper>
            <SFlexWrapper>
              <Input
                name='name'
                label='Name'
                placeholder='Name'
                value={locationForm.values.name}
                onChange={locationForm.handleChange}
                className='flex-grow-1 w-100'
              />
              <div className='flex-grow-1 w-100'>
                <Autocomplete
                  name='country'
                  label='Country'
                  disableClearable={false}
                  placeholder='Country..'
                  value={locationForm.values.country}
                  onChange={(e, value) => {
                    locationForm.handleChange('country', value);
                    locationForm.handleChange('state', null);
                    locationForm.handleChange('city', null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={COUNTRIES}
                />
              </div>
            </SFlexWrapper>
            <SFlexWrapper>
              <div className='flex-grow-1'>
                <Autocomplete
                  name='state'
                  label='State'
                  disableClearable={false}
                  placeholder='Select State..'
                  value={locationForm.values.state}
                  onChange={(e, value) => {
                    locationForm.handleChange('state', value);
                    locationForm.handleChange('city', null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={states}
                />
              </div>
              <div className='flex-grow-1'>
                <Autocomplete
                  name='city'
                  label='City'
                  disableClearable={false}
                  placeholder='Select City..'
                  value={locationForm.values.city}
                  onChange={(e, value) => locationForm.handleChange('city', value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={cities}
                />
              </div>
            </SFlexWrapper>
            <div>
              <Autocomplete
                required
                multiple
                disableClearable={false}
                loading={loadingLocations}
                name='locations'
                label='Locations'
                placeholder='Locations'
                limitTags={2}
                options={locationOptions}
                value={locationForm.values.locations}
                onChange={(e, value) => locationForm.handleChange('locations', value)}
                isOptionEqualToValue={(option, value) => option.locId === value.locId}
                getOptionLabel={(option) => `${option.name}, ${option.country}, ${option.state}, ${option.city}`}
                error={locationForm.touchedErrors.locations}
              />
            </div>
          </SSearchInputsWrapper>
        )}
      </div>
    </SCustomModal>
  );
};

export default SetRestriction;
