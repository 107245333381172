import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import Modal from 'common/Modal';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { createJobAction, updateJobStatus } from 'Api/Applicants';
import { uploadDocument } from 'Api/JobPositions';
import { getErrorMessage } from 'utils/error';
import { getMergedUrl } from 'utils/helpers';
import ApplicantInfoTab from './tabs/ApplicantInfoTab';
import YourActionsTab from './tabs/YourActionsTab';
import {
  checkResultOptions,
  actionInitialValues,
  createActionConvertor,
  criminalCheckResultOptions,
} from './ViewApplication.data';
import { actionValidationSchema } from './validationSchemas';
import { STabs } from '../../ApplicantsAndReferrals.styles';

const ViewApplicantModal = ({ applicant, statuses, onClose, onUpdate, isDriverProfile }) => {
  const showToaster = useShowToaster();
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;
  const { id, status, full_name, job_action, orientation_scheduled_date, previous_employment } = applicant || {};
  const [activeTab, setActiveTab] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [orientationDate, setOrientationDate] = useState(
    orientation_scheduled_date ? moment(orientation_scheduled_date, 'YYYY-MM-DD HH:mm:ss').toDate() : null
  );
  const [loading, setLoading] = useState(false);

  const mergeUploadedDocuments = async (documents) => {
    if (!documents.length) {
      return;
    }

    const docs = [...documents.filter((file) => file.endsWith('.pdf'))];

    const { blob } = await getMergedUrl(docs);
    const response = await uploadDocument(blob, dot);

    return response?.document_path;
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      // const { formData, updated, shouldCreate } = previousEmploymentConvertor(
      //   values.prevEmployments,
      //   id,
      //   values.prevEmployments?.filter((el) => el.id)
      // );

      const fmcsaAttachment = values.fmcsa_attachment?.length
        ? await mergeUploadedDocuments(values.fmcsa_attachment)
        : '';

      if (form.dirty) {
        await createJobAction(createActionConvertor(values, fmcsaAttachment, id));
      }
      if (selectedStatus !== status.id || selectedStatus === 6) {
        await updateJobStatus(id, {
          status_id: selectedStatus,
          orientation_scheduled_date: moment(orientationDate).isValid()
            ? moment(orientationDate).format('YYYY-MM-DD HH:mm:ss')
            : null,
        });
      }
      // if (shouldCreate) {
      //   await createPreviousEmployment(formData, dot);
      // }
      // if (updated?.id?.length) {
      //   await updatePreviousEmployment(updated, dot);
      // }
      onUpdate();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const form = useForm({
    initialValues: actionInitialValues,
    onSubmit: handleFormSubmit,
    validationSchema: actionValidationSchema,
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const resetState = () => {
    setActiveTab(0);
    form.resetForm();
  };

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    if (newValue === 4) {
      form.setErrors({});
    }
  };

  useEffect(() => {
    if (!status) {
      return;
    }
    setSelectedStatus(status.name !== 'New' ? status.id : 2);
  }, [status]);

  useEffect(() => {
    form.setValues((prevState) => {
      return {
        ...prevState,
        criminal_check_result: job_action
          ? job_action.criminal_check_result
            ? criminalCheckResultOptions[0]
            : criminalCheckResultOptions[1]
          : null,
        mvr_check_result: job_action
          ? job_action.mvr_check_result
            ? checkResultOptions[0]
            : checkResultOptions[1]
          : null,
        drug_test_result: job_action
          ? job_action.drug_test_result
            ? checkResultOptions[0]
            : checkResultOptions[1]
          : null,
        fmcsa_result: job_action ? (job_action.fmcsa_result ? checkResultOptions[0] : checkResultOptions[1]) : null,
        criminal_checked_date: job_action?.criminal_checked_date
          ? moment(job_action?.criminal_checked_date).toDate()
          : null,
        mvr_checked_date: job_action?.mvr_checked_date ? moment(job_action?.mvr_checked_date).toDate() : null,
        drug_checked_date: job_action?.drug_checked_date ? moment(job_action?.drug_checked_date).toDate() : null,
        fmcsa_checked_date: job_action?.fmcsa_checked_date ? moment(job_action?.fmcsa_checked_date).toDate() : null,
        criminal_checked_attachment: job_action?.criminal_checked_attachment || '',
        mvr_check_attachment: job_action?.mvr_check_attachment || '',
        drug_test_attachment: job_action?.drug_test_attachment || '',
        attach_medical_verification: job_action?.attach_medical_verification || '',
        attach_national_registry: job_action?.attach_national_registry || '',
        fmcsa_attachment: job_action?.fmcsa_attachment ? [job_action?.fmcsa_attachment] : [],
        prevEmployments: previous_employment?.length
          ? previous_employment.map((employment) => {
              return {
                ...employment,
                id: employment.id,
                request_date: employment.request_date ? moment(employment.request_date).toDate() : new Date(),
                employer_name: employment.employer_name || '',
                start_date: employment.start_date ? moment(employment.start_date).toDate() : null,
                end_date: employment.end_date ? moment(employment.end_date).toDate() : null,
                present: employment.present || 0,
                request_form_path: employment.request_form_path || '',
                request_form_source: employment.request_form_source || 'Email',
                response: employment.response || '',
                email: employment.email || '',
                fax: employment.fax || '',
                phone_no: employment.phone_no || '',
                request_type: employment.request_type || 'Pdf',
                response_path: employment.response_path || '',
              };
            })
          : undefined,
      };
    });
  }, [applicant]);

  const tabs = [
    { id: 0, component: <ApplicantInfoTab applicant={applicant} /> },
    ...(!isDriverProfile
      ? [
          {
            id: 1,
            component: (
              <YourActionsTab
                form={form}
                statuses={statuses}
                applicant={applicant}
                applicantTypeId={applicant?.job_positing?.applicant_type?.id}
                selectedStatus={selectedStatus}
                handleStatusChange={handleStatusChange}
                orientationDate={orientationDate}
                setOrientationDate={setOrientationDate}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Modal
      showModal={!!applicant}
      onHide={onClose}
      onExited={resetState}
      backdrop='static'
      $bgColor={activeTab === 1 ? palette.gray0 : null}
      $minWidth='1200px'
      $maxWidth='1200px'
      $height='78vh'
      padding={activeTab === 0 ? '56px 20px 16px' : null}
      headerTitle={
        applicant ? (
          <>
            {full_name} - Application ID {id}
            <STabs value={activeTab} onChange={handleTabChange}>
              <Tab label='Application' id='tab-1' />
              {!isDriverProfile && <Tab label='Your Action' id='tab-2' />}
            </STabs>
          </>
        ) : (
          'Application'
        )
      }
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close', className: 'modal-cancel-btn' },
        ...(activeTab === 1
          ? [
              {
                key: 'submit',
                type: 'primary',
                title: 'Update',
                disabled: loading,
                onClick: form.handleSubmit,
              },
            ]
          : []),
      ]}
    >
      {tabs[activeTab].component}
    </Modal>
  );
};

export default ViewApplicantModal;
