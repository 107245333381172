import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'assets/icons/drivers/moreInfo.svg';
import Chip from 'common/Chip';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { generatePdf } from 'components/AddIncidentModal/AddIncidentModal.data';
import { palette } from 'utils/constants';
import { getMergedUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { uploadDriverDocument } from 'Api/Driver';
import {
  getDriverPsp,
  createDriverPsp,
  updateDriverPsp,
  getFmcsaResults,
  createDriverFmcsa,
  updateDriverFmcsa,
  getDriverMvrRecords,
  getMedicalExaminers,
  createDriverMvrRecords,
  updateDriverMvrRecords,
  createMedicalExaminers,
  updateMedicalExaminers,
  getDriverCriminalRecords,
  getDriverDrugTestRecords,
  createDriverCriminalRecords,
  updateDriverCriminalRecords,
  createDriverDrugTestRecords,
  updateDriverDrugTestRecords,
} from 'Api/DriverV2';
import MedicalAndBackgroundEdit from './MedicalAndBackgroundEdit';
import { resultStatuses } from './MedicalAndBackgroundTab.data';
import { SWrapper, SUnderlineText, STable, SEditWrapper } from './MedicalAndBackgroundTab.styles';

const MedicalAndBackgroundTab = () => {
  const { id } = useParams();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [medicalData, setMedicalData] = useState(null);

  const mergeUploadedDocuments = async (documents) => {
    if (!documents.length) {
      return;
    }

    const { url } = documents.some(
      (file) => file?.endsWith('.png') || file?.endsWith('.jpg') || file?.endsWith('.jpeg')
    )
      ? generatePdf(documents)
      : {};

    const docs = [...documents.filter((file) => file.endsWith('.pdf'))];
    if (url) {
      docs.push(url);
    }

    const { blob } = await getMergedUrl(docs);
    const { data } = await uploadDriverDocument(blob, id);

    return data?.full_path;
  };

  const getDriverMedicalAndBackground = async () => {
    try {
      const p1 = getMedicalExaminers(id);
      const p2 = getDriverCriminalRecords(id);
      const p3 = getDriverMvrRecords(id);
      const p4 = getDriverDrugTestRecords(id);
      const p5 = getDriverPsp(id);
      const p6 = getFmcsaResults(id);
      const [
        { value: medical },
        { value: criminal },
        { value: mvr },
        { value: drug },
        { value: psp },
        { value: fmcsa },
      ] = await Promise.allSettled([p1, p2, p3, p4, p5, p6]);
      setMedicalData({
        medicalExaminersResults: medical?.data || [],
        mvrCheckResults: mvr?.data || [],
        drugTestResults: drug?.data || [],
        criminalCheckResults: criminal?.data || [],
        pspResults: psp?.data || [],
        fmcsaResults: fmcsa?.data || [],
      });
    } catch (e) {
      // Do nothing
    }
  };

  const onSave = async (values) => {
    setLoading(true);
    const {
      medicalExaminersResults,
      mvrCheckResults,
      drugTestResults,
      criminalCheckResults,
      pspResults,
      fmcsaResults,
    } = values;

    const newMedicalExaminers = medicalExaminersResults.filter((item) => item.isNew);
    const updateMedicalExaminersResults = medicalExaminersResults.filter((item) => !item.isNew);
    const newMvr = mvrCheckResults.filter((item) => item.isNew);
    const updateMvr = mvrCheckResults.filter((item) => !item.isNew);
    const newDrugTests = drugTestResults.filter((item) => item.isNew);
    const updateDrugTests = drugTestResults.filter((item) => !item.isNew);
    const newCriminalChecks = criminalCheckResults.filter((item) => item.isNew);
    const updateCriminalChecks = criminalCheckResults.filter((item) => !item.isNew);
    const newPsp = pspResults.filter((item) => item.isNew);
    const updatePsp = pspResults.filter((item) => !item.isNew);
    const newFmcsa = fmcsaResults.filter((item) => item.isNew);
    const updateFmcsa = fmcsaResults.filter((item) => !item.isNew);

    const newMedicalExaminersBody = {
      records: newMedicalExaminers.map((item) => ({
        driver_id: id,
        expiry_date: item.expiry_date ? moment(item.expiry_date).format('YYYY-MM-DD') : '',
        attach_card: item.attach_card?.includes('https') ? item.attach_card : '',
        attach_long_form: item.attach_long_form?.includes('https') ? item.attach_long_form : '',
        attach_medical_verification: item.attach_medical_verification?.includes('https')
          ? item.attach_medical_verification
          : '',
        attach_national_registry: item.attach_national_registry?.includes('https') ? item.attach_national_registry : '',
      })),
    };

    const updateMedicalExaminersBody = {
      records: updateMedicalExaminersResults.map((item) => ({
        id: item.id,
        driver_id: id,
        expiry_date: item.expiry_date ? moment(item.expiry_date).format('YYYY-MM-DD') : '',
        attach_card: item.attach_card?.includes('https') ? item.attach_card : '',
        attach_long_form: item.attach_long_form?.includes('https') ? item.attach_long_form : '',
        attach_medical_verification: item.attach_medical_verification?.includes('https')
          ? item.attach_medical_verification
          : '',
        attach_national_registry: item.attach_national_registry?.includes('https') ? item.attach_national_registry : '',
      })),
    };

    const newMvrBody = {
      records: newMvr.map((item) => ({
        driver_id: id,
        mvr_check_results: item.mvr_check_results,
        mvr_check_date: item.mvr_check_date ? moment(item.mvr_check_date).format('YYYY-MM-DD') : '',
        mvr_next_check_date: item.mvr_next_check_date ? moment(item.mvr_next_check_date).format('YYYY-MM-DD') : '',
        attach_mvr_check_result: item.attach_mvr_check_result?.includes('https') ? item.attach_mvr_check_result : '',
      })),
    };

    const updateMvrBody = {
      records: updateMvr.map((item) => ({
        id: item.id,
        driver_id: id,
        mvr_check_results: item.mvr_check_results,
        mvr_check_date: item.mvr_check_date ? moment(item.mvr_check_date).format('YYYY-MM-DD') : '',
        mvr_next_check_date: item.mvr_next_check_date ? moment(item.mvr_next_check_date).format('YYYY-MM-DD') : '',
        attach_mvr_check_result: item.attach_mvr_check_result?.includes('https') ? item.attach_mvr_check_result : '',
      })),
    };

    const newDrugTestBody = {
      records: newDrugTests.map((item) => ({
        driver_id: id,
        type: item.type,
        reason: item.reason,
        drug_check_results: item.drug_check_results,
        drug_checked_date: item.drug_checked_date ? moment(item.drug_checked_date).format('YYYY-MM-DD') : '',
        attach_drug_check_result: item.attach_drug_check_result?.includes('https') ? item.attach_drug_check_result : '',
        drug_test_custom_form: item.drug_test_custom_form?.includes('https') ? item.drug_test_custom_form : '',
      })),
    };

    const updateDrugTestBody = {
      records: updateDrugTests.map((item) => ({
        id: item.id,
        driver_id: id,
        type: item.type,
        reason: item.reason,
        drug_check_results: item.drug_check_results,
        drug_checked_date: item.drug_checked_date ? moment(item.drug_checked_date).format('YYYY-MM-DD') : '',
        attach_drug_check_result: item.attach_drug_check_result?.includes('https') ? item.attach_drug_check_result : '',
        drug_test_custom_form: item.drug_test_custom_form?.includes('https') ? item.drug_test_custom_form : '',
      })),
    };

    const newCriminalCheckBody = {
      records: newCriminalChecks.map((item) => ({
        driver_id: id,
        criminal_check_results: item.criminal_check_results,
        criminal_check_date: item.criminal_check_date ? moment(item.criminal_check_date).format('YYYY-MM-DD') : '',
        attach_criminal_result: item.attach_criminal_result?.includes('https') ? item.attach_criminal_result : '',
      })),
    };

    const updateCriminalCheckBody = {
      records: updateCriminalChecks.map((item) => ({
        id: item.id,
        driver_id: id,
        criminal_check_results: item.criminal_check_results,
        criminal_check_date: item.criminal_check_date ? moment(item.criminal_check_date).format('YYYY-MM-DD') : '',
        attach_criminal_result: item.attach_criminal_result?.includes('https') ? item.attach_criminal_result : '',
      })),
    };

    const newPspBody = {
      records: newPsp.map((item) => ({
        driver_id: id,
        psp_check_date: item.psp_check_date ? moment(item.psp_check_date).format('YYYY-MM-DD') : '',
        attach_psp_check_result: item.attach_psp_check_result?.includes('https') ? item.attach_psp_check_result : '',
      })),
    };

    const updatePspBody = {
      records: updatePsp.map((item) => ({
        id: item.id,
        driver_id: id,
        psp_check_date: item.psp_check_date ? moment(item.psp_check_date).format('YYYY-MM-DD') : '',
        attach_psp_check_result: item.attach_psp_check_result?.includes('https') ? item.attach_psp_check_result : '',
      })),
    };

    const fmcsaDosPromises = newFmcsa.map((item) => {
      return mergeUploadedDocuments(item.attach_fmcsa_result);
    });

    const fmcsaDocUrls = await Promise.all(fmcsaDosPromises);

    const newFmcsaBody = {
      records: newFmcsa.map((item, index) => {
        return {
          driver_id: id,
          reason: item.reason,
          fmcsa_checked_date: item.fmcsa_checked_date ? moment(item.fmcsa_checked_date).format('YYYY-MM-DD') : '',
          fmcsa_next_check_date: item.fmcsa_next_check_date
            ? moment(item.fmcsa_next_check_date).format('YYYY-MM-DD')
            : null,
          attach_fmcsa_result: fmcsaDocUrls[index] || '',
        };
      }),
    };

    const fmcsaUpdaeDosPromises = updateFmcsa.map((item) => {
      return mergeUploadedDocuments(item.attach_fmcsa_result);
    });

    const fmcsaUpdateDocUrls = await Promise.all(fmcsaUpdaeDosPromises);

    const updateFmcsaBody = {
      records: updateFmcsa.map((item, index) => {
        return {
          id: item.id,
          driver_id: id,
          reason: item.reason,
          fmcsa_checked_date: item.fmcsa_checked_date ? moment(item.fmcsa_checked_date).format('YYYY-MM-DD') : '',
          fmcsa_next_check_date: item.fmcsa_next_check_date
            ? moment(item.fmcsa_next_check_date).format('YYYY-MM-DD')
            : null,
          attach_fmcsa_result: fmcsaUpdateDocUrls[index] || '',
        };
      }),
    };

    try {
      const promises = [];
      promises.push(createMedicalExaminers(id, newMedicalExaminersBody));
      if (newMvr?.[0]?.mvr_check_date) {
        promises.push(createDriverMvrRecords(id, newMvrBody));
      }
      if (newDrugTests?.[0]?.drug_checked_date) {
        promises.push(createDriverDrugTestRecords(id, newDrugTestBody));
      }
      if (newCriminalChecks?.[0]?.criminal_check_date) {
        promises.push(createDriverCriminalRecords(id, newCriminalCheckBody));
      }
      if (newPsp?.[0]?.psp_check_date) {
        promises.push(createDriverPsp(id, newPspBody));
      }
      promises.push(createDriverFmcsa(id, newFmcsaBody));
      promises.push(updateMedicalExaminers(updateMedicalExaminersBody));
      promises.push(updateDriverMvrRecords(updateMvrBody));
      promises.push(updateDriverDrugTestRecords(updateDrugTestBody));
      promises.push(updateDriverCriminalRecords(updateCriminalCheckBody));
      promises.push(updateDriverPsp(updatePspBody));
      promises.push(updateDriverFmcsa(updateFmcsaBody));
      await Promise.allSettled(promises);
      showToaster({ type: 'success', message: 'Driver medical and background data have been successfully updated!' });
      getDriverMedicalAndBackground();
      setEditMode(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDriverMedicalAndBackground();
  }, []);

  return (
    <SWrapper>
      <div className='medical-tab-header'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Medical and Background
        </Typography>
        <div className='d-flex align-items-center gap-3'>
          {editMode ? (
            <>
              <CustomButton
                title='Cancel'
                type='secondary'
                disabled={loading}
                onClick={() => {
                  getDriverMedicalAndBackground();
                  setEditMode(false);
                }}
                styleTitle={{ fontSize: 12, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: 0 }}
              />
              <CustomButton
                title='Save'
                type='primary'
                disabled={loading}
                buttonProps={{ type: 'submit', form: 'medical-and-background-form', onClick: undefined }}
                styleTitle={{ fontSize: 12, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: 0 }}
              />
            </>
          ) : (
            <EditIcon className='pointer' onClick={() => setEditMode(true)} />
          )}
        </div>
      </div>
      {!editMode ? (
        <SEditWrapper>
          <div className='edit-box'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              Medical Examiners Results
            </Typography>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>EXPIRY DATE</th>
                  <th>CARD</th>
                  <th>LONG FORM</th>
                  <th>MEDICAL VERIFICATION</th>
                  <th>NATIONAL REGISTRY</th>
                </tr>
              </thead>
              <tbody>
                {medicalData?.medicalExaminersResults?.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      <Typography variant='s2'>{item?.expiry_date ? formatDate(item.expiry_date) : '-'}</Typography>
                    </td>
                    <td>
                      {item?.attach_card ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_card,
                              title: 'Medical Examiners Card',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      {item?.attach_long_form ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_long_form,
                              title: 'Medical Examiners Long Form',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      {item?.attach_medical_verification ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_medical_verification,
                              title: 'Medical Verification',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      {item?.attach_national_registry ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_national_registry,
                              title: 'National Registry',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td />
                  </tr>
                ))}
                {!medicalData?.medicalExaminersResults?.length && (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </STable>
            {!medicalData?.medicalExaminersResults?.length && <NoRecords />}
          </div>

          <div className='edit-box'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              Criminal Check Results
            </Typography>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>RESULTS</th>
                  <th>DATE CHECKED</th>
                  <th>ATTACHMENT</th>
                </tr>
              </thead>
              <tbody>
                {medicalData?.criminalCheckResults?.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      {item?.criminal_check_results ? (
                        <Chip
                          size='small'
                          label={item.criminal_check_results.toUpperCase()}
                          labelColor={resultStatuses[item.criminal_check_results].color}
                          bgColor={resultStatuses[item.criminal_check_results].bgColor}
                        />
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      <Typography variant='s2'>
                        {item?.criminal_check_date ? formatDate(item.criminal_check_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      {item?.attach_criminal_result ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_criminal_result,
                              title: 'Criminal Check Result',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                ))}
                {!medicalData?.criminalCheckResults?.length && (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </STable>
            {!medicalData?.criminalCheckResults?.length && <NoRecords />}
          </div>

          <div className='edit-box'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              MVR Check Results
            </Typography>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>REASON</th>
                  <th>DATE CHECKED</th>
                  <th>NEXT CHECK DATE</th>
                  <th>ATTACHMENT</th>
                </tr>
              </thead>
              <tbody>
                {medicalData?.mvrCheckResults?.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      {item?.mvr_check_results ? (
                        <Chip
                          size='small'
                          label={item.mvr_check_results.toUpperCase()}
                          labelColor={resultStatuses[item.mvr_check_results].color}
                          bgColor={resultStatuses[item.mvr_check_results].bgColor}
                        />
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      <Typography variant='s2'>
                        {item?.mvr_check_date ? formatDate(item.mvr_check_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='s2'>
                        {item?.mvr_next_check_date ? formatDate(item.mvr_next_check_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      {item?.attach_mvr_check_result ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_mvr_check_result,
                              title: 'MVR Check Result',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td />
                    <td />
                  </tr>
                ))}
                {!medicalData?.mvrCheckResults?.length && (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </STable>
            {!medicalData?.mvrCheckResults?.length && <NoRecords />}
          </div>

          <div className='edit-box'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              Drug & Alcohol Results
            </Typography>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>TYPE</th>
                  <th>DATE CHECKED</th>
                  <th>REASON</th>
                  <th>RESULTS</th>
                  <th>ATTACHMENT</th>
                  <th>DRUG TEST CUSTOM FORM</th>
                </tr>
              </thead>
              <tbody>
                {medicalData?.drugTestResults?.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      <Typography variant='s2'>{item?.type || '-'}</Typography>
                    </td>
                    <td>
                      <Typography variant='s2'>
                        {item?.drug_checked_date ? formatDate(item.drug_checked_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='s2'>{item?.reason || '-'}</Typography>
                    </td>
                    <td>
                      {item?.drug_check_results ? (
                        <Chip
                          size='small'
                          label={item.drug_check_results.toUpperCase()}
                          labelColor={resultStatuses[item.drug_check_results].color}
                          bgColor={resultStatuses[item.drug_check_results].bgColor}
                        />
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      {item?.attach_drug_check_result ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_drug_check_result,
                              title: 'Drug & Alcohol Results',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      {item?.drug_test_custom_form ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.drug_test_custom_form,
                              title: 'Drug Test Custom Form',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                  </tr>
                ))}
                {!medicalData?.drugTestResults?.length && (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </STable>
            {!medicalData?.drugTestResults?.length && <NoRecords />}
          </div>

          <div className='edit-box'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              FMCSA Clearinghouse Result
            </Typography>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>REASON</th>
                  <th>DATE CHECKED</th>
                  <th>NEXT CHECK DATE</th>
                  <th>ATTACHMENT</th>
                </tr>
              </thead>
              <tbody>
                {medicalData?.fmcsaResults?.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      {item?.reason ? (
                        <Chip
                          size='small'
                          label={item.reason.toUpperCase()}
                          labelColor={resultStatuses[item.reason].color}
                          bgColor={resultStatuses[item.reason].bgColor}
                        />
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td>
                      <Typography variant='s2'>
                        {item?.fmcsa_checked_date ? formatDate(item.fmcsa_checked_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='s2'>
                        {item?.fmcsa_next_check_date ? formatDate(item.fmcsa_next_check_date) : 'No expiry'}
                      </Typography>
                    </td>
                    <td>
                      {item?.attach_fmcsa_result ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_fmcsa_result,
                              title: 'FMCSA Clearinghouse Result',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td />
                    <td />
                  </tr>
                ))}
                {!medicalData?.fmcsaResults?.length && (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </STable>
            {!medicalData?.fmcsaResults?.length && <NoRecords />}
          </div>

          <div className='edit-box'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              PSP Results
            </Typography>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>DATE CHECKED</th>
                  <th>ATTACHMENT</th>
                </tr>
              </thead>
              <tbody>
                {medicalData?.pspResults?.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      <Typography variant='s2'>
                        {item?.psp_check_date ? formatDate(item.psp_check_date) : '-'}
                      </Typography>
                    </td>
                    <td>
                      {item?.attach_psp_check_result ? (
                        <SUnderlineText
                          onClick={() =>
                            setAttachment({
                              path: item.attach_psp_check_result,
                              title: 'PSP Results',
                            })
                          }
                        >
                          View
                        </SUnderlineText>
                      ) : (
                        <Typography variant='s3'>-</Typography>
                      )}
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                ))}
                {!medicalData?.pspResults?.length && (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </STable>
            {!medicalData?.pspResults?.length && <NoRecords />}
          </div>
        </SEditWrapper>
      ) : (
        <MedicalAndBackgroundEdit onSave={onSave} driverId={id} medicalData={medicalData} />
      )}
      {!!attachment && (
        <ViewPdf
          open={!!attachment}
          onClose={() => setAttachment(null)}
          pdfUrl={attachment.path}
          title={attachment.title}
        />
      )}
    </SWrapper>
  );
};

export default MedicalAndBackgroundTab;
