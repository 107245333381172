import axios from 'axios';
import authHeader from '../services/auth-header';
import axiosConfigured from '../services/axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function AbsenceData({
  page,
  query,
  from_date,
  to_date,
  sortField,
  sortBy = 'desc',
  itemsPerPage,
  type,
  status,
}) {
  const url = `${API_URL}/absence`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      query,
      type,
      status,
      itemsPerPage,
      [sortField]: sortBy,
      from_date,
      to_date,
    },
  });
  return data;
}

export async function getAbsenceList(params) {
  const { data } = await axiosConfigured.get('absence', { params });
  return data;
}

export async function createAbsence(body) {
  const { data } = await axiosConfigured.post('absence', body);
  return data;
}

export async function updateAbsence(body, id) {
  const { data } = await axiosConfigured.put(`absence/${id}`, body);
  return data;
}

export async function deleteAbsence(userType, id) {
  const { data } = await axiosConfigured.delete(`absence/${userType}/${id}`);
  return data;
}

export async function getAbsenceTypes(params) {
  const { data } = await axiosConfigured.get('absence/get-types', { params });
  return data;
}
