import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'formik';
import { useTheme } from 'context/themeContext';
import { getAlphabet, palette } from 'utils/constants';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { Typography } from 'components/Typography';
import { getEquipmentDimensions } from 'Api/EquipmentImport';
import sm from './AddStopPoint.module.css';

import PickUp from '../PickUp';
import Waypoint from '../Waypoint';
import Delivery from '../Delivery';
import { ShipmentContext } from '../../Layout';

import PopoverCreatShipment, { PopoverCreatShipmentItem } from '../helpers/PopoverCreatShipment';

const AddStopPoint = ({
  leg,
  legs,
  namePrefix,
  stopPoints,
  legsStopsCount,
  indexPrefix,
  values,
  showPopover,
  setShowPopover,
  errors,
  touched,
  optimizeRoute,
  setOptimizeRoute,
  arrayHelpersPrefix,
  setFieldValue,
  setValues,
  setFieldTouched,
}) => {
  const { use } = useTheme();
  const [dimensions, setDimensions] = useState([]);
  const { equipmentRequired, shipmentSettings } = useContext(ShipmentContext);

  const prevStopsCount = useMemo(() => {
    const prevStopsCountArr = legsStopsCount.slice(0, indexPrefix);
    return prevStopsCountArr.length ? prevStopsCountArr.reduce((a, b) => a + b, 0) : 0;
  }, [legsStopsCount, indexPrefix]);

  const getStopComponent = useCallback((type) => {
    const TYPE_STOPS = {
      1: PickUp,
      2: Delivery,
      3: Waypoint,
    };
    return TYPE_STOPS[type];
  }, []);

  const getStopName = useCallback(
    (type) => {
      const TYPE_STOPS = {
        1: { type: ' : PICK UP', color: use(palette.green500, palette.green400) },
        2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500) },
        3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText) },
      };
      return TYPE_STOPS[type];
    },
    [use]
  );

  const getDimensions = async () => {
    try {
      const { data } = await getEquipmentDimensions();
      setDimensions(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDimensions();
  }, []);

  return (
    <div className={sm.addStopPoint_container}>
      <div className={sm.legNumber_wrapper} style={{ backgroundColor: use(palette.indigo50, palette.indigo200) }}>
        <Typography variant='s2' style={{ color: palette.indigo500 }}>
          {indexPrefix + 1}
        </Typography>
      </div>
      <FieldArray
        name={`${namePrefix}.stops`}
        render={(arrayHelpers) => {
          return (
            <div className={sm.stops_wrapper} style={{ marginRight: optimizeRoute ? 450 : 0 }}>
              {leg.stops.map((stop, index) => {
                const StopComponent = getStopComponent(stop.stop_point_type);

                const lengthOptions = dimensions.reduce((acc, cur) => {
                  if (Number(cur.equipment_type_id) === Number(stop.equipment_type) && cur.length_info) {
                    acc.push({
                      ...cur,
                      key: cur.length_info.id,
                      label: `${cur.length_info.length} ${cur.length_info.unit}`,
                      labelSelected: `${cur.length_info.length} ${cur.length_info.unit}`,
                    });
                  }
                  return acc;
                }, []);

                return (
                  <div key={stop.id} style={{ width: 710 }}>
                    <div
                      className={`
                                             ${sm.pickUp_title_wrapper}
                                             ${
                                               index === 0
                                                 ? sm.first_child
                                                 : leg.stops.length - 1 === index
                                                 ? sm.last_child
                                                 : ''
                                             }
                                            `}
                      style={{
                        backgroundColor: use(palette.white, palette.dark900),
                        borderColor: use(palette.gray50, palette.gray400),
                      }}
                    >
                      <Typography
                        variant='c1'
                        className={sm.pickUp_title}
                        style={{ color: getStopName(stop.stop_point_type).color }}
                      >
                        STOP {getAlphabet(prevStopsCount + index)} {getStopName(stop.stop_point_type).type}
                      </Typography>
                      <PopoverCreatShipmentItem
                        legs={legs}
                        id={stop.id}
                        index={index}
                        stops={leg.stops}
                        stop={stop}
                        deletePopover
                        arrayHelpers={arrayHelpers}
                        indexPrefix={indexPrefix}
                        arrayHelpersPrefix={arrayHelpersPrefix}
                        shipmentSettings={shipmentSettings}
                        iconComponent={
                          <ArrowDown
                            height={16}
                            fill={use(palette.gray400, palette.gray700)}
                            style={{ transform: index === 0 && 'translateX(-40px)' }}
                          />
                        }
                      />
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'flex-start', borderRight: `1px solid ${palette.gray100}` }}
                    >
                      <div
                        className={sm.stops_wrapper_item}
                        style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
                      >
                        <StopComponent
                          stop={stop}
                          values={values}
                          prevStop={leg.stops[index - 1]}
                          index={index}
                          indexPrefix={indexPrefix}
                          stopPoints={stopPoints}
                          allPrevStops={leg.stops}
                          arrayHelpers={arrayHelpers}
                          namePrefix={`${namePrefix}.stops`}
                          touched={touched}
                          errors={errors}
                          lastChild={leg.stops.length - 1}
                          equipmentLengthData={lengthOptions}
                          equipmentRequired={equipmentRequired}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>
                      {leg.stops.length === 1 && (
                        <PopoverCreatShipment
                          legLength={leg.stops.length}
                          values={values}
                          show={showPopover}
                          type='stop'
                          arrayHelpers={arrayHelpers}
                          optimizeRoute={optimizeRoute}
                          setOptimizeRoute={setOptimizeRoute}
                          setShow={(v) => {
                            setShowPopover(v);
                          }}
                          shipmentSettings={shipmentSettings}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

export default AddStopPoint;
