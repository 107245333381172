import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiTableRow-root.MuiTableRow-head th div {
    text-transform: none !important;

    .column-title {
      font-size: 13px;
    }
  }
`;
