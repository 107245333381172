import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getShipmentDispatchSettings, updateShipmentDispatchSettings } from 'Api/CompanySettings';
import { validationSchema } from 'pages/CompanySettings/pagesComponents/Dispatch/validationSchema';
import { assignDriverFromOptions, getInitialValues } from './Dispatch.data';
import { SWrapper } from './Dispatch.styles';

const Dispatch = ({ isConfiguringStep, onCancelPrevPage, onSaveAndNext }) => {
  const showToaster = useShowToaster();
  const [loadingSave, setLoadingSave] = useState(false);
  const [settings, setSettings] = useState(null);

  const onSubmit = async (values) => {
    setLoadingSave(true);
    try {
      const body = {
        immediately: Number(values.immediately),
        dispatch_next_in_line: values.dispatch_next_in_line || 0,
        dispatch_driver_hours_before_scheduled: values.is_dispatch_driver_hours_before_scheduled
          ? values.dispatch_driver_hours_before_scheduled
          : null,
        send_tracking_updates: Number(values.send_tracking_updates),
        send_driver_reminder: values.is_send_driver_reminder ? values.send_driver_reminder : null,
        driver_from_location: values.driver_from_location?.id || 1,
      };
      await updateShipmentDispatchSettings(body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const getSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      setSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Dispatch'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingSave}
      />
      <Divider margin='8px 0 24px' />
      <div className='form-wrapper'>
        <div className='form-field-row'>
          <CustomCheckbox
            name='immediately'
            type='switch'
            smail={false}
            checked={values.immediately}
            onChange={(checked) =>
              setValues((prevState) => ({
                ...prevState,
                immediately: checked,
                dispatch_next_in_line: false,
                is_dispatch_driver_hours_before_scheduled: false,
                dispatch_driver_hours_before_scheduled: '',
              }))
            }
          />
          <Typography variant='s2'>Dispatch Immediately</Typography>
        </div>
        <div className='form-field-row'>
          <CustomCheckbox
            name='dispatch_next_in_line'
            type='switch'
            smail={false}
            checked={values.dispatch_next_in_line}
            onChange={(checked) =>
              setValues((prevState) => ({
                ...prevState,
                dispatch_next_in_line: checked,
                immediately: false,
                is_dispatch_driver_hours_before_scheduled: false,
                dispatch_driver_hours_before_scheduled: '',
              }))
            }
          />
          <Typography variant='s2'>Dispatch When Next in Line (Based on Time - Auto Dispatch)</Typography>
        </div>
        <div className='form-field-row'>
          <CustomCheckbox
            name='is_dispatch_driver_hours_before_scheduled'
            type='switch'
            smail={false}
            checked={values.is_dispatch_driver_hours_before_scheduled}
            onChange={(checked) =>
              setValues((prevState) => ({
                ...prevState,
                is_dispatch_driver_hours_before_scheduled: checked,
                dispatch_driver_hours_before_scheduled: '',
                dispatch_next_in_line: false,
                immediately: false,
              }))
            }
          />
          <Typography variant='s2'>Dispatch</Typography>
          <Input
            width='120px'
            type='number'
            name='dispatch_driver_hours_before_scheduled'
            value={values.dispatch_driver_hours_before_scheduled}
            onChange={handleChange}
            error={touchedErrors.dispatch_driver_hours_before_scheduled}
            disabled={!values.is_dispatch_driver_hours_before_scheduled}
            onKeyDown={(e) => {
              blockNotNumberChars(e);
              blockNonPositiveNumbers(e);
            }}
          />
          <Typography variant='s2'>Hour(s) Before Scheduled Pickup</Typography>
        </div>
        <div className='form-field-row'>
          <CustomCheckbox
            name='send_tracking_updates'
            type='switch'
            smail={false}
            checked={values.send_tracking_updates}
            onChange={(checked) => handleChange('send_tracking_updates', checked)}
          />
          <Typography variant='s2'>Send Tracking Updates to Customer Contact</Typography>
        </div>
        <div className='form-field-row'>
          <CustomCheckbox
            name='is_send_driver_reminder'
            type='switch'
            smail={false}
            checked={values.is_send_driver_reminder}
            onChange={(checked) =>
              setValues((prevState) => ({ ...prevState, is_send_driver_reminder: checked, send_driver_reminder: '' }))
            }
          />
          <Typography variant='s2'>Send Driver a Reminder via SMS</Typography>
          <Input
            width='120px'
            type='number'
            name='send_driver_reminder'
            value={values.send_driver_reminder}
            onChange={handleChange}
            error={touchedErrors.send_driver_reminder}
            disabled={!values.is_send_driver_reminder}
            onKeyDown={(e) => {
              blockNotNumberChars(e);
              blockNonPositiveNumbers(e);
            }}
          />
          <Typography variant='s2'>Minute(s) Before Pick Up</Typography>
        </div>
        <div className='form-field-row'>
          <Typography variant='s2'>Assign Driver From</Typography>
          <div>
            <Autocomplete
              width='180px'
              name='driver_from_location'
              options={assignDriverFromOptions}
              value={values.driver_from_location}
              onChange={(e, val) => handleChange('driver_from_location', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
        </div>
      </div>
    </SWrapper>
  );
};

export default Dispatch;
