import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getOrdinal } from '../Incidents.data';
import { SContentRow } from './RecommendedActions.styles';

const Index = ({ open, onClose, recommendedActions, title }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Recommended Actions - ${title}`}
      $maxWidth='470px'
      $minWidth='470px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Close' }]}
    >
      <div>
        {recommendedActions.map((action, i) => (
          <SContentRow key={action.id}>
            <div className='table-item'>
              <Typography variant='s2'>{getOrdinal(i + 1)}</Typography>
            </div>
            <div className='table-item'>
              <Typography variant='s2'>{action.action}</Typography>
            </div>
          </SContentRow>
        ))}
      </div>
    </Modal>
  );
};

export default Index;
