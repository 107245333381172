import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  .terms-and-conditions {
    color: ${palette.indigo500};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;
