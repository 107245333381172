import React, { useEffect, useState } from 'react';
import { getDriverIncidentCount } from 'Api/Dashboard';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import DoughnutChart from '../../Charts/DoughnutChart';

const labels = {
  0: 'Low Risk',
  1: 'High Risk',
};

const NewIncidents = ({ year, refreshDat }) => {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [newIncidentsCounts, setNewIncidentsCounts] = useState(null);

  const getCounts = async () => {
    try {
      setLoading(true);
      let params = {};
      if (!year || year === 'All') {
        params = {};
      } else {
        params = {
          from_date: `01-01-${year}`,
          to_date: `31-12-${year}`,
        };
      }
      const { data } = await getDriverIncidentCount(params);
      setNewIncidentsCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [newIncidentsCounts?.low_risk, newIncidentsCounts?.high_risk],
      backgroundColor: [palette.green400, palette.red400],

      borderColor: [palette.green400, palette.red400],
      hoverBackgroundColor: [palette.green500, palette.red500],
      hoverBorderColor: [palette.green500, palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, [year, refreshDat]);

  return (
    <div className='d-flex '>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={newIncidentsCounts?.total}
        title='High Risk vs Low Risk'
      />
      <div className='d-flex  gap-5 me-5'>
        <div className='d-flex flex-column gap-1 align-items-center'>
          <Typography variant='c3' style={{ color: palette.gray700 }} />
          <Typography variant='s2' style={{ color: use(palette.gray700) }}>
            New
          </Typography>
          <Typography variant='h5' style={{ color: palette.indigo500, textAlign: 'center' }}>
            {newIncidentsCounts?.status?.new}
          </Typography>
        </div>
        <div className='d-flex flex-column gap-1 align-items-center'>
          <Typography variant='c3' style={{ color: palette.gray700 }} />
          <Typography variant='s2' style={{ color: use(palette.gray700), whiteSpace: 'nowrap' }}>
            In Progress
          </Typography>
          <Typography variant='h5' style={{ color: palette.indigo500, textAlign: 'center' }}>
            {newIncidentsCounts?.status?.in_progress}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NewIncidents;
