import { number, object, string } from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = object().shape({
  name: string().trim().required('Required').nullable(),
  phone_number: string().trim().matches(phoneRegExp, 'Enter a valid phone number').required('Required'),
  email: string().trim().email('Enter a valid email').required('Required'),
  address: string().trim().required('Required').nullable(),
  payment_term: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  account: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required').nullable(),
});
