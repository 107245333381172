import React from 'react';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { Typography } from 'components/Typography';
import { SFooter } from '../../PublicPositions.styles';

const Footer = () => {
  return (
    <SFooter>
      <div className='d-flex align-items-center'>
        <img src={truckinLogo} alt='Truckin digital logo' width={32} height={32} />
        <Typography variant='s2' className='gray-text ml-1'>
          Powered by{' '}
          <a className='blue-link' href='https://www.truckindigital.com'>
            Truckin Digital
          </a>
        </Typography>
      </div>
      <Typography className='gray-text'>Open Jobs</Typography>
    </SFooter>
  );
};

export default Footer;
