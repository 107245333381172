import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import TrackingDetailsModal from 'components/TrackinDetailModal/TrackingDetailModal';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { getWorkingSchedules } from 'Api/Payroll';
import { updateTimeTracking } from 'Api/TimeTracking';
import DisputedActions from 'pages/Payroll/shared/DisputedActions';
import { getTimeTracked } from 'pages/Payroll/TimeTracking/TimeTracking.convertors';
import EndTime from './components/EndTime';
import StartTime from './components/StartTime';
import EndLocation from './components/EndLocation';
import StartLocation from './components/StartLocation';
import HoursWorkedId from './components/HoursWorkedId';
import NoRecords from '../../components/NoRecords';
import DeclineReason from '../../components/DeclineReason';
import { timeTrackingConverter } from './converters';
import { STable, SWrapper } from '../Tables.styles';

const HoursWorked = ({ settlement, onUpdateSuccess, timeTracking, setTimeTracking }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [selectedTrucking, setSelectedTrucking] = useState(null);
  const [truckingToDecline, setTruckingToDecline] = useState(null);
  const [actionsVisible, setActionsVisible] = useState(null);

  const { user, user_type } = settlement || {};

  const getTimeTracking = async () => {
    try {
      const { data: workingSchedules } =
        user_type === 1 ? { data: [] } : await getWorkingSchedules({ driver_id: user.id });
      const newData = timeTrackingConverter(settlement, workingSchedules);
      setTimeTracking(newData || []);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const updateTracking = async (updatedTrackingInfo) => {
    setLoadingUpdate(true);
    const payload = { ...updatedTrackingInfo };

    const diffInMinutes = Math.abs(moment(payload.end_time || new Date()).diff(payload.start_time, 'minutes'));
    const hours_tracked = Math.floor(diffInMinutes / 60);
    const minutes_tracked = diffInMinutes % 60;

    try {
      await updateTimeTracking(selectedTrucking.id, {
        ...payload,
        user_id: selectedTrucking.user_id,
        user_type: selectedTrucking.user_type,
        start_time: moment.utc(payload.start_time).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment.utc(payload.end_time).format('YYYY-MM-DD HH:mm:ss'),
        hours_tracked,
        minutes_tracked,
      });
      onUpdateSuccess();
      setSelectedTrucking(null);
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingUpdate(false);
  };

  const onDecline = async (reason) => {
    const newData = timeTracking.map((item) =>
      item.id === truckingToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
    );
    setTimeTracking(newData);
    setTruckingToDecline(null);
  };

  const onApprove = (shipment) => {
    const newData = timeTracking.map((item) => (item.id === shipment.id ? { ...item, dispute_approved: true } : item));
    setTimeTracking(newData);
  };

  useEffect(() => {
    setLoading(true);
    getTimeTracking();
  }, [settlement]);

  return (
    <SWrapper>
      <Typography variant='h3' style={{ display: 'inline-block', marginBottom: '8px' }}>
        Hours Worked
      </Typography>
      <Divider margin='8px 0 20px' />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <STable>
          <thead>
            <tr className='header-row'>
              <th>ID</th>
              <th>HOURS TRACKED</th>
              <th>START TIME</th>
              <th>START LOCATION</th>
              <th>END TIME</th>
              <th>END LOCATION</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {timeTracking.map((item) => (
              <Fragment key={item.id}>
                <tr
                  className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                    item.dispute_approved ? 'dispute-approved' : ''
                  }`}
                  onClick={() =>
                    setSelectedTrucking({
                      ...item,
                      start_time: convertToCustomerTime(item.start_time, 'MM/DD/YYYY hh:mm A'),
                      end_time: convertToCustomerTime(item.end_time, 'MM/DD/YYYY hh:mm A'),
                    })
                  }
                  onMouseEnter={() => setActionsVisible(item.id)}
                  onMouseLeave={() => setActionsVisible(null)}
                >
                  <td style={{ width: '8%' }}>
                    <HoursWorkedId data={item} />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {getTimeTracked(item)}
                    </Typography>
                  </td>
                  <td style={{ width: '17%' }}>
                    <StartTime data={item} />
                  </td>
                  <td style={{ width: '20%' }}>
                    <StartLocation data={item} />
                  </td>
                  <td style={{ width: '17%' }}>
                    {item?.end_time ? (
                      <EndTime data={item} />
                    ) : (
                      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                        -
                      </Typography>
                    )}
                  </td>
                  <td style={{ width: '20%' }}>
                    <EndLocation data={item} />
                  </td>
                  <td style={{ width: '8%' }}>
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {currency}
                      {item.amount}
                    </Typography>
                  </td>
                </tr>
                {!!item.disputed && settlement.status_id === 4 && (
                  <tr
                    className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''} ${
                      item.dispute_approved ? 'dispute-approved-actions' : ''
                    }`}
                    onMouseEnter={() => setActionsVisible(item.id)}
                    onMouseLeave={() => setActionsVisible(null)}
                  >
                    <td colSpan='9'>
                      <DisputedActions
                        data={item}
                        onApprove={() => onApprove(item)}
                        onReject={() => setTruckingToDecline(item)}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </STable>
      )}
      {!timeTracking?.length && <NoRecords />}
      {!!selectedTrucking && (
        <TrackingDetailsModal
          show={!!selectedTrucking}
          onHide={() => setSelectedTrucking(null)}
          trackingDetails={selectedTrucking}
          updateTimeTracking={updateTracking}
          loading={loadingUpdate}
        />
      )}
      <DeclineReason open={!!truckingToDecline} onClose={() => setTruckingToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default HoursWorked;
