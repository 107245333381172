import styled from 'styled-components';

export const SWaitTimeGraph = styled.div`
  width: 100%;
  height: 366px;
  display: flex;

  .chart-box {
    width: 100%;
    border-left: none;
    padding: 16px;

    :first-child {
      padding-left: 2px;
    }

    :last-child {
      padding-right: 2px;
      border-right: none;
    }

    .title-wrapper {
      min-height: 25px;
    }

    .chart-wrapper {
      margin-top: 16px;
      height: calc(100% - 20px);
    }

    canvas {
      height: 100% !important;
    }
  }
`;
