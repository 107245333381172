import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './DispatchVehicle.css';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import useMediaQuery from '@mui/material/useMediaQuery';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import down from 'assets/icons/drivers/down.svg';
import headerIcon from 'assets/icons/vehicles/green.svg';
import subRight from 'assets/icons/vehicles/sub-right.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/vehicles/shipment.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';

import Input from 'common/Input';
import UserInfo from 'components/UserInfo';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import AddressAutocomplete from 'common/AddressAutocomplete';
import AddEditModalDetails from 'components/AddEditModalStops';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import classes from 'components/TableShipments/detailsRow/details.module.css';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import OverViewStop from 'components/TableShipments/detailsRow/steps/OverView/OverViewStops';
import { SkeletonStops } from 'components/TableShipments/detailsRow/steps/OverView/OverView';
import SettingsModal from 'components/TableShipments/detailsRow/steps/OverView/SettingsModal';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatAmount } from 'services/IFTAServices';
import { getAlphabet, palette, TRUCK_TYPES } from 'utils/constants';
import { blockNonPositiveNumbers, getShipmentType } from 'utils/helpers';
import {
  addShipmentStop,
  createMultipleDriverPay,
  deleteShipmentStop,
  getShipmentInfo,
  getStops,
  updateCargo,
  UpdateStopPointStop,
} from 'Api/Shipment';
import {
  getContactsBillingCharges,
  getCostCalculation,
  getDriverCost,
  getFullPrices,
  getPlannedShipments,
  getPreviousShipments,
  getShipmentStops,
  shipmentDispatch,
} from 'Api/Planner';
import { getSMSCommunicationSettings, getShipmentDispatchSettings } from 'Api/CompanySettings';
import { UpdateStopPoint } from 'Api/StopPoint';
import { checkAddOnStatus, getEquipment } from 'Api/Equipment';
import {
  createCargoConverter,
  createCargoFormDataConverter,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import ErrorMessage from 'common/ErrorMessage';
import { DateTimePicker } from 'common/Pickers';
import CostModal from './CostModal';
import DispatchMap from './DispatchMap';
import { dispatchFromOptions } from './helpers';
import { getPlannerStatus } from '../TableShipments/helpers/constants';
import AlreadyDispatchedModal from '../PlanToVehicle/AlreadyDispatchedModal';

export default function DispatchVehicle() {
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [searchParams] = useSearchParams();
  const shipmentId = searchParams.get('id');
  const equipmentId = searchParams.get('equipment');
  const amount = searchParams.get('amount');
  const selectList = [
    { name: 'Current Location', id: 1 },
    { name: 'Previous Shipment', id: 2 },
    { name: 'Planned Shipment', id: 3 },
    { name: 'Custom Location', id: 4 },
  ];
  const { currency } = useSelector((state) => state.root);
  const [routeSelect, setRouteSelect] = useState('practical');
  const [locationSelect, setLocationSelect] = useState('Current Location');
  const [customAddress, setCustomAddress] = useState('');
  const [customLatLong, setCustomLatLong] = useState(null);
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [previousShipments, setPreviousShipments] = useState([]);
  const [previousShipment, setPreviousShipment] = useState(null);
  const [planedShipments, setPlanedShipments] = useState([]);
  const [planedShipment, setPlanedShipment] = useState(null);
  const [data, setData] = useState(null);
  const [flatRateChecked, setFlatRateChecked] = useState(!!amount || false);
  const [flatRate, setFlatRate] = useState(amount || '');
  const [shipmentStops, setShipmentStops] = useState(null);
  const [fuelPrice, setFuelPrice] = useState(null);
  const [infoHeader, setInfoHeader] = useState();
  const [legs, setLegs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDispatch, setLoadingDispatch] = useState(false);
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState('');
  const [isOpen, setIsOpen] = useState({});
  const [showLicence, setShowLicence] = useState(false);
  const [allowStaticMiles, setAllowStaticMiles] = useState(false);
  const [openCostModal, setOpenCostModal] = useState(false);
  const [costForm, setCostForm] = useState([]);
  const [smsSettings, setSmsSettings] = useState(null);
  const initialRequestRef = useRef(false);
  const [alreadyDispatched, setAlreadyDispatched] = useState(null);
  const [driverData, setDriverData] = useState(null);
  const [flatRateError, setFlatRateError] = useState(false);
  const matches = useMediaQuery('(min-width:1600px)');
  const shipmentCostController = useRef(new AbortController());
  const driverCostController = useRef(new AbortController());
  const legsStopsCount = [];

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  }, []);

  const { values, handleChange, setValues } = useForm({
    initialValues: {
      immediately: true,
      in_future: false,
      future_date: null,
      after_specific_shipment_complated: false,
      before_scheduled_pickup: false,
      before_scheduled_pickup_hours: '0',
      send_trucking_updates_to_customer: false,
      send_trucking_link_to_customer: false,
      recipient_customer_contact_book_id: '',
      send_pickup_reminder_to_driver: false,
      send_pickup_reminder_to_driver_before_minutes: '30',
    },
  });

  const { values: mapValues, handleChange: handleMapValuesChange } = useForm({
    initialValues: {
      route_type: searchParams.get('route_type') || 'practical',
      hwy_only: searchParams.get('hwy_only') ? 1 : 0,
      open_borders: searchParams.get('open_borders') ? 1 : 0,
      haz_mat: searchParams.get('haz_mat') ? 1 : 0,
      avoid_tolls: searchParams.get('avoid_tolls') ? 1 : 0,
      show_traffic: searchParams.get('show_traffic') ? 1 : 0,
      show_weather: searchParams.get('show_weather') ? 1 : 0,
      vehicle_type: searchParams.get('vehicle_type') ? 1 : 0,
    },
  });

  const handleRouteSelect = (route) => {
    setRouteSelect(route);
    handleMapValuesChange('route_type', route);
  };

  const handleLocationSelect = (location) => {
    setPreviousShipment(null);
    setPlanedShipment(null);
    setCustomAddress('');
    setCustomLatLong(null);
    setLocationSelect(location);
  };

  const onDispatch = () => {
    if (
      !values.immediately &&
      !values.in_future &&
      !values.before_scheduled_pickup &&
      !values.after_specific_shipment_complated
    ) {
      showToaster({ type: 'error', message: 'One of the dispatch options should be selected!' });
      return;
    }

    if (flatRateChecked && !flatRate) {
      setFlatRateError(true);
      showToaster({ type: 'error', message: 'Please ensure that the driver cost is specified for this shipment!' });
      return;
    }

    setLoadingDispatch(true);
    const assign_driver_from = selectList.find((item) => item.name === locationSelect).id;
    const { estimated_fuel_cost, net_profit, total_amount, empty_miles, loaded_miles } = data;

    const params = {
      shipment_id: shipmentId,
      equipment_id: equipmentId,
      assign_driver_from,
      estimated_fuel_cost,
      net_profit: flatRateChecked ? +total_amount - (+flatRate + Number(data?.estimated_fuel_cost)) : net_profit,
      route_type: mapValues.route_type,
      hwy_only: Number(mapValues.hwy_only),
      open_borders: Number(mapValues.open_borders),
      avoid_tolls: Number(mapValues.avoid_tolls),
      haz_mat: Number(mapValues.haz_mat),
      driver1_amount:
        flatRateChecked && data?.equipment?.drivers?.length === 2
          ? +flatRate / 2
          : flatRateChecked && data?.equipment?.drivers?.length === 1
          ? flatRate
          : data?.driver1_cost,
      driver2_amount:
        flatRateChecked && data?.equipment?.drivers?.length === 2
          ? +flatRate / 2
          : flatRateChecked && data?.equipment?.drivers.length === 1
          ? undefined
          : !flatRateChecked && data.driver2_cost
          ? data.driver2_cost
          : undefined,
      flat: flatRateChecked ? 1 : 0,
      company_acceptance: 1,
      immediately: Number(values.immediately),
      in_future: Number(values.in_future),
      send_trucking_link_to_customer: Number(values.send_trucking_link_to_customer),
      before_scheduled_pickup: Number(values.before_scheduled_pickup),
      after_specific_shipment_complated: Number(values.after_specific_shipment_complated),
      future_date: moment(values.future_date).format('YYYY-MM-DD HH:mm:ss'),
      before_scheduled_pickup_hours: values.before_scheduled_pickup_hours,
      recipient_customer_contact_book_id: values.recipient_customer_contact_book_id,
      send_pickup_reminder_to_driver: Number(values.send_pickup_reminder_to_driver),
      send_pickup_reminder_to_driver_before_minutes: Number(values.send_pickup_reminder_to_driver_before_minutes),
      assign_driver_from_shipment:
        assign_driver_from === 2
          ? previousShipment?.shipment_id
          : assign_driver_from === 3
          ? planedShipment?.shipment_id
          : undefined,
      assign_driver_from_shipment_latitude:
        assign_driver_from === 4 && customLatLong ? customLatLong?.latitude : undefined,
      assign_driver_from_shipment_longitude:
        assign_driver_from === 4 && customLatLong ? customLatLong?.longitude : undefined,
      empty_miles: empty_miles || 0,
      loaded_miles: loaded_miles || 0,
      lane_id: infoHeader.lane_id,
    };

    shipmentDispatch(params)
      .then(() => {
        setLoadingDispatch(false);
        if (costForm.length) {
          const body = {
            shipment_id: shipmentId,
            driver_id: new Array(costForm.length).fill(costForm[0]?.driver_id),
            type: costForm.map((el) => el.type),
            quantity: costForm.map((el) => el.qty),
            rate: costForm.map((el) => el.rate),
            total: costForm.map((el) => Number(el.qty) * Number(el.rate)),
            addition_id: costForm.map((el) => el.addition.id || null),
          };

          createMultipleDriverPay(body).then(() => {
            if (values.immediately) {
              navigate(`/shipment/${shipmentId}/dispatch`, { state: { fromDispatch: true } });
            } else {
              navigate(`/shipments?id=${shipmentId}&defaultTab=Planned`);
              showToaster({
                type: 'success',
                message: `Successfully schedule pending dispatch for Shipment ${shipmentId}!`,
              });
            }
          });
          return;
        }
        if (values.immediately) {
          navigate(`/shipment/${shipmentId}/dispatch`, { state: { fromDispatch: true } });
        } else {
          navigate(`/shipments?id=${shipmentId}&defaultTab=Planned`);
          showToaster({
            type: 'success',
            message: `Successfully schedule pending dispatch for Shipment ${shipmentId}!`,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response?.shipment_id) {
          getShipmentInfo(shipmentId)
            .then((res) => {
              const { dispatched_by_user } = res.data || {};
              if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                setAlreadyDispatched(
                  `${dispatched_by_user.first_name || ''} ${
                    dispatched_by_user.last_name || ''
                  } has already dispatched this shipment, we are now exiting the dispatch process!`
                );
              }
            })
            .catch(() => {
              // Do nothing
            });
        } else {
          const message = getErrorMessage(err);

          showToaster({
            type: 'error',
            message: message?.includes('The assign driver from shipment field is required.')
              ? 'There is no previous shipment to select. Please change assign driver from to a different option.'
              : message?.includes('Undefined index: assign_driver_from_shipment_latitude')
              ? 'Please select driver location to dispatch'
              : message,
          });
        }
        setLoadingDispatch(false);
      });
  };

  const style = useMemo(() => {
    return {
      mapWrapper: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '726px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  useEffect(() => {
    const assign_driver_from = selectList.find((item) => item.name === locationSelect).id;

    if (
      (assign_driver_from === 2 && !previousShipment?.shipment_id) ||
      (assign_driver_from === 3 && !planedShipment?.shipment_id) ||
      (assign_driver_from === 4 && !customLatLong)
    ) {
      return;
    }

    const params = {
      shipment_id: shipmentId,
      equipments: [equipmentId],
      assign_driver_from,
      route_type: mapValues.route_type,
      hwy_only: mapValues.hwy_only ? 1 : 2,
      open_borders: mapValues.open_borders ? 1 : 2,
      avoid_tolls: mapValues.avoid_tolls ? 1 : 2,
      assign_driver_from_shipment:
        assign_driver_from === 2 && previousShipment?.shipment_id
          ? previousShipment?.shipment_id
          : assign_driver_from === 3 && planedShipment?.shipment_id
          ? planedShipment?.shipment_id
          : undefined,
      assign_driver_from_shipment_latitude:
        assign_driver_from === 4 && customLatLong ? customLatLong?.latitude : undefined,
      assign_driver_from_shipment_longitude:
        assign_driver_from === 4 && customLatLong ? customLatLong?.longitude : undefined,
    };

    if (shipmentCostController.current) {
      shipmentCostController.current.abort();
      shipmentCostController.current = new AbortController();
    }

    getCostCalculation(params, shipmentCostController.current.signal)
      .then((res) => {
        if (res.data && res.data[0]) {
          setData(res.data[0]);
          if (!initialRequestRef.current) {
            initialRequestRef.current = true;

            if (res.data[0].equipment?.drivers[0]?.compensation_details?.pay_option === 'flat_rate') {
              setFlatRateChecked(true);
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response?.shipment_id) {
          getShipmentInfo(shipmentId)
            .then((res) => {
              const { dispatched_by_user } = res.data || {};
              if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                setAlreadyDispatched(
                  `${dispatched_by_user.first_name || ''} ${
                    dispatched_by_user.last_name || ''
                  } has already dispatched this shipment, we are now exiting the dispatch process!`
                );
              }
            })
            .catch(() => {
              // Do nothing
            });
        }
        // Do nothing
      });
  }, [shipmentId, equipmentId, mapValues, locationSelect, previousShipment, planedShipment, customLatLong]);

  const getFullPriceShipment = () => {
    return getFullPrices().then((res) => {
      if (res && res?.data) {
        setFuelPrice(res.data?.diesel?.price);
      }
      return res;
    });
  };

  const getShipmentSettings = async () => {
    try {
      setAllowStaticMiles(true);
    } catch (e) {
      // Do nothing
    }
  };

  const getSmsSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setSmsSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDispatchSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      if (data) {
        setValues((prevState) => ({
          ...prevState,
          immediately: !!data.immediately,
          after_specific_shipment_complated: !!data.dispatch_next_in_line,
          before_scheduled_pickup: !!data.dispatch_driver_hours_before_scheduled,
          before_scheduled_pickup_hours: data.dispatch_driver_hours_before_scheduled || '0',
          send_trucking_link_to_customer: !!data.send_tracking_updates,
          send_pickup_reminder_to_driver: !!data.send_driver_reminder,
          send_pickup_reminder_to_driver_before_minutes: data.send_driver_reminder,
        }));

        if (data.driver_from_location) {
          handleLocationSelect(dispatchFromOptions[Number(data?.driver_from_location)]);
        }
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipmentData = async () => {
    try {
      const { data } = await getEquipment(equipmentId);
      setDriverData(data.drivers?.[0] || null);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getEquipmentData();
  }, []);

  useEffect(() => {
    getHeaderInfo();
    getShipmentStops(shipmentId).then((res) => {
      setShipmentStops(res.data);
    });
    getFullPriceShipment();
    getShipmentSettings();
    getSmsSettings();
    getDispatchSettings();
  }, []);

  useEffect(() => {
    setCostForm([]);
  }, [flatRateChecked]);

  useEffect(() => {
    if (driverData?.id) {
      const params = {
        driver_id: driverData?.id,
      };
      getPlannedShipments(params).then((res) => {
        setPlanedShipments(res.data);
      });
      getPreviousShipments(params).then((res) => {
        setPreviousShipments(res.data);
        if (locationSelect === 'Previous Shipment' && !previousShipment) {
          setPreviousShipment(res.data?.[0] || null);
        }
      });
    }
  }, [driverData?.id]);

  const { totalDollar } = useMemo(() => {
    let totalDollar = 0;
    let totalCad = 0;

    infoHeader?.shipment_billing?.forEach((el) => {
      const amount = el?.total_amount || 0; // Safeguard against null/undefined values
      if (Number(el?.currency_id) === 1) {
        totalDollar += amount;
      } else {
        totalCad += amount;
      }
    });

    return { totalDollar, totalCad };
  }, [infoHeader]);

  const getHeaderInfo = () => {
    getShipmentInfo(shipmentId)
      .then((res) => {
        if (res?.data) {
          // handleMapValuesChange('open_borders', res.data.shipment_route?.open_borders);
          // handleMapValuesChange('hwy_only', res.data.shipment_route?.highway_only);
          // handleMapValuesChange('haz_mat', res.data.shipment_route?.hazmat_id);
          // handleMapValuesChange('route_type', res.data.shipment_route?.route_type);
          // handleMapValuesChange('vehicle_type', res.data.shipment_route?.vehicle_type);
          // handleMapValuesChange('avoid_tolls', res.data.shipment_route?.avoid_tolls);
          setRouteSelect(res.data.shipment_route?.route_type);
          setInfoHeader(res.data);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  function onBackPlanner() {
    navigate(`/planner/plan?id=${shipmentId}`);
  }

  useEffect(() => {
    if (shipmentStops?.shipment_billing[0]?.billing_customer?.id) {
      getContactsBillingCharges(shipmentStops?.shipment_billing[0]?.billing_customer?.id).then((res) => {
        setCustomerContactBook(res.data);
      });
    }
  }, [shipmentStops?.shipment_billing[0]?.billing_customer?.id]);

  useEffect(() => {
    if (costForm?.length && costForm?.[0]?.type === 'empty_miles') {
      setData((prevState) => ({
        ...prevState,
        empty_miles: Number(costForm[0].qty),
        loaded_miles: Number(costForm[1].qty),
        driver1_cost: costForm.reduce((acc, cur) => acc + Number(cur.total), 0),
      }));
    }

    if (
      (costForm?.length && costForm?.[0]?.type === 'per_hour') ||
      (costForm?.length && costForm?.[0]?.type === 'percentage')
    ) {
      setData((prevState) => ({
        ...prevState,
        driver1_cost: costForm.reduce((acc, cur) => acc + Number(cur.total), 0),
      }));
    }

    if (costForm?.length && costForm?.[0]?.type === 'flat_rate') {
      setFlatRate(costForm.reduce((acc, cur) => acc + Number(cur.total), 0));
    }
  }, [costForm]);

  useEffect(() => {
    getStopsData();
  }, []);

  const getStopsData = () => {
    setLoading(true);
    getStops({ shipment_id: shipmentId })
      .then((res) => res?.data && setLegs(orderingInLegsStops(res?.data)))
      .finally(() => setLoading(false));
  };

  function onClickItemSettingsIcon(stop) {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  }

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? 1 : 2);
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'cargos') {
          const cargos = values.cargos?.length ? values.cargos.filter((i) => !!i?.commodity) : [];
          createCargoFormDataConverter(cargos, formData);
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(value).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('shipment_id', shipmentId);
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      addShipmentStop(formData)
        .then(() => {
          getStopsData();
          getHeaderInfo();
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const updateStop = (params, id) => {
    setLoading(true);
    UpdateStopPointStop({ params, id })
      .then(() => {
        getStopsData();
      })
      .finally(() => setLoading(false));
  };

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  const renderTooltip = (driver) => {
    if (data?.equipment?.drivers.length > 1) {
      return (
        <Tooltip>
          {driver.fname} {driver.lname}
        </Tooltip>
      );
    }
    return <span />;
  };

  function sumDurations(durations) {
    let totalMinutes = 0;
    for (const duration of durations) {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      const parts = duration.split(' ');
      for (const part of parts) {
        if (part.endsWith('d')) {
          days = parseInt(part, 10);
        } else if (part.endsWith('h')) {
          hours = parseInt(part, 10);
        } else if (part.endsWith('m')) {
          minutes = parseInt(part, 10);
        }
      }
      totalMinutes += days * 24 * 60 + hours * 60 + minutes;
    }
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${days}d ${hours}h ${minutes}m`;
  }

  const checkAddonStatus = (value, checked) => {
    checkAddOnStatus(2, data?.equipment?.id)
      .then((res) => {
        if (res.is_addon_active) {
          handleMapValuesChange(value, checked);
        } else {
          setShowLicence(true);
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong!' });
      });
  };

  const onAddressSelect = async (data) => {
    const res = await getGeoLocation(data);
    if (res.latitude && res.longitude) {
      setCustomLatLong(res);
    }
  };

  const getGeoLocation = async (value) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: value.address,
          city: value.city?.name,
          state: value.state.name,
          zip: value.zip,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                latitude: el.Coords?.Lat,
                longitude: el.Coords?.Lon,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  const changeAddress = ({ formatted_address }) => {
    setCustomAddress(formatted_address);
  };

  function onDeleteStop(stopId) {
    deleteShipmentStop(stopId)
      .then(() => {
        showToaster({ type: 'success', message: 'Shipment stop has been deleted successfully!' });
        getHeaderInfo();
        getStopsData();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  }

  const onUpdateSuccess = () => {
    getHeaderInfo();
    getStopsData();
  };

  const calculateDriverCost = async (params) => {
    try {
      if (driverCostController.current) {
        driverCostController.current.abort();
        driverCostController.current = new AbortController();
      }

      const { data } = await getDriverCost(params, driverCostController.current.signal);
      setData((prevState) => ({
        ...prevState,
        driver1_cost: Number(data?.driver_cost?.total.replaceAll(',', '')) || 0,
        estimated_fuel_cost: Number(data?.estimated_fuel_cost),
      }));
    } catch (e) {
      // Do nothing
    }
  };

  const onEmptyMilesChange = (e) => {
    if (Number(e.target.value) !== data.empty_miles) {
      setData((prevState) => ({ ...prevState, empty_miles: Number(e.target.value) }));
      const params = {
        driver_id: data.equipment.drivers[0].id,
        equipments: [equipmentId],
        loaded_miles: data.loaded_miles,
        empty_miles: Number(e.target.value),
        shipment_amount: data.total_amount,
        total_eta: data.total_eta,
      };
      calculateDriverCost(params);
    }
  };

  const onLoadedMilesChange = (e) => {
    if (Number(e.target.value) !== data.loaded_miles) {
      setData((prevState) => ({ ...prevState, loaded_miles: Number(e.target.value) }));
      const params = {
        driver_id: data.equipment.drivers[0].id,
        equipments: [equipmentId],
        loaded_miles: Number(e.target.value),
        empty_miles: data.empty_miles,
        shipment_amount: data.total_amount,
        total_eta: data.total_eta,
      };
      calculateDriverCost(params);
    }
  };

  return (
    <div className='profile-container'>
      {showLicence && (
        <div className='check-addon-dispatch' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='dispatch-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div onClick={onBackPlanner} className='back_plan_to_vehicle'>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Plan to Vehicle
              </Typography>
            </div>
          </div>
          {shipmentStops?.is_edi_api || shipmentStops?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex '>
            <div className='dispatch-vehicle-details w-100'>
              <div className='dispatch-vehicle-details-item'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Dispatch
                </h2>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <Typography variant='overLine2' className='d-flex'>
                  <span className='bold-text'>{shipmentStops?.shipment_id}</span>
                  (REF:&nbsp;
                  {(shipmentStops?.shipment_billing && shipmentStops?.shipment_type !== 'ltl') ||
                  (shipmentStops?.shipment_type === 'ltl' && shipmentStops?.shipment_billing.length === 1) ? (
                    <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {shipmentStops?.shipment_billing?.[0]?.reference_id}
                    </Typography>
                  ) : (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='button-tooltip-2'>
                          {shipmentStops?.shipment_billing?.map((el, i) => (
                            <p key={el?.id || i} style={{ margin: 0 }}>
                              {el?.reference_id}
                            </p>
                          ))}
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div ref={ref}>
                          <Typography
                            variant='s3'
                            {...triggerHandler}
                            style={{ color: use(palette.dark800, palette.gray200) }}
                          >
                            Multi-Ref LTL
                          </Typography>
                        </div>
                      )}
                    </OverlayTrigger>
                  )}
                  )
                </Typography>
              </div>
              <div className='dispatch-vehicle-details-item'>
                {shipmentStops?.shipment_billing &&
                shipmentStops?.shipment_billing.length &&
                shipmentStops?.shipment_billing.length === 1 ? (
                  <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {shipmentStops?.shipment_billing?.[0]?.billing_customer?.company_name}&nbsp;
                    {shipmentStops.shipment_type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
                  </Typography>
                ) : shipmentStops?.shipment_billing && shipmentStops?.shipment_billing.length > 1 ? (
                  <OverLayTriggerHoverWrapper
                    overlay={shipmentStops?.shipment_billing.map((el, i) => (
                      <Typography
                        key={el?.id || i}
                        as='p'
                        variant='s2'
                        style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/customer-profile/${el?.billing_customer?.id}`);
                        }}
                      >
                        {el?.billing_customer?.company_name}
                      </Typography>
                    ))}
                  >
                    <div>
                      <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                        Multi-Customer LTL
                      </Typography>
                    </div>
                  </OverLayTriggerHoverWrapper>
                ) : null}
              </div>
              {shipmentStops?.shipment_billing[0]?.groups && (
                <div className='dispatch-vehicle-details-item'>
                  <div
                    className='tablePlaner-shortName'
                    style={{
                      color: use(palette.gray900, palette.gray700),
                      backgroundColor: shipmentStops?.shipment_billing[0]?.groups?.color,
                    }}
                  >
                    {shipmentStops?.shipment_billing[0]?.groups?.short_name}
                  </div>
                </div>
              )}

              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>{shipmentStops?.shipment_stops_count} Stops</p>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <div className='d-flex justify-content-start}'>
                  <div className='tablePlaner_status-wrapper'>
                    <span
                      className='tablePlaner_status'
                      style={{
                        backgroundColor: getPlannerStatus(infoHeader)?.bgStatus,
                        color: getPlannerStatus(infoHeader)?.colorStatus,
                      }}
                    >
                      {getPlannerStatus(infoHeader)?.statusName}
                    </span>
                  </div>
                </div>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>
                  {currency} {formatAmount(totalDollar)}
                </p>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>
                  {Math.round(Number(data?.loaded_miles || 0)) + Math.round(Number(data?.empty_miles || 0))} Miles
                </p>
              </div>
              <div className='plan-vehicle-details-item'>
                <p className='plan-details-text'>{getShipmentType(infoHeader?.shipment_type)}</p>
              </div>
              {infoHeader?.planned_duration && data?.deadhead_drive_time && (
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Route Duration:{' '}
                    <span className='cost-purple-text ms-2'>
                      {sumDurations([
                        data?.deadhead_drive_time,
                        data?.shipment_drive_time,
                        ...[
                          ...(infoHeader?.shipment_stops || []).map((item) =>
                            getAverageTimeConverted(item.stop_point?.average_waiting_time)
                          ),
                        ],
                      ])}
                    </span>
                  </p>
                  <Dropdown className='d-inline mx-2' autoClose='outside'>
                    <Dropdown.Toggle
                      id='dropdown-autoclose-outside'
                      style={{
                        border: 'none',
                        outline: 'none',
                        background: 'none',
                        boxShadow: 'none',
                        padding: 0,
                      }}
                    >
                      <img alt='down' src={down} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                      <Dropdown.Item style={{ color: use(palette.dark800, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span>Deadhead Drive Time:</span>{' '}
                          <span className='cost-purple-text ms-2'>{data?.deadhead_drive_time}</span>
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item style={{ color: use(palette.dark800, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span>Shipment Drive Time:</span>{' '}
                          <span className='cost-purple-text ms-2'>{data?.shipment_drive_time}</span>
                        </p>
                      </Dropdown.Item>
                      {infoHeader?.shipment_stops?.map((item, index) => (
                        <Dropdown.Item key={item.id} style={{ color: use(palette.dark800, palette.gray200) }}>
                          <p className='dispatch-details-text d-flex justify-content-between'>
                            <span>Stop {getAlphabet(index)} - Avg. Wait Time:</span>{' '}
                            <span className='cost-purple-text ms-2'>
                              {getAverageTimeConverted(item.stop_point?.average_waiting_time)}
                            </span>
                          </p>
                        </Dropdown.Item>
                      ))}

                      <Dropdown.Item style={{ color: use(palette.dark500, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span style={{ fontWeight: 'bold' }}>Total Duration:</span>{' '}
                          <span className='cost-purple-text ms-2'>
                            {sumDurations([
                              data?.deadhead_drive_time,
                              data?.shipment_drive_time,
                              ...[
                                ...(infoHeader?.shipment_stops || []).map((item) =>
                                  getAverageTimeConverted(item.stop_point?.average_waiting_time)
                                ),
                              ],
                            ])}
                          </span>
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {infoHeader?.scheduled_duration && (
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Scheduled Duration:{' '}
                    <span className='cost-purple-text ms-2'>
                      {getAverageTimeConverted(infoHeader.scheduled_duration)}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='plan-accordion'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowUp className='accordion-arrow' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          />
          <AccordionDetails>
            <div className='w-100 mt-4'>
              <div>
                {loading ? (
                  <SkeletonStops />
                ) : (
                  <>
                    {legs.map((leg, index) => {
                      legsStopsCount.push(leg.stops.length);
                      return (
                        <div key={leg.id} className={classes.legCountContainer}>
                          <OverViewStop
                            leg={leg}
                            legs={legs}
                            setLegs={setLegs}
                            indexPrefix={index}
                            setIsOpen={setIsOpen}
                            infoHeader={infoHeader}
                            legsStopsCount={legsStopsCount}
                            onSelectPlus={setSelectModalType}
                            deleteStop={onDeleteStop}
                            onClickSettingsIcon={onClickItemSettingsIcon}
                            onUpdateSuccess={onUpdateSuccess}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className='w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='table-wrap'>
          <div className='sub-table-container-dispatch'>
            {!!data && typeof data === 'object' && Object.keys(data).length > 0 ? (
              <MaterialTableWrapper
                columns={[
                  {
                    title: 'Vehicle',
                    field: 'VehicleID',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Trailer',
                    field: 'Trailer',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                      minWidth: '200px',
                    },
                  },
                  {
                    title: 'Driver',
                    field: 'Driver',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Compensation',
                    field: 'Compensation',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Assign Driver From',
                    field: 'AssignDriverFrom',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Empty MIles',
                    field: 'EmptyMIles',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Loaded Miles',
                    field: 'LoadedMiles',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'ETA to stop a',
                    field: 'ETA',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Cost',
                    field: 'Cost',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                ]}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  borderTop: 'none',
                  width: '100%',
                  minHeight: 200,
                }}
                data={[
                  {
                    VehicleID: (
                      <div>
                        <p className='shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          {data?.equipment?.equipment_id}
                          <small> {data.equipment?.equipment_type?.title}</small>{' '}
                          {!!data?.equipment?.truck_type && <small>({TRUCK_TYPES[data.equipment.truck_type]})</small>}
                        </p>
                      </div>
                    ),
                    Trailer: (
                      <div className='d-flex flex-column'>
                        <div className='equipment-container d-flex align-items-center'>
                          <span
                            className='dispatch-details-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {' '}
                            {data?.equipment?.hooked_to?.equipment_number}
                          </span>
                          &nbsp;
                          <span className='dispatch-details-text'>
                            {' '}
                            {data?.equipment?.hooked_to?.equipment_type?.title}{' '}
                          </span>
                        </div>
                      </div>
                    ),
                    Driver: data?.equipment?.drivers.map((d) => {
                      return (
                        <UserInfo
                          key={d.id}
                          data={{
                            ...d,
                            name: `${d.fname} ${d.lname}`,
                            status: d.status_data?.driver_status,
                            user_type: 'driver',
                          }}
                        />
                      );
                    }),
                    Compensation: (
                      <div>
                        {data?.equipment?.drivers.map((driver) => (
                          <>
                            {driver?.compensation_details?.pay_option === 'mile' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Pay Per Mile</Typography>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.mile_loaded_amt)} Loaded
                                  </span>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.mile_empty_amt)} Empty
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}
                            {driver?.compensation_details?.pay_option === 'percentage' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Percentage</Typography>
                                  <span>{driver?.compensation_details?.shipment_percentage}% of Shipment</span>
                                </div>
                              </OverlayTrigger>
                            )}

                            {driver?.compensation_details?.pay_option === 'per_hour' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Per Hour</Typography>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.per_hour_worked)} Per Hour
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}
                            {driver?.compensation_details?.pay_option === 'flat_rate' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Flat Rate</Typography>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.flat_rate_amount)}
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}
                          </>
                        ))}
                      </div>
                    ),
                    AssignDriverFrom: (
                      <div style={{ minHeight: 120, minWidth: 280 }}>
                        <div className='d-flex align-items-center'>
                          <Dropdown className='d-inline'>
                            <Dropdown.Toggle
                              id='dropdown-autoclose-outside'
                              style={{
                                border: 'none',
                                outline: 'none',
                                background: 'none',
                                boxShadow: 'none',
                                marginLeft: '0',
                                paddingLeft: '0',
                                height: 40,
                              }}
                            >
                              <div className='d-flex align-items-center'>
                                <p
                                  className='table-data-text'
                                  style={{
                                    color: use(palette.gray700, palette.gray200),
                                    fontSize: 14,
                                  }}
                                >
                                  {locationSelect}
                                </p>
                                <img src={down} className='ms-2' alt='down' />
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className='dropdown-location'
                              style={{ backgroundColor: use(palette.white, palette.dark800) }}
                            >
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                style={{ color: use(palette.dark800, palette.gray200) }}
                                onClick={() => handleLocationSelect('Current Location')}
                              >
                                Current Location
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                onClick={() => handleLocationSelect('Previous Shipment')}
                                style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                Previous Shipment
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                onClick={() => handleLocationSelect('Planned Shipment')}
                                style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                Planned Shipment
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                onClick={() => handleLocationSelect('Custom Location')}
                                style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                Custom Location
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {locationSelect === 'Previous Shipment' && (
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={subRight}
                                className='sub-right-icon'
                                alt={subRight}
                                style={{ display: 'inline-block' }}
                              />
                              <Autocomplete
                                options={previousShipments}
                                value={previousShipment}
                                onChange={(e, value) => setPreviousShipment(value)}
                                getOptionLabel={(option) =>
                                  `Shipment ${option.shipment_id} (${
                                    option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                                  }, ${
                                    option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state
                                      ?.short_name
                                  })`
                                }
                                id='select_list'
                                name='list'
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                              />
                            </div>
                            <div className='mt-2'>
                              {previousShipment?.shipment_stops?.length && (
                                <>
                                  <p className='dispatch-details-text'>
                                    {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                      ?.stop_point?.address1
                                      ? `${
                                          previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                            .stop_point.address1
                                        },`
                                      : ''}
                                    {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                      ?.stop_point?.address2
                                      ? `${
                                          previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                            .stop_point.address2
                                        },`
                                      : ''}
                                  </p>
                                  <p className='dispatch-details-text'>
                                    {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                      ?.stop_point?.state?.name
                                      ? `${
                                          previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                            .stop_point.state.name
                                        },`
                                      : ''}
                                    {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                      ?.stop_point?.city?.name
                                      ? `${
                                          previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                            .stop_point.city.name
                                        },`
                                      : ''}
                                    {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                      ?.stop_point?.zipcode
                                      ? previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]
                                          .stop_point.zipcode
                                      : ''}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {locationSelect === 'Planned Shipment' && (
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={subRight}
                                className='sub-right-icon'
                                alt={subRight}
                                style={{ display: 'inline-block' }}
                              />
                              <Autocomplete
                                options={planedShipments}
                                value={planedShipment}
                                onChange={(e, value) => setPlanedShipment(value)}
                                getOptionLabel={(option) =>
                                  `Shipment ${option.shipment_id} (${
                                    option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                                  }, ${
                                    option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state
                                      ?.short_name
                                  })`
                                }
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Typography variant='c1'>
                                      {`Shipment ${option.shipment_id} (${
                                        option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                                      },${
                                        option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state
                                          ?.short_name
                                      })`}
                                    </Typography>
                                  </li>
                                )}
                                id='select_list'
                                name='list'
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                              />
                            </div>
                            <div className='mt-2'>
                              {planedShipment?.shipment_stops?.length && (
                                <>
                                  <p className='dispatch-details-text'>
                                    {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point
                                      ?.address1
                                      ? `${
                                          planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]
                                            .stop_point.address1
                                        },`
                                      : ''}
                                    {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point
                                      ?.address2
                                      ? `${
                                          planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]
                                            .stop_point.address2
                                        },`
                                      : ''}
                                  </p>
                                  <p className='dispatch-details-text'>
                                    {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point
                                      ?.state?.name
                                      ? `${
                                          planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]
                                            .stop_point.state.name
                                        },`
                                      : ''}
                                    {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point
                                      ?.city?.name
                                      ? `${
                                          planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]
                                            .stop_point.city.name
                                        },`
                                      : ''}
                                    {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point
                                      ?.zipcode
                                      ? planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]
                                          .stop_point.zipcode
                                      : ''}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {locationSelect === 'Current Location' && (
                          <p className='dispatch-details-text'>
                            {data?.equipment?.location_target === 'Equipment'
                              ? `${data.equipment?.equipment_gps?.location}`
                              : `${data?.equipment?.drivers[0]?.driver_gps?.location}`}
                          </p>
                        )}
                        {locationSelect === 'Custom Location' && (
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={subRight}
                                className='sub-right-icon'
                                alt={subRight}
                                style={{ display: 'inline-block' }}
                              />
                              <AddressAutocomplete
                                placeholder='Search Custom Location'
                                onSuccess={(data) => onAddressSelect(data)}
                                changeAddress={(data) => changeAddress(data)}
                                onChange={(e) => {
                                  setCustomAddress(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                  }
                                }}
                                value={customAddress}
                                options={{
                                  types: ['geocode', 'establishment'],
                                  componentRestrictions: { country: ['us', 'ca', 'mx'] },
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                    EmptyMIles: (
                      <div>
                        {allowStaticMiles ? (
                          <Input
                            size='small'
                            type='number'
                            name='equipment_id'
                            onChange={onEmptyMilesChange}
                            value={Number(data?.empty_miles).toFixed()}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        ) : (
                          <Typography variant='s2'> {Number(data?.empty_miles).toFixed()}</Typography>
                        )}
                      </div>
                    ),
                    LoadedMiles: (
                      <div>
                        {allowStaticMiles ? (
                          <Input
                            size='small'
                            type='number'
                            name='equipment_id'
                            onChange={onLoadedMilesChange}
                            value={Number(data?.loaded_miles).toFixed()}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        ) : (
                          <Typography variant='s2'>{Number(data?.loaded_miles).toFixed()}</Typography>
                        )}
                      </div>
                    ),
                    ETA: (
                      <div>
                        <p className='dispatch-details-text'>{data?.eta}</p>
                        <p className='dispatch-details-text'>
                          {data?.current_location?.stop_point?.address1
                            ? `${data?.current_location?.stop_point?.address1},`
                            : ''}
                          {data?.current_location?.stop_point?.address2
                            ? `${data?.current_location?.stop_point?.address2},`
                            : ''}
                        </p>
                        <p className='dispatch-details-text'>
                          {data?.current_location?.stop_point?.state?.name
                            ? `${data?.current_location?.stop_point?.state?.name},`
                            : ''}
                          {data?.current_location?.stop_point?.city?.name
                            ? `${data?.current_location?.stop_point?.city?.name},`
                            : ''}
                          {data?.current_location?.stop_point?.zipcode
                            ? data?.current_location?.stop_point?.zipcode
                            : ''}
                        </p>
                      </div>
                    ),
                    Cost: (
                      <div>
                        {data.equipment.drivers[0].compensation_details.pay_option === 'flat_rate' ||
                        flatRateChecked ? (
                          <div>
                            <div className='d-flex align-items-center gap-2'>
                              <CustomInput
                                value={flatRate}
                                labelStyle={{ margin: 0 }}
                                onChange={(e) => {
                                  setFlatRate(e.target.value);
                                  setFlatRateError(false);
                                }}
                                style={{ width: 100, borderRadius: 6, height: 30 }}
                              />
                              {allowStaticMiles && <EditIcon onClick={() => setOpenCostModal(true)} />}
                            </div>
                            <ErrorMessage error={flatRateError && 'Required'} />
                          </div>
                        ) : (
                          <div className='d-flex align-items-center gap-2'>
                            <p
                              className='dispatch-details-text'
                              style={{ color: use(palette.gray700, palette.gray200) }}
                            >
                              {currency}
                              {formatAmount(Number(Number(data?.driver1_cost) + Number(data?.driver2_cost)).toFixed(2))}
                            </p>
                            {allowStaticMiles && <EditIcon onClick={() => setOpenCostModal(true)} />}
                          </div>
                        )}

                        <div>
                          <CustomCheckbox
                            checked={flatRateChecked}
                            onChange={(value) => setFlatRateChecked(value)}
                            disabled={data?.equipment?.drivers[0]?.compensation_details?.pay_option === 'flat_rate'}
                          >
                            <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                              Flat Rate
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      </div>
                    ),
                  },
                ]}
                options={{
                  paging: false,
                  sorting: false,
                  toolbar: false,
                  rowStyle: {
                    borderBottom: 'none',
                    width: '100%',
                  },
                  headerStyle: {
                    borderTop: 'none',
                    width: '100%',
                  },
                }}
              />
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </div>
      <div className='dispatch-vehicle-content'>
        <div className='dispatch-vehicle-data'>
          <div>
            <form>
              <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Options
              </h6>
              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.immediately}
                  name='immediately'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('in_future', false);
                      handleChange('after_specific_shipment_complated', false);
                      handleChange('before_scheduled_pickup', false);
                    } else if (
                      !values.in_future &&
                      !values.after_specific_shipment_complated &&
                      !values.before_scheduled_pickup
                    ) {
                      return;
                    }
                    handleChange('immediately', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text'>Dispatch to Driver Immediately</p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.after_specific_shipment_complated}
                  name='after_specific_shipment_complated'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('in_future', false);
                      handleChange('before_scheduled_pickup', false);
                    } else if (!values.immediately && !values.in_future && !values.before_scheduled_pickup) {
                      return;
                    }
                    handleChange('after_specific_shipment_complated', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text me-2'>
                  Pending Dispatch as Next Shipment in Sequential Date Order
                </p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2 w-100'>
                <CustomCheckbox
                  type='switch'
                  checked={values.in_future}
                  name='in_future'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('after_specific_shipment_complated', false);
                      handleChange('before_scheduled_pickup', false);
                    } else if (
                      !values.immediately &&
                      !values.after_specific_shipment_complated &&
                      !values.before_scheduled_pickup
                    ) {
                      return;
                    }
                    handleChange('in_future', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text me-2'>Pending Dispatch Queue for Dispatch on</p>
                <DateTimePicker
                  width='190px'
                  name='future_date'
                  value={values.future_date}
                  onChange={(value) => handleChange('future_date', value)}
                  onBlur={(e) => {
                    const { value } = e.target;
                    if (value && moment(value).isValid() && moment(value).isBefore(moment())) {
                      handleChange('future_date', new Date());
                    }
                  }}
                  disablePast
                />
                <p className='dispatch-vehicle-check-text'>(Based on Specific Date/Time)</p>
              </div>

              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.before_scheduled_pickup}
                  name='before_scheduled_pickup'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('in_future', false);
                      handleChange('after_specific_shipment_complated', false);
                    } else if (!values.immediately && !values.in_future && !values.after_specific_shipment_complated) {
                      return;
                    }
                    handleChange('before_scheduled_pickup', checked);
                  }}
                />
                <p className={`dispatch-vehicle-check-text ${matches ? 'nowrap' : ''}`}>
                  Pending Dispatch Queue for Dispatch
                </p>
                <div
                  className='dispatch-vehicle-input dispatch-vehicle-small-input'
                  style={{ marginLeft: '0.5rem', marginTop: 0 }}
                >
                  <input
                    type='number'
                    placeholder='0'
                    name='before_scheduled_pickup_hours'
                    value={values.before_scheduled_pickup_hours}
                    onChange={handleChange}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Hour(s) Prior to Scheduled Pickup</p>
              </div>
              <div className='with-border mt-2' />
              {customerContactBook && (
                <>
                  <div className='d-flex align-items-center my-2'>
                    <CustomCheckbox
                      type='switch'
                      checked={values.send_trucking_link_to_customer}
                      name='send_trucking_link_to_customer'
                      onChange={(checked) => handleChange('send_trucking_link_to_customer', checked)}
                    />
                    <p className='dispatch-vehicle-check-text'>Send Tracking Updates to Customer Contact</p>
                    <CustomSelect
                      options={customerContactBook}
                      defaultValue='Select Contact...'
                      onChange={(value) => handleChange('recipient_customer_contact_book_id', value)}
                      styles={{ maxWidth: 200, marginLeft: '0.5rem', marginTop: 0 }}
                      id='select_list'
                      name='recipient_customer_contact_book_id'
                      propertyName='contact_name'
                      patchProperty='id'
                    />
                  </div>
                  <div className='with-border mt-2' />
                </>
              )}
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.send_pickup_reminder_to_driver}
                  name='send_pickup_reminder_to_driver'
                  onChange={(checked) => {
                    if (!smsSettings?.send_sms) {
                      showToaster({
                        type: 'error',
                        message:
                          'Send SMS option is disabled for this account, please enable it from settings before using this option!',
                      });
                      return;
                    }
                    handleChange('send_pickup_reminder_to_driver', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text'>Send Driver SMS Reminder</p>
                <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                  <input
                    type='number'
                    placeholder='0'
                    name='send_pickup_reminder_to_driver_before_minutes'
                    value={values.send_pickup_reminder_to_driver_before_minutes}
                    onChange={handleChange}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Minute(s) Prior to Scheduled Pickup</p>
              </div>
            </form>

            <div className='with-border mt-2' />
            <div>
              <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Cost Estimate
              </h6>
              <div className='with-border mt-2' />
              <div className='dispatch-vehicle-cost'>
                <div>
                  <p className='dispatch-vehicle-cost-text'>Driver Costs</p>
                  <p className='dispatch-vehicle-cost-text'>
                    Estimated Fuel Costs{' '}
                    <span className='cost-purple-text'> ${(+fuelPrice).toFixed(2)} /GAL DIESEL</span>
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-bold-text'>Estimated Costs</p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {flatRateChecked && flatRate
                      ? formatAmount(flatRate)
                      : formatAmount(Number(data?.driver1_cost) + Number(data?.driver2_cost))}
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(data?.estimated_fuel_cost)}
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-bold-text'>
                    {currency}
                    {flatRateChecked && flatRate
                      ? formatAmount(+flatRate + Number(data?.estimated_fuel_cost))
                      : formatAmount(data?.cost)}
                  </p>
                </div>
              </div>
            </div>
            <div className='with-border mt-2' />
            <div>
              <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Net Profit
              </h6>
              <div className='with-border mt-2' />
              <div className='dispatch-vehicle-cost'>
                <div>
                  <p className='dispatch-vehicle-cost-text'>
                    Gross Shipment Charges{' '}
                    <span className='profit-gray-text'>
                      {currency}
                      {formatAmount(
                        Number(data?.empty_miles) || Number(data?.loaded_miles)
                          ? Number(data?.total_amount) / (Number(data?.empty_miles) + Number(data?.loaded_miles))
                          : 0
                      )}{' '}
                      /MILE EST. GROSS
                    </span>
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    Estimated Shipment Costs{' '}
                    <span className='profit-red-text'>
                      {currency}
                      {flatRateChecked && flatRate
                        ? formatAmount(
                            Number(data?.empty_miles) || Number(data?.loaded_miles)
                              ? (Number(flatRate) + Number(data?.estimated_fuel_cost)) /
                                  (Number(data?.empty_miles) + Number(data?.loaded_miles))
                              : 0
                          )
                        : formatAmount(
                            Number(data?.empty_miles) || Number(data?.loaded_miles)
                              ? (Number(data?.cost) + Number(data?.estimated_fuel_cost)) /
                                  (Number(data?.empty_miles) + Number(data?.loaded_miles))
                              : 0
                          )}{' '}
                      /MILE EST. COST
                    </span>
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-green-text'>
                    Estimated Net Profit{' '}
                    <span className='profit-green-text'>
                      {currency}
                      {flatRateChecked && flatRate
                        ? formatAmount(
                            Number(data?.empty_miles) || Number(data?.loaded_miles)
                              ? (Number(data?.total_amount) - (Number(flatRate) + Number(data?.estimated_fuel_cost))) /
                                  (Number(data?.empty_miles) + Number(data?.loaded_miles))
                              : 0
                          )
                        : formatAmount(
                            Number(data?.empty_miles) || Number(data?.loaded_miles)
                              ? Number(data?.net_profit) / (Number(data?.empty_miles) + Number(data?.loaded_miles))
                              : 0
                          )}{' '}
                      /MILE EST. NET
                    </span>
                  </p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(data?.total_amount)}
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {flatRateChecked && flatRate
                      ? formatAmount(+flatRate + Number(data?.estimated_fuel_cost))
                      : formatAmount(data?.cost)}
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-green-text'>
                    {currency}
                    {flatRateChecked && flatRate
                      ? formatAmount(Number(data?.total_amount) - (+flatRate + Number(data?.estimated_fuel_cost)))
                      : formatAmount(data?.net_profit)}
                  </p>
                </div>
              </div>
            </div>
            <div className='with-border mt-2' />
            <div className='d-flex justify-content-end mt-4 mb-4'>
              <CustomButton
                width={12}
                height={12}
                type='secondary'
                title='Back'
                styleButton={{ padding: '6px 12px', marginRight: '18px' }}
                onClick={() => navigate(-1)}
              />
              <CustomButton
                width={12}
                height={12}
                type='primary'
                title='Dispatch Vehicle'
                leftIcon={
                  loadingDispatch ? (
                    <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                  ) : (
                    <ShipmentIcon style={{ marginRight: 10 }} />
                  )
                }
                disabled={loadingDispatch}
                styleButton={{ padding: '6px 12px' }}
                onClick={onDispatch}
              />
            </div>
          </div>
        </div>
        <div className='dispatch-vehicle-map'>
          <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
            Route
          </h6>
          <div className='with-border mt-2' />
          <div className='d-flex justify-content-between align-items-center my-2'>
            <div className='d-flex align-items-center'>
              <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                {routeSelect}
              </p>
              <Dropdown className='d-inline mx-2'>
                <Dropdown.Toggle
                  // className="AvailableButton btn btn-link"
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '10x',
                  }}
                >
                  <img src={down} alt='down' />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                    onClick={() => handleRouteSelect('practical')}
                  >
                    Practical
                  </Dropdown.Item>

                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => handleRouteSelect('shortest')}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Shortest
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => handleRouteSelect('fastest')}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Fastest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <div className='d-flex align-items-center mb-1'>
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem', marginLeft: 0 }}>
                  Open Borders
                </p>
                <CustomCheckbox
                  checked={!!mapValues.open_borders}
                  onChange={(checked) => handleMapValuesChange('open_borders', checked)}
                />
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem' }}>
                  Highway Only
                </p>
                <CustomCheckbox
                  checked={!!mapValues.hwy_only}
                  onChange={(checked) => handleMapValuesChange('hwy_only', checked)}
                />
                <p className='dispatch-vehicle-check-text'>HazMat</p>
                <CustomCheckbox
                  checked={!!mapValues.haz_mat}
                  disabled
                  onChange={(checked) => handleMapValuesChange('haz_mat', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                <CustomCheckbox
                  checked={!!mapValues.avoid_tolls}
                  onChange={(checked) => handleMapValuesChange('avoid_tolls', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Traffic</p>
                <CustomCheckbox
                  checked={!!mapValues.show_traffic}
                  onChange={(checked) => checkAddonStatus('show_traffic', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Weather</p>
                <CustomCheckbox
                  checked={!!mapValues.show_weather}
                  onChange={(checked) => checkAddonStatus('show_weather', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
            </div>
          </div>
          <div style={style.mapWrapper}>
            {shipmentStops?.shipment_stops.length && data?.equipment && mapValues && infoHeader && (
              <DispatchMap
                data={shipmentStops?.shipment_stops}
                mapValues={mapValues}
                selectedVehicle={data?.equipment}
                empty_miles={data?.empty_miles}
                previousShipment={previousShipment}
                planedShipment={planedShipment}
                customLatLong={customLatLong}
              />
            )}
          </div>
        </div>
      </div>
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          isShipmentTable={false}
          onAdd={onAddEditModalDetails}
          onUpdateSuccess={getStopsData}
          shipmentId={shipmentId}
          legs={legs}
        />
      )}
      {openCostModal && (
        <CostModal
          open={openCostModal}
          costFormValues={costForm}
          onClose={() => setOpenCostModal(false)}
          data={data}
          flatRate={flatRate}
          flatRateChecked={flatRateChecked}
          onApply={(values) => {
            setCostForm(values);
            setOpenCostModal(false);
          }}
        />
      )}
      <AlreadyDispatchedModal
        open={!!alreadyDispatched}
        text={alreadyDispatched}
        onClose={() => setAlreadyDispatched(null)}
      />
    </div>
  );
}
